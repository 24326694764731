export default function NorthAmerica({innerRef}) {
    return <svg ref={innerRef} version="1.1" id="svgpoint" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 900 700" enable-background="new 0 0 900 700" width="100%" height="100%">
<g id="WATER_3_" data-colors="0" data-errors="0" hint-index="0" class="">
    <rect x="-8" y="0.444" fill="#a4d1dc" width="910.633" height="700.05" pointer-events="none" opacity="1" stroke="#95bec8" stroke-width="0.1" class="water"></rect>
</g>
<g id="AREA_THEDOMINICANREPUBLIC" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Dominican Republic" data-wikipediaLink="https://en.wikipedia.org/wiki/Dominican_Republic" data-infoText="<b>Dominican Republic</b> &amp;nbsp; Capital: Santo Domingo" data-imageFile="DOMINICA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M733.483,526.044c-0.74-0.2-1.08-2.56-2.36-2.63
        s-0.81-1.55-2.49-1.55c-1.69,0-3.03,0.61-4.11-0.07s-1.49-1.21-0.61-1.35s1.35-0.27,1.15-1.15c-0.2-0.88-1.01-0.4-1.62-0.07
        c-0.61,0.34-0.95,1.42-1.89,1.42s-0.27-0.88-1.82-1.69s-1.35-1.55-2.49-2.36c-1.15-0.81-1.96-1.82-3.37-1.89s-1.75-0.81-2.77-0.81
        c-1.01,0-1.62-0.94-2.9-0.88c-1.28,0.07-1.62,0.2-2.29-0.61s-1.35-1.22-2.02-1.01s-0.81,1.62-1.96,1.48s-2.3-1.15-3.37-1.15
        c-1.08,0-1.68,0.47-1.96,1.01c-0.27,0.54-0.47,1.75-1.15,1.96c-0.03,0.01-0.06,0.02-0.09,0.03c0.35,0.7,0.64,1.48,0.59,2.14
        c-0.12,1.61-0.99,2.35-0.25,3.34s1.98,2.1,0.99,2.23c-0.99,0.12-1.73-0.62-1.98,0.25s-0.37,1.48,0.62,1.73s1.49,1.11,0.87,1.49
        c-0.62,0.37-0.62,0.99-1.73,1.24s-2.6,1.23-1.49,1.86c1.11,0.62,2.35,0.74,2.72,1.49s0.99,1.61,0.5,2.47
        c-0.37,0.65-0.96,1.02-1.17,2.01c0.06,0.03,0.09,0.07,0.15,0.1c1.22,0.61,1.62,1.08,1.62,1.69s0.07,1.69,1.15,1.69
        s0.34,1.48,1.35,1.48c1.01,0-0.27-1.08,1.08-1.28c1.35-0.2,2.16-0.68,2.16-2.02s0.61-1.15,0.74-2.7s-0.54-2.43,0.88-2.56
        c1.42-0.14,3.1,1.08,3.37-0.2s0.41-1.55,1.48-1.48c1.08,0.07,0.2,2.23,1.21,2.43s0.95,0.88,2.23,0.07
        c1.28-0.81,1.21-1.28,2.16-1.28s0.94-0.47,1.28-1.01c0.34-0.54,0.88-1.01,2.43-1.01s4.52,0,5.73,0c1.21,0,1.55,0.34,1.89,0.94
        c0.34,0.61,1.01-0.13,1.08-0.61c0.07-0.47,1.21-0.61,1.75,0.4s1.55,0.81,1.76,1.69c0.2,0.88,0.87,1.42,1.35,1.35
        c0.47-0.07,0.2-0.4,0.07-1.28s-0.47-1.55,0.34-2.02c0.81-0.47,2.09-0.74,2.09-1.55
        C734.363,526.994,734.223,526.244,733.483,526.044z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_15_" x="697.853" y="520.414" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="71.09" height="24.99" pointer-events="auto" visibility="hidden"></rect>

        <ellipse fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" cx="723.881" cy="523.434" rx="26.018" ry="37.43" pointer-events="auto" opacity="0.001" class="clickarea landarea"></ellipse>
</g>
<g id="LAND" data-colors="0" data-errors="0" hint-index="0" class="">
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M720.153,969.104c0.91-0.82,1.95-1.09,1.95-2.49
        c0-1.4-1.31-2.9,0.22-3.94s2.99,0.04,3.13-1.86s-0.14-1.99-0.77-2.63c-0.63-0.63-0.99-2.31,0.77-2.67s3.21-1.63,3.85-2.85
        c0.64-1.22,1.63-2.49,2.45-3.94s1.99-2.08,1.67-3.53s0.46-2.58-1.45-3.62c-1.9-1.04-4.48-2.58-3.9-4.48
        c0.59-1.9,2.58-1.58,2.31-3.53s-3.04-2.54-2.95-3.8c0.09-1.27,2.54-2.85,3.31-3.89s3.12-2.81,3.12-4.76s-1.59-2.4-1.68-4.08
        c-0.09-1.67,1.49-2.99,1.4-4.48c-0.09-1.49-1.22-4.53-0.41-5.97c0.82-1.45,1.95-2.45,2.53-3.26c0.59-0.81,1.99-1.77,0.68-3.44
        c-1.31-1.68-1.77-1.63-2.08-3.49c-0.32-1.86-1.36-2.44-2.13-3.8s-1.45-2.22-2.26-3.08c-0.82-0.86-1.54-1.9-1.67-3.13
        c-0.11-0.97-0.43-2.2-0.43-3.48c-1.52-0.01-2.98,0.34-4.74,0.1c-1.94-0.26-1.38,0.26-3.02,0.51c-1.64,0.26-0.46,1.58-2.51,1.99
        c-2.04,0.41-1.53-0.66-1.89-1.69s-0.97,0.16-2.1,0.16s-0.1-1.13,0.15-2.51c0.26-1.38,0.2-1.68-0.41-2.56
        c-0.61-0.87-0.16-1.48-0.16-2.66s0.82-2.71,0.72-4.09s-0.3-1.53,0.56-2.25c0.87-0.72,0.56-1.99,0.87-3.53
        c0.3-1.53-0.36-1.28-1.38-1.12c-1.02,0.16-2.14,1.28-3.06,2.76s-4.04,3.78-5.98,4.34s-6.59,0.46-7.82,0.26s-2.5-2.1-2.66-3.27
        c-0.16-1.18-0.87-2.2-1.89-2.3s-1.53-0.31-1.99-0.92s-2.2-0.97-4.34-0.72c-2.15,0.26-2.46,0.05-2.46-1.18s1.48-1.43,2.1-2.1
        s-0.36-1.02-1.07-1.69c-0.72-0.67-1.28-1.38-1.53-2.14c-0.25-0.77-1.23-1.23-2.56-2.04c-1.33-0.82-2.3-3.88-3.22-5.32
        c-0.92-1.43-2.04-1.69-1.99-3.22c0.05-1.53,0-2.2-1.64-3.22c-1.64-1.02-1.38-2.15-0.1-2.56s2.2-0.92,2.51-1.84
        c0.31-0.92-0.36-1.58-0.61-2.25s0-1.23,0.31-2.2c0.31-0.97,1.79-1.74,2.66-1.99c0.87-0.26,1.68-1.48,3.37-1.89
        c1.69-0.41,1.58-1.99,1.53-2.76s-0.51-0.87-1.13-1.33c-0.61-0.46-0.51-1.99,0.77-2.66c1.28-0.66,0.51-1.43,1.58-2.6
        c1.07-1.18,1.23-1.94,1.28-3.02s-0.26-2.56-0.26-3.58s2.04-1.78,3.58-2.3c1.53-0.51,1.99-2.4,3.17-2.71
        c1.18-0.3,3.27-1.38,4.34-2.35s1.94-1.38,3.22-1.43s1.94-0.77,3.94-0.87c1.99-0.1,3.52-0.46,5.21-0.82s1.94-1.38,2.66-2.5
        c0.72-1.13,1.84-0.26,3.73-0.46c1.89-0.2,2.76,2.45,4.5,2.45s1.89-0.51,1.99-1.43s-1.33-1.89-1.84-2.56s-1.43-1.28-2.04-2.15
        s-1.18-0.41-2.66,0s-2.1,0.1-2.86-0.51c-0.77-0.61,0.3-1.07,0.82-2.15s1.33-1.02,2.1-1.79c0.77-0.77,0.97-1.79,1.43-3.17
        s2.1-3.22,3.38-4.19c1.28-0.97,0.1-2.5-0.66-3.12c-0.77-0.61-2.15-0.87-3.17-0.87s-2.15-0.77-3.17-1.28s-0.72-2.3-1.94-2.45
        c-1.23-0.15-0.87,0.87-1.64,0.92s-1.69,0.46-2.15,1.28s-1.64,0.05-2.4-0.46c-0.77-0.51-1.12-0.1-1.94-1.02
        c-0.82-0.92-1.18-1.12-2.25-1.12s-1.79,0.97-2.04,1.64s-0.82,1.12-1.64,1.22s-1.33,0.41-2.1,1.08c-0.77,0.66-1.73-0.1-2.55-0.1
        s-1.84-0.1-2.66-0.71s-1.33,0.15-2.56,1.23c-1.23,1.07-1.99-0.31-3.22-1.23s-1.68-2.25-0.15-2.66c1.53-0.41,0.36-1.48-0.36-2.91
        s-1.43-1.28-2.45-1.17c-1.02,0.1-1.43-0.36-1.58-1.53c-0.15-1.18-0.51-2.04-0.87-3.07s-1.64-1.33-2.97-1.89
        c-1.33-0.56-2.04-1.38-3.94-1.99c-1.89-0.61-2.61-3.84-3.32-5.27s-1.74-2.86-3.53-3.32c-1.79-0.46-2.71-0.97-3.53-2.3
        s-1.38-1.33-3.63-1.38c-1.11-0.03-2.09,0.02-2.79,0.08c0.07,0.38-0.14,0.83-0.38,1.58c-0.45,1.43-1.05,1.84,0.68,2.55
        c1.73,0.71,1.01,1.77,1.39,3.12s0.22,1.2,1.01,2.7c0.79,1.5-1.43,0.83-2.44,1.84s-0.24,1.28-1.24,4.38s-3.8,4.01-4.6,5.91
        c-0.8,1.9-3,3.1-4.31,4.1c-1.3,1-2.7,1.7-3.8,3.1c-1.1,1.4-4.51,1.7-5.71,2.1c-1.2,0.4-2.8,1.6-5.41,2.3c-2.6,0.7-4.71,2.5-6.61,3
        c-1.9,0.5-1.4,2.4-3.21,3.41c-1.8,1-0.6,1.5-1,4.1s-2.2,2.6-2.7,4.21c-0.5,1.6-1.7,2.4-1,4.1c0.7,1.7-0.7,2.1-2.6,2.7
        c-1.9,0.6-1.1,2.4-2,3.91s-1.8,0.5-2.9-0.2c-1.1-0.7-1-1-2.1-1.6s-0.4-1.2-0.2-1.9s-0.3-2.5-1-2.6s-1.3,0.5-2.2,0.6s-1.4-0.8-2.3-1
        c-0.9-0.2-0.5-0.9-1.9-0.9s-0.2-1-1.7-1.2s-1,2.5-1.8,2.5s-2-0.2-1.9-1.3s-0.2-1.8-0.8-2.6s0.8-0.5,1.1-1.7s1.5-0.1,3-0.7
        c1.5-0.6,0.6-1.5,0.3-2.1s0-1.3-0.9-2.4c-0.45-0.55-0.65-1.53-0.73-2.39c-1.77,0.33-2.59,1.34-3.17,2.14
        c-0.6,0.83-1.13,1.28-2.1,1.65c-0.98,0.38-1.58,0.9-1.58,1.8s-0.45,1.58-1.43,2.1s-1.35,1.8-2.78,2.63
        c-1.43,0.83-1.35,1.28-1.35,2.18s-0.75,3.08,0.3,3.91c1.05,0.83,0.98,1.28,1.65,1.73c0.68,0.45,1.05,1.13,0.53,1.73
        c-0.53,0.6-1.05,0.53-0.83,1.35c0.22,0.83,0.08,0.83,1.05,1.43c0.98,0.6,2.18,1.2,2.33,2.1c0.15,0.9,0.98,1.2,1.05,2.18
        c0.08,0.98,0.15,1.95-1.43,2.18s-1.09,0.86-2.1,1.05c-1.01,0.19-0.79,1.43,0.19,1.8c0.98,0.38,1.28,0.79,2.29,1.58
        c1.01,0.79,2.33,0.6,3.49,1.77s1.28,1.58,2.52,1.88s1.28,0.34,2.25,1.09c0.98,0.75,1.43,1.16,2.29,1.46
        c0.86,0.3,0.79,0.19,1.54,1.09s3.08,4.92,4.17,6.57c1.09,1.65,1.13,2.97,2.25,4.47s2.1,2.59,3.08,3.64
        c0.98,1.05,2.44,2.67,3.15,3.79c0.71,1.13,1.61,2.33,1.77,4.02s0.49,2.37,1.43,3.68s1.54,2.22,1.54,2.78s1.16,1.01,1.5,2.33
        s0.26,1.5,1.2,2.78s1.61,2.07,1.46,3.27c-0.15,1.2-0.19,2.59,0.56,3.68s2.67,3.53,3.23,4.47c0.56,0.94,2.74,3.34,2.78,4.66
        c0.04,1.31-0.15,2.4,0.86,3.38s1.61,1.43,2.33,1.46c0.71,0.04,1.58,1.77,1.99,3.27s0.68,2.03,0.68,2.78s-0.56,2.25,0.75,2.93
        s3.38,1.58,4.09,3c0.71,1.43,2.03,3.6,2.97,5.29c0.94,1.69,1.61,2.97,2.48,3.64c0.86,0.67,1.16,1.54,1.31,2.22
        c0.15,0.67,0.38,4.13-0.49,4.54c-0.86,0.41-1.2,1.28-1.09,1.8c0.11,0.53,0.83,2.4,1.58,3.12c0.75,0.71,1.54,1.69,1.65,2.63
        c0.11,0.94,1.01,2.14,1.99,2.97c0.98,0.83,2.1,0.56,3.15,1.8c1.05,1.24,2.48,1.77,3.27,2.93s3.04,4.32,4.36,4.62
        c1.31,0.3,2.48,1.01,3.6,1.65s3.83,1.69,4.58,2.25s1.61,0.68,2.44,1.28c0.83,0.6,2.22,1.88,2.89,2.37c0.68,0.49,2.29,1.43,3.6,1.54
        s1.65-0.22,2.52,0.49c0.86,0.71,1.91,1.73,3.46,1.84c1.54,0.11,2.52,1.16,3.64,1.32c1.13,0.15,3.08,0.75,3.72,1.31
        c0.64,0.56,2.03,0.83,3.68,2.44s2.59,2.74,4.51,3c1.91,0.26,3.46,1.77,4.05,3.27c0.6,1.5,1.54,2.14,2.74,3.27s4.88,4.36,6.01,5.07
        c0.9,0.57,2.31,1.13,3.17,1.85c0.37-0.27,0.88-0.48,1.59-0.48C718.063,969.914,719.243,969.914,720.153,969.104z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M723.043,801.334c0.19-1.61,0.16-3.52,0.86-5.72
        c0.7-2.2,0.1-3.81,0.8-5.41s1.1-2.6,1.2-4.6c0.1-2-0.4-2.5,0.4-4.21c0.8-1.7,0.9-3.31,1-5.91s0.4-2.3,1-4s1.9-3.91,0.9-5.41
        s-2.3-1.2-2.5-3.1c-0.2-1.9-1.4-4.31-2.8-5.41s-2.6-1.9-2.7-3.41c-0.1-1.5-0.5-1.7-0.5-3.4s-0.4-5.11,0.9-5.51s2.7-0.4,3.8-1
        s2-0.8,2.6-0.4c0.6,0.4,1,1.3,2.2,1.2s0.8-1.01,1.5-1.4s0.6-1.2,0-1.5s-2-0.5-2-1.5s0.1-1.5-1.3-1.3c-1.4,0.2-1.7,0.5-2.6,0.5
        c-0.9,0-3.6,0.7-3-1.7s-0.7-5.01,0.5-5.91c1.2-0.9,3.2-1.8,4.41-1.3c1.2,0.5,3.1,1.1,5.51,1c2.4-0.1,1.8-1.1,4.51-1
        c2.7,0.1,6.81,2.01,5.71,0c-1.1-2-2.1-2.2-1.4-3.1c0.7-0.9,1.8,0.3,2.7,1.7s1.8,1.6,3,1.1s1.4-1.4,1.5-2.6c0.1-1.2,1.6-2.2,2.8-1.7
        s3.11,2.2,3.51,3.91s0.8,1.7,0.7,3.2s0.9,3.51,1.8,4.11c0.57,0.38,1.56,0.52,2.55,0.18c-0.74-1.06-1.44-2.46-1.54-3.48
        c-0.15-1.5-0.75-2.48-1.35-3.76s-0.98-2.18-1.13-4.06s-2.48-4.28-2.93-5.03s-1.88-1.8-3.16-2.1c-1.28-0.3-1.2-1.13-2.55-1.8
        s-0.3-2.18,0.9-2.78c1.2-0.6,2.1-2.03,3.38-2.63s1.65-0.98,1.58-2.18c-0.08-1.2-1.95-2.03-2.85-3s-1.43-2.1-2.48-3.6
        s-0.9-3.91-1.65-5.03s-0.15-1.95,0.08-3.08c0.22-1.13,0.15-2.25,0.15-3.38s-0.22-2.1-0.38-3.38c-0.15-1.28,0.45-1.28,1.5-2.63
        s1.05-2.33,0.98-3.91c-0.08-1.58,2.03-1.28,2.1-3.3s-1.35-2.85-2.78-3.15s-1.73,0.08-2.33,0.6s-1.13,0.3-2.63,0.3s-2.1-0.38-3,0.53
        c-0.9,0.9-4.21,0.83-5.56,0.53s-1.43,0-3.91,0s-0.83,2.18-3.3,1.65c-2.48-0.53-2.33-2.85-3.08-3.83s-1.95-2.03-3.08-2.93
        c-1.13-0.9-1.58-1.43-2.03-2.85s-1.5-0.9-3.08-0.9s-2.25-0.53-2.7-1.05c-0.45-0.53-1.05-0.75-2.25-0.75s-1.88,0.68-2.33,1.5
        c-0.45,0.83-1.95,1.2-3.38,0.45s-1.05-0.08-1.88,0s-2.1-0.75-3.08-1.05c-0.98-0.3-2.4,1.05-3.83,1.13s-2.25-1.13-2.48-2.18
        s-0.6-1.05-1.5-1.43s-1.65-1.95-2.4-2.7s-0.75-1.58-0.15-2.4c0.6-0.83-0.08-1.43-0.38-2.55c-0.3-1.13,1.35-0.75,2.03-1.95
        c0.67-1.2-0.45-1.58-0.53-2.55c-0.08-0.98-0.45-1.2-1.2-1.65s-1.28-1.35-2.4-2.1c-1.13-0.75-1.35-2.93-1.13-4.13
        s-0.53-1.5-1.13-1.5s-0.98-1.13-1.88-2.1c-0.9-0.98-1.88,0.15-2.55,1.05c-0.68,0.9-2.18,0.75-2.25-0.6
        c-0.08-1.35,0.9-1.73,2.18-2.63c1.28-0.9,1.35-1.8,2.18-3.16c0.83-1.35,0.75-3.76,0.83-4.88c0.08-1.13-0.3-2.18-0.07-3.98
        c0.22-1.8,2.25-2.63,2.78-4.36s1.58-1.88,2.03-3.3s1.28-1.46,2.14-1.5s1.24-0.56,2.18-1.58c0.94-1.01,0.36-1.28,0.36-2.32
        s1.32-2.26,2.54-2.63c1.22-0.38,3.48-1.22,5.36-1.22c0.48,0,0.83,0.08,1.09,0.2c0.28-0.22,0.56-0.48,0.84-0.98
        c0.53-0.94,1.05-1.43,1.54-1.8c0.49-0.38,0.75-0.79,0.75-1.43s-0.56-0.56-1.2-1.5s-1.2-0.53-1.8-1.43s-0.53-1.13-1.84-1.24
        s-3.15-0.04-3.34,0.75s0.3,1.24-1.43,1.54s-2.22,0.08-2.59,1.16c-0.38,1.09-0.86,2.14-1.73,2.97c-0.86,0.83-1.35,1.77-2.89,1.99
        c-1.54,0.23-1.84,0.68-2.89,0.79s-1.05,0.79-1.84,1.35c-0.79,0.56-0.71,1.16-1.58,1.28c-0.86,0.11-1.39,0.83-1.58,1.39
        s-0.11,0.75-1.09,0.9c-0.98,0.15-2.29,0.34-2.7,0.75c-0.41,0.41-0.79,0.75-1.65,0.79c-0.86,0.04-1.61,0.38-1.88,0.04
        c-0.26-0.34,0.04-1.65-1.31-1.69s-1.65-0.19-2.25,0s-1.5,0.49-1.58,1.09s-0.6,1.13-0.45,1.65c0.15,0.53,0.68,1.39,0.04,1.84
        s-1.01,0.38-1.01,0.9c0,0.53,0.11,1.24-0.64,1.43s-1.47-0.71-1.13-1.46c0.34-0.75,1.05-0.15,1.09-0.86
        c0.04-0.71-0.22-0.83-1.28-0.71c-1.05,0.11-2.18,0.11-2.89-0.41c-0.71-0.53-0.22-1.2-1.05-0.98c-0.83,0.22-0.9,1.35-1.99,1.88
        c-1.09,0.53-0.08,1.93-1.46,2.21s-1.84,1.28-2.94,2.02c-1.1,0.73-1.93,1.29-1.75,2.57c0.18,1.29-1.01,1.01-1.01,2.2
        s1.65,1.1,1.19,2.11s-1.75-0.64-1.38,1.65c0.37,2.3,1.29,6.06-0.92,5.97c-2.2-0.09-3.3,0-3.67,1.29s-0.09,2.02-1.29,2.39
        c-1.19,0.37-0.18,1.84-2.02,2.02s-2.02,1.01-2.3,1.84c-0.28,0.83-0.46,1.19-1.47,1.38c-1.01,0.18-1.65,0.46-2.02,1.28
        s-1.56,0.55-1.38,1.38s1.74,0.37,1.56,1.93s0.73,2.3-0.09,3.31c-0.83,1.01-0.18,1.84-1.1,1.74c-0.92-0.09,0.09-2.38-0.92-3.21
        s-1.28-2.02-2.29-2.3c-1.01-0.28-1.01-0.73-1.75-1.74c-0.73-1.01-0.46-1.93-1.84-1.93c-0.06,0-0.1-0.03-0.15-0.03
        c0.06,0.44,0.18,0.88,0.18,1.58c0,1.28,0.33,2.18,1.45,3.96s1.28,1.23,1.67,1.9c0.39,0.67,0.06,1.23-0.56,1.73
        c-0.61,0.5-0.84,0.84-0.89,2.12c-0.06,1.28-2.01,1.28-2.85,1.45c-0.84,0.17-0.84,0.78-2.29,1.84c-0.84,0.61-1.54,1.76-1.99,2.62
        c0.16,0.18,0.34,0.34,0.45,0.54c0.72,1.29,1.37,2.49,1.85,3.09s0.52,1.45,1.41,2.33c0.88,0.89,2.33,1.53,2.41,2.45
        s0.64,1.97-0.12,2.37s-1.09,1.25-1.04,2.01c0.04,0.76,0.2,1.45,0.52,1.65s0.8,0.28,0.8,0.76s-0.12,0.77,0.44,1.25
        s0.64,0.52,0.76,1.41s0.77,1.73-0.64,2.13c-1.41,0.4-2.21,0.28-2.21,0.97s-0.36,1.08,0.52,1.6c0.88,0.52,0.92,0.72,0.92,2.09
        c0,1.37-0.12,3.22,0.36,3.94c0.48,0.72,1.09,0.8,1.13,1.45s-0.2,1.12-0.84,1.49c-0.64,0.36-0.48,0.64-0.52,1.69
        c-0.04,1.04-0.2,2.21,0.44,2.57s1.09,0.2,1.04,0.96c-0.04,0.77,0.36,1.09-0.8,1.21s-1.37,0.12-1.37,0.48s0.68,0.28,0.76,1.04
        c0.08,0.77,0.56,0.77,1.09,0.8c0.52,0.04,1.08-0.4,2.09,0.4c1.01,0.8,0.64,1.09-0.08,1.13c-0.72,0.04-1,0.44-0.92,1.08
        s-0.48,2.41-1.2,2.69c-0.72,0.28-1.37,1.21-1.33,1.89s-0.24,1-0.8,1.32s-1.28,1.13-1.28,1.77s0.04,1.57-0.52,1.93
        s-1.81,1.69-1.81,2.61s0,1.69-1.28,1.69c-1.29,0-1.49-0.4-2.33,0s-1.49,1.01-2.97,1.04c-1.49,0.04-2.49-0.24-2.69,0.44
        s-1.01,0.65-1.24,1.57c-0.24,0.92,0.2,0.68-0.48,0.88s-0.48,0.8-0.44,1.29c0.04,0.48,0.88,1.04,1.04,1.93s0.72,1.69-0.32,1.81
        s-3.86,0.32-4.1,1.08s-0.48,1.36,0.44,1.89c0.92,0.52,1.81,0.84,1.73,1.69c-0.02,0.2-0.03,0.41-0.06,0.64
        c3.45,0.65,4.18,3.04,5.77,4.04c1.8,1.13,2.4,1.73,3.68,2.18c1.28,0.45,2.48,0.45,4.66,0.98c2.18,0.53,1.95,2.85,2.33,4.05
        s2.55,1.28,3.76,1.28c1.2,0,3.08,1.2,4.36,1.5c1.28,0.3,2.93-0.3,4.21-0.3s0.83-1.28,1.2-2.33c0.38-1.05,1.5,0.83,3.3,0.83
        s1.73,1.05,2.25,2.4c0.53,1.35,3.3,1.28,4.81,2.03c0.71,0.36,0.99,0.63,1.05,0.97c0.7-0.06,1.68-0.11,2.79-0.08
        c2.25,0.05,2.81,0.05,3.63,1.38s1.74,1.84,3.53,2.3c1.79,0.46,2.81,1.89,3.53,3.32c0.71,1.43,1.43,4.65,3.32,5.27
        c1.89,0.61,2.6,1.43,3.94,1.99c1.33,0.56,2.61,0.87,2.97,1.89s0.72,1.89,0.87,3.07s0.56,1.64,1.58,1.53
        c1.02-0.1,1.74-0.26,2.45,1.17c0.72,1.43,1.89,2.51,0.36,2.91s-1.08,1.74,0.15,2.66c1.23,0.92,1.99,2.3,3.22,1.23
        s1.74-1.84,2.56-1.23c0.82,0.61,1.84,0.71,2.66,0.71s1.78,0.76,2.55,0.1c0.77-0.66,1.28-0.97,2.1-1.08
        c0.82-0.1,1.38-0.56,1.64-1.22s0.97-1.64,2.04-1.64c1.08,0,1.43,0.2,2.25,1.12s1.17,0.51,1.94,1.02s1.94,1.28,2.4,0.46
        s1.38-1.23,2.15-1.28c0.77-0.05,0.41-1.07,1.64-0.92c1.22,0.15,0.92,1.94,1.94,2.45s2.15,1.28,3.17,1.28s2.4,0.25,3.17,0.87
        c0.77,0.61,1.94,2.15,0.66,3.12s-2.92,2.81-3.38,4.19s-0.66,2.4-1.43,3.17c-0.77,0.77-1.58,0.71-2.1,1.79s-1.58,1.53-0.82,2.15
        c0.77,0.61,1.38,0.92,2.86,0.51s2.05-0.87,2.66,0s1.53,1.48,2.04,2.15c0.42,0.54,1.44,1.3,1.75,2.06
        C722.443,802.634,722.943,802.134,723.043,801.334z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M776.733,534.224c0,0-1.39-0.11-1.46,0.38s-0.86,1.35,0.04,1.65
        c0.9,0.3,0.98,0.19,1.16-0.38c0.19-0.56,0.11-0.75,0.86-0.68s1.28,0.34,1.39-0.23C778.843,534.414,778.013,534.374,776.733,534.224
        z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M768.923,529.534c0,0-0.38-0.3-0.49,0.3s-0.34,0.71-0.86,0.79
        c-0.53,0.08-0.71,0.56-0.53,0.9s0.83,0,1.05-0.34s1.16-0.34,1.31-0.75C769.563,530.014,769.453,529.754,768.923,529.534z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M773.813,527.234c0,0-1.09-0.26-1.09,0.26s0.15,0.75,0.79,0.79
        c0.64,0.04,1.2,0.41,1.46-0.23C775.233,527.424,774.563,527.574,773.813,527.234z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M794.723,527.204c0,0-1.01,0.49-0.75,0.83s0.6,0.15,0.6,0.6
        c0,0.45-0.6,1.09-0.53,1.43c0.07,0.34,0.3,0.45,0.86,0.49s1.05,0,1.05-0.71s0.26-1.5-0.04-1.95
        C795.623,527.424,795.253,526.864,794.723,527.204z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M800.363,538.734c0,0-1.05-0.45-1.16,0.08s-0.68,0.94,0.38,1.05
        C800.623,539.964,801.483,539.214,800.363,538.734z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M810.723,532.944c0,0-0.41,0.04-1.09,0
        c-0.68-0.04-1.05-0.15-1.05,0.38s1.35,0.34,1.35,1.01s0.34,0.71,0.79,0.71c0.45,0,1.2,0.6,1.28-0.15s-0.71-0.49-0.79-0.98
        C811.133,533.434,811.253,533.064,810.723,532.944z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M810.613,540.084c0,0-0.6,0.3-0.6,0.68s-0.75,1.05-0.15,1.35
        s0.83-0.15,1.28-0.49s0.98-0.38,0.98-0.9C812.113,540.194,811.663,539.594,810.613,540.084z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M814.403,548.534c0,0,0.34,0.6-0.68,0.6c-1.01,0-2.7,0-2.7,0.86
        s-0.56,2.37,0.26,2.48c0.83,0.11,1.01,0.11,1.01,0.68s0.45,0.79,1.01,0.83c0.56,0.04,0.94,0,1.01-0.71
        c0.08-0.71,0.04-0.94,0.45-1.13c0.41-0.19,0.11-1.84,1.09-1.92s1.88,0.45,1.99-0.22c0.11-0.68-0.08-0.83-0.71-0.79
        c-0.64,0.04-0.75-0.11-0.98-0.64C815.943,548.044,814.213,547.514,814.403,548.534z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M818.913,553.865c0,0-0.75-0.6-1.09-0.11s-0.19,0.9,0.19,1.01
        C818.383,554.875,819.923,554.654,818.913,553.865z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M829.573,609.294c0,0,0.15,0.79-0.56,0.9
        c-0.71,0.11-0.94,0.08-0.98,0.45c-0.04,0.38-0.19,0.68-0.79,0.9c-0.6,0.22-0.6,1.01,0.22,0.94c0.83-0.08,0.3-0.79,1.28-0.98
        c0.98-0.19,0.94-0.22,1.09-0.71c0.15-0.49,0.86-0.19,0.83-1.09C830.623,608.805,829.533,608.654,829.573,609.294z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M823.073,616.274c0,0-1.2-0.08-1.58,0.41s-0.45,1.13-1.24,1.35
        s-1.99,0.15-1.77,0.86c0.23,0.71,1.01,0.23,1.05,1.2c0.04,0.98,0.64,2.18-0.68,2.82c-1.31,0.64-1.39,1.2-1.69,1.73
        c-0.3,0.53-0.38,0.49-1.39,0.68s-1.58,1.24,0,1.13s2.18-0.41,3.3-0.34c1.13,0.08,2.33,0.41,3.08-0.04c0.75-0.45,0.86-0.83,1.84-0.9
        c0.98-0.08,1.39,0.26,1.5-0.86c0.11-1.13,0.45-1.54,0.15-2.14c-0.3-0.6-0.53-1.13-0.26-1.77c0.26-0.64,0.94-1.35,0.45-1.65
        s-0.94-0.64-0.56-1.28s0.98-1.24,0.22-1.39C824.763,615.935,824.543,616.164,823.073,616.274z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M817.183,558.255c0,0-1.05-0.08-1.28,0.71
        c-0.23,0.79-0.53,1.35-0.08,1.88s0.94,0.53,0.98,1.43s0.38,1.16,1.01,1.16c0.64,0,1.09,0.38,1.05-1.01
        c-0.04-1.39,0.64-2.14-0.34-2.7C817.553,559.154,818.153,558.255,817.183,558.255z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M823.643,568.844c0,0,0.75-0.94-0.15-0.98s-0.19-1.2-1.16-1.28
        c-0.98-0.08-1.91,0.15-1.95,0.79c-0.04,0.64-1.28,0.08-1.24,0.86s1.24,0.64,1.24,1.54s0.41,1.39,1.01,1.54
        c0.6,0.15,0.19,0.94,0.94,0.94s1.01-0.3,1.16,0.3c0.15,0.6,0.98,1.31,1.2,0.45c0.22-0.86,0.56-2.93-0.04-2.97
        C824.053,570.014,823.223,569.714,823.643,568.844z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M823.673,578.154c0,0,0.26-1.24-0.41-1.24s-0.6,0.68-1.16,0.79
        s-0.94,0.9-0.71,1.43c0.22,0.53,1.05,0.3,1.05,1.35s1.09,1.73,1.24,0.79c0.15-0.94-0.08-1.09,0.56-1.05
        c0.64,0.04,1.28-0.3,0.75-0.75C824.463,579.024,823.643,579.174,823.673,578.154z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M820.453,586.084c0,0-0.6,0-0.19,0.83s1.05,0.38,1.09-0.22
        C821.383,586.084,821.013,586.005,820.453,586.084z" pointer-events="none" class="landarea"></path>
    <path display="none" fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M840.613,586.084c0,0-0.68-0.26-0.86,0
        s-0.26,0.71,0.04,1.28c0.3,0.56-0.15,0.86,0.41,0.9c0.56,0.04,0.79,0.15,0.75-0.49s0.71-0.11,0.71-0.9
        S841.623,586.384,840.613,586.084z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M891.463,1141.064c-0.22-1.28,0.83-2.03,0.98-3
        c0.15-0.98,0.68-2.18,1.88-2.48c1.2-0.3,0.53-1.35,1.88-1.88s1.65-0.75,1.65-1.58s-0.75-1.05-1.88-1.43s-1.88-1.13-2.4-2.1
        c-0.53-0.98-1.43-2.33-1.95-3.83c-0.53-1.5-1.58-1.65-3.46-1.73s-1.73-1.43-2.55-2.93s-2.78-2.25-3.68-2.55
        c-0.9-0.3-2.4-1.28-3.68-1.73c-1.28-0.45-2.33-2.18-2.48-3.15c-0.15-0.98-2.55-0.53-3,0s-1.43,1.8-2.48,1.65s-1.05-1.8-1.13-3.23
        s-1.05-2.25-1.43-2.55s-1.8-1.65-2.1-2.55s-2.18-1.35-2.63-2.25s-1.88-0.9-2.93-0.9s-1.2,1.05-1.8,1.88s-2.18,0.6-3.3,0.23
        c-0.36-0.12-0.77-0.35-1.14-0.61c-0.18,0.18-0.42,0.33-0.73,0.46c-1.28,0.53-2.78,1.5-3.23,2.78c-0.45,1.28,0,2.7-0.3,3.83
        s-0.75,3.83-2.03,5.11s-1.43,2.25-1.5,3.31c-0.08,1.05,0.22,1.8-0.53,2.55s-1.43,1.43-1.43,3s0.68,2.78-0.23,3.83
        c-0.9,1.05-1.43,1.2-1.2,2.4c0.22,1.2,0.68,5.18-0.83,5.48c-1.5,0.3-1.8,0.45-2.25,1.73s-1.43,1.73-1.5,2.85
        c-0.08,1.13-0.9,1.58-0.9,3s0.68,1.05,0.83,2.1c0.12,0.83,0.12,1.66,0.61,2.7c0.31-0.38,0.63-0.56,1.27-0.56
        c1.01,0,1.13,0.38,1.69,1.35c0.56,0.98,1.61,2.78,2.67,2.85s0.79-1.05,2.78-1.13s3.49,0.26,4.62,1.24s2.63,2.29,3.76,2.33
        c1.13,0.04,1.61,0.19,2.59,0.6s1.09,1.73,2.03,1.88s1.8,0.53,2.44-0.19c0.64-0.71,0.64-1.13,1.95-1.16
        c1.31-0.04,3.53-0.22,4.58-0.15s1.28,1.43,2.4,1.43c0.79,0,2.78,0.56,3.83-0.11s1.5-1.31,2.48-1.35c0.98-0.04,0.94-0.69,2.34-0.69
        s3.4-0.6,4.21-1.9c0.8-1.3,1.9-1.7,2.7-2s1.4-0.3,2.01-1.7c0.6-1.4,1.7-3.6,2.5-4.1c0.37-0.23,1.07-1.05,1.93-1.98
        c-0.38-0.22-0.77-0.41-1.08-0.8C891.683,1142.564,891.683,1142.344,891.463,1141.064z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M596.823,798.914c0.3,0.6,1.2,1.5-0.3,2.1s-2.7-0.5-3,0.7
        s-1.7,0.9-1.1,1.7c0.6,0.8,0.9,1.5,0.8,2.6c-0.1,1.1,1.1,1.3,1.9,1.3s0.3-2.7,1.8-2.5s0.3,1.2,1.7,1.2s1,0.7,1.9,0.9s1.4,1.1,2.3,1
        c0.9-0.1,1.5-0.7,2.2-0.6s1.2,1.91,1,2.6c-0.2,0.7-0.9,1.3,0.2,1.9c1.1,0.6,1,0.9,2.1,1.6s2,1.7,2.9,0.2s0.1-3.3,2-3.91
        c1.9-0.6,3.31-1,2.6-2.7c-0.7-1.7,0.5-2.5,1-4.1s2.3-1.6,2.7-4.21c0.4-2.6-0.8-3.1,1-4.1s1.3-2.91,3.21-3.41
        c1.9-0.5,4.01-2.3,6.61-3s4.21-1.9,5.41-2.3c1.2-0.4,4.61-0.7,5.71-2.1c1.1-1.4,2.5-2.1,3.8-3.1s3.51-2.2,4.31-4.1
        c0.8-1.9,3.6-2.81,4.6-5.91s0.23-3.37,1.24-4.38s3.23-0.34,2.44-1.84s-0.64-1.35-1.01-2.7s0.34-2.4-1.39-3.12s-1.13-1.13-0.68-2.55
        c0.45-1.43,0.83-1.8-0.68-2.55s-4.28-0.68-4.81-2.03c-0.53-1.35-0.45-2.4-2.25-2.4s-2.93-1.88-3.3-0.83
        c-0.38,1.05,0.08,2.33-1.2,2.33s-2.93,0.6-4.21,0.3s-3.16-1.5-4.36-1.5s-3.38-0.08-3.76-1.28s-0.15-3.53-2.33-4.05
        c-2.18-0.53-3.38-0.53-4.66-0.98c-1.28-0.45-1.88-1.05-3.68-2.18c-1.59-0.99-2.31-3.39-5.77-4.04c-0.08,0.75-0.24,1.58-0.95,2.01
        c-0.92,0.56-1.49,0.8-2.49,0.76s-1.16,0.97-2.41,0.97s-2.69,0.4-2.93,0.89c-0.24,0.48,0.04,1.89-0.8,1.93s-1.29-2.09-2.29-1.85
        c-1,0.24-0.96,1.4-2.01,1.53s-0.96,0.36-1.41,1.37c-0.44,1.01-1.41,0.92-0.8,1.65s1.33,0.92,1.04,2.37s0.28,3.53-0.76,4.5
        s-1.04,2.21-2.05,2.57s-1.85,0.97-1.85,2.25c0,1.29,0.04,4.34-1.12,4.9s-0.74,0.51-1.86,1.11c-1.13,0.6-0.9,2.03-1.95,1.95
        s-1.28,0.38-0.53,1.2c0.75,0.83,1.05,1.35,1.05,2.85s0,6.08,0,6.98c0,0.9,0.75,1.95-0.38,2.03s-1.88,0.75-1.73,1.43
        s-0.15,1.2,1.28,1.2s1.88,0.3,2.18,1.05s1.43,0.75,1.95,1.88c0.53,1.13,1.35,1.13,2.33,1.2s1.8-0.08,2.03-0.6
        c0.22-0.53,0.3-1.43,1.13-1.2c0.83,0.22,1.65-0.15,1.65-0.98s-0.08-1.65,0.6-1.88s0.9-0.08,0.9,0.45s0.22,1.58,0.6,1.73
        c0.38,0.15,0.75,0.83,0.53,1.88s-0.08,1.88-0.68,2.18s-0.68,0.45-1.2,2.25c-0.53,1.8-0.68,3.38-2.33,3.38s-1.35,0.98-3.23,1.28
        c-0.05,0.01-0.09,0.03-0.13,0.04c0.09,0.86,0.29,1.84,0.73,2.39C596.823,797.615,596.523,798.315,596.823,798.914z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M880.493,718.454c-0.6-0.6-0.45-0.75-1.05-1.88s-0.6-1.73-1.65-2.7
        c-1.05-0.98-2.85-3.98-2.7-4.81s-0.15-1.73-1.05-2.25s0.15-1.88-0.6-2.03s-0.45,0.83-1.65,0.98c-1.2,0.15-2.18-0.98-2.55-2.7
        c-0.38-1.73-2.33-1.5-3.15-3.23c-0.83-1.73-0.9-0.68-2.18-2.78c-1.28-2.1,0.75-3.23,0.75-4.36s0.75-1.2,1.73-2.18
        s-0.45-2.25-0.45-3.3s0.9-1.95,1.95-2.55s2.7-0.08,4.51-0.53c1.8-0.45,0.53-3.98,0.38-4.96c-0.15-0.98,0.6-2.18,1.05-3
        c0.45-0.83,0.45-1.5,0.38-2.63c-0.03-0.47,0.14-1.14,0.34-1.75c-0.01-0.02-0.03-0.03-0.04-0.05c-0.68-1.28-1.5-2.25-2.63-2.85
        s-1.73,0.38-2.48-0.9s-1.65-1.95-2.18-2.48c-0.53-0.53-0.83-0.75-1.88-1.8s-2.85-1.35-3.98-1.43s-0.6-1.5-1.65-1.5
        s-0.22,1.58-1.28,2.03c-1.05,0.45-1.2,0.6-1.2,1.73s0,2.7-0.08,3.23s0.15,1.05-0.68,1.13s-1.5-0.15-0.98-0.9
        c0.53-0.75,0.9-0.83,0.9-2.25s-0.08-3.08,0.45-3.76s0.75-0.68,0.9-1.95c0.15-1.28,1.05-3-0.3-3.83s-1.65-2.85-2.78-3.15
        c-1.13-0.3-1.13-1.05-2.1-1.8c-0.98-0.75-0.83-3.23-2.93-3.38s-3.6-0.23-4.66-0.98c-1.05-0.75-1.73-1.73-2.55-1.73
        c-0.4,0-1.06-0.61-1.77-1.3c-0.25,0.71-0.21,1.62-0.41,2.58c-0.3,1.43-0.9,1.65-1.58,2.1c-0.68,0.45-1.28,1.2-1.88,2.25
        s-1.88,0.98-2.85,1.05c-0.98,0.08-1.13,0.75-1.65,2.25s-2.18,0.98-1.95,2.85s1.5,0.6,1.58,2.48s1.58,0.98,2.78,1.13s1.2,0,1.2,1.73
        s-1.95,1.43-2.55,2.48s-1.43,0.83-3.23,0.83s-2.63,0.3-3.38,0.98s-1.2,2.1-0.22,5.26c0.98,3.16-1.95,2.7-3.15,4.13
        s1.58,2.48,3.83,3.46c2.25,0.98,2.78,4.06,4.13,4.73c0.55,0.27,0.93,0.57,1.22,0.91c0.2-0.06,0.42-0.12,0.7-0.14
        c1.27-0.1,2.34,0.1,2.73-0.88c0.39-0.98,1.27-1.17,1.85-0.88s1.07,1.27,2.34,1.46c1.27,0.2,0.49,1.95,1.08,2.15s0.88,1.37,0.1,1.85
        s-1.36,0.78-1.46,1.95c-0.1,1.17-0.68,2.05,0.2,2.05s0.48,1.56,1.76,1.07c1.27-0.49,1.56-0.97,2.24-0.97s0.88,0.98,0.78,2.05
        s-0.2,1.36,0.88,2.44s2.64,0.88,0.88,3.32s-2.64,2.34-2.64,3.8c0,1.47,0.68,2.44-0.39,3.61c-1.08,1.17-1.17,1.37-1.37,2.93
        s-0.88,2.93-0.1,4.78c0.78,1.85,2.63,2.15,2.73,4.68c0.1,2.54-0.97,4.3,0.98,5.27s2.64,1.17,3.51,2.34
        c0.88,1.17,1.95,2.15,3.12,2.63c1.17,0.49,2.54,0.49,2.73,1.17c0.2,0.68,1.66,0.59,1.95-0.19c0.29-0.78,3.12,0.1,3.12-1.46
        s0.88-2.73,2.05-2.73s2.05,0,2.54,0.59s1.86,0.88,1.46-0.49c-0.39-1.37-0.59-2.54,1.07-2.54c1.66,0,3.61,0.39,4.2-0.49
        s1.56-1.46,1.95-2.54c0.39-1.08,0.88-1.46,2.15-1.46s1.17,0.68,1.95,1.17s2.34,1.27,3.02,0.29c0.4-0.58,1.01-1.04,1.87-1.27
        C881.893,719.224,880.963,718.924,880.493,718.454z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M909.293,711.094c0.95-0.88,1.01-1.62,1.49-2.97
        s0.34-3.04,0.34-4.4s-0.27-1.55,1.08-2.57s0.88-1.89,0.74-3.31s-2.5-1.55-2.84-1.96c-0.34-0.41-1.69-1.96-1.69-2.91
        s-0.07-1.08-0.88-1.56c-0.81-0.47-0.41-0.88-0.41-1.69s0.07-2.64,0.07-3.65s-0.47-1.42-1.08-2.23c-0.61-0.81,0.07-1.29,0.14-2.71
        s0.41-1.62,1.96-2.64s1.01-1.62,2.1-2.5c1.08-0.88,1.49-2.84,1.49-4.33c0-0.44,0-0.83,0-1.18c-0.22-0.11-0.46-0.19-0.74-0.19
        c-1.05,0-1.58-1.2-2.63-1.2s-1.5,1.05-2.93,0.98s-1.95-0.83-3.16-0.83c-1.2,0-1.88-1.13-2.85-1.13c-0.98,0-1.35,1.8-3,2.03
        c-1.65,0.22-2.55,0.75-2.78-0.15s-0.15-1.5-1.28-1.65s-0.15,2.1-1.95,2.1s-0.9,0.38-0.9,1.28s0.38,2.33-0.3,2.55
        s-0.08-1.35-1.13-1.35s-2.25,0.83-3.08,0.08s-0.68-1.35-2.25-1.35c-1.58,0-3.15,0.15-4.66,0.22c-1.48,0.08-2.95,1.08-3.64-0.1
        c-0.2,0.62-0.37,1.28-0.34,1.75c0.08,1.13,0.08,1.8-0.38,2.63c-0.45,0.83-1.2,2.03-1.05,3c0.15,0.98,1.43,4.51-0.38,4.96
        c-1.8,0.45-3.46-0.08-4.51,0.53s-1.95,1.5-1.95,2.55s1.43,2.33,0.45,3.3c-0.98,0.98-1.73,1.05-1.73,2.18s-2.03,2.25-0.75,4.36
        c1.28,2.1,1.35,1.05,2.18,2.78s2.78,1.5,3.15,3.23c0.38,1.73,1.35,2.85,2.55,2.7c1.2-0.15,0.9-1.13,1.65-0.98s-0.3,1.5,0.6,2.03
        s1.2,1.43,1.05,2.25c-0.15,0.83,1.65,3.83,2.7,4.81s1.05,1.58,1.65,2.7c0.6,1.13,0.45,1.28,1.05,1.88
        c0.47,0.47,1.39,0.77,2.03,2.17c0.6-0.16,1.31-0.21,2.23-0.01c2.24,0.49,2.73,1.56,3.41,1.56s1.66,1.07,2.05,0s1.17-1.46,0.78-1.95
        c-0.39-0.49-0.1-1.56-1.08-1.95s-2.34-0.98-2.05-1.66c0.29-0.68,0.98-0.59,0.98-1.66s0-1.95,1.17-1.85s2.05,0.68,2.73,0.88
        s2.15,0.98,3.32,0.39c1.17-0.59,0.88-1.46,2.34-1.85c1.46-0.39,1.56-1.56,2.83-1.17s0.78,1.95,2.15,2.15s1.17-0.59,2.34,0.68
        c0.08,0.09,0.17,0.15,0.26,0.23c0.44-0.45,0.84-0.45,1.28-0.88C907.873,712.924,908.353,711.974,909.293,711.094z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M940.273,688.414c-0.26-1.13-0.86-2.14-1.32-2.37
        c-0.45-0.22-0.11-1.69-1.61-1.69s-1.88,0-2.55-1.2c-0.68-1.2-0.98-2.4-2.25-2.85s-0.23-1.28-2.25-1.43
        c-2.03-0.15-2.63-2.78-4.06-3.68s-2.48-1.65-4.13-1.5s-1.58-0.38-3-0.38s-1.43-0.08-2.78-0.9s-3-0.9-3.3-0.98
        c-0.22-0.06-0.61-0.64-1.22-0.94c0,0.35,0,0.74,0,1.18c0,1.49-0.41,3.45-1.49,4.33s-0.54,1.49-2.1,2.5s-1.89,1.22-1.96,2.64
        s-0.75,1.9-0.14,2.71s1.08,1.22,1.08,2.23s-0.07,2.84-0.07,3.65s-0.41,1.22,0.41,1.69s0.88,0.61,0.88,1.56s1.35,2.5,1.69,2.91
        s2.71,0.54,2.84,1.96c0.13,1.42,0.61,2.29-0.74,3.31s-1.08,1.22-1.08,2.57s0.14,3.04-0.34,4.4s-0.54,2.09-1.49,2.97
        c-0.95,0.88-1.42,1.83-2.03,2.43c-0.44,0.44-0.84,0.43-1.28,0.88c1.1,1.08,2.29,1.53,3.65,1.62c1.46,0.1,4.39,0.19,4.78-0.59
        s0.59-1.95,2.54-1.95c1.95,0,3.12-0.78,4.88-0.49c1.76,0.29-0.49,1.27,1.85,1.27s3.61-0.29,3.9-0.97s2.63-2.54,3.03-4.78
        s1.56-2.93,2.05-5.07c0.49-2.15,0.1-3.12,1.76-4.2c1.66-1.07,1.76-3.22,2.44-4.59s1.37-3.41,2.34-4.2
        c0.52-0.41,0.95-0.84,1.35-1.35C940.443,688.944,940.353,688.734,940.273,688.414z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M806.183,1010.984c0.54-3.35,0.8-5.89,2.14-7.77
        s1.74-3.48,1.74-5.36s-0.93-3.88,0.54-4.69c1.47-0.8,2.68-3.21,3.35-4.69c0.67-1.47,2.41-5.49,5.09-5.49s5.09-1.61,7.77-1.61
        s4.96,0,6.56-0.94s2.54-1.47,4.28-1.47c1.74,0,2.95-0.13,4.15-0.27c1.2-0.13,6.7-2.28,7.9-0.53c1.2,1.74,2.14,2.41,3.61,3.08
        c1.47,0.67,5.09,2.54,5.36,3.75c0.16,0.71,0.64,1.79,1.21,2.64c0.43-0.46,1.11-0.81,1.8-1.11c1.38-0.61-0.2-2.15-1.28-3.22
        c-1.08-1.08,0.05-3.32,1.23-4.34s-0.05-2.2,1.58-2.96c1.64-0.77,1.53-4.65,2.05-6.03c0.51-1.38,0.66-2.76,1.68-4.14
        s0.41-2.2,0-2.96c-0.41-0.77-0.92-0.87-1.18-1.79s-0.1-3.06-0.41-3.93s-0.82-0.72-2.25-0.51c-1.43,0.2-3.73-2.2-4.7-2.91
        c-0.97-0.72-1.07-1.84-1.07-2.86s-0.31-2.04-0.31-2.71s0.15-1.89,1.23-2.4c1.08-0.51,1.33-1.18,1.22-2.56s-2.04-0.51-4.29-0.31
        s-2.15,0.05-4.34,0.05c-2.2,0-3.12-0.41-5.47-0.26c-2.35,0.15-3.67,0.82-5.46,0.92c-1.79,0.1-2.76-2.56-2.91-3.68
        c-0.16-1.12,0.1-2.04,0.41-3.12s-0.51-1.94-0.82-3.48c-0.31-1.53-1.33-1.18-2.15-2.61s1.18-1.89,2.15-2.3
        c0.97-0.41,0.66-2.1,0.66-2.76s0.05-1.12-0.61-1.89s-0.61-1.37-0.61-2.24s-0.41-1.38-0.87-2.04s0-1.48,1.02-2.5
        s-0.66-2.1-1.07-2.76c-0.41-0.66-1.53-0.92-3.12-1.23c-1.58-0.31-1.07-0.82-1.94-1.89c-0.87-1.08-3.42-0.2-5.11-0.36
        c-1.69-0.15-1.74,0.92-3.78,0.92s-1.99-1.07-3.37-1.68s-1.18-1.94-1.79-2.91s-1.28-0.56-2.91-0.1c-1.64,0.46-1.69-0.46-3.12-0.87
        s-1.23-0.72-3.37-1.53c-2.15-0.82-0.67-1.89-1.84-2.81c-1.18-0.92-1.68,0.41-2.71,0.41s-0.56-0.92-2.25-1.23s-0.81-1.07-2.4-1.43
        s-1.89,0.2-2.76,0.72c-0.87,0.51-1.48,0.66-3.02,0.66s-2.86-1.33-3.27-2.3c-0.41-0.97-1.94-1.53-2.86-2.56
        c-0.92-1.02-2.15-1.18-3.17-1.18s-1.28-1.33-1.33-2.15s-0.56-1.48-1.69-2.25c-1.12-0.77-0.82-1.69-1.69-2.25s-0.71-1.64-0.71-2.91
        c0-1.28,0.92-1.13,1.43-1.64s-0.1-2.45-0.97-2.81s-0.46-0.97-0.67-1.94c-0.21-0.97-0.77-1.74-0.66-2.66
        c0.1-0.92,1.58-1.18,2.4-1.53s0.26-1.23-0.1-2.15s-0.3-1.79-0.1-2.96c0.2-1.18-0.46-1.94-1.07-2.3s-0.97,0.26-1.43,0.82
        s-1.53,0.66-2.51,0.05c-0.97-0.61-1.84-0.31-3.58-0.31s-1.23,0.82-3.43,1.12c-2.2,0.31-1.33,0.92-4.14,1.23s-3.73,2.5-4.49,3.27
        c-0.77,0.77-1.07,1.48-2.91,2.05s-3.88,1.43-4.65,2.91s-1.69,0.92-4.09,1.23s-2.66,2.61-3.73,3.78c-1.08,1.18-3.12,1.74-4.5,1.53
        c-1.38-0.2-2.5-0.56-4.45-1.53c-1.94-0.97-3.27-0.46-4.96-0.56c-0.16-0.01-0.31,0-0.47,0c0,1.28,0.32,2.51,0.43,3.48
        c0.14,1.22,0.86,2.27,1.67,3.13c0.82,0.86,1.49,1.72,2.26,3.08s1.81,1.95,2.13,3.8c0.32,1.86,0.77,1.81,2.08,3.49
        s-0.09,2.63-0.68,3.44s-1.72,1.81-2.53,3.26c-0.81,1.45,0.32,4.48,0.41,5.97c0.09,1.49-1.49,2.81-1.4,4.48
        c0.09,1.67,1.68,2.13,1.68,4.08s-2.35,3.71-3.12,4.76s-3.22,2.63-3.31,3.89c-0.09,1.27,2.67,1.86,2.95,3.8
        c0.27,1.95-1.72,1.63-2.31,3.53s1.99,3.44,3.9,4.48s1.13,2.17,1.45,3.62s-0.86,2.09-1.67,3.53c-0.82,1.45-1.81,2.72-2.45,3.94
        c-0.64,1.22-2.08,2.49-3.85,2.85s-1.4,2.04-0.77,2.67s0.91,0.72,0.77,2.63c-0.01,0.11-0.03,0.19-0.05,0.29
        c0.54,0.28,1.13,0.69,1.43,1.28c0.63,1.25,1.73,2.51,2.35,3.14s1.88,0.7,1.18,2.35c-0.71,1.65,0.08,2.43,0.47,4.32
        c0.39,1.88-0.47,4.08,0.78,4.71c1.26,0.63,1.02,1.8,1.96,2.59c0.94,0.78,2.27,1.1,2.67,1.57s1.26,1.8,0.31,2.51
        s-1.96,1.26-1.57,2.43c0.39,1.18,1.57,1.25,0.71,2.43s-1.49,1.65-1.49,3.06s-0.23,4,0.63,4.47s1.65,0.7,1.25,1.72
        s-1.18,2.36,0.16,2.83c1.34,0.47,1.72-0.08,2.04,1.88c0.31,1.96,1.49,2.59,1.34,3.53s0.16,2.04,0.47,3.29
        c0.32,1.26-0.31,1.65,1.02,2.75s1.1,2.35,1.1,3.45c0,1.1,1.49,1.73,1.1,3.37c-0.39,1.65-0.16,3.14,0.86,3.45
        c1.02,0.31,2.9,0.39,3.45-0.08s1.57-0.78,2.12-0.78c0.39,0,0.93-0.22,1.55-0.31c0.11-0.36,0.51-0.69,1.45-0.9
        c2.17-0.48,0.8-1.94,2.1-2.34s2.24-2.19,2.58-3.26s1.52-0.96,3.54-1.07c2.02-0.11,0.78-1.46,1.01-2.86c0.22-1.4,0.62-0.9,2.08-0.51
        s2.92,1.12,3.7,2.47s3.19,0.28,5.1,0.28s3.48-0.45,5.33-0.39c1.85,0.06,3.09,0.9,3.54,2.86c0.45,1.97,0.95,2.02,1.63,3.71
        c0.67,1.69,0.06,3.37,1.29,3.31s0.51-2.53,0.51-3.59c0-1.07,0.67-1.74,2.02-2.64c1.35-0.9,0.96-2.02,1.85-3.59
        c0.9-1.57,2.41-1.35,4.04-1.35s4.77-0.84,7.18-0.62s3.2,1.57,3.65,2.92c0.09,0.28,0.19,0.49,0.3,0.69
        C804.943,1013.774,805.883,1012.884,806.183,1010.984z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M696.193,611.594c-1.22,0.38-2.54,1.6-2.54,2.63
        s0.58,1.3-0.36,2.32c-0.94,1.01-1.31,1.54-2.18,1.58c-0.86,0.04-1.69,0.08-2.14,1.5c-0.45,1.43-1.5,1.58-2.03,3.3
        c-0.53,1.73-2.55,2.55-2.78,4.36c-0.23,1.8,0.15,2.85,0.07,3.98c-0.07,1.13,0,3.53-0.83,4.88s-0.9,2.25-2.18,3.16
        c-1.28,0.9-2.25,1.28-2.18,2.63c0.08,1.35,1.58,1.5,2.25,0.6c0.68-0.9,1.65-2.03,2.55-1.05c0.9,0.98,1.28,2.1,1.88,2.1
        s1.35,0.3,1.13,1.5c-0.23,1.2,0,3.38,1.13,4.13s1.65,1.65,2.4,2.1c0.75,0.45,1.13,0.68,1.2,1.65c0.08,0.98,1.2,1.35,0.53,2.55
        c-0.68,1.2-2.33,0.83-2.03,1.95s0.98,1.73,0.38,2.55c-0.6,0.83-0.6,1.65,0.15,2.4s1.5,2.33,2.4,2.7s1.28,0.38,1.5,1.43
        s1.05,2.25,2.48,2.18c1.43-0.08,2.85-1.43,3.83-1.13c0.98,0.3,2.25,1.13,3.08,1.05s0.45-0.75,1.88,0s2.93,0.38,3.38-0.45
        s1.13-1.5,2.33-1.5s1.8,0.22,2.25,0.75s1.13,1.05,2.7,1.05c1.58,0,2.63-0.53,3.08,0.9c0.45,1.43,0.9,1.95,2.03,2.85
        s2.33,1.95,3.08,2.93s0.6,3.3,3.08,3.83c2.48,0.53,0.83-1.65,3.3-1.65s2.55-0.3,3.91,0c1.35,0.3,4.66,0.38,5.56-0.53
        c0.9-0.9,1.5-0.53,3-0.53s2.03,0.23,2.63-0.3s0.9-0.9,2.33-0.6c1.43,0.3,2.85,1.13,2.78,3.15c-0.08,2.03-2.18,1.73-2.1,3.3
        c0.08,1.58,0.08,2.55-0.98,3.91c-1.05,1.35-1.65,1.35-1.5,2.63s0.38,2.25,0.38,3.38s0.08,2.25-0.15,3.38
        c-0.22,1.13-0.83,1.95-0.08,3.08s0.6,3.53,1.65,5.03c1.05,1.5,1.58,2.63,2.48,3.6c0.9,0.98,2.78,1.8,2.85,3s-0.3,1.58-1.58,2.18
        s-2.18,2.03-3.38,2.63s-2.25,2.1-0.9,2.78c1.35,0.68,1.28,1.5,2.55,1.8c1.28,0.3,2.7,1.35,3.16,2.1c0.45,0.75,2.78,3.16,2.93,5.03
        c0.15,1.88,0.53,2.78,1.13,4.06c0.6,1.28,1.2,2.25,1.35,3.76c0.15,1.5,1.58,3.83,2.55,4.66c0.98,0.83,2.1,3.08,4.28,3.3
        c2.18,0.23,3.3-0.38,4.73-1.35c1.43-0.98,0.68-2.1,2.25-2.18c1.58-0.08,2.78-0.15,2.78,0.83s-1.5,2.93-0.45,3.38s1.73-2.4,3.16-3.6
        c1.43-1.2,1.28-2.78,3.76-3.23c2.48-0.45,3.6,0.22,4.28-1.35c0.68-1.58,0.83-2.03,2.18-2.03s3.23,0.08,3.46-1.58
        c0.22-1.65,0.08-4.36,2.48-4.73c2.4-0.38,3.83-1.5,4.96-1.95s1.65-1.13,1.73-1.95c0.08-0.83-0.53-2.4-2.48-2.18
        c-1.95,0.22-4.73,0.98-4.96-0.83s0.15-1.95-0.3-2.7s-0.75-0.38-0.98-2.1c-0.22-1.73-2.18-1.13-1.95-3.08s0.53-3.53-0.22-4.81
        c-0.75-1.28-2.48-2.78-3.38-2.78s-1.2-2.1-2.25-2.7c-1.05-0.6-0.45-1.88-1.2-2.48s0-1.65,1.65-0.45s1.65,2.4,2.93,1.95
        c1.28-0.45,2.03-1.43,2.93-0.08s3,1.8,3.76,2.18c0.75,0.38,1.2,0,2.03-0.23c0.83-0.22,1.35-0.67,1.88-0.22s1.05,0.15,1.8-0.08
        c0.75-0.22,1.8,0,2.4,1.35s2.03,1.5,2.18,2.4c0.15,0.9,1.2,1.05,1.95,1.05s1.43-0.23,1.43-1.58c0-1.35-1.58-1.65-1.28-2.7
        s1.28-0.98,1.65-1.73c0.38-0.75,0.08-0.9,1.35-1.13c1.28-0.22,1.28-0.6,1.8-1.28s1.05-1.13,1.8-0.15s2.48,1.5,3.46,0.45
        c0.98-1.05,1.43-1.35,2.55-1.43c1.13-0.08,2.7,0.15,3.16-0.83c0.45-0.98,0.38-1.95,1.95-2.18c1.58-0.22,3.15,0,3.91-1.2
        c0.75-1.2,2.25-1.43,3.23-2.93s1.2-0.9,1.2-2.7s-0.53-2.48-1.88-3.16c-1.35-0.67-1.88-3.76-4.13-4.73s-5.03-2.03-3.83-3.46
        s4.13-0.98,3.15-4.13s-0.53-4.58,0.22-5.26s1.58-0.98,3.38-0.98s2.63,0.22,3.23-0.83s2.55-0.75,2.55-2.48s0-1.58-1.2-1.73
        c-1.2-0.15-2.7,0.75-2.78-1.13s-1.35-0.6-1.58-2.48c-0.22-1.88,1.43-1.35,1.95-2.85s0.68-2.18,1.65-2.25
        c0.98-0.08,2.25,0,2.85-1.05s1.2-1.8,1.88-2.25s1.28-0.68,1.58-2.1c0.2-0.96,0.16-1.87,0.41-2.58c-0.75-0.73-1.56-1.55-2.21-1.85
        c-1.28-0.6-1.88-0.53-2.48,0.08c-0.6,0.6,0.15,0.68-1.65,0.68s-1.58,0.75-3.08,0.45s-2.55-0.67-3.16-0.22
        c-0.6,0.45-0.38,1.58-1.28,1.58s-1.58-0.45-1.73,0.22c-0.15,0.68,0.08,0.98-1.13,0.98c-1.2,0-0.75-0.75-2.55-0.83
        s-2.7-0.08-2.85-0.45s-0.3-1.95,0.45-1.73c0.75,0.22,0.53,0.45,1.13,0.6c0.6,0.15,1.73,0.6,2.4,0.45s1.58,0.08,1.88-0.6
        c0.3-0.67-0.22-1.88,0.6-2.1c0.83-0.22,1.13-1.05,1.13-1.43s-0.15-1.73,0.6-2.85c0.75-1.13,0.15-1.35,1.2-1.65s2.18-1.58,1.2-1.8
        s-1.13-2.03-2.78-2.03s-2.18,0.75-3.46-0.15s-1.13-2.55-2.85-2.55c-1.73,0-2.18,0.22-2.33,0.98c-0.15,0.75,0.68,2.33-0.22,2.33
        s-0.22-0.75-0.75-0.9s-1.05-0.75-1.05-1.5s-0.3-1.35-0.75-1.5s-0.83,0-1.28-0.53s-0.3-1.2-1.13-1.2s-1.65,0-1.43,0.6
        c0.23,0.6,1.65,0.53,1.73,1.35s0.83,1.2-0.15,1.5c-0.98,0.3-0.15,1.5-0.83,1.5s-0.98-1.28-1.73-1.28s-0.9-0.75-0.9-1.43
        s0.98-2.18-0.22-2.33c-1.2-0.15-2.4-0.6-2.48,0.15s0.08,1.13-0.98,1.13c-1.05,0-1.73-0.08-2.78-0.08s-1.65-0.68-0.9-1.05
        c0.75-0.38,3.3-0.53,3.3-0.98c0-0.45,0.9-0.45,1.65-0.45s0.38-1.2-0.08-1.35c-0.45-0.15-0.75-0.75-1.58-0.6s-1.95,0.75-2.03,0.08
        s-1.05-2.03,0.83-2.18c1.88-0.15,3.68,1.58,4.28,0.53s1.28-1.5,2.1-1.5s0.75-0.98,1.73-0.9s1.65,0.22,1.73-0.53
        c0.08-0.75-0.15-0.98-1.2-0.83c-1.05,0.15-1.2,0.53-2.33,0.68s-1.43,0.23-1.95-0.3s-1.2-0.75-2.25-0.75s-1.8-0.15-2.25,0.68
        s-1.43,0.98-2.63,0.98s-1.88-0.75-2.25,0c-0.38,0.75-1.43,0.9-1.88,0.9s-2.33,0-3.23,0c-0.9,0-2.48-0.22-2.55,0.53
        c-0.08,0.75,0.3,1.13,0.83,1.13s1.05,0.45,0.9,0.83c-0.15,0.38-1.13,0.45-1.5,0.15c-0.38-0.3-1.28-1.35-2.18-1.5
        c-0.9-0.15-1.65-0.08-1.65,0.75s-0.15,2.1-1.2,2.4s-1.5,1.2-2.4,1.28s-1.73,0.23-2.25,0.98s0,1.58-1.65,1.8s-3.6,0.67-4.58,0
        c-0.98-0.68-1.65-1.43-3-1.43s-2.55,0.6-3.53-0.22s-1.8-0.53-2.4-1.65s-1.73-2.7-3.38-2.78s-5.48-0.08-6.61-0.08
        s-0.08,1.5-3.08,1.58s-4.73,0.08-5.33,0.6c-0.6,0.53-0.45,0.53-2.4,0.53s-3.76,0.45-4.43,0s-0.38-1.35-1.13-1.65
        c-0.75-0.3-1.65-1.13-1.65-1.58s0.83-0.83,1.05-0.9c0.22-0.08,1.28-1.05,0.45-1.35s-1.2-0.22-1.2-0.98c0-0.75-0.6-1.05-1.13-1.5
        s-0.9-1.65-2.1-1.65s-1.13-1.88-3.3-2.18c-2.18-0.3-1.43-0.3-2.52-0.11s-1.91,0.23-2.82,0.19c-0.9-0.04-2.37-0.3-2.93-0.11
        s-2.33,1.13-2.48,0c-0.15-1.13-0.34-1.58-0.19-2.29s-0.04-1.24-0.49-1.77s-0.64-0.49-0.64-1.43s0.41-1.16-0.38-1.54
        s-1.01-1.54-1.91-1.61s-0.56,1.95-1.46,1.95s-1.28,0.98-1.28,1.58c0,0.6-0.75,0.26-0.71,1.39s0.64,1.01,0.75,1.54
        s1.09,1.05,1.88,1.24s1.95-0.38,2.25,0.3s0.04,1.54-1.01,1.35s-1.88,0-2.07,0.64s-0.83,1.43-1.24,1.46c-0.41,0.04-1.58,0-1.88,0.56
        s-0.6,0.68-2.03,0.68s-2.44-0.19-2.93,0.34s-1.39,1.69-2.55,1.77s-0.64,0.79-1.95,1.24s-2.4,0.75-2.59,1.5s-0.64,2.59-0.15,3.68
        s0.38,0.6,1.54,2.74s1.99,2.44,2.33,3.12s1.58,1.61,1.73,2.52c0.15,0.9,0.15,1.73,0.15,2.89s0.22,2.85-0.45,3.49
        c-0.68,0.64-1.2,0.98-1.5,1.58c-0.3,0.6-0.22,1.28-1.28,1.5c-1.05,0.22-3.04,0.3-3.76,0.3s0.26-0.9-1.13-1.31s-0.15-2.82-0.83-3.34
        c-0.68-0.53-1.05-0.83-1.58-1.28s-0.56-1.2-1.09-1.46s-0.26-1.54-0.9-1.73s-0.98-0.86-0.19-1.24s1.09-0.53,1.35-1.31
        c0.26-0.79,0.3-1.2,1.16-1.77c0.86-0.56,1.13-1.24,1.61-1.84c0.49-0.6,1.31-2.03,1.54-2.59c0.22-0.56,0.38-0.98,0.3-1.73
        s-0.22-1.09-0.6-1.54c-0.38-0.45-0.79-0.49-0.83-1.65c-0.04-1.16,0.26-1.54-0.41-2.14c-0.68-0.6-0.86-1.61-1.73-1.95
        c-0.86-0.34-0.94-0.56-0.86-1.5s0.49-2.03,1.84-2.25c1.35-0.22,1.54-0.86,2.59-0.86s1.2-0.41,1.77-0.94
        c0.27-0.25,0.52-0.41,0.78-0.6c-0.26-0.12-0.61-0.2-1.09-0.2C699.673,610.365,697.413,611.214,696.193,611.594z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M788.833,615.484c0,0-1.77-0.86-1.8-0.19
        c-0.04,0.68-0.49,0.68-1.01,0.71c-0.53,0.04-0.75,1.5,0.34,1.5s0.9-0.49,1.35,0.23c0.45,0.71,1.39,0.67,2.29,0.64
        c0.9-0.04,1.16-0.11,1.43-0.75s0.98-0.41,1.09-1.31c0.11-0.9-0.45-1.2-0.86-1.2s-0.38-1.54-1.01-1.54s0.04,1.77-0.68,1.99
        C789.243,615.784,789.693,615.894,788.833,615.484z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M1137.914,815.974c0-1.39-0.41-2.07,0.6-3.15
        c1.01-1.09,1.05-1.69,0.98-2.48c-0.08-0.79,0.56-2.37-0.38-2.89s-1.28,0.3-1.2-1.61s0.79-4.58,0-5.52s-0.86-0.38-0.98-1.95
        c-0.11-1.58-0.19-2.25-0.75-2.78s-0.45-3.04-0.45-4.17s-0.71-0.49-0.71-1.84c0-1.35,0.15-1.84-0.45-2.59s-0.86-0.86-1.43-1.65
        s-2.74-2.85-4.24-3s-3.31,0-3.57,0.53c-0.26,0.53,0.19,1.2-1.73,1.2s-6.08,0.64-7.44-0.15c-1.35-0.79-0.75-1.24-2.52-1.58
        s-1.77-0.15-3.49-1.5c-1.73-1.35-3.23-0.68-4.24-2.59s-2.74-0.98-3.91-2.93c-1.16-1.95-3.12-4.73-5.37-5.82s-2.89-1.35-5.22-2.7
        s-1.99-2.18-4.36-2.52s-3.31-1.8-4.54-2.25c-1.24-0.45-1.28-1.84-4.43-1.8c-3.16,0.04-4.17,1.31-5.97,1.43s-3.19-0.26-4.39,0.49
        s-2.67,0.38-2.93,1.13s-0.41,1.16-1.46,1.16c-1.05,0-2.63-0.26-3.38-1.09s-0.68-1.8-1.92-1.91s-0.53,1.99-2.52,1.99
        s-2.74,0.19-3.68-0.26c-0.94-0.45-1.88-1.28-2.7-1.31c-0.83-0.04-0.68-1.13-2.44-1.35s-0.71-1.31-2.78-1.31s-4.13,0.98-4.96,1.61
        c-0.83,0.64-1.73,1.35-2.52,1.35s-1.24,0.04-2.03,0.83c-0.79,0.79-1.77,0.79-1.8,1.61s0.94,1.84-0.49,2.03s-1.61,0.04-1.46-1.01
        s-0.07-1.16,0.45-1.58s0.64-0.34,0.71-1.24c0.07-0.9-0.04-1.35-0.79-1.24s-0.71,1.73-1.35,1.99s-0.9,1.65-1.2,1.95
        c-0.3,0.3-0.22,0.9-0.34,1.58c-0.11,0.68-0.07,1.91-0.6,2.18c-0.53,0.26-0.49,0.3-1.16,1.13c-0.68,0.83-0.08,1.99-0.86,2.52
        c-0.79,0.53-1.91,0.9-1.73,0s0.9-0.94,1.01-2.07s1.39-1.09,1.46-2.4s-1.05-1.2-0.75-2.4s0.41-1.31,0.6-2.22s-0.04-0.9,1.09-1.77
        c1.13-0.86,1.28-1.01,1.28-1.77s-0.22-1.2-1.09-0.71s-1.13,1.09-1.88,1.43c-0.75,0.34-1.73,1.01-1.43-0.22
        c0.3-1.24-0.11-0.94,1.05-1.65s1.13-0.64,1.35-1.24c0.23-0.6,1.13-0.6,1.05-1.73s-0.26-0.75-1.77-1.43
        c-1.5-0.68-2.07-1.54-2.18-2.18s-0.45-0.98-1.46-1.28c-1.01-0.3-0.6-1.2-1.43-1.24s-0.53,1.24-1.24,1.5
        c-0.71,0.26-0.79,1.09-0.9,1.84c-0.11,0.75-0.08,1.5-0.71,1.35c-0.64-0.15,0.19-2.1-0.53-2.33c-0.71-0.23-1.39-0.34-1.54-1.35
        c-0.15-1.01-0.83-1.54-1.88-1.54s-2.29,0.71-2.55-0.41c-0.26-1.13-1.05-1.13-2.07-1.13s-2.37,0.22-2.78-0.45
        c-0.41-0.68-1.16-0.56-2.44-0.64s-2.1-0.11-2.22-0.71c-0.11-0.6-0.34-1.5-0.83-1.5s-0.15-1.47-1.8-1.47s-3.19,0.08-3.53,0.19
        s-0.9,0.34-0.94,0s-0.45-1.5-1.05-1.39s-0.38,0.41-0.34,1.01c0.04,0.6,0.19,1.73-0.19,2.07s-1.09,0.11-1.09-0.34
        s-0.04-1.39-0.83-1.5c-0.79-0.11-1.24,0.08-1.39,0.9s-1.05,0.71-2.25,0.64c-1.2-0.08-1.84-0.75-2.7,0.38s-0.9,1.8-1.73,2.18
        s-1.05,0.56-1.05,1.43s0.34,3.04-0.75,3.38s-1.77,0.68-1.5,1.46c0.26,0.79,0.38,0.71,1.28,0.56s1.73-0.98,2.33-0.9
        c0.6,0.08,0.68,1.01-0.11,1.16c-0.79,0.15-1.05,0.34-1.2,0.79c-0.15,0.45-0.34,1.39-1.28,1.39s-1.39-0.22-1.46-0.56
        c-0.08-0.34-0.26-1.43-1.01-1.24s-1.43,0.45-1.5,1.01c-0.08,0.56-0.04,0.94-0.56,1.28c-0.53,0.34-1.01,0.86-1.09,1.31
        c-0.07,0.45,0.19,0.98-1.05,1.39c-1.24,0.41-1.16,1.39-1.88,1.69c-0.71,0.3-1.13,0.75-1.13,1.31s0.15,1.43-0.38,1.8
        c-0.53,0.38-0.64,0.45-0.68,1.31s0.3,1.24-0.26,1.69s-0.64,0.41-0.75,0.94s-0.41,0.68-0.75,0.68s-0.83-0.38-0.26-1.54
        s0.98-1.28,0.71-1.88c-0.26-0.6-0.41-1.58-0.04-2.14s0.6-0.94,0.98-1.61c0.38-0.68,1.05-1.35,0.41-1.84
        c-0.64-0.49-1.5-0.6-2.14-0.15c-0.64,0.45-0.86,1.13-1.54,1.16c-0.68,0.04-1.28,0.08-1.28,0.86c0,0.79-0.08,1.28-0.6,1.16
        c-0.53-0.11,0-1.39-0.86-1.54c-0.86-0.15-1.01-0.71-1.95-0.6s-1.43-0.04-1.8,0.56c-0.38,0.6-1.2,0.08-1.01-0.71
        c0.19-0.79,0.71-1.05,1.91-0.98c1.2,0.08,3,0.75,3.34-0.3s0.22-1.88,1.43-1.5c1.2,0.38,1.99,1.28,2.22,0.45
        c0.22-0.83,0.3-1.31,1.73-1.31s2.59,1.01,2.93-0.11c0.34-1.13,0.08-1.35,1.31-1.35s2.14-1.09,2.48-2.14c0.34-1.05,0-1.91,0.98-2.78
        c0.98-0.86,0.86-0.75,1.09-1.73c0.23-0.98,0.15-1.13,0.9-1.58s1.24-1.69,1.09-3.08s0.04-1.84,0.45-2.29
        c0.41-0.45,0.45-0.79,0.41-1.28c-0.04-0.49-0.68-0.98-1.65-0.9c-0.98,0.08-2.18,0.34-3.3,0c-1.13-0.34-1.31-1.28-2.74-1.31
        c-1.43-0.04-1.88,0.04-2.44,0.45c-0.56,0.41-0.98,1.01-1.88,1.31s-0.79,0.68-2.25,0.71c-1.46,0.04-1.58,0.6-2.59,0.04
        s-1.54-1.13-2.59-1.09s-1.5,0.45-2.44-0.22c-0.94-0.68-1.24-1.2-1.73-1.01s-1.09,1.01-1.32,1.61c-0.22,0.6-0.68,1.05-1.24,1.39
        s-0.83,1.01-1.24,1.58c-0.41,0.56-0.45,1.84-0.15,2.44s1.69-0.15,1.91,0.56s0.38,1.24-0.6,1.16s-1.65-0.19-1.73,0.6
        c-0.08,0.79-0.15,1.8,0.3,2.4c0.45,0.6,0.04,1.2,0.9,1.24c0.86,0.04,0.41-0.86,1.5-0.71c1.09,0.15,0.26,0.6,0.19,1.16
        s-0.19,0.75-1.09,0.64s-1.2-0.34-1.31,0.45c-0.11,0.79,0.34,1.35,0.08,2.37c-0.26,1.01-0.08,1.73,0.23,1.99
        c0.3,0.26,0.9,0,0.9,0.75s0.34,1.35,0.75,1.46c0.41,0.11,0.56,0.45,0.45,0.9s-1.84,0.75-2.1,0.15c-0.26-0.6,0.19-1.8-0.56-2.03
        c-0.75-0.23-0.34-1.58-0.9-2.18s-0.53-1.99-0.15-2.74s0.49-1.05,0.04-1.73s-1.31-3.12-2.97-0.71c-1.65,2.4-3.46,3.6-4.73,3.98
        c-1.28,0.38-1.16,1.28-2.67,1.99s-0.9,1.73-2.29,1.88c-1.39,0.15-0.75,1.31-1.61,1.43c-0.86,0.11-0.83-0.49-1.54-0.41
        c-0.71,0.08-0.94,0.41-1.54,0.45c-0.6,0.04-1.24-0.04-1.84-0.04s-0.86-0.38-1.91-0.08s-1.69,0.26-1.54-0.38s1.01-0.94,1.84-1.13
        s2.82,0.26,3.19-0.83c0.38-1.09,0.9-1.35,1.8-1.43s2.37,0.38,2.59-0.75c0.23-1.13,0.15-1.69,0.94-2.1s1.73-0.45,1.84-1.88
        c0.11-1.43,0.49-3.68,2.14-5.63s1.05-2.97,2.4-3.94s0.79-2.07,2.59-3.23s2.07-2.1,2.97-2.44c0.9-0.34,1.28-0.04,2.22-1.77
        c0.94-1.73,1.31-2.85,1.88-3.79c0.56-0.94,0.9-1.99,1.8-2.78s1.91-2.25,2.55-2.78s1.39-0.86,1.5-1.95s-0.68-1.01-1.65-1.09
        c-0.98-0.08,0.11-0.94,0.71-1.09c0.6-0.15,1.05-0.3,1.16-1.39s1.31-1.95,0.26-2.55s-1.39-2.44-2.82-2.37
        c-1.43,0.08-1.95,0.79-3.12,0.56c-1.16-0.22-0.9-1.77-1.99-2.18c-1.09-0.41-1.95,0-2.18-0.71c-0.22-0.71,1.05-3.08,0.15-4.06
        c-0.9-0.98-1.88-1.39-2.03-2.59s-0.34-1.99-0.83-2.63s0.08-1.35-0.83-1.91s-1.32-0.72-1.43-1.99c-0.04-0.45-0.04-1.5-0.04-2.25
        s0.64-2.4-0.04-3.64s0.34-1.31-0.22-2.4s0.45-1.28-1.01-2.1s-1.2-2.48-2.33-2.48s-0.83,1.58-1.91,1.99
        c-0.78,0.29-1.19,0.32-1.46-0.09c-0.4,0.52-0.83,0.94-1.35,1.35c-0.98,0.78-1.66,2.83-2.34,4.2c-0.68,1.37-0.78,3.51-2.44,4.59
        c-1.66,1.07-1.27,2.05-1.76,4.2s-1.66,2.83-2.05,5.07s-2.73,4.1-3.03,4.78c-0.29,0.68-1.56,0.97-3.9,0.97s-0.1-0.97-1.85-1.27
        c-1.76-0.29-2.93,0.49-4.88,0.49s-2.15,1.17-2.54,1.95c-0.39,0.78-3.32,0.68-4.78,0.59s-2.73-0.59-3.9-1.85s-0.98-0.49-2.34-0.68
        c-1.37-0.2-0.88-1.76-2.15-2.15s-1.37,0.78-2.83,1.17c-1.46,0.39-1.17,1.27-2.34,1.85c-1.17,0.59-2.64-0.2-3.32-0.39
        c-0.68-0.2-1.56-0.78-2.73-0.88s-1.17,0.78-1.17,1.85s-0.68,0.98-0.98,1.66s1.07,1.27,2.05,1.66s0.68,1.46,1.08,1.95
        c0.39,0.49-0.39,0.88-0.78,1.95s-1.37,0-2.05,0s-1.17-1.07-3.41-1.56s-3.42,0.29-4.1,1.27s-2.24,0.2-3.02-0.29
        s-0.68-1.17-1.95-1.17s-1.76,0.39-2.15,1.46c-0.39,1.08-1.37,1.66-1.95,2.54s-2.54,0.49-4.2,0.49c-1.66,0-1.46,1.17-1.07,2.54
        s-0.98,1.07-1.46,0.49c-0.49-0.58-1.37-0.59-2.54-0.59s-2.05,1.17-2.05,2.73s-2.83,0.68-3.12,1.46s-1.76,0.88-1.95,0.19
        c-0.2-0.68-1.56-0.68-2.73-1.17s-2.24-1.46-3.12-2.63s-1.56-1.37-3.51-2.34s-0.88-2.73-0.98-5.27c-0.1-2.54-1.95-2.83-2.73-4.68
        c-0.78-1.85-0.1-3.22,0.1-4.78c0.2-1.56,0.29-1.76,1.37-2.93s0.39-2.15,0.39-3.61c0-1.46,0.88-1.37,2.64-3.8
        c1.76-2.44,0.19-2.24-0.88-3.32c-1.08-1.08-0.97-1.37-0.88-2.44c0.1-1.07-0.1-2.05-0.78-2.05s-0.97,0.48-2.24,0.97
        s-0.88-1.07-1.76-1.07s-0.29-0.88-0.2-2.05c0.1-1.17,0.68-1.47,1.46-1.95s0.49-1.66-0.1-1.85c-0.59-0.2,0.2-1.95-1.08-2.15
        c-1.27-0.2-1.76-1.17-2.34-1.46s-1.46-0.1-1.85,0.88c-0.39,0.98-1.46,0.78-2.73,0.88c-0.28,0.02-0.5,0.08-0.7,0.14
        c0.44,0.51,0.66,1.17,0.66,2.24c0,1.8-0.22,1.2-1.2,2.7c-0.98,1.5-2.48,1.73-3.23,2.93s-2.33,0.98-3.91,1.2
        c-1.58,0.22-1.5,1.2-1.95,2.18s-2.03,0.75-3.16,0.83c-1.13,0.08-1.58,0.38-2.55,1.43c-0.98,1.05-2.7,0.53-3.46-0.45
        c-0.75-0.98-1.28-0.53-1.8,0.15s-0.53,1.05-1.8,1.28c-1.28,0.23-0.98,0.38-1.35,1.13c-0.38,0.75-1.35,0.67-1.65,1.73
        c-0.3,1.05,1.28,1.35,1.28,2.7s-0.68,1.58-1.43,1.58s-1.8-0.15-1.95-1.05c-0.15-0.9-1.58-1.05-2.18-2.4
        c-0.6-1.35-1.65-1.58-2.4-1.35c-0.75,0.22-1.28,0.53-1.8,0.08c-0.53-0.45-1.05,0-1.88,0.22c-0.83,0.23-1.28,0.6-2.03,0.23
        c-0.75-0.38-2.85-0.83-3.76-2.18s-1.65-0.38-2.93,0.08c-1.28,0.45-1.28-0.75-2.93-1.95c-1.65-1.2-2.4-0.15-1.65,0.45
        c0.75,0.6,0.15,1.88,1.2,2.48s1.35,2.7,2.25,2.7s2.63,1.5,3.38,2.78s0.45,2.85,0.22,4.81c-0.22,1.95,1.73,1.35,1.95,3.08
        s0.53,1.35,0.98,2.1c0.45,0.75,0.08,0.9,0.3,2.7s3,1.05,4.96,0.83c1.95-0.22,2.55,1.35,2.48,2.18c-0.08,0.83-0.6,1.5-1.73,1.95
        s-2.55,1.58-4.96,1.95c-2.4,0.38-2.25,3.08-2.48,4.73c-0.23,1.65-2.1,1.58-3.46,1.58s-1.5,0.45-2.18,2.03s-1.8,0.9-4.28,1.35
        c-2.48,0.45-2.33,2.03-3.76,3.23c-1.43,1.2-2.1,4.06-3.16,3.6c-1.05-0.45,0.45-2.4,0.45-3.38s-1.2-0.9-2.78-0.83
        c-1.58,0.08-0.83,1.2-2.25,2.18c-1.43,0.98-2.55,1.58-4.73,1.35c-2.18-0.22-3.3-2.48-4.28-3.3c-0.31-0.26-0.66-0.68-1.01-1.17
        c-0.99,0.34-1.98,0.2-2.55-0.18c-0.9-0.6-1.9-2.6-1.8-4.11s-0.3-1.5-0.7-3.2s-2.3-3.41-3.51-3.91c-1.2-0.5-2.7,0.5-2.8,1.7
        s-0.3,2.1-1.5,2.6s-2.1,0.3-3-1.1c-0.9-1.4-2.01-2.6-2.7-1.7s0.3,1.1,1.4,3.1c1.1,2.01-3,0.1-5.71,0c-2.7-0.1-2.1,0.9-4.51,1
        c-2.4,0.1-4.31-0.5-5.51-1s-3.2,0.4-4.41,1.3c-1.2,0.9,0.1,3.51-0.5,5.91c-0.6,2.4,2.1,1.7,3,1.7s1.2-0.3,2.6-0.5
        c1.4-0.2,1.3,0.3,1.3,1.3s1.4,1.2,2,1.5s0.7,1.1,0,1.5s-0.3,1.3-1.5,1.4c-1.2,0.1-1.6-0.8-2.2-1.2c-0.6-0.4-1.5-0.2-2.6,0.4
        s-2.5,0.6-3.8,1s-0.9,3.8-0.9,5.51c0,1.7,0.4,1.9,0.5,3.4s1.3,2.3,2.7,3.41s2.6,3.51,2.8,5.41c0.2,1.9,1.5,1.6,2.5,3.1
        s-0.3,3.7-0.9,5.41c-0.6,1.7-0.9,1.4-1,4s-0.2,4.21-1,5.91c-0.8,1.7-0.3,2.2-0.4,4.21c-0.1,2.01-0.5,3-1.2,4.6s-0.1,3.21-0.8,5.41
        c-0.7,2.2-0.68,4.1-0.86,5.72c-0.09,0.81-0.59,1.3-1.07,1.6c0.07,0.17,0.11,0.33,0.09,0.5c-0.1,0.92-0.25,1.43-1.99,1.43
        s-2.61-2.66-4.5-2.45c-1.89,0.2-3.02-0.66-3.73,0.46c-0.72,1.12-0.97,2.14-2.66,2.5s-3.22,0.72-5.21,0.82
        c-1.99,0.1-2.66,0.82-3.94,0.87s-2.15,0.46-3.22,1.43s-3.17,2.05-4.34,2.35c-1.18,0.3-1.64,2.2-3.17,2.71
        c-1.53,0.51-3.58,1.27-3.58,2.3c0,1.02,0.31,2.51,0.26,3.58s-0.21,1.84-1.28,3.02s-0.3,1.94-1.58,2.6s-1.38,2.2-0.77,2.66
        c0.61,0.46,1.07,0.56,1.13,1.33s0.15,2.35-1.53,2.76s-2.5,1.64-3.37,1.89c-0.87,0.26-2.35,1.02-2.66,1.99s-0.56,1.53-0.31,2.2
        s0.92,1.33,0.61,2.25s-1.23,1.43-2.51,1.84c-1.28,0.41-1.53,1.53,0.1,2.56c1.64,1.02,1.69,1.69,1.64,3.22
        c-0.05,1.53,1.07,1.79,1.99,3.22s1.89,4.5,3.22,5.32c1.33,0.82,2.3,1.28,2.56,2.04s0.82,1.48,1.53,2.14
        c0.72,0.67,1.68,1.02,1.07,1.69s-2.1,0.87-2.1,2.1s0.31,1.43,2.46,1.18c2.15-0.26,3.88,0.1,4.34,0.72
        c0.46,0.61,0.97,0.82,1.99,0.92s1.73,1.12,1.89,2.3c0.16,1.18,1.43,3.07,2.66,3.27s5.88,0.3,7.82-0.26s5.06-2.86,5.98-4.34
        s2.03-2.61,3.06-2.76c1.02-0.16,1.68-0.41,1.38,1.12c-0.3,1.53,0,2.81-0.87,3.53s-0.67,0.87-0.56,2.25s-0.72,2.91-0.72,4.09
        c0,1.18-0.46,1.79,0.16,2.66c0.61,0.87,0.66,1.18,0.41,2.56s-1.28,2.51-0.15,2.51s1.74-1.18,2.1-0.16s-0.15,2.1,1.89,1.69
        c2.04-0.41,0.87-1.74,2.51-1.99c1.64-0.26,1.08-0.77,3.02-0.51s3.52-0.21,5.21-0.1c1.69,0.1,3.02-0.41,4.96,0.56
        s3.07,1.33,4.45,1.53s3.42-0.36,4.5-1.53c1.08-1.18,1.33-3.48,3.73-3.78c2.4-0.31,3.32,0.26,4.09-1.23
        c0.77-1.48,2.81-2.35,4.65-2.91s2.15-1.28,2.91-2.05s1.68-2.96,4.49-3.27s1.94-0.92,4.14-1.23c2.2-0.3,1.69-1.12,3.43-1.12
        s2.61-0.3,3.58,0.31s2.05,0.51,2.51-0.05s0.82-1.17,1.43-0.82s1.28,1.12,1.07,2.3c-0.2,1.18-0.26,2.04,0.1,2.96s0.92,1.79,0.1,2.15
        s-2.3,0.61-2.4,1.53c-0.1,0.92,0.46,1.69,0.66,2.66c0.21,0.97-0.2,1.58,0.67,1.94s1.48,2.3,0.97,2.81s-1.43,0.36-1.43,1.64
        s-0.16,2.35,0.71,2.91s0.56,1.48,1.69,2.25c1.12,0.77,1.64,1.43,1.69,2.25s0.3,2.15,1.33,2.15s2.25,0.16,3.17,1.18
        s2.45,1.59,2.86,2.56s1.74,2.3,3.27,2.3s2.15-0.15,3.02-0.66c0.87-0.51,1.17-1.07,2.76-0.72s0.71,1.12,2.4,1.43
        s1.22,1.23,2.25,1.23s1.53-1.33,2.71-0.41c1.18,0.92-0.3,1.99,1.84,2.81c2.15,0.82,1.94,1.12,3.37,1.53
        c1.43,0.41,1.48,1.33,3.12,0.87s2.3-0.87,2.91,0.1s0.41,2.3,1.79,2.91s1.33,1.68,3.37,1.68s2.09-1.07,3.78-0.92
        s4.24-0.72,5.11,0.36c0.87,1.08,0.36,1.58,1.94,1.89s2.71,0.56,3.12,1.23c0.41,0.66,2.1,1.74,1.07,2.76s-1.48,1.84-1.02,2.5
        c0.46,0.66,0.87,1.17,0.87,2.04s-0.05,1.47,0.61,2.24s0.61,1.23,0.61,1.89s0.31,2.35-0.66,2.76c-0.97,0.41-2.97,0.87-2.15,2.3
        s1.84,1.07,2.15,2.61c0.31,1.53,1.13,2.4,0.82,3.48s-0.57,1.99-0.41,3.12c0.15,1.12,1.13,3.78,2.91,3.68s3.11-0.77,5.46-0.92
        c2.35-0.15,3.27,0.26,5.47,0.26s2.1,0.15,4.34-0.05c2.25-0.2,4.19-1.07,4.29,0.31s-0.15,2.04-1.22,2.56
        c-1.08,0.51-1.23,1.74-1.23,2.4s0.31,1.68,0.31,2.71s0.1,2.15,1.07,2.86c0.97,0.72,3.27,3.12,4.7,2.91s1.94-0.36,2.25,0.51
        s0.15,3.01,0.41,3.93c0.26,0.92,0.77,1.02,1.18,1.79c0.41,0.77,1.02,1.58,0,2.96s-1.17,2.76-1.68,4.14s-0.41,5.27-2.05,6.03
        c-1.64,0.77-0.41,1.94-1.58,2.96c-1.18,1.02-2.3,3.27-1.23,4.34s2.66,2.61,1.28,3.22s-2.71,1.38-2.15,2.96s0.05,4.03,0.72,5.16
        c0.66,1.12,1.43,1.89,1.43,3.17c0,1.28,0.31,3.38-1.07,5.11c-1.38,1.74-0.82,2.25-0.77,3.37c0.05,1.13-1.74,2.66,0.31,3.73
        c2.04,1.07,2.66,0.2,3.94,1.07s3.32,0.11,4.7,1.08c1.38,0.97,3.43,1.73,4.4,0.51c0.97-1.23,2.2-2.2,2.96-2.2s2.7,1.38,4.48,1.74
        c1.79,0.36,2.3,0.51,3.02,1.59c0.72,1.07,2.51,1.84,2.04,3.32s-1.12,2.6-0.41,4.04c0.72,1.43,1.53,1.53,1.02,3.42
        s-1.58,2.04-0.77,3.48c0.82,1.43,0.92,2.4,1.18,3.12c0.25,0.72,0.61,2.3,2.81,1.12s2.76-0.36,3.73-1.23s1.95-1.48,3.43-1.38
        s1.33,1.99,2.04,2.3s1.53,0.92,1.07,1.53s0.16,1.83-0.87,3.11c-1.02,1.28-1.23,2.55-1.43,3.42s-0.77,1.94-1.48,2.91
        c-0.72,0.97-0.46,2.15-0.97,3.02c-0.51,0.87-1.53,2.1-1.58,3.99c-0.01,0.5,0.04,1.06,0.11,1.64c0.76-0.09,1.82-0.32,2.49-0.92
        c1.13-1.01,1.61-1.61,2.55-1.61s2.03,0.38,2.4,1.73c0.38,1.35,0.49,3.3,1.24,4.02c0.75,0.71,1.01,1.43,0.94,2.1
        c-0.07,0.67-0.15,2.1-0.71,2.63s-0.83,1.35-0.83,1.84s-0.45,1.46-0.38,2.59s1.05,2.48-0.22,3c-1.28,0.53-0.45,1.58-1.8,1.73
        s-2.1,0.22-2.7,0.98c-0.6,0.75-1.35,2.25-3.08,2.25s-3.16-0.15-4.96,1.58c-1.8,1.73-2.48,2.7-3.98,3.15
        c-1.5,0.45-2.33,0.38-2.7,1.35c-0.38,0.98-1.73,1.8-2.7,2.03c-0.98,0.22-1.58,0.22-2.33,1.5s-0.08,2.25-1.88,2.63
        s-2.63,2.03-3.68,3.23s-1.58,2.33-3.16,3.31c-1.58,0.98-2.18,1.43-4.28,3.6s-3.23,3.68-5.03,5.11s-2.85,1.35-3.68,2.63
        c-0.62,0.96-0.73,1.71-1.29,2.24c0.38,0.26,0.78,0.49,1.14,0.61c1.13,0.38,2.7,0.6,3.3-0.23s0.75-1.88,1.8-1.88s2.48,0,2.93,0.9
        s2.33,1.35,2.63,2.25s1.73,2.25,2.1,2.55c0.38,0.3,1.35,1.13,1.43,2.55c0.08,1.43,0.08,3.08,1.13,3.23s2.03-1.13,2.48-1.65
        c0.45-0.53,2.85-0.98,3,0s1.2,2.7,2.48,3.15c1.28,0.45,2.78,1.43,3.68,1.73s2.85,1.05,3.68,2.55s0.67,2.85,2.55,2.93
        s2.93,0.22,3.46,1.73c0.53,1.5,1.43,2.85,1.95,3.83c0.53,0.98,1.28,1.73,2.4,2.1c1.13,0.38,1.88,0.6,1.88,1.43s-0.3,1.05-1.65,1.58
        c-1.35,0.53-0.68,1.58-1.88,1.88s-1.73,1.5-1.88,2.48s-1.2,1.73-0.98,3c0.23,1.28,0.23,1.5,0.83,2.25c0.31,0.39,0.7,0.58,1.08,0.8
        c1.03-1.12,2.27-2.41,3.47-3.12c2.2-1.3,6.21-3.61,7.41-6.51c1.2-2.9,2.86-6.48,4.67-7.8c1.8-1.31,1.31-2.03,0.94-3.34
        c-0.38-1.31,0-1.54,0.86-2.78s1.91-1.92,2.52-3.12c0.6-1.2,0.94-1.09,2.37-1.95s3.49-1.5,3.83-2.78s0.64-2.1,1.31-3.04
        c0.67-0.94,1.5-1.16,1.8-2.37s1.69-1.2,0.9-2.4c-0.79-1.2-1.69-0.49-1.05-1.92s0.15-1.5,0.94-1.5s0.83,1.54,1.73,1.84
        s0.41,1.54,1.39,1.54c0.98,0,1.84-0.22,2.1-0.68c0.26-0.45,0.53-0.6,1.24-0.56c0.71,0.04,0.79,0.94,0.6,1.84s-1.84,3.12-2.78,3.83
        s-1.65,1.31-2.33,2.37s-2.44,0.98-2.97,2.44c-0.53,1.46-0.19,1.69-1.5,2.67s-1.8,2.37-3.04,2.89c-1.24,0.53-1.65,0.53-2.52,1.05
        c-0.86,0.53-1.05,1.39-2.07,1.39s-1.73,0.07-2.03,0.94c-0.3,0.86-0.68,2.59,1.16,1.16s2.55-1.73,3.34-2.21
        c0.79-0.49,1.2-0.56,2.29-1.16s2.78-1.31,3.68-2.14s2.37-2.48,4.09-4.24s4.54-5.33,5.9-6.61c1.35-1.28,3.72-3.91,3.87-5.14
        c0.15-1.24,0.59-4.56,4.2-8.12c3.61-3.56,6.21-6.43,8.34-7.54c2.12-1.11,2.92-1.91,4.3-2.66c1.38-0.74,2.6-1.27,2.92-2.6
        s0.37-2.34,0.74-3.04c0.37-0.69,0.96-0.96,1.17-2.39c0.21-1.43-0.16-1.7,0.48-2.76c0.64-1.06,0.85-0.85,0.85-2.44
        s-0.32-1.96,0.27-2.92c0.58-0.95,0.69-0.53,0.85-1.86s0.27-1.59-0.32-2.23s-0.9-0.53-0.9-1.75s0.95-1.48,1.06-2.44
        s0.22-2.18-0.16-2.55c-0.37-0.37-1.17-0.69-1.12-1.43s0.37-0.59,1.17-0.48s1.17,0.05,1.17-0.69s0.26-2.76-0.64-2.71
        s-1.27,0.1-1.27-0.59s0.43-0.8,1.12-0.74c0.69,0.05,1.43,0.21,1.86-0.21c0.42-0.42,1.01-0.48,1.01-1.49s0.21-1.49-1.01-1.49
        s-2.12,0.16-2.12-0.74s-0.27-1.91,0.64-1.91c0.9,0,0,1.27,0.85,1.38s1.91-0.1,2.12,0.53c0.21,0.64,1.17,1.33,1.22,0.37
        s0.64-1.75-0.21-1.97c-0.85-0.21-1.86-0.27-0.9-0.85c0.96-0.59,2.66-0.53,2.44,0.42c-0.21,0.96-0.37,1.43,0.53,1.27
        s0.37-0.85,1.17-1.01s0.89-0.22,1.04-1.34c0.15-1.13,0.23-0.68,1.58-1.95c1.35-1.28,2.1-3,4.73-3.6s2.03-0.45,4.21-2.48
        s3-3.46,5.63-4.58c2.63-1.13,2.85-1.88,4.43-2.1c1.58-0.23,2.97-0.49,3.87-1.65s1.39-2.18,3.04-2.18s4.81,1.39,5.14-0.6
        c0.34-1.99,1.05-2.89,2.85-3s1.73-0.98,3.68-1.05c1.95-0.08,2.78-0.38,2.78-1.65c0-1.28-0.56-3.12,0.75-3.3
        c1.31-0.19,0.83-0.98,2.1-1.01c1.28-0.04,0.71,1.24,1.46,1.35s1.28,0.04,1.54-0.34c0.26-0.38,0.86-0.64,1.39-0.64
        s-0.26,0.94,0.64,0.98s1.28,0.19,1.28-0.38s-0.86-1.16-0.04-1.46c0.83-0.3,1.09-0.11,1.05,0.53c-0.04,0.64-0.15,1.35,0.75,1.39
        c0.9,0.04,2.93,0.64,3.27-0.34c0.34-0.98,0.22-1.47,1.09-1.58c0.86-0.11,0.9-0.11,1.35-1.2s0.56-1.99,1.35-1.73
        c0.79,0.26-0.71,2.4,0.49,2.63s0.68,0.86,1.61,1.05c0.94,0.19,1.39,0.19,2.03-0.26s1.05-0.98,2.55-0.98s5.11,1.01,5.33-0.23
        s1.16-1.28,1.16-2.74c0-1.46-0.38-2.44,0.9-3.42s2.1-2.78,4.02-2.78s2.1-0.64,3.23-1.24s4.06-1.8,4.2-3.76
        c0.15-1.95-0.64-5.07,0.68-6.27c1.31-1.2,1.01-1.77,2.06-3.34c1.05-1.58,0.26-2.37,1.69-3.23c1.43-0.86,2.37-1.5,2.85-2.33
        s1.54-1.28,2.03-2.78s0.79-2.03,1.5-2.33s1.28-1.01,1.65-2.55c0.38-1.54,1.16-2.85,2.03-3.72c0.86-0.86,2.52-1.8,3.04-3.04
        c0.53-1.24,1.5-2.52,1.24-4.43s-0.41-4.36,0.45-6.35s-0.15-2.29,1.58-4.66s1.61-3.76,3.45-5.18s2.59-1.77,2.89-3.12
        s-0.07-2.93,0.26-5.11c0.34-2.18,0.71-3.57,1.31-5.18s1.05-3.19,1.46-4.62s0.64-3.27,1.61-4.47c0.98-1.2,0.79-2.48,0.6-4.09
        s0.15-12.62-0.11-15.21s1.01-3.42,1.13-4.24c0.11-0.83-0.9-0.49-1.05-1.5s1.5-0.08,1.43-1.65c-0.07-1.58,0.49-3.27-0.11-3.94
        c-0.6-0.68-1.46-0.9-1.58-1.58s1.2,0,1.39-1.16c0.19-1.16,0.19-1.54,0.79-2.03c0.6-0.49,1.5-0.08,1.61-1.65
        c0.11-1.58,0.34-1.65,0.71-1.91s0.56-1.05,0.22-1.61c-0.34-0.56-1.31-0.3-1.35-1.24c-0.04-0.94,0.6-1.05,1.2-1.05
        s0.26-1.09,1.73-0.94c1.46,0.15,1.73,1.2,1.73,2.22c0,1.01,0.49,1.65,1.09,1.65s0.41-0.45,2.55-3.3s3.04-2.93,3.72-4.77
        c0.68-1.84,2.03-3.38,2.78-4.47s1.54-3.57,2.22-4.58c0.68-1.01,0.38-0.83,0.86-2.59c0.49-1.77-0.26-2.74,1.31-3.42
        c1.58-0.68,1.61-0.15,1.84-1.54c0.22-1.39,1.16-0.83,1.2-2.44c0.04-1.61-1.01-1.8,0.56-1.99s1.5-1.2,3.19-1.77
        c1.69-0.56,1.99-1.65,2.59-1.99c0.6-0.34,2.14-0.68,2.63-2.03s0.68-1.95,2.33-3.53s1.77-2.44,3.12-4.28s0.6-2.33,1.88-2.74
        s1.54-0.94,2.06-2.07s0.38-1.05,1.65-2.67c1.28-1.61,1.77-1.61,2.03-3s0.94-0.67,1.09-2.48c0.15-1.8,1.13-1.65,1.16-3.34
        c0.04-1.69,0.68-0.83,0.94-2.78s0.11-1.73,0.75-2.89c0.64-1.16,0.71-1.09,0.71-2.14s1.58-1.99,1.5-2.78
        C1139.683,816.384,1137.914,817.365,1137.914,815.974z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M898.743,1032.014c-0.72-0.31-0.56-2.2-2.04-2.3
        s-2.46,0.51-3.43,1.38s-1.53,0.05-3.73,1.23c-2.2,1.18-2.56-0.41-2.81-1.12c-0.25-0.72-0.36-1.69-1.18-3.12s0.25-1.58,0.77-3.48
        s-0.3-1.99-1.02-3.42c-0.72-1.43-0.05-2.55,0.41-4.04c0.46-1.48-1.33-2.25-2.04-3.32s-1.23-1.23-3.02-1.59
        c-1.79-0.36-3.72-1.74-4.48-1.74s-1.99,0.97-2.96,2.2c-0.97,1.22-3.02,0.46-4.4-0.51s-3.42-0.21-4.7-1.08s-1.89,0-3.94-1.07
        c-2.04-1.07-0.26-2.6-0.31-3.73s-0.61-1.64,0.77-3.37c1.38-1.74,1.07-3.84,1.07-5.11c0-1.28-0.77-2.04-1.43-3.17
        c-0.66-1.12-0.15-3.57-0.72-5.16c-0.28-0.8-0.09-1.38,0.35-1.85c-0.56-0.85-1.05-1.93-1.21-2.64c-0.27-1.21-3.88-3.08-5.36-3.75
        c-1.47-0.67-2.41-1.34-3.61-3.08s-6.7,0.4-7.9,0.53s-2.41,0.27-4.15,0.27c-1.74,0-2.68,0.53-4.28,1.47
        c-1.6,0.94-3.88,0.94-6.56,0.94s-5.09,1.61-7.77,1.61s-4.42,4.02-5.09,5.49c-0.67,1.47-1.88,3.89-3.35,4.69s-0.54,2.81-0.54,4.69
        s-0.4,3.48-1.74,5.36c-1.34,1.88-1.6,4.42-2.14,7.77c-0.3,1.9-1.25,2.79-2.01,3.22c0.42,0.77,1.12,1.15,3.13,2.62
        c2.53,1.85,2.07,2.47,3.76,5.11c1.69,2.64,3.65,3.37,4.38,4.04s2.69,1.85,4.43,2.47s2.08,2.58,4.49,3.93
        c2.41,1.35,5.45,1.51,8.59,1.51s4.66,2.3,5.72,3.31c1.07,1.01,2.47,1.79,4.55,3.37c2.08,1.57,2.75,2.13,5.28,2.02
        s2.69,1.4,4.43,1.74s4.27,0.9,5.22,2.86c0.95,1.97,1.74,1.4,1.97,3.09c0.22,1.68-0.45,1.35-1.57,1.91s-1.85,0.62-1.97,2.47
        s-1.57,2.53-2.75,3.09c-1.18,0.56-1.74,0.56-2.86,2.41s0.9,1.96,0.9,3.37s-0.51,0.9-1.91,1.68c-1.4,0.78-1.35,1.52-2.19,3.2
        c-0.84,1.69-2.19,0.78-2.58,2.3s0.78,1.52,1.74,0.9s1.85-0.39,3.26-0.79c1.4-0.39,3.59,0.22,4.83,0.22c1.23,0,2.92,0.9,3.87,1.46
        s2.02,1.01,2.98,0.67s2.13-0.34,3.93-0.56c1.8-0.22,2.7,0.74,4.18,0.84s2.4-0.56,3.58-1.84s2.35-0.41,3.32,0.25
        c0.97,0.66,1.53,0.56,2.3-0.61s1.53-1.89,2.15-3.12c0.61-1.23,2.3-1.74,4.09-1.99c1.79-0.26,3.22-2.5,4.24-4.03s1.94-1.89,2.2-3.12
        s0.25-2.1,1.38-3.22s0.2-3.88,0.26-5.77c0.05-1.89,1.07-3.12,1.58-3.99s0.26-2.04,0.97-3.02c0.72-0.97,1.28-2.04,1.48-2.91
        c0.2-0.87,0.41-2.15,1.43-3.42c1.02-1.28,0.41-2.49,0.87-3.11C900.273,1032.935,899.453,1032.315,898.743,1032.014z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M739.263,604.444c0,0-1.09-0.19-0.94,0.75
        S740.953,604.894,739.263,604.444z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M732.653,604.854c-0.19-0.04-1.69-0.22-2.67-1.2
        s-1.58-1.35-2.37-1.35s-0.11,0.83,1.28,1.84s0.64,1.31,1.95,1.58C732.163,605.984,734.643,605.315,732.653,604.854z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M719.433,601.024c0,0-0.11,0.26-0.45-0.56s0.04-1.13-0.79-1.16
        c-0.83-0.04-0.9,0.9-0.15,1.47s0.45,1.2,1.24,1.31C720.073,602.194,720.633,600.914,719.433,601.024z" pointer-events="none" class="landarea"></path>
</g>
<g id="WATER_1_" data-colors="0" data-errors="0" hint-index="0" class="">
    <path fill="#a4d1dc" stroke="#95bec8" stroke-width="0.1" d="M396.833-75.506c0,0-1.69,0.42-2.96-0.14
        c-1.27-0.57-1.41-1.27-2.39-1.2c-0.99,0.07-1.41,0.77-2.18,0.07s-1.13-1.27-1.27-0.92c-0.14,0.35-1.55,0.07-1.83,1.2
        s-0.77,2.33,1.2,2.18s2.4-1.2,3.24,0.07s2.04,0.98,2.47,1.48c0.42,0.49,0.49,1.13,1.34,1.13s0.42-1.69,1.48-1.76s1.9-0.14,1.9,0.63
        s0.64,0.78,1.41,0.78s-0.07,0.85,0.92,1.2c0.99,0.35,2.04,0.35,1.97,1.48s-0.63,0.85-0.63,1.69c0,0.84-1.27,1.27-1.97,0.56
        s-0.42-1.76-1.69-1.76s-1.41,0-1.41,0.78s0.63,1.69-0.99,1.69s-2.75-1.06-3.24,0.14s-1.13,2.18-1.9,2.18s0.42,0.92-1.2,0.99
        c-1.62,0.07-3.81-0.99-4.09-0.07s1.55,0.28,1.97,1.27s1.41,1.06,1.69,1.83s0.28,1.76,1.69,1.76s2.54,0,2.89-0.63
        c0.35-0.64,0.63-2.18,2.18-2.4c1.55-0.21,1.13-0.7,2.54-0.7s1.06,0.28,1.62-0.35c0.56-0.64,1.06-1.2,1.41-0.71s0.07,0.57,0.63,1.06
        s0.77,0.64,0.63,1.27c-0.14,0.63-0.07,0.99-1.34,1.06s-0.28,1.48-2.26,1.48c-1.97,0-2.11-0.14-2.82,0.21
        c-0.7,0.35-1.06,0.57-2.26,0.57s-1.48,1.06-0.77,1.41s0.99,0.35,2.96,0.21s3.24-0.28,4.16-0.21s1.55-0.99,2.82-0.77
        c1.27,0.21,1.62-0.07,1.62-0.92s0.28-0.7-0.14-1.06c-0.42-0.35-0.35-0.92,0.42-1.83c0.77-0.92-0.7-1.41,1.97-1.34
        s2.4,0.14,3.1,1.13s1.13,0.49,1.83,1.55c0.7,1.06,1.2,1.27,1.97,1.27s1.55,0.28,1.69-0.99s1.2-2.4,2.18-2.54s0.27-1.7,1.92-1.6
        c1.65,0.1,2.05,0.1,2.25-1.1c0.2-1.2,1.65-2,0.6-2.15s-1.4,0.45-2.05-0.35c-0.65-0.8-1.3-1.4-1.3-0.8s0.85,1.8-0.1,2.25
        s-3,0.6-3.76,0.2s-0.55-1.35-1.7-1.25c-1.15,0.1-0.85-0.55-1.95-0.6s-1.6,0-1.4-1.25s-0.45-1.2-0.5-1.9s-0.1-1.25,0.55-1.25
        s0.2,1,1.05,1.1c0.85,0.1,1.05-1.25,2.1-1.05c1.05,0.2,1.6,0.15,2.55,0.25c0.95,0.1,2.15,0.55,2.45-0.2
        c0.3-0.75,1.05-0.85-0.15-1.25c-1.2-0.4-0.95-1.85-2.4-1.65c-1.45,0.2-1.6,0.4-2.75,0.15s-0.95,0.2-2-0.7s-2.35-1.05-2.6-0.45
        s-0.3,1.3-1.75,1s-0.85,1.3-3.3,0.7c-2.45-0.6-0.5-1.2-2-1.35C399.653-74.896,398.823-76.006,396.833-75.506z" pointer-events="none" class="water" opacity="1"></path>
    <path fill="#a4d1dc" stroke="#95bec8" stroke-width="0.1" d="M404.403-17.506c0,0,0.13,1.6,0.07,2.81
        c-0.07,1.2,0.13,2.67-1.27,2.74s-2.14,0.13-2.74-0.53s-0.87-1.67-1.81-0.2s-0.13,2.27-2.34,2.67s-2.01,0.13-2.27,0.87
        c-0.27,0.74,0.2,0.8-0.87,0.8s-0.87-1.34-1.74-1.34s-0.87-1.54-1.81-1.34s-0.4,1.54-1.81,1.14c-1.4-0.4-0.74-1-1.47-1.4
        c-0.74-0.4-0.87-1.07-1.61-0.94s-0.27,1.27,0.33,1.6c0.6,0.33,0.07,2.01,0.94,2.21s1-0.13,1.74,0.6c0.74,0.74,1.34,0.47,1.74,1.4
        s1.94,1.87,2.68,2.21c0.73,0.33,0.47,0.8,1.94,1s3.68,0.27,5.35,0.27s1.94-0.47,2.61,0s0.33,1.27,1.2,1.27s1.94,0.2,1.94-1.07
        s1.27-0.87,1.4-1.74s0.94-1,1.67-1.07s1.61-0.87,2.41-0.4c0.8,0.47,0.87,1.14,1.67,1.14s0.8-0.94,2.48-0.6
        c1.67,0.33,1.87,0.74,2.88-0.47c1-1.2,0.94-1.34,2.41-1.4c1.47-0.07,0.94-1.4,2.27-1.47c1.34-0.07,1.4-0.33,2.21-1.07
        c0.8-0.74,0.94-1.74,2.01-1.74s1.81,0,2.94,0.2s1.67-0.2,2.61,0.2c0.94,0.4,2.14,0.4,2.41-0.4c0.27-0.8,1.34-1.27,0.27-1.47
        s-2.34,0.94-2.41-0.07s0.87-1.34,1.74-1.34s1.81,0.13,2.74,0.87c0.94,0.74,1.47,0.87,2.54,1.2s1.87,0.67,1.87-0.33
        s-0.6-1-1.94-1.07s-0.67-1.74-2.54-2.34c-1.87-0.6-3.68-0.4-5.02-0.33s-2.41,0.07-2.41,0.74s1.87,0.6,1.4,1.2
        c-0.47,0.6-3.41-0.67-4.35,0.53s-1.27,1.54-2.34,1.74s-2.74-0.2-3.28,0.47s0,0.94-1.67,0.94s-4.35,0.8-5.08-0.27
        c-0.74-1.07-2.81-1.27-2.81-2.88s-0.13-3.68-1.87-3.68s-2.48-0.2-2.41-1.4s-0.13-1.81-0.94-2.07c-0.8-0.27-0.4-2.74-2.07-2.81
        s-0.87,0.67-0.74,1.87s0.74,1.34,0.87,2.21C404.273-18.976,404.203-19.046,404.403-17.506z" pointer-events="none" class="water" opacity="1"></path>
    <path fill="#a4d1dc" stroke="#95bec8" stroke-width="0.1" d="M414.733,23.714c0,0-0.31-0.45-1.75,0.05
        c-1.44,0.49-2.29,0.63-2.65,1.3c-0.36,0.68-0.99,1.22-1.53,1.35c-0.54,0.14-0.94,0.58-1.89,0.58s-1.8-0.09-1.66,0.81
        s0.18,1.75,1.03,1.75s0.18,1.03,1.39,0.9c1.21-0.14,1.22-1.44,2.25-1.53s1.58-0.59,2.2-1.39c0.63-0.81,1.76-0.85,2.38-1.08
        c0.63-0.23,0.58-0.85,2.34-0.68c1.76,0.18,3.6-0.31,4.23,0.4s1.26,0.85,2.61,0.85s2.88-0.27,3.73-0.27s1.39,0.36,2.43,0
        s1.44-0.54,2.29,0s1.49,0.49,2.11,1.12c0.62,0.63,0.76,0.95,1.62,1.03s0.81,0.22,1.21,0.76s1.26,0.49,1.26,0.05
        s-0.05-1.26-0.67-1.3c-0.63-0.04-0.9-1.03-1.93-1.21s-0.9-0.49-1.98-0.76c-1.08-0.27-0.76-0.99-1.98-0.99s-1.58,0.13-1.98-0.54
        s-0.31-1.35-1.48-1.35s-1.35,0-2.16,0.14s-2.2,0.85-2.38-0.4s-0.27-1.75-0.81-2.07s-1.93-0.99-2.52-0.67
        c-0.58,0.31-1.39,0.67-1.53,1.26c-0.14,0.58,0.27,0.9-0.85,0.94s-1.53-0.14-1.84,0.4C415.913,23.704,415.953,24.304,414.733,23.714
        z" pointer-events="none" class="water" opacity="1"></path>
    <path fill="#a4d1dc" stroke="#95bec8" stroke-width="0.1" d="M470.573,99.744c0,0-0.45,0.3-1.35-0.26
        c-0.9-0.56-1.69-1.01-2.07-0.34c-0.38,0.68,0,1.8-1.05,2.1c-1.05,0.3-0.53,1.39-1.16,1.8s-0.19,1.2-0.94,1.43s-0.71,0.98-0.83,1.54
        c-0.11,0.56,0.15,1.76,1.16,1.92c1.01,0.16,0.34,0.53,1.43,0.53s1.58-0.19,1.39,0.49c-0.19,0.68-1.2,0.19-1.43,0.83
        s-1.28,0.38-1.28,1.31c0,0.94,0.08,1.5,0.49,1.95s0.15,1.35,0.68,1.76s1.05,0.45,1.28,1.39s0.94,1.09,0.83,2.29
        c-0.11,1.2-0.38,3.15,0.41,3.46c0.79,0.3,0.41,1.28,1.2,1.28s0.94-0.26,0.94-0.9s0.41-2.22,0.75-2.29s0.11,1.01,0.94,1.05
        c0.83,0.04,1.5,0.07,1.58,1.13c0.08,1.05,0.9,2.25,0.3,2.78s-1.95,1.69-1.01,2.55c0.94,0.86,1.05,0.41,1.88,0.64
        c0.83,0.22,1.39-0.22,1.16,0.56c-0.23,0.79-1.24,0.68-1.2,1.46c0.04,0.79,0.6,2.14,0,2.55s-1.13,0.71-1.2,1.5
        c-0.08,0.79-1.09,0.56-1.05,1.65s-0.41,4.32,0.64,4.32c1.05,0,1.35,0.45,1.46-0.34s0.45-0.9,1.09-1.01
        c0.64-0.11,1.39,0.49,1.43-0.86c0.04-1.35,0.56-2.07,0.75-2.78s0.15-2.14,0.6-2.52s0.6-0.49,0.64-1.2c0.04-0.71,0.19-1.05-0.68-1.2
        c-0.86-0.15-0.04-2.37-0.41-2.48c-0.38-0.11-1.31-0.19-1.16-1.16s1.2-0.41,1.01-1.28s-1.46-0.22-1.35-1.2
        c0.11-0.98,0.53-1.2,0.11-2.07c-0.41-0.86-1.24-0.9-1.16-1.65s1.13,0.04,1.28-0.94s0.15-1.95,0.26-3.04
        c0.11-1.09,0.11-1.13-0.71-1.92c-0.83-0.79-0.6-2.1-0.49-2.97c0.11-0.87,0.64-1.01,0.34-2.14c-0.3-1.13,0.53-3.12-0.26-3.46
        s-1.05-0.6-1.24-1.54s0.38-2.97-0.6-3.19s-1.16-1.24-1.88-1.43C471.363,99.674,471.703,99.334,470.573,99.744z" pointer-events="none" class="water" opacity="1"></path>
    <path fill="#a4d1dc" stroke="#95bec8" stroke-width="0.1" d="M299.303,217.774c0,0-0.98,0.19-1.46,0.75
        c-0.49,0.56-1.31,1.28-0.53,1.58c0.79,0.3,1.2,0.19,1.16,0.86s0.26,1.39-0.68,1.46s-1.61-0.34-1.65,0.3s-0.38,1.01-1.13,1.35
        c-0.75,0.34-1.2,0.38-1.2,1.09c0,0.71-0.75,2.52-0.3,3.19c0.45,0.68,1.16,1.05,1.16,2.1s-0.94,4.58,0.71,4.66
        c1.65,0.07,2.52,0.15,2.67-1.2c0.15-1.35,0.19-2.22,1.05-2.74c0.86-0.53,1.43-0.41,1.5-1.46c0.08-1.05-0.3-1.46,0.23-2.18
        c0.53-0.71,0.23-1.73,0.9-2.48c0.68-0.75,0.79-1.2,0.9-1.84s0.04-1.16,0.98-1.24c0.94-0.07,1.5,0.38,1.5-0.68
        c0-1.05,0.34-1.35-0.38-1.58c-0.71-0.23,0.15-2.7-1.5-2.85c-1.65-0.15-1.77-0.11-2.03,0.41
        C300.953,217.814,300.993,217.624,299.303,217.774z" pointer-events="none" class="water" opacity="1"></path>
    <path fill="#a4d1dc" stroke="#95bec8" stroke-width="0.1" stroke-miterlimit="10" d="M615.673,238.674
        c-0.15-0.11-0.29-0.2-0.45-0.27c-0.49-0.21-1.04-0.21-1.74-0.06c-0.93,0.2-2.33,0.6-4.32,0.47s-6.24,0.13-6.31,1.73
        c-0.07,1.59,0.86,1.86-1.06,1.79c-1.93-0.07-2.19,0.07-2.99-0.66s-0.13-0.93-2.19-0.93s-4.05-0.33-5.58,1.73s-1.86,2.99-3.05,3.05
        c-1.2,0.07-1.26,0.47-2.59,0.93s-1.39,0.13-2.72,0.73c0,0-1.06,0.26-1.2,0.73s-0.27,0.86-1.33,0.86s-1.72-0.07-1.79-0.46
        c-0.06-0.37-1.21-0.99-2.01-0.59c-0.06,0.03-0.13,0.02-0.18,0.06c-0.8,0.6-0.07,1.26-1.13,1.73c-1.06,0.46-1.79,2.19-0.4,2.39
        c1.39,0.2,1.33,0.66,2.59,1.53c1.26,0.86,2.79,1.46,4.38,1.46s2.06-0.46,4.05-0.46s3.12,0.53,4.45-0.6s2.52-2.26,4.58-2.79
        s2.26-1.13,4.38-1.59c2.13-0.47,1.99-1.26,3.59-1.66c1.59-0.4,1.66-0.66,3.85-1.46s2.46-1.53,4.38-2.59s3.92-1.73,4.38-2.92
        C615.733,239.614,616.263,239.144,615.673,238.674z" pointer-events="none" class="water" opacity="1"></path>
    <path fill="#a4d1dc" stroke="#95bec8" stroke-width="0.1" stroke-miterlimit="10" d="M606.243,213.254
        c-1.05-1-0.05-1.75-1.75-3.46c-1.7-1.7-0.7-2.2-2.35-3.15c-1.65-0.95-1.05-3.35-3.35-3.61s-3.26-1.4-4.66-1.15
        c-1.4,0.25-0.35-1.1-2.9-1.1c-2.55,0-1.9-0.8-4.26-1.1c-2.35-0.3-2.91-0.5-4.86-0.35s-3.05,0.35-4.01-0.15s-0.55-0.7-1.9-0.9
        c-1.35-0.2-2.6-1.1-3.6-1.15c-1-0.05-1.7-0.25-2-1.05s0.6-1.85-1.25-2s-0.8,0.35-1.05-1.25s0.05-2.85-0.55-3s-0.35-0.85-1.2-0.85
        c-0.85,0-1.9-0.6-1.25-1s1.2-0.15,1.3-1.25s0.6-2.2-0.15-2.7s-1.85-0.55-1.65-1.85s0.85-1.5,0.8-2.51c-0.05-1,0.4-1.75-1.95-1.55
        c-2.35,0.2-4.56,0.05-5.71-1.3s-1.55-0.25-1.95-2.7s-0.65-3.2-1.2-3.71s-0.15-2.05-1.4-2.05s-1.6,0.4-2.3-0.3s-1.25-1.4-1.7-0.75
        s-0.75,0.95-1.35,0.95s-1-1.3-1.95-1.35s-1.2-0.95-2.25-1s-1.7-0.1-2.05-0.6s-0.15-1.1-2.2-0.9s0.25,1.7-0.25,3s-0.65,0.8-1.55,0.5
        c-0.9-0.3-0.95-1.45-1.3-0.55s1.05,1.35,0.55,1.5s-1.6,0.35-1.8,1.25s-0.75,0.2-1.45,0.85c-0.7,0.65-1.1,1-1.4,0.5
        s-0.5-1.4-1.5-1.25s-0.75,1.8-2.05,2.45s-1.25,2.05-2.4,2.5s-1.2,0.8-2.3,1.15c-1.1,0.35-0.85,0.85-2.2,0.85s-1.8-0.25-2.35,0.4
        s-1.5,0.7-3.35,0.83c0,0-2.18,0.15-2.93,1.35c-0.75,1.2-0.6,1.95-2.25,2.4c-1.65,0.45-2.03,0.68-2.78,1.43s-1.5,1.58-2.03,1.73
        s-3,0.38-3.08,1.35c-0.08,0.98,0.6,1.65,1.8,1.88c1.2,0.23,1.2-1.28,2.18-1.28s3,1.05,3.76-0.23c0.75-1.28,1.43-1.28,1.95-1.2
        c0.53,0.08,0.15,1.13,0.75,1.35s0.15,1.35,0.53,1.58c0.38,0.22,1.28,0.15,1.13,0.68s0.45,1.2,1.43,1.2s1.65,0,1.8-0.45
        c0.15-0.45,0.53-0.9,1.2-0.9s1.2-0.75,2.55-0.83c1.35-0.07,3.68,0,4.58-0.68s1.95-0.6,2.63-1.5s0.45-0.9,1.73-1.58
        c1.28-0.68,0.68-1.13,1.58-1.05s0.6,1.5,1.58,2.03s-0.08,2.7,1.2,2.78c1.28,0.07,4.51-0.3,5.26,0.68s1.58,2.18,2.33,3.23
        s1.35,0.98,2.33,0.9c0.98-0.08,2.1-0.38,2.4,0.3c0.3,0.68,2.03,1.35,2.25,0.6s0.9-1.35,1.5-1.28c0.6,0.08,0.9,0.08,1.43-0.45
        s0.38-0.45,1.8-0.6c1.43-0.15,2.65-0.15,3.71-0.05s3.15,0.15,3.5-0.05s0.5-0.55,1.2-0.45s0.8,0.5,0.85,1.35s-0.8,2.55,0.65,2.6
        s1-0.05,1.45,0.05s0.95,0.55,1.6,0.6s0.85,0.65,0.95,1.2c0.1,0.55,0.05,1.15,0.6,1.15s1.55,0.3,1.95,0.4s0.55,0.25,0.75,0.7
        s1.1,0.75,1.1,1.05s-1-0.15-1,0.6s-0.5,0.85-1.25,0.85s-1.2-0.2-1.85-0.45c-0.65-0.25-0.9-0.7-1.6-0.7c-0.7,0-1.05,0.15-1.7,0.65
        s-1.45,0.75-1.85,0.4s-0.5-1.35-1.15-1.35s-1.8-1.65-2.5-1.55c-0.7,0.1-1.65,0.3-1.75,0.8s0.55,0.7-1.2,0.7s-4.56,0-4.76,0.5
        s-0.5,1.1-0.9,1.25s0.3,0.6-0.85,0.6s-0.55-0.9-2-1s-2.05,0.35-2.25,0.85s-0.95,0.65-1.8,0.6c-0.85-0.05-0.75,0.65-1.4,1.95
        s-1.75,1.8-2.3,2.8s-0.15,1.2-1.65,2.2s-1.45,0.7-1.8,1.3s-2.2,1.05-2.4,2.15c-0.2,1.1,1.05,1.25,2,1.05s0.55-1.55,1.6-1.55
        s1.45,0,1.85,0.45c0.4,0.45,0.65,1.45-0.1,2s-1.05,0.8-1,1.95s-0.95,1.75-1.75,3.95s-0.6,2.05-0.9,3.56c-0.3,1.5-0.15,1.3-1.05,2.6
        c-0.9,1.3-0.9,1.3-0.85,1.85c0.05,0.55,0.1,1.45-0.4,1.5s-1.15,1.9-0.55,2.65s0.9,1.1,1,2.4c0.1,1.3,0.5,3.1-0.15,3.6
        s-0.95,0.45-1,1.9s-0.4,2.5,0.4,3.05s-0.2,2.1,0.9,3.05s0.1,1.45,1.1,2.3s2.15,1.35,3.25,0.8s2.05-1.6,3.41-2.3
        c1.35-0.7,2.25-2.15,2.7-3.05c0.45-0.9,2.8-2.75,2.9-4.81c0.1-2.05,0.05-4.31,0.05-5.86s0.35-2-0.2-2.8s-1.4-1.4-1.35-2.75
        c0.05-1.35,0-2.4,0.55-3.05c0.55-0.65,0.8-0.75,0.7-2.05s-0.55-2.55,0.25-3.15c0.8-0.6,1-1,1-2.2s1.45-0.75,1.45-1.9
        s-0.35-1.25,0.45-1.9s0.05-2.4,1.2-2.5s0.55-1.5,1.8-1.3s1.5,0.6,1.7-0.25s0.25-1.15,1-0.95s0.1,1,0.05,1.7s0.2,2.55,1.15,2.15
        c0.95-0.4,1.1-1.45,0.75-2.25s-1.4-1.75-0.1-2.55c1.3-0.8,0.5-1,1.75-1.3s1.7-0.1,1.7-1.4c0-1.3-0.35-2.25,1.05-2.55
        c1.4-0.3,2.1-0.3,3.35,0.4s1.1,0.7,2.15,0.7c1.05,0,0.65,2.2,2.7,2.25s1.65,0.1,2.3,0.65c0.65,0.55,1.6,0.05,1.9,1.3
        c0.3,1.25,0.55,1.65,1.05,1.8c0.5,0.15,1.1,0.45,0.65,1c-0.45,0.55-1,0.6-0.85,1.6s-0.2,2.05,0.2,2.5s0.2,0.75,0.15,1.75
        s-0.55,1.9-1.15,2.6s-0.7,2.2-1.7,3s-2.1,1.1-2.7,2.05c-0.6,0.95-1.35,1.4-0.85,2.55c0.5,1.15,2.65,1.65,3.55,0.4
        c0.9-1.25,1.25-2.4,2.25-2.85c1-0.45,0.7-1.55,1.8-1.6s2.25-0.5,2.35,0.4s0.2,1.35,0.8,1.55s1.25,1.35,1.1,3.05s0.4,1.8,0.5,2.4
        c0.09,0.57-1.06,4.6,0.86,4.77c0.12,0.01,0.2,0.06,0.34,0.04c2.45-0.35,1.4-1.3,2.96-1.85c1.55-0.55,2.5-0.6,2.7-3.51
        c0.2-2.9-0.7-3.76,0.35-5.26s0.6-2.45,1.5-3.55s2.45-1.35,2.7-3.36s0.45-3.55-0.25-3.7s-0.9-2.2-1.9-2.75s-0.3-0.8-1.25-1.05
        s-0.5-0.95,0.9-0.95s1.85,0.25,2.55,0.95s1.3,2.35,2.55,2.75s1.45,0.15,1.7,1.3c0.25,1.15,0.15,1.15,1.05,1.95
        c0.9,0.8,0.7,0.3,2,0.95s0.9,0.65,2.4,0.85c1.5,0.2,1.95,0.8,2-0.25s-0.4-2.15,0.25-2.55c0.65-0.4,1-0.6,0.95-1.45
        C607.343,213.654,607.293,214.254,606.243,213.254z" pointer-events="none" class="water" opacity="1"></path>
    <path fill="#a4d1dc" stroke="#95bec8" stroke-width="0.1" stroke-miterlimit="10" d="M640.083,228.064
        c-0.49-0.9-0.04-2.29-0.45-3c0-0.01-0.01-0.02-0.01-0.03c-0.38-0.74,0.26-2.67-0.97-2.6c-1.24,0.08-1.88,0.94-2.7,1.09
        c-0.83,0.15-1.24,0.38-2.7,0.19c-1.47-0.19-2.85-0.23-1.95,0.64c0.9,0.86,2.55,0.15,2.52,0.98c-0.04,0.83-0.86,1.65-1.77,1.65
        c-0.9,0-2.29,0.34-3.19-0.34s-2.22-1.62-3.42-1.24s-2.82,0.23-4.02,0.98s-0.98,0.83-2.93,0.83s-2.93,0-3.57,0.34
        s-1.01,1.28-2.29,1.54c-0.15,0.03-1.84,0.53-2.44,1.43s-1.65,2.29-1.47,3.08c0.19,0.79-0.26,0.83,1.54,0.83
        c1.56,0,2.64,0.32,3.75,0.06c0.18-0.04,0.35-0.06,0.53-0.14c1.31-0.56,1.84-2.03,3.68-2.22c1.84-0.19,5.48-0.15,6.68,0.34
        c1.2,0.49,2.78,1.09,4.24,1.09c1.47,0,4.58,1.13,6.01-0.23c1.43-1.35,1.31-1.58,2.37-1.8c1.05-0.23,2.4-0.3,2.4-1.31
        C639.933,229.184,640.573,228.964,640.083,228.064z" pointer-events="none" class="water" opacity="1"></path>
    <path fill="#a4d1dc" stroke="#95bec8" stroke-width="0.1" stroke-miterlimit="10" d="M543.503,618.305
        c-0.08-1.5-0.75-2.4-1.65-3.3c-0.9-0.9-1.09-2.29-2.33-2.93s-1.05-0.79-1.84-1.54s-1.05-1.13-1.28-1.88c0,0-0.22-0.79-1.2-1.05
        c-0.98-0.26-1.01-1.09-2.07-1.39c-1.05-0.3-3.38,0.38-3.42,1.91c-0.04,1.54,1.16,1.35,1.35,2.55s-0.26,2.22,0.68,2.74
        c0.94,0.53,1.2,0.9,1.46,1.77c0.12,0.41,0.31,0.84,0.59,1.26c0.31,0.47,0.75,0.9,1.41,1.22c1.24,0.6,1.91,0.56,2.97,1.58
        c1.05,1.01,1.2,1.01,1.54-0.08c0.34-1.09-0.04-1.46,0.49-1.58c0.53-0.11,0.68,1.2,0.6,2.1s0.75,1.58,1.09,1.58s1.35,0.45,1.13-0.75
        c-0.19-1.02,0.35-0.83,0.47-1.68C543.503,618.694,543.513,618.524,543.503,618.305z" pointer-events="none" class="water" opacity="1"></path>
</g>
<g id="AREA_HAITI" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Haiti" data-wikipediaLink="https://en.wikipedia.org/wiki/Haiti" data-infoText="<b>Haiti</b> &amp;nbsp; Capital: Port au Prince" data-imageFile="HAITI.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M681.733,525.624c0,0-0.22-0.53-0.9-0.71
        c-0.68-0.19-0.34-1.05-1.16-1.01s-0.98,0.86-1.58,1.09c-0.6,0.23,0.11,0.9,0.75,0.94s1.09-0.11,1.5,0.49s0.64,0.6,1.28,0.68
        s2.4,0.53,2.4,0.04s-0.41-1.13-0.98-1.01C682.483,526.224,682.143,526.224,681.733,525.624z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M695.693,530.504c-0.37-0.74-1.61-0.87-2.72-1.49
        s0.37-1.61,1.49-1.86c1.11-0.25,1.11-0.87,1.73-1.24s0.12-1.24-0.87-1.49s-0.87-0.87-0.62-1.73s0.99-0.12,1.98-0.25
        c0.99-0.12-0.25-1.24-0.99-2.23s0.12-1.73,0.25-3.34c0.05-0.66-0.24-1.44-0.59-2.14c-0.67,0.24-1.65,0.94-2.74,0.03
        c-1.15-0.94-1.42-1.42-2.56-1.35c-1.15,0.07-2.5,0.88-3.51,0s-1.42-0.27-1.55-1.42s-0.47-1.21-1.08-1.21c-0.61,0-1.62,0-1.82,0.54
        s0.74,1.48-1.35,1.42c-2.09-0.07-1.89-0.67-2.43,0.14s-1.68,0.27-1.68,1.55s0.81,0.88,1.01,1.48c0.2,0.61,1.41,0.47,2.02,0.47
        c0.61,0,1.82-0.54,2.29,0.07s2.56,1.35,1.82,3.24c0,0-0.81,2.77,0.54,3.91c1.35,1.15,0.94,1.48,2.09,2.23
        c1.15,0.74,1.08,0.74,2.02,0.81c0.95,0.07,1.08,0.74,1.01,1.08s-0.27,0.88-1.48,0.88c-1.21,0-2.09,0-2.09,0.4s0.07,1.42-1.41,1.21
        c-1.49-0.2-2.36-0.95-3.84-0.54c-1.49,0.4-3.71,1.35-4.38,0.54c-0.68-0.81-1.35-1.62-2.7-1.28c-1.35,0.34-3.31,0.41-4.18-0.47
        s-0.81-1.08-1.89-1.08s-0.27,1.42-1.76,1.42c-1.48,0-1.28,1.35-1.28,1.89c0,0.54,0.94,0.47,1.35,1.21s1.22,0.81,1.89,0.81
        c0.67,0,1.41,0.4,1.75,1.08c0.34,0.67,0.74,1.96,1.55,1.96s1.41,0.13,1.41-0.67c0-0.81,0.14-1.42,0.95-1.42s1.55,0.61,1.82-0.27
        s1.08-0.94,1.82-0.94s1.55-0.34,1.96,0.54s1.08,0.74,2.43,0.74s3.3,0.34,3.98-0.14c0.68-0.47,1.82-0.94,3.51-0.94
        c1.68,0,4.65-0.07,4.79,0.4c0.13,0.45,0.34,1.31,1.4,1.92c0.21-0.98,0.79-1.36,1.17-2.01
        C696.683,532.104,696.063,531.244,695.693,530.504z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_14_" x="667.963" y="498.865" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="31.22" height="24.99" pointer-events="auto" visibility="hidden"></rect>

        <ellipse fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" cx="677.884" cy="529.69" rx="19.168" ry="21.285" pointer-events="auto" opacity="0.001" class="clickarea landarea"></ellipse>
</g>
<g id="AREA_JAMAICA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Jamaica" data-wikipediaLink="https://en.wikipedia.org/wiki/Jamaica" data-infoText="<b>Jamaica</b> &amp;nbsp; Capital: Kingston" data-imageFile="JAMAICA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M633.893,531.104c0,0-1.69,0.6-2.48-0.04s-1.47-1.31-2.63-1.39
        c-1.16-0.08-3.19,0.34-3.6,0.6s-1.31,0.75-2.55,0.75s-1.65-0.04-1.84,0.45s-1.24,0.71-1.24,1.39s0.11,0.98,1.28,0.9
        c1.17-0.08,2.85-0.38,3.3,0.79c0.45,1.16,1.31,1.09,2.1,2.07s0.68,1.69,1.58,1.69s3.08-1.2,3.94-0.15s2.07,2.78,2.82,1.2
        s0.94-1.73,1.99-1.73s3.87-0.79,4.09-0.15c0.22,0.64,1.13,1.39,1.69,0.64s0-1.05,1.24-1.31s1.77-0.83,0.79-1.13
        c-0.98-0.3-1.01-1.24-2.52-1.65s-2.74-0.41-3.57-1.28s-1.46-1.95-2.37-1.95C635.013,530.804,634.833,530.914,633.893,531.104z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_13_" x="594.693" y="526.054" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="71.09" height="24.99" pointer-events="auto" visibility="hidden"></rect>

        <ellipse fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" cx="632.883" cy="544.637" rx="25.833" ry="26.988" pointer-events="auto" opacity="0.001" class="clickarea landarea"></ellipse>
</g>
<g id="AREA_CUBA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Cuba" data-wikipediaLink="https://en.wikipedia.org/wiki/Cuba" data-infoText="<b>Cuba</b> &amp;nbsp; Capital: Havana" data-imageFile="CUBA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M555.933,479.364c0,0-1.47,0.9-1.8,1.65
        c-0.34,0.75-0.71,0.9-1.28,1.2c-0.56,0.3-1.88,1.2-1.43,1.73s1.69,0.38,1.61,1.39s-0.04,1.58-1.24,1.43s-2.78-0.53-3.57,0.08
        c-0.79,0.6-1.99,0.71-2.03,1.58c-0.04,0.86,0.19,1.01,1.05,0.6s0.79-0.9,1.8-1.05s2.22-0.19,1.88,0.64
        c-0.34,0.83-0.41,0.98,0.41,0.98s1.2-0.11,1.69-0.9s0.38-0.86,1.54-0.83c1.16,0.04,1.91,1.05,2.37-0.41
        c0.45-1.46-0.38-2.07,1.54-2.14c1.91-0.08,5.03-0.04,6.05-1.58c1.01-1.54,2.07-1.61,2.82-2.48c0.75-0.86,1.88-1.73,2.82-1.84
        s2.37-0.34,3.64-0.15c1.28,0.19,2.37,0.15,3.12,0.08c0.75-0.08,1.35-0.3,2.67,0c1.31,0.3,2.37,0.45,2.4,1.31
        c0.04,0.86,0.53,1.92-1.58,1.95c-2.1,0.04-2.89-0.3-2.63,0.38s1.39,0.75,1.91,1.54s1.46,0.79,2.25,0.83
        c0.79,0.04,2.89-0.34,3.38,0.19s1.28,1.28,2.07,1.09s0.6-1.28,1.43-1.24s0.26,1.5,0.94,1.69s1.13,0.68,2.52,0.6
        s3.68-1.77,4.88-0.11s2.63,2.22,3.72,2.89s1.09,1.13,2.82,1.31c1.73,0.19,2.59-0.08,3.3,0.49c0.71,0.56,1.54,0.94,2.82,0.98
        s3.12-1.05,4.77-0.83s2.37,1.77,2.7,3.04c0.34,1.28,0.98,2.44,1.39,3.19c0.41,0.75,1.31,2.21,2.48,3.19s2.14,2.03,4.09,1.95
        s2.78-0.19,3.94-0.26s2.07,0.19,2.97,1.09s1.54,1.77,1.54,2.29c0,0.53-0.08,1.58-1.09,1.99s-1.65,1.65-2.67,2.1
        c-1.01,0.45-1.99,0.71-2.1,1.73c-0.11,1.01-0.19,1.58,1.58,1.54c1.77-0.04,1.2-1.46,3.42-1.54c2.22-0.08,4.17,0.11,6.46,0.15
        c2.29,0.04,2.14,0.23,3-0.15s1.61-0.9,2.7-0.68c1.09,0.22,1.8,1.2,2.93,1.13c1.13-0.08,2.89-0.34,3.83-0.15s0.86,1.01,2.03,0.86
        c1.16-0.15,1.73-1.13,2.78-1.13c1.05,0,1.88,0.15,2.4-0.26s1.88-0.19,2.18-0.86c0.3-0.68,1.01-1.16,2.14-1.2s3.79,0.75,4.17-0.23
        s0.11-1.84-1.05-1.8s-1.99,0.26-2.7-0.71c-0.71-0.98-1.31-1.16-2.63-2.22c-1.31-1.05-2.4-2.03-3.53-2.07s-6.27,1.13-6.91-0.79
        c-0.64-1.92-0.41-4.17-1.77-4.02s-1.95,0.75-3-0.11s-1.65-1.69-3.34-1.92s-2.18,0.56-2.85-0.26s-1.31-1.16-1.69-0.9
        s-0.45,1.24-1.39,1.13s-0.3-1.8-1.2-2.37c-0.9-0.56-2.33-3.27-3.79-3.38c-1.46-0.11-2.55,0.53-3.12-0.23
        c-0.56-0.75-0.26-1.28-1.43-1.24s-2.14,0.26-2.29-0.53s0.94-0.38,1.39-0.79s1.39-0.79,0.38-1.16s0.22-0.64-0.68-1.28
        c-0.9-0.64-1.05-0.49-2.07-1.13s-1.09-1.16-1.54-0.94s-0.15,0.86,0.34,1.09c0.49,0.23,0.38,0.83,0.86,1.09
        c0.49,0.26,1.09,0.3,1.05,0.68c-0.04,0.38,0.41,0.94-0.45,0.98s-0.79-0.3-1.65-0.79c-0.86-0.49-1.13-0.98-1.91-1.09
        c-0.79-0.11-1.47-0.04-1.88-0.9c-0.41-0.86-0.71-0.79,0.15-1.24c0.86-0.45,1.2-0.68,1.01-1.31c-0.19-0.64-0.6-1.35-1.24-0.86
        c-0.64,0.49-0.71,1.58-1.28,1.99s-0.49,0.53-1.65,0.53s-1.2-1.24-2.22-1.13c-1.01,0.11,0.11,1.05-1.31,1.2s-1.73,0.23-2.78-0.83
        s-4.69-5.97-6.72-6.05c-2.03-0.08-1.39-1.01-2.7-0.83s-0.79,0.9-1.88,0.83c-1.09-0.08-1.31-0.26-2.18-1.01
        c-0.86-0.75-1.54-1.5-3.12-1.43s-2.67,0.83-3.42,0.41s-0.26-1.8-1.35-1.8s-0.19,1.77-1.43,1.77s-2.25,0.53-2.78-0.04
        c-0.53-0.56-1.09-1.43-2.18-1.5s-1.77-0.45-2.59-0.34c-0.83,0.11-0.94,0.23-1.5,0.23s-1.43-0.38-1.95-0.23
        c-0.53,0.15-0.64,0.08-0.86,0.56c-0.22,0.49,0.15,1.05-0.9,1.05s-2.22-0.15-3.34-0.23c-1.13-0.08-2.25,0.08-2.48,0.34
        c-0.22,0.26-0.75,0.71-1.58,0.71s-2.44,0.04-2.89,0.41c-0.45,0.38-0.41,0.83-1.46,0.83c-1.05,0-2.07-0.26-2.25,0.41
        s-0.11,1.01-1.16,1.01C557.513,478.004,556.913,478.644,555.933,479.364z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M569.453,488.264c0,0-1.91-0.26-2.07,0.75s-0.34,1.69-0.86,2.18
        s-1.39,0.75-0.71,1.2s0.83,1.13,1.35,1.28s0.3,0.98,1.35,1.01c1.05,0.04,1.24-1.13,1.88-1.2c0.64-0.08,1.16,0.56,1.54-0.56
        c0.38-1.13,0.94-0.34,1.09-1.58s-0.49-0.34-1.2-1.58C571.103,488.524,570.423,488.414,569.453,488.264z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M623.223,505.344c0,0-1.54-0.38-1.5,0.34
        c0.04,0.71,0.19,0.68,0.75,0.68s1.2,0.08,1.39-0.23C624.053,505.834,623.943,505.384,623.223,505.344z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M626.563,507.564c0,0-0.53-0.19-1.05,0.11s-0.94,0.34-0.04,0.68
        s1.05,0.41,1.28,0.98c0.23,0.56,1.35,1.01,1.65,0.45c0.3-0.56-0.15-0.71,0.68-1.2c0.83-0.49,2.25-0.98,1.77-1.61
        c-0.49-0.64-1.39-0.49-1.99,0.11c-0.6,0.6-0.79,1.31-1.35,1.35C626.943,508.464,627.353,507.934,626.563,507.564z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_12_" x="571.373" y="478.964" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="71.09" height="24.99" pointer-events="auto" visibility="hidden"></rect>
    <ellipse fill="#1e8346" stroke="#95bec8" stroke-miterlimit="10" cx="607.561" cy="484.228" rx="58.142" ry="33.486" pointer-events="auto" opacity="0.001" class="clickarea landarea" stroke-width="0.5"></ellipse>
</g>
<g id="AREA_PANAMA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Panama" data-wikipediaLink="https://en.wikipedia.org/wiki/Panama" data-infoText="<b>Panama</b> &amp;nbsp; Capital: Panamá" data-imageFile="PANAMA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M612.223,640.324
        c-0.37-0.73-0.6-1.97-1.56-2.11c-0.97-0.14-2.39,0.64-3.17-0.05c-0.78-0.69-1.24-1.51-2.25-0.96s-2.07,2.43-2.98,2.94
        c-0.92,0.51-1.97,2.29-4.68,2.62s-3.63,0.41-5.97,2.39s-4.22,2.57-5.88,2.71c-1.65,0.14-3.63-0.05-4.5-0.97s-1.1-1.47-1.88-2.16
        s-1.28-1.33-1.74-1.33s0.09,1.75-0.92,1.84s-2.8,0.55-3.08-0.97c-0.28-1.52,0.05-2.21-1.19-3.12s-1.88-2.8-3.67-3.62
        c-0.1-0.05-0.16-0.08-0.25-0.12c-0.34,0.38-0.81,0.69-1.45,0.74c-1.82,0.15-2.55,1.02-2.18,1.97c0.36,0.95-0.36,2.11,0.51,3.06
        s1.89,0.88,1.24,2.18s-1.89,1.53-1.53,2.48s0.51,1.6,0.22,2.33c-0.24,0.6,0.23,2.01,0.25,3.74c1.35-0.08,1.16-1.04,2.1-1.04
        c1,0,1.8-0.35,2.7,0.2s1,1,1.8,0.55s1.35-0.2,1.65,0.4s0.55,1.05,1.55,1.05s3.66-0.4,4.26,1.25c0.6,1.65,1.35,1.31,1.35,2.14
        s-0.09,1.28,0.87,1.65c0.97,0.37,0.87,1.2,1.74,1.52s1.15,0.09,1.61,0.97c0.46,0.87,0.87,0.41,0.83-0.28
        c-0.05-0.69,0.18-1.19-0.18-2.07s-0.87-1.93,0.05-2.07s1.79,0.83,1.7,1.61c-0.09,0.78-0.23,1.01,0.32,1.52s0.87,0.69,0.87,1.7
        s-0.55,3.17,0.87,3.53c1.42,0.37,2.71-0.14,3.58-0.14s1.15-0.28,1.56-0.64s0.83,0.14,1.19-0.46s0.51-0.92,1.84-1.06
        s2.85-0.37,2.85-1.29s-0.97-0.83-2.39-2.53c-1.42-1.7-3.45-2.71-3.58-4.04s-0.14-2.98,1.52-2.85c1.65,0.14,2.43,0.1,3.12-0.82
        s3.22-1.93,3.4-3.4s0.46-2.71,1.28-2.85c0.83-0.14,1.06-0.92,1.88-0.92s1.38,0.23,1.65-0.46s0.92-0.96,1.84-0.83
        c0.92,0.14,2.53,1.15,2.43-0.18c-0.09-1.33-0.23-1.93,0.23-2.25C612.503,641.514,612.593,641.055,612.223,640.324z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M627.023,663.214c1.45-1.06,1.45-1.68,2.29-1.84
        c0.84-0.17,2.79-0.17,2.85-1.45s0.28-1.62,0.89-2.12s0.95-1.06,0.56-1.73s-0.56-0.11-1.67-1.9c-1.12-1.78-1.45-2.68-1.45-3.96
        c0-0.71-0.13-1.15-0.18-1.58c-1.19-0.19-1.02-2.36-2.42-2.53c-1.47-0.18-1.84-2.3-2.94-2.76s-0.37-1.47-2.57-1.66
        c-2.21-0.18-1.56-1.65-3.03-1.65s-2.66-0.37-2.66-0.37l-1.55-0.12c0,0-0.96,0-1.57,0.04s-1.16-0.56-0.8,0.36s0.4,1.41,0.12,1.77
        c-0.28,0.36-0.52,0.8-0.52,1.45s0.16,0.96,0.04,1.48c-0.12,0.52-0.6,0.92-0.16,1.12s1.49,0.12,1.85,0.89s1.73,2.33,2.49,2.77
        s1.89,0.56,1.89,1.61c0,1.04-0.68,1.89,0.36,1.89s0.16-1.45,1.16-1.53s1.05-0.52,1.69-0.52s1.33-0.24,1.41,0.12
        s1.28,2.21-0.44,2.01s-1.97-0.04-1.97,0.72s0.8,1.57-0.36,1.57s-0.8,0.64-1.57,0.8c-0.76,0.16-0.24,1.25,0.2,1.57
        s0.6,0.72,0.6,1.53c0,0.8-0.08,1.37,0.6,1.73s0.84,1.57,1.81,2.53c0.81,0.81,2.22,1.45,3.09,2.39
        C625.483,664.964,626.183,663.824,627.023,663.214z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M579.893,663.444c0,0,0.15-0.98-0.49-1.28
        c-0.64-0.3-0.53-1.28-1.01-0.98s-0.98,1.28-1.43,1.43s-0.08,0.6,0.79,1.09c0.86,0.49,0.49,0.56,1.05,1.2s1.46,0.98,1.46,0.49
        S579.703,664.264,579.893,663.444z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M613.723,652.435c0,0,0.38-0.56,0-0.68
        c-0.38-0.11-1.35,0.11-1.05,0.75C612.973,653.154,613.273,653.034,613.723,652.435z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_11_" x="560.583" y="644.574" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="71.09" height="24.99" pointer-events="auto" visibility="hidden"></rect>
    <circle fill="#1e8346" stroke="#95bec8" stroke-miterlimit="10" cx="601.628" cy="649.374" r="27.279" pointer-events="auto" opacity="0.001" class="clickarea landarea" stroke-width="0.5"></circle>
</g>
<g id="AREA_COSTARICA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Costa Rica" data-wikipediaLink="https://en.wikipedia.org/wiki/Costa_Rica" data-infoText="<b>Costa Rica</b> &amp;nbsp; Capital: San José" data-imageFile="COSTARIC.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M565.283,650.154
        c0.29-0.73,0.15-1.38-0.22-2.33s0.87-1.17,1.53-2.48c0.66-1.31-0.36-1.24-1.24-2.18s-0.15-2.11-0.51-3.06s0.36-1.82,2.18-1.97
        c0.65-0.05,1.11-0.37,1.45-0.74c-1.55-0.72-2.02-1.05-2.46-2.17c-0.46-1.19-2.02-2.8-3.03-3.31s-1.33-1.24-1.88-2.48
        s-1.24-1.38-1.79-3.03s-1.1-1.51-1.79-3.03s0.14-2.34-1.1-3.12c-0.25-0.16-0.45-0.31-0.64-0.47c-0.35,0.8-0.01,1.82-0.12,2.43
        c-0.15,0.8-0.65,0.6-1.7,0.95c-1.05,0.35-1.75,0.4-2.8-0.1s-2.05-2-2.75-2.75s-2.66-1.05-3.56-1.15c-0.42-0.05-0.9-0.15-1.37-0.32
        c-0.12,0.84-0.66,0.65-0.47,1.68c0.22,1.2-0.79,0.75-1.13,0.75s-1.16-0.68-1.09-1.58s-0.08-2.22-0.6-2.1s-0.15,0.49-0.49,1.58
        c-0.34,1.09-0.49,1.09-1.54,0.08s-1.73-0.98-2.97-1.58c-0.66-0.32-1.09-0.75-1.41-1.22c-0.47,0.29-0.9,0.68-1.26,1.07
        c-0.16,0.17-0.38,0.31-0.61,0.43c0.25,0.34,0.38,0.63,0.23,0.81c-0.34,0.41-1.05,0.53-1.05,1.35s-0.11,1.01,0.38,1.43
        c0.49,0.41,0.79,1.05,0.79,1.39s0.41,0.6-0.11,1.01s0.28,1.24-0.58,1.69c-0.85,0.45-0.8,1-0.75,1.8s-1.05,1.45-0.1,2.55
        s1.65,1.5,2.5,2.6s2.55,0.25,3.3,1.5s1,1.45,2.1,1.45s2.05,0.1,2.5-0.35c0.45-0.45,1-0.4,0.5-1.2s-0.55-1-1.15-1.25
        c-0.6-0.25-1.65-0.75-1.3-1.4c0.35-0.65,1.05-0.7,1.4-0.3s0.6,0.95,1.2,0.95s2.05,0.45,2.4,1.4c0.35,0.95,0.9,1.8,0.8,2.5
        c-0.1,0.7,0.35,1.35,1.3,1.6s2.6,0.35,3.4,1.35c0.8,1,2.3,0.9,2.8,1.65s1.3,1.4,1.85,1.8c0.55,0.4,1.65,0.3,1.75,1.4
        s0.3,2.9,0.1,3.5s-1.95,1.7-1.1,2.35s2,0.75,2.45,1.45s1.25,1.05,2.1,1.15s1.2-0.4,1.1-0.9c-0.1-0.5-0.6-1.65,0.35-1.65
        s0.8,0.45,1.55,0.95s0.95,3.3,2.6,3.35c0.1,0,0.17-0.01,0.25-0.01C565.513,652.164,565.043,650.755,565.283,650.154z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_10_" x="510.663" y="619.584" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="71.09" height="24.99" pointer-events="auto" visibility="hidden"></rect>
    <circle fill="#1e8346" stroke="#95bec8" stroke-miterlimit="10" cx="546.462" cy="642.584" r="21.779" pointer-events="auto" opacity="0.001" class="clickarea landarea" stroke-width="0.5"></circle>
</g>
<g id="AREA_ELSALVADOR" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="El Salvador" data-wikipediaLink="https://en.wikipedia.org/wiki/El_Salvador" data-infoText="<b>El Salvador</b> &amp;nbsp; Capital: San Salvador" data-imageFile="ELSALV.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M508.253,588.674
        c-0.05-0.95,0.7-2,1.45-2.5s0.65-1.6-0.35-2.35s-1,0.15-2.1,0.25s-1.45-2.05-2.25-2.05s-0.95,0.45-1.65,1.55
        c-0.7,1.1-2.25,0.15-2.5-0.85s-0.9-0.85-2.3-1.45c-1.4-0.6-1.7-1.3-2.4-2.3s-1.75-1.45-3-1.8c-0.66-0.18-1.3-0.89-1.77-1.51
        c-0.45,0.18-0.89,0.31-1.24,0.41c-1.1,0.3-1.4,0.6-1.85,1.45s-1.15,1.95-1.65,2.65s-1.85,1.25-3.61,1.8
        c-0.98,0.31-1.79,1.06-2.32,1.67c1.42,1.23,2.75,1.77,3.91,2.66c1.17,0.9,1.45-0.27,3.07,0.64s1.71,1.17,2.89,1.17
        s2.7,0.81,3.16,1.35s1.17,2.16,2.17,1.98c0.99-0.18,1.44-0.81,2.8-0.81s1.8,1.26,3.43,1.44c1.62,0.18,0.63,0.81,2.34,0.72
        s2.17,0.09,3.16-0.72c0.57-0.47,0.85-0.87,1.31-1.14C508.793,590.024,508.293,589.404,508.253,588.674z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_8_" x="447.123" y="580.305" opacity="0" fill="#FFFFFF" stroke="#000000" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="71.09" height="24.99" pointer-events="auto" visibility="hidden"></rect>

        <ellipse transform="matrix(0.6 -0.8 0.8 0.6 -283.9528 633.4188)" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10.0007" cx="491.43" cy="600.657" rx="21.779" ry="21.779" pointer-events="auto" opacity="0.001" class="clickarea landarea"></ellipse>
</g>
<g id="AREA_NICARAGUA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Nicaragua" data-wikipediaLink="https://en.wikipedia.org/wiki/Nicaragua" data-infoText="<b>Nicaragua</b> &amp;nbsp; Capital: Managua" data-imageFile="NICAGUA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M533.793,616.444
        c-0.28-0.41-0.46-0.85-0.59-1.26c-0.26-0.86-0.53-1.24-1.46-1.77c-0.94-0.53-0.49-1.54-0.68-2.74s-1.39-1.01-1.35-2.55
        c0.04-1.54,2.37-2.22,3.42-1.91c1.05,0.3,1.09,1.13,2.07,1.39s1.2,1.05,1.2,1.05c0.22,0.75,0.49,1.13,1.28,1.88
        c0.79,0.75,0.6,0.9,1.84,1.54c1.24,0.64,1.43,2.03,2.33,2.93s1.58,1.8,1.65,3.3c0.01,0.22,0,0.39-0.02,0.54
        c0.47,0.18,0.95,0.28,1.37,0.32c0.9,0.1,2.85,0.4,3.56,1.15c0.7,0.75,1.7,2.25,2.75,2.75s1.75,0.45,2.8,0.1
        c1.05-0.35,1.55-0.15,1.7-0.95c0.11-0.61-0.23-1.62,0.12-2.43c-0.74-0.6-1.04-1.21-1.16-2.2c-0.14-1.24-0.32-1.74,0.55-2.71
        c0.87-0.96,1.47-0.55,1.52-2.16c0.05-1.61,0.23-3.08-0.28-3.72c-0.51-0.64-0.6-1.52,0-2.48c0.6-0.97,0-1.75,1.1-2.57
        s1.52-1.56,1.52-2.25s-0.92-1.06-0.78-2.43c0.14-1.38,0.87-2.25,0.92-4.13s-1.01-6.38,0.46-8.27c1.47-1.88,1.74-3.26,2.71-4.96
        c0.96-1.7,1.7-2.39,1.01-3.9c-0.69-1.52-1.65-2.66-1.42-3.99c0.11-0.62,0.28-1.39,0.38-2.09c-1.28-0.07-1.19-0.3-2.05-0.3
        c-1.01,0-1.13,0-1.95,0.69s-0.76,1.2-2.08,1.83s-3.15,0-3.97-0.13c-0.82-0.13-1.39,0.32-1.89,1.32s-1.51,1.45-3.46,0.88
        s-1.64-2.52-2.71-3.09s-1.45,0.44-1.83,1.32s-0.63,1.39-1.39,1.76c-0.76,0.38-0.76,1.51-0.88,2.2c-0.13,0.69-0.5,1.13-1.01,1.13
        c-0.5,0-1.2,0.88-2.65,1.58c-1.45,0.69-1.01,1.7-2.27,1.95s-0.25,2.27-1.13,2.46s-1.38-0.19-1.7-1.26s-1.39-1.01-2.14-1.64
        c-0.76-0.63-1.13,0-1.64,1.2c-0.5,1.2-2.46,1.45-4.22,1.32s-2.02,0.57-2.33,1.89c-0.32,1.32,0.06,1.7,0.69,2.2s0.76,1.26-0.19,2.02
        s-1.51-0.44-2.71,0.19s0.19,1.51,0.06,2.58s-1.32,0.94-2.65,0.94s-1.39,0.19-1.7-0.63c-0.1-0.26-0.27-0.41-0.46-0.52
        c-0.03,0.07-0.09,0.14-0.18,0.21c-0.45,0.36-1.08,0.72-2.08,0.09s-1.44-0.36-1.35,0.27s-0.18,0.81,0.72,1.53
        c0.9,0.72,1.17,0.9,1.62,2.07s2.52,2.43,3.16,3.24c0.63,0.81,4.42,3.16,4.51,4.42s2.33,3.61,3.25,4.51
        c0.31,0.3,0.46,0.64,1.43,1.42c0.98,0.79,1.54,1.95,2.44,2.52c0.9,0.56,2.07,1.46,3,2.4c0.51,0.51,1.01,0.99,1.31,1.4
        c0.23-0.11,0.44-0.25,0.61-0.43C532.903,617.125,533.323,616.734,533.793,616.444z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_7_" x="528.093" y="588.204" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="71.09" height="24.99" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_HONDURAS" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Honduras" data-wikipediaLink="https://en.wikipedia.org/wiki/Honduras" data-infoText="<b>Honduras</b> &amp;nbsp; Capital: Tegucigalpa" data-imageFile="HONDURAS.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M514.553,594.365
        c0.31,0.82,0.38,0.63,1.7,0.63s2.52,0.13,2.65-0.94s-1.26-1.95-0.06-2.58s1.76,0.57,2.71-0.19s0.82-1.51,0.19-2.02
        c-0.63-0.5-1.01-0.88-0.69-2.2s0.57-2.01,2.33-1.89c1.76,0.13,3.72-0.13,4.22-1.32c0.5-1.2,0.88-1.82,1.64-1.2
        c0.76,0.63,1.83,0.57,2.14,1.64c0.32,1.07,0.82,1.45,1.7,1.26s-0.13-2.2,1.13-2.46c1.26-0.25,0.82-1.26,2.27-1.95
        s2.14-1.58,2.65-1.58c0.5,0,0.88-0.44,1.01-1.13s0.13-1.83,0.88-2.2c0.76-0.38,1.01-0.88,1.39-1.76s0.76-1.89,1.83-1.32
        s0.76,2.52,2.71,3.09s2.96,0.12,3.46-0.88s1.07-1.45,1.89-1.32s2.65,0.76,3.97,0.13s1.26-1.13,2.08-1.83s0.94-0.69,1.95-0.69
        c0.86,0,0.77,0.23,2.05,0.3c0.11-0.8,0.12-1.52-0.2-1.82c-0.6-0.55-0.78-1.24-1.7-1.33s-2.35-2.39-3.35-2.39s-1.3-1.4-2.7-2
        s-1.8-3.41-3.21-3.41s-1.6-0.2-3-0.9s-2.7-0.9-3.91-0.9s-2.2-0.3-2.8,0.4s-1.7,0.9-4.01,0.5s-6.01-1-7.31-0.7s-1.2,2.2-3.8,2.4
        s-4.01-0.6-5.81-0.6s-1.8-1.6-3.71-1.5c-1.9,0.1-2.5,0.9-4.01,0.1c-1.51-0.8-2.5-1.2-3.41-1c-0.9,0.2-1,0.5-1.95,1.2
        c-0.92,0.68-1.66,0.97-2.61,0.76c-0.61,1.16-1.15,1.26-2.09,2.49c-1.15,1.5-2.2,1.85-3.51,2.4c-1.3,0.55-1.7,1.95-4.11,2.35
        c-2.4,0.4-1.55,3.25-1.65,5.21c-0.07,1.35-1.11,2.04-2.12,2.45c0.46,0.62,1.11,1.32,1.77,1.51c1.25,0.35,2.3,0.8,3,1.8
        s1,1.7,2.4,2.3s2.05,0.45,2.3,1.45s1.8,1.95,2.5,0.85c0.7-1.1,0.85-1.55,1.65-1.55s1.15,2.15,2.25,2.05c1.1-0.1,1.1-1,2.1-0.25
        s1.1,1.85,0.35,2.35s-1.5,1.55-1.45,2.5c0.04,0.73,0.54,1.35,0.66,2.26c0.33-0.19,0.76-0.31,1.48-0.31c1.71,0,2.16,0.18,2.16,0.54
        s-0.9,1.08,0.36,1.71c0.99,0.49,1.3,0.72,1.17,0.96C514.283,593.954,514.453,594.104,514.553,594.365z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_6_" x="513.873" y="559.924" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="71.09" height="24.99" pointer-events="auto" visibility="hidden"></rect>

        <rect id="FLAG_1_" x="511.949" y="561.809" opacity="0" fill="#0A11FF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="25.1" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_BELIZE" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Belize" data-wikipediaLink="https://en.wikipedia.org/wiki/Belize" data-infoText="<b>Belize</b> &amp;nbsp; Capital: Belmopan" data-imageFile="BELIZE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M495.093,543.734
        c-0.35,1.69-0.47,3.04-0.47,3.79s-0.06,2.63-0.23,4.09s-0.17,2.04-0.47,3.03c-0.29,0.99-0.7,1.75-0.64,2.92s0.58,1.46,1.69,1.58
        c0.99,0.1,2.02,0.48,3.23-0.6c-0.11-0.05-0.22-0.09-0.36-0.11c-1.35-0.2-0.75-1.4,0.75-2.15s3.45-1.85,4.36-3.26
        c0.9-1.4,2.05-1.7,2.3-3.25s0.05-2.35,0.6-2.95c0.55-0.6,1.45-1.45,0.7-1.75s-1.3-1.4-1.2-2.9c0.1-1.5,0.95-1.3,1.45-2.35
        s-0.1-1.2-0.1-2.5s-0.25-1.6,0.6-2.35s0-2.55,1.75-2.6c0.88-0.03,1.59-0.18,2.09-0.58c-0.52-0.59-0.77-1.25-1.55-1.78
        c-1.16-0.79-1.24-1.24-2.07-2.33c-0.83-1.09-1.83-0.81-2.83-0.81s-1.55,1.75-2.4,2.5s-1.1,1.5-1.65,3.15s-2.75,0.75-3.86,0.7
        c-1.1-0.05-0.75,0.25-1.3,1.25c-0.07,0.12-0.18,0.22-0.34,0.3c0.28,0.48,0.56,1.2,0.58,2.28
        C495.793,539.304,495.443,542.044,495.093,543.734z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_5_" x="487.503" y="532.944" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="71.09" height="24.99" pointer-events="auto" visibility="hidden"></rect>
    <circle fill="#1e8346" stroke="#95bec8" stroke-miterlimit="10" cx="511.143" cy="545.404" r="17.112" pointer-events="auto" opacity="0.001" class="clickarea landarea" stroke-width="0.5"></circle>

        <rect id="FLAG_6_" x="501.323" y="525.018" opacity="0" fill="#0A11FF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="25.1" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_GUATEMALA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Guatemala" data-wikipediaLink="https://en.wikipedia.org/wiki/Guatemala" data-infoText="<b>Guatemala</b> &amp;nbsp; Capital: Guatemala City" data-imageFile="GUATEMALA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M483.023,581.964
        c1.75-0.55,3.1-1.1,3.61-1.8c0.5-0.7,1.2-1.8,1.65-2.65c0.45-0.85,0.75-1.15,1.85-1.45s3.25-0.9,3.35-2.85s-0.75-4.81,1.65-5.21
        s2.8-1.8,4.11-2.35c1.3-0.55,2.35-0.9,3.51-2.4c0.95-1.23,1.49-1.34,2.09-2.49c-0.03-0.01-0.06,0-0.09-0.01
        c-1-0.25-1.45-2.1-2.55-2.1c-1.1,0-0.65,1.95-2,1.75c-1.21-0.18-1.07-1.43-1.99-1.84c-1.2,1.08-2.24,0.7-3.23,0.6
        c-1.11-0.12-1.63-0.41-1.69-1.58c-0.06-1.17,0.35-1.93,0.64-2.92s0.29-1.58,0.47-3.03c0.17-1.46,0.23-3.33,0.23-4.08
        s0.12-2.1,0.47-3.79s0.7-4.43,0.64-6.71c-0.03-1.08-0.3-1.8-0.58-2.28c-1.1,0.54-4.16,0.01-6.27,0.05c-2.4,0.05-4.66,0-5.66-0.2
        s-1.05-1.1-2.25-1s-3.3-0.25-5.06,0s-2.05,2.7-1.6,5.26c0.45,2.55-1,1.6-3.2,1.6s-1.8,0.95-0.5,1.7s2.1,2.8,3.61,3.45
        c1.5,0.65,1.7,1.4,2.85,2.55c1.15,1.15,2.15,1.35,3.11,3.15c0.95,1.8-0.5,2.43-1.03,3.48s-1.13,0.83-2.25,0.75
        c-1.13-0.08-2.85-0.22-4.28-0.3s-2.78-0.38-4.58-0.75c-1.8-0.38-1.73,0.38-2.78,1.13s-2.85,2.63-3.45,3.68s-2.33,3-3,4.28
        s1.05,1.8,1.05,2.63s0.38,1.35-0.68,1.88s-0.53,1.95-0.53,3.3c0,0.62-0.26,1.16-0.53,1.58c0.09,0.07,0.17,0.17,0.26,0.24
        c1.62,1.26,1.8,2.43,3.25,3.88c1.44,1.44-0.09,2.71,2.89,3.7s3.16,1.17,5.5,0.72c2.35-0.45,2.98-1.26,3.88-0.27
        s1.26,0.99,3.07,0.99c1.8,0,2.25,0.09,3.7,1.35c0.02,0.02,0.04,0.03,0.06,0.04C481.233,583.024,482.043,582.274,483.023,581.964z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_4_" x="418.003" y="553.444" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="71.09" height="24.99" pointer-events="auto" visibility="hidden"></rect>

        <rect id="FLAG_2_" x="462.664" y="558.789" opacity="0" fill="#0A11FF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="25.1" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_MEXICO" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Mexico" data-wikipediaLink="https://en.wikipedia.org/wiki/Mexico" data-infoText="<b>Mexico</b> &amp;nbsp; Capital: Mexico City" data-imageFile="MEXICO.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M459.163,568.094
        c1.05-0.53,0.68-1.05,0.68-1.88s-1.73-1.35-1.05-2.63c0.68-1.28,2.4-3.23,3-4.28c0.6-1.05,2.4-2.93,3.45-3.68
        c1.05-0.75,0.98-1.5,2.78-1.13c1.8,0.38,3.15,0.68,4.58,0.75c1.43,0.08,3.15,0.22,4.28,0.3s1.73,0.3,2.25-0.75
        c0.53-1.05,1.98-1.68,1.03-3.48s-1.95-2-3.11-3.15c-1.15-1.15-1.35-1.9-2.85-2.55c-1.5-0.65-2.3-2.7-3.61-3.45
        c-1.3-0.75-1.7-1.7,0.5-1.7s3.65,0.95,3.2-1.6c-0.45-2.55-0.15-5.01,1.6-5.26s3.86,0.1,5.06,0s1.25,0.8,2.25,1s3.26,0.25,5.66,0.2
        s6.06,0.65,6.61-0.35s0.2-1.3,1.3-1.25c1.1,0.05,3.3,0.95,3.86-0.7c0.55-1.65,0.8-2.4,1.65-3.15s1.4-2.5,2.4-2.5s2-0.28,2.83,0.81
        s0.9,1.54,2.07,2.33c0.79,0.53,1.03,1.2,1.55,1.78c0.5-0.41,0.79-1.07,0.81-2.12c0.05-2.1,0.85-3.2,1.3-4.36
        c0.45-1.15,0.75-1.15,0.85-2.65s1.4-0.85,1.4-2.5s-1.1-0.85-1.15-2.2c-0.05-1.35,0.7-0.75,0.9-1.65s0.15-1.45-0.6-1.8
        s-0.3-1.55,0.85-2.2c1.15-0.65,1.06-0.69,1.06-2.04s-0.68-2.07,0.22-3.15s2.52-1.77,2.97-3c0.45-1.24,0.75-1.8,1.77-2.63
        c1.01-0.83,2.22-1.58,2.74-2.37c0.53-0.79,0.04-2.4,0.04-3.72c0-1.31,0.22-1.24-0.45-1.8c-0.68-0.56-0.56-0.86-0.94-1.84
        s-1.09-1.39-1.65-1.09c-0.56,0.3-1.13,1.35-2.52,1.35s-1.91,0.19-2.85-0.45s-1.39-0.94-2.48-0.9c-1.09,0.04-1.24,0.23-1.95-0.26
        c-0.71-0.49-1.01-1.05-2.63-1.05s-3.83-0.19-4.66,0.71c-0.83,0.9-1.28,0.38-1.92,1.09s-1.43,0.9-2.74,0.98
        c-1.31,0.08-3.39-0.2-5.39-0.2s-2.3,0-2.8,0.35s-1.1,1.15-2.4,1.3s-2.35,0.45-3.05,0.65c-0.7,0.2-1.55,1.05-1.7,1.9
        c-0.15,0.85,0.2,1.85-0.5,2.4s-1,1.15-1,1.75s0.4,2.2-0.05,3.2s-1.05,1.2-0.75,1.95s1.1,1.35,0.6,2.1s-0.75,1.85-1.9,2.45
        s-1.35,1.2-1.35,1.8c0,0.6,0,3-0.7,3.55c-0.7,0.55-3.51,2.95-5.36,3.76c-1.85,0.81-2.35,0.7-2.7,1.45s-0.25,0.85,0.3,1
        s1.4-0.05,1.3,1.05s0.15,1.95-0.75,1.95s-0.5,1.05-1.75,1.05s-3.6,0.6-4.26,0s-0.55-1.55,0.55-1.6s0.65-0.45,1.7-0.8
        c1.05-0.35,1.15-0.8,1.15-1.25s-0.5-0.5-1.15-0.1s-1.45,1-2.2,1s-1.05,0-1.6-0.5s-0.05-0.55-2.2-0.7c-2.15-0.15-3.2-0.05-3.96,0.6
        s-1.25,2.05-3.1,2.05s-2.8,0.2-4.61,0c-1.8-0.2-4.21-0.65-4.76-0.2c-0.55,0.45-0.4,1.6-2,1.6s-1.3,1.15-3.2,1.15
        c-1.9,0-5.21,0.3-5.76-0.8s-0.85-3.4-2.45-3.9s-1.7-2.6-4.21-2.75c-2.51-0.15-2.95-0.75-4.05-0.95c-1.1-0.2-1.95-0.05-2.4,0.45
        c-0.45,0.5-0.35,1.75-1.4,2.15s-1-1.2-0.65-1.9s1.75-0.6,1.15-1.95c-0.6-1.35-0.85-1.35-1.55-2.25c-0.7-0.9-0.25-2.85-1.4-3.3
        s-1.55-1.2-1.55-2.9s0.85-3.3-0.5-4.66c-1.35-1.35-2.1-3.05-3-4.71c-0.9-1.65-3.1-3.76-3.45-5.11c-0.35-1.35-0.9-2.7-1.65-4.11
        s-1.1-4.61-0.55-5.21c0.55-0.6,0.85-1.75,0.45-2.75s-2.2,0.05-2.25-1.95s0.2-2-0.3-2.95s-1.2-2.3-1.2-4.01
        c0-1.7-0.4-4.05,0.55-5.26s1.65-1.8,1.7-3.45c0.05-1.65,0.25-4.8,0.45-7.11c0.2-2.3,0.75-2.45,1.25-5.26
        c0.5-2.8,0.65-3.51,1.3-5.96c0.65-2.45,1.8-6.51,3.41-8.16c1.6-1.65,2.25-2.86,2.95-3.86s2.15-3.15,1.6-4.06
        c-0.06-0.1-0.14-0.23-0.21-0.37c-1.14,0.47-1.88,0.42-2.91,0.48c-1.26,0.07-1.96-0.91-3.01-2.03s-2.17-1.05-3.78-1.05
        s-2.24-0.7-3.29-1.47c-1.05-0.77-4.06-2.24-6.16-3.36s-1.96-2.87-1.96-4.41s-0.28-2.52-1.19-3.5s-0.7-1.68-0.42-2.73
        c0.28-1.05,0.14-1.54,0.14-2.94s-0.98-2.03-1.89-2.8s-0.63-1.68-1.26-3.43s-2.24-3.78-3.22-4.62s-0.91-1.96-0.7-3.29
        c0.21-1.33-0.49-2.03-1.12-2.94s-0.91-2.24-1.19-4.06s-1.75-3.85-2.8-4.69s-2.1-2.66-2.87-3.99s-1.89-1.26-3.29-1.26
        s-1.89-1.05-3.64-1.05s-3.85,0.07-5.18,0.14s-2.17,0.91-3.15,3.29c-0.98,2.38-2.59,1.89-3.85,4.27s-3.29,0-4.83-1.33
        s-3.29-2.66-5.46-4.83c-2.17-2.17-3.29-3.85-3.29-5.67s-0.28-4.69-0.42-6.58s-2.03-4.76-3.43-6.23s-3.22-4.41-5.74-7.14
        s-2.73-3.57-2.87-5.25s-2.73-1.96-3.92-2.03s-2.24-0.42-3.5-0.63s-3.64-0.77-5.18-0.84c-1.54-0.07-3.15-0.21-4.41-0.77
        s-1.82,0.35-2.24,3.5c-0.42,3.15-4.97,1.05-7.28,0.42s-5.11-1.33-7.35-1.61c-2.24-0.28-5.74-1.05-9.66-1.89
        c-3.92-0.84-4.41-0.98-8.96-4.41s-7.49-4.48-12.46-7.98s-10.43-8.4-13.23-9.8s-1.33-1.47-0.07-2.73s-3.71-1.4-5.53-1.47
        c-1.82-0.07-2.24,0-5.39-0.49s-7.42-1.05-10.43-1.4c-1.04-0.12-1.77-0.22-2.29-0.29c-0.34,0.98-0.5,2.22-0.29,2.74
        c0.25,0.6,1.1,0.95,1.2,2.8c0.1,1.85,0,1.85,0.55,2.55c0.55,0.7,0.95,0.6,0.75,1.55c-0.2,0.95-0.2,1.6-0.85,1.9
        c-0.65,0.3-0.75,1.2-0.7,2.1s1.2,0.75,1.3,2.15s-0.5,3.46-0.25,4.76c0.25,1.3,1.85,1.35,1.55,2.85s-0.65,4.11-0.25,4.96
        s1.05,1.45,1.05,2.1s-0.6,1.3-0.65,2.55s0.55,2.3,0.5,3.3s0.25,2.65,1.4,3.95c1.15,1.3,2.41,1.15,4,3.22s1.99,2.39,2.31,4.69
        c0.32,2.31,0.32,2.63,1.19,3.66c0.88,1.03,0.48,1.99,1.11,2.46c0.64,0.48,0.64,1.11,0.8,2.23c0.16,1.11,0.4,2.47-0.24,2.86
        c-0.64,0.4-1.83,1.03-1.83,2.07s1.12,1.19,1.51,2.07c0.4,0.88,0.71,1.27,0.48,1.83c-0.24,0.56-1.11,1.99-1.51,1.51
        c-0.4-0.48,1.11-2.31-0.48-2.31s-1.75,0.24-2.7-0.95c-0.96-1.19-2.07,0.71-3.5-0.48s-2.31-1.75-2.94-1.75
        c-0.64,0-0.4,0.72-0.32,1.59c0.08,0.87,1.75,1.11,1.91,2.62s1.59,1.75,1.59,3.18s0.16,1.91,1.11,2.7s5.73,3.66,5.73,5.33
        c0,1.67,0.32,2.47,1.35,2.47s0.87-1.91,1.59-1.11c0.72,0.8,1.27,1.19,1.75,0.48c0.48-0.72,0.72-1.67,1.43-1.43
        c0.72,0.24,0.16,2.62,0,3.5s-0.16,1.75,1.59,3.42s4.06,2.46,3.98,3.66c-0.08,1.19,0.16,1.35,0.56,2.15s0.87,2.15,0.79,3.26
        c-0.08,1.11,0.56,2.86,0.08,4.13s-1.11,0.95-1.35,2.07c-0.24,1.11-0.24,1.83-0.88,2.46s-0.63,2.23-0.4,3.5
        c0.24,1.27,1.35,2.07,2.47,3.1c1.11,1.03,2.31,0.24,2.31,1.91s0.87,1.91,1.67,3.18c0.8,1.27,2.86,1.59,3.26,3.26
        c0.4,1.67,1.83,2.7,2.78,3.98s3.18,2.94,3.42,4.93c0.24,1.99,0.08,5.33,0.48,5.89c0.4,0.56,1.11,0.8,1.27,1.19
        c0.16,0.4,1.75,1.35,2.07,0.08s1.35-1.03,2.31-1.59c0.95-0.56,2.47-1.19,2.47-2.63c0-1.43,0-2.7-0.72-3.26s-0.56-1.91-1.11-2.39
        s-1.04-0.87-0.95-1.99c0.08-1.11,0.64-1.91-0.32-2.31c-0.95-0.4-2.3-3.02-3.26-2.94c-0.95,0.08-2.23,1.51-3.18,0.16
        c-0.95-1.35-1.19-0.56-1.19-1.67s-1.51-0.87-1.43-1.99s1.04-0.95,1.19-1.99c0.16-1.03,0.24-1.19,1.03-1.91s0.4-2.07-0.24-2.46
        c-0.64-0.4,0-2.15-0.87-2.86c-0.88-0.72-0.64-1.11-0.72-2.62s0.32-2.31-0.72-3.02s-0.87-3.74-0.95-6.28
        c-0.08-2.55,0.24-5.41-0.32-6.52s-0.8-2.7-1.35-3.02c-0.56-0.32-0.4,1.43-1.19,1.27c-0.8-0.16-0.48-1.11-1.27-1.99
        c-0.8-0.88-0.16-1.35,0.48-2.31c0.64-0.95,0.08-1.67-0.32-1.83c-0.4-0.16-2.07-1.43-1.51-3.02s0.08-1.75-0.79-2.86
        c-0.88-1.11-2.39-1.43-2.46-2.7c-0.08-1.27-0.4-1.99,0.08-3.18c0.48-1.19,1.27-1.59,1.19-3.02s-0.87-1.2-1.03-2.63
        s-0.96-0.71-1.19-2.54c-0.24-1.83-0.24-2.23-1.19-2.94s-1.43-2.31-1.75-3.66s0.16-3.1-0.8-4.37c-0.95-1.27-2.15-2.47-2.39-3.82
        s-0.88-2.15-1.83-3.02s-1.35-1.19-1.35-3.26s-0.4-4.13,0.16-5.01s0.8-1.35,0.8-3.58c0-2.23,0.08-4.38,0.24-6.36
        c0.16-1.99,0.24-1.83,1.03-2.94c0.8-1.11,1.04-0.8,1.04-2.39s-0.48-3.98,1.67-0.32s2.94,4.06,4.53,3.98
        c1.59-0.08,2.54-0.8,3.34,0.87c0.8,1.67,0.8,0.56,0.88,2.23c0.08,1.67,1.27,1.91,1.99,1.91s0.87-0.95,1.35,0.08
        s2.23,1.51,1.27,2.31c-0.95,0.79-1.51,0.95-1.51,1.91s-0.71,0.64-0.87,1.83c-0.16,1.19,0.71,1.43,0.87,1.99s0.24,1.19,0.24,3.26
        s-0.55,5.57-0.08,7.23c0.48,1.67,1.19,2.23,1.35,3.5s-0.72,2.23,0.16,2.86c0.88,0.64,1.03,1.99,0.08,2.07s-1.35,0.87-1.75,1.59
        s-1.11,1.27-0.8,2.07c0.32,0.79,1.67,1.51,1.75,0.64c0.08-0.88,0.56-0.95,1.43-0.95c0.88,0,1.43,0.95,1.43,1.59
        c0,0.64,0.72,0.71,1.75,3.34s1.83,2.94,2.31,4.45s0.55,3.26,2.94,4.53s3.26,1.35,3.34,3.9s-0.16,3.89,0.64,4.93
        c0.8,1.04,1.11,2.39,2.31,3.34c1.19,0.95,2.86-0.08,2.86,1.75s0.08,4.69,1.83,4.85s1.67,0.24,2.31,1.51
        c0.64,1.27,0.4,1.91,1.03,2.31s1.99,1.59,0.24,1.91s-1.91,0.8-2.15,1.75s-1.03-0.24-0.87,1.35s-0.08,4.21,0.79,4.61
        c0.88,0.4,0.87,2.55,2.78,2.78c1.91,0.24,1.99,0.08,2.39,1.27c0.4,1.19,1.59,1.91,3.42,2.86c1.83,0.95,2.79,1.83,2.55,3.02
        c-0.24,1.19-0.56,1.43-0.56,2.39c0,0.95,0.63,3.5,2.86,5.72c2.23,2.23,4.14,3.66,4.93,5.41s2.47,3.18,3.18,5.81
        c0.72,2.62,3.02,6.12,3.98,7.55c0.95,1.43,4.45,7.24,4.53,8.83s0.56,2.55-0.24,3.58s-0.16,1.75,0.16,2.7s0.32,1.83,1.27,2.78
        s0,1.67,0.95,2.23s1.75,0.95,1.19,1.91c-0.56,0.95-0.72,1.03-0.8,2.15s0,2.63-1.51,2.86c-1.51,0.24,0.08,0.64-1.19,0.87
        c-1.27,0.24-1.35,1.51-0.72,1.51s1.99,0.4,2.07,1.27s-0.08,1.75-1.59,1.75s-2.79-0.16-3.18,0.56c-0.4,0.72-0.64,1.43-0.24,2.7
        c0.4,1.27,1.19,2.31,1.83,4.14c0.64,1.83,3.34,9.15,5.09,9.23c1.75,0.08,8.35,3.89,9.7,6.52c1.35,2.62,4.14,6.68,5.65,7.64
        c1.51,0.96,4.37,1.67,5.8,2.7s4.3,2.15,5.41,2.15c1.11,0,4.45-0.16,4.61,0.95c0.16,1.11,0.48,2.94,3.26,4.77s4.93,4.14,5.57,4.93
        c0.64,0.8,1.35,1.19,3.58,1.91c2.23,0.72,5.01,1.99,6.84,3.34c1.83,1.35,3.74,3.34,5.65,3.34s2.31,1.75,4.61,1.99
        c2.31,0.24,3.1-0.08,3.74,1.27c0.64,1.35,1.99,3.02,3.66,3.26s2.31,0.56,3.66,1.91s3.26,2.78,4.69,2.63
        c1.43-0.16,3.82,0.16,5.25,1.43s3.25,1.83,4.21,2.46c0.95,0.64,1.67,1.03,2.94,1.19s2.7,0.56,3.74-0.16s1.99-1.11,3.02-1.11
        s1.59-1.19,3.1-1.19s2.78,0.16,3.42-1.19s1.75-1.43,3.1-1.91s1.19-2.54,2.54-2.54s1.11,1.83,2.23,1.91
        c1.11,0.08,0.95,1.51,2.47,1.43s2.23-0.64,3.18,0.16s2.23-0.48,3.66,1.91c1.43,2.39,3.58,3.98,5.41,5.65
        c1.83,1.67,5.24,4.92,7.59,7.98c2.22,2.9,3.63,4.96,5.15,6.25c0.27-0.42,0.53-0.96,0.53-1.58
        C458.633,570.044,458.113,568.625,459.163,568.094z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M257.883,363.854c0,0,0.68-1.39-0.34-1.84
        c-1.01-0.45-1.09-0.34-1.35-1.13s-0.04-1.39-0.68-1.39c-0.64,0-0.49,0.64-0.45,1.43c0.04,0.79,0.04,2.59,0.53,3.08
        s1.05,1.13,1.28,1.61c0.23,0.49,0.79,1.43,1.2,1.01C258.483,366.214,257.393,365.274,257.883,363.854z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M207.903,352.964c0,0-1.31-0.6-1.39,0.94
        c-0.07,1.54-0.53,2.48,0.07,2.67s0.83,0.56,0.83-0.34s0.41-1.2,0.75-1.35s0.49-0.41,0.49-0.83
        C208.653,353.634,208.693,353.294,207.903,352.964z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M234.113,370.644c0,0,0.53,1.05-0.15,1.31s-1.76-0.15-1.91,0.64
        s-0.07,1.2,0.75,1.09s2.52,0.6,2.59-0.53s0.15-1.54,0.04-2.29C235.313,370.124,233.963,369.074,234.113,370.644z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M523.263,502.794c-0.08,0.08-0.15,0.9-0.98,1.2
        c-0.83,0.3-0.86,0.86-1.01,1.31s0.3,2.03,0.79,1.13s0.26-0.94,1.2-1.39s1.5-0.15,1.58-1.05
        C524.913,503.094,524.613,501.324,523.263,502.794z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_3_" x="319.343" y="443.834" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="71.09" height="24.99" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_THEUNITEDSTATES" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="United States" data-wikipediaLink="https://en.wikipedia.org/wiki/United_States" data-infoText="<b>United States</b> &amp;nbsp; Capital: Washington, D.C." data-imageFile="USA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M85.643-119.566c0,0-1.35-1.07-1.97,0
        s-2.42,0.17-1.97,1.13s1.35,0.34,1.86,0.85s0.96,1.01,2.87,0.96c1.92-0.06,3.04-0.34,4.23-0.28s1.97,0,2.31-0.85
        s0.28-1.63-0.73-1.69s-0.68-1.63-1.41-1.63s-0.39,1.8-2.2,1.63C86.823-119.616,86.883-119.166,85.643-119.566z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M98.433-116.256c0,0-0.75,1.13-1.16,1.62
        s-1.35,1.16-2.55,0.98c-1.2-0.19-1.76-1.09-2.67-1.35c-0.9-0.26-4.09-0.15-1.76,1.2s2.89,1.54,3.46,2.1
        c0.56,0.56,1.99,0.86,2.74,0.86s1.5-0.45,2.22-1.76c0.71-1.32,2.74,0.26,2.63-2.03s-0.98-2.93-1.76-2.93
        C98.773-117.566,98.963-117.076,98.433-116.256z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M106.613-112.336
        c-0.43-0.27-0.74-0.45-0.67-1.17c0.11-1.09-0.98-1.13-1.24-0.68c-0.26,0.45-1.92,1.31-0.22,2.25c1.69,0.94,2.03,1.54,2.7,1.28
        c0.81-0.31,0.17-1.15-0.27-1.48C106.813-112.206,106.713-112.276,106.613-112.336z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M114.883-111.296c0,0-1.39-0.53-1.76-0.04
        s-2.07,0.49-1.92,1.54s0.53,1.28,1.84,1.62s2.85,0.79,3.64,1.69s1.13,2.48,2.03,3.57s0.83,0.34,0.83-0.49s-0.3-1.8,0.68-2.03
        s1.69-0.45,2.07-0.94s0.45-1.05,0.07-1.99s0.26-1.5-1.05-2.22c-1.31-0.71-0.94-0.26-1.92-1.39c-0.98-1.13-1.2-1.24-1.54-0.49
        C117.513-111.706,116.803-110.696,114.883-111.296z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M185.993-163.166c0,0-1.17-0.42-1.36-1.1
        c-0.19-0.68-0.11-1.59-1.21-1.59s-1.36,0.68-1.44,1.32c-0.07,0.64-0.6,3.48-1.44,3.85c-0.83,0.38-1.85,1.17-1.17,2.04
        c0.68,0.87,1.25,1.47,2.08,1.55s0.46,0.64,1.32,1.1s0.79,1.59,1.62,1.1c0.83-0.49-0.45-1.81,0.64-2.15
        c1.1-0.34,0.95-1.29,1.93-1.47s1.25-0.64,1.13-1.89c-0.11-1.25,0.27-1.82-0.87-2.15
        C186.103-162.906,186.593-162.866,185.993-163.166z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M224.763-206.006c0,0-1.62,0.53-1.65,1.5
        c-0.04,0.98-1.05,1.16-0.34,1.95s1.5,0.64,1.58,1.24c0.07,0.6-0.19,1.65-0.86,2.07c-0.68,0.41-0.64,1.35-1.35,1.8
        s-0.34,1.05-1.09,1.54c-0.75,0.49-0.45,0.83-1.28,1.16c-0.83,0.34-0.71,0.64-0.71,1.24s0.6,0.75,1.62,0.64
        c1.01-0.11,1.39-0.64,1.58,0.49s0.26,1.73,1.09,1.65c0.83-0.07,0.49-1.16,1.28-1.43c0.79-0.26,1.24-0.38,1.24-1.31
        s0.07-2.18,0.11-2.7c0.04-0.53-0.3-2.1,0.6-2.37s1.54-0.26,1.73-0.86s0.34-0.23,0.34-1.16s0.07-2.03-0.41-2.14
        c-0.49-0.11-1.05-0.19-1.01-1.09s0.07-1.58,0.79-1.84s0.83-0.56,0.56-1.31c-0.26-0.75-0.98-1.13-1.24-0.41
        c-0.26,0.71-0.86,1.01-1.24,1.16C225.703-206.036,225.363-206.226,224.763-206.006z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M133.363-97.856c0,0-0.34-0.23-0.53,0.41
        s-1.31-0.07-1.35,0.71s-0.26,0.98,0.53,0.9s1.05,0.26,1.31-0.23c0.26-0.49,0.23-0.75,0.79-0.79s0.86,0.04,0.86-0.45
        C134.973-97.776,134.523-97.736,133.363-97.856z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M133.513-93.346c0,0-0.86-0.45-1.28,0.11
        c-0.41,0.56,0.04,0.86-0.86,0.98c-0.9,0.11-0.68,0.15-0.64,0.83s0.94,0.49,1.58,0.41s0.34-1.13,1.62-1.16
        c1.28-0.04,2.03,0.15,2.07-0.53c0.04-0.68-0.23-0.6-0.9-0.53C134.413-93.156,134.113-93.086,133.513-93.346z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M670.953,260.134c0,0-0.19,1.35-1.61,1.39
        c-1.43,0.04-2.03-0.07-2.74,0.04c-0.71,0.11-1.31,0.49-1.46,1.09s-0.41,0.79-0.9,0.79s-1.43-0.26-1.65,0.19
        c-0.22,0.45-0.49,1.5,0.56,1.62c1.05,0.11,2.74,0.23,3.27-0.34c0.53-0.56,1.2-1.43,2.14-1.35c0.94,0.07,1.31,0.86,1.91,0.07
        c0.6-0.79,1.35-1.01,2.4-1.01s2.29,0.34,2.44-0.49s1.39-1.05,1.88-1.05s0.23-0.64,1.39-0.71c1.16-0.08,1.13-0.9,1.88-0.94
        s1.5-0.34,1.35-0.83s-0.83-0.3-1.58-0.3s-0.86,0.83-1.61,0.83s-0.04-0.98-0.94-1.09s-1.16,0.45-1.8,0.68s-0.79,0.34-1.05,0.83
        s-0.34,0.68-1.43,0.49C672.303,259.834,671.253,259.344,670.953,260.134z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M698.063,255.024c0,0-1.28-0.41-1.35,0.15
        s-0.26,0.94,0.22,0.94c0.49,0,0.6-0.08,1.16,0c0.56,0.07,0.94,0.26,1.05-0.23C699.273,255.404,699.043,255.254,698.063,255.024z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M692.393,253.524c0,0-0.71,0.26-0.79,0.71
        c-0.08,0.45,0.41,0.45,0.86,0.45s0.83,0.11,0.83-0.34C693.293,253.894,693.223,253.264,692.393,253.524z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M648.553,323.174c0,0-0.15,0.64-0.11,1.21
        c0.04,0.57,0.16,1.78,0.11,2.16c-0.04,0.38-0.19,0.99-0.68,1.18s-0.34,0.83-1.06,0.99c-0.72,0.15-1.37,0.11-1.52,0.34
        s-0.91,0.8-1.25,0.84s-0.49,0.23-0.79,0.76s-0.76,1.02-0.42,1.25s0.72-0.76,1.29-0.84s1.33,0.04,1.63-0.57s0.34-0.95,1.03-1.02
        s2.27-0.19,2.43-0.95c0.15-0.76,0.08-1.75,0.46-2.09s1.1-0.42,0.72-0.91s-0.57-0.15-0.61-1.18c-0.04-1.02-0.04-1.52-0.23-1.97
        C649.343,321.924,648.933,321.694,648.553,323.174z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M267.323,21.434c1.1-1.6,1.9-0.2,3.25-1.05
        c1.35-0.85,0.6-2.55,1.3-3.65c0.7-1.1,1.55-3.41,3.55-4.01s0.8-1.95-0.3-2.85s-0.6-1.5-0.65-3.91c-0.05-2.4-1.8-3.6-3.1-4.51
        c-1.3-0.9,0.8-2.05,0.9-3.1s-0.05-2.4,1.1-3.6s1.6-5.21,2.25-6.51s1.2-3.85,1.3-5.86s2.15-5.81,2.9-7.76s-0.3-1.45-0.8-3.31
        c-0.5-1.85,1.15-1.95,1.5-3.56c0.35-1.61,0-1.65-1.5-3.06c-1.5-1.4,1.2-1.8,2.45-3.36s0.05-2.85-1.4-3.9
        c-1.45-1.05-2.3-0.4-3.9-0.05s-2.65,2.15-3.81,3.1c-1.15,0.95-2.1,0.25-3.61,0.3c-1.5,0.05-2.5,0.3-3.2-0.5s0.05-1.85,0.75-2.7
        s1.05-2.75,0.55-3.96s0.4-3.4,0.9-5.01s-0.2-2.35,0-3.36s1-1.8,2.8-3.31c1.8-1.5-0.9-1.9-2.25-2.25s-1.6-0.55-3.45-1.3
        s-2.25-2.4-1.2-3.61c9.71-12.52,91.32-71.81,101.65-79.29c-0.16-0.29-0.27-0.63-0.26-1.15c0.04-1.58-0.3-4.73,0.6-5.26
        s1.5-0.98,0.6-1.31c-0.9-0.34-0.41-0.15-0.9-1.16s-1.28-1.65-1.88-1.92c-0.6-0.26-1.54-0.83-2.33-1.2
        c-0.79-0.38-1.54-0.94-1.88-2.37s0.64-2.1-0.64-3.49s-2.52-2.82-2.59-4.62s0.53-5.97,0-6.38s-1.58,0.34-1.88-0.83
        c-0.3-1.16-0.98-1.65-1.5-1.91c-0.53-0.26-1.92-0.3-2.14-1.73c-0.23-1.43,1.01-1.31,0.98-2.89c-0.04-1.58,1.16-2.14,1.95-2.63
        s1.61-1.99,0.83-2.48c-0.79-0.49-1.69-1.69-2.21-1.76c-0.53-0.08-1.16-0.3-1.13-1.01c0.04-0.71,2.07-0.26,1.92-1.13
        c-0.15-0.86,0.9-0.86,0.75-1.8s-1.24-1.24-1.5-0.98c-0.26,0.26-1.01,0.6-1.69,0.6s-1.54,0.53-1.95-0.15s-1.28-1.31-0.94-1.54
        s0.79-0.56,1.69-0.26s1.5,0.41,2.1,0.3s2.37-0.19,2.33-1.05s0.11-1.2,0.11-2.03s0.08-1.46-1.16-1.46s-3.23,0-4.28,0.04
        s-2.07,0.3-3.38-1.05s-1.92-1.73-2.18-2.59c-0.26-0.86-0.19-1.16-1.01-1.54c-0.83-0.38-1.35-0.83-1.95-0.41
        c-0.6,0.41-1.28,2.03-2.07,2.18s-1.2,0.86-1.92,0.9c-0.71,0.04-1.01-0.15-0.9-0.9s1.61-0.41,1.88-1.05s0.08-1.24-0.75-1.24
        s-1.58-0.49-2.44-0.86c-0.86-0.38-1.88-0.86-2.4-1.69c-0.53-0.83-2.25-1.5-5.11-1.28s-5.11-0.26-6.12,0.41s0.38,1.5-1.5,1.73
        s-5.82,0.71-7.32-1.28s-2.29-1.73-2.59-2.82c-0.3-1.09-0.04-2.07-1.13-2.4c-1.09-0.34-0.38-0.79-1.5-0.98
        c-1.13-0.19-1.13,1.05-2.67,1.09s-4.39-0.04-4.77,1.01c-0.38,1.05-2.89,5.41-4.81,8.37c-1.92,2.97-4.13,4.84-5.26,5.18
        s-1.92,0.98-2.74,1.39c-0.83,0.41-1.43,2.29-1.2,3c0.23,0.71,0.53,1.35,1.2,1.84c0.68,0.49,1.13,1.05,1.05,1.54
        c-0.08,0.49-0.98,1.58-1.54,1.84s-0.94,0.98-1.24,0s0.3-2.37-0.41-2.37s-1.09,1.01-1.09,1.58s-0.38,1.24-1.01,1.43
        c-0.64,0.19-1.73,0.98-1.73,1.92s0.49,1.39,1.31,1.76c0.83,0.38,1.65,0.64,1.77,1.39s0.64,2.14-0.34,2.4s-2.55,0.86-2.59-0.38
        s0.19-1.95-0.45-1.99c-0.64-0.04-1.92-0.34-2.03,0.34s-0.75,1.76-1.13,1.01s-0.38-2.03-1.09-2.03s-1.65,1.16-2.18,1.01
        s-1.43-0.86-1.5-2.14s1.09-2.37,0.3-3.23s-1.31-0.94-0.9-1.99s1.58-1.8,2.82-1.84c1.24-0.04,1.84-0.9,2.82-1.01s1.2-0.15,1.28-0.79
        s-0.79-0.6-1.05-1.39c-0.26-0.79-0.9-1.84-1.69-1.84s-1.16,0-1.69-0.71s-1.13-1.54-2.03-1.39s-2.97,0.45-3.94-0.86
        c-0.98-1.31-0.83-1.54-1.8-1.65c-0.98-0.11-1.8-0.34-1.95-0.75c-0.15-0.41-1.95,0.34-2.37-0.19s-0.41-1.31-1.28-1.31
        s-1.62,0.19-1.88,0.79c-0.26,0.6-1.16,0.71-1.35,1.24s-0.34,1.01-0.41,1.76s-0.04,3.49-0.04,4.09c0,0.6,0.19,2.14-0.45,2.37
        s-0.75,0.23-0.75-1.09c0-1.31-0.94-0.26-1.28-1.28c-0.34-1.01-0.38-1.5-0.86-1.46s0.07,1.69-1.2,2.03
        c-1.28,0.34-1.05,1.88-2.67,2.29c-1.62,0.41-2.4,1.05-2.89,1.84s-0.26,1.73-0.45,2.7s-0.38,3.53,0.75,4.09s2.74,2.1,2.85,2.97
        c0.11,0.86,0.49,1.58,0.3,2.55c-0.19,0.98-1.09,2.63,0.38,2.74c1.46,0.11,3.76,0.15,4.05,0.9c0.3,0.75,0.71,1.77,1.62,1.65
        c0.91-0.12,1.84-0.68,2.25-0.04s1.31,1.16,0.34,1.65c-0.98,0.49-1.2,1.5-2.29,1.77s-1.31,0.3-1.99-0.3c-0.68-0.6-1.46-1.5-2.33-1.2
        c-0.86,0.3-0.98,2.55-2.37,3.42c-1.39,0.86-0.79,1.41-2.07,1.57c-1.29,0.16-2.01,1.21-2.49,1.69c-0.48,0.48-0.96,0.8-2.01,0.96
        c-1.05,0.16-3.29,0.32-3.94-0.56c-0.64-0.88-1.45-0.88-1.45-2.01s-0.48-1.61-1.04-2.09s-0.88,1.05-1.93,1.05
        c-1.04,0-3.42,0.65-3.92-0.4c-0.5-1.05-0.75-1.4-1.25-1.8s-0.75-1.25-0.2-1.4s1.3,0.2,1.45-0.25s1.3-0.2,1.35-0.85
        c0.05-0.65-1.1-0.6-1.15-1.4c-0.05-0.8-0.05-1.15-0.8-1.3c-0.75-0.15-2.1-0.2-2.25,0.15c-0.15,0.35-0.25,1.15-0.95,1.15
        s-1.3-0.9-2.05-0.9s-1.95,0.8-1.95,1.25s0.4,1.65-0.95,1.65c-1.35,0-1.85-0.2-1.95,0.25c-0.1,0.45,0.1,1.35-0.65,1.35
        s-0.9-0.65-0.8-1.45c0.1-0.8-0.3-1.5-0.95-1.45s-2.8,0.95-4.06,0.65c-1.25-0.3-2.2-0.25-2.4,0.15s-0.35,1.05-2.65,1.05
        c-2.3,0-3.86-0.15-4.11,0.2c-0.25,0.35-0.25,1.35-0.95,1.85s-1.3,0.55-1.65,1.1c-0.35,0.55-0.3,0.85-1.1,1.15
        c-0.8,0.3-1.8,1-1.4,1.7c0.4,0.7,1.95-0.65,2.75-0.6c0.8,0.05,1.75,0.05,2.75-0.1c1-0.15,2.3-0.35,2.3,0.2s-0.4,0.8-1.5,0.8
        s-3.15,0.05-3.4,0.4c-0.25,0.35-0.3,0.6-1.45,0.75s-2.2,1.7-2.96,2.05c-0.75,0.35,0.7,1.25-0.05,1.85s-0.4,1.3,0.25,1.85
        s1.4,1.1,1.25,1.9s-0.05,1.15-1,1.85c-0.95,0.7-1.05,2.3-1.6,1.35s-0.8-1.3-0.7-2.2s-0.35-1.5-0.1-2.4s0.6-1.2,0.6-1.9
        s-0.5-0.7-1.6-1s-0.75-1.05-2.35-1.05s-1.45,3.1-2.3,3.65c-0.85,0.55,0.1,2.7-1.45,3.26c-1.55,0.55-2.3,2.3-2.65,3.3
        s-2.45,1.25-2.35,2.1c0.1,0.85,1.5,2.9,2.7,3.2s2.05,0.35,2.85,0.75s2.55,0.5,2.75-0.15s2.05-1.1,3-1.3s0.75,0.85-0.65,1.1
        s-2.15,0.55-2.3,1.65c-0.15,1.1,0.35,1.9-1,2.2s-2.6,0.2-2.95,0.95c-0.35,0.75-1.4,2.6-2.4,2.9c-1,0.3-0.35,0.75-2.1,1.05
        s-2.55-0.2-3.1,0.9c-0.55,1.1-1.35,2.61-2.55,2.65s-1.1,1.1-2.75,1.15c-1.65,0.05-2.15,0-2.15,0.8s-1.2,1.9,0.95,2
        s2.75-1.1,3.5-0.15c0.75,0.95,1,1,1.9,1.15s1.5,0.95,1.4,2s-0.25,2.15,0.4,2.4s0.95,0.65,0.95,1.3s0.1,1.2-0.95,1.9
        s-1.15,1.65-1.85,2c-0.7,0.35-1.3,1.1-1.4,1.9c-0.1,0.8,0.3,1.35,1.25,1s1-1.45,1.9-1.65s1.45-2.1,2.6-1.9
        c1.15,0.2,1.25,0.05,2.15-0.35s1.45-0.85,1.7-0.5s0.6,0.9,0.55,1.6s0.45,1.7-0.15,1.7s-0.3-1.6-1.1-1.65s-1.35,0.6-1.65,1.2
        c-0.3,0.6-1.8,0.25-2.35,0.95s-0.05,1.8,0.8,1.9s0.8,0.05,2.45,0.7s1.35,0.25,2.6,0.55s4.66-0.45,4.46,0.6s-2.75,0.6-3.6,0.75
        s-0.2,0.85-1.95,0.85s-3.56-0.6-4.41,0.1s-1.95,0.85-2.25,1.5s0.3,1.75-0.75,1.85s-3-0.7-3.46-0.3s-0.35,1.25-1.4,1.3
        c-1.05,0.05-1.45,1.05-2.45,1.3s-0.9,1.6-1.15,2s-1.25-0.1-1.8-0.6s-0.8-1.3-2.3-1.05s-3.05,0-4.46,0.35c-1.4,0.35-1,1.1-1.65,1.5
        s-1.85,0.35-2.85,0.1s-2.6,0.65-3.46-0.25s-1.3-1.6-2.55-1.6s-1.1-1.4-2.96-1.4s-1.95,0.05-2.65,0.55s-0.55,0.75-1.5,0.8
        s-0.55,1.3-1.85,1.8s-1.05,1.6-2.1,1.1s-1.75-1.5-1.35-2.15s1.7-0.6,1.7-1.4s0.15-1.45-0.85-1.65s-1.65-2-3.1-2.1
        c-1.45-0.1-3.3-0.5-4.31-0.35s-0.5,1.85-1.95,1.95s-2.6,0.15-3.31-0.25c-0.7-0.4,0.18-0.63-1.84-1.01c0,0-1.8-0.38-1.92,0.64
        c-0.11,1.01-0.94,2.25-0.26,2.67s1.46,0.23,1.46,0.75s0.56,1.24-0.71,1.35s-1.43,0.64-1.61,1.28c-0.19,0.64,0.64,1.13,1.35,0.45
        s1.28-1.13,1.8-0.98c0.53,0.15,3.9,0.64,4.13-0.3c0.23-0.94,0.49-1.28,1.09-1.28s1.99-0.23,1.62,1.05
        c-0.38,1.28-0.83,2.14,0.04,2.22c0.86,0.07,2.55-0.3,2.59,0.64s0,1.43,1.31,1.39s1.91-0.83,2.48,0.04
        c0.56,0.86,0.94,1.13,1.54,1.09c0.6-0.04,1.09,0.38,0.83,0.71c-0.26,0.34-1.65,0.3-1.62,1.01c0.04,0.71,0.53,0.34,1.5,0.38
        c0.98,0.04,1.16,0.26,2.22-0.11c1.05-0.38,1.76-1.01,2.93-0.08s1.73,1.39,2.74,1.43c1.01,0.04,2.03,0.3,2.59-0.19
        s-0.64-2.07,1.13-2.22c1.76-0.15,1.76-0.23,2.14,0.53c0.38,0.75,1.65,0.94,1.88,0.41s0.34-0.9,1.24-0.83
        c0.9,0.08-0.04,2.22,1.05,2.22s1.16-0.07,1.35-0.45s0.56-0.86,1.2-0.68c0.64,0.19,0.45,0.9,1.35,0.9s1.13,0.07,2.82,0.11
        s3.34,0.71,4.02-0.38c0.68-1.09,0.38-2.03,1.73-2.1c1.35-0.08,0.64,1.24,1.8,1.28s1.58,0.08,2.03,0.53s0.79,0.41,1.84,0.41
        s2.33,0.15,2.97-0.26s0.83-0.94,2.1-0.94s1.01,1.62,2.21,1.46c1.2-0.15,2.07-0.08,2.52,0.38c0.45,0.45,0.34,0.79,0.9,0.75
        s-0.34-2.07,1.05-2.07s2.59,0.11,3.83,0.11s1.65-0.38,2.78-0.23c1.13,0.15,2.48,0.53,3.04-0.23s0.68-1.13,1.28-1.16
        c0.6-0.04,0.9-1.31,0.9-2.18c0-0.86,0.07-0.94-0.41-1.43c-0.49-0.49-1.58-1.16-0.64-1.46s2.07-0.45,2.52-1.09s0.41-0.75,1.2-0.75
        s0.79,0.41,1.05,1.05c0.26,0.64,1.24,0.34,1.31-0.34s0.26-0.71,1.24-0.71s1.76-0.41,2.03,0.34c0.26,0.75,0.56,1.05,1.31,0.94
        s0.86-0.19,1.24-0.75s0.86-0.68,1.65-0.68s2.14,0.6,3.15-0.53s0.45-1.61,1.46-1.65c1.01-0.04,1.2,0.11,1.58,0.79
        s1.46,1.35,1.8,0.34s0.04-1.31,1.5-1.09s2.89,0.83,3.49-0.3s1.01-1.24,2.7-1.2s1.84,0.11,3.72,0.11s3.34-0.64,3.94,0.45
        s0.56,1.65,1.09,1.91s0.98,0.94,0.56,1.46s0.56,2.44-1.01,2.82c-1.58,0.38-1.46,0.38-1.5-0.86s-0.49-1.91-1.69-2.07
        c-1.2-0.15-1.69-0.68-2.63-1.2c-0.94-0.53-2.44-0.98-3.42,0.11s-2.29,2.74-3.72,3.19c-1.43,0.45-3.64,0.94-4.81,0.9
        c-1.16-0.04-1.2-1.46-1.99-1.24c-0.79,0.23,0.11,2.44-0.9,2.59s-0.68,0.94-1.54,0.94s-0.56,0.75,0.07,1.35
        c0.64,0.6,1.5,1.24,0.98,1.8c-0.53,0.56-1.43,0.83-2.29,0.6s-0.86-0.34-1.43-0.94c-0.56-0.6-1.69-1.2-2.03-0.75
        s0.26,1.09-0.68,1.24s-1.65,0.56-1.5,1.2s0.86,0.15,1.05,0.94s0.86,1.95,1.95,1.99s2.1,0.08,2.97,0.11
        c0.86,0.04,0.79-0.23,1.58,0.38c0.79,0.6,0.71,0.71,1.99,0.71s2.55,0.07,3.08-0.3c0.53-0.38,1.88-0.83,2.44-0.83
        s0.26,1.01,1.43,0.9s2.44-0.19,2.63,0.9c0.19,1.09,1.5,1.58,2.59,1.58s0.83-0.98,2.14-1.01c1.32-0.04,1.8-1.05,2.82-1.05
        s1.43,0,1.8-1.31c0.38-1.31,0.26-3.42,1.8-3.57c1.54-0.15,1.61-1.16,2.97-1.05s2.1,0.9,2.4,1.88c0.3,0.98,1.05,1.99,1.58,2.29
        s1.24,0.83,1.16,1.69s-0.07,2.14-0.79,2.48c-0.71,0.34-0.86,0.83-0.75,1.43s1.24,0.49,1.05,1.58s0.07,1.24-0.3,1.65
        c-0.38,0.41-0.53,0.79-0.26,1.43c0.26,0.64,0.3,1.13,0.98,1.31c0.68,0.19,1.28,0.26,1.09,0.94s-0.34,1.24-0.9,1.24
        s-0.64,1.43-0.64,2.37s0.56,4.02,1.39,4.51c0.83,0.49,2.44,0.38,4.43,1.92c1.99,1.54,2.74,2.7,3.64,3.72
        c0.9,1.02,1.54,1.99,0.68,3.19c-0.86,1.2-0.26,1.99,0.86,3.04s0.19,2.07,1.76,2.18s2.97-0.19,3.68-0.19s1.31-1.39,2.25-1.09
        s1.09,1.54,0.53,2.07s-0.75,1.76-2.33,1.76s-4.32-0.04-4.21,1.31c0.11,1.35-0.08,2.59,0.86,3.53s0.98,1.69,0.79,3.12
        s0.23,3.87,0.04,5.44c-0.19,1.58,0.08,2.67-0.38,3.64c-0.45,0.98-1.16,1.92-0.98,2.59s1.05,0.64,1.01,1.76
        c-0.04,1.13-0.51,1.6,0.59,1.7s1.1-0.8,2.2-0.45c1.1,0.35,2.05,0.55,2.4,0.35c0.35-0.2,0.6-1.2,0.35-2.4
        c-0.25-1.2-0.25-1.75,0.25-2.05s0.85-0.55,0.85-1.7s0.1-1.4,0.8-1.4c0.7,0-0.4,2.56,0.75,2.9c1.15,0.35,1.2,0.8,0.4,1.4
        c-0.8,0.6-1.1,1.55-1.45,2.45c-0.35,0.9-1.4,1.6-0.7,2.55c0.7,0.95,1.35,1.55,1.85,2.75c0.26,0.63,1.44,0.72,1.44-0.72
        s0.81-2.25,1.35-2.88s1.17-2.7,1.98-2.7s0.81,1.71,0.09,2.25s-1.35,1.35-1.35,2.52s-0.81,1.98,0,2.43s1.35,0.81,1.35,1.89
        s-0.36,2.34-0.45,3.96c-0.09,1.62-0.18,1.8-1.08,2.52s-1.08,1.44-1.17,2.43s0.27,2.16-0.99,2.34c-1.26,0.18-1.71,1.26-1.71,1.98
        s-0.35,1.1-0.35,2.38s-1.01,1.28-0.56,2.52s1.35,2.25,1.35,2.67s-1.58,1.92-1.92,2.52s-0.41,0.71-0.23,2.1s0.83,2.33,0.08,2.78
        s-1.58,0.3-1.58,1.31s-0.54,0.71-0.48,1.17s0.4,1.59-0.68,1.59s-1.65,0.46-1.88,1.08s-2.11,3.02-1.14,3.47
        c0.97,0.46,1.48,0.97,1.54,2.05s1.31,1.08,2.05,1.08s1.03,0.11,0.97,0.74s-0.51,1.48-1.88,1.48s-0.85,1.08-1.99,1.25
        c-1.14,0.17-1.02,1.08-1.02,1.88c0,0.32,0.13,0.52,0.3,0.68C264.643,22.164,266.283,22.944,267.323,21.434z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M721.953,210.894
        c-1.46-0.35-1.52-0.87-2.17-1.63c-0.77-0.9-0.83-0.49-2.43-1.32s-0.56-1.88-1.18-2.99s-1.67-1.88-2.71-2.78
        c-1.04-0.9-0.77-4.8-0.77-5.63s0-1.88-0.49-3.27s-0.63-2.23-0.28-4.17c0.35-1.95-1.39-2.23-2.36-3.34s-1.32-1.53-2.85-1.88
        c-1.53-0.35-1.6,0-2.43,1.39s-0.84,1.39-2.22,1.25c-1.39-0.14-1.04-0.9-2.09-2.99s-1.81-0.55-2.29,0.63
        c-0.49,1.18-1.11,1.25-1.39,2.57s-0.7,2.43-2.36,4.1c-1.67,1.67-0.49,3.06-1.46,4.94s-1.67,1.18-1.74,4.03s-1.39,3.41-1.53,4.87
        c-0.14,1.46-1.95,1.46-2.09,2.78c-0.14,1.32-0.77,1.32-2.36,1.32c-1.6,0-1.81,0.7-2.99,2.64c-1.18,1.95-3.61,1.53-8.34,1.53
        s-14.95,0.49-20.65,0.77s-7.85,4.31-9.11,5.49c-1.25,1.18-1.95,1.81-2.22,3.96c-0.16,1.25-0.98,1.61-1.8,1.85
        c0,0.01,0.01,0.02,0.01,0.03c0.41,0.71-0.04,2.1,0.45,3s-0.15,1.13-0.15,2.14s-1.35,1.09-2.4,1.31c-1.05,0.23-0.94,0.45-2.37,1.8
        s-4.54,0.23-6.01,0.23c-1.46,0-3.04-0.6-4.24-1.09s-4.84-0.53-6.68-0.34c-1.84,0.19-2.37,1.65-3.68,2.22
        c-0.18,0.08-0.35,0.1-0.53,0.14c0.23,0.78,0.71,1.54,1.13,2.54c0.25,0.6,0.24,1.03,0.07,1.4c0.16,0.07,0.3,0.16,0.45,0.27
        c0.6,0.46,0.07,0.93-0.4,2.13c-0.47,1.2-2.46,1.86-4.38,2.92s-2.19,1.79-4.38,2.59s-2.26,1.06-3.85,1.46
        c-1.59,0.4-1.46,1.2-3.59,1.66s-2.32,1.06-4.38,1.59s-3.25,1.66-4.58,2.79c-1.33,1.13-2.46,0.6-4.45,0.6s-2.46,0.46-4.05,0.46
        c-1.59,0-3.12-0.6-4.38-1.46s-1.2-1.33-2.59-1.53s-0.66-1.93,0.4-2.39s0.33-1.13,1.13-1.73c0.05-0.04,0.12-0.03,0.18-0.06
        c-0.61-2.27,2.52-4.67,3.83-5.49c1.32-0.83,2.64-1.32,2.99-2.23c0.3-0.79,0.55-2.41,0.7-4.05c-1.92-0.17-0.76-4.2-0.86-4.77
        c-0.1-0.6-0.65-0.7-0.5-2.4s-0.5-2.85-1.1-3.05s-0.7-0.65-0.8-1.55c-0.1-0.9-1.25-0.45-2.35-0.4s-0.8,1.15-1.8,1.6
        c-1,0.45-1.35,1.6-2.25,2.85s-3.05,0.75-3.55-0.4s0.25-1.6,0.85-2.55s1.7-1.25,2.7-2.05s1.1-2.3,1.7-3s1.1-1.6,1.15-2.6
        s0.25-1.3-0.15-1.75s-0.05-1.5-0.2-2.5s0.4-1.05,0.85-1.6c0.45-0.55-0.15-0.85-0.65-1s-0.75-0.55-1.05-1.8s-1.25-0.75-1.9-1.3
        s-0.25-0.6-2.3-0.65c-2.05-0.05-1.65-2.25-2.7-2.25s-0.9,0-2.15-0.7s-1.95-0.7-3.35-0.4c-1.4,0.3-1.05,1.25-1.05,2.55
        s-0.45,1.1-1.7,1.4c-1.25,0.3-0.45,0.5-1.75,1.3s-0.25,1.75,0.1,2.55s0.2,1.85-0.75,2.25s-1.2-1.45-1.15-2.15s0.7-1.5-0.05-1.7
        s-0.8,0.1-1,0.95c-0.2,0.85-0.45,0.45-1.7,0.25s-0.65,1.2-1.8,1.3c-1.15,0.1-0.4,1.85-1.2,2.5s-0.45,0.75-0.45,1.9
        s-1.45,0.7-1.45,1.9s-0.2,1.6-1,2.2c-0.8,0.6-0.35,1.85-0.25,3.15c0.1,1.3-0.15,1.4-0.7,2.05c-0.55,0.65-0.5,1.7-0.55,3.05
        s0.8,1.95,1.35,2.75s0.2,1.25,0.2,2.8s0.05,3.81-0.05,5.86s-2.45,3.9-2.9,4.81c-0.45,0.9-1.35,2.35-2.7,3.05s-2.3,1.75-3.41,2.3
        c-1.1,0.55-2.25,0.05-3.25-0.8s0-1.35-1.1-2.3s-0.1-2.5-0.9-3.05c-0.8-0.55-0.45-1.6-0.4-3.05s0.35-1.4,1-1.9s0.25-2.3,0.15-3.6
        s-0.4-1.65-1-2.4s0.05-2.6,0.55-2.65c0.5-0.05,0.45-0.95,0.4-1.5s-0.05-0.55,0.85-1.85c0.9-1.3,0.75-1.1,1.05-2.6
        s0.1-1.35,0.9-3.56c0.8-2.2,1.8-2.8,1.75-3.95s0.25-1.4,1-1.95s0.5-1.55,0.1-2s-0.8-0.45-1.85-0.45s-0.65,1.35-1.6,1.55
        c-0.95,0.2-2.2,0.05-2-1.05s2.05-1.55,2.4-2.15c0.35-0.6,0.3-0.3,1.8-1.3s1.1-1.2,1.65-2.2c0.55-1,1.65-1.5,2.3-2.8
        c0.65-1.3,0.55-2,1.4-1.95c0.85,0.05,1.6-0.1,1.8-0.6s0.8-0.95,2.25-0.85c1.45,0.1,0.85,1,2,1s0.45-0.45,0.85-0.6
        c0.4-0.15,0.7-0.75,0.9-1.25s3-0.5,4.76-0.5s1.1-0.2,1.2-0.7c0.1-0.5,1.05-0.7,1.75-0.8s1.85,1.55,2.5,1.55s0.75,1,1.15,1.35
        s1.2,0.1,1.85-0.4c0.65-0.5,1-0.65,1.7-0.65s0.95,0.45,1.6,0.7c0.65,0.25,1.1,0.45,1.85,0.45s1.25-0.1,1.25-0.85s1-0.3,1-0.6
        s-0.9-0.6-1.1-1.05c-0.2-0.45-0.35-0.6-0.75-0.7c-0.4-0.1-1.4-0.4-1.95-0.4s-0.5-0.6-0.6-1.15c-0.1-0.55-0.3-1.15-0.95-1.2
        s-1.15-0.5-1.6-0.6c-0.45-0.1,0,0-1.45-0.05s-0.6-1.75-0.65-2.6c-0.05-0.85-0.15-1.25-0.85-1.35c-0.7-0.1-0.85,0.25-1.2,0.45
        c-0.35,0.2-2.45,0.15-3.5,0.05c-1.05-0.1-2.28-0.1-3.71,0.05s-1.28,0.08-1.8,0.6c-0.53,0.53-0.83,0.53-1.43,0.45
        c-0.6-0.07-1.28,0.53-1.5,1.28s-1.95,0.08-2.25-0.6s-1.43-0.38-2.4-0.3s-1.58,0.15-2.33-0.9s-1.58-2.25-2.33-3.23
        s-3.98-0.6-5.26-0.68c-1.28-0.08-0.22-2.25-1.2-2.78c-0.98-0.53-0.68-1.95-1.58-2.03s-0.3,0.38-1.58,1.05
        c-1.28,0.68-1.05,0.68-1.73,1.58c-0.68,0.9-1.73,0.83-2.63,1.5c-0.9,0.68-3.23,0.6-4.58,0.68c-1.35,0.08-1.88,0.83-2.55,0.83
        c-0.68,0-1.05,0.45-1.2,0.9s-0.83,0.45-1.8,0.45s-1.58-0.68-1.43-1.2c0.15-0.53-0.75-0.45-1.13-0.68s0.08-1.35-0.53-1.58
        c-0.6-0.23-0.23-1.28-0.75-1.35s-1.2-0.07-1.95,1.2c-0.75,1.28-2.78,0.23-3.76,0.23s-0.98,1.5-2.18,1.28s-1.88-0.9-1.8-1.88
        c0.08-0.98,2.55-1.2,3.08-1.35c0.53-0.15,1.28-0.98,2.03-1.73s1.13-0.98,2.78-1.43s1.5-1.2,2.25-2.4s2.93-1.35,2.93-1.35
        c1.85-0.13,2.8-0.17,3.35-0.83s1-0.4,2.35-0.4s1.1-0.5,2.2-0.85s1.15-0.7,2.3-1.15c-1.35-2.1-2.75-1.4-4.16-1.4
        s-3.57-1.04-5.23-1.74c-1.65-0.7-2.79-0.61-3.57,0.52s-0.78,0.09-3.57,0.09c-2.79,0-2-1.39-2.96-2.79
        c-0.96-1.39-3.4,0.35-4.18-1.48s-2.96-1.57-3.57-3.04c-0.61-1.48-2.79-2-3.39,0.26c-0.61,2.26-2.96-0.43-3.48-1.22
        c-0.52-0.78-2.44-0.26-3.83-0.96c-1.39-0.7-0.61-1.31-2.79-1.92c-2.18-0.61-0.35-3.57,0.35-5.14c0.7-1.57,0-1.48-1.57-2.35
        s-1.92,0.78-2.26,2.87c-0.35,2.09-3.22,1.57-7.32,1.16c-108.15-13.82-199.08-47.8-201.91-49.43c-1.43-0.82-2.7-1.1-3.62-1.17
        c0.11,0.29,0.22,0.6,0.34,0.93c0.5,1.4,1.55,2.65,0.25,3.55s-1.5,1.6-2.05,2.3c-0.55,0.7-2.05,1.4-2.05,2.25s1.25,0.8,1.3,2.05
        s0.3,2.7-0.85,3.31s-1.95,1.85-2.6,2.65s-1.25,1-1.7,1.95s-0.25,1.55-1.55,1.85c-1.3,0.3-2.96,1.65-3.05,0.25
        c-0.1-1.4,1.75-1.3,1.55-2.75s-0.05-2.45,0.85-2.7s1.25,0.1,2.05-0.75s1.55-0.2,1.75-1.15c0.2-0.95,0.25-1.05,0.6-1.9
        s0.5-1.85-0.75-1.95s-2.35-0.3-3.05-1.45c-0.7-1.15-1.05-1.8-2.2-1.85c-1.15-0.05-1.3-0.2-2.6-1.75c-1.3-1.55-2.35-2.4-3.35-2.25
        s-1.6,2.05-1.85,3.65s-1,1.95-0.8,3.11c0.2,1.15,0.25,1.95-0.2,2.65s0,1.75-0.55,2.55s-0.5,0.65-0.5,2.25s0,2.45-0.35,2.75
        s-0.3,0.75-0.85,1.5c-0.55,0.75-0.8,0.45-0.3,1s1.1,1.3,0.65,1.9c-0.45,0.6-0.55,1-1.3,1.2s-1.2,0.3-1.1,1.1
        c0.1,0.8,0.05,1.5,1.55,1.8s2.1,0.35,2.25,1.15c0.15,0.8,0.65,2.7-1.2,1.7s-2.75-1.95-3.35-1.65s-1.25,2.7-2.6,4.06
        c-1.35,1.35-2.15,2.8-2.6,4.06s-0.55,2.05-1.25,2.75s-2.65,3.3-3.96,5.51c-1.3,2.2-2,3.65-2.9,4.91c-0.9,1.25-1.95,1.5-2.9,3.5
        s-1,3.96-2.65,5.16s-3.96,4.26-5.71,5.16s-1.75,2.5-1.7,3.26c0.05,0.75,0.3,3.05-0.5,4.16c-0.8,1.1-1.55,2.15-1.6,3.6
        c-0.05,1.45,0.05-0.85-0.75,2.9c-0.8,3.76,0.35,3.3-1,5.76s-2.15,4.36-4.06,5.31c-1.9,0.95-2.25,2.4-3.4,3.4s-1.2,2.55-1.2,3.46
        s1.5,1.5,1.5,2.9s0.2,5.71-0.3,6.46s-2,2-2,3.46s-0.4,2.9-0.8,3.55c-0.4,0.65-0.4,1.55-0.6,2.55c-0.2,1,1.4,1.55,1.4,3.15
        c0,1.6,1.2,1.7,1.15,3.96s-0.05,4.15,0.15,5.06c0.2,0.9,0.55,0.8,1.25,1.7s1.5,1.5,1.85,0.55s0.2-2.55,1.3-2.55
        s1.05,0.85,2.15,0.75c1.1-0.1,1.4-0.05,2.5,0.1s1.45,0.85,1.9,1.2c0.45,0.35,0.8,0.8,0.55,1.4c-0.25,0.6-1.75,0.25-2.4-0.45
        s-1.3-1.05-2.35-1.05s-2.4,0-2.6,1c-0.2,1,0.1,4.61-1.5,4.15c-1.6-0.45-0.85-2.85-2.1-2.5s-0.15,2.4-0.9,3.4s-0.35,3.96,0.1,4.76
        c0.45,0.8,0.75,1.55,1.6,2.3s1.3,0.8,1.3,1.75s-0.15,2-1.4,2.4s-1.6,1.2-1.8,2.15c-0.2,0.95-0.1,2.6,0.55,3.2
        c0.65,0.6,1.25,1.55,1.15,2.6c-0.1,1.05-0.5,2.3,0.15,3.15s1.05,1.35,0.95,2.45s0.75,1.9,0.75,3.1s0.15,1.6,0.7,2.2
        s0.75,0.65,0.75,2.1s-0.1,2.25-0.9,3.15c-0.8,0.9-0.35,1.6-0.55,3.15s-0.95,2.3,1.05,2.45s3.26,0.8,4.71,1.6
        c1.45,0.8,4.11,1.05,4.36,2.8s1.05,3.85,2.5,4.46c1.45,0.6,3.65,0.95,3.55,2.5c-0.1,1.55-0.05,2.3,0.9,2.6
        c0.95,0.3,2.05,0.75,2.55,1.95s1.55,2.45,2.05,3.76c0.5,1.3,1.65,2.7,1.65,3.9s-0.1,2.45-0.75,2.95s-0.95,1.05-0.8,1.95
        s1.35,1.55,0.9,2.55c-0.06,0.14-0.11,0.31-0.16,0.46c0.52,0.07,1.25,0.17,2.29,0.29c3.01,0.35,7.28,0.91,10.43,1.4
        s3.57,0.42,5.39,0.49c1.82,0.07,6.79,0.21,5.53,1.47s-2.73,1.33,0.07,2.73c2.8,1.4,8.26,6.3,13.23,9.8s7.91,4.55,12.46,7.98
        c4.55,3.43,5.04,3.57,8.96,4.41c3.92,0.84,7.42,1.61,9.66,1.89c2.24,0.28,5.04,0.98,7.35,1.61s6.86,2.73,7.28-0.42
        c0.42-3.15,0.98-4.06,2.24-3.5s2.87,0.7,4.41,0.77c1.54,0.07,3.92,0.63,5.18,0.84c1.26,0.21,2.31,0.56,3.5,0.63
        s3.78,0.35,3.92,2.03s0.35,2.52,2.87,5.25s4.34,5.67,5.74,7.14s3.29,4.34,3.43,6.23s0.42,4.76,0.42,6.58s1.12,3.5,3.29,5.67
        s3.92,3.5,5.46,4.83c1.54,1.33,3.57,3.71,4.83,1.33s2.87-1.89,3.85-4.27s1.82-3.22,3.15-3.29s3.43-0.14,5.18-0.14
        s2.24,1.05,3.64,1.05c1.4,0,2.52-0.07,3.29,1.26c0.77,1.33,1.82,3.15,2.87,3.99s2.52,2.87,2.8,4.69s0.56,3.15,1.19,4.06
        s1.33,1.61,1.12,2.94c-0.21,1.33-0.28,2.45,0.7,3.29s2.59,2.87,3.22,4.62s0.35,2.66,1.26,3.43s1.89,1.4,1.89,2.8
        s0.14,1.89-0.14,2.94c-0.28,1.05-0.49,1.75,0.42,2.73s1.19,1.96,1.19,3.5s-0.14,3.29,1.96,4.41s5.11,2.59,6.16,3.36
        c1.05,0.77,1.68,1.47,3.29,1.47c1.61,0,2.73-0.07,3.78,1.05s1.75,2.1,3.01,2.03c1.03-0.06,1.76-0.01,2.91-0.48
        c-0.6-1.1-1.55-3.23-1.24-4.34c0.35-1.25,1.35-1.4,0.75-2.75s-1.5-1.05-1.65-2.55s-0.7-1.6-0.05-2.95c0.65-1.35,1-1.8,0.35-2.9
        s-1.95-1.5-1.3-2.15c0.65-0.65,1.05,0.05,1.6,0.5c0.55,0.45,1.25,1.25,1.55-0.15s1.3-0.8,1.4-2.3s-0.25-3.86,1-3.76
        c1.25,0.1,0.75-2,1.15-2.6s0.75-0.4,1.05,0.4s0.15,1.4,0.85,1.15s0.1-1.65,0.65-2.2c0.55-0.55,1.15,0,1.15-1.7s0.2-2.3,0.55-2.3
        c0.35,0,0.5,1.1,0.5,1.85s-0.4,1.5,0.9,1.3s1.65-0.4,1.55-1.6s-1.3-2.1-0.45-2.9c0.85-0.8,1.3-0.8,2.55-0.65s1.55,0.55,1.55,1.35
        s-0.5,1.25,0.5,1.3s0.1-1.05,1.45-1s1.55,0.25,2-0.5s0.5-0.75,1.5-0.8s2.4-0.3,3.05-0.95c0.65-0.65,1.25-0.75,1.75-1.75
        s1-1.3,2-1.75s1.65-0.8,2.55-1.35c0.9-0.55,1.5-1,0.7-1.3s-1.3-0.6-1.3-1.65s-1.25-2.6-0.15-2.85s0.45,1.05,1.3,1.25
        c0.85,0.2,0.25,1.1,1.2,1.45s0.1,1.65,1,1.7c0.9,0.05,0.9-0.85,1.75-0.95s0.55-0.6,1.95-0.55c1.4,0.05,3.15,0.7,3.3-0.5
        s-0.05-1.95,0.85-2.1s0.15,0.95,1.2,0.95c1.05,0,1.2-0.2,2.8-0.05c1.6,0.15,2.6-0.65,3.7,0.05c1.1,0.7,2.1,1.15,2.9,1.85
        s1.41,0.73,2.24,1.48s1.43,0.15,2.14,0.64c0.71,0.49,0.45,0.94,1.2,0.94s0.11-0.83,1.35-0.83c1.24,0,1.39-0.11,1.31-1.2
        c-0.08-1.09,0.3-1.58,1.24-1.58s1.95,0,2.29,0.26s0.75,1.31,0.08,1.73s-1.69,0.41-1.69,0.98s0.56,0.6,1.05,0.6s0.53-0.56,1.09-0.94
        s1.05-1.31,1.54-0.6c0.49,0.71,0.68,1.61,1.35,1.61s1.39-0.34,1.46,0.9c0.08,1.24-0.26,1.73,0.71,2.07
        c0.98,0.34,1.43,0.68,1.92,1.2s0.98,0.49,1.54,0.49s0.26,0.86,1.13,0.98c0.86,0.11,1.46,0.11,1.73-0.41s0.86-0.64,1.05-1.01
        c0.19-0.38,0.45-0.53,1.88,0.41s1.2,1.09,2.1,0.98s0.94-0.45,0.45-1.61s0.26-0.98-0.38-1.99c-0.64-1.01-0.45-1.31,0.56-1.05
        s1.73,0.56,2.25,1.61s1.13,1.35,2.07,1.77c0.94,0.41,1.5,1.16,1.77,1.77s0.79,0.9,0.56,1.61s0.11,1.84,0.86,0.94
        s1.32-1.5,1.88-1.65s2.22-0.71,0.83-2.18c-1.39-1.46-2.67-1.43-3.9-2.25s-2.67-2.29-2.03-2.7s1.35-1.13,2.07-1.69
        c0.71-0.56,2.21-2.1,0.98-2.48c-1.24-0.38-2.97,1.2-3.49,0.04c-0.53-1.16-0.75-1.43-1.99-1.24s-3.27,1.13-3.61-0.34
        c-0.34-1.46-0.98-2.67-0.08-2.97c0.9-0.3,0.98-0.98,2.29-0.64s1.35,0.79,1.88,1.73s1.43,1.05,2.07,1.5s2.18,0.49,2.29-0.26
        c0.11-0.75-0.04-0.56,0.94-1.01s1.46-0.98,2.4-1.01c0.94-0.04,2.37-0.19,3.53-0.19s2.03-0.26,3.15-0.04
        c1.13,0.23,2.74,0.56,3.53,0.08c0.79-0.49,1.09-1.28,1.69-1.95s-0.19-1.5,0.53-1.65c0.71-0.15,0.22,1.2,0.75,1.54
        s0.86,1.01,0.71,2.1s-0.79,2.44,0.15,2.63s2.14,0.08,2.4-0.64c0.26-0.71,1.05-1.73,1.65-1.54c0.6,0.19-0.53,1.01,0.83,1.05
        s1.69-0.08,1.99-0.86s0.38-2.14,1.39-2.1s1.54,0.41,1.58,1.2c0.04,0.79-0.3,1.39,0.45,1.43c0.75,0.04,1.77,0.53,2.18-0.19
        c0.41-0.71,1.39-1.09,2.1-1.13c0.71-0.04,1.35,0.15,2.29,1.31s2.85,2.44,4.17,2.37s2.44,0.19,2.93,1.32s0.45,1.28,1.5,1.99
        s0.68,2.55,1.28,2.85c0.6,0.3,0.79,0.23,1.28,0.98s2.18,0.64,2.82,0.49c0.64-0.15,1.05-1.39,2.14-1.61
        c1.09-0.23,0.68-2.29,1.58-2.29s0.98,0.68,2.03,0s1.54-0.94,2.33-0.94s1.39,0.6,2.7,0.83c1.31,0.23,1.88-0.04,2.25,0.98
        c0.38,1.01,1.31,2.29,2.14,2.89s2.14,3.53,3.12,4.32s1.54,1.95,2.63,2.63s2.03,1.31,2.03,1.95s1.05,1.39,0.56,2.37
        c-0.49,0.98-0.45,1.54-0.6,2.44s0.22,2.63-0.3,3.49c-0.53,0.86-1.2,1.35-0.45,2.4s0.98,1.58,0.9,2.18s-0.68,0.45-1.05,0.11
        c-0.38-0.34-1.16-0.23-1.35,0.23s-0.64,0.49,0.38,1.01s1.95,1.13,2.44,0.49s0.64-0.9,0.98-0.6c0.34,0.3,1.69-0.08,0.04,1.58
        s-1.95,0.79-1.73,2.07s0.38,0.94,0.83,2.33c0.45,1.39,1.09,2.29,1.88,3.53s0.68,2.18,1.24,2.18s1.39,0.3,1.5,0.83
        s0.75,0.71,0.53,1.65s0.45,1.31,1.35,1.2c0.9-0.11,0.75,1.65,0.68,2.48c-0.08,0.83,0.08,1.61,0.22,2.29
        c0.15,0.68,1.09,0.19,1.13,1.16s0.11,1.39,0.86,1.5s1.46,0.26,2.48,1.16c1.01,0.9,2.29,2.25,2.33,3.19
        c0.04,0.94,1.05,1.69,0.68,2.33c-0.38,0.64-0.79,1.31-0.38,1.61s0.15,1.05,1.24,1.39s1.8,0.19,2.85,0s1.73-0.45,2.4-0.41
        s0.64,0,1.58-0.68s1.58-1.99,1.77-3.08s0.71-0.41,0.79-1.58c0.08-1.16,0.64-0.23,0.94-1.8c0.3-1.58,0.38-3.57,0.45-4.58
        c0.08-1.01,0.84,0.35,0.84-2.91c0-3.25,0.76-5.53-0.22-6.83s-1.09-1.73-1.3-3.14c-0.22-1.41-1.52-1.74-1.52-2.93
        s-0.65-0.65-0.76-2.71s-0.43-2.28-0.98-3.04s-0.32-1.08-0.76-2.28c-0.43-1.19-1.41-2.49-1.95-4.12c-0.54-1.63,0.65-1.08,1.19-0.22
        c0.54,0.87,1.19,1.19,1.3,0.54s0.76-0.54-0.43-1.74c-1.19-1.19-0.87-1.3-1.95-3.47s-1.95-2.28-2.49-4.45s-2.06-3.14-2.06-5.86
        c0-2.71-0.43-3.58-1.08-5.53s-0.65-2.28-0.65-4.23s-0.65-2.6-0.33-4.88s-1.09-3.9,0.97-4.66s2.06-0.22,2.39-2.39
        s-0.97-4.12,0.43-4.34s4.12-0.98,3.25-3.9s-0.54-1.95,1.52-1.95s2.93-1.19,4.01-1.3s0.54-1.3,2.6-1.73
        c2.06-0.43,0.87-1.63,2.06-1.84c1.19-0.22,2.39-0.33,3.9-2.28c1.52-1.95,1.84-1.41,2.28-3.25c0.43-1.84,2.17-2.39,2.6-3.47
        s1.19-3.25,4.23-3.25s1.84-0.98,4.34-0.98c2.49,0,2.6-0.98,2.71-1.84c0.11-0.87,1.13-2.31,1.95-2.82c0.79-0.49,0.6-0.71,1.8-1.05
        s1.58-0.79,1.8-1.24c0.22-0.45,0.3-0.53,1.99-0.94s1.13-1.58,2.25-1.58s2.18-0.23,2.48-0.23s0.49-0.64,1.16-0.64
        s0.08,0.75,0.56,0.9s0.83,0,0.9-0.6s0.04-0.75,0.49-0.83s0.68-0.41,0.75-1.13c0.08-0.71,0.04-1.09-1.01-0.94s-1.61,0.08-2.1,0.41
        c-0.49,0.34-0.9,0.45-1.65,0.34s-1.88-0.11-2.22-0.68c-0.34-0.56-1.47-1.5-0.6-1.69c0.86-0.19,0.94,1.05,1.65,1.05
        s1.05-0.26,1.8-0.04c0.75,0.23,0.83,0.26,1.24-0.3s1.58-0.9,1.13-1.39c-0.45-0.49-0.6-1.39-1.01-1.28
        c-0.41,0.11-0.83-1.01-1.65-0.98c-0.83,0.04-1.58,0.15-2.1-0.56c-0.53-0.71-1.39-1.01-0.49-1.09s0.94,0.45,1.58,0.79
        s1.28,0.68,1.77,0.11c0.49-0.56,0.9-1.09,1.43-0.86c0.53,0.23,0.68,1.16,1.31,1.5s1.35,0.34,1.99,0.15s2.07-0.19,2.4-1.24
        c0.34-1.05,1.01-1.05,0.98-1.92c-0.04-0.86-1.05-1.77-1.31-2.33s0.04-0.49-1.09-0.9s-0.53-0.15-1.5-0.83s-1.35-0.83-1.61-0.34
        s-0.64,0.79-1.39,0.79s-1.09,0.64-1.43-0.23c-0.34-0.86-0.56-1.5-1.05-1.58s-0.98-0.71-0.22-0.83c0.75-0.11,1.65,0.49,1.77,0.9
        c0.11,0.41,0.38,0.79,1.01,0.75c0.64-0.04,0.34-1.13,1.24-1.16c0.9-0.04,1.2-0.15,1.58-0.08c0.38,0.08,1.77-1.24,2.59-0.75
        s1.28,0.41,1.69,1.2s0.79,0.9,0.94,1.46s-0.86,0.9-0.75,1.54s0.15,1.2,0.83,0.79s0.41-1.01,0.79-1.5s0.75-0.94,0.34-1.88
        s-1.01-1.28-1.16-2.14c-0.15-0.86-1.77-0.75-1.77-2.1s-1.13-0.3-0.98-2.03s0.98-2.25-0.23-3.08c-1.2-0.83-1.65-1.73-2.03-1.5
        s-0.38,1.5-1.13,0.9s-1.13-1.65-1.73-1.88s-1.8-1.35-2.1-1.73s0.3-1.73,1.58-1.2s1.58,0.75,1.8-0.08s0.8-1.2,0.45-2.15
        c-0.35-0.95-0.65-1.25-0.15-2s0.6-0.9,0.4-2c-0.2-1.1,0.05-1.4-0.5-1.9s-1.6-0.75-1.5-1.6s1.05-0.35,0.95-1.2s-0.25-1.1-0.7-1.45
        s-0.65-1.25-0.6-2.05c0.05-0.8-0.25-1.6-0.05-3s-0.6-4.61,0.5-5.36s0.55-1.95,1.65-2.45c1.1-0.5,0.95-0.75,1.55-0.6
        s0.75,1.25,0.3,1.7s-0.6,1.1-1.15,1.35c-0.55,0.25-0.85,0.9-0.85,1.55s-0.25,2.05,0.15,2.5s0.85,0.4,0.95,1.2
        c0.1,0.8,0,2.25-0.7,2.85s-0.35,1.5,0.25,1.9s0.25,1.15,1.25,1.45c1,0.3,0.5,0.85,1.55,1.6s1.5,0.75,1.75,1.4s1.4,1.7,0.8,2.25
        c-0.6,0.55-1.9,1.6-2.2,2.15s-0.35,0.6-0.5,2.2s-0.9,2.7-0.2,3.26c0.7,0.55,1.1,0.45,1.5-0.9s0.25-1.85,1.05-2.4
        s1.3,0.15,1.55-1.65s0.05-2.15,0.7-2.85s1.05-1.05,1.35-2.4s0.85-2.2,1.55-2.65s1.1-0.85,1.25-1.6s1-0.2,0.9-1.6
        s-0.7-0.25-0.7-1.45s0.2-1.85-0.6-2.25s-1.45-2.35-2.4-2.95s-2.01-1.95-1-2.55c1-0.6,1.1-0.15,1.7,0.05
        c0.6,0.2,0.95,0.55,1.45,1.35s0.85,0.45,1.3,1.1s0.65,0.4,0.65,1.15s1.1,1.1,1.1,0.2s0.1-0.9,0.6-1.45s0.1-1.35,0.85-1.9
        s1.1-0.6,1.45-1.6c0.35-1,0.05-1.75,1.25-2.3s1.95-0.75,1.95-1.55s-0.57-1.22,0.19-2.35s0.59-1.51,0.59-2.27s-0.22-1.19,0.22-1.89
        s0.22-1.46-0.22-1.84s-2-0.92-1.95-1.89s-0.11-2.01,0.94-2.11c1.06-0.1,1-0.05,1.16-0.96c0.15-0.91,0.25-0.91,0.15-1.56
        s0.3-1.11-0.15-1.76s-0.75-0.5-0.7-1.41s0.45-1.36,0.8-0.55s0.91,1.41,0.91,2.17s0.3,1.01,0.3,1.76c0,0.76-1.26,1.51,0,1.56
        s0.6-1.86,1.71-2.17c1.11-0.3,1.61,0.2,2.11-0.81s1.06-1.11,1.81-1.16c0.76-0.05,1.61-0.05,2.07-0.65s1.46-1.06,2.27-1.06
        s2.07,0.35,2.53-0.25s0.25-0.91,1.21-0.91s1.91-0.3,3.17-0.2s3.17,0.3,3.88-0.3s0.86-1.11,0.76-1.96c-0.1-0.86,0-1.87,0.71-2.12
        s1.21,0.25,1.51,1.01s0.25,1.31,1.06,1.41c0.8,0.1,1.36,0.2,1.66-0.35s0.76-1.81,1.81-1.81c1.06,0-0.05,0.6,1.06,0.86
        c1.11,0.25,2.27,0.2,2.47-0.1c0.2-0.3,0.96-0.7,1.56-0.7s1.82,0.76,2.12-0.71c0.3-1.46,0.15-2.07-0.2-2.32
        c-0.35-0.25-0.3-1.51-0.91-1.86c-0.6-0.35-0.7-0.76-1.11-0.76s-1.76,0.5-0.66,0.76c1.11,0.25,0.15,1.16,1.01,1.51
        c0.86,0.35,0.76,0.7,0.71,1.26c-0.05,0.55,0.25,0.96-0.91,1.11s-2.97,0.1-3.38-1.11c-0.4-1.21-0.25-2.07-0.81-2.67
        c-0.55-0.6-0.8-1.56-1.66-1.91s-1.97,0.15-1.97-0.96s-0.41-1.86,0.5-1.91s1.51-0.61,1.31-1.31s-0.56-1.01-1.11-1.61
        s-1.06-1.56-0.35-2.01s1.11-0.25,1.11-1.21s-0.05-1.36,0.86-1.86c0.91-0.51,1.56-0.96,1.56-2.12c0-1.16-0.15-1.81,0.4-2.37
        c0.55-0.55,0.75-0.96,0.8-1.71c0.05-0.76,0.3-0.91,0.86-1.31c0.55-0.4,1.11-0.71,1.71,0.05c0.6,0.76,0.81,1.01,1.26,0.5
        c0.45-0.5,1.26-1.06,0.4-1.56c-0.85-0.5-1.11-1.41-0.4-1.41c0.7,0,1.56-0.3,1.96,0.35s0.91,1.26,1.06,0.45s0.4-1.26,1.31-1.11
        s1.27,0.1,1.82-0.96c0.55-1.06,1.01-1.61,0.76-2.27c-0.25-0.65-0.51-2.17,0.25-2.37s0.2,0.61,0.96,0.61s1.26-0.25,1.56,0.3
        s0.85,1.31,1.26,1.11s-0.6-1.97,0.51-2.07s2.17-0.65,2.77-0.35c0.6,0.3,1.26,0.6,1.91,0.5c0.65-0.1,0.91-0.3,1.01-0.86
        c0.1-0.55,0.35-0.7,1.06-0.76s0.2-1.01,1.26-1.16s1.31-0.76,2.97-0.96s2.32-1.26,2.32-1.76
        C722.783,212.044,721.933,211.874,721.953,210.894z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M179.733-89.366c0,0-1.13,0.49-1.43,1.09
        c-0.3,0.6-1.62,1.05-1.95,1.65s-0.41,0.71-0.83,1.2c-0.41,0.49-1.58,0.75-0.9,1.54c0.68,0.79,0.41,1.16,1.58,1.46
        c1.16,0.3,0.98,0.6,1.95,1.05c0.98,0.45,1.65,0.56,1.95,1.24s0.19,1.69,1.09,1.35c0.9-0.34,1.31-1.8,2.44-1.84s0.68,0.9,1.95,0.9
        c1.28,0,3.94,0.38,3.98-0.38c0.04-0.75,0.15-1.35,0.75-1.61s0.83-0.15,0.83-0.98s0.07-1.95-0.41-1.99
        c-0.49-0.04-0.07-1.31-0.98-1.31c-0.9,0-1.43-0.07-1.76,0.19s-1.28,0.38-1.28-0.3s-0.53-0.64,0.19-1.28
        c0.71-0.64,0.98-0.68,0.94-1.35c-0.04-0.68-1.13-0.94-1.69-0.79s-1.24,1.09-1.24,1.65c0,0.56,0.19,1.35-0.71,1.43
        c-0.9,0.07-1.73,0.56-1.91,1.13c-0.19,0.56-0.41,1.24-1.09,1.61c-0.68,0.38-1.24-0.3-0.56-1.28s1.24-1.43,1.76-1.8
        c0.52-0.37,0.83-0.75,0.64-1.58C182.853-89.176,181.273-90.266,179.733-89.366z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M176.433-80.806c0,0-0.83,0.15-1.16-0.56
        c-0.34-0.71-0.9-0.75-1.31-0.71s-0.34,1.09,0.45,1.39s0.45,1.05,1.32,1.2c0.86,0.15,1.76,0.38,1.76-0.26
        C177.483-80.386,177.443-80.806,176.433-80.806z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M195.133-89.966c0,0-1.62-0.26-2.18,0.53s-1.62,1.13-1.24,2.25
        c0.38,1.13,0.98,0.6,1.46,1.09c0.48,0.49,0.9,0.6,1.8,0.56c0.9-0.04,2.29,0.53,2.59-0.34s1.09-0.64,1.09-1.69s-0.07-2.07-1.2-2.1
        C196.333-89.706,196.143-89.746,195.133-89.966z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M234.603-84.186c0,0-0.6,0.41-0.86-0.15
        c-0.26-0.56-0.45-1.01-1.09-1.01s-0.68,0.6-0.68,1.16c0,0.56,0.64,0.86-0.34,0.98c-0.98,0.11-1.28,0.9-0.53,1.13
        c0.75,0.22,0.79,0.34,1.58-0.19c0.79-0.53,1.24-0.83,1.99-0.6s0.6,0.98,1.43,0.75c0.83-0.22-0.53-2.1,1.2-2.18s1.84-0.3,1.65-0.75
        s-1.13-0.49-1.88-0.41c-0.75,0.08-1.2,0.19-1.35,0.68C235.573-84.296,235.423-84.256,234.603-84.186z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M241.393-84.256c0,0,0.38,1.05-0.83,1.05
        c-1.2,0-0.94,0.6-0.68,1.05c0.26,0.45,1.01,0.3,1.91,0.3c0.9,0,1.73,0,1.73-0.68s0.19-1.76-0.45-1.88
        C242.453-84.526,241.173-85.006,241.393-84.256z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M268.713-23.746c0,0-1.96,0.45-1.81,2.03s0,2.22-0.71,2.67
        s-1.39,0.79-1.51,1.54s0.08,2.82-0.56,3.2c-0.64,0.38-1.85,0.56-1.73,1.54s1.39,0.83,2.37,0.79c0.98-0.04,0.64-0.49,1.88-1.88
        s2.11-1.36,2.71-2.9s-0.34-1.5,0.68-2.48s1.73-0.98,1.84-2.41C271.993-23.066,271.423-24.946,268.713-23.746z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M259.263-25.726c0,0-0.1,2.45-0.15,3.76
        c-0.05,1.3,0.15,3.06-0.7,4.06s-0.8,2.4-1.65,3.31c-0.85,0.9-1.4,1.85-1.5,2.75s-0.15,1.2-0.8,1.95c-0.65,0.75-1.25,1.55-1.25,2.2
        s-0.25,1.35-0.55,1.75s-1.1,1.2,0,1.25s0.85,0.15,1.3-0.8s0.45-1.45,1.4-1.95s1.35-0.55,2.3-2s1.6-1.7,2-2.8s0.9-0.65,1.1-2.15
        s0.25-3.05,1.25-3.25s1.95-0.3,2.3-1.2c0.35-0.9,0.55-1.1-0.1-1.8s-0.85-0.7-0.6-1.7s-1.45-1.65-1-2.5s1.9-0.25,1.9,0.75
        s0,1.95,0.65,1.7s1.2-0.6,1.3-1.85c0.1-1.25,0.8-1.85-0.45-2.45s-1.6-0.3-2.35-1.05s-1.95-1.55-2.6-0.75s-0.5,1.65-1,1.8
        C259.563-26.576,259.263-26.326,259.263-25.726z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M258.443-5.666c0,0-0.53,0.71-1.39,1.2
        c-0.86,0.49-1.65,0.71-1.91,1.16c-0.26,0.45-0.9-0.15-1.28,0.94s-0.68,1.62,0.53,1.28s1.91-0.15,2.4-1.09s0.71-1.28,1.65-1.28
        s1.47-0.3,1.43,0.41s0.71,1.5,1.31,1.65s0,1.2,0.79,1.39s0.56,0.64,1.35,0.79s1.61,0.53,1.61-0.53c0-1.05-0.83-0.71-0.86-1.62
        c-0.04-0.9-0.34-2.06,0.49-2.18c0.83-0.11,0.56,1.05,0.71,1.5s1.05,1.24,1.31,0.22c0.26-1.01,0.83-0.75,0.53-1.69
        s-0.64-0.71-0.49-2.1c0.15-1.39,0.15-1.73-0.3-2.4s-0.04-1.54-1.28-1.92s-1.39-0.45-2.14-0.19s-1.24-0.3-1.69,0.26
        s-1.43,0.3-1.84,1.09s-1.16,1.43-0.15,1.5s1.99-0.22,2.37-0.11s1.05,0.64,0.53,1.16c-0.53,0.53-0.64,0.68-1.5,0.53
        C259.763-5.816,259.273-6.486,258.443-5.666z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M257.303,3.314c0,0-1.85,0.7-1.9,1.45c-0.05,0.75,1,2.25-0.1,2.75
        s-1.9,1.1-2.15,2s-0.25,1.3-1.35,1.85s-1.95,0.65-2.1,1.95s-0.65,2.05-0.5,3.35s1.4,1,1.65-1.9s1.9-2.2,1.9-1.55s1.65-0.55,1.6,0.5
        c-0.05,1.05-0.7,1.65-1.3,1.7s-0.45,1.15-0.45,2.2s0.4,1.9,1.55,1.85s0.5-1.3,1.55-2.5c1.05-1.2,1.95-0.55,1.95-1.8
        s-0.25-2.95,0.6-3.66c0.85-0.7,1.05-0.6,1.15-1.4s0.6-1.2,1-1.2s1.2,0.95,1.4-0.4s-1.65-1.25-1.25-2.3s1.85,0.35,2.6-0.8
        s1.2-2.4,0.15-2.45c-1.05-0.05-2.2,1.35-2.8-0.2c-0.6-1.55-0.3-1.6-1.2-2.25c-0.9-0.65-1.45-1-1.6,0.15
        C257.553,1.814,258.053,2.714,257.303,3.314z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M260.613,15.984c0,0-1-0.25-1.45,0.7c-0.45,0.95-1.35,1.95-0.1,1.7
        s0.55-0.45,1.45-1.05c0.9-0.6,1.65-0.65,1.3-1.5C261.463,14.974,261.263,16.484,260.613,15.984z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M211.703,288.374c0,0-1.47-0.22-1.74,0.11s-0.65,0.65-0.22,0.92
        c0.44,0.27-0.27,0.98,0.65,1.09C211.323,290.604,213.483,288.394,211.703,288.374z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M214.783,289.774c0,0-1.78-0.37-1.57,0.57
        C213.423,291.294,216.663,289.984,214.783,289.774z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M223.893,303.014c0,0,0.34-0.75-0.41-1.32
        c-0.75-0.56-1.39-0.15-1.39-1.05s0.56-1.24,1.43-0.23s1.35,1.13,1.39,1.95C224.953,303.204,223.523,304.364,223.893,303.014z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M221.453,306.174c0,0-0.19-0.45-0.64-0.38
        c-0.45,0.08-0.45,0.38-0.41,1.2c0.04,0.83-0.07,1.2,0.53,1.13C221.533,308.044,221.873,307.714,221.453,306.174z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12" x="369.693" y="249.174" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="71.09" height="24.99" pointer-events="auto" visibility="hidden"></rect>

        <rect id="UNANSWERED" x="392.854" y="249.193" opacity="0" fill="#8A0084" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="24.768" height="24.99" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_CANADA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Canada" data-wikipediaLink="https://en.wikipedia.org/wiki/Canada" data-infoText="<b>Canada</b> &amp;nbsp; Capital: Ottawa" data-imageFile="CANADA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M449.323-139.186c0,0-1.3,0.8-2.2,1.6
        c-0.9,0.8-1.9,1.5-3.3,1.9s-1.4,1.4-3.51,1.9s-3.71,0.4-3.3,2s1.5,1.6,1.8,3.3s0.7,2.2,1.3,2.7c0.6,0.5,0.2,1.8-0.6,1.9
        c-0.8,0.1-0.5,1.4-0.8,2.5s-1.63,0.34-1.29,1.05s1.5,0.56,1.35,1.43c-0.15,0.86,1.58,1.39,2.07,0.49s2.33-1.2,3.42-1.24
        c1.09-0.04,1.13,1.43,2.74,1.58s4.23,0.1,5.23-1.25s0.8-3.16,3.2-3.56c2.4-0.4,1.6-0.9,4.15-1.15s3.35-0.6,4.46-1.65
        s1.45-1.85,4.56-1.95s4.86,0.2,5.86-0.15s1.45-0.75,3.45-0.95s5.26-0.05,6.26-0.6s-0.15-1.2,0-3.35c0.15-2.15,0.6-5.06-0.55-5.71
        c-1.15-0.65-1.4-2.15-3.45-2c-2.05,0.15-4.36,0.65-4.76-1.15s-0.1-3.66-1.35-4.76c-1.25-1.1-0.8-1.8-2.8-2.1s-2.7-0.2-3.65-0.95
        c-0.95-0.75-1.8,0-2.55-0.9s-3.01-0.6-3.56,0.65s-1.15,1.75-1.55,3.05s0.25,2.45-1.75,2.95s-1.7,1.1-3.76,1.55
        C452.373-141.586,454.273-140.236,449.323-139.186z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M472.803-126.066c0,0-2.9,0.45-4.15,1.2
        s-2.55,0-3.2,1.2s-0.6,1.2-2.55,1.75c-1.95,0.55-1.6,0.95-3.05,1.5c-1.45,0.55-1.5,1.15-2.55,1.9s-0.9,1.45,0.15,1.8
        s1.4,0,1.4,1.45s1.45,2.8,2.8,2.75c1.35-0.05,2.15-0.2,3,0.3s1.5,0.55,2.65,0.45s1.5,0.4,1.45,1.15s-1.35,0.85-2.6,0.8
        s-2.5-0.5-4.06-0.45c-1.55,0.05-5.66-0.6-7.16-0.35s-1.2,1.15-1.9,1.45c-0.7,0.3-0.55,1.25-0.2,1.45c0.35,0.2,0.65,0.6,0.75,1.4
        s0.6,2.3,2.4,2.3s2.65,0.15,3.76,1.2c1.1,1.05,3.51,1.95,5.11,1.95s3.95-0.35,5.06,1.35c1.1,1.7,1.7,1.95,2.4,2.3
        c0.7,0.35,1.35,0.7,1.35,1.5s1,1.75-1.7,1.8s-2.3,0.1-4.16-1.15c-1.85-1.25-3.61-2.7-5.36-2.85s-3.25-1.05-4.76-1.05
        s-1.6,0.1-3.2-0.35s-2.25-1-3.4-0.85s-2.76,1.95-2.8,3.7c-0.05,1.75-1,3.9,1.35,5.61c2.35,1.7,2.45,3.1,4.51,3.15
        c2.05,0.05,2.05,0.3,3.15,0.9s0.8,0.7,1.25,1.65c0.45,0.95-0.1,1.8-0.95,2.3s-1.4,1.35-1.4,2.15s-0.5,1.35,0.8,2.2
        s0.6,1.75,2.55,1.8c1.95,0.05,1.75-0.55,3.15,0.45s2.3,0.8,3.3,0.7c1-0.1,2.55,0.05,3.71,0.8c1.15,0.75,3.55,0.2,4.61,0.2
        s2.5,0.3,3.55-0.25s1.7-1.1,2.8-0.85c1.1,0.25,0.95,1.15,2.75,1.35s2.45-1.1,3.45-1.85s1.85-1.9,3-1.95c1.15-0.05,1.7,0.95,1.2,1.5
        s-1.1,0.9-0.25,1.25s1,0.9,2.05,1.05s1.35,0.6,1.7,1.7s0.85,1.8,2.15,2.25s2.4,0.45,2.8,1.15s1,0.8,2.25,0.7
        c1.25-0.1,1.5-0.3,2.45,0.6s2.2,1,2.65,0.05c0.45-0.95,2.7-0.2,3.36-1.5c0.65-1.3-0.35-1.55,0.65-2.15s1.2-1.35,0.35-1.65
        s-1.65-0.85-2.15-0.45s-1.15,0.6-1.85,0.6s-1.25-0.35-0.9-1.35s-0.2-1.4,1.05-1.3s2.15,0.3,2.4-0.25s0.65-1.2,1.85-1.25
        c1.2-0.05,2.65-1.15,3.56,0.3c0.9,1.45,0.05,2.3,1.25,2.45s0.7-1.4,1.6-1.45c0.9-0.05,0.85-0.3,0.7-0.95s-0.45-1.25,0.45-1.8
        s0.45-1.75-0.75-1.95s-1.55-1.85-3.1-2.1s-1.55-1.35-1.55-1.9s0.5-1.35-0.8-1.5s-1.7-0.2-2.1-0.8s-1.5-0.9-1.5-2.75
        s-0.6-3.71,0.3-5.01s1.8-1.65,1.9-2.95s1.15-0.95,1.25-2.4s-0.25-4.91,0.45-5.76c0.7-0.85,1-0.35,0.75-1.9s-0.25-2.5,0.25-3.15
        s0.9-0.9,0.8-1.7c-0.1-0.8-0.35-1.9-1-2.15s-1.11-0.97-1.19-1.78c-0.08-0.8-0.8-1.13-1.45-1.37s-1.29-0.4-1.61-1.21
        c-0.32-0.8-0.96-1.04-1.37-1.04c-0.4,0-0.64,2.01-1.45,2.25c-0.8,0.24-0.64,1.85-1.37,2.25s-1.05,1.37-1.05,2.17
        s0.48,2.33-0.32,2.81c-0.8,0.48-1.77,1.85-1.77,2.65s0.4,3.14-0.56,3.38s-1.29,0.8-1.77,1.53c-0.48,0.72-2.65,2.09-2.65,0.32
        s1.04-1.69,1.37-3.3c0.32-1.61,0.4-2.25,0.8-2.57s0.4-0.32,0.56-1.21c0.16-0.88,1.37-0.96,1.37-2.09s-1.37-0.56-1.37-2.17
        s-0.64-2.09-1.13-2.57c-0.48-0.48-0.16-2.01-1.04-2.09s-1.85,0.72-1.69,1.61c0.16,0.88,1.45,1.69,0.56,2.65
        c-0.88,0.96-1.61,2.57-2.41,2.17c-0.8-0.4-1.2-1.29-2.01-1.29c-0.8,0-0.89,1.69-1.61,1.69s-0.4-1.37-1.45-1.37
        c-1.04,0-2.49,1.77-2.49,0.56s0.64-1.12,1.93-1.37c1.29-0.24,0.32-1.29,1.61-1.29s1.61,0.24,1.69-0.64
        c0.08-0.88,1.85-0.32,1.13-1.29c-0.72-0.96-2.01-0.8-2.25-2.01s-1.29-2.09-2.17-2.73s-2.57-0.4-2.89,0.72
        c-0.32,1.13-1.69,0.72-2.25,1.37s-1.2,1.13-2.33,0.88c-1.13-0.24-1.45-1.37-0.24-1.61c1.2-0.24,2.25,0.24,2.49-0.96
        s0.88-1.36,1.37-1.77c0.48-0.4,1.12,0.16,1.2-1.53c0.08-1.69-0.89-2.41-1.61-2.49c-0.72-0.08-2.25-0.48-3.38-0.24
        c-1.13,0.24-2.73,0.32-3.78,0.72C476.513-126.426,475.683-126.506,472.803-126.066z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M479.663-72.166c0,0-1.54-0.22-1.77,0.41
        c-0.23,0.64-0.45,1.39-1.95,1.46s-1.88-0.15-1.88,0.3s0.86,0.15,0.94,1.09c0.08,0.94,0.08,2.14,1.65,1.95
        c1.58-0.19,1.73-0.26,2.03-1.16s1.99-0.49,1.99,0.07s0.53,0.86,1.35,0.86s1.16,0.6,1.65-0.45c0.49-1.05,0.45-1.39,1.58-1.24
        c1.13,0.15,1.43-0.07,2.1-0.11s1.39-0.38,2.14-0.38s1.46,0.22,1.69-0.49s-0.04-2.89-1.61-2.78s-2.85,0.07-3.42,0.49
        s-0.64,0.86-1.43,0.45c-0.79-0.41-0.94-0.94-1.58-0.64c-0.64,0.3-1.09,0.49-1.8,0.41C480.643-71.976,480.493-72.166,479.663-72.166
        z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M528.813-71.776c0,0-1.21,2.14-2.09,2.46
        s-0.37,1.63-1.81,1.67c-1.44,0.05-3.11-0.05-3.39,0.32s-1.3,0.28-1.11,1.16s1.86,0.18,2.55,1.39c0.7,1.21,1.02,1.58,2.69,2.64
        c1.67,1.07,2.5,0.93,3.57,1.81s1.58,1.86,3.48,2c1.9,0.14,2.55,0.37,3.06-0.6s1.11-1.16,2.37-1.16c1.25,0,1.76,0.18,1.72-0.46
        c-0.05-0.65-1.85-0.93-1.95-1.9s-0.51-0.88-0.7-2.55c-0.18-1.67-0.47-3.11-1.21-3.62c-0.74-0.51-1.48-2.09-2.27-3.34
        s-1.11-2.78-2.14-2.83s-0.93,1.3-1.53,1.72C529.463-72.656,529.373-72.516,528.813-71.776z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M519.173-120.796c0,0-1.13,0.6-1.61-0.07
        c-0.49-0.68-0.83-1.13-1.24-1.13s0.04,0.9-1.05,0.98s-1.46,0-1.5,0.3s-1.46,0.41-1.43,0.98c0.04,0.56,0.98,0.49,1.2,0.94
        c0.23,0.45,1.62,1.54,1.8,2.44c0.19,0.9,0.41,1.13,0.45,2.03c0.04,0.9-0.26,1.95,0.6,1.95s0.79-0.11,1.16-1.05
        c0.38-0.94,1.61,0.26,2.25-1.09s0.94-1.31,1.61-1.61c0.68-0.3,0.75-0.23,0.98-1.43c0.22-1.2,0-1.39-0.64-1.58s-1.09-0.68-1.24-1.05
        C520.373-120.576,519.883-121.176,519.173-120.796z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M529.983-107.766c0,0,0.11-1.16-0.3-1.8
        s-1.61-1.76-2.48-0.86c-0.86,0.9-0.26,1.35-1.43,1.61s-1.43,0.94-1.35,1.88s1.61,1.46,2.22,2.74c0.6,1.28,2.25,3.04,3.04,3.83
        s2.67,4.36,2.59,6.08c-0.08,1.73-0.22,3.23,0.53,3.9c0.75,0.68,0.64,1.43,1.5,1.43s3.15-0.07,2.97-1.95
        c-0.19-1.88-0.79-3.08-0.08-3.08s1.39,0.86,1.99,1.62c0.6,0.75,0.79,1.16,2.03,0.79c1.24-0.38,0.11-1.65,2.07-1.54
        c1.95,0.11,2.74-1.09,2.82-2.4s0-2.1,0.94-2.74s1.73-0.38,1.84-1.84c0.11-1.46,0.08-1.88-0.98-2.25c-1.05-0.38-1.73-0.75-1.77-1.76
        c-0.04-1.01,0.41-1.92-0.68-1.95c-1.09-0.04-2.85,0.41-2.78-0.56c0.08-0.98,1.69-0.41,2.1-1.35s0.75-1.13,1.5-1.13
        s1.39-0.41,1.5-1.28c0.11-0.86,0.22-1.09,0.9-1.5s2.22-0.56,2.25-1.43c0.04-0.86,0.41-0.98-0.75-1.24s-1.65-0.23-1.16-1.05
        s1.39-1.39,0.83-1.8s-1.2-0.07-1.58,0.45c-0.38,0.53-0.71,2.1-1.77,2.18c-1.05,0.08-2.14,0.26-1.69-0.71
        c0.45-0.98,1.2-1.99,0.45-1.99s-1.2,0.19-1.5,0.79s-0.75,1.13-1.35,0.75s-0.68-1.35-1.77-1.43c-1.09-0.07-3.76-0.56-3.87,0.15
        s0.04,2.14-1.54,2.18s-2.03-0.38-1.84,1.35c0.19,1.73,0.49,1.35,1.24,1.43s2.7,0.49,2.29,1.46c-0.41,0.98-1.31,0.86-1.28,2.14
        s-0.04,1.43-0.86,1.76c-0.83,0.34-0.41,1.46-2.4,1.16C530.393-106.036,529.943-106.226,529.983-107.766z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M495.323-165.606c0,0-2.36-0.09-2.92,0.57
        c-0.57,0.66-2.17,0.66-2.83,0.38c-0.66-0.28-0.22-0.9-1.2-0.75s-0.53,0.75-1.84,0.75s-0.98,0.83-2.14,1.16
        c-1.16,0.34-1.92,0.86-1.39,1.43c0.53,0.56,1.73,0.26,2.63,1.39s1.09,0.98,1.8,0.45s0.83-1.43,1.58-1.16s1.2,1.09,0.41,1.58
        s-0.98,0.9-0.94,1.69c0.04,0.79,0.26,1.13,1.39,1.05c1.13-0.07,1.39,0,1.77-1.13c0.38-1.13,0.79-1.76,1.16-2.18
        c0.38-0.41,1.73-2.44,1.58-1.09c-0.15,1.35-1.01,3.12,0.19,3.12s1.77-0.15,2.03-0.83s0.11-1.62,1.09-1.62s1.28-0.34,1.84-0.86
        s1.01-0.94,1.65-1.09c0.64-0.15,2.74,0,1.31,0.94s-2.33,0.64-2.59,1.62s-0.19,2.48,0.19,2.82c0.38,0.34,1.77,0.45,2.18-0.68
        c0.41-1.13,1.13-1.39,2.03-1.5s3.27,0.68,4.28-0.86s1.2-2.37,1.84-2.85c0.64-0.49,0.64-1.13,1.73-1.31
        c1.09-0.19,2.18-0.9,2.14-1.91c-0.04-1.01-0.79-0.26-1.13-1.05c-0.34-0.79-1.13-0.68-1.5-0.38s-0.34,1.39-1.39,1.5
        c-1.05,0.11-1.13-1.28-2.52-1.46c-1.39-0.19-0.86-1.35-2.22-1.35s-2.44,0.34-3,1.13s-2.89,0.26-3.57,1.2
        C498.293-165.976,497.543-165.536,495.323-165.606z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M492.883-155.196c0,0-1.46-0.45-1.65,0
        s-1.84,0.71-1.88,1.54c-0.04,0.83,0.56,1.35,0.9,1.46s1.16,1.09,1.46,0.11s0.64-1.24,1.65-1.24c1.01,0,0.83-0.34,1.84-0.68
        s1.16-0.26,1.5-1.01s1.73-0.94,1.31-1.35s-1.5-0.53-2.18-0.45s-0.94,0.94-1.46,1.46
        C493.863-154.816,493.863-155.156,492.883-155.196z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M493.483-146.066c0,0,0.04,0.3,0.68,1.35
        s1.16,1.99,1.61,1.31c0.45-0.68,0.3-1.31,0.86-1.24c0.56,0.07,0.41,0.68,0.3,1.31s0.11,1.28,1.13,1.05
        c1.01-0.23,1.35-0.15,1.58-1.24c0.23-1.09-0.23-1.61,0.9-1.61s2.7,0.34,1.84,1.24s-2.1,1.39-1.05,1.69
        c1.05,0.3,1.73-0.15,2.03,0.49s1.2,0.53,1.65-0.11c0.45-0.64,0.98-0.9,1.46-0.34c0.49,0.56,1.61,1.35,2.1,1.39s1.8,0.6,0.71,1.13
        s-2.22,1.5-3.12,0.68c-0.9-0.83-1.13-1.35-2.67-1.28s-3.23-0.45-4.51-0.04s-1.28,1.05-2.22,1.39s-0.98,0.9-0.79,1.43
        s1.24,1.76,1.73,1.99c0.49,0.23,1.2,0.49,1.5,1.13s2.59,0.56,3.83,0.49c1.24-0.08,3.23,0.19,3.79-0.49
        c0.56-0.68,1.05-1.5,2.33-1.24s0.68-1.24,2.07-0.68c1.39,0.56,1.54,1.01,2.4,0.64c0.86-0.38,1.54-0.94,2.07-0.94
        s1.77-0.45,1.77,0.49s0.11,1.73,0.86,1.69s0.34-1.54,1.43-1.35s0.3,1.61,1.35,2.25s1.91,0.79,2.59,0.23s1.28-0.75,1.91-0.83
        c0.64-0.08,1.05,0.11,1.69-1.05s1.31-1.69,2.18-2.48c0.86-0.79,1.61-1.09,1.91-2.07c0.3-0.98,1.09-0.98,1.2-1.99
        c0.11-1.01-0.19-0.79-0.9-1.5s-0.98-1.8-1.73-1.24s-0.9,2.1-1.8,2.03c-0.9-0.08-0.38-1.2-1.54-1.43s-1.28-1.28-1.61-1.69
        s0.6-1.05,0.98-1.54s0.98-2.97,0.98-3.6s0.45-0.71,1.24-1.13c0.79-0.41,1.61-0.83,1.05-1.2c-0.56-0.38-0.86-0.68-1.39-1.01
        c-0.53-0.34-1.31-0.34-1.5,0.07s-0.98,1.01-2.1,1.09c-1.13,0.07-0.94,0.98-2.03,1.13c-1.09,0.15-1.73,0.75-1.65,1.65
        c0.08,0.9,1.69,1.01,1.43,2.29s-0.79,1.5-2.1,1.54s-2.14-0.04-1.39,0.68c0.75,0.71,0.94,1.13,1.35,1.58s0.6,0.6,0.38,1.35
        s-0.08,1.65-1.16,1.43c-1.09-0.23-0.98-0.9-1.91-0.98c-0.94-0.08-1.32-0.15-2.03-0.83c-0.71-0.68-2.59,0.23-2.4-1.35
        s0.08-2.82,0.38-3.34c0.3-0.53,1.24-0.71,0.6-1.62c-0.64-0.9-0.71-1.35-0.71-2.21s-0.26-1.5-1.28-1.35s-2.25,0.75-2.29-0.45
        s0.53-0.38,0.53-1.58s0.34-1.88-0.53-1.91c-0.86-0.04-0.56-1.24-1.73-1.16c-1.16,0.07-0.75,1.13-2.07,1.13s-0.94,1.05-0.08,1.58
        c0.86,0.53,1.54,1.05,0.9,1.39s-1.28,0.15-2.18-0.56c-0.9-0.71-1.01-0.86-2.78-0.86s-1.28-0.19-1.88,0.98
        c-0.6,1.16-1.24,1.35-0.49,1.73c0.75,0.38,0.68,0.53,1.88,0.41s3.3-0.26,3.34,0.26c0.04,0.53,0.45,1.05-1.31,0.9
        c-1.77-0.15-3.12-0.56-3.3-0.11c-0.19,0.45-0.68,0.86,0.38,0.9c1.05,0.04,2.33,0.41,2.22,0.9c-0.11,0.49-1.24,0.38-2.48,0.3
        c-1.24-0.08-2.78,0.75-3.04-0.19s0.45-2.48-0.34-2.48s-0.75,1.39-1.95,1.5s-1.77-0.11-2.55,0.68s-1.69,0.56-1.65,1.28
        c0.04,0.71,0.75,1.01,1.43,1.01S492.843-147.196,493.483-146.066z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M532.683-135.596c0,0-0.79,1.01-1.69,1.09
        s-1.5-0.19-1.16,0.68c0.34,0.86,0.86,0.75,0.86,1.46s0.26,0.75,1.2,0.71s1.28,0.56,1.5-0.6s0.04-1.46,0.94-1.92
        c0.9-0.45,1.01-1.13,0.53-1.5c-0.49-0.38-0.3-1.39-1.09-1.39C532.993-137.056,533.623-136.076,532.683-135.596z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M522.363-168.116c0,0-0.26-0.64,0.15-0.98
        c0.41-0.34,0.64-0.71,0.6-1.16c-0.04-0.45-0.53-0.56-0.94-0.34s-1.31-0.34-1.54,0.38c-0.22,0.71-1.54,0.49-1.54,1.28
        s-0.22,1.73,1.01,1.95c1.24,0.23,1.73,0.38,1.54,1.77s-0.49,3.34,0.41,3.57c0.9,0.23,0.94,0.04,1.31,0.64
        c0.38,0.6,1.28,0.71,2.67,0.64c1.39-0.08,3.16,0.45,3.34-0.38c0.19-0.83,0.3-0.9,1.01-1.43s1.35-0.49,0.79-1.24
        s-0.71-1.32-0.11-1.69c0.6-0.38,1.31-0.94,1.69-0.45s0.34,0.56,1.09,0.53c0.75-0.04,1.54,0.56,1.46-0.26
        c-0.08-0.83-0.9-0.26-1.16-1.01s-1.28-1.8-2.48-1.88c-1.2-0.07-2.07-0.15-3.04-0.15c-0.98,0-3.87,0.08-4.13,0.53
        s-0.53,1.35-1.28,1.05C522.473-167.056,522.473-167.546,522.363-168.116z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M532.873-172.806c0,0-1.24-0.04-2.18-0.04
        s-2.78-0.34-3.04,0.26c-0.26,0.6-0.22,1.13,0.26,1.24c0.49,0.11,1.39,0.38,1.58,0.68c0.19,0.3,1.16,0.56,1.8,0.56
        s1.35-0.26,1.65,0.3c0.3,0.56,0.94,0.9,1.77,0.9s3.42,0.45,3.53-0.11c0.11-0.56,1.31-0.23,1.2-0.79s-0.9-0.45-0.9-1.2
        s-1.39-1.95-2.44-1.84C535.053-172.726,534.603-172.916,532.873-172.806z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M541.553-158.466c0,0-0.22,1.28-0.71,1.54
        c-0.49,0.26-0.6,0.6-0.45,1.2c0.15,0.6,0.56,0.71,0.64,1.58c0.08,0.86,0,1.43,0.68,1.62s1.43,0.53,1.69-0.3s0.86-0.56,0.86-1.69
        s0.3-2.82-0.15-3.38s-0.68-0.68-0.75-1.35c-0.08-0.68-0.41-1.62-0.79-1.13C542.193-159.886,541.853-159.696,541.553-158.466z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M512.823-159.846c0,0-1.5-0.6-1.5,0.11
        s-0.53,1.46,0.08,1.46c0.61,0,0.71-0.23,1.01,0.41s1.09,0.86,1.5,0.86s0.6-0.07,0.6-0.83
        C514.513-158.576,514.513-159.286,512.823-159.846z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M539.563-147.176c0,0-1,0.59-0.65,1.53
        s-0.18,0.88-0.77,1.35s-1.47,1.41-0.88,1.82c0.59,0.41,1.71-1.71,2.65-1.71s2.59,0.06,2.65-0.94s0.12-0.71-0.82-1.59
        C540.793-147.586,540.793-148.236,539.563-147.176z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M541.813-141.786c0,0-0.94-0.83-1.99-0.26
        c-1.05,0.56-1.54,0.49-1.77,1.09c-0.22,0.6-0.86,0.86-0.22,1.43c0.64,0.56,1.09,0.86,1.73,0.11c0.64-0.75,0.98-0.9,1.84-0.98
        C542.263-140.476,543.573-140.886,541.813-141.786z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M541.923-138.186c0,0-1.5-0.34-1.65,0.19
        s0.49,1.2-0.3,1.43s-1.28,0.56-0.98,1.09c0.3,0.53,0.86,0.53,1.69,0.45c0.83-0.07,1.65-0.23,3.38-0.15s3.23-0.56,4.51-0.04
        c1.28,0.53,1.39,0.45,2.18,0.45s1.61-0.68,1.61,0.15s-1.13,0.94-2.03,0.83s-2.44-0.53-2.67,0.08c-0.22,0.6-0.64,1.5-1.05,1.84
        s-0.75,0.3-1.24,1.16s-1.24,1.54-0.38,1.91c0.86,0.38,2.07,0.15,2.55,0.64c0.49,0.49,0.64,0.6,2.03,0.64s-0.08,1.62,1.35,1.54
        c1.43-0.07,1.54,0.04,1.73-1.05s1.77-0.56,1.88-2.14c0.11-1.58,0.19-2.37,0.98-2.74c0.79-0.38,1.13-0.04,1.13-1.2
        s1.05-0.71,1.24-2.07c0.19-1.35,0.26-1.39,0.98-1.92c0.71-0.53,0.04-1.46,0.6-2.25s1.24-1.31,0.56-1.88
        c-0.68-0.56-1.05-1.69-1.77-2.25c-0.71-0.56-1.13,0.07-1.28,0.79c-0.15,0.71-1.35,0.41-1.69-0.56c-0.34-0.98-0.56-1.61-1.73-1.76
        s-2.03-0.11-2.44,0.6s-1.05,0.9-0.3,1.8c0.75,0.9,1.39,1.88,0.86,2.25c-0.53,0.38-0.75,0.71-0.71,1.35s0.22,1.31-0.75,1.31
        c-0.98,0-0.94-0.75-0.79-1.65s-0.08-1.54-0.56-1.73c-0.49-0.19-1.05-0.04-0.98-1.43c0.08-1.39-0.22-1.8-0.9-1.73
        c-0.68,0.08-1.24,0.86-1.2,1.95s-0.04,3.08-0.9,3.15c-0.86,0.07-1.47,0.19-1.54,0.56
        C543.273-138.186,542.973-137.996,541.923-138.186z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M553.233-174.116c0,0-0.22,0.71-0.94,0.9
        c-0.71,0.19-1.35,0.94-0.68,1.2c0.68,0.26,1.28,0.23,1.24,0.68c-0.04,0.45-1.35,0.49-0.9,1.2c0.45,0.71,0.6,0.86,1.31,1.01
        s0.53,0.3,1.13,0.64s0.98,1.01-0.34,1.01s-0.86,0.56-2.18,0.56c-1.31,0-2.1-0.19-2.14,0.68c-0.04,0.86-0.75,1.69,0.26,2.03
        s-0.41,0.86,1.54,0.9c1.95,0.04,2.85-0.71,3.6,0.15s2.25,1.31,2.48,2.29c0.22,0.98,1.01,1.09,1.05,2.59s-0.34,2.18,0.68,2.59
        c1.01,0.41,1.84,1.24,2.25-0.04s0.9-0.9,1.13-1.62c0.22-0.71,1.73-0.3,1.61-1.09c-0.11-0.79-1.35-0.3-1.46-1.16
        c-0.11-0.86-0.53-0.9,0.08-1.65s0.86-0.64,0.86-2.14s0.04-2.07-0.68-2.44c-0.71-0.38-1.09-1.31-1.09-2.1s-0.11-1.46-0.45-1.65
        c-0.34-0.19-1.09-0.9-1.43-0.45c-0.34,0.45-0.64,1.31-1.43,1.31s-1.39-0.64-0.75-1.46c0.64-0.83,1.43-1.05,0.94-2.03
        s-0.9-1.65-1.95-1.46c-1.05,0.19-1.46,0.83-1.95-0.56C554.543-175.626,553.643-175.506,553.233-174.116z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M564.523-131.116c0,0-2.54-0.58-3,0.52
        s-0.58,1.67-1.5,2.36s-3,0.92-2.08,2.25s2.36,1.38,3.46,2.94c1.09,1.56,0.64,2.48,3.06,2.65s3.52-0.29,3.52-1.5
        s1.56-0.92,1.44-3.11c-0.11-2.19,0.81-2.13-0.23-2.83c-1.04-0.69-0.58-1.67-1.56-2.31
        C566.663-130.776,565.853-130.776,564.523-131.116z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M569.773-145.996c0,0,0.11-0.17-1.56-0.75
        s-1.61,0.46-2.25,1.5c-0.63,1.04-2.08,1.79-1.04,2.54c1.04,0.75,1.56,1.38,1.61,2.19c0.06,0.81,0.81,1.67,1.5,1.79
        c0.69,0.11,0.69,1.33,1.44,1.33s1.38,0.69,2.08,0.69c0.69,0,0.75-0.35,1.38-0.98c0.64-0.63,1.67-0.58,1.67-1.73
        s0.75-0.52,0.35-2.19s-0.23-1.61-1.9-2.08C571.383-144.146,571.443-143.866,569.773-145.996z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M578.993-140.346c0,0-3.05,0.12-3.23,0.75
        c-0.17,0.63,0.11,1.96-0.58,2.36s-0.17,0.69-0.06,1.85c0.12,1.15-0.81,1.73-0.06,2.13c0.75,0.4,0.81,0.92,0.81,1.39
        c0,0.46-0.4,1.61-0.98,2.19c-0.58,0.58-0.75,1.84-1.38,2.42c-0.64,0.58-0.86,1.67-0.92,2.13s0.06,2.42,0.06,4.04
        s0.98,1.04,1.56,1.79s1.04,0.52,1.27-0.4s0.64-1.1,1.56-1.04s-0.29,2.54,1.44,2.65s3.57,0.69,3.98-0.35
        c0.4-1.04,0.69-1.5,1.84-1.61c1.15-0.12,1.44,0.23,1.21,1.33s0.23,1.73,1.67,1.67s2.88-0.63,3.23,0.06s0.81,0.92,2.08,0.92
        s2.48,0.11,3.06-0.58s0.92-0.63,1.9-0.58c0.98,0.06-0.23,1.5,1.39,1.5s3.98,0.46,4.55-0.4c0.58-0.87,0.4-1.15,1.5-0.98
        s0.23,1.1,1.5,1.1s1.5,0.4,1.79,0.92s1.04,0.52,1.9,0.46c0.86-0.06,1.1,0.17,1.5-0.58s0.92-0.69,1.79-0.69s2.19,0.4,2.54-0.35
        s0.81-0.52,0.75-1.38c-0.06-0.87,0.92-1.21,1.56-1.27c0.63-0.06,1.73-0.29,0.86-0.75s-1.56-0.92-1.44-1.73
        c0.11-0.81,1.55-0.4,1.61-1.9s-0.98-2.19-1.73-2.25s0.35,0-2.13-1.33s-3.23-2.25-4.61-2.54s-2.25,0.12-3.63,0.23
        s-3.86-0.23-4.84-0.06c-0.98,0.17-1.9,1.38-2.83,1.44c-0.92,0.06-1.56,0.69-1.79,1.15s-1.73,0.12-1.96,0.98s-1.04,1.04-1.79,0.69
        s-0.92-2.14-1.96-2.42c-1.04-0.29-0.52-0.12-2.48-0.17c-1.96-0.06-2.31,0-3.17,0.06s-1.38-1.21-1.33-2.19
        c0.06-0.98,0.29-1.61-1.04-1.56c-1.33,0.06-0.87-1.44-2.19-1.56s-2.08-1.62-0.98-1.85c1.1-0.23,2.36,0.12,3.06,0.17
        c0.69,0.06,1.61,0.23,1.61-0.63c0-0.87-0.17-1.61-1.5-1.56c-1.33,0.06-3.52,0.35-3-0.87c0.52-1.21,1.5-0.92,1.27-1.96
        C582.003-140.516,581.133-140.456,578.993-140.346z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M593.363-149.216c0,0,1.05,0.58,0.93,1.55
        s-0.47,1.78,0.58,1.82c1.05,0.04,2.21,0,2.21,0.85s1.28,1.51-0.93,1.51s-4.96-1.12-5.93,0s-0.62,1.4-1.55,1.71
        c-0.93,0.31-1.59,0.77-1.47,1.78c0.12,1.01-0.93,2.64,0.27,3.1s1.32-1.16,2.17-0.89s-0.31,1.4,1.4,1.2
        c1.71-0.19,1.01-1.82,2.44-1.67c1.43,0.15,0.23,1.78,1.16,1.78s2.98-0.47,3.37,0.58s1.35,2.71,1.86,1.71s1.05-1.05,1.12-2.17
        c0.08-1.12,0.58-1.24,0.78-1.05c0.19,0.2,0,2.05,0.7,1.98s0.58-1.55,1.67-1.43c1.08,0.12,0.54,1.74,1.39,1.2
        c0.85-0.54,0.35-1.28,1.36-1.28s0.43,1.94,1.59,1.82c1.16-0.12,1.35,0.19,1.55-0.7c0.19-0.89,0.58-1.2,0-1.67
        c-0.58-0.46-0.16-0.81,0.81-0.7s1.63,0.62,1.32,1.08s-1.32,1.4-0.35,1.74s2.67-0.35,2.56,0.81c-0.12,1.16-0.39,2.52,0.62,2.64
        s0.93-1.4,2.52-1.55s1.43-1.05,2.33-1.09s0.58-1.63,1.94-1.82c1.36-0.2,3.06,0.74,3.06-0.78s0.78-2.21-0.27-2.56
        c-1.05-0.35-0.93-0.85-1.82-0.85s-1.82,0.5-1.82-0.5c0-1.01-0.12-1.71-1.16-1.71s-2.09-0.66-2.98-0.54
        c-0.89,0.12-2.71,0.58-3.68,0.12s-1.55-0.78-1.12-1.71c0.43-0.93,0.85-2.48,2.05-1.16c1.2,1.32,1.59,1.59,2.6,1.59
        s1.05-0.35,2.56,0.08c1.51,0.43,2.67,1.04,3.91-0.27c1.24-1.32,1.59-0.93,1.55-2.25c-0.04-1.32-0.85-1.01-1.09-1.9
        c-0.23-0.89,0.35-1.82,1.12-1.82s-0.13-0.19,2.43,0.18c2.55,0.38,3.38,0.23,3.46-0.6s0.08-0.83-0.9-1.43
        c-0.98-0.6-2.78-1.58-1.2-1.73s3,1.2,3.08-0.38s-1.58-1.28-1.2-2.1c0.38-0.83,2.25,0.3,2.33-1.35c0.07-1.65-0.3-1.5-1.05-2.33
        s-1.65-1.13-2.7-1.05s-1.8-0.68-2.48,0.08s-0.9,1.73-1.65,0.75s-1.13-2.18,0.08-2.25c1.2-0.07,1.73,0,1.73-1.65s0-2.03,1.2-2.18
        s2.63,0.08,2.33,1.35c-0.3,1.28-1.2,2.25,0.53,2.25s0.38,1.2,1.88,1.2s2.48,0.15,2.03-0.68c-0.45-0.83-2.18-0.68-2.25-1.65
        c-0.08-0.98-0.45-2.25,1.05-2.25s2.4,0.75,2.78-0.75s0.9-2.33-0.53-2.25c-1.43,0.08-2.63-0.45-2.03-1.2s1.8-0.45,2.93,0
        s0.75,1.8,1.73,1.8s2.03,0.6,2.33-0.38c0.3-0.98,1.95,0.45,1.8-1.28s-0.08-0.68-1.58-1.43s-1.2-1.88,0.3-1.95
        c1.5-0.07-0.45-2.93,1.05-2.7s2.03,0.75,2.48-0.6c0.45-1.35,1.13-2.03,2.55-3.6c1.43-1.58,1.88-2.03,3.16-3.68
        c1.28-1.65,1.88-1.58,2.25-2.85c0.38-1.28-0.6-1.28-2.18-0.9s-1.88,0.83-2.78,1.65c-0.9,0.83-0.68,0.98-1.73,1.58
        s-1.58,0.53-2.03,1.28s-2.1,1.43-2.1,0.45s0.75-2.1,1.88-2.18c1.13-0.08,1.65-0.3,2.33-1.35s0.3-1.5-1.2-1.35s-2.33,0.45-1.8-0.68
        c0.53-1.13,1.28-0.3,2.18-0.38s1.88,0.53,2.63-0.23c0.75-0.75,1.73,0.15,2.78-0.75c1.05-0.9,2.18,0.07,2.93-1.5
        c0.75-1.58,1.35-1.73,2.03-2.7c0.68-0.98,1.65-1.2,1.58-2.7c-0.08-1.5-0.9-1.88-2.33-1.88s-1.8-0.98-3.08-1.73
        s-2.03-1.05-3.68-0.98c-1.65,0.07-2.55,0.15-2.93,1.05c-0.38,0.9-0.53,1.73-2.03,1.65c-1.5-0.07-1.05-0.6,0-1.5s1.73-1.95,0.3-1.95
        s-2.48-0.23-3.38-1.05c-0.9-0.83-1.95-1.58-2.4-0.75s-0.6,2.48-1.8,1.2s-2.03-0.75-2.4-0.23s-0.75,1.35,0.22,1.88
        c0.98,0.53,1.65,0.9,0.75,1.28s-1.35,1.28-2.48-0.38c-1.13-1.65-1.28-2.55-2.33-2.55s-2.55-0.38-2.48,1.05
        c0.07,1.43,0.67,1.65,1.2,2.4s0.75,2.03,0.3,2.63s-1.58,0.45-1.8-0.83s0.08-1.88-1.35-2.1c-1.43-0.23-1.95-1.88-2.85-1.88
        s-2.48,0.45-1.13,1.35s1.8,1.13,1.58,1.58s-1.2,0.98-2.03-0.07s-1.05-1.88-1.73-1.13s0.08,0.68-1.13,1.13
        c-1.2,0.45-2.25,0.9-2.25,2.33s0.38,1.35,1.13,1.73c0.75,0.38,1.28,0.98,2.33,1.88c1.05,0.9,1.73,0.45,1.95,1.5
        c0.22,1.05-0.9,1.35-1.73,0.6c-0.83-0.75-0.45-1.65-2.55-1.73s-2.1-1.8-3.08-2.25c-0.98-0.45-1.88-3.83-3.15-3.3
        c-1.28,0.53-0.38,1.95-1.73,2.63c-1.35,0.68-1.8,1.95-0.53,2.18c1.28,0.23,2.55,0.38,2.33,1.35c-0.22,0.98-2.1,1.2-2.7,0.45
        c-0.6-0.75-1.05,0.6-2.4-1.13c-1.35-1.73-1.95-1.95-2.55-1.5s-0.45,1.95-2.25,1.8s-2.55-0.38-3.53,0.38
        c-0.98,0.75-1.88,0.6-1.95,1.58c-0.08,0.98,1.73,0.38,1.73,1.28s1.43,0.75,2.4,0.53c0.98-0.23,2.33-0.45,3.08-0.45
        s0.98,0.75-0.68,0.9c-1.65,0.15-1.28,1.28-2.48,1.35c-1.2,0.07-1.2,0.68-1.35,1.73c-0.15,1.05,1.58,1.13,1.2,2.48
        c-0.38,1.35-0.9,1.8,0.45,1.8s1.35,1.35,2.33,1.43c0.98,0.08,1.28-0.38,1.58-1.2c0.3-0.83,2.03-1.05,1.05-2.1
        c-0.98-1.05-0.45-1.2,0.68-1.65s1.88-0.6,1.88-0.15c0,0.45,1.28,1.58,0.22,1.8c-1.05,0.23-0.9,1.58-1.65,1.95s-1.13,1.28-1.05,1.95
        c0.08,0.68,0.38,0.83,1.88,1.05c1.5,0.23,2.85,0.45,3.08-0.45c0.22-0.9,1.13-1.5,2.25-1.5s2.03,0.23,2.03,0.9s1.05,0.38,2.33,0.38
        s1.95,0.9,2.7-0.75s1.95-1.2,2.33-2.4s0.6-1.05,1.05-2.63s0.53-1.88,1.58-1.8s0.75,0.38,0.15,1.35c-0.6,0.98-1.2,1.88-0.98,2.63
        c0.22,0.75,0.6,0.83,1.88,0.75c1.28-0.07,2.7-1.13,2.63-0.07c-0.08,1.05-0.3,1.35-1.8,1.28s-3-0.53-3,0.15s0.08,1.65-0.83,1.65
        s0.83,1.43-1.58,1.5c-2.4,0.08-3,0.3-5.33,0.3s-2.55-0.23-2.55,0.98c0,1.2,0.98,1.95,1.65,2.4c0.68,0.45,1.73,1.35,1.95,2.03
        c0.22,0.68,0,1.65,1.05,1.73s1.58,0.45,1.58,1.05s-1.5,0.9-2.48,0.9s-0.22-2.48-1.8-3.08s-3.38-3.68-4.43-4.28
        s-1.73,0.15-2.85-0.53c-1.13-0.68-2.1-1.43-2.7-0.9c-0.6,0.53-1.95,1.2-1.2,3.38c0.75,2.18,0.64,3.35,2.32,4.67
        c1.68,1.32,2.1,1.62,2.4,3c0.3,1.38,0.36,2.04,1.38,2.76s1.92,1.98,2.76,1.26s1.08-1.5,1.8-1.32s0.48,0.78-0.06,1.2
        s-1.62,1.32-1.62,2.16s-0.12,2.58-0.84,1.92s-0.54-1.38-1.98-1.86s-1.14-3.12-2.76-3.12s-1.62,1.26-2.88,1.26s-2.82-0.48-3.36,0.72
        s-1.62,1.5-1.92,2.28s-1.68,1.02-1.68,1.92s0.3,1.74,0.96,1.92c0.66,0.18,1.68-0.06,1.98-0.72s1.62-0.48,1.38,0.48
        c-0.24,0.96,0.18,2.04,0.78,1.62c0.6-0.42,0.48-1.92,1.38-2.4s0.9-0.72,1.38-1.68c0.48-0.96,1.2-1.26,1.44-1.08s-0.3,1.2-0.6,1.92
        s-1.08,1.08-0.48,1.5c0.6,0.42,1.38,0.36,1.2,1.02s-0.66,0.9-1.44,0.96c-0.78,0.06-0.9,0.42-1.14,1.08s-1.44,0.9-1.38,1.62
        s0.84,1.5,1.68,1.74c0.84,0.24,1.08,1.14,1.62,1.14s1.68-0.66,2.04-1.44s1.56-1.44,1.86-2.46s0.6-1.38,1.26-1.32
        s0.36,0.9-0.06,1.62s-1.08,1.32-1.44,1.98c-0.36,0.66-1.26,1.26-1.5,1.86s-0.66,1.08-1.92,1.08s-1.08,1.32-2.76,0.84
        c-1.68-0.48-2.76-2.46-2.76-3.72s-0.6-1.56-1.38-2.28s-1.32-1.68-2.16-1.26c-0.84,0.42-1.74,1.38-2.46,1.38
        S592.193-149.966,593.363-149.216z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M587.773-185.606c0,0-1.79-1.19-2.31,0
        s-1.94,0-1.49,1.34s0,1.05,1.49,1.34s1.79,0.6,1.79,1.34c0,0.75-0.97,1.27-1.87,1.12c-0.9-0.15-1.72-1.42-2.69-0.97
        c-0.97,0.45-1.12,0.6-1.64,1.49s-0.9,1.79,0.37,1.64s1.42,0.3,1.87,0.6s2.16,1.05,1.27,1.27c-0.9,0.22,0.22,2.31-1.42,2.09
        c-1.64-0.22-1.12-1.72-2.16-1.87c-1.05-0.15-0.9-1.79-2.02-0.82c-1.12,0.97-1.2,2.02-1.27,3.06s-0.37,2.24,0.75,1.94
        s1.27-0.82,2.69-0.82s2.46,0.22,1.34,0.6c-1.12,0.37-0.52,1.2-2.16,1.27c-1.64,0.07-2.24,0.07-1.72,1.19s0.75,0.82,2.39,0.82
        s3.13-0.15,4.4-0.15s1.64,0,1.79,0.82c0.15,0.82,2.02,0.22,1.94,0.97s0.07,1.12-1.49,1.12c-1.57,0-1.64,0.89-3.43,0.89
        s-3.58,0-3.43,0.9c0.15,0.89-0.45,2.46,0.6,2.98c1.05,0.52,0.08,0.97,1.27,0.97s2.61-0.15,2.54,0.52c-0.08,0.67-2.46,0-2.54,0.97
        s-0.37,1.57,0.45,1.79c0.82,0.22,1.72,0.3,1.87,0.97s1.05,1.12,1.72,1.12s0.22-1.19,1.64-1.19s1.64-0.22,1.64-1.12
        c0-0.89-0.67-2.61,0.3-2.99c0.97-0.37,0.89,0,0.67,0.97c-0.22,0.97-0.08,2.39,0.75,2.54c0.82,0.15,2.16,1.72,2.54,0.82
        c0.37-0.9,0.97-1.34,1.57-1.34s0.75-0.82,0.75-1.79c0-0.97-1.12-2.76,0.3-2.76s2.02,0.97,2.54-0.15c0.52-1.12,0.97-1.34,2.09-1.27
        c1.12,0.07,1.42,0.15,1.94-0.6s1.87,0.22,2.09-0.75c0.22-0.97-0.67-3.51-1.72-3.28c-1.04,0.22-1.79-0.3-2.24-1.27
        s-0.89-0.75-0.22-1.72c0.67-0.97,0.6-1.94-0.22-2.01c-0.82-0.08-0.9-0.74-0.52-1.94c0.37-1.2-0.3-1.94-0.9-1.94
        s-0.08,1.27-1.34,0.97c-1.27-0.3-0.6-1.42-1.64-1.57c-1.04-0.15-1.49-1.19-1.27-1.94s0.97-0.74-0.45-1.42
        c-1.42-0.67-1.79-0.75-1.57-2.46s0.82-2.09,0.07-2.76c-0.74-0.67-0.75-2.91-2.31-3.13c-1.57-0.22-2.99,0.37-2.09,1.12
        s0.9,1.64,0.37,1.79C588.893-186.126,589.793-184.866,587.773-185.606z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M570.633-178.166c0,0-2.8,0.96-1.66,1.49
        s0.18,1.4,1.23,1.84s1.05,0.88,1.66-0.18c0.61-1.05,1.93-0.7,1.93-1.75s-0.79-0.7-1.14-1.4
        C572.303-178.866,571.513-178.776,570.633-178.166z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M572.383-162.556c0,0-1.14-0.79-1.58-1.67
        s-1.31-0.88-1.58-0.18s-0.44,1.58-1.4,2.19c-0.97,0.61-1.66,0.96-0.79,1.75c0.88,0.79,1.93,0.7,1.93,1.58s0.35,1.4-0.7,1.58
        s-1.49,1.49-0.17,2.02s1.93-0.18,2.54,0.53s3.33,0.44,3.42-0.35s1.14-0.7,1.14-2.1s1.58-1.84,0.79-2.63
        c-0.79-0.79-1.49-0.18-2.37-1.05C572.733-161.766,573.093-162.036,572.383-162.556z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M573.613-152.836c0,0-1.49,0-3.07-0.09
        s-2.8,0.44-1.14,1.23s0.97,2.1,2.37,2.19s4.03,0.88,4.47,0.09c0.44-0.79,1.14-0.96,2.02-1.31c0.88-0.35,1.32-1.49,0.18-1.49
        C577.293-152.216,576.773-153.006,573.613-152.836z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M585.883-149.326c0,0-1.75-0.7-1.93,0.26
        c-0.18,0.96-0.61,2.19,0.44,2.54s0.7,1.58,1.31,2.02s1.14,0.09,1.14-0.79c0-0.88,1.32-0.79,1.32-1.93
        S588.423-148.446,585.883-149.326z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M712.083-36.846c0-1.05,0.1-0.65-1.1-1.55
        c-1.2-0.9-1.85-2.3-3.21-2.25c-1.35,0.05-2.15,0.8-2.15,1.95s1.7,1.45,1.35,2.15c-0.35,0.7-1.6,1.9-2.1,0.45s0.05-2.25-0.95-2.25
        s-1.35-0.45-1.65,0.35c-0.3,0.8-1.25,1.85-1.75,0.7s-0.05-1.65-0.4-2.3c-0.35-0.65,1.05-1,1.35-1.95s-1.1-1.15-1.6-0.65
        s-0.2,1.75-1.25,1.6s-1.5,0.55-2.4-0.35s-4.1,0-3.2-1.3c0.9-1.3,2.71-0.6,2.5-2c-0.2-1.4-0.6-2.5-2.2-2.6c-1.6-0.1-0.9,0-2.05-1.5
        c-1.15-1.5-2.5-2.2-2.65-0.9s1.65,4.16,0.3,4.11c-1.35-0.05-0.55-1.15-1.3-2.5s-1.7-1.95-1.85-0.95c-0.15,1,1.6,2.9,0.15,2.7
        c-1.45-0.2-0.2-2.45-1.6-2.8c-1.4-0.35,0.3,2.65-1.7,1.6c-2.01-1.05-0.85-2.6-2.16-2.85c-1.3-0.25-1.45-1.25-1.65-0.7
        s0.1,1.6-0.85,1c-0.95-0.6-0.85-1.1-0.75-2.4s-0.35-1.9-1.35-1.7s-0.45-0.35-1.8-0.25s-2.55,1.85-3.76,1.15
        c-1.2-0.7-0.2-1.35,0.55-1.5s1.55-0.8,0.8-0.95s-3.61,0.5-3.81-0.25s1.05-0.6,2.15-0.65c1.1-0.05,0.65-1.8,1.5-1.8
        s1.4-0.45,1.1-0.95s-1.25-0.8-2-0.4s-2.96,1-2.8-0.1c0.15-1.1-0.6-2.6,1.55-2.35s1.91,1.2,3.36,1.3s2.2-0.05,3.5,0.15
        s2.65,0.15,2.96-0.65c0.3-0.8-0.15-1.35-1.2-1.4s-2-1.55-3.26-1.55s-2.75-0.45-3.3-0.15s-1.5,0.85-2.6,0.65s-3.1-0.45-3.41,0
        c-0.3,0.45-2.55,0.4-2-0.25s2.4-1.15,3.8-1.25c1.4-0.1,3.1,0.15,3.35-0.65s0.25-0.9,1.15-0.95c0.9-0.05,2.7,0.7,2.9-0.65
        s-0.35-1.85-1.15-2.25c-0.8-0.4-1.4-3.46-2.5-2.75c-1.1,0.7-0.4,3.11-1.7,2.96s-2.3-0.4-2.76,1s-0.2,2.1-1.2,2.2s-2.05-0.2-2.6-0.1
        s-1.7,0.45-1.85-0.55c-0.15-1,0.2-2.5,1.5-2.75s1.5-1.35,2.35-1.6s1.55-0.25,2-1.3s1.75-1.95,0.8-2.5s-2.2-0.65-3-1.1
        s-2.55-0.95-2.9-0.45c-0.35,0.5,0.1,1.35-0.75,1.2s-1.7-0.65-2.2-0.35s-1.95,0.7-2.15,1.3c-0.2,0.6,0.35,2.05,0.05,3.06
        c-0.3,1-1.45,2.1-2.05,1.8c-0.6-0.3,0.2-1.65-0.85-2.2c-1.05-0.55-0.15-1.85,0.9-2.85c1.05-1,1.7-1.3,2.2-2.35
        c0.5-1.05,1.25-2.15-0.75-2.25s-1.65,1.95-3.2,2.3c-1.55,0.35-2.15,1.2-2.4,1.9s-1.2,1.45-1.35,0.4c-0.15-1.05-0.55-2.25,0.5-2.95
        s1.3-1.85,2.55-2.55s2.41,0,2.16-1.35s-1.2-1.8-1.9-1.9s-1.15-1.7-2.8-1.7c-1.65,0-1.85,1.1-2.4,2.05c-0.55,0.95-0.5,2.65-1.2,3.15
        s-0.65-0.2-1.75-0.35s0.35-2.25-0.2-3.21s-1.2-0.7-1.4-0.15s0.05,2.5-0.9,2.05s0.15-1.9-0.25-2.45c-0.4-0.55-1.15-1.5-1.65-0.85
        s0,3.25-0.9,3.06c-0.9-0.2-1.65-1.75-1.2-3s0.2-1.1,1.1-1.7c0.9-0.6,1.2-1.05,1.15-1.9s-0.94-0.61-2.17-0.7
        c-1.23-0.08-1.39-0.08-1.8,1.06s-0.08,1.88-0.98,2.29c-0.9,0.41-1.22-0.08-1.06-1.31s0.73-1.96-0.16-1.88s-1.72,2.13-2.29,1.23
        s0.16-2.13,0.98-2.45c0.82-0.33,3.93,1.23,3.77-1.06s0.16-3.03-1.72-2.86s-2.86,0.74-3.27-0.49c-0.41-1.23-1.07-1.55-2.05-1.55
        s-2.13-0.41-3.52,0s-3.03,0.98-3.27,1.8s0,1.72,1.39,1.88s1.31,0,1.96,0.65s1.96,0.74,1.39,1.31s-1.96,0.41-2.62-0.16
        c-0.65-0.57-1.88-1.06-2.53-0.9s0.33,0.9,1.06,1.72c0.74,0.82,1.47,1.23,1.31,2.04s-0.82,1.31-1.39,0.33
        c-0.57-0.98-1.56-2.04-1.88-1.47c-0.33,0.57-0.98,1.47-1.72,0.74s0.65-1.47,0.33-2.54c-0.33-1.06-0.49-0.9-1.55-1.31
        s-1.64-2.37-2.21-1.55s-0.16,2.86-0.9,3.19c-0.74,0.33-0.98,0.16-1.23,1.39c-0.24,1.23-0.82,1.31-1.47,0.74
        c-0.66-0.57,0.65-1.06-0.41-2.37c-1.06-1.31-0.98-2.46,0.08-3.19s1.96-0.82,2.37-2.13s0.82-2.13-0.41-2.78s-1.31-1.8-1.31-2.54
        s-0.08-0.41-0.66-1.64c-0.57-1.23-1.55-1.8-1.64-2.62s0.41-1.55-1.06-1.55s-1.06-1.14-2.7-1.14s-0.74,0.9-2.45,0.98
        c-1.72,0.08-2.04,0.9-2.21,2.13c-0.16,1.23,0.65,2.21-0.41,1.88s-1.39-1.72-2.54-1.47s-0.98,0.74-0.98,1.88
        c0,1.15,0.82,0.9,1.88,1.64s2.29,1.23,1.47,1.72s-1.64,0.49-2.37-0.33c-0.74-0.82-1.31-1.64-2.37-1.31s-0.16,1.31,0.49,1.55
        c0.66,0.24,1.64,0.57,1.47,1.39c-0.16,0.82-0.82,1.47-1.39,0.57s-1.15-1.31-1.72-1.39c-0.57-0.08-2.05-0.73-2.21-0.08
        c-0.16,0.65-1.15,1.39-1.15,2.7s0.49,1.39,1.55,1.72s2.21,0.9,2.29,1.96c0.08,1.06,1.06,1.8,0.49,2.29s-0.74-0.82-1.88-0.98
        c-1.15-0.16-1.31-0.49-2.37,0.08s-1.47,0.82-1.06,2.04c0.41,1.23-0.33,1.72,1.47,2.7s1.96,1.06,2.45,1.47s2.04,1.55,0.66,1.88
        c-1.39,0.33-2.13,0.57-2.13,1.39s0.57,1.96,0.33,2.37s-0.33,0.33-0.82-0.16s-1.39-1.15-2.29-0.98c-0.9,0.16-0.33,0.82-1.31,1.06
        s-1.88,1.23-2.7,0.74s0.24-0.74,1.06-1.47s0.16-1.14,1.72-1.55s2.7-0.25,2.7-1.72s-0.74-1.15-1.72-1.8s-1.14-1.06-1.55-2.94
        s-2.13-3.35-0.98-4.42c1.15-1.06,1.47-1.15,1.47-2.05s0.49-0.74-0.33-1.64s-1.23-1.55-0.08-2.62c1.15-1.06,1.88-0.98,2.04-2.54
        c0.16-1.55,0.08-1.63,1.23-2.29c1.15-0.65,2.29-1.55,2.62-2.21c0.33-0.65,1.47-1.64,2.21-2.04c0.74-0.41,0.16-1.23-1.55-1.23
        s-2.13-0.16-4.5-0.16s-2.7-0.33-3.93,0.65s-1.31,1.39-2.62,1.64c-1.31,0.24-0.06,0.8-2.89,2.2c0,0-1.95,1.28-2.55,2.33
        s-1.73,2.63-1.8,3.38c-0.08,0.75-0.68,1.95-1.13,2.03c-0.45,0.07-1.5,0.3-1.5,1.88s0.6,1.95-0.15,2.48s-1.65,0.23-1.65,1.88
        c0,1.65,0.83,2.33-0.08,3.3c-0.9,0.98-1.35,0.83-1.28,2.03s-0.83,1.88,0.9,1.88s3,0.15,3.68,0.68s0.83,0.68,2.1,0.6
        c1.28-0.07,2.18-0.68,2.85,0.45c0.68,1.13,0.6,1.5,1.35,1.5s1.2,0,0.98,0.68c-0.22,0.68-0.22,0.98-1.88,0.98s-3.3,0.38-3.6-0.3
        s-0.6-1.35-2.48-1.28s-1.65,0.3-2.03,0.68s-1.05,0.07-0.6,0.75s0.83,1.13,0.83,1.88s1.28,2.85,1.95,3.08s1.73,0.07,2.25,0.9
        s0.75,1.2,2.1,1.2s1.43-0.07,1.73-0.68c0.3-0.6,0.98-0.68,2.33-0.68s1.05,0.3,1.05,1.43s0.45,1.65,0.98,1.88
        c0.53,0.22,1.43,1.65,2.7,1.5s3-1.28,3.83-0.38s1.5,1.65,3.38,1.5s2.55-0.38,3.3-0.38s1.73,0.3,2.85,0.9s1.5,0.68,2.63,0.53
        s1.2-0.75,0-1.35s-2.25-0.68-2.48-1.35c-0.22-0.68,0-0.75,1.2-0.45s2.25,0.6,3,1.28s1.5,0.75,2.18,0.6s1.13-1.65,2.33-0.98
        s1.05,1.35,2.33,1.35s2.33,0.07,2.4,0.68s0.08,1.58-0.9,1.73c-0.98,0.15-0.53-0.9-2.03-0.83s-3.3,0.75-1.5,1.35s1.88,0.9,2.25,1.8
        c0.38,0.9,1.43,0.9,2.18-0.3s1.5-1.65,2.03-2.18s1.28-0.68,2.18-1.28c0.9-0.6,1.65-0.15,1.05-1.73c-0.6-1.58-0.15-1.43-1.09-1.92
        s-1.65-0.94-1.28-1.61c0.38-0.68-0.11-1.88,1.16-1.92c1.28-0.04,1.39,0.94,1.5,1.84s0.19,1.5,1.69,1.76s3.08,1.99,2.93,3.27
        s0.71,1.84,2.03,1.8c1.32-0.04,1.88-0.22,1.8,0.53s0.19,1.31,1.01,1.31s0.56,0.53,1.05,1.13s0.49,1.05,1.5,1.35
        s0.88,0.46,1.58,0.86s1.1,1.65,0.4,1.95c-0.7,0.3-3.2-0.25-3.86,0.8c-0.65,1.05-1.8,1.75-1.35,2.6s0.55,0.8,2.2,0.75
        s1.25-0.8,2.35-1.2s1.5-0.45,2.35-0.6s1.75-1.75,2.4-1.2s0.9,1.25,1.1,2.15c0.2,0.9,0.8,1.6,1.55,1.75s0.35,1.1,1.1,1.05
        s2.2-1.8,1.95-0.3s0.85,3.1,1.7,3.05c0.85-0.05,2.3-0.2,2.55,0.55s0.7,1.4,1.35,2.7c0.65,1.3,2.25,2.55,2.25,3.56s1,1.55,0.8,2.75
        s0.05,2.65-1.35,3.05c-0.78,0.22-0.85,1.2-1.06,2.15c0.2,0.13,0.42,0.26,0.66,0.4c0.79,0.49,0.94,0.49,1.8,0.6s0.79,0.41,1.65,0.53
        c0.86,0.11,1.28,0.19,2.44,0.19s2.33,0.3,2.37-0.45s-0.34-1.46,0-2.29s1.13-0.9,1.65-0.45c0.53,0.45,1.09,1.39,1.91,1.43
        c0.82,0.04,1.77,0.41,1.95,0.9s-0.34,1.61,0.75,1.58c1.09-0.04,2.33-0.68,2.63-0.49s1.46-0.53,1.91-0.26
        c0.45,0.27,0.38,1.31-0.49,1.31s-1.16,0.3-0.68,0.64s1.09,0.64,0.79,1.09c-0.3,0.45-1.35,0.41-1.47,0.79
        c-0.11,0.38-0.38,0.56-1.43,0.56s-0.23,0.6-1.61,0.64s-2.14,0-2.44,0.75s-0.49,1.16-1.39,1.16s-1.8,0.3-1.58-0.53
        s0.6-2.03,0.45-2.44c-0.15-0.41-0.49-2.03-1.61-2.4c-1.13-0.38-2.44-0.68-3.15-0.45c-0.71,0.22-1.16,0.34-1.88,0
        s-1.46-0.79-2.14-1.05c-0.39-0.15-0.94-0.39-1.52-0.63c-0.18,0.29-0.41,0.54-0.78,0.68c-1.75,0.65-2.05,1.05-3.71,1.45
        s0.35,2.1-1.9,2.85s-1.8,2.3-0.55,3.15s1.75,1.7,2.45,2.2s1.3,0.65,1.3,1.6s0.7,1.95-1.35,2.05s-0.85,1.1-2.91,1.1
        c-2.05,0-3.05,0.2-3.8,1.25s-0.45,1.65-0.1,2.2s0.9,1.15,0.2,1.2s-0.35,1.55-1.2,1.25c-0.85-0.3,0.15-1.65-0.8-2.6
        s-1.2-1.25-2.35-1.35c-1.15-0.1-1.5-0.95-2.76-1.15c-1.25-0.2-0.5-1.55-2.4-1.55s-3.46,0.4-3.46,1.3s0.4,2.35-0.45,2.55
        s-3,1.6-3.05,2.7s-0.1,4.51,0.9,5.06s1.45,1.7,2.8,1.75s2.15,0.3,2.91,0.65c0.75,0.35,2.05,0.35,2.8-0.4s1.8-1.55,3.1-1.55
        s1.55-1.2,3-1.2s1,1.4,2.61,1.35c1.6-0.05,1.65-0.2,2.2-1s2.06-1,0.5-1.8c-1.55-0.8-2.26-1.7-1.85-2.2c0.4-0.5,1.05-0.1,1.65,0.65
        c0.6,0.75,1.4,0.8,1.9,0.85s0.5,0.75,0.5,1.45s0.85,1.1,1.2,0.95c0.35-0.15,0.7-0.65,0.65-1.4s0.6-1.1,1.4-1s1.8,1.1,1.8,2.15
        s-0.8,1.45,1.65,2.85s2.2,2.96,3.3,3.46s1.45,1.65,2.65,1.7s1.5-0.1,2.65-0.05s3.41,1.1,1.7,1.3c-1.7,0.2-1.15,1.05-2.4,1.15
        s-0.85,1.45,0.1,1.95s1.85,1.1,2.5,1.85s1.2,1.4,1.85,0.7c0.65-0.7,1.96-1.1,2.01-0.5s-0.9,1.9,0.4,1.9c1.3,0,1.35,1.15,2.65,1.1
        c1.3-0.05,5.01-0.75,5.66,0.9c0.65,1.65,1.15,2.55,2.4,2.95s2.25,1.25,3.25,1.55s1.25,1.15,3.66,0.8c2.4-0.35,3.86-0.85,5.11-0.25
        s2.1,0.55,3,1.5s2.1,0.9,2.7,0.9s2.55,0,2.35-1.15s0.35-2.35-0.65-2.55s-1.55-0.35-2.35-1.5s-0.95-1.55-1.75-2.5
        s-0.85-0.45-2.35-1.35s-2.01-2.5-3.41-2.6c-1.4-0.1-1.15-1.25-2.85-1.55c-1.7-0.3-2.55-0.55-3.46-1.55s-2.25-1.75-2.9-2.7
        s0.15-1.55,1.4-1.3s1.75,1.45,2.55,1.4s2.16,1.05,2.16-0.15s-0.45-2.01,0.35-1.75c0.8,0.25,0.85,0.9,1.25,1.8s0.35,1.45,1.65,1.85
        c1.3,0.4,1.3,0.4,2.05,1.05s1.2,1.95,2,1.65s1-0.95-0.25-1.8s-1.7-1.85-0.9-1.95s1.75,0.35,2.3,1.4s0.05,2.2,1.2,2.45s1.45,0,2.4,0
        s1-0.2,1.35,0.7s1.96-0.1,2.2,1.05c0.25,1.15-0.05,2.3,1.2,2.5s1.2-0.05,0.75-1.15s0.85-1.35-0.2-2.35s-1.5-1.5-0.9-1.6
        s0.95,0.35,1.55,0.55s1.1-0.7,0.45-1.25c-0.65-0.55-1.1-0.8-1.45-1.75c-0.35-0.95-0.65-1.9,0.15-1.85c0.8,0.05,0.45,0.4,1.15,0.7
        s0.95,1.15,1.25-0.15s0.4-2.71-0.5-2.9c-0.9-0.2-0.95-1.85-2.25-2.35s-1.25-1.45-2.55-2.1s-1.4-0.45-2.3-1.4
        c-0.9-0.95,0.15-1.7,0.75-2.1c0.6-0.4,1.91-1.1,0.8-1.25c-1.1-0.15-3.41,0.65-3.41-0.5s0.45-2.8-0.8-3s-1.55,0-1.65,0.45
        c-0.1,0.45,0.3,1.4-0.75,1.15s-1.05-0.9-2.3-0.9s-1.35,0.5-2.05-1s-0.4-1.9-1.4-2.6c-1-0.7-1.45-1.25-1.65-2.45
        s-0.9-2.45-1.85-2.45c-0.95,0-1.96,0-2.2,0.45c-0.25,0.45-0.65,0.1-1.33-0.99s-0.41-1.01-1.16-2.06s0-2.07-1.24-2.22
        s-4.32-1.24-1.73-1.2s2.63-0.45,3,0.34c0.38,0.79-0.22,2.03,1.01,2.25c1.24,0.22,3.76,1.31,3.76,0.07s-0.19-1.28-1.31-2.07
        c-1.13-0.79-1.43-1.24-1.69-2.07s-0.98-1.24-2.03-1.24s-2.7,0.04-1.88-0.71c0.83-0.75,2.52-0.79,3.68-0.38s2.55,2.48,2.82,0.86
        s1.13-1.92,2.03-1.2c0.9,0.71,0.11,2.14,1.99,2.48s2.89,0.34,3.38,1.2s-0.19,1.73,1.46,1.92s1.15-0.8,2.56-1.5
        c1.4-0.7,1.05-1,1.55-1.9s1.2-1.6,1.3-0.65s0.1,2.95-0.75,3.31s-1.95,0.2-2.1,1.55c-0.15,1.35,0.45,1.5,1.45,2s1.1,0.95,1.05,1.7
        s0,1.35,1.1,1.2s2.2-1.05,2.3-0.05s-0.1,1.65,0.7,1.7s-0.25,2.15,1.6,2.2s2.25-1,2.7,0.05s1.5,1.9,1.5,0.55s1.9-0.65,1.35-2
        s-0.7,0.25-1.3-1.55s-1.4-0.35-1.6-2.3c-0.2-1.95-0.35-2.6-1.15-3.06c-0.8-0.45-1.3-1.4-0.3-1.45s1.35,0.85,1.75,1.65
        s1.8,2.35,2.05,0.95s1-1.65,1.5-2.15s0.95-0.4,1-1.65s1.76-0.7,0.65-1.65c-1.1-0.95-2.26-1.1-2.15-1.9s0.6-0.95,1.45-0.8
        c0.85,0.15,1.5,0.4,1.6-0.7s-0.85-1-0.2-1.75C711.433-36.146,712.083-35.786,712.083-36.846z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M623.223-105.516c0,0-1.16-0.04-1.95,0.04
        c-0.79,0.07-1.65-0.08-2.52-0.79c-0.86-0.71-1.28-0.79-2.29-0.79c-1.01,0-2.22,0.23-2.59,0.64c-0.38,0.41-0.68,1.43-0.6,2.18
        s1.61,1.31,2.22,2.74c0.6,1.43,0.64,2.25,1.01,3.04c0.38,0.79,0.15,2.03,1.5,2.18s1.58,0.56,2.22-0.15
        c0.64-0.71,0.68-1.05,2.14-1.05s2.44,0.08,3.72,0.08c1.28,0,1.5,0,3,0.07c1.5,0.08,2.97,0.3,2.97-0.86s-1.65-1.24-2.1-2.07
        c-0.45-0.83-1.69-2.78-2.52-3.15C626.603-103.786,625.183-105.586,623.223-105.516z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M621.313-63.376c0,0-0.15,0.68-0.6,0.9
        s-1.01,0.11-1.05,0.56s0,0.53,0.45,0.56c0.45,0.04,0.79,0.22,0.79,0.79c0,0.56-0.22,1.46-0.68,1.5c-0.45,0.04-0.9-0.04-1.05,0.41
        c-0.15,0.45,0,0.75-0.6,0.94s-0.79,0.38-1.09,0.75c-0.3,0.38-0.71,1.01,0,1.16s0.98,0,0.94,0.64s1.2,1.2,1.28,0.26
        c0.08-0.94-0.11-1.13,0.41-1.39c0.53-0.26,1.16-0.15,1.43-1.01s0.19-1.16,0.56-1.39c0.38-0.22,0.6-0.34,0.6-1.16s-0.11-1.46,0-1.84
        s0.11-0.53,0.53-0.64c0.41-0.11,0.68-0.04,0.75-0.68c0.08-0.64,0.41-1.43-0.11-1.5c-0.52-0.07-0.75-0.07-0.9,0.45
        s-0.22,1.16-0.71,0.79C621.763-63.646,621.503-63.796,621.313-63.376z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M628.563-60.866c0,0-0.75-0.19-0.9,0.41
        s-0.53,0.64-0.75,0.71c-0.23,0.07-0.49,0.71-0.08,0.75s0.86-0.15,1.05,0.6s0.64,0.83,1.01,0.68c0.38-0.15,0.41-0.26,0.45-1.01
        c0.04-0.75,1.24-0.15,1.28-1.01c0.04-0.86-0.15-1.24-0.6-1.09C629.573-60.676,629.463-60.716,628.563-60.866z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M633.323-49.146c0,0-1.88-0.64-2.29,0.45
        s-0.86,2.07-1.47,2.48c-0.6,0.41-0.26,1.65-0.94,1.92c-0.68,0.26-0.79,0.9-0.79,1.61s-0.26,2.25,0.26,2.78
        c0.53,0.53,0.56,0.15,0.71,1.09s1.35,1.58,2.1,1.58s4.28,0.86,4.62-0.26c0.34-1.12,0.83-0.79,1.54-1.32
        c0.71-0.53,1.5-0.6,1.69-1.39s1.05-0.83,1.05-1.61s0.34-1.28-0.3-1.73s-0.75-0.38-0.79-1.43s0.86-2.4,0.19-2.67
        s-1.84-0.45-2.03-0.9s-0.64-0.9-1.24-0.83C635.053-49.296,634.113-48.926,633.323-49.146z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M639.373-52.306c0,0-0.38-0.64-0.98-0.68
        s-1.39-0.22-1.39,0.49s-0.49,1.09,0.34,1.43s0.75,0.83,1.24,1.13s1.35,0.71,1.61,0.26s0.6-0.79,0.15-1.01
        C639.903-50.916,639.673-51.666,639.373-52.306z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M645.153-46.636c0,0-0.56,0.19-0.98-0.3
        s-1.2-1.35-1.73-1.2c-0.53,0.15-0.75,1.09-1.35,1.09s-1.2,1.24-0.34,1.54s1.5,1.24,1.65,1.8s0.83,0.79,1.99,0.79
        s2.18,0.19,2.1-0.86s0.26-1.01,0.94-1.16c0.67-0.15,0.83-0.79,0.38-1.01s-1.39,0.34-1.58,0.11
        C646.053-46.066,645.873-46.746,645.153-46.636z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M576.293-9.176c0,0,0.97,4.27-0.67,4.57
        c-1.65,0.3-2.25,0-2.62,0.9s-2.84,1.05-2.84,2.1s-0.15,2.47,1.72,2.4s1.57-1.2,2.99-1.2s2.1,0.37,2.99-0.22
        c0.9-0.6,1.05-0.3,1.8,0.45s0.67,1.8-0.22,1.8s-0.9,1.12-0.82,1.65c0.08,0.52-0.82,1.2,0.08,1.72s0.75,1.57,1.65,1.65
        s1.35,0.38,1.65-0.52s0.97-1.2,1.8-1.42s1.72,0.07,1.87-1.35s0-2.85,1.95-2.85s3.29-0.9,3.44-2.25s0.6-2.02,1.42-2.17
        s1.65,1.2,2.99,1.27c1.35,0.08,2.77,1.2,2.7,2.02s0.9,1.42,1.72,1.42c0.82,0,0.08,1.42,1.72,1.42c1.65,0,1.05,0.97,2.4,0.97
        s0.9,1.2,1.95,1.27c1.05,0.08,0.45-1.05,1.35-1.35s1.65-0.45,1.72-1.2s1.2-0.67,1.2-1.57s-1.5-0.75-1.87-1.8s-0.75-1.42-0.97-1.5
        s-0.22,0.97-1.72,0.52s-1.5-0.38-2.17-0.08s-2.1,1.57-2.02,0.3c0.08-1.27,1.72-0.53,1.5-1.95s0.3-2.4-0.6-3.14
        c-0.9-0.75,0.22-2.17-1.65-2.55c-1.87-0.37-0.82-1.2-2.62-1.2s-2.99-4.04-5.16-4.72c-2.17-0.67-3-0.22-3.15-1.2
        c-0.15-0.97-0.97-2.25-1.57-2.32c-0.6-0.08-0.97,0.45-1.35,1.27s-1.5,0.3-1.35-0.75s0.6-0.9,0.08-2.17
        c-0.53-1.27-0.6-1.5-1.57-1.95c-0.97-0.45-3.07-1.72-3.22,0s-0.97,2.99-1.27,3.29s-1.12,2.02-1.12,2.69s-0.6,1.35-0.97,2.32
        s-0.67,2.1-0.67,2.99C576.743-10.676,576.063-10.676,576.293-9.176z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M590.293,11.424c0,0-0.56,1.05-1.43,1.43
        s-1.24,0.49-1.28,1.2c-0.04,0.71,0.9,0.41,0.98,1.61s0.56,1.5,1.91,1.5s3.23,0.45,3.64-0.34s1.2-0.41,1.73-1.35
        c0.53-0.94,1.2-1.99,2.03-2.1s1.35-0.26,1.61-1.09s1.24-0.38,1.28-1.46c0.04-1.09-0.26-0.98-0.98-1.28
        c-0.71-0.3-1.05-1.2-1.95-1.28s-1.58,0-1.88,0.53s-0.45,0.9-1.31,0.79s-0.9,0.38-1.46-0.45s-0.79-0.94-1.05-0.38
        s-0.68,1.01-0.98,1.13S590.743,10.634,590.293,11.424z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M613.683,15.254
        c-0.11-0.01-1.61-0.38-1.95,0.38c-0.34,0.75-0.41,1.28-0.98,1.8c-0.56,0.53-1.39,1.09-1.43,1.92s-0.11,1.73,0.53,2.29
        c0.64,0.56,1.09,0.98,1.35,1.65s0.6,0.83,1.46,0.83c0.86,0,0.98-0.34,1.24-1.01c0.26-0.68,1.43-0.94,1.8-2.1
        c0.38-1.16,0.19-1.31,0.83-1.65s0.71-0.56,0.6-1.31c-0.11-0.75-0.45-0.68-1.2-1.09C615.193,16.534,615.153,15.404,613.683,15.254z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M625.213,4.024c0,0-1.13-0.22-1.77-0.08
        c-0.64,0.15-1.65-0.71-2.29-0.3c-0.64,0.41-0.41,1.09,0.34,1.39s1.5,0.64,1.65,1.39s0.3,1.39,1.09,1.39s1.43,0.23,1.54-0.49
        c0.11-0.71,1.73-0.04,1.61-0.86c-0.11-0.83-0.41-1.47-0.86-1.47C626.083,5.004,626.113,4.064,625.213,4.024z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M629.493,1.284c0,0-1.61-0.83-1.84,0.04
        c-0.22,0.86,0.11,1.31,0.75,1.31s0.68,1.43,1.32,1.62s0.86,0.26,2.18,0.26s1.69-0.08,1.39-0.6c-0.3-0.53-1.43,0.08-1.73-0.71
        S630.733,1.774,629.493,1.284z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M613.533,82.214c0,0-1.28-0.53-1.61,0.19
        c-0.34,0.71-0.41,0.79-1.16,0.79s-1.43,0.26-1.35,0.98s0.53,1.09,1.05,1.16c0.53,0.08,0.83,0.15,0.56,0.68s-0.3,0.86-0.9,1.28
        c-0.6,0.41-1.5,1.09-0.6,1.43s2.03,0.22,2.44-0.56c0.41-0.79,0.68-0.56,2.4-0.6c1.73-0.04,1.2-0.26,1.77-1.28s0.94-0.86,1.73-1.16
        s1.61-0.19,1.61-1.28s-0.19-1.88-0.98-1.84s-1.16,0.71-1.69,1.39s-0.75,1.16-1.16,1.46c-0.41,0.3-0.6-0.26-0.49-1.28
        c0.11-1.01-0.04-0.98,0.45-1.46c0.49-0.49,0.34-1.2-0.19-1.28s-0.68,0.71-0.9,0.9C614.293,81.914,614.443,82.284,613.533,82.214z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M598.293,118.114c0,0-1.39-0.04-2.03,0
        s-0.98,0.26-1.65,0.83c-0.68,0.56-1.5,0.53-1.13,1.43c0.38,0.9,1.09,1.05,2.82,1.54s2.33,0.98,3.12,1.65
        c0.79,0.68,1.77,0.83,2.33,0.94s1.43,0,1.43-0.98s0.98-1.46-0.38-1.95c-1.35-0.49-1.28-2.29-2.29-2.59
        C599.503,118.684,599.453,118.114,598.293,118.114z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M689.773,34.364c0,0-0.76-1.16-1.28-1.16
        s-0.38,1.01-1.13,1.09c-0.75,0.07-1.05,1.05-0.38,1.13c0.68,0.08,0.08,1.39,0.83,1.43s0.9-0.15,0.9-0.83
        C688.713,35.344,690.573,35.324,689.773,34.364z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M707.043,19.574c0,0-0.64-0.94-1.31-1.58
        c-0.68-0.64-0.64-1.54-1.35-1.69s-1.28-0.11-1.16,0.34c0.11,0.45,0.64,0.45,0.53,1.05c-0.11,0.6,0.71,1.65-0.11,1.76
        s-1.13,0.26-0.9,1.01s0.79,1.16,1.69,1.13c0.9-0.04-0.34,1.13,1.09,1.2s2.22,0.68,2.33-0.26c0.11-0.94,0.56-1.31,0.11-1.65
        C707.493,20.554,707.753,20.474,707.043,19.574z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M748.583,152.694
        c-0.16,0.06-1.05,0.67-1.72-0.08c-0.67-0.76-2.31-1.22-2.86-1.09s-0.97,0.38-2.44,0.38c-1.47,0-2.23,0.5-3.03-0.42
        c-0.8-0.93-0.63-1.09-1.47-1.01c-0.84,0.08-0.76,0.67-1.51,0.67c-0.76,0-0.92,1.01-0.25,1.13c0.67,0.13,1.3,0.38,1.97,0.88
        s1.18,0.8,1.98,0.88s1.68,0.54,2.39,1.13c0.72,0.59,1.18,0.38,1.77,1.43c0.59,1.05,0.67,1.39,1.89,1.3s0.92,1.3,3.03,1.05
        s3.66-0.46,4.38,0.13c0.71,0.59,0.8,1.05,1.64,1.01c0.84-0.04,1.35,0.13,1.51-0.55c0.16-0.68,0.51-0.8,1.22-0.88
        c0.71-0.08,1.39-0.17,1.34-0.84c-0.04-0.67-0.46-1.22-1.26-1.22s-1.3-1.98-2.48-1.98s-1.09,0.08-1.93-0.67
        c-0.84-0.76-1.26-0.17-2.1-0.59C749.803,152.954,749.513,152.324,748.583,152.694z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M745.873,188.964c0,0-1.15,0.13-1.1-1.28
        c0.04-1.41,0.35-2.24-0.04-2.95s-1.28-1.23-1.54-0.31c-0.26,0.92-0.66,2.51-1.23,3.08s-1.15,1.28,0,1.72s0.13,0.88,1.45,1.32
        s2.29,0.18,3.17,1.14c0.88,0.97,1.1,1.45,1.98,1.41c0.88-0.05,0.62,1.5,1.94,1.41s1.49,0.22,1.72-0.44
        c0.22-0.66,0.48-0.7,1.32-0.75s1.05,0.13,1.32,0.79c0.27,0.66,0.97,1.54,1.67,1.54s2.46,0.83,2.42-0.44
        c-0.04-1.28-0.66-2.07,0.27-2.64c0.92-0.57,2.29-0.88,2.11-1.94s-0.7,0.22-1.23-0.79s-1.36,0.13-1.45,0.48
        c-0.09,0.35-0.62,0.44-0.97-0.18s-0.57-0.75-1.41-0.57c-0.84,0.18-0.04,1.5-1.8,1.5s-2.55,1.14-3.21,0.09s-0.75-1.63-1.72-1.63
        s-0.13,0.75-1.1,0.88s-1.19,0.57-1.5-0.13C746.623,189.584,746.753,189.144,745.873,188.964z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M770.283,186.024c0,0,0.32,3.22-0.95,4.29
        c-1.26,1.07-1.58,1.7-1.83,2.78c-0.25,1.07-0.82,2.78,0.19,3.16s1.2,0.13,1.64,0.76c0.44,0.63,0.44,1.45,2.02,1.01
        s1.64-0.51,1.26-1.64c-0.38-1.14-0.38-1.7-0.12-2.71c0.25-1.01,0.63-1.7,1.26-1.77c0.63-0.06,1.07,0.32,0.88,1.2
        c-0.19,0.88-0.44,1.26-0.25,1.45s1.01,1.39,0.19,1.83s-1.01,1.39-1.01,1.7c0,0.32,1.32-0.06,2.4-0.13c1.08-0.06,1.45,0.7,2.21-1.01
        c0.76-1.7,2.21-0.76,2.4-2.27c0.19-1.52-0.19-1.52,0.57-2.02c0.76-0.51,1.45-1.07,0.95-1.58c-0.51-0.51-0.7-1.45-1.52-0.76
        s-1.14,0.95-1.96,0.95s-1.45,0.76-1.89-0.13c-0.44-0.88-0.89-0.57-0.89-2.08c0-1.52,0-1.52,0-3.1s0.7-2.84-1.26-3.09
        s-2.65-0.19-2.9,0.7C771.413,184.444,770.093,184.634,770.283,186.024z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M791.793,128.664c0,0-0.15,0.68-0.67,1.88
        c-0.53,1.2-0.08,2.18-0.6,3.15s-1.13,1.58-1.05,2.55c0.08,0.98-0.83,1.28-0.83,2.4c0,1.13-0.22,1.28-0.22,3.15s0.38,2.93-0.45,3.53
        s-1.35,1.13-0.68,1.88c0.68,0.75,0.45,1.65,1.35,1.65s1.73,0.3,1.73,0.9c0,0.6-0.68,0.83-1.65,0.6c-0.98-0.22-2.4-0.45-2.78,0.15
        c-0.38,0.6-0.3,1.35,0.38,1.8s0.53,1.35,1.58,1.43c1.05,0.07,2.03-0.38,2.03,0.3s0.75,2.4-0.22,2.33c-0.98-0.07-0.3-0.9-2.1-1.05
        s-2.55-0.6-2.55,0.75s0.53,3.68-0.53,4.06c-1.06,0.38-0.9-0.83-1.65-0.83s-0.38,1.05-1.13,1.43c-0.75,0.38-0.53,1.73,1.43,1.58
        c1.95-0.15,1.13-0.68,2.33-0.68s1.73,1.35,0.75,1.95s-2.4,1.58-2.63,2.48c-0.22,0.9-0.45,1.65-1.65,2.03s-2.85,1.43-2.03,2.1
        s1.43,0,1.43,1.2s0.83,2.78,1.8,2.78c0.98,0,2.78-0.07,2.78-0.83s1.8,0.45,2.48-0.98s1.05-1.28,2.63-1.28s3.23,0.6,4.43-0.23
        s2.18-0.15,3.16,0s1.43,0.83,3.38,0.53s1.73-1.2,3.16-1.2s2.1-0.08,2.63-1.2c0.53-1.13,0.98-1.2,1.8-2.1s1.35-1.43,1.35-0.23
        s-0.3,1.88,0.08,2.1c0.38,0.23,0.38,0.68-0.38,0.98c-0.75,0.3-0.23,0.68,0.38,0.68s0.98,0.15,1.2,0.75c0.23,0.6,2.03,0.53,2.1-0.15
        s0.15-1.8,0.75-2.55s0.6-0.9,1.13-1.05s-0.08-1.13,0.98-1.13c1.05,0-0.15,1.13,0.68,1.5c0.83,0.38,1.65,1.05,0.22,1.65
        c-1.43,0.6-1.73,1.43-2.03,2.55c-0.3,1.13-0.68,1.58-1.73,2.03s-1.28,1.05-2.03,1.2s-1.58,0.75-0.98,0.98
        c0.6,0.23,0.75,1.35,1.95,1.35s2.78,0.3,3.3-0.53c0.53-0.83,2.18-0.98,2.18-2.03s-0.22-2.4,0.68-3.45s1.43-0.83,2.25-2.33
        c0.83-1.5,1.88-1.88,2.03-3.53s0.83-2.93,1.73-1.2c0.9,1.73,0.22,2.03,1.13,2.25s1.8-0.3,1.5,0.75s-1.28,1.58-1.28,2.85
        c0,1.28-0.45,1.13-0.45,2.93c0,1.8,0.22,1.05,0.6,2.4c0.38,1.35,1.05,1.2,1.5-0.15c0.45-1.35,1.35-1.2,1.5-2.18s0.3-1.88,1.05-1.58
        s1.35,1.43,1.13,2.48s-0.38,1.58,0.08,2.03c0.45,0.45-0.08,1.2,1.35,1.13c1.43-0.08,0.3-1.5,1.65-1.73s2.1-0.45,1.95-2.03
        s-0.15-3.08-0.08-5.11c0.08-2.03,0.22-2.18-0.45-3.46c-0.68-1.28-2.1-0.75-2.1,0.38s0.22,1.58-0.53,1.65
        c-0.75,0.07-1.5,0.23-1.35-1.43c0.15-1.65-0.3-2.93,0.08-3.83c0.38-0.9,0.53-0.15,0.45-1.88s-0.6-2.4-1.28-1.05
        c-0.68,1.35-2.18,2.63-2.03,3.83c0.15,1.2,0.38,2.93-0.68,2.93c-1.05,0-2.1,0.38-2.1-1.2s-0.15-1.95-0.15-2.93s-2.1-0.75,0-2.55
        s1.58-2.63,2.4-3.08c0.83-0.45,1.2-0.83,0.68-1.28c-0.53-0.45-1.43-1.73-2.25-1.43c-0.83,0.3-0.23,1.5-1.58,1.65
        c-1.35,0.15-0.98,1.13-1.95,1.88c-0.98,0.75-0.98,2.1-1.95,2.03c-0.98-0.07-0.08-1.05,0.23-1.88c0.3-0.83,1.88-0.83,1.05-2.18
        c-0.83-1.35-2.03-1.5-2.03-2.48s1.5-0.38,1.65-1.8c0.15-1.43,0.15-1.28,0.9-1.88s0.83-1.65,0.3-1.65s-1.73-1.73-2.4-1.73
        s-1.2-1.43-1.88-1.28s0,1.73-1.43,1.8s-1.65,0.68-1.65,1.35c0,0.68,0,1.28-0.53,1.35s-0.3-0.53-0.75-1.5s-0.3-1.65-1.28-1.35
        c-0.98,0.3-1.43,1.35-1.43,2.03s-0.08,1.13-0.98,1.2c-0.9,0.07-0.9-0.3-1.05,0.9c-0.15,1.2,0.22,1.8-0.6,1.95
        c-0.83,0.15-1.28,0.07-1.05-0.83c0.23-0.9,0.68-1.35,0.68-2.03s0.53-1.73-0.75-1.73s-0.98,0.9-1.73,1.13
        c-0.75,0.22-0.68-0.98-1.8-0.98s-0.9,0.53-1.43,0.98c-0.53,0.45-1.43,0.45-1.35-0.53s1.2-1.8,0.67-1.8s-2.1-0.45-1.2-1.43
        c0.9-0.98,1.88-0.22,2.25-1.28s1.88-0.75,1.35-1.73s-2.1-0.45-3-0.38s-1.28,1.65-2.1,0.53c-0.83-1.13-0.45-2.48-1.05-1.8
        s0.3,2.18-0.45,2.63c-0.75,0.45-1.28-0.15-1.5,1.8c-0.23,1.95,0.3,2.4-0.15,3s-1.35,0.83-1.05-0.68c0.3-1.5,0.53-1.88,0.23-3.23
        c-0.3-1.35-0.68-3.38-0.08-4.66s2.03-1.13,2.03-3.15c0-2.02,0.3-1.95,0.9-2.93c0.6-0.98,0.53-1.05,0.53-1.88s0.38-1.73-0.75-2.18
        s-2.18,0.3-2.03-1.13c0.15-1.43,0.9-1.13,1.8-1.05c0.9,0.07,1.8,0.75,1.88-0.22c0.08-0.98,0.68-1.35-0.23-1.65
        c-0.9-0.3,0.15-2.1-0.75-2.18c-0.9-0.07,0.15,1.43-2.25,1.5s-1.65,0.45-3,1.13C793.673,125.584,792.853,125.654,791.793,128.664z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M817.973,143.494c0,0,0.49-0.98-0.11-1.31
        c-0.6-0.34-0.86-0.41-0.9,0.3C816.923,143.194,816.653,145.894,817.973,143.494z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M808.993,176.574c0,0-0.26,0.15-0.41-0.53
        s-0.23-0.86-0.68-0.79s-0.38,0.53-0.08,1.2c0.3,0.68,0.19,0.83,0.64,1.13s0,1.2,0.6,1.16s0.75-0.23,0.75-0.9
        C809.823,177.174,810.343,176.764,808.993,176.574z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M796.373,115.514
        c0.38-1.05,0.45-1.88-0.22-1.95c-0.68-0.07-0.45,1.35-1.65,1.13s-1.95-0.83-2.4-1.13s-0.83-1.05,0.15-1.05s0.53-0.68,0.68-1.88
        c0.15-1.2,0.68-1.95-0.45-2.25s-2.03-0.07-1.43-1.13c0.6-1.05,1.35-0.98,1.2-2.55c-0.15-1.58-0.08-1.5-1.5-1.73
        s-2.1-2.25-3.38-2.85s-1.65-0.75-2.7-0.38c-1.05,0.38-2.25,0.9-2.55,1.8s-0.08,1.95-1.13,2.55s-0.45-0.07-1.43-0.53
        c-0.98-0.45-1.05-1.58,0.22-2.1c1.28-0.53,0.83-0.68,0.9-1.5c0.08-0.83,0.45-1.05-0.68-1.43s-0.75-0.68-1.43-1.5
        s-0.53-0.83-1.88-0.83s-2.25-0.22-2.63,0.6c-0.38,0.83-2.25,0.45-2.55,1.13s-1.2,2.03-2.18,1.88s-0.38,2.78-2.48,2.93
        s-0.83,1.28-2.4,1.8c-1.58,0.53-1.65,1.95-2.55,2.1c-0.9,0.15-0.68,2.1-1.8,2.25c-1.13,0.15-1.05-0.3-1.2-1.73s-1.2-0.9-2.78-1.58
        s-2.63-1.13-1.35-1.43s0.98,0.3,1.95,0.53c0.98,0.22,2.4,0.68,2.63-0.07s0.75-2.48,1.73-2.48s2.7,0.68,3.08-0.45
        s-0.53-2.25,0.68-2.25c1.2,0,1.95,0.45,2.33-0.45s0.98-1.58,2.1-1.73c1.13-0.15,1.2-1.65,2.1-1.8c0.9-0.15,1.2-1.43,2.4-1.43
        s0.68-1.05,2.18-1.05s1.5-0.53,1.2-0.75s-0.9-0.3-1.43-1.13c-0.53-0.83-1.28-0.98-2.1-0.98s-2.18,0.6-3.23,0s-1.43-1.5-2.03-1.43
        c-0.6,0.07,0.22,1.2-1.35,1.13c-1.58-0.08-1.88-0.08-2.4-0.68c-0.53-0.6-1.5-1.95-2.25-2.33s-0.22,1.05-1.35,0.75
        s-1.13,0-1.58,0.98s-0.53,1.8-1.43,1.73s-1.65-0.08-1.5-1.28s0.08-1.2,0.75-1.5c0.68-0.3,0.9-0.75,0.68-1.28s-0.6,0-1.35,0
        s-1.35,0.15-2.1,0.22s-0.98,0.53-1.13,1.05c-0.15,0.53,0.22,1.28-0.45,1.35c-0.68,0.08-1.58,1.05-1.5-0.6s0.15-2.33,0.68-2.63
        c0.53-0.3,1.8-0.75,1.05-1.35s-1.95-0.6-2.25-1.65s-0.68-1.65-1.58-1.65s-1.5,0.22-2.48-0.6c-0.98-0.83-1.28,0.53-3.3-1.58
        s-1.88-0.68-2.93-2.25s-0.98-1.65-2.03-1.8c-1.05-0.15-2.25-1.2-2.25-1.65c0-0.45-1.73-0.68-1.65-1.73c0.08-1.05,0.75-1.2,1.35-0.3
        c0.6,0.9,0.23,1.2,1.05,1.35s2.55,1.65,2.25,0.08s0.3-2.55,0.75-2.78c0.45-0.23,2.03-1.65,1.35-1.8c-0.67-0.15-1.13-1.95-2.18-1.95
        s-2.25,1.28-2.4-0.23s-0.38-1.65-1.13-1.58s-1.95,0.83-1.65-0.3s0.6-1.73,1.28-1.73c0.68,0,0.98-0.75,0.45-0.9
        c-0.53-0.15-1.39-0.49-1.95-1.5s-0.68-2.03-1.46-1.95c-0.79,0.08-0.11,1.91-0.86,1.8s-1.13-0.08-1.31-0.94
        c-0.19-0.86,0.79-1.65,0.08-1.76s-1.31-0.53-1.77,0.15c-0.45,0.68-0.38,0.49-1.05,1.16s-1.58,1.58-1.54,0.49
        c0.04-1.09,0.38-1.28,0.83-1.58s1.61-0.79,1.61-1.39s1.05-2.03-0.41-2.22c-1.46-0.19-1.8-0.19-2.22,0.11
        c-0.41,0.3-0.68,0.38-1.39,0.45s-0.6,0.9-1.2,0.94c-0.6,0.04-0.9-0.41-0.68-1.05c0.22-0.64,0.45-0.75,1.24-0.86
        s1.43-0.07,1.43-0.68s1.16-0.23,1.39-1.24c0.23-1.01,0.15-1.54-0.67-1.65c-0.83-0.11-0.98-1.31-2.03-1.54
        c-1.05-0.23-0.79-0.83-1.39-1.28c-0.6-0.45-1.13-0.49-1.31,0.34c-0.19,0.83,0.04,1.05-0.86,1.09c-0.9,0.04-2.74,0.41-2.33-0.26
        s2.52-0.26,2.52-1.13c0-0.87,0.56-1.31-0.26-2.07c-0.83-0.75-1.39-0.04-1.65-0.9s-0.3-1.29-1.75-1.64s-2-0.65-1.95-1.8
        c0.05-1.15,0.95-2.3-0.15-2.7c-1.1-0.4-2.05-0.15-2.2-1.25s0.25-1.05-0.4-1.45s-0.75-1.7-1.5-1.7s-0.35,1.2-1.4,1.45
        c-1.05,0.25-0.45,2.15-0.5,2.9s-0.2,1.65-0.6,2.15s-1.75,0.7-1.75,1.55s0.4,1.15,1.1,1.65c0.7,0.5,1.1,1.25,0.75,1.95
        s-0.75,1.2-1.2,1.6s-0.25,1.55,0.35,1.85s1.2,0,1.2,0.6s-0.35,0.8-1.05,0.8s-1.15,0.3-1.2,0.85s-0.15,0.5-0.1,1.25s-0.4,1,0.05,1.5
        s0.7,0.55,0.55,1.3s0.45,0.9-0.15,1.6c-0.6,0.7-1.1,0.45-1.1-0.35s0.1-1.35-0.3-1.9c-0.4-0.55,0.45-1.6-1.2-1.7
        c-1.65-0.1-2.05,0.35-2.05,1.6s-1.1,0.7-2.01,1.6c-0.9,0.9-1.35,0.85-2.2,1.9s-0.7,2.55-2.1,2.45c-1.4-0.1-1.85-0.7-1.75-1.45
        s0.05-0.65-0.8-1s-0.6-1.65-1.1-0.8s-0.35,2.3-0.8,2.75s-0.55,2.4-1.25,2.7s-0.3-0.4-0.1-1.75s0.65-1.85,0.65-2.75s0.1-1.5-0.7-2.5
        s-1-2.85-2.25-2.85s-1.25,0.55-2.55,0.4s-1.8-0.2-2.2,0.6s-0.55,1.4-1.25,1.5s-2.55,0.95-2.55-0.15s-0.5-1.5,0.7-1.7
        s2.16-0.05,2.16-1s-0.35-1.55,0-2.05s0.55-1.15,0.25-1.7s-1.35-0.4-2.35-0.4s-0.9-0.75-0.85-1.25c0.05-0.5,0.2-1.25,0.65-1.25
        s0.95-0.2,0.95-1.05s-0.3-1.15-1-1.45s-0.55-1-0.5-1.9s0.6-2.15,0-2.45s-1.45-1.55-1.35-2.65c0.1-1.1-0.05-2.65,0.6-3.06
        s0.95-0.6,0.95-1.45s-0.55-0.85-0.6-1.5s-1-1.3-1.3-0.4s-0.8,1.25-1.3,1.35s-0.3,1.85-0.95,0.8s-0.45-2-1-2.2s0.5-1.5-0.75-1.45
        s-1.2,1.65-1.75,1.65s-1.2-0.05-1.35-0.65c-0.15-0.6-0.1-0.85-0.75-0.85s-1.85-0.45-2.55,0s-1.8,1.05-2.15-0.35
        c-0.35-1.4-1-1.75-1.2-2.8s-0.35-1.95-1.4-2.25c-1.05-0.3-0.65-1-1.6-1.4c-0.95-0.4-0.3-1.25-1.45-1.25s-0.85,0.7-1.25,1.35
        s-1.55,0.5-1.2-0.4s0.7-1,0.45-2.1s-1.1,0.05-2.01-0.95c-0.9-1-0.95-2.1-1.75-2.25s-0.95-0.1-1.55-0.65s-0.95-0.85-1.8-0.8
        s-0.3,0.75-1.3,1s-0.25,1.2-1.55,1.35s-0.35,1.25-1.45,1.45c-1.1,0.2-1.5,0.3-1.9-0.6s-0.85-0.75-2.1-0.75s-1.9-0.6-2.4,0.1
        s-0.35,1.75-1.05,1.65s-1.2-0.25-1.3-1.15s-0.25-1.25-1.25-1.3s-1.66,0.25-1.9-0.6c-0.25-0.85,0.15-1.15-1.05-1.1
        s-2.3,0.15-3.3-0.45s-1.2-1.05-2-0.85s-0.25,1.35-1.15,1.5s-0.95,1.2-1.95,1.25s-0.8,1-0.8,1.75s-0.2,4.21,0.55,4.71
        s0.7,0.9,1.25,1.2c0.55,0.3,0.25-0.3,1.05,1.15c0.8,1.45,0.75,1.75,0.25,1.85s-0.85,0.4-0.85,0.95s0.7,3.15-0.3,3.15
        s-1,0.25-1,0.95s-1.3,1.1-1.05,1.7s1.05,0.5,1.55,0.55s0.35,0.4,0.35,1.4s0.75,0.4,0.95,1.6s0.15,3.9,0.3,4.66s0.6,1.25,1.2,1.2
        c0.6-0.05,1.4-0.65,1.4-0.05c0,0.6-0.05,1.45-0.7,1.7s0.5,1.2-1.2,1.2s-1.95,0.45-1.8,1.45s0.45,2.7-0.45,3.5s-1.05,2.1-2.75,2.81
        c-1.7,0.7-2.85,1.1-2.3,1.95c0.55,0.85,0.7,0.5,1.45,1.8s1.6,2.2,2.95,2.95c1.35,0.75,1.75,1.15,3.16,2.2
        c1.4,1.05,2.15,1.75,3.41,4.01s2.55,4.21,2.9,6.41c0.35,2.2,0.7,3.4,1.05,5.26s1.2,4.31-0.05,6.46s-0.85,4.36-3.6,5.71
        s-1.15,4.16-5.96,6.21s-5.11,2.5-6.51,2.95s-1.15,1.35-3.26,1.6c-2.1,0.25-1.8,0.45-3.4,0.8c-1.6,0.35-1.05,1.2-0.2,1.65
        s1.3,2.2,2.2,3.56s1.35,3.06,1.9,4.46c0.55,1.4,1.2,3.31,1,4.76s-0.75,3.15-0.1,4s0.85,1.95,0.8,2.65c-0.05,0.7,0.2,2.4,0.85,2.95
        c0.65,0.55,0.65,1.65,1.25,2.3s0.55,2.2-0.15,3.2s-1.35,2.3-2.1,2.85s-2.3,1.75-0.7,2.25s2.5,1.55,2,2.3s-2.05,4.71-2.3,2.95
        c-0.25-1.76-1-2.6-1.7-3.2c-0.7-0.6-1.25-2.3-2.6,0s-1.1,2.45-1.1,3.46s0.25,1.9-0.9,1.65s-1-1.75-2.15-2.45s-2-0.3-2.8,0.05
        s-1.85,0.25-1.55-1.1s0.5-1.8,0.15-3.15c-0.35-1.35-1.3-2.6-2.51-3.5c-1.2-0.9-2.55-2.6-3.85-2.6s-3.96,1.05-3.55-0.25
        c0.4-1.3,2.75-0.4,2.8-1.7s0.25-2.2-0.9-2.75s-4.51-3.21-4.56-4.96s0.05-2.45,1.1-3.36c1.05-0.9,0.55-2.1,0.55-3.46
        c0-1.35,0.3-3.61-0.4-4.61s-1.1-1.15-1.1-2.55s-0.2-2.85,0.45-3.56c0.65-0.7,0.95-0.9,0.95-3s0.55-4.1-1.45-4.06
        c-2,0.05-3.71,1.1-5.56-0.3s-3.1-2.3-4.71-2.3s-4.05,0.25-5.56,0.33c-1.5,0.08-4.81,1.2-5.03-0.38c-0.22-1.58-0.15-1.43-1.65-2.78
        s-2.7-2.7-4.43-3s-3.23-1.8-4.88-2.1s-3.76,0-4.88-2.33c-1.13-2.33-4.88-8.26-8.11-9.16s-4.36-0.6-5.48-1.73
        c-1.13-1.13-3.53-1.88-4.58-2.93s-2.55-1.43-4.66-1.43s-1.95,0.53-2.7,0.9c-0.75,0.38-0.83,0.9-2.55,0.9s-2.25,0.38-3.23,0.38
        s-1.13,0.38-2.25,0.75c-1.13,0.38-1.28-0.3-0.3-1.2c0.98-0.9,2.63-0.6,2.48-3.23s0.68-4.13-0.3-5.18s-1.13-1.65-1.13-3.08
        s1.28-7.21-0.38-7.81s-1.65-0.3-2.63-1.2s-1.88-0.3-2.63-0.3s-0.98,1.58-1.43,2.25s-0.3,2.78-0.98,3.15
        c-0.68,0.38-1.5,0.6-1.13-0.98c0.38-1.58,1.43-2.25,1.5-3.6s0.98-1.13-0.75-2.25c-1.73-1.12-2.7-1.05-2.1-2.63s3.23-1.13,2.7-4.43
        c-0.53-3.31-0.23-3.15,1.43-5.56c1.65-2.4,0.15-5.33,1.8-6.08s3-1.43,3.38-2.55c0.38-1.13,0.9-1.88,2.03-3.08s0.83-3.08,2.03-3.76
        s0.98-1.5,2.18-1.58s2.03,0,2.18-1.28c0.15-1.28,0.22-2.7,1.28-2.63s2.33,0.3,2.33-0.9s-0.75-1.35-0.68-2.1
        c0.08-0.75,0.98,0.45,1.2,0.68c0.22,0.23,1.35,0.6,1.73-0.15s0.9-1.8,1.65-1.88s1.95-0.23,1.73-1.5c-0.22-1.28-0.98-1.8,0.15-2.1
        s1.88-0.3,2.7,0.08c0.83,0.38,1.73,0.98,3.08,0.3c1.35-0.68,0.45-1.43,2.1-1.35c1.65,0.08,2.18,0.38,2.18-1.2s-0.3-3.9-1.5-4.13
        c-1.2-0.22-2.48-0.98-3.46-1.58c-0.98-0.6-1.8-0.53-2.55-0.08s-2.03,0.83-1.8-0.08c0.23-0.9,1.5-1.95,0.23-2.18
        c-1.28-0.22-2.03-1.8-3.53-1.58c-1.5,0.22-2.7-0.15-3.23-0.9c-0.53-0.75-1.13-1.73-0.08-2.48s1.58-1.43,1.88-0.53
        s1.5,2.33,2.18,2.63s1.95,0.3,2.55,0.98s0.75,1.35,2.1,1.5s3.38-0.15,4.13,0.83s1.28,1.95,2.48,1.95c1.2,0,3-0.08,3.98-0.08
        s1.88-0.83,1.88-1.8c0-0.98-0.38-1.8,0.3-2.63s1.28-1.65,1.8-0.75c0.53,0.9,0.3,2.18,1.35,2.18s1.95-1.2,2.93-0.75
        s4.96,0.6,5.26-0.38s2.4-0.08,2.7-2.1c0.3-2.03,1.13-2.55,2.25-3.83c1.13-1.28,2.63-2.25,3.16-3.08s0.68-1.43-0.15-1.95
        c-0.83-0.53-1.05-1.2-2.4-1.2s-2.85-0.75-3.91-0.53c-1.05,0.22-3.83,0.53-4.51-0.98c-0.68-1.5-1.13-1.95-1.8-2.63
        c-0.68-0.68-0.9-1.95-2.4-2.55s-2.78-0.45-2.78-1.2s2.4,0.53,3.15-0.6s2.78,0.53,3.76,2.03s3.23,1.73,3.76,3.08s1.73,1.5,2.85,1.58
        s3.76,2.03,4.36,0.75s0.45-1.2,2.25-2.25s2.18-3.23,3.3-3.68c1.13-0.45,1.88-0.3,2.18-1.58s-1.13-1.5-1.73-1.5s-1.5-0.22-1.5-1.2
        s-0.9-2.18,0.38-2.18s1.35,0.07,2.18,0.53c0.83,0.45,0.98,0.6,1.8,0.22s2.25,0,2.25,0.38s-0.9,1.28,0.53,1.8
        c1.43,0.53,1.28,0.75,2.18,1.43c0.9,0.68,1.2-1.05,2.48-0.68c1.28,0.38,0.15,1.13,1.58,1.05s1.73-0.6,1.43-1.28
        s-0.98-1.58-2.4-1.95s-1.28-1.5-2.4-2.33c-1.13-0.83-1.35-1.28-0.08-1.95s2.1-0.9,2.1,0.07s0.38,2.48,1.43,2.63s0,1.43,1.58,1.43
        s0.68,1.58,2.18,1.58s0.15-1.35,1.88-1.43c1.73-0.07,3-0.83,3.91-1.13c0.9-0.3,1.13-0.38,1.95-1.73c0.83-1.35,1.43-1.88,2.55-2.4
        c1.13-0.53,1.05-0.22,1.2-1.73s0.98-1.13,0.98-2.4s-0.22-1.73-0.98-2.4c-0.75-0.68-1.65-1.73-2.33-2.25s-1.28-0.68-0.98-1.8
        c0.3-1.13,1.28-1.5,0.53-1.88s-2.33,0.3-2.25-1.35s-0.3-1.65,1.05-1.65s1.58-0.07,1.73,0.6c0.15,0.68,1.35,0.68,1.35,0
        s-0.3-0.9,0.83-1.13c1.13-0.22,1.05-0.53,1.65-1.5c0.6-0.98,0.22-1.88-0.53-1.88s-2.4,1.2-2.55,0c-0.15-1.2,0-1.13,0.98-1.58
        s1.58,0.22,1.58-1.2s-0.38-0.53-1.73-1.5c-1.35-0.98-1.2-1.88-2.03-1.95s-2.1-0.38-1.73-1.2c0.38-0.83,0.53-0.98,0.15-1.35
        c-0.38-0.37-1.2-0.9-1.95-0.83s-1.2,0.75-2.7-0.22c-1.5-0.98-1.8-0.53-3.08-1.2s-1.95-0.68-3.3-0.68s-2.48,0.3-2.55,1.13
        c-0.08,0.83-0.08,1.65-0.6,2.1s-0.53,0.9,0.15,1.43s0,2.1,0.68,2.55s1.28,0,1.28,1.28s0.98,3.23-0.53,3.23s-3.08-0.9-3.23,0.22
        c-0.15,1.13-0.53,2.78-0.9,3.23c-0.38,0.45-1.35,1.58-1.43,2.4s-0.38,1.65-0.83,2.1s-0.6,0.68-0.75-0.53
        c-0.15-1.2-0.22-1.88-1.05-2.1s-0.9-1.5-1.95-1.35s-0.3,1.95-1.28,2.4c-0.98,0.45-0.75,1.5,0.53,2.33s2.03,1.65,2.03,2.4
        s-0.98,1.5-1.65,1.8c-0.68,0.3-1.05,0.3-1.43,1.28s-0.08,1.65-1.13,1.8s-1.35-0.07-1.2-1.13c0.15-1.05-0.98-0.53-1.28-2.33
        c-0.3-1.8-2.25-1.65-2.18-2.93s-0.83-4.43,0.53-4.88s2.25,0.38,2.63-1.88c0.38-2.25,1.05-4.58-0.45-5.33s-1.58-1.05-2.03-2.18
        s-1.2-2.18-2.03-2.18s-0.98,0.15-1.35,0.83c-0.38,0.68-2.18,1.95-2.4,3c-0.22,1.05,0.08,2.03-0.83,2.55s-1.2,0.38-1.28,1.35
        c-0.08,0.98,0.08,1.8-0.9,1.95s-1.95,0.15-1.5-1.8s-0.38-2.93,0.83-3.61c1.2-0.68,0.45-0.75-0.08-2.1s-0.83-1.65-0.53-3.31
        c0.3-1.65,0.3-0.98,1.5-0.9s1.2-0.53,0.6-0.83c-0.6-0.3-2.1-2.03-3.98-2.03s-3.91,0.3-4.06-0.83c-0.15-1.13-1.5-0.9-0.9-1.8
        s2.7,0.07,2.78-1.05s0.68-0.07,0.08-1.13c-0.6-1.05-1.28-1.58,0-1.73s2.4,1.13,2.63,0.07c0.22-1.05,0.6-1.43-0.15-1.88
        s-1.05-0.15-1.05-1.73s-1.13-0.3-1.58-1.8s-1.13-1.43-0.75-2.7c0.38-1.28,0-1.65,0.9-2.18s0.6-0.68,0.6-1.88s-0.3-1.58-0.75-1.88
        s-0.53-0.6-1.05-1.58c-0.53-0.98-0.22-2.18-0.98-2.14c-0.75,0.04-2.63,0.56-2.37-0.83s-0.08-1.69,0.94-1.8
        c1.01-0.11,1.43,0.3,1.65-1.01c0.22-1.31,0.53-1.31,1.43-1.54s1.13-0.53,1.35-1.13s1.46-1.01,0.98-1.88
        c-0.49-0.86-2.25-1.2-1.99-1.8s0.15-0.64,1.09-0.64s2.03-0.38,3.15,0.34c1.13,0.71,0.49,1.43,1.61,1.43s2.18,0.38,2.55-0.19
        c0.38-0.56,1.43-0.26,2.03-1.92c0.6-1.65,1.28-1.65,2.33-3.04s1.2-1.58,2.63-2.55c1.43-0.98,1.5-1.73,2.29-2.14
        s1.16-1.54,0.56-2.07s-1.13-1.35-2.29-1.35s-1.2-0.83-2.07-0.86c-0.86-0.04,0.22,0.86-1.54,0.68c-1.77-0.19-1.8-0.75-2.48-1.43
        s-1.2-1.73-2.78-1.84s-3.38-0.34-4.17,0.15s-1.28,1.05-2.07,1.24s-0.19,1.39-1.43,1.77s-1.8,1.2-2.03,1.88
        c-0.22,0.68-0.3,2.1-0.86,3.04s-1.88,1.91-1.84,3.3s0.04,3.04-0.26,4.88s-0.04,3.04-0.6,4.02c-0.56,0.98-0.11,4.13-2.44,4.73
        s-2.55,0.49-2.59,1.31c-0.04,0.83,0,1.16-1.13,1.16s-1.73,0.83-2.37,1.65c-0.64,0.83-1.16,1.5-1.24,2.14s-1.13,0.9-1.16,1.92
        c-0.03,1.02,0,1.28,0.49,1.54s1.58,0.11,1.16,1.05s-0.9,1.76-1.58,1.99s-2.07-0.15-2.22,1.01c-0.15,1.16-0.75,4.09,0.49,5.03
        s1.35,1.2,2.29,1.95s1.95,1.31,2.67,2.22c0.71,0.9,1.09,1.2,2.1,1.2c1.01,0,0.98-0.19,1.99-0.19s1.5-0.04,1.43,0.6
        s1.01,1.58,0.26,1.84s-0.56-0.83-1.65-0.9s-1.65-0.79-2.25,0.22s-1.13,3-1.61,3.64c-0.49,0.64-1.16,1.58-0.83,2.25
        c0.34,0.68,1.43,0.56,1.65-0.19s1.2-1.76,1.65-1.8s1.54,0.49,0.98,1.43s-0.9,1.09-1.01,1.95s0.19,1.65-1.09,1.65
        s-2.44,0.19-2.85,0.98s-1.46,2.63-2.74,2.89c-1.28,0.26-3.38-0.07-3.64,0.41s-0.41,1.16-1.05,1.46s-1.58,0.98-1.35,1.88
        c0.22,0.9,1.09,0.9,1.09,1.61s-0.71,0.6-0.94,1.8c-0.22,1.2,0.19,2.14-0.19,3.34s-0.26,1.61-1.46,1.5s-2.89,0.86-3.83-0.3
        s-1.58-1.76-0.6-1.76s3.87,1.13,3.94,0.04s0.11-1.8-0.68-2.14s-1.35-0.49-1.2-1.54c0.15-1.05-1.2-2.33,0.11-3.46
        s1.8-1.35,2.29-2.22c0.49-0.86,0.79-1.16,0.75-1.95s-0.22-0.94-1.24-0.98c-1.01-0.04-1.54-0.64-2.03-1.54
        c-0.49-0.9-0.38-0.79-1.43-1.54s-1.13-1.5-1.77-1.5s-0.53,1.61-1.91,1.61s-1.09,0.07-2.33-0.04s-1.39,0.19-1.5,1.05
        s-0.75,2.67,1.13,2.85s2.48-0.04,3.08,0.71c0.6,0.75,1.5,1.24,1.43,1.92s-0.26,1.65-1.46,1.73c-1.2,0.07-2.25,0.3-2.4-0.68
        s-0.08-2.7-1.24-2.7s-1.8-0.11-2.18,0.34s-0.56,0.56-1.65,0.56s-1.47-0.56-2.55-1.13c-1.09-0.56-1.28-1.88-2.93-1.88
        c-1.65,0-3.04,0.2-5.14,0.3s-2.55,0.9-3.36,0.05c-0.8-0.85-1-1.15-1.8-1.25s-1.25,0.25-1.75-0.8s-1.8-0.1-1.8-1.55
        s-0.25-2.1-1.15-2.2s-1.6,0.45-2.6,0.1s-1.4-0.05-1.9-1.45s0-1.6-1.2-2.15c-1.2-0.55-1.45-1-1.65-2.25
        c-0.2-1.25,0.05-1.8-0.85-1.75s-0.6,0.95-1.6,1.15s-1.25,1.95-2.35,2s-2.55-0.05-4.05-0.05s-4.46-0.4-5.16,0.25s-2,1.75-1.85,2.8
        s1.1,2.2,0.35,3s-0.75,1.75-0.75,2.6s-1.2,1.3-0.55,2.2c0.65,0.9,1.25,1.55-0.1,1.8s-2.7-0.05-2.9,0.25c-0.2,0.3,0.3,1.9-0.9,1.95
        c-1.2,0.05-2.2-0.1-2.05-0.95c0.15-0.85,1.75-1.15,1.5-2.3s0.2-2.05,1.15-2.05c0.95,0,1.35,0.5,1.2-1.5s0.45-4.26-0.65-4.66
        s-1.5-0.3-1.85-1.7c-0.35-1.4-0.95-0.8-1.6-2.35s-0.65-2.5-1.95-2.5c-1.3,0-3.01,0.75-4.56,0.75s-3.4,1.1-4.81-0.05
        c-1.4-1.15-1.95-1.4-2.95-1.4s-1.35,1.25-2.5-0.05s-1.3-1.65-2.35-1.75c-1.05-0.1-2-0.05-3.05-1.35c-1.05-1.3-1.05-1.1-2-1.8
        c-0.95-0.7-1.25-0.65-0.9-1.65s1.75,0.05,2.3-1.15s0.5-1.25,1.85-1.25s1.9-0.1,2.15,0.6s0.85,1.05,1.75,1s1,0.15,1.4-0.9
        s1.3-0.9,1.2-1.95c-0.1-1.05-0.8-0.4-0.8-2.35s-0.75-2.35-1.35-2.45s-1.2-1.9-2.4-2.7c-1.2-0.8-1.65-0.65-1.65,0s0.1,1.8-0.75,1.45
        s-0.35-1.9-2.1-2.2s-1.8-0.1-2.4-1.4s-2.3-1.5-2.6-3.25c-0.3-1.75-0.2-3.3-1.6-3.36c-1.4-0.05-2.15,0-2.55-0.9s0.15-1.85-0.6-2.25
        s-1.05-0.85-1.2-2.3c-0.15-1.45-0.05-1.8-1-2.6s-2.85-3.35-4.31-3.35s-2.1,0.65-3.15,1.55s-1.8,0.7-2.35,1.05
        c-0.55,0.35-0.25,0.85-1.45,0.9s-2.1-0.45-2.1-1.35s0.55-0.9,1.5-2.05s0.55-1.9,1.6-2.9c1.05-1,1.35-0.55,1.75-1.65
        s0.4-1.75-0.7-1.75s-2.05-0.45-2.65,0.95s-0.5,1.7-1.95,1.8s-2.6,0.65-2.75,1.45s0.9,1.95-1,1.95s-2.2,0.1-2.2-1.5
        s-1-3.85,0.7-5.06c1.7-1.2,1.35-2.65,2.35-3.55s1.4-1.35,1.45-2.35c0.05-1,0.35-4.3-1.2-4.15s-0.85,1.55-2,2.4s-0.9,1.9-2.25,1.9
        s-2.2,0.4-2.55,0.75c-0.35,0.35-0.26,0.8-1.2-0.1s-0.71-1.13-1.43-0.83c-0.71,0.3-0.86,0.19-0.83,0.86
        c0.04,0.68,0.83,1.77-0.38,1.99c-1.2,0.23-0.86-0.49-0.98-1.54s-0.15-1.5-1.16-1.35s-0.04-0.04-2.07-0.79s-2.1-0.45-2.93-0.08
        c-0.83,0.38,0.15,1.65-0.9,1.73c-1.05,0.07-2.18-0.08-2.63,0.6s-1.05,1.65-1.88,1.58c-0.83-0.07-0.15-1.28,0.15-1.8
        s2.25-0.68,1.05-1.43s-1.73-0.45-1.73-1.5s0.53-0.6,1.8-0.6c1.28,0,1.73-0.6,2.48-0.3s2.25,0.75,2.85,0.38
        c0.6-0.38,0.98-0.53,2.33-0.38c1.35,0.15,2.18,0.23,3.38,0.15c1.2-0.07,0.9-1.65,0.53-1.88c-0.38-0.23-0.9-0.6-2.1-1.35
        s-1.43-0.6-2.03,0.08s-1.35,1.13-2.25,0.15c-0.9-0.98-1.73-0.15-2.7,0.23c-0.98,0.38-2.03,0.23-2.7-0.3s-0.9-0.9-1.2-0.3
        c-0.3,0.6-1.05,0.83-1.5,0.23s-1.13-0.75-1.8-0.45c-0.68,0.3-0.15,1.28-1.65,0.9s-1.58-1.2-2.63-1.13
        c-1.05,0.07-1.58,0.07-1.95,0.75c-0.38,0.68-1.95,0.75-3.23,0.68c-1.28-0.08-1.5,0.38-1.95,1.43s0.53,2.25-0.6,2.78
        s-2.03-0.07-1.88-1.35s1.5-0.98,0.6-2.4c-0.9-1.43-1.73-1.43-2.03-0.3s-0.45,2.93-1.2,1.5s1.13-3.45-0.38-4.28
        c-1.5-0.83-2.4,0.83-2.25-1.28c0.15-2.1-0.9-0.6-0.98-3.15c-0.08-2.55-0.75-3.76,0.08-4.88c0.83-1.13,1.05-0.45,0.75-2.03
        s-0.3-1.13-0.6-2.7c-0.3-1.58-0.38-1.58-1.8-2.18c-0.96-0.4-1.6-0.67-1.92-1.25c-10.33,7.48-91.94,66.77-101.65,79.29
        c-1.05,1.2-0.65,2.85,1.2,3.61c1.85,0.75,2.1,0.95,3.45,1.3s4.06,0.75,2.25,2.25c-1.8,1.5-2.6,2.3-2.8,3.31c-0.2,1,0.5,1.75,0,3.36
        c-0.5,1.6-1.4,3.81-0.9,5.01s0.15,3.1-0.55,3.96c-0.7,0.85-1.45,1.9-0.75,2.7s1.7,0.55,3.2,0.5s2.45,0.65,3.61-0.3
        c1.15-0.95,2.2-2.75,3.81-3.1s2.45-1,3.9,0.05c1.45,1.05,2.65,2.35,1.4,3.9s-3.96,1.95-2.45,3.36c1.5,1.4,1.85,1.45,1.5,3.06
        c-0.35,1.6-2,1.7-1.5,3.56c0.5,1.85,1.55,1.35,0.8,3.31c-0.75,1.95-2.8,5.76-2.9,7.76s-0.65,4.56-1.3,5.86s-1.1,5.31-2.25,6.51
        s-1,2.55-1.1,3.6s-2.2,2.2-0.9,3.1c1.3,0.9,3.05,2.1,3.1,4.51c0.05,2.4-0.45,3,0.65,3.91s2.3,2.25,0.3,2.85s-2.85,2.91-3.55,4.01
        c-0.7,1.1,0.05,2.8-1.3,3.65c-1.35,0.85-2.15-0.55-3.25,1.05c-1.04,1.51-2.67,0.73-6.08,1.47c0.25,0.24,0.61,0.39,0.95,0.63
        c0.57,0.4,1.77,0.23,1.65,1.2c-0.12,0.97,0.06,1.65-1.88,1.94s-1.99,1.14-1.99,1.88s0.8,3.41-0.23,3.76
        c-1.02,0.34-1.2,0.63-1.31-0.63s-0.8-1.48-1.25-1.31s0.17,1.42-0.91,1.65c-1.08,0.23-1.76,0.68-1.31,1.2
        c0.46,0.51,1.59-0.06,1.42,0.8c-0.17,0.85-1.31,0.51-1.42,1.31s-0.63,0.74-1.42,0.74s-0.51,0.57-0.51,1.2s-0.74,3.47,0.06,4.04
        s0.4,0.68,0.97,1.14c0.57,0.45,0.51-0.28,0.8-0.8s1.25-0.57,1.71,0.06s1.25,0.45,1.71-0.11c0.46-0.57,2.9,0.17,3.13-0.8
        c0.23-0.97,0.17-1.88,1.42-2.05s1.54-0.11,2.62-0.68s1.94,0.34,0.97,0.74s-1.59,0.63-1.59,1.42c0,0.79-0.17,1.59-1.6,1.77
        c-1.42,0.17-1.71,1.82-3.02,1.93s-2.1,0.68-2.33,1.54c-0.23,0.86-0.46,1.88-1.31,2.05s-0.8,1.02-1.65,0.63
        c-0.85-0.4-1.31,0-1.37,0.97s-0.4,1.94,0.85,1.59c1.25-0.34,2.33-0.74,2.28-0.06c-0.06,0.68,0.11,1.31-0.91,1.59
        c-1.02,0.28-1.76,1.25-1.99,1.99c-0.23,0.74-0.29,1.25,0.51,0.91c0.8-0.34,0.8-0.91,1.54-0.68c0.74,0.23-0.06,1.37,0.68,1.42
        s2.11,0.34,2.22,0.97c0.11,0.63-0.12,1.14,1.25,1.14s0.74-0.46,2.05-0.4c1.31,0.06,1.82,0.06,1.82,0.45s-0.57,1.54-2.16,1.54
        s-1.77,0.85-3.53,0.85s-1.88-0.46-2.05-1.08c-0.17-0.63-0.4-1.14-0.85-1.14s0.23,1.25-0.68,1.59c-0.91,0.34-0.68,1.54-0.57,2.05
        s1.2,0.68,0.63,1.42c-0.57,0.74-0.51,1.08-1.54,1.08c-1.02,0-1.42,0.11-1.42,0.74s-0.23,1.48,0.63,1.48c0.85,0,1.59-0.68,1.48,0.4
        s0.06,1.42-0.8,1.99c-0.85,0.57-1.99,0.91-1.25,1.59s1.25,1.25,0.4,1.76s-0.97,1.31-0.17,1.54s0.97,1.99,2.05,1.94
        s1.68,0.05,2.52,0.43c0.85,0.38,2.07-0.19,2.07,0.66c0,0.85,0,1.6-1.04,1.6s-1.41,0.94-0.47,0.94s-0.09,0.66,1.51,1.32
        s2.73-0.66,2.73,0.38s0.66,1.22,0.66,2.07s-0.47,1.32,0.38,1.7s1.13-0.66,2.26-0.66s1.04-1.13,1.6-2.17
        c0.57-1.04,0.28-0.94,1.13-1.13s0.85,0.66,0.38,1.04s-1.04,0.94-1.23,1.6c-0.19,0.66-0.38,1.51-1.32,1.7
        c-0.94,0.19-0.85,1.22-0.47,1.79s1.6,1.04,0.75,1.98s-1.6,1.98-1.98,1.7s-0.47,1.04-0.57,2.54c-0.09,1.51,0.18,1.82,0.18,2.37
        s-0.55,2.05,0,2.15s0.9-0.1,1-1s0-1.5,0.9-1.45s1.85,0,2.25-0.55s0.2-1.9,1.45-2.3s1.45,0.2,0.85,0.5s-1.2,1.1-1.4,1.8
        c-0.2,0.7-0.8,1.75-1.5,1.9s-1.05,0.45-1.1,1.15c-0.05,0.7-0.25,2.65,1.25,2.9s2.25-0.2,2.95,0.2s1.35,1.35,0.75,2.15
        s-1.8,1.1-1.8,2c0,0.69,0.26,1.4,0.61,2.33c0.91,0.07,2.19,0.35,3.62,1.17c2.83,1.63,93.76,35.61,201.91,49.43
        c4.1,0.41,6.97,0.93,7.32-1.16s0.7-3.75,2.26-2.87c1.57,0.87,2.26,0.79,1.57,2.35c-0.7,1.57-2.52,4.53-0.35,5.14
        c2.17,0.61,1.39,1.22,2.79,1.92c1.39,0.7,3.31,0.17,3.83,0.96c0.52,0.78,2.87,3.48,3.48,1.22c0.61-2.26,2.79-1.74,3.39-0.26
        c0.61,1.48,2.79,1.22,3.57,3.04s3.22,0.09,4.18,1.48s0.17,2.79,2.96,2.79c2.79,0,2.79,1.04,3.57-0.09
        c0.78-1.13,1.92-1.22,3.57-0.52s3.82,1.74,5.23,1.74c1.4,0,2.8-0.7,4.16,1.4c1.15-0.45,1.1-1.85,2.4-2.5s1.05-2.3,2.05-2.45
        s1.2,0.75,1.5,1.25s0.7,0.15,1.4-0.5s1.25,0.05,1.45-0.85s1.3-1.1,1.8-1.25s-0.9-0.6-0.55-1.5c0.35-0.9,0.4,0.25,1.3,0.55
        c0.9,0.3,1.05,0.8,1.55-0.5s-1.8-2.8,0.25-3s1.85,0.4,2.2,0.9c0.35,0.5,1,0.55,2.05,0.6s1.3,0.95,2.25,1s1.35,1.35,1.95,1.35
        s0.9-0.3,1.35-0.95c0.45-0.65,1,0.05,1.7,0.75s1.05,0.3,2.3,0.3s0.85,1.55,1.4,2.05c0.55,0.5,0.8,1.25,1.2,3.7s0.8,1.35,1.95,2.7
        s3.35,1.5,5.71,1.3s1.9,0.55,1.95,1.55s-0.6,1.2-0.8,2.51c-0.2,1.3,0.9,1.35,1.65,1.85s0.25,1.6,0.15,2.7
        c-0.1,1.1-0.65,0.85-1.3,1.25c-0.65,0.4,0.4,1,1.25,1s0.6,0.7,1.2,0.85c0.6,0.15,0.3,1.4,0.55,3s-0.8,1.1,1.05,1.25
        s0.95,1.2,1.25,2s1,1,2,1.05s2.25,0.95,3.6,1.15s0.95,0.4,1.9,0.9s2.05,0.3,4.01,0.15c1.95-0.15,2.5,0.05,4.86,0.35
        c2.35,0.3,1.7,1.1,4.26,1.1c2.55,0,1.5,1.35,2.9,1.1s2.35,0.9,4.66,1.15s1.7,2.65,3.35,3.6s0.65,1.45,2.35,3.15
        c1.7,1.7,0.7,2.45,1.75,3.45s1.1,0.4,1.15,1.25s-0.3,1.05-0.95,1.45s-0.2,1.5-0.25,2.55s-0.5,0.45-2,0.25s-1.1-0.2-2.4-0.85
        s-1.1-0.15-2-0.95s-0.8-0.8-1.05-1.95s-0.45-0.9-1.7-1.3c-1.25-0.4-1.85-2.05-2.55-2.75s-1.15-0.95-2.55-0.95s-1.85,0.7-0.9,0.95
        s0.25,0.5,1.25,1.05s1.2,2.6,1.9,2.75s0.5,1.7,0.25,3.71c-0.25,2-1.8,2.25-2.7,3.35s-0.45,2.05-1.5,3.55s-0.15,2.35-0.35,5.26
        c-0.2,2.9-1.15,2.95-2.7,3.51c-1.55,0.55-0.5,1.5-2.96,1.85c-0.14,0.02-0.23-0.03-0.34-0.04c-0.15,1.64-0.39,3.26-0.7,4.05
        c-0.35,0.9-1.67,1.39-2.99,2.23c-1.31,0.83-4.45,3.22-3.83,5.49c0.8-0.39,1.95,0.22,2.01,0.59c0.07,0.4,0.73,0.46,1.79,0.46
        s1.2-0.4,1.33-0.86s1.2-0.73,1.2-0.73c1.33-0.6,1.39-0.26,2.72-0.73s1.4-0.86,2.59-0.93c1.2-0.07,1.53-0.99,3.05-3.05
        c1.53-2.06,3.52-1.73,5.58-1.73s1.39,0.2,2.19,0.93s1.06,0.6,2.99,0.66c1.93,0.07,1-0.2,1.06-1.79c0.07-1.59,4.32-1.86,6.31-1.73
        s3.39-0.27,4.32-0.47c0.7-0.15,1.26-0.15,1.74,0.06c0.17-0.37,0.18-0.81-0.07-1.4c-0.42-1-0.9-1.76-1.13-2.54
        c-1.11,0.26-2.2-0.06-3.75-0.06c-1.8,0-1.35-0.04-1.54-0.83c-0.19-0.79,0.86-2.18,1.47-3.08s2.29-1.4,2.44-1.43
        c1.28-0.26,1.65-1.2,2.29-1.54c0.64-0.34,1.61-0.34,3.57-0.34c1.95,0,1.73-0.07,2.93-0.83s2.82-0.6,4.02-0.98
        c1.2-0.38,2.52,0.56,3.42,1.24c0.9,0.68,2.29,0.34,3.19,0.34s1.73-0.83,1.77-1.65c0.04-0.83-1.61-0.11-2.52-0.98
        c-0.9-0.86,0.49-0.83,1.95-0.64c1.47,0.19,1.88-0.04,2.7-0.19s1.46-1.01,2.7-1.09c1.22-0.07,0.59,1.86,0.97,2.6
        c0.82-0.25,1.64-0.6,1.8-1.86c0.28-2.16,0.97-2.78,2.22-3.96c1.25-1.18,3.41-5.21,9.11-5.49s15.92-0.77,20.65-0.77
        c4.73,0,7.16,0.42,8.34-1.53c1.18-1.95,1.39-2.64,2.99-2.64s2.22,0,2.36-1.32s1.95-1.32,2.09-2.78c0.14-1.46,1.46-2.02,1.53-4.87
        c0.07-2.85,0.77-2.15,1.74-4.03s-0.21-3.27,1.46-4.94c1.67-1.67,2.08-2.78,2.36-4.1c0.28-1.32,0.9-1.39,1.39-2.57
        s1.25-2.71,2.29-0.63c1.04,2.09,0.7,2.85,2.09,2.99s1.39,0.14,2.22-1.25c0.83-1.39,0.9-1.74,2.43-1.39
        c1.53,0.35,1.88,0.76,2.85,1.88c0.97,1.11,2.71,1.39,2.36,3.34c-0.35,1.95-0.21,2.78,0.28,4.17c0.49,1.39,0.49,2.43,0.49,3.27
        c0,0.83-0.28,4.73,0.77,5.63c1.04,0.9,2.09,1.67,2.71,2.78c0.63,1.11-0.42,2.15,1.18,2.99c1.6,0.83,1.67,0.42,2.43,1.32
        c0.65,0.77,0.71,1.28,2.17,1.64c0-0.1-0.04-0.17-0.01-0.29c0.24-1.29,0.16-1.25,1.21-1.09c1.04,0.16,1.97,0.28,2.29-0.44
        s0.8-0.96,1.61-0.88c0.8,0.08,1.25,0.16,1.33-0.96c0.08-1.13-0.24-1.77,0.56-2.13s1.05-0.36,1.13,0.72s0,1.85,0.84,1.73
        c0.84-0.12,0.32-1.33,1.29-1.41s0.77-0.76,2.17-1.08c1.41-0.32,0.84-1.17,1.69-1.29c0.84-0.12,0.96-1.27,1.87-1.61
        s1.08-0.64,1.29-1.59c0.21-0.95,0.86-0.13,0.95-1.16s0.39-1.81,0.91-1.51c0.52,0.3-0.39,1.42,0.17,1.46
        c0.56,0.04,1.12-0.3,1.55,0.04s-0.17,0.99-0.65,0.99s-0.6,0.17-0.6,0.99s-0.34,1.59-1.03,1.81c-0.69,0.21-1.12,0.51-0.78,1.16
        s0.47,0.86,1.77,0.86s1.42-0.26,1.77,0.13c0.34,0.39,0.69,0.43,1.25,0.43s2.07-0.43,2.15,0.17s0.39,1.29-0.65,1.29
        c-1.03,0-1.59,0.13-2.5-0.35c-0.9-0.47-1.98-0.65-2.58-0.3c-0.6,0.35-1.68,0.82-2.28,1.29c-0.6,0.47-2.2,1.33-2.5,2.07
        c-0.3,0.73-2.06,2.93-3.23,3.23c-1.16,0.3-2.11,1.12-2.28,2.28c-0.17,1.16,0.3,1.76-0.3,2.41c-0.6,0.65-1.42,1.72-0.09,2.37
        s0.09,2.97,1.12,3.53c1.03,0.56,1.85,0.17,2.67,1.12s1.17,1.89,1.77,2.07c0.6,0.17,1.98,0.04,2.2-0.86c0.22-0.9,0.3-1.12,1.16-1.34
        c0.86-0.21,2.07-0.6,2.41-1.89c0.34-1.29,2.33-1.08,2.5-2.24s0.47-1.98,1.12-2.41c0.65-0.43,1.12-0.43,1.2-1.55
        c0.09-1.12,0.65-1.2,1.38-2.41s0.78-2.2,1.34-2.2s0.39,1.94,1.16,2.02c0.77,0.09,1.42-0.56,1.72-1.55s0.99-0.73,1.98-0.82
        c0.99-0.09,0.99-1.2,2.34-1.2c1.35,0,2.29-0.19,2.74-0.79c0.45-0.6,1.28,0,2.33-1.01s1.28-1.16,2.22-1.39
        c0.94-0.23,0.3-0.9,1.91-1.28s1.65-1.35,2.7-1.43c1.05-0.07,0.86-1.16,1.77-1.39c0.9-0.23,1.2-0.26,1.54-0.98
        c0.34-0.71-0.75-0.68-1.28-1.24c-0.53-0.56-0.64-0.19-0.64-1.2s-0.04-1.24-0.64-1.46c-0.6-0.23-1.24-0.34-1.61,0.23
        c-0.38,0.56-1.13,0.9-1.69,0.56s-1.05-0.34-1.01-1.16c0.04-0.83-0.04-1.46-0.98-1.28c-0.94,0.19-1.43,0.68-1.54,1.43
        s-0.41,1.09-1.77,1.09s-2.48,0.26-3.04-0.6s-0.86-1.43-2.29-1.31c-1.43,0.11-2.39,0.53-3.69,0.58s-4.06,0.85-4.46-0.5
        s-0.15-3.5-1.95-3.5s-4.35,1.6-4.55-0.05c-0.2-1.65-0.35-1.85-1.35-2.6s-0.85-1.85-1.65-2.55c-0.8-0.7-0.2-3.55-1.1-3.71
        c-0.9-0.15-3.81,0.8-3.76-0.25s1-0.5,1.45-1.9s2-0.1,1.65-2.3s0.45-3.05-0.75-3.96c-1.2-0.9-1.45-1.3-2.6-1.1
        c-1.15,0.2-0.75,0.65-1.25,1.65s-0.65,1.55-1.7,1.65c-1.05,0.1-0.4-1.85-1.55-2.3c-1.15-0.45-1.65-0.75-2.46-0.7
        c-0.8,0.05-1.25-0.85-2.75-0.7c-1.5,0.15-1.75,0.35-2.75,0.1s-0.25-0.9,0.6-0.95s0.6-0.95,2.15-0.8c1.55,0.15,2.35,0.4,2.85-0.1
        s1-0.8,1.3-0.3s0.8,1.05,1.85,0.95s0.25,0.85,1.4,0.8s0.8-0.6,1.8-0.7c1-0.1,1.2-0.1,1.75-1.05s0.95-1.65,1.85-1.95
        c0.9-0.3,1.05-0.1,1.5-1s0.5-0.95,1.2-1.15s0.9-0.3,1.05-0.85c0.15-0.55,1.7-0.2,1.6-1.05c-0.1-0.85-1.1-0.55-1.35-1.3
        s-1.65-0.55-1.4-1.3s1.45-0.6,1.4-1.1s-1.05,0.2-1.05-0.6s-0.1-0.8-1.1-0.85s-0.9,0-1.45-0.75s-1.05,0.1-2.25-0.8
        c-1.2-0.9-2-1.05-3.2-1.05s-2.91,0.02-4.61,0.81s-2.15,0.68-3.96,0.9c-1.81,0.23-2.72,0.91-4.19,1.13
        c-1.47,0.23-2.27,1.81-4.08,2.38s-3.28,1.7-4.98,2.72c-1.7,1.02-4.98,3.06-5.66,3.96s-4.07,2.38-4.98,3.96s-2.72,4.53-3.85,6.11
        s-2.15,2.38-2.72,3.62s-1.24,2.15-1.47,2.6c-0.23,0.45-0.79,1.02-2.04,1.59c-1.25,0.57-2.15,1.58-3.28,2.04s-2.72,1.02-0.91-1.02
        c1.81-2.04,3.17-1.93,3.96-3.85c0.79-1.92,1.61-3.48,3.22-4.95c1.61-1.46,2.4-1.73,2.78-2.82c0.38-1.09,0.11-2.48-0.71-2.85
        c-0.83-0.38-2.74-1.61-3.15-1.95c-0.41-0.34-0.98,1.35-2.52,0.19s-1.01-1.69,0.04-1.73s1.16,0.38,2.22,0.56
        c1.05,0.19,1.8,0.3,2.52,0.86c0.71,0.56,1.65,1.65,2.44,1.58c0.79-0.07,1.09-0.38,1.43-1.31c0.34-0.94,1.99-0.94,2.22-2.29
        c0.23-1.35-0.56-2.73,1.94-4.53s2.3-4.81,4.71-4.81s2.21-0.7,3.91-0.9c1.7-0.2,1.6-1.4,2.4-1.5c0.8-0.1,1.4,0.2,1.5-1.4
        s0.2-2.6,0.8-3.21c0.6-0.6,0.3-2.9,2.4-3.81c2.1-0.9,1.2-1.9,2.7-1.9s1.9,0.2,2.6-0.3s0.8-0.7,3.21-0.7s4.05,0.38,5.63,0.07
        s0.53-1.13,3.6-1.13c3.08,0,3.91-1.05,5.33-0.68c1.43,0.38,1.5,0.45,2.55,0.53c1.05,0.07,1.88,0.22,2.25-0.38
        c0.38-0.6,1.2-0.83,2.4-0.83s1.73-0.07,3.53,0.07c1.8,0.14,3.38-0.15,5.56-0.07c2.18,0.07,2.48,0.83,3.98,0.07
        c1.5-0.75,1.8-1.28,3.23-1.28s2.25,0.3,2.85-0.45s1.28-0.83,2.18-0.9c0.9-0.07,1.95,0.22,2.55-0.9c0.6-1.13,0.83-1.8,1.65-2.03
        s0.9-0.75,1.58-1.58s1.28-2.1,2.18-2.1c0.9,0,1.05-0.98,1.05-2.1c0-1.13-0.08-1.58,0.68-2.33c0.75-0.75,0.08-2.63,1.5-2.63
        s1.2,0.75,2.55,0.15s0.75-1.5,2.25-1.8s1.13-1.35,3.23-1.35s2.55,0.15,3.53-0.9s1.8-1.35,2.78-2.55c0.98-1.2,1.8-1.28,2.1-2.78
        s0.75-1.8,1.35-2.03c0.6-0.22,0.75-0.3,0.98-0.98C797.503,116.874,796.003,116.574,796.373,115.514z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M240.203,25.144c0,0-0.3,1.04-0.52,2.59
        c-0.22,1.56-0.15,3.33-1.26,3.85s-0.74,1.33-0.66,2.44c0.08,1.11,0.15,2.22-0.45,2.89c-0.59,0.67-0.52,1.63-0.44,2.66
        s-0.07,0.66,0,2.89s0.81,1.7,0.15,3.48c-0.67,1.78-0.22,1.93,0.22,2.89s1.48,1.33,1.63,0.37c0.15-0.96-1.26-0.52-0.74-1.93
        c0.52-1.41,1.18-0.89,0.89-2.74s-0.15-2.07,0.82-2c0.96,0.07,1.63,0.07,1.18-0.74c-0.44-0.81-1.48,0.44-1.26-1.93
        s0.3-2.29,1.18-2.29s1.78,0.15,2.07-0.59c0.3-0.74-0.22-1.04-1.11-1.04s-2.37-0.74-1.48-1.18s2.66,0,2.96-0.96s1.18-0.66,1.63-1.48
        c0.45-0.81,0.89-1.33,1.78-1.93c0.89-0.59,0.45-1.56,1.48-1.63s1.93-0.07,1.63-0.89c-0.3-0.81-1.48-1.63-2.29-1.11
        s-1.41,1.85-2.3,2.3c-0.89,0.44-1.41,1.26-2.96,0.67s0.44-1.26,1.78-1.78c1.33-0.52,1.7-0.59,1.93-1.63c0.22-1.04-1.18-0.74-1.18-2
        s-0.15-1.7-1.48-1.7C242.053,22.624,240.493,23.294,240.203,25.144z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" d="M245.453,73.034c0,0-0.94-0.04-1.28,0.98s-0.83,1.39,0.11,1.73
        c0.94,0.34,1.73,0.38,2.22,0.38s1.09,0.26,1.47,0.75s0.6,0.68,0.3,1.2c-0.3,0.53-0.75,1.13-1.51,0.68
        c-0.75-0.45-2.03-1.51-2.41-1.05s-0.56,0.98-1.13,2.15s0.19,1.62,1.73,2.26s2.93,0.49,2.63,1.5c-0.3,1.02-1.28,0.6-1.28,1.39
        s-0.26,1.39,0.34,1.73s1.05,0.53,0.57,1.05c-0.49,0.53-1.24,0.53-0.83,0.94s1.58,0.3,1.77,0.83s0.72,0.53,1.36,0.53
        s1.92-0.3,1.84,0.6c-0.07,0.9-2.26,0.87-3.24,0.87c-0.98,0-0.83,0.53-0.75,1.13s0.26,0.9,1.2,1.09c0.94,0.19,1.81,0.11,1.81,0.79
        s-0.68,1.13-0.19,1.81s0.79,1.35,0.57,1.84c-0.23,0.49-0.79,1.39-0.6,2.07c0.19,0.68,0.45,0.98,1.39,0.94s0.83-0.87,1.54-0.11
        c0.72,0.75,1.58,0.49,2.33-0.53s1.01-1.28,1.35-0.83s0.68,1.02-0.15,1.62c-0.83,0.6-1.24,1.43-2.37,1.47
        c-1.13,0.04-1.77-0.19-1.88,0.68c-0.11,0.86,0.83,2.75,0.83,2.75s2.86,2.71,3.2,3.91c0.34,1.2,1.66,1.62,2.75,2.45
        c1.09,0.83,2.75,2.22,2.86,0.57c0.11-1.66,1.58-2.22,1.69-4.03s0.75-1.66,0.04-2.63c-0.71-0.98-0.75-0.75-0.38-1.88
        c0.38-1.13,0.83-1.47,0.15-2.22s-0.9-1.88-1.92-2.33c-1.02-0.45-1.32-1.43-1.58-2.18c-0.26-0.75-1.32-1.32-1.02-2.63
        c0.3-1.32,1.32-0.94,1.17-2.41s0.04-3.8,0.04-5.34s0.26-3.42-1.13-3.99c-1.39-0.56-1.17-0.83-3.12-2.03
        c-1.96-1.21-4.18-2.41-4.74-4.03c-0.56-1.62-0.57-2.41-1.77-3.27c-1.2-0.87-1.2-1.99-2.33-2.11
        C245.983,71.984,246.813,72.884,245.453,73.034z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_1_" x="369.693" y="67.374" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="71.09" height="24.99" pointer-events="auto" visibility="hidden"></rect>

        <rect id="UNANSWERED_1_" x="470.275" y="76.408" opacity="0" fill="#8A0084" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="24.768" height="24.99" pointer-events="auto" visibility="hidden"></rect>
</g>
<rect id="SVGHUD" x="842.983" y="-260.676" stroke="#FFFFFF" stroke-width="6" stroke-miterlimit="10" width="113.92" height="27.94"></rect>
<g id="CITY_CARIBBEANSEA" data-colors="0" data-errors="0" hint-index="0" class="">
    <circle id="CITYR5_39_" fill="#166c38" cx="750.173" cy="573.022" r="9.014" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea" opacity="0"></circle>
    <path fill="none" stroke="#95bec8" stroke-miterlimit="10" d="M750.918,523.943c36.021-6.06,70.643,6.798,77.332,28.719
        c6.688,21.923-17.089,44.608-53.109,50.668c-36.021,6.06-70.643-6.798-77.332-28.719
        C691.121,552.691,714.898,530.005,750.918,523.943z" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></path>

        <rect id="rect12_40_" x="726.616" y="585.634" opacity="0" fill="#FFFFFF" enable-background="new    " width="53.022" height="16.967" pointer-events="none"></rect>
</g>
<g id="CITY_PUERTORICO" data-colors="0" data-errors="0" hint-index="0" class="">
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M753.493,526.484c0,0-1.35-0.49-1.73,0.08
        c-0.38,0.56-0.49,1.54-0.98,1.39c-0.49-0.15-1.01-0.98-1.43-1.39c-0.42-0.41-0.94-0.26-1.24,0.38s-0.3,1.43-0.64,1.43
        s-0.9-0.23-0.9,0.64c0,0.86,1.16,0.11,1.05,1.5s-0.53,3.34,1.43,3.38s2.55-0.68,3.46-0.38c0.9,0.3,1.28,0.41,2.37,0.23
        c1.09-0.19,1.05-0.45,2.63-0.45s4.02,0.79,4.58,0.08c0.56-0.71,0.9-0.83,1.35-1.28c0.45-0.45,0.34-0.34,0.94-1.01
        c0.6-0.68,0.64-0.75,1.24-1.01s1.46-0.41,1.5-1.05s-0.75-0.34-1.13-0.71c-0.38-0.38-0.53-0.83-1.73-0.83c-1.2,0-2.1,0.56-2.85,0.15
        s-1.35-1.01-2.1-1.09s-1.58-0.15-2.29,0C756.313,526.674,754.733,526.674,753.493,526.484z" pointer-events="none" class="landarea"></path>
    <ellipse fill="none" stroke="#95bec8" cx="761.516" cy="528.35" rx="19.506" ry="13.862" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></ellipse>
</g>
<g id="CITY_THEBAHAMAS" data-colors="0" data-errors="0" hint-index="0" class="">
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M620.483,431.444c0,0,0.15-0.34-1.16-0.86s-1.73-0.23-1.95,0
        c-0.22,0.23-0.08,1.28-1.01,1.58c-0.94,0.3-1.84,0.64-1.35,1.28c0.49,0.64,1.99,1.05,2.63,0.26c0.64-0.79,0.11-1.95,1.69-1.5
        s2.14,0.41,2.82,0.38c0.68-0.04,0.19-0.53,1.13-0.86c0.94-0.34,1.39-0.19,1.39-0.79s-0.45-0.75-0.98-0.56
        c-0.53,0.19-0.68,0.45-1.28,0.83C621.803,431.554,620.893,432.124,620.483,431.444z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M631.523,430.764c0,0,0.11-0.71-0.64-0.9s-1.01-0.23-1.69-0.68
        c-0.68-0.45-0.68-1.24-1.24-1.24s-2.48-0.11-2.33,0.53c0.15,0.64,0.45,0.6,1.35,0.71c0.9,0.11,0.98,1.05,1.84,1.35
        c0.86,0.3,0.94,0.08,1.65,0.9c0.71,0.83,1.91,1.58,2.18,2.33c0.26,0.75,0.79,1.84,0.19,2.4s-0.38,1.5-0.94,1.95
        s-0.71,0.64-0.71,1.31s-0.3,1.24,0.38,1.24s1.69-0.23,1.69-1.39s0.04-1.88,0.45-2.07c0.41-0.19,1.02-0.19,1.02-0.83
        s0.26-0.94,0.04-1.58c-0.22-0.64-0.3-1.65-0.86-1.88s-0.6-0.75-1.05-0.98C632.393,431.744,631.673,431.484,631.523,430.764z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M625.853,454.984c0,0,0.08-0.75-0.6-1.5s-0.75-0.98-0.94-2.03
        s0.41-2.37-0.64-2.44s-1.35,0.23-1.35,0.98s0.38,2.59-0.26,2.89c-0.64,0.3-0.94,0.6-0.94,1.13s0.34,1.31-0.41,1.5
        s-1.24,0.45-0.9,1.01c0.34,0.56,1.05-0.38,1.24,0.75s1.28,1.54,1.84,1.99c0.56,0.45,1.65,0.9,2.14,0.34s0.75-0.98,1.54-1.09
        s0.83-0.38,0.79-1.28c-0.04-0.9-0.08-1.05-0.64-1.09C626.153,456.114,625.933,455.594,625.853,454.984z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M628.713,462.274c0,0-0.11-1.16-0.94-1.2s-1.43,0.64-1.54,1.01
        c-0.11,0.38-1.01,1.35-0.19,2.29c0.83,0.94,1.43,1.16,1.69,1.84s2.25,1.5,1.95-0.19
        C629.383,464.344,629.123,463.404,628.713,462.274z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M643.163,448.114c0,0-1.39-0.68-1.95-1.16s-1.46-1.61-2.33-1.61
        s-0.45,1.39,0.49,1.92s1.5,0.79,1.99,1.24c0.49,0.45,2.33,0.64,2.37,1.8c0.04,1.16,0.56,1.73-0.26,1.84s-0.86,0.56-0.86,0.98
        c0,0.41-0.19,2.4,0.56,2.59s1.69,0.75,1.69-0.41s-0.15-2.29-0.04-3.08c0.11-0.79,0.71-2.82-0.15-3.19
        C643.803,448.644,643.953,448.604,643.163,448.114z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M652.553,459.154c0,0-0.6-0.04-1.09-1.2
        c-0.49-1.16-0.41-1.24-0.98-1.65c-0.56-0.41-0.71,0.3-0.11,1.46c0.6,1.16,0.34,1.5,0.64,2.93s0.26,1.65,1.09,1.84
        s1.73,0.75,1.88-0.64C654.133,460.504,653.563,459.874,652.553,459.154z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M648.803,470.274c0,0-0.11-0.6-1.05-0.75s-0.45-0.79-1.58-1.05
        c-1.13-0.26-0.68-1.58,0.45-0.9s1.01,0.68,1.54,1.01s1.35,0.71,1.58,1.28C649.963,470.424,649.363,471.554,648.803,470.274z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M656.643,471.584c0,0,0.26-0.6-0.34-1.09
        c-0.6-0.49-0.64,0.04-0.75-1.2c-0.11-1.24,0.04-1.61-0.6-1.61c-0.64,0-0.64,1.09-0.6,1.8s1.39,1.31,1.35,2.63
        c-0.04,1.31,0.08,1.84,0.83,2.37s0.9,0.64,1.13,1.31c0.22,0.68,0.71,0.56,1.2,0.56s1.13-0.38,0.49-0.68s-0.45-1.16-1.31-1.5
        C657.173,473.844,656.193,473.054,656.643,471.584z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M667.013,478.874c0,0-0.83-0.11-0.98,0.15
        c-0.15,0.26-0.56,0.79,0.22,0.79s1.31,0.34,1.5-0.11C667.953,479.244,667.803,478.944,667.013,478.874z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M669.153,481.204c-0.03,0.23,0.19,0.68-0.45,0.83
        s-0.56,0.41-0.94,0.9s-0.34,1.2-1.2,1.35s-0.98,0.45-0.86,1.13c0.11,0.68,0.56,0.83,0.98,0.23c0.41-0.6,0.53-0.79,1.09-1.05
        s0.98-0.3,1.28-0.79c0.3-0.49,0.68-0.26,0.94-1.01s0.38-1.05,0.22-1.77C670.053,480.294,669.373,479.584,669.153,481.204z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M680.643,482.364c0,0-0.56-0.41-1.35-0.11s-1.28,0.83-0.68,0.9
        c0.6,0.08,2.14-0.11,2.37,0.38c0.22,0.49,0.49,0.83,1.09,0.64s0.38-0.11,0.26-0.53
        C682.223,483.224,682.293,482.814,680.643,482.364z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M676.063,497.684c0,0-1.54-0.53-1.77,0.26
        c-0.22,0.79-1.65,0.34-1.65,1.46c0,1.13,0.45,1.05,1.32,1.05c0.86,0,1.77-0.04,2.59-0.08c0.83-0.04,1.39,0.53,1.73-0.41
        s0.22-1.13,1.01-1.46c0.79-0.34,1.13-0.3,1.16-1.24c0.04-0.94,0.53-1.31-0.56-1.39c-1.09-0.08-1.43,0.41-1.47,0.83
        c-0.04,0.41,0.11,1.09-0.56,1.16C677.183,497.944,676.883,498.064,676.063,497.684z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M693.413,488.714c-0.13-0.33-0.3-0.64-0.9-0.71
        c-0.6-0.08-1.77-1.24-1.31-0.3c0.45,0.94-0.11,1.13,0.56,1.28c0.68,0.15,1.39,0.34,1.39,0.86s0.19,0.53,0.79,0.49
        s1.69,0.38,1.69-0.26s-0.08-1.09-0.71-0.83C694.273,489.494,693.593,489.194,693.413,488.714z" pointer-events="none" class="landarea"></path>
    <ellipse fill="none" stroke="#95bec8" cx="636.626" cy="449.277" rx="23.862" ry="19.844" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></ellipse>
    <ellipse fill="none" stroke="#95bec8" cx="667.833" cy="475.861" rx="23.862" ry="19.844" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></ellipse>
    <ellipse fill="none" stroke="#95bec8" cx="676.62" cy="484.228" rx="23.862" ry="19.844" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></ellipse>

        <rect id="rect12_2_" x="652.353" y="448.577" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="31.22" height="24.99" pointer-events="none"></rect>
</g>
<g id="CITY_RIOGRANDE" data-colors="0" data-errors="0" hint-index="0" class="">
    <path id="RIVER_7_" fill="none" stroke="#95bec8" stroke-miterlimit="10" d="M324.505,280.685c1.752,2.757,3.791-1.697,5.794-1.414
        c2.637,3.787,7.171,1.606,9.596,5.559c1.922,2.316-0.325,3.933-0.95,6.137c-1.458,3.817,0.734,10.697-3.248,12.464
        c0.267-0.06-1.376,0.559-1.037,0.338c-2.128,1.771-0.109,5.541-0.982,7.797c-3.925,2.943-11.481,8.113-8.343,13.935
        c0.757,2.724,0.757,2.724-0.092,4.594c-0.578,1.272-0.508,3.516-0.574,4.531c-0.05,0.764,0.546,3.02,0.688,3.327
        c1.09,2.352,4.566,3.429,5.616,5.968c1.971,4.767,2.927,6.837,6.027,10.029c3.033,4.305,3.252,8.776,6.359,13.161
        c6.751,9.524,5.918,14.205,14.044,18.084c5.549,2.649,5.037-11.753,17.472-8.639c8.915,2.232,13.973,25.422,20.262,39.257
        c5.832,12.829,12.448,11.158,20.406,12.829" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></path>
    <path id="_x3C_Path_x3E__2_" fill="none" stroke="#95bec8" stroke-miterlimit="10" d="M415.058,438.592
        c-3.254,0-5.782-0.775-7.075-1.172l-0.084-0.026c-0.896-0.237-1.776-0.515-2.636-0.826c-0.642-0.075-1.334-0.182-2.067-0.337
        c-1.728-0.18-5.135-0.574-8.858-2.585c-2.179-1.179-3.982-2.511-5.296-3.485l-0.623-0.457c-3.988-2.906-5.931-6.378-6.761-7.863
        l-0.024-0.041c-2.429-3.601-3.293-6.954-3.707-9.01c-0.033-0.075-0.065-0.149-0.1-0.229c-1.39-1.359-2.346-2.678-2.977-3.645
        c-1.865-2.043-3.494-4.487-4.57-6.87c-0.521-1.153-0.891-2.232-1.161-3.179c-2.41,1.576-4.854,2.402-6.918,2.829
        c-6.492,1.337-11.955-0.689-13.753-1.355c-2.593-0.96-4.455-2.228-5.551-3.037c-0.337-0.181-0.674-0.362-1.001-0.547
        c-1.021-0.568-1.893-1.179-2.606-1.739c-1.722-1.032-3.251-2.317-4.575-3.847c-1.797-2.079-2.824-4.133-3.428-5.452
        c-0.597-0.842-1.523-2.18-2.401-3.932c-0.792-1.58-1.658-3.557-2.213-6.061c-1.658-1.428-3.195-3.084-4.664-5.03
        c-0.514-0.681-0.95-1.328-1.32-1.918c-0.473-0.486-0.892-0.969-1.264-1.435c-0.484-0.334-0.982-0.712-1.49-1.14
        c-2.777-1.675-4.632-3.753-5.805-5.45c-1.015-1.466-1.654-2.825-2.035-3.709c-0.488-0.877-1.164-2.198-1.737-3.907
        c-0.699-1.008-1.415-2.231-2.027-3.679c-0.05-0.117-0.098-0.234-0.145-0.35c-0.371-0.492-0.735-1.008-1.094-1.569
        c-5.058-7.996-2.659-15.382-1.394-18.187c0.362-0.803,0.777-1.567,1.243-2.293c0.409-1.177,0.967-2.449,1.733-3.741
        c0-0.034,0-0.07,0-0.104c-0.06-1.874-0.165-6.353,2.506-11.166c0.597-1.069,1.231-1.969,1.849-2.727
        c0.639-1.174,2.032-3.643,4.554-5.992c0.058-0.055,0.116-0.107,0.172-0.159c0.121-0.14,0.246-0.28,0.375-0.422
        c0.268-1.935,0.766-3.649,1.409-5.164c-2.585-1.253-5.062-3.224-7.231-6.11c-5.912-7.869-4.357-19.082,3.51-24.997
        c2.528-1.9,5.395-3.032,8.317-3.432c5.064-2.271,9.825-1.674,12.087-1.162c2.377,0.536,4.606,1.499,6.632,2.856
        c3.776,0.823,7.026,2.689,10,5.704v0.001c1.076,0.979,3.212,2.98,4.877,6.207c0.855,1.662,1.713,3.487,2.267,5.711
        c0.734,2.95,1.313,8.309-1.776,13.991c0.007,1.012,0.005,2.101-0.038,3.249c-0.108,2.95-0.785,5.673-2.018,8.141
        c-0.544,2.099-1.455,4.04-2.659,5.756c-0.348,2.481-1.21,5.366-3.161,8.193c-1.191,1.721-2.627,3.191-4.301,4.404
        c-0.224,0.242-0.464,0.488-0.72,0.734c-0.001,0.769-0.038,1.415-0.065,1.904l-0.009,0.184c-0.103,3.195-0.86,6.139-2.258,8.798
        c0.145,0.308,0.285,0.627,0.418,0.958c0.071,0.176,0.139,0.35,0.204,0.522c1.913,1.48,3.472,2.961,4.743,4.51
        c1.422,1.282,2.638,2.776,3.637,4.479c0.148,0.25,0.292,0.504,0.433,0.76c0.979,0.754,2.095,1.759,3.169,3.065
        c0.641,0.616,1.167,1.199,1.618,1.746c0.603,0.565,1.332,1.311,2.069,2.231c1.237,1.546,2.137,3.115,2.79,4.624
        c1.231-0.499,2.585-0.926,4.063-1.229c1.262-0.26,2.415-0.368,3.367-0.403c0.287-0.065,0.588-0.128,0.909-0.186
        c1.65-0.301,7.505-1.11,13.326,1.318c4.117,1.719,6.712,4.344,8.043,5.745l0.129,0.108c0.906,0.754,2.422,2.018,3.945,3.845
        l0.262,0.307c1.032,1.209,2.759,3.23,4.118,5.996c0.17,0.346,0.32,0.68,0.454,0.992c0.703,1.122,1.544,2.668,2.198,4.594
        c0.071,0.16,0.147,0.317,0.222,0.477c0.284,0.606,0.565,1.221,0.833,1.856c0.416,0.987,0.724,1.914,0.956,2.74
        c0.224,0.284,0.442,0.577,0.661,0.886c0.861,0.812,1.753,1.803,2.646,3.058c2.347,2.624,3.764,5.154,4.692,8.452
        c0.247,0.544,0.548,1.244,0.847,2.081c1.961,0.282,3.811,0.853,5.64,1.733c7.46,0.772,13.911,6.237,15.585,13.966
        c2.083,9.622-4.029,19.112-13.653,21.198C417.803,438.465,416.371,438.592,415.058,438.592z M402.249,436.007
        c0.008,0.002,0.018,0.005,0.026,0.007C402.266,436.012,402.257,436.01,402.249,436.007z M381.883,422.16
        c0.004,0.006,0.008,0.013,0.013,0.019C381.89,422.173,381.886,422.166,381.883,422.16z M411.799,403.283
        c-0.008,0.001-0.017,0.004-0.025,0.005C411.782,403.286,411.789,403.284,411.799,403.283z M417.073,402.94
        c0.002,0.001,0.006,0.001,0.009,0.002C417.079,402.941,417.077,402.941,417.073,402.94z M409.393,400.87l0.1,0.074
        C409.458,400.92,409.426,400.895,409.393,400.87z M411.251,400.547c0,0.005,0.001,0.009,0.002,0.015
        C411.252,400.557,411.251,400.552,411.251,400.547z M400.97,381.939c0.002,0.009,0.005,0.019,0.008,0.026
        C400.975,381.957,400.973,381.948,400.97,381.939z M329.479,378.243c0.013,0.009,0.024,0.021,0.037,0.03
        C329.502,378.264,329.492,378.252,329.479,378.243z" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></path>

        <rect id="rect12_22_" x="310.219" y="381.965" opacity="0" fill="#FFFFFF" enable-background="new    " width="62.892" height="20.125" pointer-events="none"></rect>
    <circle id="CITYR5_10_" fill="#166c38" cx="341.665" cy="367.06" r="10.692" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea" opacity="0"></circle>
</g>
<g id="CITY_ATLANTIC" data-colors="0" data-errors="0" hint-index="0" class="">
    <circle id="CITYR5_33_" fill="#166c38" cx="798.858" cy="344.417" r="9.014" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea" opacity="0"></circle>
    <path fill="none" stroke="#95bec8" stroke-miterlimit="10" d="M818.62,222.204c55.616-21.888,109.073,24.54,119.401,103.696
        s-26.385,161.063-81.999,182.95c-55.616,21.888-109.073-24.54-119.401-103.696C726.293,325.997,763.003,244.087,818.62,222.204z" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></path>

        <rect id="rect12_9_" x="765.345" y="357.333" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="71.09" height="24.99" pointer-events="none"></rect>
</g>
<g id="CITY_PACIFICOCEAN" data-colors="0" data-errors="0" hint-index="0" class="">
    <circle id="CITYR5_1_" fill="#166c38" cx="105.184" cy="359.494" r="9.014" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea" opacity="0"></circle>
    <path fill="none" stroke="#95bec8" stroke-miterlimit="10" d="M78.313,167.003c48.081-27.953,94.295,31.34,103.223,132.432
        c8.928,101.092-22.81,205.696-70.889,233.649c-48.081,27.953-94.295-31.34-103.223-132.432S30.232,194.95,78.313,167.003z" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></path>

        <rect id="rect12_16_" x="69.639" y="373.804" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="71.09" height="24.99" pointer-events="none"></rect>
</g>
<g id="CITY_GULFOFMEXICO" data-colors="0" data-errors="0" hint-index="0" class="">
    <circle id="CITYR5_2_" fill="#166c38" cx="481.813" cy="445.465" r="9.014" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea" opacity="0"></circle>
    <path fill="none" stroke="#95bec8" stroke-miterlimit="10" d="M475.68,392.284c38.56-7.355,75.623,8.247,82.783,34.848
        c7.16,26.601-18.293,54.126-56.851,61.481c-38.56,7.355-75.623-8.247-82.783-34.848C411.668,427.164,437.12,399.638,475.68,392.284
        z" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></path>
</g>
</svg>
}