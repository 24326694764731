export default function Italy() {
    return <svg version="1.1" id="svgpoint" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 900 725" enable-background="new 0 0 900 725">
    <rect id="BACKGROUND" x="2.015" y="2.24" fill="#deded7" stroke="#deded7" stroke-miterlimit="10" width="900" height="724.8" class="background"></rect>
    <g id="AREA_ITALY_ABRUZZI" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Abruzzo" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_ITALY_ABRUZZI.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <path id="ITALY_ABRUZZI" fill="#1e8346" stroke="#7eb08e" stroke-width="0.5" d="M554.995,350.5c-0.47,0.3-1.03,0.56-1.3,0.79
            c-0.38,0.32-1.16,0.93-1.46,1.33c-0.49,0.67-0.92,2.34-1.4,3.03c-0.43,0.63-1.92,1.45-2.12,2.18c-0.18,0.64,0.58,1.94,0.61,2.61
            c0.03,0.69-0.03,2.16-0.42,2.73c-0.43,0.62-2.15,0.92-2.61,1.52c-0.57,0.75-0.52,2.81-0.97,3.64c-0.55,1.02-2.03,2.99-3.09,3.46
            c-0.83,0.36-2.79,0.26-3.64-0.06c-0.56-0.21-1.55-1.04-1.82-1.58c-0.33-0.65-0.04-2.21-0.24-2.91c-0.16-0.54-0.56-1.69-1.03-2
            c-0.54-0.37-1.98-0.42-2.61-0.24c-0.49,0.14-1.15,1.35-1.64,1.21c-0.3-0.08-0.22-0.98-0.48-1.15c-0.38-0.25-1.37-0.09-1.82,0
            c-0.48,0.1-1.45,0.46-1.82,0.79c-0.47,0.41-1.17,1.51-1.27,2.12c-0.1,0.61,0.33,1.84,0.55,2.43c0.15,0.39,0.76,1.04,0.79,1.45
            c0.02,0.34-0.2,1.09-0.48,1.27c-0.55,0.36-2.07-0.49-2.61-0.12c-0.53,0.37-0.64,1.85-0.79,2.49c-0.11,0.48-0.05,1.52-0.3,1.94
            c-0.24,0.4-1.05,1.1-1.52,1.09c-0.37-0.01-0.81-0.77-1.15-0.91c-0.35-0.15-1.18-0.36-1.52-0.18c-0.56,0.3-0.86,1.68-1.15,2.24
            c-0.21,0.4-0.7,1.05-0.99,1.63c-0.01-0.01-0.01-0.02-0.01-0.02c-0.42-0.73-1.95-1.64-2.46-2.3c-0.29-0.38-0.63-1.27-0.88-1.67
            c-0.3-0.47-0.89-1.45-1.33-1.79c-0.23-0.18-0.8-0.47-1.09-0.42c-0.34,0.05-0.83,0.61-1.06,0.85c-0.32,0.33-0.71,1.21-1.09,1.45
            c-0.51,0.33-1.76,0.64-2.36,0.58c-0.55-0.06-1.61-0.55-2-0.94c-0.62-0.62-0.98-2.46-1.52-3.15c-0.39-0.51-1.4-1.35-1.97-1.64
            c-0.43-0.22-1.41-0.57-1.88-0.45c-0.48,0.11-1.3,0.83-1.52,1.27c-0.16,0.33,0.17,1.17-0.06,1.45c-0.16,0.2-0.8,0.11-1,0.26
            c-0.34,0.26-0.43,1.42-0.85,1.49c-0.49,0.09-1.25-0.85-1.54-1.25c-0.33-0.47-0.43-1.69-0.73-2.18c-0.28-0.47-1.05-1.3-1.49-1.62
            c-0.39-0.27-1.27-0.8-1.74-0.77c-0.44,0.03-1.08,0.78-1.5,0.93c-0.47,0.17-1.49,0.39-1.98,0.28c-0.48-0.1-1.33-0.68-1.66-1.05
            c-0.25-0.28-0.68-0.96-0.65-1.33c0.04-0.43,0.97-0.95,1.05-1.37c0.09-0.5-0.3-1.51-0.57-1.94c-0.35-0.58-1.32-1.6-1.94-1.86
            c-0.41-0.18-1.33-0.07-1.78-0.08c-0.63-0.02-1.91,0.15-2.51-0.04c-0.39-0.13-1.1-0.62-1.38-0.93c-0.34-0.39-0.53-1.49-0.89-1.86
            c-0.34-0.34-1.26-0.83-1.74-0.85c-0.4-0.02-1.1,0.54-1.5,0.57c-0.54,0.04-1.68-0.15-2.1-0.48c-0.51-0.41-0.98-1.71-1.13-2.34
            c-0.1-0.43,0.05-1.34-0.04-1.78c-0.11-0.57-0.47-1.73-0.85-2.18c-0.16-0.19-0.72-0.31-0.85-0.52c-0.2-0.32-0.28-1.15-0.12-1.49
            s0.85-0.81,1.21-0.89c0.54-0.11,1.6,0.45,2.14,0.45c0.59,0,1.79-0.2,2.3-0.48c0.34-0.19,0.78-0.86,1.05-1.13
            c0.33-0.33,0.97-1.05,1.37-1.29c0.28-0.17,0.92-0.45,1.25-0.45c0.43,0.01,1.29,0.36,1.62,0.65c0.34,0.3,0.54,1.29,0.89,1.58
            c0.29,0.24,1.04,0.48,1.42,0.48c0.35,0.01,1.08-0.17,1.33-0.4c0.32-0.29,0.63-1.18,0.65-1.62c0.02-0.46-0.33-1.35-0.57-1.74
            c-0.24-0.39-0.94-1.01-1.29-1.29c-0.26-0.21-0.98-0.43-1.13-0.73c-0.19-0.36,0.1-1.22,0.04-1.62c-0.09-0.58-0.38-1.78-0.77-2.22
            c-0.48-0.55-2.02-0.89-2.63-1.29c-0.37-0.25-1.1-0.8-1.33-1.17c-0.27-0.42-0.52-1.41-0.57-1.9c-0.05-0.48,0.22-1.45,0.2-1.94
            s-0.1-1.52-0.36-1.94c-0.19-0.31-0.83-0.75-1.17-0.89c-0.28-0.12-0.98,0.04-1.21-0.16c-0.22-0.19-0.37-0.84-0.32-1.13
            c0.08-0.49,0.73-1.33,1.13-1.62c0.43-0.3,1.55-0.3,2.02-0.52c0.3-0.15,1.02-0.48,1.05-0.81c0.04-0.43-0.81-1.02-1.17-1.25
            c-0.34-0.21-1.2-0.26-1.54-0.48c-0.24-0.17-0.68-0.64-0.73-0.93c-0.07-0.36,0.18-1.15,0.44-1.42c0.49-0.49,1.97-0.63,2.63-0.85
            c0.74-0.25,2.17-0.95,2.95-1.05c0.35-0.05,1.07,0.17,1.42,0.12c0.64-0.1,1.95-0.5,2.39-0.97c0.22-0.24,0.22-0.98,0.4-1.25
            c0.17-0.25,0.8-0.5,0.93-0.77c0.16-0.34,0.13-1.13,0.04-1.49c-0.1-0.42-0.72-1.09-0.85-1.5c-0.1-0.3,0.13-1.06-0.12-1.25
            c-0.06-0.04-0.14-0.05-0.23-0.04c0.43-0.3,1.17-0.52,1.44-0.78c0.26-0.24,0.59-0.89,0.85-1.12c0.32-0.28,1.1-0.63,1.45-0.85
            c0.4-0.24,1.31-0.64,1.61-1s0.6-1.29,0.67-1.76c0.06-0.41-0.23-1.24-0.12-1.64c0.06-0.23,0.42-0.58,0.55-0.79
            c0.2-0.34,0.41-1.15,0.7-1.42c0.24-0.24,0.9-0.53,1.24-0.58c0.51-0.06,1.53,0.17,2,0.36c0.3,0.13,0.76,0.64,1.06,0.76
            c0.36,0.14,1.14,0.18,1.52,0.15c0.38-0.03,1.17-0.18,1.49-0.39c0.23-0.16,0.57-0.64,0.67-0.91c0.14-0.39-0.06-1.26,0.09-1.64
            c0.08-0.2,0.47-0.47,0.58-0.67c0.23-0.44,0.19-1.54,0.48-1.94c0.19-0.25,0.79-0.54,1.09-0.64c0.35-0.12,1.1-0.08,1.45-0.18
            c0.26-0.08,0.76-0.32,1-0.45c0.35-0.21,0.92-0.83,1.3-0.97c0.41-0.16,1.32-0.12,1.76-0.15c0.37-0.03,1.14,0.03,1.49-0.09
            c0.29-0.1,0.84-0.33,1.24-0.61c0.11,0.45,0.22,0.88,0.3,1.17c0.25,0.93,0.69,2.81,1.01,3.72c0.47,1.3,1.59,3.85,2.26,5.05
            c0.48,0.86,1.76,2.37,2.22,3.23c0.46,0.85,0.95,2.74,1.45,3.56c0.44,0.71,1.66,1.86,2.18,2.51c0.48,0.6,1.35,1.86,1.82,2.47
            c0.91,1.17,2.65,3.62,3.76,4.61s3.75,2.44,5.01,3.23c0.47,0.3,1.43,0.87,1.9,1.17c0.66,0.42,2.09,1.13,2.63,1.7
            c0.35,0.37,0.71,1.37,1.01,1.78c0.45,0.6,1.49,1.7,2.06,2.18c0.48,0.4,1.64,0.95,2.1,1.37c0.59,0.55,1.32,2.08,1.98,2.55
            c0.46,0.32,1.63,0.46,2.14,0.69c0.78,0.34,2.16,1.38,2.95,1.7c0.42,0.17,1.34,0.31,1.78,0.45c0.55,0.17,1.58,0.73,2.14,0.81
            c0.27,0.04,0.86-0.22,1.09-0.08c0.32,0.19,0.57,1,0.61,1.37c0.05,0.51-0.41,1.48-0.45,1.98c-0.05,0.72-0.05,2.21,0.24,2.87
            c0.21,0.48,0.98,1.25,1.41,1.54c0.52,0.33,1.82,0.44,2.34,0.77C554.625,350.06,554.805,350.27,554.995,350.5z" pointer-events="auto" class="landarea"></path>

            <rect id="rect12" x="481.915" y="334.52" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.84" height="19.63" pointer-events="auto" visibility="hidden"></rect>
    </g>
    <g id="AREA_ITALY_BASILICATA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Basilicata" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_ITALY_BASILICATA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <path id="ITALY_BASILICATA" fill="#1e8346" stroke="#7eb08e" stroke-width="0.5" d="M667.045,451.55
            c0.3,0.41,0.88,1.07,1.27,1.72c-0.7,1.17-1.48,2.56-1.96,3.38c-0.32,0.53-0.88,1.65-1.25,2.14c-0.31,0.4-1.17,1.01-1.41,1.45
            c-0.4,0.72-0.47,2.43-0.77,3.19c-0.23,0.58-1.09,1.57-1.21,2.18c-0.09,0.45,0.35,1.38,0.2,1.82c-0.06,0.18-0.41,0.41-0.53,0.57
            c-0.29,0.39-0.73,1.24-0.97,1.66c-0.41,0.71-1.14,2.17-1.62,2.83c-0.34,0.48-1.23,1.26-1.58,1.74c-0.09,0.12-0.19,0.3-0.29,0.49
            c-0.59-0.27-1.43-0.42-1.85-0.45c-0.51-0.05-1.49,0.42-2,0.48c-0.54,0.06-1.66-0.17-2.18,0c-0.52,0.18-1.41,0.9-1.76,1.33
            c-0.32,0.4-0.59,1.43-0.85,1.88c-0.17,0.29-0.66,0.78-0.79,1.09c-0.21,0.51-0.08,1.71-0.36,2.18c-0.2,0.32-0.97,0.62-1.21,0.91
            c-0.44,0.51-1.11,1.71-1.27,2.36c-0.12,0.48-0.15,1.52,0,2c0.16,0.5,1.18,1.18,1.21,1.7c0.04,0.65-0.67,1.95-1.21,2.3
            c-0.42,0.27-1.53,0.31-2,0.12c-0.6-0.24-1.19-1.57-1.76-1.88c-0.51-0.28-1.72-0.48-2.3-0.42c-0.83,0.08-2.3,0.95-3.09,1.21
            c-0.45,0.15-1.46,0.18-1.82,0.48s-0.4,1.43-0.79,1.7c-0.54,0.37-1.99,0.4-2.61,0.18c-0.55-0.19-1.53-1.02-1.7-1.58
            c-0.15-0.52,0.6-1.58,0.55-2.12c-0.05-0.53-0.72-1.44-0.91-1.94c-0.15-0.4-0.15-1.38-0.48-1.64c-0.35-0.27-1.33-0.15-1.76-0.06
            c-0.51,0.11-1.46,0.6-1.88,0.91c-0.31,0.23-0.68,0.98-1.03,1.15c-0.48,0.24-1.6,0.3-2.12,0.18c-0.31-0.07-0.78-0.6-1.09-0.67
            c-0.64-0.14-2.03-0.02-2.61,0.3c-0.49,0.27-1.34,1.2-1.4,1.76c-0.04,0.43,0.69,1.1,0.79,1.52c0.09,0.42,0.2,1.36-0.06,1.7
            c-0.23,0.3-1.13,0.24-1.46,0.42c-0.41,0.23-1.14,0.68-1.66,1.16c-0.01-0.01-0.01-0.02-0.02-0.03c-0.4-0.68-1.77-1.6-2.18-2.26
            c-0.27-0.43-0.42-1.47-0.69-1.9c-0.29-0.47-1.21-1.17-1.54-1.62c-0.42-0.58-0.87-2-1.37-2.51c-0.31-0.31-1.08-0.52-1.63-0.8
            c0.15-0.49,0.41-1.03,0.59-1.32c0.26-0.41,0.95-1.1,1.33-1.39c0.34-0.27,1.26-0.5,1.52-0.85c0.29-0.4,0.36-1.45,0.36-1.94
            c0-0.51-0.46-1.5-0.36-2c0.11-0.56,0.79-1.56,1.21-1.94c0.41-0.37,1.61-0.58,2-0.97c0.37-0.37,0.57-1.48,0.91-1.88
            c0.25-0.3,1.04-0.6,1.27-0.91c0.31-0.41,0.65-1.43,0.67-1.94c0.02-0.6-0.47-1.73-0.61-2.3c-0.1-0.41-0.04-1.38-0.36-1.64
            c-0.28-0.23-1.09-0.01-1.46,0c-0.65,0.02-2.03,0.42-2.61,0.12c-0.29-0.15-0.6-0.79-0.73-1.09c-0.2-0.49-0.21-1.56-0.36-2.06
            c-0.2-0.66-0.59-2.07-1.09-2.55c-0.5-0.48-1.93-0.8-2.61-0.97c-0.7-0.17-2.17-0.12-2.85-0.36c-0.49-0.17-1.4-0.72-1.76-1.09
            c-0.33-0.34-0.88-1.17-0.97-1.64c-0.13-0.66,0.39-1.99,0.36-2.67c-0.03-0.82-0.19-2.54-0.67-3.21c-0.31-0.43-1.32-0.91-1.82-1.09
            c-0.39-0.14-1.26-0.06-1.64-0.24c-0.48-0.23-1.41-0.94-1.58-1.45c-0.11-0.34,0.1-1.11,0.3-1.39c0.26-0.36,1.3-0.52,1.52-0.91
            c0.2-0.37,0.03-1.28-0.06-1.7c-0.12-0.56-0.4-1.76-0.85-2.12c-0.43-0.35-1.69-0.11-2.18-0.36c-0.6-0.3-1.55-1.33-1.94-1.88
            c-0.31-0.44-0.65-1.47-0.91-1.94c-0.35-0.64-1.31-1.8-1.58-2.49c-0.26-0.69-0.64-2.19-0.48-2.91c0.13-0.62,0.86-1.77,1.4-2.12
            c0.39-0.25,1.38-0.44,1.82-0.3c0.43,0.14,0.86,1.1,1.27,1.27c0.45,0.19,1.5,0.21,1.94,0c0.52-0.24,1-1.42,1.46-1.76
            c0.36-0.26,1.27-0.42,1.64-0.67c0.49-0.33,1.44-1.14,1.64-1.7c0.28-0.8-0.07-2.58-0.3-3.4c-0.15-0.52-0.8-1.43-0.97-1.94
            c-0.26-0.77-0.58-2.4-0.61-3.21c-0.01-0.37,0.09-0.94,0.18-1.48c0.66-0.02,1.36-0.1,1.78-0.24c0.72-0.24,1.87-1.29,2.51-1.7
            c0.58-0.38,1.66-1.47,2.34-1.45c0.51,0.01,1.39,0.73,1.7,1.13c0.3,0.39,0.22,1.62,0.65,1.86c0.38,0.21,1.41-0.08,1.7-0.4
            c0.24-0.27-0.01-1.13,0.16-1.45c0.34-0.63,1.77-1.28,2.18-1.86c0.29-0.41,0.37-1.51,0.73-1.86c0.34-0.33,1.31-0.59,1.78-0.65
            c0.75-0.09,2.28,0.09,2.99,0.32c0.93,0.3,2.67,1.28,3.4,1.94c0.6,0.55,1.59,1.9,1.86,2.67c0.2,0.57,0.25,1.83,0.16,2.42
            c-0.08,0.51-0.44,1.51-0.73,1.94c-0.26,0.39-1.27,0.83-1.37,1.29c-0.1,0.47,0.37,1.46,0.73,1.78c0.41,0.36,1.59,0.39,2.1,0.57
            c0.64,0.22,2.01,0.58,2.51,1.05c0.44,0.42,0.51,1.92,1.05,2.18c0.39,0.19,1.42-0.07,1.7-0.4c0.35-0.42-0.2-1.71,0.08-2.18
            s1.32-1.15,1.86-1.13c0.64,0.02,1.74,0.93,2.1,1.45c0.28,0.39,0.42,1.38,0.48,1.86c0.12,0.92-0.38,2.87,0,3.72
            c0.28,0.61,1.44,1.44,2.02,1.78c0.83,0.48,2.74,0.88,3.64,1.21c0.84,0.31,2.61,0.81,3.32,1.37c0.71,0.57,1.45,2.36,2.1,2.99
            c0.55,0.54,1.82,1.81,2.59,1.7c0.47-0.07,0.99-1.04,1.21-1.45c0.24-0.45,0.3-1.51,0.57-1.94c0.23-0.38,0.88-1.05,1.29-1.21
            c0.36-0.14,1.19-0.17,1.54,0c0.58,0.3,0.96,1.81,1.53,2.1c0.51,0.26,1.76,0.26,2.26,0c0.61-0.31,1.01-1.88,1.62-2.18
            c0.38-0.19,1.32-0.18,1.7,0c0.55,0.26,1.39,1.27,1.54,1.86c0.14,0.56-0.31,1.71-0.48,2.26c-0.14,0.46-0.69,1.3-0.73,1.78
            c-0.05,0.56,0.17,1.72,0.48,2.18c0.22,0.33,1.1,0.55,1.29,0.89c0.2,0.35,0.13,1.22,0.08,1.62c-0.06,0.46-0.57,1.31-0.57,1.78
            c0,0.53,0.37,1.57,0.65,2.02c0.4,0.66,1.47,1.89,2.18,2.18c0.6,0.25,1.99-0.26,2.59,0
            C665.655,449.59,666.585,450.93,667.045,451.55z" pointer-events="auto" class="landarea"></path>

            <rect id="rect12_1" x="606.915" y="444.8" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.84" height="19.63" pointer-events="auto" visibility="hidden"></rect>
    </g>
    <g id="AREA_ITALY_CALABRIA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Calabria" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_ITALY_CALABRIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <path id="ITALY_CALABRIA" fill="#1e8346" stroke="#7eb08e" stroke-width="0.5" d="M693.645,544.76
            c0.09,0.24-0.14,0.79-0.32,0.97c-0.17,0.18-0.74,0.17-0.93,0.32c-0.36,0.28-0.85,1.1-0.97,1.54c-0.11,0.39-0.05,1.22,0.04,1.62
            c0.09,0.39,0.85,1.09,0.69,1.46c-0.13,0.3-0.98,0.22-1.25,0.4c-0.35,0.24-0.86,0.96-1.05,1.33c-0.15,0.3-0.18,1.01-0.36,1.29
            c-0.1,0.16-0.46,0.34-0.57,0.48c-0.16,0.22-0.19,0.9-0.45,1.01c-0.17,0.07-0.58-0.09-0.69-0.24c-0.12-0.16,0.14-0.66,0-0.81
            c-0.22-0.24-0.98-0.02-1.29-0.08c-0.2-0.04-0.57-0.26-0.77-0.28s-0.68-0.04-0.81,0.12c-0.15,0.18,0.13,0.72,0.04,0.93
            c-0.07,0.17-0.38,0.49-0.57,0.48c-0.4-0.02-0.92-0.79-1.13-1.13c-0.15-0.23-0.2-0.83-0.36-1.05c-0.14-0.2-0.52-0.59-0.77-0.61
            c-0.32-0.03-0.82,0.52-1.13,0.61c-0.61,0.18-1.91,0.08-2.55,0.16c-0.39,0.05-1.16,0.22-1.54,0.32c-1.61,0.45-4.82,1.46-6.31,2.22
            c-1.5,0.77-4.28,2.76-5.54,3.88c-0.73,0.65-2,2.14-2.59,2.91c-0.34,0.44-1.01,1.34-1.21,1.86c-0.2,0.5-0.23,1.59-0.4,2.1
            c-0.13,0.37-0.59,1.03-0.69,1.41c-0.08,0.3-0.01,0.94-0.06,1.25c-0.06,0.33-0.41,0.96-0.38,1.29c0.06,0.63,0.98,1.65,1.15,2.26
            c0.26,0.94,0.09,2.92,0.24,3.88c0.11,0.67,0.54,1.97,0.71,2.63c0.17,0.67,0.52,1.99,0.65,2.67c0.07,0.41,0.11,1.25,0.2,1.66
            c0.14,0.67,0.71,1.95,0.81,2.63c0.08,0.51,0.16,1.57,0,2.06c-0.22,0.67-1.22,1.74-1.7,2.26c-0.51,0.56-1.73,1.48-2.26,2.02
            c-0.52,0.53-1.36,1.79-1.94,2.26c-0.69,0.56-2.29,1.36-3.11,1.7c-0.56,0.23-1.82,0.34-2.34,0.65c-0.57,0.33-1.45,1.34-1.86,1.86
            c-1.1,1.38-2.74,4.54-3.76,5.98c-0.86,1.22-3.25,3.26-3.68,4.69c-0.44,1.48,0.72,4.61,0.53,6.15c-0.14,1.1-1.02,3.18-1.46,4.21
            c-0.35,0.83-1.03,2.55-1.66,3.19c-0.69,0.71-2.5,1.77-3.48,1.94c-1.06,0.19-3.17-0.7-4.24-0.69c-0.84,0.01-2.5,0.4-3.32,0.61
            c-1.14,0.29-3.27,1.53-4.45,1.5c-0.65-0.02-1.84-0.69-2.43-0.97c-0.9-0.43-2.75-1.3-3.48-1.98c-0.67-0.63-1.72-2.2-2.02-3.07
            c-0.27-0.77-0.22-2.43-0.28-3.23c-0.07-0.86-0.26-2.58-0.24-3.44c0.02-0.83,0.5-2.48,0.41-3.31c-0.09-0.82-0.99-2.3-1.13-3.11
            c-0.06-0.34-0.18-1.08,0-1.37c0.2-0.33,1.07-0.48,1.38-0.73c0.52-0.42,1.14-1.75,1.74-2.06c0.59-0.32,2.05-0.01,2.67-0.28
            c0.78-0.35,2.13-1.55,2.55-2.3c0.52-0.94,0.45-3.22,0.77-4.24c0.19-0.6,0.78-1.73,1.05-2.3c0.38-0.8,1.49-2.31,1.54-3.19
            c0.03-0.46-0.64-1.28-0.61-1.74c0.06-0.87,1.46-2.22,1.62-3.07c0.15-0.85,0.02-2.7-0.45-3.44c-0.44-0.69-2.13-1.25-2.75-1.78
            c-0.33-0.28-0.81-1.05-1.17-1.29c-0.35-0.24-1.41-0.22-1.58-0.61c-0.3-0.68,0.68-2.14,1.09-2.75c0.5-0.74,1.84-1.98,2.59-2.47
            c0.33-0.22,1.13-0.38,1.46-0.61c0.6-0.42,1.38-1.73,1.98-2.14c0.55-0.38,1.83-0.81,2.47-1.01c0.57-0.18,1.74-0.52,2.34-0.53
            c0.73-0.01,2.15,0.66,2.87,0.57c0.49-0.06,1.39-0.55,1.78-0.85c0.71-0.56,1.89-1.98,2.3-2.79c0.5-0.99,0.97-3.19,1.09-4.28
            c0.13-1.14,0.24-3.48-0.12-4.57c-0.18-0.54-0.88-1.51-1.33-1.86c-0.35-0.27-1.33-0.35-1.66-0.65c-0.6-0.55-0.94-2.28-1.46-2.91
            c-0.36-0.44-1.4-0.99-1.78-1.41c-0.44-0.5-1.14-1.64-1.37-2.26c-0.19-0.52-0.22-1.65-0.36-2.18c-0.16-0.57-0.72-1.61-0.85-2.18
            c-0.21-0.93-0.14-2.86-0.28-3.8c-0.17-1.09-0.8-3.21-1.01-4.29c-0.2-0.99-0.36-3.03-0.65-4c-0.24-0.83-1.09-2.37-1.37-3.19
            c-0.33-0.98-0.5-3.1-1.01-4c-0.58-1.03-2.7-2.35-3.31-3.36c-0.37-0.61-0.48-2.13-0.89-2.71c-0.49-0.7-1.99-1.64-2.71-2.1
            c-0.38-0.24-1.29-0.5-1.58-0.85c-0.48-0.6-0.59-2.23-0.85-2.95c-0.39-1.09-1.28-3.23-1.82-4.24c-0.36-0.68-1.42-1.86-1.66-2.59
            c-0.22-0.68,0.02-2.17-0.16-2.87c-0.1-0.38-0.6-1.03-0.69-1.41c-0.05-0.24,0.08-0.74,0-0.97c-0.1-0.28-0.63-0.66-0.77-0.93
            c-0.13-0.25-0.19-0.83-0.28-1.09c-0.16-0.43-0.8-1.2-0.81-1.66c-0.01-0.44,0.7-1.17,0.73-1.62c0.04-0.67-0.44-1.99-0.79-2.6
            c0.52-0.49,1.25-0.93,1.66-1.16c0.33-0.19,1.22-0.13,1.46-0.42c0.26-0.33,0.15-1.28,0.06-1.7s-0.83-1.09-0.79-1.52
            c0.05-0.56,0.91-1.49,1.4-1.76c0.57-0.32,1.97-0.44,2.61-0.3c0.31,0.07,0.78,0.59,1.09,0.67c0.52,0.12,1.65,0.06,2.12-0.18
            c0.35-0.17,0.72-0.92,1.03-1.15c0.42-0.31,1.37-0.8,1.88-0.91c0.43-0.09,1.41-0.21,1.76,0.06c0.34,0.26,0.33,1.24,0.48,1.64
            c0.19,0.5,0.86,1.41,0.91,1.94c0.05,0.55-0.7,1.6-0.55,2.12c0.16,0.56,1.15,1.38,1.7,1.58c0.62,0.22,2.07,0.19,2.61-0.18
            c0.39-0.27,0.43-1.4,0.79-1.7c0.36-0.3,1.37-0.34,1.82-0.48c0.79-0.26,2.27-1.13,3.09-1.21c0.58-0.06,1.79,0.14,2.3,0.42
            c0.57,0.31,1.16,1.64,1.76,1.88c0.47,0.19,1.58,0.15,2-0.12c0.55-0.35,1.26-1.65,1.21-2.3c-0.03-0.52-1.05-1.2-1.21-1.7
            c-0.15-0.48-0.12-1.52,0-2c0.16-0.65,0.84-1.86,1.27-2.36c0.25-0.29,1.02-0.59,1.21-0.91c0.29-0.47,0.15-1.67,0.36-2.18
            c0.13-0.31,0.62-0.8,0.79-1.09c0.26-0.45,0.53-1.48,0.85-1.88c0.34-0.43,1.24-1.16,1.76-1.33s1.64,0.06,2.18,0
            c0.51-0.06,1.49-0.53,2-0.48c0.42,0.04,1.26,0.18,1.85,0.45c-0.18,0.34-0.38,0.74-0.52,0.96c-0.27,0.43-1,1.21-1.13,1.7
            c-0.2,0.73-0.04,2.3,0.16,3.03c0.13,0.48,0.77,1.3,0.93,1.78c0.13,0.4,0.11,1.27,0.28,1.66c0.11,0.26,0.68,0.61,0.69,0.89
            c0.01,0.46-0.9,1.08-1.09,1.5c-0.33,0.72-0.43,2.34-0.73,3.07c-0.28,0.69-1.08,1.96-1.54,2.55c-0.5,0.65-1.93,1.6-2.26,2.34
            c-0.42,0.95-0.57,3.16-0.28,4.17c0.29,1.02,2.17,2.43,2.51,3.44c0.23,0.69-0.33,2.28,0.04,2.91c0.25,0.43,1.32,0.73,1.74,1.01
            c0.81,0.54,2.18,2.06,3.11,2.35c0.81,0.24,2.56-0.03,3.4-0.2c0.36-0.07,1.01-0.46,1.37-0.48c0.33-0.02,0.96,0.28,1.29,0.28
            c0.52,0,1.51-0.51,2.02-0.44c0.75,0.1,2.12,0.9,2.71,1.37c0.75,0.61,1.69,2.37,2.42,2.99c0.78,0.65,2.62,1.58,3.56,1.98
            c0.64,0.28,2.05,0.52,2.67,0.85c0.26,0.14,0.66,0.61,0.93,0.73c0.32,0.13,1.06,0.02,1.37,0.16c0.51,0.24,1.25,1.11,1.62,1.54
            c0.43,0.49,1,1.71,1.49,2.14c0.47,0.41,1.64,0.92,2.22,1.13c0.32,0.12,1,0.24,1.33,0.32c0.23,0.06,0.81,0.03,0.93,0.24
            c0.13,0.24-0.24,0.79-0.32,1.05c-0.22,0.66-0.76,1.95-0.93,2.63c-0.15,0.58-0.35,1.75-0.36,2.34c-0.02,0.81,0.05,2.47,0.32,3.23
            c0.2,0.56,0.88,1.56,1.29,1.98c0.22,0.22,0.92,0.41,1.05,0.69c0.22,0.47-0.08,1.56-0.2,2.06c-0.12,0.49-0.58,1.41-0.73,1.9
            c-0.11,0.36-0.33,1.08-0.36,1.46c-0.05,0.55-0.07,1.72,0.16,2.22c0.23,0.52,1.34,1.1,1.58,1.62c0.18,0.4-0.07,1.37,0.16,1.74
            c0.25,0.41,1.23,0.74,1.62,1.01c0.19,0.13,0.5,0.6,0.73,0.57c0.27-0.04,0.38-0.83,0.65-0.89
            C693.065,544.23,693.565,544.54,693.645,544.76z" pointer-events="auto" class="landarea"></path>

            <rect id="rect12_2" x="629.545" y="523.8" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.84" height="19.63" pointer-events="auto" visibility="hidden"></rect>
    </g>
    <g id="AREA_ITALY_CAMPANIA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Campania" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_ITALY_CAMPANIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <path id="ITALY_CAMPANIA" fill="#1e8346" stroke="#7eb08e" stroke-width="0.5" d="M534.135,455.44c0.06,0.28,0.13,1-0.12,1.15
            c-0.28,0.17-0.91-0.55-1.24-0.48c-0.14,0.03-0.29,0.34-0.42,0.39c-0.2,0.07-0.64-0.05-0.85-0.03c-0.28,0.02-0.86,0.33-1.12,0.21
            c-0.3-0.14-0.66-0.82-0.64-1.15c0.02-0.23,0.34-0.63,0.55-0.73c0.28-0.14,0.97-0.09,1.27,0c0.29,0.08,0.85,0.37,1.15,0.33
            c0.27-0.03,0.68-0.59,0.94-0.52C533.885,454.68,534.085,455.2,534.135,455.44z M521.475,441.64c-0.04-0.14-0.23-0.38-0.36-0.45
            c-0.24-0.12-0.85-0.18-1.09-0.04c-0.21,0.12-0.43,0.61-0.45,0.85c-0.01,0.34,0.26,1.05,0.53,1.25c0.25,0.19,1.01,0.36,1.25,0.16
            c0.18-0.15,0.02-0.7,0.04-0.93C521.405,442.28,521.535,441.84,521.475,441.64z M518.405,443.42c-0.18-0.29-0.95-0.37-1.25-0.53
            c-0.28-0.14-0.74-0.61-1.05-0.65c-0.34-0.04-0.95,0.42-1.29,0.45c-0.42,0.03-1.26-0.46-1.66-0.32c-0.33,0.11-0.78,0.73-0.93,1.05
            c-0.18,0.39-0.44,1.3-0.28,1.7c0.13,0.32,0.86,0.56,1.09,0.81c0.22,0.24,0.36,1.06,0.69,1.13c0.35,0.08,0.86-0.69,1.21-0.77
            c0.41-0.09,1.24,0.26,1.66,0.24c0.34-0.01,1.09-0.05,1.33-0.28c0.33-0.32,0.38-1.33,0.45-1.78
            C518.405,444.21,518.545,443.64,518.405,443.42z M614.575,464.81c-0.28-0.23-1.09-0.01-1.46,0c-0.65,0.02-2.03,0.42-2.61,0.12
            c-0.29-0.15-0.6-0.79-0.73-1.09c-0.2-0.49-0.21-1.56-0.36-2.06c-0.2-0.66-0.59-2.07-1.09-2.55c-0.5-0.48-1.93-0.8-2.61-0.97
            c-0.7-0.17-2.17-0.12-2.85-0.36c-0.49-0.17-1.4-0.72-1.76-1.09c-0.33-0.34-0.88-1.17-0.97-1.64c-0.13-0.66,0.39-1.99,0.36-2.67
            c-0.03-0.82-0.19-2.54-0.67-3.21c-0.31-0.43-1.32-0.91-1.82-1.09c-0.39-0.14-1.26-0.06-1.64-0.24c-0.48-0.23-1.41-0.94-1.58-1.45
            c-0.11-0.34,0.1-1.11,0.3-1.39c0.26-0.36,1.3-0.52,1.52-0.91c0.2-0.37,0.03-1.28-0.06-1.7c-0.12-0.56-0.4-1.76-0.85-2.12
            c-0.43-0.35-1.69-0.11-2.18-0.36c-0.6-0.3-1.55-1.33-1.94-1.88c-0.31-0.44-0.65-1.47-0.91-1.94c-0.35-0.64-1.31-1.8-1.58-2.49
            c-0.26-0.69-0.64-2.19-0.48-2.91c0.13-0.62,0.86-1.77,1.4-2.12c0.39-0.25,1.38-0.44,1.82-0.3c0.43,0.14,0.86,1.1,1.27,1.27
            c0.45,0.19,1.5,0.21,1.94,0c0.52-0.24,1-1.42,1.46-1.76c0.36-0.26,1.27-0.42,1.64-0.67c0.49-0.33,1.44-1.14,1.64-1.7
            c0.28-0.8-0.07-2.58-0.3-3.4c-0.15-0.52-0.8-1.43-0.97-1.94c-0.26-0.77-0.58-2.4-0.61-3.21c-0.01-0.37,0.09-0.94,0.18-1.48
            c-0.5,0.01-0.97-0.01-1.29-0.08c-0.62-0.13-1.7-0.84-2.26-1.13c-0.84-0.45-2.37-1.8-3.32-1.86c-0.52-0.03-1.45,0.62-1.94,0.81
            c-0.66,0.26-1.96,0.95-2.67,0.97c-0.83,0.02-2.55-0.41-3.15-0.97c-0.64-0.59-0.86-2.48-1.29-3.23c-0.26-0.46-1.13-1.19-1.29-1.7
            c-0.28-0.89,0.32-2.8,0.16-3.72c-0.14-0.78-0.56-2.47-1.21-2.91c-0.49-0.33-1.77,0.1-2.34,0c-0.8-0.13-2.35-0.67-3.07-1.05
            c-0.45-0.24-1.37-0.77-1.62-1.21c-0.48-0.85-0.95-3.07-0.4-3.88c0.28-0.42,1.72-0.11,1.94-0.57c0.37-0.77-0.73-2.51-1.29-3.15
            c-0.51-0.58-1.95-1.25-2.67-1.54c-0.63-0.25-2.06-0.24-2.68-0.6c-0.81,0.26-2.25,0.23-2.74,0.6c-0.48,0.36-0.89,1.59-1.38,1.94
            c-0.45,0.32-1.55,0.62-2.1,0.65c-0.86,0.05-2.54-0.75-3.4-0.65c-0.79,0.09-2.24,0.87-2.91,1.29c-0.63,0.4-1.91,1.29-2.26,1.94
            c-0.34,0.63,0.05,2.27-0.4,2.83c-0.41,0.5-1.79,1.02-2.42,0.89c-0.74-0.16-1.52-1.74-2.18-2.1c-0.62-0.34-2.09-0.89-2.75-0.65
            c-0.26,0.1-0.41,0.75-0.65,0.89c-0.87,0.54-3.03,0.82-4.04,0.65c-0.5-0.09-1.3-0.82-1.78-0.97c-0.31-0.1-0.98-0.25-1.29-0.16
            c-0.58,0.17-1.28,1.36-1.86,1.54c-0.48,0.15-1.64,0.17-2.02-0.16c-0.54-0.48-0.58-2.11-0.57-2.83c0.01-0.52,0.68-1.54,0.48-2.02
            c-0.14-0.35-0.93-0.7-1.29-0.81c-0.72-0.22-2.3,0.2-2.99-0.08c-0.43-0.18-0.94-1.11-1.37-1.29c-0.54-0.23-1.78-0.32-2.34-0.16
            c-0.43,0.12-1.27,0.64-1.46,1.05c-0.25,0.53,0.2,1.76,0.16,2.34c-0.04,0.6-0.05,1.94-0.48,2.35c-0.46,0.43-1.88,0.39-2.51,0.32
            c-0.43-0.04-1.32-0.26-1.62-0.57c-0.33-0.33-0.22-1.46-0.57-1.78c-0.55-0.5-2.17-0.57-2.91-0.65c-0.33-0.04-1.02,0.15-1.56,0.22
            c-0.01,0.13-0.02,0.24-0.05,0.33c-0.2,0.66-1.21,1.67-1.55,2.27c-0.33,0.6-0.9,1.86-1.09,2.52c-0.22,0.74-0.41,2.29-0.48,3.06
            c-0.07,0.73,0.13,2.24-0.09,2.94c-0.23,0.73-1.11,2.05-1.67,2.58c-0.37,0.35-1.33,0.76-1.76,1.03c-0.44,0.28-1.62,0.66-1.92,1.16
            c0.12,0.11,0.23,0.21,0.32,0.3c0.71,0.72,1.9,2.35,2.51,3.15c0.82,1.08,2.43,3.26,3.15,4.41c0.53,0.84,1.49,2.57,1.9,3.48
            c0.3,0.66,0.61,2.09,0.97,2.71c0.44,0.76,1.64,2.09,2.3,2.67c0.47,0.41,1.69,0.9,2.1,1.38c0.72,0.83,1.53,2.93,1.9,3.96
            c0.3,0.85,0.81,2.61,0.85,3.52c0.03,0.72-0.53,2.12-0.44,2.83c0.05,0.45,0.34,1.37,0.69,1.66c0.28,0.23,1.05,0.33,1.41,0.32
            c0.35-0.01,1.15-0.07,1.33-0.36c0.14-0.21-0.01-0.81-0.16-1.01c-0.17-0.23-0.92-0.23-1.05-0.48c-0.16-0.33,0.05-1.2,0.32-1.45
            c0.25-0.23,1-0.17,1.33-0.16c0.37,0.01,1.12,0.13,1.46,0.28c0.41,0.2,1,0.98,1.41,1.17c0.27,0.13,0.9,0.32,1.17,0.2
            c0.26-0.12,0.43-0.75,0.61-0.97c0.35-0.43,1.15-1.22,1.62-1.54c0.42-0.29,1.35-0.84,1.86-0.85s1.49,0.47,1.9,0.77
            c0.67,0.49,1.54,1.94,2.1,2.55c0.53,0.58,1.56,1.83,2.26,2.18c0.71,0.36,2.48,0.1,3.15,0.52c0.33,0.21,0.74,0.93,0.89,1.29
            c0.18,0.42,0.58,1.38,0.36,1.78c-0.17,0.32-1.03,0.37-1.33,0.57c-0.6,0.39-1.68,1.38-2.06,1.98c-0.33,0.51-0.32,1.9-0.77,2.3
            c-0.51,0.46-2.23,0.08-2.67,0.61c-0.23,0.28,0.06,1.12-0.08,1.45c-0.2,0.48-1.38,1.05-1.38,1.58c0.01,0.36,0.63,0.95,0.97,1.05
            c0.65,0.18,2.02-0.36,2.59-0.73c0.33-0.21,0.72-0.95,1.01-1.21c0.48-0.43,1.55-1.16,2.14-1.42c0.62-0.27,1.96-0.72,2.63-0.65
            c0.53,0.06,1.38,0.85,1.9,0.93c0.48,0.07,1.44-0.19,1.9-0.32c0.69-0.19,2.04-0.7,2.67-1.05c0.4-0.22,1.02-0.95,1.46-1.09
            c0.34-0.11,1.07-0.05,1.41,0c0.39,0.06,1.12,0.61,1.5,0.48c0.36-0.12,0.64-0.94,0.85-1.25c0.25-0.37,0.55-1.34,0.97-1.49
            c0.14-0.05,0.43,0.21,0.57,0.16c0.23-0.08,0.34-0.68,0.57-0.77c0.27-0.11,0.92,0,1.13,0.2c0.14,0.13,0.06,0.58,0.16,0.73
            c0.36,0.52,1.64,0.95,2.1,1.38c0.58,0.52,1.43,1.86,1.98,2.43c0.41,0.42,1.42,1.07,1.78,1.54c0.59,0.77,1.19,2.66,1.66,3.52
            c0.5,0.92,1.77,2.61,2.22,3.56c0.43,0.89,0.74,2.93,1.33,3.72c0.26,0.35,1.23,0.62,1.41,1.01c0.14,0.3,0.05,1.02-0.12,1.29
            c-0.18,0.29-0.9,0.52-1.17,0.73c-0.49,0.38-1.46,1.17-1.78,1.7c-0.17,0.28-0.4,0.93-0.36,1.25c0.04,0.32,0.67,0.81,0.65,1.13
            c-0.03,0.42-0.75,1.03-1.05,1.33c-0.22,0.22-0.83,0.49-0.97,0.77c-0.17,0.34-0.18,1.18-0.04,1.54c0.11,0.28,0.57,0.75,0.85,0.85
            c0.26,0.09,0.82-0.13,1.09-0.12c0.17,0.01,0.55,0.01,0.69,0.12c0.13,0.11,0.08,0.53,0.2,0.65c0.15,0.14,0.6,0.17,0.81,0.2
            c0.33,0.05,1.06-0.11,1.33,0.08c0.46,0.31,0.56,1.64,0.97,2.02c0.48,0.46,1.8,0.99,2.47,1.01c0.41,0.01,1.17-0.44,1.58-0.53
            c0.35-0.08,1.06-0.22,1.41-0.16c0.42,0.06,1.21,0.42,1.53,0.69c0.29,0.24,0.64,0.94,0.85,1.25c0.22,0.32,0.56,1.07,0.89,1.29
            c0.32,0.21,1.16,0.14,1.49,0.32s0.73,0.86,1.05,1.05c0.45,0.26,1.64,0.21,2.02,0.57c0.34,0.31,0.54,1.28,0.61,1.74
            c0.04,0.28,0.16,0.93-0.04,1.13c-0.19,0.19-0.86-0.14-1.05,0.04c-0.17,0.15-0.23,0.69-0.12,0.89c0.14,0.27,0.8,0.45,1.09,0.52
            c0.29,0.08,0.93,0.2,1.21,0.08c0.25-0.11,0.42-0.8,0.69-0.85c0.33-0.06,0.98,0.37,1.17,0.65c0.14,0.2-0.07,0.81,0.12,0.97
            c0.4,0.33,1.55-0.19,2.06-0.12c0.48,0.07,1.34,0.68,1.82,0.65c0.27-0.02,0.8-0.27,0.97-0.48c0.28-0.35,0.23-1.35,0.44-1.74
            c0.25-0.46,0.97-1.26,1.41-1.54c0.17-0.11,0.61-0.12,0.77-0.24c0.41-0.32,0.67-1.47,1.09-1.78c0.37-0.28,1.32-0.42,1.78-0.48
            c0.57-0.09,1.73-0.17,2.3-0.08c0.49,0.07,1.51,0.3,1.86,0.65c0.16,0.16,0.14,0.68,0.28,0.85c0.1,0.12,0.27,0.23,0.48,0.33
            c0.15-0.49,0.41-1.03,0.59-1.32c0.26-0.41,0.95-1.1,1.33-1.39c0.34-0.27,1.26-0.5,1.52-0.85c0.29-0.4,0.36-1.45,0.36-1.94
            c0-0.51-0.46-1.5-0.36-2c0.11-0.56,0.79-1.56,1.21-1.94c0.41-0.37,1.61-0.58,2-0.97c0.37-0.37,0.57-1.48,0.91-1.88
            c0.25-0.3,1.04-0.6,1.27-0.91c0.31-0.41,0.65-1.43,0.67-1.94c0.02-0.6-0.47-1.73-0.61-2.3
            C614.835,466.03,614.905,465.07,614.575,464.81z" pointer-events="auto" class="landarea"></path>

            <rect id="rect12_3" x="527.075" y="416.16" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.84" height="19.63" pointer-events="auto" visibility="hidden"></rect>
    </g>
    <g id="AREA_ITALY_EMILIAROMAGNA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Emilia-Romagna" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_ITALY_EMILIAROMAGNA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <path id="ITALY_EMILIAROMAGNA" fill="#1e8346" stroke="#7eb08e" stroke-width="0.5" d="M280.925,185.01
            c0.4-0.66,0.79-1.34,0.99-1.81c0.35-0.84,0.51-2.73,0.97-3.52c0.24-0.41,1.15-0.9,1.33-1.33c0.22-0.53-0.28-1.8,0-2.3
            c0.24-0.44,1.41-0.68,1.7-1.09c0.39-0.56,0.74-2,0.61-2.67c-0.17-0.81-1.51-1.97-1.94-2.67c-0.42-0.67-1.32-2.01-1.45-2.79
            c-0.18-1.06,0.29-3.22,0.61-4.24c0.33-1.06,1.33-3.07,1.94-4c0.53-0.81,1.61-2.66,2.55-2.91c0.71-0.19,2.07,1.12,2.79,0.97
            c1.05-0.22,2.13-2.59,3.15-2.91c0.67-0.21,2.2-0.02,2.79,0.36c0.68,0.45,1.03,2.32,1.7,2.79c1.11,0.78,4.04,1.51,5.34,1.09
            c0.98-0.32,2.02-2.34,2.79-3.03c0.64-0.58,1.95-1.84,2.79-2.06c0.74-0.2,2.27,0.32,3.03,0.36c0.61,0.04,1.85-0.2,2.43,0
            c0.77,0.27,2.26,1.29,2.55,2.06c0.3,0.81-0.84,2.57-0.61,3.4c0.22,0.79,1.37,2.32,2.18,2.43c1.11,0.15,2.65-2.25,3.76-2.43
            c0.83-0.13,2.54,0.44,3.27,0.85c0.84,0.47,1.88,2.36,2.79,2.67c1.1,0.38,3.46-0.67,4.61-0.49c0.89,0.14,2.53,0.96,3.27,1.46
            c0.66,0.44,1.57,1.88,2.3,2.18c1.35,0.55,4.41,0.46,5.82,0.12c1.17-0.29,3.07-2.04,4.24-2.3c1.04-0.23,3.21,0,4.24,0.24
            c1.22,0.28,3.36,1.98,4.61,1.94c1.08-0.04,2.85-1.6,3.88-1.94c1.35-0.44,4.18-0.76,5.58-0.97c1.18-0.18,3.63-0.14,4.73-0.61
            c0.69-0.29,1.92-0.89,2.88-1.59c0.28,0.1,0.55,0.18,0.76,0.21c0.74,0.13,2.24-0.27,2.99-0.24c0.8,0.03,2.42,0.17,3.15,0.49
            c0.49,0.21,1.23,1.04,1.7,1.29c0.72,0.39,2.36,0.73,3.07,1.13c0.61,0.35,1.57,1.43,2.18,1.78c0.71,0.41,2.26,1.08,3.07,1.13
            c0.43,0.02,1.32-0.19,1.7-0.4c0.6-0.34,1.54-1.38,1.94-1.94c0.34-0.48,0.6-1.73,1.05-2.1c0.61-0.51,2.29-1.04,3.07-0.89
            c0.51,0.1,1.17,1.02,1.62,1.29c0.47,0.29,1.47,0.85,2.02,0.89c0.72,0.05,2.17-0.39,2.75-0.81c0.49-0.36,0.87-1.68,1.37-2.02
            c0.48-0.32,1.69-0.4,2.26-0.49c0.76-0.11,2.31-0.37,3.07-0.24c1.2,0.2,3.42,1.29,4.45,1.94c0.57,0.36,1.55,1.3,2.02,1.78
            c0.5,0.5,1.4,1.57,1.86,2.1c0.53,0.62,1.73,1.78,2.1,2.51c0.16,0.32,0.33,0.87,0.45,1.38c-0.17,0.15-0.32,0.31-0.4,0.44
            c-0.1,0.17-0.16,0.59-0.15,0.79c0.02,0.42,0.58,1.21,0.45,1.61c-0.07,0.21-0.48,0.48-0.7,0.55c-0.17,0.05-0.58,0.04-0.73-0.06
            c-0.2-0.13-0.24-0.73-0.45-0.85c-0.23-0.13-0.83-0.07-1.06,0.06c-0.33,0.18-0.91,0.83-0.91,1.21c0,0.36,0.54,1.01,0.88,1.12
            c0.39,0.13,1.22-0.54,1.61-0.39c0.24,0.09,0.55,0.59,0.58,0.85c0.05,0.43-0.59,1.18-0.64,1.61c-0.08,0.75,0.41,2.22,0.48,2.97
            c0.05,0.45-0.02,1.37,0.06,1.82c0.07,0.37,0.52,1.04,0.48,1.41c-0.02,0.32-0.31,1.01-0.61,1.13c-0.17,0.07-0.57-0.1-0.73-0.2
            c-0.34-0.22-0.65-1.31-1.05-1.25c-0.23,0.03-0.15,0.77-0.36,0.85c-0.28,0.11-0.93-0.27-1.09-0.52c-0.13-0.21-0.02-0.74,0.04-0.97
            c0.05-0.2,0.37-0.52,0.36-0.73c-0.01-0.24-0.27-0.71-0.48-0.81c-0.24-0.1-0.85,0-1.01,0.2c-0.22,0.28,0.27,1.12,0.08,1.41
            c-0.14,0.22-0.71,0.41-0.97,0.4c-0.25-0.01-0.64-0.45-0.89-0.45c-0.36,0.01-1.07,0.42-1.25,0.73c-0.31,0.51-0.26,1.8-0.12,2.38
            c0.12,0.52,0.69,1.46,1.05,1.86c0.24,0.26,0.82,0.85,1.17,0.81c0.21-0.03,0.51-0.44,0.57-0.65c0.06-0.24-0.32-0.75-0.2-0.97
            c0.11-0.19,0.62-0.39,0.81-0.28c0.23,0.13,0.02,0.86,0.2,1.05c0.15,0.16,0.63,0.24,0.85,0.2c0.47-0.08,1.17-0.83,1.62-1.01
            c0.31-0.13,1.03-0.09,1.29-0.28c0.21-0.15,0.33-0.72,0.52-0.89c0.21-0.19,0.77-0.57,1.05-0.49c0.39,0.12,0.71,0.99,0.85,1.38
            c0.15,0.43,0.27,1.36,0.24,1.82c-0.03,0.55-0.48,1.59-0.52,2.14c-0.06,0.73,0.05,2.2,0.2,2.91c0.25,1.19,1.27,3.41,1.62,4.57
            c0.33,1.11,0.81,3.37,1.09,4.49c0.31,1.22,0.85,3.68,1.29,4.85c0.3,0.79,1.07,2.32,1.54,3.03c0.59,0.89,2.1,2.43,2.75,3.27
            c0.48,0.63,1.38,1.93,1.82,2.59c0.63,0.95,1.66,3.03,2.43,3.88c0.61,0.67,2.21,1.6,2.87,2.22c0.92,0.87,2.37,3,3.36,3.8
            c0.61,0.49,2.01,1.26,2.75,1.54c0.35,0.13,1.09,0.26,1.45,0.32c0.42,0.07,1.06,0.1,1.69,0.14c-0.21,0.7-0.43,1.4-0.44,1.88
            c-0.02,0.74,0.61,2.17,0.55,2.91c-0.05,0.56-0.42,1.7-0.79,2.12c-0.43,0.49-1.83,0.76-2.3,1.21c-0.37,0.35-0.68,1.39-1.03,1.76
            c-0.5,0.53-1.69,1.67-2.43,1.64c-0.69-0.03-1.84-1.13-2.12-1.76c-0.23-0.5,0.11-1.64,0.06-2.18c-0.03-0.34-0.07-1.08-0.3-1.33
            c-0.46-0.5-2.11-0.32-2.61-0.79c-0.34-0.32-0.44-1.35-0.67-1.76c-0.05-0.09-0.13-0.19-0.23-0.29c-0.29-0.31-0.7-0.68-0.74-0.98
            c-0.07-0.52,1.07-1.5,0.79-1.94c-0.35-0.55-1.96-0.2-2.61-0.12c-0.46,0.06-1.38,0.29-1.76,0.55c-0.46,0.31-0.92,1.43-1.39,1.7
            c-0.06,0.03-0.12,0.06-0.18,0.09c-0.76,0.32-2.19,0.45-2.91,0.4c-0.4-0.03-1.19-0.71-1.52-0.49s-0.03,1.19-0.12,1.58
            c-0.09,0.38-0.27,1.24-0.61,1.46c-0.41,0.26-1.58,0.26-1.94-0.06c-0.24-0.21-0.05-0.98-0.18-1.27c-0.2-0.43-0.8-1.4-1.27-1.4
            c-0.45,0-1,0.94-1.21,1.33c-0.34,0.63-0.75,2.07-0.73,2.79c0.02,0.59,0.66,1.66,0.73,2.24c0.06,0.54-0.05,1.65-0.18,2.18
            c-0.15,0.58-0.83,1.6-0.97,2.18c-0.11,0.46-0.16,1.25-0.17,1.92c-0.33-0.21-0.66-0.39-0.9-0.35c-0.36,0.07-0.7,0.88-1.03,1.05
            c-0.48,0.24-1.61,0.39-2.14,0.3c-0.41-0.07-1.11-0.59-1.49-0.75c-0.52-0.21-1.74-0.24-2.12-0.65c-0.4-0.41-0.15-1.83-0.57-2.22
            c-0.52-0.49-2.08-0.58-2.79-0.57c-0.54,0.01-1.56,0.46-2.1,0.5c-0.48,0.04-1.47,0.05-1.92-0.12c-0.56-0.21-1.54-0.96-1.9-1.43
            c-0.54-0.69-0.91-2.47-1.33-3.23c-0.34-0.6-1.12-1.77-1.62-2.24s-1.82-0.99-2.3-1.48c-0.35-0.35-0.96-1.18-1.09-1.66
            c-0.09-0.34-0.12-1.11,0.08-1.4c0.25-0.35,1.32-0.32,1.6-0.65c0.36-0.42,0.3-1.64,0.52-2.14c0.25-0.57,0.95-1.62,1.37-2.08
            c0.27-0.29,1.06-0.62,1.23-0.97c0.14-0.28,0.15-0.98,0.04-1.27c-0.14-0.37-0.75-0.93-1.09-1.13c-0.28-0.16-0.95-0.32-1.27-0.32
            c-0.31,0-0.97,0.1-1.21,0.3c-0.26,0.22-0.24,1.09-0.55,1.23c-0.41,0.19-1.38-0.21-1.72-0.51c-0.43-0.39-0.6-1.65-0.91-2.14
            c-0.32-0.51-1.03-1.53-1.56-1.82c-0.45-0.25-1.53-0.26-2.04-0.32c-0.49-0.06-1.49-0.01-1.96-0.16c-0.45-0.15-1.41-0.6-1.62-1.03
            s0.43-1.46,0.24-1.9c-0.15-0.37-0.88-0.89-1.27-0.97c-0.76-0.16-2.4,0.27-3.03,0.73c-0.36,0.26-0.54,1.28-0.91,1.52
            c-0.5,0.32-1.79,0.1-2.36,0.24c-0.49,0.12-1.55,0.35-1.88,0.73c-0.27,0.32-0.11,1.3-0.36,1.64c-0.21,0.28-0.98,0.41-1.21,0.67
            c-0.28,0.32-0.62,1.15-0.61,1.58c0.02,0.47,0.85,1.23,0.79,1.7c-0.05,0.41-0.65,1.13-1.03,1.27c-0.49,0.18-1.54-0.31-2.06-0.36
            c-0.59-0.06-1.77-0.1-2.36-0.06c-0.58,0.04-1.73,0.44-2.3,0.36c-0.48-0.06-1.37-0.51-1.76-0.79c-0.43-0.31-1.03-1.24-1.45-1.58
            c-0.37-0.29-1.17-0.86-1.64-0.91c-0.38-0.04-1.17,0.18-1.45,0.42c-0.4,0.34-0.56,1.49-0.91,1.88c-0.31,0.34-1.12,0.89-1.58,0.97
            c-0.55,0.09-1.69-0.22-2.18-0.49c-0.91-0.49-2.12-2.26-2.97-2.85c-0.55-0.38-1.76-1.05-2.42-1.15c-0.59-0.09-1.83,0.03-2.36,0.3
            c-0.41,0.21-0.88,1.1-1.27,1.33c-0.58,0.34-1.94,0.77-2.61,0.67c-0.76-0.12-1.9-1.34-2.61-1.64c-0.55-0.23-1.82-0.21-2.3-0.55
            c-0.7-0.49-1.27-2.26-1.88-2.85c-0.63-0.6-2.18-1.45-2.97-1.82c-0.62-0.29-2.11-0.38-2.61-0.85c-0.36-0.34-0.48-1.4-0.73-1.82
            c-0.23-0.4-0.69-1.49-1.15-1.46c-0.24,0.02-0.46,0.6-0.48,0.85c-0.02,0.27,0.37,0.76,0.36,1.03c-0.02,0.58-0.36,1.93-0.91,2.12
            c-0.45,0.16-1.39-0.54-1.7-0.91c-0.36-0.43-0.46-1.63-0.73-2.12c-0.39-0.72-1.36-2.09-2-2.61c-0.54-0.44-1.82-1.03-2.49-1.21
            c-0.81-0.23-2.52-0.11-3.33-0.3c-0.79-0.19-2.4-0.7-3.03-1.21c-0.32-0.26-0.8-0.99-0.91-1.4c-0.17-0.61,0.27-1.95,0.06-2.55
            c-0.14-0.4-0.71-1.1-1.09-1.27c-0.44-0.2-1.46,0.11-1.94,0.06c-0.54-0.05-1.63-0.25-2.12-0.49c-0.35-0.17-0.84-0.85-1.21-0.97
            c-0.52-0.17-1.68-0.15-2.18,0.06c-0.54,0.22-1.39,1.08-1.7,1.58c-0.29,0.47-0.34,1.64-0.61,2.12c-0.14,0.25-0.57,0.66-0.79,0.85
            c-0.36,0.31-1.13,0.91-1.58,1.09c-0.46,0.18-1.48,0.15-1.94,0.3c-0.02,0.01-0.03,0.01-0.05,0.02c-0.28-0.39-0.55-0.79-0.74-1.05
            c-0.46-0.65-1.12-2.23-1.82-2.61c-0.37-0.2-1.27-0.05-1.7-0.06c-0.48-0.02-1.47-0.18-1.94-0.06c-0.62,0.16-1.59,1.1-2.18,1.33
            c-0.4,0.16-1.34,0.61-1.7,0.36c-0.27-0.19-0.26-1.01-0.18-1.33c0.12-0.51,0.95-1.27,1.15-1.76c0.2-0.5,0.43-1.58,0.42-2.12
            c-0.01-0.78-0.35-2.32-0.67-3.03c-0.37-0.82-1.54-2.23-2.12-2.91c-0.31-0.36-0.89-1.17-1.33-1.33c-0.45-0.17-1.47,0-1.94,0.12
            c-0.53,0.13-1.45,0.89-2,0.91c-0.48,0.02-1.38-0.47-1.82-0.67c-0.47-0.21-1.32-0.85-1.82-0.97c-0.58-0.14-1.77,0.03-2.36,0.06
            c-0.27,0.01-0.65,0.06-1.05,0.11c0.3-0.8,1.14-1.82,1.47-2.41C280.205,186.19,280.565,185.61,280.925,185.01z" pointer-events="auto" class="landarea"></path>

            <rect id="rect12_4" x="336.185" y="178.43" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.84" height="19.63" pointer-events="auto" visibility="hidden"></rect>
    </g>
    <g id="AREA_ITALY_FRIULIVENEZIAGIULIA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Friuli-Venezia Giulia" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_ITALY_FRIULIVENEZIAGIULIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <path id="ITALY_FRIULIVENEZIAGIULIA" fill="#1e8346" stroke="#7eb08e" stroke-width="0.5" d="M497.345,109.67
            c0.3,0.37,0.36,1.45,0.2,1.9c-0.08,0.25-0.49,0.66-0.73,0.77c-0.3,0.13-1.02-0.13-1.29,0.04c-0.29,0.18-0.56,0.85-0.65,1.17
            c-0.08,0.27,0.14,0.92-0.04,1.13c-0.26,0.31-1.2,0.19-1.58,0.32c-0.26,0.09-0.7,0.47-0.97,0.49c-0.48,0.03-1.35-0.53-1.82-0.61
            c-0.38-0.06-1.18,0.16-1.54,0.01c-0.16-0.07-0.48-0.51-0.48-0.51s-0.17-0.67,0.26-1.56c0.28-0.59,1.03-0.89,1.16-1.11
            c0.2-0.34,0.26-1.2,0.12-1.58c-0.18-0.51-1.15-1.17-1.52-1.58c-0.48-0.54-1.38-1.67-1.85-2.21c-0.4-0.46-1.2-1.37-1.62-1.81
            c-0.62-0.64-1.91-1.89-2.59-2.47c-0.25-0.22-0.73-0.74-1.05-0.81c-0.31-0.06-1.01,0.08-1.21,0.32c-0.27,0.32-0.18,1.25-0.12,1.66
            c0.06,0.39,0.49,1.07,0.57,1.46c0.08,0.45,0.2,1.41,0,1.82c-0.24,0.5-1.22,1.19-1.74,1.37c-0.48,0.17-1.56,0.24-2.02,0.04
            c-0.41-0.18-0.77-1.14-1.17-1.33c-0.36-0.17-1.22,0.14-1.58-0.04c-0.3-0.15-0.55-0.9-0.85-1.05c-0.42-0.21-1.43,0.09-1.86-0.08
            c-0.29-0.11-0.67-0.72-0.97-0.81c-0.4-0.11-1.24,0.25-1.66,0.24c-0.51-0.01-1.51-0.23-1.98-0.4c-0.31-0.12-0.86-0.53-1.17-0.65
            c-0.36-0.13-1.14-0.47-1.5-0.32c-0.27,0.11-0.39,0.82-0.65,0.97c-0.29,0.17-1.07-0.12-1.33,0.08c-0.21,0.16-0.24,0.77-0.36,1.01
            c-0.11,0.22-0.35,0.69-0.57,0.81c-0.19,0.1-0.66-0.09-0.85,0c-0.39,0.19-0.94,0.96-1.05,1.38c-0.05,0.2-0.03,0.67,0.12,0.81
            c0.32,0.31,1.36,0,1.78,0.16c0.27,0.1,0.81,0.42,0.93,0.69c0.11,0.26,0.06,0.91-0.12,1.13c-0.2,0.24-1.01,0.12-1.21,0.36
            c-0.23,0.27-0.02,1.08-0.16,1.41c-0.17,0.41-0.69,1.2-1.09,1.38c-0.4,0.17-1.3-0.14-1.74-0.16c-0.32-0.01-0.98,0.06-1.29,0
            c-0.19-0.04-0.46-0.24-0.71-0.36c0.01-0.53-0.02-1.11-0.18-1.42c-0.31-0.63-1.83-1.21-2.1-1.86c-0.32-0.76,0.18-2.49,0.16-3.31
            c-0.01-0.67-0.07-2.02-0.24-2.67c-0.2-0.77-0.65-2.44-1.29-2.91c-0.66-0.48-2.46-0.06-3.23-0.32c-0.47-0.16-1.21-0.94-1.7-1.05
            c-0.83-0.19-2.61,0.07-3.4,0.4c-0.43,0.19-1.03,1.01-1.45,1.21c-0.43,0.2-1.46,0.07-1.86,0.32c-0.39,0.25-0.69,1.24-1.05,1.54
            c-0.56,0.46-1.96,1.3-2.67,1.13c-0.36-0.09-0.84-0.78-0.97-1.13c-0.22-0.61,0.4-2.08,0-2.59c-0.22-0.28-1.02-0.31-1.37-0.32
            c-0.55-0.02-1.72,0.62-2.18,0.32c-0.47-0.29-0.57-1.56-0.65-2.1c-0.08-0.62,0.28-1.91,0.08-2.51c-0.16-0.49-0.89-1.29-1.29-1.62
            c-0.37-0.29-1.32-0.53-1.7-0.81c-0.65-0.49-1.76-1.73-2.18-2.43c-0.47-0.78-1.29-2.49-1.29-3.4c-0.01-0.71,0.5-2.13,0.97-2.67
            c0.38-0.43,1.65-0.62,2.02-1.05c0.4-0.47,0.83-1.72,0.81-2.34c-0.02-0.72-0.68-2.1-1.13-2.67c-0.48-0.6-1.98-1.22-2.51-1.78
            c-0.81-0.87-2.22-2.89-2.51-4.04c-0.26-1.04-0.11-3.31,0.32-4.29c0.33-0.74,1.55-1.96,2.26-2.35c0.47-0.26,1.73-0.01,2.1-0.4
            c0.45-0.47,0.29-1.94,0.32-2.59c0.04-0.81-0.52-2.55-0.08-3.23c0.46-0.72,2.36-1.04,3.15-1.37c0.9-0.38,2.86-0.87,3.64-1.46
            c0.55-0.42,1.43-1.55,1.7-2.18c0.35-0.83-0.04-2.83,0.48-3.56c0.45-0.63,2.3-0.75,2.75-1.37c0.25-0.35,0.19-1.27,0.24-1.7
            c0.08-0.65,0.21-1.7,0.3-2.67c0.51,0.05,1.39-0.69,1.89-0.7c0.61-0.01,1.81,0.4,2.3,0.76c0.29,0.21,0.62,0.88,0.85,1.15
            c0.27,0.32,0.8,1.01,1.18,1.18c0.6,0.27,1.98,0.14,2.64,0.18c0.48,0.03,1.46,0.14,1.94,0.09c0.44-0.05,1.26-0.4,1.7-0.46
            c0.53-0.07,1.59-0.01,2.12,0c0.48,0.01,1.44,0.13,1.91,0.06c0.5-0.07,1.43-0.56,1.94-0.61c0.38-0.03,1.21-0.01,1.52,0.21
            c0.3,0.22,0.33,1.13,0.64,1.33c0.45,0.3,1.66-0.13,2.15,0.09c0.33,0.15,0.77,0.78,1.03,1.03c0.23,0.23,0.63,0.81,0.94,0.91
            c0.45,0.15,1.42-0.15,1.88-0.24c0.67-0.14,2.03-0.43,2.64-0.76c0.37-0.2,0.87-0.99,1.27-1.09c0.36-0.09,1.1,0.19,1.45,0.3
            c0.5,0.17,1.39,0.87,1.91,0.88c0.32,0.01,0.86-0.48,1.18-0.49c0.33,0,0.92,0.35,1.21,0.49c0.38,0.18,1.05,0.71,1.45,0.79
            c0.46,0.09,1.45,0.01,1.88-0.18c0.21-0.1,0.44-0.59,0.67-0.64c0.3-0.06,0.83,0.36,1.12,0.46c0.49,0.17,1.55,0.31,2,0.58
            c0.29,0.17,0.7,0.74,0.97,0.94c0.17,0.13,0.64,0.21,0.76,0.39c0.17,0.27,0.07,0.94,0.02,1.25c-0.12,0.83-0.39,2.63-1.01,3.19
            c-0.25,0.23-1.02,0.14-1.33,0.28c-0.45,0.21-1.25,0.84-1.58,1.21c-0.2,0.24-0.37,0.89-0.61,1.09c-0.2,0.17-0.75,0.23-0.97,0.36
            c-0.4,0.26-1.05,0.98-1.37,1.33c-0.24,0.26-0.59,0.91-0.89,1.09c-0.29,0.18-1.02,0.15-1.33,0.28c-0.29,0.13-0.87,0.47-1.05,0.73
            c-0.29,0.42-0.3,1.51-0.48,1.98c-0.11,0.3-0.37,0.91-0.61,1.13c-0.32,0.3-1.15,0.64-1.58,0.73c-0.21,0.04-0.67-0.16-0.85-0.04
            c-0.3,0.2-0.59,1-0.48,1.33c0.14,0.42,1.14,0.7,1.45,1.01c0.27,0.25,0.85,0.81,0.89,1.17c0.04,0.35-0.58,0.94-0.57,1.29
            c0.01,0.35,0.36,1.06,0.65,1.25c0.25,0.17,0.95,0.28,1.21,0.12c0.43-0.27,0.32-1.6,0.73-1.9c0.38-0.27,1.43-0.25,1.86-0.08
            c0.46,0.18,0.94,1.18,1.38,1.42c0.51,0.27,1.75,0.14,2.26,0.4c0.39,0.21,0.92,0.97,1.25,1.25c0.24,0.2,0.72,0.61,1.01,0.73
            c0.3,0.12,1.1-0.1,1.29,0.16c0.25,0.33-0.15,1.22-0.24,1.62c-0.15,0.62-0.49,1.87-0.81,2.43c-0.3,0.52-1.08,1.48-1.58,1.82
            c-0.31,0.21-1.07,0.36-1.42,0.49c-0.41,0.15-1.4,0.27-1.62,0.65c-0.18,0.31,0.32,1.08,0.2,1.41c-0.09,0.24-0.67,0.45-0.77,0.69
            c-0.15,0.34,0.19,1.15,0.04,1.5c-0.1,0.23-0.69,0.41-0.77,0.65c-0.11,0.34,0.1,1.13,0.36,1.37c0.38,0.36,1.58,0.61,2.06,0.4
            c0.17-0.07,0.25-0.51,0.4-0.61c0.36-0.21,1.24-0.2,1.66-0.16c0.34,0.03,1.08,0.13,1.33,0.36c0.29,0.26,0.51,1.07,0.57,1.46
            c0.09,0.56,0.11,1.75-0.12,2.26c-0.15,0.34-0.85,0.74-1.05,1.05c-0.31,0.47-0.62,1.59-0.85,2.1c-0.14,0.31-0.61,0.87-0.61,1.21
            c0.01,0.33,0.37,0.99,0.65,1.17c0.58,0.39,2.14,0.07,2.79,0.32c0.49,0.19,1.4,0.82,1.7,1.25c0.25,0.36,0.19,1.37,0.48,1.7
            c0.27,0.31,1.14,0.45,1.49,0.65c0.39,0.22,1.22,0.65,1.49,1.01c0.23,0.3,0.3,1.09,0.48,1.42c0.27,0.47,0.97,1.34,1.42,1.66
            c0.29,0.21,1.1,0.25,1.33,0.53c0.29,0.34,0.19,1.35,0.4,1.74c0.24,0.44,0.99,1.16,1.42,1.42
            C495.525,109.26,496.955,109.2,497.345,109.67z" pointer-events="auto" class="landarea"></path>

            <rect id="rect12_5" x="425.095" y="70.55" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.84" height="19.63" pointer-events="auto" visibility="hidden"></rect>
    </g>
    <g id="AREA_ITALY_LAZIO" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Lazio" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_ITALY_LAZIO.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <path id="ITALY_LAZIO" fill="#1e8346" stroke="#7eb08e" stroke-width="0.5" d="M467.385,434.43c0.02,0.17,0.36,0.4,0.36,0.57
            c0,0.31-0.42,0.87-0.67,1.05c-0.18,0.13-0.66,0.31-0.87,0.22c-0.18-0.08-0.33-0.5-0.36-0.69c-0.04-0.22,0.13-0.67,0.1-0.89
            c-0.04-0.25-0.38-0.68-0.38-0.93c0-0.2,0.15-0.6,0.28-0.75c0.19-0.23,0.81-0.41,1.03-0.61c0.11-0.1,0.24-0.39,0.36-0.46
            c0.27-0.16,0.96-0.3,1.25-0.18c0.21,0.08,0.56,0.48,0.55,0.71c-0.01,0.22-0.43,0.49-0.61,0.63c-0.19,0.14-0.7,0.25-0.83,0.45
            c-0.06,0.09-0.01,0.34-0.04,0.45C467.535,434.1,467.375,434.31,467.385,434.43z M461.675,431.45c-0.22-0.08-0.73,0.13-0.88,0.3
            c-0.14,0.16-0.2,0.64-0.15,0.85c0.03,0.16,0.31,0.39,0.36,0.55c0.06,0.17-0.06,0.61,0.09,0.7c0.19,0.1,0.65-0.22,0.79-0.39
            c0.18-0.22,0.34-0.81,0.3-1.09C462.155,432.1,461.925,431.55,461.675,431.45z M399.915,326.67c-0.57,0.1-1.78,0.05-2.3,0.3
            c-0.45,0.22-1.36,0.9-1.45,1.39c-0.08,0.43,0.36,1.34,0.73,1.58c0.42,0.27,1.55-0.21,2,0c0.46,0.21,1.23,1.02,1.33,1.52
            c0.11,0.5-0.3,1.54-0.61,1.94c-0.35,0.45-1.41,1.01-1.94,1.21c-0.42,0.16-1.32,0.2-1.76,0.3c-0.58,0.14-1.84,0.29-2.3,0.67
            c-0.32,0.26-0.69,0.71-1.02,1.24c0.17,0.08,0.32,0.16,0.45,0.23c1.06,0.59,3.05,2.03,3.96,2.83c0.55,0.48,1.61,1.48,1.98,2.1
            c0.29,0.49,0.45,1.66,0.69,2.18c0.24,0.54,1.03,1.46,1.21,2.02c0.1,0.32-0.04,1.04,0.12,1.33c0.19,0.33,1.04,0.55,1.21,0.89
            c0.12,0.24-0.07,0.83,0,1.09c0.15,0.55,0.86,1.5,1.17,1.98c0.44,0.68,1.48,1.93,1.9,2.63c0.3,0.49,0.77,1.56,1.05,2.06
            c0.22,0.39,0.57,1.32,0.97,1.54c0.27,0.14,0.91,0,1.21-0.04c0.59-0.08,1.71-0.56,2.3-0.53c0.53,0.03,1.52,0.47,1.98,0.73
            c0.75,0.42,2.06,1.57,2.71,2.14c0.87,0.76,2.39,2.54,3.31,3.23c0.86,0.65,2.97,1.37,3.72,2.14c0.63,0.65,1.3,2.38,1.7,3.19
            c0.39,0.8,1.26,2.37,1.45,3.23c0.15,0.66-0.07,2.05,0.08,2.71c0.1,0.45,0.66,1.24,0.73,1.7c0.05,0.33-0.32,1.04-0.16,1.33
            c0.26,0.48,1.43,0.8,1.94,1.01c0.93,0.39,2.95,0.75,3.84,1.21c0.95,0.5,2.58,1.93,3.4,2.63c1.01,0.86,2.98,2.66,3.88,3.64
            c0.96,1.04,2.73,3.24,3.52,4.41c0.85,1.25,2.21,3.96,2.99,5.26c0.43,0.7,1.06,2.36,1.78,2.75c0.27,0.14,0.91,0.04,1.21,0
            c0.41-0.05,1.2-0.39,1.62-0.4c0.63-0.03,1.97,0.07,2.51,0.4c0.46,0.29,0.84,1.42,1.25,1.78c0.21,0.18,0.7,0.46,0.97,0.48
            c0.33,0.03,0.96-0.36,1.29-0.36c0.58-0.01,1.7,0.4,2.26,0.57c0.75,0.22,2.26,0.62,2.95,0.97c0.8,0.4,2.25,1.5,2.95,2.06
            c0.78,0.63,2.38,1.89,2.95,2.71c0.4,0.57,0.79,1.94,1.05,2.59c0.18,0.45,0.43,1.41,0.73,1.78c0.34,0.42,1.28,1.02,1.78,1.21
            c0.39,0.15,1.28,0.43,1.66,0.24c0.31-0.15,0.55-0.88,0.65-1.21c0.08-0.26-0.07-0.86,0.08-1.09c0.19-0.28,0.91-0.45,1.21-0.61
            c0.78-0.42,2.24-1.47,3.07-1.78c0.9-0.34,2.8-0.66,3.76-0.73c0.69-0.05,2.08-0.02,2.75,0.12c0.57,0.12,1.62,0.7,2.18,0.85
            c0.73,0.19,2.3,0.14,2.99,0.45c0.54,0.24,1.36,1.13,1.82,1.49c0.23,0.18,0.66,0.58,0.93,0.69c0.31,0.12,1.02,0.03,1.33,0.16
            c0.3,0.13,0.75,0.62,1.01,0.81c0.27,0.19,0.8,0.64,1.13,0.69c0.49,0.06,1.54-0.23,1.9-0.57c0.2-0.18,0.48-0.77,0.36-1.01
            c-0.13-0.26-1.01-0.09-1.09-0.36c-0.12-0.39,0.7-1.03,1.01-1.29c0.25-0.21,0.85-0.55,1.17-0.61c0.38-0.06,1.13,0.14,1.5,0.24
            c0.23,0.06,0.65,0.36,0.89,0.36c0.47,0.01,1.31-0.64,1.78-0.61c0.29,0.02,0.8,0.36,1.05,0.52c0.38,0.25,1.01,0.75,1.46,1.16
            c0.31-0.49,1.48-0.87,1.92-1.16c0.43-0.27,1.39-0.68,1.76-1.03c0.55-0.53,1.44-1.85,1.67-2.58c0.22-0.7,0.02-2.21,0.09-2.94
            c0.07-0.77,0.27-2.32,0.48-3.06c0.19-0.66,0.76-1.92,1.09-2.52c0.33-0.6,1.34-1.62,1.55-2.27c0.03-0.1,0.05-0.21,0.05-0.33
            c0.04-0.61-0.14-1.52-0.05-2c0.11-0.64,0.56-1.9,0.91-2.46c0.28-0.44,1.24-1.02,1.49-1.49c0.41-0.76,0.7-2.53,0.7-3.4
            c-0.01-0.93-0.35-2.82-0.8-3.64c-0.01-0.01-0.01-0.02-0.01-0.02c-0.42-0.73-1.95-1.64-2.46-2.3c-0.29-0.38-0.63-1.27-0.88-1.67
            c-0.3-0.47-0.89-1.45-1.33-1.79c-0.23-0.18-0.8-0.47-1.09-0.42c-0.34,0.05-0.83,0.61-1.06,0.85c-0.32,0.33-0.71,1.21-1.09,1.45
            c-0.51,0.33-1.76,0.64-2.36,0.58c-0.55-0.06-1.61-0.55-2-0.94c-0.62-0.62-0.98-2.46-1.52-3.15c-0.39-0.51-1.4-1.35-1.97-1.64
            c-0.43-0.22-1.41-0.57-1.88-0.45c-0.48,0.11-1.3,0.83-1.52,1.27c-0.16,0.33,0.17,1.17-0.06,1.45c-0.16,0.2-0.8,0.11-1,0.26
            c-0.34,0.26-0.43,1.42-0.85,1.49c-0.49,0.09-1.25-0.85-1.54-1.25c-0.33-0.47-0.43-1.69-0.73-2.18c-0.28-0.47-1.05-1.3-1.49-1.62
            c-0.39-0.27-1.27-0.8-1.74-0.77c-0.44,0.03-1.08,0.78-1.5,0.93c-0.47,0.17-1.49,0.39-1.98,0.28c-0.48-0.1-1.33-0.68-1.66-1.05
            c-0.25-0.28-0.68-0.96-0.65-1.33c0.04-0.43,0.97-0.95,1.05-1.37c0.09-0.5-0.3-1.51-0.57-1.94c-0.35-0.58-1.32-1.6-1.94-1.86
            c-0.41-0.18-1.33-0.07-1.78-0.08c-0.63-0.02-1.91,0.15-2.51-0.04c-0.39-0.13-1.1-0.62-1.38-0.93c-0.34-0.39-0.53-1.49-0.89-1.86
            c-0.34-0.34-1.26-0.83-1.74-0.85c-0.4-0.02-1.1,0.54-1.5,0.57c-0.54,0.04-1.68-0.15-2.1-0.48c-0.51-0.41-0.98-1.71-1.13-2.34
            c-0.1-0.43,0.05-1.34-0.04-1.78c-0.11-0.57-0.47-1.73-0.85-2.18c-0.16-0.19-0.72-0.31-0.85-0.52c-0.2-0.32-0.28-1.15-0.12-1.49
            s0.85-0.81,1.21-0.89c0.54-0.11,1.6,0.45,2.14,0.45c0.59,0,1.79-0.2,2.3-0.48c0.34-0.19,0.78-0.86,1.05-1.13
            c0.33-0.33,0.97-1.05,1.37-1.29c0.28-0.17,0.92-0.45,1.25-0.45c0.43,0.01,1.29,0.36,1.62,0.65c0.34,0.3,0.54,1.29,0.89,1.58
            c0.29,0.24,1.04,0.48,1.42,0.48c0.35,0.01,1.08-0.17,1.33-0.4c0.32-0.29,0.63-1.18,0.65-1.62c0.02-0.46-0.33-1.35-0.57-1.74
            c-0.24-0.39-0.94-1.01-1.29-1.29c-0.26-0.21-0.98-0.43-1.13-0.73c-0.19-0.36,0.1-1.22,0.04-1.62c-0.09-0.58-0.38-1.78-0.77-2.22
            c-0.48-0.55-2.02-0.89-2.63-1.29c-0.37-0.25-1.1-0.8-1.33-1.17c-0.27-0.42-0.52-1.41-0.57-1.9c-0.05-0.48,0.22-1.45,0.2-1.94
            s-0.1-1.52-0.36-1.94c-0.19-0.31-0.83-0.75-1.17-0.89c-0.28-0.12-0.98,0.04-1.21-0.16c-0.22-0.19-0.37-0.84-0.32-1.13
            c0.08-0.49,0.73-1.33,1.13-1.62c0.43-0.3,1.55-0.3,2.02-0.52c0.3-0.15,1.02-0.48,1.05-0.81c0.04-0.43-0.81-1.02-1.17-1.25
            c-0.34-0.21-1.2-0.26-1.54-0.48c-0.24-0.17-0.68-0.64-0.73-0.93c-0.07-0.36,0.18-1.15,0.44-1.42c0.49-0.49,1.97-0.63,2.63-0.85
            c0.74-0.25,2.17-0.95,2.95-1.05c0.35-0.05,1.07,0.17,1.42,0.12c0.64-0.1,1.95-0.5,2.39-0.97c0.22-0.24,0.22-0.98,0.4-1.25
            c0.17-0.25,0.8-0.5,0.93-0.77c0.16-0.34,0.13-1.13,0.04-1.49c-0.1-0.42-0.72-1.09-0.85-1.5c-0.1-0.3,0.13-1.06-0.12-1.25
            c-0.06-0.04-0.14-0.05-0.23-0.04c-0.27,0.03-0.66,0.23-0.86,0.2c-0.41-0.06-1.21-0.39-1.49-0.69c-0.29-0.29-0.39-1.18-0.65-1.5
            c-0.33-0.39-1.27-0.86-1.7-1.13c-0.21-0.14-0.6-0.48-0.85-0.52c-0.45-0.08-1.33,0.33-1.78,0.36c-0.17,0.01-0.46,0.06-0.77,0.1
            c0,0.53-0.04,1.1-0.16,1.44c-0.15,0.44-0.89,1.08-1.09,1.5c-0.22,0.45-0.32,1.48-0.52,1.94c-0.17,0.38-0.55,1.21-0.93,1.37
            c-0.59,0.26-1.9-0.45-2.55-0.4c-0.76,0.06-2.28,0.5-2.91,0.93c-0.39,0.26-0.94,1.05-1.17,1.45c-0.17,0.29-0.28,0.99-0.48,1.25
            c-0.24,0.32-0.89,1.01-1.29,0.97s-0.72-0.98-1.05-1.21c-0.26-0.18-0.86-0.47-1.17-0.45c-0.33,0.02-0.93,0.39-1.13,0.65
            c-0.36,0.45-0.35,1.75-0.69,2.22c-0.36,0.5-1.58,0.98-1.98,1.45c-0.28,0.33-0.46,1.23-0.77,1.54c-0.27,0.27-0.99,0.65-1.38,0.69
            c-0.43,0.04-1.23-0.49-1.66-0.48c-0.38,0.01-1.15,0.25-1.45,0.48c-0.26,0.2-0.68,0.77-0.73,1.09c-0.05,0.39,0.36,1.12,0.48,1.49
            c0.12,0.36,0.51,1.04,0.48,1.42c-0.02,0.4-0.36,1.22-0.69,1.45c-0.3,0.22-1.12,0.26-1.49,0.2c-0.33-0.05-0.88-0.54-1.21-0.57
            s-1.06,0.12-1.29,0.36c-0.32,0.34-0.39,1.35-0.4,1.82c-0.01,0.32,0.28,0.95,0.2,1.25c-0.08,0.34-0.57,0.91-0.85,1.13
            c-0.45,0.35-1.63,0.57-2.1,0.89c-0.36,0.24-0.81,1.07-1.21,1.21c-0.31,0.11-1.03,0.07-1.29-0.12c-0.29-0.21-0.58-0.97-0.57-1.33
            c0.01-0.35,0.58-0.91,0.61-1.25c0.02-0.38-0.16-1.26-0.48-1.45c-0.33-0.2-1.19,0.07-1.54,0.24c-0.29,0.14-0.61,0.86-0.93,0.89
            c-0.39,0.03-1.08-0.56-1.29-0.89c-0.17-0.27-0.28-0.95-0.2-1.25c0.06-0.24,0.52-0.54,0.61-0.77c0.15-0.39,0.23-1.25,0.16-1.66
            c-0.05-0.32-0.28-0.96-0.52-1.17c-0.33-0.28-1.23-0.49-1.66-0.48c-0.52,0.01-1.46,0.66-1.98,0.65c-0.47-0.02-1.33-0.46-1.74-0.69
            c-0.45-0.24-1.38-0.75-1.66-1.17c-0.21-0.32-0.17-1.15-0.32-1.5c-0.18-0.4-0.83-1.03-1.05-1.41c-0.19-0.33-0.54-1.04-0.61-1.42
            c-0.08-0.42,0.19-1.29,0.08-1.7c-0.1-0.38-0.52-1.07-0.81-1.33c-0.19-0.18-0.71-0.35-0.93-0.48c-0.62-0.38-1.71-1.4-2.39-1.66
            c-0.41-0.15-1.31-0.29-1.74-0.2s-1.18,0.62-1.54,0.89c-0.33,0.24-0.78,1.03-1.17,1.13c-0.44,0.12-1.42-0.16-1.78-0.44
            c-0.28-0.22-0.46-0.98-0.69-1.25c-0.33-0.4-1.21-0.97-1.58-1.33c-0.29-0.28-0.73-0.97-1.05-1.21c-0.34-0.26-1.33-0.39-1.54-0.77
            c-0.2-0.36-0.07-1.31,0.16-1.66c0.12-0.18,0.63-0.24,0.77-0.4c0.22-0.27,0.38-0.99,0.4-1.33c0.02-0.29-0.01-0.89-0.16-1.13
            c-0.14-0.21-0.6-0.51-0.85-0.53c-0.27-0.02-0.73,0.44-1.01,0.45c-0.48,0.01-1.54-0.29-1.82-0.69c-0.22-0.3-0.13-1.13-0.04-1.49
            c0.08-0.33,0.59-0.87,0.65-1.21c0.06-0.39-0.17-1.19-0.28-1.58c-0.1-0.33-0.47-0.87-0.6-1.29c-0.22,0.16-0.44,0.3-0.61,0.38
            c-0.66,0.31-2.2,0.27-2.85,0.61c-0.44,0.23-1.18,0.92-1.45,1.33c-0.32,0.47-0.8,1.56-0.79,2.12c0.02,0.61,0.65,1.72,0.97,2.24
            c0.27,0.45,1.08,1.17,1.33,1.64c0.28,0.52,0.79,1.66,0.73,2.24c-0.05,0.47-0.7,1.24-0.97,1.64c-0.29,0.43-0.89,1.29-1.27,1.64
            c-0.31,0.28-1.16,0.57-1.39,0.91c-0.21,0.31-0.3,1.08-0.3,1.45c-0.01,0.54,0.51,1.6,0.36,2.12c-0.1,0.35-0.76,0.79-0.97,1.09
            c-0.27,0.38-1,1.75-0.8,1.76C402.895,326.01,400.655,326.54,399.915,326.67z M403.205,325.94h-0.01H403.205z" pointer-events="auto" class="landarea"></path>

            <rect id="rect12_6" x="421.785" y="355.72" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.84" height="19.63" pointer-events="auto" visibility="hidden"></rect>
    </g>
    <g id="AREA_ITALY_LIGURIA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Liguria" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_ITALY_LIGURIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <path id="ITALY_LIGURIA" fill="#1e8346" stroke="#7eb08e" stroke-width="0.5" d="M278.505,189.01c0.4-0.04,0.78-0.1,1.05-0.11
            c0.59-0.03,1.79-0.2,2.36-0.06c0.5,0.12,1.35,0.76,1.82,0.97c0.44,0.2,1.33,0.68,1.82,0.67c0.55-0.02,1.47-0.78,2-0.91
            c0.47-0.12,1.49-0.29,1.94-0.12c0.44,0.17,1.03,0.98,1.33,1.33c0.59,0.68,1.75,2.09,2.12,2.91c0.32,0.71,0.66,2.26,0.67,3.03
            c0.01,0.54-0.22,1.62-0.42,2.12c-0.2,0.49-1.04,1.24-1.15,1.76c-0.07,0.33-0.09,1.14,0.18,1.33c0.36,0.25,1.3-0.2,1.7-0.36
            c0.59-0.24,1.56-1.17,2.18-1.33c0.47-0.12,1.45,0.04,1.94,0.06c0.42,0.01,1.32-0.14,1.7,0.06c0.7,0.38,1.36,1.96,1.82,2.61
            c0.18,0.26,0.46,0.66,0.74,1.05c0.26,0.37,0.52,0.73,0.72,0.95c0.38,0.44,1.17,1.3,1.64,1.64c0.48,0.34,1.71,0.61,2.12,1.03
            c0.53,0.54,0.98,2.04,1.27,2.73c0.33,0.79,0.67,2.55,1.21,3.21c0.32,0.39,1.32,0.76,1.7,1.09c0.44,0.39,1.08,1.42,1.52,1.82
            c0.53,0.49,1.73,1.3,2.36,1.64c0.56,0.3,1.83,0.58,2.36,0.91c0.59,0.37,1.73,1.26,2.06,1.88c0.43,0.8,0.5,2.67,0.55,3.58
            c0.02,0.29-0.01,0.71-0.04,1.13c-0.35-0.18-0.79-0.37-1.05-0.47c-0.67-0.25-2.11-0.41-2.73-0.76c-0.49-0.27-1.23-1.13-1.64-1.52
            c-0.34-0.32-0.93-1.12-1.36-1.3c-0.28-0.12-0.96,0.11-1.21-0.06c-0.37-0.25-0.43-1.3-0.73-1.64c-0.23-0.26-0.84-0.7-1.18-0.7
            c-0.32,0-0.99,0.36-1.09,0.67c-0.09,0.26,0.29,0.79,0.42,1.03c0.19,0.34,0.89,0.86,0.94,1.24c0.04,0.27-0.15,0.91-0.39,1.03
            c-0.47,0.23-1.52-0.38-2-0.58c-0.81-0.33-2.45-1.05-3.06-1.67c-0.47-0.47-0.86-1.81-1.27-2.34c-0.33-0.41-1.07-1.26-1.58-1.4
            c-0.31-0.08-0.98,0.07-1.24,0.24c-0.11,0.07-0.17,0.4-0.3,0.42c-0.44,0.07-1.18-0.69-1.42-1.06c-0.21-0.32-0.18-1.14-0.33-1.49
            c-0.14-0.32-0.56-0.89-0.82-1.12c-0.3-0.26-1.04-0.58-1.39-0.76c-0.49-0.24-1.56-0.55-2-0.88c-0.29-0.22-0.63-0.93-0.94-1.12
            c-0.33-0.2-1.1-0.34-1.49-0.36c-0.21-0.01-0.64,0.12-0.85,0.09c-0.24-0.04-0.73-0.22-0.91-0.39c-0.19-0.18-0.23-0.8-0.45-0.94
            c-0.25-0.15-0.92,0.21-1.15,0.03c-0.34-0.27-0.15-1.33-0.39-1.7c-0.31-0.47-1.48-0.87-1.79-1.33c-0.18-0.26-0.09-1.01-0.33-1.21
            c-0.37-0.31-1.44-0.05-1.91-0.15c-0.49-0.1-1.47-0.41-1.91-0.67c-0.23-0.13-0.56-0.62-0.82-0.7c-0.32-0.1-1.05-0.06-1.33,0.12
            c-0.19,0.12-0.39,0.57-0.42,0.79c-0.05,0.33,0.31,0.97,0.27,1.3c-0.03,0.27-0.24,0.87-0.48,1c-0.3,0.16-1.07,0.03-1.33-0.18
            c-0.21-0.17-0.19-0.82-0.36-1.03c-0.16-0.2-0.68-0.35-0.85-0.55c-0.21-0.24-0.46-0.87-0.52-1.18c-0.06-0.32,0.26-1.03,0.09-1.3
            c-0.18-0.29-0.96-0.38-1.27-0.51c-0.84-0.37-2.45-1.29-3.33-1.55c-0.91-0.26-2.85-0.21-3.76-0.46c-0.64-0.17-1.77-1.05-2.42-1.03
            c-0.3,0.01-0.76,0.53-1.06,0.55c-0.27,0.02-0.77-0.24-1-0.36c-0.46-0.26-1.18-1.1-1.67-1.3c-0.71-0.29-2.27-0.32-3.03-0.33
            c-0.6-0.01-1.83,0-2.4,0.21c-0.54,0.2-1.36,1.07-1.85,1.36c-0.3,0.19-0.93,0.53-1.27,0.64c-0.26,0.08-0.83,0.05-1.06,0.18
            c-0.43,0.25-0.87,1.24-1.27,1.52c-0.4,0.28-1.43,0.39-1.85,0.64c-0.43,0.25-1.04,1.11-1.49,1.33c-0.23,0.11-0.77,0.07-1,0.18
            c-0.31,0.15-0.76,0.7-1.03,0.91c-0.44,0.33-1.46,0.8-1.85,1.18c-0.22,0.22-0.63,0.73-0.67,1.03s0.34,0.85,0.36,1.15
            c0.03,0.41-0.12,1.25-0.27,1.64c-0.2,0.52-0.9,1.41-1.21,1.87c-0.41,0.59-1.1,1.9-1.7,2.3c-0.45,0.3-1.59,0.35-2.1,0.52
            c-0.38,0.13-1.07,0.54-1.46,0.65c-0.32,0.1-1.03,0.06-1.33,0.2c-0.6,0.29-1.55,1.28-1.94,1.82c-0.42,0.58-0.88,1.96-1.21,2.59
            c-0.17,0.31-0.62,0.88-0.73,1.21c-0.18,0.55-0.02,1.77-0.24,2.3c-0.13,0.31-0.64,0.79-0.85,1.05c-0.3,0.37-0.96,1.07-1.17,1.49
            c-0.24,0.51-0.54,1.62-0.52,2.18c0.01,0.24,0.32,0.69,0.28,0.93c-0.04,0.27-0.4,0.74-0.61,0.93c-0.24,0.22-0.88,0.43-1.13,0.65
            c-0.36,0.3-0.86,1.11-1.17,1.46c-0.39,0.44-1.12,1.44-1.66,1.7c-0.26,0.13-0.88,0.06-1.17,0.12c-0.35,0.07-1.06,0.26-1.38,0.45
            c-0.35,0.2-0.85,0.9-1.21,1.09c-0.26,0.14-0.86,0.18-1.13,0.28c-0.53,0.19-1.48,0.83-2.02,0.97c-0.61,0.16-1.91-0.03-2.51,0.16
            c-0.6,0.2-1.54,1.18-2.14,1.38c-0.43,0.14-1.38-0.04-1.82,0.08c-0.43,0.12-1.46,0.77-1.58,0.81s-1.27-0.02-1.66,0.12
            s-0.93,0.89-1.33,1.01c-0.48,0.15-1.52-0.05-2.02-0.12c-0.52-0.08-1.53-0.5-2.06-0.49c-0.42,0.01-1.2,0.42-1.62,0.49
            c-0.56,0.09-2.26,0.08-2.26,0.08s-0.33-0.97-0.38-1.3c-0.08-0.49,0.07-1.49-0.06-1.97c-0.1-0.38-0.73-1-0.76-1.4
            c-0.02-0.3,0.25-0.88,0.42-1.12c0.29-0.39,1.18-0.86,1.52-1.21c0.3-0.32,0.81-1.03,1-1.43c0.2-0.43,0.3-1.4,0.51-1.82
            c0.21-0.4,0.75-1.16,1.12-1.43c0.61-0.44,2.25-0.54,2.82-1.03c0.33-0.28,0.79-1.09,0.85-1.52c0.06-0.45-0.51-1.32-0.42-1.76
            c0.06-0.3,0.51-0.78,0.73-1c0.06-0.07,0.14-0.14,0.24-0.21c0.28,0.33,0.63,0.61,0.88,0.69c0.46,0.14,1.46-0.23,1.82-0.55
            c0.34-0.3,0.45-1.29,0.73-1.64c0.34-0.43,1.21-1.34,1.76-1.33c0.62,0.01,1.36,1.32,1.94,1.52c0.4,0.14,1.28-0.05,1.7,0
            c0.65,0.08,1.89,0.57,2.55,0.61c0.63,0.04,1.98,0.06,2.49-0.3c0.37-0.26,0.5-1.29,0.79-1.64c0.44-0.53,1.65-1.28,2.24-1.64
            c0.51-0.3,1.81-0.53,2.12-1.03s0.03-1.77,0-2.37c-0.02-0.46-0.27-1.37-0.18-1.82c0.11-0.52,0.96-1.35,1.03-1.88
            c0.06-0.46-0.25-1.38-0.42-1.82c-0.12-0.31-0.66-0.82-0.67-1.15c-0.01-0.41,0.48-1.13,0.73-1.46c0.6-0.78,2.38-1.82,2.97-2.61
            c0.68-0.9,1.54-3.01,1.94-4.06c0.41-1.08,0.66-3.45,1.27-4.43c0.27-0.43,1.02-1.26,1.52-1.33c0.38-0.05,1.12,0.4,1.4,0.67
            c0.58,0.56,0.9,2.33,1.52,2.85c0.5,0.42,1.87,1,2.49,0.79c0.42-0.14,0.8-1.07,1.03-1.46c0.27-0.45,0.62-1.45,0.91-1.88
            c0.35-0.51,1.12-1.59,1.7-1.82c0.59-0.23,1.91,0.23,2.55,0.18c1.07-0.08,3.28-0.34,4.18-0.91c0.5-0.32,1-1.49,1.46-1.88
            c0.38-0.33,1.28-0.82,1.76-0.97c0.76-0.24,2.37-0.46,3.15-0.36c0.47,0.06,1.44,0.32,1.76,0.67c0.4,0.43,0.19,1.91,0.67,2.24
            c0.38,0.26,1.37-0.06,1.82-0.12c0.87-0.11,2.76-0.13,3.46-0.67c0.89-0.68,1.59-2.96,2-4c0.26-0.65,0.29-2.18,0.79-2.67
            c0.47-0.46,1.89-0.6,2.55-0.67c0.77-0.08,2.36-0.07,3.09,0.18c0.43,0.15,1.19,0.71,1.52,1.03c0.49,0.48,1.02,1.84,1.58,2.24
            c0.51,0.37,1.8,0.66,2.42,0.73C277.465,189.12,277.995,189.07,278.505,189.01z" pointer-events="auto" class="landarea"></path>

            <rect id="rect12_7" x="230.845" y="188.75" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.84" height="19.63" pointer-events="auto" visibility="hidden"></rect>
    </g>
    <g id="AREA_ITALY_LOMBARDIA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Lombardy" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_ITALY_LOMBARDIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <path id="ITALY_LOMBARDIA" fill="#1e8346" stroke="#7eb08e" stroke-width="0.5" d="M350.925,104.22c0.17,0.74,0.02,2.01,0,2.66
            c-0.03,0.87-0.05,2.65-0.32,3.48c-0.22,0.67-0.99,1.89-1.45,2.43c-0.6,0.7-2.21,1.67-2.83,2.35c-0.62,0.67-1.76,2.14-2.1,2.99
            c-0.45,1.12-0.74,3.56-0.73,4.77c0.01,1.19,0.33,3.6,0.81,4.69c0.35,0.79,1.71,1.98,2.1,2.75c0.45,0.88,0.86,2.85,1.13,3.8
            c0.21,0.75,0.52,2.27,0.81,2.99c0.28,0.69,0.92,2.07,1.45,2.59c0.39,0.38,1.42,0.83,1.94,0.97c0.59,0.16,1.86-0.05,2.43,0.16
            c0.43,0.16,1.12,0.82,1.45,1.13c0.64,0.59,1.82,1.89,2.34,2.59c0.58,0.76,1.32,2.59,2.02,3.23c0.75,0.69,2.71,1.42,3.64,1.86
            c0.86,0.41,2.73,0.94,3.48,1.54c0.61,0.49,1.5,1.81,1.86,2.51c0.24,0.47,0.34,1.59,0.65,2.02c0.26,0.38,1.13,0.8,1.45,1.13
            c0.45,0.47,1.03,1.69,1.54,2.1c0.54,0.44,1.65,1.01,2.55,1.32c-0.96,0.7-2.18,1.29-2.88,1.59c-1.1,0.47-3.55,0.43-4.73,0.61
            c-1.4,0.21-4.23,0.53-5.58,0.97c-1.03,0.34-2.8,1.9-3.88,1.94c-1.25,0.04-3.39-1.66-4.61-1.94c-1.04-0.24-3.21-0.47-4.24-0.24
            c-1.18,0.26-3.07,2.02-4.24,2.3c-1.41,0.34-4.48,0.43-5.82-0.12c-0.73-0.3-1.64-1.74-2.3-2.18c-0.75-0.5-2.39-1.32-3.27-1.46
            c-1.14-0.18-3.51,0.86-4.61,0.49c-0.91-0.31-1.95-2.2-2.79-2.67c-0.74-0.41-2.44-0.98-3.27-0.85c-1.11,0.18-2.65,2.57-3.76,2.43
            c-0.81-0.11-1.96-1.64-2.18-2.43c-0.24-0.83,0.91-2.59,0.61-3.4c-0.29-0.77-1.77-1.79-2.55-2.06c-0.57-0.2-1.82,0.04-2.43,0
            c-0.76-0.05-2.29-0.56-3.03-0.36c-0.84,0.22-2.14,1.48-2.79,2.06c-0.77,0.69-1.81,2.71-2.79,3.03c-1.3,0.42-4.22-0.31-5.34-1.09
            c-0.67-0.47-1.01-2.34-1.7-2.79c-0.59-0.38-2.12-0.58-2.79-0.36c-1.02,0.32-2.1,2.69-3.15,2.91c-0.72,0.15-2.08-1.16-2.79-0.97
            c-0.93,0.25-2.01,2.1-2.55,2.91c-0.61,0.93-1.61,2.94-1.94,4c-0.31,1.02-0.79,3.19-0.61,4.24c0.13,0.77,1.04,2.12,1.45,2.79
            c0.43,0.7,1.77,1.86,1.94,2.67c0.14,0.67-0.21,2.11-0.61,2.67c-0.29,0.41-1.46,0.65-1.7,1.09c-0.28,0.51,0.22,1.77,0,2.3
            c-0.18,0.43-1.1,0.93-1.33,1.33c-0.46,0.79-0.62,2.68-0.97,3.52c-0.2,0.47-0.59,1.15-0.99,1.81c-0.16-0.5-0.42-1.05-0.65-1.32
            c-0.31-0.36-1.38-0.57-1.64-0.97c-0.35-0.55-0.05-1.98-0.24-2.61c-0.22-0.7-1.18-1.87-1.45-2.55c-0.21-0.54-0.17-1.8-0.55-2.24
            c-0.22-0.26-0.93-0.49-1.27-0.55c-0.71-0.11-2.17,0.46-2.85,0.24c-0.57-0.19-1.52-1.05-1.82-1.58c-0.39-0.7-0.52-2.35-0.55-3.15
            c-0.01-0.41,0.26-1.23,0.18-1.64c-0.1-0.49-0.64-1.38-0.97-1.76c-0.36-0.42-1.41-0.91-1.76-1.33c-0.29-0.35-0.44-1.34-0.79-1.64
            c-0.28-0.24-1.03-0.43-1.39-0.42c-0.38,0.01-1.08,0.56-1.45,0.49c-0.4-0.08-0.99-0.75-1.21-1.09c-0.26-0.39-0.46-1.32-0.61-1.76
            c-0.17-0.5-0.38-1.56-0.67-2c-0.18-0.27-0.67-0.73-0.97-0.85c-0.45-0.19-1.49-0.3-1.94-0.12c-0.42,0.17-0.87,1.04-1.21,1.33
            c-0.41,0.36-1.28,1.15-1.82,1.21c-0.83,0.1-2.43-0.73-3.15-1.15c-0.67-0.39-1.89-1.39-2.37-2c-0.38-0.49-0.8-1.69-1.09-2.24
            c-0.18-0.34-0.55-1.03-0.79-1.33c-0.25-0.31-0.99-0.73-1.15-1.09c-0.32-0.74-0.16-2.42-0.06-3.21c0.07-0.59,0.6-1.71,0.61-2.3
            c0-0.61-0.32-1.82-0.61-2.37c-0.27-0.51-1.38-1.19-1.52-1.76c-0.11-0.47,0.13-1.54,0.49-1.88c0.43-0.41,1.74-0.3,2.3-0.49
            c0.49-0.16,1.37-0.76,1.88-0.85c0.59-0.1,1.88-0.17,2.37,0.18c0.32,0.23,0.35,1.15,0.61,1.46c0.31,0.37,1.16,1.1,1.64,1.03
            c0.53-0.08,1.19-1.14,1.4-1.64c0.15-0.35,0.11-1.14,0.12-1.52c0.02-0.42-0.26-1.36,0-1.7c0.22-0.29,1.06-0.23,1.4-0.36
            c0.48-0.19,1.49-0.58,1.82-0.97c0.37-0.44,0.74-1.61,0.73-2.18c-0.02-0.91-0.85-2.63-1.33-3.4c-0.4-0.63-1.61-1.58-2.06-2.18
            c-0.5-0.66-1.38-2.08-1.7-2.85c-0.39-0.95-0.64-3.03-0.97-4c-0.27-0.79-0.84-2.36-1.33-3.03c-0.38-0.52-1.61-1.14-1.94-1.7
            c-0.32-0.55-0.25-1.92-0.55-2.49c-0.24-0.46-1.01-1.18-1.4-1.52c-0.56-0.49-2-1.09-2.43-1.7c-0.23-0.32-0.42-1.12-0.42-1.52
            c0-1.01,0.85-2.9,1.09-3.88c0.3-1.2,0.53-3.72,1.03-4.85c0.27-0.61,1.24-1.6,1.58-2.18c0.46-0.79,1.06-2.54,1.52-3.33
            c0.44-0.76,1.76-2.03,2.06-2.85c0.33-0.9,0.36-2.86,0.3-3.82c-0.05-0.83-0.69-2.44-0.67-3.27c0.01-0.42,0.25-1.25,0.42-1.64
            c0.19-0.42,0.82-0.98,1.22-1.52c0.28,0.24,0.8,0.57,1.11,0.67c0.39,0.13,1.26,0.3,1.66,0.16c0.24-0.08,0.48-0.64,0.73-0.69
            c0.24-0.04,0.7,0.21,0.89,0.36c0.39,0.31,0.7,1.37,1.13,1.62c0.4,0.22,1.37,0.05,1.82,0.12c0.4,0.06,1.31,0.1,1.58,0.4
            c0.42,0.49,0.37,1.92,0.24,2.55c-0.08,0.39-0.48,1.14-0.77,1.42c-0.33,0.32-1.27,0.56-1.66,0.81c-0.3,0.19-1.15,0.53-1.13,0.89
            c0.02,0.31,0.76,0.54,1.05,0.65c0.36,0.13,1.2,0.01,1.54,0.2c0.49,0.28,0.93,1.43,1.37,1.78c0.34,0.27,1.3,0.39,1.58,0.73
            c0.36,0.42,0.34,1.64,0.57,2.14c0.18,0.4,0.8,1.05,0.97,1.46c0.19,0.46,0.53,1.46,0.4,1.94c-0.07,0.26-0.64,0.55-0.73,0.81
            c-0.09,0.27-0.08,0.93,0.12,1.13c0.45,0.45,1.93,0.07,2.55,0.24c0.41,0.11,1.12,0.67,1.54,0.73c0.34,0.04,1.07-0.03,1.33-0.24
            c0.37-0.3,0.58-1.32,0.69-1.78c0.07-0.3-0.05-0.96,0.12-1.21c0.22-0.33,1.31-0.35,1.41-0.73c0.14-0.49-0.65-1.42-1.05-1.74
            c-0.23-0.18-0.92-0.15-1.13-0.36c-0.38-0.37-0.51-1.49-0.69-1.98c-0.14-0.39-0.63-1.12-0.61-1.54c0.02-0.36,0.66-0.93,0.69-1.29
            c0.04-0.44-0.54-1.22-0.57-1.66c-0.03-0.42,0.19-1.26,0.32-1.66c0.2-0.6,0.63-1.88,1.13-2.26c0.34-0.26,1.43,0.01,1.7-0.32
            c0.37-0.47-0.17-1.79-0.08-2.39c0.06-0.42,0.25-1.29,0.52-1.62c0.35-0.41,1.51-0.61,1.9-0.97c0.17-0.16,0.31-0.65,0.48-0.81
            c0.34-0.32,1.33-0.56,1.66-0.89c0.45-0.45,0.88-1.71,1.21-2.26c0.48-0.8,1.68-2.25,2.1-3.07c0.36-0.71,0.75-2.27,1.09-2.99
            c0.18-0.38,0.8-1.04,0.89-1.46c0.09-0.43-0.12-1.32-0.24-1.74c-0.11-0.36-0.65-0.99-0.65-1.37c0-0.36,0.57-0.94,0.61-1.29
            c0.06-0.48-0.11-1.51-0.4-1.9c-0.22-0.29-1.07-0.4-1.29-0.69c-0.19-0.25-0.34-0.91-0.28-1.21c0.06-0.33,0.63-0.79,0.77-1.09
            c0.25-0.54,0.16-1.87,0.57-2.3c0.34-0.37,1.41-0.53,1.9-0.61c0.38-0.06,1.17-0.13,1.54,0c0.34,0.12,0.92,0.6,1.13,0.89
            c0.21,0.3,0.21,1.14,0.48,1.37c0.36,0.31,1.44,0.58,1.86,0.36c0.26-0.13,0.4-0.77,0.48-1.05c0.09-0.3-0.01-1.02,0.2-1.25
            c0.21-0.23,0.94-0.47,1.21-0.32c0.33,0.18,0.39,1.05,0.45,1.41c0.11,0.76,0.04,2.31-0.04,3.07c-0.08,0.7-0.59,2.05-0.57,2.75
            c0.02,0.44,0.24,1.33,0.48,1.7c0.29,0.43,1.35,0.85,1.62,1.29c0.29,0.47,0.18,1.69,0.45,2.18c0.21,0.39,0.84,1.09,1.25,1.25
            c0.56,0.23,1.82-0.11,2.42-0.04c0.45,0.05,1.29,0.48,1.74,0.44c0.31-0.02,0.82-0.49,1.13-0.49c0.4,0,1.07,0.75,1.45,0.65
            c0.42-0.11,0.82-1.02,1.01-1.42c0.24-0.49,0.17-1.77,0.61-2.1c0.26-0.19,0.98-0.06,1.29,0c0.35,0.07,0.98,0.56,1.33,0.53
            c0.43-0.05,1.14-0.67,1.45-0.97c0.16-0.15,0.35-0.57,0.52-0.69c0.34-0.22,1.21-0.22,1.58-0.4c0.34-0.17,0.81-0.83,1.17-0.93
            c0.27-0.08,0.9-0.04,1.13,0.12c0.36,0.24,0.43,1.32,0.81,1.54c0.38,0.21,1.33-0.27,1.74-0.12c0.36,0.14,0.92,0.75,1.09,1.09
            c0.23,0.46,0.09,1.55,0.28,2.02c0.17,0.42,0.68,1.2,1.05,1.46c0.4,0.29,1.54,0.23,1.9,0.57c0.21,0.2,0.36,0.8,0.4,1.09
            c0.04,0.22-0.1,0.68-0.04,0.89c0.12,0.39,0.62,1.11,0.97,1.33c0.46,0.3,1.6,0.55,2.14,0.44c0.47-0.09,1.3-0.68,1.62-1.05
            c0.34-0.39,0.86-1.38,0.85-1.9c-0.02-0.64-0.73-1.81-1.17-2.26c-0.31-0.33-1.22-0.61-1.58-0.89c-0.37-0.3-1.12-0.96-1.25-1.41
            c-0.07-0.21,0.14-0.67,0.12-0.89c-0.03-0.33-0.49-0.94-0.4-1.25c0.08-0.28,0.62-0.65,0.89-0.77c0.45-0.19,1.52,0.05,1.94-0.2
            c0.4-0.24,1.09-1.1,1.05-1.58c-0.04-0.42-0.85-0.94-1.17-1.21c-0.3-0.25-0.91-0.82-1.29-0.85c-0.37-0.03-1.01,0.54-1.37,0.61
            c-0.42,0.08-1.39,0.22-1.7-0.08c-0.65-0.64-0.1-2.74-0.24-3.65c-0.07-0.48-0.48-1.4-0.45-1.88c0.04-0.62,0.59-1.78,0.88-2.33
            c0.22-0.43,0.87-1.17,1.09-1.61c0.21-0.41,0.31-1.38,0.61-1.73c0.4-0.47,1.6-0.95,2.18-1.15c0.52-0.17,1.61-0.35,2.15-0.36
            c0.39-0.01,1.22-0.03,1.55,0.18c0.38,0.24,0.82,1.11,0.91,1.55c0.08,0.4-0.36,1.25-0.21,1.64c0.19,0.49,1.17,1.05,1.61,1.33
            c0.31,0.2,0.96,0.64,1.33,0.64c0.34-0.01,0.96-0.41,1.21-0.64c0.36-0.32,0.62-1.47,1.09-1.58c0.31-0.07,0.81,0.49,1.09,0.64
            c0.33,0.18,1.13,0.37,1.36,0.67c0.18,0.23,0.08,0.92,0.27,1.15c0.14,0.17,0.45,0.38,0.77,0.53c0,0.26,0.02,0.49,0.05,0.66
            c0.09,0.42,0.34,1.35,0.73,1.54c0.6,0.28,1.93-0.77,2.59-0.65c0.71,0.14,1.92,1.11,2.34,1.7c0.32,0.45,0.63,1.55,0.65,2.1
            c0.01,0.5-0.57,1.45-0.48,1.94c0.08,0.43,0.89,1.03,0.97,1.46c0.08,0.43-0.01,1.51-0.4,1.7c-0.41,0.19-1.17-0.74-1.62-0.81
            c-0.81-0.12-2.54,0.12-3.23,0.57c-0.45,0.29-1.21,1.24-1.21,1.78c0,0.46,0.66,1.29,1.05,1.54c0.54,0.34,2.11-0.09,2.51,0.4
            c0.5,0.61-0.15,2.36-0.16,3.15c-0.02,1.43,0.38,4.32,0.16,5.74c-0.17,1.08-1.06,3.11-1.45,4.12c-0.45,1.15-1.34,3.45-1.94,4.53
            c-0.39,0.71-1.52,1.93-1.86,2.67c-0.36,0.78-0.95,2.46-0.89,3.32c0.03,0.46,0.46,1.32,0.73,1.7c0.29,0.41,1.2,0.94,1.45,1.37
            c0.4,0.68,0.64,2.3,0.81,3.07c0.13,0.62,0.22,1.9,0.4,2.51c0.18,0.6,0.66,1.78,1.05,2.26c0.67,0.82,2.34,2.44,3.4,2.51
            c0.62,0.04,1.79-0.72,2.18-1.21c0.36-0.44,0.31-1.72,0.65-2.18c0.27-0.38,1.09-0.93,1.54-1.05c0.61-0.17,1.93-0.09,2.51,0.16
            c0.59,0.26,1.6,1.19,1.86,1.78C350.875,104.05,350.905,104.13,350.925,104.22z" pointer-events="auto" class="landarea"></path>

            <rect id="rect12_8" x="273.715" y="112.27" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.84" height="19.63" pointer-events="auto" visibility="hidden"></rect>
    </g>
    <g id="AREA_ITALY_MARCHE" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Marches" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_ITALY_MARCHE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <path id="ITALY_MARCHE" fill="#1e8346" stroke="#7eb08e" stroke-width="0.5" d="M508.055,296.07c0.13,0.36,0.29,0.94,0.43,1.49
            c-0.4,0.28-0.94,0.5-1.24,0.61c-0.35,0.13-1.11,0.06-1.49,0.09c-0.44,0.03-1.35-0.01-1.76,0.15c-0.38,0.14-0.95,0.76-1.3,0.97
            c-0.24,0.14-0.74,0.38-1,0.45c-0.35,0.1-1.11,0.06-1.45,0.18c-0.3,0.1-0.9,0.38-1.09,0.64c-0.3,0.4-0.26,1.5-0.48,1.94
            c-0.1,0.2-0.49,0.46-0.58,0.67c-0.15,0.38,0.05,1.25-0.09,1.64c-0.1,0.27-0.43,0.75-0.67,0.91c-0.32,0.22-1.1,0.36-1.49,0.39
            c-0.38,0.03-1.16-0.02-1.52-0.15c-0.31-0.11-0.76-0.63-1.06-0.76c-0.47-0.2-1.5-0.43-2-0.36c-0.34,0.04-1,0.34-1.24,0.58
            c-0.28,0.28-0.5,1.08-0.7,1.42c-0.12,0.21-0.48,0.56-0.55,0.79c-0.11,0.4,0.18,1.23,0.12,1.64c-0.07,0.47-0.37,1.4-0.67,1.76
            c-0.3,0.36-1.2,0.76-1.61,1c-0.36,0.22-1.14,0.57-1.45,0.85c-0.26,0.23-0.59,0.88-0.85,1.12c-0.27,0.26-1.01,0.48-1.44,0.78
            c-0.27,0.03-0.66,0.23-0.86,0.2c-0.41-0.06-1.21-0.39-1.49-0.69c-0.29-0.29-0.39-1.18-0.65-1.5c-0.33-0.39-1.27-0.86-1.7-1.13
            c-0.21-0.14-0.6-0.48-0.85-0.52c-0.45-0.08-1.33,0.33-1.78,0.36c-0.17,0.01-0.46,0.06-0.77,0.1c0-0.33-0.02-0.64-0.04-0.87
            c-0.05-0.43-0.45-1.23-0.48-1.66c-0.03-0.35,0.01-1.06,0.16-1.38c0.09-0.18,0.45-0.4,0.57-0.57c0.32-0.47,0.99-1.54,0.89-2.1
            c-0.08-0.45-1.07-0.91-1.25-1.33c-0.17-0.38,0.15-1.34-0.12-1.66c-0.22-0.26-1-0.37-1.33-0.28c-0.44,0.12-1.07,0.87-1.37,1.21
            c-0.17,0.19-0.33,0.76-0.57,0.85c-0.45,0.16-1.47-0.2-1.82-0.52c-0.37-0.35-0.53-1.42-0.69-1.9c-0.14-0.44-0.27-1.39-0.52-1.78
            c-0.18-0.28-0.73-0.71-1.01-0.89c-0.4-0.25-1.27-0.74-1.74-0.73c-0.29,0.01-0.86,0.27-1.05,0.48c-0.26,0.29-0.22,1.18-0.48,1.45
            c-0.21,0.21-0.84,0.46-1.13,0.4c-0.55-0.11-1.36-1.04-1.7-1.5c-0.4-0.54-0.8-1.86-1.09-2.47c-0.25-0.52-0.89-1.51-1.05-2.06
            c-0.21-0.74-0.29-2.3-0.24-3.07c0.03-0.52,0.41-1.54,0.4-2.06c0-0.39-0.17-1.17-0.32-1.54c-0.16-0.39-0.67-1.08-0.93-1.42
            c-0.31-0.39-1.19-0.99-1.38-1.45c-0.21-0.52-0.12-1.7-0.04-2.26c0.06-0.46,0.45-1.32,0.52-1.78c0.09-0.51,0.28-1.57,0.12-2.06
            c-0.2-0.62-1.15-1.57-1.62-2.02c-0.37-0.36-1.32-0.86-1.66-1.25c-0.32-0.38-0.77-1.3-0.93-1.78c-0.3-0.94-0.26-2.98-0.57-3.92
            c-0.13-0.39-0.54-1.11-0.77-1.45c-0.2-0.29-0.83-0.74-0.89-1.09c-0.06-0.34,0.31-0.98,0.45-1.29c0.19-0.44,0.81-1.23,0.89-1.7
            c0.07-0.43-0.05-1.37-0.28-1.74c-0.21-0.33-0.9-0.86-1.29-0.89c-0.42-0.03-1.24,0.43-1.54,0.73c-0.27,0.27-0.34,1.14-0.65,1.37
            c-0.45,0.34-1.67,0.46-2.22,0.36c-0.51-0.08-1.45-0.6-1.82-0.97c-0.49-0.49-0.83-1.95-1.29-2.47c-0.36-0.41-1.35-0.92-1.82-1.21
            c-0.28-0.17-0.91-0.41-1.13-0.65c-0.36-0.39-0.45-1.63-0.89-1.94c-0.33-0.23-1.21-0.13-1.62-0.12c-0.51,0.02-1.58,0.02-2.02,0.28
            c-0.42,0.25-0.75,1.33-1.17,1.58c-0.32,0.19-1.11,0.33-1.45,0.2c-0.55-0.21-1.42-1.24-1.54-1.82c-0.09-0.47,0.37-1.4,0.61-1.82
            c0.15-0.27,0.73-0.66,0.81-0.97c0.1-0.41-0.07-1.32-0.32-1.66c-0.28-0.37-1.2-0.74-1.66-0.81c-0.43-0.07-1.36,0.02-1.74,0.24
            c-0.24,0.14-0.45,0.74-0.69,0.89c-0.38,0.25-1.32,0.44-1.78,0.4c-0.26-0.02-0.77-0.1-1.18-0.23c0.1-0.35,0.17-0.74,0.17-0.98
            c-0.01-0.44-0.52-1.24-0.53-1.68c-0.01-0.43,0.47-1.62,0.45-1.68s1.69-0.14,2.1-0.49c0.32-0.26,0.38-1.19,0.63-1.52
            c0.17-0.22,0.69-0.49,0.89-0.69c0.16-0.16,0.52-0.5,0.53-0.73c0.02-0.45-0.63-1.2-0.93-1.54c-0.23-0.26-0.81-0.66-1.09-0.87
            c-0.23-0.17-0.68-0.51-0.93-0.65c-0.34-0.19-1.04-0.53-1.41-0.61c-0.43-0.08-1.34-0.14-1.74,0.04c-0.25,0.11-0.49,0.68-0.73,0.81
            c-0.33,0.18-1.12,0.29-1.49,0.24c-0.24-0.03-0.57-0.26-0.9-0.46c0.02-0.67,0.06-1.47,0.17-1.92c0.14-0.58,0.82-1.6,0.97-2.18
            c0.14-0.53,0.24-1.64,0.18-2.18c-0.07-0.59-0.71-1.65-0.73-2.24c-0.02-0.72,0.39-2.15,0.73-2.79c0.21-0.4,0.76-1.33,1.21-1.33
            c0.47,0,1.07,0.97,1.27,1.4c0.14,0.29-0.06,1.06,0.18,1.27c0.36,0.32,1.53,0.32,1.94,0.06c0.33-0.21,0.51-1.07,0.61-1.46
            c0.09-0.38-0.21-1.36,0.12-1.58s1.12,0.46,1.52,0.49c0.72,0.06,2.15-0.07,2.91-0.4c0.02,0.2,0.03,0.39,0,0.52
            c-0.06,0.25-0.49,0.66-0.45,0.91c0.03,0.23,0.43,0.56,0.64,0.67c0.28,0.15,0.95,0.13,1.24,0.24c0.42,0.16,1.09,0.81,1.52,0.94
            c0.39,0.12,1.3,0.29,1.64,0.06c0.22-0.15,0.2-0.77,0.33-1c0.16-0.27,0.68-0.67,0.85-0.94c0.08-0.12,0.12-0.41,0.14-0.69
            c0.1,0.11,0.18,0.21,0.23,0.29c0.23,0.41,0.32,1.44,0.67,1.76c0.5,0.46,2.14,0.29,2.61,0.79c0.23,0.25,0.27,0.99,0.3,1.33
            c0.05,0.54-0.29,1.69-0.06,2.18c0.29,0.63,1.43,1.73,2.12,1.76c0.73,0.04,1.92-1.1,2.43-1.64c0.35-0.37,0.66-1.41,1.03-1.76
            c0.47-0.45,1.88-0.72,2.3-1.21c0.37-0.43,0.74-1.56,0.79-2.12c0.06-0.74-0.56-2.17-0.55-2.91c0.01-0.48,0.23-1.18,0.44-1.88
            c0.6,0.04,1.19,0.09,1.58,0.18c0.75,0.18,2.22,0.69,2.87,1.09c1.24,0.76,3.22,2.97,4.33,3.92c0.76,0.66,2.29,1.97,3.11,2.55
            c1.12,0.78,3.63,1.93,4.69,2.79c0.82,0.67,2.05,2.45,2.87,3.11c0.47,0.38,1.57,0.88,2.06,1.21c0.58,0.39,1.7,1.24,2.22,1.7
            c0.45,0.4,1.22,1.36,1.7,1.74c0.73,0.58,2.35,1.55,3.19,1.94c0.54,0.25,1.71,0.54,2.26,0.77c0.84,0.34,2.39,1.77,3.27,1.58
            c0.45-0.1,0.75-1.24,1.17-1.41c0.4-0.17,1.33,0.02,1.74,0.16c0.4,0.14,1.16,0.59,1.42,0.93c0.22,0.3,0.24,1.11,0.45,1.41
            c0.26,0.39,1.05,0.93,1.41,1.21c0.42,0.32,1.54,0.72,1.74,1.21c0.2,0.49-0.42,1.54-0.4,2.06c0.02,0.52,0.29,1.55,0.53,2.02
            c0.38,0.74,1.75,1.83,2.1,2.59c0.34,0.73,0.35,2.39,0.61,3.15c0.34,1.02,1.34,2.96,1.9,3.88c0.39,0.65,1.48,1.75,1.82,2.43
            c0.61,1.19,1.1,3.85,1.58,5.09c0.67,1.76,2.45,5.09,3.15,6.83c0.3,0.73,0.9,2.21,1.05,2.99c0.15,0.82-0.04,2.5,0.12,3.31
            c0.2,0.98,1.02,2.8,1.42,3.72C506.115,292.18,507.545,294.72,508.055,296.07z" pointer-events="auto" class="landarea"></path>

            <rect id="rect12_9" x="447.965" y="255.87" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.84" height="19.63" pointer-events="auto" visibility="hidden"></rect>
    </g>
    <g id="AREA_ITALY_MOLISE" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Molise" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_ITALY_MOLISE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <path id="ITALY_MOLISE" fill="#1e8346" stroke="#7eb08e" stroke-width="0.5" d="M572.205,371.67c0.35,0.35,0.91,1.28,0.89,1.78
            c-0.02,0.56-0.68,1.6-1.13,1.94s-1.76,0.2-2.18,0.57c-0.35,0.3-0.4,1.38-0.73,1.7c-0.36,0.35-1.4,0.92-1.86,0.73
            c-0.38-0.16-0.27-1.37-0.65-1.54c-0.45-0.2-1.46,0.36-1.86,0.65c-0.66,0.46-1.84,1.72-2.02,2.51c-0.23,1.01,0.52,3.1,0.97,4.04
            c0.41,0.87,1.79,2.28,2.34,3.07c0.43,0.61,1.01,2.08,1.62,2.51c0.02,0.02,0.05,0.03,0.07,0.04c-0.81,0.26-2.25,0.23-2.74,0.6
            c-0.48,0.36-0.89,1.59-1.38,1.94c-0.45,0.32-1.55,0.62-2.1,0.65c-0.86,0.05-2.54-0.75-3.4-0.65c-0.79,0.09-2.24,0.87-2.91,1.29
            c-0.63,0.4-1.91,1.29-2.26,1.94c-0.34,0.63,0.05,2.27-0.4,2.83c-0.41,0.5-1.79,1.02-2.42,0.89c-0.74-0.16-1.52-1.74-2.18-2.1
            c-0.62-0.34-2.09-0.89-2.75-0.65c-0.26,0.1-0.41,0.75-0.65,0.89c-0.87,0.54-3.03,0.82-4.04,0.65c-0.5-0.09-1.3-0.82-1.78-0.97
            c-0.31-0.1-0.98-0.25-1.29-0.16c-0.58,0.17-1.28,1.36-1.86,1.54c-0.48,0.15-1.64,0.17-2.02-0.16c-0.54-0.48-0.58-2.11-0.57-2.83
            c0.01-0.52,0.68-1.54,0.48-2.02c-0.14-0.35-0.93-0.7-1.29-0.81c-0.72-0.22-2.3,0.2-2.99-0.08c-0.43-0.18-0.94-1.11-1.37-1.29
            c-0.54-0.23-1.78-0.32-2.34-0.16c-0.43,0.12-1.27,0.64-1.46,1.05c-0.25,0.53,0.2,1.76,0.16,2.34c-0.04,0.6-0.05,1.94-0.48,2.35
            c-0.46,0.43-1.88,0.39-2.51,0.32c-0.43-0.04-1.32-0.26-1.62-0.57c-0.33-0.33-0.22-1.46-0.57-1.78c-0.55-0.5-2.17-0.57-2.91-0.65
            c-0.33-0.04-1.02,0.15-1.56,0.22c0.04-0.61-0.14-1.52-0.05-2c0.11-0.64,0.56-1.9,0.91-2.46c0.28-0.44,1.24-1.02,1.49-1.49
            c0.41-0.76,0.7-2.53,0.7-3.4c-0.01-0.93-0.35-2.82-0.8-3.64c0.29-0.58,0.78-1.23,0.99-1.63c0.29-0.56,0.6-1.95,1.15-2.24
            c0.34-0.18,1.16,0.04,1.52,0.18c0.34,0.14,0.78,0.9,1.15,0.91c0.47,0.01,1.27-0.69,1.52-1.09c0.26-0.42,0.19-1.46,0.3-1.94
            c0.15-0.63,0.25-2.11,0.79-2.49c0.54-0.37,2.06,0.48,2.61,0.12c0.28-0.19,0.51-0.93,0.48-1.27c-0.03-0.41-0.64-1.07-0.79-1.45
            c-0.22-0.58-0.64-1.81-0.55-2.43c0.1-0.61,0.81-1.71,1.27-2.12c0.37-0.33,1.33-0.69,1.82-0.79c0.45-0.09,1.44-0.25,1.82,0
            c0.26,0.17,0.18,1.07,0.48,1.15c0.49,0.14,1.15-1.08,1.64-1.21c0.63-0.18,2.07-0.12,2.61,0.24c0.47,0.32,0.88,1.46,1.03,2
            c0.2,0.7-0.09,2.26,0.24,2.91c0.27,0.54,1.26,1.36,1.82,1.58c0.85,0.33,2.8,0.42,3.64,0.06c1.06-0.46,2.54-2.44,3.09-3.46
            c0.45-0.83,0.4-2.89,0.97-3.64c0.46-0.6,2.18-0.89,2.61-1.52c0.39-0.57,0.45-2.04,0.42-2.73c-0.03-0.67-0.79-1.96-0.61-2.61
            c0.2-0.73,1.69-1.55,2.12-2.18c0.47-0.69,0.9-2.36,1.4-3.03c0.29-0.4,1.08-1.01,1.46-1.33c0.27-0.23,0.82-0.49,1.3-0.79
            c0.27,0.34,0.55,0.72,0.8,0.87c0.83,0.48,2.81,0.62,3.72,0.93c0.5,0.17,1.43,0.72,1.94,0.85c0.71,0.18,2.23,0.02,2.91,0.28
            c0.77,0.3,1.96,1.51,2.63,1.98c0.55,0.39,1.65,1.2,2.26,1.49c0.89,0.42,2.52,0.96,3.83,1.31c-0.46,0.48-1.24,0.95-1.53,1.32
            c-0.43,0.58-1.23,1.82-1.46,2.51c-0.12,0.37-0.19,1.15-0.16,1.54c0.06,0.69,0.83,1.98,0.73,2.67c-0.07,0.49-0.92,1.22-1.05,1.7
            c-0.16,0.61-0.26,1.98,0.08,2.51C570.085,371.04,571.715,371.18,572.205,371.67z" pointer-events="auto" class="landarea"></path>

            <rect id="rect12_10" x="515.815" y="371.49" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.84" height="19.63" pointer-events="auto" visibility="hidden"></rect>
    </g>
    <g id="AREA_ITALY_PIEMONTE" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Piedmont" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_ITALY_PIEMONTE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <path id="ITALY_PIEMONTE" fill="#1e8346" stroke="#7eb08e" stroke-width="0.5" d="M280.285,183.69
            c0.23,0.27,0.49,0.82,0.65,1.32c-0.36,0.6-0.73,1.18-0.95,1.59c-0.33,0.59-1.17,1.61-1.47,2.41c-0.51,0.06-1.04,0.11-1.38,0.07
            c-0.63-0.07-1.91-0.36-2.42-0.73c-0.55-0.4-1.09-1.76-1.58-2.24c-0.33-0.32-1.08-0.88-1.52-1.03c-0.73-0.25-2.32-0.26-3.09-0.18
            c-0.65,0.06-2.07,0.21-2.55,0.67c-0.5,0.48-0.53,2.02-0.79,2.67c-0.41,1.04-1.11,3.33-2,4c-0.7,0.53-2.58,0.55-3.46,0.67
            c-0.45,0.06-1.44,0.38-1.82,0.12c-0.48-0.33-0.27-1.81-0.67-2.24c-0.32-0.34-1.29-0.61-1.76-0.67c-0.79-0.1-2.4,0.12-3.15,0.36
            c-0.48,0.15-1.38,0.64-1.76,0.97c-0.45,0.39-0.95,1.56-1.46,1.88c-0.9,0.57-3.12,0.83-4.18,0.91c-0.64,0.05-1.95-0.41-2.55-0.18
            c-0.58,0.23-1.35,1.31-1.7,1.82c-0.29,0.43-0.64,1.43-0.91,1.88c-0.23,0.38-0.61,1.31-1.03,1.46c-0.62,0.21-1.99-0.37-2.49-0.79
            c-0.62-0.52-0.93-2.29-1.52-2.85c-0.28-0.27-1.01-0.72-1.4-0.67c-0.5,0.07-1.24,0.91-1.52,1.33c-0.62,0.97-0.87,3.35-1.27,4.43
            c-0.4,1.05-1.26,3.16-1.94,4.06c-0.6,0.79-2.37,1.82-2.97,2.61c-0.25,0.32-0.74,1.05-0.73,1.46c0.01,0.33,0.54,0.84,0.67,1.15
            c0.17,0.43,0.49,1.36,0.42,1.82c-0.07,0.53-0.92,1.35-1.03,1.88c-0.09,0.45,0.16,1.36,0.18,1.82c0.03,0.59,0.31,1.86,0,2.37
            c-0.31,0.5-1.62,0.73-2.12,1.03c-0.6,0.36-1.8,1.1-2.24,1.64c-0.29,0.35-0.42,1.37-0.79,1.64c-0.51,0.37-1.86,0.34-2.49,0.3
            c-0.65-0.04-1.9-0.53-2.55-0.61c-0.42-0.05-1.3,0.14-1.7,0c-0.58-0.2-1.32-1.51-1.94-1.52c-0.55,0-1.41,0.9-1.76,1.33
            c-0.28,0.35-0.39,1.34-0.73,1.64c-0.36,0.31-1.36,0.68-1.82,0.55c-0.26-0.08-0.6-0.36-0.88-0.69c0.54-0.41,1.49-0.89,1.73-1.37
            c0.28-0.56,0.29-1.91,0.06-2.49c-0.2-0.51-1.24-1.09-1.55-1.55c-0.24-0.36-0.68-1.17-0.67-1.61c0.01-0.45,0.8-1.19,0.76-1.64
            c-0.02-0.25-0.39-0.68-0.61-0.82c-0.25-0.17-0.91-0.4-1.18-0.27c-0.4,0.19-0.43,1.36-0.82,1.58c-0.66,0.37-2.28-0.32-3.03-0.21
            s-2.2,0.7-2.85,1.09c-0.44,0.26-1.04,1.24-1.55,1.33c-0.51,0.09-1.43-0.65-1.94-0.73c-0.51-0.07-1.55,0.06-2.06,0.15
            c-0.45,0.08-1.31,0.56-1.76,0.49c-0.54-0.1-1.29-1.05-1.79-1.27c-0.49-0.22-1.63-0.19-2.09-0.46c-0.44-0.25-0.96-1.21-1.4-1.49
            c-0.46-0.29-1.59-0.4-2.06-0.67c-0.34-0.2-0.94-0.75-1.18-1.06c-0.19-0.24-0.34-0.87-0.55-1.09c-0.18-0.19-0.67-0.54-0.94-0.51
            c-0.18,0.02-0.39,0.45-0.58,0.46c-0.57,0.02-1.35-1.03-1.85-1.3c-0.38-0.21-1.17-0.63-1.61-0.67c-0.54-0.05-1.61,0.59-2.12,0.42
            c-0.37-0.12-0.91-0.79-1.06-1.15c-0.21-0.51-0.12-1.64-0.09-2.18c0.01-0.27,0.22-0.8,0.15-1.06c-0.15-0.51-0.93-1.32-1.36-1.64
            c-0.45-0.32-1.68-0.42-2.06-0.82c-0.57-0.6-0.92-2.34-1.06-3.15c-0.11-0.61-0.15-1.87-0.06-2.49c0.06-0.4,0.24-1.22,0.49-1.55
            c0.2-0.26,0.91-0.45,1.09-0.73c0.13-0.21,0.24-0.74,0.15-0.97c-0.19-0.46-1.28-0.81-1.61-1.18c-0.3-0.34-0.77-1.14-0.88-1.58
            c-0.18-0.71-0.15-2.21,0.03-2.91c0.11-0.43,0.52-1.25,0.82-1.58c0.24-0.27,0.95-0.54,1.21-0.79c0.57-0.53,1.42-1.84,1.85-2.49
            c0.29-0.43,0.97-1.25,1.06-1.76c0.1-0.59-0.58-1.75-0.46-2.34c0.08-0.39,0.59-1.08,0.91-1.3c0.4-0.29,1.43-0.63,1.91-0.51
            c0.36,0.08,0.79,0.8,1.12,0.97c0.36,0.18,1.29,0.62,1.58,0.33c0.49-0.5-0.39-2.07-0.64-2.73c-0.28-0.73-1.28-1.98-1.49-2.73
            c-0.15-0.55-0.09-1.71-0.06-2.27c0.03-0.58,0.51-1.76,0.3-2.3c-0.23-0.61-1.53-1.22-1.97-1.7c-0.26-0.28-0.57-1.04-0.91-1.21
            c-0.29-0.14-0.99-0.15-1.27,0c-0.32,0.17-0.54,0.98-0.85,1.18c-0.31,0.2-1.11,0.45-1.46,0.3c-0.36-0.15-0.53-1.09-0.85-1.3
            c-0.45-0.3-1.64-0.18-2.12-0.42c-0.46-0.23-1.15-1-1.52-1.36c-0.31-0.31-1.02-0.89-1.18-1.3c-0.16-0.42-0.07-1.35,0-1.79
            c0.05-0.29,0.38-0.82,0.39-1.12c0.02-0.44-0.32-1.3-0.42-1.73c-0.08-0.34-0.31-1.01-0.33-1.36c-0.02-0.3,0.24-0.93,0.12-1.21
            c-0.11-0.25-0.61-0.59-0.88-0.67c-0.36-0.1-1.12,0.22-1.49,0.15c-0.35-0.06-1.07-0.35-1.27-0.64c-0.27-0.38-0.1-1.42-0.27-1.85
            c-0.18-0.44-0.93-1.11-1.12-1.55c-0.15-0.33-0.36-1.04-0.3-1.4c0.1-0.62,0.78-1.76,1.24-2.18c0.33-0.3,1.16-0.74,1.61-0.76
            c0.46-0.02,1.27,0.63,1.73,0.61c0.27-0.01,0.77-0.3,1-0.46c0.26-0.17,0.61-0.74,0.91-0.82c0.4-0.11,1.26,0.11,1.61,0.33
            c0.32,0.21,0.52,1.12,0.88,1.27c0.35,0.15,1.16-0.11,1.52-0.24c0.34-0.13,1.01-0.48,1.24-0.76c0.17-0.2,0.18-0.82,0.39-0.97
            c0.38-0.26,1.4,0.12,1.85,0c0.36-0.1,1.16-0.41,1.3-0.76c0.18-0.45-0.69-1.4-0.51-1.85c0.18-0.45,1.19-0.85,1.64-1.03
            c0.65-0.26,2.06-0.51,2.76-0.51c0.41,0,1.22,0.23,1.64,0.27c0.29,0.03,0.93,0.19,1.18,0.03c0.19-0.13,0.21-0.68,0.36-0.85
            c0.29-0.31,1.29-0.4,1.52-0.76c0.27-0.44,0.15-1.57,0-2.06c-0.17-0.55-1.06-1.38-1.3-1.91c-0.2-0.43-0.62-1.37-0.49-1.82
            c0.17-0.55,1.19-1.32,1.73-1.55c0.24-0.1,0.8-0.02,1.06-0.06c0.43-0.07,1.43-0.09,1.67-0.46c0.28-0.43-0.13-1.55-0.3-2.03
            c-0.11-0.32-0.55-0.85-0.7-1.15c-0.09-0.18-0.17-0.51-0.27-0.79c0.4-0.04,0.91,0.01,1.15,0.06c0.43,0.08,1.08,0.87,1.52,0.85
            c0.48-0.02,1.24-0.75,1.58-1.09c0.21-0.21,0.46-0.77,0.67-0.97c0.53-0.51,1.79-1.35,2.49-1.58c0.54-0.17,1.71,0.06,2.24-0.12
            c0.61-0.21,1.65-1.01,2.12-1.46c0.39-0.37,0.99-1.28,1.33-1.7c0.27-0.32,0.72-1.08,1.09-1.27c0.46-0.24,1.55-0.24,2.06-0.18
            c0.54,0.06,1.53,0.59,2.06,0.67c0.76,0.11,2.33-0.17,3.09-0.06c0.52,0.07,1.49,0.51,2,0.61c0.54,0.1,1.65,0.31,2.18,0.18
            c0.54-0.13,1.47-0.78,1.88-1.15c0.48-0.43,0.99-1.77,1.58-2.06c0.49-0.24,1.65,0.13,2.18,0c0.58-0.14,1.81-0.58,2.12-1.09
            c0.23-0.38,0.15-1.35,0-1.76c-0.14-0.41-0.88-0.96-1.09-1.33c-0.11-0.2-0.36-0.63-0.3-0.85c0.1-0.38,1-0.68,1.15-1.03
            s0.1-1.17-0.06-1.52c-0.32-0.67-1.72-1.42-2.24-1.94c-0.44-0.44-1.46-1.28-1.64-1.88c-0.19-0.65,0.17-2.04,0.42-2.67
            c0.26-0.62,1.57-1.51,1.58-2.18c0-0.45-0.77-1.09-1.03-1.46c-0.35-0.5-1.21-1.42-1.4-2c-0.15-0.46-0.02-1.46,0-1.94
            c0.01-0.36,0.06-1.03,0.11-1.66c0.41,0.03,0.84,0.02,1.1,0.07c0.29,0.05,0.88,0.14,1.17,0.2c0.42,0.08,1.24,0.44,1.66,0.36
            c0.32-0.06,0.92-0.41,1.09-0.69c0.32-0.51,0.02-1.83,0.24-2.38c0.25-0.63,1.09-1.78,1.66-2.14c0.73-0.46,2.59-0.37,3.36-0.77
            c0.55-0.29,1.56-1.11,1.86-1.66c0.44-0.8,0.19-2.77,0.57-3.6c0.29-0.65,1.18-1.9,1.82-2.22c0.42-0.21,1.52,0.2,1.9-0.08
            c0.3-0.22,0.22-1.15,0.49-1.41c0.27-0.28,1.17-0.27,1.46-0.53c0.39-0.34,0.91-1.34,0.93-1.86c0.01-0.46-0.59-1.26-0.73-1.7
            c-0.17-0.55-0.1-1.8-0.45-2.26c-0.16-0.22-0.76-0.34-0.97-0.53c-0.31-0.26-0.88-0.9-0.97-1.29c-0.06-0.26,0.05-0.83,0.2-1.05
            c0.28-0.4,1.42-0.57,1.7-0.97c0.11-0.15-0.02-0.61,0.12-0.73c0.42-0.34,1.63,0.25,2.14,0.08c0.41-0.14,1.09-0.73,1.33-1.09
            c0.21-0.31,0.15-1.19,0.44-1.41c0.3-0.22,1.2,0.22,1.5,0c0.39-0.29,0.18-1.56,0.57-1.86c0.39-0.3,1.63,0.27,1.98-0.08
            c0.28-0.28,0.13-1.19,0.08-1.58c-0.06-0.47-0.77-1.34-0.61-1.78c0.19-0.51,1.42-0.82,1.9-1.09c0.67-0.38,1.94-1.38,2.71-1.49
            c0.57-0.08,1.86,0.07,2.26,0.49c0.4,0.41,0.3,1.7,0.4,2.26c0.1,0.52,0.44,1.54,0.4,2.06c-0.03,0.42-0.48,1.16-0.53,1.58
            c-0.05,0.48,0.34,1.47,0.2,1.94c-0.11,0.39-0.87,0.91-1.01,1.29c-0.23,0.61-0.29,1.95-0.16,2.59c0.1,0.51,0.58,1.48,0.93,1.86
            c0.49,0.53,2,0.95,2.47,1.5c0.33,0.38,0.45,1.47,0.77,1.86c0.31,0.37,1.25,0.74,1.62,1.05c0.31,0.26,0.79,0.91,1.09,1.17
            c0.37,0.32,1.26,0.79,1.62,1.13c0.35,0.34,0.81,1.24,1.17,1.58c0.02,0.02,0.04,0.04,0.07,0.06c-0.4,0.54-1.03,1.1-1.22,1.52
            c-0.18,0.38-0.41,1.21-0.42,1.64c-0.02,0.84,0.62,2.44,0.67,3.27c0.06,0.96,0.03,2.92-0.3,3.82c-0.3,0.82-1.62,2.09-2.06,2.85
            c-0.46,0.79-1.06,2.54-1.52,3.33c-0.34,0.58-1.3,1.57-1.58,2.18c-0.5,1.13-0.73,3.65-1.03,4.85c-0.24,0.98-1.09,2.87-1.09,3.88
            c0,0.39,0.2,1.19,0.42,1.52c0.43,0.6,1.87,1.21,2.43,1.7c0.39,0.34,1.16,1.06,1.4,1.52c0.29,0.56,0.22,1.94,0.55,2.49
            c0.33,0.56,1.56,1.18,1.94,1.7c0.49,0.67,1.07,2.25,1.33,3.03c0.33,0.97,0.58,3.05,0.97,4c0.31,0.77,1.2,2.19,1.7,2.85
            c0.45,0.6,1.66,1.55,2.06,2.18c0.49,0.77,1.31,2.48,1.33,3.4c0.02,0.57-0.36,1.74-0.73,2.18c-0.33,0.39-1.34,0.78-1.82,0.97
            c-0.34,0.13-1.17,0.08-1.4,0.36c-0.26,0.34,0.02,1.27,0,1.7c-0.01,0.38,0.02,1.16-0.12,1.52c-0.21,0.5-0.86,1.56-1.4,1.64
            c-0.48,0.07-1.33-0.66-1.64-1.03c-0.26-0.3-0.29-1.22-0.61-1.46c-0.48-0.35-1.78-0.29-2.37-0.18c-0.51,0.09-1.39,0.69-1.88,0.85
            c-0.56,0.18-1.88,0.08-2.3,0.49c-0.35,0.34-0.6,1.41-0.49,1.88c0.13,0.57,1.25,1.24,1.52,1.76c0.28,0.54,0.61,1.76,0.61,2.37
            c0,0.6-0.54,1.71-0.61,2.3c-0.1,0.8-0.26,2.48,0.06,3.21c0.16,0.36,0.9,0.78,1.15,1.09c0.24,0.3,0.6,0.99,0.79,1.33
            c0.3,0.55,0.71,1.75,1.09,2.24c0.48,0.61,1.7,1.61,2.37,2c0.73,0.42,2.32,1.25,3.15,1.15c0.54-0.06,1.4-0.86,1.82-1.21
            c0.34-0.29,0.8-1.16,1.21-1.33c0.45-0.18,1.49-0.06,1.94,0.12c0.3,0.12,0.79,0.58,0.97,0.85c0.29,0.44,0.5,1.5,0.67,2
            c0.15,0.44,0.35,1.37,0.61,1.76c0.23,0.34,0.81,1.01,1.21,1.09c0.38,0.08,1.07-0.48,1.45-0.49c0.36-0.01,1.12,0.19,1.39,0.42
            c0.34,0.3,0.5,1.29,0.79,1.64c0.35,0.43,1.4,0.92,1.76,1.33c0.33,0.38,0.87,1.27,0.97,1.76c0.08,0.4-0.19,1.23-0.18,1.64
            c0.02,0.8,0.15,2.46,0.55,3.15c0.3,0.52,1.25,1.39,1.82,1.58c0.68,0.22,2.14-0.36,2.85-0.24c0.34,0.06,1.05,0.28,1.27,0.55
            c0.37,0.44,0.33,1.71,0.55,2.24c0.27,0.68,1.23,1.85,1.45,2.55c0.2,0.63-0.11,2.05,0.24,2.61
            C278.895,183.12,279.975,183.33,280.285,183.69z" pointer-events="auto" class="landarea"></path>

            <rect id="rect12_11" x="184.505" y="152.84" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.84" height="19.63" pointer-events="auto" visibility="hidden"></rect>
    </g>
    <g id="AREA_ITALY_PUGLIA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Apulia" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_ITALY_PUGLIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <path id="ITALY_PUGLIA" fill="#1e8346" stroke="#7eb08e" stroke-width="0.5" d="M588.775,344.93c-0.05,0.11,0.03,0.38-0.03,0.48
            c-0.07,0.12-0.34,0.33-0.48,0.3c-0.14-0.02-0.33-0.31-0.36-0.45c-0.03-0.13,0.14-0.42,0.09-0.55c-0.06-0.16-0.53-0.28-0.52-0.45
            c0.02-0.17,0.48-0.2,0.58-0.33c0.06-0.1-0.03-0.39,0.06-0.45c0.19-0.14,0.79,0,0.94,0.18c0.15,0.19,0.09,0.74,0,0.97
            C589.005,344.72,588.815,344.83,588.775,344.93z M590.625,343.65c-0.13,0-0.4,0.02-0.52,0.09s-0.3,0.29-0.33,0.42
            c-0.04,0.19,0,0.63,0.15,0.76s0.61,0.12,0.79,0.03c0.2-0.1,0.44-0.54,0.48-0.76c0.02-0.11,0.01-0.35-0.06-0.42
            C591.045,343.68,590.755,343.65,590.625,343.65z M591.535,342.26c0.06-0.14-0.09-0.48-0.21-0.58c-0.22-0.17-0.87-0.21-1.12-0.09
            s-0.6,0.66-0.61,0.94c-0.01,0.19,0.18,0.61,0.36,0.67c0.24,0.08,0.71-0.3,0.91-0.45C591.025,342.62,591.455,342.45,591.535,342.26z
             M570.345,390.26c0.72,0.28,2.16,0.96,2.67,1.54c0.56,0.64,1.66,2.39,1.29,3.15c-0.22,0.45-1.66,0.15-1.94,0.57
            c-0.54,0.81-0.08,3.03,0.4,3.88c0.25,0.44,1.17,0.98,1.62,1.21c0.72,0.38,2.27,0.92,3.07,1.05c0.58,0.1,1.86-0.33,2.34,0
            c0.66,0.44,1.08,2.13,1.21,2.91c0.16,0.92-0.44,2.83-0.16,3.72c0.16,0.51,1.03,1.23,1.29,1.7c0.43,0.76,0.65,2.64,1.29,3.23
            c0.61,0.56,2.33,0.99,3.15,0.97c0.71-0.02,2.01-0.71,2.67-0.97c0.49-0.19,1.42-0.84,1.94-0.81c0.95,0.06,2.47,1.41,3.32,1.86
            c0.56,0.3,1.64,1,2.26,1.13c0.32,0.07,0.8,0.09,1.29,0.08c0.66-0.02,1.36-0.1,1.78-0.24c0.72-0.24,1.87-1.29,2.51-1.7
            c0.58-0.38,1.66-1.47,2.34-1.45c0.51,0.01,1.39,0.73,1.7,1.13c0.3,0.39,0.22,1.62,0.65,1.86c0.38,0.21,1.41-0.08,1.7-0.4
            c0.24-0.27-0.01-1.13,0.16-1.45c0.34-0.63,1.77-1.28,2.18-1.86c0.29-0.41,0.37-1.51,0.73-1.86c0.34-0.33,1.31-0.59,1.78-0.65
            c0.75-0.09,2.28,0.09,2.99,0.32c0.93,0.3,2.67,1.28,3.4,1.94c0.6,0.55,1.59,1.9,1.86,2.67c0.2,0.57,0.25,1.83,0.16,2.42
            c-0.08,0.51-0.44,1.51-0.73,1.94c-0.26,0.39-1.27,0.83-1.37,1.29c-0.1,0.47,0.37,1.46,0.73,1.78c0.41,0.36,1.59,0.39,2.1,0.57
            c0.64,0.22,2.01,0.58,2.51,1.05c0.44,0.42,0.51,1.92,1.05,2.18c0.39,0.19,1.42-0.07,1.7-0.4c0.35-0.42-0.2-1.71,0.08-2.18
            s1.32-1.15,1.86-1.13c0.64,0.02,1.74,0.93,2.1,1.45c0.28,0.39,0.42,1.38,0.48,1.86c0.12,0.92-0.38,2.87,0,3.72
            c0.28,0.61,1.44,1.44,2.02,1.78c0.83,0.48,2.74,0.88,3.64,1.21c0.84,0.31,2.61,0.81,3.32,1.37c0.71,0.57,1.45,2.36,2.1,2.99
            c0.55,0.54,1.82,1.81,2.59,1.7c0.47-0.07,0.99-1.04,1.21-1.45c0.24-0.45,0.3-1.51,0.57-1.94c0.23-0.38,0.88-1.05,1.29-1.21
            c0.36-0.14,1.19-0.17,1.54,0c0.58,0.3,0.96,1.81,1.53,2.1c0.51,0.26,1.76,0.26,2.26,0c0.61-0.31,1.01-1.88,1.62-2.18
            c0.38-0.19,1.32-0.18,1.7,0c0.55,0.26,1.39,1.27,1.54,1.86c0.14,0.56-0.31,1.71-0.48,2.26c-0.14,0.46-0.69,1.3-0.73,1.78
            c-0.05,0.56,0.17,1.72,0.48,2.18c0.22,0.33,1.1,0.55,1.29,0.89c0.2,0.35,0.13,1.22,0.08,1.62c-0.06,0.46-0.57,1.31-0.57,1.78
            c0,0.53,0.37,1.57,0.65,2.02c0.4,0.66,1.47,1.89,2.18,2.18c0.6,0.25,1.99-0.26,2.59,0c0.71,0.31,1.64,1.65,2.1,2.26
            c0.3,0.41,0.88,1.07,1.27,1.72c0.34-0.56,0.65-1.06,0.91-1.43c0.73-1.05,2.24-3.15,3.23-3.96c0.72-0.59,2.44-1.42,3.32-1.74
            c0.83-0.3,2.57-0.77,3.46-0.77c0.6,0,1.89,0.15,2.34,0.55c0.36,0.3,0.32,1.48,0.71,1.74c0.36,0.24,1.32,0.14,1.72-0.02
            c0.41-0.16,0.96-0.89,1.29-1.17c0.22-0.19,0.63-0.64,0.91-0.73c0.49-0.15,1.53,0.22,2.04,0.16c0.32-0.04,0.93-0.53,1.23-0.4
            c0.38,0.16,0.71,1.07,0.73,1.48c0.02,0.3-0.2,0.94-0.42,1.13c-0.29,0.25-1.11,0.28-1.5,0.3c-0.54,0.03-1.69-0.47-2.16-0.2
            c-0.26,0.15-0.49,0.79-0.51,1.09c-0.02,0.27,0.33,0.76,0.34,1.03c0.01,0.19-0.02,0.59-0.14,0.73c-0.29,0.34-1.42,0.25-1.7,0.61
            c-0.09,0.12-0.07,0.45-0.06,0.61c0.01,0.35-0.08,1.25,0.24,1.39c0.23,0.1,0.61-0.47,0.85-0.55c0.45-0.13,1.42-0.1,1.88,0
            c0.7,0.15,1.91,1.05,2.61,1.21c0.46,0.11,1.42-0.06,1.88,0.06c0.63,0.17,1.76,0.86,2.3,1.21c0.34,0.23,0.88,0.92,1.27,1.03
            c0.35,0.1,1.11-0.29,1.46-0.18c0.45,0.14,0.96,1.09,1.4,1.27c0.36,0.15,1.21-0.07,1.58,0.06c0.33,0.12,0.75,0.78,1.09,0.85
            c0.33,0.06,0.95-0.32,1.27-0.36c0.8-0.11,2.44,0.21,3.21,0c0.32-0.09,0.83-0.57,1.15-0.67c0.76-0.23,2.38-0.02,3.15-0.18
            c0.41-0.09,1.16-0.65,1.58-0.61c0.31,0.03,0.74,0.58,1.03,0.67c0.75,0.24,2.37,0,3.15,0.12c0.64,0.1,1.85,0.79,2.49,0.67
            c0.28-0.05,0.64-0.74,0.91-0.67c0.32,0.08,0.29,1,0.55,1.21c0.37,0.3,1.63,0.02,1.88,0.42c0.1,0.16-0.2,0.54-0.18,0.73
            c0.05,0.51,0.7,1.39,0.85,1.88c0.16,0.51,0.31,1.59,0.36,2.12c0.05,0.48-0.33,1.65,0.06,1.94c0.24,0.18,0.85-0.32,1.15-0.3
            c0.34,0.02,1.03,0.24,1.27,0.48c0.34,0.34,0.48,1.37,0.67,1.82c0.28,0.67,1.13,1.9,1.27,2.61c0.12,0.58-0.25,1.79-0.12,2.36
            c0.09,0.4,0.67,1.06,0.79,1.45c0.13,0.44,0.4,1.46,0.12,1.82c-0.16,0.21-0.93-0.06-1.03,0.18c-0.19,0.43,0.67,1.27,1.03,1.58
            c0.25,0.22,1,0.29,1.21,0.55c0.18,0.22,0.11,0.85,0.24,1.09c0.47,0.85,1.93,2.19,2.73,2.73c0.35,0.24,1.19,0.48,1.58,0.67
            c0.64,0.3,1.91,0.92,2.49,1.33c0.64,0.45,1.53,2.03,2.3,2.12c0.28,0.03,0.71-0.46,0.97-0.55c0.65-0.22,2.05-0.47,2.73-0.36
            c0.43,0.06,1.23,0.47,1.58,0.73c0.48,0.36,1.09,1.47,1.58,1.82c0.31,0.22,1.02,0.52,1.4,0.55c0.35,0.03,1.14-0.06,1.4-0.3
            c0.42-0.4,0.58-1.66,0.61-2.24c0.03-0.58-0.34-1.72-0.36-2.3c-0.02-0.7,0.39-2.11,0.24-2.79c-0.11-0.48-0.88-1.22-0.97-1.7
            c-0.1-0.53,0.22-1.59,0.3-2.12c0.12-0.72,0.12-2.27,0.55-2.85c0.26-0.36,1.35-0.46,1.58-0.85c0.19-0.31-0.11-1.11,0-1.45
            c0.12-0.36,0.76-0.86,0.91-1.21c0.26-0.61,0.15-2.01,0.42-2.61c0.24-0.53,1.33-1.21,1.52-1.76c0.2-0.59,0.07-1.92-0.18-2.49
            c-0.21-0.48-1.15-1.09-1.46-1.52c-0.41-0.58-1.08-1.86-1.27-2.55c-0.17-0.59-0.02-1.86-0.24-2.43c-0.31-0.8-1.47-2.11-2.06-2.73
            c-0.37-0.38-1.26-0.96-1.64-1.33c-0.59-0.58-1.54-1.96-2.12-2.55c-0.84-0.84-2.76-2.24-3.64-3.03c-0.74-0.66-2.03-2.18-2.85-2.73
            c-0.99-0.67-3.33-1.35-4.37-1.94c-0.98-0.56-2.87-1.84-3.7-2.61c-0.33-0.31-0.8-1.11-1.15-1.39c-0.61-0.48-2.42-0.71-2.79-1.39
            c-0.26-0.5,0.48-1.71,0.3-2.24c-0.16-0.47-1.21-0.94-1.4-1.39c-0.13-0.31,0.26-1.12,0-1.33c-0.33-0.28-1.29,0.15-1.7,0.3
            c-0.33,0.12-0.87,0.82-1.21,0.73c-0.3-0.08-0.66-0.78-0.61-1.09c0.04-0.26,0.69-0.48,0.79-0.73c0.1-0.27,0.08-0.94-0.12-1.15
            c-0.5-0.52-2.13-0.39-2.85-0.42c-0.67-0.03-2.01,0.23-2.67,0.12c-0.76-0.13-2.15-0.83-2.85-1.15c-1.29-0.59-3.73-2.05-5.03-2.61
            c-0.9-0.39-2.76-1.01-3.7-1.27c-1.36-0.38-4.21-0.68-5.52-1.21c-1.08-0.44-2.96-1.86-3.94-2.49c-1.35-0.85-4.11-2.45-5.4-3.4
            c-1.36-1-3.8-3.36-5.15-4.37c-0.57-0.42-1.76-1.16-2.36-1.52c-0.92-0.55-2.77-1.64-3.76-2.06c-0.61-0.26-1.91-0.58-2.55-0.79
            c-1.09-0.37-3.21-1.23-4.3-1.58c-1.2-0.38-3.67-0.9-4.85-1.33c-0.64-0.23-1.82-0.98-2.49-1.15c-1.16-0.31-3.64-0.09-4.79-0.42
            c-1.03-0.3-2.81-1.58-3.82-1.94c-0.75-0.26-2.31-0.56-3.09-0.67c-0.54-0.07-1.68,0.09-2.18-0.12c-0.29-0.12-0.63-0.71-0.91-0.85
            c-0.44-0.22-1.5-0.14-1.94-0.36c-0.39-0.2-0.9-0.98-1.27-1.21c-0.38-0.24-1.26-0.51-1.7-0.61c-0.4-0.08-1.26,0.04-1.64-0.12
            c-0.57-0.25-1.26-1.42-1.82-1.7c-0.65-0.32-2.16-0.26-2.85-0.48c-0.64-0.21-1.8-0.96-2.43-1.21c-0.87-0.35-2.77-0.63-3.58-1.09
            c-0.66-0.38-1.52-1.83-2.24-2.06c-0.57-0.18-1.78,0.4-2.36,0.3c-1.52-0.26-4.08-2.14-5.48-2.79c-1.29-0.6-4.13-1.31-5.26-2.18
            c-0.72-0.56-1.74-2.11-2.18-2.91c-0.75-1.36-2.03-4.24-2.22-5.78c-0.09-0.72-0.02-2.23,0.32-2.87c0.32-0.59,1.54-1.28,2.06-1.7
            c0.68-0.55,2.02-1.67,2.75-2.14c0.55-0.36,1.84-0.8,2.3-1.25c0.43-0.42,0.79-1.67,1.21-2.1c0.53-0.55,2.01-1.12,2.59-1.62
            c0.48-0.42,1.28-1.43,1.66-1.94c0.37-0.5,1.26-1.48,1.33-2.1c0.09-0.79-0.84-2.23-1.05-2.99c-0.09-0.34-0.17-1.04-0.28-1.37
            c-0.24-0.73-0.76-2.23-1.29-2.79c-0.57-0.6-2.22-1.14-2.95-1.54c-0.69-0.38-1.93-1.45-2.71-1.58c-1.09-0.18-3.24,0.67-4.33,0.89
            c-0.77,0.15-2.32,0.39-3.07,0.61c-0.74,0.21-2.14,0.83-2.87,1.05c-1.18,0.35-3.59,0.94-4.81,1.13c-0.73,0.12-2.22,0.35-2.95,0.24
            c-0.73-0.1-2.01-1.01-2.75-1.05c-0.58-0.03-1.67,0.44-2.22,0.61c-1,0.3-2.95,1.06-3.96,1.29c-1.48,0.34-4.5,0.78-6.02,0.85
            c-1.15,0.05-3.46-0.19-4.61-0.24c-1.42-0.07-4.3,0.01-5.7-0.24c-0.33-0.06-0.74-0.15-1.18-0.27c-0.46,0.48-1.24,0.95-1.53,1.32
            c-0.43,0.58-1.23,1.82-1.46,2.51c-0.12,0.37-0.19,1.15-0.16,1.54c0.06,0.69,0.83,1.98,0.73,2.67c-0.07,0.49-0.92,1.22-1.05,1.7
            c-0.16,0.61-0.26,1.98,0.08,2.51c0.38,0.58,2.01,0.72,2.51,1.21c0.35,0.35,0.91,1.28,0.89,1.78c-0.02,0.56-0.68,1.6-1.13,1.94
            s-1.76,0.2-2.18,0.57c-0.35,0.3-0.4,1.38-0.73,1.7c-0.36,0.35-1.4,0.92-1.86,0.73c-0.38-0.16-0.27-1.37-0.65-1.54
            c-0.45-0.2-1.46,0.36-1.86,0.65c-0.66,0.46-1.84,1.72-2.02,2.51c-0.23,1.01,0.52,3.1,0.97,4.04c0.41,0.87,1.79,2.28,2.34,3.07
            c0.43,0.61,1.01,2.08,1.62,2.51c0.02,0.02,0.05,0.03,0.07,0.04C568.295,390.02,569.715,390.01,570.345,390.26z" pointer-events="auto" class="landarea"></path>

            <rect id="rect12_12" x="634.835" y="411.73" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.84" height="19.63" pointer-events="auto" visibility="hidden"></rect>
    </g>
    <g id="AREA_ITALY_SARDEGNA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Sardinia" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_ITALY_SARDEGNA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <path id="ITALY_SARDEGNA" fill="#1e8346" stroke="#7eb08e" stroke-width="0.5" d="M257.605,571.56
            c-0.22,0.18-0.81,0.32-1.09,0.3c-0.31-0.02-0.93-0.23-1.15-0.46c-0.15-0.15-0.13-0.66-0.3-0.79c-0.14-0.1-0.54-0.11-0.7-0.03
            c-0.18,0.09-0.26,0.62-0.46,0.64c-0.34,0.03-0.8-0.67-0.97-0.97c-0.15-0.25-0.11-0.91-0.3-1.12c-0.26-0.28-1.09-0.36-1.43-0.55
            c-0.26-0.15-0.67-0.77-0.97-0.73c-0.29,0.04-0.45,0.79-0.7,0.94c-0.2,0.12-0.69,0.06-0.91,0.12c-0.38,0.1-1.18,0.32-1.46,0.61
            c-0.18,0.19-0.24,0.76-0.36,1c-0.09,0.16-0.41,0.42-0.42,0.61c-0.01,0.27,0.49,0.67,0.51,0.94c0.02,0.28-0.12,0.93-0.36,1.06
            c-0.21,0.11-0.75-0.09-0.94-0.24c-0.19-0.15-0.42-0.63-0.46-0.88c-0.04-0.29,0.45-0.94,0.24-1.15c-0.19-0.19-0.82,0.33-1.06,0.21
            c-0.19-0.09-0.34-0.55-0.39-0.76c-0.13-0.45-0.22-1.41-0.15-1.88c0.07-0.47,0.42-1.38,0.7-1.76c0.12-0.16,0.59-0.32,0.64-0.52
            c0.07-0.29-0.26-0.9-0.49-1.09c-0.2-0.17-0.82-0.43-1.03-0.27c-0.25,0.19,0.07,0.96,0,1.27c-0.05,0.22-0.2,0.79-0.42,0.82
            c-0.31,0.04-0.64-0.71-0.85-0.94c-0.14-0.15-0.46-0.42-0.55-0.61c-0.33-0.7-0.41-2.29-0.46-3.06c-0.03-0.47,0.33-1.5,0.06-1.88
            c-0.2-0.29-1.12-0.15-1.33-0.42c-0.18-0.23,0.1-0.93-0.06-1.18c-0.17-0.28-0.95-0.38-1.15-0.64c-0.12-0.15-0.1-0.58-0.21-0.73
            s-0.47-0.28-0.61-0.39c-0.19-0.16-0.53-0.52-0.67-0.73c-0.16-0.25-0.25-0.91-0.49-1.09c-0.28-0.21-1.1,0.03-1.36-0.18
            c-0.23-0.19-0.29-0.84-0.39-1.12c-0.21-0.58-0.5-1.82-0.88-2.3c-0.27-0.35-1.12-0.71-1.46-1c-0.34-0.29-1.07-0.88-1.21-1.3
            c-0.19-0.55-0.05-1.81,0.21-2.34c0.33-0.66,1.52-1.65,2.18-1.97c0.3-0.15,1.07-0.01,1.3-0.24c0.24-0.24,0.29-1.01,0.21-1.33
            c-0.12-0.47-0.91-1.14-1.18-1.55c-0.3-0.46-0.62-1.63-1.09-1.91c-0.22-0.13-0.81,0.16-1,0c-0.24-0.21-0.18-0.93-0.18-1.24
            c0-0.41,0.03-1.26,0.24-1.61c0.19-0.3,0.96-0.53,1.18-0.82c0.3-0.4,0.74-1.39,0.67-1.88c-0.04-0.27-0.47-0.68-0.67-0.88
            c-0.24-0.24-1.01-0.49-1.09-0.82c-0.09-0.35,0.42-1.01,0.64-1.3c0.36-0.49,1.37-1.23,1.76-1.7c0.41-0.48,1.03-1.6,1.43-2.09
            c0.15-0.18,0.55-0.45,0.64-0.67c0.16-0.39-0.04-1.28,0.03-1.7c0.06-0.36,0.48-1.03,0.46-1.4c-0.03-0.38-0.62-0.99-0.7-1.36
            c-0.07-0.36,0.09-1.11,0.12-1.49c0.04-0.45,0.23-1.34,0.18-1.79c-0.04-0.34-0.45-0.96-0.46-1.3c-0.01-0.31,0.35-0.87,0.36-1.18
            c0.03-0.66-0.47-1.92-0.55-2.58c-0.05-0.45-0.3-1.45-0.03-1.82c0.17-0.23,0.82-0.44,1.09-0.33c0.52,0.2,1.01,1.37,1.15,1.91
            c0.1,0.4-0.28,1.29-0.06,1.64c0.2,0.33,1.11,0.38,1.4,0.64c0.22,0.21,0.33,0.89,0.58,1.06c0.32,0.23,1.18,0.42,1.55,0.27
            c0.26-0.1,0.64-0.6,0.67-0.88c0.02-0.25-0.21-0.83-0.46-0.88c-0.16-0.03-0.35,0.38-0.51,0.39c-0.27,0.02-0.77-0.33-0.97-0.52
            c-0.22-0.2-0.55-0.72-0.64-1c-0.1-0.35-0.16-1.13,0-1.45c0.2-0.39,1.33-0.6,1.4-1.03c0.04-0.25-0.56-0.57-0.61-0.82
            c-0.07-0.38,0.31-1.12,0.42-1.49c0.1-0.31,0.11-1.14,0.42-1.24c0.21-0.07,0.49,0.59,0.7,0.52c0.22-0.07,0.32-0.66,0.24-0.88
            c-0.12-0.33-1.01-0.5-1.15-0.82c-0.15-0.33,0.02-1.11,0.15-1.45c0.12-0.32,0.45-1.11,0.79-1.12s0.53,0.89,0.82,1.06
            c0.31,0.18,1.12,0.37,1.43,0.18c0.24-0.15,0.46-0.79,0.39-1.06c-0.07-0.31-0.77-0.59-0.91-0.88c-0.16-0.34,0.11-1.21-0.12-1.52
            c-0.19-0.25-0.87-0.36-1.18-0.42c-0.29-0.06-0.98-0.27-1.18-0.06c-0.23,0.24,0.18,0.98,0.12,1.3c-0.03,0.2-0.14,0.67-0.33,0.73
            c-0.31,0.1-0.88-0.45-1.12-0.67c-0.24-0.22-0.5-0.88-0.79-1.03c-0.37-0.18-1.25-0.15-1.64-0.03c-0.29,0.09-0.96,0.4-0.97,0.7
            c0,0.18,0.54,0.28,0.58,0.45c0.05,0.24-0.24,0.72-0.42,0.88c-0.2,0.17-0.77,0.46-1,0.33c-0.33-0.19-0.06-1.22-0.33-1.49
            c-0.23-0.23-1.02-0.07-1.27-0.27c-0.22-0.18-0.43-0.75-0.49-1.03c-0.16-0.76,0.01-2.34,0.06-3.12c0.02-0.36,0.02-1.11,0.15-1.45
            c0.13-0.33,0.77-0.78,0.85-1.12c0.06-0.28-0.05-0.91-0.24-1.12c-0.21-0.22-0.97-0.11-1.18-0.33c-0.21-0.23-0.39-0.94-0.24-1.21
            c0.22-0.42,1.28-0.71,1.76-0.76c0.35-0.04,1.05,0.34,1.4,0.27c0.49-0.1,1.27-0.79,1.67-1.09c0.18-0.14,0.59-0.39,0.67-0.61
            c0.21-0.57-0.07-1.85-0.27-2.42c-0.09-0.27-0.53-0.7-0.61-0.97c-0.15-0.55,0.14-1.74,0.03-2.3c-0.06-0.33-0.56-0.91-0.51-1.24
            c0.03-0.21,0.41-0.48,0.51-0.67c0.17-0.32,0.45-1,0.49-1.36c0.04-0.38-0.37-1.15-0.24-1.52c0.07-0.2,0.55-0.38,0.64-0.58
            c0.17-0.4-0.09-1.32-0.06-1.76c0.02-0.29,0.23-0.86,0.18-1.15c-0.08-0.51-0.53-1.5-0.91-1.85c-0.31-0.28-1.24-0.3-1.55-0.58
            c-0.16-0.15-0.22-0.66-0.39-0.79c-0.36-0.26-1.43,0.02-1.76-0.27c-0.17-0.15-0.22-0.65-0.24-0.88c-0.06-0.58,0.11-1.75,0.18-2.33
            c0.07-0.5,0.42-1.5,0.36-2c-0.08-0.67-0.95-1.82-1.09-2.49c-0.13-0.64,0.35-2.08,0-2.64c-0.2-0.32-1.05-0.45-1.36-0.67
            c-0.18-0.12-0.54-0.39-0.64-0.58c-0.21-0.41-0.11-1.4-0.21-1.85c-0.08-0.34-0.41-0.96-0.46-1.3c-0.04-0.36,0.3-1.11,0.12-1.42
            c-0.27-0.47-1.4-0.92-1.94-0.94c-0.24-0.01-0.64,0.35-0.88,0.36c-0.26,0.02-0.77-0.36-1-0.24c-0.22,0.12-0.14,0.79-0.33,0.94
            c-0.25,0.19-0.95,0.22-1.24,0.12c-0.22-0.08-0.58-0.42-0.67-0.64c-0.13-0.33-0.05-1.08,0.06-1.42c0.06-0.19,0.41-0.47,0.42-0.67
            c0.01-0.16-0.13-0.51-0.27-0.58c-0.26-0.11-0.9,0.15-1.09,0.36c-0.3,0.35-0.09,1.43-0.33,1.82c-0.11,0.17-0.57,0.3-0.67,0.48
            c-0.18,0.35,0.31,1.3,0.03,1.58c-0.15,0.16-0.71,0.14-0.88,0c-0.4-0.33-0.34-1.54-0.39-2.06c-0.06-0.59-0.23-1.81-0.03-2.36
            c0.08-0.22,0.41-0.57,0.61-0.7c0.21-0.14,0.77-0.12,0.97-0.27c0.24-0.18,0.5-0.75,0.61-1.03c0.14-0.38,0.13-1.21,0.3-1.58
            c0.13-0.27,0.82-0.64,0.76-0.94c-0.08-0.38-1.08-0.47-1.4-0.7c-0.37-0.26-1.06-0.86-1.3-1.24c-0.14-0.22-0.38-0.72-0.33-0.97
            c0.06-0.33,0.72-0.75,0.85-1.06c0.17-0.42,0.13-1.36,0.18-1.82c0.06-0.52-0.07-1.69,0.27-2.09c0.26-0.3,1.28-0.12,1.55-0.42
            c0.26-0.29,0.12-1.17,0.21-1.55c0.14-0.54,0.55-1.57,0.79-2.06c0.19-0.4,0.94-1.08,0.91-1.52c-0.02-0.27-0.44-0.7-0.67-0.85
            c-0.33-0.22-1.21-0.17-1.52-0.42c-0.26-0.21-0.56-0.85-0.61-1.18c-0.07-0.5,0.21-1.52,0.36-2c0.08-0.25,0.18-0.87,0.42-0.97
            c0.35-0.15,1.07,0.4,1.43,0.55c0.38,0.15,1.35,0.26,1.52,0.64c0.14,0.32-0.53,0.96-0.49,1.3c0.05,0.34,0.7,0.8,0.82,1.12
            c0.13,0.34,0,1.11,0.12,1.45c0.17,0.46,0.82,1.22,1.12,1.61c0.23,0.3,0.65,0.98,1,1.12c0.46,0.19,1.48-0.2,1.97-0.12
            c0.42,0.07,1.15,0.58,1.58,0.64c0.37,0.05,1.11-0.23,1.49-0.21c0.59,0.02,1.8,0.19,2.3,0.48c0.36,0.21,0.73,1.04,1.09,1.24
            c0.4,0.23,1.36,0.34,1.82,0.33c0.75-0.01,2.22-0.42,2.94-0.64c0.67-0.2,2.07-0.53,2.61-0.97c0.53-0.43,0.97-1.83,1.46-2.3
            c0.68-0.66,2.44-1.49,3.27-1.94c0.3-0.16,0.9-0.6,1.24-0.61c0.49-0.01,1.3,0.72,1.79,0.76c0.56,0.04,1.69-0.3,2.18-0.58
            c0.32-0.18,0.82-0.73,1.06-1c0.51-0.58,1.49-1.81,1.82-2.52c0.22-0.46,0.33-1.5,0.51-1.97c0.14-0.35,0.43-1.07,0.7-1.33
            c0.33-0.33,1.26-0.59,1.64-0.85c0.49-0.34,1.34-1.21,1.79-1.61c0.39-0.35,1.2-1.03,1.58-1.39c0.76-0.73,1.91-2.73,2.91-3.06
            c0.4-0.13,1.24,0.3,1.67,0.3c0.41,0,1.25-0.07,1.61-0.27c0.43-0.25,0.85-1.26,1.27-1.52c0.29-0.17,1.1-0.03,1.33-0.27
            c0.44-0.45,0.43-1.86,0.42-2.49c0-0.32-0.11-0.98-0.24-1.27c-0.11-0.25-0.59-0.59-0.67-0.85c-0.08-0.24-0.06-0.78,0.06-1
            c0.12-0.21,0.58-0.51,0.82-0.55c0.22-0.03,0.63,0.23,0.85,0.24c0.4,0.02,1.18-0.21,1.58-0.3c0.38-0.09,1.13-0.46,1.52-0.39
            c0.37,0.07,0.98,0.58,1.24,0.85c0.17,0.17,0.53,0.57,0.55,0.82s-0.37,0.67-0.42,0.91c-0.06,0.28-0.05,0.87,0,1.15
            c0.05,0.24,0.15,0.79,0.36,0.91s0.82,0.05,0.97-0.15c0.16-0.22-0.28-0.85-0.15-1.09c0.07-0.13,0.38-0.31,0.52-0.27
            c0.17,0.05,0.32,0.44,0.36,0.61c0.07,0.26-0.1,0.84,0,1.09c0.08,0.19,0.37,0.64,0.58,0.61c0.25-0.04,0.2-0.82,0.42-0.94
            s0.82,0.33,1,0.15c0.19-0.19-0.36-0.86-0.18-1.06c0.19-0.22,0.92-0.1,1.18,0.03c0.24,0.12,0.52,0.6,0.67,0.82
            c0.24,0.35,0.6,1.11,0.85,1.45c0.15,0.2,0.47,0.6,0.67,0.76c0.37,0.31,1.5,0.54,1.67,1c0.09,0.26-0.33,0.76-0.36,1.03
            c-0.05,0.4-0.04,1.27,0.18,1.61c0.12,0.19,0.55,0.53,0.76,0.45c0.33-0.11,0.37-0.97,0.48-1.3c0.09-0.27,0.13-0.9,0.33-1.09
            c0.32-0.3,1.38-0.12,1.73-0.39c0.22-0.17,0.23-0.89,0.48-1c0.21-0.09,0.69,0.15,0.88,0.27c0.39,0.26,0.98,1.02,1.21,1.42
            c0.24,0.42,0.62,1.36,0.61,1.85c-0.01,0.23-0.17,0.69-0.33,0.85c-0.29,0.26-1.26,0.14-1.49,0.45c-0.18,0.26,0.13,0.96,0.06,1.27
            c-0.05,0.25-0.32,0.69-0.45,0.91c-0.14,0.23-0.54,0.63-0.64,0.88c-0.16,0.4-0.58,1.5-0.21,1.73c0.25,0.15,0.73-0.47,0.97-0.64
            c0.31-0.22,0.85-1.05,1.21-0.94c0.29,0.09,0.4,0.85,0.39,1.15c0,0.16-0.34,0.47-0.24,0.61c0.13,0.19,0.72,0.09,0.91-0.03
            c0.2-0.13,0.26-0.71,0.45-0.85c0.35-0.23,1.25-0.24,1.67-0.18c0.41,0.06,1.27,0.32,1.55,0.64c0.19,0.22,0.37,0.84,0.3,1.12
            c-0.04,0.18-0.31,0.49-0.48,0.55c-0.4,0.13-1.23-0.51-1.64-0.39s-1,0.83-1.18,1.21c-0.16,0.35-0.01,1.18-0.15,1.55
            c-0.06,0.16-0.34,0.39-0.39,0.55c-0.09,0.27,0.23,0.98,0,1.15c-0.21,0.16-0.74-0.27-1-0.3c-0.55-0.07-1.82-0.3-2.21,0.09
            c-0.22,0.21-0.28,1-0.06,1.21c0.25,0.24,1.05-0.14,1.39-0.09c0.52,0.07,1.48,0.71,2,0.67c0.33-0.03,0.84-0.52,1.15-0.64
            c0.42-0.16,1.4-0.67,1.76-0.39c0.19,0.15,0.06,0.73,0.03,0.97c-0.02,0.13-0.17,0.36-0.15,0.48c0.06,0.48,0.61,1.39,1.03,1.64
            c0.28,0.16,1.01-0.07,1.3,0.06c0.31,0.14,0.67,0.81,0.97,0.97c0.29,0.15,1.02,0.08,1.3,0.24c0.26,0.14,0.8,0.58,0.79,0.88
            c-0.01,0.21-0.4,0.53-0.61,0.58c-0.29,0.07-0.87-0.47-1.15-0.36c-0.15,0.06-0.33,0.41-0.33,0.58c0,0.16,0.29,0.41,0.3,0.58
            c0.01,0.2-0.11,0.64-0.27,0.76c-0.17,0.11-0.64,0.06-0.82-0.03s-0.29-0.64-0.48-0.64c-0.2,0.01-0.41,0.47-0.45,0.67
            c-0.07,0.27-0.04,0.87,0.12,1.09c0.29,0.4,1.44,0.5,1.82,0.82c0.44,0.37,1.18,1.35,1.3,1.91c0.11,0.48-0.36,1.45-0.3,1.94
            c0.05,0.46,0.38,1.39,0.7,1.73c0.17,0.18,0.74,0.23,0.88,0.42c0.19,0.26,0.17,0.95,0.15,1.27c-0.02,0.36-0.2,1.05-0.3,1.39
            c-0.06,0.22-0.26,0.63-0.3,0.85c-0.08,0.36-0.19,1.1-0.12,1.45c0.11,0.61,0.73,1.71,1.09,2.21s1.51,1.16,1.76,1.73
            c0.11,0.26-0.12,0.91,0.03,1.15c0.2,0.33,1.13,0.45,1.36,0.76c0.16,0.21,0.31,0.77,0.27,1.03c-0.05,0.38-0.65,0.95-0.79,1.3
            c-0.31,0.76-0.3,2.51-0.73,3.21c-0.2,0.32-0.99,0.63-1.15,0.97c-0.27,0.59,0.26,2.01,0,2.61c-0.17,0.4-0.89,1.01-1.27,1.21
            c-0.25,0.13-0.83,0.14-1.09,0.24c-0.75,0.31-2.22,1.13-2.73,1.76c-0.33,0.4-0.48,1.48-0.73,1.94c-0.33,0.62-1.35,1.66-1.64,2.3
            c-0.32,0.74-0.7,2.35-0.67,3.15c0.04,0.95,0.83,2.73,1.09,3.64c0.24,0.83,0.43,2.61,0.91,3.33c0.53,0.81,2.57,1.57,3.03,2.43
            c0.29,0.53,0.39,1.81,0.24,2.39c-0.07,0.26-0.5,0.63-0.61,0.88c-0.17,0.41-0.2,1.32-0.36,1.73c-0.22,0.56-1.08,1.46-1.27,2.03
            c-0.12,0.36-0.26,1.15-0.15,1.52c0.11,0.39,0.84,0.93,1,1.3c0.18,0.42,0.48,1.39,0.3,1.82c-0.14,0.35-1.05,0.57-1.21,0.91
            c-0.1,0.21,0,0.69,0.06,0.91c0.09,0.35,0.57,0.95,0.64,1.3c0.14,0.72-0.03,2.21-0.24,2.91c-0.14,0.46-0.79,1.24-0.94,1.7
            c-0.14,0.42-0.24,1.32-0.21,1.76c0.05,0.7,0.63,2.01,0.7,2.7c0.04,0.37,0.02,1.13-0.09,1.49c-0.14,0.44-0.82,1.14-0.97,1.58
            c-0.09,0.26-0.07,0.82-0.12,1.09c-0.06,0.28-0.3,0.81-0.33,1.09c-0.09,0.78,0.21,2.36,0.18,3.15c-0.04,0.98-0.29,2.93-0.55,3.88
            c-0.21,0.8-1.26,2.24-1.24,3.06c0.01,0.52,0.77,1.39,0.85,1.91c0.14,0.9-0.19,2.73-0.42,3.61c-0.18,0.67-0.89,1.89-1.12,2.55
            c-0.15,0.42-0.13,1.42-0.45,1.73c-0.12,0.11-0.52,0.01-0.64,0.12c-0.21,0.22-0.32,0.91-0.18,1.18c0.13,0.27,0.87,0.34,1.06,0.58
            c0.26,0.32,0.22,1.24,0.45,1.58c0.19,0.27,1.08,0.43,1.09,0.76c0.01,0.28-0.61,0.6-0.88,0.67c-0.18,0.05-0.56-0.16-0.73-0.09
            c-0.38,0.16-0.84,0.95-1,1.33c-0.13,0.3-0.14,0.98-0.21,1.3c-0.15,0.7-0.8,2.04-0.76,2.76c0.03,0.47,0.72,1.28,0.73,1.76
            c0.01,0.38-0.42,1.06-0.52,1.42c-0.1,0.41,0.08,1.4-0.24,1.67c-0.27,0.23-1.16-0.3-1.42-0.06c-0.23,0.2-0.08,0.91-0.09,1.21
            c-0.01,0.27,0.19,0.9,0,1.09s-0.85,0.12-1.09,0c-0.21-0.1-0.49-0.51-0.58-0.73c-0.1-0.24,0.11-0.87-0.09-1.03
            c-0.31-0.23-1.13,0.27-1.52,0.27c-0.48,0.01-1.45-0.07-1.88-0.27c-0.16-0.07-0.35-0.4-0.52-0.46c-0.31-0.11-1.03,0.17-1.33,0.03
            s-0.59-0.79-0.82-1.03c-0.21-0.22-0.67-0.65-0.94-0.79c-0.32-0.16-1.11-0.11-1.39-0.33c-0.33-0.25-0.67-1.06-0.79-1.46
            c-0.09-0.28,0.05-0.94-0.12-1.18c-0.38-0.55-1.74-1.05-2.39-1.21c-0.48-0.12-1.51-0.13-2-0.06c-0.46,0.07-1.33,0.47-1.79,0.58
            c-0.31,0.07-1.02-0.04-1.24,0.18c-0.18,0.17-0.03,0.77-0.18,0.97c-0.14,0.18-0.59,0.4-0.82,0.39c-0.34-0.01-0.84-0.66-1.18-0.67
            c-0.26-0.01-0.68,0.4-0.94,0.46c-0.3,0.06-0.93-0.1-1.24-0.06c-0.4,0.05-1.21,0.27-1.55,0.48c-0.32,0.2-0.73,0.86-1,1.12
            c-0.42,0.4-1.47,1-1.79,1.49c-0.33,0.49-0.68,1.68-0.67,2.27c0.02,0.57,0.41,1.7,0.76,2.15c0.23,0.3,1.02,0.55,1.24,0.85
            c0.27,0.36,0.71,1.3,0.55,1.73c-0.15,0.4-1.19,0.54-1.45,0.88c-0.14,0.18-0.27,0.65-0.27,0.88c0,0.32,0.3,0.93,0.36,1.24
            c0.08,0.36,0.45,1.18,0.21,1.46c-0.13,0.16-0.62,0.03-0.82,0c-0.19-0.03-0.54-0.3-0.73-0.24c-0.28,0.09-0.49,0.76-0.73,0.94
            c-0.44,0.34-1.63,0.52-2.06,0.88c-0.49,0.41-1.05,1.61-1.39,2.15c-0.19,0.29-0.46,0.99-0.76,1.18c-0.19,0.12-0.67,0.08-0.88,0.15
            c-0.24,0.08-0.73,0.3-0.91,0.48C257.805,571,257.755,571.43,257.605,571.56z M236.655,560.28c-0.08-0.19-0.71-0.13-0.76-0.33
            c-0.09-0.33,0.77-0.75,0.82-1.09c0.04-0.3-0.28-0.88-0.46-1.12c-0.21-0.29-0.89-0.62-1.09-0.91c-0.15-0.21-0.14-0.8-0.33-0.97
            c-0.37-0.33-1.42-0.38-1.91-0.42c-0.35-0.03-1.06-0.07-1.4,0.03c-0.24,0.07-0.72,0.3-0.85,0.52c-0.1,0.16-0.12,0.55-0.06,0.73
            c0.05,0.17,0.43,0.37,0.46,0.55c0.02,0.15-0.13,0.45-0.24,0.55c-0.1,0.09-0.45,0.04-0.51,0.15c-0.21,0.35,0.13,1.26,0.36,1.61
            c0.12,0.18,0.62,0.32,0.73,0.52c0.17,0.3-0.04,1.05,0.09,1.36c0.11,0.28,0.69,0.63,0.79,0.91c0.1,0.29-0.13,0.95-0.03,1.24
            c0.07,0.2,0.45,0.47,0.51,0.67c0.09,0.25-0.07,0.8,0,1.06c0.08,0.31,0.33,1.03,0.64,1.12c0.18,0.06,0.51-0.29,0.7-0.33
            c0.34-0.08,1.11,0.2,1.4,0c0.25-0.18,0.35-0.87,0.39-1.18c0.06-0.44-0.16-1.32-0.12-1.76c0.04-0.42,0.27-1.23,0.39-1.64
            C236.265,561.2,236.775,560.59,236.655,560.28z M228.675,553.82c0.02-0.09-0.31-1.11-0.33-1.49c-0.02-0.43,0.47-1.38,0.21-1.73
            c-0.28-0.38-1.42-0.46-1.88-0.33c-0.24,0.07-0.57,0.49-0.76,0.67c-0.18,0.17-0.48,0.59-0.7,0.7c-0.2,0.1-0.69,0-0.88,0.12
            c-0.26,0.17-0.62,0.75-0.67,1.06c-0.03,0.22,0.06,0.7,0.21,0.85c0.14,0.14,0.66,0.02,0.79,0.18c0.18,0.23-0.05,0.89,0,1.18
            c0.06,0.3,0.21,0.96,0.46,1.15c0.18,0.15,0.71,0.04,0.91,0.15c0.33,0.19,0.62,1.17,1,1.15c0.33-0.02,0.54-0.82,0.76-1.06
            c0.18-0.19,0.74-0.42,0.82-0.67c0.1-0.29-0.33-0.88-0.3-1.18C228.335,554.37,228.635,554.02,228.675,553.82z M231.835,426.15
            c0.19,0.09,0.66,0.06,0.85-0.03c0.2-0.1,0.54-0.45,0.61-0.67c0.09-0.32-0.24-0.98-0.21-1.3c0.02-0.25,0.41-0.7,0.33-0.94
            c-0.05-0.16-0.42-0.26-0.55-0.36c-0.33-0.27-0.79-1.05-1.18-1.21c-0.29-0.12-0.97-0.1-1.27,0c-0.29,0.09-0.84,0.44-1,0.7
            c-0.1,0.16-0.18,0.59-0.09,0.76s0.58,0.2,0.67,0.36c0.06,0.11,0.03,0.4-0.06,0.48c-0.14,0.14-0.6,0.04-0.79,0.09
            c-0.16,0.04-0.49,0.15-0.61,0.27c-0.28,0.3-0.3,1.22-0.58,1.52c-0.16,0.17-0.66,0.28-0.88,0.39c-0.15,0.08-0.51,0.18-0.58,0.33
            c-0.11,0.25,0.31,0.81,0.21,1.06c-0.08,0.19-0.59,0.27-0.67,0.45c-0.13,0.32,0.03,1.11,0.27,1.36c0.21,0.21,0.88,0.15,1.15,0.27
            c0.27,0.12,0.68,0.78,0.97,0.7c0.37-0.11,0.55-1.04,0.58-1.42c0.02-0.33-0.1-1.01-0.3-1.27c-0.12-0.15-0.58-0.2-0.67-0.36
            c-0.11-0.2-0.04-0.72,0.09-0.91c0.18-0.26,0.84-0.4,1.12-0.55c0.26-0.13,0.74-0.51,1.03-0.55c0.29-0.04,0.88,0.12,1.12,0.27
            C231.555,425.7,231.675,426.08,231.835,426.15z M284.485,412.98c-0.12-0.2-0.64-0.26-0.87-0.3c-0.17-0.03-0.55-0.14-0.69-0.04
            c-0.22,0.15-0.37,0.77-0.32,1.03c0.04,0.21,0.42,0.48,0.51,0.67c0.08,0.18-0.03,0.64,0.12,0.77c0.16,0.14,0.66,0.08,0.85-0.02
            c0.25-0.14,0.6-0.65,0.63-0.93c0.02-0.18-0.26-0.49-0.28-0.67C284.405,413.36,284.555,413.09,284.485,412.98z M285.735,414.64
            c-0.12,0.18-0.17,0.68-0.06,0.87c0.13,0.24,0.7,0.44,0.97,0.51c0.21,0.05,0.68,0.11,0.87,0c0.16-0.09,0.25-0.49,0.34-0.65
            c0.16-0.25,0.73-0.67,0.71-0.97c-0.01-0.22-0.58-0.43-0.61-0.65c-0.02-0.23,0.49-0.57,0.48-0.81c0-0.23-0.28-0.68-0.48-0.77
            c-0.14-0.06-0.5,0.01-0.61,0.12c-0.14,0.15-0.02,0.64-0.14,0.81c-0.09,0.12-0.44,0.18-0.53,0.3c-0.11,0.17,0.02,0.64-0.1,0.81
            C286.445,414.41,285.865,414.44,285.735,414.64z M290.285,417.87c0.13-0.27-0.17-0.9-0.1-1.19c0.06-0.26,0.43-0.68,0.53-0.93
            s0.27-0.79,0.22-1.05c-0.04-0.25-0.29-0.77-0.53-0.85c-0.28-0.1-0.94,0.14-1.11,0.38c-0.14,0.2,0.1,0.73,0.02,0.95
            c-0.07,0.21-0.45,0.49-0.55,0.69c-0.1,0.23-0.26,0.73-0.18,0.97c0.08,0.24,0.57,0.49,0.73,0.69c0.13,0.16,0.21,0.66,0.4,0.71
            C289.885,418.27,290.215,418.02,290.285,417.87z M303.145,436.39c0.13-0.11,0.53-0.14,0.63-0.28c0.14-0.21,0.19-0.83,0.02-1.01
            c-0.12-0.13-0.56-0.11-0.73-0.04c-0.15,0.07-0.3,0.41-0.42,0.53c-0.15,0.15-0.54,0.36-0.67,0.52c-0.12,0.16-0.38,0.55-0.32,0.75
            c0.04,0.15,0.33,0.35,0.48,0.36c0.18,0.02,0.51-0.21,0.65-0.32C302.895,436.79,303.025,436.48,303.145,436.39z M303.885,438.22
            c-0.21-0.09-0.7-0.22-0.91-0.12s-0.53,0.54-0.51,0.77c0.03,0.27,0.54,0.64,0.79,0.75c0.15,0.06,0.51,0.08,0.67,0.02
            c0.18-0.07,0.52-0.32,0.57-0.51c0.04-0.14-0.1-0.44-0.18-0.57C304.235,438.46,304.015,438.28,303.885,438.22z" pointer-events="auto" class="landarea"></path>

            <rect id="rect12_13" x="240.005" y="474.26" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.84" height="19.63" pointer-events="auto" visibility="hidden"></rect>
    </g>
    <g id="AREA_ITALY_SICILIA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Sicily" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_ITALY_SICILIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <path id="ITALY_SICILIA" fill="#1e8346" stroke="#7eb08e" stroke-width="0.5" d="M597.355,693.89
            c-0.25,0.32-0.56,1.09-0.81,1.41c-0.36,0.48-1.41,1.17-1.7,1.7c-0.26,0.47-0.27,1.59-0.45,2.1c-0.2,0.57-0.92,1.59-1.05,2.18
            c-0.11,0.51-0.02,1.58,0.04,2.1c0.09,0.78,0.34,2.35,0.65,3.07c0.13,0.3,0.63,0.78,0.73,1.09c0.13,0.42-0.1,1.32,0,1.74
            c0.09,0.39,0.62,1.03,0.73,1.41c0.12,0.45,0.42,1.51,0.12,1.86c-0.24,0.28-1.16-0.1-1.46,0.12c-0.28,0.22-0.17,1.18-0.48,1.33
            c-0.3,0.15-0.99-0.25-1.29-0.4c-0.36-0.18-1-0.69-1.29-0.97c-0.41-0.4-0.91-1.51-1.41-1.78c-0.43-0.23-1.47-0.27-1.94-0.16
            c-0.47,0.11-1.18,0.96-1.66,0.97c-0.42,0.01-1.17-0.54-1.5-0.81c-0.34-0.28-0.74-1.17-1.13-1.38c-0.53-0.27-1.79-0.25-2.39-0.16
            c-0.28,0.04-0.79,0.3-1.05,0.4c-0.43,0.17-1.3,0.46-1.7,0.69c-0.34,0.19-0.87,0.84-1.25,0.93c-0.46,0.11-1.41-0.19-1.86-0.32
            c-0.43-0.13-1.22-0.61-1.66-0.69c-0.56-0.1-1.72,0.23-2.26,0.08c-0.27-0.07-0.75-0.4-0.93-0.61c-0.33-0.38-0.47-1.49-0.85-1.82
            c-0.45-0.38-1.69-0.52-2.26-0.65c-0.58-0.13-1.76-0.28-2.34-0.4c-0.46-0.1-1.36-0.39-1.82-0.48c-0.51-0.1-1.6-0.04-2.06-0.28
            c-0.2-0.1-0.43-0.51-0.61-0.65c-0.4-0.32-1.51-0.6-1.74-1.05c-0.13-0.26,0.19-0.85,0.12-1.13c-0.11-0.48-0.82-1.22-1.05-1.66
            c-0.36-0.7-0.74-2.28-1.21-2.91c-0.27-0.35-1.1-0.75-1.37-1.09c-0.3-0.37-0.69-1.25-0.85-1.7c-0.13-0.38-0.11-1.24-0.32-1.58
            c-0.38-0.6-1.72-1.28-2.26-1.74c-0.62-0.51-1.71-1.71-2.39-2.14c-0.37-0.24-1.23-0.51-1.62-0.73c-0.49-0.27-1.35-1.03-1.86-1.25
            c-0.53-0.24-1.71-0.39-2.26-0.57c-0.53-0.16-1.51-0.72-2.06-0.77c-0.55-0.05-1.63,0.43-2.18,0.4c-0.66-0.04-1.89-0.65-2.55-0.77
            c-0.81-0.15-2.46-0.34-3.27-0.24c-0.68,0.08-1.95,0.73-2.63,0.81c-0.54,0.06-1.64-0.24-2.18-0.16c-0.59,0.09-1.63,0.81-2.22,0.85
            c-0.43,0.03-1.38-0.1-1.7-0.4c-0.3-0.29-0.16-1.31-0.45-1.62c-0.29-0.32-1.25-0.37-1.62-0.61c-0.26-0.17-0.61-0.74-0.89-0.89
            c-0.56-0.3-1.87-0.42-2.51-0.48c-0.32-0.03-1,0.14-1.29,0c-0.45-0.21-0.87-1.25-1.29-1.5c-0.51-0.29-1.77-0.2-2.3-0.45
            c-0.52-0.24-1.45-0.98-1.78-1.46c-0.18-0.27-0.14-1.05-0.4-1.25c-0.13-0.1-0.5,0.03-0.65-0.04c-0.35-0.18-0.7-0.93-0.97-1.21
            c-0.54-0.58-1.62-1.81-2.35-2.14c-0.39-0.18-1.28-0.12-1.7-0.2c-0.42-0.09-1.23-0.4-1.66-0.48c-0.41-0.08-1.27-0.01-1.66-0.16
            c-0.3-0.12-0.75-0.64-1.05-0.77c-0.37-0.15-1.21-0.09-1.58-0.24c-0.36-0.15-0.92-0.72-1.25-0.93c-0.65-0.41-2.05-1.05-2.71-1.46
            c-0.37-0.22-1.08-0.7-1.42-0.97c-0.42-0.35-1.06-1.28-1.54-1.54c-0.24-0.13-0.83-0.05-1.05-0.2c-0.4-0.28-0.64-1.33-0.97-1.7
            c-0.35-0.39-1.3-0.91-1.7-1.25c-0.39-0.33-1.14-1.02-1.45-1.41c-0.26-0.33-0.5-1.22-0.85-1.46c-0.48-0.32-1.75-0.14-2.3-0.32
            c-0.25-0.09-0.68-0.43-0.93-0.53c-0.66-0.24-2.05-0.6-2.75-0.53c-0.72,0.08-1.94,1.21-2.67,1.17c-0.36-0.02-1.03-0.44-1.25-0.73
            c-0.24-0.3-0.19-1.17-0.45-1.46c-0.38-0.41-1.69-0.43-2.06-0.85c-0.21-0.24-0.12-0.97-0.32-1.21c-0.32-0.39-1.33-0.73-1.82-0.85
            c-0.41-0.1-1.27-0.07-1.7-0.08c-0.72-0.01-2.15-0.01-2.87,0.04c-0.67,0.04-2,0.28-2.67,0.32c-0.57,0.04-1.71-0.07-2.26,0.04
            c-0.86,0.17-2.4,1.23-3.27,1.29c-0.55,0.04-1.68-0.22-2.14-0.53c-0.64-0.42-1.47-1.78-1.82-2.47c-0.24-0.46-0.35-1.56-0.65-1.98
            c-0.18-0.26-0.74-0.6-1.01-0.77c-0.4-0.24-1.24-0.7-1.7-0.81c-0.45-0.11-1.44,0.16-1.86-0.04c-0.21-0.1-0.44-0.56-0.61-0.73
            c-0.41-0.41-1.51-0.98-1.78-1.5c-0.18-0.35-0.08-1.19-0.16-1.58c-0.11-0.53-0.46-1.56-0.65-2.06c-0.18-0.47-0.52-1.44-0.81-1.86
            c-0.18-0.26-0.76-0.6-0.89-0.89c-0.11-0.23-0.19-0.78-0.08-1.01c0.13-0.28,0.76-0.52,1.01-0.69c0.24-0.16,0.83-0.39,0.97-0.65
            c0.23-0.41,0.04-1.4,0.08-1.86c0.04-0.44,0.33-1.32,0.2-1.74c-0.09-0.29-0.66-0.66-0.81-0.93c-0.13-0.24-0.34-0.79-0.28-1.05
            s0.47-0.65,0.69-0.81c0.13-0.09,0.51-0.11,0.61-0.24c0.14-0.19,0.16-0.71,0.08-0.93c-0.07-0.19-0.59-0.36-0.61-0.57
            c-0.01-0.14,0.31-0.31,0.36-0.45c0.08-0.2-0.03-0.67,0.08-0.85c0.1-0.16,0.55-0.24,0.65-0.4c0.11-0.17,0.18-0.63,0.08-0.81
            c-0.12-0.21-0.78-0.18-0.89-0.4c-0.11-0.22,0.34-0.74,0.24-0.97s-0.84-0.26-0.85-0.51c-0.01-0.35,0.86-0.61,1.17-0.77
            c0.33-0.17,1.07-0.38,1.39-0.57c0.2-0.11,0.56-0.4,0.71-0.57c0.26-0.29,0.55-1.06,0.85-1.31c0.33-0.28,1.17-0.64,1.6-0.67
            c0.32-0.02,0.92,0.38,1.23,0.32c0.38-0.06,1.03-0.56,1.29-0.85c0.25-0.28,0.51-0.99,0.69-1.31c0.13-0.24,0.33-0.78,0.55-0.95
            c0.19-0.15,0.7-0.33,0.94-0.27c0.25,0.05,0.51,0.65,0.76,0.67c0.37,0.03,1.08-0.46,1.24-0.79c0.11-0.23-0.16-0.75-0.12-1
            c0.04-0.31,0.51-0.84,0.48-1.15s-0.61-0.73-0.67-1.03c-0.08-0.4,0.08-1.28,0.33-1.61c0.17-0.22,0.72-0.52,1-0.48
            c0.24,0.03,0.55,0.48,0.76,0.61c0.27,0.17,1,0.26,1.15,0.55c0.16,0.3-0.29,1.01-0.21,1.33c0.08,0.35,0.7,0.83,0.85,1.15
            c0.26,0.57,0.21,1.91,0.52,2.46c0.33,0.58,1.41,1.42,1.94,1.82c0.33,0.24,1.1,0.57,1.42,0.82c0.48,0.36,1.17,1.46,1.73,1.67
            c0.49,0.18,1.59,0.02,2.09-0.12c0.35-0.1,0.92-0.6,1.27-0.7c0.31-0.09,1.01,0.09,1.3-0.06c0.3-0.16,0.57-0.84,0.82-1.06
            c0.5-0.45,1.71-1.04,2.27-1.4c0.4-0.25,1.33-0.63,1.58-1.03c0.27-0.44,0.32-1.55,0.21-2.06c-0.06-0.31-0.53-0.79-0.61-1.09
            c-0.05-0.2-0.1-0.64,0-0.82c0.09-0.16,0.55-0.2,0.64-0.36c0.13-0.26-0.23-0.87-0.15-1.15c0.09-0.35,0.6-0.92,0.88-1.15
            c0.29-0.24,0.97-0.6,1.33-0.7c0.34-0.1,1.07-0.11,1.42-0.09c0.6,0.04,1.76,0.44,2.36,0.46c0.73,0.02,2.25-0.08,2.91-0.39
            c0.5-0.24,1.22-1.12,1.61-1.52c0.28-0.29,0.72-1.03,1.09-1.18c0.44-0.18,1.55-0.25,1.91,0.06c0.23,0.2,0.11,0.91,0.24,1.18
            c0.13,0.26,0.53,0.7,0.76,0.88c0.27,0.21,1.01,0.36,1.21,0.64c0.4,0.55,0.28,2.04,0.45,2.7c0.13,0.51,0.35,1.58,0.7,1.97
            c0.2,0.22,0.79,0.47,1.09,0.52c0.37,0.06,1.11-0.19,1.49-0.18c0.56,0.01,1.67,0.46,2.21,0.33c0.34-0.08,0.94-0.54,1.15-0.82
            c0.17-0.22,0.14-0.94,0.39-1.06c0.23-0.11,0.72,0.27,0.97,0.3c0.19,0.03,0.61-0.11,0.76,0c0.29,0.22,0.33,1.06,0.39,1.42
            c0.09,0.55-0.12,1.74,0.12,2.24c0.18,0.39,0.89,0.97,1.27,1.15c0.44,0.21,1.48,0.07,1.91,0.27c0.63,0.3,1.45,1.5,2.03,1.88
            c0.51,0.33,1.66,0.72,2.21,0.97c0.78,0.35,2.29,1.24,3.12,1.42c0.84,0.19,2.58,0.19,3.43,0.03c0.66-0.12,1.91-0.69,2.52-1
            c0.37-0.19,1.08-0.63,1.4-0.91c0.29-0.26,0.69-0.95,1-1.18c0.41-0.31,1.39-0.63,1.85-0.85c0.38-0.19,1.09-0.81,1.52-0.79
            c0.28,0.01,0.66,0.58,0.94,0.61c0.23,0.02,0.64-0.27,0.85-0.36c0.31-0.14,0.88-0.52,1.21-0.58c0.27-0.05,0.84,0.02,1.09,0.12
            c0.35,0.15,0.83,0.83,1.18,0.97c0.57,0.23,1.86,0.37,2.46,0.21c0.35-0.09,0.85-0.67,1.18-0.82c0.29-0.13,0.92-0.29,1.24-0.27
            c0.61,0.03,1.73,0.64,2.34,0.76c0.58,0.11,1.78,0.25,2.36,0.18c0.65-0.07,1.87-0.65,2.52-0.76c0.76-0.12,2.32,0.08,3.06-0.09
            c0.73-0.16,2.03-0.94,2.73-1.18c0.45-0.16,1.37-0.44,1.85-0.48c0.74-0.07,2.23,0.28,2.97,0.18c0.86-0.12,2.5-0.76,3.27-1.15
            c0.28-0.14,0.73-0.6,1.03-0.7c0.29-0.09,0.91,0.03,1.21-0.03c0.39-0.07,1.13-0.37,1.49-0.55c0.69-0.34,2.05-1.13,2.61-1.67
            c0.49-0.47,1.18-1.67,1.55-2.24c0.37-0.59,0.79-2.07,1.4-2.42c0.59-0.35,2.05-0.07,2.73-0.06c0.57,0.01,1.72,0.24,2.27,0.12
            c0.65-0.14,1.82-0.81,2.36-1.18c0.48-0.32,1.19-1.31,1.73-1.52c0.38-0.14,1.24-0.16,1.61,0c0.52,0.22,1.05,1.37,1.55,1.64
            c0.54,0.3,1.82,0.36,2.43,0.46c0.49,0.08,1.52,0.04,1.97,0.24c0.45,0.21,1.02,1.15,1.49,1.33c0.43,0.17,1.4,0.18,1.85,0.06
            c0.82-0.23,2.22-1.28,2.85-1.85c0.61-0.56,1.74-1.84,2.06-2.61c0.35-0.82,0.52-2.65,0.48-3.55c-0.02-0.45-0.21-1.35-0.39-1.76
            c-0.11-0.25-0.69-0.61-0.64-0.88c0.04-0.23,0.56-0.46,0.79-0.48c0.37-0.04,1.13,0.22,1.4,0.48c0.34,0.33,0.58,1.32,0.64,1.79
            c0.06,0.46-0.28,1.4-0.18,1.85c0.04,0.2,0.23,0.59,0.39,0.7c0.44,0.29,1.57,0.33,2.09,0.24c0.68-0.11,1.96-0.75,2.52-1.15
            c0.54-0.39,1.45-1.39,1.82-1.94c0.21-0.31,0.35-1.11,0.64-1.36c0.17-0.15,0.63-0.25,0.85-0.27c0.46-0.05,1.4,0.34,1.85,0.21
            c0.65-0.19,1.68-1.16,2.09-1.7c0.26-0.34,0.35-1.3,0.7-1.55c0.19-0.13,0.71,0.01,0.94-0.06c0.32-0.09,0.82-0.64,1.15-0.64
            c0.28,0,0.71,0.48,0.97,0.58c0.35,0.13,1.12,0.17,1.49,0.24c0.75,0.15,2.45,0.12,2.97,0.67c0.12,0.13,0.19,0.53,0.12,0.7
            c-0.22,0.53-1.4,0.98-1.85,1.33c-0.47,0.36-1.48,1.03-1.82,1.52c-0.31,0.44-0.74,1.49-0.76,2.03c-0.02,0.44,0.58,1.27,0.48,1.7
            c-0.1,0.48-1.03,1.07-1.24,1.52c-0.2,0.43-0.27,1.41-0.36,1.88c-0.17,0.81-0.31,2.51-0.7,3.24c-0.15,0.29-0.72,0.67-0.91,0.94
            c-0.44,0.64-0.89,2.16-1.3,2.82c-0.35,0.54-1.34,1.41-1.67,1.97c-0.15,0.25-0.25,0.84-0.39,1.09c-0.36,0.63-1.44,1.64-1.88,2.21
            c-0.37,0.48-1.08,1.47-1.36,2c-0.3,0.58-0.65,1.84-0.94,2.42c-0.2,0.41-0.78,1.12-0.94,1.55c-0.15,0.4-0.03,1.34-0.27,1.7
            c-0.22,0.33-1.06,0.54-1.33,0.82c-0.32,0.33-0.67,1.21-0.91,1.61c-0.32,0.53-1.04,1.52-1.33,2.06c-0.28,0.53-0.91,1.59-0.97,2.18
            c-0.04,0.43,0.31,1.27,0.36,1.7c0.05,0.44,0.12,1.35,0.03,1.79c-0.08,0.39-0.55,1.07-0.64,1.46c-0.17,0.81,0.02,2.48,0,3.3
            c-0.02,0.86,0.25,2.67-0.15,3.43c-0.17,0.32-0.91,0.61-1.15,0.88c-0.29,0.32-0.68,1.09-0.91,1.46c-0.17,0.27-0.44,0.86-0.67,1.09
            c-0.24,0.25-1.02,0.42-1.18,0.73c-0.23,0.42,0.22,1.47,0.03,1.91c-0.07,0.16-0.47,0.31-0.52,0.48c-0.07,0.26,0.21,0.77,0.33,1
            c0.19,0.35,0.68,0.98,0.97,1.24c0.22,0.19,0.88,0.34,1,0.61c0.14,0.3-0.04,1.02-0.21,1.3c-0.17,0.27-0.88,0.48-1.03,0.76
            c-0.11,0.21-0.04,0.71-0.06,0.94c-0.04,0.54-0.19,1.61-0.15,2.15c0.04,0.57,0.33,1.69,0.46,2.24c0.07,0.32,0.12,1.03,0.33,1.27
            c0.3,0.34,1.26,0.48,1.67,0.67c0.56,0.25,1.6,0.91,2.18,1.09c0.28,0.09,0.91,0.32,1.15,0.15c0.2-0.14-0.01-0.84,0.21-0.94
            c0.26-0.12,0.79,0.33,1,0.52c0.31,0.26,0.71,0.99,0.97,1.3c0.35,0.41,1.36,1.05,1.49,1.58c0.09,0.37-0.23,1.15-0.46,1.46
            c-0.25,0.34-0.95,0.93-1.36,0.97c-0.32,0.03-1.01-0.26-1.15-0.55c-0.12-0.24,0.41-0.92,0.18-1.06c-0.27-0.17-0.89,0.45-1.03,0.73
            c-0.24,0.48-0.08,1.63,0.06,2.15c0.11,0.4,0.53,1.13,0.79,1.46c0.16,0.2,0.52,0.59,0.76,0.67c0.23,0.07,0.77-0.25,0.97-0.12
            c0.17,0.11,0.28,0.59,0.24,0.79c-0.04,0.19-0.44,0.42-0.48,0.61c-0.05,0.23,0.07,0.73,0.21,0.91c0.37,0.46,1.54,0.98,2.12,1
            c0.28,0.01,0.78-0.44,1.06-0.39c0.33,0.05,0.88,0.54,1.06,0.82c0.13,0.21,0.13,0.74,0.21,0.97c0.13,0.37,0.73,1.01,0.7,1.4
            c-0.02,0.28-0.37,0.83-0.64,0.91c-0.24,0.07-0.68-0.31-0.91-0.39c-0.13-0.05-0.43-0.24-0.55-0.15c-0.18,0.14-0.11,0.71,0,0.91
            c0.14,0.26,0.68,0.61,0.97,0.64c0.33,0.03,0.92-0.58,1.24-0.48c0.21,0.06,0.38,0.55,0.52,0.73c0.18,0.23,0.77,0.59,0.76,0.88
            c-0.01,0.26-0.5,0.69-0.76,0.73c-0.33,0.04-0.85-0.68-1.18-0.64c-0.24,0.03-0.52,0.54-0.73,0.67c-0.23,0.13-0.84,0.13-1,0.33
            c-0.17,0.21-0.06,0.82-0.12,1.09c-0.07,0.34-0.17,1.07-0.42,1.3c-0.22,0.21-0.91,0.15-1.18,0.27c-0.48,0.21-1.33,0.85-1.73,1.18
            C597.775,693.45,597.475,693.73,597.355,693.89z M428.475,714.99c0.06-0.22,0.23-0.68,0.18-0.91c-0.07-0.35-0.56-0.93-0.82-1.18
            c-0.25-0.24-0.89-0.5-1.15-0.73c-0.24-0.21-0.53-0.84-0.82-0.97c-0.19-0.09-0.62,0.06-0.82,0c-0.46-0.15-1.3-0.74-1.55-1.15
            c-0.17-0.28-0.22-0.98-0.18-1.3c0.02-0.14,0.23-0.38,0.21-0.52c-0.03-0.23-0.34-0.65-0.55-0.76c-0.28-0.15-1.03-0.24-1.27-0.03
            c-0.26,0.22-0.22,1-0.18,1.33c0.02,0.2,0.26,0.56,0.27,0.76c0.02,0.23-0.17,0.68-0.18,0.91c-0.03,0.41,0.01,1.24,0.09,1.64
            c0.08,0.37,0.29,1.12,0.52,1.42c0.19,0.26,0.89,0.47,1.03,0.76c0.15,0.31-0.24,1.08-0.06,1.36c0.11,0.17,0.6,0.15,0.73,0.3
            c0.15,0.18,0.11,0.7,0.21,0.91c0.14,0.29,0.92,0.68,1.23,0.77c0.45,0.14,1.11,0.12,1.5-0.14c0.42-0.28,1.03-1.15,1.3-1.58
            C428.305,715.7,428.415,715.23,428.475,714.99z M429.445,635.17c-0.19-0.23-0.95-0.11-1.15-0.33c-0.2-0.21-0.09-0.88-0.24-1.12
            c-0.11-0.17-0.57-0.3-0.67-0.48c-0.12-0.23,0.17-0.84,0-1.03c-0.22-0.25-1-0.21-1.33-0.18s-1.15,0.08-1.27,0.39
            c-0.09,0.23,0.5,0.6,0.52,0.85s-0.49,0.7-0.39,0.94c0.12,0.29,0.85,0.43,1.15,0.52c0.21,0.06,0.67-0.03,0.85,0.09
            c0.2,0.15,0.24,0.74,0.42,0.91c0.26,0.25,0.97,0.56,1.33,0.55c0.24-0.01,0.73-0.22,0.85-0.42
            C429.595,635.69,429.555,635.29,429.445,635.17z M440.395,634.8c-0.26-0.12-0.91,0.16-1.15,0c-0.18-0.12-0.12-0.75-0.33-0.82
            c-0.23-0.07-0.63,0.34-0.79,0.52c-0.08,0.08-0.12,0.33-0.21,0.39c-0.18,0.12-0.78-0.11-0.88,0.09c-0.11,0.23,0.49,0.63,0.55,0.88
            c0.04,0.18-0.21,0.59-0.09,0.73c0.22,0.27,1.05,0.16,1.39,0.09c0.27-0.05,0.7-0.53,0.97-0.48c0.29,0.04,0.65,0.6,0.85,0.82
            c0.09,0.09,0.21,0.36,0.33,0.39c0.37,0.09,1.26-0.25,1.39-0.61c0.12-0.32-0.29-1.02-0.55-1.24c-0.21-0.18-0.83-0.13-1.06-0.27
            C440.685,635.2,440.535,634.87,440.395,634.8z M441.155,630.62c-0.15-0.19-0.78,0.06-0.94-0.12c-0.12-0.14,0.04-0.55,0-0.73
            c-0.03-0.13-0.11-0.45-0.24-0.48c-0.22-0.07-0.66,0.27-0.79,0.46c-0.18,0.27-0.23,0.96-0.18,1.27c0.04,0.22,0.22,0.66,0.39,0.79
            c0.22,0.16,0.8,0.26,1.06,0.18c0.2-0.06,0.51-0.39,0.61-0.58C441.145,631.23,441.275,630.77,441.155,630.62z M590.375,569.83
            c0.12-0.15,0.47-0.36,0.53-0.55c0.07-0.24-0.02-0.81-0.2-0.99c-0.13-0.12-0.51-0.1-0.69-0.12s-0.58-0.14-0.73-0.04
            c-0.18,0.12-0.13,0.66-0.26,0.83c-0.12,0.16-0.55,0.28-0.67,0.45c-0.13,0.19-0.29,0.69-0.18,0.89c0.08,0.14,0.48,0.14,0.61,0.24
            c0.17,0.13,0.27,0.69,0.48,0.71c0.19,0.02,0.41-0.4,0.53-0.55C589.955,570.49,590.215,570.03,590.375,569.83z M583.555,580.8
            c0-0.23-0.3-0.67-0.52-0.76c-0.18-0.07-0.57,0.16-0.76,0.15c-0.14-0.01-0.39-0.24-0.52-0.18c-0.2,0.1-0.24,0.63-0.27,0.85
            c-0.03,0.17-0.07,0.54,0,0.7c0.08,0.17,0.37,0.44,0.55,0.48c0.25,0.07,0.84-0.04,1.03-0.21c0.11-0.1,0.12-0.44,0.18-0.58
            C583.315,581.13,583.555,580.94,583.555,580.8z M572.535,586.97c0-0.29,0.22-0.85,0.16-1.13c-0.04-0.2-0.22-0.61-0.4-0.69
            c-0.27-0.12-0.87,0.27-1.17,0.24c-0.28-0.03-0.77-0.46-1.05-0.45c-0.23,0.01-0.67,0.26-0.81,0.45c-0.2,0.27-0.45,1.01-0.28,1.29
            c0.16,0.28,0.95,0.22,1.21,0.4c0.23,0.17,0.46,0.75,0.69,0.93c0.2,0.15,0.68,0.39,0.93,0.36s0.74-0.33,0.85-0.57
            C572.745,587.62,572.535,587.18,572.535,586.97z M577.705,588.22c-0.25,0.03-0.6,0.51-0.85,0.57c-0.37,0.08-1.15-0.39-1.5-0.24
            c-0.37,0.15-0.81,0.91-0.93,1.29c-0.1,0.32-0.1,1.03,0.04,1.33c0.18,0.38,0.97,0.79,1.25,1.09c0.32,0.35,0.7,1.33,1.13,1.54
            c0.19,0.09,0.7,0.11,0.85-0.04c0.19-0.19,0.07-0.82,0.04-1.09c-0.03-0.3-0.38-0.89-0.28-1.17c0.1-0.31,0.94-0.49,1.01-0.81
            c0.08-0.35-0.64-0.93-0.65-1.29c-0.01-0.27,0.55-0.72,0.45-0.97C578.215,588.28,577.855,588.2,577.705,588.22z M580.375,596.47
            c-0.27-0.27-1.21,0.37-1.54,0.16c-0.33-0.21-0.27-1.16-0.53-1.46c-0.15-0.17-0.59-0.51-0.81-0.45c-0.26,0.07-0.46,0.67-0.53,0.93
            c-0.09,0.36-0.11,1.16,0.04,1.5c0.14,0.3,0.71,0.68,0.97,0.89c0.26,0.21,0.77,0.67,1.09,0.77c0.43,0.14,1.5,0.32,1.82,0
            c0.13-0.13,0.06-0.56,0-0.73c-0.08-0.23-0.54-0.53-0.61-0.77C580.235,597.11,580.525,596.62,580.375,596.47z M559.435,586.64
            c-0.18-0.24-0.94,0.14-1.21,0c-0.29-0.15-0.48-0.9-0.77-1.05c-0.25-0.13-0.89-0.18-1.13-0.04c-0.22,0.13-0.47,0.64-0.48,0.89
            c-0.02,0.34,0.26,1.01,0.48,1.25c0.3,0.32,1.27,0.82,1.66,0.61c0.12-0.06,0.02-0.43,0.12-0.53c0.19-0.19,0.85-0.02,1.05-0.2
            C559.335,587.41,559.575,586.84,559.435,586.64z M546.615,588.42c-0.09-0.26-0.56-0.63-0.81-0.73c-0.24-0.1-0.82-0.16-1.05-0.04
            c-0.23,0.11-0.57,0.59-0.61,0.85c-0.05,0.39,0.28,1.2,0.57,1.46c0.3,0.26,1.2,0.61,1.54,0.4c0.25-0.16,0.19-0.88,0.24-1.17
            C546.535,589,546.685,588.61,546.615,588.42z" pointer-events="auto" class="landarea"></path>

            <rect id="rect12_14" x="515.815" y="642.9" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.84" height="19.63" pointer-events="auto" visibility="hidden"></rect>
    </g>
    <g id="AREA_ITALY_TOSCANA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Tuscany" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_ITALY_TOSCANA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <path id="ITALY_TOSCANA" fill="#1e8346" stroke="#7eb08e" stroke-width="0.5" d="M371.685,344.16c-0.04,0.21,0.35,0.67,0.2,0.83
            c-0.18,0.2-0.88,0.05-1.07-0.14c-0.15-0.14-0.05-0.62-0.12-0.81s-0.39-0.49-0.4-0.69c-0.01-0.21,0.17-0.63,0.32-0.77
            c0.13-0.11,0.48-0.21,0.65-0.2c0.24,0.01,0.76,0.18,0.89,0.38c0.11,0.17,0.05,0.62-0.02,0.81
            C372.065,343.74,371.715,343.98,371.685,344.16z M362.475,336.95c-0.08-0.31-0.06-0.99-0.21-1.27c-0.14-0.25-0.6-0.61-0.85-0.76
            c-0.19-0.11-0.63-0.34-0.85-0.3c-0.13,0.02-0.38,0.2-0.42,0.33c-0.06,0.2,0.33,0.6,0.24,0.79c-0.1,0.21-0.75,0.14-0.88,0.33
            c-0.14,0.21-0.11,0.79,0.03,1c0.15,0.22,0.8,0.24,1,0.42c0.26,0.24,0.41,1,0.67,1.24c0.27,0.26,1.02,0.79,1.36,0.64
            c0.28-0.12,0.37-0.85,0.36-1.15C362.925,337.89,362.555,337.28,362.475,336.95z M334.445,338.54c0-0.34-0.25-1.02-0.48-1.25
            c-0.2-0.2-0.77-0.41-1.05-0.4s-0.83,0.24-1.01,0.45c-0.09,0.09-0.12,0.37-0.16,0.48c-0.11,0.29-0.6,0.85-0.48,1.13
            c0.08,0.19,0.56,0.25,0.75,0.32c0.16,0.07,0.51,0.14,0.65,0.24c0.13,0.09,0.26,0.42,0.4,0.46c0.24,0.07,0.77-0.1,0.95-0.26
            C334.235,339.5,334.455,338.85,334.445,338.54z M322.465,321.09c-0.03-0.29-0.57-0.69-0.65-0.97c-0.08-0.27,0.19-0.89,0.04-1.13
            c-0.13-0.21-0.65-0.42-0.89-0.4c-0.18,0.02-0.54,0.24-0.61,0.4c-0.12,0.29,0.25,0.9,0.24,1.21c-0.01,0.29-0.05,0.95-0.28,1.13
            c-0.14,0.11-0.58-0.11-0.73,0c-0.17,0.13-0.32,0.61-0.24,0.81c0.13,0.34,0.89,0.73,1.25,0.77s1.11-0.27,1.37-0.52
            C322.225,322.14,322.505,321.44,322.465,321.09z M338.585,308.12c-0.26-0.36-0.39-1.32-0.33-1.76c0.06-0.46,0.74-1.21,0.79-1.67
            c0.04-0.4-0.2-1.22-0.39-1.58c-0.13-0.25-0.57-0.63-0.76-0.85c-0.18-0.21-0.42-0.84-0.7-0.85c-0.3-0.01-0.7,0.6-0.82,0.88
            c-0.09,0.22,0.06,0.72-0.03,0.94c-0.08,0.21-0.51,0.49-0.61,0.7c-0.07,0.14-0.01,0.51-0.12,0.61c-0.1,0.08-0.4-0.04-0.52,0.03
            c-0.16,0.09-0.33,0.44-0.39,0.61c-0.11,0.29,0,0.95-0.15,1.21c-0.13,0.24-0.57,0.69-0.85,0.7c-0.22,0.01-0.58-0.33-0.7-0.52
            c-0.13-0.19-0.07-0.7-0.21-0.88c-0.15-0.19-0.67-0.3-0.88-0.42c-0.19-0.11-0.56-0.35-0.7-0.52c-0.22-0.27-0.25-1.14-0.58-1.24
            c-0.21-0.07-0.65,0.26-0.76,0.45c-0.09,0.17,0.02,0.59,0.03,0.79c0.02,0.33,0.2,0.98,0.12,1.3c-0.05,0.2-0.35,0.51-0.42,0.7
            c-0.07,0.18,0.02,0.66-0.15,0.76c-0.36,0.2-1.2-0.33-1.55-0.55c-0.37-0.23-0.82-1.14-1.24-1.24c-0.26-0.06-0.81,0.17-1.03,0.33
            c-0.14,0.11-0.26,0.5-0.42,0.58c-0.29,0.14-0.96-0.1-1.27-0.03c-0.33,0.07-0.95,0.36-1.21,0.58c-0.34,0.28-0.97,0.99-1.06,1.42
            c-0.08,0.38,0.1,1.21,0.33,1.52c0.27,0.34,1.21,0.52,1.55,0.79c0.36,0.29,0.71,1.32,1.15,1.42c0.32,0.08,0.88-0.55,1.21-0.55
            c0.27,0,0.73,0.39,1,0.45c0.34,0.08,1.13,0.25,1.39,0.03c0.24-0.2-0.01-1.02,0.21-1.24c0.18-0.18,0.75-0.27,1-0.21
            c0.36,0.08,0.81,0.89,1.18,0.88c0.27-0.01,0.73-0.45,0.85-0.7c0.1-0.21-0.14-0.75,0-0.94c0.11-0.15,0.54-0.3,0.7-0.21
            c0.14,0.08,0.13,0.45,0.15,0.61c0.02,0.17-0.12,0.55,0,0.67c0.11,0.1,0.49,0.04,0.61-0.06c0.15-0.13,0.18-0.56,0.21-0.76
            c0.02-0.13-0.09-0.42,0-0.52c0.12-0.13,0.53-0.11,0.7-0.06c0.21,0.06,0.63,0.32,0.73,0.52c0.13,0.25-0.06,0.85,0,1.12
            c0.1,0.42,0.39,1.31,0.76,1.55c0.28,0.18,1.04-0.13,1.33,0.03c0.17,0.09,0.27,0.53,0.42,0.64c0.18,0.12,0.67,0.3,0.85,0.18
            c0.36-0.23,0.52-1.26,0.39-1.67c-0.11-0.36-0.89-0.73-1.12-1.03c-0.17-0.22-0.53-0.7-0.52-0.97c0.01-0.2,0.29-0.54,0.45-0.64
            c0.22-0.13,0.8-0.28,1-0.12s-0.09,0.83,0.09,1c0.12,0.11,0.51,0.09,0.67,0.03c0.21-0.08,0.57-0.4,0.67-0.61
            c0.11-0.23,0.16-0.81,0.03-1.03C339.525,308.45,338.765,308.37,338.585,308.12z M309.325,289.42c-0.12-0.3-0.29-1.01-0.57-1.17
            c-0.15-0.09-0.56-0.07-0.69,0.04c-0.18,0.16-0.06,0.76-0.18,0.97c-0.13,0.23-0.73,0.45-0.81,0.71c-0.1,0.3,0.37,0.91,0.28,1.21
            c-0.06,0.2-0.53,0.39-0.61,0.59c-0.08,0.23-0.04,0.77,0.1,0.97c0.12,0.17,0.58,0.27,0.75,0.38c0.16,0.11,0.38,0.5,0.57,0.51
            c0.27,0.01,0.73-0.43,0.87-0.67c0.15-0.24,0.13-0.84,0.2-1.11c0.09-0.33,0.46-0.93,0.46-1.27
            C309.715,290.27,309.435,289.7,309.325,289.42z M312.685,264.7c-0.2-0.1-0.72-0.06-0.89,0.08c-0.15,0.13-0.21,0.57-0.2,0.77
            c0.01,0.3,0.11,0.98,0.36,1.13c0.19,0.11,0.69-0.02,0.85-0.16c0.22-0.19,0.4-0.81,0.36-1.09
            C313.135,265.21,312.875,264.8,312.685,264.7z M412.165,303.87c0.42-0.63,0.47-2.25,0.85-2.91c0.27-0.47,1.27-1.09,1.52-1.58
            c0.19-0.37,0.3-1.22,0.3-1.64c0.02-0.87-0.13-2.66-0.48-3.46c-0.2-0.45-1.05-1.07-1.27-1.52c-0.18-0.36-0.17-1.22-0.36-1.58
            c-0.24-0.46-1.2-1.04-1.39-1.52c-0.22-0.52-0.1-1.68-0.12-2.24c-0.03-0.71-0.31-2.18-0.06-2.85c0.19-0.51,0.93-1.49,1.45-1.64
            c0.26-0.07,0.8,0.17,1.03,0.3c0.2,0.11,0.44,0.58,0.67,0.61c0.59,0.07,1.71-0.68,2.06-1.15s0.34-1.73,0.48-2.3
            c0.12-0.46,0.17-1.53,0.55-1.82c0.78-0.6,3.12,0.42,3.94-0.12c0.45-0.3,0.55-1.54,0.91-1.94c0.32-0.36,1.31-0.68,1.64-1.03
            c0.33-0.35,0.9-1.22,0.91-1.7c0.02-0.75-0.82-2.13-1.27-2.73c-0.4-0.52-1.4-1.42-2-1.7c-0.45-0.2-1.49-0.1-1.94-0.3
            s-1.27-0.85-1.52-1.27c-0.28-0.48-0.25-1.65-0.42-2.18c-0.17-0.51-0.9-1.4-0.91-1.94c-0.01-0.28,0.22-0.84,0.42-1.03
            c0.23-0.21,0.95-0.18,1.21-0.36c0.33-0.23,0.88-0.88,0.97-1.27c0.11-0.5-0.45-1.49-0.42-2c0.03-0.53,0.3-1.63,0.67-2
            c0.34-0.34,1.41-0.36,1.82-0.61c0.39-0.23,0.98-0.94,1.33-1.21c0.55-0.42,1.9-0.96,2.3-1.52c0.1-0.14,0.2-0.38,0.28-0.64
            c0.1-0.35,0.17-0.74,0.17-0.98c-0.01-0.44-0.52-1.24-0.53-1.68c-0.01-0.43,0.47-1.62,0.45-1.68s1.69-0.14,2.1-0.49
            c0.32-0.26,0.38-1.19,0.63-1.52c0.17-0.22,0.69-0.49,0.89-0.69c0.16-0.16,0.52-0.5,0.53-0.73c0.02-0.45-0.63-1.2-0.93-1.54
            c-0.23-0.26-0.81-0.66-1.09-0.87c-0.23-0.17-0.68-0.51-0.93-0.65c-0.34-0.19-1.04-0.53-1.41-0.61c-0.43-0.08-1.34-0.14-1.74,0.04
            c-0.25,0.11-0.49,0.68-0.73,0.81c-0.33,0.18-1.12,0.29-1.49,0.24c-0.24-0.03-0.57-0.26-0.9-0.46c-0.33-0.21-0.66-0.39-0.9-0.35
            c-0.36,0.07-0.7,0.88-1.03,1.05c-0.48,0.24-1.61,0.39-2.14,0.3c-0.41-0.07-1.11-0.59-1.49-0.75c-0.52-0.21-1.74-0.24-2.12-0.65
            c-0.4-0.41-0.15-1.83-0.57-2.22c-0.52-0.49-2.08-0.58-2.79-0.57c-0.54,0.01-1.56,0.46-2.1,0.5c-0.48,0.04-1.47,0.05-1.92-0.12
            c-0.56-0.21-1.54-0.96-1.9-1.43c-0.54-0.69-0.91-2.47-1.33-3.23c-0.34-0.6-1.12-1.77-1.62-2.24s-1.82-0.99-2.3-1.48
            c-0.35-0.35-0.96-1.18-1.09-1.66c-0.09-0.34-0.12-1.11,0.08-1.4c0.25-0.35,1.32-0.32,1.6-0.65c0.36-0.42,0.3-1.64,0.52-2.14
            c0.25-0.57,0.95-1.62,1.37-2.08c0.27-0.29,1.06-0.62,1.23-0.97c0.14-0.28,0.15-0.98,0.04-1.27c-0.14-0.37-0.75-0.93-1.09-1.13
            c-0.28-0.16-0.95-0.32-1.27-0.32c-0.31,0-0.97,0.1-1.21,0.3c-0.26,0.22-0.24,1.09-0.55,1.23c-0.41,0.19-1.38-0.21-1.72-0.51
            c-0.43-0.39-0.6-1.65-0.91-2.14c-0.32-0.51-1.03-1.53-1.56-1.82c-0.45-0.25-1.53-0.26-2.04-0.32c-0.49-0.06-1.49-0.01-1.96-0.16
            c-0.45-0.15-1.41-0.6-1.62-1.03s0.43-1.46,0.24-1.9c-0.15-0.37-0.88-0.89-1.27-0.97c-0.76-0.16-2.4,0.27-3.03,0.73
            c-0.36,0.26-0.54,1.28-0.91,1.52c-0.5,0.32-1.79,0.1-2.36,0.24c-0.49,0.12-1.55,0.35-1.88,0.73c-0.27,0.32-0.11,1.3-0.36,1.64
            c-0.21,0.28-0.98,0.41-1.21,0.67c-0.28,0.32-0.62,1.15-0.61,1.58c0.02,0.47,0.85,1.23,0.79,1.7c-0.05,0.41-0.65,1.13-1.03,1.27
            c-0.49,0.18-1.54-0.31-2.06-0.36c-0.59-0.06-1.77-0.1-2.36-0.06c-0.58,0.04-1.73,0.44-2.3,0.36c-0.48-0.06-1.37-0.51-1.76-0.79
            c-0.43-0.31-1.03-1.24-1.45-1.58c-0.37-0.29-1.17-0.86-1.64-0.91c-0.38-0.04-1.17,0.18-1.45,0.42c-0.4,0.34-0.56,1.49-0.91,1.88
            c-0.31,0.34-1.12,0.89-1.58,0.97c-0.55,0.09-1.69-0.22-2.18-0.49c-0.91-0.49-2.12-2.26-2.97-2.85c-0.55-0.38-1.76-1.05-2.42-1.15
            c-0.59-0.09-1.83,0.03-2.36,0.3c-0.41,0.21-0.88,1.1-1.27,1.33c-0.58,0.34-1.94,0.77-2.61,0.67c-0.76-0.12-1.9-1.34-2.61-1.64
            c-0.55-0.23-1.82-0.21-2.3-0.55c-0.7-0.49-1.27-2.26-1.88-2.85c-0.63-0.6-2.18-1.45-2.97-1.82c-0.62-0.29-2.11-0.38-2.61-0.85
            c-0.36-0.34-0.48-1.4-0.73-1.82c-0.23-0.4-0.69-1.49-1.15-1.46c-0.24,0.02-0.46,0.6-0.48,0.85c-0.02,0.27,0.37,0.76,0.36,1.03
            c-0.02,0.58-0.36,1.93-0.91,2.12c-0.45,0.16-1.39-0.54-1.7-0.91c-0.36-0.43-0.46-1.63-0.73-2.12c-0.39-0.72-1.36-2.09-2-2.61
            c-0.54-0.44-1.82-1.03-2.49-1.21c-0.81-0.23-2.52-0.11-3.33-0.3c-0.79-0.19-2.4-0.7-3.03-1.21c-0.32-0.26-0.8-0.99-0.91-1.4
            c-0.17-0.61,0.27-1.95,0.06-2.55c-0.14-0.4-0.71-1.1-1.09-1.27c-0.44-0.2-1.46,0.11-1.94,0.06c-0.54-0.05-1.63-0.25-2.12-0.49
            c-0.35-0.17-0.84-0.85-1.21-0.97c-0.52-0.17-1.68-0.15-2.18,0.06c-0.54,0.22-1.39,1.08-1.7,1.58c-0.29,0.47-0.34,1.64-0.61,2.12
            c-0.14,0.25-0.57,0.66-0.79,0.85c-0.36,0.31-1.13,0.91-1.58,1.09c-0.46,0.18-1.48,0.15-1.94,0.3c-0.02,0.01-0.03,0.01-0.05,0.02
            c0.26,0.37,0.52,0.73,0.72,0.95c0.38,0.44,1.17,1.3,1.64,1.64c0.48,0.34,1.71,0.61,2.12,1.03c0.53,0.54,0.98,2.04,1.27,2.73
            c0.33,0.79,0.67,2.55,1.21,3.21c0.32,0.39,1.32,0.76,1.7,1.09c0.44,0.39,1.08,1.42,1.52,1.82c0.53,0.49,1.73,1.3,2.36,1.64
            c0.56,0.3,1.83,0.58,2.36,0.91c0.59,0.37,1.73,1.26,2.06,1.88c0.43,0.8,0.5,2.67,0.55,3.58c0.02,0.29-0.01,0.71-0.04,1.13
            c0.14,0.07,0.28,0.14,0.38,0.2c0.47,0.28,1.31,1,1.79,1.27c0.36,0.21,1.18,0.42,1.52,0.67c0.38,0.27,0.94,1.04,1.24,1.4
            c0.24,0.28,0.69,0.88,0.94,1.15c0.23,0.26,0.79,0.68,0.97,0.97c0.45,0.73,0.74,2.46,1.06,3.24c0.29,0.71,1.18,1.99,1.36,2.73
            c0.14,0.54,0.08,1.66,0.06,2.21c-0.01,0.36-0.16,1.07-0.12,1.43c0.07,0.64,0.65,1.82,0.76,2.46c0.11,0.64,0.16,1.93,0.12,2.58
            c-0.05,0.68-0.6,2.02-0.48,2.7c0.07,0.43,0.56,1.19,0.85,1.52s1.17,0.68,1.39,1.06c0.27,0.46,0.32,1.59,0.33,2.12
            c0.01,0.48-0.18,1.43-0.21,1.91c-0.02,0.3,0.05,0.93-0.05,1.21c-0.11,0.3-0.7,0.7-0.77,1.01c-0.08,0.37,0.18,1.13,0.36,1.46
            s0.88,0.75,1.05,1.09c0.23,0.45,0.18,1.51,0.36,1.98c0.15,0.4,0.59,1.15,0.89,1.45c0.37,0.38,1.41,0.76,1.78,1.13
            c0.67,0.68,1.46,2.44,1.98,3.23c0.36,0.54,1.3,1.49,1.49,2.1c0.18,0.57-0.19,1.82,0,2.39c0.06,0.17,0.32,0.43,0.45,0.57
            c0.25,0.28,0.88,0.74,1.09,1.05c0.24,0.35,0.45,1.2,0.65,1.58c0.24,0.47,0.94,1.28,1.09,1.78c0.14,0.45-0.02,1.43,0.08,1.9
            c0.08,0.36,0.49,1.01,0.57,1.38c0.26,1.17,0.17,3.61,0.2,4.81c0.03,1.05,0.28,3.18,0.04,4.2c-0.07,0.31-0.44,0.84-0.57,1.13
            c-0.18,0.4-0.57,1.19-0.65,1.62c-0.08,0.49,0.42,1.61,0.08,1.98c-0.14,0.15-0.64-0.07-0.81,0.04c-0.33,0.22-0.66,1.02-0.69,1.42
            c-0.03,0.37,0.28,1.08,0.45,1.42c0.18,0.35,0.63,1,0.93,1.25c0.36,0.31,1.19,0.92,1.66,0.89c0.29-0.02,0.79-0.43,0.93-0.69
            c0.13-0.23,0.01-0.8,0.08-1.05c0.07-0.26,0.23-0.87,0.48-0.97c0.48-0.18,1.39,0.71,1.9,0.77c0.5,0.06,1.48-0.34,1.98-0.32
            c0.53,0.02,1.56,0.3,2.06,0.48c0.77,0.28,2.29,0.93,2.91,1.45c0.29,0.24,0.82,0.84,0.89,1.21c0.07,0.38-0.4,1.11-0.4,1.49
            c-0.01,0.38,0.37,1.11,0.36,1.49c-0.01,0.43-0.25,1.3-0.48,1.66c-0.2,0.31-0.96,0.61-1.13,0.93c-0.11,0.21-0.15,0.74-0.08,0.97
            c0.12,0.38,0.71,0.99,1.01,1.25c0.46,0.39,1.51,1.09,2.1,1.21c0.28,0.06,0.85-0.15,1.13-0.12c0.4,0.05,1.14,0.42,1.54,0.52
            c0.85,0.22,2.67,0.22,3.44,0.65c0.96,0.53,2.46,2.26,2.95,3.23c0.39,0.78,0.07,2.78,0.65,3.44c0.47,0.53,2.05,0.59,2.71,0.85
            c0.4,0.15,1.39,0.33,1.58,0.73c0.13,0.27-0.41,0.88-0.32,1.17c0.08,0.27,0.68,0.51,0.85,0.73c0.32,0.4,0.73,1.35,0.93,1.82
            c0.17,0.38,0.16,1.46,0.57,1.58c0.23,0.07,0.54-0.52,0.77-0.61c0.29-0.1,0.98-0.24,1.21-0.04c0.31,0.26,0.09,1.23,0.2,1.62
            c0.13,0.42,0.7,1.14,0.77,1.58c0.1,0.66-0.22,1.99-0.4,2.63c-0.13,0.44-0.32,1.48-0.73,1.7c-0.32,0.18-1.09-0.21-1.45-0.24
            c-0.41-0.04-1.28-0.22-1.66-0.04c-0.27,0.13-0.6,0.67-0.73,0.93c-0.2,0.41-0.49,1.32-0.44,1.78c0.02,0.23,0.21,0.72,0.4,0.85
            c0.32,0.21,1.18-0.06,1.54,0.08c0.41,0.17,1.13,0.78,1.33,1.17c0.15,0.31-0.11,1.12,0.12,1.38c0.17,0.18,0.73,0.19,0.97,0.16
            c0.45-0.05,1.4-0.3,1.7-0.65c0.16-0.19,0.11-0.74,0.2-0.97c0.1-0.24,0.52-0.63,0.57-0.89c0.05-0.29-0.35-0.89-0.24-1.17
            c0.09-0.23,0.58-0.48,0.81-0.57c0.4-0.14,1.27-0.11,1.7-0.12c0.34-0.01,1.05-0.1,1.37,0c0.33,0.1,0.82,0.64,1.13,0.77
            c0.84,0.34,2.69,0.37,3.56,0.61c1.08,0.29,3.16,1.16,4.2,1.58c0.9,0.36,2.6,0.99,3.67,1.51c0.33-0.53,0.7-0.98,1.02-1.24
            c0.47-0.38,1.72-0.53,2.3-0.67c0.43-0.1,1.34-0.14,1.76-0.3c0.54-0.2,1.59-0.76,1.94-1.21c0.31-0.4,0.71-1.44,0.61-1.94
            c-0.11-0.49-0.87-1.31-1.33-1.52s-1.58,0.27-2,0c-0.37-0.23-0.81-1.15-0.73-1.58c0.09-0.49,1-1.18,1.45-1.39
            c0.52-0.25,1.73-0.2,2.3-0.3c0.74-0.13,2.98-0.66,3.26-0.73c-0.19-0.01,0.53-1.38,0.8-1.76c0.21-0.3,0.87-0.74,0.97-1.09
            c0.15-0.52-0.37-1.58-0.36-2.12c0.01-0.37,0.09-1.15,0.3-1.45c0.23-0.34,1.09-0.63,1.39-0.91c0.38-0.35,0.98-1.21,1.27-1.64
            c0.27-0.39,0.92-1.16,0.97-1.64c0.07-0.59-0.45-1.73-0.73-2.24c-0.25-0.46-1.06-1.19-1.33-1.64c-0.32-0.52-0.95-1.63-0.97-2.24
            c-0.02-0.57,0.47-1.65,0.79-2.12c0.27-0.41,1.02-1.11,1.45-1.33c0.65-0.33,2.19-0.29,2.85-0.61c0.17-0.08,0.39-0.22,0.61-0.38
            C411.585,304.49,411.985,304.14,412.165,303.87z" pointer-events="auto" class="landarea"></path>

            <rect id="rect12_15" x="347.225" y="251.7" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.84" height="19.63" pointer-events="auto" visibility="hidden"></rect>
    </g>
    <g id="AREA_ITALY_TRENTINOALTOADIGE" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Trentino-Alto Adige" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_ITALY_TRENTINOALTOADIGE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <path id="ITALY_TRENTINOALTOADIGE" fill="#1e8346" stroke="#7eb08e" stroke-width="0.5" d="M425.445,40.49
            c-0.6,0.65-1.65,1.3-1.94,1.85c-0.48,0.91-0.21,3.31-0.97,4c-0.55,0.5-2.26,0.18-2.97,0.42c-0.66,0.23-1.74,1.26-2.42,1.4
            c-0.72,0.14-2.24-0.12-2.91-0.42c-0.79-0.37-1.76-2.1-2.61-2.3c-0.78-0.19-2.5,0.26-3.09,0.79c-0.47,0.42-0.77,1.75-0.91,2.37
            c-0.2,0.89,0.24,2.9-0.3,3.64c-0.41,0.55-1.86,0.94-2.55,1.03c-0.59,0.08-1.77-0.33-2.36-0.3c-0.95,0.04-3.01,0.14-3.7,0.79
            c-0.32,0.3-0.65,1.29-0.48,1.7c0.23,0.55,1.66,0.72,2.06,1.15c0.34,0.37,0.86,1.32,0.85,1.82c-0.02,0.83-1.05,2.27-1.58,2.91
            c-0.45,0.55-1.81,1.21-2.18,1.82c-0.4,0.66-0.8,2.27-0.67,3.03c0.11,0.63,0.87,1.74,1.33,2.18c0.69,0.65,2.56,1.27,3.33,1.82
            c0.75,0.53,2.34,1.6,2.73,2.43c0.34,0.71,0.25,2.38,0.12,3.15c-0.14,0.82-0.85,2.34-1.21,3.09c-0.43,0.9-1.11,3-2,3.46
            c-0.42,0.22-1.41-0.19-1.88-0.18c-0.98,0.02-2.98,0.15-3.88,0.55c-0.48,0.21-1.4,0.86-1.64,1.33c-0.27,0.56-0.02,1.87-0.06,2.49
            c-0.05,0.67-0.05,2.05-0.3,2.67c-0.36,0.88-1.66,2.34-2.36,2.97c-0.59,0.53-1.88,1.76-2.67,1.7c-0.5-0.04-1.23-0.92-1.52-1.33
            c-0.34-0.48-0.48-1.73-0.85-2.18c-0.33-0.41-1.25-1.02-1.76-1.15c-0.66-0.17-2.09-0.06-2.73,0.18c-0.83,0.32-2.5,1.35-2.79,2.18
            c-0.15,0.43,0.08,1.46,0.42,1.76c0.32,0.28,1.42-0.15,1.7,0.18c0.28,0.34,0.1,1.38-0.12,1.76c-0.27,0.45-1.24,1.05-1.76,1.15
            c-0.32,0.06-0.95-0.26-1.27-0.24c-0.6,0.03-1.8,0.35-2.3,0.67c-0.8,0.5-2.18,1.92-2.55,2.79c-0.29,0.67-0.08,2.19-0.18,2.91
            c-0.14,1.01-0.27,3.16-0.85,4c-0.24,0.36-1.17,0.64-1.45,0.97c-0.49,0.57-0.8,2.14-1.27,2.73c-0.54,0.67-1.89,1.92-2.73,2.06
            c-0.64,0.11-1.95-0.42-2.49-0.79c-0.49-0.34-0.93-1.6-1.45-1.88c-0.43-0.23-1.51-0.35-1.94-0.12c-0.55,0.3-0.67,1.88-1.21,2.18
            c-0.53,0.29-1.91,0.2-2.42-0.12c-0.59-0.37-1.31-1.73-1.33-2.43c-0.02-0.51,0.75-1.37,0.85-1.88c0.14-0.67,0.38-2.21-0.06-2.73
            c-0.55-0.64-2.49-0.69-3.33-0.61c-0.42,0.04-1.2,0.14-1.88,0.33c-0.02-0.09-0.05-0.18-0.08-0.25c-0.26-0.59-1.27-1.52-1.86-1.78
            c-0.58-0.25-1.9-0.33-2.51-0.16c-0.45,0.12-1.26,0.67-1.54,1.05c-0.34,0.46-0.29,1.74-0.65,2.18c-0.39,0.49-1.56,1.25-2.18,1.21
            c-1.05-0.07-2.73-1.69-3.4-2.51c-0.39-0.48-0.87-1.67-1.05-2.26c-0.19-0.61-0.27-1.89-0.4-2.51c-0.16-0.78-0.4-2.39-0.81-3.07
            c-0.26-0.43-1.17-0.96-1.45-1.37c-0.26-0.38-0.7-1.24-0.73-1.7c-0.06-0.86,0.53-2.54,0.89-3.32c0.34-0.74,1.46-1.96,1.86-2.67
            c0.6-1.08,1.49-3.38,1.94-4.53c0.4-1.02,1.29-3.04,1.45-4.12c0.22-1.42-0.18-4.31-0.16-5.74c0.01-0.79,0.66-2.54,0.16-3.15
            c-0.4-0.49-1.97-0.06-2.51-0.4c-0.39-0.25-1.05-1.07-1.05-1.54c0-0.54,0.76-1.49,1.21-1.78c0.69-0.44,2.42-0.69,3.23-0.57
            c0.45,0.06,1.21,1,1.62,0.81c0.39-0.18,0.48-1.27,0.4-1.7c-0.08-0.43-0.89-1.03-0.97-1.46c-0.08-0.49,0.5-1.44,0.48-1.94
            c-0.02-0.55-0.33-1.65-0.65-2.1c-0.42-0.59-1.63-1.56-2.34-1.7c-0.65-0.13-1.98,0.93-2.59,0.65c-0.38-0.18-0.63-1.12-0.73-1.54
            c-0.04-0.17-0.05-0.41-0.05-0.66c0.3,0.13,0.6,0.22,0.8,0.17c0.23-0.05,0.57-0.49,0.61-0.73c0.04-0.28-0.42-0.75-0.42-1.03
            c-0.01-0.44,0.42-1.26,0.64-1.64c0.17-0.3,0.75-0.76,0.85-1.09c0.1-0.31,0.07-1.02-0.09-1.3c-0.12-0.22-0.61-0.46-0.82-0.61
            c-0.17-0.12-0.47-0.41-0.67-0.46c-0.48-0.11-1.45,0.28-1.94,0.27c-0.3,0-0.93-0.02-1.18-0.18c-0.3-0.2-0.61-0.9-0.79-1.21
            c-0.16-0.29-0.57-0.88-0.58-1.21c-0.01-0.44,0.4-1.29,0.67-1.64c0.16-0.2,0.7-0.38,0.82-0.61c0.22-0.41,0-1.4,0.12-1.85
            c0.08-0.31,0.39-0.89,0.58-1.15c0.16-0.23,0.75-0.54,0.79-0.82c0.04-0.31-0.55-0.81-0.61-1.12c-0.05-0.26,0-0.8,0.12-1.03
            c0.19-0.35,1-0.69,1.21-1.03c0.3-0.47,0.5-1.6,0.58-2.15c0.04-0.3-0.16-0.98,0.03-1.21c0.3-0.36,1.35-0.41,1.82-0.42
            c0.43-0.01,1.27,0.27,1.7,0.3c0.29,0.02,0.89,0.12,1.15,0c0.34-0.15,0.68-0.9,0.97-1.12c0.31-0.24,1.03-0.64,1.42-0.7
            c0.4-0.06,1.25,0.07,1.61,0.27c0.21,0.12,0.48,0.54,0.64,0.73c0.22,0.28,0.51,1.06,0.85,1.15c0.35,0.1,0.98-0.58,1.33-0.55
            c0.26,0.02,0.7,0.39,0.88,0.58c0.27,0.27,0.85,0.92,0.82,1.3c-0.03,0.36-0.79,0.76-0.94,1.09c-0.12,0.25-0.32,0.85-0.18,1.09
            c0.25,0.46,1.42,0.72,1.94,0.82c0.44,0.08,1.34-0.1,1.79-0.06c0.78,0.07,2.29,0.49,3.06,0.64c0.3,0.06,0.91,0.19,1.21,0.21
            c0.56,0.05,1.68,0,2.24,0c0.54,0,1.65,0.16,2.15-0.03c0.56-0.22,1.41-1.11,1.79-1.58c0.29-0.36,0.65-1.23,0.91-1.61
            c0.14-0.19,0.5-0.51,0.61-0.73c0.24-0.5,0.41-1.63,0.45-2.18c0.04-0.43-0.14-1.3-0.06-1.73c0.08-0.46,0.55-1.29,0.73-1.73
            c0.17-0.43,0.34-1.4,0.67-1.73c0.34-0.34,1.43-0.35,1.79-0.67c0.34-0.3,0.43-1.44,0.85-1.61c0.28-0.11,0.85,0.42,1.15,0.39
            c0.55-0.04,1.47-0.74,1.94-1.03c0.39-0.24,1.07-0.95,1.52-1.06c0.48-0.12,1.48,0.25,1.97,0.24c0.45-0.01,1.32-0.4,1.76-0.3
            c0.29,0.07,0.73,0.51,0.97,0.7c0.17,0.13,0.43,0.53,0.64,0.55c0.36,0.03,0.9-0.61,1.24-0.73c0.33-0.12,1.09-0.03,1.39-0.21
            c0.41-0.25,0.74-1.26,1.12-1.55c0.2-0.15,0.7-0.39,0.94-0.33c0.29,0.07,0.56,0.73,0.82,0.88c0.41,0.24,1.37,0.47,1.85,0.45
            c0.4-0.01,1.13-0.4,1.52-0.49c0.51-0.11,1.57-0.27,2.09-0.21c0.43,0.04,1.23,0.37,1.64,0.51c0.52,0.19,1.51,0.81,2.06,0.85
            c0.34,0.02,0.97-0.3,1.3-0.33c0.39-0.03,1.16,0.14,1.55,0.12c0.7-0.04,2.14-0.21,2.76-0.55c0.51-0.28,1.15-1.31,1.61-1.67
            c0.39-0.31,1.29-0.75,1.7-1.03c0.51-0.35,1.39-1.27,1.97-1.49c0.48-0.18,1.55-0.11,2.06-0.15c0.45-0.04,1.38,0,1.79-0.18
            c0.44-0.19,1.01-1.08,1.45-1.24c0.49-0.18,1.61,0.19,2.09,0c0.36-0.14,0.77-0.9,1.12-1.06c0.48-0.23,1.6-0.33,2.12-0.24
            c0.44,0.07,1.32,0.42,1.61,0.76c0.27,0.32,0.58,1.2,0.48,1.61c-0.06,0.23-0.56,0.48-0.67,0.7c-0.14,0.29,0.05,1.02-0.15,1.27
            c-0.28,0.36-1.24,0.71-1.7,0.67c-0.27-0.02-0.67-0.56-0.94-0.58c-0.33-0.02-0.96,0.31-1.21,0.51c-0.28,0.23-0.61,0.91-0.82,1.21
            c-0.15,0.23-0.55,0.64-0.61,0.91s-0.01,0.89,0.15,1.12c0.21,0.29,1.1,0.34,1.3,0.64c0.19,0.27,0.15,0.98,0.12,1.3
            c-0.03,0.34-0.42,0.96-0.39,1.3c0.02,0.2,0.2,0.61,0.36,0.73c0.23,0.16,0.84,0.01,1.09,0.12c0.25,0.12,0.61,0.58,0.82,0.76
            c0.2,0.17,0.56,0.61,0.82,0.64c0.35,0.03,0.92-0.58,1.27-0.64c0.38-0.07,1.24-0.05,1.55,0.18c0.2,0.15,0.2,0.78,0.39,0.94
            s0.82-0.01,0.97,0.18c0.27,0.33,0.05,1.29-0.06,1.7c-0.1,0.38-0.59,1.02-0.7,1.4c-0.12,0.4-0.29,1.24-0.18,1.64
            c0.12,0.42,0.67,1.17,1.06,1.36c0.28,0.14,0.96-0.04,1.27,0.03c0.59,0.13,1.78,0.54,2.24,0.94c0.4,0.35,0.77,1.4,1.09,1.82
            C424.875,40,425.155,40.24,425.445,40.49z" pointer-events="auto" class="landarea"></path>

            <rect id="rect12_16" x="343.485" y="45.23" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.84" height="19.63" pointer-events="auto" visibility="hidden"></rect>
    </g>
    <g id="AREA_ITALY_UMBRIA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Umbria" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_ITALY_UMBRIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <path id="ITALY_UMBRIA" fill="#1e8346" stroke="#7eb08e" stroke-width="0.5" d="M474.785,311.69c0,0.53-0.04,1.1-0.16,1.44
            c-0.15,0.44-0.89,1.08-1.09,1.5c-0.22,0.45-0.32,1.48-0.52,1.94c-0.17,0.38-0.55,1.21-0.93,1.37c-0.59,0.26-1.9-0.45-2.55-0.4
            c-0.76,0.06-2.28,0.5-2.91,0.93c-0.39,0.26-0.94,1.05-1.17,1.45c-0.17,0.29-0.28,0.99-0.48,1.25c-0.24,0.32-0.89,1.01-1.29,0.97
            s-0.72-0.98-1.05-1.21c-0.26-0.18-0.86-0.47-1.17-0.45c-0.33,0.02-0.93,0.39-1.13,0.65c-0.36,0.45-0.35,1.75-0.69,2.22
            c-0.36,0.5-1.58,0.98-1.98,1.45c-0.28,0.33-0.46,1.23-0.77,1.54c-0.27,0.27-0.99,0.65-1.38,0.69c-0.43,0.04-1.23-0.49-1.66-0.48
            c-0.38,0.01-1.15,0.25-1.45,0.48c-0.26,0.2-0.68,0.77-0.73,1.09c-0.05,0.39,0.36,1.12,0.48,1.49c0.12,0.36,0.51,1.04,0.48,1.42
            c-0.02,0.4-0.36,1.22-0.69,1.45c-0.3,0.22-1.12,0.26-1.49,0.2c-0.33-0.05-0.88-0.54-1.21-0.57s-1.06,0.12-1.29,0.36
            c-0.32,0.34-0.39,1.35-0.4,1.82c-0.01,0.32,0.28,0.95,0.2,1.25c-0.08,0.34-0.57,0.91-0.85,1.13c-0.45,0.35-1.63,0.57-2.1,0.89
            c-0.36,0.24-0.81,1.07-1.21,1.21c-0.31,0.11-1.03,0.07-1.29-0.12c-0.29-0.21-0.58-0.97-0.57-1.33c0.01-0.35,0.58-0.91,0.61-1.25
            c0.02-0.38-0.16-1.26-0.48-1.45c-0.33-0.2-1.19,0.07-1.54,0.24c-0.29,0.14-0.61,0.86-0.93,0.89c-0.39,0.03-1.08-0.56-1.29-0.89
            c-0.17-0.27-0.28-0.95-0.2-1.25c0.06-0.24,0.52-0.54,0.61-0.77c0.15-0.39,0.23-1.25,0.16-1.66c-0.05-0.32-0.28-0.96-0.52-1.17
            c-0.33-0.28-1.23-0.49-1.66-0.48c-0.52,0.01-1.46,0.66-1.98,0.65c-0.47-0.02-1.33-0.46-1.74-0.69c-0.45-0.24-1.38-0.75-1.66-1.17
            c-0.21-0.32-0.17-1.15-0.32-1.5c-0.18-0.4-0.83-1.03-1.05-1.41c-0.19-0.33-0.54-1.04-0.61-1.42c-0.08-0.42,0.19-1.29,0.08-1.7
            c-0.1-0.38-0.52-1.07-0.81-1.33c-0.19-0.18-0.71-0.35-0.93-0.48c-0.62-0.38-1.71-1.4-2.39-1.66c-0.41-0.15-1.31-0.29-1.74-0.2
            s-1.18,0.62-1.54,0.89c-0.33,0.24-0.78,1.03-1.17,1.13c-0.44,0.12-1.42-0.16-1.78-0.44c-0.28-0.22-0.46-0.98-0.69-1.25
            c-0.33-0.4-1.21-0.97-1.58-1.33c-0.29-0.28-0.73-0.97-1.05-1.21c-0.34-0.26-1.33-0.39-1.54-0.77c-0.2-0.36-0.07-1.31,0.16-1.66
            c0.12-0.18,0.63-0.24,0.77-0.4c0.22-0.27,0.38-0.99,0.4-1.33c0.02-0.29-0.01-0.89-0.16-1.13c-0.14-0.21-0.6-0.51-0.85-0.53
            c-0.27-0.02-0.73,0.44-1.01,0.45c-0.48,0.01-1.54-0.29-1.82-0.69c-0.22-0.3-0.13-1.13-0.04-1.49c0.08-0.33,0.59-0.87,0.65-1.21
            c0.06-0.39-0.17-1.19-0.28-1.58c-0.1-0.33-0.47-0.87-0.6-1.29c0.39-0.28,0.79-0.63,0.96-0.89c0.42-0.63,0.47-2.25,0.85-2.91
            c0.27-0.47,1.27-1.09,1.52-1.58c0.19-0.37,0.3-1.22,0.3-1.64c0.02-0.87-0.13-2.66-0.48-3.46c-0.2-0.45-1.05-1.07-1.27-1.52
            c-0.18-0.36-0.17-1.22-0.36-1.58c-0.24-0.46-1.2-1.04-1.39-1.52c-0.22-0.52-0.1-1.68-0.12-2.24c-0.03-0.71-0.31-2.18-0.06-2.85
            c0.19-0.51,0.93-1.49,1.45-1.64c0.26-0.07,0.8,0.17,1.03,0.3c0.2,0.11,0.44,0.58,0.67,0.61c0.59,0.07,1.71-0.68,2.06-1.15
            s0.34-1.73,0.48-2.3c0.12-0.46,0.17-1.53,0.55-1.82c0.78-0.6,3.12,0.42,3.94-0.12c0.45-0.3,0.55-1.54,0.91-1.94
            c0.32-0.36,1.31-0.68,1.64-1.03c0.33-0.35,0.9-1.22,0.91-1.7c0.02-0.75-0.82-2.13-1.27-2.73c-0.4-0.52-1.4-1.42-2-1.7
            c-0.45-0.2-1.49-0.1-1.94-0.3c-0.45-0.2-1.27-0.85-1.52-1.27c-0.28-0.48-0.25-1.65-0.42-2.18c-0.17-0.51-0.9-1.4-0.91-1.94
            c-0.01-0.28,0.22-0.84,0.42-1.03c0.23-0.21,0.95-0.18,1.21-0.36c0.33-0.23,0.88-0.88,0.97-1.27c0.11-0.5-0.45-1.49-0.42-2
            c0.03-0.53,0.3-1.63,0.67-2c0.34-0.34,1.41-0.36,1.82-0.61c0.39-0.23,0.98-0.94,1.33-1.21c0.55-0.42,1.9-0.96,2.3-1.52
            c0.1-0.14,0.2-0.38,0.28-0.64c0.41,0.14,0.92,0.21,1.18,0.23c0.45,0.04,1.39-0.16,1.78-0.4c0.24-0.15,0.44-0.75,0.69-0.89
            c0.38-0.22,1.3-0.31,1.74-0.24c0.45,0.07,1.38,0.44,1.66,0.81c0.26,0.34,0.43,1.25,0.32,1.66c-0.08,0.31-0.65,0.7-0.81,0.97
            c-0.23,0.42-0.7,1.35-0.61,1.82c0.11,0.59,0.98,1.61,1.54,1.82c0.34,0.13,1.14-0.01,1.45-0.2c0.42-0.25,0.75-1.33,1.17-1.58
            c0.44-0.26,1.51-0.27,2.02-0.28c0.4-0.01,1.28-0.11,1.62,0.12c0.44,0.31,0.53,1.55,0.89,1.94c0.22,0.24,0.86,0.47,1.13,0.65
            c0.47,0.29,1.45,0.81,1.82,1.21c0.47,0.52,0.8,1.97,1.29,2.47c0.36,0.37,1.31,0.89,1.82,0.97c0.55,0.09,1.77-0.02,2.22-0.36
            c0.3-0.23,0.38-1.11,0.65-1.37c0.3-0.3,1.11-0.76,1.54-0.73c0.39,0.03,1.08,0.56,1.29,0.89c0.23,0.37,0.35,1.3,0.28,1.74
            c-0.08,0.47-0.7,1.26-0.89,1.7c-0.14,0.31-0.51,0.96-0.45,1.29c0.06,0.35,0.7,0.8,0.89,1.09c0.23,0.34,0.64,1.06,0.77,1.45
            c0.31,0.94,0.26,2.98,0.57,3.92c0.15,0.48,0.61,1.4,0.93,1.78c0.34,0.4,1.29,0.89,1.66,1.25c0.46,0.45,1.42,1.41,1.62,2.02
            c0.16,0.49-0.03,1.55-0.12,2.06c-0.08,0.46-0.46,1.32-0.52,1.78c-0.08,0.56-0.17,1.74,0.04,2.26c0.19,0.46,1.07,1.06,1.38,1.45
            c0.26,0.33,0.77,1.02,0.93,1.42c0.15,0.36,0.32,1.14,0.32,1.54c0,0.52-0.37,1.54-0.4,2.06c-0.05,0.77,0.03,2.33,0.24,3.07
            c0.16,0.56,0.8,1.54,1.05,2.06c0.29,0.61,0.69,1.92,1.09,2.47c0.33,0.46,1.14,1.39,1.7,1.5c0.3,0.06,0.92-0.19,1.13-0.4
            c0.27-0.27,0.23-1.17,0.48-1.45c0.19-0.21,0.76-0.48,1.05-0.48c0.47-0.01,1.34,0.48,1.74,0.73c0.29,0.18,0.83,0.61,1.01,0.89
            c0.25,0.39,0.38,1.34,0.52,1.78c0.16,0.48,0.32,1.55,0.69,1.9c0.35,0.32,1.37,0.69,1.82,0.52c0.24-0.09,0.4-0.66,0.57-0.85
            c0.3-0.34,0.93-1.1,1.37-1.21c0.33-0.09,1.11,0.03,1.33,0.28c0.27,0.31-0.05,1.28,0.12,1.66c0.18,0.42,1.17,0.88,1.25,1.33
            c0.1,0.56-0.57,1.63-0.89,2.1c-0.11,0.17-0.48,0.39-0.57,0.57c-0.15,0.31-0.19,1.03-0.16,1.38c0.04,0.43,0.43,1.23,0.48,1.66
            C474.775,311.05,474.785,311.36,474.785,311.69z" pointer-events="auto" class="landarea"></path>

            <rect id="rect12_17" x="409.035" y="288.75" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.84" height="19.63" pointer-events="auto" visibility="hidden"></rect>
    </g>
    <g id="AREA_ITALY_VALLEDAOSTA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Aosta Valley" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_ITALY_VALLEDAOSTA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <path id="ITALY_VALLEDAOSTA" fill="#1e8346" stroke="#7eb08e" stroke-width="0.5" d="M217.275,116.2c0.15,0.41,0.23,1.38,0,1.76
            c-0.31,0.51-1.54,0.95-2.12,1.09c-0.53,0.13-1.69-0.24-2.18,0c-0.58,0.29-1.1,1.63-1.58,2.06c-0.41,0.37-1.34,1.03-1.88,1.15
            c-0.53,0.13-1.65-0.08-2.18-0.18c-0.51-0.1-1.48-0.54-2-0.61c-0.77-0.11-2.33,0.17-3.09,0.06c-0.54-0.08-1.52-0.61-2.06-0.67
            c-0.51-0.06-1.6-0.06-2.06,0.18c-0.37,0.19-0.83,0.95-1.09,1.27c-0.34,0.42-0.94,1.33-1.33,1.7c-0.47,0.44-1.51,1.25-2.12,1.46
            c-0.53,0.18-1.71-0.05-2.24,0.12c-0.7,0.22-1.96,1.07-2.49,1.58c-0.21,0.2-0.46,0.76-0.67,0.97c-0.34,0.34-1.1,1.07-1.58,1.09
            c-0.43,0.02-1.09-0.77-1.52-0.85c-0.24-0.04-0.75-0.1-1.15-0.06c-0.05-0.16-0.11-0.3-0.18-0.39c-0.27-0.37-1.2-0.76-1.46-1.15
            c-0.25-0.39-0.2-1.4-0.46-1.79c-0.1-0.15-0.4-0.42-0.58-0.46c-0.31-0.07-0.95,0.42-1.24,0.3c-0.18-0.07-0.42-0.47-0.42-0.67
            c0-0.24,0.48-0.58,0.49-0.82c0-0.17-0.19-0.49-0.33-0.58c-0.38-0.24-1.38,0.07-1.79-0.12c-0.16-0.07-0.4-0.36-0.49-0.51
            c-0.26-0.46-0.53-1.5-0.58-2.03c-0.03-0.4,0.23-1.21,0.18-1.61c-0.07-0.55-0.92-1.56-0.76-2.09c0.11-0.36,1.09-0.55,1.21-0.91
            c0.18-0.49-0.29-1.56-0.51-2.03c-0.14-0.3-0.49-0.94-0.79-1.09c-0.22-0.11-0.76-0.06-1,0.03c-0.3,0.11-0.68,0.76-1,0.79
            c-0.35,0.03-0.94-0.48-1.24-0.67c-0.36-0.22-1.08-0.68-1.4-0.97c-0.25-0.23-0.65-0.8-0.88-1.06c-0.23-0.27-1.09-0.74-0.94-1.06
            c0.11-0.24,0.77-0.01,1.03,0c0.27,0.01,0.85,0.25,1.06,0.09c0.15-0.11,0.17-0.53,0.16-0.71c-0.01-0.32-0.27-0.91-0.38-1.21
            c-0.12-0.32-0.22-1.1-0.53-1.25c-0.19-0.1-0.62,0.22-0.83,0.18c-0.43-0.07-1.23-0.56-1.52-0.89c-0.2-0.23-0.45-0.81-0.46-1.11
            c-0.02-0.34,0.39-0.97,0.38-1.31c-0.01-0.39-0.53-1.1-0.51-1.5c0.03-0.43,0.52-1.19,0.79-1.54c0.14-0.18,0.46-0.53,0.67-0.61
            c0.4-0.15,1.29,0.07,1.72,0.08c0.39,0.01,1.17,0.05,1.56,0.02c0.57-0.04,1.73-0.13,2.24-0.36c0.36-0.16,0.87-0.8,1.21-0.99
            c0.27-0.14,0.92-0.18,1.15-0.36c0.27-0.22,0.63-0.86,0.69-1.19c0.09-0.47-0.5-1.45-0.3-1.88c0.16-0.35,0.93-0.78,1.32-0.83
            c0.26-0.03,0.77,0.19,0.99,0.32c0.48,0.28,1.38,1.02,1.62,1.52c0.21,0.43-0.09,1.49,0.16,1.9c0.21,0.34,0.97,0.88,1.37,0.85
            c0.43-0.04,0.87-1.06,1.29-1.13c0.26-0.05,0.77,0.24,0.99,0.38c0.24,0.16,0.52,0.78,0.81,0.83c0.27,0.04,0.77-0.32,0.97-0.51
            c0.34-0.32,0.65-1.25,0.99-1.58c0.49-0.47,1.81-0.97,2.38-1.33c0.37-0.24,1-0.93,1.43-1.03c0.3-0.07,0.97,0.04,1.23,0.2
            c0.36,0.22,0.69,1.1,1.01,1.37c0.29,0.25,0.97,0.79,1.35,0.73c0.46-0.07,1.05-0.94,1.31-1.33c0.19-0.28,0.31-0.99,0.52-1.25
            c0.23-0.27,0.89-0.61,1.21-0.77c0.78-0.38,2.55-0.67,3.27-1.15c0.27-0.18,0.65-0.75,0.89-0.97c0.34-0.31,1.01-1.26,1.46-1.13
            c0.44,0.13,0.24,1.46,0.61,1.74c0.22,0.17,0.85,0.08,1.13,0.08c0.55,0,1.64-0.18,2.18-0.12c0.46,0.05,1.44,0.17,1.78,0.49
            c0.29,0.27,0.36,1.14,0.52,1.5c0.23,0.49,0.64,1.56,1.09,1.86c0.21,0.14,0.58,0.19,0.96,0.22c-0.05,0.64-0.1,1.31-0.11,1.66
            c-0.02,0.49-0.15,1.48,0,1.94c0.18,0.58,1.04,1.5,1.4,2c0.26,0.36,1.03,1.01,1.03,1.46c0,0.67-1.32,1.56-1.58,2.18
            c-0.26,0.63-0.61,2.02-0.42,2.67c0.17,0.6,1.2,1.44,1.64,1.88c0.52,0.52,1.92,1.27,2.24,1.94c0.16,0.34,0.21,1.17,0.06,1.52
            c-0.15,0.36-1.06,0.66-1.15,1.03c-0.06,0.22,0.19,0.65,0.3,0.85C216.395,115.24,217.135,115.79,217.275,116.2z" pointer-events="auto" class="landarea"></path>

            <rect id="rect12_18" x="164.885" y="99.79" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.84" height="19.63" pointer-events="auto" visibility="hidden"></rect>
    </g>
    <g id="AREA_ITALY_VENETO" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Veneto" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_ITALY_VENETO.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <path id="ITALY_VENETO" fill="#1e8346" stroke="#7eb08e" stroke-width="0.5" d="M375.145,160.27c-0.9-0.32-2.02-0.88-2.55-1.32
            c-0.5-0.41-1.08-1.63-1.54-2.1c-0.32-0.33-1.19-0.75-1.45-1.13c-0.3-0.43-0.4-1.55-0.65-2.02c-0.36-0.7-1.25-2.02-1.86-2.51
            c-0.74-0.59-2.62-1.13-3.48-1.54c-0.92-0.44-2.89-1.17-3.64-1.86c-0.7-0.64-1.45-2.47-2.02-3.23c-0.53-0.7-1.7-1.99-2.34-2.59
            c-0.34-0.31-1.02-0.97-1.45-1.13c-0.57-0.22-1.84,0-2.43-0.16c-0.52-0.14-1.55-0.6-1.94-0.97c-0.54-0.51-1.17-1.9-1.45-2.59
            c-0.29-0.72-0.6-2.25-0.81-2.99c-0.27-0.95-0.68-2.92-1.13-3.8c-0.39-0.77-1.76-1.96-2.1-2.75c-0.48-1.09-0.8-3.5-0.81-4.69
            c-0.01-1.21,0.28-3.65,0.73-4.77c0.34-0.85,1.48-2.32,2.1-2.99c0.62-0.68,2.23-1.65,2.83-2.35c0.46-0.54,1.24-1.75,1.45-2.43
            c0.27-0.83,0.3-2.6,0.32-3.48c0.02-0.65,0.17-1.92,0-2.66c0.68-0.19,1.46-0.29,1.88-0.33c0.84-0.08,2.78-0.04,3.33,0.61
            c0.44,0.52,0.2,2.06,0.06,2.73c-0.1,0.51-0.87,1.36-0.85,1.88c0.03,0.69,0.75,2.06,1.33,2.43c0.51,0.32,1.89,0.42,2.42,0.12
            c0.55-0.3,0.67-1.88,1.21-2.18c0.43-0.23,1.51-0.11,1.94,0.12c0.52,0.28,0.97,1.54,1.45,1.88c0.54,0.37,1.84,0.9,2.49,0.79
            c0.84-0.14,2.19-1.4,2.73-2.06c0.47-0.59,0.78-2.16,1.27-2.73c0.29-0.33,1.21-0.61,1.45-0.97c0.57-0.85,0.71-2.99,0.85-4
            c0.1-0.72-0.1-2.24,0.18-2.91c0.37-0.87,1.74-2.29,2.55-2.79c0.51-0.32,1.7-0.64,2.3-0.67c0.32-0.01,0.96,0.31,1.27,0.24
            c0.52-0.1,1.49-0.7,1.76-1.15c0.22-0.38,0.4-1.42,0.12-1.76c-0.27-0.33-1.38,0.1-1.7-0.18c-0.34-0.3-0.57-1.33-0.42-1.76
            c0.29-0.84,1.96-1.87,2.79-2.18c0.64-0.24,2.07-0.36,2.73-0.18c0.51,0.13,1.43,0.74,1.76,1.15c0.37,0.46,0.51,1.7,0.85,2.18
            c0.29,0.41,1.01,1.29,1.52,1.33c0.79,0.06,2.08-1.17,2.67-1.7c0.71-0.64,2-2.09,2.36-2.97c0.26-0.62,0.26-2,0.3-2.67
            c0.04-0.62-0.21-1.93,0.06-2.49c0.23-0.47,1.15-1.12,1.64-1.33c0.9-0.39,2.9-0.53,3.88-0.55c0.47-0.01,1.46,0.4,1.88,0.18
            c0.89-0.46,1.57-2.56,2-3.46c0.36-0.75,1.07-2.27,1.21-3.09c0.13-0.78,0.22-2.44-0.12-3.15c-0.39-0.83-1.98-1.9-2.73-2.43
            c-0.78-0.55-2.65-1.16-3.33-1.82c-0.46-0.44-1.23-1.55-1.33-2.18c-0.13-0.76,0.26-2.37,0.67-3.03c0.37-0.61,1.73-1.27,2.18-1.82
            c0.52-0.64,1.55-2.08,1.58-2.91c0.02-0.5-0.51-1.45-0.85-1.82c-0.4-0.43-1.84-0.61-2.06-1.15c-0.17-0.41,0.16-1.4,0.48-1.7
            c0.69-0.65,2.75-0.75,3.7-0.79c0.6-0.02,1.77,0.38,2.36,0.3c0.68-0.09,2.14-0.48,2.55-1.03c0.54-0.74,0.1-2.75,0.3-3.64
            c0.14-0.62,0.44-1.94,0.91-2.37c0.6-0.53,2.32-0.97,3.09-0.79c0.85,0.2,1.82,1.94,2.61,2.3c0.67,0.31,2.19,0.56,2.91,0.42
            c0.69-0.13,1.77-1.17,2.42-1.4c0.71-0.24,2.42,0.08,2.97-0.42c0.76-0.69,0.49-3.09,0.97-4c0.29-0.55,1.34-1.2,1.94-1.85
            c0.36,0.31,0.72,0.64,0.88,0.91c0.14,0.23,0.11,0.84,0.3,1.03c0.22,0.22,0.91,0.27,1.21,0.36c0.53,0.17,1.61,0.45,2.09,0.73
            s1.16,1.25,1.67,1.49c0.36,0.17,1.18,0.21,1.58,0.21c0.5,0,1.48-0.23,1.97-0.24c0.27-0.01,0.8,0.07,1.06,0.06
            c0.57-0.01,1.72-0.36,2.27-0.21c0.46,0.12,1.1,1.03,1.58,1.09c0.01,0,0.01,0,0.02,0c-0.09,0.97-0.22,2.01-0.3,2.67
            c-0.05,0.43,0.01,1.35-0.24,1.7c-0.45,0.62-2.3,0.75-2.75,1.37c-0.52,0.73-0.14,2.73-0.48,3.56c-0.27,0.64-1.14,1.77-1.7,2.18
            c-0.78,0.59-2.73,1.08-3.64,1.46c-0.79,0.33-2.69,0.65-3.15,1.37c-0.43,0.68,0.12,2.43,0.08,3.23c-0.03,0.65,0.12,2.11-0.32,2.59
            c-0.37,0.39-1.63,0.15-2.1,0.4c-0.72,0.39-1.93,1.6-2.26,2.35c-0.44,0.98-0.58,3.24-0.32,4.28c0.28,1.15,1.69,3.18,2.51,4.04
            c0.53,0.56,2.02,1.18,2.51,1.78c0.45,0.57,1.11,1.94,1.13,2.67c0.02,0.62-0.4,1.88-0.81,2.34c-0.37,0.43-1.65,0.62-2.02,1.05
            c-0.47,0.53-0.98,1.96-0.97,2.67c0.01,0.91,0.82,2.62,1.29,3.4c0.43,0.7,1.53,1.94,2.18,2.43c0.38,0.28,1.33,0.52,1.7,0.81
            c0.41,0.32,1.13,1.13,1.29,1.62c0.2,0.59-0.16,1.89-0.08,2.51c0.07,0.54,0.18,1.81,0.65,2.1s1.63-0.35,2.18-0.32
            c0.35,0.02,1.15,0.05,1.37,0.32c0.4,0.51-0.22,1.98,0,2.59c0.13,0.35,0.61,1.05,0.97,1.13c0.71,0.17,2.11-0.67,2.67-1.13
            c0.36-0.29,0.66-1.28,1.05-1.54c0.4-0.26,1.43-0.13,1.86-0.32c0.43-0.2,1.02-1.03,1.45-1.21c0.79-0.34,2.56-0.59,3.4-0.4
            c0.49,0.11,1.23,0.89,1.7,1.05c0.77,0.26,2.58-0.15,3.23,0.32c0.64,0.47,1.09,2.14,1.29,2.91c0.17,0.65,0.23,2,0.24,2.67
            c0.02,0.83-0.48,2.55-0.16,3.31c0.27,0.65,1.79,1.23,2.1,1.86c0.16,0.32,0.18,0.89,0.18,1.42c-0.13-0.06-0.24-0.1-0.34-0.09
            c-0.42,0.05-1.01,0.79-1.37,1.01c-0.43,0.26-1.4,0.61-1.82,0.89c-0.26,0.17-0.72,0.58-0.93,0.81c-0.26,0.28-0.55,1.09-0.91,1.21
            c-0.22,0.08-0.68-0.28-0.91-0.24c-0.39,0.07-0.98,0.68-1.3,0.91c-0.76,0.53-2.22,1.68-3.03,2.12c-0.37,0.2-1.2,0.44-1.58,0.64
            c-0.42,0.21-1.2,0.75-1.61,1c-0.47,0.29-1.4,0.85-1.88,1.12c-0.44,0.25-1.36,0.69-1.79,0.97c-0.44,0.29-1.21,1.06-1.67,1.32
            c-0.97,0.56-3.17,1.13-4.16,1.66c-0.63,0.33-1.67,1.36-2.35,1.58c-0.25,0.08-0.82,0.17-1.05,0.04c-0.28-0.16-0.59-0.81-0.61-1.13
            c-0.02-0.33,0.24-1.02,0.48-1.25c0.3-0.29,1.22-0.31,1.58-0.53c0.3-0.19,0.74-0.78,1.01-1.01c0.34-0.28,1.17-0.67,1.45-1.01
            c0.26-0.3,0.35-1.21,0.69-1.42c0.17-0.1,0.63-0.06,0.81,0.04c0.21,0.12,0.3,0.71,0.53,0.81c0.32,0.14,1.07-0.1,1.37-0.28
            c0.25-0.15,0.73-0.6,0.73-0.89c0-0.17-0.27-0.51-0.45-0.53c-0.21-0.02-0.44,0.51-0.65,0.53c-0.17,0.01-0.54-0.2-0.57-0.36
            c-0.06-0.33,0.93-0.74,0.81-1.05c-0.08-0.19-0.6-0.12-0.81-0.12c-0.27,0-0.82,0.19-1.09,0.16c-0.41-0.04-1.13-0.51-1.54-0.57
            c-0.19-0.02-0.61-0.04-0.77,0.08c-0.15,0.11-0.15,0.56-0.28,0.69c-0.25,0.23-1.1,0.17-1.29,0.44c-0.16,0.22-0.02,0.82,0,1.09
            c0.02,0.29,0.32,0.87,0.2,1.13c-0.11,0.24-0.65,0.67-0.89,0.57c-0.27-0.12,0.07-1.05-0.2-1.17c-0.22-0.1-0.6,0.4-0.81,0.53
            s-0.6,0.47-0.85,0.49c-0.27,0.01-0.74-0.41-1.01-0.44c-0.29-0.04-0.92,0.01-1.17,0.16c-0.19,0.11-0.4,0.51-0.52,0.69
            c-0.22,0.32-0.54,1.06-0.85,1.29c-0.22,0.17-0.84,0.15-1.05,0.32c-0.23,0.19-0.73,0.8-0.57,1.05c0.22,0.34,1.22-0.19,1.62-0.12
            c0.32,0.06,1.04,0.23,1.17,0.53c0.18,0.4-0.18,1.4-0.53,1.66c-0.27,0.2-1.02,0.16-1.33,0.04c-0.26-0.1-0.54-0.69-0.81-0.77
            c-0.38-0.1-1.28,0.06-1.54,0.36c-0.23,0.28-0.13,1.09-0.08,1.46c0.04,0.29,0.42,0.81,0.36,1.09c-0.03,0.15-0.35,0.3-0.4,0.44
            c-0.18,0.49,0.34,1.62,0.08,2.06c-0.13,0.23-0.67,0.45-0.93,0.49c-0.26,0.03-0.78-0.12-1.01-0.24c-0.29-0.15-0.88-0.54-0.97-0.85
            c-0.12-0.43,0.65-1.27,0.52-1.7c-0.11-0.36-0.96-0.66-1.17-0.97c-0.14-0.21-0.08-0.89-0.32-0.97c-0.32-0.1-0.94,0.51-1.09,0.81
            c-0.15,0.31-0.06,1.04,0,1.37c0.05,0.28,0.4,0.81,0.36,1.09c-0.04,0.31-0.56,0.76-0.65,1.05c-0.19,0.62-0.21,1.95-0.12,2.59
            c0.06,0.44,0.22,1.42,0.57,1.7c0.29,0.24,1.18-0.04,1.49,0.16s0.45,1.07,0.77,1.25c0.26,0.15,0.91,0.06,1.21,0.04
            c0.47-0.03,1.39-0.39,1.86-0.32c0.28,0.04,0.88,0.24,1.01,0.49c0.12,0.23-0.11,0.76-0.16,1.01c-0.05,0.24-0.29,0.73-0.24,0.97
            c0.05,0.25,0.36,0.77,0.61,0.81c0.17,0.03,0.48-0.25,0.57-0.4c0.12-0.21,0.07-0.73,0.08-0.97c0.02-0.4-0.02-1.22,0.04-1.62
            c0.05-0.32,0.29-0.93,0.36-1.25c0.09-0.41,0.2-1.24,0.28-1.66c0.05-0.27,0.24-0.81,0.24-1.09c0.01-0.29-0.4-0.92-0.2-1.13
            c0.14-0.15,0.66-0.06,0.81,0.08c0.21,0.2,0.16,0.85,0.16,1.13c0,0.23-0.08,0.7-0.12,0.93c-0.08,0.5-0.3,1.49-0.4,1.98
            c-0.1,0.46-0.43,1.35-0.4,1.82c0.01,0.23,0.28,0.66,0.28,0.89c0.01,0.26-0.19,0.76-0.28,1.01c-0.12,0.33-0.58,0.91-0.61,1.25
            c-0.03,0.42,0.46,1.2,0.52,1.62c0.03,0.2-0.12,0.65,0,0.81c0.1,0.12,0.5,0.04,0.61,0.16c0.22,0.24,0.01,1.03,0.2,1.29
            c0.09,0.12,0.43,0.17,0.52,0.28c0.17,0.21,0.35,0.75,0.3,1.01c-0.04,0.2-0.43,0.45-0.48,0.65c-0.09,0.31,0.22,0.97,0.12,1.27
            c-0.05,0.16-0.28,0.49-0.45,0.51c-0.26,0.03-0.7-0.4-0.85-0.63c-0.1-0.15-0.01-0.61-0.16-0.71c-0.23-0.14-0.88,0.02-1.05,0.22
            c-0.19,0.23-0.12,0.93-0.02,1.21c0.09,0.24,0.63,0.53,0.67,0.79c0.03,0.19-0.28,0.52-0.3,0.71c-0.02,0.16-0.04,0.53,0.08,0.63
            c0.15,0.12,0.61,0.03,0.77-0.08c0.18-0.13,0.18-0.71,0.38-0.79c0.23-0.1,0.76,0.16,0.95,0.32c0.25,0.21,0.36,0.93,0.59,1.17
            c0.14,0.15,0.5,0.4,0.71,0.4c0.28,0.01,0.72-0.5,0.99-0.49c0.29,0.01,0.75,0.42,0.97,0.61c0.25,0.21,0.62,0.76,0.87,0.97
            c0.5,0.43,1.73,1,2.22,1.43c0.28,0.24,0.82,0.79,0.97,1.13c0.05,0.12,0.14,0.43,0.06,0.54c-0.1,0.13-0.5,0.1-0.67,0.09
            c-0.35-0.01-1.02-0.35-1.36-0.27c-0.2,0.04-0.59,0.29-0.67,0.49c-0.1,0.24,0.01,0.86,0.21,1.03c0.21,0.18,0.85,0.09,1.12,0.03
            c0.24-0.06,0.63-0.42,0.88-0.46c0.19-0.03,0.62,0.02,0.76,0.15c0.21,0.21,0.06,0.92,0.24,1.15c0.21,0.26,0.98,0.34,1.21,0.58
            c0.16,0.16,0.41,0.59,0.39,0.82c-0.02,0.3-0.55,0.73-0.7,1c-0.14,0.25-0.41,0.78-0.45,1.06c-0.07,0.43,0.15,1.32,0.12,1.76
            c-0.02,0.37-0.17,1.11-0.3,1.46c-0.12,0.3-0.39,0.93-0.67,1.09c-0.34,0.2-1.23,0.24-1.58,0.06c-0.26-0.13-0.61-0.68-0.64-0.97
            c-0.03-0.34,0.45-0.91,0.52-1.24c0.08-0.41,0.19-1.32-0.03-1.67c-0.14-0.21-0.66-0.51-0.91-0.46c-0.29,0.06-0.57,0.68-0.76,0.91
            c-0.15,0.19-0.52,0.53-0.58,0.76c-0.05,0.23,0,0.78,0.18,0.94c0.13,0.12,0.58-0.07,0.7,0.06c0.21,0.24,0.07,0.99-0.06,1.27
            c-0.07,0.14-0.46,0.27-0.45,0.42c0.01,0.34,0.95,0.57,1.03,0.91c0.07,0.31-0.33,0.9-0.48,1.18c-0.24,0.45-0.67,1.53-1.15,1.7
            c-0.33,0.11-1.08-0.18-1.33-0.42c-0.32-0.3-0.37-1.29-0.64-1.64c-0.17-0.22-0.73-0.45-0.91-0.67c-0.11-0.14-0.12-0.55-0.27-0.64
            c-0.31-0.18-1.07,0.04-1.39,0.18c-0.17,0.08-0.42,0.25-0.63,0.44c-0.11-0.51-0.29-1.07-0.45-1.38c-0.37-0.73-1.57-1.89-2.1-2.51
            c-0.46-0.53-1.36-1.61-1.86-2.1c-0.48-0.48-1.45-1.42-2.02-1.78c-1.02-0.65-3.25-1.74-4.45-1.94c-0.76-0.13-2.31,0.13-3.07,0.24
            c-0.57,0.08-1.78,0.16-2.26,0.49c-0.51,0.34-0.88,1.66-1.37,2.02c-0.58,0.42-2.03,0.85-2.75,0.81c-0.55-0.04-1.55-0.6-2.02-0.89
            c-0.44-0.27-1.11-1.19-1.62-1.29c-0.78-0.16-2.46,0.38-3.07,0.89c-0.45,0.38-0.71,1.63-1.05,2.1c-0.4,0.56-1.34,1.61-1.94,1.94
            c-0.38,0.21-1.26,0.43-1.7,0.4c-0.82-0.05-2.36-0.73-3.07-1.13c-0.61-0.35-1.57-1.43-2.18-1.78c-0.71-0.41-2.36-0.74-3.07-1.13
            c-0.47-0.26-1.21-1.08-1.7-1.29c-0.73-0.31-2.36-0.46-3.15-0.49c-0.75-0.03-2.25,0.37-2.99,0.24
            C375.695,160.45,375.425,160.37,375.145,160.27z M425.425,133.22c0.1,0.06,0.21,0.32,0.32,0.34c0.17,0.03,0.48-0.19,0.61-0.3
            c0.11-0.1,0.26-0.37,0.32-0.51c0.1-0.19,0.22-0.61,0.3-0.81c0.09-0.21,0.26-0.65,0.4-0.83c0.13-0.17,0.5-0.4,0.65-0.55
            c0.16-0.15,0.47-0.48,0.59-0.67c0.1-0.17,0.24-0.59,0.16-0.77c-0.06-0.14-0.37-0.27-0.51-0.32c-0.13-0.05-0.43-0.14-0.57-0.1
            c-0.25,0.07-0.57,0.52-0.75,0.71c-0.2,0.21-0.62,0.6-0.77,0.85c-0.16,0.27-0.26,0.9-0.4,1.17c-0.14,0.26-0.58,0.68-0.71,0.95
            c-0.08,0.16-0.27,0.53-0.18,0.69C424.965,133.2,425.305,133.15,425.425,133.22z" pointer-events="auto" class="landarea"></path>

            <rect id="rect12_19" x="364.665" y="109.61" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.84" height="19.63" pointer-events="auto" visibility="hidden"></rect>
    </g>
    </svg>
}