export default function Germany() {
    return <svg version="1.1" id="svgpoint" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 900 700" enable-background="new 0 0 900 700">
<rect id="BACKGROUND" fill="#deded7" width="900" height="700" stroke="#deded7" class="background"></rect>
<g id="AREA_GERMANY_NIEDERSACHSEN" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Lower Saxony" data-infoText="<b>Lower Saxony</b> &amp;nbsp; Capital: Hanover">
    <path fill="#1e8346" d="M335.817,142.635c0.492-0.491,1.398-0.895,2.012-0.895c0.615,0,1.722,0.051,2.459,0.113
        c0.738,0.062,0.989-0.291,0.559-0.782c-0.43-0.493-1.235-0.793-1.788-0.671c-0.554,0.122-1.408,0.224-1.901,0.224
        c-0.492,0-1.095,0.099-1.341,0.223c-0.246,0.123-0.849,0.425-1.341,0.671s-0.592,0.799-0.223,1.23
        C334.621,143.177,335.324,143.128,335.817,142.635z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>
    <path fill="#1e8346" d="M345.989,140.846c1.044,0,2.352-0.101,2.906-0.224c0.554-0.122,1.006-0.373,1.006-0.559
        c0-0.183-0.503-0.336-1.118-0.336s-1.57-0.151-2.124-0.336c-0.553-0.185-1.257-0.233-1.565-0.112
        c-0.307,0.123-0.659,0.525-0.782,0.895C344.189,140.546,344.944,140.846,345.989,140.846z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>
    <path fill="#1e8346" d="M355.155,139.617c0.554,0.062,1.509,0.162,2.123,0.224c0.615,0.062,0.615-0.241,0-0.671
        s-1.47-0.833-1.899-0.895c-0.43-0.062-0.883,0.19-1.006,0.559C354.25,139.204,354.601,139.555,355.155,139.617z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>
    <path fill="#1e8346" d="M367.339,145.318c0.369-0.245,0.972-0.649,1.341-0.894c0.369-0.246,0.268-0.597-0.223-0.782
        c-0.493-0.185-1.095-0.135-1.341,0.112c-0.246,0.245-0.447,0.799-0.447,1.228C366.668,145.413,366.97,145.564,367.339,145.318z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>
    <path fill="#1e8346" d="M296.025,155.042c0.861-0.062,1.414-0.313,1.229-0.558c-0.185-0.246-0.084-0.649,0.223-0.895
        s0.961-0.65,1.453-0.895c0.493-0.246,0.493-0.748,0-1.117c-0.492-0.37-1.498-0.52-2.236-0.336c-0.738,0.185-1.895,0.587-2.57,0.895
        c-0.675,0.308-1.179,0.861-1.117,1.23c0.061,0.368,0.413,0.923,0.782,1.228C294.157,154.902,295.163,155.104,296.025,155.042z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>
    <path fill="#1e8346" d="M309.325,146.883c0.923-0.123,2.531-0.274,3.577-0.336s1.398-0.263,0.782-0.448
        c-0.614-0.185-1.57-0.336-2.123-0.336c-0.553,0-1.71,0.101-2.571,0.224c-0.861,0.122-2.118,0.375-2.794,0.559
        c-0.676,0.185-0.625,0.385,0.112,0.446C307.045,147.056,308.404,147.006,309.325,146.883z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>
    <path fill="#1e8346" d="M319.498,144.648c0.738,0,1.945,0.099,2.683,0.223c0.738,0.123,1.844-0.079,2.459-0.448
        c0.615-0.368,1.419-0.77,1.788-0.894c0.369-0.123,0.067-0.324-0.671-0.446c-0.738-0.123-2.096-0.224-3.018-0.224
        c-0.923,0-1.928,0-2.236,0c-0.308,0-0.962,0.402-1.454,0.895C318.557,144.244,318.76,144.648,319.498,144.648z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>
    <path fill="#1e8346" d="M330.451,143.418c0.493,0.062,0.895-0.091,0.895-0.336c0-0.246-0.201-0.446-0.446-0.446
        c-0.246,0-0.649,0.15-0.895,0.336C329.759,143.154,329.959,143.356,330.451,143.418z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>
    <path fill="#1e8346" d="M538.467,203.832c-0.06-0.347-0.323-0.494-0.601-0.65c-0.147-0.083-0.328-0.182-0.529-0.339
        c-0.642-0.499-1.613-0.675-2.16-0.362c-0.266,0.147-0.676-0.062-0.881-0.432c-0.336-0.6-0.967-1.336-1.439-1.674
        c-0.489-0.348-1.507-0.622-2.319-0.622c-0.818,0-2.04,0.475-2.782,1.082c-0.529,0.432-1.408,0.535-1.818,0.219
        c-0.527-0.41-1.371-1.253-1.844-1.844c-0.484-0.605-1.124-1.536-1.423-2.077c-0.332-0.597-1.105-1.476-1.76-2
        c-0.484-0.386-1.09-0.626-1.584-0.626c-0.237,0-0.439,0.055-0.654,0.177c-0.151,0-0.463-0.236-0.681-0.759
        c-0.373-0.898-1.319-1.34-2.037-0.947c-0.57,0.318-1.345,0.836-1.727,1.154c-0.251,0.214-0.91,0.249-1.326,0.063
        c-0.517-0.229-1.597-1.069-2.36-1.832l-2.459-2.459c-0.563-0.563-1.48-1.378-2.044-1.815c-0.572-0.446-1.713-1.07-2.544-1.39
        c-0.798-0.308-1.953-0.759-2.566-1.005c-0.486-0.194-1.341-1.032-1.84-1.97c-0.482-0.901-1.465-1.883-2.275-1.965
        c-0.441-0.044-0.816-0.505-0.816-1.007c0-0.715-0.262-1.926-0.584-2.699c-0.34-0.816-1.146-1.726-1.837-2.072
        c-0.665-0.333-1.686-0.554-2.31-0.48c-0.219,0.024-0.381,0.038-0.513,0.052c-0.217,0.02-0.361,0.033-0.52,0.064
        c-0.254,0.05-0.47,0.116-0.802,0.218c-0.205,0.064-0.455,0.142-0.79,0.237c-0.762,0.215-2.069,0.216-2.709,0.004
        c-0.736-0.245-2.073-0.791-2.983-1.216c-0.901-0.42-2.425-1.403-3.396-2.191c-0.902-0.734-2.21-1.557-2.851-1.638
        c-0.194-0.024-0.338-0.045-0.455-0.064c-0.196-0.029-0.326-0.048-0.556-0.053c-0.201,0-0.392,0.02-0.665,0.048
        c-0.191,0.02-0.426,0.043-0.74,0.068c-0.838,0.064-1.807,0.219-2.205,0.351c-0.468,0.156-0.991,0.723-1.214,1.318
        c-0.124,0.334-0.896,0.791-1.742,0.904c-0.836,0.113-2.083-0.369-2.737-1.022c-0.744-0.746-1.742-1.791-2.223-2.333
        c-0.179-0.202-0.299-0.387-0.396-0.538c-0.181-0.283-0.353-0.549-0.782-0.582c-0.34,0-0.547,0.226-0.767,0.468
        c-0.132,0.144-0.282,0.308-0.497,0.48c-0.612,0.493-1.495,1.278-1.968,1.752c-0.263,0.264-1.149,0.544-2.269,0.544
        c-0.21,0-0.422-0.01-0.629-0.033c-1.23-0.129-2.25-0.811-2.395-1.34c-0.176-0.644-0.619-1.041-1.157-1.041
        c-0.244,0-0.489,0.087-0.71,0.251c-0.366,0.274-1.205-0.106-1.728-0.788c-0.659-0.86-1.57-2.414-2.031-3.467
        c-0.465-1.06-1.057-2.84-1.322-3.966c-0.019-0.079-0.026-0.161-0.034-0.241c-0.022-0.216-0.047-0.461-0.214-0.664
        c-0.402-0.488-1.013-0.697-1.553-0.882c-0.28-0.097-0.544-0.187-0.747-0.305c-1.194-0.69-3.137-1.509-4.329-1.823
        c-1.094-0.288-2.857-1.567-3.848-2.793c-0.999-1.236-1.94-2.901-2.054-3.641c-0.133-0.869-0.904-2.317-1.754-3.299
        c-0.791-0.913-2.084-2.605-2.881-3.77c-0.792-1.157-1.886-2.948-2.44-3.991c-0.559-1.055-1.517-2.718-2.137-3.71
        c-0.652-1.042-1.639-2.124-2.245-2.463c-0.598-0.333-1.639-0.645-2.368-0.711c-0.658-0.058-1.953-0.063-2.848,0
        c-0.852,0.06-2.346,0.111-3.33,0.111c-1.026,0-2.644,0.366-3.608,0.813c-0.88,0.411-2.11,0.807-2.686,0.865
        c-0.661,0.067-1.597,0.328-2.128,0.593c-0.411,0.205-1.272,0.298-1.798,0.194l-2.794-0.559c-0.881-0.177-2.526-0.825-3.592-1.417
        c-1.087-0.602-2.507-1.63-3.167-2.29c-0.737-0.737-2.099-1.192-2.995-0.984c-0.867,0.2-1.871,0.681-2.286,1.095
        c-0.406,0.406-0.98,1.442-1.308,2.361c-0.308,0.862-0.754,2.004-0.997,2.548c-0.251,0.563-0.66,1.789-0.912,2.733
        c-0.244,0.919-0.595,2.172-0.779,2.783c-0.182,0.602-0.789,2.174-1.329,3.429c-0.585,1.365-0.938,3.162-0.806,4.092
        c0.127,0.892,0.593,2.342,1.038,3.233c0.162,0.326,0.259,0.591,0.338,0.808c0.138,0.376,0.247,0.674,0.555,0.884
        c0.189,0.129,0.386,0.142,0.556,0.142l0.255-0.004c0.1,0,0.216,0.004,0.351,0.017c0.49,0.045,1.182,0.263,1.484,0.464
        c0.4,0.268,1.172,0.386,1.758,0.386c0.121,0,0.239-0.004,0.423-0.017c0.467,0,0.833,0.41,0.833,0.933
        c0,0.767,0.096,2.249,0.213,3.305c0.111,0.996,0.067,2.338-0.096,2.933c-0.125,0.458-0.921,1.121-1.862,1.344
        c-0.883,0.205-2.196,0.223-2.711,0.042c-0.222-0.077-0.388-0.163-0.524-0.233c-0.271-0.138-0.526-0.248-0.798-0.156
        c-0.431,0.138-0.548,0.587-0.625,0.883c-0.018,0.068-0.034,0.133-0.055,0.188c-0.26,0.715-0.419,2.03-0.355,2.933
        c0.062,0.856,0.112,2.15,0.112,2.883s-0.05,2.026-0.112,2.883c-0.013,0.195-0.009,0.377,0.003,0.542
        c-0.146-0.192-0.397-0.4-0.681-0.634c-0.279-0.229-0.861-0.709-0.866-0.892v-2.57c0-0.851,0.05-2.295,0.111-3.218
        c0.058-0.874,0.299-1.993,0.525-2.444c0.23-0.46,0.867-1.289,1.391-1.813c0.577-0.576,1.057-1.424,0.856-1.927
        c-0.173-0.432-0.722-0.928-1.151-1.173c-0.45-0.256-1.322-0.666-1.942-0.914c-0.204-0.082-0.341-0.185-0.501-0.305
        c-0.273-0.206-0.583-0.439-1.241-0.602c-0.948-0.239-1.755-0.842-1.887-1.236c-0.192-0.575-0.608-1.46-0.929-1.97
        c-0.358-0.575-1.293-1.133-2.129-1.272c-0.845-0.137-2.109,0.302-2.851,0.977c-0.744,0.675-1.38,1.803-1.451,2.565
        c-0.061,0.669-0.258,1.905-0.441,2.754c-0.227,1.06,0.18,2.02,0.926,2.186c0.584,0.129,1.53,0.132,2.054-0.002
        c0.326-0.074,0.724,0.094,0.841,0.367c0.164,0.381,0.254,1.228,0.197,1.849c-0.058,0.644-0.307,1.785-0.543,2.495
        c-0.236,0.704-0.984,2.098-1.634,3.046c-0.672,0.977-1.705,1.71-2.213,1.755c-0.57,0.044-1.119-0.219-1.227-0.469
        c-0.194-0.451-0.758-1.428-1.258-2.177c-0.465-0.697-1.231-1.529-1.739-1.697c-0.463-0.153-1.057-0.089-1.504,0.173
        c-0.169,0-0.461-0.102-0.729-0.333c-0.366-0.314-0.73-0.896-0.781-1.246c-0.058-0.402-0.057-1.236,0.001-1.818
        c0.04-0.396,0.38-0.706,0.854-0.706c0.003,0,0.006,0,0.009,0c0.71,0.068,1.605-0.165,2.036-0.524
        c0.417-0.348,0.953-1.25,1.221-2.053c0.245-0.735,0.309-1.76-0.057-2.215c-0.287-0.358-0.847-0.749-1.273-0.891
        c-0.204-0.068-0.613-0.579-0.788-1.223c-0.195-0.714-0.722-1.77-1.176-2.353c-0.424-0.547-1.062-1.527-1.424-2.19
        c-0.361-0.66-0.8-1.685-0.979-2.283c-0.174-0.581-0.321-1.612-0.321-2.25c0-0.82-0.881-1.792-2.006-2.214
        c-1.017-0.381-3.025-0.691-4.478-0.691c-1.444,0-3.445,0.256-4.459,0.573c-0.969,0.302-2.489,0.596-3.389,0.656
        c-0.936,0.062-2.305,0.214-3.051,0.34c-0.76,0.126-2.93,0.63-4.835,1.123c-1.058,0.273-2.232,0.436-3.141,0.436
        c-0.83,0-1.116-0.133-1.184-0.175c-0.74-0.445-3.097-0.884-4.95-1.054c-2.008-0.183-4.182-0.288-4.762-0.223
        c-0.605,0.067-1.651,0.482-2.377,0.944c-0.518,0.329-1.36,0.925-1.554,1.216c-0.082,0.12-0.643,0.613-1.371,1.144
        c-0.698,0.509-1.728,1.488-2.297,2.182c-0.552,0.676-1.35,1.248-1.722,1.341c-0.59,0.147-1.279,0.77-1.566,1.418
        c-0.312,0.703-0.054,1.593,0.575,1.985c0.441,0.275,1.051,0.981,1.331,1.544c0.244,0.489,0.069,1.284-0.383,1.737
        c-0.445,0.444-1.204,0.722-1.546,0.593c-0.627-0.233-1.52,0.135-1.962,0.799c-0.38,0.57-1.094,1.945-1.592,3.067
        c-0.509,1.144-0.923,3.16-0.923,4.495c0,1.303,0.051,2.824,0.114,3.39c0.067,0.611,0.533,1.707,1.06,2.497
        c0.557,0.833,1.644,1.408,2.463,1.261c0.663-0.121,2.248-0.27,3.532-0.331c1.275-0.059,2.89,0.092,3.567,0.318
        c0.746,0.247,1.383,0.675,1.495,0.861c0.083,0.136,0.16,0.236,0.224,0.316c0.017,0.023,0.037,0.048,0.051,0.051
        c-0.027,0.03-0.075,0.076-0.117,0.114c-0.098,0.09-0.219,0.201-0.352,0.362l-1.117,1.341c-0.366,0.437-0.595,1.238-0.523,1.82
        c0.045,0.362-0.195,1.18-0.614,1.779c-0.163,0.233-0.303,0.401-0.415,0.538c-0.192,0.231-0.344,0.415-0.416,0.686
        c-0.111,0.415-0.045,0.779,0.044,1.284c0.055,0.307,0.123,0.683,0.169,1.18c0.12,1.256-0.125,3.354-0.544,4.673
        c-0.403,1.266-0.635,3.033-0.326,3.803c0.229,0.572,0.423,2.255,0.423,3.676v4.248c0,0.82-0.351,2.616-0.767,3.922
        c-0.417,1.312-1.121,3.022-1.535,3.733c-0.426,0.731-1.47,2.273-2.328,3.436c-0.874,1.187-1.992,3.017-2.492,4.081
        c-0.506,1.075-1.071,3.583-1.26,5.588c-0.185,1.974-0.386,5.143-0.448,7.064c-0.067,2.065-0.509,3.735-0.797,4.021
        c-0.303,0.304-0.959,0.339-1.365,0.07c-0.604-0.402-1.745-0.679-2.561-0.615c-0.692,0.054-1.735-0.142-2.28-0.414
        c-0.659-0.331-1.963-0.554-2.967-0.48c-0.906,0.062-2.293-0.045-3.031-0.215c-0.889-0.211-2.237,0.133-2.971,0.738
        c-0.691,0.564-1.273,1.521-0.99,2.18c0.151,0.353,0.199,0.921,0.101,1.214c-0.176,0.528,0.055,1.397,0.361,1.945
        c0.217,0.391-0.085,1.002-0.658,1.336c-0.854,0.499-1.417,2.21-1.283,3.893c0.129,1.619,0.984,3.578,1.904,4.365
        c0.915,0.785,2.517,1.423,3.572,1.423c1.085,0,1.902,0.176,2.034,0.269c0.311,0.233,1.539,0.772,2.54,1.162
        c1.188,0.461,2.785,0.334,3.475-0.288c0.442-0.398,1.178,0,1.603,0.803c0.566,1.069,1.434,2.447,1.935,3.07
        c0.444,0.556,0.82,1.74,0.82,2.584c0,0.855-0.335,2.103-0.73,2.727c-0.448,0.704-1.019,2.153-1.273,3.232
        c-0.267,1.135-0.106,2.711,0.366,3.589c0.157,0.292,0.245,0.544,0.322,0.768c0.131,0.38,0.255,0.738,0.666,0.851
        c0.415,0.114,0.76-0.135,1.126-0.441c0.213-0.179,0.455-0.38,0.791-0.581c0.824-0.491,2.426-0.823,3.519-0.696
        c1.12,0.131,2.964,0.197,4.084,0.123c1.118-0.07,2.816-0.524,3.787-1.009c0.941-0.471,2.537-1.29,3.547-1.822
        c1.018-0.536,2.182-1.202,2.593-1.485c0.425-0.291,1.306-1.12,2.005-1.89c0.625-0.688,1.894-1.482,2.771-1.732
        c1.022-0.292,2.226-1.099,2.743-1.836c0.473-0.676,1.026-1.505,1.233-1.849c0.309-0.514,0.038-1.448-0.374-2.065
        c-0.342-0.512-0.495-1.402-0.327-1.903c0.165-0.496,0.613-0.869,0.901-0.869c0.439,0,1.627,0.219,2.596,0.477
        c0.864,0.23,1.645,1.32,1.704,2.377c0.073,1.33,1.429,3.16,3.087,4.171c1.58,0.96,4.426,1.965,6.345,2.239
        c1.94,0.278,3.764,1.002,4.186,1.477c0.477,0.537,0.685,1.285,0.552,1.595c-0.164,0.385-0.703,0.87-1.151,1.037
        c-0.705,0.266-1.067,1.111-0.81,1.883c0.16,0.479-0.145,1.345-0.651,1.852c-0.591,0.591-1.165,1.535-1.069,2.121
        c0.051,0.303-0.08,1.104-0.346,1.771c-0.317,0.792-0.022,2.018,0.673,2.787c0.672,0.747,2.013,1.331,3.054,1.331
        c0.389,0,0.703,0.116,0.818,0.302c0.072,0.117,0.065,0.262-0.021,0.434c-0.366,0.729-0.595,1.987-0.522,2.86
        c0.023,0.278-0.045,0.519-0.194,0.679c-0.152,0.164-0.375,0.233-0.631,0.184c-0.76-0.137-1.842-0.136-2.347,0.007
        c-0.568,0.164-1.253,0.894-1.56,1.661c-0.313,0.781-0.187,1.889,0.286,2.52c0.407,0.546,1.181,1.484,1.724,2.094
        c0.624,0.703,1.969,0.784,2.876,0.178c0.807-0.537,2.053-1.296,2.779-1.691c0.62-0.339,1.768-0.392,2.383-0.112
        c0.812,0.368,1.867,0.488,2.362,0.239c0.36-0.18,1.128-0.331,1.679-0.331c0.798,0,1.883-0.879,2.471-1.729
        c0.56-0.81,1.654-1.702,2.391-1.946c0.518-0.173,1.202-0.284,1.744-0.284c0.262,0,0.419,0.026,0.506,0.048
        c0.535,0.135,1.737,0.463,2.672,0.729c0.27,0.077,0.553,0.117,0.843,0.117c0.86,0,1.613-0.332,1.967-0.864
        c0.378-0.566,1.174-1.361,1.738-1.736c0.619-0.414,1.462-1.09,1.879-1.506c0.465-0.465,0.83-1.266,0.83-1.822
        c0-0.591-0.518-1.849-1.02-2.726c-0.541-0.945-0.737-2.142-0.581-2.662c0.216-0.718,0.276-2.595,0.135-4.272
        c-0.133-1.602-0.243-3.559-0.243-4.362c0-0.934-0.989-2.189-2.251-2.857c-1.173-0.62-2.748-1.237-3.512-1.375
        c-0.568-0.102-1.065-0.575-1.065-1.011c0-0.542,0.367-1.576,0.802-2.259c0.37-0.582,1.225-0.93,1.798-0.717
        c0.772,0.282,1.966,0.401,2.626,0.255c0.714-0.16,1.569-1.055,1.947-2.037c0.312-0.812,1.046-1.862,1.6-2.294
        c0.491-0.382,1.344-0.592,1.749-0.439c0.61,0.229,2.08,0.112,3.261-0.236c1.214-0.357,2.381-0.597,2.71-0.597
        c0.238,0,0.97,0.432,1.581,1.11c0.553,0.615,1.072,1.917,1.133,2.846c0.067,1.003,0.121,2.636,0.121,3.637
        c0,1.159,0.941,2.291,2.099,2.523l3.657,0.732c1.057,0.21,2.827-0.029,3.866-0.513c0.971-0.455,2.228-0.947,2.744-1.075
        c0.742-0.186,1.552-1.311,1.937-2.274c0.378-0.945,1.225-2.166,1.849-2.667c0.655-0.524,1.722-1.218,2.379-1.547
        c0.517-0.26,1.42,0.045,1.908,0.644c0.559,0.683,0.818,1.611,0.677,1.987c-0.221,0.588-0.336,1.566-0.263,2.228
        c0.063,0.563,0.009,1.519-0.116,2.084c-0.125,0.564-0.607,1.633-1.053,2.332c-0.492,0.773-1.013,1.491-1.175,1.651
        c-0.122,0.123-0.79,0.436-1.557,0.646c-0.833,0.226-1.729,0.892-2.041,1.515c-0.282,0.562-0.624,1.755-0.763,2.66
        c-0.152,0.989,0.323,2.299,1.084,2.983c0.644,0.581,1.512,1.555,1.895,2.131c0.355,0.534,0.493,1.012,0.488,1.091
        c-0.214,0.321-0.37,1.365-0.421,1.989c-0.054,0.641-0.585,1.226-1.159,1.278c-0.524,0.048-0.969,0.278-1.22,0.631
        c-0.187,0.264-0.249,0.578-0.173,0.881c0.134,0.537,0.295,1.341,0.36,1.795c0.092,0.651,0.917,1.141,1.917,1.141
        c0.803,0,2.047,0.055,2.774,0.122c0.616,0.056,1.344,0.481,1.559,0.913c0.282,0.563,1.071,1.636,1.759,2.392
        c0.593,0.654,0.799,1.476,0.586,1.775c-0.401,0.561-0.418,1.771-0.04,2.754c0.318,0.828,0.588,2.058,0.588,2.684v1.951
        c0,0.547,0.423,1.115,0.96,1.294c0.526,0.176,1.191,0.013,1.472-0.361c0.012-0.014,0.101-0.068,0.312-0.068
        c0.228,0,0.5,0.063,0.747,0.173c0.478,0.212,0.898,0.753,0.898,1.157c0,0.49-0.211,1.376-0.459,1.936
        c-0.157,0.355-0.152,0.692,0.016,0.949c0.214,0.329,0.63,0.475,1.205,0.371c0.623-0.117,1.415,0.232,1.735,0.71
        c0.349,0.523,0.441,1.62,0.203,2.399c-0.148,0.478-0.076,0.947,0.199,1.321c0.328,0.443,0.904,0.697,1.584,0.697
        c1.066,0,2.586,0.054,3.387,0.121c0.639,0.054,1.225,0.693,1.279,1.399c0.064,0.835,0.009,2.078-0.117,2.714
        c-0.127,0.641-0.452,1.668-0.709,2.245c-0.255,0.575-0.947,1.658-1.51,2.366c-0.224,0.282-0.413,0.48-0.568,0.645
        c-0.257,0.271-0.46,0.485-0.559,0.808c-0.087,0.285-0.045,0.538,0.006,0.859c0.031,0.187,0.068,0.416,0.092,0.719
        c0.06,0.775,0.06,1.872,0.001,2.397c-0.052,0.473-0.373,1.162-0.686,1.476c-0.493,0.494-0.457,1.425,0.084,2.119
        c0.158,0.205,0.261,0.38,0.345,0.524c0.151,0.259,0.294,0.504,0.604,0.597c0.251,0.074,0.488-0.016,0.736-0.137
        c0.123-0.06,0.273-0.133,0.473-0.2c0.567-0.19,1.63-0.595,2.37-0.904c0.539-0.224,1.097-0.017,1.224,0.45
        c0.23,0.841,1.05,1.361,1.771,1.124c0.223-0.074,0.39-0.137,0.524-0.189c0.189-0.07,0.303-0.114,0.428-0.133
        c0.138-0.021,0.264-0.013,0.452-0.004c0.14,0.006,0.312,0.014,0.54,0.017c0.519,0.005,1.592,0.38,2.344,0.818
        c0.753,0.439,1.817,1.504,2.322,2.326c0.555,0.901,0.849,1.838,0.808,2.086c-0.004,0.028-0.019,0.111-0.207,0.111
        c-0.121,0-0.266-0.039-0.41-0.112c-0.722-0.358-1.496,0.014-1.61,0.757c-0.034,0.223-0.053,0.392-0.067,0.528
        c-0.021,0.188-0.033,0.302-0.07,0.407c-0.065,0.185-0.164,0.338-0.329,0.592c-0.114,0.178-0.254,0.396-0.427,0.693
        c-0.49,0.838-0.446,2.399,0.097,3.554c0.477,1.012,0.978,2.264,1.095,2.732c0.126,0.503,0.382,1.321,0.571,1.826
        c0.078,0.209,0.156,0.364,0.221,0.49c0.055,0.107,0.102,0.2,0.097,0.236c-0.012,0.081-0.085,0.158-0.248,0.326
        c-0.13,0.132-0.288,0.294-0.467,0.526c-0.383,0.492-1.162,0.999-1.701,1.106c-0.779,0.155-1.389,0.986-1.389,1.893
        c0,0.586-0.363,1.221-0.777,1.359c-0.195,0.065-0.372,0.088-0.516,0.107c-0.263,0.035-0.661,0.089-0.716,0.502
        c-0.078,0.573,0.43,0.789,0.922,0.998c0.301,0.128,0.642,0.274,1.002,0.514c1.006,0.672,2.555,1.443,3.452,1.718
        c0.802,0.246,1.896,1.027,2.389,1.706c0.419,0.575,1.097,0.933,1.769,0.933c0.316,0,0.617-0.08,0.871-0.233
        c0.749-0.449,1.511-1.448,1.736-2.271c0.254-0.933-0.061-1.809-0.719-1.996c-0.391-0.112-0.93-0.504-1.154-0.84
        c-0.014-0.021-0.016-0.068-0.004-0.124c0.06-0.285,0.437-0.793,1.126-1.237c0.956-0.615,2.064-1.392,2.472-1.732
        c0.202-0.168,0.763-0.021,1.23,0.562c0.708,0.884,1.536,1.173,1.871,0.723c0.284-0.377,0.433-1.431,0.433-2.274
        c0-0.665,0.296-1.13,0.597-1.13h0.002c0.397,0.05,1.205,0.562,1.729,1.218c0.196,0.245,0.321,0.463,0.43,0.655
        c0.188,0.327,0.365,0.638,0.766,0.688c0.013,0.001,0.092,0.006,0.106,0.006c0.382,0,0.629-0.253,0.914-0.547
        c0.182-0.187,0.39-0.4,0.686-0.622c0.614-0.461,1.6-1.294,1.796-1.688c0.066-0.073,0.444-0.211,1.059-0.211
        c0.108,0,0.219,0.004,0.331,0.013c0.242,0.02,0.495,0.03,0.74,0.03c0.246,0,1.08-0.013,1.485-0.175
        c0.281-0.113,1.153-0.327,2.004-0.468c0.89-0.148,1.889-0.629,2.275-1.092c0.376-0.449,0.729-1.219,0.806-1.753
        c0.042-0.295,0.448-0.704,0.962-0.817c0.264-0.059,0.558-0.092,0.808-0.092c0.253,0,0.373,0.033,0.395,0.043
        c0.353,0.234,0.964-0.006,1.422-0.552c0.28-0.338,0.865-0.683,1.251-0.738c0.619-0.088,1.464-0.752,1.926-1.345
        c0.457-0.589,1.418-1.55,2.142-2.141c0.785-0.642,1.656-1.976,1.94-2.973c0.266-0.933,0.836-1.642,1.111-1.72
        c0.4-0.113,1.319-0.112,1.915,0.006c0.618,0.123,1.84,0.765,2.667,1.4c0.939,0.722,2.761,0.792,3.905,0.148
        c0.66-0.372,1.468-0.602,2.111-0.602c0.26,0,0.476,0.04,0.608,0.116c0.371,0.204,0.752,0.148,0.992-0.136
        c0.288-0.339,0.371-0.977,0.236-1.798c-0.177-1.061,0.303-2.297,1.047-2.698c0.321-0.171,0.6-0.268,0.847-0.351
        c0.415-0.142,0.807-0.275,0.906-0.726c0.099-0.455-0.205-0.744-0.527-1.052c-0.191-0.182-0.408-0.391-0.63-0.685
        c-0.582-0.774-0.828-1.738-0.685-2.121c0.25-0.669-0.006-1.583-0.583-2.078c-0.418-0.358-0.871-1.114-0.989-1.649
        c-0.148-0.659-0.821-1.845-1.534-2.703c-0.657-0.787-1.004-1.829-0.905-2.273c0.138-0.631,0.197-2.15,0.129-3.384
        c-0.066-1.169,0.371-2.253,0.803-2.493c0.696-0.387,1.36-1.512,1.51-2.563c0.128-0.9,0.017-2.152-0.441-2.701
        c-0.25-0.3-0.53-1.287-0.53-2.223c0-1.031-0.467-2.361-1.061-3.027c-0.175-0.196-0.241-0.407-0.195-0.611
        c0.054-0.233,0.258-0.439,0.562-0.566c0.9-0.375,1.786-1.214,2.017-1.91c0.152-0.454,0.917-1.235,1.774-1.631
        c0.776-0.356,1.969-0.587,2.598-0.464c0.691,0.137,1.979,0.25,2.87,0.25c0.88,0,2.646-0.111,3.937-0.245
        c1.292-0.136,3.021-0.47,3.852-0.747c0.981-0.328,1.515-1.394,1.188-2.376c-0.216-0.649,0.102-1.471,0.694-1.795
        c0.738-0.401,1.901-1.121,2.593-1.604c0.754-0.528,1.405-1.545,1.483-2.314c0.08-0.806-0.423-1.752-1.146-2.155
        c-0.464-0.256-0.672-1.025-0.455-1.674c0.242-0.729,0.958-1.65,1.561-2.012c0.46-0.277,0.764-0.69,0.837-1.135
        c0.057-0.351-0.038-0.69-0.268-0.958c-0.385-0.449-1.15-1.598-1.672-2.508c-0.552-0.967-0.717-1.97-0.592-2.263
        c0.275-0.642-0.05-1.722-0.522-2.397c-0.282-0.401-0.323-0.748-0.274-0.924c0.031-0.107,0.089-0.143,0.131-0.161
        c0.47-0.201,1.17-0.471,1.559-0.6c0.549-0.183,0.962-0.686,0.962-1.172c0-0.509-0.558-1.196-1.111-1.61
        c-0.507-0.38-1.873-1.915-2.982-3.353c-1.189-1.536-1.872-3.379-1.765-4.137c0.113-0.784,0.672-1.565,1.197-1.671
        c0.283-0.057,0.509-0.237,0.64-0.509c0.273-0.57,0.116-1.541-0.407-2.536c-0.714-1.357-1.252-2.679-1.308-3.061
        c-0.051-0.366,0.128-1.389,0.469-2.272c0.236-0.613,0.22-1.208-0.042-1.633c-0.187-0.304-0.489-0.492-0.847-0.528
        c-0.511-0.052-1.547-0.665-2.214-1.466c-0.641-0.768-1.453-2.121-1.775-2.955c-0.351-0.913-1.374-2.337-2.279-3.174
        c-0.871-0.804-1.477-1.93-1.477-2.436c0-0.74-0.346-1.84-0.788-2.503c-0.366-0.548-0.675-1.412-0.675-1.887
        c0-0.543-0.055-1.703-0.123-2.587c-0.045-0.605,0.881-1.461,2.287-1.717c0.694-0.126,1.422-0.195,2.049-0.195
        c0.711,0,1.038,0.091,1.119,0.145c0.282,0.189,0.68,0.228,0.964,0.228c0.499,0,1.062-0.117,1.547-0.318
        c0.849-0.353,2.003-1.219,2.571-1.93c0.549-0.688,1.33-1.859,1.74-2.61c0.356-0.655,1.37-1.36,2.215-1.54
        c0.24-0.051,0.492-0.079,0.727-0.079c0.636,0,0.933,0.18,0.976,0.266c0.296,0.596,1.318,0.917,2.128,0.917
        c0.773,0,2.133,0.164,2.972,0.357c0.802,0.186,1.781,0.695,2.139,1.112c0.332,0.386,1.031,0.468,1.559,0.468
        c0.68,0,1.466-0.13,2.211-0.367c1.506-0.48,3.681-1.54,4.846-2.363c1.142-0.806,2.68-1.905,3.419-2.441
        c0.838-0.611,1.433-1.76,1.356-2.617c-0.062-0.681,0.148-1.833,0.459-2.514c0.26-0.571,0.634-0.803,0.887-0.815
        c0.559,0.283,1.456-0.215,2.009-1.097c0.169-0.27,0.336-0.461,0.48-0.63C538.305,204.493,538.536,204.224,538.467,203.832z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_16_" x="381.321" y="220.997" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" strokeMiterlimit="10" enable-background="new    " width="57.692" height="20.279" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_GERMANY_THURINGEN" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Thuringia" data-infoText="<b>Thuringia</b> &amp;nbsp; Capital: Erfurt">
    <path fill="#1e8346" d="M597.298,379.849c-0.604-0.738-1.427-1.835-1.829-2.439c-0.404-0.603-1.392-1.153-2.195-1.219
        c-0.806-0.067-1.794-0.944-2.195-1.951c-0.403-1.006-1.226-1.938-1.829-2.072c-0.603-0.134-1.756-0.959-2.561-1.83
        c-0.804-0.871-2.341-1.749-3.414-1.951c-1.073-0.2-2.172-0.695-2.439-1.098c-0.269-0.404-0.708-0.732-0.974-0.732
        c-0.269,0-1.256,0.549-2.195,1.219c-0.939,0.67-1.417,0.782-1.707,1.219c-0.156,0.234-0.111,0.494-0.245,1.097
        c-0.133,0.604,0.141,1.316,0.611,1.585s1.072,0.761,1.341,1.096c0.269,0.337,0.103,1.05-0.367,1.586
        c-0.469,0.537-1.126,1.305-1.462,1.707c-0.337,0.404-0.665,1.172-0.732,1.708s-0.342,1.468-0.61,2.072
        c-0.269,0.603-0.654,0.769-0.854,0.367c-0.2-0.403-0.86-1.006-1.463-1.341c-0.604-0.337-1.482-0.391-1.951-0.123
        c-0.47,0.269-1.512,0.16-2.317-0.244c-0.805-0.402-1.683-0.566-1.951-0.366c-0.269,0.2-1.037,0.366-1.708,0.366
        s-1.713-0.604-2.317-1.341c-0.604-0.738-1.866-1.725-2.805-2.195c-0.939-0.469-2.091-1.183-2.56-1.585
        c-0.47-0.403-1.184-0.73-1.586-0.73s-1.83,0.219-3.172,0.488c-1.34,0.269-2.876-0.611-3.414-1.952
        c-0.537-1.34-1.141-2.987-1.341-3.657s-1.243-1.219-2.316-1.219c-1.074,0-2.172,0.274-2.439,0.61
        c-0.269,0.337-1.203,0.391-2.074,0.123c-0.871-0.269-2.353-0.215-3.292,0.121c-0.939,0.337-2.147,0.336-2.683,0
        c-0.537-0.337-1.086-1.158-1.221-1.829c-0.133-0.671-0.188-1.878-0.121-2.683c0.067-0.804-0.262-1.791-0.732-2.195
        c-0.47-0.402-1.458-0.786-2.195-0.852c-0.738-0.067-1.506-0.561-1.707-1.098c-0.2-0.537-0.2-1.36,0-1.83
        c0.2-0.469,0.804-1.126,1.341-1.462c0.537-0.337,1.468-1.104,2.072-1.708c0.603-0.604,0.769-1.646,0.367-2.316
        c-0.403-0.671-0.95-1.823-1.219-2.561s-0.761-1.78-1.096-2.317c-0.337-0.537-1.27-1.25-2.074-1.585
        c-0.804-0.336-1.902-0.993-2.439-1.463c-0.537-0.47-1.469-0.799-2.074-0.733c-0.604,0.067-1.702-0.043-2.439-0.244
        c-0.738-0.2-2-0.255-2.805-0.122s-2.231-0.085-3.17-0.489c-0.939-0.402-2.255-0.621-2.927-0.488
        c-0.67,0.135-1.658-0.304-2.195-0.974s-0.921-1.878-0.852-2.683c0.067-0.805-0.152-1.957-0.488-2.561
        c-0.337-0.603-0.611-1.702-0.611-2.439s-0.329-1.78-0.73-2.317c-0.404-0.537-0.732-1.414-0.732-1.952
        c0-0.535,0.274-0.974,0.61-0.974c0.337,0,0.665-0.604,0.732-1.341c0.067-0.738-0.482-1.452-1.219-1.585
        c-0.738-0.133-2.055-0.464-2.926-0.73c-0.873-0.269-1.805-0.709-2.073-0.977c-0.269-0.268-1.365-0.377-2.438-0.244
        c-1.074,0.135-2.5,0.025-3.172-0.244c-0.67-0.268-0.892-0.505-1.219-0.488c-0.45,0.027-0.713,0.384-1.585,0.854
        c-0.872,0.469-1.42,1.84-1.219,3.047c0.2,1.208-0.128,1.922-0.733,1.585c-0.603-0.336-1.975-0.116-3.048,0.489
        c-1.072,0.604-2.664,0.549-3.535-0.122c-0.873-0.671-2.135-1.33-2.806-1.463c-0.671-0.133-1.603-0.133-2.072,0
        c-0.47,0.133-1.074,1.012-1.341,1.951c-0.269,0.939-1.092,2.202-1.83,2.806c-0.738,0.602-1.725,1.59-2.194,2.194
        c-0.47,0.603-1.238,1.153-1.708,1.219c-0.469,0.067-1.126,0.45-1.462,0.854c-0.337,0.402-0.776,0.621-0.977,0.488
        c-0.2-0.133-0.86-0.133-1.462,0c-0.604,0.133-1.153,0.627-1.22,1.098c-0.067,0.47-0.396,1.183-0.733,1.585
        c-0.336,0.404-1.268,0.842-2.072,0.976c-0.805,0.133-1.738,0.353-2.073,0.488c-0.336,0.133-1.268,0.189-2.072,0.122
        c-0.805-0.067-1.573,0.098-1.708,0.367c-0.133,0.268-0.902,0.982-1.707,1.585c-0.805,0.604-1.222,0.709-1.463,1.098
        c-0.261,0.422-0.164,0.878-0.366,1.952c-0.201,1.072,0.183,2.718,0.852,3.657c0.67,0.939,1.604,1.98,2.073,2.316
        c0.469,0.337,1.347,1.159,1.951,1.83c0.603,0.671,1.427,1.932,1.83,2.805c0.402,0.873,1.774,1.75,3.049,1.952
        c1.275,0.2,3.085,0.969,4.024,1.707s1.433,1.725,1.096,2.194c-0.336,0.47-0.505,1.307-0.38,1.861
        c0.124,0.553-0.314,1.048-0.976,1.097c-0.661,0.05-1.588-0.292-2.058-0.762s-0.964-0.47-1.098,0
        c-0.135,0.47,0.03,1.183,0.367,1.585c0.336,0.404,0.39,1.83,0.121,3.17c-0.268,1.341-1.037,1.884-0.756,2.439
        c0.591,1.163,4.329,1.869,4.416,3.172c0.029,0.431-0.549,0.549-1.22,1.219c-0.671,0.669-2.043,0.944-3.048,0.61
        c-1.006-0.336-2.598-0.719-3.536-0.852c-0.939-0.134-1.817,0.304-1.952,0.974c-0.133,0.671-0.08,1.878,0.122,2.683
        c0.201,0.804-0.128,1.737-0.73,2.072c-0.604,0.337-1.483,0.445-1.952,0.245s-0.853,0.128-0.853,0.732
        c0,0.602,0.604,1.152,1.341,1.218c0.738,0.067,1.725,0.45,2.194,0.854c0.469,0.402,0.909,1.169,0.976,1.707
        c0.067,0.537-0.208,0.976-0.61,0.976s-0.677,0.659-0.61,1.463c0.067,0.804-0.756,1.902-1.829,2.439
        c-1.072,0.537-2.336,1.634-2.805,2.439c-0.469,0.804-0.854,2.176-0.854,3.048s-0.164,2.298-0.366,3.17
        c-0.201,0.872-0.586,1.859-0.854,2.195s-0.707,1.048-0.975,1.585c-0.269,0.537-0.433,1.469-0.366,2.072
        c0.067,0.604,0.781,1.428,1.586,1.83c0.805,0.402,1.738,0.732,2.072,0.732c0.336,0,0.445-0.384,0.244-0.854
        s0.292-1.403,1.098-2.073c0.805-0.671,1.902-1.33,2.439-1.463c0.536-0.133,1.249,0.085,1.585,0.488
        c0.336,0.404,0.884,1.226,1.22,1.83c0.336,0.603,0.664,1.427,0.731,1.83c0.067,0.402-0.208,0.676-0.61,0.61
        c-0.402-0.067-0.896,0.317-1.098,0.852c-0.201,0.537-0.256,1.196-0.122,1.465c0.134,0.268,0.025,0.708-0.244,0.974
        c-0.269,0.269-0.214,0.872,0.122,1.341c0.336,0.47,0.499,1.183,0.366,1.585c-0.134,0.404-0.244,1.281-0.244,1.952
        s-0.281,1.103,0,1.219c0.358,0.148,0.494-0.494,1.098-1.097c0.603-0.603,1.316-1.427,1.585-1.83
        c0.269-0.402,0.708-0.676,0.976-0.61c0.269,0.067,0.926,0.231,1.463,0.366s1.249,0.19,1.585,0.123
        c0.336-0.068,0.939,0.26,1.341,0.73s0.898,1.238,1.098,1.708c0.2,0.469,1.188,1.074,2.194,1.341
        c1.007,0.269,2.159,0.652,2.562,0.852c0.402,0.2,0.73,0.639,0.73,0.976c0,0.336,0.494,1.378,1.098,2.316
        c0.603,0.939,1.316,2.257,1.585,2.928c0.269,0.671,0.816,1.275,1.218,1.341c0.404,0.067,1.116,0.122,1.586,0.122
        s1.019,0.329,1.22,0.73c0.2,0.404,0.914,1.226,1.585,1.829s1.769,1.098,2.439,1.098c0.67,0,1.219,0.383,1.219,0.854
        c0,0.47,0.439,1.02,0.976,1.22c0.536,0.2,1.14,1.024,1.341,1.829c0.2,0.804,0.366,2.177,0.366,3.048
        c0,0.873-0.055,2.135-0.122,2.806s0.756,1.219,1.829,1.219c1.074,0,2.501,0.219,3.17,0.488c0.671,0.269,1.493-0.171,1.829-0.976
        c0.337-0.805,1.214-1.353,1.952-1.218c0.738,0.133,1.835,0.299,2.439,0.366s1.098-0.372,1.098-0.976s-0.219-1.372-0.488-1.709
        c-0.268-0.336-0.598-0.829-0.732-1.097c-0.133-0.268-0.792-0.542-1.463-0.61c-0.67-0.067-1.441-0.45-1.708-0.852
        c-0.269-0.404-0.761-0.897-1.098-1.097c-0.336-0.2-0.61-1.08-0.61-1.952c0-0.871,0.439-1.475,0.976-1.341
        c0.537,0.135,1.196-0.249,1.464-0.853c0.268-0.603,0.816-1.042,1.218-0.975c0.404,0.067,1.061-0.098,1.465-0.367
        c0.402-0.268,1.169-0.597,1.706-0.731c0.537-0.133,1.36-0.078,1.829,0.123c0.47,0.201,1.348,0.366,1.952,0.366
        s1.371,0.329,1.708,0.733c0.336,0.402,0.719,1.005,0.852,1.341s0.572,0.337,0.976,0c0.402-0.336,1.224-0.226,1.829,0.244
        c0.604,0.469,1.866,0.58,2.805,0.244c0.939-0.337,2.037-0.445,2.439-0.244c0.402,0.2,0.787,0.86,0.854,1.462
        c0.067,0.604-0.043,1.647-0.245,2.317c-0.2,0.67,0.019,1.439,0.488,1.707c0.47,0.269,1.403,0.489,2.073,0.489
        s1.713,0.165,2.317,0.366c0.603,0.2,1.371-0.183,1.708-0.854c0.336-0.669,0.664-1.712,0.73-2.316
        c0.067-0.604,0.45-1.371,0.854-1.708c0.402-0.336,0.458-1.158,0.121-1.829c-0.336-0.671-0.719-1.823-0.852-2.561
        s-0.299-1.945-0.366-2.683s-0.122-2.109-0.122-3.048s0.274-1.761,0.61-1.829c0.337-0.068,0.939-0.562,1.341-1.098
        c0.404-0.537,1.226-1.306,1.829-1.708c0.603-0.404,1.647-0.676,2.317-0.61c0.671,0.067,1.547,0.505,1.951,0.976
        c0.402,0.469,0.567,1.73,0.367,2.803c-0.2,1.074,0.402,2.391,1.341,2.928c0.939,0.537,1.652,1.414,1.585,1.951
        c-0.067,0.537,0.317,1.14,0.854,1.341c0.536,0.2,1.193,0.751,1.462,1.221c0.268,0.469,1.366,0.469,2.439,0
        c1.074-0.47,2.611-0.744,3.414-0.611s2.177,0.079,3.048-0.122c0.872-0.2,2.245-0.695,3.05-1.097
        c0.805-0.402,1.847-0.842,2.316-0.976c0.47-0.133,0.964,0.25,1.097,0.854s0.683,0.988,1.219,0.854
        c0.537-0.135,1.58-0.519,2.317-0.854c0.738-0.337,1.341-1.214,1.341-1.952s0.494-1.506,1.098-1.707s0.952-0.117,1.098-0.366
        c0.172-0.293-0.111-0.603-0.244-1.341c-0.135-0.738-0.245-1.891-0.245-2.561s0.274-1.713,0.61-2.317
        c0.337-0.603,1.159-1.262,1.83-1.463c0.67-0.2,1.713-0.092,2.317,0.245c0.603,0.336,1.262,0.005,1.463-0.733
        c0.2-0.738,0.804-1.506,1.341-1.707s1.25-0.367,1.585-0.367c0.336,0,1.103-0.274,1.707-0.611c0.603-0.336,1.042-1.103,0.976-1.706
        c-0.067-0.603,0.152-1.481,0.489-1.952c0.336-0.47,1.377-1.019,2.316-1.219c0.939-0.2,2.255-0.639,2.926-0.976
        c0.67-0.336,1.384-1.158,1.585-1.829c0.201-0.671-0.237-1.549-0.974-1.952c-0.738-0.402-1.341-1.225-1.341-1.829
        s-0.329-1.208-0.73-1.341c-0.404-0.133-0.293-0.792,0.244-1.463c0.536-0.67,0.647-1.713,0.244-2.317
        c-0.402-0.602-0.622-1.535-0.489-2.072s1.121-1.086,2.194-1.22c1.074-0.133,2.445-0.738,3.05-1.341
        c0.604-0.603,1.536-0.987,2.072-0.852c0.537,0.135,1.525-0.47,2.195-1.341c0.67-0.872,1.823-1.915,2.561-2.318
        c0.738-0.402,1.78-0.676,2.317-0.609s1.853,0.011,2.927-0.122s2.5-0.792,3.17-1.463
        C597.846,381.74,597.902,380.587,597.298,379.849z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_4_" x="479.863" y="379.059" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" strokeMiterlimit="10" enable-background="new    " width="57.692" height="20.279" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_GERMANY_BAYERN" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Bavaria" data-infoText="<b>Bavaria</b> &amp;nbsp; Capital: Munich">
    <path fill="#1e8346" d="M667.688,567.729c-0.185-1.414-0.336-3.075-0.336-3.688c0-0.615-0.302-1.57-0.67-2.123
        c-0.37-0.553-0.419-1.76-0.113-2.683c0.308-0.923,0.572-1.236,0.559-1.677c-0.013-0.453-0.302-0.754-0.671-1.677
        c-0.37-0.923-1.174-2.331-1.788-3.129c-0.615-0.801-1.419-2.159-1.788-3.018c-0.37-0.861-1.224-1.414-1.901-1.23
        c-0.676,0.185-1.733-0.118-2.347-0.671c-0.615-0.553-1.52-1.61-2.012-2.347c-0.493-0.738-1.447-1.945-2.125-2.683
        c-0.676-0.738-1.983-1.492-2.905-1.677c-0.923-0.185-1.928,0.167-2.235,0.782c-0.308,0.615-1.114,0.967-1.789,0.782
        c-0.676-0.185-1.834-0.788-2.571-1.341c-0.738-0.553-1.442-1.61-1.565-2.347c-0.122-0.738-0.425-1.945-0.67-2.683
        s-0.849-1.744-1.341-2.236c-0.491-0.491-1.246-1.246-1.677-1.677c-0.43-0.43-1.236-1.236-1.788-1.788
        c-0.553-0.553-1.509-0.905-2.123-0.782c-0.615,0.123-1.672,0.123-2.347,0s-1.583-0.525-2.012-0.895
        c-0.43-0.37-0.782-1.475-0.782-2.459s-0.402-1.838-0.895-1.899c-0.493-0.063-3.722-0.581-3.129-2.125c0.107-0.282,0,0-0.448-1.117
        c-0.448-1.118-0.16-1.03-0.448-1.118c-0.849-0.257-1.794-1.448-2.346-2.125c-0.553-0.676-1.006-1.531-1.006-1.901
        s-0.754-1.475-1.677-2.459s-2.481-1.889-3.466-2.012c-0.984-0.123-2.039,0.078-2.347,0.446c-0.307,0.369-0.96,0.47-1.452,0.224
        c-0.493-0.246-1.549-0.397-2.347-0.336c-0.801,0.061-1.957-0.793-2.573-1.901c-0.615-1.107-1.62-2.515-2.235-3.129
        c-0.615-0.615-1.771-1.369-2.57-1.677c-0.801-0.308-1.857-1.364-2.347-2.347c-0.493-0.984-0.793-2.693-0.67-3.8
        c0.122-1.107-0.331-2.062-1.006-2.123c-0.678-0.063-1.531-0.616-1.901-1.231s-0.719-1.822-0.782-2.683
        c-0.062-0.86-0.314-1.916-0.561-2.347c-0.245-0.43-0.949-1.083-1.564-1.453s-1.118-1.475-1.118-2.459s-0.905-2.29-2.012-2.905
        c-1.107-0.616-2.365-1.672-2.794-2.347c-0.431-0.678-1.085-1.531-1.454-1.901c-0.37-0.37-0.468-1.174-0.223-1.788
        c0.246-0.616,1.051-1.572,1.788-2.125c0.738-0.553,1.392-1.81,1.453-2.796c0.062-0.984,0.412-1.788,0.782-1.788
        c0.369,0,0.923-0.705,1.23-1.565c0.308-0.86,0.759-1.916,1.006-2.347c0.246-0.43,0.597-1.184,0.782-1.677
        c0.185-0.491-0.017-1.246-0.446-1.677c-0.43-0.43-1.385-0.933-2.123-1.117c-0.738-0.185-1.19-0.838-1.006-1.453
        c0.185-0.615,0.133-1.62-0.112-2.235c-0.246-0.616-1.101-1.019-1.902-0.896c-0.799,0.122-1.653-0.078-1.899-0.446
        c-0.246-0.37-0.849-0.923-1.341-1.23c-0.493-0.307-1.247-0.86-1.677-1.228c-0.43-0.37-1.236-0.923-1.788-1.228
        c-0.553-0.308-1.459-0.71-2.012-0.895c-0.553-0.185-1.257-1.04-1.565-1.901s-0.962-1.766-1.454-2.013
        c-0.491-0.246-1.096-0.949-1.341-1.564c-0.246-0.616-0.397-1.722-0.336-2.46c0.063-0.738-0.089-1.945-0.336-2.683
        c-0.245-0.738-0.85-1.894-1.341-2.57c-0.493-0.678-1.346-1.28-1.899-1.341c-0.553-0.063-1.256-0.465-1.564-0.896
        c-0.307-0.43-0.057-1.285,0.558-1.899c0.615-0.615,0.766-1.872,0.336-2.794c-0.43-0.923-0.383-1.442-0.782-1.677
        c-0.313-0.185-0.654,0.045-1.453,0.101c-0.801,0.056-2.113-0.392-2.918-0.996c-0.805-0.603-1.847-1.81-2.317-2.683
        c-0.469-0.872-1.237-2.738-1.706-4.146c-0.47-1.408-0.25-2.259-0.854-2.561c-0.258-0.128-0.494,0.165-1.098,0.366
        c-0.603,0.2-1.098,0.969-1.098,1.707s-0.603,1.615-1.341,1.952c-0.738,0.336-1.78,0.719-2.317,0.854
        c-0.537,0.133-1.086-0.25-1.219-0.854c-0.133-0.603-0.627-0.988-1.097-0.854c-0.469,0.133-1.511,0.574-2.316,0.976
        c-0.804,0.402-2.177,0.897-3.05,1.097c-0.871,0.2-2.244,0.255-3.048,0.122c-0.805-0.133-2.341,0.141-3.414,0.611
        c-1.072,0.469-2.172,0.469-2.439,0c-0.269-0.47-0.926-1.021-1.462-1.221c-0.537-0.201-0.921-0.804-0.854-1.341
        c0.067-0.536-0.646-1.413-1.585-1.951c-0.939-0.537-1.541-1.853-1.341-2.928c0.2-1.072,0.035-2.335-0.367-2.803
        c-0.404-0.47-1.28-0.909-1.951-0.976c-0.67-0.067-1.713,0.206-2.317,0.61c-0.602,0.402-1.425,1.171-1.829,1.708
        c-0.402,0.535-1.004,1.03-1.341,1.098c-0.336,0.067-0.61,0.89-0.61,1.829s0.055,2.31,0.122,3.048s0.231,1.945,0.366,2.683
        c0.133,0.738,0.518,1.891,0.852,2.561c0.337,0.67,0.282,1.493-0.121,1.829c-0.404,0.337-0.787,1.104-0.854,1.708
        c-0.067,0.603-0.396,1.647-0.73,2.316c-0.337,0.671-1.104,1.055-1.708,0.854c-0.603-0.2-1.647-0.366-2.317-0.366
        s-1.603-0.22-2.074-0.489c-0.469-0.268-0.688-1.036-0.488-1.707c0.201-0.671,0.312-1.713,0.245-2.317
        c-0.067-0.602-0.451-1.261-0.854-1.462c-0.402-0.201-1.5-0.093-2.439,0.244c-0.939,0.336-2.201,0.225-2.805-0.244
        c-0.603-0.47-1.427-0.579-1.829-0.244c-0.404,0.337-0.842,0.336-0.976,0c-0.133-0.337-0.518-0.939-0.852-1.341
        c-0.337-0.404-1.104-0.733-1.708-0.733s-1.482-0.164-1.952-0.366c-0.469-0.2-1.291-0.256-1.829-0.123
        c-0.536,0.133-1.304,0.464-1.706,0.731c-0.403,0.269-1.061,0.434-1.465,0.367c-0.402-0.067-0.951,0.372-1.218,0.975
        c-0.269,0.604-0.928,0.988-1.464,0.853c-0.537-0.133-0.976,0.47-0.976,1.341c0,0.872,0.274,1.751,0.61,1.952
        c0.337,0.2,0.83,0.694,1.098,1.097c0.268,0.402,1.037,0.786,1.708,0.852c0.67,0.067,1.33,0.342,1.463,0.61
        c0.133,0.269,0.465,0.762,0.733,1.097c0.269,0.337,0.488,1.104,0.488,1.709c0,0.603-0.494,1.042-1.098,0.976
        c-0.603-0.067-1.702-0.231-2.439-0.366s-1.615,0.414-1.952,1.218c-0.336,0.805-1.158,1.245-1.829,0.976
        c-0.669-0.269-2.096-0.488-3.17-0.488c-1.072,0-1.896-0.549-1.829-1.219c0.067-0.67,0.122-1.933,0.122-2.806
        c0-0.871-0.165-2.244-0.366-3.048c-0.201-0.805-0.806-1.628-1.341-1.829c-0.537-0.2-0.976-0.749-0.976-1.22
        c0-0.47-0.549-0.854-1.219-0.854c-0.671,0-1.769-0.494-2.439-1.098c-0.671-0.602-1.384-1.425-1.585-1.829
        c-0.2-0.402-0.749-0.73-1.22-0.73c-0.47,0-1.183-0.055-1.586-0.122c-0.402-0.067-0.949-0.671-1.218-1.341
        c-0.269-0.671-0.982-1.989-1.585-2.928c-0.604-0.938-1.098-1.98-1.098-2.316c0-0.337-0.329-0.776-0.73-0.976
        c-0.404-0.2-1.555-0.585-2.562-0.852c-1.006-0.268-1.994-0.872-2.194-1.341c-0.2-0.47-0.695-1.238-1.098-1.708
        c-0.402-0.47-1.006-0.799-1.341-0.73c-0.336,0.067-1.048,0.011-1.585-0.123c-0.537-0.133-1.194-0.299-1.463-0.366
        s-0.708,0.207-0.976,0.61c-0.269,0.404-0.982,1.226-1.585,1.83c-0.604,0.603-0.889,0.77-1.098,1.097
        c-0.192,0.302-0.219,0.604-0.488,1.341c-0.269,0.738-0.927,1.835-1.463,2.438c-0.537,0.603-1.525,1.702-2.195,2.439
        c-0.671,0.738-2.427,1.725-3.902,2.195s-3.232,0.361-3.902-0.244c-0.671-0.603-1.549-0.823-1.952-0.488
        c-0.402,0.336-0.895,1.541-1.097,2.683c-0.201,1.14-0.53,2.785-0.733,3.657c-0.201,0.872-0.421,2.189-0.488,2.927
        s-0.725,1.452-1.463,1.585c-0.738,0.135-1.726,0.629-2.195,1.098c-0.469,0.47-0.853,1.403-0.853,2.073
        c0,0.671-0.603,1.439-1.341,1.707c-0.738,0.269-2.22,0.269-3.293,0c-1.072-0.268-2.335-0.049-2.805,0.488
        c-0.47,0.537-0.853,1.58-0.853,2.317s0.275,1.671,0.61,2.073c0.336,0.402,0.391,1.225,0.122,1.829s-0.433,1.647-0.366,2.317
        c0.067,0.67-1.03,1.658-2.438,2.194c-1.409,0.537-3.385-0.011-4.391-1.218c-1.006-1.208-2.707-2.305-3.779-2.439
        c-1.073-0.133-2.883-0.299-4.024-0.367c-1.141-0.067-2.293,0.317-2.561,0.854c-0.269,0.537-0.817,1.305-1.22,1.708
        c-0.402,0.402-1.006,0.402-1.341,0c-0.336-0.404-1.049-0.898-1.585-1.098c-0.537-0.201-1.361-0.147-1.83,0.121
        c-0.469,0.269-1.347,0.709-1.951,0.977c-0.603,0.268-1.391,1.242-1.749,2.164c-0.357,0.923-0.651,1.677-0.651,1.677
        s-0.302,0.251-0.67,0.558c-0.369,0.308-0.218,0.66,0.336,0.783s1.257,0.577,1.565,1.006c0.308,0.43,0.559,0.782,0.559,0.782
        s0.109,0.791,0.244,1.757c0.134,0.967-0.03,2.142-0.366,2.612c-0.335,0.47-0.61,1.897-0.61,3.171s0.275,2.865,0.61,3.535
        c0.336,0.67,0.664,1.932,0.733,2.805c0.067,0.872,0.616,1.75,1.219,1.952c0.603,0.2,1.042,0.639,0.975,0.974
        c-0.067,0.337,0.098,1.05,0.366,1.585c0.268,0.537,0.872,0.976,1.341,0.976s0.799,0.549,0.73,1.219
        c-0.067,0.671-0.067,1.549,0,1.952c0.067,0.402,0.123,1.5,0.123,2.439c0,0.938-0.439,2.255-0.975,2.927
        c-0.537,0.669-0.921,1.987-0.854,2.926c0.067,0.938-0.317,2.146-0.854,2.683c-0.536,0.536-0.975,1.248-0.975,1.585
        c0,0.336,0.219,0.993,0.488,1.463s0.28,0.723,0.488,0.854c0.388,0.244,0.823,0.055,1.83,0.122c1.005,0.067,2.871,0.122,4.146,0.122
        c1.274,0,2.81-0.494,3.413-1.097c0.603-0.604,1.372-1.756,1.708-2.561c0.336-0.804,0.994-1.408,1.464-1.341
        c0.469,0.067,1.292-0.098,1.829-0.367c0.537-0.268,1.36-0.377,1.829-0.244c0.47,0.135,1.128-0.578,1.463-1.585
        c0.336-1.006,0.445-2.322,0.245-2.927c-0.201-0.603-0.86-0.933-1.463-0.73c-0.603,0.2-1.317,0.037-1.586-0.367
        c-0.269-0.402-0.927-0.896-1.464-1.098c-0.536-0.2-1.007-0.654-1.047-1.006c-0.039-0.351-0.071-0.639-0.071-0.639
        s0.051-0.454,0.112-1.006c0.062-0.553,0.564-1.056,1.118-1.119c0.554-0.062,1.107-0.262,1.229-0.446
        c0.123-0.185,0.676-0.385,1.229-0.446c0.554-0.061,1.359-0.112,1.788-0.112s1.285,0.151,1.901,0.336
        c0.615,0.185,1.419,0.133,1.788-0.112c0.369-0.246,1.072,0.005,1.565,0.558c0.492,0.553,1.296,1.257,1.788,1.564
        c0.492,0.308,1.145,0.157,1.453-0.336c0.307-0.491,0.349-0.734,0.559-0.894c0.303-0.23,0.659-0.192,1.463-0.427
        c0.805-0.235,1.519,0.342,1.586,1.281c0.067,0.938-0.097,2.529-0.366,3.535c-0.268,1.006-0.103,2.049,0.366,2.317
        c0.47,0.268,1.347-0.171,1.952-0.976c0.603-0.805,1.372-0.86,1.708-0.122s1.432,0.572,2.438-0.367
        c1.005-0.938,2.378-0.884,3.049,0.123c0.67,1.006,1.22,2.543,1.22,3.413c0,0.873,0.549,2.245,1.219,3.05s0.89,2.067,0.488,2.805
        s-0.952,1.835-1.219,2.438c-0.269,0.603-0.268,1.318,0,1.585c0.269,0.269,0.873,0.104,1.341-0.364
        c0.469-0.47,1.128-0.854,1.463-0.854s0.883,0.822,1.219,1.829c0.336,1.007,0.664,2.597,0.731,3.536s1.111,1.652,2.317,1.585
        c1.207-0.067,2.744-0.671,3.414-1.341c0.671-0.67,1.219-1.603,1.219-2.072c0-0.47,0.439-0.964,0.975-1.098
        c0.537-0.135,1.251,0.414,1.586,1.218c0.336,0.806,0.61,1.683,0.61,1.952c0,0.269,0.165,0.982,0.366,1.585s0.146,1.536-0.122,2.072
        c-0.269,0.537-0.104,1.197,0.366,1.465c0.47,0.269,0.964,1.31,1.098,2.316c0.133,1.007-0.305,1.995-0.976,2.195
        c-0.669,0.2-1.22,1.08-1.22,1.952c0,0.871,0.439,1.969,0.976,2.439c0.537,0.469,0.976,1.622,0.976,2.559
        c0,0.939-0.22,1.818-0.489,1.952c-0.269,0.133-0.432,0.683-0.366,1.219c0.067,0.537,0.342,1.908,0.61,3.049
        s0.872,2.567,1.341,3.172c0.469,0.603,1.293,1.317,1.83,1.586c0.537,0.268,1.58,0.816,2.317,1.218
        c0.738,0.404,1.232,1.116,1.098,1.585c-0.133,0.47-0.627,1.292-1.098,1.83c-0.47,0.537-0.361,1.47,0.244,2.073
        c0.604,0.602,1.208,1.535,1.341,2.072s0.903,1.194,1.707,1.463c0.804,0.269,1.902,1.146,2.439,1.951
        c0.537,0.806,1.47,1.903,2.073,2.439c0.603,0.537,1.427,1.47,1.829,2.073c0.404,0.604,1.172,1.647,1.708,2.317
        c0.536,0.67,0.646,1.493,0.244,1.829c-0.404,0.336-0.568,0.83-0.367,1.098c0.2,0.268,0.367,1.036,0.367,1.707
        s-0.109,2.372-0.244,3.78s-0.518,3.438-0.854,4.513c-0.336,1.072-0.5,2.335-0.367,2.803c0.135,0.47,0.684,1.072,1.221,1.341
        c0.536,0.268,1.248,1.037,1.585,1.708c0.336,0.67,0.389,1.658,0.121,2.195c-0.268,0.536-0.927,0.974-1.462,0.974
        c-0.537,0-0.976-0.383-0.976-0.854c0-0.469-0.329-0.798-0.733-0.73c-0.402,0.067-0.896,0.45-1.098,0.854
        c-0.2,0.402-0.749,1.006-1.218,1.341c-0.47,0.337-1.074,0.116-1.341-0.489c-0.269-0.603-0.817-1.097-1.221-1.097
        c-0.402,0-1.28-0.111-1.951-0.244s-1.384,0.361-1.585,1.098c-0.2,0.738,0.019,1.723,0.489,2.194c0.47,0.47,1.291,1.292,1.829,1.83
        c0.536,0.537,0.7,1.249,0.366,1.586c-0.336,0.336-0.554,0.993-0.488,1.462c0.067,0.47,0.122,1.787,0.122,2.927
        c0,1.141-0.549,2.183-1.219,2.317c-0.671,0.133-1.769,0.903-2.439,1.707c-0.67,0.805-2.042,1.902-3.048,2.439
        c-1.007,0.537-2.214,0.703-2.683,0.367c-0.47-0.337-1.897-0.227-3.172,0.244c-1.274,0.469-2.426,1.182-2.56,1.585
        c-0.134,0.402-0.518,1.225-0.853,1.829c-0.336,0.603-0.83,2.141-1.098,3.414c-0.269,1.275-0.214,3.031,0.122,3.903
        c0.336,0.871,1.049,2.189,1.585,2.927c0.537,0.738,1.194,2.219,1.463,3.293c0.268,1.072,0.653,2.883,0.854,4.024
        c0.201,1.14,0.586,2.95,0.854,4.024c0.268,1.072,0.926,3.104,1.463,4.512c0.536,1.408,0.921,3.329,0.852,4.268
        c-0.067,0.939-0.506,3.079-0.974,4.756c-0.47,1.678-1.128,3.433-1.464,3.903c-0.336,0.469-0.336,1.237,0,1.706
        c0.336,0.47,0.61,1.732,0.61,2.806c0,1.072-0.055,2.554-0.122,3.292s0.209,1.945,0.611,2.683c0.402,0.738,0.621,1.725,0.488,2.194
        c-0.133,0.47-0.683,1.183-1.219,1.585c-0.537,0.404-0.921,1.062-0.854,1.464s0.506,1.116,0.976,1.585
        c0.47,0.47,0.852,1.128,0.852,1.465c0,0.336,0.112,1.487,0.245,2.56c0.134,1.074-0.635,2.391-1.708,2.927
        c-1.072,0.537-2.116,0.921-2.316,0.854c-0.201-0.067-0.751-0.122-1.219-0.122c-0.47,0-1.732,0.439-2.805,0.976
        s-2.83,0.646-3.902,0.244c-1.072-0.404-2.28-0.898-2.683-1.098c-0.402-0.2-1.336,0.239-2.072,0.976
        c-0.737,0.738-2.11,2-3.049,2.805c-0.939,0.804-2.695,1.463-3.902,1.463s-2.414,0.328-2.683,0.732
        c-0.269,0.402-0.872,0.786-1.341,0.852c-0.228,0.033-0.533,0.238-0.841,0.534c-0.325,0.314-0.655,0.732-0.907,1.183
        c-0.491,0.876-1.011,1.153-0.895,1.594c0.084,0.315,0.503,0.302,1.118,0.67c0.615,0.369,1.621,0.719,2.236,0.782
        c0.615,0.062,2.023,0.011,3.129-0.109c0.932-0.103,1.893-0.586,2.359-1.138c0.087-0.103,0.164-0.209,0.212-0.315
        c0.307-0.676,0.961-1.985,1.453-2.906c0.492-0.923,1.397-1.576,2.012-1.453s1.067,0.877,1.006,1.677
        c-0.061,0.799,0.392,1.805,1.006,2.235c0.615,0.431,1.57,0.783,2.124,0.783s1.408,0.101,1.9,0.223
        c0.492,0.123,1.146,0.175,1.453,0.113c0.308-0.062,0.76,0.089,1.006,0.336c0.246,0.246,0.951,0.799,1.565,1.228
        c0.615,0.431,1.118,1.237,1.118,1.789s0.503,0.855,1.118,0.67s1.42,0.167,1.788,0.783c0.369,0.615,1.023,1.47,1.453,1.899
        c0.43,0.43,0.833,1.084,0.895,1.453c0.062,0.37-0.089,1.124-0.336,1.677c-0.246,0.553-0.397,1.76-0.336,2.683
        c0.062,0.923,0.615,1.576,1.23,1.453c0.615-0.122,1.418-0.172,1.788-0.111c0.37,0.063,1.124-0.19,1.677-0.559
        s1.559-0.268,2.235,0.224c0.676,0.493,0.929,1.346,0.559,1.899s-0.57,1.559-0.446,2.235c0.123,0.677-0.178,1.683-0.67,2.236
        s-0.542,1.257-0.113,1.565c0.431,0.308,1.588,0.359,2.573,0.113c0.984-0.245,2.441-0.801,3.241-1.232
        c0.799-0.43,1.805-1.285,2.235-1.899c0.431-0.615,1.286-1.721,1.902-2.459c0.615-0.738,1.369-1.643,1.677-2.013
        c0.307-0.37,0.759-0.772,1.006-0.895c0.245-0.123,0.748-0.727,1.118-1.341c0.37-0.615,1.023-1.771,1.453-2.569
        c0.43-0.801,0.631-2.209,0.446-3.13c-0.185-0.923-0.738-2.029-1.229-2.46c-0.491-0.43-0.792-1.436-0.671-2.235
        c0.123-0.799,0.475-1.754,0.783-2.123c0.308-0.37,0.258-1.124-0.112-1.677s-0.217-1.208,0.336-1.453
        c0.553-0.246,1.006-0.096,1.006,0.336c0,0.43,0.05,1.385,0.112,2.123c0.061,0.738,0.765,1.341,1.564,1.341
        c0.801,0,1.957,0.101,2.572,0.224c0.615,0.122,1.37-0.331,1.677-1.006c0.308-0.678,1.463-1.229,2.57-1.229s2.717,0.403,3.576,0.895
        c0.861,0.493,2.019,0.943,2.571,1.006c0.553,0.062,1.359,0.412,1.788,0.782c0.431,0.37,1.639,0.419,2.683,0.112
        c1.046-0.308,2.303-0.559,2.796-0.559c0.493,0,1.146,0.302,1.454,0.67c0.307,0.37,0.104,1.072-0.448,1.565
        c-0.553,0.493-0.553,1.095,0,1.341s1.359,0.647,1.789,0.894c0.43,0.245,1.385,1.203,2.123,2.125
        c0.738,0.923,1.341,2.079,1.341,2.57c0,0.493,0.102,1.247,0.224,1.677c0.123,0.431,1.078,0.783,2.122,0.783
        c1.046,0,2.756-0.101,3.802-0.222c1.046-0.123,2.504-0.778,3.242-1.454c0.738-0.676,1.894-1.482,2.571-1.789
        c0.676-0.307,1.48-0.407,1.786-0.223c0.308,0.185,0.559,0.788,0.559,1.341s0.302,1.257,0.67,1.564c0.37,0.308,1.174,0.308,1.788,0
        c0.616-0.307,1.321-1.111,1.566-1.786c0.246-0.678,0.949-1.583,1.564-2.012c0.615-0.431,1.721-0.734,2.459-0.67
        c0.738,0.062,1.442-0.443,1.565-1.119s0.626-1.783,1.119-2.46c0.491-0.676,1.697-1.179,2.683-1.116
        c0.984,0.062,2.592,0.062,3.576,0c0.984-0.063,1.99-0.817,2.235-1.677c0.246-0.861,1.153-2.522,2.011-3.688
        c0.861-1.168,2.068-1.823,2.683-1.454c0.616,0.37,1.923,0.469,2.908,0.223c0.984-0.245,2.392-0.446,3.129-0.446
        c0.738,0,1.945,0.05,2.683,0.112c0.738,0.063,1.541-0.138,1.788-0.445c0.246-0.308,1.554-0.911,2.905-1.341
        c1.353-0.431,3.315-0.934,4.36-1.119c1.046-0.185,2.504-0.234,3.242-0.113c0.738,0.123,2.298,0.274,3.465,0.336
        c1.168,0.063,2.981-0.091,4.024-0.336c1.046-0.247,1.626-0.043,1.901-0.446c0.223-0.328-0.101-0.705-0.224-1.565
        c-0.122-0.86-0.474-2.42-0.781-3.463c-0.308-1.046-0.782-1.457-0.559-1.901c0.107-0.214,0.402-0.151,0.895-0.336
        c0.491-0.185,1.346-0.788,1.899-1.341s1.056-0.553,1.119,0c0.062,0.553,0.112,1.46,0.112,2.012c0,0.553,1.056,0.754,2.347,0.446
        c1.291-0.308,2.85-0.258,3.465,0.112c0.615,0.369,1.872,0.47,2.794,0.224c0.923-0.246,2.028,0.106,2.459,0.783
        c0.43,0.676,1.488,1.883,2.347,2.683c0.861,0.801,2.27,1.051,3.13,0.558c0.861-0.493,2.068-1.398,2.683-2.012
        c0.615-0.615,2.023-1.419,3.129-1.788s2.616-0.57,3.354-0.446c0.738,0.122,1.744,0.474,2.236,0.781
        c0.493,0.308,1.146,1.012,1.454,1.565c0.307,0.553,1.061,1.46,1.677,2.013c0.615,0.553,0.664,1.61,0.112,2.347
        c-0.553,0.738-0.654,2.096-0.224,3.018c0.43,0.923,1.236,1.828,1.788,2.011c0.553,0.185,1.76,0.939,2.683,1.677
        s2.231,1.844,2.906,2.459c0.676,0.616,1.883,1.069,2.683,1.006c0.799-0.062,1.603-0.062,1.788,0
        c0.185,0.063,0.839-0.44,1.453-1.116c0.616-0.678,0.867-1.834,0.561-2.571c-0.308-0.738-0.358-1.844-0.113-2.459
        c0.246-0.616,0.446-1.471,0.446-1.902c0-0.43,0.201-0.982,0.446-1.228c0.246-0.246,0.448-1.001,0.448-1.677
        s0.352-1.531,0.783-1.901c0.43-0.37,0.48-1.576,0.112-2.683c-0.37-1.107-1.324-2.767-2.123-3.688
        c-0.801-0.923-2.209-1.425-3.13-1.117c-0.923,0.308-2.331,0.51-3.13,0.448s-1.453-0.465-1.453-0.896c0-0.43,0.302-1.236,0.67-1.788
        c0.37-0.553,0.671-1.559,0.671-2.236c0-0.676,0.503-1.983,1.117-2.905c0.616-0.923,1.369-2.18,1.677-2.796
        c0.307-0.615,0.587-0.807,0.559-1.117c-0.036-0.417-0.454-0.603-1.006-1.341c-0.553-0.738-1.006-1.593-1.006-1.901
        c0-0.307-0.553-1.313-1.23-2.235c-0.676-0.923-1.329-2.029-1.452-2.46c-0.122-0.43-0.828-0.782-1.565-0.782
        c-0.738,0-1.544-0.905-1.788-2.012c-0.246-1.107-1.151-2.717-2.012-3.578s-2.12-2.217-2.796-3.018
        c-0.676-0.799-1.482-2.207-1.789-3.129c-0.307-0.923-0.156-1.877,0.336-2.123c0.493-0.246,1.297-0.9,1.789-1.453
        c0.493-0.553,1.146-1.006,1.454-1.006c0.307,0,0.96-0.905,1.452-2.013c0.493-1.106,1.599-2.264,2.46-2.571
        c0.861-0.308,1.17-0.432,1.565-0.559c0.387-0.124,0.705-0.2,1.565-0.446s1.966-1.051,2.457-1.788
        c0.493-0.738,1.599-1.643,2.46-2.013c0.861-0.369,2.319-0.972,3.242-1.341c0.923-0.37,2.18-1.023,2.794-1.453
        c0.615-0.431,1.922-0.835,2.905-0.896c0.985-0.063,2.292-0.161,2.908-0.223c0.615-0.062,1.771-0.615,2.57-1.228
        c0.799-0.616,2.157-1.974,3.018-3.018c0.861-1.046,1.969-2.705,2.46-3.688c0.493-0.985,0.894-2.394,0.894-3.13
        c0-0.737-0.05-1.794-0.113-2.347c-0.062-0.553,0.141-1.862,0.448-2.905c0.308-1.046,0.308-2.455,0-3.13
        c-0.308-0.676-0.559-1.883-0.559-2.683s-0.225-1.168,0-1.453c0.303-0.384,0.855-0.201,1.901-0.446
        c1.046-0.246,2.554-0.045,3.354,0.445c0.799,0.493,2.006,0.793,2.683,0.67c0.676-0.122,1.482-0.123,1.789,0
        c0.307,0.122,0.96,0.525,1.452,0.895c0.493,0.37,1.196,0.923,1.565,1.229c0.369,0.307,1.225,0.759,1.901,1.006
        c0.676,0.246,0.948,0.613,1.23,0.446c0.316-0.187,0.101-0.654,0.224-1.453c0.122-0.799,0.977-2.006,1.899-2.683
        c0.923-0.676,1.978-1.883,2.347-2.683C667.722,570.953,667.873,569.143,667.688,567.729z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_5_" x="526.202" y="547.516" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" strokeMiterlimit="10" enable-background="new    " width="57.692" height="20.279" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_GERMANY_SACHSENANHALT" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Saxony-Anhalt" data-infoText="<b>Saxony-Anhalt</b> &amp;nbsp; Capital: Magdeburg">
    <path fill="#1e8346" d="M619.613,316.928c0.537-0.738,1.525-1.725,2.195-2.194c0.67-0.47,1.275-1.184,1.341-1.585
        c0.067-0.403-0.317-1.226-0.854-1.83c-0.537-0.603-1.194-2.029-1.463-3.17c-0.269-1.141-0.816-2.896-1.218-3.902
        c-0.404-1.006-1.555-1.994-2.562-2.195c-1.006-0.201-1.883-0.914-1.951-1.585s-0.616-1.275-1.219-1.341
        c-0.603-0.067-1.371-0.505-1.708-0.976c-0.336-0.469-1.103-0.963-1.707-1.096c-0.604-0.134-1.316-0.08-1.585,0.122
        c-0.269,0.2-0.871,0.2-1.341,0c-0.47-0.201-1.292-0.915-1.83-1.587c-0.536-0.671-1.633-1.383-2.438-1.585
        c-0.805-0.201-1.683-0.639-1.951-0.974c-0.269-0.336-0.982-0.776-1.587-0.976c-0.604-0.201-1.921-0.806-2.926-1.341
        c-1.006-0.537-2.158-0.647-2.561-0.245c-0.404,0.402-1.169,1.116-1.707,1.586c-0.537,0.469-1.525,0.689-2.195,0.488
        c-0.67-0.2-1.438-0.805-1.707-1.341s-0.816-1.194-1.218-1.463c-0.404-0.269-1.062-0.762-1.465-1.098
        c-0.402-0.336-1.172-0.391-1.708-0.122c-0.535,0.269-1.248,0.214-1.585-0.122c-0.336-0.336-1.213-0.664-1.951-0.731
        s-1.615-0.945-1.951-1.952c-0.337-1.006-1.27-2.652-2.074-3.658c-0.805-1.004-1.737-1.939-2.073-2.072
        c-0.336-0.135-0.83-0.738-1.098-1.341c-0.268-0.604-0.815-1.482-1.218-1.952c-0.402-0.469-0.842-1.403-0.976-2.072
        c-0.133-0.671-0.024-1.713,0.245-2.317c0.268-0.602,0.761-1.645,1.098-2.316c0.336-0.671,0.116-1.823-0.489-2.562
        c-0.604-0.737-0.876-2.329-0.61-3.535c0.269-1.208,0.488-2.634,0.488-3.172s0.274-1.744,0.611-2.683
        c0.336-0.938,0.61-2.366,0.61-3.17c0-0.805,0.383-2.89,0.852-4.634c0.47-1.744,0.361-3.172-0.244-3.172
        c-0.604,0-1.425-0.494-1.829-1.098c-0.402-0.603-0.954-0.657-1.221-0.122c-0.269,0.537-0.926,1.141-1.462,1.341
        c-0.537,0.201-1.249,0.037-1.585-0.366c-0.337-0.402-0.391-1.281-0.123-1.952c0.269-0.671-0.005-1.053-0.61-0.852
        c-0.603,0.2-0.659-0.512-0.122-1.585c0.537-1.072,0.976-2.719,0.976-3.658c0-0.938,0.714-2.035,1.585-2.439
        c0.871-0.402,1.717-1.226,1.879-1.829c0.162-0.604,0.334-0.816,0.294-1.097c-0.068-0.485-0.454-0.754-1.006-1.677
        c-0.553-0.923-0.754-2.28-0.446-3.018c0.307-0.738,0.256-1.693-0.113-2.123c-0.37-0.431-0.328-1.103,0-1.565
        c0.176-0.248,0.274-0.473,0.611-1.052c0.336-0.578,0.499-1.709,0.366-2.515c-0.133-0.805-0.464-1.902-0.732-2.439
        c-0.268-0.536-0.926-1.249-1.462-1.585c-0.537-0.336-1.141-0.444-1.341-0.244c-0.201,0.2-0.806,0.366-1.341,0.366
        c-0.537,0-1.14-0.493-1.341-1.098c-0.2-0.602-1.017-0.758-1.81-0.346c-0.792,0.414-1.052,0.641-1.442,0.752
        c-0.349,0.101-0.654,0.052-1.453,0.113c-0.801,0.062-1.806-0.089-2.238-0.336c-0.43-0.246-1.184-0.598-1.677-0.783
        c-0.491-0.185-1.397-0.535-2.012-0.782c-0.615-0.245-1.62-0.698-2.235-1.006s-1.017-1.212-0.894-2.012
        c0.122-0.799-0.381-1.453-1.119-1.453c-0.738,0-1.643-0.604-2.013-1.341c-0.369-0.738-1.023-1.341-1.454-1.341
        c-0.43,0-1.084,0.251-1.453,0.559c-0.37,0.308-1.023-0.045-1.453-0.782c-0.43-0.739-1.236-1.443-1.788-1.565
        c-0.553-0.123-0.785-0.092-1.006-0.224c-0.718-0.431-1.859-2.164-2.683-2.013c-0.389,0.072-0.38,0.604-0.844,1.341
        c-0.464,0.738-1.173,1.177-1.576,0.976c-0.402-0.201-1.004,0.237-1.341,0.976c-0.336,0.737-0.554,1.944-0.488,2.683
        c0.067,0.737-0.482,1.779-1.219,2.316c-0.738,0.537-2.274,1.634-3.414,2.439s-3.28,1.847-4.754,2.317
        c-1.475,0.469-3.012,0.469-3.414,0c-0.404-0.47-1.446-1.018-2.317-1.22c-0.871-0.201-2.244-0.367-3.049-0.367
        c-0.804,0-1.628-0.329-1.829-0.73c-0.201-0.402-1.134-0.567-2.074-0.366c-0.939,0.2-2.037,0.969-2.439,1.707
        s-1.171,1.89-1.707,2.561c-0.537,0.671-1.634,1.495-2.439,1.83c-0.805,0.336-1.791,0.391-2.195,0.121
        c-0.402-0.268-1.939-0.268-3.413,0c-1.475,0.27-2.628,1.203-2.561,2.074s0.122,2.024,0.122,2.561s0.329,1.47,0.731,2.072
        c0.403,0.605,0.733,1.648,0.733,2.317c0,0.671,0.714,1.878,1.585,2.683c0.871,0.806,1.859,2.177,2.194,3.048
        c0.337,0.872,1.159,2.245,1.83,3.05c0.671,0.804,1.769,1.518,2.439,1.585c0.671,0.067,0.944,0.836,0.61,1.707
        c-0.337,0.872-0.554,1.969-0.488,2.439c0.067,0.47,0.671,1.896,1.341,3.17c0.671,1.275,0.671,2.427,0,2.561
        c-0.67,0.133-1.33,1.012-1.463,1.952c-0.133,0.938,0.689,2.913,1.83,4.39c1.14,1.476,2.509,3.013,3.047,3.414
        c0.536,0.402,0.976,1.006,0.976,1.341s-0.329,0.718-0.732,0.852c-0.402,0.133-1.115,0.409-1.585,0.611
        c-0.469,0.2-0.47,0.914,0,1.585c0.469,0.669,0.689,1.603,0.489,2.072c-0.2,0.47,0.073,1.623,0.61,2.561
        c0.537,0.939,1.306,2.092,1.708,2.561c0.404,0.47,0.182,1.184-0.489,1.586c-0.67,0.402-1.438,1.389-1.707,2.194
        c-0.269,0.806,0.006,1.738,0.611,2.074c0.602,0.336,1.041,1.158,0.974,1.829c-0.067,0.671-0.67,1.603-1.341,2.072
        c-0.67,0.469-1.823,1.184-2.561,1.585c-0.738,0.404-1.121,1.392-0.852,2.195c0.268,0.805-0.172,1.683-0.976,1.952
        c-0.805,0.268-2.505,0.597-3.78,0.73s-3.03,0.244-3.902,0.244s-2.133-0.111-2.805-0.244c-0.671-0.133-1.932,0.087-2.805,0.488
        c-0.872,0.404-1.75,1.226-1.952,1.83c-0.2,0.603-1.024,1.371-1.829,1.707c-0.804,0.336-1.025,1.103-0.488,1.708
        c0.537,0.602,0.976,1.866,0.976,2.805s0.274,2.037,0.61,2.437c0.337,0.404,0.5,1.501,0.367,2.439
        c-0.135,0.939-0.739,1.982-1.341,2.317c-0.604,0.336-1.042,1.597-0.976,2.805c0.067,1.208,0.011,2.688-0.122,3.294
        c-0.135,0.602,0.304,1.755,0.974,2.559c0.67,0.806,1.33,1.957,1.465,2.561c0.133,0.603,0.627,1.427,1.098,1.83
        c0.469,0.402,0.688,1.169,0.488,1.707c-0.201,0.537,0.127,1.634,0.73,2.439c0.603,0.804,1.097,1.463,1.097,1.463
        s0.549,0.22,1.219,0.488c0.671,0.269,2.097,0.378,3.172,0.244c1.072-0.133,2.17-0.024,2.438,0.244
        c0.269,0.269,1.202,0.709,2.073,0.977c0.871,0.268,2.189,0.597,2.926,0.73c0.738,0.133,1.286,0.847,1.219,1.585
        s-0.396,1.341-0.732,1.341s-0.61,0.439-0.61,0.974c0,0.537,0.329,1.414,0.732,1.952c0.402,0.537,0.73,1.58,0.73,2.317
        c0,0.738,0.274,1.835,0.611,2.439c0.336,0.603,0.554,1.756,0.488,2.561c-0.068,0.804,0.315,2.012,0.852,2.683
        c0.537,0.671,1.525,1.11,2.195,0.974c0.67-0.133,1.988,0.085,2.927,0.488c0.939,0.404,2.366,0.622,3.17,0.489
        s2.067-0.079,2.805,0.122c0.738,0.2,1.835,0.311,2.439,0.244c0.603-0.067,1.536,0.262,2.074,0.733
        c0.537,0.47,1.635,1.128,2.439,1.463c0.805,0.336,1.737,1.048,2.074,1.585c0.336,0.537,0.828,1.58,1.096,2.317
        c0.269,0.738,0.816,1.891,1.219,2.561c0.402,0.671,0.237,1.712-0.367,2.316c-0.603,0.603-1.535,1.371-2.072,1.708
        c-0.537,0.336-1.141,0.993-1.341,1.462c-0.2,0.47-0.2,1.292,0,1.83c0.2,0.537,0.969,1.031,1.707,1.098s1.725,0.45,2.195,0.852
        c0.47,0.404,0.799,1.392,0.732,2.195c-0.068,0.805-0.013,2.013,0.121,2.683c0.135,0.67,0.684,1.492,1.221,1.829
        c0.536,0.336,1.744,0.337,2.683,0c0.938-0.336,2.421-0.389,3.292-0.121s1.805,0.214,2.073-0.123c0.268-0.336,1.365-0.61,2.439-0.61
        c1.072,0,2.116,0.549,2.316,1.219c0.2,0.67,0.805,2.317,1.341,3.657c0.537,1.341,2.074,2.22,3.414,1.952
        c1.341-0.269,2.769-0.488,3.172-0.488s1.116,0.327,1.586,0.73c0.469,0.402,1.62,1.116,2.56,1.585
        c0.939,0.47,2.201,1.458,2.805,2.195c0.603,0.738,1.647,1.341,2.317,1.341s1.439-0.165,1.708-0.366
        c0.268-0.2,1.146-0.036,1.951,0.366c0.804,0.404,1.847,0.512,2.317,0.244c0.469-0.268,1.346-0.214,1.951,0.123
        c0.603,0.336,1.263,0.938,1.463,1.341c0.2,0.402,0.586,0.237,0.854-0.367c0.268-0.603,0.542-1.536,0.61-2.072
        c0.067-0.537,0.396-1.305,0.732-1.708c0.336-0.402,0.993-1.169,1.462-1.707c0.47-0.537,0.635-1.25,0.367-1.586
        s-0.871-0.828-1.341-1.096c-0.47-0.269-0.744-0.981-0.611-1.585c0.134-0.603,0.199-0.82,0.245-1.097
        c0.045-0.272,0.055-0.494,0.122-1.098c0.067-0.603-0.372-1.208-0.976-1.341c-0.603-0.133-1.262-0.738-1.463-1.341
        s-0.694-1.37-1.097-1.707c-0.402-0.336-0.621-1.05-0.488-1.585c0.133-0.537,0.025-1.414-0.244-1.952
        c-0.268-0.535-0.489-1.907-0.489-3.048s-0.274-2.402-0.61-2.805c-0.337-0.404-0.611-1.116-0.611-1.585
        c0-0.47-0.275-1.677-0.611-2.683c-0.337-1.007-0.005-1.94,0.732-2.073s1.452-0.572,1.587-0.976
        c0.133-0.402,0.025-1.609-0.244-2.683c-0.268-1.072-0.378-2.554-0.245-3.292s-0.03-2-0.366-2.805c-0.337-0.804-0.337-1.957,0-2.561
        c0.336-0.604,0.61-1.975,0.61-3.049s0.549-2.774,1.219-3.78s1.989-1.663,2.927-1.463c0.939,0.2,2.367,0.037,3.172-0.366
        c0.804-0.404,2.396-0.954,3.535-1.221c1.141-0.269,2.128-0.926,2.195-1.462c0.067-0.537,0.67-0.866,1.341-0.733
        c0.67,0.133,1.603,0.133,2.072,0c0.47-0.133,1.568-0.299,2.439-0.366c0.872-0.067,2.19-0.231,2.928-0.367
        c0.738-0.133,1.835-0.244,2.438-0.244s1.264-0.549,1.464-1.218c0.201-0.671,0.804-1.219,1.341-1.219
        c0.536,0,1.688,0.165,2.56,0.366c0.872,0.2,2.3-0.239,3.171-0.976c0.871-0.738,2.272-1.218,3.109-1.067
        c0.838,0.152,1.16,0.146,1.525,0.275c0.272,0.097,0.454,0.251,1.006,0.558c0.553,0.308,1.307,0.861,1.677,1.229
        c0.37,0.37,0.923,0.568,1.228,0.446c0.308-0.123,0.449-0.121,0.559-0.223c0.161-0.15,0.175-0.356,0.386-0.792
        c0.212-0.436,0.66-0.353,0.996,0.182c0.337,0.537,0.994,0.976,1.465,0.976c0.469,0,0.669,0.108,0.852,0
        c0.328-0.195,0.328-0.603,0.732-1.341C618.308,318.872,619.076,317.666,619.613,316.928z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_6_" x="517" y="291.623" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" strokeMiterlimit="10" enable-background="new    " width="57.692" height="20.279" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_GERMANY_SACHSEN" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Saxony" data-infoText="<b>Saxony</b> &amp;nbsp; Capital: Dresden">
    <path fill="#1e8346" d="M719.777,347.748c0.307-0.923,0.558-2.229,0.558-2.905c0-0.678-0.454-1.883-1.006-2.683
        c-0.553-0.801-1.408-2.058-1.9-2.796c-0.493-0.738-0.845-1.795-0.783-2.347c0.062-0.553-0.089-1.509-0.336-2.124
        s-0.446-1.62-0.446-2.235s0-1.47,0-1.899c0-0.431-0.201-1.185-0.446-1.677c-0.246-0.493-1.153-1.297-2.012-1.789
        c-0.861-0.493-2.019-0.845-2.571-0.783c-0.553,0.063-1.61-0.391-2.347-1.006c-0.738-0.615-1.896-1.118-2.571-1.118
        s-1.531-0.251-1.901-0.559c-0.37-0.307-0.821-1.162-1.006-1.899c-0.185-0.738-0.122-1.07-0.336-1.341
        c-0.183-0.234-0.471-0.248-1.047-0.549c-0.575-0.302-1.266,0.274-1.534,1.28c-0.269,1.007-1.202,1.501-2.073,1.097
        c-0.871-0.402-2.024-0.456-2.559-0.121c-0.537,0.337-1.58,0.828-2.317,1.096c-0.738,0.269-1.78,0.817-2.317,1.219
        s-1.888,0.617-3.002,0.476c-1.116-0.138-2.597,0.574-3.293,1.585c-0.697,1.012-1.978,1.237-2.85,0.499
        c-0.871-0.738-1.913-1.506-2.316-1.707c-0.404-0.201-1.61-0.532-2.683-0.733c-1.074-0.2-2.336,0.239-2.806,0.976
        c-0.469,0.738-1.291,1.452-1.829,1.585c-0.537,0.133-1.194,1.012-1.463,1.952c-0.269,0.938-0.982,1.707-1.585,1.707
        s-1.098,0.494-1.098,1.096c0,0.604,0.11,1.427,0.244,1.829c0.133,0.404,0.025,1.501-0.244,2.439
        c-0.269,0.939-0.432,2.586-0.366,3.659c0.067,1.073-0.372,1.785-0.976,1.585c-0.603-0.201-1.427-0.422-1.83-0.489
        c-0.402-0.067-1.004-0.396-1.341-0.733c-0.336-0.336-1.158-0.336-1.829,0c-0.67,0.337-1.658,0.5-2.195,0.367
        c-0.535-0.133-1.633,0.141-2.437,0.611c-0.805,0.469-2.177,0.852-3.049,0.852s-2.739,0-4.147,0c-1.407,0-3.163-0.22-3.901-0.489
        c-0.738-0.268-2-0.488-2.806-0.488c-0.804,0-1.845-0.494-2.316-1.097c-0.469-0.602-1.458-1.37-2.195-1.706
        c-0.738-0.337-1.78-0.939-2.316-1.341c-0.537-0.404-1.36-0.404-1.83,0c-0.47,0.402-1.238,0.896-1.707,1.096
        c-0.47,0.2-1.292,0.586-1.829,0.852c-0.537,0.269-1.196,0.709-1.464,0.977c-0.268,0.268-1.31,0.159-2.316-0.244
        c-1.007-0.404-2.102-0.404-2.439,0c-0.336,0.402-0.829,0.182-1.097-0.489c-0.269-0.67-0.122-1.521,0.324-1.89
        c0.448-0.37,0.7-0.432,0.813-0.671c0.119-0.254,0-0.503,0-1.119c0-0.615-0.251-1.469-0.559-1.899s-0.559-1.033-0.559-1.341
        s-0.07-0.439,0-0.559c0.104-0.178,0.324-0.182,0.722-0.406c0.397-0.224,0.612-0.955,0.478-1.625
        c-0.134-0.671-0.738-1.823-1.341-2.561s-0.933-1.725-0.732-2.194c0.201-0.47-0.016-1.128-0.488-1.465
        c-0.47-0.336-1.403-0.444-2.072-0.244c-0.67,0.2-1.603-0.239-2.073-0.976c-0.47-0.738-0.506-1.15-0.854-1.341
        c-0.187-0.102-0.383,0-0.852,0c-0.47,0-1.128-0.439-1.465-0.976c-0.336-0.535-0.783-0.618-0.996-0.182
        c-0.212,0.436-0.225,0.644-0.386,0.792c-0.111,0.102-0.251,0.099-0.559,0.223c-0.307,0.122-0.859-0.077-1.228-0.446
        c-0.37-0.37-1.124-0.923-1.677-1.229c-0.553-0.307-0.735-0.461-1.006-0.558c-0.364-0.129-0.686-0.123-1.525-0.275
        c-0.837-0.151-2.238,0.331-3.109,1.067c-0.871,0.738-2.299,1.176-3.171,0.976c-0.871-0.2-2.024-0.366-2.56-0.366
        c-0.537,0-1.14,0.549-1.341,1.219c-0.2,0.669-0.861,1.218-1.464,1.218c-0.602,0-1.701,0.111-2.438,0.244
        c-0.738,0.135-2.056,0.3-2.928,0.367c-0.871,0.067-1.969,0.232-2.439,0.366c-0.469,0.133-1.402,0.133-2.072,0
        s-1.275,0.195-1.341,0.733c-0.067,0.536-1.055,1.193-2.195,1.462c-1.14,0.268-2.732,0.817-3.535,1.221
        c-0.805,0.402-2.232,0.566-3.172,0.366c-0.938-0.2-2.255,0.458-2.927,1.463c-0.67,1.006-1.219,2.706-1.219,3.78
        c0,1.072-0.274,2.444-0.61,3.049c-0.337,0.603-0.337,1.756,0,2.561c0.336,0.805,0.499,2.067,0.366,2.805s-0.023,2.219,0.245,3.292
        c0.269,1.074,0.377,2.281,0.244,2.683c-0.135,0.404-0.849,0.842-1.587,0.976s-1.069,1.066-0.732,2.073
        c0.336,1.006,0.611,2.212,0.611,2.683c0,0.469,0.274,1.182,0.611,1.585c0.336,0.402,0.61,1.663,0.61,2.805s0.221,2.513,0.489,3.048
        c0.269,0.537,0.377,1.414,0.244,1.952c-0.133,0.536,0.085,1.25,0.488,1.585c0.404,0.337,0.897,1.103,1.097,1.707
        s0.86,1.208,1.463,1.341s1.042,0.738,0.976,1.341c-0.067,0.603-0.36,0.959-0.122,1.098c0.454,0.266,0.768-0.549,1.707-1.219
        c0.939-0.671,1.927-1.219,2.195-1.219s0.706,0.329,0.974,0.733c0.268,0.402,1.366,0.898,2.439,1.098
        c1.074,0.201,2.611,1.08,3.414,1.951c0.805,0.871,1.957,1.695,2.561,1.83c0.602,0.133,1.425,1.066,1.829,2.072
        c0.402,1.006,1.39,1.883,2.195,1.951c0.805,0.067,1.791,0.616,2.195,1.219c0.402,0.604,1.224,1.702,1.829,2.439
        c0.602,0.738,0.548,1.891-0.123,2.561c-0.669,0.671-2.096,1.33-3.17,1.463c-1.072,0.133-2.39,0.189-2.927,0.122
        s-1.58,0.207-2.317,0.608c-0.738,0.404-1.891,1.446-2.561,2.318c-0.671,0.871-1.658,1.476-2.195,1.341
        c-0.536-0.133-1.468,0.249-2.072,0.852c-0.603,0.603-1.975,1.208-3.05,1.341c-1.072,0.133-2.06,0.683-2.194,1.22
        c-0.133,0.537,0.087,1.47,0.489,2.072c0.403,0.604,0.292,1.647-0.244,2.317c-0.537,0.671-0.647,1.33-0.244,1.463
        c0.402,0.133,0.73,0.738,0.73,1.341s0.604,1.427,1.341,1.829c0.738,0.404,1.177,1.281,0.974,1.952
        c-0.2,0.671-0.914,1.493-1.585,1.829c-0.671,0.337-1.987,0.776-2.926,0.976s-1.981,0.749-2.316,1.219
        c-0.337,0.47-0.555,1.348-0.489,1.952c0.067,0.602-0.372,1.37-0.976,1.706c-0.603,0.337-1.371,0.611-1.707,0.611
        s-1.048,0.165-1.585,0.367c-0.537,0.2-1.141,0.969-1.341,1.707s-0.86,1.067-1.463,0.733c-0.603-0.337-1.647-0.445-2.317-0.245
        c-0.671,0.2-1.493,0.86-1.83,1.463c-0.336,0.604-0.61,1.647-0.61,2.317s0.111,1.823,0.245,2.561
        c0.133,0.738,0.158,1.011,0.244,1.341c0.167,0.654,0.383,1.153,0.854,2.561c0.469,1.408,1.237,3.274,1.706,4.146
        c0.47,0.872,1.513,2.079,2.317,2.683c0.805,0.603,2.117,1.052,2.918,0.996c0.799-0.055,1.09-0.071,1.453-0.101
        c0.279-0.024,0.503-0.05,1.117-0.112c0.615-0.063,1.62,0.09,2.235,0.336c0.616,0.246,1.168,1.202,1.232,2.123
        c0.062,0.923,0.464,1.627,0.894,1.565c0.43-0.063,1.134-0.011,1.564,0.109c0.431,0.123,0.734,0.778,0.67,1.454
        c-0.062,0.676,0.241,1.531,0.67,1.901c0.431,0.37,0.783,1.275,0.783,2.012c0,0.738,0.201,1.744,0.448,2.236
        c0.245,0.491,0.849,0.793,1.341,0.671c0.491-0.122,0.894-0.878,0.894-1.677c0-0.801,0-2.058,0-2.796s0.302-1.442,0.67-1.564
        c0.37-0.123,0.67-0.828,0.67-1.565c0-0.738,0.454-1.693,1.006-2.123c0.553-0.431,1.105-1.286,1.228-1.902
        c0.122-0.615,0.778-1.62,1.454-2.235s1.683-1.319,2.236-1.564c0.553-0.246,1.006-0.951,1.006-1.566c0-0.615,0.352-1.47,0.782-1.899
        c0.43-0.43,1.285-0.832,1.899-0.894c0.616-0.063,2.225-0.465,3.579-0.896c1.351-0.43,3.113-0.43,3.911,0
        c0.799,0.431,1.906-0.122,2.459-1.228s1.61-1.962,2.347-1.899c0.738,0.062,1.492-0.141,1.677-0.448
        c0.185-0.308,0.89-0.357,1.565-0.113c0.676,0.246,1.985,1.001,2.906,1.677c0.923,0.678,2.381,1.08,3.242,0.895
        c0.86-0.185,1.916-1.04,2.347-1.901c0.43-0.86,0.832-2.067,0.894-2.683c0.062-0.615,0.313-1.62,0.558-2.235
        c0.246-0.615,0.901-0.967,1.454-0.782s1.61,0.084,2.347-0.224c0.738-0.308,1.795-0.509,2.347-0.448
        c0.553,0.063,1.408-0.188,1.899-0.558c0.493-0.37,1.095-1.324,1.341-2.125c0.245-0.799,0.749-2.057,1.119-2.794
        c0.37-0.738,0.923-1.392,1.228-1.453c0.308-0.062,0.911,0.039,1.341,0.224c0.431,0.185,1.135,0.336,1.567,0.336
        c0.43,0,0.732-0.603,0.671-1.341c-0.063-0.738,0.24-1.492,0.67-1.677s1.134-0.838,1.564-1.453c0.43-0.616,1.033-0.665,1.341-0.113
        c0.308,0.553,0.911,1.257,1.341,1.564c0.43,0.308,1.184,0.559,1.677,0.559s1.146-0.402,1.454-0.895
        c0.307-0.491,0.911-1.598,1.341-2.458c0.431-0.861,0.982-2.019,1.228-2.571c0.246-0.553,0.397-1.509,0.336-2.123
        c-0.062-0.616,0.744-1.168,1.789-1.231c1.044-0.062,2.302-0.412,2.793-0.782c0.493-0.37,1.448-0.57,2.124-0.446
        c0.676,0.123,1.883-0.078,2.683-0.446c0.799-0.369,2.157-0.621,3.018-0.559c0.861,0.061,1.917,0.112,2.347,0.112
        s1.487-0.454,2.347-1.006c0.861-0.553,1.516-1.509,1.454-2.123c-0.063-0.615,0.089-1.369,0.336-1.677
        c0.245-0.308,0.748-0.509,1.117-0.448c0.37,0.063,1.023-0.44,1.453-1.116c0.43-0.678,1.336-1.03,2.011-0.783
        c0.676,0.246,1.733,0.146,2.347-0.224c0.615-0.369,1.57-0.871,2.123-1.117s1.408-0.548,1.901-0.67
        c0.493-0.123,1.247-0.626,1.677-1.119c0.43-0.491,1.236-0.994,1.788-1.116c0.553-0.123,1.56-0.978,2.236-1.901
        s1.683-1.576,2.236-1.453c0.553,0.122,1.862,0.021,2.906-0.225c1.043-0.245,2.101-0.548,2.347-0.671
        c0.245-0.122,0.497-0.474,0.558-0.781c0.062-0.308,0.112-0.962,0.112-1.454c0-0.493-0.955-1.247-2.123-1.677
        c-1.168-0.43-2.224-1.336-2.347-2.012s-0.878-1.23-1.677-1.23s-1.051-0.754-0.558-1.677c0.491-0.923,1.044-2.331,1.228-3.129
        c0.185-0.799,0.688-1.1,1.119-0.671c0.43,0.43,1.285,0.733,1.899,0.671c0.615-0.062,1.418,0.29,1.788,0.783
        c0.369,0.491,1.677,0.491,2.906,0c1.229-0.493,2.488-0.593,2.796-0.224c0.307,0.37,0.91,0.72,1.341,0.782
        c0.43,0.062,1.083,0.313,1.453,0.558c0.37,0.246,1.023,0.952,1.453,1.567s0.831,1.671,0.894,2.347
        c0.062,0.676-0.188,1.833-0.558,2.57s-0.37,1.341,0,1.341s1.324-0.25,2.124-0.558c0.799-0.307,1.352,0.044,1.228,0.782
        c-0.123,0.738-0.375,2.096-0.559,3.018c-0.185,0.923,0.419,1.928,1.341,2.236c0.923,0.307,2.231,0.759,2.907,1.006
        c0.676,0.245,1.683,0.548,2.236,0.67c0.553,0.122,1.408-0.028,1.901-0.336c0.491-0.307,1.296-1.062,1.786-1.677
        c0.493-0.616,0.819-0.768,0.895-1.119c0.073-0.338-0.151-0.604-0.336-1.341c-0.185-0.738,0.167-1.945,0.782-2.683
        c0.616-0.738,1.32-2.298,1.566-3.466c0.245-1.167,0.698-2.626,1.006-3.241c0.307-0.615,0.759-1.972,1.006-3.018
        c0.245-1.046,0.698-2.756,1.006-3.802c0.307-1.043,0.709-2.352,0.894-2.905s0.537-1.509,0.783-2.124
        c0.245-0.615,0.397-2.072,0.336-3.241c-0.063-1.168-0.062-2.626,0-3.241C719.17,349.928,719.469,348.671,719.777,347.748z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_7_" x="603.49" y="359.884" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" strokeMiterlimit="10" enable-background="new    " width="57.692" height="20.279" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_GERMANY_SCHLESWIGHOLSTEIN" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Schleswig-Holstein" data-infoText="<b>Schleswig-Holstein</b> &amp;nbsp; Capital: Kiel">
    <path fill="#1e8346" d="M513.47,89.799c-0.364-0.181-1.086-0.014-1.669,0.351c-0.45,0.283-1.467,0.62-2.22,0.735
        c-0.772,0.117-2.077-0.042-2.855-0.319c-0.45-0.161-0.942-0.25-1.38-0.25c-0.348,0-0.82,0.058-1.091,0.329
        c-0.285,0.284-0.968,0.77-1.492,1.061c-0.582,0.324-1.424,1.01-1.877,1.526c-0.422,0.484-1.409,1.42-2.201,2.089
        c-0.842,0.712-1.744,1.149-2.019,1.149h-2.459c-0.818,0-2.078-0.757-2.749-1.654c-0.767-1.022-2.223-2.269-3.248-2.781
        c-0.999-0.5-2.468-1.158-3.272-1.466c-0.781-0.299-2.311-1.09-3.409-1.76c-1.11-0.679-2.621-1.586-3.36-2.016
        c-0.793-0.464-1.851-0.828-2.405-0.828c-0.549,0-1.456,0.263-2.062,0.6c-0.525,0.292-1.516,0.686-2.16,0.864
        c-0.777,0.212-1.71,1.13-2.121,2.092c-0.367,0.854-0.923,2.268-1.238,3.15c-0.274,0.768-0.676,1.673-0.948,2.114
        c-0.026-0.058-0.058-0.148-0.084-0.279c-0.127-0.635-0.386-1.517-0.581-1.967c-0.08-0.187-0.164-0.326-0.234-0.44
        c-0.021-0.034-0.044-0.072-0.063-0.103c0.029-0.014,0.063-0.03,0.093-0.045c0.114-0.054,0.255-0.121,0.416-0.228
        c0.401-0.268,1.026-0.891,1.424-1.418c0.443-0.591,0.508-1.674,0.148-2.465c-0.253-0.558-0.219-1.393,0.076-1.783
        c0.494-0.658,0.035-1.818-1.065-2.699c-0.854-0.682-2.097-1.477-2.7-1.628c-0.568-0.144-1.62,0.026-2.343,0.354
        c-0.65,0.297-1.97,0.686-2.94,0.87c-0.985,0.185-2.295,0.436-2.911,0.559s-1.574,0.326-2.13,0.447
        c-0.427,0.093-1.089-0.079-1.367-0.356c-0.059-0.059-0.081-0.123-0.069-0.205c0.023-0.152,0.18-0.402,0.552-0.605
        c0.66-0.362,1.854-0.959,2.662-1.332c0.823-0.38,2.006-1.101,2.639-1.608c0.591-0.473,1.265-1.253,1.351-1.772
        c0.055-0.324,0.287-0.879,0.507-1.21c0.326-0.49,0.503-1.901,0.503-2.981v-4.246c0-1.306-0.572-2.617-1.303-2.983
        c-0.627-0.314-1.449-0.674-1.833-0.802c-0.477-0.157-1.135-0.02-1.461,0.305c-0.096,0.097-0.217,0.178-0.331,0.239
        c0.041-0.072,0.096-0.153,0.165-0.247c0.334-0.444,0.936-0.959,1.316-1.123c0.424-0.181,0.976-0.308,1.209-0.308
        c0.187,0,0.351-0.09,0.453-0.246c0.273-0.424-0.012-1.16-0.148-1.458c-0.289-0.636-0.583-1.864-0.642-2.679
        c-0.068-0.973-0.726-2.118-1.496-2.607c-0.681-0.432-1.592-0.991-2.026-1.238c-0.648-0.367-1.283-0.083-1.284,0.514l0.007,0.967
        c-0.014,0.028-0.117,0.121-0.298,0.157c-0.437,0.088-0.837,0.435-0.93,0.808c-0.024,0.093-0.255,0.33-0.592,0.435
        c-0.26,0-0.824-0.344-1.282-0.902c-0.558-0.681-1.52-1.746-2.146-2.372c-0.669-0.669-1.88-1.301-2.757-1.436
        c-0.801-0.123-2.16-0.324-3.022-0.446c-0.819-0.118-2-0.463-2.58-0.752c-0.578-0.289-1.078-0.883-1.165-1.234
        c-0.168-0.674-1.006-0.992-1.79-0.678c-0.688,0.274-1.448,0.878-1.733,1.374c-0.198,0.347-0.823,0.742-1.338,0.843
        c-0.465,0.093-0.964-0.093-1.08-0.362c-0.066-0.153-0.102-0.28-0.131-0.383c-0.057-0.2-0.117-0.407-0.329-0.547
        c-0.196-0.128-0.404-0.143-0.758-0.096c-0.143,0.019-0.318,0.04-0.543,0.04c-0.698,0-1.679,0.38-2.233,0.865
        c-0.446,0.391-1.493,0.815-2.285,0.928c-0.851,0.121-1.934,0.22-2.412,0.22c-0.366,0-0.852-0.332-1.041-0.709
        c-0.22-0.441-0.289-1.055-0.205-1.31c0.305-0.921-2.153-1.998-2.649-2.204c-1.506-0.627-3.85-1.289-5.226-1.476
        c-1.289-0.177-2.958-0.762-3.645-1.277c-0.783-0.588-2.319-1.12-3.424-1.185c-1.026-0.06-2.495-0.304-3.275-0.544
        c-0.868-0.266-2.989-0.052-4.665,0.448c-1.468,0.432-3.381,0.344-3.927-0.151c-0.245-0.223-0.413-0.443-0.562-0.636
        c-0.263-0.343-0.544-0.665-0.972-0.561c-0.352,0.085-0.412,0.414-0.456,0.655c-0.023,0.123-0.05,0.277-0.114,0.448
        c-0.104,0.276-0.232,0.482-0.303,0.591c-0.039-0.058-0.082-0.122-0.118-0.178c-0.287-0.439-0.613-0.938-1.054-0.938
        c-0.448,0-1.378,0.103-2.071,0.229c-0.668,0.121-1.695,0.219-2.287,0.219c-0.673,0-1.622,0.212-2.161,0.481
        c-0.411,0.204-1.272,0.299-1.798,0.194c-0.572-0.114-1.5-0.456-2.026-0.747c-0.331-0.184-0.788-0.112-1.128-0.02
        c-0.122,0.034-0.248,0.068-0.341,0.072c-0.248-0.298-0.422-0.909-0.372-1.309c0.02-0.158,0.138-0.456,0.263-0.77
        c0.205-0.511,0.458-1.148,0.498-1.708c0.06-0.837,0.16-1.79,0.218-2.082c0.045-0.224,0.357-0.779,0.709-1.188
        c0.171-0.2,0.298-0.43,0.422-0.653c0.163-0.295,0.317-0.575,0.553-0.752c0.585-0.438,0.987-1.452,0.915-2.308
        c-0.044-0.541,0.234-1.052,0.598-1.092c0.534-0.059,0.68-0.291,0.708-0.475c0.043-0.28-0.162-0.524-0.61-0.722
        c-0.36-0.16-0.801-0.251-1.21-0.251c-0.21,0-0.51,0.025-0.754,0.147c-0.498,0.25-1.311,1.412-1.822,2.377
        c-0.56,1.058-1.271,2.633-1.585,3.51c-0.306,0.856-1.105,2.906-1.783,4.569c-0.617,1.515-1.254,3.481-1.254,4.151
        c0,0.542-0.099,1.981-0.221,3.208c-0.125,1.246-0.276,3.26-0.337,4.488c-0.062,1.238-0.113,2.649-0.113,3.147
        c0,0.612,0.208,1.23,0.671,1.23c0.19,0,1.755-0.397,1.854-1.188c0.063-0.507,0.114-1.378,0.114-1.942
        c0-0.556,0.105-1.204,0.199-1.44c0.166-0.415,0.135-1.549,0.021-2.404c-0.122-0.916,0.053-1.974,0.287-2.348
        c0.225-0.361,0.149-0.732,0.099-0.977c-0.009-0.044-0.022-0.106-0.028-0.151c0.268-0.083,0.815,0.062,1.163,0.323
        c0.512,0.385,1.351,0.856,1.871,1.052c0.579,0.214,1.561,0.099,2.182-0.245c0.525-0.292,1.515-0.688,2.161-0.865
        c0.672-0.183,1.671-0.432,2.219-0.554c0.524-0.117,1.565-0.215,2.275-0.215c0.708,0,1.693,0.097,2.154,0.212
        c0.253,0.064,0.586,0.699,0.529,1.552c-0.062,0.943-0.062,2.125,0.002,2.636c0.067,0.533,0.523,1.759,1.038,2.792
        c0.507,1.012,1.053,2.054,1.243,2.37c0.127,0.211,0.183,0.871,0.07,1.438c-0.154,0.773,0.371,1.672,1.196,2.048
        c0.597,0.273,1.305,0.879,1.516,1.299c0.277,0.554,1.074,1.306,1.816,1.711c0.593,0.322,1.234,1.243,1.398,2.01
        c0.188,0.876,0.544,2.15,0.796,2.837c0.246,0.679,0.3,1.371,0.228,1.551c-0.076,0.19-0.062,0.4,0.038,0.591
        c0.159,0.302,0.526,0.541,0.982,0.644c0.457,0.102,1.138,0.703,1.455,1.285c0.367,0.675,0.817,1.524,1.001,1.891
        c0.231,0.465,1.141,1.334,1.884,1.904c0.483,0.372,0.689,0.754,0.679,0.969c-0.003,0.072-0.031,0.118-0.094,0.157
        c-0.312,0.196-0.601,0.266-0.814,0.271c-0.273-0.274-0.924-0.479-1.425-0.542c-0.548-0.072-1.422,0.045-1.947,0.243
        c-0.523,0.196-1.414,0.72-1.989,1.167c-0.625,0.485-1.172,1.32-1.244,1.899c-0.071,0.568,0.047,1.301,0.269,1.667
        c0.238,0.395,0.77,0.75,1.239,0.829c0.264,0.043,0.691,0.05,0.923,0.05c0.373,0,0.794-0.015,1.173-0.048
        c0.741-0.061,1.676-0.422,1.948-0.967c0.182-0.364,0.637-1.173,0.994-1.766c0.341-0.568,1.007-1.285,1.456-1.565
        c0.322-0.201,0.971,0.077,1.339,0.584c0.425,0.583,0.509,1.712,0.187,2.465c-0.364,0.849-0.874,1.345-1.033,1.363
        c-0.509,0-1.521,0.553-2.213,1.072c-0.724,0.543-2.098,1.329-3.062,1.75c-0.598,0.262-1.294,0.425-1.816,0.425
        c-0.302,0-0.425-0.057-0.455-0.073c-0.231-0.133-0.633-0.2-1.192-0.2c-0.683,0-1.518,0.101-2.29,0.275
        c-1.377,0.313-3.073,0.661-3.781,0.779c-0.811,0.135-1.874,0.666-2.418,1.212c-0.522,0.522-0.945,1.312-0.745,1.814
        c0.195,0.488,0.889,0.619,1.484,0.278c0.195-0.111,0.48-0.17,0.529-0.054c0.111,0.275,0.131,0.587,0.108,0.655
        c-0.041,0.087-0.336,0.371-0.745,0.606c-0.358,0.202-0.982,0.343-1.351,0.294c-0.644-0.103-1.165,0.432-1.165,1.114
        c0,0.528,0.251,1.798,0.573,2.889c0.336,1.141,1.191,2.358,1.948,2.772c0.785,0.427,1.835,0.409,2.299-0.058
        c0.345-0.346,1.135-0.936,1.724-1.291c0.479-0.288,1.34-0.29,1.765-0.008c0.63,0.419,2.049,0.429,3.098,0.035
        c0.635-0.24,1.314-0.393,1.73-0.393c0.144,0,0.19,0.021,0.187,0.013c0.355,0.264,1.097,0.214,1.69-0.082
        c0.498-0.249,1.259-0.655,1.693-0.904c0.135-0.077,0.273-0.127,0.407-0.135c-0.006,0.185-0.099,0.605-0.314,1.036
        c-0.221,0.443-0.737,1.052-1.125,1.33c-0.405,0.289-1.451,0.837-2.284,1.194c-0.991,0.426-1.768,1.345-1.768,2.096
        c0,0.567-0.242,1.579-0.528,2.209c-0.321,0.708-0.67,1.556-0.796,1.933c-0.161,0.484-0.071,1.393,0.108,1.991
        c0.191,0.635,0.605,1.618,0.923,2.189c0.378,0.681,1.343,1.008,2.094,0.709c0.592-0.236,1.844-0.587,2.736-0.765
        c0.756-0.151,1.471,0.351,1.617,1.078c0.19,0.952,0.657,2.298,1.04,3.002c0.337,0.618,0.575,1.814,0.519,2.61
        c-0.057,0.791-0.483,1.935-0.933,2.497c-0.31,0.387-0.658,0.565-0.849,0.565c-0.072,0-0.096-0.024-0.105-0.033
        c-0.372-0.372-1.53-1.064-2.434-1.517c-1.07-0.534-2.464-0.575-3.053-0.064c-0.593,0.519-0.833,1.654-0.547,2.587
        c0.253,0.823,0.721,1.912,1.042,2.427c0.29,0.465,0.787,1.61,1.083,2.499c0.324,0.972,1.521,2.591,2.667,3.609
        c1.19,1.057,2.793,1.745,3.657,1.527c0.423-0.105,0.902-0.169,1.284-0.169c0.287,0,0.393,0.036,0.411,0.044
        c0.384,0.152,1.548,0.301,2.451,0.357l0.901,0.06c0.376,0.026,0.62,0.043,0.898,0.053c0.144,0.004,0.277,0.005,0.427,0.005
        l1.26-0.005c0.865,0,2.486,0.444,3.538,0.97c1.089,0.543,2.717,1.58,3.629,2.309c0.767,0.613,2.307,1.846,3.586,2.236
        c-0.013,0.102-0.024,0.236-0.024,0.407c0,0.259,0.016,0.415,0.031,0.517c-1.376,1.828,0.946,4.458,1.944,5.588l0.228,0.26
        c0.707,0.836,1.864,1.778,2.578,2.101c0.519,0.236,1.399,1.404,1.832,2.706c0.458,1.373,0.764,2.787,0.764,3.247
        c0,0.74,0.91,2.111,1.767,3.025c0.945,1.007,2.286,2.141,2.991,2.526c0.696,0.378,1.979,0.892,2.862,1.145
        c0.24,0.069,0.522,0.186,0.813,0.308c0.855,0.353,1.773,0.666,2.257,0.307c0.331-0.244,0.306-0.664,0.284-1.035
        c-0.008-0.151-0.02-0.307-0.002-0.451c0.131-1.116,0.779-2.172,1.28-2.422c0.49-0.246,1.126,0.025,1.374,0.571
        c0.413,0.91,1.804,0.921,2.954,0c1.004-0.803,2.319-1.844,2.923-2.313c0.529-0.41,1.188-0.666,1.56-0.666
        c0.003,0,0.006,0.001,0.008,0.001c0.557,0.079,1.59-0.48,2.231-1.058c0.638-0.573,1.594-1.213,2.088-1.398
        c0.637-0.24,1.316-1.089,1.547-1.932c0.159-0.584,0.693-1.095,1.14-1.095c0.652,0,1.834-0.279,2.689-0.636
        c0.525-0.217,0.999-0.186,0.999,0.058c0,0.519-0.167,1.162-0.317,1.413c-0.225,0.373-0.459,1.014-0.532,1.458
        c-0.09,0.547,0.364,1.238,1.035,1.575c0.468,0.235,1.064,0.935,1.302,1.531c0.254,0.635,0.523,1.93,0.587,2.826
        c0.059,0.836-0.337,1.938-0.865,2.409c-0.674,0.598-1.258,2.039-1.331,3.28c-0.068,1.14,0.299,2.46,1.107,2.66
        c0.431,0.107,1.007,0.698,1.233,1.265c0.299,0.745,0.951,1.568,1.487,1.873c0.403,0.23,0.908,0.783,1.078,1.183
        c0.241,0.564,0.93,1.13,1.568,1.289c0.439,0.112,0.821,0.478,0.869,0.734c0.029,0.146,0.034,0.262,0.039,0.356
        c0.01,0.19,0.023,0.406,0.203,0.576c0.194,0.183,0.435,0.183,0.579,0.183l0.157-0.001c0.098,0,0.212,0.002,0.347,0.02
        c0.378,0.048,1.502,0.672,2.514,1.493c1.004,0.816,2.591,1.839,3.534,2.278c0.928,0.434,2.298,0.992,3.054,1.245
        c0.391,0.129,0.928,0.201,1.512,0.201c0.582,0,1.146-0.07,1.593-0.197c0.316-0.091,0.572-0.126,0.798-0.157
        c0.386-0.054,0.72-0.099,0.947-0.419c0.218-0.305,0.15-0.632,0.079-0.978c-0.043-0.209-0.092-0.444-0.108-0.749
        c-0.05-0.806,0.072-2.435,0.271-3.632c0.178-1.081,0.706-1.679,1.055-1.679c0.001,0,0.003,0,0.005,0
        c0.746,0.167,1.569-0.132,1.835-0.665c0.126-0.253,0.948-0.821,2.001-1.173c1.089-0.363,2.238-1.121,2.617-1.726
        c0.332-0.529,0.919-1.331,1.312-1.789c0.448-0.524,0.856-1.516,0.931-2.258c0.054-0.547,0.647-1.274,1.268-1.556
        c0.601-0.274,1.609-0.176,2.094,0.204c0.798,0.62,1.671,0.351,1.758-0.471c0.069-0.616,0.124-1.507,0.124-1.987
        c0-0.44,0.165-1.696,0.362-2.743c0.209-1.109,0.262-2.641,0.121-3.414c-0.151-0.829-0.963-1.943-1.851-2.536
        c-0.771-0.513-1.794-1.032-2.259-1.032c-0.212,0-0.894-0.256-1.52-0.675c-0.539-0.361-1.167-1.032-1.343-1.438
        c-0.239-0.558-1.027-1.178-1.797-1.408c-0.44-0.132-0.805-1.009-0.62-2.059c0.209-1.176,0.209-3.089,0-4.263
        c-0.207-1.179-0.116-2.491,0.099-2.976c0.216-0.486,1.099-1.427,2.068-2.05c0.908-0.582,2.087-1.368,2.576-1.716
        c0.363-0.26,1.167-0.361,1.651-0.197c0.558,0.186,1.34,0.382,1.691,0.382c0.172,0,0.318-0.093,0.384-0.242
        c0.057-0.128,0.209-0.473-0.991-1.783c-0.733-0.799-1.04-1.711-0.931-1.988c0.068-0.173,0.142-0.3,0.2-0.405
        c0.117-0.206,0.238-0.417,0.152-0.692c-0.08-0.256-0.288-0.356-0.471-0.444c-0.092-0.044-0.203-0.097-0.333-0.183
        c-0.278-0.185-0.522-0.63-0.522-0.952c0-0.554-0.376-1.178-0.856-1.417c-0.381-0.191-1.208-0.502-1.84-0.691
        c-0.571-0.172-1.335-0.553-1.671-0.832c-0.316-0.262-0.737-0.971-0.9-1.516c-0.164-0.543-0.064-1.622,0.216-2.352
        c0.303-0.787,0.748-1.828,0.993-2.318c0.107-0.215,0.533-0.434,1.138-0.434c0.168,0,0.339,0.017,0.503,0.053
        c0.998,0.21,3.079-0.915,4.772-2.544c1.591-1.53,3.622-3.213,4.524-3.755c0.981-0.588,2.062-1.572,2.463-2.239
        c0.402-0.672,0.718-2.073,0.718-3.191c0-1.046,0.05-2.537,0.111-3.327c0.065-0.822,0.014-2.113-0.113-2.876
        c-0.119-0.719-0.22-2.077-0.22-2.963c0-0.85,0.38-2.091,0.831-2.708c0.174-0.239,0.727-1.057,0.595-1.599
        C513.702,89.993,513.606,89.868,513.47,89.799z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>
    <path fill="#1e8346" d="M381.981,124.75c-0.554,0.123-0.956,0.474-0.895,0.782c0.062,0.308,0.162,0.811,0.224,1.119
        s0.364,0.256,0.67-0.112c0.307-0.37,0.659-0.973,0.782-1.341C382.886,124.829,382.535,124.627,381.981,124.75z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>
    <path fill="#1e8346" d="M379.523,65.62c-0.43-0.801-0.531-1.906-0.224-2.459c0.307-0.554,0.207-0.806-0.224-0.559
        c-0.43,0.245-1.084,0.9-1.453,1.453s-0.671,1.509-0.671,2.123c0,0.615,0.403,1.722,0.895,2.459
        c0.493,0.739,1.246,1.341,1.677,1.341c0.43,0,0.983-0.2,1.228-0.446c0.246-0.245,0.246-0.9,0-1.453
        C380.506,67.526,379.952,66.419,379.523,65.62z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>
    <path fill="#1e8346" d="M390.924,60.589c-0.185-0.368-0.637-0.972-1.006-1.341c-0.369-0.368-1.123-0.568-1.677-0.446
        c-0.554,0.123-1.359,0.123-1.788,0c-0.43-0.122-1.235-0.022-1.788,0.224c-0.554,0.246-1.359,0.699-1.788,1.006
        c-0.43,0.307-0.732,0.911-0.67,1.341c0.061,0.43,0.815,1.286,1.677,1.901c0.861,0.615,2.52,1.218,3.688,1.341
        c1.167,0.122,2.475-0.582,2.905-1.565C390.907,62.065,391.108,60.958,390.924,60.589z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>
    <path fill="#1e8346" d="M522.376,87.081c-0.185-0.431-0.587-1.285-0.895-1.901c-0.308-0.616-0.861-1.672-1.23-2.347
        c-0.369-0.675-1.376-1.833-2.236-2.57c-0.861-0.738-2.117-1.443-2.793-1.565c-0.678-0.123-1.935-0.274-2.796-0.336
        s-1.967,0.392-2.46,1.006c-0.491,0.615-1.145,1.922-1.452,2.905c-0.308,0.984-0.458,2.091-0.336,2.459
        c0.123,0.37,0.524,0.571,0.894,0.448s1.223-0.324,1.899-0.446c0.678-0.123,1.482,0.681,1.789,1.788
        c0.308,1.106,1.112,1.911,1.789,1.788c0.676-0.122,1.431-0.525,1.677-0.895c0.245-0.368,0.9-0.419,1.453-0.112
        c0.553,0.308,1.459,0.559,2.013,0.559c0.553,0,1.459,0,2.012,0C522.257,87.863,522.561,87.511,522.376,87.081z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_9_" x="414.028" y="92.375" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" strokeMiterlimit="10" enable-background="new    " width="57.692" height="20.279" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_GERMANY_MECKLENBURGVORPOMMERN" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Mecklenburg-Vorpommern" data-infoText="<b>Mecklenburg-Vorpommern</b> &amp;nbsp; Capital: Schwerin">
    <path fill="#1e8346" d="M645.109,109.66c0.553,0.43,0.705,1.638,0.336,2.683c-0.37,1.046-0.821,2.402-1.006,3.018
        s-0.084,1.218,0.224,1.341c0.307,0.123,0.91-0.229,1.341-0.783c0.43-0.553,0.933-1.105,1.117-1.228
        c0.185-0.123,0.587-0.073,0.895,0.113c0.307,0.183,0.508,0.938,0.445,1.677c-0.062,0.736,0.141,1.341,0.448,1.341
        c0.308,0,0.759-0.152,1.006-0.336c0.246-0.186,0.649-0.788,0.894-1.341c0.246-0.554,0.397-1.409,0.336-1.901
        c-0.063-0.493,0.29-0.895,0.783-0.895s1.247,0.101,1.677,0.224c0.43,0.122,1.033,0.827,1.341,1.565
        c0.308,0.737,0.811,1.341,1.119,1.341c0.307,0,0.759,0.602,1.006,1.341c0.245,0.737,0.446,2.096,0.446,3.018
        c0,0.923-0.2,1.828-0.446,2.013c-0.245,0.185-0.651-0.068-0.896-0.559c-0.246-0.493-0.698-0.845-1.006-0.783
        c-0.307,0.062-0.759,0.313-1.006,0.559c-0.245,0.246-0.597,0.246-0.782,0s-0.185-0.9,0-1.454c0.185-0.553,0.135-1.157-0.112-1.341
        c-0.246-0.185-0.9-0.284-1.453-0.223c-0.553,0.062-1.106,0.513-1.23,1.006c-0.123,0.493,0.178,1.447,0.67,2.123
        c0.493,0.678,1.095,1.632,1.341,2.125c0.246,0.491,0.146,1.398-0.224,2.012c-0.37,0.615-1.223,1.369-1.901,1.677
        c-0.676,0.308-1.53,0.861-1.9,1.23c-0.37,0.368-0.571,0.871-0.448,1.118c0.122,0.245,0.626,0.597,1.119,0.782
        c0.493,0.185,1.146,0.336,1.454,0.336c0.307,0,0.96,0,1.452,0c0.493,0,1.146-0.151,1.454-0.336s0.811-0.385,1.119-0.446
        c0.307-0.062,0.91-0.363,1.341-0.671c0.43-0.308,1.084-0.761,1.453-1.006c0.37-0.246,0.923-0.446,1.229-0.446
        s0.81-0.152,1.116-0.336c0.308-0.186,0.861-0.186,1.229,0c0.37,0.183,1.275,0.233,2.013,0.112c0.738-0.123,1.896-0.123,2.571,0
        c0.676,0.122,0.999,0.436,1.229,0.224c0.207-0.191-0.05-0.503-0.112-1.119c-0.063-0.616-0.414-1.42-0.783-1.789
        c-0.37-0.368-0.37-0.972,0-1.341c0.37-0.368,0.68-0.434,0.67-0.671c-0.01-0.258-0.352-0.302-0.783-0.671
        c-0.43-0.368-1.687-1.475-2.794-2.459s-2.816-2.543-3.8-3.465c-0.984-0.923-2.441-2.331-3.241-3.129
        c-0.801-0.801-2.108-1.704-2.908-2.012c-0.799-0.308-2.005-0.759-2.683-1.006c-0.676-0.245-1.833-1.202-2.57-2.123
        c-0.738-0.923-1.545-1.979-1.789-2.347c-0.246-0.37-0.799-1.025-1.228-1.454c-0.43-0.43-0.984-0.681-1.228-0.559
        c-0.246,0.123-0.448,0.727-0.448,1.341c0,0.615,0.204,1.57,0.448,2.123C643.902,108.425,644.556,109.23,645.109,109.66z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>
    <path fill="#1e8346" d="M527.518,124.75c0.676,0.37,1.482,0.269,1.789-0.224c0.307-0.491,0.659-0.491,0.781,0
        c0.123,0.493,0.375,0.644,0.559,0.336c0.185-0.308,0.537-1.364,0.782-2.347c0.246-0.984,0.096-1.938-0.336-2.123
        c-0.43-0.185-1.184-0.034-1.677,0.336c-0.491,0.37-1.346,0.972-1.9,1.341c-0.553,0.37-1.056,0.972-1.119,1.341
        C526.337,123.778,526.842,124.381,527.518,124.75z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>
    <path fill="#1e8346" d="M615.375,88.312c-0.553,0-1.66,0.099-2.46,0.223c-0.799,0.123-1.603,0.828-1.788,1.564
        c-0.185,0.739,0.167,1.996,0.782,2.796c0.616,0.799,1.622,1.854,2.238,2.347c0.615,0.493,1.418,1.145,1.788,1.453
        c0.369,0.308,0.972,0.761,1.341,1.006c0.37,0.246,0.923,0.347,1.23,0.224s1.111-0.474,1.786-0.782
        c0.678-0.308,1.03-0.006,0.783,0.67c-0.246,0.676,0.157,1.431,0.895,1.677c0.738,0.245,1.643,0.548,2.013,0.671
        c0.37,0.122,0.972,0.122,1.341,0c0.37-0.123,0.671-0.678,0.671-1.23s-0.151-1.208-0.336-1.453
        c-0.185-0.246-0.636-0.347-1.006-0.224s-0.972,0.223-1.341,0.223c-0.37,0-0.419-0.302-0.112-0.671
        c0.307-0.368,0.81-0.72,1.116-0.782c0.308-0.062,0.912-0.765,1.341-1.564c0.431-0.801,1.085-1.604,1.454-1.788
        c0.369-0.186,0.972-0.689,1.341-1.119c0.37-0.43,1.124-0.833,1.677-0.895s1.559-0.262,2.235-0.446c0.678-0.185,1.632-0.185,2.125,0
        s1.146,0.034,1.454-0.336c0.307-0.37,0.96-0.469,1.452-0.224c0.493,0.246,0.392,0.85-0.222,1.341
        c-0.615,0.493-0.513,0.744,0.224,0.559c0.738-0.185,1.492-0.185,1.677,0s-0.067,0.688-0.559,1.118
        c-0.493,0.431-0.944,1.033-1.006,1.341s0.241,0.459,0.671,0.336c0.43-0.122,0.984,0.028,1.228,0.336
        c0.246,0.308,0.65,0.61,0.896,0.67c0.245,0.062,0.497-0.693,0.558-1.677c0.062-0.984,0.313-2.241,0.558-2.794
        c0.246-0.553,0.096-1.307-0.336-1.677c-0.43-0.37-0.982-1.023-1.228-1.453c-0.246-0.431-0.849-1.135-1.341-1.565
        c-0.493-0.43-1.498-0.833-2.236-0.895c-0.738-0.062-1.541-0.464-1.788-0.894c-0.246-0.431-0.748-1.387-1.117-2.123
        c-0.37-0.739-0.568-1.795-0.446-2.347c0.123-0.554,0.426-1.309,0.67-1.677c0.246-0.37,0.9-1.025,1.453-1.454
        c0.553-0.43,1.207-1.236,1.453-1.789c0.246-0.553,0.446-1.559,0.446-2.235s-0.454-1.632-1.006-2.123
        c-0.553-0.493-1.359-0.845-1.788-0.783c-0.43,0.061-1.586,0.262-2.57,0.446c-0.985,0.185-2.443,0.486-3.243,0.671
        c-0.799,0.185-2.057-0.015-2.794-0.446c-0.738-0.43-1.593-1.385-1.901-2.123c-0.307-0.738-0.61-1.693-0.671-2.123
        c-0.063-0.431,0.29-0.984,0.783-1.23c0.493-0.246,1.046-0.9,1.229-1.454c0.185-0.553-0.269-1.105-1.006-1.228
        s-1.844,0.029-2.459,0.336c-0.616,0.307-1.672,0.558-2.347,0.558c-0.678,0-1.834,0.554-2.571,1.23
        c-0.738,0.678-1.391,1.432-1.453,1.677c-0.063,0.246,0.491,0.246,1.228,0c0.738-0.245,1.544-0.446,1.789-0.446
        s0.295,0.604,0.112,1.341c-0.185,0.738-0.587,1.894-0.895,2.571c-0.308,0.676-0.559,1.63-0.559,2.123
        c0,0.493,0.352,0.793,0.782,0.671c0.431-0.123,0.934-0.474,1.119-0.782s0.788-0.962,1.341-1.454
        c0.553-0.491,1.157-1.246,1.341-1.677c0.185-0.43,0.587,0.023,0.894,1.006c0.308,0.984,1.114,1.938,1.789,2.123
        c0.676,0.185,1.683,0.587,2.236,0.895c0.553,0.308,1.207,0.357,1.453,0.113c0.246-0.246,0.649-0.347,0.894-0.224
        c0.246,0.123,0.499,0.777,0.561,1.453c0.063,0.678,0.161,1.482,0.223,1.789s0.062,0.96,0,1.453s0.241,1.095,0.67,1.341
        c0.43,0.246,0.882,0.95,1.006,1.565c0.123,0.615,0.375,1.268,0.559,1.453c0.185,0.185,0.133,0.537-0.112,0.782
        c-0.246,0.246-0.397,0.598-0.336,0.782c0.063,0.186,0.01,0.537-0.113,0.783s-0.576,0.448-1.006,0.448s-0.933-0.303-1.117-0.67
        c-0.185-0.37-0.185-0.923,0-1.23c0.185-0.308,0.034-0.761-0.336-1.006c-0.369-0.246-0.52-0.949-0.336-1.565
        c0.185-0.616-0.017-1.119-0.446-1.119c-0.431,0-1.237-0.302-1.789-0.67c-0.553-0.368-1.006-0.972-1.006-1.341
        c0-0.368-0.25-0.972-0.558-1.341c-0.307-0.368-1.062-0.317-1.677,0.113c-0.616,0.43-1.17,0.178-1.232-0.558
        c-0.063-0.739-0.062-1.896,0-2.571c0.063-0.677-0.341-0.777-0.894-0.224s-1.106,1.711-1.228,2.571
        c-0.123,0.861-0.426,1.162-0.671,0.67c-0.246-0.493-0.85-1.096-1.341-1.341c-0.493-0.246-1.448-0.347-2.124-0.224
        s-1.733,0.274-2.347,0.336c-0.615,0.062-0.967,0.414-0.782,0.783c0.185,0.368,0.535,1.071,0.782,1.564
        c0.246,0.493,1.051,0.996,1.788,1.117c0.738,0.123,1.341,0.525,1.341,0.895s0.352,0.923,0.782,1.23
        c0.431,0.308,0.381,0.709-0.11,0.895c-0.493,0.183-1.448,0.636-2.125,1.006c-0.676,0.368-1.38,1.174-1.565,1.788
        s0.167,1.067,0.782,1.006c0.616-0.062,1.571,0.693,2.125,1.677c0.553,0.984,0.855,1.889,0.67,2.012
        C616.532,88.209,615.928,88.312,615.375,88.312z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>
    <path fill="#1e8346" d="M608.446,78.698c0.307-0.861,0.81-1.967,1.116-2.46c0.308-0.491,0.559-1.598,0.559-2.459
        s0.101-1.867,0.224-2.235c0.123-0.37,0.425-0.621,0.67-0.559c0.246,0.062,0.446-0.241,0.446-0.671c0-0.431-0.302-0.782-0.671-0.782
        c-0.37,0-0.923,0.251-1.23,0.558s-0.558,0.861-0.558,1.228c0,0.37,0,1.326,0,2.125s-0.251,2.057-0.558,2.796
        c-0.308,0.737-0.559,1.943-0.559,2.683C607.887,79.658,608.138,79.559,608.446,78.698z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_10_" x="561.634" y="133.439" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" strokeMiterlimit="10" enable-background="new    " width="57.692" height="20.279" pointer-events="auto" visibility="hidden"></rect>
    <g data-colors="0">
        <path fill="#1e8346" d="M528.837,200.881c-0.604,0.194-1.17,0.503-1.789,0.645s-1.338,0.083-1.784-0.368
            c-0.415-0.419-0.492-1.086-0.331-1.653s0.524-1.053,0.902-1.505c1.322-1.583,2.908-2.92,4.485-4.249
            c1.128-0.951,10.618-8.542,16.385-11.676c0.287-0.225,0.564-0.439,0.797-0.61c0.364-0.264,0.765-0.422,1.072-0.422
            c0.046,0,0.129,0.015,0.199,0.047c0.121-0.047,0.243-0.094,0.357-0.132c5.548-1.86,9.762-3.449,10.365-4.387
            c0.604-0.939,1.426-1.323,1.83-0.854c0.402,0.469,1.281,0.415,1.951-0.122c0.671-0.536,1.439-0.48,1.707,0.122
            c0.268,0.605,0.928,0.769,1.464,0.367c0.538-0.401,1.304-0.732,1.706-0.732c0.403,0,1.171,0.549,1.708,1.22
            c0.536,0.67,1.304,1.218,1.708,1.218c0.402,0,1.389,0.275,2.194,0.611c0.804,0.335,1.847,0.721,2.317,0.854
            c0.469,0.133,0.963,0.628,1.098,1.097c0.134,0.469,1.176,1.401,2.316,2.072c1.14,0.671,2.732,1.055,3.536,0.854
            c0.805-0.201,2.067-0.036,2.805,0.366c0.738,0.403,1.945,0.678,2.683,0.61c0.737-0.066,1.78,0.262,2.317,0.732
            c0.535,0.471,1.797,0.963,2.803,1.098c1.008,0.134,1.941,0.903,2.074,1.707s0.793,1.354,1.463,1.219
            c0.671-0.133,1.988-0.079,2.926,0.122c0.94,0.202,2.312,0.367,3.05,0.367c0.737,0,1.781-0.22,2.316-0.489
            c0.537-0.269,1.086,0.061,1.221,0.732c0.133,0.671,0.572,1.384,0.974,1.585c0.404,0.201,1.39-0.514,2.195-1.585
            c0.805-1.075,1.902-2.447,2.439-3.05c0.536-0.603,1.468-1.866,2.072-2.805c0.604-0.941,1.482-1.213,1.952-0.61
            c0.469,0.602,1.292,1.262,1.829,1.463c0.537,0.2,1.47,0.09,2.072-0.244c0.604-0.335,1.317-1.105,1.585-1.707
            c0.269-0.606,0.872-1.372,1.341-1.708c0.47-0.336,1.568-0.445,2.439-0.244c0.872,0.201,1.531,0.804,1.464,1.341
            c-0.066,0.537,0.044,1.194,0.244,1.463s0.694,0.323,1.098,0.122c0.402-0.201,0.95-1.134,1.218-2.072
            c0.269-0.939,1.092-1.926,1.83-2.195s1.672-0.927,2.074-1.463c0.402-0.537,0.73-1.58,0.73-2.317s0.384-2.164,0.854-3.17
            c0.469-1.006,1.237-2.269,1.707-2.805c0.47-0.537,1.459-0.976,2.195-0.976s2.932,0.111,4.876,0.244
            c1.945,0.134,4.25,0.354,5.122,0.487c0.873,0.136,2.189-0.303,2.927-0.974s2.056-1.33,2.927-1.463
            c0.872-0.135,2.19,0.359,2.928,1.096c0.739,0.737,1.67,2.221,2.074,3.293c0.402,1.073,1.004,2.499,1.341,3.17
            c0.335,0.67,0.937,1.658,1.341,2.195c0.402,0.538,0.731,1.416,0.731,1.951c0,0.537,0.384,0.976,0.854,0.976
            c0.469,0,1.512,0.166,2.316,0.366c0.805,0.201,2.067,0.092,2.805-0.244c0.737-0.335,1.669-1.432,2.073-2.438
            c0.402-1.006,2.24-2.305,4.084-2.887c1.845-0.582,2.616-1.533,3.354-1.056c0.107,0.069,0.162,0.164,0.191,0.284
            c0.034-0.049,0.078-0.082,0.107-0.139c0.259-0.503,0.002-0.962-0.293-1.494c-0.177-0.317-0.396-0.711-0.581-1.22
            c-0.49-1.341-1.085-3.425-1.329-4.645c-0.248-1.236-0.652-2.999-0.901-3.932c-0.25-0.944-1.066-2.833-1.816-4.211
            c-0.703-1.285-1.194-3.657-1.079-5.178c0.114-1.471-0.028-3.382-0.512-4.028c-0.339-0.451-0.773-1.519-0.947-2.329
            c-0.189-0.878-0.493-2.093-0.677-2.709c-0.067-0.224-0.096-0.409-0.119-0.558c-0.044-0.28-0.087-0.543-0.337-0.73
            c-0.182-0.136-0.382-0.153-0.723-0.106c-0.127,0.017-0.284,0.039-0.484,0.039c-0.48,0-1.178-0.182-1.491-0.391
            c-0.048-0.032-0.069-0.069-0.073-0.128c-0.008-0.122,0.074-0.346,0.333-0.57c0.564-0.495,1.007-1.342,1.007-1.93
            c0-0.569-0.279-1.35-0.635-1.779c-0.448-0.532-1.27-0.301-1.677,0.511c-0.244,0.486-1.056,0.857-1.686,0.822
            c-0.844-0.064-2.243-0.009-3.091,0.114c-0.812,0.117-2.444-0.392-3.581-1.074c-1.138-0.684-2.725-1.39-3.413-1.39
            c-0.47,0-1.306-0.377-1.789-0.808c-0.566-0.504-1.462-1.35-1.997-1.886c-0.561-0.562-1.477-1.377-2.044-1.817
            c-0.566-0.44-1.388-1.003-1.829-1.256c-0.284-0.162-0.425-0.598-0.299-0.933c0.17-0.456,0.603-1.178,0.945-1.576
            c0.372-0.432,1.066-1.177,1.549-1.658c0.522-0.52,1.09-1.246,1.292-1.652c0.249-0.498,0.074-1.256-0.398-1.729
            c-0.405-0.404-0.994-0.828-1.343-0.968c-0.137-0.055-0.507-0.538-0.695-1.228c-0.209-0.762-1.204-1.878-2.269-2.544
            c-0.935-0.584-2.015-1.511-2.358-2.025c-0.201-0.303-0.28-0.537-0.299-0.652c0.367-0.18,0.669-0.717,0.791-1.206
            c0.124-0.495,0.423-1.54,0.666-2.331c0.271-0.881,0.264-2.065-0.014-2.694c-0.274-0.615-1.066-1.465-1.804-1.935
            c-0.679-0.432-1.574-1.028-1.996-1.331c-0.352-0.253-0.572-0.748-0.534-1.022c0.043-0.297-0.053-0.478-0.141-0.579
            c-0.234-0.274-0.681-0.272-1.153,0.025c-0.491,0.308-1.447,0.913-2.123,1.343c-0.641,0.407-1.663,0.847-2.234,0.96
            c-0.603,0.121-1.751,0.319-2.558,0.444c-0.774,0.119-1.783,0.524-2.127,1.038c-0.293,0.44-0.414,1.338-0.273,2.04
            c0.078,0.392-0.021,0.591-0.064,0.61c-0.336-0.055-0.906-0.246-1.247-0.416c-0.218-0.108-0.694-0.865-0.944-1.799
            c-0.254-0.957-0.869-1.926-1.552-1.926c-0.182,0-0.446-0.348-0.446-0.894c0-0.862-0.852-1.564-1.901-1.564
            c-0.774,0-1.314-0.356-1.344-0.601c-0.051-0.41-0.25-1.141-0.668-1.28c-0.092-0.04-0.425-0.342-0.734-0.774
            s-0.917-1.242-1.349-1.8c-0.5-0.644-1.409-1.109-2.089-1.021c-0.495,0.051-1.037-0.098-1.179-0.24
            c-0.31-0.31-1.209-0.792-1.898-1.078c-0.752-0.313-1.911-0.768-2.584-1.012c-0.567-0.206-1.133-0.906-1.238-1.529
            c-0.128-0.772-0.597-2.025-1.047-2.797c-0.385-0.661-0.576-1.72-0.412-2.313c0.18-0.655,0.231-1.628-0.024-2.138
            c-0.234-0.466-0.886-0.891-1.488-0.965c-0.41-0.051-0.851-0.358-0.972-0.601c-0.16-0.318-0.301-1.084-0.301-1.638
            c0-0.422-0.059-1.306-0.279-1.638c-0.06-0.09-0.207-0.244-0.52-0.244c-0.298,0-0.681,0.135-1.055,0.368
            c-0.494,0.309-1.504,0.964-2.244,1.458c-0.754,0.504-1.681,1.224-2.063,1.605c-0.419,0.419-0.806,1.143-0.876,1.643
            c-0.045,0.314-0.426,0.949-0.896,1.361c-0.488,0.427-1.056,0.699-1.232,0.699c-0.273,0-0.787-0.094-1.125-0.207
            c-0.23-0.076-0.44-0.351-0.44-0.576c0-0.503-0.338-1.032-0.77-1.206c-0.445-0.175-1.041,0.038-1.305,0.474
            c-0.087,0.142-0.244,0.235-0.346,0.209c-0.356-0.176-0.863-0.124-1.32,0.169c-0.076,0-0.162-0.06-0.217-0.153
            c-0.234-0.387-0.699-0.686-1.114-0.719c0.007-0.058,0.035-0.153,0.108-0.283c0.211-0.368,0.255-0.66,0.135-0.87
            c-0.122-0.21-0.377-0.282-0.66-0.167c-0.391,0.156-1.046,0.672-1.454,1.081c-0.409,0.407-1.35,1.1-2.057,1.513
            c-0.498,0.29-0.949,0.45-1.181,0.466c-0.244-0.263-0.724-0.401-1.109-0.401c-0.534,0-1.024,0.417-1.112,0.949
            c-0.043,0.253-0.377,0.74-0.761,1.014c-0.427,0.307-1.228,0.856-1.779,1.223c-0.635,0.424-1.19,1.214-1.264,1.801
            c-0.048,0.381-0.432,0.888-0.822,1.084c-0.537,0.269-1.223,0.798-1.563,1.204c-0.396,0.475-0.411,1.378-0.035,2.054
            c0.273,0.493,0.299,0.884,0.282,0.94c-0.025,0.021-0.372,0.06-0.62-0.062c-0.262-0.131-0.672-0.595-0.842-1.048
            c-0.057-0.15,0.014-0.44,0.078-0.697c0.089-0.368,0.182-0.749,0.054-1.092c-0.146-0.386-0.06-1.025,0.182-1.364
            c0.336-0.469,0.704-1.307,0.838-1.911c0.114-0.52,0.385-0.944,0.493-1.002c0.42-0.209,0.68-0.979,0.743-1.608
            c0.051-0.514,0.404-1.187,0.754-1.438c0.249-0.178,0.352-0.348,0.458-0.497c0.309,0,0.911,0.189,1.287,0.404
            c0.534,0.307,1.374,0.24,1.834-0.145c0.308-0.256,0.939-0.481,1.35-0.481c0.678,0,1.228-0.502,1.228-1.118
            c0-0.284,0.21-0.612,0.441-0.688c0.406-0.136,0.992-0.455,1.332-0.727c0.192-0.155,0.559-0.194,0.738-0.085
            c0.444,0.264,1.267-0.014,1.863-0.611c0.39-0.387,1.065-0.661,1.348-0.586c0.371,0.104,0.876,0.378,1.084,0.587
            c0.318,0.313,0.93,0.504,1.409,0.429c0.336-0.059,0.942-0.002,1.306,0.102c0.488,0.138,1.348,0.08,1.886-0.121
            c0.429-0.161,1.336-0.263,1.976-0.204c0.798,0.068,1.703-0.228,2.078-0.678c0.215-0.259,0.313-0.587,0.268-0.9
            c-0.042-0.293-0.204-0.547-0.451-0.713c-0.425-0.283-1.242-0.504-1.863-0.504h-7.712c-1.351,0-2.993-0.05-3.658-0.111
            c-0.674-0.062-1.908-0.259-2.754-0.44c-0.778-0.167-1.451-0.657-1.532-0.984c-0.17-0.693-1.106-0.856-1.959-0.313
            c-0.743,0.471-1.49,1.281-1.701,1.842c-0.185,0.495-0.589,1.656-0.899,2.582c-0.289,0.872-0.874,1.997-1.276,2.456
            c-0.468,0.536-0.94,1.329-1.076,1.806c-0.103,0.358-0.61,1.448-1.198,2.499c-0.553,0.981-1.357,2.388-1.786,3.125
            c-0.401,0.686-1.371,1.657-2.121,2.118c-0.799,0.493-2.358,1.447-3.465,2.123c-1.159,0.71-2.473,2.131-2.925,3.169
            c-0.45,1.027-1.128,2.038-1.479,2.339c-0.538,0.461-0.777,1.312-0.542,1.937c0.138,0.368,0.019,0.934-0.258,1.21
            c-0.499,0.499-0.769,1.935-0.769,3.031c0,0.459-0.166,0.65-0.254,0.65c-0.076,0-0.223-0.111-0.318-0.414
            c-0.288-0.925-0.386-2.382-0.215-3.183c0.223-1.035-0.285-2.063-1.133-2.295c-0.689-0.189-0.998-0.259-1.524-0.111
            c-0.45,0.128-1.554,0.579-2.51,1.025c-0.908,0.425-2.451,1.071-3.442,1.442c-0.94,0.352-2.524,0.65-3.458,0.65
            c-0.953,0-2.2-0.098-2.72-0.215c-0.557-0.123-1.569-0.326-2.249-0.449c-0.769-0.141-2.099,0.352-2.959,1.081
            c-0.838,0.708-1.751,1.676-2.08,2.201c-0.27,0.432-1.011,0.984-1.62,1.204c-0.729,0.268-1.716,0.891-2.244,1.42
            c-0.498,0.498-1.159,1.209-1.475,1.586c-0.356,0.429-0.636,1.113-0.636,1.556c0,0.568,0.332,0.613,0.434,0.613
            c0.274,0,0.437-0.264,0.52-0.439c0.104-0.089,0.615-0.118,0.832-0.13c0.362-0.02,0.541-0.034,0.677-0.124
            c0.152-0.102,0.469-0.237,0.677-0.289c0.308-0.076,0.76-0.355,1.052-0.647c0.258-0.258,0.679-0.782,0.935-1.168
            c0.195-0.292,0.613-0.591,0.924-0.64c0.168,0,0.405,0.215,0.485,0.534c0.083,0.334-0.084,0.718-0.366,0.84
            c-0.533,0.228-1.061,1.024-1.204,1.813c-0.12,0.654-0.389,1.146-0.49,1.214c-0.225,0.113-0.762,0.307-1.175,0.425
            c-0.103,0.03-0.352,0.049-0.593,0.067c-0.744,0.057-1.514,0.114-1.769,0.561c-0.239,0.415-0.634,1.011-0.865,1.298
            c-0.292,0.364-0.522,0.996-0.522,1.438c0,0.336-0.146,1.055-0.318,1.57c-0.172,0.52-0.352,1.287-0.352,1.672
            c0,0.191-0.287,0.962-0.738,1.732c-0.325,0.556-0.962,0.869-1.335,0.657c-0.512-0.284-1.226-0.625-1.535-0.703
            c-0.248-0.063-0.905-0.214-1.462-0.338c-0.566-0.124-1.214-0.37-1.464-0.52c-0.409-0.242-1.162-0.187-1.681,0.111
            c-0.392,0.224-1.101,0.461-1.624,0.522c-0.344,0-0.761-0.271-0.928-0.605c-0.223-0.444-0.411-1.194-0.411-1.638
            c0-0.611-0.518-1.378-1.178-1.746c-0.494-0.274-1.094-0.919-1.31-1.408c-0.347-0.779-1.784-1.15-3.006-1.094
            c-1.239,0.063-2.766,0.216-3.402,0.342c-0.646,0.128-1.894,0.647-2.783,1.157c-0.884,0.505-2.451,1.517-3.492,2.253
            c-0.404,0.283-0.723,0.478-0.984,0.636c-0.426,0.259-0.732,0.445-0.988,0.772c-0.126,0.16-0.178,0.321-0.242,0.526
            c-0.037,0.114-0.082,0.253-0.151,0.432c-0.274,0.693,0.342,1.902,1.06,2.687c0.324,0.353,0.541,0.637,0.676,0.838
            c-0.293-0.06-0.681-0.161-1.053-0.285c-0.689-0.229-1.713-0.096-2.253,0.289c-0.479,0.342-1.647,1.119-2.547,1.697
            c-1.005,0.645-2.023,1.673-2.319,2.341c-0.296,0.669-0.362,2.147-0.147,3.365c0.196,1.11,0.196,2.918,0,4.029
            c-0.233,1.329,0.245,2.566,1.087,2.817c0.631,0.19,1.229,0.695,1.374,1.031c0.228,0.53,0.925,1.291,1.587,1.732
            c0.571,0.381,1.429,0.787,1.892,0.787c0.207,0,1.046,0.358,1.888,0.919c0.729,0.488,1.445,1.447,1.563,2.098
            c0.127,0.695,0.072,2.147-0.121,3.172c-0.209,1.116-0.372,2.376-0.372,2.866c0,0.46-0.054,1.317-0.12,1.913
            c-0.026,0.236-0.132,0.236-0.188,0.236c-0.132,0-0.312-0.08-0.493-0.22c-0.683-0.529-1.957-0.662-2.783-0.284
            c-0.851,0.386-1.58,1.31-1.658,2.101c-0.06,0.602-0.414,1.468-0.773,1.888c-0.41,0.478-1.026,1.316-1.372,1.869
            c-0.259,0.414-1.252,1.11-2.26,1.446c-0.953,0.317-2.113,0.954-2.39,1.51c-0.102,0.205-0.602,0.417-1.091,0.309
            c-0.801-0.181-1.632,0.81-1.867,2.224c-0.206,1.239-0.331,2.937-0.279,3.783c0.019,0.302-0.005,0.543-0.028,0.758
            c-0.036,0.375-0.072,0.728,0.239,0.992c0.206,0.171,0.478,0.15,0.781,0.067c0.127-0.035,0.282-0.08,0.478-0.102
            c0.512-0.043,1.395,0.146,1.937,0.415c0.547,0.274,1.241,1.065,1.519,1.729c0.288,0.693,0.532,1.811,0.532,2.441
            c0,0.845,0.638,1.597,1.42,1.676c0.444,0.044,1.272,0.718,1.75,1.613c0.533,1.001,1.492,2,2.183,2.278
            c0.616,0.245,1.774,0.699,2.575,1.007c0.767,0.294,1.852,0.886,2.373,1.292c0.544,0.425,1.434,1.214,1.98,1.76l2.459,2.459
            c0.838,0.838,1.963,1.705,2.561,1.97c0.649,0.287,1.573,0.216,2.028-0.16c0.349-0.292,1.094-0.787,1.625-1.084
            c0.368-0.21,0.871,0.092,1.091,0.617c0.387,0.934,1.197,1.402,1.723,1.046c0.048-0.03,0.13-0.049,0.229-0.049
            c0.343,0,0.801,0.189,1.164,0.479c0.578,0.463,1.307,1.287,1.593,1.801c0.314,0.567,0.982,1.541,1.486,2.171
            c0.509,0.635,1.388,1.511,1.957,1.955c0.659,0.515,1.882,0.402,2.654-0.229c0.23-0.188,0.53-0.355,0.843-0.503
            C528.961,200.853,528.898,200.861,528.837,200.881z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>
        <path fill="#1e8346" d="M538.163,204.046c-0.173-0.063-0.347-0.125-0.511-0.212c-0.193-0.102-0.379-0.221-0.565-0.34
            c0.121,0.104,0.227,0.202,0.309,0.282c0.212,0.207,0.396,0.385,0.693,0.431c0.049,0.007,0.097,0.009,0.145,0.011
            C538.208,204.16,538.173,204.103,538.163,204.046z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>
        <path fill="#1e8346" d="M535.941,202.941c-0.417-0.088-0.869-0.028-1.26-0.199c-0.328-0.143-0.567-0.428-0.823-0.678
            c-0.255-0.249-0.545-0.458-0.848-0.644c0.27,0.298,0.534,0.643,0.701,0.942c0.387,0.695,1.222,1.019,1.801,0.688
            c0.152-0.088,0.422-0.084,0.706-0.016C536.126,203.001,536.037,202.961,535.941,202.941z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>
    </g>
</g>
<g id="AREA_GERMANY_HAMBURG" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Hamburg" data-infoText="<b>Hamburg</b> &amp;nbsp; Capital: Hamburg">
    <path fill="#1e8346" d="M448.532,166.088c0.469,1.072,1.403,2.664,2.072,3.536c0.671,0.872,1.658,1.255,2.195,0.852
        c0.535-0.402,1.14-0.127,1.341,0.61c0.2,0.739,1.408,1.452,2.683,1.586c1.275,0.133,2.702-0.141,3.17-0.61
        c0.47-0.47,1.343-1.247,1.941-1.727c0.597-0.48,1.086-0.874,1.086-0.874s0.404,0.451,0.895,1.006
        c0.493,0.553,1.498,1.609,2.236,2.347c0.738,0.737,2.096,1.239,3.018,1.117c0.923-0.123,1.828-0.626,2.012-1.119
        c0.185-0.491,0.636-0.994,1.006-1.118c0.37-0.123,1.324-0.274,2.125-0.336c0.799-0.062,1.453-0.113,1.453-0.113
        s-0.064-0.338-0.143-0.752c-0.077-0.412-0.581-0.861-1.118-0.996c-0.537-0.133-1.141-0.627-1.341-1.096
        c-0.2-0.47-0.749-1.072-1.219-1.341s-1.072-1.036-1.341-1.707s-0.926-1.331-1.463-1.465c-0.537-0.133-0.922-1.176-0.854-2.316
        c0.067-1.141,0.616-2.513,1.219-3.05s1.042-1.744,0.976-2.683c-0.067-0.938-0.342-2.255-0.61-2.926
        c-0.269-0.671-0.928-1.438-1.465-1.707c-0.536-0.269-0.919-0.817-0.852-1.219s0.287-1.006,0.488-1.341
        c0.2-0.336,0.366-1.048,0.366-1.585s-0.659-0.703-1.463-0.367s-1.957,0.611-2.561,0.611c-0.602,0-1.261,0.603-1.463,1.341
        c-0.2,0.738-0.804,1.506-1.341,1.707c-0.536,0.201-1.523,0.861-2.194,1.463c-0.671,0.603-1.549,1.042-1.952,0.976
        c-0.401-0.067-1.225,0.263-1.829,0.732c-0.603,0.469-1.921,1.511-2.926,2.316c-1.006,0.806-2.103,0.86-2.439,0.123
        c-0.336-0.739-1.158-1.067-1.829-0.732c-0.67,0.336-1.33,1.541-1.465,2.683c-0.055,0.474-0.106,0.908-0.147,1.256
        c0.004,0.001,0.01,0.003,0.014,0.004c0.678,0.122,1.733,0.525,2.347,0.895c0.615,0.368,1.118,0.671,1.118,0.671l0.225,1.786
        c0,0-0.806-0.251-1.789-0.558c-0.448-0.141-1.064-0.391-1.705-0.678c0.057,0.239,0.117,0.497,0.181,0.769
        C447.458,163.204,448.061,165.015,448.532,166.088z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_12_" x="432.735" y="149.677" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" strokeMiterlimit="10" enable-background="new    " width="57.692" height="20.279" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_GERMANY_HESSEN" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Hesse" data-infoText="<b>Hesse</b> &amp;nbsp; Capital: Wiesbaden">
    <path fill="#1e8346" d="M461.09,413.87c0.133-0.402-0.03-1.115-0.366-1.585c-0.336-0.469-0.391-1.072-0.122-1.341
        c0.268-0.268,0.378-0.708,0.244-0.974c-0.134-0.269-0.08-0.928,0.122-1.465c0.201-0.535,0.695-0.919,1.098-0.852
        c0.402,0.067,0.677-0.207,0.61-0.61c-0.067-0.403-0.397-1.226-0.731-1.83c-0.336-0.604-0.884-1.427-1.22-1.83
        c-0.336-0.402-1.049-0.621-1.585-0.488c-0.537,0.133-1.634,0.792-2.439,1.463c-0.805,0.671-1.299,1.603-1.098,2.073
        s0.092,0.854-0.244,0.854s-1.268-0.331-2.072-0.733c-0.805-0.402-1.519-1.226-1.586-1.83c-0.067-0.602,0.097-1.535,0.366-2.072
        s0.708-1.25,0.975-1.585c0.269-0.337,0.652-1.323,0.854-2.195c0.201-0.872,0.366-2.298,0.366-3.17s0.384-2.244,0.854-3.048
        c0.469-0.805,1.732-1.902,2.805-2.439c1.072-0.537,1.896-1.634,1.829-2.439s0.208-1.463,0.61-1.463s0.677-0.439,0.61-0.976
        c-0.067-0.537-0.507-1.305-0.976-1.707c-0.47-0.404-1.458-0.787-2.194-0.854c-0.738-0.067-1.341-0.616-1.341-1.218
        c0-0.603,0.384-0.933,0.853-0.733s1.348,0.092,1.952-0.245c0.603-0.336,0.933-1.268,0.73-2.072
        c-0.201-0.805-0.255-2.013-0.122-2.683c0.134-0.671,1.012-1.11,1.952-0.974c0.939,0.133,2.531,0.518,3.536,0.852
        c1.006,0.336,2.377,0.06,3.048-0.61c0.67-0.67,1.248-0.788,1.22-1.219c-0.087-1.302-4.147-3.172-4.147-3.172
        s0.22-1.097,0.488-2.439c0.269-1.34,0.215-2.767-0.121-3.17c-0.337-0.402-0.502-1.115-0.367-1.585c0.133-0.47,0.627-0.47,1.098,0
        c0.47,0.47,1.397,0.812,2.058,0.762s1.1-0.544,0.976-1.097c-0.126-0.553,0.044-1.39,0.38-1.861
        c0.337-0.469-0.157-1.457-1.096-2.194c-0.939-0.738-2.749-1.506-4.024-1.707s-2.647-1.08-3.049-1.952s-1.225-2.133-1.83-2.805
        c-0.603-0.67-1.481-1.493-1.951-1.83c-0.469-0.336-1.403-1.377-2.073-2.316c-0.669-0.939-1.054-2.584-0.852-3.657
        c0.201-1.074,0.486-1.471,0.366-1.952c-0.094-0.378-0.439-0.549-0.976-1.219c-0.536-0.67-1.414-1.275-1.951-1.341
        c-0.537-0.067-0.976,0.591-0.976,1.463c0,0.873-0.164,1.805-0.366,2.072c-0.201,0.269-0.805-0.06-1.341-0.73
        c-0.537-0.671-1.305-0.945-1.708-0.611c-0.402,0.337-1.499,1.104-2.438,1.709c-0.939,0.602-1.488,1.427-1.219,1.829
        c0.268,0.402,0.872,0.842,1.341,0.976c0.469,0.133,0.689,0.847,0.488,1.585s-0.914,1.671-1.585,2.073s-1.658,0.127-2.195-0.611
        c-0.536-0.738-1.688-1.56-2.561-1.829c-0.872-0.268-2.385-1.023-3.364-1.677c-0.978-0.655-1.849-0.659-1.778-1.189
        c0.035-0.263,0.453-0.151,1.006-0.336c0.554-0.185,1.006-0.939,1.006-1.677s0.503-1.442,1.118-1.564
        c0.615-0.123,1.47-0.678,1.901-1.23c0.43-0.553,0.734-0.69,0.782-1.006c0.036-0.236-0.151-0.402-0.336-0.895
        c-0.185-0.493-0.436-1.297-0.559-1.789c-0.123-0.491-0.625-1.749-1.118-2.793c-0.492-1.046-0.541-2.504-0.112-3.242
        s0.652-0.976,0.782-1.341c0.084-0.237,0.069-0.448,0.153-0.997c0.084-0.548,0.591-0.777,1.128-0.508
        c0.536,0.268,1.03,0.16,1.097-0.244c0.067-0.402-0.317-1.444-0.853-2.315c-0.537-0.873-1.634-1.97-2.439-2.439
        c-0.805-0.47-1.934-0.86-2.509-0.865c-0.577-0.006-0.788-0.05-1.048-0.009c-0.262,0.04-0.452,0.151-1.006,0.336
        c-0.554,0.185-1.157-0.219-1.341-0.895c-0.185-0.676-0.939-0.978-1.677-0.671c-0.738,0.308-1.794,0.71-2.347,0.895
        c-0.554,0.185-0.757,0.244-1.006,0.336c-0.756,0.279-2.414,0.982-2.722,1.726c-0.107,0.259,0.111,0.494,0.244,1.097
        c0.134,0.604,0.08,1.647-0.122,2.317c-0.201,0.67-0.86,1.933-1.463,2.805s-1.921,2.133-2.926,2.805
        c-1.006,0.67-2.049,1.769-2.317,2.439c-0.268,0.67-0.927,1.275-1.463,1.341c-0.537,0.067-1.141,0.45-1.341,0.854
        c-0.201,0.402-0.86,0.73-1.463,0.73s-1.481-0.383-1.951-0.854c-0.47-0.469-0.853-1.402-0.853-2.072s-0.384-1.33-0.854-1.463
        c-0.469-0.133-1.347-0.465-1.951-0.733c-0.603-0.269-1.591-0.487-2.195-0.487c-0.603,0-1.811,0.603-2.683,1.341
        s-1.475,2.164-1.341,3.17c0.134,1.007,0.518,2.049,0.853,2.317c0.336,0.269,0.61,0.926,0.61,1.463c0,0.537-0.438,0.976-0.974,0.976
        c-0.537,0-1.414,0.165-1.952,0.366c-0.536,0.2-1.634,0.419-2.439,0.488c-0.804,0.067-2.89,0.342-4.633,0.611
        c-1.744,0.269-3.829,1.31-4.634,2.316c-0.805,1.007-2.067,2.708-2.805,3.78c-0.738,1.074-0.958,2.611-0.488,3.414
        c0.47,0.804,1.238,1.134,1.707,0.732c0.469-0.404,1.512-0.898,2.317-1.098c0.805-0.2,1.792,0.183,2.194,0.854
        c0.402,0.669,0.951,2.371,1.219,3.779c0.269,1.408-0.224,3.33-1.097,4.269c-0.872,0.938-1.366,2.201-1.097,2.803
        c0.269,0.604-0.117,1.536-0.854,2.073c-0.738,0.537-2.055,0.811-2.927,0.61c-0.872-0.2-2.133-0.421-2.805-0.488
        c-0.671-0.067-1.549,0.152-1.951,0.488c-0.402,0.337-0.512,1.27-0.244,2.073c0.269,0.805,0.323,2.122,0.122,2.926
        c-0.201,0.805-0.585,2.013-0.852,2.683c-0.269,0.67-0.927,1.493-1.463,1.83c-0.536,0.336-1.086,1.103-1.219,1.707
        c-0.134,0.603-0.245,1.592-0.245,2.194c0,0.603-0.494,1.097-1.097,1.097c-0.604,0-1.372,0.603-1.708,1.341
        s-1.103,1.725-1.708,2.195c-0.603,0.47-1.536,0.854-2.072,0.854c-0.537,0-1.25-0.22-1.586-0.488
        c-0.336-0.269-0.939-0.654-1.341-0.854c-0.402-0.201-1.116,0.017-1.586,0.488c-0.469,0.47-1.291,1.238-1.829,1.708
        c-0.536,0.469-1.305,1.182-1.708,1.585c-0.402,0.402-0.786,1.169-0.853,1.706s-0.561,1.196-1.097,1.465
        c-0.536,0.268-0.866,1.146-0.731,1.951c0.133,0.804,0.408,2.011,0.61,2.683c0.201,0.671-0.019,1.823-0.488,2.561
        s-1.184,1.397-1.586,1.463c-0.402,0.067-0.62-0.026-0.731,0.122c-0.334,0.44,0.111,0.988,0.245,2.195
        c0.133,1.208,0.408,2.744,0.61,3.413c0.201,0.67,0.641,1.713,0.976,2.317c0.336,0.603,0.225,1.756-0.244,2.561
        c-0.469,0.804-1.238,1.902-1.708,2.439c-0.469,0.537-1.292,0.647-1.83,0.244c-0.536-0.402-1.524-0.566-2.194-0.366
        c-0.671,0.2-1.549,1.079-1.952,1.951c-0.402,0.873-0.512,2.354-0.244,3.294c0.269,0.938,0.322,2.091,0.122,2.559
        c-0.201,0.47-0.641,1.568-0.976,2.439c-0.335,0.873-0.06,1.696,0.611,1.83s1.549,0.738,1.951,1.341
        c0.402,0.604,1.225,2.03,1.83,3.172c0.604,1.14,1.317,2.95,1.586,4.024c0.268,1.072,0.103,2.17-0.366,2.438
        c-0.47,0.269-1.402,0.488-2.072,0.488c-0.671,0-1.275,0.494-1.341,1.097c-0.067,0.603-0.397,1.372-0.731,1.707
        c-0.336,0.337-0.939,0.391-1.341,0.123c-0.402-0.269-1.006-0.16-1.341,0.244c-0.336,0.402-0.994,1.115-1.464,1.585
        c-0.469,0.47-1.458,1.183-2.194,1.585c-0.738,0.404-0.792,1.555-0.122,2.561c0.67,1.006,0.945,2.102,0.61,2.438
        c-0.336,0.337-0.884,0.445-1.219,0.245c-0.336-0.2-1.049-0.147-1.585,0.122c-0.537,0.269-1.141,0.981-1.341,1.585
        c-0.201,0.604-1.134,1.546-2.073,2.093c-0.939,0.549-1.617,0.511-1.707,0.997c-0.065,0.351,0.402,0.503,0.895,1.118
        c0.492,0.615,1.346,1.57,1.9,2.123c0.554,0.553,1.207,1.56,1.454,2.236c0.246,0.676,0.949,1.331,1.564,1.454
        c0.615,0.122,2.073-0.029,3.242-0.336c1.168-0.308,2.929-1.012,3.912-1.565c0.984-0.553,2.392-1.056,3.129-1.117
        c0.738-0.063,2.246-0.314,3.354-0.561c1.106-0.245,2.867-0.195,3.912,0.113c1.044,0.308,1.9,0.559,1.9,0.559
        s0.754,0.654,1.677,1.453s2.079,2.207,2.57,3.129c0.492,0.923,0.995,2.481,1.118,3.464c0.123,0.986,0.073,2.342-0.112,3.018
        c-0.185,0.678,0.067,1.583,0.559,2.013c0.492,0.431,0.894,1.336,0.894,2.012c0,0.678,0.251,1.733,0.559,2.347
        c0.307,0.616,0.81,1.722,1.118,2.46s1.163,1.291,1.901,1.228c0.738-0.061,1.191,0.493,1.006,1.23
        c-0.185,0.738-0.888,1.744-1.565,2.236c-0.676,0.493-1.682,1.046-2.236,1.23s-1.257,0.636-1.564,1.006s-0.559,1.223-0.559,1.901
        c0,0.676,0.201,1.984,0.447,2.905c0.246,0.923,0.799,2.481,1.228,3.466c0.43,0.984,0.346,1.57,0.783,1.788
        c0.346,0.173,0.677-0.16,1.504-0.356c0.828-0.196,2.054,0.028,2.723,0.498c0.671,0.47,1.824,1.568,2.561,2.439
        c0.738,0.871,1.89,1.311,2.561,0.976c0.671-0.337,1.055-1.872,0.854-3.414c-0.201-1.543,0.731-2.969,2.072-3.171
        c1.341-0.2,2.494,0.622,2.561,1.83c0.067,1.208,0.451,2.744,0.854,3.414c0.402,0.671,1.225,1.603,1.829,2.072
        c0.603,0.47,1.755,0.909,2.561,0.976c0.805,0.067,1.738,0.45,2.072,0.852c0.336,0.404,0.995,0.567,1.464,0.367s1.128-0.2,1.463,0
        c0.336,0.2,0.555,0.86,0.488,1.463s-0.505,1.097-0.975,1.097s-1.183,0.165-1.586,0.366c-0.402,0.2-0.622,0.914-0.488,1.585
        c0.133,0.671-0.086,1.384-0.488,1.585s-0.143,1.026,0.58,1.83c0.721,0.805,0.82,1.488,1.31,1.463
        c0.38-0.02,0.402-0.553,0.895-1.228c0.492-0.678,1.095-1.632,1.341-2.125s0.899-0.845,1.453-0.783
        c0.554,0.063,0.843,0.307,1.006,0.113c0.216-0.255-0.164-0.577-0.366-1.281c-0.201-0.704-0.311-1.609-0.244-2.013
        c0.067-0.402,0.561-1.004,1.098-1.341c0.536-0.336,1.908-0.444,3.048-0.244c1.141,0.2,2.458-0.019,2.927-0.488
        c0.469-0.47,1.403-0.689,2.073-0.488c0.669,0.2,1.109-0.019,0.974-0.489c-0.133-0.47-0.627-1.403-1.097-2.073
        c-0.469-0.669-0.744-1.547-0.61-1.949c0.134-0.404,0.244-0.733,0.244-0.733s-0.219-0.383-0.488-0.854
        c-0.269-0.47-0.488-1.128-0.488-1.463c0-0.337,0.439-1.05,0.975-1.585c0.537-0.537,0.921-1.745,0.854-2.683
        c-0.067-0.939,0.317-2.257,0.854-2.926c0.536-0.671,0.975-1.989,0.975-2.927c0-0.939-0.055-2.037-0.123-2.439
        c-0.067-0.404-0.067-1.281,0-1.952s-0.262-1.219-0.73-1.219c-0.469,0-1.073-0.439-1.341-0.976
        c-0.269-0.535-0.433-1.248-0.366-1.585c0.067-0.336-0.372-0.774-0.975-0.974c-0.603-0.201-1.153-1.08-1.219-1.952
        c-0.067-0.872-0.397-2.133-0.733-2.805c-0.335-0.67-0.61-2.263-0.61-3.535c0-1.275,0.275-2.701,0.61-3.171
        c0.336-0.47,0.5-1.645,0.366-2.612c-0.133-0.967-0.244-1.757-0.244-1.757s-0.251-0.352-0.559-0.782
        c-0.307-0.43-1.011-0.882-1.565-1.006c-0.554-0.124-0.704-0.475-0.336-0.783c0.369-0.307,0.671-0.558,0.671-0.558
        s0.292-0.754,0.651-1.677c0.358-0.923,1.145-1.897,1.749-2.164c0.603-0.269,1.482-0.709,1.951-0.977
        c0.47-0.268,1.293-0.322,1.83-0.121c0.536,0.2,1.249,0.694,1.585,1.098c0.336,0.402,0.939,0.402,1.341,0
        c0.402-0.404,0.952-1.172,1.22-1.708c0.268-0.537,1.42-0.921,2.561-0.854c1.141,0.068,2.951,0.233,4.024,0.367
        c1.072,0.133,2.774,1.231,3.779,2.439c1.006,1.207,2.982,1.755,4.391,1.218c1.408-0.535,2.505-1.524,2.438-2.194
        c-0.067-0.671,0.098-1.713,0.366-2.317c0.269-0.603,0.214-1.427-0.122-1.829c-0.336-0.404-0.61-1.336-0.61-2.073
        s0.383-1.78,0.853-2.317c0.469-0.536,1.732-0.754,2.805-0.488c1.072,0.269,2.555,0.269,3.293,0
        c0.738-0.268,1.341-1.036,1.341-1.707s0.384-1.603,0.853-2.073c0.469-0.469,1.458-0.963,2.195-1.098
        c0.738-0.133,1.397-0.847,1.463-1.585c0.067-0.738,0.286-2.054,0.488-2.927c0.201-0.872,0.531-2.518,0.732-3.657
        c0.201-1.141,0.695-2.347,1.097-2.683c0.402-0.337,1.28-0.117,1.952,0.488c0.671,0.604,2.427,0.714,3.902,0.244
        s3.232-1.458,3.902-2.195s1.658-1.835,2.195-2.439c0.536-0.602,1.194-1.701,1.463-2.438c0.268-0.738,0.426-0.99,0.488-1.341
        c0.054-0.3,0-0.549,0-1.219C460.858,415.15,460.957,414.274,461.09,413.87z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_14_" x="376.81" y="403.583" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" strokeMiterlimit="10" enable-background="new    " width="57.692" height="20.279" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_GERMANY_BREMEN" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Bremen" data-infoText="<b>Bremen</b> &amp;nbsp; Capital: Bremen">
    <path fill="#1e8346" d="M385.383,187.663c0.279-0.035,0.494,0.111,1.098,0.244c0.603,0.133,1.482,0.409,1.951,0.611
        s1.841,0.804,3.049,1.341s2.744,0.865,3.414,0.731c0.67-0.133,1.603,0.142,2.072,0.611s1.567,1.019,2.439,1.219
        c0.872,0.201,1.97,0.694,2.439,1.096c0.47,0.402,1.458,0.732,2.194,0.732s1.616-0.494,1.951-1.098
        c0.336-0.603,0.83-0.494,1.098,0.245c0.268,0.737,0.323,1.944,0.122,2.683c-0.201,0.737,0.072,1.834,0.608,2.438
        c0.537,0.603,0.701,1.316,0.366,1.585c-0.336,0.269-0.664,0.981-0.731,1.586c-0.067,0.602-0.506,1.48-0.976,1.951
        c-0.469,0.47-1.072,0.634-1.341,0.366c-0.268-0.268-1.311-0.158-2.316,0.244c-1.006,0.402-2.049,0.459-2.317,0.123
        c-0.269-0.336-0.982-0.831-1.585-1.098c-0.603-0.269-1.976-0.488-3.049-0.488c-1.072,0-1.952-0.714-1.952-1.586
        c0-0.871-0.494-2.024-1.097-2.561s-1.321-1.661-1.595-2.499c-0.275-0.838-0.413-1.133-0.498-1.525
        c-0.054-0.248-0.051-0.454-0.112-1.006c-0.061-0.553-0.715-1.208-1.453-1.453c-0.738-0.246-1.895-0.801-2.572-1.229
        c-0.676-0.43-1.43-1.236-1.677-1.789c-0.246-0.553-0.539-0.747-0.446-1.006C384.554,187.887,385.128,187.695,385.383,187.663z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>
    <path fill="#1e8346" d="M389.612,164.926c0.987-0.233,1.935-0.944,2.109-1.583c0.175-0.636,0.221-2.014,0.105-3.057
        c-0.116-1.042-0.211-2.515-0.211-3.269c0-0.753-0.569-1.321-1.265-1.265c-0.696,0.059-1.551-0.085-1.898-0.317
        c-0.348-0.231-1.085-0.466-1.639-0.519c-0.553-0.054-0.839-0.287-1.005-0.097c-0.245,0.28,0.151,0.652,0.336,1.453
        c0.185,0.799,0.687,2.308,1.117,3.354c0.43,1.045,0.683,2.202,0.559,2.571c-0.123,0.368-0.676,0.973-1.229,1.341
        c-0.329,0.219-1.145,0.541-0.986,1.031c0.091,0.279,0.498,0.175,1.106,0.387C387.323,165.171,388.626,165.156,389.612,164.926z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_15_" x="370.176" y="187.816" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" strokeMiterlimit="10" enable-background="new    " width="57.692" height="20.279" pointer-events="auto" visibility="hidden"></rect>
    <circle fill="#1e8346" cx="385.636" cy="160.821" r="17.179" pointer-events="auto" opacity="0.001" class="clickarea landarea" stroke="#7eb08e" stroke-width="0.5"></circle>
</g>
<g id="AREA_GERMANY_NORDRHEINWESTFALEN" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="North Rhine-Westphalia" data-infoText="<b>North Rhine-Westphalia</b> &amp;nbsp; Capital: Düsseldorf">
    <path fill="#1e8346" d="M402.56,341.926c-0.507,0.177-1.414,0.165-1.952,0.366c-0.536,0.2-1.634,0.419-2.439,0.488
        c-0.804,0.067-2.89,0.342-4.633,0.611c-1.744,0.269-3.829,1.31-4.634,2.316c-0.805,1.007-2.067,2.708-2.805,3.78
        c-0.738,1.074-0.958,2.611-0.488,3.414c0.47,0.804,1.238,1.134,1.707,0.733c0.469-0.404,1.512-0.898,2.317-1.098
        c0.805-0.2,1.792,0.183,2.194,0.854c0.402,0.669,0.951,2.371,1.219,3.779c0.269,1.408-0.224,3.33-1.097,4.269
        c-0.872,0.938-1.366,2.201-1.097,2.803c0.269,0.604-0.117,1.536-0.854,2.073c-0.738,0.537-2.055,0.811-2.927,0.61
        c-0.872-0.2-2.133-0.421-2.805-0.488c-0.671-0.067-1.549,0.152-1.951,0.488c-0.402,0.337-0.512,1.27-0.244,2.073
        c0.269,0.805,0.323,2.122,0.122,2.926c-0.201,0.805-0.585,2.013-0.852,2.683c-0.269,0.67-0.927,1.493-1.463,1.83
        c-0.536,0.336-1.086,1.103-1.219,1.707c-0.134,0.603-0.245,1.592-0.245,2.194c0,0.603-0.494,1.097-1.097,1.097
        c-0.604,0-1.372,0.603-1.708,1.341s-1.103,1.725-1.708,2.195c-0.603,0.47-1.536,0.854-2.072,0.854c-0.537,0-1.25-0.22-1.586-0.488
        c-0.336-0.269-0.939-0.654-1.341-0.854c-0.402-0.201-1.116,0.017-1.586,0.488c-0.469,0.47-1.291,1.238-1.829,1.708
        c-0.536,0.469-1.305,1.182-1.708,1.585c-0.402,0.402-0.786,1.169-0.853,1.706s-0.561,1.196-1.097,1.465
        c-0.536,0.268-0.866,1.146-0.731,1.951c0.133,0.804,0.408,2.011,0.61,2.683c0.201,0.671-0.019,1.823-0.488,2.561
        s-1.184,1.397-1.586,1.463c-0.402,0.067-0.547,0.136-0.731,0.122c-0.192-0.016-0.33-0.109-0.731-0.244
        c-0.402-0.133-1.006-0.133-1.341,0c-0.336,0.133-0.829-0.361-1.097-1.098c-0.268-0.738-1.146-2.274-1.951-3.413
        c-0.805-1.141-1.464-2.567-1.464-3.17c0-0.603-0.055-1.867-0.122-2.806c-0.067-0.938-0.725-1.707-1.463-1.707
        s-1.561-0.549-1.83-1.22c-0.268-0.67-1.091-1.439-1.829-1.708c-0.738-0.268-1.341-0.816-1.341-1.218c0-0.404,0-1.281,0-1.952
        c0-0.669-0.603-1.492-1.341-1.829c-0.738-0.336-1.726-0.225-2.195,0.244c-0.469,0.47-0.963,1.403-1.097,2.073
        c-0.133,0.67-0.133,1.878,0,2.683c0.134,0.805-0.085,1.519-0.488,1.585c-0.402,0.067-1.061,0.396-1.463,0.733
        c-0.402,0.336-1.225,0.83-1.83,1.097c-0.603,0.268-0.823,1.199-0.488,2.072c0.336,0.873-0.214,1.969-1.219,2.439
        c-1.005,0.469-2.433,1.237-3.17,1.707c-0.738,0.47-2.11,1.128-3.049,1.463c-0.939,0.337-2.366,0.83-3.171,1.098
        c-0.805,0.269-2.505,0.542-3.78,0.61c-1.274,0.067-2.316,0.891-2.316,1.83c0,0.938-0.439,2.201-0.975,2.805
        s-1.469,1.316-2.072,1.585c-0.604,0.269-1.591,0.488-2.194,0.488s-1.811-0.384-2.683-0.853c-0.872-0.47-2.08-0.142-2.683,0.731
        c-0.603,0.872-1.427,1.53-1.83,1.463c-0.402-0.067-1.225,0.152-1.83,0.489c-0.603,0.336-1.427,0.389-1.829,0.121
        c-0.403-0.268-1.062-0.268-1.464,0c-0.402,0.269-1.171,0.873-1.708,1.341c-0.537,0.47-1.688,0.964-2.56,1.097
        c-0.872,0.135-1.969,0.739-2.438,1.341c-0.469,0.604-1.239,1.207-1.708,1.341c-0.469,0.133-0.58,0.739-0.244,1.341
        c0.336,0.603,0.391,1.647,0.122,2.317c-0.268,0.671-0.926,1.438-1.463,1.707c-0.536,0.268-1.579-0.17-2.316-0.974
        c-0.738-0.804-1.725-1.189-2.194-0.854c-0.47,0.337-1.073,1.378-1.341,2.317c-0.269,0.939-0.269,1.815,0,1.951
        c0.269,0.135,0.652,1.012,0.852,1.952c0.201,0.939,0.366,2.255,0.366,2.926s-0.877,1.658-1.951,2.195
        c-1.072,0.536-2.225,0.591-2.56,0.121c-0.336-0.469-0.939-1.072-1.341-1.341c-0.402-0.268-1.116-0.157-1.586,0.245
        c-0.469,0.402-1.292,0.183-1.829-0.488c-0.537-0.671-1.8-1.11-2.805-0.976c-1.006,0.133-2.597,0.738-3.536,1.341
        c-0.939,0.604-2.036,0.549-2.439-0.122c-0.402-0.671-1.28-1.603-1.951-2.073c-0.67-0.469-1.219-0.469-1.219,0
        c0,0.47,0.383,1.348,0.853,1.952c0.469,0.603,0.474,1.359,0.009,1.677c-0.464,0.318-0.597,0.652-0.843,0.579
        c-0.493-0.147-0.352-0.855-0.783-1.899c-0.431-1.046-0.933-2.202-1.118-2.571c-0.185-0.37,0.017-1.778,0.447-3.129
        c0.43-1.353,0.531-2.862,0.224-3.354c-0.308-0.493-0.71-1.499-0.895-2.236c-0.185-0.738-0.688-1.442-1.118-1.565
        c-0.43-0.123-1.236-0.425-1.788-0.671c-0.554-0.246-1.459-0.346-2.012-0.223c-0.554,0.122-1.257-0.331-1.565-1.006
        c-0.308-0.678-0.76-1.784-1.006-2.46s-0.246-1.531,0-1.901c0.246-0.369,0.949-0.923,1.564-1.229
        c0.615-0.307,1.419-0.96,1.788-1.452c0.369-0.493,0.52-1.247,0.336-1.677c-0.185-0.431-0.939-0.833-1.677-0.896
        c-0.738-0.062-1.643-0.161-2.012-0.223c-0.369-0.062-0.62-0.665-0.559-1.341s-0.139-1.38-0.446-1.565
        c-0.308-0.185-0.962-1.241-1.454-2.347c-0.492-1.107-1.146-2.113-1.453-2.236c-0.307-0.122-1.313-0.273-2.236-0.336
        c-0.923-0.062-1.778-0.412-1.9-0.782c-0.123-0.37-0.19-0.498-0.224-0.671c-0.082-0.412-0.051-0.754-0.112-1.677
        c-0.062-0.923-0.464-2.18-0.895-2.794c-0.43-0.615-0.331-1.268,0.223-1.453s0.955-0.989,0.894-1.788
        c-0.062-0.801,0.14-1.656,0.448-1.902c0.307-0.245,1.112-0.446,1.788-0.446s1.33-0.553,1.453-1.23
        c0.123-0.676,0.174-1.781,0.113-2.458c-0.062-0.678-0.867-1.583-1.788-2.012c-0.923-0.431-1.677-1.085-1.677-1.454
        c0-0.37,0.151-1.275,0.336-2.011c0.185-0.738-0.319-1.442-1.118-1.564c-0.799-0.123-1.806-0.375-2.236-0.559
        c-0.43-0.185-1.385-0.135-2.123,0.112c-0.738,0.246-1.19-0.106-1.006-0.783c0.185-0.676,0.033-1.48-0.336-1.786
        c-0.369-0.308-0.52-0.811-0.336-1.119c0.185-0.308,0.285-1.012,0.224-1.565c-0.062-0.553,0.29-1.157,0.782-1.341
        c0.492-0.185,0.995,0.017,1.118,0.446c0.123,0.431,0.677,1.085,1.23,1.454c0.554,0.37,1.408-0.084,1.901-1.006
        c0.492-0.923,1.699-2.28,2.683-3.018s2.441-1.744,3.241-2.235c0.8-0.493,2.057-1.196,2.795-1.565
        c0.738-0.37,1.341-1.526,1.341-2.571c0-1.046-0.603-1.498-1.341-1.006c-0.738,0.493-1.644,0.693-2.011,0.448
        c-0.369-0.246-0.771-1.101-0.894-1.902c-0.123-0.799-0.072-2.005,0.112-2.683c0.185-0.676,1.19-2.185,2.236-3.354
        c1.046-1.167,2.202-2.626,2.57-3.241c0.369-0.615,1.375-1.721,2.236-2.459c0.862-0.738,1.565-2.246,1.565-3.354
        c0-1.107-0.101-2.918-0.224-4.024c-0.122-1.106-0.122-2.465,0-3.018c0.123-0.553,0.224-1.61,0.224-2.347s-0.402-1.896-0.895-2.571
        c-0.492-0.676-1.447-2.285-2.123-3.578c-0.676-1.291-1.582-2.649-2.013-3.018c-0.43-0.37-0.43-1.425,0-2.347
        c0.431-0.923-0.022-2.079-1.006-2.571c-0.984-0.491-1.939-1.346-2.124-1.899c-0.185-0.553-0.336-1.509-0.336-2.123
        c0-0.615-0.452-1.42-1.006-1.788c-0.554-0.369-1.408-0.973-1.9-1.341c-0.492-0.37-0.541-0.973-0.112-1.341
        c0.431-0.369,0.782-1.074,0.782-1.565c0-0.493-0.251-1.348-0.559-1.901c-0.307-0.553-0.508-1.408-0.446-1.901
        c0.062-0.491,0.716-0.843,1.454-0.782c0.738,0.062,1.693-0.642,2.124-1.564c0.43-0.923,0.334-1.564,0.782-1.677
        c0.272-0.068,0.402,0.302,0.895,0.671c0.492,0.368,1.447,0.871,2.123,1.117c0.676,0.245,1.582,0.649,2.012,0.894
        c0.431,0.246,0.597,0.575,0.783,0.446c0.302-0.209-0.1-0.652-0.223-1.453c-0.122-0.799-0.526-1.956-0.895-2.57
        s-0.067-1.27,0.671-1.453c0.738-0.186,1.895,0.419,2.571,1.341c0.676,0.923,1.933,1.575,2.794,1.453
        c0.861-0.123,1.816,0.128,2.123,0.558c0.308,0.431,1.163,0.933,1.901,1.117c0.738,0.185,1.744,0.788,2.236,1.341
        c0.493,0.554,1.146,0.303,1.453-0.558c0.307-0.861,1.364-1.263,2.347-0.894c0.984,0.368,2.694,0.167,3.801-0.448
        c1.107-0.616,2.918-1.52,4.024-2.013c1.107-0.493,2.616-1.046,3.354-1.228c0.738-0.186,1.895,0.015,2.57,0.446
        c0.676,0.43,1.733,0.329,2.347-0.224c0.615-0.553,1.52-1.659,2.011-2.459c0.492-0.801,1.246-1.807,1.677-2.236
        c0.43-0.43,0.48-1.385,0.112-2.124c-0.369-0.737-1.223-1.692-1.9-2.123c-0.676-0.43-1.985-0.984-2.906-1.228
        c-0.923-0.246-1.626-0.949-1.565-1.565c0.062-0.616,0.716-1.42,1.454-1.789c0.738-0.368,1.592-1.273,1.9-2.012
        c0.307-0.737,1.062-1.391,1.677-1.453s1.57-0.212,2.124-0.336c0.554-0.122,1.459-0.727,2.011-1.341
        c0.554-0.615,1.308-1.418,1.677-1.788s1.275-1.023,2.012-1.453c0.738-0.431,1.744-1.437,2.236-2.236
        c0.493-0.799,1.297-1.504,1.789-1.565c0.492-0.062,1.296-0.313,1.788-0.558c0.492-0.246,0.676-0.327,0.894-0.446
        c0.423-0.23,0.746-0.444,1.656-0.987c0.911-0.542,2.589-0.876,3.73-0.742c1.141,0.133,2.952,0.189,4.024,0.122
        c1.073-0.068,2.719-0.505,3.658-0.976c0.939-0.47,2.532-1.287,3.542-1.819c1.008-0.53,2.16-1.189,2.56-1.463
        c0.4-0.274,1.276-1.101,1.946-1.839s1.988-1.56,2.927-1.829s2.091-1.036,2.561-1.707s1.019-1.495,1.22-1.83
        c0.201-0.336,0.036-1.104-0.366-1.707c-0.403-0.603-0.568-1.591-0.367-2.195c0.201-0.602,0.751-1.098,1.219-1.098
        c0.47,0,1.678,0.22,2.683,0.489c1.006,0.269,1.884,1.475,1.952,2.683c0.067,1.207,1.383,2.963,2.926,3.902
        c1.542,0.938,4.341,1.926,6.218,2.194c1.878,0.269,3.853,0.981,4.39,1.586c0.535,0.602,0.811,1.48,0.61,1.951
        c-0.201,0.47-0.805,1.018-1.341,1.22c-0.536,0.201-0.811,0.86-0.61,1.463c0.201,0.604-0.128,1.592-0.733,2.195
        c-0.603,0.603-1.042,1.427-0.975,1.829s-0.097,1.28-0.366,1.951c-0.268,0.671,0.006,1.769,0.611,2.439
        c0.603,0.671,1.865,1.219,2.805,1.219c0.938,0,1.432,0.549,1.097,1.219c-0.336,0.671-0.555,1.878-0.488,2.683
        s-0.482,1.354-1.219,1.219c-0.738-0.134-1.725-0.134-2.194,0c-0.47,0.135-1.073,0.792-1.341,1.463
        c-0.269,0.671-0.158,1.658,0.244,2.195s1.171,1.47,1.707,2.072c0.537,0.603,1.634,0.659,2.439,0.122
        c0.805-0.536,2.067-1.305,2.805-1.707s1.945-0.458,2.683-0.122s1.671,0.445,2.073,0.244c0.402-0.2,1.225-0.366,1.829-0.366
        s1.591-0.713,2.195-1.585c0.603-0.872,1.755-1.805,2.56-2.074s1.902-0.378,2.438-0.244c0.537,0.135,1.744,0.464,2.683,0.732
        c0.939,0.268,2.037-0.006,2.439-0.611c0.403-0.602,1.226-1.427,1.83-1.829c0.603-0.404,1.426-1.062,1.829-1.465
        c0.402-0.401,0.731-1.115,0.731-1.585c0-0.469-0.439-1.622-0.975-2.56c-0.537-0.939-0.811-2.255-0.611-2.926
        c0.201-0.671,0.256-2.537,0.122-4.147c-0.134-1.609-0.244-3.584-0.244-4.39s-0.932-1.957-2.072-2.561
        c-1.141-0.602-2.678-1.208-3.415-1.341c-0.738-0.133-1.341-0.738-1.341-1.341c0-0.602,0.384-1.701,0.854-2.439
        c0.468-0.737,1.458-1.121,2.194-0.852c0.737,0.269,1.835,0.377,2.438,0.244c0.604-0.135,1.372-0.958,1.708-1.83
        c0.336-0.871,1.104-1.969,1.708-2.437c0.603-0.47,1.536-0.69,2.072-0.489s1.908,0.092,3.049-0.244s2.402-0.611,2.805-0.611
        c0.402,0,1.225,0.55,1.83,1.221c0.603,0.671,1.153,2.042,1.219,3.048c0.067,1.004,0.122,2.652,0.122,3.658s0.823,1.993,1.83,2.194
        c1.005,0.2,2.652,0.53,3.657,0.732c1.006,0.201,2.653-0.02,3.658-0.489c1.006-0.47,2.268-0.963,2.805-1.096
        c0.537-0.134,1.305-1.067,1.708-2.074c0.402-1.006,1.28-2.268,1.952-2.805c0.671-0.537,1.769-1.25,2.439-1.585
        c0.67-0.336,1.714-0.006,2.317,0.73c0.603,0.738,0.933,1.781,0.731,2.317c-0.201,0.536-0.311,1.468-0.244,2.074
        c0.067,0.602,0.012,1.59-0.122,2.194c-0.134,0.603-0.628,1.702-1.098,2.439c-0.469,0.738-1.018,1.505-1.219,1.707
        s-0.969,0.53-1.707,0.732c-0.738,0.2-1.561,0.805-1.83,1.341c-0.269,0.535-0.597,1.688-0.731,2.561
        c-0.134,0.873,0.305,2.079,0.976,2.683c0.671,0.602,1.549,1.59,1.951,2.194c0.402,0.603,0.622,1.261,0.488,1.463
        c-0.134,0.201-0.299,1.024-0.366,1.83c-0.067,0.805-0.725,1.519-1.463,1.585c-0.738,0.067-1.233,0.561-1.098,1.098
        c0.133,0.536,0.3,1.359,0.366,1.829c0.067,0.47,0.781,0.852,1.586,0.852c0.804,0,2.067,0.055,2.805,0.123
        c0.738,0.067,1.561,0.561,1.829,1.098c0.269,0.537,1.036,1.58,1.708,2.316c0.671,0.739,0.945,1.726,0.61,2.195
        c-0.336,0.469-0.336,1.566,0,2.439c0.336,0.872,0.61,2.133,0.61,2.805s0,1.55,0,1.951c0,0.404,0.33,0.842,0.731,0.976
        c0.402,0.135,0.896,0.025,1.098-0.244c0.201-0.269,0.86-0.269,1.463,0s1.097,0.927,1.097,1.463c0,0.537-0.22,1.47-0.488,2.072
        c-0.269,0.605,0.117,0.988,0.854,0.854c0.738-0.133,1.671,0.25,2.072,0.854c0.402,0.602,0.512,1.81,0.244,2.683
        c-0.269,0.872,0.391,1.585,1.463,1.585s2.61,0.055,3.414,0.122c0.805,0.067,1.519,0.835,1.586,1.707s0.011,2.135-0.122,2.806
        s-0.463,1.713-0.73,2.316c-0.269,0.603-0.968,1.702-1.555,2.439c-0.588,0.738-0.942,0.931-1.067,1.341
        c-0.106,0.348,0.05,0.654,0.112,1.454c0.061,0.799,0.062,1.906,0,2.459c-0.061,0.553-0.413,1.307-0.782,1.677
        s-0.318,1.124,0.112,1.677c0.431,0.553,0.837,0.691,0.783,1.006c-0.137,0.793-2.335,1.021-2.722,1.726
        c-0.135,0.246,0.111,0.494,0.244,1.097c0.134,0.604,0.08,1.647-0.122,2.317c-0.201,0.67-0.86,1.933-1.463,2.805
        s-1.921,2.133-2.926,2.805c-1.006,0.67-2.049,1.769-2.317,2.439c-0.269,0.67-0.927,1.275-1.463,1.341
        c-0.537,0.067-1.141,0.45-1.341,0.854c-0.201,0.402-0.86,0.73-1.463,0.73s-1.482-0.383-1.951-0.854
        c-0.47-0.469-0.853-1.402-0.853-2.072s-0.384-1.33-0.854-1.463c-0.469-0.133-1.347-0.465-1.951-0.733
        c-0.603-0.269-1.591-0.487-2.195-0.487c-0.603,0-1.811,0.603-2.683,1.341s-1.475,2.164-1.341,3.17
        c0.134,1.007,0.562,2.001,0.853,2.317C403.343,339.94,404.193,341.358,402.56,341.926z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_17_" x="294.244" y="323.643" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" strokeMiterlimit="10" enable-background="new    " width="57.692" height="20.279" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_GERMANY_SAARLAND" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Saarland" data-infoText="<b>Saarland</b> &amp;nbsp; Capital: Saarbrücken">
    <path fill="#1e8346" d="M311.225,522.642c-0.336-0.806-0.225-1.903,0.244-2.439c0.47-0.537,1.073-1.305,1.341-1.708
        c0.269-0.402,0.653-1.445,0.854-2.316c0.201-0.872,0.804-1.86,1.341-2.195c0.536-0.336,1.14-1.158,1.341-1.829
        c0.201-0.671-0.239-1.769-0.976-2.439c-0.738-0.671-1.945-1.275-2.683-1.341c-0.738-0.067-1.341-0.561-1.341-1.098
        s-0.383-1.36-0.853-1.829c-0.47-0.47-0.908-1.403-0.975-2.073c-0.067-0.671,0.427-1.33,1.097-1.463
        c0.671-0.133,1.33-0.738,1.464-1.341c0.133-0.603,0.133-1.536,0-2.072c-0.134-0.537-0.189-1.25-0.123-1.585
        c0.067-0.337-0.042-1.05-0.244-1.586c-0.201-0.537-0.421-1.414-0.488-1.951c-0.067-0.537-0.397-1.086-0.731-1.219
        c-0.336-0.133-1.049,0.085-1.586,0.489c-0.536,0.402-1.908-0.038-3.049-0.977c-1.141-0.938-2.952-1.872-4.024-2.072
        s-2.5-0.749-3.17-1.219s-1.878-0.854-2.683-0.854s-1.629-0.274-1.83-0.61c-0.201-0.337-1.464,0.103-2.805,0.974
        c-1.341,0.872-3.59,2.19-4.999,2.928s-3.604,1.56-4.877,1.829c-1.275,0.269-2.536,0.432-2.805,0.366
        c-0.269-0.067-1.037,0.207-1.708,0.611c-0.671,0.402-2.098,0.676-3.171,0.61c-1.072-0.067-2.938-0.177-4.146-0.244
        c-1.207-0.067-3.512-0.075-5.121-0.02c-1.61,0.055-2.394-0.402-2.927,0.101c-0.225,0.212-0.051,0.554-0.112,1.23
        c-0.062,0.676-0.162,1.834-0.224,2.572c-0.062,0.738-0.37,1.125-0.112,1.341c0.334,0.278,0.754-0.201,1.677-0.446
        c0.923-0.246,2.482,0.005,3.465,0.558c0.984,0.553,2.493,1.559,3.354,2.235c0.861,0.678,1.465,1.531,1.341,1.901
        c-0.123,0.37,0.28,1.072,0.895,1.565s1.118,1.247,1.118,1.677s0,1.033,0,1.341s-0.05,0.811-0.112,1.119
        c-0.061,0.307,0.693,1.564,1.677,2.793c0.984,1.23,1.788,2.689,1.788,3.242s0.554,1.307,1.23,1.677
        c0.676,0.37,1.179,1.124,1.117,1.677c-0.062,0.553,0.391,1.307,1.006,1.677c0.615,0.37,0.816,1.174,0.447,1.788
        c-0.369,0.616-0.419,1.521-0.112,2.012c0.307,0.493,1.113,0.944,1.788,1.006c0.677,0.063,1.934,0.162,2.796,0.225
        c0.861,0.062,1.917-0.241,2.347-0.671c0.43-0.431,0.53-1.286,0.223-1.902c-0.307-0.615-0.459-1.369-0.336-1.677
        c0.123-0.307,0.677-0.356,1.23-0.109c0.554,0.245,1.408,0.246,1.901,0c0.492-0.245,1.247-0.045,1.677,0.445
        c0.43,0.493,1.687,1.297,2.794,1.789c1.107,0.493,2.012,1.549,2.012,2.347c0,0.799,0.251,2.207,0.559,3.129
        c0.307,0.923,0.861,1.476,1.229,1.229c0.369-0.247,1.023-0.949,1.454-1.564c0.43-0.616,1.235-0.817,1.788-0.448
        c0.554,0.37,1.359,0.67,1.788,0.67c0.431,0,1.135,0.251,1.565,0.558c0.43,0.308,1.436,0.509,2.236,0.448
        c0.799-0.062,1.854,0.089,2.347,0.336c0.492,0.246,1.146,0.044,1.453-0.448c0.307-0.491,1.213-0.943,2.011-1.006
        c0.799-0.062,1.454-0.464,1.454-0.894s0-0.782,0-0.782s-0.567-0.586-1.26-1.301C312.403,524.691,311.561,523.446,311.225,522.642z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_18_" x="242.86" y="508.926" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" strokeMiterlimit="10" enable-background="new    " width="57.692" height="20.279" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_GERMANY_RHEINLANDPFALZ" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Rhineland-Palatinate" data-infoText="<b>Rhineland-Palatinate</b> &amp;nbsp; Capital: Mainz">
    <path fill="#1e8346" d="M364.098,542.021c0.43-0.801,0.933-1.957,1.117-2.573c0.185-0.615,0.637-1.671,1.006-2.347
        c0.369-0.676,1.072-1.833,1.565-2.57c0.492-0.738,0.994-1.894,1.117-2.57c0.123-0.678,0.274-1.883,0.336-2.683
        c0.062-0.801,0.364-2.509,0.67-3.802c0.307-1.291,1.112-2.649,1.788-3.018c0.677-0.37,1.482-1.223,1.789-1.899
        c0.307-0.678,0.961-1.632,1.453-2.124c0.492-0.493,0.643-1.247,0.336-1.677s-0.559-0.933-0.559-1.117
        c0-0.185,0.201-0.838,0.447-1.453c0.246-0.616,0.648-1.823,0.894-2.683c0.246-0.861,0.447-1.967,0.447-2.46
        s0.151-1.398,0.336-2.012c0.185-0.615-0.318-1.369-1.118-1.677c-0.799-0.308-1.302-0.962-1.117-1.454
        c0.185-0.491-0.017-1.547-0.446-2.347c-0.431-0.799-0.984-2.257-1.23-3.241s-0.447-2.392-0.447-3.129s0-1.341,0-1.341
        s-0.352-0.805-0.783-1.788c-0.43-0.986-0.984-2.544-1.228-3.466c-0.246-0.923-0.447-2.229-0.447-2.905
        c0-0.677,0.251-1.531,0.559-1.901c0.307-0.37,1.011-0.821,1.564-1.006c0.554-0.185,1.559-0.738,2.236-1.23
        c0.677-0.493,1.38-1.498,1.565-2.236s-0.269-1.291-1.006-1.23c-0.738,0.063-1.593-0.491-1.901-1.228
        c-0.307-0.738-0.81-1.844-1.118-2.46c-0.307-0.615-0.559-1.67-0.559-2.347c0-0.676-0.402-1.581-0.894-2.012
        c-0.493-0.43-0.744-1.335-0.559-2.013c0.185-0.676,0.234-2.033,0.112-3.018c-0.123-0.984-0.625-2.543-1.118-3.464
        c-0.492-0.923-1.649-2.331-2.57-3.129c-0.923-0.799-1.677-1.453-1.677-1.453s-0.855-0.251-1.9-0.559
        c-1.046-0.308-2.806-0.357-3.912-0.113c-1.106,0.246-2.616,0.498-3.354,0.561c-0.738,0.062-2.146,0.564-3.129,1.117
        c-0.984,0.553-2.744,1.257-3.912,1.565c-1.168,0.307-2.627,0.457-3.242,0.336c-0.615-0.123-1.318-0.778-1.564-1.454
        s-0.9-1.683-1.454-2.236c-0.554-0.553-1.408-1.509-1.9-2.123c-0.493-0.615-0.96-0.767-0.895-1.118
        c0.09-0.486,0.769-0.448,1.707-0.997c0.939-0.548,1.872-1.49,2.073-2.093s0.804-1.316,1.341-1.585
        c0.536-0.269,1.25-0.322,1.585-0.122c0.336,0.2,0.884,0.092,1.219-0.245c0.336-0.336,0.061-1.432-0.61-2.438
        c-0.67-1.006-0.616-2.157,0.122-2.561c0.738-0.402,1.725-1.115,2.194-1.585c0.47-0.47,1.128-1.183,1.464-1.585
        c0.336-0.404,0.939-0.512,1.341-0.244s1.006,0.214,1.341-0.123c0.336-0.336,0.664-1.103,0.731-1.707
        c0.067-0.603,0.67-1.097,1.341-1.097s1.603-0.219,2.072-0.488s0.634-1.365,0.366-2.438c-0.269-1.074-0.981-2.884-1.586-4.024
        c-0.603-1.141-1.427-2.567-1.83-3.172c-0.402-0.603-1.28-1.208-1.951-1.341c-0.67-0.133-0.945-0.958-0.611-1.83
        c0.336-0.871,0.775-1.969,0.976-2.439c0.201-0.469,0.147-1.622-0.122-2.559c-0.269-0.939-0.159-2.421,0.244-3.294
        c0.402-0.872,1.28-1.75,1.952-1.951c0.671-0.2,1.658-0.036,2.194,0.366c0.537,0.404,1.36,0.293,1.83-0.244s1.238-1.634,1.708-2.439
        c0.469-0.805,0.579-1.957,0.244-2.561c-0.336-0.604-0.774-1.647-0.976-2.317c-0.201-0.669-0.475-2.206-0.61-3.413
        c-0.134-1.208,0.102-1.766-0.245-2.195c-0.121-0.151-0.33-0.109-0.731-0.244c-0.402-0.133-1.006-0.133-1.341,0
        c-0.336,0.133-0.829-0.361-1.097-1.098c-0.268-0.738-1.146-2.274-1.951-3.413c-0.805-1.141-1.464-2.567-1.464-3.17
        c0-0.603-0.055-1.867-0.122-2.806c-0.067-0.938-0.725-1.707-1.463-1.707s-1.561-0.549-1.83-1.22
        c-0.268-0.671-1.091-1.439-1.829-1.708c-0.738-0.268-1.341-0.816-1.341-1.218c0-0.404,0-1.281,0-1.952
        c0-0.669-0.603-1.492-1.341-1.829c-0.738-0.336-1.726-0.225-2.195,0.244c-0.469,0.47-0.963,1.403-1.097,2.073
        c-0.133,0.67-0.133,1.878,0,2.683c0.134,0.805-0.085,1.519-0.488,1.585c-0.402,0.067-1.061,0.396-1.463,0.732
        s-1.225,0.83-1.83,1.097c-0.603,0.268-0.823,1.199-0.488,2.072c0.336,0.873-0.214,1.969-1.219,2.439
        c-1.005,0.469-2.433,1.237-3.17,1.707c-0.738,0.47-2.11,1.128-3.049,1.463c-0.939,0.337-2.366,0.83-3.171,1.098
        c-0.805,0.269-2.505,0.542-3.78,0.61c-1.274,0.067-2.316,0.891-2.316,1.83c0,0.938-0.439,2.201-0.975,2.805
        s-1.469,1.316-2.072,1.585c-0.604,0.269-1.591,0.488-2.194,0.488s-1.811-0.384-2.683-0.853c-0.872-0.47-2.08-0.142-2.683,0.731
        c-0.603,0.872-1.427,1.53-1.83,1.463c-0.402-0.067-1.225,0.152-1.83,0.489c-0.603,0.336-1.427,0.39-1.829,0.121
        c-0.403-0.268-1.062-0.268-1.464,0c-0.402,0.269-1.171,0.873-1.708,1.341c-0.537,0.47-1.688,0.964-2.56,1.097
        c-0.872,0.135-1.969,0.739-2.438,1.341c-0.469,0.604-1.239,1.207-1.708,1.341c-0.469,0.133-0.58,0.739-0.244,1.341
        c0.336,0.603,0.391,1.647,0.122,2.317c-0.268,0.671-0.926,1.438-1.463,1.707c-0.536,0.268-1.579-0.17-2.316-0.974
        c-0.738-0.804-1.725-1.189-2.194-0.854c-0.47,0.337-1.073,1.378-1.341,2.317c-0.269,0.939-0.269,1.815,0,1.951
        s0.652,1.012,0.852,1.952c0.201,0.939,0.366,2.255,0.366,2.926s-0.877,1.658-1.951,2.195c-1.072,0.536-2.225,0.591-2.56,0.121
        c-0.336-0.469-0.939-1.072-1.341-1.341c-0.402-0.268-1.116-0.157-1.586,0.245c-0.469,0.402-1.292,0.183-1.829-0.488
        c-0.537-0.671-1.8-1.11-2.805-0.976c-1.006,0.133-2.597,0.738-3.536,1.341c-0.939,0.604-2.036,0.549-2.439-0.122
        c-0.402-0.67-1.28-1.603-1.951-2.073c-0.67-0.469-1.219-0.469-1.219,0c0,0.47,0.383,1.348,0.853,1.952
        c0.469,0.603,0.474,1.359,0.009,1.677c-0.464,0.318-0.843,0.579-0.843,0.579s-0.503,0.302-1.118,0.671
        c-0.615,0.37-1.57,0.419-2.124,0.113c-0.554-0.308-1.408,0.044-1.9,0.782s-0.894,1.643-0.894,2.012c0,0.37-0.302,0.871-0.67,1.117
        c-0.369,0.246-1.072,0.446-1.564,0.446c-0.493,0-1.599,0.503-2.46,1.117c-0.862,0.616-1.313,1.974-1.006,3.018
        c0.308,1.046,0.106,1.8-0.446,1.677c-0.554-0.122-1.157,0.078-1.341,0.448c-0.185,0.37-0.486,1.324-0.67,2.124
        c-0.185,0.799-0.282,1.085-0.336,1.453c-0.278,1.939-0.392,5.866-0.447,7.824c-0.01,0.364,0,0.654,0,1.454
        c0,0.799,0.302,2.107,0.67,2.905c0.369,0.799,0.923,1.906,1.229,2.459c0.308,0.553,0.912,1.006,1.341,1.006
        c0.43,0,0.933,1.006,1.118,2.236c0.185,1.23,0.788,2.689,1.341,3.242c0.554,0.553,1.257,1.157,1.565,1.341
        c0.308,0.185,0.811,0.838,1.119,1.453c0.307,0.615,1.212,1.165,2.012,1.228c0.799,0.062,1.705,0.465,2.012,0.896
        c0.307,0.43,1.162,1.084,1.9,1.453c0.738,0.37,2.297,0.622,3.465,0.559c1.167-0.061,1.872,0.541,1.564,1.341
        c-0.307,0.799-0.408,2.005-0.223,2.683c0.185,0.676,0.033,1.33-0.336,1.452c-0.369,0.123-0.57,0.978-0.447,1.901
        c0.123,0.923,0.454,1.321,0.224,1.677c-0.158,0.245-0.503,0.151-1.118,0.336c-0.615,0.185-1.621,0.788-2.236,1.341
        s-1.067,1.56-1.006,2.236c0.062,0.676-0.289,1.783-0.782,2.46c-0.492,0.676-1.246,1.581-1.677,2.012
        c-0.43,0.43-0.933,1.236-1.118,1.788c-0.185,0.553-0.236,1.408-0.112,1.901c0.123,0.491,0.022,1.296-0.224,1.786
        c-0.246,0.493-0.583,0.684-0.446,0.895c0.396,0.616,1.317-0.045,2.927-0.101c1.61-0.057,3.915-0.048,5.121,0.02
        c1.207,0.067,3.073,0.177,4.146,0.244c1.072,0.067,2.5-0.207,3.171-0.61c0.671-0.404,1.439-0.678,1.708-0.611
        c0.268,0.067,1.53-0.097,2.805-0.366c1.274-0.269,3.468-1.091,4.877-1.829c1.409-0.738,3.658-2.055,4.999-2.928
        c1.341-0.871,2.602-1.311,2.805-0.974c0.201,0.336,1.025,0.61,1.83,0.61s2.012,0.383,2.683,0.854c0.67,0.47,2.097,1.019,3.17,1.219
        c1.072,0.2,2.883,1.134,4.024,2.072c1.141,0.939,2.512,1.379,3.049,0.977c0.537-0.404,1.25-0.622,1.586-0.489
        s0.664,0.683,0.731,1.219s0.286,1.413,0.488,1.951c0.201,0.537,0.311,1.25,0.244,1.586c-0.067,0.336-0.011,1.048,0.123,1.585
        c0.133,0.535,0.133,1.468,0,2.072c-0.134,0.604-0.793,1.208-1.464,1.341c-0.67,0.133-1.164,0.792-1.097,1.463
        c0.067,0.671,0.505,1.603,0.975,2.073c0.469,0.469,0.853,1.291,0.853,1.829c0,0.537,0.604,1.031,1.341,1.098
        c0.738,0.067,1.945,0.671,2.683,1.341c0.738,0.671,1.177,1.769,0.976,2.439c-0.201,0.671-0.805,1.493-1.341,1.829
        c-0.537,0.336-1.14,1.323-1.341,2.195c-0.201,0.871-0.586,1.913-0.854,2.316c-0.268,0.404-0.872,1.171-1.341,1.708
        c-0.469,0.536-0.579,1.633-0.244,2.439c0.336,0.804,1.177,2.049,1.87,2.764s0.81,1.252,1.26,1.301
        c0.401,0.043,0.603-0.402,1.341-0.895s1.895-0.644,2.57-0.336s1.733,0.61,2.347,0.67c0.615,0.062,1.118,0.464,1.118,0.894
        c0,0.431,0.302,1.336,0.671,2.012c0.369,0.678,1.023,1.883,1.454,2.683c0.43,0.801,1.385,1.504,2.123,1.566
        s1.794,0.816,2.347,1.677c0.554,0.86,1.76,1.614,2.683,1.677c0.923,0.061,2.532,0.061,3.578,0c1.044-0.063,2.454-0.263,3.129-0.448
        s1.833,0.217,2.57,0.894c0.738,0.677,1.693,1.18,2.124,1.119c0.43-0.062,1.386-0.212,2.124-0.336
        c0.738-0.123,1.744,0.078,2.236,0.446c0.492,0.37,1.749,0.923,2.794,1.23c1.046,0.308,2.303,1.112,2.796,1.789
        c0.492,0.676,1.749,1.43,2.794,1.677c1.046,0.245,2.805,0.647,3.912,0.894c1.107,0.246,2.012,0.446,2.012,0.446
        s0.503-0.2,1.118-0.446C362.812,543.674,363.667,542.819,364.098,542.021z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_19_" x="283.422" y="453.816" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" strokeMiterlimit="10" enable-background="new    " width="57.692" height="20.279" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_GERMANY_BADENWURTTEMBERG" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Baden-Wurttemberg" data-infoText="<b>Baden-Wurttemberg</b> &amp;nbsp; Capital: Stuttgart">
    <path fill="#1e8346" d="M484.382,567.273c-0.337-0.67-1.05-1.441-1.585-1.708c-0.537-0.269-1.086-0.871-1.221-1.341
        c-0.133-0.469,0.032-1.731,0.367-2.803c0.337-1.073,0.719-3.105,0.854-4.513c0.133-1.408,0.244-3.11,0.244-3.78
        c0-0.67-0.166-1.439-0.367-1.707c-0.201-0.269-0.037-0.762,0.367-1.098c0.402-0.336,0.292-1.158-0.244-1.829
        c-0.537-0.671-1.305-1.713-1.708-2.317c-0.402-0.603-1.225-1.536-1.829-2.073c-0.603-0.535-1.536-1.633-2.073-2.439
        c-0.537-0.805-1.634-1.682-2.439-1.951s-1.573-0.926-1.707-1.463c-0.133-0.537-0.738-1.47-1.341-2.072
        c-0.603-0.603-0.714-1.536-0.244-2.073s0.964-1.36,1.098-1.83c0.133-0.469-0.361-1.182-1.098-1.585
        c-0.738-0.402-1.78-0.951-2.317-1.218c-0.537-0.269-1.361-0.983-1.83-1.586c-0.469-0.604-1.074-2.03-1.341-3.172
        c-0.268-1.14-0.542-2.512-0.61-3.049c-0.067-0.537,0.097-1.086,0.366-1.219s0.489-1.012,0.489-1.952
        c0-0.938-0.439-2.091-0.976-2.559c-0.537-0.47-0.976-1.568-0.976-2.439c0-0.872,0.551-1.751,1.22-1.952
        c0.671-0.2,1.11-1.188,0.976-2.195c-0.133-1.006-0.627-2.048-1.098-2.316c-0.47-0.268-0.634-0.928-0.366-1.465
        c0.268-0.535,0.323-1.468,0.122-2.072c-0.2-0.603-0.366-1.316-0.366-1.585c0-0.269-0.274-1.146-0.61-1.952
        c-0.336-0.805-1.05-1.353-1.586-1.218c-0.536,0.133-0.975,0.627-0.975,1.098c0,0.469-0.549,1.402-1.219,2.072
        c-0.671,0.67-2.207,1.275-3.414,1.341c-1.207,0.067-2.249-0.646-2.317-1.585c-0.067-0.939-0.397-2.529-0.731-3.536
        c-0.336-1.006-0.884-1.829-1.219-1.829c-0.336,0-0.994,0.383-1.463,0.854c-0.469,0.469-1.072,0.632-1.341,0.364
        c-0.268-0.268-0.269-0.982,0-1.585c0.268-0.602,0.817-1.701,1.219-2.438c0.402-0.738,0.183-2-0.488-2.805
        c-0.67-0.804-1.219-2.177-1.219-3.05c0-0.871-0.549-2.407-1.22-3.413c-0.671-1.007-2.044-1.061-3.049-0.123
        c-1.006,0.939-2.103,1.104-2.438,0.367c-0.336-0.738-1.103-0.683-1.708,0.122c-0.603,0.804-1.482,1.243-1.952,0.976
        c-0.47-0.269-0.634-1.311-0.366-2.317c0.269-1.006,0.433-2.597,0.366-3.535c-0.067-0.939-0.781-1.516-1.586-1.281
        c-0.804,0.234-1.16,0.196-1.463,0.427c-0.21,0.16-0.252,0.402-0.559,0.894c-0.308,0.493-0.961,0.644-1.453,0.336
        c-0.492-0.307-1.296-1.011-1.788-1.564c-0.493-0.553-1.196-0.804-1.565-0.558c-0.369,0.245-1.174,0.296-1.788,0.112
        c-0.615-0.185-1.47-0.336-1.901-0.336s-1.236,0.05-1.788,0.112c-0.554,0.062-1.106,0.262-1.229,0.446
        c-0.123,0.185-0.676,0.385-1.229,0.446c-0.554,0.063-1.056,0.566-1.118,1.119c-0.062,0.553-0.112,1.006-0.112,1.006
        s0.032,0.288,0.071,0.639c0.039,0.352,0.51,0.806,1.047,1.006c0.537,0.201,1.195,0.695,1.464,1.098
        c0.269,0.404,0.981,0.567,1.586,0.367c0.603-0.201,1.261,0.127,1.463,0.73c0.201,0.603,0.091,1.921-0.245,2.927
        c-0.336,1.007-0.994,1.72-1.463,1.585c-0.469-0.133-1.292-0.024-1.829,0.244c-0.536,0.269-1.36,0.434-1.829,0.367
        c-0.47-0.067-1.128,0.537-1.464,1.341c-0.336,0.805-1.103,1.957-1.708,2.561c-0.603,0.603-2.14,1.097-3.413,1.097
        c-1.275,0-3.141-0.055-4.146-0.122c-1.006-0.067-1.448-0.377-1.83-0.122c-0.16,0.107-0.111,0.329-0.244,0.733
        c-0.133,0.402,0.141,1.28,0.61,1.95c0.47,0.67,0.963,1.603,1.097,2.073s-0.305,0.689-0.974,0.489
        c-0.671-0.201-1.604,0.017-2.073,0.488c-0.469,0.469-1.786,0.688-2.927,0.488s-2.512-0.092-3.048,0.244
        c-0.537,0.337-1.031,0.939-1.098,1.341c-0.067,0.404,0.042,1.309,0.244,2.013c0.201,0.705,0.581,1.026,0.366,1.281
        c-0.163,0.194-0.452-0.05-1.006-0.113c-0.554-0.061-1.207,0.291-1.453,0.783c-0.246,0.493-0.849,1.447-1.341,2.125
        c-0.493,0.676-0.514,1.209-0.895,1.228c-0.49,0.025-0.589-0.659-1.31-1.463s-0.981-1.628-0.58-1.83
        c0.402-0.2,0.622-0.914,0.488-1.585s0.085-1.384,0.488-1.585c0.403-0.2,1.116-0.366,1.586-0.366s0.908-0.494,0.975-1.097
        c0.067-0.604-0.152-1.262-0.488-1.463c-0.336-0.2-0.994-0.2-1.463,0s-1.128,0.036-1.464-0.367
        c-0.336-0.402-1.268-0.786-2.072-0.852c-0.805-0.067-1.957-0.506-2.561-0.976c-0.603-0.469-1.427-1.402-1.829-2.072
        c-0.402-0.671-0.786-2.207-0.854-3.414c-0.067-1.208-1.22-2.03-2.561-1.83c-1.341,0.201-2.274,1.628-2.072,3.171
        c0.201,1.543-0.184,3.079-0.854,3.414c-0.671,0.336-1.823-0.104-2.561-0.976c-0.738-0.871-1.891-1.969-2.561-2.439
        c-0.669-0.47-1.896-0.694-2.723-0.498c-0.828,0.196-1.265,0.054-1.504,0.356c-0.209,0.263,0,0.603,0,1.341s0.201,2.146,0.447,3.129
        c0.246,0.984,0.8,2.441,1.23,3.241c0.43,0.801,0.631,1.857,0.446,2.347c-0.185,0.493,0.318,1.146,1.117,1.454
        c0.8,0.308,1.303,1.062,1.118,1.677s-0.336,1.52-0.336,2.012c0,0.493-0.201,1.599-0.447,2.46c-0.246,0.86-0.648,2.067-0.894,2.683
        c-0.246,0.615-0.447,1.268-0.447,1.453s0.251,0.688,0.559,1.117c0.307,0.43,0.156,1.184-0.336,1.677s-1.146,1.447-1.453,2.124
        c-0.308,0.676-1.113,1.53-1.789,1.899c-0.676,0.37-1.48,1.726-1.788,3.018c-0.307,1.291-0.61,3.001-0.67,3.802
        c-0.062,0.799-0.212,2.005-0.336,2.683c-0.123,0.676-0.625,1.833-1.117,2.57c-0.493,0.738-1.196,1.894-1.565,2.57
        s-0.821,1.733-1.006,2.347c-0.185,0.616-0.688,1.772-1.117,2.572c-0.431,0.799-1.286,1.653-1.901,1.899s-1.118,0.446-1.118,0.446
        s-0.352,0.251-0.783,0.559c-0.43,0.308-1.084,1.062-1.453,1.677s-1.123,1.822-1.677,2.683c-0.554,0.861-1.359,2.47-1.788,3.578
        c-0.43,1.107-1.034,2.816-1.341,3.8c-0.308,0.984-1.365,2.141-2.347,2.57c-0.984,0.431-1.99,1.135-2.236,1.566
        c-0.246,0.43-1.052,0.882-1.788,1.006c-0.738,0.122-1.291,0.776-1.229,1.452c0.062,0.678-0.391,1.632-1.006,2.125
        c-0.615,0.493-1.772,1.649-2.571,2.571c-0.799,0.923-2.057,1.928-2.794,2.236c-0.738,0.307-1.492,1.212-1.677,2.013
        c-0.185,0.799-0.486,2.057-0.67,2.794c-0.185,0.738-0.282,0.999-0.336,1.341c-0.065,0.415-0.051,0.754-0.112,1.677
        c-0.062,0.923-0.213,2.28-0.336,3.018s-0.577,1.541-1.006,1.788c-0.43,0.246-0.683,1.001-0.559,1.677
        c0.123,0.676-0.078,1.783-0.447,2.46c-0.369,0.676-0.972,1.833-1.341,2.57c-0.369,0.738-0.62,1.894-0.559,2.57
        c0.062,0.678,0.212,1.985,0.336,2.907c0.123,0.923,0.072,1.828-0.112,2.011c-0.185,0.185-0.788,0.688-1.341,1.118
        c-0.554,0.431-1.157,1.286-1.341,1.902c-0.185,0.615-0.436,1.922-0.559,2.905c-0.123,0.984-0.575,2.039-1.006,2.347
        c-0.43,0.308-1.285,1.314-1.901,2.236c-0.615,0.923-1.318,2.13-1.564,2.683s-0.447,1.359-0.447,1.788s-0.453,1.285-1.006,1.899
        c-0.554,0.616-1.056,2.124-1.118,3.354s-0.011,2.84,0.112,3.578s0.677,2.146,1.23,3.129c0.554,0.985,0.956,2.393,0.895,3.13
        c-0.062,0.738-0.665,1.744-1.341,2.235c-0.677,0.493-1.28,1.599-1.341,2.46s-0.364,1.917-0.671,2.347
        c-0.308,0.43-0.509,1.336-0.448,2.013c0.062,0.676-0.19,1.834-0.559,2.571c-0.369,0.738-0.771,1.694-0.894,2.123
        c-0.123,0.43-0.223,1.385-0.223,2.123s0.151,1.896,0.336,2.571c0.185,0.676,0.134,1.583-0.113,2.012
        c-0.246,0.43-0.598,1.134-0.782,1.564c-0.185,0.431-0.185,1.388,0,2.125c0.185,0.738,0.688,1.844,1.118,2.46
        c0.43,0.615,1.285,1.922,1.901,2.905c0.615,0.984,0.887,1.314,1.118,1.788c0.363,0.748,0.599,2.483,1.118,3.13
        c0.227,0.284,0.554,0.352,1.229,0.782c0.676,0.43,1.733,0.732,2.347,0.671c0.615-0.062,1.923-0.262,2.907-0.446
        c0.984-0.185,2.29-0.587,2.905-0.895c0.615-0.308,1.319-0.911,1.565-1.341s0.849-0.832,1.341-0.894
        c0.492-0.063,1.447,0.09,2.123,0.336c0.677,0.246,1.331,0.9,1.454,1.453c0.122,0.553,1.028,0.958,2.012,0.895
        c0.984-0.062,2.341-0.063,3.018,0c0.676,0.062,2.236,0.112,3.465,0.112s2.436-0.402,2.683-0.895c0.246-0.493,0.8-1.046,1.23-1.229
        c0.43-0.185,1.285-0.636,1.899-1.006c0.615-0.369,1.873-0.871,2.795-1.117c0.923-0.246,1.243-0.454,1.677-0.446
        c0.292,0.005,0.503,0.151,1.118,0.336c0.615,0.185,1.37,0.587,1.677,0.895c0.307,0.307,0.961,0.96,1.453,1.452
        c0.493,0.493,1.297,0.895,1.789,0.895s1.397,0,2.012,0s1.318,0.101,1.564,0.224s1.403-0.077,2.571-0.446
        c1.168-0.37,2.054-0.118,2.124-0.671c0.033-0.262-0.402-0.251-0.894-0.559c-0.493-0.308-0.493-0.71,0-0.895
        c0.492-0.185,1.196-0.688,1.564-1.117c0.369-0.43,1.123-0.582,1.677-0.336s1.359,0.949,1.788,1.564
        c0.431,0.616,0.441,1.123,0.782,1.119c0.302-0.004,0.302-0.454,0.67-1.006c0.369-0.553,0.772-1.359,0.895-1.789
        c0.122-0.43,0.475-1.033,0.782-1.341c0.307-0.307,0.581-0.362,0.559-0.558c-0.03-0.262-0.402-0.251-0.894-0.558
        c-0.492-0.308-1.498-0.559-2.236-0.559s-1.895,0.253-2.57,0.559c-0.677,0.308-2.488,0.156-4.024-0.336
        c-1.537-0.493-2.644-1.096-2.46-1.341c0.185-0.246,0.336-0.85,0.336-1.341c0-0.493,0.453-1.247,1.006-1.677
        c0.554-0.431,1.208-1.488,1.454-2.347c0.246-0.861,0.749-1.767,1.117-2.012c0.369-0.246,1.324-0.699,2.123-1.006
        c0.8-0.308,1.705-0.559,2.012-0.559c0.308,0,0.66-0.251,0.783-0.559c0.122-0.308,0.726-0.61,1.341-0.671
        c0.615-0.062,1.218,0.342,1.341,0.895c0.122,0.553,0.475,1.106,0.782,1.23c0.307,0.122,0.861-0.18,1.229-0.67
        c0.369-0.493,1.023-0.493,1.454,0c0.431,0.491,1.236,1.043,1.788,1.228c0.554,0.185,1.057,0.939,1.118,1.677
        s0.161,1.693,0.223,2.124c0.061,0.43-0.643,0.832-1.564,0.894c-0.923,0.062-1.425,0.412-1.118,0.782
        c0.308,0.369,0.374,0.554,0.559,0.67c0.195,0.123,0.402,0.101,0.895,0.224c0.492,0.123,1.246,0.375,1.677,0.559
        c0.43,0.185,1.285,0.486,1.899,0.671c0.615,0.185,0.883,0.509,1.118,0.336c0.224-0.166,0-0.503,0-1.117c0-0.616,0-1.471,0-1.902
        c0-0.43,0.251-0.933,0.559-1.117c0.308-0.185,1.011,0.017,1.565,0.446c0.554,0.431,1.006,1.286,1.006,1.902
        c0,0.615,0.302,1.671,0.671,2.347c0.369,0.676,0.409,0.997,0.67,1.228c0.225,0.2,0.503,0.202,1.118,0.448s1.772,0.044,2.571-0.448
        c0.504-0.31,1.183-0.679,1.807-0.99c0.366-0.182,0.716-0.35,0.989-0.464c0.737-0.308,1.895-0.558,2.57-0.558s1.682,0,2.236,0
        s1.66,0.302,2.459,0.67c0.799,0.37,2.258,0.821,3.242,1.006s2.392,0.234,3.129,0.113c0.738-0.123,5.315,2.089,10.171,4.918
        c4.857,2.828,6.763,5.03,8.831,5.141c0.457,0.025,0.404-0.718,0.895-1.594c0.252-0.45,0.582-0.869,0.907-1.183
        c0.308-0.298,0.613-0.502,0.841-0.534c0.47-0.067,1.072-0.45,1.341-0.852c0.269-0.404,1.475-0.732,2.683-0.732
        c1.207,0,2.963-0.659,3.902-1.463c0.939-0.805,2.311-2.067,3.049-2.805s1.671-1.177,2.072-0.976c0.402,0.2,1.61,0.694,2.683,1.098
        c1.072,0.402,2.829,0.293,3.902-0.244s2.335-0.976,2.805-0.976c0.469,0,1.018,0.055,1.219,0.122s1.244-0.317,2.316-0.854
        c1.072-0.536,1.842-1.852,1.708-2.927c-0.133-1.072-0.245-2.224-0.245-2.56c0-0.337-0.382-0.994-0.852-1.465
        c-0.469-0.469-0.908-1.183-0.976-1.585c-0.067-0.402,0.317-1.061,0.854-1.464c0.537-0.402,1.086-1.115,1.219-1.585
        c0.133-0.469-0.085-1.457-0.488-2.194c-0.402-0.738-0.678-1.945-0.611-2.683s0.122-2.219,0.122-3.292
        c0-1.074-0.275-2.336-0.61-2.806c-0.336-0.469-0.336-1.237,0-1.706c0.336-0.47,0.994-2.225,1.464-3.903
        c0.469-1.677,0.908-3.815,0.974-4.756c0.068-0.939-0.317-2.86-0.852-4.268c-0.537-1.408-1.195-3.438-1.463-4.512
        c-0.269-1.074-0.653-2.885-0.854-4.024c-0.201-1.141-0.586-2.952-0.854-4.024c-0.269-1.074-0.926-2.556-1.463-3.293
        c-0.536-0.738-1.25-2.055-1.585-2.927c-0.336-0.873-0.391-2.629-0.122-3.903s0.762-2.81,1.098-3.414
        c0.336-0.603,0.719-1.427,0.853-1.829c0.134-0.404,1.286-1.116,2.56-1.585c1.275-0.47,2.701-0.581,3.172-0.244
        c0.469,0.336,1.676,0.17,2.683-0.367c1.006-0.537,2.377-1.634,3.048-2.439c0.67-0.804,1.769-1.573,2.439-1.707
        c0.671-0.133,1.219-1.176,1.219-2.317c0-1.14-0.055-2.456-0.122-2.927c-0.067-0.469,0.152-1.126,0.488-1.462
        c0.336-0.337,0.171-1.05-0.366-1.586c-0.537-0.537-1.359-1.36-1.829-1.83c-0.47-0.47-0.689-1.457-0.489-2.194
        s0.914-1.232,1.585-1.098c0.671,0.133,1.549,0.244,1.951,0.244c0.403,0,0.953,0.494,1.221,1.097
        c0.268,0.603,0.871,0.826,1.341,0.489c0.469-0.336,1.018-0.939,1.218-1.341c0.201-0.404,0.695-0.787,1.098-0.854
        c0.404-0.067,0.732,0.262,0.732,0.73c0,0.47,0.439,0.854,0.976,0.854c0.536,0,1.194-0.439,1.462-0.974
        C484.772,568.932,484.717,567.944,484.382,567.273z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_20_" x="375.326" y="573.796" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" strokeMiterlimit="10" enable-background="new    " width="57.692" height="20.279" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_GERMANY_BRANDENBURG" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Brandenburg" data-infoText="<b>Brandenburg</b> &amp;nbsp; Capital: Potsdam">

        <rect id="rect12_8_" x="625.362" y="280.033" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" strokeMiterlimit="10" enable-background="new    " width="57.692" height="20.279" pointer-events="auto" visibility="hidden"></rect>
    <path fill="#1e8346" d="M703.568,313.097c0-0.801-0.454-1.755-1.006-2.123c-0.553-0.37-1.509-1.072-2.125-1.565
        c-0.615-0.492-1.218-1.447-1.341-2.124c-0.122-0.676-0.575-2.236-1.006-3.465c-0.43-1.228-1.236-2.436-1.788-2.683
        c-0.553-0.246-1.157-0.9-1.341-1.454c-0.185-0.553-0.034-1.407,0.336-1.899c0.369-0.493,1.124-1.549,1.677-2.347
        c0.553-0.799,1.359-2.006,1.788-2.683c0.431-0.676,0.986-1.883,1.232-2.683c0.245-0.799,0.246-1.906,0-2.46
        c-0.245-0.553-0.246-1.76,0-2.683c0.245-0.923,0.647-2.128,0.894-2.683c0.246-0.553,0.496-1.76,0.558-2.683
        c0.063-0.923,0.304-1.302,0.113-1.677c-0.119-0.235-0.402-0.251-0.894-0.558c-0.493-0.307-1.247-0.81-1.677-1.117
        c-0.431-0.308-0.833-1.112-0.896-1.789c-0.062-0.676,0.141-1.882,0.448-2.683c0.308-0.799,0.308-1.906,0-2.459
        s-0.357-1.46-0.113-2.013c0.246-0.553,0.448-1.711,0.448-2.571c0-0.861-0.705-1.716-1.564-1.901
        c-0.861-0.185-2.319-0.587-3.242-0.894c-0.923-0.307-1.778-1.213-1.901-2.013c-0.123-0.799-0.525-2.157-0.895-3.018
        s-0.924-2.17-1.23-2.905c-0.308-0.739-0.81-1.644-1.116-2.012c-0.308-0.37-0.207-1.174,0.223-1.789
        c0.43-0.616,1.033-1.52,1.341-2.012c0.308-0.493,0.861-1.247,1.23-1.677c0.37-0.43,0.972-1.336,1.341-2.012
        c0.37-0.676,0.37-1.531,0-1.899c-0.369-0.37-0.72-1.174-0.782-1.789c-0.063-0.616-0.011-1.622,0.11-2.236
        c0.123-0.615,0.324-1.52,0.448-2.012c0.123-0.491,0.296-0.675,0.224-0.895c-0.135-0.415-0.603-0.503-1.341-1.117
        c-0.738-0.615-2.196-1.52-3.242-2.013c-1.046-0.491-2.504-1.346-3.242-1.901c-0.738-0.553-1.894-1.71-2.571-2.57
        c-0.676-0.861-1.781-2.318-2.458-3.241c-0.678-0.923-1.883-1.928-2.683-2.236c-0.801-0.308-1.907-0.962-2.46-1.454
        c-0.553-0.491-1.711-1.549-2.57-2.347c-0.861-0.801-2.47-1.654-3.578-1.899c-1.107-0.246-2.012-1.001-2.012-1.677
        c0-0.678,0.302-1.632,0.67-2.125c0.369-0.491,0.469-1.749,0.224-2.794c-0.246-1.046-0.549-2.554-0.67-3.354
        c-0.123-0.799,0.681-2.107,1.788-2.907c1.107-0.799,2.565-2.005,3.242-2.683c0.676-0.676,1.933-1.783,2.793-2.459
        c0.861-0.676,1.817-2.337,2.125-3.688s0.71-3.364,0.895-4.472c0.185-1.105,0.133-2.515-0.112-3.129
        c-0.246-0.615-0.298-1.52-0.113-2.013c0.185-0.491,0.586-1.246,0.894-1.677c0.307-0.43,0.445-0.571,0.559-0.782
        c0.256-0.474,0.352-0.905,0.782-2.012c0.431-1.106,0.431-2.414,0-2.907c-0.43-0.491-0.883-1.749-1.006-2.794
        c-0.122-1.046,0.178-1.64-0.223-1.899c-0.738-0.477-1.509,0.474-3.354,1.056c-1.844,0.582-3.682,1.881-4.084,2.887
        c-0.404,1.006-1.336,2.103-2.073,2.438c-0.738,0.336-2,0.445-2.805,0.244c-0.804-0.2-1.847-0.366-2.316-0.366
        c-0.47,0-0.854-0.439-0.854-0.976c0-0.535-0.329-1.413-0.731-1.951c-0.404-0.537-1.006-1.525-1.341-2.195
        c-0.337-0.671-0.939-2.097-1.341-3.17c-0.404-1.072-1.335-2.556-2.074-3.293c-0.738-0.737-2.056-1.231-2.928-1.096
        c-0.871,0.133-2.189,0.792-2.927,1.463c-0.738,0.671-2.054,1.11-2.927,0.974c-0.872-0.133-3.177-0.353-5.122-0.487
        c-1.944-0.133-4.14-0.244-4.876-0.244s-1.725,0.439-2.195,0.976c-0.47,0.536-1.238,1.799-1.707,2.805
        c-0.47,1.006-0.854,2.433-0.854,3.17s-0.328,1.78-0.73,2.317c-0.402,0.536-1.336,1.194-2.074,1.463s-1.561,1.256-1.83,2.195
        c-0.268,0.938-0.816,1.871-1.218,2.072c-0.404,0.201-0.898,0.147-1.098-0.122s-0.31-0.926-0.244-1.463
        c0.067-0.537-0.592-1.14-1.464-1.341c-0.871-0.201-1.969-0.092-2.439,0.244c-0.469,0.336-1.072,1.102-1.341,1.708
        c-0.268,0.602-0.981,1.372-1.585,1.707c-0.602,0.334-1.535,0.444-2.072,0.244c-0.537-0.201-1.36-0.861-1.829-1.463
        c-0.47-0.603-1.348-0.331-1.952,0.61c-0.604,0.939-1.536,2.202-2.072,2.805c-0.537,0.603-1.634,1.975-2.439,3.05
        c-0.805,1.071-1.791,1.786-2.195,1.585c-0.402-0.201-0.841-0.914-0.974-1.585c-0.135-0.671-0.684-1.001-1.221-0.732
        c-0.535,0.269-1.579,0.489-2.316,0.489c-0.738,0-2.11-0.165-3.05-0.367c-0.938-0.201-2.255-0.255-2.926-0.122
        c-0.67,0.135-1.33-0.415-1.463-1.219s-1.066-1.573-2.074-1.707c-1.006-0.135-2.268-0.627-2.803-1.098
        c-0.537-0.47-1.58-0.798-2.317-0.732c-0.738,0.068-1.945-0.207-2.683-0.61c-0.738-0.402-2-0.567-2.805-0.366
        c-0.804,0.201-2.396-0.183-3.536-0.854s-2.182-1.603-2.316-2.072c-0.135-0.469-0.629-0.964-1.098-1.097
        c-0.47-0.133-1.513-0.519-2.317-0.854c-0.805-0.336-1.792-0.611-2.194-0.611c-0.404,0-1.172-0.548-1.708-1.218
        c-0.537-0.671-1.305-1.22-1.708-1.22c-0.402,0-1.168,0.331-1.706,0.732c-0.536,0.402-1.196,0.238-1.464-0.367
        c-0.268-0.602-1.036-0.658-1.707-0.122c-0.67,0.537-1.549,0.591-1.951,0.122c-0.404-0.469-1.226-0.085-1.83,0.854
        c-0.603,0.938-4.817,2.527-10.365,4.387c-4.954,1.661-17.502,11.691-18.81,12.793c-1.577,1.329-3.163,2.666-4.485,4.249
        c-0.378,0.452-0.741,0.938-0.902,1.505c-0.161,0.567-0.084,1.234,0.331,1.653c0.446,0.451,1.165,0.51,1.784,0.368
        s1.185-0.451,1.789-0.645c1.72-0.552,3.728-0.079,5.021,1.183c0.256,0.25,0.495,0.535,0.823,0.678
        c0.391,0.171,0.843,0.111,1.26,0.199c0.633,0.133,1.139,0.591,1.711,0.893c0.164,0.087,0.338,0.149,0.511,0.212
        c0.133,0.785,1.91,1.609,2.658,1.85c0.245,0.079,0.454,0.101,1.006,0.224c0.553,0.122,1.359,0.827,1.788,1.565
        c0.43,0.737,1.083,1.09,1.453,0.782s1.023-0.559,1.453-0.559c0.431,0,1.085,0.603,1.454,1.341c0.37,0.738,1.275,1.341,2.013,1.341
        s1.241,0.654,1.119,1.453c-0.123,0.801,0.279,1.705,0.894,2.012c0.615,0.308,1.62,0.761,2.235,1.006
        c0.616,0.246,1.521,0.597,2.011,0.782c0.493,0.185,1.247,0.537,1.677,0.783c0.431,0.246,1.437,0.397,2.238,0.336
        c0.799-0.062,1.103-0.011,1.453-0.113c0.391-0.112,0.65-0.338,1.442-0.752c0.793-0.412,1.61-0.257,1.81,0.346
        c0.201,0.605,0.805,1.098,1.341,1.098c0.535,0,1.14-0.165,1.341-0.366c0.2-0.2,0.805-0.092,1.341,0.244
        c0.535,0.336,1.194,1.05,1.462,1.585c0.269,0.537,0.598,1.634,0.732,2.439c0.133,0.806-0.03,1.937-0.366,2.515
        c-0.337,0.579-0.435,0.805-0.611,1.052c-0.328,0.463-0.37,1.134,0,1.565c0.369,0.43,0.419,1.385,0.113,2.123
        c-0.308,0.738-0.106,2.096,0.446,3.018c0.553,0.923,0.938,1.192,1.006,1.677c0.039,0.282-0.132,0.494-0.294,1.097
        c-0.162,0.602-1.008,1.427-1.879,1.829c-0.871,0.404-1.585,1.501-1.585,2.439c0,0.939-0.439,2.586-0.976,3.658
        s-0.481,1.785,0.122,1.585s0.877,0.182,0.61,0.852c-0.268,0.671-0.214,1.55,0.123,1.952c0.336,0.402,1.048,0.567,1.585,0.366
        c0.536-0.2,1.193-0.805,1.462-1.341c0.268-0.535,0.818-0.481,1.221,0.122c0.404,0.604,1.225,1.098,1.829,1.098
        c0.603,0,0.714,1.428,0.244,3.172c-0.469,1.744-0.852,3.829-0.852,4.634c0,0.804-0.274,2.232-0.61,3.17
        c-0.337,0.939-0.611,2.146-0.611,2.683c0,0.537-0.219,1.964-0.488,3.172c-0.268,1.207,0.005,2.799,0.61,3.535
        c0.603,0.739,0.823,1.892,0.489,2.562c-0.337,0.671-0.831,1.713-1.098,2.316c-0.269,0.603-0.378,1.647-0.245,2.317
        c0.133,0.669,0.573,1.603,0.976,2.072c0.404,0.47,0.952,1.348,1.218,1.952c0.269,0.603,0.762,1.207,1.098,1.341
        c0.337,0.133,1.27,1.067,2.074,2.072c0.804,1.006,1.737,2.652,2.073,3.658c0.336,1.006,1.213,1.884,1.951,1.952
        c0.738,0.067,1.615,0.396,1.951,0.731c0.337,0.336,1.05,0.391,1.585,0.122c0.537-0.269,1.306-0.214,1.708,0.122
        s1.061,0.83,1.465,1.098c0.402,0.269,0.949,0.928,1.218,1.463c0.269,0.537,1.036,1.141,1.707,1.341
        c0.67,0.201,1.658-0.019,2.195-0.488c0.537-0.47,1.303-1.184,1.707-1.586c0.402-0.402,1.555-0.292,2.561,0.245
        c1.006,0.535,2.322,1.14,2.926,1.341c0.603,0.2,1.318,0.641,1.587,0.976c0.268,0.336,1.146,0.773,1.951,0.974
        s1.902,0.914,2.438,1.585c0.537,0.671,1.36,1.384,1.83,1.587c0.47,0.2,1.073,0.2,1.341,0c0.269-0.201,0.982-0.257,1.585-0.122
        c0.603,0.133,1.372,0.627,1.707,1.096c0.337,0.47,1.104,0.909,1.708,0.976c0.603,0.067,1.153,0.671,1.219,1.341
        c0.067,0.671,0.944,1.383,1.951,1.585c1.007,0.201,2.159,1.189,2.562,2.195c0.402,1.006,0.949,2.762,1.218,3.902
        c0.269,1.141,0.926,2.566,1.463,3.17s0.92,1.427,0.854,1.83c-0.067,0.401-0.671,1.115-1.341,1.585
        c-0.67,0.469-1.658,1.457-2.195,2.194c-0.537,0.738-1.305,1.945-1.707,2.683c-0.403,0.738-0.745,0.96-0.732,1.341
        c0.014,0.397,0.384,0.603,0.854,1.341c0.47,0.738,1.403,1.177,2.073,0.976c0.669-0.2,1.602-0.092,2.072,0.244
        c0.47,0.337,0.689,0.994,0.488,1.465c-0.2,0.469,0.128,1.457,0.732,2.194c0.604,0.738,1.208,1.891,1.341,2.561
        s-0.082,1.402-0.478,1.625c-0.397,0.224-0.617,0.228-0.722,0.406c-0.07,0.121,0,0.251,0,0.559s0.251,0.912,0.559,1.341
        c0.308,0.43,0.559,1.285,0.559,1.899c0,0.616,0.119,0.865,0,1.119c-0.113,0.239-0.366,0.302-0.813,0.671
        c-0.446,0.369-0.593,1.219-0.324,1.89c0.269,0.671,0.762,0.891,1.097,0.489c0.337-0.404,1.432-0.404,2.439,0
        c1.006,0.402,2.049,0.511,2.316,0.244c0.269-0.269,0.928-0.709,1.464-0.977s1.359-0.652,1.829-0.852
        c0.469-0.2,1.237-0.694,1.707-1.096c0.47-0.404,1.293-0.404,1.83,0c0.536,0.402,1.579,1.005,2.316,1.341
        c0.738,0.336,1.726,1.103,2.195,1.706c0.47,0.603,1.511,1.097,2.316,1.097c0.806,0,2.068,0.22,2.806,0.488
        c0.738,0.269,2.494,0.489,3.901,0.489c1.408,0,3.275,0,4.147,0c0.871,0,2.244-0.383,3.049-0.852
        c0.804-0.47,1.902-0.744,2.437-0.611c0.537,0.133,1.525-0.03,2.195-0.367c0.671-0.336,1.493-0.336,1.829,0
        c0.337,0.337,0.939,0.665,1.341,0.733c0.404,0.067,1.226,0.287,1.83,0.489c0.604,0.2,1.042-0.512,0.976-1.585
        c-0.067-1.074,0.097-2.72,0.366-3.659c0.269-0.938,0.377-2.035,0.244-2.439c-0.133-0.402-0.244-1.224-0.244-1.829
        c0-0.602,0.494-1.096,1.098-1.096c0.603,0,1.316-0.769,1.585-1.707c0.269-0.939,0.926-1.818,1.463-1.952
        c0.537-0.133,1.36-0.847,1.829-1.585c0.47-0.738,1.732-1.176,2.806-0.976c1.073,0.2,2.279,0.53,2.683,0.733
        c0.402,0.2,1.444,0.969,2.316,1.707s2.153,0.513,2.85-0.499c0.695-1.011,2.177-1.724,3.293-1.585
        c1.115,0.14,2.465-0.074,3.002-0.476s1.58-0.952,2.317-1.219c0.738-0.269,1.78-0.759,2.317-1.096
        c0.535-0.336,1.688-0.282,2.559,0.121c0.872,0.404,1.805-0.09,2.074-1.097c0.269-1.006,0.959-1.581,1.534-1.28
        c0.575,0.302,0.772,0.656,1.047,0.549c0.387-0.151,0.251-0.705,0.559-1.564c0.308-0.861,0.509-2.219,0.448-3.018
        C703.618,315.204,703.568,313.897,703.568,313.097z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>
</g>
<g id="AREA_GERMANY_BERLIN" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Berlin" data-infoText="<b>Berlin</b> &amp;nbsp; Capital: Berlin">
    <path fill="#1e8346" d="M647.781,249.252c-0.871-0.269-2.024-0.928-2.561-1.463c-0.537-0.537-1.414-1.031-1.952-1.098
        c-0.535-0.067-0.865-0.944-0.73-1.951c0.133-1.006-0.25-2.103-0.854-2.439c-0.602-0.336-1.81-1.268-2.683-2.073
        c-0.871-0.804-2.023-1.957-2.56-2.561c-0.535-0.602-1.031-1.59-1.098-2.194s-0.505-1.592-0.976-2.195
        c-0.47-0.603-1.183-0.603-1.586,0c-0.402,0.604-1.06,1.042-1.462,0.976c-0.403-0.068-1.173,0.371-1.708,0.974
        c-0.537,0.603-1.359,1.098-1.829,1.098c-0.469,0-1.183-0.274-1.585-0.61c-0.402-0.336-1.116-0.72-1.585-0.854
        c-0.47-0.135-1.074,0.305-1.341,0.976c-0.269,0.671-0.983,1.658-1.585,2.195c-0.603,0.537-1.591,0.974-2.195,0.974
        c-0.603,0-1.208,0.988-1.341,2.195c-0.133,1.208-0.189,3.128-0.122,4.268s-0.207,2.513-0.61,3.048
        c-0.404,0.537-1.062,1.576-1.465,2.307c-0.404,0.734-0.729,0.953-0.731,1.332c-0.002,0.479,0.411,0.758,0.914,1.686
        s1.467,1.585,2.143,1.463c0.677-0.123,1.632-0.475,2.124-0.782c0.493-0.307,1.447-0.508,2.125-0.446
        c0.676,0.062,0.926,0.051,1.228,0.112c0.376,0.074,0.659,0.206,1.463,0.458c0.804,0.253,2.067,1.226,2.805,2.165
        c0.738,0.938,1.341,1.048,1.341,0.244s0.714-1.354,1.585-1.219c0.872,0.133,1.642-0.195,1.708-0.732
        c0.067-0.537,0.616-1.14,1.22-1.341c0.603-0.201,1.208,0.073,1.341,0.611c0.133,0.537,0.572,0.646,0.976,0.244
        c0.402-0.402,1.335-0.402,2.072,0c0.738,0.402,2,0.896,2.804,1.098c0.804,0.201,1.902,1.08,2.439,1.951
        c0.537,0.872,1.141,1.091,1.341,0.489c0.2-0.605,0.694-1.208,1.096-1.341c0.404-0.134,0.732-0.849,0.732-1.585
        c0-0.739,0.274-1.617,0.61-1.952c0.337-0.336,0.665-1.103,0.732-1.707c0.067-0.603,0.396-1.261,0.732-1.463
        C649.092,249.904,648.652,249.52,647.781,249.252z" pointer-events="auto" stroke="#7eb08e" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_13_" x="603.49" y="235.778" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enableBackground="new    " width="57.692" height="20.279" pointer-events="auto" visibility="hidden"></rect>
</g>
</svg>
}
