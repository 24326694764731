export default function Africa({innerRef}) {
   return <svg ref={innerRef} version="1.1" id="svgpoint" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 900 725" enable-background="new 0 0 900 725" width="100%" height="100%" preserveAspectRatio="xMidYMid meet">
<rect id="WATER" x="-1.346" y="-3.667" fill="#a4d1dc" stroke="#95bec8" strokeMiterlimit="10" width="904.013" height="731.032" strokeWidth="0.1" opacity="1" className="water"></rect>
<g id="LAND" data-colors="0" data-errors="0" hint-index="0" className="">
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M621.047-83.307
      c1.321-0.209,2.502-0.348,1.946-1.251s-1.807-1.529-0.278-2.989s1.529-1.946,1.599-3.059c0.07-1.112-0.417-2.224-1.599-2.016
      c-1.182,0.209-1.807,1.043-3.406-0.556s-3.059-3.267-4.101-2.711c-1.043,0.556-2.572,1.877-3.684,0.417s-2.989-2.294-4.31-2.155
      s-1.946,1.668-3.893-0.278s-4.31-5.353-5.144-4.588s-3.198,0.278-3.615,1.321s-1.668,1.599-2.92,1.251
      c-1.251-0.348-2.155-1.946-3.337-2.085c-1.182-0.139-2.016-0.626-2.433,0.07c-0.417,0.695-1.877,0.626-2.503,0
      s-0.765-3.754-1.599-5.422s-1.668-4.101-2.92-4.31c-1.251-0.209-2.294,1.321-3.893,0.417s-3.198-0.07-3.337-1.807
      c-0.139-1.738-0.487-3.059-0.904-3.615s-0.07-1.46,0.765-1.599c0.834-0.139,1.946-0.417,0.487-1.738
      c-1.46-1.321-1.807-4.31-3.267-4.657s-1.738-1.529-2.363-0.695c-0.626,0.834-2.155-1.599-3.198-0.626
      c-1.043,0.973-1.529,2.781-3.406,2.433s-2.502-1.668-3.337-1.182c-0.834,0.487-1.599,0.556-2.155,1.738s-2.641,2.016-3.754,0.834
      c-0.106-0.113-0.208-0.223-0.311-0.334c-2.042,0.813-5.359,0.195-6.919,0.195c-1.807,0-2.781,3.198-3.615,4.727s0,4.588,0,5.561
      s-2.085,0.139-2.781-1.251c-0.695-1.39-1.807-1.112-3.476-1.112c-1.668,0-2.224,0.139-3.754,0.695s-1.807-1.112-1.807-1.807
      s-2.085-1.112-3.059-1.112c-0.973,0-0.973,2.224-1.807,2.224s-1.807-0.973-2.224-1.807s-2.363-0.556-3.615-0.556
      c-1.251,0-1.251,1.251-2.363,1.529s-3.893-1.529-6.117-2.641s-3.893-0.834-5.422-0.973s-2.641-0.834-4.449-1.112
      s-5.422-0.834-7.229-0.834s-2.085,0.556-4.866,0.556s-2.92,0.695-3.476,1.946s-2.642,1.529-4.866,1.529
      c-0.604,0-1.081,0.062-1.461,0.152c-0.337,1.797,1.184,2.423,1.322,3.81c0.139,1.39,1.182,2.502,2.433,3.476
      c1.251,0.973,0.973,3.059,0.695,4.866c-0.278,1.807-1.877,2.641-3.615,3.476c-1.738,0.834-3.962,4.449-6.326,5.909
      c-2.363,1.46-1.946,3.406-1.738,5.7c0.209,2.294,0.487,2.016-0.626,2.294c-1.112,0.278-1.599,0.348-1.877,1.807
      c-0.278,1.46-1.043,1.946-2.502,3.823s0.417,2.989,1.182,3.684s2.989,1.738,4.31,2.155c0.453,0.143,0.766,0.371,0.96,0.636
      c0.774-0.267,1.783-0.493,2.794-0.358c2.085,0.278,3.476,1.529,5.144,1.529s5.839-0.834,7.09-0.139s2.085,4.171,3.476,2.781
      c1.39-1.39,2.363-2.363,3.337-2.363c0.973,0,2.224-0.417,3.337-0.417s2.92,0.417,3.754-1.112c0.834-1.529,2.502-1.668,3.893-2.224
      c1.39-0.556,3.754-0.695,5.839-1.529s4.588-1.39,5.422-0.278c0.834,1.112,1.668,2.92,3.754,2.92s4.449,0.278,5.144,1.112
      s2.363,2.085,3.059,4.032s3.059,2.781,3.059,4.449s0.278,4.588,1.807,5.144s2.224,1.251,1.946,2.781
      c-0.278,1.529-1.529,3.476-3.059,2.502s-4.032-2.224-4.866-1.668s-1.807,1.39-1.39,2.363s1.251,2.92-0.973,4.032
      s-2.781,2.502-2.781,3.337s-3.059,2.363-1.529,3.476c1.529,1.112,3.337,1.112,4.588,0.556c1.251-0.556,2.781-1.946,3.754-1.946
      s1.668-0.556,2.641,1.112c0.612,1.049,0.84,0.724,1.27,0.441c-0.255-0.927-0.678-1.785-0.965-2.55
      c-0.598-1.594,2.79-1.993,4.983-3.387s4.383-3.388,5.978-6.576c1.595-3.188,4.782-2.193,6.576-2.591
      c1.793-0.398,2.989-1.793,4.184-0.798c1.196,0.996-0.2,1.395-1.994,1.595c-1.793,0.2,0.201,1.793-0.796,2.789
      s0.997,1.993,2.59,1.993c1.592,0,1.594,0.796,3.189,1.394c1.595,0.598,2.789-0.598,4.582,0.399
      c1.793,0.997,2.193-0.598,3.788-1.196c1.594-0.598,3.586,1.196,2.788,2.391c-0.799,1.196-2.989,1.196-4.582,1.196
      c-1.592,0-2.193,0.996-4.385,2.59c-2.193,1.594-0.598,2.391,1.793,2.391c2.391,0,1.196,1.196,4.983,1.993
      c3.788,0.798,1.196,3.787,0.598,5.58c-0.598,1.793,1.394,2.989,3.386,2.789s4.185-0.996,5.182-2.789s1.394-1.793,3.986-1.793
      c2.592,0,2.788-0.2,3.785-1.395c0.997-1.196,3.986-0.798,6.576-0.798s3.785,0.399,4.782-2.789c0.998-3.189-0.198-2.789-1.195-2.789
      s-3.785,1.196-6.975,0.996s-4.383-1.594-5.38-3.587c-0.998-1.993-2.392-3.985-0.397-4.783c1.994-0.798,5.179-1.793,6.576-3.787
      c1.397-1.993,3.587-1.793,5.978-2.192c2.392-0.398,3.984,0.398,5.579-0.798s3.986-3.188,6.576-3.188
      c1.314,0,2.269-0.257,3.203-0.562c-0.12-0.965-0.239-2.911,0.639-3.479c1.182-0.765,1.39-1.807,2.92-2.363
      c1.529-0.556,1.668-2.433,4.24-2.433c2.572,0,6.812,0.904,7.16-0.626c0.348-1.529,1.112-1.46,1.46-2.572s-0.07-1.182-0.765-2.294
      s-0.765-1.599-1.39-2.294S619.726-83.098,621.047-83.307z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M532.001-61.41
      c-1.529-0.556-1.807-3.476-1.807-5.144s-2.363-2.502-3.059-4.449c-0.696-1.947-2.363-3.198-3.059-4.032
      c-0.695-0.834-3.059-1.112-5.144-1.112s-2.92-1.807-3.754-2.92c-0.834-1.112-3.337-0.556-5.422,0.278
      c-1.277,0.511-2.656,0.761-3.854,1.005c0.822,0.557,1.685,1.417,2.324,2.748c1.668,3.476,3.476,7.646,5.561,9.037
      c2.085,1.39,3.198,3.059,3.615,3.893s1.39,2.224,1.39,4.032s-0.417,3.059-1.112,3.893s-1.112,1.946-0.556,3.059
      c0.556,1.112,0,3.754,0.695,4.588c0.41,0.492,0.581,0.982,1.106,1.246c-0.01-0.989,1.953-2.106,1.953-2.775
      c0-0.834,0.556-2.224,2.781-3.337c2.224-1.112,1.39-3.059,0.973-4.032s0.556-1.807,1.39-2.363s3.337,0.695,4.866,1.668
      c1.529,0.973,2.781-0.973,3.059-2.502C534.225-60.159,533.53-60.854,532.001-61.41z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M531.603-44.425
      c-0.431,0.282-0.658,0.608-1.27-0.441c-0.973-1.668-1.668-1.112-2.641-1.112c-0.973,0-2.503,1.39-3.754,1.946
      c-1.251,0.556-3.059,0.556-4.588-0.556c-0.302-0.22-0.421-0.457-0.424-0.701c-0.525-0.264-0.696-0.754-1.106-1.246
      c-0.695-0.834-0.139-3.476-0.695-4.588s-0.139-2.224,0.556-3.059s1.112-2.085,1.112-3.893s-0.973-3.198-1.39-4.032
      s-1.529-2.502-3.615-3.893c-2.085-1.39-3.893-5.561-5.561-9.037c-0.639-1.332-1.502-2.191-2.324-2.748
      c-0.759,0.155-1.446,0.308-1.985,0.524c-1.39,0.556-3.059,0.695-3.893,2.224s-2.641,1.112-3.754,1.112s-2.363,0.417-3.337,0.417
      c-0.973,0-1.946,0.973-3.337,2.363c-1.39,1.39-2.224-2.085-3.476-2.781c-1.251-0.695-5.422,0.139-7.09,0.139
      s-3.059-1.251-5.144-1.529c-1.011-0.135-2.02,0.09-2.794,0.358c0.372,0.507,0.284,1.156-0.264,1.658
      c-0.834,0.765-2.781,1.112-4.24,1.668s-2.92,2.92-4.657,4.379c-1.738,1.46-3.128,6.256-4.379,9.176s-2.85,2.433-4.449,2.572
      s-1.738,1.39-3.545,1.321c-1.807-0.07-2.155,0.695-1.39,1.39c0.765,0.695,1.39,1.46,2.363,2.572s1.39,1.738,1.877,3.615
      s2.502,2.711,3.684,2.502c1.182-0.209,2.016,0.417,2.294,1.39s0.278,0.973-0.904,2.294s1.321,1.668,1.668,2.294
      s2.85,0.626,4.032,1.668c1.182,1.043,3.059-0.487,3.476-1.599c0.417-1.112,1.599,0.139,2.502,0.695
      c0.904,0.556,0.556,0.834,0,1.738s-0.069,1.321-0.069,2.711c0,0.666-0.049,0.948-0.214,1.124c0.133,0.165,0.314,0.31,0.562,0.36
      c0.695,0.139,1.391,0.649,1.483,1.344s0.092,2.827,2.734,2.178s3.43-0.834,4.217-0.232c0.787,0.603,2.131,1.02,3.846,1.159
      s2.92-0.834,3.939-0.278c1.019,0.556,3.337,0.649,4.078,0.649s2.317-0.371,2.734,0.139c0.417,0.51,1.575,1.205,2.549,0.324
      c0.973-0.881,3.058-1.669,3.8-2.873c0.742-1.205,3.105-1.807,4.31-1.807s3.012-1.205,4.542-1.158
      c1.529,0.046,2.41,0.324,4.217,1.205c1.807,0.881,3.198,0.371,3.615,1.159s0.928,1.668,2.132,1.761
      c1.078,0.083,3.23,0.608,5.232,0.785c-0.066-1.86-1.045-4.097-0.797-5.952c0.399-2.989,2.391-2.193,2.791-4.384
      c0.399-2.192,0.796-1.196,2.391-1.594s3.187,0,4.184-2.193C531.954-42.336,531.878-43.421,531.603-44.425z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M467.493-10.596
      c0.973,0.626,2.294,1.599,2.781,1.946c0.487,0.348,2.502,1.529,3.337,2.433c0.834,0.904,3.893,2.781,5.7,2.641
      c1.807-0.139,4.101-0.695,5.422-0.417c1.321,0.278,4.31,1.529,5.144,2.224s4.31,1.321,5.77,0.904s3.198-0.487,3.267-1.946
      c0.07-1.46,0.556-3.059,1.529-3.267s1.321,0.487,2.016-0.834c0.695-1.321,1.182-1.946,2.502-1.946c1.321,0,1.738,0.626,2.502-0.348
      c0.765-0.973,1.738-1.043,3.406,0.07c1.668,1.112,2.572,0.904,3.615,0.904c0.628,0,1.264-0.079,1.953-0.185
      c-0.577-1.331-1.011-2.695-1.342-3.684c-0.799-2.391,0.997-6.975,1.396-9.565c0.4-2.59,1.992-0.798,4.981-2.591
      c1.134-0.681,1.436-1.678,1.395-2.816c-2.002-0.177-4.154-0.702-5.232-0.785c-1.205-0.093-1.715-0.973-2.132-1.761
      s-1.807-0.278-3.615-1.159c-1.807-0.881-2.688-1.159-4.217-1.205c-1.529-0.046-3.337,1.158-4.542,1.158s-3.568,0.603-4.31,1.807
      c-0.742,1.205-2.827,1.993-3.8,2.873c-0.973,0.881-2.132,0.185-2.549-0.324s-1.993-0.139-2.734-0.139s-3.059-0.093-4.078-0.649
      s-2.224,0.417-3.939,0.278s-3.059-0.556-3.846-1.159c-0.787-0.603-1.576-0.417-4.217,0.232c-2.641,0.649-2.641-1.483-2.734-2.178
      c-0.092-0.695-0.788-1.205-1.483-1.344c-0.248-0.049-0.428-0.195-0.562-0.36c-0.179,0.191-0.494,0.255-1.037,0.545
      c-1.043,0.556-0.765,0.765-1.39,2.502c-0.626,1.738,0.278,1.807,0.834,2.155s0.973,1.043,1.112,2.085
      c0.139,1.043,2.433,1.668,3.128,2.92c0.695,1.251-0.417,1.599-1.043,2.641s-1.946,1.251-3.198,1.251
      c-1.251,0-1.599,0.417-0.904,1.251s0.556,1.529,0.556,2.711c0,0.563-0.258,0.899-0.695,1.073
      C466.471-11.965,466.888-10.985,467.493-10.596z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M456.51,3.029
      c2.363,0.348,4.657,0.278,4.935-0.487s0.765-1.738,2.085-1.738c1.321,0,5.492,0.417,6.117-0.348s1.321-0.973,0.973-2.433
      s-0.834-2.989-0.487-4.032c0.24-0.721,0.417-1.572,0.685-2.283c-0.239-0.154-0.434-0.279-0.546-0.359
      c-0.487-0.348-1.807-1.321-2.781-1.946c-0.605-0.389-1.022-1.369-1.251-2.055c-0.481,0.191-1.179,0.185-2.016,0.039
      c-1.599-0.278-2.502,0.487-3.615,0.487c-1.112,0-1.738,0.278-3.059,1.182c-1.321,0.904-2.363,1.043-4.657,1.043
      s-2.016,2.363-2.85,3.754c-0.834,1.39-0.139,3.823-0.139,5.909c0,1.916,2.752,2.776,3.848,3.765
      C454.361,3.173,455.285,2.848,456.51,3.029z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M431.809-96.676
      c-0.649-1.112-2.873-1.02-4.171-1.02s-2.224-0.649-2.595-1.761c-0.37-1.112-1.205-1.576-2.41-1.761
      c-1.205-0.185-1.483-0.185-1.853-0.649c-0.371-0.464-0.556-0.927-1.575-0.927s-1.112,0.741-0.649,1.483s-0.371,0.464-0.834,1.39
      c-0.463,0.927-1.668,0.093-2.966-0.556c-1.297-0.649-1.02-1.112-1.39-1.946c-0.37-0.834-1.112-1.39-1.854-2.132
      s-1.297-0.834-2.502-1.297c-1.205-0.463-2.502-0.742-3.337-0.742c-0.834,0-1.668-0.556-1.946-1.668s-1.019-0.927-2.039-1.02
      c-0.184-0.017-0.332-0.084-0.464-0.173c-0.337,0.666-0.911,1.432-1.76,1.378c-1.483-0.093-2.41-0.742-2.688-1.483
      c-0.278-0.741-1.668-1.019-1.761-0.463s-0.37,2.039-1.39,2.039s-1.576,0.185-2.317,0.927s-2.595,0.093-2.966,0.927
      s-1.206,1.112-2.132,1.112s-1.297,0.464-2.132,1.112c-0.834,0.649-2.132,1.019-2.688,1.019s-2.224-0.093-3.151,0.278
      s-0.001,1.112-1.483,1.02c-1.483-0.093-2.688,0.371-1.019,1.39c1.668,1.02,1.02,1.946,2.039,2.039s1.112,1.205,1.112,2.132
      s-0.37,2.502,0.278,3.151s2.502,2.224,3.244,2.966c0.741,0.742,1.669,1.391,2.595,1.854s1.946,1.112,2.688,1.575
      c0.741,0.464,0.834,1.205,1.854,1.576c0.821,0.298,2.12,1.317,2.79,2.622c0.074,0.007,0.149,0.01,0.223,0.02
      c1.599,0.209,2.294,1.321,3.198,0.973s2.294,0.278,3.267-0.695s1.599-1.39,1.877-2.572s0.834-2.016,2.224-1.877
      c1.39,0.139,2.363,0.556,2.781,1.251s1.946,0.904,2.781,1.043c0.834,0.139,0.834,1.182,2.642,1.182s2.224-0.626,2.781-1.182
      c0.556-0.556,0.973,0,1.182,0.765s1.321,0.487,2.224,0.556c0.904,0.07,1.529,0.278,2.155-0.487s0.626-2.085,1.112-2.085
      s1.39,0.07,1.529,0.695c0.139,0.626,0.834,0.626,1.738,0.556s1.39-0.07,1.877-0.904s0.695-1.321,1.46-1.46
      c0.765-0.139,1.321,0,1.738-0.904s0.626-2.294,1.321-2.711s1.112-1.39,2.155-1.39c0.762,0,1.377-0.963,2.086-1.102
      C434.024-94.745,432.393-95.675,431.809-96.676z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M359.325-62.292
      c-0.701,0.058-1.695,0-1.928-0.351c-0.234-0.351-1.052-0.351-2.338-0.935s-0.818-0.701-1.52-0.877
      c-0.701-0.175-0.701-0.468-1.052-1.227c-0.351-0.76,0.351-1.637,0.701-2.279c0.196-0.359,0.136-1.229,0.046-1.928
      c-0.007-0.001-0.014-0.001-0.021-0.002c-1.182-0.139-2.502,0.07-3.267-0.695c-0.765-0.765-1.877-0.278-2.572,0.07
      c-0.695,0.348-1.529-1.46-2.433-1.529c-0.904-0.07-0.556,1.043-0.695,2.572s-2.294-0.278-3.059-0.695
      c-0.765-0.417-1.599-0.209-3.545-0.348c-0.993-0.071-1.84,0.093-2.432,0.271c-0.078,0.401-0.233,0.763-0.533,1.003
      c-0.927,0.741-1.205,0.556-2.595,0.371s-1.668,0.185-1.668,0.834s-0.093,1.854-1.112,2.224c-1.019,0.371-3.244,1.947-3.8,3.244
      s-1.853,2.78-2.502,3.522c-0.649,0.742-1.112,1.298-1.298,2.595c-0.185,1.297-1.761,2.873-0.371,2.688
      c1.39-0.185,2.132-1.019,2.688-2.132c0.556-1.112,1.205-1.205,2.317-1.39s1.853,0.464,1.853,1.112c0,0.649-0.185,1.668,0.649,2.039
      c0.645,0.287,1.73,1.128,1.717,1.924c0.527,0.041,1.083,0.105,1.527,0.208c1.205,0.278,2.781-0.37,3.8,0.093
      c1.019,0.463,2.039,0.37,2.781-0.927c0.741-1.297,0.926-1.483,1.575-2.41c0.649-0.927,1.391-2.502,2.317-1.02
      c0.927,1.483,1.576,1.761,2.224,2.224c0.649,0.464,1.297,0.927,1.575,1.668c0.278,0.742,1.483,2.41,1.668,0.834
      s0.093-2.41,0.742-2.688c0.649-0.278,0.927-0.37,1.02-1.297s-0.649-2.317,0.927-2.132s1.019,0.927,1.761,1.02
      c0.742,0.093,0.185,1.39,2.502,0.741c2.317-0.649,2.873-1.112,3.244-2.224s2.317-0.185,2.41-1.297
      c0.059-0.708,0.158-1.828,0.723-2.478C360.669-62.28,359.822-62.333,359.325-62.292z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M419.575-75.637
      c-0.926-1.205-1.761-2.039-1.761-2.781c0-0.656,0.218-2.62,0.268-4.348c-0.178-0.017-0.366-0.039-0.569-0.055
      c-0.904-0.07-2.016,0.209-2.224-0.556c-0.209-0.765-0.626-1.321-1.182-0.765s-0.973,1.182-2.781,1.182s-1.807-1.043-2.642-1.182
      c-0.834-0.139-2.363-0.348-2.781-1.043s-1.39-1.112-2.781-1.251c-1.39-0.139-1.946,0.695-2.224,1.877
      c-0.278,1.182-0.904,1.599-1.877,2.572c-0.973,0.973-2.363,0.348-3.267,0.695c-0.904,0.348-1.599-0.765-3.198-0.973
      c-1.599-0.209-3.267,0.139-4.31,0.209s-1.321,0.834-1.599,2.363c-0.278,1.529-1.251,1.599-2.711,1.599s-1.807,0.765-2.155,2.085
      s-0.07,1.251,0.765,1.946s0.348,1.46,1.182,2.085c0.834,0.626-0.07,0.834-0.626,1.946s-1.946-0.209-2.641-0.626
      s-2.711-1.807-2.85-0.209c-0.139,1.599-0.626,1.112-2.155,0.695s-2.433-0.626-3.476-0.626s-2.016,0.834-2.363,1.599
      c-0.348,0.765-1.112,0.973-2.016,1.251s-2.155-0.765-2.155-0.765s-0.765-0.556-1.599-1.182c-0.834-0.626-1.39,0-2.155,0.139
      c-0.765,0.139-1.182,0.139-1.529,1.043s-0.626,1.251-1.39,1.321c-0.765,0.07-1.112-0.556-1.668-1.46
      c-0.553-0.898-2.686-0.904-3.872-1.041c0.091,0.698,0.15,1.568-0.046,1.928c-0.351,0.643-1.052,1.52-0.701,2.279
      c0.351,0.76,0.351,1.052,1.052,1.227s0.234,0.292,1.52,0.877c1.286,0.584,2.104,0.585,2.338,0.935
      c0.234,0.351,1.227,0.409,1.928,0.351c0.497-0.041,1.344,0.012,2.026,0.397c0.322-0.37,0.795-0.589,1.502-0.488
      c1.946,0.278,1.483,1.39,3.244,0.278s-0.278-1.298,3.244-1.39c3.522-0.093,4.912-0.278,5.561-0.556
      c0.649-0.278,1.668-0.093,1.668,0.927s0.371,2.039,1.205,2.781c0.834,0.742,3.059,0.371,4.356,1.205s4.449,0,6.21,1.019
      c1.761,1.02,2.781,0.556,3.893,0.556s2.224-0.463,3.522,0.185c1.297,0.649,2.41,0.464,3.059-0.371
      c0.649-0.834,1.483-1.946,2.966-1.946s2.966,0.556,4.357-0.185s3.8,0.556,4.449-0.927c0.648-1.483,0.741-2.317,1.761-2.595
      s1.761,0.093,1.946-1.668c0.186-1.761-0.185-3.059,0.927-3.337s0.927-0.741,0.556-1.853s-0.742-1.39,1.019-1.39
      c1.761,0,2.502-0.371,2.502-1.298C419.297-73.879,420.501-74.432,419.575-75.637z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M413.412-58.421
      c0.834,0.348,1.251,0.07,1.946,0.834c0.695,0.765,2.016,0.626,2.155,1.251c0.139,0.626,0.209,1.946,1.182,2.016
      s1.946,0.278,2.363,0.904s1.043,1.321,1.877,1.529c0.834,0.209,1.46,1.529,1.946,1.807c0.487,0.278,1.251,0.556,2.781,0.556
      c1.529,0,3.684,0.209,4.518-0.487s1.529-1.807,2.85-1.807s4.588,0.139,5.005-0.556s1.112-1.807,2.224-1.807s2.572-0.07,3.545,0
      c0.689,0.049,1.445,0.136,2.125,0.378c0.233-0.251,0.761-0.411,1.629-0.378c1.807,0.07,1.946-1.182,3.545-1.321
      s3.198,0.348,4.449-2.572s2.642-7.716,4.379-9.176c1.738-1.46,3.198-3.823,4.657-4.379s3.406-0.904,4.24-1.668
      c0.834-0.765,0.626-1.877-0.695-2.294s-3.545-1.46-4.31-2.155c-0.331-0.301-0.869-0.68-1.256-1.165
      c-0.689,0.165-1.417,0.401-2.266,0.401c-1.297,0-2.502-0.742-2.595-1.39c-0.093-0.649-1.668-1.205-2.966-1.205
      s-1.483-0.278-2.873-0.834c-1.39-0.556-1.575-0.185-2.966,0c-1.39,0.185-1.483,2.224-2.224,3.522
      c-0.741,1.297-2.502,2.317-3.337,2.317s-1.668-0.093-2.317-0.927s-1.946-0.093-2.224,0.556c-0.278,0.649-1.39,0.278-2.132,0.834
      c-0.741,0.556-1.483,0.556-2.41,0.556s-1.297,1.39-1.39,2.132c-0.093,0.742-1.853,0-2.595,0.927
      c-0.741,0.927-3.893,0.649-5.654,0.649s-1.297-1.112-2.224-1.205s-1.39-1.297-2.873-2.039c-0.661-0.331-1.211-0.384-1.618-0.343
      c0.145,0.775-0.606,1.277-0.606,2.011c0,0.927-0.741,1.298-2.502,1.298c-1.761,0-1.39,0.278-1.019,1.39s0.556,1.575-0.556,1.853
      s-0.741,1.576-0.927,3.337c-0.17,1.614-0.808,1.438-1.698,1.612c0.093,0.581,0.192,1.349,0.192,1.98
      C412.786-60.298,412.577-58.768,413.412-58.421z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M471.525-21.579
      c-0.695-1.251-2.989-1.877-3.128-2.92c-0.139-1.043-0.556-1.738-1.112-2.085c-0.556-0.348-1.46-0.417-0.834-2.155
      c0.626-1.738,0.348-1.946,1.39-2.502c1.043-0.556,1.251-0.278,1.251-1.668s-0.487-1.807,0.069-2.711s0.904-1.182,0-1.738
      s-2.085-1.807-2.502-0.695c-0.417,1.112-2.294,2.641-3.476,1.599c-1.182-1.043-3.684-1.043-4.032-1.668s-2.85-0.973-1.668-2.294
      s1.182-1.321,0.904-2.294s-1.112-1.599-2.294-1.39s-3.198-0.626-3.684-2.502c-0.487-1.877-0.904-2.502-1.877-3.615
      c-0.973-1.113-1.599-1.877-2.363-2.572c-0.398-0.361-0.491-0.74-0.239-1.012c-0.68-0.242-1.436-0.329-2.125-0.378
      c-0.973-0.07-2.433,0-3.545,0s-1.807,1.112-2.224,1.807s-3.684,0.556-5.005,0.556c-0.483,0-0.881,0.151-1.234,0.373
      c0.151,0.441,0.401,0.869,0.817,1.017c0.973,0.348,1.112,1.46,1.112,1.877s0.209,1.946,0.904,2.224s0.626,1.043,0.626,1.46
      s0.348,1.529,0.834,1.877c0.487,0.348-1.112,1.946-0.278,2.433s1.877,1.877,1.529,2.711c-0.348,0.834-2.155,2.155-2.224,3.198
      c-0.07,1.043,0.973,1.738,1.946,2.016c0.973,0.278,2.224,0.626,1.877,1.46s-2.711,0.556-2.433,1.46
      c0.278,0.904,0.904,0.278,1.321,1.182c0.417,0.904,1.182,1.46,0.626,2.155s-1.182,1.39-0.973,2.016s0.139,1.043,1.668,1.946
      c1.529,0.904,2.85,1.251,4.171,2.016c1.321,0.765,3.198,1.321,2.294,2.711s-2.294,2.85-0.695,3.754s2.224,1.599,3.059,2.641
      c0.47,0.588,0.985,1.398,1.382,2.016c0.329-0.379,0.798-0.626,1.537-0.626c2.294,0,3.337-0.139,4.657-1.043
      c1.321-0.904,1.946-1.182,3.059-1.182c1.112,0,2.016-0.765,3.615-0.487s2.711,0.07,2.711-1.112s0.139-1.877-0.556-2.711
      c-0.695-0.834-0.348-1.251,0.904-1.251c1.251,0,2.572-0.209,3.198-1.251S472.22-20.328,471.525-21.579z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M454.007,3.793
      c-0.834-1.112-4.101-1.946-4.101-4.032s-0.695-4.518,0.139-5.909c0.565-0.942,0.622-2.33,1.313-3.128
      c-0.397-0.619-0.912-1.428-1.382-2.016c-0.834-1.043-1.46-1.738-3.059-2.641c-0.501-0.283-0.708-0.621-0.729-0.994
      c-0.585,0.146-1.13,0.325-1.565,0.369c-0.695,0.07-1.112-0.278-2.155-0.695s-1.043,1.321-1.946,2.016
      c-0.904,0.695-0.904,1.668-0.765,2.92c0.064,0.572-0.12,1.331-0.338,1.988c0.677,0.693,1.277,1.266,1.748,1.609
      c2.192,1.595,0,5.779,0,9.963s-1.995,5.181,0.597,6.376c2.592,1.196,1.794,2.193,1.394,3.787c-0.213,0.85-0.421,1.7-0.301,2.55
      c0.591-0.237,1.504-0.514,2.67-0.552c2.085-0.07,3.337-0.556,3.823-1.946c0.487-1.39,1.112-3.059,2.363-4.101
      c1.251-1.043,1.529-2.085,2.016-2.572S454.841,4.906,454.007,3.793z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M431.276-16.644
      c0.626-0.417,1.39-0.695,0.695-1.46s-1.599-1.877-0.626-2.016s1.668-0.07,2.016-1.182s0.069-1.599,1.112-1.599
      s1.46,0.487,1.529-0.556s-0.139-1.668,1.043-1.807c1.032-0.121,1.375-0.772,2.833-0.38c0.174-0.242,0.377-0.487,0.573-0.732
      c0.556-0.695-0.209-1.251-0.626-2.155s-1.043-0.278-1.321-1.182s2.085-0.626,2.433-1.46c0.348-0.834-0.904-1.182-1.877-1.46
      c-0.973-0.278-2.016-0.973-1.946-2.016c0.07-1.043,1.877-2.363,2.224-3.198c0.348-0.834-0.695-2.224-1.529-2.711
      c-0.173-0.101-0.238-0.252-0.242-0.428c-0.282-0.059-0.576-0.128-1.218-0.128c-1.112,0-2.85-0.556-3.615-0.973
      c-0.765-0.417-2.92-0.487-5.074-0.07c-2.155,0.417-2.363,0.07-4.24-0.765c-1.877-0.834-2.92-0.139-4.379-0.139
      s-1.46-0.139-2.224-0.626s-1.182,0.139-2.016,0.348c-0.834,0.209-0.765,1.807-1.877,1.738c-1.112-0.07-1.529-0.487-2.224-1.182
      c-0.425-0.425-1.652-0.304-2.544-0.146c-0.329,0.715-0.893,1.496-0.723,2.232c0.209,0.904,0.556,2.085,1.738,2.781
      s1.39,1.321,1.738,2.294s1.599,1.877,1.738,3.198s0.695,2.155,2.642,2.781c1.946,0.626,2.711,2.572,3.267,3.684
      s1.599,1.46,2.781,2.294s1.599,1.529,2.155,2.572s2.711,1.599,4.588,2.781c0.987,0.621,1.452,1.606,1.674,2.402
      c0.398,0.11,0.802,0.209,1.205,0.307C430.967-16.07,431.046-16.49,431.276-16.644z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M445.318-20.397
      c-1.321-0.765-2.642-1.112-4.171-2.016s-1.46-1.321-1.668-1.946c-0.135-0.405,0.081-0.84,0.4-1.284
      c-1.458-0.392-1.801,0.259-2.833,0.38c-1.182,0.139-0.973,0.765-1.043,1.807s-0.487,0.556-1.529,0.556s-0.765,0.487-1.112,1.599
      c-0.348,1.112-1.043,1.043-2.016,1.182s-0.07,1.251,0.626,2.016c0.695,0.765-0.069,1.043-0.695,1.46
      c-0.231,0.154-0.31,0.573-0.318,1.041c0.148,0.036,0.293,0.078,0.443,0.113c2.034,0.47,5.543,4.625,8.018,7.16
      c0.218-0.657,0.401-1.416,0.338-1.988c-0.139-1.251-0.139-2.224,0.765-2.92c0.904-0.695,0.904-2.433,1.946-2.016
      c1.043,0.417,1.46,0.765,2.155,0.695c0.436-0.043,0.98-0.223,1.565-0.369c-0.046-0.818,0.804-1.805,1.424-2.759
      C448.516-19.077,446.639-19.633,445.318-20.397z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M438.089-42.989
      c-0.487-0.348-0.834-1.46-0.834-1.877s0.069-1.182-0.626-1.46s-0.904-1.807-0.904-2.224s-0.139-1.529-1.112-1.877
      c-0.416-0.149-0.666-0.576-0.817-1.017c-0.613,0.386-1.087,0.993-1.616,1.434c-0.834,0.695-2.989,0.487-4.518,0.487
      s-2.294-0.278-2.781-0.556s-1.112-1.599-1.946-1.807c-0.834-0.209-1.46-0.904-1.877-1.529s-1.39-0.834-2.363-0.904
      s-1.043-1.39-1.182-2.016s-1.46-0.487-2.155-1.251c-0.695-0.765-1.112-0.487-1.946-0.834c-0.178-0.074-0.301-0.211-0.396-0.378
      c-0.828,0.707-1.48,1.855-1.897,2.533c-0.556,0.904-0.765,0.904-2.224,1.043c-1.459,0.139-1.738,0.348-2.363,0.973
      c-0.626,0.626-0.626,1.043,0.348,1.668c0.973,0.626,0.417,0.695,0.139,1.46c-0.278,0.765-1.807,1.321-3.128,1.807
      c-0.843,0.31-1.119,1.665-1.208,2.607c-0.709,0.326-1.463,0.558-2.059,0.243c-1.182-0.626-1.946-1.668-2.92-1.738
      c-0.973-0.07-1.321,0.139-1.877,0.834s-2.155,1.112-3.198,0.765c-0.875-0.292-2.683-0.189-4.597,0.097
      c0.178,0.864,0.762,1.914,1.126,2.521c0.598,0.996,0.796,3.587,2.19,4.185s0.997-0.398,1.794-1.594
      c0.796-1.196,1.595,0.796,1.793,2.989c0.199,2.193,1.994,2.989,1.994,1.793s-0.2-4.982,0.598-4.982
      c0.799,0,2.788,1.594,2.989,3.587c0.201,1.993,2.191,2.591,2.391,5.778c0.201,3.188,0.598,2.591,1.794,2.591
      c1.195,0,3.386,0.996,3.587,2.192c0.2,1.196,3.386,1.793,6.375,1.793s-2.789,3.189-0.397,3.986
      c2.391,0.798,2.788-1.595,3.187-0.997c0.399,0.598,0,0.798-0.997,1.595c-0.997,0.798,0.799,1.793,2.391,1.394
      c1.593-0.399,1.994,0,4.783,1.395c2.189,1.095,4.135,1.819,6.124,2.37c-0.222-0.796-0.688-1.781-1.674-2.402
      c-1.877-1.182-4.032-1.738-4.588-2.781s-0.973-1.738-2.155-2.572s-2.224-1.182-2.781-2.294c-0.556-1.112-1.321-3.059-3.267-3.684
      c-1.946-0.626-2.502-1.46-2.642-2.781c-0.14-1.321-1.39-2.224-1.738-3.198s-0.556-1.599-1.738-2.294s-1.529-1.877-1.738-2.781
      c-0.17-0.736,0.394-1.517,0.723-2.232l0,0c0.892-0.158,2.12-0.278,2.544,0.146c0.695,0.695,1.112,1.112,2.224,1.182
      s1.043-1.529,1.877-1.738s1.251-0.834,2.016-0.348s0.765,0.626,2.224,0.626s2.502-0.695,4.379,0.139
      c1.877,0.834,2.085,1.182,4.24,0.765s4.31-0.348,5.074,0.07c0.765,0.417,2.502,0.973,3.615,0.973c0.642,0,0.936,0.069,1.218,0.128
      C437.555-41.655,438.474-42.714,438.089-42.989z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M386.599-49.748
      c0.18-0.009,0.362-0.016,0.564-0.016c1.992,0,2.192,1.394,1.195,1.992c-0.389,0.234-0.441,0.712-0.327,1.266
      c1.914-0.286,3.721-0.389,4.597-0.097c1.043,0.348,2.641-0.07,3.198-0.765c0.556-0.695,0.904-0.904,1.877-0.834
      c0.973,0.07,1.738,1.112,2.92,1.738c0.596,0.315,1.35,0.083,2.059-0.242c0.09-0.942,0.366-2.297,1.208-2.608
      c1.321-0.487,2.85-1.043,3.128-1.807c0.278-0.765,0.834-0.834-0.139-1.46c-0.973-0.626-0.973-1.043-0.348-1.668
      c0.626-0.626,0.904-0.834,2.363-0.973s1.668-0.139,2.224-1.043c0.417-0.678,1.069-1.826,1.897-2.533
      c-0.35-0.615-0.229-1.751-0.229-2.681c0-0.631-0.099-1.399-0.192-1.98c-0.081,0.016-0.163,0.033-0.249,0.057
      c-1.02,0.278-1.112,1.112-1.761,2.595c-0.648,1.483-3.059,0.185-4.449,0.927s-2.873,0.185-4.357,0.185
      c-1.483,0-2.317,1.112-2.966,1.946c-0.649,0.834-1.761,1.019-3.059,0.371c-1.297-0.649-2.41-0.185-3.522-0.185
      s-2.132,0.464-3.893-0.556c-0.127-0.073-0.262-0.135-0.402-0.19c-0.034,0.92-0.061,1.865-0.061,2.508
      C387.877-54.345,386.462-52.038,386.599-49.748z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M559.462,60.989c0,0-2.2-0.223-2.678,0.351
      c-0.478,0.574-1.657,0.159-2.104,0.988c-0.446,0.829-0.542,1.658,0.096,2.518c0.638,0.861,2.232,2.136,2.997,2.072
      s1.18-0.351,2.008,0.383c0.829,0.733,1.593,1.147,1.785,0.191c0.192-0.956,1.148-0.765,2.519-0.765s1.083-2.518,3.06-2.518
      s2.678,0.638,2.869-0.223c0.191-0.861-0.573-1.881-0.287-2.486c0.287-0.606,0.51-1.403,1.371-1.626
      c0.861-0.223,1.434-1.02,2.295-1.211c0.861-0.191,0.829-0.319,1.498-0.956s1.913-0.797,0.765-1.243s-1.562-0.478-2.327,0.032
      c-0.765,0.51-1.435,0.861-2.231,1.052c-0.797,0.191-0.223,0.924-1.881,1.052c-1.658,0.128-3.38,1.434-5.165,1.116
      c-1.785-0.319-3.251-0.51-3.57,0.191C560.163,60.607,560.514,60.957,559.462,60.989z" pointerEvents="none" className="landarea"></path>
   <g data-colors="0">
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M661.295,14.707
         c-1.182-0.348-2.92-1.946-3.823-2.641c-0.904-0.695-1.112-4.588-0.973-6.256s-1.39-1.738-2.155-3.476s-1.46-2.433-2.85-2.711
         s-1.807-1.599-2.294-2.711s-3.615-0.626-4.449,0.139s-4.866,0.278-6.743,0.278c-0.92,0-1.654-0.484-2.166-0.978
         c-1.115,0.816-2.45,1.688-3.577,2.507c-2.193,1.594-4.981,1.196-7.772,2.391c-2.79,1.196-4.782,0.2-6.375-0.796
         c-1.592-0.996-2.592-0.2-3.388,0.796s-2.391,0.399-5.182,0.798c-2.79,0.398-4.581-0.398-5.38-1.594
         c-0.798-1.196-2.59,0.198-4.581,0.398c-1.992,0.2-2.392,0-3.389-1.793s-3.187-0.996-4.584-0.598
         c-1.396,0.398-2.391-0.598-2.589-2.391c-0.199-1.793-3.188-2.192-5.78-2.192c-2.592,0-2.788-0.399-3.785-2.193
         s-9.166-0.996-13.152-0.996s-2.79,1.196-6.176,1.196s-3.587,1.594-7.772,3.587c-4.184,1.993-4.184,2.591-6.179,4.185
         s-5.978,0.398-7.571-0.598c-1.593-0.996-3.19,1.594-5.38,0.398c-2.191-1.196-2.592,0-4.983,0.399
         c-2.392,0.399-2.191,3.587-1.794,6.177s-2.59,1.595-4.184,2.591c-1.595,0.996-5.778-0.398-7.373-0.996
         c-1.594-0.598-1.394,0.198-2.989,0.598c-1.594,0.399-2.989-0.2-5.38-0.399c-2.391-0.2-4.184,0.598-5.181,2.591
         c-0.998,1.993-2.392,4.185-1.794,6.376c0.598,2.192-0.397,1.993-1.394,3.587s-0.198,0.997,2.59,2.193s2.592,0.198,3.19-0.598
         c0.597-0.796-1.595-2.391-1.595-3.587s2.192-1.793,3.388-1.196c1.196,0.598-0.399,1.594-0.598,2.989
         c-0.198,1.395,0.397,2.192,1.394,2.789c0.997,0.598-0.198,0.996-1.394,1.395c-1.195,0.399,0,1.196,1.196,1.793
         c1.195,0.598,0.397,2.391-0.598,2.391s-1.595-0.399-1.595-1.196c0-0.796-0.598-1.595-1.595-1.993s-0.796,1.196-0.397,2.591
         s-1.793,1.196-2.193,0c-0.399-1.196-1.195-1.793-2.391-1.196c-1.195,0.598,0.598,1.594,0.797,2.989
         c0.198,1.395,1.594,1.594,2.79,0.996s2.391,1.395,2.989,0.996c0.598-0.399,0.796-0.198,2.791,0.399
         c1.994,0.598,2.589,1.394,2.589,1.992s-1.793,1.196-2.79,0.598s-1.593,0-1.992,0.997c-0.399,0.997,1.195,0.996,2.391,0.996
         c1.196,0,1.394,0.797,1.196,2.391c-0.199,1.594,2.989,0,3.586,1.196c0.598,1.196-0.997,0.798-1.991,1.196
         c-0.995,0.398-1.794,3.587-0.799,2.989s2.79-1.395,3.986-1.594c1.196-0.198,3.986,0.398,4.383,1.394
         c0.397,0.996-3.984,0.598-5.179,0.598c-1.196,0-1.595,0.798-2.391,1.993c-0.797,1.196,2.989,0.598,3.983-0.598
         c0.995-1.196,2.392-0.598,2.592,0.2c0.201,0.798,2.392,0.398,2.789-0.598s2.592-0.2,4.385,0.198s1.992,1.993,2.391,3.388
         s1.394,0.996,3.785,1.793c2.392,0.798,6.576,0.598,7.97-0.798c1.394-1.395,1.995-3.189,1.995-4.583s1.793-1.196,4.383-1.196
         c2.589,0,3.785,1.594,6.176,2.391c2.391,0.798,5.978,4.981,6.777,5.779c0.798,0.798,5.578-0.798,9.962-1.993
         c4.383-1.196,4.583-2.989,5.779-4.583s5.182,0,7.372,1.196c2.191,1.196,3.587,0.2,4.983-1.594
         c1.397-1.793,2.789-1.993,2.789-0.598s0,1.992-1.395,3.188c-1.394,1.196-0.198,3.389-0.597,4.982
         c-0.085,0.339-0.225,0.642-0.38,0.932c0.272,0.14,0.508,0.272,0.658,0.379c0.648,0.464,3.059,0.742,3.244-0.741
         s0.093-2.41,1.483-2.688c1.39-0.278,2.317-0.371,1.668-1.298c-0.649-0.927-1.02-1.853-0.649-2.966
         c0.371-1.113,1.853-2.224,3.244-1.112c1.39,1.112,1.112,1.946,3.059,1.761c1.946-0.185,1.761-1.39,2.873-1.39
         s2.317-1.02,3.708-1.112c1.39-0.093,3.985-0.834,5.097,0.649s3.244,1.297,5.098,1.112s6.025-0.185,7.044-0.834
         s2.78-1.483,4.263-2.317s3.337-2.688,4.82-1.668c1.483,1.02,2.503,1.112,3.615,1.112s2.503-0.742,3.708-1.298
         s2.224-0.371,2.781-0.927c0.502-0.502,1.159-0.468,2.918,1.793c0.859-0.54,1.569-0.967,1.855-1.376
         c0.649-0.927,0.742-1.993,2.595-2.039c1.854-0.046,3.476,0.185,3.939,0.788s1.807,0.834,2.92,0.788
         c1.112-0.046,2.78-0.834,3.383,0.093c0.603,0.927,1.715,3.012,2.595,2.271c0.88-0.742,2.271-0.881,2.456-1.344
         c0.098-0.247,0.473-0.939,0.802-1.534c-0.139-0.155-0.292-0.308-0.477-0.459c-1.019-0.834-1.297-0.741-1.39-2.132
         c-0.093-1.39-3.059-2.132-2.781-3.708c0.278-1.575,2.688-2.132,1.668-3.893c-1.019-1.761-1.205-2.132-1.205-3.615
         s0.001-1.205-0.926-3.059s-2.41-4.449-0.186-4.727s2.132-0.926,2.502-2.132c0.194-0.629,0.566-1.101,1.035-1.334
         C662.675,15.023,662.079,14.938,661.295,14.707z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M519.877-4.132
         c-1.52-0.585-2.624-2.401-3.44-4.286c-0.689,0.106-1.325,0.185-1.953,0.185c-1.043,0-1.946,0.209-3.615-0.904
         c-1.668-1.112-2.642-1.043-3.406-0.07c-0.765,0.973-1.182,0.348-2.502,0.348c-1.321,0-1.807,0.626-2.502,1.946
         c-0.054,0.102-0.105,0.189-0.155,0.268c0.232,0.528,0.499,1.039,0.711,1.192c0.487,0.348,0.973,0.834,1.112,1.46
         s-0.139,2.224-1.321,2.363s-1.321,0.904-1.321,1.39s0.765,2.016,0,2.641c-0.592,0.484-1.432,0.805-1.685,1.634
         c1.156,0.591,2.193,1.284,3.338,1.596c2.19,0.598,4.385,1.196,5.38-0.598c0.995-1.793,4.185-1.594,6.777-3.387
         s4.383,0,7.173,0.398c2.791,0.398,2.789-0.398,3.786-2.192C527.252-1.939,522.469-3.135,519.877-4.132z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M516.847,48.884
         c0,0,0.093,0.834-0.695,1.251s-1.9,0.648-1.854,1.436c0.046,0.788-0.139,1.159-0.51,1.761c-0.371,0.602-0.557,0.881,0.463,0.834
         s1.159-1.437,2.132-1.437s1.066-0.556,1.251-1.344c0.185-0.788,0.926-0.741,0.926-1.668
         C518.561,48.791,516.986,47.448,516.847,48.884z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M497.337,9.354c0,0-1.205-0.51-1.205,0.417
         s-0.139,1.807,0.556,1.761s0.788-0.185,0.834-0.741c0.046-0.556,1.019-0.093,0.973-0.834
         C498.449,9.215,497.94,9.354,497.337,9.354z" pointerEvents="none" className="landarea"></path>
   </g>
   <g data-colors="0">
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M504.126-3.992
         c-0.139-0.626-0.626-1.112-1.112-1.46c-0.213-0.152-0.479-0.664-0.711-1.192c-0.597,0.946-0.963,0.374-1.861,0.566
         c-0.973,0.209-1.46,1.807-1.529,3.267s-1.807,1.529-3.267,1.946s-4.935-0.209-5.77-0.904s-3.823-1.946-5.144-2.224
         s-3.615,0.278-5.422,0.417s-4.866-1.738-5.7-2.641c-0.643-0.696-1.985-1.556-2.791-2.074c-0.268,0.711-0.445,1.561-0.685,2.283
         c-0.348,1.043,0.139,2.572,0.487,4.032s-0.348,1.668-0.973,2.433s-4.796,0.348-6.117,0.348s-1.807,0.973-2.085,1.738
         s-2.572,0.834-4.935,0.487c-1.225-0.18-2.148,0.144-2.756,0.498c0.097,0.088,0.186,0.176,0.254,0.267
         c0.834,1.112,0.209,2.502-0.278,2.989s-0.765,1.529-2.016,2.572c-1.251,1.043-1.877,2.711-2.363,4.101
         c-0.487,1.39-1.738,1.877-3.823,1.946c-1.165,0.039-2.079,0.315-2.67,0.552c0.105,0.744,0.462,1.489,1.299,2.233
         c1.793,1.594,2.989-1.196,4.782,1.594c1.793,2.789,2.59,2.193,4.185,6.576c1.594,4.383,2.589,3.189,4.383,3.189
         c1.793,0,0.399,1.594-0.997,2.391c-1.397,0.798,0.399,4.384,2.391,5.181c1.992,0.796,0.598,3.986,0.598,5.78
         c0,1.793,2.192,2.391,3.787,1.594c1.595-0.798,1.992,0,2.788,1.395c0.797,1.395,2.392,0.198,3.788,0s1.593,2.192,2.19,3.188
         c0.598,0.996,0.997-1.992,0.997-3.785s0-2.391-0.796-3.388s-1.396-1.594-1.396-3.587s1.994,0.798,4.184,0.2
         c2.191-0.598,0.201-2.192-0.397-2.789c-0.597-0.598-1.595-1.993,1.196-3.189c2.79-1.196,0.796,1.594,3.984,3.387
         c3.187,1.793,2.192-1.394,1.595-2.989c-0.598-1.595-2.59-1.594,0.198-1.394s2.391,1.992,4.783,1.394
         c2.391-0.598-1.794-2.59-1.794-3.785c0-1.196-1.793-3.189-2.59-3.587c-0.796-0.398-2.79-1.196-4.782-1.993
         c-1.992-0.798-1.793-3.587-3.187-4.982c-1.395-1.395-3.389-5.38-4.584-5.978c-1.196-0.598-1.394-2.59-0.4-2.789
         c0.995-0.2,0.598-1.594,1.595-2.789c0.997-1.196,1.992,1.793,2.59,1.992s1.595,2.989,3.587,3.388s0-0.798,0-2.591
         s2.192,0.797,3.19,2.391c0.997,1.594,1.991,0,0.796-1.594c-1.196-1.594-0.598-2.193,1.595-0.598
         c2.192,1.595,1.793-0.2,1.592-0.997c-0.2-0.798-2.589-1.594-4.184-2.391s0.399-1.793,2.192-1.793c1.794,0,1.395-1.594,2.791-2.391
         c1.396-0.798,1.394,1.993,2.391,3.189c0.997,1.196,1.196-1.993,1.394-3.189c0.199-1.196,4.584,0.598,7.573,0.598
         c1.427,0,2.58,0.455,3.635,0.995c0.253-0.829,1.093-1.149,1.685-1.634c0.765-0.626,0-2.155,0-2.641s0.139-1.251,1.321-1.39
         C503.987-1.768,504.265-3.367,504.126-3.992z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M508.691,55.789
         c0,0,0.278,0.881-0.278,1.298c-0.556,0.417-1.066,1.205-0.556,1.39s1.761,0.324,1.761-0.649
         C509.618,56.855,509.432,54.399,508.691,55.789z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M476.807,57.226
         c0,0-0.742,3.336,0.185,3.383c0.927,0.046,1.946-0.232,2.781,0s1.854,0.046,2.271,0.742c0.417,0.695,0.787,0.695,1.853,0.695
         s2.641-0.046,3.383,0.881s1.019,1.483,2.502,1.529s2.873,0.788,3.29-0.139c0.417-0.927,0.602-1.112,1.668-1.066
         c1.066,0.046,1.252,0.371,1.9,0.649c0.648,0.278,0.463-0.973,2.317-0.695s1.9,0.046,2.039-0.417
         c0.139-0.464,1.112-0.556,1.019-1.344c-0.092-0.788-0.648-1.205-1.575-0.834c-0.927,0.371-1.808,0.649-2.595,0.278
         s-1.622-1.344-2.734-1.297c-1.112,0.046-3.43,0.603-3.939-0.139s-0.417-0.788-1.714-0.881c-1.297-0.093-2.873-0.602-3.337,0.046
         c-0.463,0.649-1.807,1.205-2.363,0.602c-0.556-0.602-0.186-1.853-1.761-2.039c-1.575-0.185-2.456,0.185-3.337-0.278
         C477.781,56.438,476.947,56.623,476.807,57.226z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M491.451,35.723
         c0,0-1.112-0.324-1.529-1.066s-0.649-1.019-1.251-1.066c-0.602-0.046-1.205,1.344-0.37,1.761c0.834,0.417,0.741,0.324,1.158,0.927
         s0.788,0.927,1.39,0.927S492.147,36.464,491.451,35.723z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M485.103,45.223
         c0,0,0.324,0.834-0.325,0.834s-0.51,0.834,0,0.834s1.066,0,1.066-0.649S485.428,44.389,485.103,45.223z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M493.491,41.516
         c0,0-1.993-0.325-1.946,0.602c0.046,0.927-0.185,1.251,0.649,1.298c0.834,0.046,1.39,0.093,1.622,0.51s1.112,0.185,1.205-0.371
         s0.279-1.808-0.046-2.132c-0.325-0.324-0.278-0.973-0.834-0.927C493.583,40.543,494.325,41.562,493.491,41.516z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M498.774,36.835c0,0-0.834,0.51,0,0.881
         s0.974,0.649,1.066,0.093C499.932,37.252,499.469,36.557,498.774,36.835z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M497.615,43.647
         c0,0,0.325,0.602-0.278,0.973c-0.603,0.371-1.019,1.205-0.556,1.251c0.463,0.046,0.973,0.139,1.112-0.417s0.926-0.371,0.787-1.251
         C498.542,43.323,497.662,42.813,497.615,43.647z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M502.388,47.077
         c0,0-0.556-0.139-0.417,0.463c0.139,0.603,0.418,0.741,0.649,0.695s0.37-0.278,0.37-0.602
         C502.991,47.308,502.898,47.077,502.388,47.077z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M491.405,11.532
         c0,0-1.019,0.047-1.019,0.788s-0.325,1.668,0.741,1.761s2.642,0.464,2.642-0.556s0.047-1.715-0.741-1.807
         C492.239,11.625,492.239,11.3,491.405,11.532z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M494.418,5.971c0,0-0.696,0.51-0.186,0.695
         s1.205,0.371,1.483,0.139C495.993,6.574,495.113,5.647,494.418,5.971z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M489.459,16.352
         c0,0-0.185,0.695,0.278,0.695s0.649,0,0.649-0.417S489.552,15.981,489.459,16.352z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M453.744,35.361
         c0,0,0.201-0.808-0.219-0.824c-0.42-0.017-0.874-0.067-1.245-0.067s-0.892-0.034-0.892,0.387s0.067,0.69,0.471,0.707
         c0.404,0.017,0.639-0.034,0.656,0.505c0.017,0.538,0.135,0.724,0.438,0.741c0.303,0.017,0.774,0.067,0.774-0.253
         C453.728,36.236,453.61,35.798,453.744,35.361z" pointerEvents="none" className="landarea"></path>
   </g>
   <g data-colors="0">
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M335.789-32.261
         c0-0.741-1.112-0.185-2.781-0.185c-1.668,0-0.742-0.556-1.854-0.834s-1.854-1.02-1.854-1.854s-1.112-1.112,0.556-2.224
         s0.742-1.575-0.278-1.946c-1.02-0.371-0.741-1.019-2.224-2.41s1.39-1.854,2.502-2.502c1.112-0.649,1.946-1.576,1.946-2.317
         s-1.297-1.205-2.781-2.317c-1.483-1.112,0.185-1.761,1.205-2.688s-0.556-2.224-1.39-2.595s-0.649-1.39-0.649-2.039
         s-0.741-1.298-1.853-1.112c-1.112,0.185-1.761,0.278-2.317,1.39s-1.297,1.946-2.688,2.132c-1.39,0.185,0.185-1.39,0.371-2.688
         c0.185-1.297,0.649-1.854,1.298-2.595c0.649-0.742,1.946-2.224,2.502-3.522c0.556-1.297,2.781-2.873,3.8-3.244
         c1.019-0.371,1.112-1.576,1.112-2.224c0-0.649,0.278-1.02,1.668-0.834c1.39,0.185,1.668,0.371,2.595-0.371
         c0.927-0.741,0.556-2.595,0.371-3.429s0.278-1.946,0.185-2.966c-0.093-1.02,0.556-1.946,1.297-2.595s0.371-1.854,0.371-2.688
         s1.205-1.854,2.317-2.317c1.112-0.464,1.205-1.112,1.39-2.317c0.185-1.205-1.854-0.927-3.337-0.927s-1.761-0.556-2.317-1.112
         s-2.132-0.556-2.966-0.093s-1.668-0.278-2.966-0.649s-1.297-0.649-2.132-2.41c-0.834-1.761-3.8-1.297-5.19-1.39
         s-2.595-0.093-3.893-0.185c-1.298-0.093-1.39-0.185-1.761-1.02c-0.371-0.834-1.298-1.02-2.595-1.483s-1.112-0.927-2.039-3.059
         s-1.39,0.093-2.873,0.093s-1.39,0.742-2.502,0.556c-1.112-0.185-1.298-2.595-1.298-3.429s-2.039-1.298-3.151-1.761
         c-1.112-0.464-1.854-1.297-3.337-1.575s-1.02-0.927-1.39-2.039c-0.371-1.112-1.761-0.927-3.337-1.112
         c-1.575-0.185-2.224-1.112-2.502-2.224c-0.154-0.614-0.76-1.511-1.272-2.191c-0.884,0.167-1.764,0.308-2.621,0.425
         c-4.384,0.598-4.185,5.78-4.583,9.167s-5.58,4.982-7.971,5.181c-2.391,0.199-5.779,3.787-5.779,5.38
         c0,1.594-4.584,0.798-6.975,0.598s-4.583-0.996-4.783-2.391c-0.2-1.395-2.789-1.793-3.985-1.793s-2.989,2.193,0,3.587
         s0.598,2.989,2.192,5.78s-0.598,3.387-2.192,3.587s-3.587-0.2-4.384-0.797c-0.797-0.598-2.192-0.996-3.387,0
         c-1.196,0.996-2.789,0-3.387-1.993c-0.598-1.993-5.182-1.992-5.182,0.2s-3.785,0.996-5.579,0.798
         c-1.794-0.198-2.989,1.196-4.584,1.992s-0.996,2.791,0.997,2.791s1.196,1.793-0.2,1.793c-1.395,0-1.395,1.394-0.598,1.793
         s2.989,1.196,2.989,1.196s4.982,1.196,7.174,1.196s2.789,1.992,2.989,2.789c0.2,0.798,3.189,0.398,5.778,0.2
         c2.59-0.198,1.196,2.391,2.591,2.989s1.793,2.789,2.192,4.783c0.398,1.993,1.993,4.383,4.783,5.181
         c2.789,0.798,2.989,1.395,3.787,3.189c0.798,1.793-0.598,1.394-1.594,1.793s-0.399,2.789,1.594,3.387
         c1.993,0.598,0.996,1.594-0.2,2.391c-1.196,0.798-1.594,3.387-1.594,6.576s-0.996,5.778-0.996,9.365s-1.993,3.787-3.587,5.78
         c-0.239,0.299-0.483,0.524-0.731,0.698c0.658,0.228,1.505,0.554,2.163,0.919c1.251,0.695,2.641,2.224,3.684,2.502
         s3.823,1.39,4.866,2.016s2.989,0,4.518,0.904s4.171,1.112,5.005,1.043c0.834-0.07,2.085,0,2.641-0.765s1.946-1.182,2.781-0.695
         c0.834,0.487,3.059,0.487,3.337,1.529s0.765,2.155,2.224,2.433c1.46,0.278,4.171-0.973,5.422,0.209s2.989,1.182,3.823,0.834
         c0.834-0.348,1.46-1.112,2.641-1.112c0.912,0,1.083-0.495,2.294-0.562c-0.677-1.769-2.026-2.979-2.026-5.17
         c0-2.789,5.182-3.389,6.378-4.783c1.196-1.394,4.783-0.2,7.97-0.2s8.967,3.787,12.554,4.185c3.587,0.398,4.783-1.395,6.975-4.583
         c1.354-1.969,2.937-2.948,4.887-3.317c0.101-0.604,0.345-1.197,0.799-1.651C335.603-30.963,335.789-31.519,335.789-32.261z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M351.163-20.084c0,0-0.07,3.232-1.807,3.232
         s-1.981,0.834-2.92,1.216c-0.938,0.382-1.425,1.251-1.703,2.607s0.104,3.649,0.73,4.518s0.695,3.719,2.085,4.032
         s1.807,0.869,1.703,1.564s0.765,0.66,1.182,0s0.278-1.147,0.66-1.495s0.869-0.938,0.695-1.807
         c-0.174-0.869,0.035-2.398,0.66-2.885s-0.174-1.877,0.66-1.981s0.904-0.174,0.904-1.043s0.695-2.468-0.417-2.641
         c-1.112-0.174-0.834-1.321-0.834-2.12S352.171-22.483,351.163-20.084z" pointerEvents="none" className="landarea"></path>
   </g>
   <g data-colors="0">
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M432.2,9.618
         c-0.199-1.196-4.185-4.583-6.976-6.177c-2.79-1.594-3.986-2.989-6.975-3.587s-4.581-2.591-6.972-3.189
         c-2.392-0.598-0.998-1.993,0.198-3.387c1.196-1.394-3.785-2.391-6.576-2.391c-2.79,0-5.578-0.997-7.771-2.791
         c-2.193-1.793-3.388-6.177-3.587-9.166c-0.198-2.989-7.97-7.573-10.562-9.167s-3.984-3.387-4.981-6.178
         c-0.997-2.791,1.794-3.188,1.595-5.181c-0.198-1.993-1.195-1.395-1.396-4.185c-0.201-2.789,2.592-0.398,4.385-1.993
         c1.612-1.433,2.426-1.896,4.018-1.976c-0.137-2.29,1.278-4.597,1.278-6.055c0-0.643,0.028-1.588,0.061-2.508
         c-1.804-0.715-4.604-0.055-5.808-0.829c-1.297-0.834-3.522-0.464-4.356-1.205c-0.834-0.742-1.205-1.761-1.205-2.781
         c0-1.02-1.019-1.205-1.668-0.927s-2.039,0.464-5.561,0.556c-3.522,0.093-1.483,0.278-3.244,1.39s-1.297,0-3.244-0.278
         c-1.946-0.278-2.132,1.854-2.224,2.966s-2.039,0.185-2.41,1.297s-0.927,1.576-3.244,2.224c-2.317,0.649-1.761-0.649-2.502-0.741
         c-0.742-0.093-0.185-0.834-1.761-1.02c-1.576-0.185-0.834,1.205-0.927,2.132s-0.371,1.019-1.02,1.297s-0.556,1.112-0.742,2.688
         s-1.39-0.093-1.668-0.834c-0.278-0.742-0.927-1.205-1.575-1.668c-0.649-0.464-1.298-0.742-2.224-2.224
         c-0.927-1.483-1.668,0.093-2.317,1.02s-0.834,1.112-1.575,2.41c-0.741,1.297-1.761,1.39-2.781,0.927
         c-1.019-0.463-2.595,0.185-3.8-0.093c-0.444-0.102-1-0.167-1.527-0.208c-0.004,0.233-0.096,0.462-0.327,0.671
         c-1.02,0.927-2.688,1.576-1.205,2.688s2.781,1.575,2.781,2.317s-0.834,1.669-1.946,2.317c-1.112,0.649-3.986,1.112-2.502,2.502
         c1.484,1.39,1.205,2.039,2.224,2.41c1.02,0.371,1.946,0.834,0.278,1.946s-0.556,1.39-0.556,2.224s0.742,1.576,1.854,1.854
         s0.185,0.834,1.854,0.834c1.668,0,2.781-0.556,2.781,0.185s-0.186,1.297-0.927,2.039c-0.454,0.454-0.698,1.047-0.799,1.651
         c1.208-0.228,2.555-0.224,4.08-0.072c3.986,0.398,4.583-4.583,6.177-6.177s3.986-0.2,6.378,0.198s3.785,2.989,6.177,3.388
         c2.391,0.399,2.591,2.789,3.189,4.383s0.797,3.587,1.196,5.38c0.398,1.793,3.387,5.182,6.974,7.772s4.584,3.787,7.374,5.58
         c2.788,1.793,9.763,7.174,11.758,6.775c1.994-0.398,4.581,2.391,5.978,4.185c1.396,1.793,3.785,2.789,5.578,2.789
         c1.794,0,2.193,1.594,2.59,3.787s3.19,2.59,4.782,2.192c1.593-0.398,1.794,1.395,2.193,3.189c0.399,1.793,1.394,3.785,2.193,6.177
         c0.798,2.391,0.995,3.787,0.198,4.384c-0.796,0.598-2.192,0.2-2.192,2.989s-2.392,1.196-1.794,3.387
         c0.598,2.192,1.994,1.595,2.989,0.997c0.995-0.598,2.391-1.595,3.788-2.989c1.396-1.394,1.195-1.993,1.394-4.384
         c0.198-2.391,2.193-2.192,3.986-2.591s1.593-1.594,1.593-2.391c0-0.798-0.598-2.989-2.789-3.587
         c-2.19-0.598-1.994-1.793-1.793-3.587c0.201-1.793,1.593-5.38,3.386-6.576s3.788,2.791,5.581,2.591s2.391,1.793,3.386,3.189
         c0.995,1.395,3.19,2.192,4.584,0C434.394,10.21,432.398,10.813,432.2,9.618z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M383.892,31.32c0,0-0.649,0.881-1.807,0.556
         c-1.158-0.324-1.9-0.417-2.549,0.139c-0.649,0.556-1.669,2.224-0.603,3.198c1.066,0.973,1.344,1.205,2.781,1.344
         s1.668,0.278,2.085,0.834s0.881,0.788,1.715,0.788c0.834,0,0.602,1.529,2.039,1.668s1.669,0.556,2.41,1.39
         s0.834,1.112,2.317,1.112c1.483,0,2.781-0.093,3.29,0.602s1.761,1.668,1.993,2.363c0.232,0.695,0.788,0.788,1.761,0.788
         s3.847,0.324,3.893-0.603c0.046-0.927-0.277-1.483,0.371-1.761s1.761-0.464,0.834-1.576c-0.927-1.112-2.317-1.39-2.363-2.456
         c-0.047-1.066-0.51-2.781,1.065-3.337c1.576-0.556,1.853-1.529,2.085-2.363s0.093-1.483,0.51-1.715
         c0.417-0.232,1.066-1.159,0.556-1.946s-1.575-0.834-2.363-0.093s-0.603,1.158-2.178,0.927s-3.893-0.556-4.727,0.185
         c-0.834,0.741-1.065,0.881-2.549,0.834s-4.727,0.695-5.376-0.232c-0.648-0.927-1.807-0.324-2.271-1.39
         c-0.463-1.066-1.251-0.742-1.714-0.556C384.633,30.208,384.448,30.486,383.892,31.32z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M347.533,0.653c0,0-0.737,1.802-2.867,1.638
         S341.554,0.98,340.98,1.8c-0.573,0.819-1.802,1.966,0.491,3.686s1.966,2.375,1.72,3.768c-0.246,1.392-0.41,2.703-0.164,2.949
         s0.41,0.737,0.41,1.392s0.327,0.737-0.41,1.229c-0.737,0.491-0.737,2.13-0.737,2.785s-0.164,4.259,0.819,4.259
         s1.228,0.082,1.31,0.819s0.737,1.065,1.474,1.065s0.901,0.327,1.392-0.491c0.491-0.819,1.966-1.147,2.048-2.375
         c0.082-1.229,0.655-1.474,1.31-0.819s2.048,0.573,2.375,0.409c0.328-0.164-0.819-1.802,0.819-2.048s-0.164-1.228,0.737-2.048
         c0.901-0.819,0.655-2.212,0.655-3.358c0-1.147,0.246-1.147-0.491-1.474s-0.983-1.146-0.737-1.966
         c0.246-0.819,0.983-0.327,1.065-0.737s1.147-0.491,1.147-1.392s0.082-2.048-0.409-2.621s-0.737-1.884-1.065-2.457
         c-0.328-0.574-1.311-1.884-1.966-2.457C352.12-0.657,348.843-2.296,347.533,0.653z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M360.026-18.972
         c0,0,0.104,0.695-0.487,0.799s-0.904,0.487-0.521,0.73s0.73,0.695,1.286,0.452s0.487-0.278,0.382-0.799
         C360.582-18.312,360.721-19.563,360.026-18.972z" pointerEvents="none" className="landarea"></path>
   </g>
   <g data-colors="0">
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M202.324,35.028
         c0.278-1.251,1.483-1.715,2.502-2.41s0.927-1.668,0.603-2.456s-1.251-1.066-1.946-1.668s-0.139-1.298,0.093-2.039
         c0.232-0.742,0.278-1.436,1.205-1.993s1.575-1.576,1.529-2.271c-0.046-0.695-0.788-1.529-1.344-1.807s-0.741-0.834-0.88-1.39
         s-0.324-1.529-1.715-2.271c-1.39-0.742-1.622-1.946-0.973-1.807c0.649,0.139,3.151,0.371,4.449,0s0.834-1.39,0.973-2.085
         s0.093-1.668,0.046-2.781s0.232-1.436,0.695-1.946c0.464-0.51,0.232-1.993,0.278-3.012s-0.139-2.873-0.695-3.615
         s0.185-1.112,0.602-1.298c0.417-0.185,1.066-1.066,1.298-1.575c0.232-0.51,1.02-0.649,2.132-0.788s1.622-1.158,1.946-1.668
         s-0.371-1.853-1.159-2.178s-0.834-0.603-1.251-1.529c-0.417-0.927-0.881-0.695-2.363-1.298c-1.483-0.603-1.576,0.139-2.642,0.232
         s-1.39,0.602-1.993,1.251s-1.993-0.417-3.661-0.649c-1.668-0.232-2.41,1.251-3.8,1.02c-1.39-0.232-0.324-1.298-0.185-2.595
         s-1.853-1.251-2.966-1.251s-1.298,0.88-1.576,1.622c-0.191,0.51-1.282,0.494-1.944,0.434c-0.311,2.153-0.307,2.639,1.499,4.265
         c1.993,1.793,0.796,4.982,0.796,7.174s0.2,4.185-1.793,5.58s-0.796,6.376-2.989,8.568s-1.594,4.384-1.594,7.174
         c0,2.789,1.993,2.791,3.787,3.189c1.793,0.398,0.996,3.985,0.996,5.978s-0.598,3.787-0.598,5.978c0,2.192,0.598,2.989,1.993,2.591
         c1.395-0.398,3.785-0.399,4.981,0.598c1.196,0.997,3.787,0.398,4.584-0.598c0.44-0.55,0.943-0.917,1.772-1.136
         c-0.323-1.082-0.916-1.748-1.249-2.525C201.35,37.067,202.045,36.279,202.324,35.028z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M-7.142,14.985c0,0-0.417,0.07-0.417,0.765
         c0,0.695-0.209,1.599,0.417,1.599s0.556-0.278,0.487-0.834C-6.725,15.958-6.795,14.846-7.142,14.985z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M19.968,23.326c0,0-1.668-0.973-2.085-0.278
         c-0.417,0.695-0.278,0.834,0.834,0.973s1.043,0.487,1.738,1.043c0.695,0.556,0.626,0.973,1.39,1.182
         c0.765,0.209,1.807,0.973,0.973-0.278S21.149,23.883,19.968,23.326z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M16.284,25.273c0,0-1.182-0.417-1.946,0.07
         s-0.904,0.973,0.487,0.973s2.224-0.417,2.641,0.417c0.417,0.834,1.251,0.904,2.016,0.904s1.807,0.417,1.668-0.278
         s-1.321-0.487-1.599-1.043C19.273,25.759,18.855,25.62,16.284,25.273z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M28.379,22.979c0,0-0.834,0.278-0.209,0.834
         C28.796,24.369,29.213,23.465,28.379,22.979z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M42.629,32.294c0,0-1.599-0.626-2.433-1.043
         s-2.502,0.209-1.46,1.112c1.043,0.904,3.267,1.529,3.684,2.224c0.417,0.695,1.39,1.043,2.155,0.834s1.251-1.946,0.487-2.433
         C44.297,32.502,44.158,32.85,42.629,32.294z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M117.216,86.027
         c1.46,0.695,2.92,0.278,3.406-0.139c0.487-0.417,0.765-2.502-0.695-2.155c0,0-0.973,0.278-1.321-0.417s-1.321-0.834-2.016-0.348
         C115.896,83.455,115.757,85.332,117.216,86.027z" pointerEvents="none" className="landarea"></path>
   </g>
   <g data-colors="0">
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M387.407-152.87
         c0,0-1.228,0.307-1.075,0.998c0.154,0.691,0.538,1.611,1.535,1.995c0.998,0.384,1.919,1.074,2.226-0.153
         C390.4-151.258,390.707-153.253,387.407-152.87z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M400.575-112.154
         c0.093-1.483-0.278-2.595-1.39-2.781c-1.112-0.185-1.39-1.298-1.112-2.873s0-1.854,0.556-2.41s0.648-1.761,0-2.688
         s-1.205-1.112-1.205-3.059s-0.556-3.707-2.039-4.819s-2.039-1.668-1.297-2.224c0.741-0.556,1.575-0.742,2.039-2.502
         c0.464-1.761,0.463-2.317-0.834-3.429s-0.834-1.946-0.927-3.244c-0.058-0.8,0.097-2.339,0.224-3.402
         c-0.852-0.382-1.726-0.964-2.644-1.423c-1.994-0.997-3.785-0.398-4.581-1.992c-0.797-1.594-2.193-0.798-4.185-1.595
         s-3.587-3.188-4.584-1.594s-2.789,3.787-4.783,3.587c-1.993-0.2-1.196,3.387-4.384,3.189c-3.189-0.198-3.188-1.794-2.391-3.986
         c0.796-2.193-1.394-0.996-3.188-0.598c-1.793,0.398-1.993-0.598-4.783-0.996c-2.486-0.355-2.124-1.822-2.016-4.395
         c-0.871-0.095-2.012-0.391-2.699-0.905c-0.9-0.675-1.238,0.15-2.476,0.638s-2.439-0.563-4.202-0.338s-2.776-0.9-3.714-2.138
         c-0.292-0.386-0.628-0.695-0.952-0.938c-1.934,1.601,1.558,4.106,3.903,4.887c2.391,0.796,0.398,1.993-1.196,2.791
         c-1.594,0.798-0.598,2.391,1.196,2.59c1.793,0.199,0.798,4.185-0.2,4.185s-2.391,0.2-2.989,2.591
         c-0.598,2.391-2.989,1.993-3.785-0.2s-5.978,0-7.573-0.398s-3.188,0.398-1.793,2.192c0.59,0.758,0.534,1.158,0.173,1.354
         c0.425,0.522,0.912,1.216,1.06,1.807c0.278,1.112,1.297,2.317,0.834,3.244c-0.463,0.927-1.668,2.41-1.668,2.966
         s-0.278,1.298-1.297,1.576c-1.02,0.278-1.761,0.834-1.668,1.668s-0.093,1.205,1.112,1.205s2.224,0.834,2.132,1.39
         s-1.854,1.02-2.224,2.132c-0.371,1.112-1.298,2.595-2.502,2.966s-1.761,0.742-3.151,0.556s-2.224-0.093-2.224,0.927
         s-0.093,1.483,0.649,2.039c0.742,0.556,2.41,1.112,1.39,2.224c-1.019,1.112-0.463,1.39-0.927,2.688s0.093,2.41,0.093,3.8
         s0.278,1.946,1.02,2.966c0.742,1.02,1.019,1.576,1.205,2.41c0.185,0.834-0.464,1.297-1.112,1.483
         c-0.649,0.185-1.946,1.576-1.205,2.688c0.742,1.112,1.019,1.576,2.039,1.854s1.205,1.761,1.39,2.688
         c0.134,0.668-0.07,1.195-0.263,1.922c0.64,0.193,1.182,0.531,1.468,1.137c0.834,1.761,0.834,2.039,2.132,2.41
         s2.132,1.112,2.966,0.649s2.41-0.463,2.966,0.093s0.834,1.112,2.317,1.112c1.483,0,3.522-0.278,3.337,0.927
         s-0.278,1.854-1.39,2.317c-1.112,0.464-2.317,1.483-2.317,2.317s0.37,2.039-0.371,2.688s-1.39,1.576-1.297,2.595
         c0.093,1.019-0.371,2.132-0.185,2.966c0.125,0.564,0.326,1.589,0.163,2.426c0.593-0.178,1.439-0.342,2.432-0.271
         c1.946,0.139,2.781-0.07,3.545,0.348c0.765,0.417,2.92,2.224,3.059,0.695c0.139-1.529-0.209-2.641,0.695-2.572
         c0.904,0.07,1.738,1.877,2.433,1.529s1.807-0.834,2.572-0.07c0.765,0.765,2.085,0.556,3.267,0.695s3.337,0.139,3.893,1.043
         s0.904,1.529,1.668,1.46c0.765-0.07,1.043-0.417,1.39-1.321c0.347-0.904,0.765-0.904,1.529-1.043
         c0.764-0.139,1.321-0.765,2.155-0.139c0.834,0.626,1.599,1.182,1.599,1.182s1.251,1.043,2.155,0.765s1.668-0.487,2.016-1.251
         c0.348-0.765,1.321-1.599,2.363-1.599s1.946,0.209,3.476,0.626c1.529,0.417,2.016,0.904,2.155-0.695s2.155-0.209,2.85,0.209
         s2.085,1.738,2.641,0.626s1.46-1.321,0.626-1.946c-0.834-0.626-0.348-1.39-1.182-2.085s-1.112-0.626-0.765-1.946
         s0.695-2.085,2.155-2.085s2.433-0.07,2.711-1.599c0.278-1.529,0.556-2.294,1.599-2.363c0.994-0.066,2.558-0.38,4.087-0.228
         c-0.67-1.304-1.969-2.324-2.79-2.622c-1.02-0.371-1.112-1.112-1.854-1.576c-0.741-0.464-1.761-1.112-2.688-1.575
         c-0.926-0.463-1.854-1.112-2.595-1.854s-2.595-2.317-3.244-2.966c-0.648-0.649-0.278-2.224-0.278-3.151s-0.093-2.039-1.112-2.132
         s-0.371-1.019-2.039-2.039s-0.463-1.483,1.019-1.39c1.483,0.093,0.556-0.649,1.483-1.02s2.595-0.278,3.151-0.278
         s1.853-0.371,2.688-1.019c0.834-0.649,1.205-1.112,2.132-1.112c0.926,0,1.761-0.278,2.132-1.112s2.224-0.185,2.966-0.927
         c0.741-0.742,1.297-0.927,2.317-0.927s1.297-1.483,1.39-2.039c0.093-0.556,1.483-0.278,1.761,0.463
         c0.278,0.741,1.205,1.39,2.688,1.483c0.849,0.053,1.423-0.713,1.76-1.378C400.618-109.859,400.499-110.939,400.575-112.154z" pointerEvents="none" className="landarea"></path>
   </g>
   <g data-colors="0">
      <g data-colors="0">
         <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M292.389-13.029
            c-0.834,0.348-2.572,0.348-3.823-0.834s-3.962,0.07-5.422-0.209c-1.46-0.278-1.946-1.39-2.224-2.433
            c-0.278-1.043-2.502-1.043-3.337-1.529c-0.834-0.487-2.224-0.07-2.781,0.695c-0.556,0.765-1.807,0.695-2.641,0.765
            c-0.834,0.07-3.476-0.139-5.005-1.043s-3.476-0.278-4.518-0.904c-1.043-0.626-3.823-1.738-4.866-2.016s-2.433-1.807-3.684-2.502
            c-0.657-0.365-1.505-0.691-2.163-0.919c-1.402,0.988-2.927,0.148-4.451-0.698c-1.793-0.996-3.188-1.196-4.383-0.399
            c-1.196,0.796-2.989,0.798-4.384-0.198s-3.787-0.598-7.174,0.198s-9.565-1.196-10.76-2.192c-1.196-0.996-8.569-0.2-12.554,0.398
            s-5.181-0.598-7.174-1.793c-1.993-1.196-5.58,1.395-6.576,2.989s-2.789,1.793-5.58,2.791c-2.791,0.997-1.394,4.185,0.598,4.783
            s0.598,5.778,0.2,8.568c-0.037,0.261-0.072,0.495-0.104,0.717c0.662,0.06,1.753,0.076,1.944-0.434
            c0.278-0.742,0.464-1.622,1.576-1.622s3.105-0.046,2.966,1.251c-0.139,1.297-1.205,2.363,0.185,2.595s2.132-1.252,3.8-1.02
            s3.058,1.298,3.661,0.649s0.927-1.158,1.993-1.251s1.159-0.834,2.642-0.232c1.483,0.603,1.946,0.371,2.363,1.298
            s0.464,1.205,1.251,1.529s1.483,1.668,1.159,2.178s-0.834,1.529-1.946,1.668s-1.9,0.278-2.132,0.788
            c-0.232,0.51-0.881,1.39-1.298,1.575s-1.158,0.556-0.602,1.298s0.742,2.595,0.695,3.615s0.185,2.502-0.278,3.012
            c-0.464,0.51-0.741,0.834-0.695,1.946c0.046,1.112,0.093,2.085-0.046,2.781s0.324,1.714-0.973,2.085
            c-1.298,0.371-3.8,0.139-4.449,0s-0.417,1.066,0.973,1.807c1.39,0.742,1.576,1.715,1.715,2.271s0.324,1.112,0.88,1.39
            s1.298,1.112,1.344,1.807c0.046,0.695-0.602,1.715-1.529,2.271s-0.973,1.251-1.205,1.993s-0.788,1.437-0.093,2.039
            c0.695,0.602,1.622,0.881,1.946,1.668c0.324,0.788,0.417,1.761-0.603,2.456c-1.019,0.695-2.224,1.159-2.502,2.41
            s-0.973,2.039-0.556,3.012c0.333,0.777,0.926,1.443,1.249,2.525c0.673-0.178,1.561-0.259,2.811-0.259
            c2.789,0,2.192,2.192,4.583,2.192s1.993,4.584,2.791,5.978s0.996,2.193,4.383,3.587s4.185-0.796,5.78-2.391
            s3.387-1.394,5.579-2.989c2.192-1.595,6.776-1.594,8.569-1.793c1.793-0.2,5.181,0.997,6.376,1.595
            c1.196,0.598,1.793-1.793,4.185-1.395c2.391,0.398,3.388-1.196,3.986-3.985c0.598-2.789,4.583-4.584,7.772-4.982
            s2.989-3.587,4.185-6.576c1.196-2.989,2.391-2.789,4.783-3.587c2.391-0.798,0.796-2.591-0.798-3.985s-1.196-1.993-1.196-3.986
            s2.192-5.38,3.587-7.372c1.395-1.992,0.797-3.786,3.587-4.982c2.789-1.196,2.192-1.594,3.189-4.185s4.583-2.192,8.768-3.189
            s4.783-2.192,7.971-4.783c3.189-2.591,3.985-3.785,3.785-6.576c-0.043-0.598-0.181-1.121-0.365-1.604
            c-1.211,0.067-1.382,0.562-2.294,0.562C293.849-14.141,293.224-13.376,292.389-13.029z" pointerEvents="none" className="landarea"></path>
         <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M305.365,11.486
            c0,0-0.719-0.371-0.719-0.788s-0.44-0.463-1.205-0.278c-0.765,0.185-1.228,0.463-1.182,1.228s0,1.043,0.788,1.02
            s1.414,0.046,1.414,0.533s0.973,0.626,1.367,0.626s0.858,0.023,0.95-0.533c0.093-0.556,0.278-1.668-0.533-1.622
            C305.435,11.718,305.69,11.625,305.365,11.486z" pointerEvents="none" className="landarea"></path>
         <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M295.077,12.042
            c0,0-0.649,0.765-1.159,0.742s-0.904,0.95-1.645,1.043s-1.275,0.185-1.46,0.672s-0.324,0.51-0.857,0.533
            s-0.255,1.344,0.255,1.344s0.231,1.135,1.158,1.112s1.553-0.208,1.692,0.51c0.139,0.718,0.695,0.602,1.251,0.718
            s0.672,0.88,1.321,0.927c0.649,0.046,0.857,0.185,0.927-0.278s0-0.765,0.603-0.857c0.602-0.093,1.483,0.046,1.575-0.718
            s0.093-2.271,0.093-2.781s0.185-0.857-0.209-1.043c-0.394-0.185-0.626-0.07-0.765-0.95c-0.139-0.881-0.579-1.251-0.95-1.39
            C296.537,11.486,295.541,11.463,295.077,12.042z" pointerEvents="none" className="landarea"></path>
         <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M280.503,20.731
            c0,0-0.093,0.417-0.093,1.182s-0.394,1.668,0.278,1.807c0.672,0.139,0.672,0.046,0.765-0.44s1.089,0.023,1.112-0.649
            s0.301-1.228-0.348-1.483C281.568,20.894,280.619,20.129,280.503,20.731z" pointerEvents="none" className="landarea"></path>
      </g>
      <g data-colors="0">
         <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M111.516,124.12
            c-0.765-0.209-1.182-0.209-1.182-1.043s-0.695-1.321-0.973-0.556c0,0-0.278,2.155-0.07,3.337
            c0.209,1.182,2.224,1.251,2.363,0.139C111.794,124.885,112.281,124.329,111.516,124.12z" pointerEvents="none" className="landarea"></path>
         <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M109.57,132.323
            c0,0-2.641,0.139-2.502,1.112s2.502,1.251,2.92,0.348C110.404,132.879,110.335,132.253,109.57,132.323z" pointerEvents="none" className="landarea"></path>
         <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M118.954,131.002
            c1.112,0.904,2.92,0.904,3.198-0.139s1.112-1.529,1.807-2.085s0.904-1.043,0.834-1.668c-0.07-0.626-3.406-0.904-4.449-0.348
            c0,0-1.807,0.139-2.085,1.321s-0.695,0.904-1.738,0.904s-1.46,0.834-1.182,1.321
            C115.618,130.794,117.842,130.099,118.954,131.002z" pointerEvents="none" className="landarea"></path>
         <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M128.478,133.435
            c1.182,0.348,1.251,1.043,2.294,1.043s0.904-0.487,1.46-1.251s0.626-2.433,0.417-3.059s-1.738-0.348-1.599-1.043
            s-1.251-0.626-1.251-0.278c0,0,0.139,0.973-0.904,1.182c-1.043,0.209-1.39,1.112-1.251,1.738
            C127.782,132.392,127.296,133.088,128.478,133.435z" pointerEvents="none" className="landarea"></path>
         <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M144.327,128.986
            c1.112-0.417,1.251-0.626,1.46-1.946s-0.765-2.92-1.321-2.224c0,0-2.572,1.251-2.781,3.267
            C141.476,130.099,143.214,129.403,144.327,128.986z" pointerEvents="none" className="landarea"></path>
         <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M144.813,120.645
            c1.043,0.07,0.417,2.502,1.738,2.502s1.807-0.348,2.502-1.39c0.695-1.043,0.765-2.92-0.278-2.155c0,0-1.668,1.112-2.155,0.417
            s-1.668-1.46-2.016-0.834C144.257,119.811,143.77,120.575,144.813,120.645z" pointerEvents="none" className="landarea"></path>
      </g>
   </g>
   <g data-colors="0">
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M758.336,179.893
         c-0.068,0.205-0.129,0.401-0.183,0.59c-0.019,0.065-0.034,0.126-0.051,0.19c-0.034,0.121-0.066,0.242-0.093,0.357
         c-0.017,0.07-0.031,0.137-0.047,0.205c-0.023,0.103-0.045,0.205-0.063,0.303c-0.013,0.066-0.024,0.129-0.035,0.193
         c-0.016,0.095-0.03,0.188-0.043,0.279c-0.008,0.058-0.016,0.116-0.022,0.172c-0.011,0.092-0.019,0.182-0.026,0.269
         c-0.004,0.048-0.009,0.098-0.011,0.144c-0.006,0.095-0.009,0.187-0.01,0.276c-0.001,0.035-0.003,0.071-0.003,0.104
         c0,0.117,0.002,0.229,0.007,0.337c0.001,0.004,0.001,0.008,0.001,0.013c0.006,0.118,0.016,0.23,0.028,0.339
         c0,0.001,0,0.003,0.001,0.004c0.017,0.157,0.041,0.304,0.067,0.443c0.003,0.012,0.004,0.024,0.007,0.036
         c0.026,0.134,0.055,0.259,0.086,0.379c0.004,0.02,0.01,0.039,0.014,0.058c0.031,0.117,0.062,0.229,0.093,0.336
         c0.035,0.12,0.068,0.236,0.099,0.347c0.278,1.019,2.873,3.244,3.151,4.449s0,3.986-1.297,4.913s-1.112,5.19-1.946,6.117
         s-1.298,3.337-2.039,6.024s-10.566,5.468-12.605,5.932s-14.459,5.283-14.459,5.283l9.919,21.045
         c0.914-0.218,1.83-0.47,2.792-1.048c1.992-1.196,3.986-0.399,5.78-2.391c1.793-1.992,4.184,0.397,6.576,0.796
         c2.392,0.399,3.987-2.391,4.185-5.182c0.199-2.79,2.391-3.586,5.579-3.586c3.187,0,4.983-1.794,5.181-3.984
         c0.199-2.191,2.791-2.391,3.786-4.584s4.584-0.997,7.573-1.196c2.989-0.198,1.195-3.986-0.4-5.779
         c-1.595-1.794,0.799-1.992,0.4-3.587c-0.4-1.595,1.195-2.59,4.383-2.59c3.187,0,3.19-1.195,3.388-3.785
         c0.199-2.59,1.595-2.193,3.587-4.185s3.388-4.184,4.383-6.776s3.986-4.981,2.391-5.978c-1.595-0.997-3.984-2.191-6.576-6.973
         c-2.592-4.782-5.978-3.986-8.567-3.986c-2.59,0-5.579-0.598-6.975-2.193c-1.397-1.595-3.786-1.992-6.576-3.387
         c-2.791-1.395,0.598-3.785-0.995-6.177c-0.29-0.435-0.479-0.985-0.6-1.612c-1.017,0.683-1.676,1.343-1.676,1.343
         S759.355,176.835,758.336,179.893z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M768.101,155.541
         c0.106-1.77,0.224-3.556,0.069-5.038c-0.397-3.785-3.19-2.591-3.787,0.398c-0.062,0.312-0.135,0.582-0.214,0.831
         c0.266,0.624,0.677,1.357,1.304,2.024C766.885,155.257,766.537,156.835,768.101,155.541z" pointerEvents="none" className="landarea"></path>
   </g>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M731.921,179.059
      c0.648,0.834,1.576,2.966,3.893,2.688s4.264-0.926,6.117-0.463c1.854,0.463,3.243,1.761,5.097,1.761
      c1.47,0,7.654,0.408,10.755,0.623c0-0.001,0-0.001,0-0.001c-0.012-0.109-0.022-0.221-0.028-0.339c0-0.004,0-0.009-0.001-0.013
      c-0.005-0.108-0.008-0.22-0.007-0.337c0-0.034,0.002-0.07,0.003-0.104c0.002-0.09,0.004-0.181,0.01-0.276
      c0.003-0.047,0.007-0.096,0.011-0.144c0.007-0.087,0.015-0.177,0.026-0.269c0.006-0.057,0.014-0.114,0.022-0.172
      c0.013-0.091,0.027-0.184,0.043-0.279c0.011-0.064,0.022-0.128,0.035-0.193c0.018-0.098,0.04-0.2,0.063-0.303
      c0.015-0.068,0.029-0.135,0.047-0.205c0.027-0.115,0.06-0.236,0.093-0.357c0.017-0.064,0.032-0.124,0.051-0.19
      c0.054-0.19,0.115-0.386,0.183-0.59c1.019-3.059,8.156-17.703,8.156-17.703s0.66-0.66,1.676-1.343
      c-0.283-1.47-0.182-3.378-0.067-5.307c-1.564,1.294-1.216-0.284-2.628-1.785c-0.628-0.667-1.038-1.4-1.304-2.024
      c-0.68,2.136-1.965,2.187-3.571,4.151c-1.793,2.193-3.187,1.793-4.383,4.383c-1.195,2.59-4.385,4.185-6.377,6.975
      c-1.992,2.79-9.366,2.789-13.152,2.191c-3.785-0.598-4.584,0.399-6.774,2.193c-0.917,0.752-1.554,0.589-2.089,0.05
      c0.093,1.234,0.302,2.613,0.763,3.212C729.511,176.093,731.273,178.225,731.921,179.059z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M689.471,112.604
      c-1.854-0.278-3.244,0.556-4.078,2.595s-1.668,3.522-2.873,4.171c-0.74,0.398-1.443,1.983-1.877,3.149c0.027,0,0.055,0,0.082,0
      c0.073,0,0.142,0.001,0.211,0.001c0.126,0.001,0.247,0.001,0.363,0.001c0.057,0,0.113,0,0.167,0c0.06,0,0.117,0,0.174,0
      c0.065,0,0.128,0,0.188,0c0.15,0,0.291,0,0.414,0c2.41,0,7.044,0.371,7.6,3.151c0.556,2.781,2.595,2.595,4.82,2.595
      c1.651,0,2.892-0.002,3.575-1.064c-0.591-0.69-1.207-1.039-1.207-2.208c0-1.993-0.201-1.992-2.592-3.785s0-4.782,0-6.178
      c0-0.309,0.153-0.525,0.404-0.68c-0.514-0.278-1.094-0.615-1.478-0.914C692.53,112.789,691.325,112.882,689.471,112.604z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M718.365,163.117
      c0.139,0.556,0.834,2.016,1.46,2.224c0.626,0.209,0.973,0.556,2.085,0.139c0.937-0.351,1.287-0.749,2.079-0.866
      c0.361-1.434,0.571-3.842,1.138-5.544c0.597-1.793-0.4-2.989-0.598-5.579c-0.199-2.59-0.598-2.591-2.193-2.591
      s-2.391,0.598-2.193,2.989c0.199,2.391-1.793,1.594-1.793,4.384c0,1.792-0.739,2.756-1.427,2.639
      c0.003,0.413,0.059,0.855,0.26,1.022C717.601,162.283,718.226,162.561,718.365,163.117z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M573.431,99.165
      c-1.298,1.668-2.039,3.151-0.556,5.376c1.483,2.224,0.37,4.634,1.854,6.117c0.091,0.092,0.181,0.191,0.268,0.297
      c0.026,0.031,0.05,0.067,0.076,0.099c0.06,0.077,0.121,0.155,0.179,0.238c0.029,0.041,0.056,0.086,0.085,0.129
      c0.053,0.08,0.106,0.159,0.157,0.244c0.029,0.048,0.056,0.098,0.084,0.147c0.048,0.083,0.096,0.166,0.142,0.252
      c0.029,0.055,0.057,0.113,0.086,0.169c0.042,0.084,0.085,0.166,0.125,0.252c0.03,0.062,0.058,0.127,0.086,0.19
      c0.037,0.082,0.074,0.163,0.11,0.247c0.03,0.07,0.058,0.141,0.086,0.212c0.031,0.078,0.063,0.155,0.093,0.233
      s0.058,0.157,0.086,0.235c0.026,0.07,0.052,0.141,0.076,0.211c0.03,0.088,0.059,0.175,0.087,0.263
      c0.019,0.06,0.039,0.12,0.058,0.179c0.03,0.097,0.058,0.193,0.086,0.289c0.014,0.049,0.029,0.097,0.042,0.145
      c0.029,0.105,0.056,0.208,0.083,0.31c0.009,0.036,0.019,0.073,0.028,0.109c0.028,0.112,0.053,0.22,0.077,0.327
      c0.005,0.024,0.011,0.049,0.016,0.073c0.024,0.114,0.046,0.223,0.066,0.331c0.003,0.016,0.006,0.032,0.009,0.048
      c0.02,0.112,0.037,0.219,0.053,0.323c0.001,0.009,0.003,0.018,0.004,0.027c0.015,0.106,0.028,0.206,0.037,0.301
      c0.001,0.006,0.001,0.011,0.002,0.017c0.009,0.095,0.015,0.183,0.019,0.266c0,0.003,0.001,0.007,0.001,0.009
      c1.322-0.923,2.206-3.399,2.206-4.389c0-1.112,0.834-4.032,0.904-5.074c0.07-1.043,0.278-1.738,0.765-2.711
      s0.626-2.016,1.043-3.684c0.417-1.668,1.043-3.545,1.043-4.31s0-3.615-0.139-4.171s-0.139-1.529,0.07-2.363
      c0.208-0.834,0.904-3.893,0.765-5.074c-0.017-0.146-0.043-0.295-0.075-0.445c-0.016-0.073-0.036-0.146-0.055-0.219
      c-0.018-0.074-0.035-0.149-0.056-0.222c-0.03-0.104-0.062-0.204-0.094-0.304c-0.013-0.038-0.024-0.077-0.036-0.114
      c-0.041-0.121-0.083-0.237-0.125-0.348c-0.011-0.03-0.022-0.064-0.034-0.092c-0.028,0.052-0.06,0.099-0.096,0.142
      c-0.001,0.001-0.001,0.002-0.002,0.003c-0.031,0.037-0.067,0.067-0.105,0.095c-0.006,0.005-0.011,0.012-0.018,0.017
      c-0.036,0.024-0.078,0.041-0.121,0.057c-0.012,0.004-0.021,0.012-0.034,0.015c-0.057,0.017-0.12,0.026-0.19,0.026
      c-0.822,0-2.744-0.154-4.618-0.878c-0.76,1.544-0.462,3.589-0.462,6.142c0,3.587-1.793,2.192-3.187,5.779
      c-0.172,0.443-0.341,0.815-0.508,1.134C574.129,96.884,574.145,98.248,573.431,99.165z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M697.998,112.326
      c-1.112-0.834-0.742-1.854-2.873-2.781c-2.132-0.927-1.854-2.132-1.575-3.151c0.278-1.02,0.556-2.039-1.39-2.688
      c-1.946-0.649-2.317-4.912-1.297-5.098s1.483-0.278,1.39-1.761c-0.093-1.483-0.185-2.41-1.946-3.337s-1.854-3.707-4.635-4.727
      s-3.615-3.059-5.561-3.522c-1.946-0.464-3.985-0.093-3.707-1.854s1.668-2.41-0.927-3.985c-2.595-1.576-2.966-2.132-3.522-3.337
      c-0.556-1.205-1.761-0.834-0.834-2.966c0.927-2.132-0.37-4.078,1.112-5.19c1.483-1.112,0.185-3.708,2.966-4.264
      s2.781-0.649,1.946-2.224c-0.834-1.575-1.576-0.37-1.483-2.132c0.093-1.761,0.835-1.39,1.854-1.668s1.112-1.854-1.39-1.854
      c-2.503,0-2.966-1.298-4.82-1.298s-1.761-0.834-1.854-2.224s-1.205-1.575-1.946-3.429s-1.854-4.356-1.854-5.747
      c0-1.138-0.194-1.902-0.82-2.599c-0.329,0.594-0.703,1.287-0.802,1.534c-0.185,0.463-1.576,0.603-2.456,1.344
      c-0.88,0.742-1.993-1.344-2.595-2.271c-0.603-0.927-2.271-0.139-3.383-0.093s-2.456-0.185-2.92-0.788
      c-0.463-0.603-2.085-0.834-3.939-0.788c-1.853,0.046-1.946,1.112-2.595,2.039c-0.286,0.409-0.996,0.836-1.855,1.376l0,0
      c-0.127,0.08-0.257,0.162-0.39,0.247c-0.036,0.023-0.073,0.048-0.109,0.071c-0.099,0.064-0.199,0.129-0.299,0.196
      c-0.051,0.034-0.103,0.07-0.155,0.105c-0.088,0.06-0.177,0.121-0.266,0.184c-0.057,0.04-0.115,0.082-0.172,0.123
      c-0.084,0.061-0.169,0.124-0.254,0.187c-0.06,0.046-0.12,0.092-0.18,0.138c-0.082,0.064-0.163,0.13-0.244,0.197
      c-0.06,0.05-0.121,0.099-0.18,0.15c-0.081,0.07-0.162,0.143-0.242,0.216c-0.057,0.052-0.114,0.102-0.169,0.155
      c-0.087,0.083-0.172,0.169-0.256,0.256c-0.046,0.047-0.093,0.093-0.138,0.141c-0.128,0.138-0.254,0.279-0.374,0.427
      c-1.807,2.224-2.781,2.085-4.727,2.641s-2.224,3.337-2.363,5.005s0.278,2.502,0.834,3.754c0.556,1.251,0,1.529-0.695,3.476
      c-0.695,1.947-1.251,2.92-0.973,5.561c0.278,2.641-3.337,5.005-6.951,7.229c-3.615,2.224-9.315,4.032-13.346,6.812
      c-4.032,2.781-10.01,5.839-14.111,8.898s-6.812,1.46-8.064,0.973c-1.251-0.487-2.92-2.641-4.379-3.267s-1.599-2.433-2.016-2.781
      c-0.204-0.17-0.49-0.389-0.73-0.57c0.004,0.009,0.007,0.02,0.01,0.029c0.042,0.111,0.084,0.226,0.125,0.348
      c0.013,0.037,0.024,0.077,0.036,0.114c0.033,0.1,0.065,0.2,0.094,0.304c0.021,0.073,0.037,0.148,0.056,0.222
      c0.018,0.073,0.039,0.146,0.055,0.219c0.032,0.149,0.058,0.299,0.075,0.445c0.139,1.182-0.556,4.24-0.765,5.074
      s-0.209,1.807-0.07,2.363s0.139,3.406,0.139,4.171s-0.626,2.641-1.043,4.31c-0.417,1.668-0.556,2.711-1.043,3.684
      s-0.695,1.668-0.765,2.711c-0.069,1.043-0.904,3.962-0.904,5.074c0,0.991-0.884,3.466-2.206,4.389
      c0.001,0.043,0.005,0.09,0.005,0.129c0,0.069,0.005,0.131,0.013,0.19c0.002,0.016,0.005,0.031,0.008,0.047
      c0.007,0.044,0.016,0.085,0.027,0.124c0.003,0.011,0.007,0.023,0.01,0.034c0.169,0.549,0.637,0.718,0.305,2.151
      c-0.001,0.002-0.001,0.003-0.001,0.004c1.54-0.049,4.63-0.032,6.127,0.785c2.039,1.112,2.781,2.224,4.82,0.556
      s4.635-6.859,7.229-6.859s5.561,0.371,5.746-1.483s0.741-2.781,1.854-2.966c1.112-0.185,1.297-1.668,0.556-2.595
      c-0.741-0.927-0.741-1.669-2.039-2.41s-2.595-1.298-2.039-3.893c0.556-2.595,1.854-4.263,4.82-4.819s6.117-0.742,7.415-1.854
      c1.297-1.112,2.595-1.668,5.932-0.741c3.337,0.927,7.785-0.185,11.492,3.337c3.708,3.522,5.746,3.892,8.342,4.634
      c2.595,0.742,7.044,5.19,9.269,6.673c2.224,1.483,9.639,7.6,11.678,9.454c2.039,1.854,3.892,4.078,8.527,4.634
      c0.218,0.026,0.446,0.05,0.677,0.074c0.043,0.004,0.086,0.009,0.13,0.014c0.224,0.022,0.453,0.043,0.688,0.064
      c0.049,0.004,0.099,0.008,0.148,0.012c0.243,0.02,0.489,0.04,0.74,0.058c0.011,0.001,0.022,0.002,0.033,0.002
      c0.247,0.018,0.5,0.034,0.754,0.05c0.052,0.003,0.103,0.007,0.154,0.01c0.254,0.016,0.512,0.03,0.771,0.044
      c0.051,0.003,0.103,0.005,0.154,0.008c0.268,0.014,0.539,0.027,0.81,0.039c0.011,0.001,0.022,0.001,0.033,0.001
      c0.268,0.012,0.538,0.023,0.808,0.033c0.047,0.002,0.095,0.004,0.142,0.005c0.269,0.01,0.537,0.02,0.805,0.028
      c0.049,0.002,0.098,0.003,0.147,0.005c0.276,0.009,0.551,0.017,0.824,0.024c0.006,0,0.012,0,0.018,0
      c0.275,0.008,0.548,0.014,0.819,0.02c0.037,0.001,0.076,0.002,0.114,0.003c0.266,0.006,0.528,0.011,0.787,0.016
      c0.039,0.001,0.078,0.002,0.117,0.002c0.534,0.01,1.052,0.017,1.545,0.023c0.026,0,0.053,0.001,0.08,0.001
      c0.238,0.003,0.471,0.005,0.696,0.007c0.034,0.001,0.068,0.001,0.103,0.001c0.224,0.002,0.442,0.003,0.651,0.005
      c0.012,0,0.024,0,0.037,0c0.187,0.001,0.364,0.002,0.537,0.003c0.031,0,0.062,0,0.092,0c0.434-1.165,1.138-2.75,1.877-3.149
      c1.205-0.649,2.039-2.132,2.873-4.171c0.834-2.039,2.225-2.873,4.078-2.595s3.059,0.185,3.893,0.834
      c0.384,0.299,0.964,0.636,1.478,0.914c0.885-0.544,3.067-0.272,4.775-0.118C699.137,113.522,698.536,112.729,697.998,112.326z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M591.435,69.414
      c0.487,0.626,1.599,2.155,0.07,3.615s-0.695,2.781-2.016,2.989c-1.321,0.209-2.294,0.417-2.502,0.973s-0.139,2.155-0.487,2.92
      s-0.904,1.529-2.085,1.529c-0.099,0-0.181,0.02-0.257,0.049c-0.019,0.007-0.037,0.015-0.055,0.024
      c-0.068,0.033-0.129,0.074-0.18,0.128c-0.001,0.002-0.003,0.002-0.004,0.004c-0.053,0.056-0.095,0.125-0.134,0.199
      c-0.01,0.02-0.02,0.039-0.03,0.06c-0.033,0.07-0.062,0.144-0.087,0.223c-0.002,0.007-0.004,0.012-0.007,0.019
      c-0.027,0.086-0.05,0.176-0.072,0.267c-0.051,0.206-0.096,0.417-0.156,0.607c-0.005,0.016-0.011,0.032-0.016,0.048
      c-0.03,0.087-0.062,0.169-0.102,0.242c0.008,0.02,0.015,0.043,0.023,0.063c0.24,0.18,0.526,0.4,0.73,0.57
      c0.417,0.348,0.556,2.155,2.016,2.781c1.46,0.626,3.128,2.781,4.379,3.267c1.251,0.487,3.962,2.085,8.064-0.973
      c4.101-3.059,10.079-6.117,14.111-8.898c4.032-2.781,9.732-4.588,13.346-6.812c3.615-2.224,7.229-4.588,6.951-7.229
      s0.278-3.615,0.973-5.561s1.251-2.224,0.695-3.476c-0.556-1.251-0.973-2.085-0.834-3.754c0.139-1.668,0.417-4.449,2.363-5.005
      s2.919-0.417,4.727-2.641c0.12-0.148,0.245-0.289,0.374-0.427c0.045-0.049,0.092-0.094,0.138-0.141
      c0.085-0.087,0.169-0.174,0.256-0.256c0.056-0.053,0.112-0.104,0.169-0.155c0.08-0.073,0.16-0.146,0.242-0.216
      c0.06-0.051,0.12-0.1,0.18-0.15c0.081-0.067,0.162-0.133,0.244-0.197c0.06-0.047,0.12-0.093,0.18-0.138
      c0.085-0.064,0.169-0.126,0.254-0.187c0.057-0.042,0.115-0.083,0.172-0.123c0.089-0.063,0.178-0.124,0.266-0.184
      c0.052-0.035,0.104-0.071,0.155-0.105c0.101-0.067,0.2-0.132,0.299-0.196c0.036-0.024,0.073-0.048,0.109-0.071
      c0.133-0.085,0.263-0.167,0.39-0.247c-1.759-2.261-2.416-2.295-2.918-1.793c-0.556,0.556-1.576,0.371-2.781,0.927
      s-2.595,1.298-3.708,1.298c-1.112,0-2.132-0.093-3.615-1.112c-1.483-1.02-3.337,0.834-4.82,1.668
      c-1.483,0.834-3.244,1.668-4.263,2.317c-1.019,0.649-5.191,0.649-7.044,0.834s-3.986,0.371-5.098-1.112
      c-1.112-1.483-3.707-0.741-5.097-0.649c-1.39,0.093-2.595,1.112-3.708,1.112c-1.112,0-0.927,1.205-2.873,1.39
      c-1.946,0.185-1.668-0.649-3.059-1.761c-1.39-1.112-2.873,0-3.244,1.112s0,2.039,0.649,2.966s-0.278,1.02-1.668,1.298
      s-1.298,1.205-1.483,2.688c-0.185,1.483-2.595,1.205-3.244,0.741c-0.15-0.107-0.386-0.24-0.658-0.379
      c-0.572,1.073-1.357,1.968-0.417,3.85c1.196,2.391,0,2.391,0.797,4.982c0.483,1.57,0.306,2.19-0.085,3.055
      c0.892,0.176,2.313,0.545,2.912,1.19C589.766,68.44,590.948,68.788,591.435,69.414z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M815.614,153.107
      c0.185-1.298,1.946-1.854,3.708-4.634c1.761-2.781,7.322-2.224,8.62-2.595s0.926-2.224,1.761-3.8
      c0.834-1.576-1.946-1.297-3.522-2.224c-1.575-0.927-0.927-3.522-0.927-6.58c0-3.059-3.151-3.708-5.746-4.356
      c-2.595-0.649-5.098-4.171-5.561-5.654c-0.463-1.483-1.297-3.893-4.264-6.303c-0.109-0.088-0.206-0.175-0.298-0.262
      c-0.027-0.026-0.052-0.051-0.077-0.076c-0.064-0.063-0.124-0.126-0.18-0.189c-0.023-0.026-0.046-0.052-0.067-0.077
      c-0.059-0.07-0.113-0.14-0.163-0.209c-0.01-0.014-0.022-0.029-0.031-0.043c-0.059-0.087-0.111-0.173-0.156-0.258
      c-0.003-0.007-0.006-0.014-0.01-0.02c-0.04-0.077-0.073-0.154-0.102-0.23c-0.006-0.016-0.011-0.032-0.017-0.048
      c-0.025-0.071-0.046-0.142-0.061-0.213c-0.002-0.01-0.005-0.021-0.008-0.031c-0.034-0.167-0.044-0.329-0.033-0.487
      c0-0.001,0-0.001,0-0.002c0-0.002,0-0.003,0-0.005c0.007-0.082,0.018-0.162,0.035-0.241c0.001-0.005,0.002-0.01,0.003-0.014
      c0.232-1.055,1.337-1.888,1.924-2.37c0.006-0.005,0.013-0.01,0.018-0.015c0.017-0.014,0.034-0.028,0.05-0.042
      c0.026-0.022,0.051-0.043,0.074-0.063c0.011-0.01,0.022-0.019,0.033-0.029c0.033-0.029,0.062-0.056,0.087-0.081
      c0.649-0.649,1.854-1.761,2.502-2.781c0.649-1.02,2.132-1.761,2.966-2.781s1.112-2.873,0.649-3.8s-1.576-2.41-2.966-2.688
      s-3.985-0.185-5.097-0.464c-1.112-0.278-1.483-2.317-1.483-3.429s0.834-1.761,0.834-4.727s-0.371-2.224-1.205-2.595
      s-0.185-2.502-1.019-2.781c-0.834-0.278-0.463-1.39-0.463-2.595s0.463-1.298,2.503-3.059s-0.556-1.483-1.854-1.761
      s-0.648-2.966-0.648-4.449s0.556-2.317,1.946-2.41c1.39-0.093,0.556-1.39,0.556-2.224s0.463-1.854,1.668-2.688
      s0.463-2.502,0.463-3.522s0.463-1.39,1.112-2.873c0.056-0.129,0.098-0.27,0.134-0.416c0.008-0.033,0.016-0.065,0.023-0.099
      c0.03-0.148,0.054-0.3,0.068-0.458c0.001-0.01,0.001-0.02,0.002-0.029c0.013-0.159,0.017-0.323,0.016-0.489
      c0-0.023,0.001-0.045,0-0.068c-0.003-0.177-0.012-0.355-0.027-0.533c-0.012-0.145-0.028-0.289-0.047-0.431
      c-0.001-0.011-0.003-0.023-0.004-0.034c-0.017-0.134-0.037-0.267-0.059-0.396c-0.001-0.008-0.002-0.016-0.004-0.023
      c-0.117-0.705-0.275-1.311-0.348-1.612c-0.014-0.056-0.025-0.104-0.031-0.138c-0.093-0.464,0.278-1.946,0.556-2.781
      c0.278-0.834-0.093-1.39-0.741-2.224s-0.278-1.39-0.278-2.595s-4.542-0.278-6.117-0.278s-3.337-2.039-4.171-3.429
      c-0.834-1.39-0.927-1.854-3.337-1.854s-2.688-2.132-3.522-3.615s-4.912-1.576-6.951-1.576s-2.966-1.205-4.263-1.668
      c-1.297-0.464-3.8-0.649-4.264-1.297c-0.463-0.649-2.503-2.595-3.615-2.595s-1.39-0.556-2.688-1.019
      c-1.297-0.463-3.059,0.463-3.893,1.575c-0.834,1.112-2.966,0.927-5.283,0.556s-3.893,0-4.171,0.742s-1.298,0.742-2.781,1.576
      c-1.483,0.834-2.224,2.132-2.224,3.151c0,1.02-0.649,1.483-2.317,1.39c-1.668-0.093-1.761,1.205-3.522,1.02
      c-0.608-0.064-1.154-0.139-1.617-0.209c0.042,1.32,0.772,1.266,1.099,2.072c0.004,0.009,0.008,0.016,0.011,0.025
      c0.021,0.055,0.039,0.117,0.055,0.179c0.005,0.02,0.012,0.037,0.017,0.058c0.014,0.059,0.024,0.127,0.034,0.194
      c0.005,0.031,0.011,0.057,0.015,0.09c0.008,0.069,0.011,0.149,0.015,0.227c0.002,0.038,0.006,0.071,0.007,0.112
      c0.002,0.123,0,0.257-0.009,0.403c-0.139,2.363-3.893,0.834-6.256,0.834s-2.363,0.973-4.588,1.529
      c-2.224,0.556-3.198,0.556-4.449,1.251s-4.032,0.973-5.839-0.278s-3.754-0.834-5.7-1.807s-5.561-3.615-6.256-5.144
      s-2.224-1.668-4.31-1.946c-2.085-0.278-3.059-0.278-4.727-1.112c-1.668-0.834-2.363-3.059-2.363-5.422
      c0-1.038-0.161-1.727-0.376-2.315c-1.595,0.197-3.122,0.189-3.609-0.42c-0.742-0.927-1.483-0.927-2.224-1.576
      c-0.742-0.649-1.761-0.742-2.224-1.483s-0.185-1.946,1.205-2.41s0.927-2.132,0.185-2.966s0.278-1.205,0.278-2.781
      s-1.112-1.668-2.966-1.483s-1.854,1.576-3.43,1.576s-1.946,0.927-3.615,2.317s-2.317,2.132-4.356,3.707
      c-0.099,0.077-0.199,0.145-0.299,0.212h-0.001c-0.117,0.079-0.235,0.152-0.353,0.219c-0.005,0.003-0.011,0.006-0.016,0.009
      c-0.116,0.065-0.231,0.125-0.348,0.179c-0.007,0.004-0.015,0.007-0.022,0.011c-0.115,0.053-0.229,0.101-0.343,0.145
      c-0.009,0.003-0.017,0.007-0.027,0.01c-0.113,0.042-0.228,0.08-0.342,0.114c-0.009,0.002-0.018,0.005-0.027,0.008
      c-0.113,0.033-0.226,0.062-0.339,0.088c-0.009,0.002-0.019,0.004-0.028,0.006c-0.113,0.025-0.227,0.046-0.339,0.064
      c-0.009,0.001-0.017,0.003-0.026,0.004c-0.114,0.017-0.227,0.032-0.34,0.043c-0.007,0.001-0.014,0.001-0.021,0.002
      c-0.115,0.011-0.229,0.02-0.343,0.026c-0.004,0-0.009,0-0.014,0.001c-0.116,0.006-0.232,0.009-0.347,0.01l0,0
      c-0.694,0.005-1.367-0.064-1.999-0.11c-0.009-0.001-0.017-0.001-0.027-0.002c-0.112-0.008-0.223-0.015-0.332-0.021
      c-1.761-0.093-2.502-0.834-4.356-1.298c-1.853-0.464-0.741-1.668-2.039-1.853s-2.781-2.41-2.781-3.986s-0.556-1.761-1.668-2.317
      c-0.135-0.067-0.268-0.115-0.399-0.149c-0.097-0.024-0.191-0.032-0.286-0.038c-0.03-0.002-0.062-0.011-0.092-0.011
      c-0.122-0.001-0.24,0.012-0.355,0.039c0,0,0,0-0.001,0c-0.115,0.027-0.227,0.068-0.333,0.121c-0.469,0.233-0.843,0.705-1.037,1.335
      c-0.371,1.205-0.278,1.853-2.502,2.132s-0.741,2.873,0.186,4.727s0.926,1.576,0.926,3.059s0.186,1.854,1.205,3.615
      c1.019,1.761-1.39,2.317-1.668,3.893c-0.278,1.575,2.688,2.317,2.781,3.708c0.093,1.39,0.371,1.297,1.39,2.132
      c0.185,0.152,0.338,0.304,0.477,0.459c0.288-0.522,0.542-0.969,0.542-0.969s-0.254,0.447-0.542,0.969
      c0.626,0.698,0.82,1.462,0.82,2.599c0,1.39,1.112,3.893,1.854,5.747s1.854,2.039,1.946,3.429c0.093,1.39,0,2.224,1.854,2.224
      s2.317,1.298,4.82,1.298c2.502,0,2.41,1.576,1.39,1.854c-1.019,0.278-1.761-0.093-1.854,1.668
      c-0.093,1.761,0.649,0.556,1.483,2.132c0.834,1.575,0.834,1.668-1.946,2.224c-2.781,0.556-1.483,3.151-2.966,4.264
      c-1.483,1.112-0.186,3.059-1.112,5.19c-0.926,2.131,0.278,1.761,0.834,2.966c0.556,1.205,0.926,1.761,3.522,3.337
      c2.595,1.576,1.205,2.224,0.927,3.985s1.761,1.39,3.707,1.854s2.781,2.502,5.561,3.522c2.781,1.02,2.873,3.8,4.635,4.727
      s1.853,1.854,1.946,3.337c0.093,1.483-0.371,1.575-1.39,1.761s-0.649,4.449,1.297,5.098s1.668,1.668,1.39,2.688
      c-0.278,1.02-0.556,2.224,1.575,3.151c2.132,0.927,1.761,1.946,2.873,2.781c0.538,0.403,1.139,1.196,1.619,1.908
      c0.001,0,0.002,0,0.003,0c2.192,0.199,2.192-0.996,2.192-2.59c0-1.594,1.992-1.196,2.789,0.398c0.796,1.594,2.989,1.793,5.181,0.2
      c2.193-1.594,3.786,0.198,4.783,3.587s1.992,3.587,3.986,5.779s1.592,5.978,2.788,8.369c1.196,2.391,6.777,4.982,10.363,5.978
      c3.587,0.996,2.191,3.787,5.978,4.982c3.788,1.196,6.375,3.387,8.967,4.383s5.18,1.595,7.174,0.997s3.386-1.196,5.978-0.798
      c2.592,0.398,4.582-0.798,6.176-3.189c1.595-2.391,3.587-1.992,5.978-0.398c2.392,1.594,2.193,4.384,2.59,6.576
      c0.397,2.192,0.399,2.192,1.397,4.384c0.997,2.193,5.578,3.387,9.165,3.387s4.981,0.598,6.774,1.793c1.794,1.196,7.772,0,11.16,0.2
      s5.78,1.793,10.361,2.391c0.482,0.063,0.895,0.115,1.271,0.159c0.057-0.537,0.106-1.133,0.106-1.6
      C814.78,158.112,815.429,154.405,815.614,153.107z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M927.762,39.847
      c-1.298-0.278-2.41,0.464-3.244,1.02s-2.41,0.371-2.966-0.278s-2.039-0.834-4.171-0.556s-4.727,2.781-5.839,3.893
      s-3.151,1.205-4.357,2.317s-3.8,1.205-5.468-0.185c-1.668-1.39,0.278-4.171,0.093-5.098s-0.186-2.41,0.278-3.337
      s0.834-1.854-1.02-2.688s-0.926-1.483-1.205-3.429c-0.278-1.946-1.761-2.873-3.151-2.873s-2.502,3.244-4.078,4.078
      c-1.576,0.834-2.039,1.483-2.224,4.264s-2.132,1.483-4.171,1.112s-2.781,0.556-2.688,2.039s0.464,3.059-0.556,3.059
      s-1.483-1.02-3.337-2.224c-1.854-1.205-1.39,1.39-4.078,1.39s-2.502,2.224-4.264,2.41c-1.761,0.185-1.575-0.742-1.853-2.132
      c-0.016-0.081-0.039-0.152-0.066-0.216c-0.009-0.023-0.022-0.042-0.033-0.062c-0.02-0.038-0.04-0.075-0.063-0.106
      c-0.016-0.021-0.033-0.039-0.05-0.056c-0.023-0.025-0.048-0.049-0.074-0.07c-0.02-0.016-0.041-0.03-0.063-0.043
      c-0.028-0.016-0.056-0.031-0.086-0.044c-0.024-0.011-0.049-0.021-0.076-0.03c-0.031-0.01-0.065-0.017-0.098-0.024
      c-0.029-0.006-0.056-0.013-0.086-0.017c-0.035-0.005-0.072-0.006-0.109-0.008c-0.095-0.006-0.194-0.006-0.3,0.001
      c-0.312,0.022-0.672,0.09-1.054,0.151c-0.038,0.006-0.076,0.012-0.115,0.018c-0.06,0.009-0.12,0.017-0.182,0.026
      c-0.057,0.008-0.113,0.014-0.17,0.021c-0.053,0.006-0.106,0.012-0.16,0.017c-0.064,0.006-0.127,0.01-0.191,0.014
      c-0.048,0.003-0.097,0.006-0.146,0.007c-0.073,0.002-0.146,0.001-0.219-0.001c-0.041-0.001-0.081-0.001-0.121-0.003
      c-0.114-0.007-0.226-0.018-0.338-0.036c-1.668-0.278-2.503-0.927-3.059-1.853c-0.031-0.052-0.076-0.098-0.125-0.141
      c-0.015-0.013-0.031-0.024-0.047-0.036c-0.042-0.031-0.088-0.059-0.139-0.085c-0.018-0.009-0.035-0.019-0.054-0.028
      c-0.071-0.032-0.148-0.06-0.232-0.084c-0.015-0.004-0.033-0.008-0.048-0.011c-0.073-0.02-0.152-0.036-0.234-0.051
      c-0.031-0.005-0.063-0.01-0.096-0.015c-0.078-0.012-0.159-0.022-0.243-0.03c-0.028-0.003-0.055-0.006-0.085-0.009
      c-0.11-0.009-0.224-0.015-0.342-0.019c-0.038-0.001-0.078-0.002-0.116-0.002c-0.086-0.002-0.174-0.002-0.264-0.002
      c-0.049,0.001-0.099,0.001-0.149,0.002c-0.091,0.002-0.185,0.005-0.278,0.009c-0.042,0.002-0.084,0.003-0.126,0.005
      c-0.124,0.006-0.251,0.014-0.379,0.023c-0.067,0.005-0.134,0.01-0.201,0.015c-0.07,0.006-0.14,0.011-0.21,0.018
      c-0.075,0.007-0.151,0.013-0.226,0.021c-0.056,0.005-0.112,0.011-0.169,0.017c-0.166,0.017-0.331,0.035-0.495,0.054
      c-0.028,0.003-0.056,0.006-0.084,0.009c-0.483,0.057-0.953,0.121-1.379,0.179c-0.17,0.023-0.335,0.046-0.489,0.067
      c-2.039,0.278-2.595-0.649-3.615-1.575c-1.019-0.927-2.41-0.556-2.873,1.019c-0.464,1.575-2.595,2.132-4.356,2.132
      c-1.761,0-2.502,1.205-2.966,2.781c-0.463,1.576-1.483,2.41-1.576,4.356s-1.112,3.522-2.873,3.522s-1.946,1.761-4.078,2.039
      s-3.151,0.927-4.541,1.205s-1.298,1.205-0.649,2.224c0.649,1.02-1.019,1.483-1.668,2.41c-0.649,0.927-1.483,1.483-3.059,0.927
      c-1.575-0.556-2.41,0.742-2.873,1.576s-1.297-0.185-1.575-1.298c-0.278-1.112-2.41-1.298-3.151-1.298
      c-0.742,0-1.483,0.278-2.41-1.112c-0.527-0.791-1.774-0.921-2.751-0.887c0.015,0.178,0.024,0.356,0.027,0.533
      c0.001,0.023,0,0.045,0,0.068c0.001,0.166-0.003,0.329-0.016,0.489c-0.001,0.01-0.001,0.02-0.002,0.029
      c-0.014,0.159-0.038,0.311-0.068,0.458c-0.007,0.033-0.015,0.066-0.023,0.099c-0.036,0.146-0.077,0.287-0.134,0.416
      c-0.649,1.483-1.112,1.854-1.112,2.873s0.742,2.688-0.463,3.522s-1.668,1.854-1.668,2.688s0.834,2.132-0.556,2.224
      c-1.39,0.093-1.946,0.927-1.946,2.41s-0.65,4.171,0.648,4.449s3.893,0,1.854,1.761s-2.503,1.854-2.503,3.059
      s-0.371,2.317,0.463,2.595s0.185,2.41,1.019,2.781s1.205-0.37,1.205,2.595s-0.834,3.615-0.834,4.727s0.371,3.151,1.483,3.429
      s3.707,0.185,5.097,0.464s2.503,1.761,2.966,2.688s0.185,2.781-0.649,3.8c-0.834,1.02-2.317,1.761-2.966,2.781
      s-1.853,2.132-2.502,2.781c-0.025,0.025-0.055,0.052-0.087,0.081c-0.01,0.009-0.022,0.019-0.033,0.029
      c-0.023,0.02-0.048,0.041-0.074,0.063c-0.016,0.014-0.033,0.027-0.05,0.042c-0.005,0.005-0.012,0.01-0.018,0.015
      c-0.586,0.482-1.692,1.314-1.924,2.37c-0.001,0.005-0.002,0.01-0.003,0.014c-0.017,0.079-0.029,0.16-0.035,0.241
      c0,0.002,0,0.003,0,0.005c1.401,0.794,3.533,1.912,4.819,2.146c2.039,0.371,2.873,0.464,4.264,1.298
      c1.39,0.834,2.965,1.019,4.541,1.019s4.82,0.093,7.971,0.093s5.19,0.648,7.322-0.371c2.132-1.019,3.8-1.205,5.932-1.205
      s4.727,0.093,6.21-0.371c1.483-0.464,2.503-0.278,4.634-0.834c2.132-0.556,2.41-1.02,2.224-3.708s-1.854-6.209,0.927-7.971
      c2.781-1.761,1.483-2.039,2.966-2.966c1.483-0.927,2.132-1.946,3.151-0.649s0.927,0.649,2.224,0.649s3.059-0.463,3.059-1.205
      c0-0.742-2.317-1.483-2.039-2.41s1.853-0.742,2.966-0.464c1.112,0.278,1.761-0.556,2.595-1.019
      c0.834-0.464,3.892-1.205,4.912,0.185c1.02,1.39,2.596,1.853,2.966,0.834s0.834-1.39,1.668-1.946
      c0.834-0.556,0.926-1.112,0.926-2.41s-1.575-1.854-1.205-2.966s1.668-1.483,1.668-3.244s0.185-3.985,2.873-3.985
      s3.429-0.185,3.893-1.112s2.318-1.39,0.742-3.059c-1.576-1.668-2.503-2.502-2.688-3.337c-0.185-0.834-0.278-1.761,1.576-1.761
      s2.409,0.648,2.595,1.019s1.854,0,3.059,0s0.649-1.112,2.132-1.205c1.483-0.093,1.761-0.742,1.575-2.132
      c-0.185-1.39-0.464-1.668-1.205-2.317s-0.556-1.112,0.742-2.132c1.297-1.019,4.449-1.946,4.171-4.356
      c-0.278-2.41,0.278-4.449-0.649-5.283s-0.835-2.502-2.039-2.781c-1.205-0.278-1.112-1.483,0.186-2.224
      c1.297-0.742,1.483-1.483,3.059-1.668c1.575-0.185,1.297-0.834,2.41-1.668c1.112-0.834,3.522-1.669,3.893-2.595
      c0.371-0.927,2.317-0.926,5.098-1.019c2.781-0.093,5.375-0.371,7.693-0.185c2.317,0.185,5.283,0.185,5.839-0.742
      c0.067-0.112,0.139-0.21,0.214-0.297c0.022-0.026,0.047-0.047,0.07-0.071c0.055-0.058,0.11-0.115,0.169-0.164
      c0.026-0.021,0.052-0.038,0.078-0.058c0.061-0.046,0.123-0.091,0.188-0.131c0.019-0.012,0.039-0.022,0.059-0.033
      c0.396-0.227,0.848-0.362,1.331-0.617c0.069-0.036,0.138-0.07,0.208-0.112c0.927-0.556,1.112-0.649,1.946-2.317
      c0.042-0.084,0.073-0.168,0.111-0.252C930.937,40.056,928.856,40.082,927.762,39.847z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M814.132,59.126
      c0.927,1.39,1.668,1.112,2.41,1.112c0.742,0,2.873,0.185,3.151,1.298c0.278,1.112,1.112,2.132,1.575,1.298s1.298-2.132,2.873-1.576
      s2.41,0,3.059-0.927c0.649-0.927,2.317-1.39,1.668-2.41s-0.741-1.946,0.649-2.224s2.409-0.927,4.541-1.205s2.318-2.039,4.078-2.039
      c1.761,0,2.78-1.575,2.873-3.522c0.093-1.946,1.113-2.781,1.576-4.356c0.463-1.576,1.205-2.781,2.966-2.781
      s3.892-0.556,4.356-2.132c0.464-1.575,1.854-1.946,2.873-1.019s1.575,1.853,3.615,1.575c0.154-0.021,0.319-0.044,0.489-0.067
      c0.426-0.059,0.896-0.122,1.379-0.179c0.005,0,0.009-0.001,0.014-0.002c0.044-1.587,0.351-3.034,0.573-4.479
      c0.278-1.807-0.834-1.251-1.946-2.363s-4.31-1.807-7.368-2.224c-3.059-0.417-6.812-2.92-9.315-5.839
      c-2.502-2.92-5.978-1.946-7.229-3.893c-1.251-1.946-6.117-5.144-8.62-6.673s-2.641-1.39-3.059-4.032
      c-0.417-2.641-1.807-1.668-2.781-4.032s-1.112-3.476-1.807-5.7s-4.032-2.085-5.422-0.139s-4.866-0.834-7.646-0.695
      c-2.781,0.139-3.059-2.502-2.502-4.866c0.556-2.363-0.834-2.502-0.834-3.893s-1.668-2.641-4.727-2.641
      c-3.059,0-4.171-1.529-5.422-3.337c-1.251-1.807-3.337-1.807-4.171-0.278s-0.556,2.085-2.641,2.224
      c-2.085,0.139-1.946,0.139-2.085,2.085c-0.139,1.946-1.946,0.973-4.449,0.834c-2.502-0.139-2.781,2.502-3.754,4.171
      c-0.973,1.668,0.417,2.641,0.973,4.032s-4.31,1.39-6.117,0.973c-0.203-0.047-0.4-0.08-0.593-0.107
      c-0.059-0.008-0.116-0.014-0.174-0.02c-0.14-0.015-0.279-0.026-0.414-0.032c-0.056-0.002-0.113-0.006-0.168-0.007
      c-0.179-0.003-0.355,0-0.529,0.012c-0.033,0.002-0.066,0.007-0.099,0.01c-0.143,0.012-0.286,0.028-0.428,0.049
      c-0.067,0.01-0.135,0.021-0.203,0.033c-0.11,0.019-0.219,0.04-0.328,0.063c-0.069,0.015-0.138,0.029-0.208,0.045
      c-0.139,0.033-0.278,0.069-0.418,0.107c-0.018,0.005-0.037,0.009-0.055,0.014c-0.405,0.113-0.817,0.246-1.247,0.389
      c-1.668,0.556-4.171,0-5.422-1.668s-2.641-4.727-3.198-6.534c-0.556-1.807-3.198-0.834-4.588-2.502s-2.502-1.251-5.561-0.973
      s-2.363,1.946-4.866,1.946c-2.503,0-3.476,1.39-4.727,3.198c-0.65,0.939-1.636,1.613-2.435,2.046
      c0.219,0.194,0.475,0.368,0.746,0.515c0.004,0.003,0.009,0.005,0.014,0.008c0.128,0.069,0.258,0.131,0.391,0.187
      c0.02,0.008,0.039,0.016,0.059,0.024c0.13,0.053,0.261,0.102,0.392,0.141c1.39,0.417,0.834,2.224,2.502,3.059
      s0.973,2.085,0.973,3.615s-0.139,2.641-1.529,3.059s-0.695,4.171-0.278,5.144s0.973,1.946,1.251,3.476
      c0.278,1.529,1.39,1.668,1.251,0.834c-0.139-0.834,0.695-2.502,1.807-2.502s2.224,0.278,2.085,1.946s-1.946,2.085-2.92,2.641
      c-0.973,0.556-0.139,2.224-1.39,4.032c-1.251,1.807,0.139,2.92,1.251,4.032s0.417-2.085,1.112-2.781s0.278-1.946,0.834-3.059
      c0.556-1.113,2.224,1.39,4.31,2.502s1.946,2.781,0.695,4.31s-0.417,6.117-1.112,8.064s0.973,3.893,0.973,5.839
      c0,0.042,0.004,0.075,0.005,0.115c0.463,0.071,1.009,0.146,1.617,0.209c1.761,0.185,1.854-1.112,3.522-1.02
      c1.668,0.093,2.317-0.371,2.317-1.39c0-1.02,0.742-2.317,2.224-3.151c1.483-0.834,2.502-0.834,2.781-1.576
      c0.278-0.742,1.854-1.112,4.171-0.742c2.317,0.371,4.449,0.556,5.283-0.556c0.834-1.112,2.595-2.039,3.893-1.575
      c1.297,0.463,1.575,1.019,2.688,1.019c1.112,0,3.151,1.947,3.615,2.595c0.463,0.649,2.966,0.834,4.264,1.297
      c1.297,0.464,2.224,1.668,4.263,1.668s6.117,0.093,6.951,1.576s1.113,3.615,3.522,3.615c2.41,0,2.503,0.464,3.337,1.854
      s2.595,3.429,4.171,3.429s6.117-0.927,6.117,0.278c0,1.205-0.37,1.761,0.278,2.595s1.019,1.39,0.741,2.224
      c-0.278,0.834-0.649,2.317-0.556,2.781c0.007,0.033,0.018,0.081,0.031,0.138c0.073,0.3,0.23,0.906,0.348,1.612
      c0.002,0.008,0.003,0.015,0.004,0.023c0.022,0.13,0.041,0.262,0.059,0.396c0.002,0.012,0.003,0.023,0.004,0.034
      c0.018,0.143,0.035,0.286,0.047,0.431C812.358,58.204,813.604,58.335,814.132,59.126z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M705.529-54.965
      c0.002-0.007,0.004-0.013,0.005-0.02c0.034-0.135,0.069-0.283,0.11-0.447c0.278-1.112,1.668-1.946,3.893-2.085
      s2.085-0.834,2.781-2.085c0.695-1.251,1.251-1.529,3.198-1.529c1.946,0,2.641-0.695,3.615-1.529
      c0.973-0.834,1.39-1.251,3.059-1.251s5.283,1.668,6.117,2.363s3.198,0.695,3.754-0.139s2.224-0.556,3.893-1.112
      c1.668-0.556,3.059,1.807,3.754,2.641s0.139,1.668-0.973,1.807s-0.695,1.529,0.556,2.085c1.251,0.556,0,2.224,0,4.31
      s-2.92,1.529-3.337,2.502s0.417,2.502,2.085,3.476c1.668,0.973-0.278,1.112-1.807,0.834s-1.112-0.973-1.946-0.973
      s-5.283,0.278-7.368,0.278s-3.476,0.417-3.754,1.807s-1.112,2.781-2.781,3.059c-1.668,0.278,0,2.224,1.946,2.502
      s1.251,1.946,0,2.224s-2.363,0.139-3.893-0.695c-1.529-0.834-2.224-0.556-3.476-0.139c-1.251,0.417-1.668,2.363-1.39,3.337
      c0.278,0.973,1.251,0.695,3.476,1.529s3.476,4.588,4.31,5.978s0.973,2.085,0.973,3.615s0.834,1.807,2.781,1.807
      s1.668,1.112,2.502,2.502s4.032,0.834,5.561,0.973s2.224,2.502,2.085,4.31c-0.139,1.807-0.556,2.085-2.224,2.363
      c-1.668,0.278-0.417,2.641-1.251,3.893c-0.058,0.086-0.102,0.173-0.137,0.259c-0.011,0.027-0.017,0.053-0.027,0.08
      c-0.021,0.06-0.039,0.12-0.049,0.18c-0.005,0.029-0.008,0.058-0.011,0.087c-0.006,0.059-0.009,0.117-0.007,0.175
      c0.001,0.026,0.003,0.053,0.005,0.079c0.007,0.064,0.019,0.128,0.036,0.191c0.004,0.018,0.008,0.036,0.013,0.053
      c0.105,0.342,0.346,0.663,0.662,0.942c0.799-0.433,1.785-1.107,2.435-2.046c1.251-1.807,2.224-3.198,4.727-3.198
      c2.502,0,1.807-1.668,4.866-1.946s4.171-0.695,5.561,0.973c1.39,1.668,4.032,0.695,4.588,2.502s1.946,4.866,3.198,6.534
      s3.754,2.224,5.422,1.668c0.43-0.143,0.842-0.277,1.247-0.39l-0.135-39.65c0,0,4.588-0.834,6.951-1.807s4.032-1.946,6.812-2.502
      c2.541-0.508,7.512-2.632,10.048-1.829c0.223-0.767,0.444-1.508,0.683-2.033c0.638-1.403,1.53-2.933,2.805-2.933
      c1.275,0,1.785,0.638,2.295,1.658s0.638,1.148,2.168,0.638s0.255-3.188,1.147-4.973c0.892-1.785,3.061,0.383,3.061,0.383
      c2.04,1.658,3.825,0.765,5.228,0.765c1.402,0,1.276-1.403,0.893-1.913s-2.295-0.893-4.591-2.168s1.658-2.168,3.315-1.658
      s2.933,1.02,4.973,1.785c2.04,0.765,1.275,1.403,0,2.423s-1.148,1.02-2.55,1.403s-0.765,2.55-1.785,4.08
      c-1.02,1.53-0.51,3.443,0.765,3.953s0.893,1.275,0.893,3.315s1.02,1.53,1.02,3.06s-0.128,1.148-1.403,1.657
      c-0.318,0.127-0.669,0.39-1.021,0.734c0.682,0.498,1.263,1.01,1.667,1.523c2.085,2.641,6.117,5.144,7.646,7.368
      s1.668,3.615,4.866,3.198s5.561-2.085,9.593-1.39c4.032,0.695,8.898,1.946,10.705,0.556s4.032-3.059,6.951,1.112
      c2.92,4.171,3.198,5.839,5.422,5.978s1.529,2.085,1.39,4.31s1.112,3.476,2.641,5.422s2.224,4.449,2.641,6.534
      s0.556,2.92,3.198,2.92s7.785-1.946,8.481,0.695s-0.139,3.754,1.39,4.171s3.754,2.224,3.615,0.417
      c-0.139-1.807-1.668-2.781,0.139-3.615s2.085-3.615,4.727-4.727c2.642-1.112,2.92-1.807,4.449-2.641s2.502-0.556,4.032-2.085
      c1.53-1.529,2.502-3.059,4.31-3.059c1.807,0,2.502-0.973,3.615-3.476c0.701-1.578,2.177-2.712,3.729-3.336
      c0.01-0.004,0.021-0.008,0.031-0.012c0.14-0.056,0.281-0.108,0.421-0.156c0.025-0.008,0.049-0.015,0.074-0.023
      c0.128-0.042,0.255-0.081,0.381-0.116c0.042-0.011,0.083-0.02,0.125-0.03c0.109-0.028,0.219-0.056,0.327-0.078
      c0.062-0.013,0.123-0.022,0.184-0.033c0.088-0.016,0.176-0.034,0.263-0.046c0.085-0.012,0.167-0.019,0.251-0.027
      c0.062-0.006,0.125-0.015,0.186-0.02c0.143-0.011,0.285-0.016,0.423-0.016c2.224,0,5.561,0.139,7.229,1.251s4.032,1.112,5.7,1.39
      s3.337,0.417,3.337-1.529s0.278-3.615,1.529-4.171s2.224-3.198,4.727-1.807c2.502,1.39,2.781,3.476,5.422,3.476
      c2.642,0,4.032,2.502,7.09,1.251c3.059-1.251,4.31-1.39,6.812-1.112s5.144-1.112,7.368-0.556s3.615,0.556,7.229,0.834
      c3.615,0.278,5.7,0.834,7.646,1.39s2.503,0.278,3.476,1.668s2.085,1.946,3.476,2.224c0.859,0.172,2.407,0.449,3.859,0.67
      c-0.016-0.173-0.032-0.346-0.047-0.52c-0.002-0.022-0.003-0.044-0.005-0.067c-0.012-0.147-0.022-0.292-0.032-0.437
      c-0.003-0.046-0.005-0.091-0.008-0.137c-0.008-0.123-0.013-0.245-0.017-0.365c-0.002-0.055-0.003-0.11-0.005-0.164
      c-0.003-0.109-0.003-0.216-0.003-0.322c0-0.059,0-0.117,0.002-0.174c0.002-0.099,0.007-0.194,0.013-0.288
      c0.003-0.056,0.006-0.114,0.011-0.169c0.008-0.092,0.02-0.178,0.032-0.264c0.006-0.05,0.012-0.102,0.021-0.149
      c0.016-0.089,0.037-0.17,0.058-0.25c0.01-0.038,0.017-0.078,0.029-0.114c0.035-0.112,0.076-0.215,0.124-0.307
      c0.741-1.39,3.337-1.298,3.8-2.688s1.854-1.112,1.112-3.615c-0.741-2.502-0.093-4.171-1.761-5.376
      c-1.668-1.205-1.483-1.761-1.575-4.356c-0.092-2.595,0.649-6.302-1.297-6.302s-2.503-0.741-2.224-2.224
      c0.278-1.483,3.708-0.464,3.893-1.298s5.098,0.464,5.839-0.834c0.741-1.298,2.873-2.039,4.634-1.668s1.39,1.853,4.078,2.039
      c2.688,0.185,4.541,0.186,5.005-1.019s0.557-2.224-0.926-2.224s-1.946-0.927-1.761-1.946s1.019-6.025,2.688-7.137
      c1.668-1.112,1.02-1.112,1.576-3.337c0.556-2.224,1.112-3.614,1.668-4.819s0.463-3.615,3.8-2.039s1.947,2.688,5.098,2.41
      s4.913-0.464,6.395,0.371c1.483,0.834,2.503,0.742,3.615-0.371c1.112-1.112,1.761-1.483,2.873-1.668s1.946-0.649,1.761-2.317
      s0.279-2.317-0.463-3.429c-0.742-1.112-0.926-2.781-0.834-4.171s1.204-5.19,4.078-5.19s6.58,0,6.858-3.151
      c0.278-3.151-0.371-3.615,1.112-4.541c0.146-0.091,0.281-0.189,0.409-0.289c0.019-0.014,0.038-0.029,0.057-0.043
      c0.122-0.099,0.235-0.201,0.338-0.307c0.015-0.015,0.029-0.03,0.044-0.046c0.104-0.11,0.2-0.222,0.281-0.336
      c0.002-0.003,0.004-0.006,0.006-0.009c0.086-0.121,0.159-0.243,0.216-0.366c0-0.001,0-0.001,0.001-0.002
      c0.077-0.165,0.126-0.329,0.147-0.49c0.001-0.008,0-0.017,0.001-0.025c0.007-0.068,0.009-0.135,0.005-0.201
      c-0.002-0.02-0.005-0.04-0.007-0.06c-0.006-0.054-0.016-0.107-0.03-0.159c-0.006-0.024-0.015-0.048-0.023-0.071
      c-0.017-0.047-0.037-0.094-0.06-0.139c-0.013-0.025-0.026-0.049-0.041-0.073c-0.027-0.043-0.058-0.086-0.092-0.127
      c-0.019-0.023-0.036-0.046-0.057-0.069c-0.04-0.043-0.087-0.084-0.135-0.124c-0.022-0.018-0.041-0.037-0.065-0.055
      c-0.076-0.056-0.159-0.111-0.252-0.16c-1.39-0.742-1.946-1.112-1.946-2.41s-1.39-3.059-2.781-1.298
      c-1.39,1.761-5.005,2.688-7.229,1.761s-3.615-1.019-4.635-3.059c-1.019-2.039,0.279-3.986-2.595-4.264
      c-2.873-0.278-4.727-0.093-5.654-2.595s-2.78-5.839-4.912-6.395c-2.132-0.556-4.357-0.463-6.21,0.742
      c-1.854,1.205-3.8,0.834-4.542,1.946c-0.741,1.112-1.761,0.834-3.059-0.185c-1.297-1.02-2.873-0.927-3.615-0.834
      s-1.39-0.185-2.039-1.298c-0.649-1.112-2.502,1.205-2.502-1.297s-1.761-3.244-2.873-3.522s-2.781,1.298-2.873,2.688
      s-2.595,6.117-5.005,2.781s-9.083-17.517-12.419-20.854c-3.337-3.337-7.137-9.639-10.937-11.956s-7.693-3.8-6.58-5.468
      c1.112-1.668,3.244-2.132,3.244-3.615s-1.668-0.649-3.615-0.371c-1.946,0.278-3.799,1.02-4.541,1.946s-0.904,1.066-2.573,1.066
      c-1.668,0-2.85-0.209-2.85,0.834s-0.348,2.363-2.155,2.502c-1.807,0.139-3.128-0.278-3.476,0.348s-1.46,1.112-1.599,1.877
      s0.278,3.337-0.973,2.363c-1.251-0.973-2.224-1.251-3.059-1.668c-0.834-0.417-2.294-0.139-2.919,0.07
      c-0.626,0.209-1.738,3.476-3.198,2.224s-2.363-2.781-0.834-3.337s1.668-0.834,2.155-1.807s1.946-1.46,1.112-2.363
      c-0.834-0.904-1.668-0.626-2.503,0.556c-0.834,1.182-2.433,1.112-3.406,0.209c-0.973-0.904-1.807-2.781-2.363-1.529
      c-0.556,1.252-1.738,2.85-2.155,1.39c-0.417-1.46-1.182-2.294-1.807-2.781s-0.417-2.781-1.668-1.529
      c-1.251,1.252-2.224,1.321-2.92,1.321s-0.834,2.502-2.433,1.529s-5.561-1.668-4.518-2.224c1.043-0.556,1.599-1.46,1.529-2.711
      c-0.07-1.251-0.209-0.765-0.973-2.085c-0.765-1.321-0.973-1.946-1.321-3.893c-0.348-1.946-0.973-2.989-2.016-3.406
      c-1.043-0.417-2.294-0.626-2.572,0.278s-0.695,2.224-2.711,1.251s-2.016-1.668-5.005-2.641s-4.588-0.139-6.117,0.973
      s-4.101-0.626-5.074,2.155c-0.973,2.781-2.92,2.502-5.839,2.781c-2.92,0.278-12.651,2.641-14.876,2.92
      c-2.224,0.278-4.31,0.417-5.422,1.946c-1.112,1.529-3.476,1.668-6.117,1.668s-5.283-0.278-6.256,0.834
      c-0.973,1.112-3.893,0.278-5.7,1.529s-2.92,1.251-5.422,1.668c-2.502,0.417-4.171-1.39-5.422-0.139
      c-1.251,1.251-2.92,1.112-3.893,0.417c-0.973-0.695-2.455-0.556-3.058-0.185c-0.603,0.371-2.92,0.093-2.317,1.437
      c0.602,1.344,0.695,2.641,0.417,3.29s-0.14,1.252,0.741,1.622c0.881,0.371,2.179,0.232,2.132,1.483s-0.278,1.993,1.668,2.271
      c1.946,0.278,3.846,0.139,4.078,0.741c0.232,0.603-0.093,2.827-2.039,2.41s-6.21-1.576-6.951-0.834s-0.556,2.085-1.529,2.456
      c-0.973,0.371-1.158,1.53,0.232,2.456c1.39,0.927,1.947,2.873,0.371,3.893c-1.576,1.019-2.271,1.344-3.522,1.714
      c-1.251,0.371-2.224,0.834-2.827,0.927c-0.602,0.093-1.019,1.39,0.695,2.039s1.853,2.873,3.244,2.92
      c1.39,0.046,2.549-0.139,3.198,0.417s0.648,1.158,2.224,1.483c1.576,0.324,3.151,1.993,3.708,2.456
      c0.556,0.463,0.649,1.622-0.834,2.688s-1.9,3.198-2.966,3.198s-1.715,1.298-3.105,1.298s-4.31,0.093-5.005-0.927
      s-1.668-2.271-2.827-0.371s-2.966,4.82-4.171,3.105s-1.854-2.224-3.476-2.224s-2.827-0.278-3.661-1.344s-2.178-3.708-3.43-3.661
      c-1.251,0.046-4.82-0.417-4.959,0.417s-1.483,3.012-2.734,2.317c-1.251-0.695-3.383-2.549-4.681-2.456
      c-1.297,0.093-2.503,1.298-3.847,0.973c-1.344-0.324-1.715,0.463-2.781,1.761s-2.085,2.92-3.615,3.059
      c-1.529,0.139-2.688-0.232-5.283-2.363c-2.595-2.131-3.986-2.317-3.986-1.205s0.463,4.449-1.205,4.263
      c-1.668-0.185,1.02-2.502-1.854-4.171c-2.873-1.668-3.522-3.429-5.19-3.8s-1.483-3.522-3.986-3.429
      c-2.503,0.093-6.859,1.019-7.322-0.556s-1.39-2.224-2.873-2.224s-3.059-0.556-3.151,0.927c-0.093,1.483-1.483,2.781-3.615,1.761
      s-1.761-2.688-3.8-2.781s-2.595,0.927-3.43,2.595s-2.503,3.151-4.727,3.337c-2.224,0.185-3.986,0.464-4.542,1.854
      c-0.556,1.39-1.575,3.8-4.356,4.263c-2.781,0.464-3.151-0.093-3.151,1.668s-1.205,3.615,0.278,4.449
      c1.483,0.834,2.039,1.483,0.556,2.595s-3.244,2.781-4.912,0.093c-1.668-2.688-3.151-3.337-3.986-4.356s-1.854-1.761-3.059-0.463
      s-0.092,3.151-1.854,4.078c-1.761,0.927-2.503,2.317-3.059,3.429s-1.019,2.966-0.185,3.985c0.834,1.02,2.595,2.41,1.019,3.707
      s-2.966,2.502-3.244,3.8s-2.132,1.576-0.464,3.059s4.356,0.834,4.449,2.873c0.093,2.039,0.463,5.19,1.946,5.19
      s2.225-1.019,3.522-0.649s4.264,0.371,5.283,2.039c1.019,1.668,2.317,4.171,3.337,4.912s2.502,2.781,2.41,3.707
      c-0.093,0.927-4.356,2.595-1.854,3.707c2.029,0.902,3.51,1.134,5.382,2.969l0,0C705.459-54.704,705.493-54.827,705.529-54.965z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M764.783-0.749
      c0.07-0.016,0.139-0.03,0.208-0.045c0.109-0.023,0.219-0.044,0.328-0.063c0.067-0.011,0.135-0.023,0.203-0.033
      c0.142-0.02,0.285-0.037,0.428-0.049c0.033-0.003,0.066-0.008,0.099-0.01c0.174-0.012,0.351-0.015,0.529-0.012
      c0.055,0.001,0.112,0.005,0.168,0.007c0.136,0.006,0.274,0.017,0.414,0.032c0.058,0.007,0.116,0.012,0.174,0.02
      c0.193,0.027,0.39,0.06,0.593,0.107c1.807,0.417,6.673,0.417,6.117-0.973s-1.946-2.363-0.973-4.032
      c0.973-1.668,1.251-4.31,3.754-4.171c2.502,0.139,4.31,1.112,4.449-0.834s0-1.946,2.085-2.085s1.807-0.695,2.641-2.224
      s2.92-1.529,4.171,0.278c1.251,1.807,2.363,3.337,5.422,3.337s4.727,1.251,4.727,2.641s1.39,1.529,0.834,3.893
      c-0.556,2.363-0.278,5.005,2.502,4.866c2.781-0.139,6.256,2.641,7.646,0.695s4.727-2.085,5.422,0.139s0.834,3.337,1.807,5.7
      s2.363,1.39,2.781,4.032c0.417,2.641,0.556,2.502,3.059,4.032c2.502,1.529,7.368,4.727,8.62,6.673
      c1.251,1.946,4.727,0.973,7.229,3.893c2.503,2.92,6.256,5.422,9.315,5.839s6.256,1.112,7.368,2.224s2.224,0.556,1.946,2.363
      c-0.222,1.445-0.529,2.892-0.573,4.479c0.023-0.003,0.047-0.005,0.07-0.008c0.164-0.019,0.329-0.037,0.495-0.054
      c0.056-0.006,0.113-0.012,0.169-0.017c0.076-0.007,0.151-0.014,0.226-0.021c0.071-0.006,0.14-0.012,0.21-0.018
      c0.068-0.005,0.135-0.011,0.201-0.015c0.128-0.009,0.255-0.017,0.379-0.023c0.043-0.002,0.084-0.003,0.126-0.005
      c0.094-0.004,0.187-0.007,0.278-0.009c0.05-0.001,0.099-0.002,0.149-0.002c0.09-0.001,0.178,0,0.264,0.002
      c0.039,0.001,0.078,0.001,0.116,0.002c0.118,0.004,0.232,0.01,0.342,0.019c0.029,0.002,0.056,0.006,0.085,0.009
      c0.085,0.008,0.166,0.018,0.243,0.03c0.033,0.005,0.064,0.01,0.096,0.015c0.083,0.015,0.16,0.032,0.234,0.051
      c0.015,0.004,0.033,0.007,0.048,0.011c0.085,0.024,0.161,0.053,0.232,0.084c0.019,0.009,0.036,0.018,0.054,0.028
      c0.051,0.026,0.097,0.054,0.139,0.085c0.016,0.012,0.032,0.023,0.047,0.036c0.049,0.043,0.094,0.089,0.125,0.141
      c0.556,0.927,1.39,1.575,3.059,1.853c0.112,0.019,0.225,0.03,0.338,0.036c0.04,0.002,0.081,0.002,0.121,0.003
      c0.073,0.002,0.146,0.003,0.219,0.001c0.049-0.001,0.098-0.004,0.146-0.007c0.064-0.004,0.128-0.008,0.191-0.014
      c0.054-0.005,0.107-0.01,0.16-0.017c0.057-0.007,0.113-0.013,0.17-0.021c0.061-0.008,0.122-0.017,0.182-0.026
      c0.039-0.006,0.077-0.012,0.115-0.018c0.382-0.061,0.741-0.129,1.054-0.151c-0.031-0.982,0.112-2.491,1.196-3.123
      c1.668-0.973,1.112-3.198,2.781-3.476c1.668-0.278,1.946-1.112,1.807-2.781c-0.139-1.668-0.834-2.085-1.668-2.641
      c-0.834-0.556-1.39-1.112-1.251-2.641s2.224-4.032-0.139-3.893s-3.476,0.556-4.588-1.112s-2.224-2.085-1.946-3.198
      c0.278-1.112,1.807-1.251,2.641-1.529s2.085-2.085,3.337-0.556c1.251,1.529,3.059,1.112,5.005,0.973s0.278-2.781,1.807-3.615
      s0-2.085-0.278-2.641s0.139-1.529,2.085-1.529c1.946,0,3.337-0.973,3.337-3.059s2.224-3.198,2.92-2.363s2.781,1.668,4.032-0.278
      s1.946-1.807,2.781-1.946s2.503,0.556,2.92,1.251s0.139,3.337-0.834,3.754c-0.973,0.417-2.502,2.085-1.112,2.641
      c0.103,0.041,0.215,0.07,0.331,0.093c0.033,0.007,0.068,0.011,0.103,0.016c0.09,0.014,0.181,0.023,0.277,0.028
      c0.034,0.002,0.067,0.004,0.102,0.004c0.125,0.003,0.254,0.001,0.387-0.007c0.001,0,0.002,0,0.002,0
      c0.15-0.01,0.303-0.026,0.459-0.046c0.538-0.07,1.106-0.192,1.647-0.292c0.065-0.012,0.13-0.024,0.194-0.035
      c0.068-0.012,0.137-0.024,0.205-0.034c0.106-0.017,0.211-0.032,0.314-0.045c0.033-0.004,0.066-0.009,0.098-0.013
      c0.131-0.015,0.256-0.023,0.378-0.028c0.037-0.002,0.072-0.001,0.109-0.001c0.091-0.001,0.178,0.002,0.262,0.009
      c0.035,0.003,0.07,0.005,0.103,0.009c0.11,0.014,0.216,0.033,0.311,0.065c1.251,0.417,3.337,1.112,4.588,1.112
      s3.059,0.834,3.198-0.973s1.112-2.363,3.476-2.363c2.363,0,3.198-0.695,3.893-1.39s3.198-1.251-0.834-2.781
      s-3.893-0.278-5.283-2.363s-3.893-5.422-4.727-3.198s-2.363,4.449-5.144,3.059s-2.502-3.059-4.727-3.059s-2.92-1.668-1.251-2.641
      s4.449-2.224,5.561-3.476c0.941-1.059,1.674-2.915,0.196-4.977c-0.018,0.037-0.04,0.073-0.057,0.111
      c-1.112,2.502-1.807,3.476-3.615,3.476c-1.807,0-2.781,1.529-4.31,3.059s-2.502,1.251-4.032,2.085
      c-1.529,0.834-1.807,1.529-4.449,2.641s-2.92,3.893-4.727,4.727s-0.278,1.807-0.139,3.615c0.139,1.808-2.085,0-3.615-0.417
      s-0.695-1.529-1.39-4.171s-5.839-0.695-8.481-0.695s-2.781-0.834-3.198-2.92c-0.417-2.085-1.112-4.588-2.641-6.534
      s-2.781-3.198-2.641-5.422s0.834-4.171-1.39-4.31s-2.503-1.807-5.422-5.978s-5.144-2.502-6.951-1.112s-6.673,0.139-10.705-0.556
      c-4.032-0.695-6.395,0.973-9.593,1.39s-3.337-0.973-4.866-3.198c-1.529-2.225-5.561-4.727-7.646-7.368
      c-0.405-0.513-0.985-1.025-1.667-1.523c-1.058,1.034-2.135,2.804-2.422,3.857c-0.383,1.402-2.423,0.893-3.825,2.168
      c-1.403,1.275-4.845,0.638-8.033,0.382c-3.188-0.255-4.463-1.913-5.866-2.933c-1.403-1.02-0.51-3.698-0.51-6.886
      s0.765-2.678,1.53-3.57c0.478-0.558,0.857-1.914,1.229-3.195c-2.537-0.803-7.507,1.321-10.048,1.829
      c-2.781,0.556-4.449,1.529-6.812,2.502c-2.363,0.973-6.951,1.807-6.951,1.807l0.135,39.65c0.018-0.005,0.037-0.009,0.055-0.014
      C764.505-0.681,764.644-0.717,764.783-0.749z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M919.606,18.669
      c-1.807,0-1.946,1.112-3.198,1.529s-1.946,0.278-3.337-0.973s-3.893-0.278-4.588,0.834s-3.337-0.139-4.866-1.807
      c-1.529-1.668-4.449-0.973-5.283,0.278c-0.834,1.251-2.502,0.834-3.337,0.139c-0.834-0.695-2.641-0.695-4.866-1.251
      c-2.224-0.556-2.224,0.556-5.561,0.556s-1.39-3.337-0.973-4.588s2.503-2.641,4.31-2.641s2.92,1.529,5.283,1.529
      c1.265,0,1.611-1.035,1.662-1.998c-0.156,0.02-0.31,0.036-0.459,0.046c-0.001,0-0.001,0-0.002,0
      c-0.133,0.008-0.261,0.01-0.387,0.007c-0.035-0.001-0.068-0.003-0.102-0.004c-0.096-0.005-0.188-0.015-0.277-0.028
      c-0.035-0.005-0.07-0.009-0.103-0.016c-0.116-0.023-0.228-0.052-0.331-0.093c-1.39-0.556,0.139-2.224,1.112-2.641
      s1.251-3.059,0.834-3.754s-2.085-1.39-2.92-1.251s-1.529,0-2.781,1.946s-3.337,1.112-4.032,0.278s-2.92,0.278-2.92,2.363
      s-1.39,3.059-3.337,3.059c-1.946,0-2.363,0.973-2.085,1.529c0.278,0.556,1.807,1.807,0.278,2.641s0.139,3.476-1.807,3.615
      s-3.754,0.556-5.005-0.973s-2.503,0.278-3.337,0.556s-2.363,0.417-2.641,1.529s0.834,1.529,1.946,3.198
      c1.112,1.668,2.224,1.251,4.588,1.112c2.363-0.139,0.278,2.363,0.139,3.893s0.417,2.085,1.251,2.641s1.529,0.973,1.668,2.641
      c0.139,1.668-0.139,2.502-1.807,2.781c-1.668,0.278-1.112,2.502-2.781,3.476c-1.084,0.632-1.228,2.141-1.196,3.123
      c0.106-0.007,0.205-0.007,0.3-0.001c0.037,0.002,0.074,0.004,0.109,0.008c0.03,0.004,0.058,0.011,0.086,0.017
      c0.033,0.007,0.067,0.014,0.098,0.024c0.027,0.009,0.052,0.019,0.076,0.03c0.03,0.013,0.059,0.028,0.086,0.044
      c0.022,0.014,0.043,0.028,0.063,0.043c0.027,0.021,0.051,0.044,0.074,0.07c0.017,0.018,0.034,0.036,0.05,0.056
      c0.023,0.032,0.044,0.068,0.063,0.106c0.01,0.021,0.023,0.039,0.033,0.062c0.027,0.064,0.049,0.135,0.066,0.216
      c0.278,1.39,0.092,2.317,1.853,2.132c1.761-0.185,1.576-2.41,4.264-2.41s2.224-2.595,4.078-1.39s2.317,2.224,3.337,2.224
      s0.648-1.576,0.556-3.059c-0.092-1.483,0.65-2.41,2.688-2.039c2.039,0.371,3.986,1.668,4.171-1.112s0.649-3.429,2.224-4.264
      s2.688-4.078,4.078-4.078s2.873,0.927,3.151,2.873s-0.649,2.595,1.205,3.429s1.484,1.761,1.02,2.688s-0.463,2.41-0.278,3.337
      c0.185,0.927-1.761,3.707-0.093,5.098c1.668,1.39,4.263,1.298,5.468,0.185s3.244-1.205,4.357-2.317s3.708-3.615,5.839-3.893
      c2.132-0.278,3.615-0.093,4.171,0.556s2.132,0.834,2.966,0.278s1.946-1.298,3.244-1.02c1.094,0.234,3.175,0.208,4.746,0.86
      c0-0.002,0.001-0.003,0.002-0.005c0.176-0.391,0.307-0.778,0.394-1.157c0.001-0.002,0.001-0.004,0.001-0.005
      c0.042-0.187,0.074-0.373,0.094-0.556c0.001-0.011,0.002-0.022,0.003-0.033c0.018-0.174,0.028-0.347,0.027-0.517
      c0-0.027-0.003-0.053-0.004-0.08c-0.003-0.155-0.013-0.308-0.031-0.461c-0.007-0.05-0.018-0.099-0.027-0.148
      c-0.021-0.128-0.042-0.257-0.074-0.383c-0.022-0.085-0.053-0.168-0.079-0.251c-0.029-0.091-0.053-0.182-0.088-0.272
      c-0.068-0.174-0.146-0.345-0.236-0.514c-0.741-1.39-0.741-2.873-0.741-4.171s-0.741-4.078-1.575-4.356s-3.151-1.483-3.893-0.741
      s-2.318,1.39-2.688-0.093s-1.019-2.688-1.019-4.819c0-0.085-0.001-0.179-0.002-0.278c-0.001-0.037-0.001-0.075-0.002-0.115
      c0-0.055-0.002-0.114-0.003-0.173c-0.014-0.781-0.041-1.865-0.002-3.012c0.001-0.007,0.001-0.013,0.001-0.019
      C921.392,18.527,920.664,18.669,919.606,18.669z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M974.799-12.612
      c-1.39-0.278-2.503-0.834-3.476-2.224s-1.529-1.112-3.476-1.668c-1.946-0.556-4.032-1.112-7.646-1.39
      c-3.615-0.278-5.005-0.278-7.229-0.834s-4.866,0.834-7.368,0.556c-2.503-0.278-3.754-0.139-6.812,1.112
      c-3.059,1.251-4.449-1.251-7.09-1.251c-2.641,0-2.92-2.085-5.422-3.476c-2.502-1.39-3.476,1.251-4.727,1.807
      s-1.529,2.224-1.529,4.171s-1.668,1.807-3.337,1.529c-1.668-0.278-4.032-0.278-5.7-1.39s-5.005-1.251-7.229-1.251
      c-0.138,0-0.279,0.005-0.423,0.016c-0.061,0.005-0.124,0.014-0.186,0.02c-0.084,0.009-0.166,0.016-0.251,0.027
      c-0.087,0.012-0.175,0.03-0.263,0.046c-0.061,0.011-0.122,0.02-0.184,0.033c-0.109,0.023-0.218,0.05-0.327,0.078
      c-0.042,0.011-0.083,0.019-0.125,0.03c-0.127,0.034-0.254,0.074-0.381,0.116c-0.024,0.008-0.049,0.014-0.074,0.023
      c-0.141,0.048-0.281,0.1-0.421,0.156c-0.011,0.004-0.021,0.008-0.031,0.012c-1.513,0.609-2.952,1.706-3.672,3.225
      c1.479,2.062,0.746,3.918-0.196,4.977c-1.112,1.251-3.893,2.502-5.561,3.476c-1.668,0.974-0.973,2.641,1.251,2.641
      s1.946,1.668,4.727,3.059c2.781,1.39,4.31-0.834,5.144-3.059s3.337,1.112,4.727,3.198c1.39,2.085,1.251,0.834,5.283,2.363
      s1.529,2.085,0.834,2.781s-1.529,1.39-3.893,1.39c-2.363,0-3.337,0.556-3.476,2.363s-1.946,0.973-3.198,0.973
      s-3.337-0.695-4.588-1.112c-0.096-0.032-0.201-0.051-0.311-0.065c-0.033-0.004-0.068-0.007-0.103-0.009
      c-0.084-0.007-0.171-0.01-0.262-0.009c-0.036,0-0.072,0-0.109,0.001c-0.122,0.005-0.248,0.014-0.378,0.028
      c-0.032,0.004-0.066,0.009-0.098,0.013c-0.103,0.013-0.207,0.028-0.314,0.045c-0.068,0.011-0.136,0.022-0.205,0.034
      c-0.064,0.011-0.129,0.023-0.194,0.035c-0.541,0.1-1.109,0.222-1.647,0.292c-0.051,0.963-0.396,1.998-1.662,1.998
      c-2.363,0-3.476-1.529-5.283-1.529s-3.893,1.39-4.31,2.641s-2.363,4.588,0.973,4.588s3.337-1.112,5.561-0.556
      s4.032,0.556,4.866,1.251c0.834,0.695,2.503,1.112,3.337-0.139s3.754-1.946,5.283-0.278s4.171,2.92,4.866,1.807
      s3.198-2.085,4.588-0.834s2.085,1.39,3.337,0.973s1.39-1.529,3.198-1.529c1.058,0,1.787-0.142,2.959-0.121
      c0-0.007,0.001-0.015,0.001-0.022c0.005-0.154,0.012-0.308,0.02-0.463c0.001-0.005,0.001-0.01,0.001-0.015
      c0.017-0.323,0.041-0.648,0.072-0.969c0.002-0.02,0.004-0.04,0.006-0.059c0.014-0.135,0.029-0.27,0.046-0.404
      c0.003-0.028,0.007-0.056,0.011-0.084c0.018-0.146,0.039-0.291,0.062-0.433c0.001-0.006,0.002-0.011,0.003-0.017
      c0.025-0.153,0.052-0.303,0.082-0.451c0.003-0.017,0.008-0.034,0.011-0.051c0.026-0.122,0.053-0.242,0.082-0.36
      c0.009-0.035,0.018-0.069,0.027-0.103c0.027-0.105,0.056-0.207,0.087-0.307c0.009-0.03,0.018-0.061,0.028-0.091
      c0.081-0.252,0.173-0.488,0.278-0.704c0.015-0.03,0.03-0.059,0.045-0.088c0.04-0.077,0.08-0.15,0.123-0.221
      c0.018-0.03,0.037-0.06,0.056-0.09c0.048-0.074,0.098-0.143,0.15-0.209c0.015-0.018,0.028-0.038,0.042-0.056
      c0.067-0.081,0.138-0.155,0.213-0.223c0.017-0.015,0.036-0.029,0.053-0.044c0.059-0.05,0.12-0.096,0.184-0.138
      c0.027-0.018,0.054-0.034,0.082-0.05c0.061-0.035,0.124-0.066,0.188-0.093c0.028-0.012,0.055-0.024,0.083-0.035
      c0.093-0.033,0.189-0.061,0.291-0.078c2.781-0.464,4.449-1.02,5.19-2.41s1.669-1.668,2.873-1.668s2.132,0,2.781-0.927
      c0.648-0.927,2.78-0.927,2.873,0.371c0.093,1.298-0.092,1.668,1.483,1.668s1.761-1.205,3.244-1.112
      c1.483,0.093,3.708,0.556,4.264-2.966c0.556-3.522,2.503-3.893,4.727-3.893s9.639,0.649,9.917-1.39s4.634-4.356,7.229-5.468
      s3.985-2.132,6.117-2.41s3.059,0.093,3.244-1.39c0.011-0.089,0.018-0.187,0.021-0.292c0.002-0.037,0.001-0.079,0.001-0.118
      c0.001-0.071,0.002-0.142,0.001-0.219c-0.001-0.048-0.003-0.099-0.005-0.149c-0.003-0.076-0.005-0.153-0.01-0.234
      c-0.003-0.051-0.007-0.103-0.01-0.156c-0.006-0.087-0.012-0.175-0.02-0.267c-0.004-0.048-0.008-0.096-0.012-0.145
      c-0.01-0.114-0.02-0.229-0.032-0.348c-0.001-0.015-0.003-0.03-0.004-0.044c-0.033-0.344-0.07-0.703-0.104-1.067
      C977.206-12.162,975.658-12.44,974.799-12.612z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M663.408,15.313
      c0.115-0.026,0.233-0.041,0.355-0.039c0.03,0,0.061,0.009,0.092,0.011c0.094,0.007,0.188,0.013,0.286,0.038
      c0.131,0.033,0.264,0.081,0.399,0.149c1.112,0.556,1.668,0.741,1.668,2.317s1.483,3.8,2.781,3.986
      c1.298,0.185,0.186,1.39,2.039,1.853c1.854,0.464,2.595,1.205,4.356,1.298c0.109,0.006,0.22,0.013,0.332,0.021
      c0.009,0.001,0.018,0.001,0.027,0.002c0.632,0.046,1.304,0.116,1.999,0.11l0,0c0.115-0.001,0.231-0.005,0.347-0.01
      c0.005,0,0.01-0.001,0.014-0.001c0.114-0.006,0.228-0.014,0.343-0.026c0.007-0.001,0.014-0.002,0.021-0.002
      c0.113-0.011,0.226-0.026,0.34-0.043c0.009-0.001,0.017-0.003,0.026-0.004c0.112-0.018,0.226-0.039,0.339-0.064
      c0.009-0.002,0.018-0.004,0.028-0.006c0.113-0.026,0.227-0.055,0.339-0.088c0.009-0.003,0.018-0.006,0.027-0.008
      c0.114-0.034,0.228-0.072,0.342-0.114c0.009-0.004,0.018-0.007,0.027-0.01c0.114-0.044,0.229-0.091,0.343-0.145
      c0.008-0.004,0.015-0.007,0.022-0.011c0.116-0.055,0.232-0.114,0.348-0.179c0.005-0.003,0.01-0.006,0.016-0.009
      c0.118-0.067,0.236-0.14,0.353-0.219c0.001,0,0.001,0,0.001,0c-0.069-0.896-0.178-1.948-0.327-2.321
      c-0.278-0.695-0.556-1.599-0.556-3.476s-2.155-1.043-2.711-1.946c-0.556-0.904-2.016-1.946-3.545-2.781
      c-1.529-0.834,0.695-2.502,0.626-3.337c-0.069-0.834-2.224-2.294-2.919-2.641c-0.695-0.348-0.973-2.016,0.069-2.363
      c1.043-0.348,0.834-1.807,0.07-2.224s-1.599-1.043-2.85-1.529c-0.659-0.256-0.931-1.436-1.042-2.497c0,0,0,0-0.001,0
      c-0.043,0.052-0.093,0.101-0.143,0.151c-0.034,0.034-0.067,0.067-0.104,0.099c-0.049,0.043-0.101,0.085-0.155,0.125
      c-0.051,0.038-0.106,0.073-0.162,0.108c-0.052,0.032-0.101,0.065-0.156,0.094c-0.112,0.06-0.232,0.113-0.361,0.159
      c-0.039,0.014-0.083,0.023-0.123,0.036c-0.106,0.033-0.216,0.061-0.332,0.084c-0.055,0.011-0.112,0.019-0.17,0.027
      c-0.118,0.017-0.242,0.028-0.37,0.035c-0.058,0.003-0.113,0.007-0.172,0.007c-0.19,0.002-0.388-0.005-0.601-0.029
      c-1.877-0.209-2.92,0.695-4.657,0.07c-1.738-0.626-2.016,0.973-3.823,0.695c-1.089-0.168-2.199,0.068-2.913,0.283
      c0.262,0.368,0.507,0.816,0.758,1.386c0.765,1.738,2.294,1.807,2.155,3.476s0.07,5.561,0.973,6.256
      c0.904,0.695,2.642,2.294,3.823,2.641c0.784,0.231,1.38,0.316,1.776,0.728c0.001,0,0.001,0,0.002-0.001
      c0.106-0.053,0.218-0.094,0.333-0.121C663.407,15.313,663.407,15.313,663.408,15.313z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M678.395-6.008
      c-1.39,1.39-0.139,1.599,0.209,2.502c0.348,0.904,0.973,1.321,2.016,1.529c1.043,0.209,1.182,0.904,1.043,1.946
      s-2.363,1.182-3.893,0.904s-2.433-0.209-2.989-1.182s-1.946-0.695-3.198-2.085c-1.251-1.39-3.059,0-3.406,0.834
      c-0.079,0.19-0.195,0.379-0.344,0.557c-0.001,0.001-0.002,0.003-0.003,0.004c0.111,1.061,0.383,2.241,1.042,2.497
      c1.251,0.487,2.085,1.112,2.85,1.529s0.973,1.877-0.07,2.224c-1.043,0.348-0.765,2.016-0.069,2.363
      c0.695,0.348,2.85,1.807,2.919,2.641c0.07,0.834-2.155,2.502-0.626,3.337c1.529,0.834,2.989,1.877,3.545,2.781
      c0.556,0.904,2.711,0.07,2.711,1.946s0.278,2.781,0.556,3.476c0.149,0.373,0.258,1.424,0.327,2.321
      c0.1-0.067,0.199-0.135,0.299-0.212c2.039-1.575,2.688-2.317,4.356-3.707s2.039-2.317,3.615-2.317s1.576-1.39,3.43-1.576
      c1.853-0.185,2.966-0.093,2.966,1.483s-1.02,1.946-0.278,2.781c0.742,0.834,1.205,2.502-0.185,2.966
      c-1.39,0.464-1.669,1.669-1.205,2.41s1.483,0.834,2.224,1.483c0.742,0.649,1.483,0.649,2.224,1.576
      c0.487,0.609,2.014,0.616,3.609,0.42c-0.276-0.751-0.641-1.337-0.875-2.273c-0.417-1.668,1.251-2.641,1.112-3.754
      c-0.139-1.112,0.139-1.39,2.363-2.92c2.224-1.529,1.529-2.92,1.529-5.144s1.251-2.085,3.198-4.31s1.39-3.754-0.139-4.588
      s-2.363-2.781-3.337-3.476s-1.807-1.946-2.224-4.171c-0.417-2.224-1.39-3.198-2.641-3.754c-0.148-0.066-0.276-0.149-0.393-0.243
      c-0.04-0.032-0.073-0.07-0.11-0.104c-0.072-0.067-0.142-0.135-0.203-0.21c-0.037-0.046-0.071-0.094-0.105-0.142
      c-0.052-0.074-0.104-0.148-0.151-0.226c-0.031-0.053-0.063-0.107-0.093-0.162c-0.046-0.084-0.092-0.167-0.135-0.253
      c-0.045-0.089-0.09-0.176-0.134-0.265c-0.038-0.077-0.076-0.152-0.115-0.226c-0.043-0.084-0.091-0.163-0.137-0.242
      c-0.026-0.044-0.051-0.09-0.078-0.131c-0.075-0.115-0.155-0.223-0.245-0.318c-0.391,0.904-0.935,2.072-1.367,2.662
      c-0.765,1.043-0.765,1.946-2.363,2.155c-1.599,0.209-2.016,0.904-2.711,2.016s-2.572,0.626-3.823,0.348s-3.476-2.711-4.866-4.657
      s-2.85-1.877-4.24-3.128c-0.012-0.011-0.025-0.018-0.037-0.029C679.505-7.498,679.088-6.701,678.395-6.008z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M874.561,171.737
      c1.39,0,2.781,0.139,2.781-0.973s0.417-2.502,2.085-2.502s4.449-0.695,5.005,0.417s1.112,1.112,2.641,1.112s1.807-1.39,3.198-1.39
      s0.834-1.807,2.224-1.668s2.085,0.695,2.363,1.668c0.278,0.973,0.556,1.39,1.668,0.139c1.112-1.251,1.946-0.834,1.39-2.224
      c-0.556-1.39,0.834-1.251-0.417-2.92c-1.251-1.668-0.139-4.032-1.668-4.31c-1.529-0.278-0.556-3.059-2.363-3.337
      c-1.807-0.278-2.92-0.973-3.337-1.807s-1.251-2.224-0.417-2.641s1.529-1.39,0.695-3.059c-0.834-1.668-0.834-1.529-2.085-1.668
      c-1.251-0.139-4.171-1.112-4.31-1.946s-0.695-3.059,1.112-3.754c1.807-0.695,1.807-0.834,3.337-3.476
      c1.529-2.641,2.503-4.588,3.893-4.588s2.224,0.695,2.502,1.807s0.834,1.668,1.251,1.668s1.807,0.417,2.085-0.973
      c0.278-1.39,0.973-1.112,2.085-1.112s5.144,0.139,5.283-1.668c0.139-1.807,0.695-2.363,1.946-3.059
      c1.251-0.695,1.251-4.727,3.476-5.005c2.224-0.278,2.641-0.139,4.171-2.502s2.641-2.224,2.92-4.171
      c0.278-1.946,0.417-4.31,2.085-4.449s2.92,0.834,3.476-1.946c0.556-2.781,0.417-3.893,1.946-4.866s2.085-2.363,3.337-2.363
      c1.251,0,2.363-1.112,1.251-1.39s-1.529-1.251-1.529-2.224s1.251-2.085,0.556-3.059c-0.695-0.974-0.556-2.781,1.251-3.198
      s5.283-1.112,5.422-1.946s0.556-1.668-1.529-1.946s-4.866-0.417-5.005-1.807s-2.503-1.946-2.503-2.92s-1.946-2.363-1.668-4.171
      c0.278-1.807-2.085-3.893-0.417-5.005s1.39-1.807-0.139-2.641s-1.668-1.112-1.668-2.502s0-4.866,1.807-4.866
      s3.059,0.834,5.005,0.834s3.615,0.278,4.171,0.834s2.503,1.668,5.005,1.112c2.503-0.556,3.198-1.529,5.978-1.668
      s4.31-0.139,4.866-1.807s2.363-3.476,4.032-3.476c1.668,0,4.032-0.139,4.727,1.112c0.695,1.251,2.224,2.085,2.363,0.834
      c0.105-0.943-0.108-2.912-1.17-4.778c-1.35,0.749-4.162,0.355-5.791,0.22c-0.026-0.002-0.055-0.005-0.08-0.007
      c-0.116-0.009-0.225-0.017-0.327-0.023c-1.668-0.093-0.927-0.649-3.151-0.927s-1.668-0.556-2.688-1.946
      c-1.019-1.39-2.224-0.834-3.615-0.834s-1.575-1.112-2.595-1.946s0.556-2.224,0.556-3.429s-0.926-2.224-1.761-2.595
      c-0.835-0.371-0.556-1.112-2.132-1.483c-1.576-0.371-1.668-0.834-2.224-1.576s-1.205-0.556-3.151-0.556
      c-1.204,0-2.052-0.567-2.525-1c-0.482,0.254-0.935,0.391-1.331,0.617c-0.02,0.011-0.04,0.022-0.059,0.033
      c-0.065,0.039-0.127,0.084-0.188,0.131c-0.026,0.02-0.053,0.037-0.078,0.058c-0.059,0.049-0.114,0.106-0.169,0.164
      c-0.023,0.024-0.048,0.045-0.07,0.071c-0.075,0.087-0.147,0.185-0.214,0.297c-0.556,0.927-3.522,0.927-5.839,0.742
      c-2.317-0.185-4.912,0.093-7.693,0.185c-2.781,0.093-4.727,0.093-5.098,1.019c-0.371,0.927-2.781,1.761-3.893,2.595
      s-0.834,1.483-2.41,1.668c-1.575,0.185-1.761,0.927-3.059,1.668c-1.297,0.742-1.39,1.946-0.186,2.224
      c1.205,0.278,1.112,1.946,2.039,2.781c0.927,0.834,0.371,2.873,0.649,5.283c0.278,2.41-2.873,3.337-4.171,4.356
      c-1.297,1.019-1.483,1.483-0.742,2.132c0.741,0.649,1.02,0.927,1.205,2.317s-0.093,2.039-1.575,2.132
      c-1.483,0.093-0.927,1.205-2.132,1.205s-2.873,0.371-3.059,0c-0.186-0.371-0.741-1.019-2.595-1.019s-1.761,0.927-1.576,1.761
      s1.112,1.668,2.688,3.337c1.576,1.668-0.278,2.132-0.742,3.059s-1.205,1.112-3.893,1.112s-2.873,2.224-2.873,3.985
      s-1.298,2.132-1.668,3.244c-0.37,1.112,1.205,1.668,1.205,2.966s-0.092,1.854-0.926,2.41c-0.834,0.556-1.298,0.927-1.668,1.946
      c-0.37,1.019-1.946,0.556-2.966-0.834s-4.078-0.649-4.912-0.185s-1.483,1.297-2.595,1.019s-2.688-0.463-2.966,0.464
      c-0.278,0.927,2.039,1.668,2.039,2.41c0,0.742-1.761,1.205-3.059,1.205c-1.297,0-1.205,0.649-2.224-0.649
      c-1.019-1.297-1.668-0.278-3.151,0.649s-0.185,1.205-2.966,2.966s-1.112,5.283-0.927,7.971s-0.093,3.151-2.224,3.708
      c-2.132,0.556-3.151,0.371-4.634,0.834c-1.483,0.464-4.078,0.371-6.21,0.371s-3.8,0.185-5.932,1.205
      c-2.132,1.019-4.171,0.371-7.322,0.371s-6.395-0.093-7.971-0.093s-3.151-0.185-4.541-1.019s-2.224-0.927-4.264-1.298
      c-1.286-0.234-3.418-1.352-4.819-2.146c0,0.001,0,0.001,0,0.002c-0.011,0.158-0.002,0.321,0.033,0.487
      c0.002,0.01,0.005,0.021,0.008,0.031c0.016,0.07,0.036,0.141,0.061,0.213c0.005,0.016,0.011,0.032,0.017,0.048
      c0.029,0.076,0.062,0.152,0.102,0.23c0.003,0.007,0.006,0.014,0.01,0.02c0.045,0.085,0.097,0.171,0.156,0.258
      c0.01,0.014,0.022,0.029,0.031,0.043c0.049,0.069,0.104,0.139,0.163,0.209c0.022,0.026,0.045,0.052,0.067,0.077
      c0.056,0.062,0.116,0.125,0.18,0.189c0.026,0.026,0.05,0.051,0.077,0.076c0.092,0.087,0.19,0.174,0.298,0.262
      c2.966,2.41,3.8,4.82,4.264,6.303c0.463,1.483,2.966,5.005,5.561,5.654s5.746,1.298,5.746,4.356c0,3.059-0.648,5.653,0.927,6.58
      s4.356,0.649,3.522,2.224c-0.834,1.576-0.463,3.429-1.761,3.8s-6.858-0.185-8.62,2.595c-1.761,2.781-3.522,3.337-3.708,4.634
      c-0.186,1.297-0.834,5.005-0.834,6.117c0,0.467-0.049,1.064-0.106,1.6c3.202,0.377,2.924,0.051,4.707-1.554
      c1.992-1.793,8.769,1.196,10.562,0.2s3.188-1.196,5.579-1.395c2.391-0.2,7.573-0.2,11.956,0.398
      c4.383,0.598,4.184-1.594,6.179-1.794c1.994-0.2,4.184,0.2,5.179,1.993s0.799,3.587,2.989,3.985s1.994,2.791,2.79,5.58
      c0.797,2.788,3.987,3.388,6.775,4.782c0.522,0.261,0.954,0.515,1.317,0.77C873.164,172.796,873.823,171.737,874.561,171.737z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1129.932,130.15
      c-0.069,0.011-0.14,0.017-0.214,0.009c-0.08-0.007-0.164-0.024-0.252-0.06c-0.834-0.348-1.668,0-2.364,0.209
      c-0.695,0.209-0.834-0.278-1.46-1.043s-2.364,0.209-4.101,0c-1.738-0.209-0.208-1.877,0.556-2.363
      c0.765-0.487,0.695-1.182,0.626-1.877c-0.07-0.695-0.556-1.321-1.529-1.807s-0.278-1.668-0.348-2.224
      c-0.07-0.556-0.834-1.182-1.529-1.321c-0.695-0.139-0.626-1.251-1.46-1.182c-0.834,0.07-1.043,1.529-1.946,1.529
      s-2.085,2.155-2.572,2.781s-1.321,0.348-2.016-0.209c-0.695-0.556-1.043-0.07-2.363-0.07c-1.321,0-1.46-1.321-2.433-2.363
      c-0.973-1.043-2.294,0.904-3.893,1.807c-1.599,0.904-1.877,1.807-2.989,3.337c-1.112,1.529-2.92,1.529-4.449,1.46
      s-1.668,0.695-2.155,1.877c-0.487,1.182-1.46,2.085-2.989,2.363s-1.321,1.946-1.738,3.337c-0.417,1.39-3.198,0.904-4.379,0.695
      c-1.182-0.209-2.085,0.556-3.059,0.765c-0.081,0.017-0.149,0.017-0.211,0.009c-0.47,0.287-1.06,0.059-1.355,0.236
      c-0.473,0.283-0.472,0.944-0.284,1.322s1.322,1.133,2.645,1.322c1.323,0.189,1.039,2.645,1.134,3.211
      c0.095,0.566,0.944,1.039,1.794,1.889s-2.456,0.378-4.156,0.378s-3.022,0.094-4.344,0.094c-1.323,0-3.684,0-4.156,0.85
      s-2.172,0.472-2.267-0.284c-0.094-0.756-0.756-1.039-1.795-1.039c-1.039,0-1.228,0.094-1.606,1.228
      c-0.378,1.133-1.983,0.85-2.928,0.944s-2.645-0.283-2.928-0.755s-2.267-0.85-3.59-0.85s-0.944-1.795-1.889-2.173
      c-0.944-0.378-0.189-1.322-0.284-2.739c-0.038-0.571,0.139-1.14,0.364-1.618c-0.053-0.051-0.1-0.105-0.143-0.158
      c-0.011-0.014-0.022-0.028-0.033-0.042c-0.041-0.053-0.078-0.107-0.106-0.159c-0.348-0.626,0.139-2.781,0.139-3.545
      s-0.209-1.46-1.251-1.529c-1.043-0.07-1.807,0.348-2.433,0.973c-0.042,0.042-0.092,0.079-0.148,0.112
      c-0.018,0.011-0.04,0.02-0.06,0.03c-0.04,0.02-0.082,0.04-0.127,0.057c-0.025,0.009-0.053,0.017-0.08,0.026
      c-0.046,0.014-0.092,0.027-0.142,0.039c-0.03,0.007-0.061,0.013-0.092,0.018c-0.053,0.01-0.109,0.018-0.166,0.026
      c-0.031,0.004-0.063,0.008-0.095,0.011c-0.065,0.006-0.131,0.011-0.199,0.015c-0.028,0.002-0.055,0.004-0.084,0.005
      c-0.087,0.004-0.177,0.005-0.268,0.005c-0.012,0-0.023,0.001-0.035,0.001c-0.221-0.001-0.455-0.009-0.695-0.022
      c0.043,0.702,0.083,1.443,0.083,1.788c0,0.755,0.19,1.795-0.566,1.889c-0.756,0.094-0.945,0.755-0.85,1.417
      c0.094,0.661-0.189,3.778,0.472,4.062c0.661,0.283,0.756,0.85,0.756,1.606c0,0.572,0.54,0.93,0.803,1.684
      c-1.005-0.025-1.743,1.011-2.375,1.783c-0.834,1.019-2.224-0.093-3.708,0.093s-1.761,0.278-3.43-0.556s-1.854,0.278-3.337,0.278
      s-3.337-1.02-5.653-1.205c-2.317-0.185-2.502-0.371-3.059-1.297c-0.556-0.927-0.834-0.649-3.059-0.371
      c-2.224,0.278-1.854-0.741-3.337-1.019s-2.502-0.742-2.781-1.854c-0.278-1.112-1.112-1.205-1.854-2.595s-1.668-1.112-2.966-1.205
      c-1.297-0.093-1.39,0.464-2.317,1.112c-0.927,0.649-2.317-0.185-3.429-0.649s-1.946,0.278-3.244,0.649
      c-1.298,0.371-1.668-0.463-2.224-1.668c-0.556-1.205-1.761-1.297-3.059-1.205c-1.298,0.093-2.688-0.556-3.429-1.854
      s-1.39-0.463-1.946,0c-0.556,0.463-1.298,0.649-2.132-0.278c-0.834-0.927-2.317-1.297-3.059-2.873s-2.039-1.668-3.151-1.854
      c-1.112-0.185-2.039-0.371-2.595-1.297c-0.556-0.927-2.039-0.742-3.615-1.02c-1.575-0.278-1.39-1.39-1.853-3.244
      c-0.463-1.854,0.556-1.853,1.297-2.502c0.741-0.649,0.834-1.019,0.741-2.224c-0.093-1.205,0.556-1.668,0.927-2.966
      c0.371-1.298,1.019-1.39,2.502-2.317c1.231-0.769,1.502-1.347,1.241-2.21c-0.024-0.08-0.053-0.164-0.086-0.249
      c-0.005-0.014-0.01-0.027-0.016-0.041c-0.036-0.09-0.075-0.184-0.12-0.281c-0.556-1.205-1.112-0.649-2.039-1.39
      c-0.926-0.742-1.668-0.834-3.43-1.483c-1.761-0.649-1.575-1.39-2.595-3.059c-1.02-1.668-1.575-0.649-3.244-0.649
      c-1.668,0-1.946-1.112-2.781-2.966c-0.834-1.854-2.039-0.649-3.615-0.649s-1.112-5.19-1.575-6.859
      c-0.463-1.668-0.834-1.298-1.668-2.595c-0.834-1.297-0.371-2.41,0.463-2.41s1.761-0.742,2.224-0.464s0.649,1.854,1.112,2.688
      s2.132-0.556,2.781-1.483s1.854-0.834,2.688-1.668s0-1.761-0.741-2.502s-0.649-1.112-0.741-1.668
      c-0.092-0.556-1.02-1.576-2.224-2.039c-1.205-0.464-2.317-2.41-1.854-3.707c0.463-1.298-0.092-1.483-1.205-2.688
      c-1.112-1.205-0.186-2.132,0.834-2.781s1.946,0.185,2.595,1.112c0.648,0.927,1.576,0,3.337-0.463s2.595-3.244,3.337-4.356
      c0.741-1.112,1.298-1.483,2.41-1.761s1.112-2.595,0.926-3.893c-0.186-1.297,0.649-1.483,1.112-2.781
      c0.463-1.297-1.297-1.39-2.966-1.761c-1.668-0.371-2.502-1.483-3.708-3.059s-3.151-1.39-4.912-1.39s-1.946,0.464-3.43,0.927
      c-1.483,0.464-1.483,1.575-2.966,1.668s-1.39,0.927-2.595,2.132c-0.059,0.059-0.127,0.111-0.199,0.16
      c-0.057,0.041-0.118,0.078-0.182,0.113c1.062,1.865,1.275,3.835,1.17,4.778c-0.139,1.251-1.668,0.417-2.363-0.834
      c-0.695-1.251-3.059-1.112-4.727-1.112c-1.668,0-3.476,1.807-4.032,3.476c-0.556,1.669-2.085,1.668-4.866,1.807
      s-3.476,1.112-5.978,1.668s-4.449-0.556-5.005-1.112s-2.224-0.834-4.171-0.834s-3.198-0.834-5.005-0.834s-1.807,3.476-1.807,4.866
      s0.139,1.668,1.668,2.502c1.529,0.834,1.807,1.529,0.139,2.641s0.695,3.198,0.417,5.005s1.668,3.198,1.668,4.171
      s2.363,1.529,2.503,2.92s2.92,1.529,5.005,1.807s1.668,1.112,1.529,1.946c-0.139,0.834-3.615,1.529-5.422,1.946
      s-1.946,2.224-1.251,3.198s-0.556,2.085-0.556,3.059s0.417,1.946,1.529,2.224s0,1.39-1.251,1.39s-1.807,1.39-3.337,2.363
      s-1.39,2.085-1.946,4.866c-0.556,2.781-1.807,1.807-3.476,1.946c-1.668,0.139-1.807,2.502-2.085,4.449
      c-0.278,1.946-1.39,1.807-2.92,4.171c-1.53,2.364-1.946,2.224-4.171,2.502c-2.224,0.278-2.224,4.31-3.476,5.005
      c-1.251,0.695-1.807,1.251-1.946,3.059s-4.171,1.668-5.283,1.668s-1.807-0.278-2.085,1.112s-1.668,0.973-2.085,0.973
      s-0.973-0.556-1.251-1.668c-0.278-1.112-1.112-1.807-2.502-1.807s-2.363,1.946-3.893,4.588c-1.529,2.642-1.529,2.781-3.337,3.476
      c-1.807,0.695-1.251,2.92-1.112,3.754s3.059,1.807,4.31,1.946c1.251,0.139,1.251,0,2.085,1.668
      c0.834,1.668,0.139,2.641-0.695,3.059c-0.834,0.417,0,1.807,0.417,2.641s1.529,1.529,3.337,1.807
      c1.807,0.278,0.834,3.059,2.363,3.337s0.417,2.641,1.668,4.31c1.251,1.668-0.139,1.529,0.417,2.92
      c0.556,1.391-0.278,0.973-1.39,2.224c-1.112,1.251-1.39,0.834-1.668-0.139s-0.973-1.529-2.363-1.668s-0.834,1.668-2.224,1.668
      s-1.668,1.39-3.198,1.39c-1.529,0-2.085,0-2.641-1.112s-3.337-0.417-5.005-0.417s-2.085,1.39-2.085,2.502s-1.39,0.973-2.781,0.973
      c-0.739,0-1.397,1.059-1.854,2.053c1.574,1.101,1.859,2.194,2.669,3.815c0.997,1.994,5.38,2.391,7.573,4.185
      c2.192,1.793,5.777-1.794,8.369,0c2.592,1.793-0.201,2.391-2.791,2.788c-2.589,0.397-2.391,1.595-4.383,1.396
      c-1.992-0.198-3.787-0.597-3.787,0.797s4.584,5.578,6.179,7.97c1.594,2.391,3.983,4.385,7.173,6.179s3.984,2.392,5.777,0.199
      c1.794-2.193,4.584-1.992,6.777-3.388c2.193-1.397,3.187-2.789,2.391-4.185s0.199-4.981,0.199-6.576s2.391,0.598,2.989,2.592
      c0.597,1.994,0.796,1.992,0.399,4.185c-0.397,2.192,2.19,5.578,2.59,7.372c0.399,1.793,0.198,2.59-2.392,5.779
      c-2.589,3.19,0,6.576,0.199,9.166c0.198,2.59,4.584,14.747,5.182,19.328c0.597,4.582,1.992,10.163,4.184,13.95
      c2.193,3.788,4.783,8.766,6.775,13.549c1.992,4.782,2.592,7.173,2.989,10.363c0.397,3.19,2.79,6.375,5.581,10.56
      c2.79,4.184,4.98,7.771,6.375,11.956c1.394,4.184,1.396,6.576,1.994,9.964s4.782,6.576,5.777,8.967s5.581,2.193,6.777,0.598
      c1.195-1.595,4.383-3.786,4.581-5.978c0.199-2.193,1.595-1.794,4.584-2.59s1.992-2.791,1.793-3.986
      c-0.198-1.196,2.392-4.981,3.786-6.177c1.394-1.195,3.587-0.997,3.787-2.192c0.201-1.196-0.597-4.981-0.997-6.576
      c-0.399-1.595,0.598-3.587,1.196-5.579c0.598-1.992,1.595-4.184,2.79-5.779c1.196-1.595,1.196-3.389,0.995-5.182
      s-0.198-3.785-0.198-5.978s-1.595-2.391-0.997-3.984c0.597-1.592-0.199-3.787-0.797-5.182c-0.598-1.394,0.199-3.785,0.997-6.576
      c0.799-2.791,0.797-2.193,3.786-2.193s4.584-1.593,6.176-4.185c1.593-2.592,4.185-0.994,6.777-2.589s2.589-5.78,3.785-6.975
      c1.196-1.196,4.383-1.794,5.579-3.386c1.195-1.593,4.782-4.584,6.179-5.581c1.396-0.998,4.184-2.59,6.972-5.978
      c2.789-3.389,4.185-6.177,6.576-6.775c2.391-0.598,4.185-1.195,6.377-1.992c2.193-0.796,4.384-4.385,6.177-5.978
      c1.793-1.592,0.598-3.586,0.799-5.581c0.2-1.994,0.597-4.184,2.391-4.184c1.793,0,4.184-0.397,4.782-1.593
      c0.598-1.195,1.992-1.994,2.59,0s2.989,1.394,2.989,1.394s3.587-0.997,4.782,0c0.122,0.102,0.237,0.183,0.346,0.252
      c0.054-1.736-0.114-5.042-0.175-5.776c-0.069-0.834-0.695-0.973-0.973-2.016s0.139-3.823,0.139-4.866s0.07-2.016-0.695-2.641
      c-0.765-0.626-1.043-1.112-1.529-2.433c-0.487-1.321-0.487-4.379,0-5.77c0.487-1.39-1.182-2.989-1.946-3.406
      c-0.765-0.417-1.182-1.182-1.946-1.946c-0.765-0.765-0.556-1.668-0.417-2.433s1.738-0.904,1.738-1.321s0.487-1.321,1.807-1.321
      c1.321,0,2.085,0.07,2.502-0.834s0.695-1.877,0.556-2.085c-0.139-0.209-2.711-0.487-3.476-0.626
      c-0.765-0.139-1.46-1.46-1.738-2.155c-0.278-0.695-0.834-0.834-1.321-1.043s-1.112-0.139-1.112-0.765s0.208-1.529,1.043-1.668
      c0.834-0.139,0.208-1.112,1.39-1.321s0.695-1.39,0.487-1.946c-0.091-0.242-0.061-0.613-0.001-0.957c0,0,0,0.001,0.001,0.001
      c0.578,0.487,0.889,0.92,1.26,1.226c0.003,0.002,0.005,0.004,0.008,0.006c0.045,0.036,0.091,0.071,0.137,0.104
      c0.014,0.009,0.028,0.018,0.042,0.027c0.037,0.025,0.076,0.048,0.116,0.071c0.019,0.011,0.039,0.021,0.06,0.031
      c0.037,0.019,0.075,0.036,0.114,0.053c0.024,0.01,0.049,0.02,0.074,0.029c0.039,0.014,0.08,0.028,0.122,0.04
      c0.028,0.008,0.055,0.017,0.085,0.024c0.044,0.011,0.091,0.021,0.139,0.03c0.03,0.006,0.059,0.012,0.091,0.017
      c0.055,0.008,0.114,0.014,0.174,0.02c0.029,0.003,0.056,0.007,0.086,0.009c0.093,0.006,0.19,0.01,0.295,0.01
      c1.761,0,2.132-0.186,2.688,1.019s1.02,2.039,2.595,2.317c1.576,0.278,2.224-1.205,3.429-1.483s1.205,0.371,1.205,1.483
      s-1.39,2.317-0.556,3.151s-1.019,2.688,0,3.244s1.854,0.556,2.595,1.205s2.502,0.834,3.8,0.927
      c1.298,0.093,5.098-0.464,6.951-0.278c1.854,0.185,0.835,1.297,3.43,0.556c2.595-0.741,3.429-0.556,4.541-0.371
      s1.112,1.112,1.483,1.576c0.371,0.463,1.113,1.576-0.185,1.946c-1.298,0.371-1.483,0.741-1.483,1.575s-1.39,1.39-1.668,2.41
      s-1.39,1.575-2.132,1.668s-1.853,0-1.946,0.556s-2.317,0.742-2.317,1.483s-1.483,1.02-1.205,1.946
      c0.278,0.926,1.112,3.151,1.112,4.356s0.278,1.761,1.205,1.761s2.503,2.224,3.151,0.463s0.278-3.614,0.927-3.8
      s-0.464-1.575,1.112-1.946s2.966-0.742,2.966,0.463s-0.463,4.17,0,4.634s0.834,1.298,0.926,2.132
      c0.093,0.834,1.483,1.205,1.39,3.059c-0.092,1.853-0.556,3.336,0.093,3.985c0.053,0.053,0.094,0.12,0.128,0.191
      c0.007,0.015,0.014,0.031,0.02,0.047c0.03,0.072,0.055,0.15,0.071,0.236c0,0.001,0,0.001,0,0.002
      c0.463,0.02,1.029,0.096,1.588,0.313c1.251,0.487,2.363,0.834,2.433-0.278c0.069-1.112,1.668-0.904,1.668-1.807
      s-0.278-1.946-0.904-1.946s-0.834,0-0.834-1.182s-0.208-2.92,0.834-3.406c1.043-0.487,1.807-0.973,1.807-2.433
      s-0.208-4.032,0.556-4.449c0.765-0.417,0.626-1.251-0.139-1.807s-1.112-1.668,0.348-1.668c1.46,0,1.321,1.043,2.989,0.973
      c1.668-0.07,2.155,0.973,2.294,1.599c0.139,0.626,1.251,0,1.182-1.251s-0.765-3.545,1.182-3.615s0-2.224,1.738-2.433
      s1.182-1.668,1.251-2.641c0.07-0.973,1.251-1.529,0.487-2.155c-0.765-0.626-1.46-0.695-1.39-1.321
      c0.069-0.626,0.695-0.278,0.765-1.46c0.069-1.182,0.973-1.251,1.599-1.39c0.626-0.139,2.155-1.182,2.224-2.433
      c0.07-1.251,1.599-1.668,0.765-2.641s-1.043-0.556-0.695-2.294s0.834-3.615,2.85-3.754c2.016-0.139,3.337-1.946,4.24-3.684
      s3.545-2.085,4.657-2.085s2.781-0.556,3.684,0.904c0.904,1.46,2.016,0.695,1.946-0.417s-1.807-1.112-1.529-2.711
      s0.417-2.711,2.016-3.128s1.946-1.043,1.946-2.294C1129.883,131.482,1129.966,130.843,1129.932,130.15z" pointerEvents="none" className="landarea"></path>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M996.209,317.714
      c-1.668-1.112-4.727-0.556-5.074-3.128c-0.348-2.572-5.352-10.427-6.812-11.47s-3.198-3.476-5.7-3.267c0,0-1.946,1.39-0.695,2.363
      s0.973,1.043,0.904,3.267c-0.07,2.224,0.556,2.155-0.695,2.711c-1.251,0.556-2.711,0.487-2.572,1.946
      c0.139,1.46,0.278,3.059-0.208,3.893c-0.487,0.834-0.139,3.128-0.348,4.101c-0.208,0.973,0,4.588,0,6.048s-0.07,1.946,0.556,3.337
      c0.626,1.39,0.556,2.294,0.834,3.684c0.278,1.39,1.46,2.016,2.433,3.267c0.973,1.251,1.807,1.46,2.92,1.807
      c1.112,0.348,2.224,0.209,2.85-0.417s2.641-1.39,4.101-1.738s5.631-3.267,5.839-4.449s0-3.823,0.556-5.353
      C995.654,322.786,997.877,318.826,996.209,317.714z" pointerEvents="none" className="landarea"></path>
   <g data-colors="0">
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M728.708,218.053
         c0,0-26.076,8.275-32.007,11.241c-5.932,2.966-5.746,3.893-9.639,12.049c-3.893,8.156-5.746-2.41-8.712-4.819
         c-2.966-2.41-14.621-3.893-15.525-4.727c-0.904-0.834-2.363-0.139-2.989,0.487c-0.626,0.626-1.46,0.417-2.433,0.417
         c-0.973,0-1.529-0.07-1.877-0.834c-0.348-0.765-0.626-0.695-1.738-0.973s-2.155,0.209-2.224,1.599
         c-0.07,1.39-0.765,1.807-1.807,2.711c-1.043,0.904,0.834,2.224,0.904,3.615c0.045,0.886-1.915,1.461-3.343,1.762
         c0.3,2.219-0.294,5.157-0.703,8.562c-0.598,4.981,0.598,5.182,2.592,8.967c1.994,3.786,2.59,7.573,2.19,10.761
         c-0.399,3.187,2.392,5.181,3.986,6.179c1.595,0.997,4.783,1.195,6.177-0.4s2.391-0.399,4.983-0.598
         c2.592-0.198,3.984-2.989,4.981-4.584s8.171-1.593,10.76-1.992c2.59-0.4,4.783-0.997,6.576-2.392
         c1.794-1.394,3.188-3.388,5.978-2.589c2.791,0.798,5.78,0,8.171-3.587s4.185-1.595,7.571-3.788
         c3.386-2.192,4.184-0.796,9.168-1.793s4.981-3.587,8.37-4.185c3.388-0.597,2.788-4.383,3.386-6.176
         c0.597-1.794,2.391-1.992,4.385-2.989c1.031-0.515,2.007-0.711,2.985-0.944l-9.909-21.024
         C728.794,218.01,728.708,218.053,728.708,218.053z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M751.152,275.543
         c-1.112-0.371-1.9-1.067-3.151-1.159s-1.901,0.093-3.522,0.047c0,0-1.761-0.324-2.271,0.417s-2.039,0.464-1.807,1.529
         s1.483,0.509,1.854,1.158c0.371,0.649,1.158,0.926,2.224,1.019s1.065-0.602,2.085-0.648c1.02-0.047,1.9,0.232,2.317-0.417
         s0.788-0.927,1.622-0.927S752.264,275.914,751.152,275.543z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M729.835,278.092
         c0,0-2.224-0.278-2.41,0.139c-0.186,0.417,0.509,0.232,0.927,0.927c0.417,0.695,0.881,1.019,1.576,1.019s2.224,0.279,2.271-0.463
         C732.245,278.972,731.41,278.277,729.835,278.092z" pointerEvents="none" className="landarea"></path>
   </g>
   <g data-colors="0">
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M694.662,128.268
         c-2.224,0-4.264,0.185-4.82-2.595c-0.556-2.781-5.19-3.151-7.6-3.151c-0.122,0-0.264,0-0.414,0c-0.061,0-0.123,0-0.188,0
         c-0.057,0-0.114,0-0.174,0c-0.054,0-0.111,0-0.167,0c-0.116,0-0.237-0.001-0.363-0.001c-0.07,0-0.139,0-0.211-0.001
         c-0.028,0-0.054,0-0.082,0c-0.029,0-0.062,0-0.092,0c-0.172-0.001-0.351-0.002-0.537-0.003c-0.013,0-0.024,0-0.037,0
         c-0.208-0.001-0.427-0.003-0.651-0.005c-0.034-0.001-0.068-0.001-0.103-0.001c-0.225-0.002-0.458-0.004-0.696-0.007
         c-0.027,0-0.054-0.001-0.08-0.001c-0.493-0.006-1.01-0.013-1.545-0.023c-0.04-0.001-0.078-0.001-0.117-0.002
         c-0.259-0.005-0.521-0.01-0.787-0.016c-0.037-0.001-0.076-0.002-0.114-0.003c-0.271-0.006-0.544-0.013-0.819-0.02
         c-0.006,0-0.012,0-0.018,0c-0.273-0.007-0.549-0.016-0.824-0.024c-0.049-0.001-0.098-0.003-0.147-0.005
         c-0.268-0.009-0.537-0.018-0.805-0.028c-0.047-0.002-0.094-0.004-0.142-0.005c-0.27-0.01-0.539-0.021-0.808-0.033
         c-0.011-0.001-0.022-0.001-0.033-0.001c-0.272-0.012-0.542-0.026-0.81-0.039c-0.052-0.003-0.103-0.005-0.154-0.008
         c-0.26-0.014-0.517-0.028-0.771-0.044c-0.052-0.003-0.102-0.007-0.154-0.01c-0.254-0.016-0.507-0.033-0.754-0.05
         c-0.011-0.001-0.022-0.001-0.033-0.002c-0.251-0.018-0.497-0.038-0.74-0.058c-0.049-0.004-0.099-0.008-0.148-0.012
         c-0.235-0.02-0.464-0.041-0.688-0.064c-0.044-0.005-0.087-0.009-0.13-0.014c-0.231-0.024-0.459-0.048-0.677-0.074
         c-4.635-0.556-6.487-2.781-8.527-4.634s-9.454-7.971-11.678-9.454s-6.673-5.932-9.269-6.673c-2.595-0.742-4.634-1.112-8.342-4.634
         s-8.156-2.41-11.492-3.337c-3.337-0.927-4.635-0.371-5.932,0.741s-4.449,1.298-7.415,1.854s-4.264,2.224-4.82,4.819
         s0.741,3.151,2.039,3.893c1.298,0.741,1.297,1.483,2.039,2.41c0.741,0.927,0.556,2.41-0.556,2.595
         c-1.112,0.185-1.668,1.112-1.854,2.966c-0.186,1.854-3.151,1.483-5.746,1.483s-5.191,5.19-7.229,6.859s-2.781,0.556-4.82-0.556
         c-1.497-0.817-4.587-0.834-6.127-0.785c-0.001,0.002-0.001,0.004-0.002,0.006c-0.027,0.117-0.06,0.242-0.098,0.377
         c-0.006,0.021-0.014,0.045-0.02,0.067c-0.035,0.121-0.073,0.246-0.119,0.382c-0.022,0.069-0.05,0.147-0.076,0.22
         c-0.036,0.103-0.07,0.201-0.111,0.312c-0.073,0.198-0.155,0.409-0.248,0.637c0.771,0.118,0.134,1.88,0.642,4.079
         c0.598,2.59-0.997,2.193-1.196,4.584c-0.198,2.391,0.397,1.394,2.59,1.394c2.193,0,3.188,3.189,5.182,6.178
         s3.187,5.181,4.981,8.768c1.793,3.587,4.981,6.576,6.975,9.565c1.994,2.989,1.992,1.993,1.992,4.783
         c0,2.79,0.399,2.591,1.793,5.38c1.394,2.789,1.992,2.791,4.981,3.389c2.989,0.598,4.185,2.789,5.38,5.379
         c1.196,2.59,2.791,4.185,3.788,6.377c0.997,2.193,1.394,3.987,0.397,5.78s-0.397,2.59,0.399,4.782
         c0.796,2.193,0.199,3.386,0.598,4.783c0.399,1.396,2.193,4.383,3.388,6.176c1.196,1.794,1.992,1.794,4.981,2.391
         c2.989,0.598,4.584,3.389,6.177,5.579c1.592,2.191,3.19,5.581,3.787,8.57c0.598,2.989,4.383,7.97,6.973,9.364
         s2.391,2.193,2.79,4.185c0.4,1.992,0.797,2.79,2.791,4.983c0.631,0.694,0.961,1.568,1.1,2.595
         c1.428-0.301,3.388-0.875,3.343-1.762c-0.07-1.39-1.946-2.711-0.904-3.615c1.043-0.904,1.738-1.321,1.807-2.711
         c0.07-1.39,1.112-1.877,2.224-1.599s1.39,0.209,1.738,0.973c0.348,0.765,0.904,0.834,1.877,0.834s1.807,0.209,2.433-0.417
         s2.085-1.321,2.989-0.487s12.558,2.318,15.525,4.727c2.966,2.41,4.82,12.975,8.712,4.819c3.893-8.156,3.707-9.082,9.639-12.049
         c5.932-2.966,32.007-11.241,32.007-11.241s0.086-0.043,0.256-0.047l-0.009-0.02c0,0,12.419-4.819,14.459-5.283
         s11.864-3.244,12.605-5.932s1.205-5.097,2.039-6.024s0.649-5.19,1.946-6.117s1.575-3.708,1.297-4.913s-2.873-3.429-3.151-4.449
         c-0.03-0.111-0.064-0.226-0.099-0.347c-0.031-0.107-0.062-0.218-0.093-0.336c-0.004-0.019-0.01-0.038-0.014-0.058
         c-0.031-0.12-0.06-0.246-0.086-0.379c-0.002-0.012-0.004-0.024-0.007-0.036c-0.027-0.14-0.05-0.286-0.067-0.443
         c-0.001-0.001-0.001-0.002-0.001-0.003c-3.102-0.215-9.286-0.623-10.755-0.623c-1.854,0-3.244-1.297-5.097-1.761
         c-1.853-0.463-3.8,0.185-6.117,0.463s-3.244-1.854-3.893-2.688c-0.648-0.834-2.41-2.966-3.337-4.171
         c-0.461-0.599-0.67-1.978-0.763-3.212c-0.742-0.749-1.286-2.229-2.096-3.04c-1.394-1.395-2.192-2.391-1.793-3.785
         c0.021-0.071,0.039-0.158,0.058-0.236c-0.792,0.118-1.142,0.515-2.079,0.866c-1.112,0.417-1.46,0.07-2.085-0.139
         c-0.626-0.209-1.321-1.668-1.46-2.224c-0.139-0.556-0.765-0.834-1.182-1.182c-0.201-0.167-0.256-0.609-0.26-1.022
         c-0.383-0.065-0.75-0.461-0.964-1.245c-0.598-2.193-1.196-3.985-3.587-6.376s0-4.783-0.598-8.17s-2.989-3.986-4.581-4.584
         c-1.593-0.598-2.791-2.192-4.984-3.387c-2.192-1.196-2.589-4.583-2.989-7.572c-0.165-1.235-0.568-1.889-0.984-2.376
         C697.554,128.266,696.313,128.268,694.662,128.268z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M640.536,237.094
         c0,0-0.615-0.55-0.647-1.196s-1.034-1.843-1.519-1.778s-0.033,1.52-0.097,1.843c-0.065,0.323-0.711,0.323-1.034,0.582
         c-0.323,0.258-0.032,1.067,0.679,1.325s1.519,0.614,2.133,1.164s1.81,1.163,1.875,0.517
         C641.99,238.904,642.378,238.452,640.536,237.094z" pointerEvents="none" className="landarea"></path>
   </g>
   <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M714.935,150.626
      c-0.614,0.092-0.675,0.491-0.675,0.952s-0.154,1.228,0.399,1.228s0.767,0.153,0.798,0.522c0.031,0.368,0.215,0.491,0.583,0.583
      s0.982,0.43,1.013-0.123s0-0.798,0.338-1.136s0.338-0.614,0.369-1.013c0.03-0.399,0.061-0.921-0.768-1.228
      c0,0-0.154-0.092-0.245-0.614c-0.092-0.522-0.43-0.645-0.829-0.522s-0.553,0.43-0.522,0.767
      C715.426,150.381,715.548,150.534,714.935,150.626z" pointerEvents="none" className="landarea"></path>
   <g data-colors="0">
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1850.061-379.826
         c1.297,0.185,5.561,0.556,6.488-0.371s1.483-1.483,3.151-1.483s3.893-1.112,4.449,0.185s1.853,1.853,2.781,1.297
         c0.927-0.556,2.039-1.112,3.708-1.112s2.224-1.297,5.19-1.297s5.375-0.742,5.746-1.854s1.669-3.522-0.926-3.522
         s-2.41-1.298-3.893-2.224c-1.483-0.927-3.708-0.37-4.078-1.297s-2.965-1.483-4.263-0.556s-2.781,1.298-5.005,1.112
         c0,0-4.264-0.371-6.303,1.112s-1.483,2.039-3.893,2.966c-2.41,0.927-4.449,2.039-4.634,3.337S1848.764-380.011,1850.061-379.826z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1959.243-265.083
         c0.185-0.741,0.185-2.039-2.224-1.853c-2.41,0.185-1.483-0.927-3.708-1.298c0,0-3.892-0.556-4.819-1.668s-1.668-1.483-3.708-1.483
         c-2.039,0-2.781,0.371-3.337,0.927s-1.854,0.371-2.41-0.185s-1.668-1.112-2.595-1.112s-1.112-1.668-1.854-1.668
         s-2.224,1.483-2.224,2.224c0,0.742-0.557,2.595,0.927,3.151c1.483,0.556,1.854,0.741,2.41,1.297s1.483,0.556,1.854-0.185
         c0.371-0.742,1.298-0.927,2.595-0.927s5.19,0.371,5.746,1.298s2.595,1.668,3.337,2.039c0.741,0.371,0.741,1.112,2.41,1.298
         c1.668,0.185,1.669-0.371,3.522-0.742C1957.018-264.341,1959.058-264.342,1959.243-265.083z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1722.352-209.899
         c0,0-0.744,1.041-2.529,1.041s-2.529,0.892-2.529,2.231s-0.893,2.232-1.934,2.827s-1.934,2.38,0.595,1.636
         c2.529-0.744,2.231-2.678,4.909-2.975c2.678-0.298,4.165-0.446,4.165-2.231C1725.031-209.156,1724.881-211.536,1722.352-209.899z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1651.238-107.4
         c-0.298,1.19-0.745,2.083-1.637,1.19s-2.38,0.446-2.677,1.785c-0.298,1.339-1.041,1.487-2.38,2.083
         c-1.339,0.595-2.38,1.041-2.231,3.124s1.785,2.38,2.826,1.19c1.042-1.19,4.017-1.934,4.166-3.422
         c0.149-1.487,0.149-2.529,1.488-2.678s2.975-1.041,2.231-2.083C1653.023-106.21,1651.536-108.59,1651.238-107.4z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1646.031-107.698
         c-0.744-0.595-0.892-1.934-1.636-1.041c0,0-1.339,1.487,0.149,1.785C1646.031-106.656,1646.776-107.103,1646.031-107.698z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1638.742-93.565
         c0,0-1.934,1.785-2.38,2.678c-0.446,0.892-0.148,2.529,1.042,2.529s0.744-2.231,1.785-3.422
         C1640.23-92.97,1640.675-94.904,1638.742-93.565z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1631.154-84.044
         c0,0-2.677,1.487-0.595,1.487C1632.642-82.557,1633.386-84.193,1631.154-84.044z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1614.344-65.002
         c0,0-0.595,0.744-2.827,2.529c-2.231,1.785-1.041,2.529,0.744,1.339C1614.047-62.324,1616.427-66.639,1614.344-65.002z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1522.403-118.409
         c1.934,1.637,3.868,2.975,3.868,5.206s0.446,4.165-0.298,5.802c-0.743,1.637-1.339,1.934-1.339,2.827s-0.892,1.339,0.298,2.083
         s1.339,1.934,1.339,4.165s0.744,6.099-0.149,7.289c-0.892,1.19-1.19,1.488-1.19,2.827s0.743,2.529-0.446,3.422
         c-1.19,0.893-0.892,1.636-0.892,2.826s0.446,2.083,1.19,2.827c0.744,0.744,1.785,1.041,1.636,3.422s-1.041,2.529-1.339,4.314
         s-1.339,0.893-1.19,2.38s1.042,1.934,1.042,4.017s-0.298,2.678-0.893,3.719s-0.892,1.19-0.744,3.57
         c0.149,2.38-0.893,5.207,1.19,5.653c2.083,0.446,1.19,0.297,1.636-1.785c0.446-2.083,2.231-1.636,2.678-3.719
         c0.446-2.083,0.743-1.785,2.083-1.785s4.76-0.446,4.76,1.488s0.745,2.529,1.637,3.124s-0.596,1.785,0.446,1.785
         s0.744,0.149,0.893-0.893c0.149-1.041,1.488-1.934,1.042-4.76c-0.446-2.826-1.636-2.38-2.975-2.827
         c-1.339-0.446-2.678-1.339-2.529-2.529s0.149-2.232-1.488-3.719c-1.637-1.487-3.422-4.314-2.975-6.694
         c0.446-2.38,2.232-4.314,3.124-8.033c0.892-3.719,1.636-6.694,3.57-6.992s2.678-1.19,4.463,0.149s2.231,0.298,3.719,2.38
         c1.488,2.083,2.529,4.463,3.571,4.165c1.042-0.297,1.488-1.339-0.297-2.826c-1.785-1.488-3.273-2.975-3.273-4.909
         s-1.488-2.975-1.934-5.505c-0.446-2.529-2.231-2.529-2.231-5.951s-1.339-2.975-1.488-5.504s1.041-3.124-0.595-4.761
         c-1.636-1.636-2.083-2.082-1.488-2.678c0.595-0.595,1.488-0.298,0.595-1.488c-0.893-1.19-2.827-2.826-2.975-4.909
         s0.892-1.934,1.19-4.166s1.488-0.446,1.637-3.868s-0.447-4.165-1.488-6.1c-1.041-1.934-0.595-3.57-1.487-3.57
         s-1.042-1.636-1.042-3.422c0-1.785,0.298-4.612-0.595-5.356s-4.166-2.677-4.315-0.148c0,0-0.446,1.488,0.744,1.934
         c1.19,0.446,1.488,0.446,1.488,1.934s0.149,1.488-0.744,1.785c-0.893,0.297-0.595,0.893-0.446,2.231
         c0.149,1.339,0.148,1.785-1.042,1.487c-1.19-0.297-2.826-1.785-3.272-0.893s-2.231,0.744-2.231,2.529s-0.446,1.785,0.446,2.529
         c0.892,0.744,0.595,1.636,0.595,2.975s0.596,2.232-0.595,3.868c-1.19,1.636-1.934,2.38-1.786,4.165
         C1521.659-121.533,1520.469-120.045,1522.403-118.409z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1600.209-54.589
         c-0.893,0-1.339-0.893-2.975,0.893c0,0-1.19,0.744-2.827,2.083c-1.636,1.339-4.315,2.975-2.976,3.124s2.677,0,4.314-1.339
         s3.124-1.488,3.273-2.529C1599.167-53.399,1601.101-54.589,1600.209-54.589z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1584.29-46.853
         c0,0-0.594,1.487-2.677,1.785s-5.058-0.149-5.951,1.339c-0.893,1.488-3.571,4.463-4.761,5.207s-3.571,2.678-2.38,2.678
         s2.528,0.298,4.016-1.487s2.232-3.273,4.017-3.719c1.785-0.446,2.826-0.298,3.57-1.488c0.744-1.19,1.934-1.339,3.571-1.785
         C1585.333-44.771,1586.373-48.192,1584.29-46.853z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1564.653-33.911
         c-1.042-1.19-3.125-5.207-3.719-2.678c0,0-1.636,4.612-2.975,5.207s-3.124,3.273-1.042,2.678c2.083-0.595,2.53-2.381,3.72-3.273
         c1.19-0.892,1.933-1.636,2.826-0.744C1564.355-31.828,1565.695-32.721,1564.653-33.911z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1937.489-297.757
         c4.184,0-2.092-2.69,0.598-2.69c2.69,0,5.978,0,6.277-1.495s2.092-2.092,5.082-3.587c2.989-1.495-0.598-2.69-2.989-3.587
         s-2.69-3.288-5.679-4.185s-4.184-2.989-6.277-5.38s-7.473-2.391-9.864-0.598c-2.392,1.793-5.082-0.598-5.381-2.092
         c-0.299-1.495-3.288-0.598-7.173-0.299c-3.886,0.299-0.897,3.288,0.897,5.082c1.793,1.794,0.299,2.989,0,4.185
         c-0.299,1.196-2.989,1.794-5.082-0.299c-2.092-2.092-0.299-4.484-1.495-6.576c-1.195-2.092,0-1.196,1.196-3.886
         c1.195-2.69-3.288-2.092-3.886-4.185c-0.598-2.092-8.071-6.277-11.06-7.772s-2.391-2.391-11.06-5.081
         c-8.669-2.69-5.978-0.598-9.266-5.679c-3.288-5.082-6.277-1.196-6.875-2.989c-0.597-1.793-5.081-0.299-7.473-3.288
         c-2.391-2.989-5.978-1.495-7.771-4.185s-9.266-2.391-14.945-3.587s-5.38-1.196-7.473-4.185
         c-2.093-2.989-16.141-0.299-19.429-0.897s-5.082-0.299-6.576,0.897c-1.495,1.196-5.679-2.69-11.358-2.391
         c-5.679,0.299-5.679,0.299-9.266-2.092c-3.587-2.391-4.185,0.299-4.185,2.092s0,2.69-1.793,3.886
         c-1.794,1.196-0.299,2.69,1.495,2.989c1.793,0.299,2.989,3.288,4.484,5.978s1.195,3.587-1.794,3.587
         c-2.989,0-2.989,3.288-6.576,2.989c-3.586-0.299-5.679-2.092-6.875-4.185c-1.195-2.092-4.782-2.989-7.473-2.989
         c-2.69,0-2.391-2.69-3.288-5.081s3.288-2.69,6.277-0.897c2.989,1.794,3.886,0.897,4.484-1.793s-3.288-3.587-7.473-4.484
         c-4.184-0.897-5.38,1.793-7.173,3.886c-1.794,2.092-0.897,3.886-5.679,3.886s-7.473,0-8.967-0.897
         c-1.495-0.897-4.484-0.598-8.369,0c-3.886,0.598-5.082,0-6.875-1.495c-1.794-1.495-5.978-1.196-8.668-0.897
         s-7.174,0.299-9.565,0.299s-5.679,0.897-6.277,2.391c-0.598,1.495-3.587,1.196-4.484-0.598s-6.875-1.793-10.163-3.288
         s0.299-4.185,1.794-6.576s-5.978-10.163-13.152-12.853s-18.831-1.495-23.614-1.495c-4.782,0-11.358,1.495-16.141,2.69
         c-4.783,1.196-8.071,2.391-11.956,2.391c-3.886,0-7.473-1.196-6.277-3.587s-2.092-2.092-4.184-4.783
         c-2.092-2.69-4.783-1.495-5.978-0.299c-1.196,1.196-2.392,2.092-4.484-0.299c-2.092-2.391-0.299-3.288-4.185-3.587
         c-3.886-0.299-1.793-1.196-3.886-1.196s-3.886-1.794-2.69-2.391c1.196-0.598,2.989,0,6.576-0.598s-2.391-5.679-5.679-6.875
         s-14.347-1.793-18.532-1.793c-4.185,0-5.679,0-8.37,0c-2.69,0-2.092,2.989-0.897,4.185c1.196,1.196-1.195,1.196-1.195,3.886
         c0,2.69-2.392,0.598-3.288,2.092s-3.288,2.092-5.38,0.598c-2.092-1.495,0.299-2.989,3.288-3.587s3.288-2.69,3.288-3.886
         s0.598-2.69-2.391-2.989s-2.392,1.196-5.381,1.196s-0.597-1.794,1.495-3.587c2.093-1.793,0.598-2.391-2.391-2.391
         s-10.76-1.793-18.831-1.793s-1.196-1.793-7.174-0.598c-5.978,1.196-5.38-1.793-8.967-2.69c-3.586-0.897,0.299,2.69,0.299,4.783
         c0,2.092-1.794,2.092-4.783,2.092s-4.484-0.299-8.07,0c-3.587,0.299-3.288,3.886-2.69,5.38c0.598,1.495,3.587,0,6.277-0.598
         s1.196,2.989-0.597,3.587c-1.794,0.598-0.897,3.587,1.495,6.277c2.391,2.69,0.299,3.288-2.392,3.288c-2.69,0-4.484,0-5.081-1.495
         c-0.598-1.495-2.093-0.897-4.484-1.793c-2.392-0.897-4.185,1.495-7.473,0.897s-3.587,0.299-2.69,1.793
         c0.897,1.494,2.989,0.897,5.679,2.092c2.69,1.196-0.299,2.69-2.989,2.092c-2.69-0.598-3.886-0.299-5.381-2.989
         s-4.782-0.598-7.173-3.288s-5.082,0.897-8.669,0.897c-3.587,0-2.989,1.495-5.679,3.288s-6.576,0.299-9.864-1.495
         c-3.288-1.793-3.586-1.196-4.484-5.082c-0.897-3.886-3.288-2.092-5.081-1.196c-1.794,0.897-2.092,4.185-3.587,7.174
         s-2.093,4.484-3.288,7.772s-4.782,3.587-6.576,0.897c-1.794-2.69-4.185-0.897-7.174-2.989
         c-2.989-2.092-10.462-10.163-11.657-12.554s-5.978-2.989-5.978-6.277s6.875,2.391,9.565,1.196c2.69-1.196,2.69-5.679-0.897-5.978
         c-3.587-0.299-4.185-1.794-2.989-2.69c1.196-0.897,5.38-2.092,1.495-3.587s0.598-3.288,0-5.38s-3.886-1.196-7.174-4.783
         s-10.462-2.092-14.945-0.299c-4.484,1.793-9.565-0.299-15.244-4.185c-5.679-3.886-5.978-0.897-9.565,0
         c-3.587,0.897-2.989,4.484-0.598,6.875c2.392,2.391-0.897,2.092-2.69,4.783c-1.794,2.69-1.794,1.196-5.978,0.897
         c-4.185-0.299-5.082,1.196-12.255,1.196s-8.07-2.092-8.967-3.288c-0.897-1.196-3.886-0.897-6.576,0.299
         c-2.69,1.196-4.783,0.598-8.668-2.69c-3.886-3.288,2.391-3.587,2.092-5.082s-7.772-0.897-13.75-1.196s-6.277-1.495-10.162-1.793
         c-3.886-0.299-7.174-1.196-9.266,1.196c-2.092,2.391-6.576,0.897-10.162,0.897c-3.587,0-2.391,1.495-0.299,3.886
         c2.093,2.391-1.195,2.391-3.886,0c-2.69-2.391,0-2.989-1.195-7.772c-1.196-4.783-3.587-0.299-4.484,1.495
         c-0.897,1.793-3.886,0-8.668-0.897s-2.092-0.897-2.989-2.69s0,0-5.082-0.299s-2.093,0-6.576-0.897
         c-4.484-0.897-4.782,2.989-4.484,5.38c0.299,2.391,4.185-0.299,6.576-0.299s-0.897,1.793-2.989,2.989
         c-2.093,1.196-2.69,0.897-5.082,0.299c-2.391-0.598-3.886,3.288-5.978,3.288s-5.679,0-8.668,2.092s-6.277,1.793-9.864,0.598
         c-3.587-1.196-5.081,2.092-5.978,4.783c-0.897,2.69-2.989,2.69-6.875,2.391s3.587-2.391,4.484-5.082
         c0.897-2.69,2.092-3.587,4.484-4.185s4.484-0.598,6.875-3.886s6.875-2.391,9.864-3.288s3.587-0.598,5.978-3.587
         s3.886-2.092,6.576-2.989c2.69-0.897,1.195-0.897,2.391-4.185c1.195-3.288,3.288-1.495,5.978-2.69
         c2.69-1.196,4.484-2.092,7.473-2.989s4.782-0.299,6.277-2.989s2.69-1.495,5.679-2.391c2.989-0.897,5.38-3.587,7.473-6.875
         s-0.598-2.69-0.897-3.587s-2.392-0.299-3.886,0c-1.495,0.299-0.597-2.092-2.391-3.587c-1.793-1.495-2.092-1.793,0.598-1.196
         c2.69,0.598,1.793,0.598,4.484,2.391c2.69,1.793,4.185,0.598,5.082-0.897s1.195-2.989-0.897-2.989
         c-2.093,0-2.392-0.598-2.392-2.69s0.299-2.989-0.597-2.989c-0.897,0-2.989,2.391-4.484,2.391s-2.69-1.495-2.69-3.886
         s-1.793-2.391-1.793-2.391s-3.587,0.897-2.989-0.299c0.597-1.196-0.299-2.989-1.196-2.989s-2.092,0.598-5.38-1.793
         s-6.277-2.391-8.967-1.196c-2.69,1.196-3.288,1.793-7.473,0.299c-4.185-1.495-6.277,0.598-9.266,0s-4.484,0.299-5.38,3.288
         c-0.897,2.989-5.38,0.598-8.967,0.598s-2.092-0.299-1.195-2.989c0.897-2.69,3.886-1.495,5.081-4.185
         c1.196-2.69-4.184-1.793-9.565-2.69s-4.185,0.598-11.658,0.598s-3.587-1.495-0.598-2.69c2.989-1.196,4.185,0,4.783-1.495
         c0.597-1.495,4.782-1.793,4.782-2.989s-1.196-2.69-4.484-2.69s-3.886-0.299-6.576-2.092c-2.69-1.794-5.082-2.092-7.473-0.598
         c-2.391,1.495-3.288,0.897-6.875,0.299s-3.587,2.989-6.277,3.886s-2.69,3.288-5.381,4.484c-2.69,1.196-3.288,2.391-5.38,4.185
         c-2.093,1.793-1.793,3.288-0.897,3.288s0.598,1.495,1.495,4.185s2.092,1.495,6.875,2.69c4.783,1.196-0.299,2.092-2.391,1.196
         c-2.092-0.897-4.185-1.196-8.668-1.495c-4.483-0.299-6.277,0.897-9.864-0.299s-5.679-1.794-4.484,0.598
         c1.196,2.391,0.598,2.989,4.185,3.886s2.989,2.69,1.495,2.989c-1.495,0.299-3.587,0-6.875-1.495s-5.978-0.598-6.277,0.598
         s-1.495,0-3.886,2.391s-4.484,0-5.679,0.897s-3.886,0.598-3.886,0.598s-5.679,0.897-8.668,0.598s3.288-1.793,3.586-3.587
         c0.299-1.793,3.288-0.897,3.587-2.69c0.299-1.793-4.184-0.897-5.978-0.897c-1.793,0-3.587,0.598-3.587,1.495
         s-1.195,1.793-2.989-0.598c-1.793-2.391-3.288-0.897-3.288,1.495c0,2.391-1.495,1.495-3.587,1.495c-2.093,0-5.679,0-8.071,0
         c-2.391,0-3.586,0-5.081,0.897s0.897,1.495,2.989,1.495s3.586,0.299,2.092,2.391c-1.495,2.092-3.288,1.196-5.978,0.897
         c-2.69-0.299-6.277,0-8.967,1.793c-2.69,1.793-5.978,0.299-7.173,0.299c-1.196,0-5.679,1.495-7.772,2.69
         c-2.092,1.196-4.184,0.897-6.277,0.897s-5.779,1.196-7.372,4.185c-1.593,2.989-6.377,1.594-9.766,0.798
         c-3.388-0.796-1.793,2.989-3.187,2.989s-3.785,1.793-2.391,2.391s2.391,2.192,1.992,3.188c-0.4,0.996-2.59-0.198-4.185-1.196
         c-1.595-0.997-4.185,0.797-4.185,1.993s3.788,0.996,6.179,2.192s3.984,3.189,3.386,3.986s-2.989-1.595-6.176-2.591
         c-3.188-0.996-2.193,2.989-1.595,2.989s3.787,0.598,5.38,1.993s3.788,1.594,3.986,3.387c0.199,1.793-3.785,1.395-7.573,1.395
         c-3.787,0-5.179,0.996-6.176,2.59s-4.383,0.797-6.576-0.198s-4.783,0.198-5.78,0.796s-8.567-0.398-13.948,0
         c-5.38,0.398-5.578,1.395-10.361,1.395c-4.782,0-5.581,1.196-7.173,1.993c-1.593,0.798-2.193,4.185-2.193,5.38
         c0,1.196,2.59,2.789,3.986,4.185c1.397,1.395,0.199,2.391-0.997,3.387s0.997,3.985,1.793,5.58c0.797,1.595,5.78,1.992,9.965,1.992
         c4.184,0,0.597,3.189,3.386,5.182c2.788,1.993,1.793,3.587-0.199,4.185s-2.391,0.598-7.372-0.2s-4.983-2.391-5.978-4.384
         c-0.995-1.993-3.587-2.789-6.576-3.387s-3.388-0.598-5.182-2.391c-1.793-1.793-4.782-0.996-7.372-0.996
         c-2.589,0-4.385-2.193-5.978-3.388c-1.592-1.196-4.983-0.598-5.581,0.997c-0.597,1.595-2.589,1.594-4.383,2.59
         c-1.793,0.996,0.199,1.793,2.791,1.595c2.592-0.198,1.394,1.394,0.995,2.789c-0.4,1.395,2.592,0.996,4.184,0.2
         c1.593-0.796,3.388,1.196,3.788,2.989c0.399,1.793-3.19,1.594-5.182,1.594s-4.185-0.996-5.38-2.591
         c-1.196-1.595-3.785-0.996-3.785,0.399s-4.386-0.798-5.581,0.996c-1.196,1.793,1.195,2.591,1.594,4.384
         c0.4,1.793,3.188,2.989,4.783,2.789c1.595-0.2,4.782,1.594,5.579,2.192c0.796,0.598,2.79,1.595,3.388,0.598s1.793,0.2,3.587,0.399
         c1.793,0.2,0.399,2.59,1.394,3.188s1.595,3.389,0.399,3.389s-2.989-0.598-4.185-2.591c-1.195-1.993-3.586-1.793-6.176-2.391
         s-6.975-0.598-9.964-0.398s-3.986-1.595-5.978-1.794c-1.992-0.198-0.199-2.989,0.997-4.584s-1.196-1.196-1.793-2.789
         c-0.598-1.594-0.799-3.587,0.397-3.587c1.195,0,1.994-2.591,2.989-4.384c0.994-1.794-0.797-4.383-0.797-5.579
         s-0.796-3.587-1.595-3.786c-0.798-0.2-0.994-1.395-3.187-1.196c-2.193,0.2-1.196,3.387-0.399,3.787
         c0.796,0.399,0.597,4.383,0.597,6.177s-0.994,3.787-3.187,3.986c-2.193,0.2-4.584,1.992-6.774,2.192
         c-2.191,0.2-3.389,1.196-3.389,2.591s-0.796,2.789-2.989,4.783c-2.192,1.993,0.199,2.989,2.193,3.985s2.788,3.387,3.386,5.58
         c0.598,2.193,3.388,1.992,4.185,4.383c0.796,2.391-1.992,4.584-4.185,5.78s-0.598,2.989-2.19,5.38
         c-1.593,2.391,0.597,5.38,1.592,6.177c0.995,0.796,1.794,1.595,0.399,3.189c-1.394,1.594-0.198,2.192,0.199,2.989
         s3.986,0.2,5.978,0.398c1.992,0.199,5.38-0.598,7.972-1.992s-0.798,1.196,5.777,1.394c6.576,0.199,7.375,1.595,9.565,2.193
         c2.191,0.598,4.983,1.394,5.182,3.387c0.198,1.993,2.59,4.982,3.187,5.779c0.598,0.796-0.198,2.989-2.788,2.989
         s-1.793,2.791-1.793,5.38c0,2.59,3.386,2.591,5.578,2.591c2.193,0,3.188,2.591,0.998,2.391c-2.191-0.2-4.584,0.2-5.581-1.594
         c-0.998-1.794-2.789-0.798-3.984-2.391c-1.196-1.594,0-3.587,0.796-4.982s-0.997-2.192-0.399-3.587s0.399-2.591-1.394-3.387
         c-1.794-0.796-3.188-1.793-3.587-3.587c-0.399-1.793-0.997-2.791-3.986-2.791s-4.981-0.398-7.174,1.395s-2.19,0.598-5.777,0.598
         s-3.388,2.591-3.587,5.181c-0.198,2.59,0.995,3.189,2.391,4.783c1.397,1.594,1.992,2.791,1.394,5.38
         c-0.597,2.59-1.992,3.189-4.584,4.584s-1.794,3.587-2.789,6.774c-0.994,3.188-2.989,3.189-5.581,3.389s-1.992,2.59-4.981,2.59
         s-1.991,1.794-2.79,4.385c-0.799,2.591-2.788,2.391-4.185,2.591c-1.396,0.2-2.788,0.198-3.983-1.993
         c-1.196-2.192-4.783,0.2-7.973,0.598c-3.189,0.398-3.386,1.196-4.981-0.996c-1.594-2.192-3.187-0.2-3.785-1.794
         c-0.598-1.594-1.595-0.997-3.388-1.196c-1.794-0.198-1.992-0.798-1.992-2.591s3.386,0,3.984,0s2.79,0.2,5.181,1.196
         c2.392,0.996,4.584,1.594,5.978,2.192c1.395,0.598,2.791-1.793,4.584-1.793c1.794,0,2.392-0.796,2.191-1.992
         c-0.201-1.196,0.399-2.391,2.592-3.388c2.192-0.997,1.793-1.594,2.19-3.785c0.397-2.192,3.587-1.196,3.788-3.189
         c0.201-1.993,1.793-2.192,3.984-2.989c2.19-0.798,2.391-5.779,2.592-8.17c0.2-2.391,0.597-1.993,2.391-3.189
         c1.793-1.196-0.997-2.989-2.989-3.985s-3.19-1.793-3.986-2.989c-0.797-1.196-1.794-1.993-1.794-4.185s0.997-4.185,1.196-5.58
         c0.198-1.395,0.198-4.384-0.399-5.58c-0.598-1.196-0.598-3.985-0.797-5.38c-0.198-1.395,0.797-3.188,1.595-5.778
         c0.799-2.591,0.995-4.583,0.397-6.576c-0.598-1.993-2.19-3.985-3.386-4.185c-1.195-0.2-1.595-1.395-2.989-2.192
         c-1.394-0.796-1.195-2.989,0.598-3.787c1.793-0.797,1.793-1.594,2.19-3.189s0.997-2.789,2.791-4.981
         c1.793-2.192,1.394-7.174,1.195-9.167c-0.198-1.993-3.187-2.391-6.377-3.189c-3.19-0.797-8.369-1.594-12.554-1.594
         c-4.184,0-4.782,0.798-7.97-0.798c-3.187-1.595-3.785,0.997-5.779,3.189s-2.391,5.779-2.788,10.561
         c-0.397,4.782-3.389,4.584-5.38,7.771c-1.992,3.188-7.573,5.182-10.562,6.378c-2.989,1.196-3.389,4.981-3.188,6.576
         s0.397,1.992,3.587,0.796s0,0,1.593,2.989c1.592,2.989-0.199,3.189-1.196,5.182s0.201,1.594,1.793,3.785
         c1.593,2.192-0.796,0.997-3.586,0.997c-2.791,0-1.593,5.779-0.598,7.372c0.995,1.594,2.192-1.794,2.989-1.794
         c0.796,0,4.782,1.794,6.377,1.993c1.595,0.2,2.391,3.787,3.388,5.978c0.997,2.192,2.392,3.587,4.383,3.787
         c1.992,0.2,2.392,4.185,0.797,5.181c-1.595,0.996-1.196,1.993-1.794,3.587c-0.597,1.594-3.586,0-5.179-1.793
         c-1.593-1.794-5.182-2.789-6.576-4.385c-1.394-1.595-2.391-2.59-3.986-2.59c-1.595,0-3.986-1.196-6.176-2.791
         c-2.191-1.595-6.378-2.391-8.57-2.391c-2.193,0-2.789-0.796-3.984-2.59c-1.196-1.793-6.576-2.391-9.565-2.989
         c-2.989-0.598-13.551-3.587-16.939-2.791c-3.389,0.796-2.191-1.196-4.981-1.196c-2.791,0-3.587,0.798-5.38,2.591
         c-1.794,1.793-2.193,0.797-2.392,2.591c-0.198,1.793,1.794,2.391,2.392,4.185c0.597,1.793,0,2.391,1.394,2.59
         s1.396,2.791,0.201,4.384c-1.196,1.594-3.19,1.793-5.978,1.993c-2.789,0.2-2.392,1.594-0.4,2.391
         c1.992,0.798,0.199,2.391-1.394,2.391c-1.592,0-3.19,0.598-5.38-0.598s-1.793-3.587-0.598-4.185
         c1.196-0.598,2.191-1.993,0.995-2.791c-1.196-0.797-2.59-2.391-3.984-1.992s-3.587,0.398-4.184,2.192
         c-0.598,1.794-1.995,1.594-4.185,1.594s-2.791,0.399-4.385,1.993c-1.595,1.594-2.789,1.594-5.18,0s-2.592-0.2-7.173-0.398
         c-4.582-0.198-2.193,1.394-5.182,1.594c-2.989,0.2-3.388,1.395-3.986,3.786c-0.598,2.391-1.394,2.192-3.984,0.398
         c-2.59-1.794-1.196,1.196-2.989,0.398s-2.989,1.595-6.377,1.196c-3.389-0.399-0.4-2.391,1.793-2.59
         c2.193-0.198,2.193-2.193,1.196-3.389s0.796-2.789,0.997-3.985s1.592-3.189-1.994-2.591c-3.587,0.598-4.783,1.993-7.571,2.989
         c-2.788,0.996-1.994,2.193-1.994,4.185s-0.797,1.993-1.992,1.993c-1.196,0-0.796-0.398-1.196-2.391
         c-0.399-1.993-2.79,0.996-4.782,0.398s-4.981,0.997-7.772,2.193c-2.79,1.196-2.391,3.188-7.372,4.383
         c-4.981,1.196-4.184,2.591-6.576,3.388c-2.392,0.798-3.19,0.198-3.389,1.594c-0.198,1.395-1.793,0.797-4.782,0.598
         c-2.989-0.199-3.785,2.192-3.388,3.787s-1.593,3.587-2.191,6.177c-0.598,2.59-3.787,1.395-5.182,2.791
         c-1.394,1.395-4.584,0.796-6.975,0.796s-3.983-1.992-5.179-4.185c-1.196-2.193-2.989-2.59-4.584-3.188s-1.595-1.793-1.394-3.388
         s2.391-0.598,2.391-2.192s1.593-0.598,3.785-1.594c2.193-0.996,4.783-0.997,7.373-0.997c2.589,0,0.2-3.387-0.995-5.181
         c-1.196-1.794-2.791-0.996-3.19-2.591s-2.19-2.391-2.989-2.391s-2.391-0.796-3.188-1.793c-0.796-0.997-4.981,0.797-7.771,0.997
         s-4.584-0.2-6.774-1.793c-2.191-1.594-3.389,0.996-2.989,1.594c0.399,0.598,1.195,0.996,4.383,2.192
         c3.187,1.196,2.79,3.189,2.989,5.978c0.198,2.789-0.796,3.587-1.992,6.378s-2.193,2.989-2.193,4.981
         c0,1.992,3.389,2.391,5.38,2.791c1.992,0.399,0.598,1.992,0.797,5.779c0.198,3.787-0.199,2.789-1.992,3.985
         c-1.794,1.196-0.598,2.989-0.399,4.384c0.198,1.395,0,3.387-1.196,3.189s-0.995-1.993-1.394-3.388s-2.989,0.2-3.986,1.595
         s-2.391-0.598-0.199-1.993c2.193-1.395,1.794-2.591,0.4-2.989c-1.395-0.398-2.791-1.196-4.984-1.196
         c-2.192,0-2.788,0.398-4.184-0.996c-1.397-1.394-3.587,0.796-5.38,3.188c-1.794,2.391-4.384,3.587-8.169,3.986
         c-3.785,0.399-3.787,4.783-7.573,5.579c-3.785,0.796-2.79,2.391-3.187,3.787c-0.397,1.395,2.589,3.787,4.782,4.783
         s1.992,3.587,1.992,6.178s-2.59,1.196-3.986,0s-2.788-0.2-4.185,0.598c-1.396,0.798-2.788-0.598-3.983-2.193
         c-1.196-1.595-4.783-1.196-5.78-0.598s-2.193,0.399-2.59-0.996c-0.397-1.395-3.189-1.196-4.584-3.787
         c-1.394-2.591-1.992-1.394-4.782-1.394s-1.595,2.391-4.185,3.785c-2.589,1.394-0.997,3.388,0.4,3.787
         c1.396,0.398,2.589,1.993,4.581,3.985c1.992,1.992,1.995,0.997,4.584,0.399c2.59-0.598,2.989,1.594,3.188,3.985
         c0.198,2.391-2.391,2.591-3.386,3.587s-2.989,0.797-3.788-0.398c-0.798-1.196-1.992-0.797-3.984-0.797s-3.986-0.598-4.184-1.993
         c-0.199-1.395-1.595-1.594-2.989-2.789c-1.394-1.196-4.185-0.798-6.576-0.798s-2.989-1.793-2.791-2.989
         c0.199-1.196,0.199-3.387-0.796-5.779c-0.995-2.391-0.399-3.189-2.193-4.385c-1.793-1.196,0.4-2.391,2.193-5.181
         c1.794-2.789-0.798-3.189-0.997-5.181c-0.198-1.992-3.587-3.587-4.981-4.185c-1.394-0.598-3.19-1.196-5.182-1.196
         c-1.991,0-0.399-0.997-0.597-2.391c-0.199-1.394-1.794-1.196-2.59-0.2s-2.592-0.598-1.994-1.594
         c0.598-0.996,0.598-1.595-1.394-1.993s-1.196-1.196-3.587-3.587s-0.598-2.789,1.793-2.591c2.391,0.199,3.986,2.193,5.38,4.384
         c1.395,2.192,3.786,1.594,5.579,2.391c1.794,0.798,5.38,1.395,7.573,1.793s5.38,1.594,8.171,2.789
         c2.79,1.196,8.168,1.993,11.755,1.993s5.38,2.391,10.961,3.387s13.35-2.192,16.339-4.185s6.775-4.583,9.764-6.775
         c2.989-2.192,2.592-7.971,1.195-8.769c-1.396-0.797-2.19-2.192-2.391-4.583c-0.201-2.391-1.394-1.793-3.587-1.793
         s-2.391-0.798-3.187-2.391c-0.797-1.594-1.992-2.989-3.986-2.989c-1.995,0-2.989-1.395-5.778-2.192
         c-2.788-0.796-1.396-1.395-3.986-1.395c-2.589,0-3.986-0.598-7.372-3.587s-6.377-3.587-10.76-6.178s-4.983-0.996-7.573-2.989
         s-4.383-1.793-6.177-0.598c-1.793,1.196-3.986-0.398-4.782-1.594c-0.796-1.196-4.983-0.598-6.377-0.598
         c-1.394,0-1.992,0-2.193,3.387s-2.989,3.189-4.582,2.391c-1.592-0.797,2.59-2.59,3.188-4.981c0.598-2.391-1.595-2.791-2.391-1.595
         c-0.797,1.196-3.188,1.395-3.188,0.797s-0.997-1.993-3.587-2.789c-2.589-0.796-1.595-2.591,0.199-1.993
         c1.793,0.598,4.584,0.598,4.782-0.398c0.199-0.996-0.598-2.193-2.989-2.193s-1.793-1.196-3.785-2.192
         c-1.992-0.996-3.986-0.2-2.391,1.395c1.595,1.595-2.193,0.398-3.587,2.789s-4.185,1.395-4.185,0c0-0.911-0.428-1.395-1.388-1.678
         c-0.326,1.07-0.172,2.312,0.026,3.203c0.278,1.251-0.904,1.321-2.224,1.251s-1.251-0.765-2.711-1.321s-2.503,1.251-3.059,2.781
         s-1.946,1.39-3.337,1.46c-1.391,0.07-1.946,1.807-2.92,3.545c-0.778,1.39-2.222,1.799-3.125,1.268
         c-0.393,0.503-0.959,1.066-1.579,1.165c-1.158,0.185-2.641,0.046-2.781,0.556s-0.185,1.066,0.788,1.112s1.761,0.556,1.622,1.298
         s-0.696,1.483-1.437,1.946c-0.741,0.464-0.695,1.39-0.695,2.456s-0.324,1.9,1.02,2.781s1.761,2.642,3.522,2.688
         s2.178,0.093,3.939,2.363c1.761,2.271,3.059,2.92,3.893,3.476s1.206,2.085-0.231,3.476s-3.43,3.893-4.357,5.005
         s-2.966,2.549-2.688,3.615c0.278,1.066-0.881,2.595,0.88,3.337s1.669,1.297,2.549,2.317s1.854,4.681,2.641,5.654
         s1.668,2.224,1.946,2.966c0.278,0.742,1.9,4.171,0.417,4.495c-1.483,0.324-2.502,1.066-2.502,1.946
         c0,0.881-1.065,1.854-0.834,2.734s1.807,2.178,1.112,2.873s-1.251,1.436-1.251,2.595s0.37,2.734,1.297,2.781
         c0.927,0.046,2.132-0.046,3.151,0.927s0.417,1.668-0.649,1.993s-0.88,1.854,0.093,2.549s0.973,1.437,2.132,1.668
         c1.158,0.232,2.456,0.927,2.456,1.807s0.046,2.271-1.344,2.734c-1.39,0.464-5.701,2.966-2.317,4.634
         c3.383,1.668,6.906,4.634,8.249,5.561c1.344,0.927,1.576,1.483,2.224,2.224s2.966,2.41,0.324,5.283
         c-2.641,2.873-12.003,12.906-14.158,14.922s-8.62,7.438-10.357,8.203c-1.738,0.765-3.545,2.572-4.24,3.128
         c-0.484,0.388-1.611,1.486-2.508,2.751c2.809,0.058,5.865,0.394,7.685,2.379c2.193,2.391,1.196,1.196,6.576,1.793
         c5.38,0.598,5.38,2.391,5.179,3.587c-0.2,1.196-3.187-0.198-5.978-0.796c-2.79-0.598-2.589,0.198-3.386,1.793
         c-0.796,1.595-2.391,1.594-5.181,1.196c-2.141-0.305-2.756,1.03-3.286,2.478c1.201,0.713,0.562,2.02,1.038,2.615
         c0.556,0.695,0.556,1.599-0.765,1.946c-1.321,0.347-1.112,2.224-2.92,3.337c-1.807,1.112-2.641,2.92-2.224,4.727
         s-0.07,2.711-0.278,4.24s2.294,3.893,3.059,5.561s-1.112,0.765-2.572,1.39c-1.46,0.626-0.834,1.321-0.834,2.781
         s1.668,1.877,3.059,2.92s0.348,1.529-0.278,2.641c-0.626,1.112-0.556,2.502,0.208,3.267c0.765,0.765,0.417,1.946,1.946,3.684
         s1.112,3.198,1.321,5.074c0.208,1.877,3.267,1.599,4.935,1.807c1.668,0.209,1.946,0.417,3.823,0.417s1.529,1.182,1.877,2.711
         s1.599,1.599,3.198,0.765s1.39-1.738,3.267-1.599s3.754,1.668,6.326,3.059c2.572,1.39-0.209,3.962,0.765,5.005
         c0.973,1.043,1.251,2.016,0.278,3.823c-0.973,1.807,0.417,2.224,1.39,3.893c0.973,1.668,3.615,5.978,5.144,6.187
         s1.738,1.529,1.946,2.989s2.085,0.695,3.406,0.695s1.668,1.877,3.198,3.267c1.529,1.39,0.626,1.529,0.348,2.294
         c-0.278,0.765-1.39,1.877-2.92,1.946c-1.529,0.07-1.668,1.529-2.711,1.599c-1.043,0.07-1.599-0.834-3.545-1.738
         s-1.807,0.556-2.433,1.807s-0.139,1.529,0.834,2.016c0.973,0.487,0.626,1.738,1.39,3.059c0.765,1.321-0.904,0.973-0.348,2.433
         c0.556,1.46,1.46,2.502,2.572,3.684s3.198,0.348,3.754-0.834s1.321-1.251,2.155-1.738c0.834-0.487,1.46,0.834,3.337,1.182
         c1.877,0.348,2.363-1.46,3.406-2.433s2.572,1.46,3.198,0.626s0.904,0.348,2.363,0.695s1.807,3.337,3.267,4.657
         c1.46,1.321,0.348,1.599-0.487,1.738c-0.834,0.139-1.182,1.043-0.765,1.599s0.765,1.877,0.904,3.615s1.738,0.904,3.337,1.807
         c1.599,0.904,2.641-0.626,3.893-0.417c1.251,0.209,2.085,2.641,2.92,4.31c0.834,1.668,0.973,4.796,1.599,5.422
         s2.085,0.695,2.503,0c0.417-0.695,1.251-0.209,2.433-0.07s2.085,1.738,3.337,2.085c1.251,0.348,2.503-0.209,2.92-1.251
         c0.417-1.043,2.781-0.556,3.615-1.321s3.198,2.641,5.144,4.588s2.572,0.417,3.893,0.278s3.198,0.695,4.31,2.155
         s2.642,0.139,3.684-0.417c1.043-0.556,2.503,1.112,4.101,2.711c1.599,1.599,2.224,0.765,3.406,0.556s1.668,0.904,1.599,2.016
         c-0.07,1.112-0.07,1.599-1.599,3.059s-0.278,2.085,0.278,2.989s-0.626,1.043-1.946,1.251s-0.834,1.877-0.209,2.572
         s0.695,1.182,1.39,2.294s1.112,1.182,0.765,2.294c-0.348,1.112-1.112,1.043-1.46,2.572s-4.588,0.626-7.16,0.626
         c-2.572,0-2.711,1.877-4.24,2.433s-1.738,1.599-2.92,2.363c-0.878,0.568-0.758,2.514-0.639,3.479
         c0.907-0.296,1.795-0.638,2.973-0.834c2.392-0.398,3.389-1.793,5.978-0.598c2.59,1.196-0.397,1.793-1.195,1.793
         c-0.799,0-0.797,1.793-1.992,1.993c-1.196,0.2-3.388,0.398-3.785,2.192c-0.397,1.793-3.587,0-4.584,1.793s1.195,1.993,4.184,3.387
         s0,1.595-2.192,1.595c-2.193,0-0.995,1.992-2.989,3.188c-1.995,1.196-1.395,1.196-1.395,3.587s-3.785,0.997-5.779,0.997
         s-2.59,2.391-2.59,3.587s2.989,0.398,4.783,1.594c1.793,1.196,3.187,3.787,5.578,3.587c2.392-0.2,2.791,2.789,7.973,3.985
         c4.512,1.041,6.607,5.708,10.099,8.213c0.686-0.126,1.441-0.272,1.838-0.371c0.834-0.209,2.433-0.417,3.267,0
         c0.834,0.417,2.363-0.07,3.684,0.417c1.321,0.487,3.476,2.224,5.352,2.989c1.877,0.765,2.85,0.834,5.144,0.626
         c2.294-0.209,7.299-0.695,8.203,0.417s1.877,2.155,3.476,2.433s2.572,0.626,2.781,1.46s1.39,2.363,2.363,2.363
         s2.294-0.556,2.85-1.251c0.556-0.695,1.39-0.973,3.267-0.973s4.171-0.626,4.588,0.417s0.556,1.668,2.016,1.668
         s3.476,0.695,3.476,2.294s-2.224,1.529,0.209,2.781c0.152,0.078,0.301,0.146,0.448,0.206c0.03,0.012,0.059,0.02,0.089,0.032
         c0.116,0.045,0.232,0.088,0.345,0.123c0.021,0.007,0.04,0.01,0.06,0.016c0.122,0.036,0.243,0.069,0.361,0.096
         c0.005,0.001,0.009,0.001,0.014,0.002c1.765,0.387,3.154-0.22,4.274,0.746c0.001,0.001,0.002,0.001,0.002,0.002
         c0.012,0.01,0.025,0.018,0.037,0.029c1.39,1.251,2.85,1.182,4.24,3.128s3.615,4.379,4.866,4.657s3.128,0.765,3.823-0.348
         s1.112-1.807,2.711-2.016s1.599-1.112,2.363-2.155c0.433-0.59,0.976-1.758,1.367-2.662l-0.001-0.001
         c-0.096-0.1-0.203-0.187-0.324-0.257c-0.973-0.556-1.946-1.112-2.641-2.92s-3.337-4.31-5.144-6.117s-1.112-6.534-1.112-8.481
         s0-2.502-0.695-3.476c-0.695-0.973-1.251-1.807-2.224-3.198c-0.973-1.39-2.224-1.807-3.754-2.641
         c-1.529-0.834-0.973-2.92,0.973-3.754c1.946-0.834,0.556-1.668,2.224-2.781c1.668-1.112,1.39-2.224,2.363-3.476
         c0.973-1.251,0.973-1.529,0.973-2.92c0-1.39,3.476-1.112,3.476-1.112c1.529-0.139,1.946,1.112,2.781-0.278
         s0.973-0.973,2.224-1.529s1.251,0.139,2.085,0.834c0.834,0.695,1.529-1.946,1.668-2.781c0.139-0.834,0.556-1.251,1.946-1.39
         c0.108-0.011,0.205-0.024,0.297-0.038c0.026-0.004,0.05-0.008,0.074-0.013c0.07-0.012,0.136-0.026,0.197-0.041
         c0.017-0.004,0.036-0.008,0.052-0.012c0.074-0.02,0.141-0.042,0.203-0.065c0.011-0.004,0.02-0.009,0.03-0.014
         c0.05-0.021,0.097-0.044,0.139-0.069c0.012-0.007,0.025-0.015,0.037-0.022c0.046-0.029,0.089-0.061,0.127-0.095
         c0.002-0.001,0.003-0.002,0.005-0.004c0.04-0.037,0.075-0.077,0.106-0.12c0.007-0.009,0.014-0.019,0.02-0.028
         c0.026-0.037,0.049-0.075,0.071-0.117c0.005-0.009,0.01-0.018,0.015-0.027c0.025-0.052,0.049-0.106,0.07-0.165l0,0
         c-1.871-1.835-3.352-2.067-5.382-2.969c-2.502-1.112,1.761-2.781,1.854-3.707c0.093-0.927-1.39-2.966-2.41-3.707
         s-2.317-3.244-3.337-4.912c-1.019-1.668-3.986-1.668-5.283-2.039s-2.039,0.649-3.522,0.649s-1.854-3.151-1.946-5.19
         c-0.093-2.039-2.781-1.39-4.449-2.873c-1.668-1.483,0.186-1.761,0.464-3.059s1.668-2.502,3.244-3.8s-0.185-2.688-1.019-3.707
         c-0.834-1.02-0.371-2.873,0.185-3.985c0.556-1.112,1.297-2.502,3.059-3.429c1.761-0.927,0.649-2.781,1.854-4.078
         s2.224-0.556,3.059,0.463s2.317,1.668,3.986,4.356c1.668,2.688,3.43,1.02,4.912-0.093c1.483-1.112,0.927-1.761-0.556-2.595
         c-1.483-0.834-0.278-2.688-0.278-4.449s0.371-1.205,3.151-1.668c2.781-0.464,3.8-2.873,4.356-4.263s2.317-1.668,4.542-1.854
         c2.224-0.185,3.893-1.668,4.727-3.337s1.39-2.688,3.43-2.595c2.039,0.093,1.668,1.761,3.8,2.781
         c2.132,1.02,3.522-0.278,3.615-1.761s1.668-0.927,3.151-0.927s2.41,0.649,2.873,2.224s4.82,0.649,7.322,0.556
         s2.317,3.059,3.986,3.429c1.668,0.371,2.317,2.132,5.19,3.8s0.185,3.986,1.854,4.171c1.668,0.185,1.205-3.151,1.205-4.263
         s1.39-0.927,3.986,1.205s3.754,2.502,5.283,2.363s2.549-1.761,3.615-3.059s1.437-2.085,2.781-1.761s2.549-0.881,3.847-0.973
         c1.297-0.093,3.43,1.761,4.681,2.456c1.251,0.695,2.595-1.483,2.734-2.317c0.139-0.834,3.708-0.371,4.959-0.417
         s2.595,2.595,3.43,3.661c0.834,1.066,2.039,1.344,3.661,1.344s2.271,0.51,3.476,2.224s3.012-1.205,4.171-3.105
         s2.132-0.649,2.827,0.371s3.615,0.927,5.005,0.927s2.039-1.298,3.105-1.298s1.483-2.132,2.966-3.198
         c1.483-1.066,1.39-2.224,0.834-2.688c-0.556-0.463-2.132-2.132-3.708-2.456s-1.575-0.927-2.224-1.483s-1.807-0.371-3.198-0.417
         c-1.39-0.046-1.529-2.271-3.244-2.92s-1.297-1.946-0.695-2.039s1.575-0.556,2.827-0.927c1.251-0.371,1.946-0.695,3.522-1.714
         s1.019-2.966-0.371-3.893c-1.39-0.927-1.205-2.085-0.232-2.456s0.788-1.714,1.529-2.456c0.741-0.742,5.005,0.417,6.951,0.834
         c1.946,0.417,2.271-1.807,2.039-2.41s-2.132-0.463-4.078-0.741s-1.715-1.02-1.668-2.271s-1.251-1.112-2.132-1.483
         c-0.881-0.371-1.019-0.973-0.741-1.622s0.185-1.946-0.417-3.29s1.714-1.066,2.317-1.437s2.085-0.51,3.058,0.185
         c0.973,0.695,2.641,0.834,3.893-0.417s2.92,0.556,5.422,0.139c2.503-0.417,3.615-0.417,5.422-1.668s4.727-0.417,5.7-1.529
         s3.615-0.834,6.256-0.834s5.005-0.139,6.117-1.668s3.198-1.668,5.422-1.946s11.956-2.641,14.876-2.92
         c2.919-0.278,4.866,0,5.839-2.781c0.973-2.781,3.545-1.043,5.074-2.155s3.128-1.946,6.117-0.973
         c2.989,0.973,2.989,1.668,5.005,2.641s2.433-0.348,2.711-1.251c0.278-0.904,1.529-0.695,2.572-0.278s1.668,1.46,2.016,3.406
         c0.348,1.946,0.556,2.572,1.321,3.893c0.765,1.321,0.904,0.834,0.973,2.085s-0.487,2.155-1.529,2.711
         c-1.043,0.556,2.92,1.251,4.518,2.224c1.599,0.973,1.738-1.529,2.433-1.529s1.668-0.07,2.92-1.321
         c1.252-1.251,1.043,1.043,1.668,1.529c0.625,0.486,1.39,1.321,1.807,2.781s1.599-0.139,2.155-1.39
         c0.556-1.251,1.39,0.626,2.363,1.529c0.973,0.904,2.572,0.973,3.406-0.209s1.668-1.46,2.503-0.556
         c0.834,0.904-0.626,1.39-1.112,2.363c-0.487,0.973-0.626,1.251-2.155,1.807s-0.626,2.085,0.834,3.337
         c1.46,1.252,2.572-2.016,3.198-2.224c0.626-0.209,2.085-0.487,2.919-0.07s1.807,0.695,3.059,1.668
         c1.251,0.973,0.834-1.599,0.973-2.363c0.139-0.765,1.251-1.251,1.599-1.877s1.668-0.209,3.476-0.348
         c1.807-0.139,2.155-1.46,2.155-2.502s1.182-0.834,2.85-0.834c1.668,0,1.831-0.139,2.573-1.066
         c0.742-0.927,2.595-1.668,4.541-1.946c1.946-0.278,3.615-1.112,3.615,0.371s-2.132,1.946-3.244,3.615
         c-1.112,1.668,2.781,3.151,6.58,5.468c3.8,2.317,7.6,8.62,10.937,11.956c3.337,3.337,10.01,17.517,12.419,20.854
         s4.913-1.39,5.005-2.781c0.092-1.39,1.761-2.966,2.873-2.688s2.873,1.02,2.873,3.522s1.853,0.185,2.502,1.297
         s1.297,1.39,2.039,1.298s2.317-0.185,3.615,0.834c1.297,1.02,2.317,1.298,3.059,0.185c0.741-1.112,2.688-0.742,4.542-1.946
         c1.853-1.205,4.078-1.298,6.21-0.742s3.985,3.893,4.912,6.395s2.781,2.317,5.654,2.595s1.575,2.224,2.595,4.264
         c1.019,2.039,2.41,2.132,4.635,3.059c2.224,0.927,5.839,0,7.229-1.761s2.781,0,2.781,1.298s0.556,1.668,1.946,2.41
         c0.094,0.05,0.177,0.104,0.252,0.16c0.024,0.018,0.042,0.037,0.065,0.055c0.048,0.04,0.095,0.081,0.135,0.124
         c0.021,0.022,0.038,0.046,0.057,0.069c0.034,0.042,0.066,0.084,0.092,0.127c0.015,0.024,0.028,0.049,0.041,0.073
         c0.023,0.046,0.043,0.092,0.06,0.139c0.008,0.024,0.016,0.047,0.023,0.071c0.014,0.052,0.023,0.105,0.03,0.159
         c0.002,0.02,0.005,0.04,0.007,0.06c0.004,0.066,0.002,0.133-0.005,0.201c-0.001,0.009,0,0.017-0.001,0.025
         c-0.02,0.161-0.07,0.325-0.147,0.49c-0.001,0-0.001,0-0.001,0.001c0.485,0.164,0.996,0.424,1.337,0.841
         c0.834,1.02,1.483,0.556,2.781,0.649c0.092,0.007,0.181,0.015,0.269,0.025c0.049,0.005,0.096,0.011,0.143,0.016
         c0.031,0.004,0.062,0.007,0.093,0.011c0.382,0.047,0.731,0.096,1.075,0.066c0.007-0.001,0.014-0.001,0.021-0.002
         c0.073-0.007,0.146-0.018,0.218-0.033c0.009-0.002,0.018-0.004,0.027-0.005c0.079-0.018,0.157-0.04,0.237-0.069
         c0.099-0.036,0.198-0.084,0.299-0.141c0.014-0.008,0.027-0.016,0.04-0.024c0.096-0.057,0.194-0.124,0.293-0.204
         c0.005-0.004,0.009-0.006,0.014-0.01c0.099-0.081,0.201-0.178,0.305-0.285c0.024-0.024,0.048-0.05,0.072-0.076
         c0.105-0.114,0.213-0.236,0.325-0.38c1.298-1.668,2.688-3.707,4.357-3.707s2.873,1.02,4.541-0.093
         c1.668-1.112,2.41-1.483,3.8-1.112s1.946-0.927,2.317-2.039s-0.278-2.688,1.576-2.688s2.41-2.225,4.912-2.317
         s4.264-0.834,5.561-2.039s2.595-1.575,4.542-1.483c1.946,0.093,0.463-2.224,2.873-2.132s2.132-1.298,3.893-1.39
         c1.761-0.093,2.688-0.185,2.781,0.742s1.206,1.39,2.132,1.019c0.927-0.371,1.297-0.741,1.761,0.185
         c0.464,0.927,1.112,0.834,3.986,0.834c2.873,0,7.878-0.649,7.414,0.927c-0.463,1.576-0.186,3.986,1.39,4.542
         s3.058-0.463,3.986,0.834c0.927,1.297,1.946,0.556,3.058,1.205c1.112,0.649,2.225,1.39,3.151,0.371s2.224-1.483,3.8-1.019
         c1.576,0.463,0.834,1.483,2.781,1.483c1.946,0,4.634-0.927,5.19,0.278c0.556,1.205,1.297,0.464,1.946,1.298
         s0.741,0.371,1.112-0.278c0.371-0.649,1.483-0.093,2.039-0.927c0.556-0.834,0.556-1.39,2.224-1.668s2.317-0.278,2.966-1.575
         c0.649-1.298,0.278-1.669,1.39-2.317s1.113-1.483-0.37-2.41s-1.854-1.205-2.224-2.502c-0.371-1.298-1.484-1.576-1.576-2.873
         s-0.186-1.761,0.556-2.873c0.742-1.112,0.279-2.502,1.576-2.873s1.761-3.059,3.8-3.8c2.039-0.742,2.595-0.834,2.873-2.039
         c0.278-1.205,0.185-2.781,1.854-1.205s2.317,1.39,3.8,1.668s1.946,0.278,2.595,1.298c0.649,1.02,1.483,1.019,3.615,0.927
         c2.132-0.093,3.151-0.556,4.264,0.371s2.224,1.668,3.522,1.761c1.297,0.093,1.946,1.483,4.078,1.39s3.985-0.278,4.449,1.112
         s0.464,2.503,1.205,3.244s0.833,1.946,1.019,3.429s-0.185,1.946,1.205,2.781c1.39,0.834,1.391,1.575,4.264,2.317
         s3.707,0.649,5.19,1.668c1.483,1.02,3.244,0.927,4.727,0.649s1.483-1.112,3.429-2.132c1.946-1.02,1.853-0.927,3.429-1.02
         c1.576-0.093,1.112-1.112,3.337-1.112s2.781-0.185,4.171,0.649s2.132,0.556,3.151,0.834s0.649,1.205,2.317,1.112
         s2.873-1.946,4.541-0.464c1.668,1.483,2.132,3.893,4.357,3.893c2.224,0,5.654-0.649,5.839,1.576c0.185,2.224,0.834,3.151,2.41,3.8
         c1.575,0.649,1.576,2.317,3.708,2.132s3.244-1.391,5.746-0.185s2.502,1.854,4.912,1.854s6.581,0.185,8.805,0.093
         c2.224-0.093,1.761-2.317,4.727-2.317s4.82-0.464,6.673-0.742c1.854-0.278,2.132-1.019,4.078-1.019s4.356,0.185,4.634-1.298
         c0.278-1.483-0.093-2.132,1.668-2.873c1.761-0.742,1.669-1.669,3.522-2.039c1.853-0.371,1.112-0.464,2.502-1.39
         c1.39-0.927,1.02-1.02,3.059-1.112s2.781,0,5.283,0.927s1.297,1.854,2.966,2.873s4.449,1.298,5.283,0.464s1.205-1.668,2.873-1.39
         c1.668,0.278,3.151,0,4.171,1.205c0.12,0.142,0.222,0.285,0.316,0.427c0.003,0.005,0.007,0.01,0.01,0.015
         c0.093,0.143,0.176,0.286,0.256,0.426c0,0,0,0.001,0.001,0.002s0.002,0.003,0.002,0.004c0.047,0.082,0.093,0.164,0.139,0.244
         c0.001,0.003,0.003,0.005,0.004,0.008c0.039,0.067,0.077,0.133,0.116,0.197c0.004,0.006,0.008,0.013,0.011,0.019
         c0.041,0.067,0.083,0.133,0.127,0.198c0.006,0.008,0.012,0.017,0.018,0.026c0.036,0.053,0.074,0.105,0.115,0.156
         c0.013,0.017,0.027,0.033,0.04,0.05c0.038,0.046,0.078,0.092,0.119,0.137c0.013,0.013,0.025,0.027,0.037,0.04
         c0.055,0.056,0.114,0.111,0.177,0.164c0.009,0.008,0.02,0.015,0.029,0.023c0.054,0.044,0.112,0.086,0.173,0.128
         c0.022,0.015,0.044,0.029,0.066,0.043c0.056,0.036,0.116,0.07,0.178,0.103c0.023,0.012,0.045,0.025,0.068,0.037
         c0.087,0.043,0.178,0.085,0.277,0.124c0.01,0.004,0.021,0.007,0.03,0.011c0.09,0.034,0.187,0.067,0.289,0.097
         c0.034,0.01,0.07,0.02,0.105,0.029c0.087,0.024,0.178,0.046,0.274,0.067c0.038,0.008,0.075,0.017,0.115,0.025
         c0.135,0.027,0.275,0.052,0.427,0.073c2.688,0.371,2.966,0.649,4.542,1.205c1.575,0.556,1.946,1.668,3.985,0.371
         c2.039-1.298,2.317-2.966,4.264-3.522c1.946-0.556,2.131-1.575,3.8-1.575s3.337-0.649,3.708-1.854s-0.278-1.854-1.112-2.502
         c-0.834-0.649-0.556-2.039,0.834-3.059s2.039-2.039,2.781-3.707c0.741-1.668,1.761-2.41,2.317-4.542s1.297-4.356,3.151-5.376
         s3.893-1.854,4.449-3.059s0.557-1.576-0.092-2.132s-1.02-1.297-1.02-2.132c0-0.834-0.092-2.502-1.761-2.41
         c-1.668,0.093-3.43,0.093-3.43-0.927s-0.185-1.39,1.483-1.854c1.668-0.464,1.946-2.781,4.171-3.893s2.039-1.483,3.429-1.483
         s1.669-1.112,3.522-1.205s3.151,0.093,5.005-0.278c1.853-0.371,1.112-1.112,3.893-1.112s3.522,0.742,5.468,0.185
         s2.966-1.297,4.264-0.927c1.297,0.371,2.873,1.575,4.449,1.853c1.575,0.278,2.41,1.019,2.966,1.668
         c0.556,0.649,1.205,1.02,3.059,0.927s5.746-0.556,6.58,0.834s1.484,2.595,2.966,3.059c1.483,0.463,2.039,1.575,2.41,2.873
         c0.371,1.298,2.502,1.483,2.688,3.429s0,4.263,1.39,5.839s1.576,2.039,1.854,2.966s1.112,1.946,1.204,3.707
         c0.093,1.761-0.092,2.132,1.02,3.244s1.575,1.483,2.41,2.966c0.834,1.483,0.649,2.039,1.112,3.707s2.132,1.298,1.575,3.244
         s-0.463,3.708,0.649,4.727s2.41,2.317,3.615,2.502s2.039,1.483,2.966,0.185c0.927-1.297,1.854-1.39,2.873-0.556
         s2.688,3.337,4.727,2.224c2.039-1.112,2.781-0.278,4.171,1.02s1.853,3.614,3.8,4.263s4.357,0.093,4.357,1.39
         c0,1.298-0.278,2.132,0.834,2.873c1.112,0.741,0.648,2.688,0.648,3.8s-1.576,2.41,0.278,3.337s1.854,1.947,2.224,2.317
         c0.371,0.371,1.854,0.648,3.244-0.093s1.575,0.464,3.707,0.649c2.132,0.185,3.615,0.463,5.005-0.927s2.596-3.615,4.356-3.707
         s3.8,0,5.005-0.742c1.204-0.741,1.112-1.02,3.151-1.39c2.039-0.371,1.483-1.853,3.151-1.853s4.357-0.093,4.542,0.927
         c0.185,1.02,0.834,1.947-0.186,2.41c-1.019,0.463-2.224,1.39-0.927,2.317s1.668,2.132,1.019,3.615
         c-0.649,1.483-1.483,1.761-2.595,1.854c-1.112,0.093-2.039,1.483-2.039,3.429s-2.132,3.522-2.039,5.283s0.185,4.171-0.927,5.19
         s-1.761,1.205-2.317,3.059c-0.556,1.853-0.834,3.8-2.224,4.263c-1.39,0.464-1.762,0.834-1.854,2.781
         c-0.092,1.946-0.556,2.873-2.781,2.502c-2.224-0.371-4.356-1.297-5.746-2.039s-2.781-1.391-3.615-0.093s-1.39,2.41-2.966,2.688
         c-1.576,0.278-3.8,0.278-3.986,1.39s0.464,2.224,1.205,2.873s0,1.483,0.741,2.132s0.927,2.039,0.834,3.429s-0.463,4.264,0,5.376
         s0.185,2.595,0,3.893c-0.185,1.297-0.742,0.834-1.483,2.224s-1.205,1.946-2.688,2.039s-1.575-0.463-1.854,0.742
         c-0.035,0.154-0.085,0.298-0.146,0.432c-0.01,0.023-0.026,0.042-0.036,0.065c-0.054,0.11-0.112,0.215-0.18,0.31
         c-0.006,0.009-0.014,0.015-0.02,0.024c-0.16,0.216-0.352,0.388-0.562,0.509l-0.001,0.001c0.052,0.381,0.167,0.731,0.388,0.977
         c0.449,0.499,0.761,0.89,0.999,1.275c1.652-2.739,6.352-4.887,9.697-5.673c3.389-0.796,5.381,1.196,9.565,1.992
         c4.185,0.796,6.774-0.198,10.163-1.594c3.388-1.395,8.568-5.778,10.959-8.767s8.369-8.967,12.953-14.149s4.383-4.981,8.967-8.568
         s6.176-9.167,7.57-12.953c1.394-3.787,4.185-3.985,7.174-8.768c2.989-4.783,3.986-5.978,5.978-7.572
         c1.992-1.594,2.592-4.982,2.79-7.771c0.199-2.789,0.4-2.591,1.794-5.38c1.394-2.789,0.399-7.373-0.399-10.163
         c-0.799-2.789,0-3.587,1.195-5.78c1.196-2.193,1.794-5.082,2.788-7.772c0.995-2.69,2.592-2.789,3.389-4.682
         c0.796-1.893-0.101-2.989-1.095-3.986c-0.995-0.997-0.1-2.192,0.299-2.889c0.4-0.697,0.397-2.492-1.396-4.484
         s0.897-1.196,2.391-2.292c1.495-1.096-0.299-2.292-2.291-3.387c-1.992-1.095-2.092-1.296-4.285-2.391
         c-2.192-1.095-2.788-3.886-3.886-6.079s-2.59-2.092-3.785-1.594c-1.196,0.499-3.886,0.299-5.978-0.598
         c-2.093-0.897-2.791-1.196-3.09,0.499c-0.299,1.694-0.696,3.686-2.291,3.686s-3.089,1.295-4.084,3.387s-3.288,1.395-4.684,0.399
         s1.196-1.993,2.492-3.388c1.296-1.395-1.495-0.796-2.291-1.992s2.19-2.492,3.087-4.384c0.897-1.893-2.291-0.897-2.69-0.2
         c-0.399,0.697-1.693,1.395-3.188,2.193s-0.1,2.391-0.198,3.886s-0.997,2.291-2.791,2.291c-1.793,0-1.195-4.383-1.195-6.277
         s1.992-3.288,1.495-4.583s-4.285-0.798-5.579,0s-2.891,0.697-4.584-0.398s-3.687-0.598-4.184-1.993
         c-0.498-1.395,1.992-3.686,4.584-4.484s3.486-1.395,4.881-3.587c1.394-2.192,1.894-1.793,5.581-3.387s6.475-6.079,9.864-7.772
         c3.388-1.693,0.598-2.69,1.992-4.085c1.394-1.395,3.187-3.686,5.779-5.58s4.084-1.992,6.277-3.985
         c2.192-1.993,3.087-3.587,5.777-4.384s2.093-1.893,3.587-5.38s2.79-2.889,5.88-3.985s2.291-3.886,4.682-6.576
         c2.392-2.69,4.682-1.793,8.269-3.088s4.087-0.698,7.872-1.196c3.785-0.497,5.481-0.399,8.668-0.399s5.578-0.099,7.872-0.198
         c2.293-0.099,1.891,2.491,3.886,3.088c1.994,0.598,1.794-1.495,3.087-3.088c1.294-1.594,3.687,0.796,4.983,0.497
         c1.296-0.299,2.489-0.099,4.185,1.096c1.695,1.196,3.685-0.897,4.682-1.993s1.693-0.198,2.889,1.096
         c1.196,1.295,2.293,0.798,3.587,0.697c1.294-0.1,0.997-1.095,0.997-2.49s1.793-0.499,2.291-1.594
         c0.497-1.095,2.791-2.092,5.082-1.993s4.783,1.196,7.174,2.591s2.293,1.793,3.886,1.295c1.593-0.499,2.293-1.893,4.883-0.598
         c2.59,1.295,1.394,1.594,3.886,3.288s-1.595,0.996-3.987,0.897c-2.391-0.099-3.187,2.591-1.495,4.086
         c1.693,1.495,2.69,0.996,4.285,0c1.595-0.996,2.391-0.698,3.288-1.395s2.889,0.299,4.383,1.096
         c1.495,0.798,1.894,0.099,2.891-0.698s2.889-1.793,3.587-3.486s4.484,0.796,5.578,1.693c1.095,0.897,1.992,0,2.291-0.897
         c0.299-0.897,2.592-0.598,4.484-0.198c1.891,0.399,2.192-0.598,3.687-0.997c1.495-0.399,0.4-1.693-0.399-2.491
         c-0.799-0.798-1.891-0.2-3.886-1.295c-1.994-1.095-1.794,1.295-2.788,1.295c-0.995,0-1.098-1.993-1.196-3.587s0-2.391,0.897-2.391
         s1.794-1.993,2.391-3.189c0.598-1.196,2.69-2.491,5.082-3.985c2.391-1.495,3.886-3.985,6.973-6.277
         c3.087-2.292,6.875-7.075,9.666-9.664c2.79-2.59,5.878-2.69,7.872-2.092c1.994,0.598,2.59,0.697,3.886-0.698
         s4.783,0.499,5.18-0.497c0.397-0.996,1.296-0.897,2.391-0.798s1.595,1.395,2.492,2.789s3.288-0.099,3.986-1.495
         c0.698-1.395,1.992-1.594,3.087-0.398c1.095,1.196-0.497,1.196-0.897,2.591c-0.399,1.395-0.897,1.394-2.589,2.889
         c-1.693,1.495-1.595,0.798-1.095,2.492s1.196,0.598,1.196,2.491s0.597,1.793,1.592,1.096s1.495,0.198,1.595,1.394
         s-1.296,0.897-1.793,3.089c-0.497,2.193,2.989,0.299,4.782-0.797c1.794-1.096,4.682-3.088,7.174-6.077s5.779-4.185,8.567-5.978
         c2.789-1.793,2.791,0.099,4.484,0.398s1.894-1.694,1.397-3.189c-0.497-1.495,0-1.495,0-2.989c0-1.495-0.1-2.192,0.397-2.889
         s1.894-2.69,2.592-4.285s3.587,0,4.184-1.196c0.598-1.196,2.69-0.299,4.881-0.398s3.389,0.897,3.389,2.292s-3.288,0-4.584,0.598
         s-1.693,2.789-1.892,4.783c-0.199,1.993,0.397,3.387-1.296,5.181c-1.693,1.793-1.394,1.594-1.595,2.591
         c-0.2,0.997,1.595,1.095,1.894,2.291c0.299,1.196,0.1,0.997-1.693,1.894s-1.695,2.291-1.793,4.185s-2.492,1.295-5.082,1.893
         c-2.59,0.598-3.886,2.491-5.779,3.189c-1.894,0.698-3.688,0.598-4.185,2.789c-0.497,2.192-0.698,2.092-2.889,3.686
         s-3.489,3.587-5.38,4.683s-4.087,3.488-6.478,4.882s-2.092,1.894-3.886,4.883c-1.793,2.989-2.889,3.188-5.479,5.48
         c-2.589,2.292-2.891,2.591-5.88,4.783s-3.823,2.205-5.392,1.943c-1.569-0.261-1.793,0.224-2.914,1.719s-1.569,1.121-3.512,0.897
         s-1.457,0.075-2.802,0.56c-1.345,0.486-0.075,1.607,0.71,3.923c0.785,2.317-1.831,4.297-2.877,5.082
         c-1.046,0.785-3.512,2.653-5.081,5.717c-1.569,3.064-2.952,7.062-2.952,8.407s-0.897,6.464-1.009,9.714
         c-0.112,3.251,3.063,16.477,3.736,17.635c0.672,1.158-0.336,4.745,0.374,8.369c0.71,3.624,1.644,3.886,2.578,5.567
         s0.037,7.211,0.972,8.594c0.934,1.382,0.187,4.67,0.897,6.426s1.158,1.457,1.158,3.176s4.67-1.868,5.455-2.765
         c0.784-0.897,2.391-1.98,5.231-4.446c2.839-2.466,3.699-2.914,4.932-5.081s1.345-4.521,1.644-6.987
         c0.299-2.466,1.57-2.391,2.615-3.587c1.047-1.196,3.251-1.271,4.185-2.018c0.934-0.747,2.653-1.196,4.147-0.149
         c1.495,1.046,1.606,0.635,2.204,0.075s-0.523-1.532-0.785-2.055c-0.261-0.523,0.112-1.905-0.635-3.213
         c-0.747-1.308-0.523-1.906-0.411-3.139c0.112-1.233,0.635-2.802,1.719-3.251c1.083-0.448,2.429-2.466,3.55-3.736
         c1.121-1.27,5.53-1.98,7.883-1.308c2.354,0.673,3.849-0.822,5.604-2.653c1.756-1.831,0.374-3.251-1.009-4.484
         s-1.121-2.242-1.532-4.446s0.299-3.325,1.495-5.53c1.196-2.204,3.512-2.728,4.259-4.783c0.747-2.055,2.13-0.485,3.512,1.42
         c1.383,1.906,2.093,0.897,3.513,0.075c1.42-0.822,1.644-2.615,1.457-4.409c-0.187-1.793-1.681-2.466-3.325-3.512
         c-1.644-1.046-1.345-0.71-1.644-1.98c-0.299-1.27,0.037-2.54,0.299-4.297c0.261-1.756,0.411-2.242,1.382-2.877
         c0.972-0.635,2.13-1.719,2.504-3.064s-1.606-1.308-2.317-1.345c-0.71-0.037-0.934-0.635-1.121-1.495
         c-0.187-0.859-2.541,0.075-2.802,0.972s-0.224-0.972-2.017,0.523s-2.69,2.391-3.587-2.69c-0.897-5.081,5.381-8.668,8.37-11.059
         s3.586-4.185,3.586-5.978s1.495-4.783,2.989-7.772s5.082,0,5.978-0.897c0.897-0.897,2.69,0,3.886,1.495
         c1.195,1.495,3.586-0.598,4.484-2.391c0.897-1.793,4.184-1.793,5.38-2.989c1.195-1.196,2.989-3.288,3.587-1.495
         c0.597,1.793-0.897,3.288-0.897,6.277s4.484,0.598,6.277-2.391c1.793-2.989,3.288-2.989,5.38-3.587s3.886-2.69,7.174-2.69
         s7.473,0.598,8.668,0.598c1.196,0,3.288,2.69,4.484,2.989s2.391,2.989,3.886,5.38s2.989-4.783,4.484-7.174
         s5.381-1.196,8.071-2.989c2.69-1.793,4.484-2.092,5.082-3.886s3.288-2.69,5.38-4.783c2.092-2.092,2.989,0.299,4.783-2.989
         c1.793-3.288,3.886,0,5.38-2.092c1.495-2.092,4.484-0.299,5.679-2.391c1.196-2.092,3.288-0.299,3.587-1.495
         c0.299-1.196,4.484-2.69,7.174-3.886s4.782-0.299,5.679-2.092s2.392-1.793,4.484-2.092c2.093-0.299,0.897-3.886,0.897-3.886
         s1.793,0,2.989,2.092c1.195,2.092,2.391,2.391,4.184,2.391c1.794,0,3.886,0.299,6.277,1.495s5.38,0.299,5.978-0.897
         c0.597-1.196,3.288-1.495,3.587-3.886c0.299-2.391-2.69-1.793-2.69-3.587s-0.897-2.989-1.794-2.989s-2.391-1.196-2.989-3.886
         c-0.597-2.69,0.299-4.484-0.597-5.38c-0.897-0.897-1.196-2.391-2.092-4.484s-2.69-1.495-3.886-0.598s-2.69-0.299-3.886-0.897
         c-1.195-0.598-1.793-1.793-2.391-3.587c-0.598-1.793-1.793-2.69-1.793-2.69s-2.69,2.391-4.484,2.391c-1.794,0-2.989,0-2.989-2.391
         s2.092-2.69,3.587-1.495c1.495,1.196,3.586-0.299,4.184-1.196c0.598-0.897,2.391-0.299,3.288,0.897s3.586,3.288,7.173,3.288
         c3.587,0,6.277-0.299,8.071-2.092c1.793-1.793,4.185-2.391,7.473-3.886s2.093-2.391,2.093-3.886s1.195-1.793,1.495-4.185
         c0.299-2.391-2.391-2.989-3.288-5.38s2.989-0.897,3.886-3.288s1.495-0.598,3.586-1.196c2.092-0.598,5.679,1.495,1.794,3.886
         c-3.886,2.391,1.495,4.484,1.195,6.277c-0.299,1.793,2.392,1.793,5.381,1.793s5.978,0,8.668-0.897s5.978,0.299,8.369,0.598
         c2.392,0.299,3.288,6.277,4.484,8.37c1.196,2.092,4.185,3.886,8.668,3.288c4.484-0.598,3.587,0.299,4.782,2.989
         c1.196,2.69,2.092,0.598,4.783,0.598c2.69,0,3.886,2.092,5.38,1.495c1.495-0.598,5.082-0.897,5.082-2.092
         c0-1.196-0.897-1.196-3.587-1.495c-2.69-0.299-1.793-4.185,1.196-4.185s0.897-1.793,3.886-2.989s0.897-3.288-0.897-5.679
         C1927.326-299.849,1933.304-297.757,1937.489-297.757z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M706.325-557.54
         c-1.908,1.388-0.868,2.602-2.949,2.602s-4.162,0.521-5.203-0.347c-1.041-0.867-4.336,0.867-5.204,0.867
         c-0.867,0-5.203,0.347-1.214,1.214s2.775,1.735,1.561,2.255s-2.427,2.255-4.336,1.214c-1.908-1.041-2.948-1.908-4.51-1.561
         c-1.561,0.347-2.775,1.734-0.347,2.602c2.428,0.867,3.122,1.214,4.51,0.867s4.683-0.174,3.642,0.694
         c-1.041,0.867-0.52,2.081,1.735,2.081s0.173-1.734,3.295-1.388c3.122,0.347,4.336,0.347,4.857-0.867
         c0.52-1.214,1.561-1.734,0-2.602c-1.561-0.867-1.561-1.908,1.908-1.388s4.682,1.56,5.723-0.694
         c1.041-2.255,2.775-2.775,4.857-2.775s5.377,0.694,6.418-0.694c1.04-1.388,2.255-1.908,3.642-1.908s1.561-1.388,1.561-1.388
         s-1.041-1.388-3.816-1.388c-2.775,0-5.03,1.04-4.857,0.173c0.174-0.867,1.735-1.561,0.521-1.734
         c-1.214-0.173-1.735-1.214-5.551-1.041c0,0-2.082,1.735-4.51,1.735c-2.428,0-5.376,1.734-2.601,1.908
         C708.233-558.927,708.234-558.927,706.325-557.54z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M669.208-555.805
         c4.336,0.52,5.551,0,7.632,1.561s3.989,1.388,5.55,0s4.857-1.041,5.897-2.949s3.642-2.602,6.07-1.041
         c2.429,1.561,6.071,1.041,6.071,0.347c0-0.694-1.735-3.295-3.642-2.949c-1.908,0.347-4.51,0.52-5.377-0.347
         s-2.948-2.428-7.285,1.388c0,0-2.602,1.561-5.724,1.561s-5.376,0.694-7.458,0.694C668.861-557.54,664.871-556.325,669.208-555.805
         z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M712.804-547.098
         c0.695-1.043,1.39-1.251,0.278-1.877c-1.112-0.626-1.529-0.626-1.668-0.348c0,0-0.834,1.182-1.321,1.738
         c-0.487,0.556-1.182,1.877-0.209,1.877C710.858-545.707,712.109-546.055,712.804-547.098z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M715.376-565.032
         c1.043,0.417,1.738,0,1.877-0.695c0.139-0.695,1.599-0.904,2.085-0.695s2.085-0.417,1.182-1.251
         c-0.904-0.834-2.085-1.112-2.919-1.043c0,0-1.877,0.487-2.085,1.251C715.307-566.7,714.334-565.449,715.376-565.032z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M716.558-542.649
         c1.39,0,1.738-0.208,2.85,0.556c1.112,0.765,3.128,0.348,3.893,0.07s1.112-1.807-0.834-3.128s-1.946-0.973-2.92-0.765
         c0,0-0.695,0.834-2.363,1.182c-1.668,0.348-4.588,0.348-4.24,1.46C713.291-542.162,715.168-542.649,716.558-542.649z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M737.759-547.932
         c1.807,0,5.283,0.278,5.7-0.487s-1.043-2.641-2.363-3.128c-1.321-0.487-0.487-1.043-1.877-1.39c0,0-1.251-0.556-1.738,0.487
         s-0.904,1.738-2.642,2.155c-1.738,0.417-2.085,1.321-1.529,1.877C733.867-547.862,735.952-547.932,737.759-547.932z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M746.588-553.562
         c1.39,0,3.267,2.155,4.032,0.834c0.765-1.321-0.417-3.615-2.363-3.754c0,0-2.155-0.139-2.155,0.695
         S745.197-553.562,746.588-553.562z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M749.855-559.471
         c2.085,0,2.503-0.139,3.406,0.348c0.904,0.487,2.572,1.112,2.989,0.348s0.765-0.973,2.294-0.834s2.572,0.139,2.85,1.043
         c0.278,0.904,0.417,1.46,3.198,1.46s7.855,0.973,8.481-0.139c0.626-1.112,0.556-1.182,2.224-1.182s3.615,0.069,3.893-1.321
         s0.487-1.668,1.668-1.668c1.182,0,1.599,0.695,1.668,1.39c0.069,0.695,1.112,1.182,2.433,0.973
         c1.321-0.208,6.187,0.07,6.117-0.973s0.278-2.085-0.834-2.294s-1.599-0.417-1.668-1.251s0-3.059-1.946-2.989
         s-3.754-0.348-4.032,0.348c-0.278,0.695-1.321,2.155-2.155,1.668c-0.834-0.487-1.668-1.251-2.711-1.182
         c-1.043,0.07-1.529,0.626-2.85,0s-2.572-0.904-3.893-0.973c-1.321-0.069-4.588-0.487-4.935,0.487
         c-0.348,0.973-0.904,0.834-3.128,0.834s-4.727,0.556-5.074-0.348c-0.348-0.904-0.556-1.182-2.085-1.251
         c-1.529-0.07-2.572-1.321-3.337-0.209c-0.765,1.112-1.46,1.182-1.39,2.085s-0.139,1.321,1.877,1.251s3.754-0.417,3.823,0.139
         c0.069,0.556,0.626,1.668-2.016,1.668s-3.337,0-4.935,0.139c0,0-1.807,0-1.738,1.043
         C748.117-559.819,747.769-559.471,749.855-559.471z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M763.062-568.508
         c2.85,0,6.048-0.417,7.368-0.417s4.588-0.904,5.005,0.139s0.765,1.39,1.877,1.39s3.337,0.765,3.615-0.278s1.46-1.251,1.529-1.877
         c0.069-0.626-1.321-2.085,0.487-2.224c1.807-0.139,2.781-0.417,3.406,0.348c0.626,0.765,2.155,0.904,2.781,0.904
         s4.31,0.904,4.24-0.07c-0.07-0.973-0.07-1.251,0.834-1.321s1.251-0.556,0.139-0.834s-1.46-1.39-3.059-1.112s0,1.877-3.059,0.695
         s-2.781-1.599-4.101-1.599s-1.043,0-2.016-0.834c-0.973-0.834-3.059-1.946-3.754-1.182s-1.529,0.834-2.85,0.973
         s-1.112,0.834-2.433,1.46c-1.321,0.626-2.155,0.695-2.85,1.46s-1.182,1.669-2.781,1.669s-1.321-0.139-3.128-0.139
         c0,0-3.406-0.417-3.545,0.695C760.629-569.55,760.212-568.508,763.062-568.508z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M775.714-548.975
         c0.973,0.07,1.182,0.626,1.182,1.599s1.46,1.182,2.155,1.251s2.641,0.904,3.406-0.556s2.085-1.043,2.363-1.877
         c0.278-0.834,1.251-1.599,2.85-1.738s3.406,0.139,3.823-0.209s2.711-1.043,1.738-1.46s-1.251-0.417-2.294-0.834
         s-2.085,0.208-2.989-0.417c-0.904-0.626-3.128-1.182-5.074-1.182s-4.518,0.348-5.978,0.348s-2.711-0.417-2.92,0.765
         s0.556,1.807-1.599,1.807s-6.743-0.765-6.743,1.182c0,0,0.208,1.182-1.112,1.738c-1.321,0.556-0.695,0.695-0.695,1.529
         s1.46,1.529,2.503,1.529c1.043,0,8.62,0.487,8.342-0.834C774.393-547.654,774.74-549.044,775.714-548.975z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M783.012-579.908
         c0.07,1.599,2.433,2.363,2.781,1.251s1.877-1.043,3.267-1.043s5.144,0.348,5.352-0.278c0.208-0.626,1.321,0.209,1.321-1.043
         c0-1.251-0.07-1.807-1.39-1.807s-2.502-0.139-4.31,0c0,0-3.684,0.07-5.074,0.487c-1.39,0.417-3.406,0.556-2.989,1.112
         C782.387-580.672,782.943-581.507,783.012-579.908z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M790.728-543.344
         c1.182,0,1.668,0,1.877,0.834c0.208,0.834,1.668,0.904,3.128,0.904s1.182,0.278,1.738-0.765c0.556-1.043,2.502,0.348,2.781-1.043
         c0.279-1.391,0.487-1.321-0.626-1.39c-1.112-0.07-1.807,0.139-2.085-0.695s-1.321-0.695-2.294-0.834c0,0-2.641-0.556-3.198,0.417
         c-0.556,0.973-1.043,1.112-1.946,1.182C789.199-544.665,789.547-543.344,790.728-543.344z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M794.83-558.428
         c-0.278,0.765-1.251,0.973-0.904,1.807c0.348,0.834,0.209,2.502,1.946,2.989c1.738,0.487,3.198,1.668,3.962,1.112
         c0.765-0.556,1.668-1.043,2.92-1.043c1.251,0,0.834,1.251,2.711,1.251s4.449,0.556,4.727-0.626s2.085-0.904,3.823-1.946
         c1.738-1.043,2.642-1.877,3.267-2.363s2.641-0.973,2.781-1.668s-0.07-1.321-0.904-1.321s-0.765-1.738-1.877-1.807
         c-1.112-0.07-5.77-0.348-6.465-0.07s0.139,1.46-2.989,1.39s-7.994-0.139-9.315-0.07c0,0-1.182,0.209-1.46,0.973
         C796.776-559.054,795.108-559.193,794.83-558.428z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M802.685-563.572
         c2.503,0.348,2.433,1.182,3.893,1.112s3.337-0.209,4.171-1.112c0.834-0.904,2.781-0.904,2.92-1.668s-0.556-1.738-1.599-1.668
         c-1.043,0.07-3.893,0.348-5.909,0.348c0,0-2.711-0.07-3.198,0.695s-2.016,0.07-1.946,0.834
         C801.087-564.267,800.182-563.92,802.685-563.572z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M816.865-575.598
         c1.946,0.278,1.599,0.348,2.642-0.834s2.155-1.321,3.545-0.834c1.39,0.487,0.626,0.973,3.198,1.043s4.866,0.417,5.631-0.487
         c0.765-0.904,1.251-2.989-0.208-3.128s-1.182-0.904-3.406-0.973c-2.224-0.07-3.615-0.139-3.962,0.487
         c-0.348,0.626-1.321,0.695-3.267,0.695c0,0-2.363,0.07-2.92,0.695c-0.556,0.626,1.043,2.016-1.112,1.529s-2.641-0.139-2.572,0.348
         C814.502-576.571,814.919-575.876,816.865-575.598z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M825.833-563.433
         c-0.626,0.626-1.251,1.321-2.294,1.39c-1.043,0.07-2.502,1.668-0.904,2.294c1.599,0.626,2.989,0.556,3.476,1.39
         s3.754,0.556,5.561,0.487c1.807-0.07,3.337,0.487,4.31-0.487c0.973-0.973,2.363-1.182,3.754-1.112
         c1.391,0.07,1.946,0.556,2.572-0.209c0.626-0.765,1.807-0.139,2.572-1.321s2.294-0.973,2.294-2.572s0.208-2.92-1.39-3.823
         c-1.599-0.904-2.294,0.139-2.989-1.182s-2.433-0.556-3.615-0.556s-1.39,0.556-2.155,1.599c-0.765,1.043-1.182,0.626-1.182,1.668
         c0,1.042,0,1.807-1.877,1.668s-2.085,0.139-3.823,0.208C830.142-563.989,826.458-564.059,825.833-563.433z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M707.73-357.258
         c-1.807-0.417-2.92-1.807-4.032-1.529s-1.807,1.39-3.198,1.39s-4.31,2.781-4.171,4.588c0,0-0.417,4.449,0.834,4.866
         s1.946,1.529,3.754,1.668c1.807,0.139,3.198-0.695,4.588-0.695s1.112-0.556,2.781-1.39c1.668-0.834,1.39-3.059,3.337-3.059
         s1.946,0.139,2.224-0.834c0.278-0.973,0.695-1.112-2.085-2.085C708.982-355.311,709.537-356.84,707.73-357.258z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M789.616-365.46
         c1.39,0.834,0.834,2.085,2.92,2.085s3.198-0.417,3.754,0.973c0.556,1.39,1.807,1.668,3.198,1.668s4.032-0.139,4.171-0.973
         c0.139-0.834,1.529-2.92-0.556-3.754s-7.09-3.615-8.064-5.005c0,0-2.502-3.198-3.754-2.641c-1.251,0.556-3.337,1.251-2.92,2.085
         s0.834,0.556,0.556,1.946C788.643-367.684,788.226-366.294,789.616-365.46z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M725.803-399.104
         c-0.556,0.834,0.139,1.39-1.112,2.085c-1.251,0.695-0.973,1.112-0.973,2.085s1.39,0.834,1.39,2.224s0.973,2.92,2.641,3.198
         c1.668,0.278,2.224,0.417,2.642-0.417c0.417-0.834,1.529-0.834,2.363-0.834s2.363-0.278,2.641,0.556s0.556,2.224,1.668,2.224
         s0.973-1.668,2.363-1.668s2.224,0.278,2.224,1.112s0.556,1.529,1.668,1.529s-1.112,1.668,1.39,1.946
         c2.503,0.278,2.92,0.556,2.642,1.251c-0.278,0.695-2.503,0.417-2.503,1.112s-1.251,0.278-1.251,1.668s-1.529,1.112-3.198,1.251
         c-1.668,0.139-2.085,1.529,0.556,1.39c2.641-0.139,4.032-0.278,4.449,0.139c0.417,0.417,1.807,0.556,3.198,0.556
         s5.005,0.834,5.561,1.807c0.556,0.973,3.754,1.529,4.032,0.556c0.278-0.973,0.556-1.39,1.112-1.39
         c0.556,0,1.251-0.834,1.529,0.278c0.278,1.112,0.556,0.834,2.641,0.834s3.337,0.695,3.337-0.278s0.695-1.529,1.39-1.112
         s0.834,1.529,2.363,1.529s6.673-0.417,7.229-0.556s2.781-1.39-1.39-2.641s-8.898-5.005-10.288-7.09s-5.839-7.507-5.839-9.315
         s-1.251-4.449-0.556-4.866c0.695-0.417,1.807-1.946,1.251-2.642c-0.556-0.695-1.251-2.502-3.059-2.502
         c-1.807,0-0.556-1.112,0.834-1.251s1.946-0.278,1.668-1.529c-0.278-1.251,2.503-0.417,1.668-1.946
         c-0.834-1.529-4.449-0.278-4.032-1.807s2.781-0.278,4.449-0.278c1.668,0,3.615,0.278,3.615-0.834c0-1.112-3.198-2.085-2.085-2.92
         s4.588,0.695,4.727-0.973s2.085-4.032,3.893-4.032c1.807,0,2.92-0.695,2.92-1.529s-2.781-2.363-0.417-2.641
         c2.363-0.278,4.31,0,3.893-1.807s2.224-0.556,2.224-2.224s-1.39-3.476,0.417-3.754c1.807-0.278,5.005-0.556,5.422-2.363
         s2.224-2.085,0.973-3.476c-1.251-1.391,0.417-2.641,3.198-1.946c2.781,0.695,1.39-2.085,1.529-3.337
         c0.139-1.251,2.085-0.417,2.92,0.556c0.834,0.973,1.946,2.085,2.781,0.417c0.834-1.668,1.668-1.251,3.615-1.946
         c1.946-0.695,1.112-1.39,2.085-3.337c0.973-1.946,0.695-1.668,3.615-3.754s3.476-3.059,5.144-2.502
         c1.668,0.557,2.085,0.139,2.641-1.251s1.112-1.668,3.198-1.807c2.085-0.139,3.754-1.39,5.978-1.807s4.032,0.417,4.727-1.251
         s1.946-2.641,2.781-1.529s1.668,1.946,2.363,0.834c0.695-1.112,0.556-0.417,2.92-1.112c2.363-0.695,2.363-2.085,7.09-2.363
         c4.727-0.278,5.978-1.668,8.898-2.224s5.144-1.529,7.646-2.085s3.337-2.363,5.978-2.502c2.642-0.139,6.534-1.668,7.924-3.337
         c1.39-1.668,3.059-4.171,3.059-5.561s-0.556-3.198-2.085-3.893s-4.171-0.556-5.144-1.529c-0.973-0.973-3.893-0.695-5.7-0.695
         s-1.529,1.112-4.171,1.39s-2.224,2.224-5.005,2.363s-4.171,0.695-4.449,2.085c-0.278,1.39,1.112,2.92-0.973,2.92
         s-2.781-0.695-3.337,0.278c-0.556,0.973-1.529,1.112-3.059,1.112s-4.449,0.834-5.7,1.668c-1.251,0.834-3.754,1.529-5.7,1.529
         s-2.641-0.417-4.171,0.973s-2.92,0.973-5.144,0.973s-2.781,0-4.449-0.834c-1.668-0.834-4.727-1.251-5.839-1.251
         s-4.866,0.695-5.005,2.085s-1.668,3.337-2.92,2.085c-1.251-1.251-3.476-1.529-3.893,0.556s-0.834,1.39-2.92,1.668
         c-2.085,0.278-2.085,1.112-2.781,1.529s-1.529,0.695-2.502-0.695s-2.641-0.834-2.92,0.278s-1.668,0.278-2.085,1.668
         s-0.556,1.946-2.224,1.946s-3.337,0.278-3.615,1.251s-3.059,0.973-3.059,2.92c0,1.946-0.417,1.251-2.781,1.39
         c-2.363,0.139-4.31,0.417-4.588,1.668s0.278,2.781-1.668,2.781s-3.198-2.224-5.005-1.39s-2.641,3.476-0.278,3.615
         s3.337,0.973,2.641,2.085c-0.695,1.112-3.337,0.139-3.476,1.251s0.278,2.224-1.39,2.502s-2.92,1.112-2.363,2.085
         s0,2.224-1.112,2.642c-1.112,0.417,2.363,1.946,0.695,2.502c-1.668,0.556,0.695,1.668-2.224,1.946s-2.641-0.556-3.337,0.556
         c-0.695,1.112-0.417,2.641-2.642,3.059c-2.225,0.418-3.476,0.834-3.754,1.807s-3.198-0.139-3.337,1.112s-1.529,0.973,1.529,2.224
         c3.059,1.251,3.476,1.946,4.449,0.834c0.973-1.112,1.251-1.668,3.337-1.668s2.503-0.139,2.085,0.973
         c-0.417,1.112-3.198,0.695-4.032,1.251s-1.668,0.834-1.668,1.668s1.807,1.807,0,2.502s-3.476,0.834-5.422,0.973
         c-1.946,0.139-1.39,0.973-3.059,0.973c-1.668,0-2.363,0.556-2.363,1.39s1.251,1.251,1.39,2.224
         c0.139,0.973-0.278,1.112-1.946,1.112c-1.668,0-2.92-0.139-4.032,0.973s-2.641,3.476-0.278,3.754s3.893-0.139,3.754,0.695
         s-2.363,0.973-2.502,2.781c0,0-0.278,2.085-1.39,2.085s-1.529,0.695-1.39,1.668s-0.695,1.807-2.781,1.668
         C728.306-400.356,726.359-399.939,725.803-399.104z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M888.464-415.788
         c1.946,0.278,5.839,0.417,6.395-0.695s2.642-0.417,4.449-0.417c1.807,0,3.476-1.39,2.641-2.224
         c-0.834-0.834-1.668-1.807-2.781-2.642c-1.112-0.834-2.502-2.224-5.7-1.39c0,0-2.641-0.556-3.476,0.278
         c-0.834,0.834-2.363,0.695-2.085,1.807C888.186-419.958,886.517-416.066,888.464-415.788z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M925.167-414.536
         c1.251,0.834,4.866,2.781,4.866,1.112c0-1.668,0.695-2.781-0.973-3.476s-3.198-1.807-3.476-0.695
         C925.584-417.595,923.915-415.37,925.167-414.536z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M936.984-421.21
         c2.224-0.556,2.781-1.668,5.005-1.668s5.422,0.695,4.866-0.139c-0.556-0.834-3.893-1.946-7.646-1.529c0,0-3.059,0-3.893,1.251
         C934.481-422.044,934.759-420.653,936.984-421.21z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M967.153-410.226
         c1.807,0,5.005,1.112,5.005,0s0.556-3.059-0.695-3.476c-1.251-0.417-2.502-4.449-3.615-2.502c0,0-1.112,1.807-1.807,2.224
         s-1.668,1.251-1.112,1.946C965.484-411.339,965.345-410.226,967.153-410.226z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M969.377-441.229
         c1.946,0.417,3.059-0.278,3.059-0.278s0-1.807-2.92-1.251C969.516-442.759,967.431-441.646,969.377-441.229z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M991.76-464.308
         c1.807,0,5.283-0.695,5.283-1.668s0.973,0.139,2.224,0.139s2.502-0.556,2.641,0.278s2.224,1.112,2.503,0.695
         c0.278-0.417,0.278-1.251,1.39-1.112s2.224-1.112,0.417-1.112s-2.781,0-3.337-0.417c-0.556-0.417-3.198-0.695-3.893-0.695
         s-2.641,0.417-2.641,1.112s-0.834,0.834-2.085,0.834s-1.668-1.112-2.502-0.973s-1.251-0.139-1.39,0.556
         C990.37-466.671,989.953-464.308,991.76-464.308z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M991.76-454.02
         c0.139,1.39,1.946,2.224,2.085,1.112c0.139-1.112,0.695-1.529,2.085-1.39s0.834-0.973,0.139-1.251s0-3.893-1.39-3.615
         s-1.529-0.417-1.39,0.278c0,0,0.973,2.502-1.112,2.363s-2.781,0.139-2.641,0.834S991.621-455.41,991.76-454.02z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M997.877-434.973
         c0,0-1.946,0.556-1.39,0.973s2.92,1.529,2.781,0.417S999.129-435.112,997.877-434.973z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1004.829-434.834
         c-1.39,0.278-2.085-0.695-2.92,0c0,0-0.973,2.085,0,2.085s3.059,0.278,3.615,0.695s1.251,0.278,1.251-0.695
         S1006.219-435.112,1004.829-434.834z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1012.058-431.358
         c1.807,0,4.032,1.112,4.31,0.695s-0.556-0.973-1.807-1.39c-1.251-0.417-1.251-1.112-2.224-0.973
         C1012.336-433.027,1010.251-431.358,1012.058-431.358z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1016.507-439.005
         c1.668,1.529,1.807,1.39,2.085,0.278s2.224-1.39,0.695-1.668c-1.529-0.278-1.807-0.417-2.642-0.417
         C1016.646-440.812,1014.839-440.534,1016.507-439.005z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1023.041-444.566
         c1.807,0.556,3.198,0.973,3.337-0.139s-0.695-1.807-3.198-1.807C1023.18-446.512,1021.234-445.122,1023.041-444.566z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1022.902-439.978
         c1.529,0,5.144,0.834,5.422,0s-0.139-1.807-1.668-1.946c-1.529-0.139-0.834-0.695-2.503-0.556c0,0-2.503-0.278-2.503,0.834
         S1021.373-439.978,1022.902-439.978z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1058.215-489.193
         c0.973-0.139,2.224-0.139,2.224-0.973c0-0.834,0.417-1.946-2.224-1.668c0,0-0.834,0.834-1.112,1.529
         C1056.825-489.61,1057.241-489.054,1058.215-489.193z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1053.906-488.776
         c0,0-1.529-0.139-1.807,0.417C1051.819-487.803,1054.323-485.857,1053.906-488.776z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1119.247-485.579
         c0-0.834,2.641,0.139,2.641-1.251s0.278-3.059-0.973-3.059s0.139,0.695-2.224,0.973c-2.364,0.278-4.032-0.139-3.893,0.973
         c0,0-3.754-0.278-3.754,0.695s0.139,1.251,1.251,1.529C1113.407-485.441,1119.247-484.744,1119.247-485.579z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1119.664-478.627
         c0.417-1.112,0.973-1.668,1.807-1.668s0.695-0.278-0.834-0.556c-1.529-0.278-0.417-0.278-1.529-0.278c0,0-2.641,0.139-2.224,0.973
         S1119.247-477.515,1119.664-478.627z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1107.986-480.852
         c-2.502,0-2.781,1.39-0.695,1.668c2.085,0.278,6.534,1.251,6.534,0.139s-0.695-1.807-1.529-1.807
         c-0.834,0-1.529-2.224-2.502-0.834C1109.793-481.686,1110.489-480.852,1107.986-480.852z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1099.088-479.601
         c0,0-1.112,1.946,0.834,1.946S1100.34-480.435,1099.088-479.601z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1129.953-480.435
         c0,0-0.834,2.781,0.139,2.92s1.112-0.278,1.112-1.112C1131.203-479.461,1130.508-481.825,1129.953-480.435z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1088.522-531.596
         c0-0.695-1.668-2.224-4.171-2.085c0,0-1.39,0-2.502,0.834c-1.112,0.834-3.893,0.695-1.39,1.668c2.502,0.973,1.807,0,3.337-0.278
         c1.529-0.278,1.807-0.834,2.781-0.139C1087.549-530.901,1088.522-530.901,1088.522-531.596z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1074.342-535.072
         c0,0-5.144-0.834-2.92,0.278s3.615,0.556,4.171,1.668s1.807,0.556,1.668,0S1076.288-534.794,1074.342-535.072z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1085.186-527.286
         c-0.139-1.39-1.529-1.251-1.807-0.417c0,0-1.946,2.085,0.278,2.085s3.893,0.834,3.476-0.695S1085.325-525.896,1085.186-527.286z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1066.278-566.492
         c2.92,0.139,10.427,1.251,10.983,0s-0.139-2.503-1.39-2.503s-3.476,0.278-5.144-0.834c0,0-2.642-1.39-3.615-0.139
         c-0.973,1.251-3.615,0.278-3.754,1.39C1063.22-567.465,1063.359-566.631,1066.278-566.492z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1076.844-551.06
         c0.278,1.251,0.556,0.834,2.502,0.834c1.946,0,3.754,0,4.171,0.973s1.251,1.807,2.502,1.807s3.754,0.556,3.754,1.946
         c0,1.39-0.695,0.556-2.641,0.556s-2.085-0.139-3.893,0c0,0-1.946,1.112-2.502,0c-0.556-1.112-0.695-1.529-2.224-1.39
         s-3.059-0.139-2.92,1.112s-1.529,0.556-1.807-0.417s-0.556-0.695-2.085-0.695s-2.085,1.39-0.556,1.529
         c1.529,0.139,2.503,0.556,2.92,1.946s-0.417,2.641,2.085,2.781c2.502,0.139,4.727,0.278,5.144,1.251s1.39,0.973,3.337,1.39
         c1.946,0.417,4.866,1.112,5.561-0.417c0.695-1.529,1.668-1.807,2.641-1.807s3.198-0.556,3.615-1.251
         c0.417-0.695,0-1.251-0.556-1.807s-0.695-1.807,1.39-1.668s2.92,0.417,4.171-0.556s2.224-1.529,4.31-1.529
         c2.085,0,3.059-0.139,7.368-1.39c4.31-1.251,7.09-1.251,9.593-1.251s6.673-0.278,6.395-1.668s-2.224-0.834-2.363-2.363
         s-1.251-1.529-0.973-2.781s0.139-2.085,1.807-1.807s2.642-0.556,4.31-0.417c1.668,0.139,1.946,0.556,1.946-1.112
         s-0.139-1.39-3.198-1.946c-3.059-0.556-3.337-1.668-5.283-1.529c-1.946,0.139-1.668-0.973-3.754-2.503
         c-2.085-1.529-4.449-5.283-6.673-5.422s-4.171,0.139-5.978,0.417s-0.695,3.059-3.754,3.059s-4.449,0.556-6.673,0.973
         s-2.502,1.251-4.588,0.973c-2.085-0.278-1.807,1.112-4.032,2.224s-3.059,3.059-1.529,3.337c1.529,0.278,4.31-0.973,2.92,0.556
         c-1.39,1.529-2.92,1.807-3.337,2.92c-0.417,1.112-1.112,1.39-2.363,1.39s-0.973,1.251-3.198,1.251
         c-2.224,0-2.085,0.278-2.502,0.973C1079.487-551.893,1076.566-552.311,1076.844-551.06z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1130.786-540.772
         c0.973-2.085,1.668-3.337,2.502-3.337s1.39-2.085-0.278-2.92c-1.668-0.834-4.866-1.251-5.978-1.112s-1.946,0.973-4.171,1.112
         c-2.224,0.139-4.032-0.278-6.256,0.139s-3.615,0.556-4.588,1.529c-0.973,0.973-1.112,1.251-2.641,0.834
         c-1.529-0.417-2.085-0.973-3.476,0.139c0,0-2.364,0.556-2.92,1.668s2.781,2.781,0.278,2.781s-4.032,0.556-5.144,1.251
         s-2.085,2.781-4.171,2.92c-2.085,0.139-6.951-0.834-5.978,0.417s2.364,0.278,2.92,1.251s2.224,1.668,2.781,1.112
         c0.556-0.556,1.668-1.39,2.364-0.417c0.695,0.973,0.695,0.973,2.781,0.973c2.085,0,2.92-0.695,3.198,1.251
         s-0.556,3.198,1.39,3.198s0.278,2.363,2.224,2.502s3.615,1.668,4.31,0.834c0.695-0.834,0.278-1.807,1.946-0.973
         c1.668,0.834,0,1.807,1.529,1.807s4.449-1.112,6.256-0.695s5.7,1.251,6.395,2.363c0.695,1.112,3.615,1.668,6.256,1.668
         s10.705,0.417,11.4-0.139c0.695-0.556,2.502-0.834,3.476-1.112c0.973-0.278,4.032-0.973,4.032-2.503
         c0-1.53,0.417-1.251-1.529-2.085c-1.946-0.834-3.476-1.39-4.31-2.502c-0.834-1.112-0.834-0.834,0.973-0.834
         s3.198-1.39,3.198-3.059c0-1.668,0-3.476,1.946-3.476s2.363-0.139,2.502-0.695s-1.946-0.417-1.946-2.224s-0.556-2.781-1.807-3.476
         s-3.476-1.807-4.588-2.502s-2.085-0.417-3.754-0.417c-1.668,0-1.807,0.556-2.641,0.973s-1.529,0.973-1.529,1.807
         s-1.39,0.556-2.92,0.556s-1.251,0.139-2.224,1.668s-1.112,1.946-3.615,1.807C1126.476-538.826,1129.813-538.687,1130.786-540.772z
         " pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1152.892-514.218
         c-1.112,0.556-0.695,2.503-0.695,2.503s0.139,0.695-1.39,1.39s-3.059,2.085-3.059,3.476s0.139,2.642,2.502,2.781
         c2.364,0.139,3.337-1.251,5.7-1.529c2.364-0.278,3.893,0,4.866-1.529s2.363-1.946,5.978-1.807s8.62,0.695,10.427,0.139
         s2.085-1.251,5.144-1.251s4.31,0.556,5.422-0.556s3.198-0.695,5.144-0.834s3.615,0.278,4.171-0.973
         c0.556-1.251,0.834-1.112,1.807-1.39s2.224-0.556,2.085-2.363c-0.139-1.807,0.417-2.781-0.973-3.337s0.139-2.363-2.363-2.363
         s-3.615,1.39-4.866-0.417s-0.973-2.781-2.781-3.059c-1.807-0.278-1.946-2.224-3.754-2.503c-1.807-0.278-1.529,0.695-2.92,0.834
         s-1.807,0-3.059,1.112c-1.251,1.112-2.781,0.834-3.337,1.946s-1.807,2.502-2.085,1.112s1.807-1.946,1.946-3.059
         c0.139-1.112,1.112-1.946,1.251-3.059c0.139-1.112,2.085-0.834,1.112-1.946c-0.973-1.112-1.529-1.251-2.781-1.251
         s-1.251-1.807-3.059-1.668s-1.668,1.251-1.668,2.224s0.278,2.085-1.251,1.668c-1.529-0.417-1.807-1.807-3.337-1.807
         c-1.529,0-0.556,1.251-2.224,1.946s-2.085,1.529-2.224,2.363s-1.946,0.973,0.278,1.39s2.919,0.556,2.502,1.529
         c-0.417,0.973-1.112,0.139-2.781-0.139c-1.668-0.278-2.364,0.278-2.641,0.834c-0.278,0.556-1.529,2.363,0.139,2.641
         c1.668,0.278,2.642,0.556,2.085,1.39s-1.668,0.695-3.059,0s-3.337-1.946-4.588,2.92
         C1154.56-516.859,1154.005-514.774,1152.892-514.218z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1205.165-508.518
         c1.39,0,2.502,0.556,3.198-0.417c0.695-0.973,1.946,0,1.946-1.251s-0.973-1.251-2.363-1.251c0,0-1.39,0.139-2.641,0.834
         C1204.053-509.908,1203.776-508.518,1205.165-508.518z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1209.336-507.267
         c1.251,0.556,1.39-0.556,1.946,0.556s0.834,0.695,2.781,0.695c1.946,0,2.781-0.278,3.059,0.417s1.39,0.834,1.529,0
         s1.251,0,1.251-1.251s-0.417-1.529-1.112-1.529c-0.695,0-0.278-1.529-1.529-1.39c-1.251,0.139,0.417,1.529-1.529,1.39
         s-1.251-1.251-3.476-0.695c0,0,0.139,0.695-0.834,0.695C1210.448-508.379,1208.085-507.823,1209.336-507.267z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1210.726-495.31
         c0,0-2.085,1.112-1.251,1.668s2.224,1.112,3.058,0.139C1213.368-494.476,1212.396-495.728,1210.726-495.31z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1217.121-493.225
         c0,0-1.112,0.695-1.112,1.807s2.363,2.224,2.502,0.834C1218.651-491.974,1218.373-494.198,1217.121-493.225z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1269.488-440.627
         c-1.112,0-1.391-0.093-2.688,0.278c0,0-1.854,0.371-2.41-0.278c-0.556-0.649-1.39-0.927-2.781-1.02s-1.947,0.556-2.317,1.112
         s-1.02,1.854-2.781,1.854s-3.614,0.556-2.873,1.019c0.741,0.464,1.575,1.298,2.966,1.298s2.039,0.649,2.781,1.205
         c0.741,0.556,1.483,1.205,2.688,1.205s3.707-0.186,4.171-0.927s3.152-0.648,3.244-1.297s0.463-1.39,1.205-1.576
         c0.742-0.185,0.741-0.742,0.556-1.576S1270.6-440.627,1269.488-440.627z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1292.752-437.198
         c0.741,0.371,0.835,0.371,1.761-0.093c0.927-0.464,1.205-0.742,0.741-1.02c-0.464-0.278-1.39-1.391-1.946-0.742
         C1293.308-439.051,1292.01-437.568,1292.752-437.198z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1470.427-457.495
         c0.139-1.668,2.503-1.946,1.39-3.337c-1.112-1.39-1.39-2.502-2.364-3.337s-2.224-0.834-1.946,0.556c0,0,0.973,4.032-0.556,4.171
         s-1.112,1.529-0.556,2.224C1466.951-456.522,1470.288-455.827,1470.427-457.495z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1466.396-434.556
         c1.946,1.251,2.364,1.668,3.337,3.337s3.615,1.39,3.615,0.417s-0.695-1.39-2.085-2.642c-1.39-1.251-4.449-4.866-5.422-4.032
         C1465.839-437.476,1464.449-435.807,1466.396-434.556z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1512.552-430.941
         c0.695-0.834,2.92-0.834,2.92-1.807s1.529-0.695,1.39-2.224s-0.695-1.251-2.085-2.085s-3.337-0.973-4.31,0
         c0,0-1.112,1.39-2.085,1.39c-0.973,0-1.112,0.834-1.112,1.529c0,0.695-0.278,1.529,1.251,2.224
         C1510.05-431.219,1511.857-430.107,1512.552-430.941z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1504.35-422.461
         c0.834,1.39,2.224,2.642,3.058,1.807c0.834-0.834,0.973-1.668,3.476-1.668s4.171-0.695,5.005,0.278s3.059,0.417,3.337,1.112
         s3.615,1.251,5.978,1.529c2.363,0.278,9.176,0.278,10.149,0.278s2.224-0.695,2.224-2.363s0-2.781-1.39-2.92
         s-0.139-1.668-3.337-2.642c-3.198-0.973-3.893-2.363-5.283-2.781s-1.251-1.807-2.92-1.807s-3.615-0.417-3.615,0.417
         s-0.973,1.251-2.364,1.112c0,0-2.92-0.417-3.754,1.112s-2.641,3.476-3.476,4.31c-0.834,0.834-0.834,1.112-3.059,1.112
         C1506.157-423.573,1503.515-423.851,1504.35-422.461z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1480.021-453.325
         c1.39,0.695,3.476,1.668,3.476,2.502s0.139,1.251,1.807,1.251s1.668,0.695,2.502,1.807s0.556,2.224,2.92,2.224
         s3.476-0.834,4.727,0.695c1.251,1.529,3.059,2.363,4.171,1.668s1.946-0.834,2.224-1.807s-0.139-1.946,0.556-2.642
         c0.695-0.695-0.417-2.085,1.251-1.946s2.641,1.112,2.641,1.946s0.834,0.834,1.946,0.973s2.224,0.973,2.92-0.139
         c0.695-1.112,0.556-1.39,2.92-1.251c2.363,0.139,3.198-0.834,5.005-0.973s3.615-1.112,4.866-0.139s1.529,2.641,3.198,2.781
         c1.668,0.139,2.364,0.417,2.364-0.695s2.641-0.973,4.032-0.834s4.31,1.112,4.588-0.417s1.807-1.668,3.337-1.668
         s4.032,0.139,5.7-1.251s0-3.059,1.668-3.059s1.946-1.529,0.834-1.39s-1.112-0.556-1.112-1.529s-0.139-0.695,1.39-0.695
         s0.556-1.112,1.807-1.251s2.363-1.668,0.556-1.807s-3.337-0.556-5.7-1.529c-2.364-0.973-3.337-1.946-4.588-1.807
         s-1.529,0.139-2.363-1.39s-1.39-0.278-2.503-0.139c-1.112,0.139-0.278,1.668-2.363,1.39c-2.085-0.278-2.502,0.278-1.946,0.973
         c0.556,0.695,1.529,3.337-1.251,3.476c-2.781,0.139-2.502,1.946-2.502,2.502s0,1.668,1.251,2.224s3.059,1.946,2.781,3.198
         c-0.278,1.251-1.112,1.529-2.781,1.39c-1.668-0.139-0.556-2.085-2.502-3.337c-1.946-1.251-2.502-4.449-3.198-4.866
         c-0.695-0.417-1.112-1.668,0.834-2.085s1.251-1.807,3.058-1.807s2.781,0.834,3.059-0.695c0.278-1.529-1.946-1.807-3.337-2.502
         s-3.893-1.529-4.588-2.642c-0.695-1.112-2.085-1.529-3.615-1.529c-1.529,0-1.251,0.834-2.641,0.973s-2.781,0.834-2.781,2.502
         s-0.556,3.198,0,3.754s1.529,1.112,0,1.946s-2.224,0.556-2.641-0.278s-0.973-0.417-1.251-1.807s0-1.39-1.529-1.39
         s-3.476,0.278-3.476-1.251s0.139-2.085-2.503-2.502c-2.642-0.417-4.866-0.139-5.144-1.668s-0.278-2.363-1.807-2.642
         c-1.529-0.278-2.224,0.278-2.781,0.695c-0.556,0.417-0.139,1.668-1.39,1.529s-1.946-0.695-2.363,0.139s-1.251,2.363-3.337,2.224
         c0,0-2.502-0.695-2.502,0.973s-0.278,2.224-1.668,2.363s-2.363,0.695-2.363,2.085s0.139,1.946,0.973,2.363
         s0.973,1.251,0.695,2.085c-0.278,0.834,0.139,0.695-1.668,1.251C1478.074-456.244,1478.63-454.02,1480.021-453.325z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1560.516-453.325
         c1.112,0,1.39,1.112,2.781,1.112s3.059-0.139,3.198,1.112s0.695,1.39,2.641,1.39s1.529,0.139,3.198,0.556
         c1.668,0.417,3.615,0.973,4.727,1.668s2.641,1.668,5.144,2.085c2.502,0.417,8.759,0.556,10.01-0.278s2.363-1.112,3.754-1.112
         s1.807-1.112,3.059-1.112s1.946-0.417,2.224-1.251s1.946-0.695,2.085-2.085c0.139-1.39-0.417-1.946-1.112-1.946
         c-0.695,0-2.085,1.251-3.058,0.556s-2.364-1.529-4.032-1.668s-3.198,0.278-4.171-0.417s-1.251-0.973-2.641-0.973
         s-2.085-0.139-2.641,0.417s-0.278,0.973-2.224,0.695c-1.946-0.278-1.946-0.417-2.085-1.807s-1.668-1.946-3.754-1.946
         c-2.085,0-5.7,0.139-6.256,0.973c-0.556,0.834-2.92,1.251-4.171,0.834s-1.251-2.502-1.807-3.337c-0.556-0.834-2.641-0.695-2.641,0
         s-1.112,0.973-0.695,1.807c0,0-0.139,2.502-1.251,2.502C1559.682-455.549,1559.404-453.325,1560.516-453.325z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1582.899-479.601
         c1.112,0,4.31,0.556,5.005-0.278s1.39-1.807,0-2.085s-0.417-0.278-2.781-0.139c0,0-1.668-0.417-2.781,0.556
         c-1.112,0.973-1.529,0-1.529,1.251C1580.814-479.044,1581.787-479.601,1582.899-479.601z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M446.152-151.429
         c0.695,1.877,1.738,2.016,3.198,2.155s3.128-1.112,4.866-0.139s2.781,1.043,3.823,0.765c1.043-0.278,3.198-0.834,5.353-0.904
         c1.656-0.053,4.498,0.136,6.484-0.529c-0.022-0.01-0.045-0.017-0.066-0.027c-1.158-0.556-0.139-2.271,0.37-3.8
         s-0.185-2.178-0.926-3.105s-2.966-1.159-3.939-1.159s-1.946-0.232-3.151-0.046c-1.205,0.185-2.595-1.02-3.012-1.807
         c-0.417-0.788-1.668-0.695-2.085-0.324s-1.019-0.185-1.761-0.742c-0.384-0.288-1.225-0.24-1.949-0.128
         c-0.577,2.373,4.541,0.504,2.737,3.696c-1.144,2.023-4.165-0.047-8.151-0.445c-3.349-0.335-3.045,2.562-3.805,4.68
         C444.873-153.002,445.772-152.456,446.152-151.429z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1125.225-474.457
         c0,0-1.112-0.278-1.668,0.973c-0.556,1.251-1.39,2.085,0.556,2.502s2.085-0.139,2.085-1.251S1126.894-474.596,1125.225-474.457z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M943.24-418.29
         c0.139,0.973,2.641,2.085,2.641,1.112s1.39-1.529,0-1.529C945.882-418.707,943.101-419.263,943.24-418.29z" pointerEvents="none" className="landarea"></path>
      <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M740.262-385.154
         c-0.656-0.655-1.179,0-2.403-0.699c0,0-2.489-1.835-3.888-1.747c-1.398,0.087-1.616,1.66-0.175,2.752
         c1.441,1.092,5.111,3.232,5.461,2.359C739.606-383.363,740.917-384.499,740.262-385.154z" pointerEvents="none" className="landarea"></path>
      <g data-colors="0">
         <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1740.354-159.022
            c0,0,0.298-2.826-1.785-2.826s-3.422,0.595-2.232,1.488s1.19,3.422,2.827,3.719c1.636,0.298,2.381,1.339,3.273,2.529
            s1.636-0.298,0.892-1.339C1742.584-156.493,1740.501-158.129,1740.354-159.022z" pointerEvents="none" className="landarea"></path>
         <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M1752.849-154.856
            c0,0-2.529-1.636-2.827-1.041s0.298,1.339,1.637,2.231c1.339,0.893,2.231,0.893,2.975,1.934s2.381,0.595,1.042-0.744
            S1754.486-153.22,1752.849-154.856z" pointerEvents="none" className="landarea"></path>
      </g>
      <g data-colors="0">
         <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M678.71,469.51
            c0,0-1.024-0.256-1.075,0.461c-0.052,0.717-0.973,0.461-1.127,1.28s0.052,1.434,0.973,1.639c0.922,0.205,1.843,0.256,2.099-0.358
            C679.837,471.916,680.298,469.663,678.71,469.51z" pointerEvents="none" className="landarea"></path>
         <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M761.748,576.96
            c0,0-1.602-1.201-2.335-0.934c-0.734,0.267-1.067,1.268-1.801,1.468s-1.401,1.268,0.534,2.468c1.934,1.201,3.336,1.6,3.669,0.466
            C762.148,579.295,762.548,577.895,761.748,576.96z" pointerEvents="none" className="landarea"></path>
      </g>
      <g data-colors="0">
         <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M217.368,527.616
            c0,0-1.626,0.085-1.626,0.942c0,0.856,0.514,2.055,1.883,2.226c1.37,0.171,1.027-0.942,1.027-1.798
            C218.652,528.13,218.053,527.444,217.368,527.616z" pointerEvents="none" className="landarea"></path>
         <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M156.633,732.544
            c0,0-1.707-0.396-1.676,0.518c0.03,0.915,0.061,1.371,0.427,1.585c0.365,0.213,0.793,0.396,1.158,1.006
            c0.366,0.609,0.914,0.335,1.036-0.244C157.7,734.83,158.157,732.758,156.633,732.544z" pointerEvents="none" className="landarea"></path>
         <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M177.907,763.378
            c0,0-0.744,0.348-0.767,1.023c-0.023,0.674-0.116,1.139,0.488,1.465s1.743,0.093,1.767-0.232
            C179.418,765.307,179.674,762.634,177.907,763.378z" pointerEvents="none" className="landarea"></path>
         <path fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeMiterlimit="10" d="M140.133,454.887
            c0,0-1.02,0.092-0.927,1.483c0.093,1.39,0.371,1.761,1.205,1.761s0.834-0.557,0.834-1.483
            C141.245,455.721,140.874,454.238,140.133,454.887z" pointerEvents="none" className="landarea"></path>
      </g>
   </g>

      <path id="LEBANON" fill="#166c38" stroke="#95bec8" strokeWidth="0.5px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M582.926,83.704c0.017-0.009,0.017-0.009,0.034-0.017c0.044-0.017,0.086-0.034,0.12-0.061c0.009-0.009,0.017-0.009,0.017-0.017
      c0.044-0.026,0.078-0.061,0.103-0.095l0,0c0.034-0.044,0.069-0.086,0.095-0.147c0.044-0.078,0.069-0.155,0.103-0.241
      c0.009-0.017,0.009-0.034,0.017-0.052c0.061-0.189,0.103-0.406,0.155-0.613c0.017-0.086,0.043-0.181,0.069-0.267
      c0-0.009,0.008-0.009,0.008-0.017c0.026-0.078,0.052-0.155,0.086-0.224c0.008-0.017,0.017-0.044,0.034-0.061
      c0.044-0.078,0.078-0.147,0.13-0.198c0,0,0,0,0.009-0.009c0.052-0.052,0.112-0.095,0.181-0.13c0.017-0.009,0.034-0.017,0.052-0.026
      c0.078-0.034,0.155-0.052,0.258-0.052c1.183,0,1.743-0.768,2.088-1.537c0.346-0.768,0.276-2.375,0.492-2.927
      c0.207-0.562,1.182-0.768,2.512-0.976c1.329-0.207,0.492-1.537,2.02-3.005c1.536-1.468,0.415-3.005-0.069-3.627
      c-0.492-0.631-1.674-0.976-2.58-1.952c-0.604-0.648-2.028-1.019-2.927-1.192c-0.25,0.562-0.604,1.235-0.915,2.331
      c-0.803,2.797-2.201,3.799-3.203,6.795c-1.002,3.005,0.398,4.205-2.201,5.803c-0.751,0.458-1.225,1.01-1.536,1.632
      c1.882,0.725,3.806,0.881,4.635,0.881C582.806,83.73,582.866,83.721,582.926,83.704z" pointerEvents="none" className="landarea"></path>
</g>
<g id="AREA_MADAGASCAR" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Madagascar" dataWikipediaLink="https://en.wikipedia.org/wiki/Madagascar" data-infotext="<b>Madagascar</b> &amp;nbsp; Capital: Antananarivo" data-imageFile="MADAGAS.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="MADAGASCAR" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M712.457,517.495c0.139-2.642-0.556-5.144-0.973-8.203c-0.417-3.059-0.834-4.449-2.085-6.534c-1.251-2.085-2.502-2.502-2.502-3.754
      c0-1.251-1.668-1.807-1.668-2.781s-2.224-1.946-2.224-0.973s0.139,2.641-0.834,2.92c-0.973,0.278-3.337-0.417-2.363,0.834
      c0.973,1.251,0.973,1.946,0.973,3.615c0,1.668-0.278,1.668-0.973,3.337c-0.695,1.668-1.39,1.807-2.503,1.112
      c-1.112-0.695-2.224-0.139-1.39,0.834s1.39,2.224-0.139,2.224s0-1.39-2.085-1.251s-3.198,1.529-2.085,2.502
      c1.112,0.973,1.946,1.529,1.529,2.502s-2.781,0.278-2.781,1.807s4.31,5.144,2.224,4.31s-1.807-1.251-2.92-0.973
      c-1.112,0.278-2.085-0.417-2.502,1.39s-0.139,2.085-1.807,2.502s-2.92,0.834-3.615,2.085s-3.059,0.139-3.754,1.946
      s0.139,2.919-2.919,2.919c-3.059,0-3.476,0.417-4.171,1.39s-2.92-1.39-4.171,0.139c-1.251,1.529-0.973,1.529-2.641,1.529
      s-3.198-1.668-3.337,0.834s1.112,4.171-0.695,5.978s-3.198,1.112-3.198,4.588c0,0-0.417,5.283,1.112,6.534
      s-0.278,3.893,0.973,4.727c1.251,0.834,0,3.754,1.39,4.727c1.39,0.973,1.668,2.363,1.529,4.588c-0.139,2.225-0.278,4.032-1.529,5.7
      s-1.668,2.363-2.224,4.171c-0.556,1.807-1.807,1.668-2.642,3.337c-0.834,1.668-2.502,2.224-2.502,3.893
      c0,1.668-0.973,3.059-1.529,4.588c-0.556,1.529,0,3.615,0.278,5.839s0.556,2.781,1.807,4.171s3.198,2.224,1.807,3.893
      c-1.39,1.668-1.39,0.556-1.39,2.641c0,2.085-2.085,2.92,0.556,5.005c2.641,2.085,2.363,2.781,2.92,4.449
      c0.556,1.668,1.251,3.059,2.919,3.754s3.337,1.112,5.839,2.781c2.503,1.668,5.7,2.642,6.395,0.834
      c0.695-1.807,3.059-1.946,4.588-2.781s3.337-1.112,4.588-1.112s2.92-0.139,3.893-2.642c0.973-2.502,0.973-4.171,2.363-6.812
      c1.39-2.641,2.224-5.422,2.92-7.646s4.31-11.539,4.588-14.459s0.556-7.368,1.946-10.288s3.893-7.785,4.449-10.288
      c0.556-2.502,3.476-7.785,3.615-12.79s0.695-8.203,2.085-10.288s2.92-4.171,2.224-5.839s-1.946-2.502-1.946-4.727
      s1.668-5.422,2.503-3.059c0.835,2.363,0.695,2.92,2.085,2.92s2.224,0.278,1.807-1.251s-0.834-1.946,0.834-2.224
      c1.668-0.278,0.139-2.641-1.251-4.449C712.457,520.414,712.318,520.136,712.457,517.495z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_90_" x="652.233" y="549.509" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_ERITREA" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Eritrea" dataWikipediaLink="https://en.wikipedia.org/wiki/Eritrea" data-infotext="<b>Eritrea</b> &amp;nbsp; Capital: Asmara" data-imageFile="ERITREA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="ERITREA" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M591.921,260.296c0.695-1.112,2.224-0.695,3.754-0.139c1.529,0.556,0.556-2.363,2.781-1.529s0.139,1.112,1.112,1.807
      s0,2.224,1.39,2.085s0.278-0.695,0.695-2.224s1.39-0.973,1.112-2.92c-0.278-1.946,1.251-3.198,2.502-1.39
      c1.251,1.808,1.668,2.502,3.615,2.781c1.946,0.278,2.641,0.278,3.337-0.834s2.363-0.556,2.92,0.417s1.39,0.417,3.198,0.417
      c1.807,0,6.534-0.695,7.368,1.112s1.807,1.321,2.363,1.321s2.224,0.695,2.711,1.738s2.085,3.476,2.85,4.101
      s3.128,1.946,3.962,2.989s2.363,2.781,3.128,3.754s2.294,3.476,4.032,3.754c1.738,0.278,2.433,0.07,2.433-1.043
      s1.251-0.556,1.529-1.182c0.139-0.313,0.539-0.643,0.912-0.973c-0.445-0.384-0.926-0.767-1.469-1.184
      c-1.943-1.495-5.978-7.473-8.967-9.864c-2.989-2.391-6.426-5.978-8.967-7.622c-2.54-1.644-7.921-2.391-9.266-4.932
      c-1.345-2.54-4.633-3.886-5.231-8.071c-0.598-4.184-1.196-9.117-2.391-11.806c-0.895-2.014-2.209-3.943-3.502-5.476
      c-0.51,0.515-0.95,1.059-0.95,1.408c0,0.834-0.556,2.502-2.363,3.059c-1.807,0.556-3.198,1.807-4.449,1.807
      s-1.946,0.417-1.946,1.668c0,1.251-0.556,1.39-2.085,1.668s-2.363,0.278-2.502,1.807c-0.139,1.529-0.417,3.198,0,4.31
      s-0.695,2.502-1.39,3.615c-0.695,1.112-1.39,2.085-1.39,3.198c0,1.112-0.556,2.363-1.39,3.059s-1.668,1.251-0.834,2.781
      c0.834,1.529,0.278,4.171,0.278,6.117c0,0.452,0.015,0.974,0.024,1.525C591.162,261.195,591.576,260.849,591.921,260.296z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_88_" x="576.408" y="238.408" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_EGYPT" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Egypt" dataWikipediaLink="https://en.wikipedia.org/wiki/Egypt" data-infotext="<b>Egypt</b> &amp;nbsp; Capital: Cairo" data-imageFile="EGYPT.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="EGYPT" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M577.074,120.877c-0.074,0.199-0.155,0.409-0.248,0.637c-0.137-0.021-0.313,0.004-0.554,0.094
      c-1.595,0.598,0.199,3.189-1.595,4.384c-1.793,1.196-0.796,1.993-0.796,4.185s-1.592,2.59-1.793,3.785s-1.593,1.197-3.587-1.394
      s-3.988-2.989-4.983-5.38s-1.397-4.784-3.587-6.975c-2.19-2.192-2.19-3.189-2.59-5.38c-0.399-2.192-1.195-0.997-1.195-4.185
      c0-3.188-0.799-3.587-0.997-5.978c-0.199-2.391-1.196-4.384,0.597-3.587c1.794,0.798,2.793,1.994,3.788,0.399
      s4.584-1.197,4.782-0.399c0.199,0.798,4.783,1.793,6.177-0.996c1.222-2.445,2.293-1.983,3.478-4.246
      c0.159,1.043,0.174,2.407-0.539,3.324c-1.298,1.668-2.039,3.151-0.556,5.376c1.483,2.224,0.37,4.634,1.854,6.117
      c0.091,0.092,0.181,0.191,0.268,0.297c0.026,0.031,0.05,0.067,0.076,0.099c0.06,0.077,0.121,0.155,0.179,0.238
      c0.029,0.041,0.056,0.086,0.085,0.129c0.053,0.08,0.106,0.159,0.157,0.244c0.029,0.048,0.056,0.098,0.084,0.147
      c0.048,0.083,0.096,0.166,0.142,0.252c0.029,0.055,0.057,0.113,0.086,0.169c0.042,0.084,0.085,0.167,0.125,0.252
      c0.03,0.062,0.058,0.127,0.086,0.19c0.037,0.082,0.074,0.163,0.11,0.247c0.03,0.07,0.058,0.141,0.086,0.212
      c0.031,0.078,0.063,0.155,0.093,0.233s0.058,0.157,0.086,0.235c0.026,0.07,0.052,0.141,0.076,0.211
      c0.03,0.088,0.059,0.175,0.087,0.263c0.019,0.06,0.039,0.12,0.058,0.179c0.03,0.097,0.058,0.193,0.086,0.289
      c0.014,0.048,0.029,0.097,0.042,0.145c0.029,0.105,0.056,0.208,0.083,0.31c0.009,0.036,0.019,0.073,0.028,0.109
      c0.028,0.111,0.053,0.22,0.077,0.327c0.005,0.024,0.011,0.049,0.016,0.073c0.024,0.114,0.047,0.224,0.066,0.331
      c0.003,0.016,0.006,0.032,0.009,0.048c0.02,0.112,0.038,0.219,0.053,0.323c0.001,0.009,0.003,0.018,0.004,0.027
      c0.015,0.106,0.028,0.207,0.037,0.301c0.001,0.006,0.001,0.011,0.002,0.017c0.009,0.095,0.015,0.184,0.019,0.266
      c0.002,0.046,0.005,0.097,0.005,0.138c0,0.069,0.005,0.131,0.013,0.19c0.002,0.016,0.005,0.032,0.008,0.047
      c0.007,0.044,0.016,0.085,0.027,0.124c0.003,0.011,0.007,0.023,0.01,0.034c0.168,0.551,0.637,0.718,0.305,2.151
      c-0.001,0.004-0.002,0.007-0.003,0.01c-0.027,0.117-0.06,0.243-0.098,0.377c-0.006,0.022-0.014,0.045-0.02,0.067
      c-0.035,0.121-0.074,0.247-0.119,0.382c-0.023,0.07-0.05,0.146-0.076,0.22C577.148,120.667,577.115,120.766,577.074,120.877z
       M490.015,97.497c-0.556,0.278-0.278,1.39-1.251,1.668s-1.946,1.529-0.695,2.502s0.417,2.224,1.251,2.781
      c0.834,0.556,1.529,1.807,0.556,2.224s-1.112,2.085-1.112,2.641s-2.224,1.39-1.529,2.781c0.695,1.39,0.139,2.92,1.112,3.337
      s0.556,2.641,0.973,3.893c0.004,0.012,0.008,0.036,0.012,0.064c0.001,0.008,0.002,0.018,0.003,0.027
      c0.003,0.026,0.007,0.057,0.009,0.093c0.001,0.011,0.002,0.02,0.003,0.032c0.004,0.048,0.007,0.105,0.011,0.168
      c0,0.017,0.002,0.036,0.003,0.053c0.003,0.053,0.005,0.11,0.008,0.171c0.001,0.024,0.002,0.048,0.003,0.074
      c0.003,0.082,0.007,0.169,0.01,0.265c0.001,0.019,0.001,0.04,0.002,0.06c0.003,0.081,0.005,0.167,0.008,0.257
      c0.001,0.042,0.002,0.085,0.003,0.128c0.002,0.087,0.004,0.179,0.007,0.274c0.001,0.031,0.002,0.061,0.002,0.093
      c0.003,0.124,0.005,0.254,0.008,0.389c0.001,0.061,0.002,0.125,0.003,0.189c0.002,0.089,0.003,0.18,0.005,0.273
      c0.001,0.062,0.002,0.124,0.003,0.188c0.003,0.141,0.005,0.286,0.007,0.437c0.001,0.08,0.003,0.165,0.004,0.248
      c0.001,0.099,0.002,0.199,0.003,0.302c0.002,0.092,0.003,0.184,0.004,0.279c0.001,0.12,0.003,0.244,0.004,0.369
      c0.001,0.064,0.002,0.125,0.002,0.191c0.002,0.188,0.004,0.383,0.006,0.581c0.001,0.1,0.002,0.203,0.003,0.306
      c0.001,0.098,0.002,0.197,0.002,0.298c0.001,0.122,0.002,0.244,0.003,0.37c0.001,0.135,0.002,0.274,0.003,0.412
      c0.001,0.199,0.003,0.401,0.004,0.608c0,0.078,0.001,0.157,0.001,0.236c0.004,0.56,0.007,1.146,0.01,1.757
      c0,0.023,0,0.046,0.001,0.069c0,0.181,0.002,0.364,0.002,0.549c0,0.094,0.001,0.189,0.001,0.284
      c0.003,0.603,0.005,1.227,0.008,1.871c0,0.041,0,0.083,0,0.124c0.001,0.2,0.002,0.402,0.002,0.606
      c0.001,0.161,0.001,0.325,0.002,0.488c0.001,0.297,0.002,0.599,0.002,0.903c0,0.086,0.001,0.172,0.001,0.259
      c0.001,0.244,0.001,0.49,0.002,0.738c0,0.003,0,0.006,0,0.009c0.002,0.761,0.003,1.542,0.004,2.343c0,0.066,0,0.133,0,0.2
      c0.001,0.241,0.001,0.482,0.001,0.727c0.001,0.305,0.001,0.612,0.001,0.922c0,0.133,0.001,0.269,0.001,0.404
      c0,0.168,0,0.335,0,0.504c0,0.237,0,0.475,0,0.714c0,0.114,0,0.229,0,0.344c0.001,0.358,0.001,0.719,0.001,1.083
      c0,0.191,0,0.381,0,0.573c0,0.148,0,0.297,0,0.446c0,0.235,0,0.47,0,0.707c0,0.189,0,0.379,0,0.569c0,0.092,0,0.186,0,0.279
      c0,0.377-0.001,0.755-0.001,1.137c0,0.726,0,1.461-0.001,2.202c0,0.101,0,0.202-0.001,0.303c-0.001,0.812-0.001,1.633-0.002,2.46
      c0,0.074,0,0.148-0.001,0.222c-0.001,0.768-0.002,1.542-0.003,2.32c0,0.203,0,0.405-0.001,0.608
      c-0.001,0.404-0.001,0.809-0.002,1.215c0,0.013,0,0.026,0,0.039c-0.019,11.84-0.061,24.547-0.098,34.519h54.839
      c0,0,0.973,0.278,1.668-0.834s1.668-1.39,2.085-0.139s1.668,1.529,4.171,1.39s10.844-1.112,11.817,1.112s2.363,1.946,4.171,1.251
      c1.807-0.695,0.417-2.502,2.641-3.337s5.561-2.224,5.839-3.893c0.278-1.668,0.139-3.198,1.946-3.059s2.641,0,3.337-0.834
      c0.383-0.459,1.146-1.509,2.103-2.429c-1.184-2.939-2.206-4.134-1.168-6.062c1.046-1.943,2.391-1.195,0.448-3.736
      c-1.943-2.541-2.839-1.196-4.334-4.783s-0.598-5.978-3.736-8.818c-3.139-2.839-3.139-3.437-4.185-7.771s-4.484-7.024-4.782-10.462
      c-0.299-3.438-2.541-4.334-4.036-6.427c-1.495-2.092-4.035-4.334-4.782-6.875s0.149-2.989-0.897-4.932
      c-1.047-1.943-1.495-2.84-1.196-4.633s0.149-4.782-0.299-6.127c-0.449-1.345-0.449-4.783-1.495-5.38
      c-1.047-0.598-2.392-0.449-2.392-1.495c0-1.046,2.092-1.943,0.897-2.69c-1.196-0.747-2.242,0-3.736,0.449
      c-1.495,0.448-2.84-2.69-4.932-1.793s-5.829,0.448-7.622,2.541c-1.794,2.092-4.036,2.242-5.679,3.587
      c-1.644,1.345-2.391,2.092-5.978,0.747c-3.587-1.345-8.519-1.943-10.91-2.839c-2.392-0.897-7.025-3.587-10.163-3.587
      s-5.231-1.793-6.875-0.747s-1.495,2.242-4.036,1.046c-0.618-0.291-1.119-0.715-1.565-1.189
      C490.394,96.997,490.205,97.402,490.015,97.497z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_87_" x="500.134" y="134.614" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_LIBYA" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Libya" dataWikipediaLink="https://en.wikipedia.org/wiki/Libya" data-infotext="<b>Libya</b> &amp;nbsp; Capital: Tripoli" data-imageFile="LIBYA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="LIBYA" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M365.239,91.936c-2.085,0.695-2.85,1.946-3.545,2.989s-1.46,1.39-2.711,1.668s-0.973,1.112-1.251,2.016s-0.07,2.085,0.904,2.85
      c0.973,0.765,0.556,1.251,0.626,2.85s-0.417,2.085-1.668,2.85s-1.668,0.973-1.946,2.085c-0.184,0.736-1.827,0.741-2.918,0.66
      l-0.001,0.001c-0.049,0.077-0.11,0.155-0.183,0.235c-0.01,0.011-0.019,0.022-0.03,0.033c-0.079,0.083-0.167,0.167-0.275,0.253
      c-1.39,1.112-1.39,2.92,0.139,3.893s1.668,2.224,1.946,3.198c0.278,0.973,1.668,2.363,1.39,4.449
      c-0.278,2.085,0.695,3.754-0.278,5.283c-0.973,1.529-0.834,2.92,0,3.615s1.668,2.224,0.973,3.615s-0.973,2.363-1.668,2.641
      s-1.529,2.085-0.556,3.059c0.973,0.973,1.251,1.39,1.39,2.641s0.973,3.059-0.695,3.337s-1.529,1.668-2.502,1.946
      c-0.973,0.278-1.807,1.807-0.278,3.337c1.529,1.529,2.502,4.032,3.476,4.588s1.807,1.807,1.807,4.171
      c0,2.363,1.112,5.839,4.866,5.978c3.754,0.139,7.229,0.417,8.203,1.807c0.695,0.992,2.875,5.028,4.068,7.258
      c0.073-0.016,0.142-0.027,0.209-0.037c0.13-0.022,0.253-0.037,0.358-0.037c4.264,0,5.191,1.112,6.859,1.854
      s5.746-0.186,7.415,1.853c1.668,2.039,2.966,4.078,4.634,4.264c1.668,0.185,0.927-1.297,4.264-2.224
      c0.211-0.059,0.405-0.118,0.584-0.18c0.065-0.022,0.119-0.045,0.18-0.067c0.109-0.04,0.22-0.079,0.318-0.12
      c0.066-0.028,0.122-0.056,0.184-0.084c0.082-0.037,0.167-0.073,0.242-0.111c0.058-0.029,0.108-0.06,0.162-0.089
      c0.068-0.037,0.139-0.074,0.201-0.112c0.052-0.032,0.096-0.064,0.144-0.095c0.056-0.037,0.116-0.074,0.168-0.112
      c0.046-0.033,0.086-0.066,0.13-0.099c0.049-0.037,0.099-0.075,0.145-0.113c0.041-0.034,0.078-0.068,0.117-0.102
      c0.044-0.039,0.09-0.077,0.132-0.116c0.037-0.034,0.073-0.068,0.11-0.103c0.044-0.042,0.09-0.085,0.134-0.127
      c0.02-0.019,0.04-0.038,0.059-0.057c0.045-0.043,0.089-0.086,0.134-0.13c0.046-0.045,0.094-0.09,0.142-0.134
      c0.033-0.031,0.065-0.061,0.098-0.092c0.047-0.042,0.097-0.085,0.147-0.128c0.035-0.03,0.068-0.061,0.105-0.092
      c0.053-0.043,0.111-0.087,0.167-0.13c0.039-0.029,0.074-0.059,0.114-0.088c0.063-0.046,0.134-0.092,0.203-0.137
      c0.04-0.027,0.076-0.053,0.118-0.08c0.079-0.049,0.167-0.098,0.255-0.147c0.04-0.023,0.076-0.046,0.118-0.068
      c0.102-0.054,0.216-0.108,0.331-0.161c0.036-0.017,0.068-0.035,0.105-0.052c0.158-0.07,0.327-0.14,0.512-0.209
      c2.966-1.112,4.449-3.522,8.342-1.297c3.54,2.023,58.116,30.1,67.984,35.175v-3.709l9.176-0.278c0,0,0-0.013,0-0.03
      c0.001-0.05,0.001-0.169,0.002-0.35c0.008-1.553,0.036-7.739,0.068-16.161c0.002-0.548,0.004-1.104,0.007-1.67
      c0.037-9.973,0.079-22.68,0.098-34.519c0-0.013,0-0.026,0-0.039c0-0.406,0.001-0.811,0.002-1.215
      c0.001-0.203,0.001-0.405,0.001-0.608c0.001-0.778,0.002-1.552,0.003-2.32c0.001-0.074,0.001-0.148,0.001-0.222
      c0.001-0.827,0.002-1.647,0.002-2.46c0.001-0.101,0.001-0.202,0.001-0.303c0.001-0.742,0.001-1.475,0.001-2.202
      c0-0.381,0.001-0.76,0.001-1.137c0-0.093,0-0.186,0-0.279c0-0.191,0-0.38,0-0.569c0-0.237,0-0.472,0-0.707c0-0.149,0-0.298,0-0.446
      c0-0.192,0-0.382,0-0.573c0-0.364,0-0.725-0.001-1.083c0-0.115,0-0.23,0-0.344c0-0.239,0-0.477,0-0.714c0-0.169,0-0.336,0-0.504
      c0-0.135-0.001-0.27-0.001-0.404c0-0.31-0.001-0.616-0.001-0.922c0-0.244,0-0.486-0.001-0.727c0-0.067,0-0.133,0-0.2
      c-0.001-0.8-0.002-1.582-0.004-2.343c0-0.003,0-0.006,0-0.009c0-0.248-0.001-0.494-0.002-0.738c0-0.087-0.001-0.173-0.001-0.259
      c0-0.305-0.002-0.606-0.002-0.903c-0.001-0.164-0.001-0.327-0.002-0.488c-0.001-0.204-0.001-0.406-0.002-0.606
      c0-0.041,0-0.083,0-0.124c-0.002-0.645-0.004-1.268-0.008-1.871c0-0.095-0.001-0.19-0.001-0.284
      c-0.001-0.185-0.002-0.368-0.002-0.549c-0.001-0.023-0.001-0.046-0.001-0.069c-0.003-0.611-0.007-1.197-0.01-1.757
      c0-0.079-0.001-0.158-0.001-0.236c-0.002-0.206-0.003-0.409-0.004-0.608c-0.001-0.139-0.002-0.277-0.003-0.412
      c-0.001-0.125-0.002-0.247-0.003-0.37c-0.001-0.1-0.002-0.2-0.002-0.298c-0.001-0.103-0.002-0.205-0.003-0.306
      c-0.002-0.198-0.004-0.393-0.006-0.581c-0.001-0.065-0.001-0.127-0.002-0.191c-0.001-0.125-0.003-0.248-0.004-0.369
      c-0.001-0.094-0.002-0.187-0.004-0.279c-0.001-0.103-0.002-0.203-0.003-0.302c-0.001-0.083-0.003-0.167-0.004-0.248
      c-0.002-0.15-0.004-0.296-0.007-0.437c-0.001-0.064-0.002-0.126-0.003-0.188c-0.002-0.093-0.003-0.184-0.005-0.273
      c-0.001-0.064-0.002-0.128-0.003-0.189c-0.003-0.135-0.005-0.266-0.008-0.389c-0.001-0.032-0.001-0.062-0.002-0.093
      c-0.002-0.095-0.004-0.187-0.007-0.274c-0.001-0.044-0.002-0.087-0.003-0.128c-0.002-0.09-0.005-0.176-0.008-0.257
      c-0.001-0.02-0.001-0.041-0.002-0.06c-0.003-0.096-0.007-0.183-0.01-0.265c-0.001-0.026-0.002-0.05-0.003-0.074
      c-0.003-0.062-0.005-0.119-0.008-0.171c-0.001-0.018-0.002-0.037-0.003-0.053c-0.004-0.064-0.007-0.12-0.011-0.168
      c-0.001-0.012-0.002-0.021-0.003-0.032c-0.003-0.036-0.006-0.067-0.009-0.093c-0.001-0.01-0.002-0.019-0.003-0.027
      c-0.004-0.028-0.008-0.052-0.012-0.064c-0.417-1.251,0-3.476-0.973-3.893s-0.417-1.946-1.112-3.337
      c-0.695-1.39,1.529-2.224,1.529-2.781s0.139-2.224,1.112-2.641s0.278-1.668-0.556-2.224s0-1.807-1.251-2.781
      c-1.251-0.973-0.278-2.224,0.695-2.502c0.973-0.278,0.695-1.39,1.251-1.668c0.19-0.095,0.379-0.5,0.547-0.99
      c-1.389-1.476-2.227-3.444-4.263-3.444c-2.69,0-3.736,1.046-5.53-0.149c-1.793-1.196-4.334-1.793-5.978-1.793
      c-1.644,0,0.299-3.587-4.184-4.633c-4.484-1.046-6.576-2.989-10.462-1.943c-3.886,1.046-9.714,2.242-11.209,4.334
      c-1.495,2.092-4.334,2.84-4.334,4.932s-0.149,3.587,0.897,5.38s2.69,2.989,0.449,5.38c-2.242,2.391-5.082,7.024-8.37,5.38
      s-8.668-6.725-13.899-7.772c-5.231-1.046-6.725-1.644-9.714-1.644c-2.989,0-5.679,1.345-7.174-2.242
      c-1.495-3.587-1.195-8.22-4.932-9.116c-3.736-0.897-4.484-1.046-6.725-2.541c-2.242-1.495-5.679-2.839-8.668-2.242
      c-2.989,0.598-6.576,1.345-9.266-0.149c-2.485-1.38-4.715-2.875-6.925-3.332c-0.089,2.248-0.316,7.327-0.585,8.242
      C369.34,90.337,367.324,91.241,365.239,91.936z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_86_" x="392.967" y="128.271" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_TUNISIA" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Tunisia" dataWikipediaLink="https://en.wikipedia.org/wiki/Tunisia" data-infotext="<b>Tunisia</b> &amp;nbsp; Capital: Tunis" data-imageFile="TUNISIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="TUNISIA" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M344.038,44.806c-1.668,0.139-2.502,0.834-2.363,1.668s-2.085,0.834-1.251,1.807c0.834,0.973,1.39,1.668,1.39,3.337
      s-0.973,3.059-0.556,3.893s0.556,1.112,0.834,3.337c0.278,2.224,0.834,3.615,0,4.727c-0.834,1.112-0.417,4.866-2.224,4.866
      s-2.641,0.834-2.92,1.668c-0.278,0.834-3.059,0.834-3.059,2.641s0.417,3.754,1.39,4.449c0.973,0.695,0.695,5.005,2.781,5.144
      c2.085,0.139,3.337,0.139,3.337,2.224s0.278,4.449,2.92,5.283c2.641,0.834,3.754,2.085,4.31,3.754c0.556,1.668,0,3.059,0.695,5.005
      s0.973,2.502,1.39,4.449c0.417,1.947,0.973,3.476,1.39,4.449c0.025,0.058,0.052,0.115,0.081,0.171
      c0.007,0.014,0.015,0.029,0.023,0.043c0.024,0.046,0.049,0.091,0.074,0.137c0.004,0.008,0.009,0.015,0.013,0.023
      c0.384,0.668,0.903,1.294,0.436,2.024c0,0,0,0,0,0.001c1.092,0.08,2.734,0.076,2.918-0.66c0.278-1.112,0.695-1.321,1.946-2.085
      c1.251-0.765,1.738-1.251,1.668-2.85s0.348-2.085-0.626-2.85c-0.973-0.765-1.182-1.946-0.904-2.85s0-1.738,1.251-2.016
      s2.016-0.626,2.711-1.668c0.695-1.043,1.46-2.294,3.545-2.989c2.085-0.695,4.101-1.599,4.449-2.781
      c0.269-0.915,0.496-5.994,0.585-8.242c-0.182-0.038-0.365-0.082-0.548-0.105c-2.391-0.299-1.794-1.495-2.989-2.541
      c-1.196-1.046-3.736-1.046-5.231-1.644s-4.035-2.989-2.242-5.53s4.035-3.886,4.633-5.829s3.587-1.793,2.839-4.484
      c-0.747-2.69,0.448-4.484-2.391-5.38c-2.84-0.897-4.484-2.69-2.84-5.38c1.644-2.69,4.484-3.886,4.783-5.081
      c0.299-1.196,0-4.334-1.495-2.69s-3.288,5.38-4.334,2.541c-1.046-2.839-2.242-4.334-3.736-4.932c-1.495-0.598-4.035-1.196-5.679,0
      c-1.319,0.959-3.697,2.301-6.438,2.795C344.864,43.639,344.94,44.731,344.038,44.806z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_85_" x="321.924" y="58.236" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_ALGERIA" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Algeria" dataWikipediaLink="https://en.wikipedia.org/wiki/Algeria" data-infotext="<b>Algeria</b> &amp;nbsp; Capital: Algiers" data-imageFile="ALGERIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="ALGERIA" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M370.453,167.983c-0.973-1.39-4.449-1.668-8.203-1.807s-4.866-3.615-4.866-5.978c0-2.363-0.834-3.615-1.807-4.171
      s-1.946-3.059-3.476-4.588c-1.529-1.529-0.695-3.059,0.278-3.337c0.973-0.278,0.834-1.668,2.502-1.946s0.834-2.085,0.695-3.337
      c-0.139-1.251-0.417-1.668-1.39-2.641c-0.973-0.973-0.139-2.781,0.556-3.059s0.973-1.251,1.668-2.641s-0.139-2.92-0.973-3.615
      c-0.834-0.695-0.973-2.085,0-3.615c0.973-1.529,0-3.198,0.278-5.283s-1.112-3.476-1.39-4.449s-0.417-2.224-1.946-3.198
      s-1.529-2.781-0.139-3.893c0.108-0.086,0.197-0.171,0.275-0.253c0.011-0.011,0.02-0.022,0.03-0.033
      c0.071-0.079,0.133-0.158,0.183-0.235l0.001-0.001c0.467-0.73-0.052-1.356-0.436-2.024c-0.004-0.008-0.009-0.015-0.013-0.023
      c-0.026-0.045-0.051-0.091-0.074-0.137c-0.008-0.014-0.015-0.029-0.023-0.043c-0.029-0.057-0.056-0.114-0.081-0.171
      c-0.417-0.973-0.973-2.502-1.39-4.449c-0.417-1.947-0.695-2.502-1.39-4.449s-0.139-3.337-0.695-5.005s-1.668-2.92-4.31-3.754
      s-2.92-3.198-2.92-5.283s-1.251-2.085-3.337-2.224c-2.085-0.139-1.807-4.449-2.781-5.144c-0.973-0.695-1.39-2.642-1.39-4.449
      s2.781-1.807,3.059-2.641c0.278-0.834,1.112-1.668,2.92-1.668c1.807,0,1.39-3.754,2.224-4.866c0.834-1.112,0.278-2.502,0-4.727
      c-0.278-2.224-0.417-2.502-0.834-3.337c-0.417-0.834,0.556-2.224,0.556-3.893c0-1.669-0.556-2.363-1.39-3.337
      s1.39-0.973,1.251-1.807s0.695-1.529,2.363-1.668c0.902-0.075,0.827-1.167,0.569-2.153c-0.675,0.122-1.372,0.194-2.081,0.194
      c-3.587,0-4.932,2.69-6.725,0.448s-1.943-1.793-4.334-1.196c-2.391,0.598-3.139,1.196-4.334,0.299
      c-1.196-0.897-3.736-0.299-4.633,0.897s-3.587,1.345-5.082,1.345s-1.943,1.046-4.334-0.299c-2.391-1.345-3.288-1.943-5.829-1.644
      c-2.541,0.299-5.231-0.449-6.725,1.345c-1.495,1.793-6.576,0.897-9.415,1.644c-2.84,0.747-3.736,0.897-6.875,1.196
      c-3.138,0.299-7.921,0.448-9.266,1.943s-4.035,1.196-5.231,3.288c-1.196,2.092-5.38,2.391-7.473,2.69
      c-2.092,0.299-4.484,1.046-5.829,3.138s-2.391,3.886-5.38,3.886c-0.838,0-1.592-0.001-2.289-0.034
      c0.098,0.711,0.386,1.412,1.099,1.689c1.668,0.649,2.781,1.483,2.966,2.41s1.205,3.059,0.649,4.078
      c-0.556,1.02,0.371,3.893-0.556,4.634c-0.927,0.741,0,1.576,0.464,2.502c0.464,0.927-0.278,3.244,0.649,4.356
      s1.39,1.946,1.39,2.966s0.927,1.761,1.576,2.224c0.649,0.464,1.761,0.834,1.483,2.224c-0.278,1.39,0.464,3.615-2.039,3.522
      c-2.502-0.093-4.727,0.463-6.303-0.371c-1.575-0.834-4.449-1.112-5.468-0.463c-1.02,0.649-2.781,1.298-2.966,2.224
      s-1.946,0.927-2.595,1.946s-3.151,0.278-3.986,0.463c-0.834,0.185-0.834,1.483-0.649,2.224c0.185,0.742,0,2.224,0.556,2.781
      c0.556,0.556,1.205,1.854-0.093,2.317s-2.873,0.093-3.893,1.39s-2.317,1.112-3.522,1.39s-2.966,0.741-3.8,2.132
      c-0.834,1.39-1.576,2.41-1.946,3.337c-0.371,0.927-1.761,1.761-3.707,1.761s-5.839,0.092-6.859,1.297
      c-1.019,1.205-1.854,1.947-3.244,1.761c-1.39-0.185-3.244-1.761-4.356,0.093c-1.112,1.854-2.502,2.225-3.615,2.41
      c-1.112,0.185-1.112,1.483-2.595,2.317s-2.781,2.039-4.449,2.688s-1.576,0-1.761,5.746c-0.005,0.148-0.009,0.303-0.013,0.462
      c-0.001,0.064-0.003,0.131-0.004,0.197c-0.002,0.099-0.004,0.198-0.007,0.301c-0.001,0.082-0.003,0.166-0.004,0.251
      c-0.001,0.09-0.003,0.18-0.004,0.272c-0.001,0.093-0.002,0.188-0.003,0.284c-0.001,0.091-0.002,0.184-0.003,0.277
      c0,0.099-0.002,0.199-0.002,0.301c-0.001,0.093-0.001,0.187-0.002,0.282c-0.001,0.106-0.001,0.212-0.001,0.32
      c-0.001,0.094-0.001,0.191-0.001,0.288c0,0.111-0.001,0.221-0.001,0.335c0,0.094,0,0.191,0.001,0.286
      c0,0.254,0.001,0.511,0.002,0.773c0,0.063,0.001,0.125,0.001,0.189c0.001,0.195,0.002,0.393,0.004,0.592c0,0.01,0,0.02,0,0.03
      c0.009,1.275,0.026,2.621,0.048,3.967c1.309,0.105,3.145,0.408,4.623,1.252c0.037,0.021,0.093,0.056,0.16,0.097
      c0.021,0.013,0.048,0.03,0.072,0.046c0.052,0.033,0.11,0.07,0.175,0.112c0.036,0.023,0.073,0.047,0.113,0.073
      c0.075,0.049,0.16,0.105,0.25,0.163c0.036,0.024,0.067,0.044,0.105,0.069c0.131,0.087,0.275,0.182,0.433,0.287
      c0.048,0.032,0.102,0.068,0.151,0.101c0.118,0.079,0.241,0.161,0.372,0.248c0.072,0.048,0.147,0.099,0.222,0.149
      c0.127,0.085,0.258,0.173,0.395,0.265c0.077,0.051,0.151,0.102,0.231,0.155c0.171,0.115,0.351,0.236,0.536,0.361
      c0.083,0.056,0.165,0.112,0.251,0.169c0.208,0.14,0.423,0.286,0.647,0.437c0.111,0.075,0.228,0.154,0.343,0.232
      c0.165,0.111,0.332,0.225,0.504,0.341c0.113,0.077,0.226,0.154,0.343,0.232c0.224,0.152,0.455,0.308,0.69,0.468
      c0.077,0.053,0.152,0.103,0.231,0.157c0.32,0.217,0.648,0.44,0.988,0.671c0.084,0.058,0.174,0.119,0.26,0.177
      c0.241,0.164,0.485,0.33,0.735,0.5c0.179,0.122,0.363,0.247,0.546,0.372c0.166,0.112,0.333,0.226,0.502,0.342
      c0.21,0.143,0.421,0.287,0.637,0.434c0.193,0.132,0.391,0.267,0.589,0.401c0.171,0.117,0.338,0.231,0.513,0.35
      c0.389,0.265,0.787,0.537,1.191,0.813c0.135,0.092,0.273,0.186,0.409,0.279c0.266,0.182,0.533,0.364,0.805,0.55
      c0.225,0.153,0.452,0.308,0.68,0.464c0.179,0.122,0.36,0.246,0.541,0.37c0.247,0.169,0.492,0.337,0.743,0.508
      c0.215,0.147,0.435,0.298,0.654,0.447c0.562,0.384,1.132,0.774,1.712,1.171c0.042,0.028,0.082,0.056,0.124,0.085
      c0.367,0.251,0.738,0.505,1.111,0.761c0.075,0.051,0.15,0.102,0.225,0.154c0.345,0.236,0.692,0.474,1.043,0.714
      c0.174,0.119,0.35,0.24,0.526,0.36c1.054,0.722,2.13,1.459,3.227,2.211c0.163,0.112,0.326,0.224,0.491,0.336
      c0.304,0.209,0.61,0.418,0.917,0.629c0.214,0.147,0.429,0.294,0.645,0.442c0.297,0.203,0.592,0.406,0.891,0.611
      c0.18,0.124,0.362,0.248,0.544,0.373c0.31,0.212,0.622,0.427,0.934,0.641l0,0c0.232,0.159,0.466,0.32,0.7,0.48
      c0.189,0.13,0.378,0.259,0.567,0.389c0.621,0.426,1.245,0.854,1.873,1.285c0.069,0.047,0.138,0.094,0.207,0.142
      c0.2,0.137,0.399,0.274,0.599,0.411c0.453,0.311,0.907,0.622,1.363,0.935c0.072,0.05,0.144,0.099,0.217,0.149
      c0.044,0.03,0.089,0.061,0.134,0.092c1.288,0.885,2.586,1.775,3.886,2.668c0.463,0.318,0.926,0.636,1.39,0.955
      c0.05,0.034,0.099,0.068,0.149,0.102c0.535,0.368,1.071,0.736,1.607,1.104c0.157,0.108,0.314,0.215,0.471,0.323
      c4.238,2.911,8.457,5.811,12.47,8.57c0.629,0.433,1.253,0.862,1.872,1.287c4.574,3.145,8.829,6.073,12.478,8.584
      c0.006,0.004,0.013,0.009,0.019,0.014c1.632,1.123,3.138,2.16,4.5,3.097c0.892,0.614,1.723,1.186,2.481,1.708
      c0.241,0.166,0.469,0.323,0.695,0.478c0.623,0.428,1.189,0.818,1.694,1.166c0.255,0.175,0.494,0.341,0.717,0.494
      c0.175,0.121,0.344,0.237,0.498,0.343c0.141,0.098,0.277,0.191,0.402,0.276c0.345,0.238,0.62,0.427,0.824,0.568
      c0.042,0.029,0.08,0.055,0.116,0.08c0.129,0.089,0.216,0.148,0.263,0.181c0.022,0.015,0.043,0.03,0.043,0.03
      s2.41,1.854,2.224,2.966c-0.185,1.112,1.112,2.966,2.41,2.966s1.853,0.926,2.595,2.224s0.741,1.483,2.595,1.483
      s3.522,0.927,3.708,1.483s2.039,0.741,3.151,0.741s2.41,0.371,2.595,2.039s2.595,1.483,1.668,2.966s-2.966,1.854-1.297,3.522
      c1.668,1.668,4.449,1.854,4.449,0.371s1.853-1.854,4.263-1.483c0.163,0.025,0.315,0.04,0.461,0.047
      c0.377,0.02,0.702-0.015,1.001-0.085h0.001c0.091-0.021,0.179-0.046,0.266-0.073c0.011-0.003,0.022-0.006,0.033-0.009
      c0.087-0.028,0.172-0.058,0.256-0.09c0.009-0.004,0.018-0.007,0.028-0.01c0.32-0.124,0.633-0.276,0.975-0.427
      c0.001-0.001,0.002-0.001,0.003-0.002c0.09-0.039,0.182-0.078,0.276-0.117c0.029-0.012,0.057-0.023,0.087-0.035
      c0.078-0.031,0.158-0.061,0.24-0.09c0.041-0.015,0.081-0.029,0.123-0.043c0.077-0.026,0.157-0.05,0.239-0.074
      c0.049-0.015,0.098-0.029,0.149-0.043c0.081-0.022,0.168-0.042,0.254-0.061c0.056-0.013,0.11-0.026,0.168-0.038
      c0.091-0.018,0.19-0.033,0.287-0.048c0.06-0.009,0.116-0.02,0.179-0.028c0.109-0.014,0.227-0.024,0.344-0.034
      c0.058-0.005,0.113-0.012,0.173-0.016c0.182-0.011,0.372-0.018,0.574-0.018c3.337,0,6.488-0.371,9.083-2.595
      s16.869-14.088,21.688-16.683c0.148-0.08,0.315-0.173,0.498-0.278c0.062-0.036,0.137-0.08,0.203-0.119
      c0.13-0.075,0.258-0.15,0.402-0.236c0.083-0.049,0.177-0.106,0.264-0.159c0.141-0.085,0.279-0.168,0.432-0.261
      c0.103-0.063,0.217-0.134,0.326-0.2c0.154-0.095,0.306-0.188,0.47-0.29c0.107-0.066,0.222-0.138,0.333-0.207
      c0.178-0.111,0.356-0.222,0.544-0.341c0.112-0.071,0.232-0.146,0.348-0.219c0.203-0.128,0.406-0.256,0.619-0.39
      c0.096-0.061,0.197-0.125,0.294-0.187c0.255-0.161,0.511-0.324,0.778-0.494c0.043-0.028,0.089-0.057,0.133-0.084
      c8.528-5.431,22.931-14.846,27.157-15.737C373.328,173.011,371.147,168.975,370.453,167.983z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_84_" x="263.624" y="121.72" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_MOROCCO" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Morocco" dataWikipediaLink="https://en.wikipedia.org/wiki/Morocco" data-infotext="<b>Morocco</b> &amp;nbsp; Capital: Rabat" data-imageFile="MOROCCO.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="MOROCCO" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M191.161,134.662c0-0.064-0.001-0.125-0.001-0.189c-0.001-0.262-0.002-0.52-0.002-0.773c0-0.096-0.001-0.192-0.001-0.286
      c0-0.114,0-0.223,0.001-0.335c0-0.096,0-0.193,0.001-0.288c0-0.108,0.001-0.214,0.001-0.32c0.001-0.095,0.001-0.19,0.002-0.282
      c0.001-0.102,0.001-0.201,0.002-0.301c0.001-0.094,0.002-0.186,0.003-0.277c0.001-0.096,0.002-0.191,0.003-0.284
      c0.001-0.092,0.003-0.182,0.004-0.272c0.001-0.084,0.003-0.169,0.004-0.251c0.002-0.103,0.004-0.202,0.007-0.301
      c0.001-0.066,0.003-0.133,0.004-0.197c0.004-0.16,0.008-0.315,0.013-0.462c0.185-5.747,0.093-5.098,1.761-5.746
      c1.668-0.649,2.966-1.854,4.449-2.688s1.483-2.132,2.595-2.317s2.502-0.556,3.615-2.41c1.113-1.854,2.966-0.278,4.356-0.093
      s2.224-0.556,3.244-1.761c1.019-1.205,4.912-1.297,6.859-1.297s3.337-0.834,3.707-1.761c0.371-0.927,1.112-1.946,1.946-3.337
      c0.834-1.39,2.595-1.853,3.8-2.132s2.502-0.093,3.522-1.39s2.595-0.927,3.893-1.39s0.649-1.761,0.093-2.317
      s-0.371-2.039-0.556-2.781s-0.185-2.039,0.649-2.224s3.337,0.556,3.986-0.463s2.41-1.02,2.595-1.946s1.946-1.576,2.966-2.224
      c1.02-0.649,3.893-0.371,5.468,0.463s3.8,0.278,6.303,0.371c2.502,0.093,1.761-2.132,2.039-3.522s-0.834-1.761-1.483-2.224
      c-0.649-0.464-1.576-1.205-1.576-2.224s-0.463-1.854-1.39-2.966s-0.185-3.429-0.649-4.356s-1.39-1.761-0.464-2.502
      c0.927-0.741,0-3.615,0.556-4.634c0.556-1.02-0.463-3.151-0.649-4.078c-0.185-0.927-1.298-1.761-2.966-2.41
      c-0.713-0.277-1.001-0.978-1.099-1.689c-1.788-0.084-3.188-0.385-4.586-1.46c-1.943-1.495-5.829-1.196-8.07-0.747
      c-2.242,0.448-5.231,2.541-8.37,0.747c-3.138-1.793-4.633-2.391-5.38-3.736c-0.747-1.345-0.747-2.541-3.288-2.69
      c-2.541-0.15-3.437-0.15-3.886,1.793s-0.449,4.484-1.943,7.323c-1.495,2.84-2.54,4.334-3.886,5.679
      c-1.345,1.345-4.484,4.932-6.725,5.679c-2.242,0.747-8.818,4.035-10.611,7.024c0,0-2.989,3.587-3.886,4.633
      c-0.897,1.046-2.391,3.138-2.839,5.38c-0.449,2.242-2.391,1.196-2.84,3.437c-0.448,2.242,0,3.288-0.747,5.082
      c-0.747,1.793-1.196,3.138,0,5.231c1.196,2.092,1.943,3.288,1.196,5.38c-0.747,2.092-3.138,6.426-5.829,9.266
      c-2.69,2.84-5.38,2.989-7.622,5.231c-2.242,2.242-2.989,4.484-7.622,5.082s-8.967,1.644-10.761,3.886
      c-0.113,0.142-0.22,0.302-0.321,0.479l40.407,0.256C191.161,134.718,191.161,134.691,191.161,134.662z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_83_" x="181.423" y="74.725" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_WESTERNSAHARA" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Western Sahara" dataWikipediaLink="https://en.wikipedia.org/wiki/Western_Sahara" data-infotext="<b>Western Sahara</b> (disputed)" data-imageFile="WESTERNSAHARA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="WESTERNSAHARA" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M152.552,194.63c0,0-0.185-7.693-0.093-8.805s0-2.132-0.834-2.781s0.185-2.317,0.278-3.337s2.595-1.854,3.244-2.41
      s1.854-1.39,3.059-1.297s3.151-1.576,3.151-1.576l0.371-23.264l29.752-0.464c0,0,0-0.008-0.001-0.02c0-0.004,0-0.004,0-0.008
      c-0.001-0.032-0.003-0.096-0.006-0.187c-0.002-0.08-0.005-0.185-0.009-0.307c-0.001-0.033-0.002-0.068-0.003-0.104
      c0-0.002,0-0.005,0-0.007c-0.001-0.045-0.003-0.09-0.004-0.139c-0.004-0.133-0.008-0.277-0.013-0.44
      c-0.001-0.023-0.001-0.048-0.002-0.071c-0.004-0.137-0.008-0.284-0.013-0.44c-0.001-0.038-0.002-0.075-0.003-0.115
      c-0.08-2.914-0.228-8.758-0.262-13.575c0-0.01,0-0.02,0-0.03c-0.001-0.169-0.002-0.339-0.003-0.506l-40.407-0.256
      c-1.503,2.612-1.82,8.751-4.76,10.431c-3.138,1.793-5.828,2.989-7.024,6.725s-1.793,9.864-4.334,11.956s-5.53,4.035-6.426,6.875
      c-0.897,2.84-3.437,3.736-3.587,7.024c-0.149,3.288,0.747,3.288-1.793,5.38c-2.541,2.092-3.886,3.587-4.782,6.128
      c-0.616,1.744-0.953,3.693-1.391,5.473c0.24-0.258,0.497-0.439,0.497-0.439s-0.257,0.181-0.497,0.439
      c-0.004,0.017-0.008,0.036-0.013,0.054L152.552,194.63z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_82_" x="114.01" y="154.746" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_NIGER" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Niger" dataWikipediaLink="https://en.wikipedia.org/wiki/Niger" data-infotext="<b>Niger</b> &amp;nbsp; Capital: Niamey" data-imageFile="NIGER.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="NIGERIA" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M387.534,264.222c0.078-0.113,0.147-0.232,0.207-0.357c0.003-0.007,0.007-0.012,0.01-0.018c0.056-0.117,0.102-0.243,0.142-0.375
      c0.005-0.017,0.012-0.033,0.017-0.051c0.035-0.124,0.06-0.26,0.081-0.401c0.004-0.029,0.012-0.054,0.016-0.084
      c0.021-0.171,0.033-0.355,0.033-0.554c0-1.529,0.278-2.224-0.278-2.989c-0.556-0.765-1.599-1.39-0.278-2.016
      c1.321-0.626,2.781-4.101,3.893-5.561s9.106-11.4,11.47-13.207c2.363-1.807,2.294-3.754,2.294-6.465s1.043-5.283,1.043-7.716
      s0.139-10.427,0.417-12.026s0.278-3.406,0.973-4.379c0.695-0.973,1.738-1.738,1.668-3.059s0.278-2.155-1.043-2.85
      s-2.363-0.834-2.363-2.502s-0.765-3.615-1.529-4.518c-0.765-0.904-1.807-2.224-1.946-3.684s0.626-2.92-0.139-4.31
      s-0.765-3.337-0.765-4.866c0-0.768-0.123-1.958-0.245-2.952c-0.044,0.042-0.09,0.085-0.134,0.127
      c-0.036,0.034-0.073,0.069-0.11,0.103c-0.042,0.039-0.088,0.078-0.132,0.116c-0.039,0.034-0.077,0.068-0.117,0.102
      c-0.046,0.037-0.096,0.075-0.145,0.113c-0.043,0.033-0.084,0.066-0.13,0.099c-0.053,0.037-0.112,0.075-0.168,0.112
      c-0.048,0.031-0.093,0.064-0.144,0.095c-0.062,0.038-0.134,0.075-0.201,0.112c-0.054,0.029-0.104,0.06-0.162,0.089
      c-0.075,0.038-0.16,0.074-0.242,0.111c-0.061,0.028-0.117,0.056-0.184,0.084c-0.098,0.04-0.21,0.08-0.318,0.12
      c-0.061,0.022-0.116,0.045-0.18,0.067c-0.18,0.061-0.374,0.121-0.584,0.18c-3.337,0.927-2.595,2.41-4.264,2.224
      c-1.668-0.185-2.966-2.224-4.634-4.264c-1.668-2.039-5.746-1.112-7.415-1.853s-2.595-1.854-6.859-1.854
      c-0.104,0-0.23,0.018-0.358,0.037c-0.068,0.011-0.135,0.022-0.209,0.037c-4.226,0.89-18.632,10.308-27.157,15.737
      c-0.044,0.028-0.089,0.057-0.133,0.084c-0.266,0.169-0.524,0.333-0.778,0.494c-0.098,0.062-0.199,0.126-0.294,0.187
      c-0.213,0.135-0.416,0.263-0.619,0.39c-0.116,0.073-0.236,0.149-0.348,0.219c-0.188,0.118-0.366,0.229-0.544,0.341
      c-0.111,0.069-0.226,0.141-0.333,0.207c-0.164,0.102-0.316,0.195-0.47,0.29c-0.109,0.067-0.223,0.137-0.326,0.2
      c-0.153,0.093-0.291,0.176-0.432,0.261c-0.087,0.053-0.181,0.11-0.264,0.159c-0.144,0.086-0.272,0.16-0.402,0.236
      c-0.066,0.039-0.14,0.083-0.203,0.119c-0.183,0.105-0.35,0.198-0.498,0.278c-4.819,2.595-19.093,14.459-21.688,16.683
      s-5.746,2.595-9.083,2.595c-0.202,0-0.392,0.007-0.574,0.018c-0.061,0.004-0.115,0.011-0.173,0.016
      c-0.117,0.01-0.235,0.02-0.344,0.034c-0.062,0.008-0.119,0.019-0.179,0.028c-0.098,0.015-0.196,0.03-0.287,0.048
      c-0.058,0.012-0.112,0.026-0.168,0.038c-0.087,0.02-0.173,0.04-0.254,0.061c-0.051,0.014-0.1,0.029-0.149,0.043
      c-0.081,0.024-0.162,0.048-0.239,0.074c-0.042,0.014-0.083,0.029-0.123,0.043c-0.082,0.029-0.163,0.059-0.24,0.09
      c-0.029,0.011-0.058,0.023-0.087,0.035c-0.094,0.039-0.187,0.078-0.276,0.117c-0.001,0.001-0.002,0.001-0.003,0.002
      c-0.342,0.151-0.654,0.303-0.975,0.427c-0.009,0.003-0.018,0.007-0.028,0.01c-0.084,0.032-0.169,0.062-0.256,0.09
      c-0.011,0.003-0.022,0.006-0.033,0.009c-0.087,0.027-0.175,0.052-0.266,0.073h-0.001c-0.3,0.07-0.625,0.105-1.001,0.085
      c-0.015,5.402-0.118,24.265-0.832,25.534c-0.834,1.483-0.649,1.854-1.112,3.986s-3.151,3.244-4.078,4.264
      s-7.785,0.834-10.659,0.834s-4.542,0.185-6.766,0.185s-3.8,1.483-4.541,2.688c-0.742,1.205-2.966,0.742-5.19,0.834
      c-0.335,0.014-0.661,0.02-0.982,0.023c-0.057,0.001-0.112,0.001-0.169,0.001c-0.296,0.001-0.584-0.001-0.865-0.006
      c0.062,0.564,0.058,1.166-0.116,1.651c-0.464,1.297-1.853,2.688-0.278,3.337c1.575,0.649,1.483,2.317,1.575,3.337
      c0.093,1.019,0.742,2.039,2.317,2.595c1.575,0.556,1.297,1.483,2.966,2.039c1.668,0.556,2.317,1.484,1.761,1.854
      s-2.039,1.297-1.39,2.317s1.576,0.649,2.502,2.039c0.927,1.39,1.576,1.853,2.781,2.224c1.205,0.371,1.668,0.835,2.132-0.463
      s1.761-0.278,2.317,0.185s0.834,1.297,0.649,2.132c-0.185,0.835-0.185,2.224,0.464,2.595c0.242,0.138,0.6,0.328,0.953,0.512l0,0
      c0.02-0.078,0.045-0.155,0.075-0.229c0.007-0.017,0.015-0.034,0.022-0.05c0.026-0.058,0.055-0.114,0.087-0.169
      c0.013-0.022,0.026-0.043,0.04-0.064c0.034-0.051,0.073-0.099,0.115-0.148c0.017-0.02,0.033-0.04,0.051-0.06
      c0.062-0.065,0.13-0.127,0.208-0.186c1.112-0.834,0.626-1.668,1.738-1.738s1.39-1.668,2.92-0.07
      c1.529,1.599,1.668,3.059,2.85,3.476s1.251,1.251,2.433,1.251c0.042,0,0.073-0.004,0.112-0.005
      c0.131-0.003,0.246-0.011,0.349-0.026c0.005-0.001,0.008-0.002,0.013-0.003c0.099-0.015,0.187-0.036,0.262-0.066
      c0.002-0.001,0.004-0.002,0.006-0.003c0.466-0.185,0.509-0.671,0.509-1.774c0-1.529,0-4.449,1.529-5.422s1.738-1.182,1.877-3.684
      s-0.139-4.518,1.46-4.935s2.155-1.46,4.24-1.46s3.754,0.487,4.796-0.209s0.973-0.834,2.433-0.834c1.46,0,1.738,0.139,2.433,0.765
      s1.043,0.834,1.807,0.834c0.765,0,0.556,0.904,1.807,0.904s2.572,0.348,3.476,1.46c0.904,1.112,1.599,2.85,2.363,3.476
      s1.599,0.556,2.433-0.139s1.529-0.278,2.642-0.348c1.112-0.07,1.251-0.973,2.363-1.529s1.877-1.46,3.337-0.487
      s3.337,2.224,4.31,2.92c0.973,0.695,1.599,1.946,3.893,1.877c2.294-0.07,5.005,0.139,5.839-0.765
      c0.834-0.904,2.433-1.529,3.406-2.363s1.529-1.877,3.684-1.946s10.288-0.834,11.053,0.209c0.765,1.043,1.599,1.39,2.989,1.668
      c1.39,0.278,2.989,1.39,3.893-0.07c0.904-1.46,1.182-2.433,3.684-2.92c2.502-0.487,3.059-0.695,4.101-1.529
      C386.859,264.981,387.25,264.634,387.534,264.222z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_81_" x="326.975" y="218.657" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_MAURITANIA" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Mauritania" dataWikipediaLink="https://en.wikipedia.org/wiki/Mauritania" data-infotext="<b>Mauritania</b> &amp;nbsp; Capital: Nouakchott" data-imageFile="MAURITANIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="MAURITANIA" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M126.508,238.979c0.649,0.371,2.039,0.37,2.595-0.139s2.086-0.603,3.244-0.695s2.132-0.278,3.059-0.556s2.827-0.046,4.449,0.51
      c1.622,0.556,2.734,0.788,3.059,1.761c0.324,0.973,1.39,1.9,2.734,1.9s2.178,0.093,2.641,0.788c0.464,0.695,1.9,2.502,2.595,3.661
      s3.012,4.913,4.078,5.33c1.066,0.417,2.039,0.371,2.641,1.251c0.603,0.88,0.973,2.363,2.224,2.734
      c0.042,0.013,0.087,0.014,0.13,0.024c0.001,0,0.002,0,0.002,0.001c0.459,0.106,0.949,0.074,1.4-0.081
      c0.011-0.004,0.021-0.01,0.033-0.014c0.136-0.049,0.269-0.105,0.394-0.175c0.034-0.019,0.063-0.043,0.096-0.064
      c0.1-0.062,0.199-0.124,0.289-0.2c0.052-0.043,0.095-0.096,0.143-0.144c0.062-0.063,0.128-0.121,0.182-0.191
      c0.06-0.077,0.104-0.163,0.152-0.248c0.03-0.053,0.068-0.1,0.093-0.155c0.066-0.146,0.116-0.302,0.144-0.467
      c0.232-1.344,0.603-2.086,0.742-3.569s0.046-2.178,1.483-2.688c1.437-0.51,2.873-0.463,3.337,0.556s0.741,1.622,1.205,1.993
      s0.927,0.231,0.973,1.112s1.112,1.252,1.298,0.047c0.185-1.205,0.093-2.502,1.9-2.641s2.966-0.557,4.356,0.138s4.866,0.973,5.422,0
      s1.39-2.363,2.781-1.251c1.39,1.112,2.781,1.112,5.144,1.112c2.363,0,27.527,0,27.527,0s-0.973-1.946,0.556-2.781
      s1.112-1.668,1.112-2.641s0.973-1.946-0.556-3.059c-1.529-1.112-2.224-1.946-2.224-4.032s-7.924-75.908-7.924-75.908l14.224-0.365
      c-0.312-0.214-0.624-0.428-0.934-0.641c-0.181-0.124-0.363-0.249-0.544-0.373c-0.299-0.205-0.595-0.408-0.891-0.611
      c-0.216-0.148-0.431-0.295-0.645-0.442c-0.307-0.21-0.613-0.42-0.917-0.629c-0.164-0.113-0.327-0.224-0.491-0.336
      c-1.097-0.751-2.173-1.489-3.227-2.211c-0.175-0.12-0.351-0.241-0.526-0.36c-0.351-0.24-0.697-0.478-1.043-0.714
      c-0.075-0.052-0.15-0.103-0.225-0.154c-0.374-0.256-0.745-0.51-1.111-0.761c-0.042-0.029-0.082-0.057-0.124-0.085
      c-0.581-0.397-1.151-0.787-1.712-1.171c-0.218-0.149-0.438-0.3-0.654-0.447c-0.251-0.172-0.497-0.339-0.743-0.508
      c-0.181-0.124-0.362-0.248-0.541-0.37c-0.229-0.156-0.456-0.311-0.68-0.464c-0.272-0.186-0.539-0.368-0.805-0.55
      c-0.136-0.093-0.274-0.187-0.409-0.279c-0.404-0.276-0.802-0.548-1.191-0.813c-0.175-0.119-0.342-0.233-0.513-0.35
      c-0.197-0.134-0.396-0.27-0.589-0.401c-0.216-0.147-0.427-0.291-0.637-0.434c-0.169-0.115-0.336-0.229-0.502-0.342
      c-0.184-0.125-0.367-0.25-0.546-0.372c-0.25-0.171-0.494-0.336-0.735-0.5c-0.086-0.058-0.175-0.119-0.26-0.177
      c-0.34-0.231-0.668-0.454-0.988-0.671c-0.078-0.053-0.153-0.104-0.231-0.157c-0.235-0.16-0.466-0.316-0.69-0.468
      c-0.116-0.079-0.23-0.156-0.343-0.232c-0.172-0.117-0.339-0.23-0.504-0.341c-0.115-0.078-0.232-0.157-0.343-0.232
      c-0.224-0.152-0.439-0.297-0.647-0.437c-0.086-0.058-0.168-0.113-0.251-0.169c-0.185-0.125-0.365-0.246-0.536-0.361
      c-0.08-0.053-0.155-0.104-0.231-0.155c-0.137-0.092-0.269-0.181-0.395-0.265c-0.075-0.051-0.151-0.101-0.222-0.149
      c-0.131-0.087-0.254-0.169-0.372-0.248c-0.05-0.033-0.104-0.07-0.151-0.101c-0.157-0.105-0.301-0.2-0.433-0.287
      c-0.038-0.025-0.069-0.045-0.105-0.069c-0.09-0.059-0.174-0.114-0.25-0.163c-0.04-0.026-0.077-0.049-0.113-0.073
      c-0.065-0.042-0.123-0.079-0.175-0.112c-0.024-0.015-0.051-0.032-0.072-0.046c-0.067-0.042-0.123-0.077-0.16-0.097
      c-1.478-0.844-3.314-1.147-4.623-1.252c-0.022-1.345-0.039-2.692-0.048-3.967c0.034,4.817,0.181,10.66,0.262,13.575
      c0.001,0.039,0.002,0.077,0.003,0.115c0.004,0.156,0.008,0.303,0.013,0.44c0.001,0.024,0.001,0.048,0.002,0.071
      c0.005,0.163,0.009,0.307,0.013,0.44c0.001,0.049,0.003,0.094,0.004,0.139c0,0.002,0,0.005,0,0.007
      c0.001,0.036,0.002,0.071,0.003,0.104c0.004,0.122,0.007,0.227,0.009,0.307c0.003,0.091,0.005,0.154,0.006,0.187
      c0,0.005,0,0.005,0,0.008c0.001,0.012,0.001,0.02,0.001,0.02l-29.752,0.464l-0.371,23.264c0,0-1.946,1.669-3.151,1.576
      s-2.41,0.741-3.059,1.297s-3.151,1.39-3.244,2.41s-1.112,2.688-0.278,3.337s0.927,1.668,0.834,2.781s0.093,8.805,0.093,8.805
      l-35.909-0.091c0.004-0.018,0.008-0.036,0.013-0.054c-0.192,0.205-0.373,0.459-0.401,0.715c-0.064,0.577-0.192,2.181-0.77,2.694
      c-0.577,0.513-0.385,2.052,0.064,2.629c0.449,0.577,1.667,1.091,2.244-0.513s1.667-3.271,1.988-1.86s0.385,2.116,1.026,2.565
      s1.282,0.128,2.309,1.86c1.026,1.732,1.667,2.758,1.411,3.912c-0.257,1.154-1.475,1.411-1.988,2.309
      c-0.513,0.898-0.962,3.591-0.128,4.553c0.834,0.962,2.052,1.731,2.309,3.655c0.257,1.924,0.898,3.98,0.898,5.84
      c0,1.859,0.898,4.938-0.064,7.695c-0.962,2.758-3.335,6.286-3.527,7.311c-0.142,0.755,0.204,1.371,0.601,1.9
      c0.269-0.23,0.584-0.399,0.962-0.446C125.442,239.072,125.859,238.608,126.508,238.979z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_80_" x="148.395" y="201.639" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_MALI" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Mali" dataWikipediaLink="https://en.wikipedia.org/wiki/Mali" data-infotext="<b>Mali</b> &amp;nbsp; Capital: Bamako" data-imageFile="MALI.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="MALI" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M300.569,216.503c0,1.483-2.781,1.297-4.449-0.371s0.371-2.039,1.297-3.522c0.927-1.483-1.483-1.297-1.668-2.966
      c-0.185-1.668-1.483-2.039-2.595-2.039s-2.966-0.185-3.151-0.741s-1.854-1.483-3.708-1.483s-1.853-0.185-2.595-1.483
      s-1.298-2.224-2.595-2.224s-2.595-1.854-2.41-2.966s-2.224-2.966-2.224-2.966s-0.018-0.013-0.043-0.03
      c-0.045-0.03-0.133-0.092-0.263-0.181c-0.036-0.024-0.074-0.051-0.116-0.08c-0.204-0.141-0.479-0.33-0.824-0.568
      c-0.124-0.085-0.26-0.179-0.402-0.276c-0.155-0.106-0.323-0.223-0.498-0.343c-0.223-0.153-0.462-0.318-0.717-0.494
      c-0.505-0.348-1.073-0.739-1.694-1.166c-0.226-0.156-0.454-0.313-0.695-0.478c-0.758-0.522-1.589-1.094-2.481-1.708
      c-1.361-0.937-2.867-1.974-4.5-3.097c-0.006-0.004-0.013-0.009-0.019-0.014c-3.648-2.51-7.905-5.439-12.478-8.584
      c-0.619-0.425-1.242-0.854-1.872-1.287c-4.013-2.759-8.232-5.659-12.47-8.57c-0.157-0.108-0.314-0.215-0.471-0.323
      c-0.535-0.368-1.071-0.736-1.607-1.104c-0.049-0.034-0.099-0.068-0.149-0.102c-0.464-0.318-0.927-0.637-1.39-0.955
      c-1.3-0.893-2.598-1.783-3.886-2.668c-0.044-0.03-0.089-0.061-0.134-0.092c-0.073-0.05-0.145-0.099-0.217-0.149
      c-0.456-0.313-0.91-0.624-1.363-0.935c-0.2-0.137-0.399-0.274-0.599-0.411c-0.069-0.048-0.138-0.095-0.207-0.142
      c-0.628-0.431-1.252-0.859-1.873-1.285c-0.19-0.13-0.378-0.26-0.567-0.389c-0.235-0.161-0.466-0.32-0.7-0.48l-14.224,0.365
      c0,0,7.924,73.823,7.924,75.908s0.695,2.92,2.224,4.032c1.529,1.112,0.556,2.085,0.556,3.059c0,0.973,0.417,1.807-1.112,2.641
      s-0.556,2.781-0.556,2.781s-25.164,0-27.527,0s-3.754,0-5.144-1.112s-2.224,0.278-2.781,1.251c-0.556,0.973-4.032,0.695-5.422,0
      s-2.549-0.277-4.356-0.138c-1.807,0.139-1.714,1.436-1.9,2.641c-0.185,1.205-1.251,0.834-1.298-0.047
      c-0.046-0.881-0.51-0.741-0.973-1.112c-0.464-0.371-0.741-0.973-1.205-1.993s-1.9-1.066-3.337-0.556
      c-1.437,0.51-1.344,1.205-1.483,2.688s-0.51,2.224-0.742,3.569c-0.029,0.165-0.078,0.321-0.144,0.467
      c-0.025,0.055-0.063,0.103-0.093,0.155c-0.048,0.085-0.093,0.172-0.152,0.248c-0.054,0.07-0.12,0.128-0.182,0.191
      c-0.048,0.048-0.09,0.1-0.143,0.144c-0.09,0.075-0.189,0.138-0.289,0.2c-0.033,0.02-0.062,0.045-0.096,0.064
      c-0.125,0.07-0.258,0.127-0.394,0.175c-0.011,0.004-0.021,0.01-0.033,0.014c-0.451,0.155-0.941,0.187-1.4,0.081
      c-0.001-0.001-0.002-0.001-0.002-0.001c-0.246,0.868-0.431,1.86-0.084,2.108c0.649,0.463,1.575,1.019,1.575,2.132
      c0,1.113,0.093,2.41-0.185,3.244s-0.649,1.39,0.093,1.946s0.649,1.576,2.039,1.854s1.946,0,2.132,1.112
      c0.185,1.112,0.464,2.039,1.02,2.502s0.556,1.205,0.649,2.317s0.278,2.503-0.278,2.966c-0.321,0.268-0.612,0.445-0.889,0.689
      c0.054,0.056,0.099,0.119,0.131,0.19c0.033,0.071,0.054,0.148,0.063,0.234c0.139,1.39-0.139,2.781,0.834,2.92
      s2.502,0.556,2.781-0.695c0.278-1.251,1.946-1.529,2.085-0.417s-0.417,1.807,0.834,2.085c1.251,0.278,1.946,0,2.224-1.112
      s-0.139-1.668,1.668-1.529s2.085,0.139,2.781,0.973c0.695,0.834,1.112,0.973,2.224,0.834s1.39-2.502,2.224-2.502s1.39,0,1.39-0.834
      s2.224-1.807,2.92-0.139c0.695,1.668,0.209,2.781,1.251,3.198s1.112,2.224,1.112,2.92s-0.348,1.877,1.46,2.016
      c1.807,0.139,1.668,0.834,1.112,1.529s-2.572,2.502-1.321,2.711c1.251,0.209,2.224-0.417,2.433,0.765s-1.251,2.642,0.417,3.267
      c1.668,0.626,0.417,0.904,1.39,2.294c0.01,0.015,0.021,0.028,0.031,0.042c0.299,0.423,0.579,0.746,0.843,0.952l0.001,0.001
      c0.052,0.041,0.104,0.077,0.155,0.109c0.002,0.002,0.004,0.002,0.007,0.004c0.049,0.03,0.098,0.056,0.146,0.077
      c0.003,0.002,0.007,0.002,0.009,0.003c0.047,0.02,0.094,0.036,0.14,0.048c0.005,0.002,0.01,0.002,0.016,0.003
      c0.044,0.01,0.087,0.017,0.131,0.018c0.007,0,0.014-0.001,0.021-0.001c0.041,0.001,0.082-0.002,0.122-0.009
      c0.009-0.002,0.018-0.006,0.027-0.008c0.038-0.009,0.076-0.018,0.113-0.034c0.012-0.005,0.024-0.013,0.036-0.019
      c0.034-0.016,0.069-0.033,0.103-0.055c0.015-0.01,0.03-0.024,0.044-0.035c0.031-0.023,0.061-0.045,0.091-0.073
      c0.019-0.017,0.038-0.04,0.057-0.06c0.026-0.027,0.052-0.052,0.077-0.082c0.024-0.029,0.048-0.065,0.072-0.097
      c0.02-0.028,0.04-0.052,0.06-0.081c0.044-0.065,0.087-0.137,0.13-0.214c0.695-1.251,1.46-1.807,2.294-1.807
      s1.321,0.487,1.321,1.043s0.139,0.973,1.39,0.973s2.92,0.348,2.989-0.765c0.07-1.112,2.085,0.139,2.085-1.182s0-1.46,1.39-1.46
      s2.294-0.07,2.224,1.39c-0.07,1.46-0.417,2.016,0.765,2.016s2.085,0.209,2.363-0.834c0.278-1.043,0.973-1.182,2.433-1.112
      c0.223,0.011,0.436,0.028,0.644,0.049c0.007,0.001,0.013,0.002,0.02,0.003c0.205,0.021,0.399,0.049,0.585,0.082
      c0.053-1.075,0.119-2.544,0.119-3.262c0-1.205,2.502-3.151,2.317-4.82c-0.185-1.668-0.742-3.708-1.298-3.893
      s-0.092-1.575,1.205-1.483s2.41,0.463,2.873-0.371c0.464-0.834,2.966-0.557,3.337-1.761c0.371-1.205,0.185-4.449,1.483-4.727
      c1.298-0.278,1.946-0.834,1.668-2.132s-0.834-2.317,0.464-3.43s0.834-2.224,2.039-1.668s0.741,1.484,1.668,1.576
      c0.927,0.092,1.391,1.205,2.317,1.205c0.927,0,1.761-0.185,1.761-1.575s0-1.946,1.205-1.946s2.039-0.834,2.224-2.132
      c0.185-1.298,0.556-2.41,1.668-2.781s0.463-1.483,1.946-1.39c1.483,0.092,1.483,0.926,2.41,0.926s1.761-0.092,1.761-1.205
      c0-1.112,0.464-1.668,1.576-1.483s1.483,0.185,2.039-0.556s1.02-2.132,2.873-1.761c1.854,0.371,1.205,0.186,2.224-1.205
      s1.576-2.224,3.059-2.224s2.688-0.463,2.966,0.278s0.649,1.112,1.854,1.112c0.14,0,0.292,0.003,0.451,0.007
      c0.028,0.001,0.057,0.002,0.085,0.003c0.765,0.022,1.731,0.079,2.823,0.1c0.28,0.005,0.57,0.007,0.865,0.006
      c0.056,0,0.112,0,0.169-0.001c0.32-0.003,0.647-0.009,0.982-0.023c2.224-0.092,4.449,0.37,5.19-0.834
      c0.742-1.205,2.317-2.688,4.541-2.688s3.893-0.185,6.766-0.185s9.732,0.186,10.659-0.834s3.615-2.132,4.078-4.264
      s0.278-2.503,1.112-3.986c0.714-1.269,0.817-20.132,0.832-25.534c-0.146-0.007-0.298-0.022-0.461-0.047
      C302.422,214.649,300.569,215.02,300.569,216.503z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_79_" x="230.421" y="214.005" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_BURKINAFASO" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Burkina Faso" dataWikipediaLink="https://en.wikipedia.org/wiki/Burkina_Faso" data-infotext="<b>Burkina Faso</b> &amp;nbsp; Capital: Ouagadougou" data-imageFile="BURKINAFASO.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="BURKINAFASO" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M287.778,279.251c-0.649-0.371-0.649-1.761-0.464-2.595s-0.093-1.668-0.649-2.132c-0.556-0.463-1.854-1.483-2.317-0.185
      c-0.464,1.298-0.927,0.834-2.132,0.463s-1.854-0.834-2.781-2.224s-1.854-1.019-2.502-2.039c-0.649-1.02,0.834-1.946,1.39-2.317
      c0.556-0.37-0.093-1.298-1.761-1.854s-1.39-1.483-2.966-2.039c-1.575-0.556-2.224-1.576-2.317-2.595s0-2.688-1.575-3.337
      c-1.575-0.649-0.185-2.039,0.278-3.337c0.174-0.486,0.178-1.088,0.116-1.651c-1.092-0.02-2.06-0.077-2.823-0.1
      c-0.028-0.001-0.057-0.002-0.085-0.003c-0.159-0.004-0.311-0.007-0.451-0.007c-1.205,0-1.576-0.371-1.854-1.112
      s-1.483-0.278-2.966-0.278s-2.039,0.834-3.059,2.224s-0.371,1.575-2.224,1.205c-1.853-0.371-2.317,1.02-2.873,1.761
      s-0.927,0.741-2.039,0.556s-1.576,0.371-1.576,1.483s-0.834,1.205-1.761,1.205s-0.927-0.834-2.41-0.926
      c-1.483-0.092-0.834,1.019-1.946,1.39s-1.483,1.483-1.668,2.781s-1.019,2.132-2.224,2.132s-1.205,0.556-1.205,1.946
      s-0.834,1.575-1.761,1.575s-1.39-1.112-2.317-1.205c-0.927-0.092-0.463-1.02-1.668-1.576s-0.741,0.556-2.039,1.668
      s-0.742,2.132-0.464,3.43s-0.371,1.853-1.668,2.132c-1.298,0.278-1.112,3.522-1.483,4.727s-2.873,0.927-3.337,1.761
      s-1.576,0.463-2.873,0.371s-1.761,1.297-1.205,1.483s1.112,2.224,1.298,3.893c0.185,1.668-2.317,3.615-2.317,4.82
      c0,0.717-0.066,2.186-0.119,3.262c0.292,0.052,0.557,0.12,0.787,0.205c0.039,0.014,0.077,0.029,0.114,0.045
      c0.054,0.022,0.107,0.045,0.156,0.07c0.081,0.041,0.158,0.084,0.226,0.132c0.022,0.015,0.04,0.033,0.061,0.049
      c0.055,0.043,0.104,0.088,0.148,0.136c0.018,0.02,0.035,0.039,0.051,0.06c0.052,0.066,0.097,0.136,0.128,0.213
      c0.278,0.695,1.043,1.112,1.39,1.112s0.973,1.043,1.251,1.529c0.278,0.487,1.043,1.807,1.46,1.807s1.251,0.904,1.877,0.904
      c0.626,0,0.834,0.417,1.599,1.182s1.946,0.973,2.224-0.417c0.278-1.39,1.043-1.807,1.877-1.946s1.738-0.556,2.433-1.182
      c0.695-0.626,1.807-0.626,2.294,0.417s0.834,1.112,1.877,1.182s1.668,0.139,1.877,0.765c0.026,0.078,0.064,0.168,0.111,0.268
      c0.333,0.695,1.145,1.817,1.753,1.951c0.327-0.48,0.639-1.2,0.639-2.219c0-2.155,0.695-4.588-0.487-5.213
      c-1.182-0.626-0.904-0.904-0.834-2.224c0.07-1.321-1.043-4.171,1.043-4.032c2.085,0.139,9.94,0.139,12.026,0.07
      s5.492-0.209,6.604,0.695c1.112,0.904,1.39,0.834,2.016-0.209c0.626-1.043,2.085-1.807,2.92-1.807c0.069,0,0.133,0.013,0.194,0.03
      c0.016,0.005,0.032,0.01,0.047,0.015c0.055,0.02,0.107,0.044,0.157,0.075c0.004,0.003,0.009,0.005,0.014,0.008
      c0.053,0.034,0.104,0.075,0.154,0.119c0.017,0.015,0.034,0.03,0.051,0.046c0.035,0.034,0.071,0.068,0.105,0.105
      c0.176,0.186,0.349,0.404,0.56,0.59c0.005,0.004,0.01,0.009,0.014,0.013c0.065,0.056,0.133,0.109,0.206,0.157l0.001,0.001
      c0.051,0.033,0.104,0.063,0.16,0.09c0.003,0.002,0.006,0.003,0.009,0.005c0.051,0.024,0.106,0.045,0.162,0.064
      c0.008,0.003,0.016,0.007,0.025,0.009c0.053,0.016,0.11,0.028,0.167,0.039c0.014,0.002,0.026,0.007,0.039,0.008
      c0.072,0.011,0.147,0.017,0.228,0.017c1.251,0,2.224,0,4.171,0.07c0.268,0.01,0.52,0.029,0.761,0.051
      c0.036,0.003,0.068,0.007,0.103,0.01c0.217,0.021,0.424,0.043,0.623,0.064c0.215,0.023,0.419,0.042,0.617,0.054
      c0.011,0.001,0.022,0.002,0.033,0.002c0.203,0.011,0.399,0.014,0.59,0.002c0.138-0.009,0.273-0.028,0.407-0.055
      c0.016-0.003,0.032-0.006,0.048-0.009c0.128-0.028,0.254-0.067,0.379-0.116c0.022-0.009,0.044-0.017,0.065-0.027
      c0.125-0.053,0.249-0.116,0.374-0.194c0.018-0.011,0.037-0.025,0.055-0.037c0.131-0.086,0.261-0.182,0.393-0.301
      c1.321-1.182,1.668-3.267,3.893-3.267c2.224,0,3.962,0.626,4.866-0.348c0.904-0.973,2.433-1.46,2.502-2.572
      c0.005-0.087,0.005-0.178,0.004-0.269c-0.001-0.027-0.002-0.055-0.003-0.083c-0.003-0.068-0.007-0.138-0.012-0.208
      c-0.002-0.027-0.004-0.054-0.006-0.081c-0.006-0.073-0.013-0.146-0.021-0.22c-0.034-0.338-0.075-0.684-0.074-1.019
      c0-0.006,0-0.012,0-0.019c0.001-0.099,0.006-0.196,0.016-0.292c0-0.004,0.001-0.008,0.001-0.012c0.011-0.101,0.028-0.2,0.052-0.297
      l0,0C288.378,279.579,288.02,279.389,287.778,279.251z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_78_" x="225.959" y="264.544" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_SENEGAL" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Senegal" dataWikipediaLink="https://en.wikipedia.org/wiki/Senegal" data-infotext="<b>Senegal</b> &amp;nbsp; Capital: Dakar" data-imageFile="SENEGAL.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="SENEGAL" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M167.196,272.763c-0.093-1.112-0.093-1.853-0.649-2.317c-0.556-0.463-0.834-1.39-1.02-2.502c-0.185-1.112-0.742-0.834-2.132-1.112
      s-1.297-1.298-2.039-1.854s-0.371-1.112-0.093-1.946s0.185-2.132,0.185-3.244s-0.927-1.668-1.575-2.132
      c-0.347-0.248-0.163-1.24,0.084-2.108c-0.043-0.01-0.088-0.011-0.13-0.024c-1.251-0.371-1.622-1.853-2.224-2.734
      c-0.603-0.88-1.576-0.834-2.641-1.251c-1.066-0.417-3.383-4.171-4.078-5.33s-2.132-2.966-2.595-3.661
      c-0.464-0.695-1.298-0.788-2.641-0.788s-2.41-0.926-2.734-1.9c-0.324-0.973-1.437-1.205-3.059-1.761
      c-1.622-0.556-3.522-0.788-4.449-0.51s-1.9,0.464-3.059,0.556c-1.158,0.092-2.688,0.186-3.244,0.695s-1.946,0.51-2.595,0.139
      c-0.649-0.371-1.066,0.093-2.92,0.325c-0.378,0.047-0.692,0.217-0.962,0.446c0.142,0.189,0.29,0.368,0.425,0.537
      c-0.135-0.169-0.283-0.348-0.425-0.537c-0.025,0.021-0.051,0.039-0.075,0.061c0.019,0.745-0.043,1.596-0.321,2.704
      c-0.897,3.587-3.288,6.725-4.932,8.669c-1.644,1.943-0.299,5.679,1.644,7.024s0,3.288,0.897,5.231s0-0.149,0.15,2.092
      c0.002,0.039,0,0.071,0.002,0.109c0.94-0.222,2.088-0.478,2.669-0.478c0.742,0,1.529,0.648,2.734,0.741s2.688-0.232,3.151-0.834
      c0.464-0.602,1.112-0.371,2.178-0.371s1.854-0.185,2.085-0.88c0.232-0.695,1.529-0.278,2.734-0.417
      c1.205-0.139,2.271,1.112,3.059,1.993c0.788,0.88,0.881,0.927,1.993,1.251s2.317,0,2.92-0.231s1.02-0.556,1.576-0.139
      s0.556,1.065,0.185,1.344c-0.371,0.278-1.205,0.556-2.363,0.556s-0.927,0.834-1.668,0.788c-0.742-0.046-1.668-0.463-2.178-1.019
      s-1.112-0.834-1.946-0.927s-1.251-0.139-1.715-0.556c-0.464-0.417-1.251-0.602-1.622,0.139c-0.371,0.741-2.224,1.251-3.383,1.297
      s-1.483,0.139-2.085,0.649s-1.853,0.695-3.383,0.649c-1.529-0.046-2.317,0.324-3.708,0.417c-0.649,0.043-1.18,0.339-1.569,0.642
      c0.011,0.119,0.016,0.231,0.035,0.359c0.235,1.527-0.079,4.063,0.717,5.299c0.876-0.083,2.707-0.23,3.366-0.091
      c0.881,0.186,1.9-0.139,3.337,0.093s3.197,0.046,3.846-0.834s2.502-1.483,3.615-1.483c1.112,0,3.105,0.371,5.376,0.371
      c2.271,0,4.773-0.324,5.793-0.324c0.768,0,1.063-0.131,1.459,0.102c0.186-0.18,0.484-0.224,0.963-0.178
      c0.018,0.002,0.039,0.005,0.057,0.007c0.064,0.007,0.128,0.014,0.198,0.023c0.091,0.013,0.187,0.028,0.289,0.045
      c1.668,0.278,3.337,0.556,4.31,1.668c0.973,1.112,1.529,1.112,2.92,1.112c1.39,0,1.39,0.973,2.224,1.112s1.39-1.39,2.92-1.39
      c0.091,0,0.19-0.003,0.293-0.007c0.034-0.002,0.072-0.004,0.107-0.005c0.073-0.004,0.147-0.008,0.225-0.013
      c0.042-0.003,0.086-0.006,0.129-0.009c0.081-0.005,0.163-0.011,0.248-0.018c0.041-0.003,0.082-0.007,0.123-0.01
      c0.129-0.01,0.26-0.021,0.395-0.031c0.043-0.003,0.085-0.007,0.128-0.01c0.086-0.007,0.172-0.013,0.259-0.02
      c0.053-0.004,0.106-0.007,0.159-0.01c0.078-0.005,0.157-0.01,0.235-0.015c0.058-0.003,0.116-0.006,0.174-0.008
      c0.127-0.006,0.251-0.01,0.375-0.013c0.069-0.002,0.139-0.003,0.206-0.004c0.062,0,0.123,0.002,0.184,0.003
      c0.063,0.001,0.127,0.002,0.188,0.004c0.061,0.002,0.119,0.007,0.178,0.011c0.059,0.004,0.118,0.008,0.175,0.014
      c0.057,0.005,0.112,0.013,0.166,0.02c0.055,0.008,0.107,0.016,0.16,0.025c0.052,0.009,0.103,0.02,0.152,0.031
      c0.049,0.012,0.096,0.026,0.142,0.039c0.045,0.014,0.09,0.027,0.133,0.043c0.044,0.017,0.084,0.037,0.124,0.056
      c0.036,0.018,0.075,0.035,0.108,0.055c0.042,0.026,0.077,0.056,0.112,0.086c0.023,0.019,0.051,0.036,0.071,0.057
      c0.277-0.243,0.568-0.421,0.889-0.689C167.474,275.266,167.289,273.875,167.196,272.763z" pointerEvents="auto" className="landarea"></path>

      <rect id="recti12_3_" x="102.264" y="233.634" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_THEGAMBIA" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="The Gambia" dataWikipediaLink="https://en.wikipedia.org/wiki/The_Gambia" data-infotext="<b>The Gambia</b> &amp;nbsp; Capital: Banjul" data-imageFile="GAMBIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="THEGAMBIA" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M124.944,269.26c1.534,0.046,2.788-0.139,3.393-0.651c0.604-0.511,0.929-0.605,2.091-0.651s3.021-0.558,3.393-1.301
      c0.372-0.743,1.162-0.558,1.627-0.139c0.465,0.418,0.883,0.465,1.72,0.558s1.441,0.372,1.952,0.93s1.441,0.976,2.184,1.022
      c0.743,0.046,0.511-0.79,1.673-0.79s1.998-0.279,2.37-0.558s0.372-0.929-0.186-1.347s-0.976-0.093-1.58,0.139
      s-1.813,0.557-2.928,0.232c-1.115-0.325-1.209-0.372-1.999-1.255s-1.859-2.138-3.068-1.998s-2.51-0.279-2.742,0.418
      c-0.232,0.697-1.023,0.883-2.091,0.883s-1.719-0.232-2.184,0.372c-0.465,0.604-1.952,0.93-3.161,0.837
      c-1.208-0.093-1.998-0.743-2.742-0.743c-0.583,0-1.735,0.257-2.677,0.479c0.109,2.019-0.494,2.957-0.337,4.627
      c0.39-0.304,0.923-0.601,1.574-0.644C122.62,269.585,123.411,269.213,124.944,269.26z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_77_" x="115.554" y="253.626" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="21.446" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
   <circle fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeMiterlimit="10" cx="120.407" cy="263.677" r="8.13" pointerEvents="auto" opacity="0.001" className="clickarea landarea"></circle>

      <ellipse transform="matrix(0.3473 -0.9377 0.9377 0.3473 -161.4656 297.7685)" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeMiterlimit="9.9993" cx="133.186" cy="264.882" rx="5.773" ry="5.773" pointerEvents="auto" opacity="0.001" className="clickarea landarea"></ellipse>
   <path id="SHOWBEFORE" opacity="0.06" stroke="#FFFFFF" strokeMiterlimit="10" enable-background="new    " d="M126.912,269.527
      l-1.374-3.124l-44.777,19.691c-3.342-5.311-9.239-8.851-15.977-8.851c-10.428,0-18.882,8.454-18.882,18.882
      c0,10.428,8.454,18.882,18.882,18.882s18.882-8.454,18.882-18.882c0-2.47-0.488-4.823-1.351-6.985L126.912,269.527z" pointerEvents="auto" className="showbefore"></path>
</g>
<g id="AREA_GUINEA" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Guinea" dataWikipediaLink="https://en.wikipedia.org/wiki/Guinea" data-infotext="<b>Guinea</b> &amp;nbsp; Capital: Conakry" data-imageFile="GUINEA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="GUINEA" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M152.274,305.48c0.742-1.112,0.649-1.483,1.946-2.039c1.298-0.556,2.039-1.761,2.317-3.244s0.742-1.946,2.224-1.483
      s2.317,0.464,3.151-0.185s2.039-0.927,2.966-0.927s3.151-0.371,3.615,0.556c0.463,0.927,2.502,1.483,2.688,2.502
      c0.185,1.019,0.556,2.781,1.576,3.429s1.391,1.668,1.576,2.317s0.092,3.985,0.463,4.449c0.043,0.054,0.106,0.119,0.181,0.192
      c0.079,0.078,0.172,0.167,0.272,0.266c0.4-0.33,0.9-0.573,1.401-0.365c1.112,0.463,1.576,1.205,2.502,0.649
      c0.927-0.556,2.039-1.205,3.059-0.556c1.02,0.648,1.854,1.39,1.668,2.595c-0.185,1.205-0.649,2.132,0.185,2.688
      s0.834,1.112,0.741,1.946s-2.039,3.522-0.463,3.244c1.575-0.278,2.224,2.41,2.781,1.483s2.873-0.557,3.059-2.132
      c0.185-1.575,0.278-3.244,1.575-2.132c0.08,0.068,0.152,0.137,0.217,0.204c0.017,0.017,0.03,0.034,0.046,0.051
      c0.046,0.05,0.092,0.101,0.132,0.15c0.012,0.016,0.022,0.03,0.034,0.046c0.039,0.051,0.077,0.102,0.11,0.152
      c0.006,0.01,0.011,0.019,0.017,0.028c0.501,0.787,0.267,1.382,1.08,1.244c0.041-0.007,0.079-0.012,0.126-0.023
      c1.205-0.278,0.927-1.946,2.039-1.946s0.185-1.39,0.742-1.668c0.557-0.278,1.483-0.092,1.39-1.297s-1.483-0.278-2.224-1.668
      c-0.742-1.39-2.039-2.873,0.093-2.41s1.853-0.741,2.873,0.556s1.576,0.741,1.576-0.093c0-0.834,0.278-1.668-0.742-2.132
      c-1.019-0.464-0.556-1.205-1.575-1.39c-1.019-0.185-1.205-1.39-0.185-1.668c1.02-0.278,1.02-0.927,1.02-1.761
      c0-0.834,0.278-2.224-1.02-2.224s-2.688-0.927-2.41-1.761s0.464-1.112,0.464-2.41c0-1.124-0.067-2.387,0.518-3.549
      c-0.01-0.015-0.021-0.027-0.031-0.042c-0.973-1.39,0.278-1.668-1.39-2.294s-0.209-2.085-0.417-3.267
      c-0.208-1.182-1.182-0.556-2.433-0.765c-1.251-0.209,0.765-2.016,1.321-2.711s0.695-1.39-1.112-1.529s-1.46-1.321-1.46-2.016
      s-0.07-2.502-1.112-2.92s-0.556-1.529-1.251-3.198c-0.695-1.668-2.92-0.695-2.92,0.139s-0.556,0.834-1.39,0.834
      s-1.112,2.363-2.224,2.502s-1.529,0-2.224-0.834c-0.695-0.834-0.973-0.834-2.781-0.973c-1.807-0.139-1.39,0.417-1.668,1.529
      s-0.973,1.39-2.224,1.112c-1.251-0.278-0.695-0.973-0.834-2.085s-1.807-0.834-2.085,0.417s-1.807,0.834-2.781,0.695
      c-0.973-0.139-0.695-1.529-0.834-2.92c-0.009-0.086-0.03-0.163-0.063-0.234c-0.033-0.07-0.077-0.133-0.131-0.19
      c-0.02-0.021-0.048-0.038-0.071-0.057c-0.036-0.03-0.07-0.061-0.112-0.086c-0.033-0.02-0.071-0.037-0.108-0.055
      c-0.04-0.02-0.08-0.04-0.124-0.056c-0.042-0.016-0.087-0.03-0.133-0.043c-0.046-0.014-0.093-0.027-0.142-0.039
      c-0.049-0.011-0.1-0.022-0.152-0.031c-0.052-0.009-0.105-0.018-0.16-0.025c-0.055-0.008-0.11-0.015-0.166-0.02
      c-0.057-0.005-0.116-0.01-0.175-0.014s-0.117-0.009-0.178-0.011c-0.062-0.003-0.125-0.003-0.188-0.004
      c-0.061-0.001-0.121-0.003-0.184-0.003c-0.068,0.001-0.137,0.002-0.206,0.004c-0.124,0.003-0.249,0.007-0.375,0.013
      c-0.058,0.003-0.116,0.004-0.174,0.008c-0.078,0.004-0.157,0.009-0.235,0.015c-0.053,0.003-0.106,0.007-0.159,0.01
      c-0.087,0.007-0.173,0.013-0.259,0.02c-0.043,0.003-0.086,0.007-0.128,0.01c-0.134,0.011-0.266,0.021-0.395,0.031
      c-0.041,0.003-0.083,0.007-0.123,0.01c-0.085,0.007-0.167,0.012-0.248,0.018c-0.043,0.003-0.087,0.007-0.129,0.009
      c-0.078,0.005-0.152,0.009-0.225,0.013c-0.036,0.002-0.073,0.004-0.107,0.005c-0.103,0.004-0.202,0.007-0.293,0.007
      c-1.529,0-2.085,1.529-2.92,1.39c-0.834-0.139-0.834-1.112-2.224-1.112s-1.946,0-2.92-1.112c-0.973-1.112-2.641-1.39-4.31-1.668
      c-0.102-0.017-0.198-0.031-0.289-0.045c-0.07-0.01-0.134-0.017-0.198-0.023c-0.018-0.002-0.039-0.005-0.057-0.007
      c-0.479-0.046-0.777-0.002-0.963,0.178l0,0c-0.262,0.253-0.301,0.776-0.301,1.705c0,1.807-0.695,1.946-1.946,2.781
      c-1.251,0.834,0.695,1.251,1.39,2.363c0.695,1.112-0.556,2.085-1.807,2.085s-2.085,0.556-4.171,0.834
      c-2.085,0.278-0.556,1.39-2.92,2.224c-1.039,0.367-1.621,1.243-1.947,2.111c1.113,0.48,2.098,1.089,2.277,2.342
      c0.299,2.092,1.345,4.035,3.438,5.082c2.092,1.046,5.081,2.092,6.576,4.334c1.139,1.708,1.757,3.766,2.384,6.566
      C150.963,306.187,151.885,306.064,152.274,305.48z" pointerEvents="auto" className="landarea"></path>

      <rect id="recti12_2_" x="159.677" y="277.684" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="44.56" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_GUINEABISSAU" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Guinea-Bissau" dataWikipediaLink="https://en.wikipedia.org/wiki/Guinea-Bissau" data-infotext="<b>Guinea-Bissau</b> &amp;nbsp; Capital: Bissau" data-imageFile="GUINEABISSAU.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
   <path id="GUINEABISSAU" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeMiterlimit="10" d="M145.74,273.644
      c-1.02,0-3.522,0.324-5.793,0.324s-4.264-0.371-5.376-0.371c-1.112,0-2.966,0.603-3.615,1.483s-2.41,1.066-3.846,0.834
      c-1.437-0.232-2.456,0.093-3.337-0.093c-0.66-0.139-2.49,0.008-3.366,0.091c0.217,0.337,0.511,0.583,0.927,0.679
      c1.943,0.448,0.299,3.138,2.69,3.138s6.875-0.449,7.323,2.242c0.448,2.69,0.299,4.035,1.943,4.932
      c0.659,0.36,1.462,0.624,2.207,0.945c0.326-0.868,0.908-1.744,1.947-2.111c2.363-0.834,0.834-1.946,2.92-2.224
      c2.085-0.278,2.92-0.834,4.171-0.834s2.502-0.973,1.807-2.085c-0.695-1.112-2.641-1.529-1.39-2.363s1.946-0.973,1.946-2.781
      c0-0.929,0.039-1.452,0.301-1.705C146.803,273.513,146.508,273.644,145.74,273.644z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_76_" x="92.64" y="274.084" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="41.311" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_LIBERIA" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Liberia" dataWikipediaLink="https://en.wikipedia.org/wiki/Liberia" data-infotext="<b>Liberia</b> &amp;nbsp; Capital: Monrovia" data-imageFile="LIBERIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
   <path id="LIBERIA" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeMiterlimit="10" d="M201.86,340.7
      c1.205-0.463,0.556-3.615,0.556-4.356s-0.742-1.483-2.039-1.761c-1.297-0.278-1.854-1.205-2.039-2.132
      c-0.185-0.926-1.483-0.834-3.059-1.02s-0.649-1.39-2.595-1.575s-1.298-1.483-0.093-1.853s1.112-1.668,1.576-2.502
      s0.649-1.854-0.278-2.595c-0.414-0.331-0.513-1.312-0.496-2.294c-0.812,0.138-0.58-0.455-1.08-1.244
      c-0.006-0.009-0.011-0.018-0.017-0.028c-0.033-0.05-0.071-0.101-0.11-0.152c-0.012-0.016-0.021-0.03-0.034-0.046
      c-0.039-0.049-0.086-0.1-0.132-0.15c-0.016-0.017-0.029-0.034-0.046-0.051c-0.065-0.067-0.137-0.136-0.217-0.204
      c-1.298-1.112-1.39,0.556-1.575,2.132c-0.185,1.575-2.502,1.205-3.059,2.132s-1.205-1.761-2.781-1.483
      c-1.575,0.278,0.371-2.41,0.463-3.244c0.093-0.834,0.093-1.39-0.741-1.946s-0.371-1.483-0.185-2.688
      c0.185-1.205-0.649-1.946-1.668-2.595c-1.02-0.648-2.132,0-3.059,0.556s-1.39-0.186-2.502-0.649
      c-0.501-0.209-1.001,0.035-1.401,0.365c0.024,0.024,0.049,0.049,0.073,0.074c0.023,0.022,0.045,0.045,0.068,0.068
      c0.022,0.023,0.044,0.047,0.067,0.07c0.031,0.033,0.061,0.066,0.092,0.1c0.017,0.019,0.034,0.037,0.05,0.056
      c0.047,0.054,0.093,0.109,0.139,0.166c0.011,0.014,0.022,0.028,0.033,0.042c0.036,0.046,0.071,0.093,0.105,0.142
      c0.013,0.019,0.027,0.039,0.04,0.058c0.033,0.049,0.065,0.099,0.095,0.151c0.009,0.016,0.019,0.031,0.029,0.048
      c0.038,0.068,0.074,0.137,0.106,0.208c0.005,0.01,0.008,0.021,0.013,0.031c0.026,0.061,0.049,0.123,0.07,0.186
      c0.007,0.022,0.013,0.043,0.019,0.065c0.016,0.056,0.029,0.115,0.04,0.173c0.004,0.021,0.008,0.041,0.011,0.061
      c0.011,0.078,0.019,0.158,0.019,0.239c0,0.007-0.001,0.014-0.001,0.021c-0.001,0.073-0.007,0.148-0.019,0.224
      c-0.004,0.025-0.009,0.05-0.014,0.076c-0.012,0.061-0.028,0.124-0.047,0.187c-0.008,0.026-0.016,0.052-0.025,0.078
      c-0.031,0.086-0.068,0.174-0.115,0.263c-0.834,1.575-2.595,2.41-2.781,3.337c-0.185,0.926-1.576,1.761-2.41,2.224
      s-1.761,1.946-2.224,2.688c-0.372,0.595-1.521,1.133-2.295,2.473c1.963,1.348,3.871,3.202,6.811,5.722
      c4.185,3.587,8.818,7.024,11.657,9.416c2.84,2.391,7.772,4.334,10.761,6.127c1.546,0.928,3.373,1.452,5.169,1.538
      c-0.069-1.546-0.19-3.876-0.312-4.422C200.285,342.369,200.655,341.164,201.86,340.7z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_2_" x="160.244" y="335.182" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="46.1" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_THEIVORYCOAST" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Ivory Coast" dataWikipediaLink="https://en.wikipedia.org/wiki/Ivory_Coast" data-infotext="<b>Ivory Coast</b> (also known as Côte d'Ivoire) &amp;nbsp; Capital: Yamoussoukro" data-imageFile="IVORY.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="THEIVORYCOAST" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M242.757,340.306c1.112-0.209,1.043-1.251,1.043-2.085s-1.39-1.043-2.294-1.043s-0.556-1.529-0.556-2.155s-0.07-1.46-0.973-2.155
      s-0.278-2.155-0.834-2.781s-0.07-2.224-0.07-2.989s1.599-2.294,1.599-2.85s0.139-1.668,0.765-2.294
      c0.626-0.626,0.765-2.016,0.834-3.406s1.807-3.337,2.781-4.032c0.973-0.695,0.765-2.363,0.765-3.406s-0.626-1.321-1.321-1.738
      c-0.695-0.417,0.07-2.155-0.487-2.572c-0.556-0.417-0.487-1.39-0.556-2.363c-0.07-0.973-0.07-1.807-0.765-1.946
      c-0.609-0.122-1.43-1.256-1.766-1.957c-0.048-0.099-0.085-0.19-0.111-0.268c-0.209-0.626-0.834-0.695-1.877-0.765
      s-1.39-0.139-1.877-1.182s-1.599-1.043-2.294-0.417s-1.599,1.043-2.433,1.182s-1.599,0.556-1.877,1.946s-1.46,1.182-2.224,0.417
      c-0.765-0.765-0.973-1.182-1.599-1.182c-0.626,0-1.46-0.904-1.877-0.904s-1.182-1.321-1.46-1.807
      c-0.278-0.487-0.904-1.529-1.251-1.529s-1.112-0.417-1.39-1.112c-0.031-0.077-0.077-0.147-0.128-0.213
      c-0.016-0.021-0.033-0.04-0.051-0.06c-0.043-0.048-0.093-0.093-0.148-0.136c-0.021-0.016-0.038-0.034-0.061-0.049
      c-0.068-0.048-0.145-0.091-0.226-0.132c-0.049-0.024-0.102-0.048-0.156-0.07c-0.037-0.016-0.075-0.03-0.114-0.045
      c-0.231-0.085-0.495-0.154-0.787-0.205c-0.186-0.033-0.381-0.06-0.585-0.082c-0.007-0.001-0.013-0.002-0.02-0.003
      c-0.206-0.022-0.421-0.038-0.644-0.049c-1.46-0.07-2.155,0.07-2.433,1.112c-0.278,1.043-1.182,0.834-2.363,0.834
      c-1.182,0-0.834-0.556-0.765-2.016s-0.834-1.39-2.224-1.39s-1.39,0.139-1.39,1.46c0,1.321-2.016,0.07-2.085,1.182
      c-0.07,1.112-1.738,0.765-2.989,0.765s-1.39-0.417-1.39-0.973s-0.487-1.043-1.321-1.043s-1.599,0.556-2.294,1.807
      c-0.043,0.078-0.087,0.149-0.13,0.214c-0.02,0.03-0.04,0.054-0.06,0.081c-0.024,0.033-0.048,0.068-0.072,0.097
      c-0.026,0.03-0.051,0.055-0.077,0.082c-0.019,0.02-0.038,0.043-0.057,0.06c-0.03,0.028-0.061,0.05-0.091,0.073
      c-0.015,0.011-0.029,0.025-0.044,0.035c-0.034,0.022-0.068,0.039-0.103,0.055c-0.012,0.006-0.024,0.014-0.036,0.019
      c-0.037,0.016-0.075,0.026-0.113,0.034c-0.009,0.002-0.018,0.006-0.027,0.008c-0.04,0.008-0.081,0.009-0.122,0.009
      c-0.007,0-0.014,0.002-0.021,0.001c-0.043-0.002-0.087-0.009-0.131-0.018c-0.005-0.001-0.01-0.001-0.016-0.003
      c-0.046-0.011-0.093-0.028-0.14-0.048c-0.003-0.002-0.006-0.002-0.009-0.003c-0.048-0.021-0.097-0.047-0.146-0.077
      c-0.002-0.002-0.004-0.003-0.007-0.004c-0.051-0.032-0.103-0.068-0.155-0.109l-0.001-0.001c-0.264-0.206-0.544-0.529-0.843-0.952
      c-0.585,1.162-0.518,2.425-0.518,3.549c0,1.297-0.185,1.575-0.464,2.41s1.112,1.761,2.41,1.761s1.02,1.39,1.02,2.224
      c0,0.834,0,1.483-1.02,1.761s-0.834,1.483,0.185,1.668s0.556,0.926,1.575,1.39s0.742,1.297,0.742,2.132
      c0,0.834-0.556,1.39-1.576,0.093c-1.02-1.297-0.741-0.093-2.873-0.556s-0.834,1.019-0.093,2.41c0.742,1.39,2.132,0.463,2.224,1.668
      s-0.834,1.019-1.39,1.297s0.371,1.668-0.742,1.668c-1.112,0-0.834,1.668-2.039,1.946c-0.046,0.011-0.085,0.016-0.126,0.023
      c-0.017,0.982,0.082,1.963,0.496,2.294c0.927,0.741,0.742,1.761,0.278,2.595s-0.371,2.132-1.576,2.502
      c-1.205,0.37-1.854,1.668,0.093,1.853s1.019,1.39,2.595,1.575c1.576,0.186,2.873,0.093,3.059,1.02
      c0.185,0.926,0.741,1.854,2.039,2.132s2.039,1.02,2.039,1.761s0.649,3.893-0.556,4.356c-1.205,0.463-1.575,1.668-1.39,2.502
      c0.121,0.546,0.242,2.876,0.312,4.422c1.675,0.081,3.325-0.219,4.695-0.94c2.839-1.495,4.484-2.242,7.473-3.288
      c2.989-1.046,4.782-2.69,10.013-2.989s8.37-1.495,11.359-0.149c2.47,1.112,4.736,0.594,7.135,1.222
      C241.636,340.658,241.922,340.462,242.757,340.306z" pointerEvents="auto" className="landarea"></path>

      <rect id="C9" x="190.319" y="317.977" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_GHANA" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Ghana" dataWikipediaLink="https://en.wikipedia.org/wiki/Ghana" data-infotext="<b>Ghana</b> &amp;nbsp; Capital: Accra" data-imageFile="GHANA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="GHANA" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M273.463,328.789c-0.927-1.205-2.317-3.522-1.019-5.098c1.298-1.575-0.464-2.039,0.278-3.429s0.278-3.8,0.278-4.819
      s-0.834-3.615-1.576-4.635c-0.742-1.02-3.429-3.522-3.429-4.263s0.741-2.41,1.946-3.059c1.205-0.648,0.741-3.059,0.741-3.986
      s0.742-0.927,1.298-1.668s-0.834-2.039-1.39-2.873c-0.556-0.834-1.668-1.204-3.059-1.946c-1.39-0.742-1.02-2.502-0.185-3.244
      c0.311-0.277,0.546-0.696,0.716-1.113l-0.001-0.001c-0.073-0.048-0.141-0.101-0.206-0.157c-0.005-0.004-0.01-0.008-0.014-0.013
      c-0.211-0.186-0.385-0.404-0.56-0.59c-0.035-0.037-0.07-0.072-0.105-0.105c-0.017-0.016-0.034-0.031-0.051-0.046
      c-0.05-0.044-0.101-0.085-0.154-0.119c-0.004-0.003-0.009-0.005-0.014-0.008c-0.05-0.031-0.103-0.055-0.158-0.075
      c-0.016-0.005-0.031-0.01-0.047-0.015c-0.062-0.017-0.125-0.03-0.194-0.03c-0.834,0-2.294,0.765-2.92,1.807
      c-0.626,1.043-0.904,1.112-2.016,0.209c-1.112-0.904-4.518-0.765-6.604-0.695c-2.085,0.07-9.94,0.07-12.026-0.069
      c-2.085-0.139-0.973,2.711-1.043,4.032c-0.07,1.321-0.348,1.599,0.834,2.224s0.487,3.059,0.487,5.213
      c0,1.019-0.311,1.739-0.639,2.219c0.004,0.001,0.009,0.004,0.013,0.005c0.695,0.139,0.695,0.973,0.765,1.946
      c0.07,0.973,0,1.946,0.556,2.363s-0.209,2.155,0.487,2.572c0.695,0.417,1.321,0.695,1.321,1.738s0.209,2.711-0.765,3.406
      c-0.973,0.695-2.711,2.641-2.78,4.032c-0.07,1.39-0.209,2.78-0.834,3.406s-0.765,1.738-0.765,2.294s-1.599,2.085-1.599,2.85
      s-0.487,2.363,0.07,2.989c0.556,0.626-0.07,2.085,0.834,2.78s0.973,1.529,0.973,2.155s-0.348,2.155,0.556,2.155
      s2.294,0.209,2.294,1.043c0,0.834,0.07,1.877-1.043,2.085c-0.835,0.156-1.121,0.352-1.3,1.174c0.504,0.133,1.014,0.312,1.533,0.571
      c2.989,1.495,7.622,3.139,8.818,1.196s3.437-0.747,6.127-2.092c2.69-1.345,6.277-1.046,7.323-2.391s4.633-2.391,6.875-2.69
      s3.437-0.149,4.185-1.644c0.247-0.494,0.823-0.936,1.576-1.316C276.456,330.623,274.279,329.848,273.463,328.789z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_74_" x="230.421" y="333.769" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="20.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_NIGERIA" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Nigeria" dataWikipediaLink="https://en.wikipedia.org/wiki/Nigeria" data-infotext="<b>Nigeria</b> &amp;nbsp; Capital: Abuja" data-imageFile="NIGERIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="NIGER" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M343.968,343.504c1.46-0.834,2.433-2.363,2.433-4.032c0-1.668-0.417-4.449,0.695-5.144s2.433-2.92,3.754-3.337
      c1.321-0.417,2.085-1.043,2.989-2.363s1.807-3.476,3.476-3.059c1.668,0.417,2.363,0.973,2.989,0.417s0.834-2.363,1.946-1.738
      s1.877,1.182,2.711,1.668c0.834,0.487,0.973,1.182,0.904,1.807c-0.07,0.626-0.556,2.155,0.695,2.363
      c1.251,0.209,2.572,0.209,3.128-1.46c0.556-1.668,0.973-3.198,2.572-4.101c1.599-0.904,0.417-2.363,2.016-4.588
      c1.599-2.224,2.155-4.032,2.294-5.492s0.348-2.224,1.529-3.267c1.182-1.043,3.128-3.059,3.128-4.518s0.348-3.893,2.155-4.24
      s1.807-0.626,2.294-2.502c0.487-1.877,1.251-2.085,1.529-3.962c0.278-1.877,0.834-4.101,1.599-5.353
      c0.765-1.251,1.738-3.476,3.893-3.823s4.379-1.668,4.379-2.781s0.765-5.561-0.765-6.187c-1.529-0.626-2.502,0.07-2.781-1.529
      c-0.278-1.599-1.043-2.363-0.904-3.545c0.02-0.166,0.034-0.344,0.042-0.526c0.002-0.039,0.002-0.079,0.003-0.118
      c0.005-0.154,0.006-0.312,0-0.472c-0.001-0.037-0.003-0.073-0.005-0.11c-0.009-0.174-0.024-0.351-0.048-0.528
      c-0.004-0.025-0.007-0.05-0.01-0.075c-0.03-0.202-0.071-0.405-0.125-0.606c-0.001-0.003-0.002-0.006-0.002-0.009
      c-0.028-0.103-0.064-0.204-0.099-0.305c-0.021-0.061-0.042-0.122-0.067-0.182c-0.033-0.083-0.07-0.165-0.108-0.245
      c-0.041-0.087-0.087-0.172-0.136-0.257c-0.034-0.059-0.066-0.119-0.104-0.177c-0.094-0.145-0.196-0.287-0.314-0.42
      c-0.766-0.868-2.991-3.27-4.12-4.486c-0.284,0.412-0.676,0.759-1.163,1.149c-1.043,0.834-1.599,1.043-4.101,1.529
      c-2.502,0.487-2.781,1.46-3.684,2.92c-0.904,1.46-2.502,0.348-3.893,0.07c-1.39-0.278-2.224-0.626-2.989-1.668
      c-0.765-1.043-8.898-0.278-11.053-0.209s-2.711,1.112-3.684,1.946s-2.572,1.46-3.406,2.363c-0.834,0.904-3.545,0.695-5.839,0.765
      c-2.294,0.069-2.92-1.182-3.893-1.877s-2.85-1.946-4.31-2.92c-1.46-0.973-2.224-0.07-3.337,0.487
      c-1.112,0.556-1.251,1.46-2.363,1.529s-1.807-0.348-2.642,0.348c-0.834,0.695-1.668,0.765-2.433,0.139s-1.46-2.363-2.363-3.476
      c-0.904-1.112-2.224-1.46-3.476-1.46c-1.251,0-1.043-0.904-1.807-0.904c-0.765,0-1.112-0.209-1.807-0.834s-0.973-0.765-2.433-0.765
      c-1.46,0-1.39,0.139-2.433,0.834s-2.711,0.209-4.796,0.209s-2.642,1.043-4.24,1.46c-1.599,0.417-1.321,2.433-1.46,4.935
      c-0.139,2.502-0.348,2.711-1.877,3.684c-1.529,0.973-1.529,3.893-1.529,5.422c0,1.105-0.043,1.588-0.509,1.774
      c-0.002,0.001-0.004,0.003-0.006,0.003c-0.075,0.029-0.164,0.05-0.262,0.066c-0.005,0.001-0.008,0.002-0.013,0.003
      c-0.102,0.015-0.219,0.023-0.349,0.026c-0.001,0.001-0.002,0.001-0.003,0.001c0.05,0.667,0.018,1.438-0.295,1.928
      c-0.649,1.019,0,3.429,0.927,3.986c0.927,0.556,1.483,2.687,1.483,3.429s0,4.727-1.668,6.303c-1.668,1.575-1.946,2.965-2.781,4.263
      c-0.834,1.298-0.742,3.059-2.502,3.8c-1.761,0.741-2.502,1.854-2.502,3.244s-0.649,2.873-0.464,4.542
      c0.185,1.668-0.092,5.283,0.371,6.395s0.463,2.688,0.649,3.615c0.185,0.926-0.093,3.615-0.371,4.727
      c-0.153,0.613-0.053,1.562,0.068,2.416c0.342-0.014,0.692-0.024,1.06-0.024c4.633,0,12.554-1.644,16.739,1.943
      c4.185,3.587,5.978,3.736,5.978,6.875c0,3.138-0.449,4.782,2.541,7.473c2.989,2.69,4.932,2.242,6.128,1.495
      c1.196-0.747,4.633-2.092,6.426-1.794c1.793,0.299,3.587,0.299,5.978,0.299c1.382,0,3.56-0.545,5.443-0.461
      C342.718,345.317,343.061,344.022,343.968,343.504z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_73_" x="321.924" y="288.558" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_BENIN" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Benin" dataWikipediaLink="https://en.wikipedia.org/wiki/Benin" data-infotext="<b>Benin</b> &amp;nbsp; Capital: Porto-Novo" data-imageFile="BENIN.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="BENIN" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M292.227,328.003c0.278-1.112,0.556-3.8,0.371-4.727c-0.185-0.926-0.185-2.502-0.649-3.615c-0.463-1.112-0.185-4.727-0.371-6.395
      c-0.185-1.668,0.464-3.151,0.464-4.542s0.742-2.502,2.502-3.244c1.761-0.741,1.668-2.502,2.502-3.8s1.112-2.688,2.781-4.263
      c1.668-1.575,1.668-5.561,1.668-6.303s-0.556-2.873-1.483-3.429s-1.576-2.966-0.927-3.986c0.313-0.491,0.345-1.262,0.295-1.928
      c-0.038,0.001-0.068,0.005-0.109,0.005c-1.182,0-1.251-0.834-2.433-1.251s-1.321-1.877-2.85-3.476s-1.807,0-2.92,0.07
      s-0.626,0.904-1.738,1.738c-0.078,0.059-0.146,0.121-0.208,0.186c-0.018,0.02-0.034,0.04-0.051,0.06
      c-0.042,0.048-0.081,0.097-0.115,0.148c-0.014,0.021-0.027,0.042-0.04,0.064c-0.033,0.055-0.062,0.111-0.087,0.169
      c-0.008,0.017-0.016,0.033-0.022,0.05c-0.03,0.074-0.056,0.151-0.075,0.229l0,0c-0.024,0.097-0.041,0.196-0.052,0.297
      c-0.001,0.004-0.001,0.008-0.001,0.012c-0.01,0.096-0.015,0.194-0.016,0.292c0,0.007,0,0.013,0,0.019
      c-0.001,0.335,0.04,0.681,0.074,1.019c0.008,0.074,0.015,0.147,0.021,0.22c0.002,0.027,0.004,0.054,0.006,0.081
      c0.005,0.07,0.009,0.14,0.012,0.208c0.001,0.028,0.002,0.055,0.003,0.083c0.002,0.092,0.002,0.182-0.004,0.269
      c-0.07,1.112-1.599,1.599-2.502,2.572c-0.904,0.973-2.642,0.348-4.866,0.348s-2.572,2.085-3.893,3.267
      c-0.132,0.118-0.263,0.215-0.393,0.301c-0.018,0.012-0.037,0.026-0.055,0.037c-0.125,0.078-0.249,0.141-0.374,0.194
      c-0.021,0.009-0.044,0.018-0.065,0.027c-0.125,0.049-0.251,0.088-0.379,0.116c-0.016,0.003-0.032,0.006-0.048,0.009
      c-0.134,0.027-0.269,0.046-0.407,0.055c0.098,0.993,0.13,2.102-0.155,2.482c-0.556,0.742-2.224,2.224-0.093,3.429
      c2.132,1.205,1.761,2.225,2.688,2.317s1.576,0.834,1.854,2.039s-1.761,3.151,0.834,4.264s2.039,0.463,2.224,2.966
      c0.185,2.502-0.556,6.117-0.927,7.322s-0.185,3.522,0,4.356s1.112,4.078,0.278,5.191c-0.834,1.112-1.576,2.41-0.927,3.522
      s1.668,2.502,1.668,3.337c0,0.371,0.22,0.871,0.465,1.31c2.401-0.181,4.734-1.148,8.594-1.304
      C292.174,329.565,292.074,328.615,292.227,328.003z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_72_" x="274" y="286.108" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="27.986" height="20.542" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_TOGO" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Togo" dataWikipediaLink="https://en.wikipedia.org/wiki/Togo" data-infotext="<b>Togo</b> &amp;nbsp; Capital: Lomé" data-imageFile="TOGO.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="TOGO" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M281.568,327.076c-0.649-1.112,0.093-2.41,0.927-3.522s-0.093-4.357-0.278-5.191c-0.185-0.834-0.371-3.151,0-4.356
      s1.112-4.82,0.927-7.322c-0.185-2.502,0.371-1.853-2.224-2.966s-0.556-3.059-0.834-4.264s-0.927-1.946-1.854-2.039
      s-0.556-1.112-2.688-2.317s-0.464-2.688,0.093-3.429c0.285-0.38,0.253-1.49,0.155-2.482c-0.191,0.013-0.387,0.01-0.59-0.002
      c-0.011-0.001-0.022-0.002-0.033-0.002c-0.198-0.012-0.403-0.031-0.617-0.054c-0.198-0.021-0.407-0.043-0.623-0.064
      c-0.034-0.003-0.068-0.007-0.103-0.01c-0.241-0.022-0.493-0.041-0.761-0.051c-1.946-0.07-2.92-0.07-4.171-0.07
      c-0.08,0-0.156-0.006-0.228-0.017c-0.014-0.002-0.026-0.006-0.039-0.008c-0.058-0.01-0.114-0.022-0.167-0.039
      c-0.009-0.003-0.016-0.007-0.025-0.009c-0.056-0.019-0.111-0.04-0.162-0.064c-0.003-0.002-0.006-0.003-0.009-0.005
      c-0.056-0.027-0.109-0.057-0.16-0.09l0,0c-0.171,0.417-0.405,0.836-0.716,1.113c-0.834,0.741-1.205,2.502,0.185,3.244
      c1.39,0.742,2.502,1.112,3.059,1.946c0.556,0.834,1.946,2.132,1.39,2.873s-1.298,0.741-1.298,1.668s0.464,3.337-0.741,3.986
      s-1.946,2.317-1.946,3.059c0,0.741,2.688,3.243,3.429,4.263c0.742,1.02,1.576,3.615,1.576,4.635s0.464,3.429-0.278,4.819
      s1.02,1.854-0.278,3.429c-1.298,1.575,0.092,3.893,1.019,5.098c0.815,1.06,2.993,1.834,4.388,4.277
      c1.528-0.77,3.796-1.274,5.598-1.374c0.07-0.004,0.14-0.011,0.21-0.017c-0.244-0.44-0.465-0.939-0.465-1.31
      C283.237,329.578,282.217,328.188,281.568,327.076z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_71_" x="269" y="307.053" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="17.704" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
   <path id="SHOWBEFORE_1_" opacity="0.06" stroke="#FFFFFF" strokeMiterlimit="10" enable-background="new    " d="M281.823,325.36
      l-3.349-0.656l-9.409,48.002c-6.268-0.29-12.542,2.529-16.39,8.06c-5.956,8.56-3.844,20.328,4.716,26.283
      c8.56,5.956,20.328,3.844,26.283-4.716c5.956-8.56,3.844-20.328-4.716-26.283c-2.028-1.411-4.238-2.354-6.505-2.881L281.823,325.36
      z" pointerEvents="auto" className="showbefore"></path>
</g>
<g id="AREA_CHAD" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Chad" dataWikipediaLink="https://en.wikipedia.org/wiki/Chad" data-infotext="<b>Chad</b> &amp;nbsp; Capital: N'Djamena" data-imageFile="CHAD.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="CHAD" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M407.642,321.051c1.39,0,1.877-0.07,2.085-0.695c0.209-0.626,0.904-0.556,1.877-0.695c0.973-0.139,1.321-0.487,1.946-1.321
      c0.626-0.834,2.155-1.599,2.155-0.417s-0.417,2.016,0.487,2.155s1.46,2.085,2.433,0.139c0.973-1.946,1.321-2.85,2.85-2.92
      s1.321-1.043,3.476-1.043s5.909,0.626,7.229-0.765s2.502-2.642,3.615-3.476c1.112-0.834,1.46-0.904,1.251-1.599
      c-0.209-0.695-0.487-0.904-1.668-1.39c-1.182-0.487-0.487-1.112,0.695-1.39s0.695-0.973,2.641-0.834
      c1.946,0.139,5.283,0.556,6.256-0.069c0.973-0.626,2.572-0.07,4.588-1.529c2.016-1.459,3.198-2.502,4.796-4.31
      c1.599-1.807,2.92-2.92,4.518-3.893c1.599-0.973,1.599-4.518,3.545-5.353c1.946-0.834,2.363-1.877,3.962-1.877
      c0.993,0,2.924-0.08,4.245-0.141c0.069-0.398,0.177-0.773,0.291-1.102c0.004-0.012,0.008-0.024,0.012-0.036
      c0.054-0.155,0.109-0.299,0.161-0.43c0.006-0.016,0.011-0.028,0.017-0.044c0.051-0.123,0.098-0.238,0.139-0.332
      c0.417-0.973-1.112-2.085-2.781-2.781c-1.668-0.695-1.251-4.032-1.529-5.7c-0.278-1.668-0.834-2.224-0.556-3.337
      c0.278-1.112-1.946-1.39-3.476-1.39s-1.251-1.251-1.251-2.224s0.278-1.807,1.807-2.502s1.39-2.641,0.556-3.059
      s-0.139-0.695-0.139-2.502s0.973-1.668,2.781-2.224c1.807-0.556-0.278-2.641-0.278-3.615s0.278-1.668,1.529-1.668
      s0.834-0.834,0.834-1.807s0.417-1.668,1.807-2.085s1.112-1.668,0.278-2.92c-0.834-1.251,0.139-2.085,1.807-2.085
      s2.363,0,3.337-1.112c0.973-1.112,4.171,0,4.171,0v-35.219c-9.868-5.074-64.444-33.152-67.984-35.175
      c-3.893-2.224-5.376,0.185-8.342,1.297c-0.184,0.069-0.354,0.139-0.512,0.209c-0.037,0.017-0.068,0.034-0.105,0.052
      c-0.115,0.053-0.229,0.107-0.331,0.161c-0.042,0.022-0.078,0.045-0.118,0.068c-0.087,0.049-0.176,0.098-0.255,0.147
      c-0.042,0.027-0.079,0.053-0.118,0.08c-0.069,0.046-0.14,0.091-0.203,0.137c-0.04,0.029-0.076,0.059-0.114,0.088
      c-0.056,0.043-0.114,0.087-0.167,0.13c-0.037,0.03-0.071,0.061-0.105,0.092c-0.049,0.043-0.1,0.086-0.147,0.128
      c-0.034,0.031-0.066,0.061-0.098,0.092c-0.048,0.045-0.096,0.09-0.142,0.134c-0.045,0.043-0.09,0.086-0.134,0.13
      c-0.02,0.019-0.04,0.038-0.059,0.057c0.122,0.994,0.245,2.183,0.245,2.952c0,1.529,0,3.476,0.765,4.866s0,2.85,0.139,4.31
      c0.139,1.46,1.182,2.781,1.946,3.684c0.765,0.904,1.529,2.85,1.529,4.518s1.043,1.807,2.363,2.502
      c1.321,0.695,0.973,1.529,1.043,2.85c0.069,1.321-0.973,2.085-1.668,3.059c-0.695,0.973-0.695,2.781-0.973,4.379
      c-0.278,1.598-0.417,9.593-0.417,12.026s-1.043,5.005-1.043,7.716s0.07,4.657-2.294,6.465c-2.363,1.807-10.357,11.748-11.47,13.207
      c-1.112,1.46-2.572,4.935-3.893,5.561s-0.278,1.251,0.278,2.016c0.556,0.765,0.278,1.46,0.278,2.989c0,0.2-0.012,0.383-0.033,0.554
      c-0.004,0.03-0.011,0.055-0.016,0.084c-0.021,0.141-0.046,0.276-0.081,0.401c-0.005,0.018-0.013,0.034-0.017,0.051
      c-0.04,0.132-0.086,0.257-0.142,0.375c-0.003,0.006-0.007,0.011-0.01,0.018c-0.06,0.125-0.13,0.244-0.207,0.357
      c1.129,1.216,3.355,3.617,4.12,4.486c0.118,0.134,0.22,0.275,0.314,0.42c0.038,0.058,0.07,0.118,0.104,0.177
      c0.048,0.085,0.095,0.169,0.136,0.257c0.039,0.081,0.075,0.162,0.108,0.245c0.024,0.06,0.046,0.121,0.067,0.182
      c0.036,0.102,0.071,0.202,0.099,0.305c0,0,0,0.001,0.001,0.002c1.249-0.022,3.06,0.073,3.181,0.798
      c0.185,1.112,0.742,1.39,1.946,1.946c1.205,0.556,0.556,1.298,1.39,1.946s1.112,1.576,1.112,2.502s0.186,1.575,0.834,2.132
      c0.648,0.556,0.741,2.224,0.741,3.615s0.37,3.615-0.371,4.449s-1.02,2.41-0.278,3.522s0.371,2.41,1.483,3.151
      s1.112,1.204,1.575,1.853c0.464,0.649,1.668,0.927,2.595,1.483s1.39,1.669-0.649,1.483s-5.653-0.928-7.971-0.742
      c-2.317,0.186-4.078-0.648-5.19,0.371s-1.761,2.966-0.278,4.078s3.985,4.264,5.097,5.191c1.112,0.926,2.688,1.575,3.337,2.595
      c0.649,1.02,2.688,2.224,2.688,3.337s0.741,2.595,1.205,3.059c0.028,0.028,0.058,0.065,0.089,0.107
      c0.01,0.015,0.022,0.034,0.033,0.051c0.02,0.03,0.04,0.06,0.061,0.096c0.014,0.024,0.028,0.052,0.042,0.078
      c0.018,0.034,0.036,0.066,0.054,0.102c0.016,0.033,0.033,0.068,0.049,0.103c0.016,0.035,0.032,0.069,0.048,0.106
      c0.017,0.04,0.035,0.083,0.052,0.125c0.015,0.036,0.029,0.07,0.043,0.107c0.018,0.048,0.036,0.098,0.054,0.148
      c0.012,0.034,0.024,0.067,0.036,0.102c0.018,0.055,0.036,0.111,0.054,0.168c0.01,0.031,0.02,0.063,0.029,0.095
      c0.018,0.061,0.035,0.123,0.052,0.185c0.008,0.029,0.015,0.057,0.022,0.086c0.017,0.066,0.033,0.133,0.047,0.199
      c0.005,0.026,0.011,0.051,0.016,0.077c0.015,0.07,0.027,0.138,0.039,0.208c0.004,0.022,0.007,0.045,0.011,0.067
      c0.011,0.071,0.021,0.141,0.029,0.211c0.002,0.02,0.004,0.039,0.006,0.058c0.007,0.072,0.013,0.142,0.015,0.211
      c0.001,0.016,0.001,0.031,0.002,0.047c0.002,0.071,0.002,0.14-0.001,0.207c-0.001,0.013-0.002,0.025-0.003,0.037
      c-0.004,0.068-0.01,0.134-0.02,0.197c-0.002,0.009-0.004,0.018-0.005,0.027c-0.011,0.065-0.024,0.126-0.042,0.184
      c-0.002,0.005-0.004,0.01-0.005,0.016c-0.02,0.059-0.042,0.116-0.069,0.166l0,0C406.225,320.845,406.95,321.051,407.642,321.051z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_70_" x="402.976" y="241.951" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_SOUTHSUDAN" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="South Sudan" dataWikipediaLink="https://en.wikipedia.org/wiki/South_Sudan" data-infotext="<b>South Sudan</b> &amp;nbsp; Capital: Juba" data-imageFile="SOUTHSUDAN.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="SUTHSUDAN" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M581.355,339.819c-2.641-0.417-2.085-1.112-2.781-2.085c-0.695-0.973-0.973-3.893-1.668-6.117s-2.641-4.727-4.588-5.283
      c-1.946-0.556-3.198-2.085-3.476-3.337c-0.278-1.251-0.417-1.946-2.085-1.946s-1.529-1.39-2.085-1.807s-1.668-0.278-3.059-0.278
      s-2.224-1.807-0.973-2.502s0.556-1.946,0.973-3.198c0.417-1.251,1.946-1.112,3.615-0.278c1.668,0.834,3.476,0.139,4.588-0.973
      s0.417-7.646,0.278-9.871c-0.015-0.245-0.017-0.467-0.012-0.681h-0.322c-0.824-0.211-1.638-0.353-2.474-0.49
      c-0.12-2.372,1.088-3.973,0.878-6.123c-0.219-2.233-1.577-2.548-2.686-4.155c-1.078-1.562-1.458-2.764-2.952-4.117
      c-1.452-1.315-2.92-2.486-5.045-2.707c0.549-2.613,1.105-4.563,1.105-7.491c0-2.769,0.748-4.831,1.111-7.257
      c-2.05-0.119-4.606-0.516-6.549-0.233c-0.056,1.103-0.013,2.248-0.069,3.351c-2.109-0.191-4.319-0.158-6.504-0.211
      c3.042,2.273,4.442,5.233,3.123,9.371c-0.927,2.908-5.336,4.359-5.972,7.709c-1.89,1.189-5.356,3.952-6.146,6.156
      c-1.571,0.088-3.177,0.042-4.76,0.04c-1.716-0.002-1.632,0.317-3.003-1.312c-2.089-2.483-2.896-3.768-6.312-3.214
      c-0.311,1.084-0.582,2.137-0.525,3.351c-2.261,0.115-8.187-0.88-7.513,3.192c-2.154,0.105-4.404,0.109-6.556,0
      c-0.083-3.446-2.495-3.334-5.28-3.613c-1.228-0.123-2.576-0.449-3.876-0.229c-1.372,0.232-2.137,1.011-3.382,1.407
      c-1.292,2.741-4.057,1.485-5.339-0.439c-0.931-1.397-0.599-1.381-2.224-1.881c-1.12-0.344-2.699-0.364-3.843,0.039
      c-0.366,1.86-0.113,4.327-2.275,4.789c-0.672,1.848-2.78,2.689-4.87,3.297c-0.001,0.485-0.042,1.01-0.042,1.45
      c0,1.112,0.417,2.224-0.973,2.92c-1.39,0.696-1.946,1.529-1.807,2.641s-0.695,2.502,2.085,2.502s4.727-0.695,4.727,0.834
      c0,1.529-0.417,3.476,2.224,3.476c2.642,0,3.337-0.973,3.754,0.556s1.112,2.224,2.641,2.363s1.39,1.807,1.39,3.059
      c0,1.252,0.834,2.363,2.224,3.198c1.39,0.834,2.224,1.668,3.754,1.946c1.529,0.278,3.476,1.529,3.476,3.198
      s-0.834,3.198,0.834,4.31c1.668,1.112,5.005,2.363,5.422,3.337c0.373,0.87,2.072,1.415,1.735,4.499
      c0.136,0.039,0.267,0.099,0.392,0.172c0.013,0.008,0.026,0.014,0.038,0.022c0.118,0.073,0.229,0.162,0.332,0.266
      c0.016,0.015,0.03,0.031,0.046,0.047c0.1,0.106,0.193,0.224,0.274,0.358c0.011,0.018,0.02,0.037,0.03,0.055
      c0.082,0.143,0.155,0.297,0.212,0.467c0.556,1.668,2.595,0.927,2.595,2.595s1.112,2.78,2.039,2.966s0.556,1.854,2.224,1.854
      c1.668,0,3.337,0.742,3.708-0.556c0.371-1.298,1.112-2.966,2.781-1.668c1.668,1.297,2.595,2.039,3.893,0.37
      c1.297-1.668,2.41-2.966,4.078,0s1.854,4.82,5.005,5.191s1.298,3.151,3.151,3.151c0.106,0,0.203-0.008,0.295-0.021
      c0.043-0.006,0.082-0.016,0.122-0.024c0.043-0.009,0.086-0.018,0.127-0.029c0.051-0.015,0.1-0.031,0.147-0.05
      c0.021-0.008,0.041-0.017,0.062-0.026c0.055-0.024,0.109-0.05,0.16-0.079c0.002-0.002,0.005-0.003,0.008-0.005
      c0.473-0.273,0.756-0.731,1.21-1.066c0.004-0.003,0.008-0.006,0.013-0.009c0.06-0.044,0.124-0.085,0.191-0.124
      c0.011-0.007,0.022-0.013,0.034-0.02c0.148-0.081,0.316-0.149,0.511-0.195c0.022-0.005,0.047-0.009,0.07-0.014
      c0.074-0.015,0.152-0.027,0.235-0.035c0.035-0.004,0.072-0.006,0.11-0.009c0.08-0.005,0.166-0.007,0.254-0.005
      c0.039,0,0.076,0,0.116,0.002c0.13,0.006,0.267,0.017,0.415,0.039c2.595,0.371,5.376-0.926,6.488,0.371s2.224,1.854,3.151,0.927
      s0.741-1.854,2.781-1.854c2.039,0,3.707-1.668,5.005-0.556c1.298,1.112,3.152,1.298,5.191-1.297
      c0.116-0.148,0.227-0.276,0.336-0.395c0.032-0.035,0.063-0.067,0.094-0.099c0.079-0.083,0.157-0.159,0.232-0.229
      c0.029-0.027,0.058-0.054,0.086-0.078c0.097-0.085,0.192-0.161,0.283-0.227c0.001,0,0.003-0.002,0.004-0.003
      c0.098-0.07,0.192-0.127,0.282-0.177c0.016-0.009,0.031-0.016,0.046-0.024c0.077-0.041,0.153-0.077,0.225-0.106
      c0.011-0.005,0.023-0.01,0.035-0.015c0.482-0.19,0.862-0.171,1.169-0.291c0.007-0.002,0.013-0.005,0.02-0.008
      c0.035-0.015,0.068-0.032,0.102-0.051c0.014-0.008,0.027-0.017,0.04-0.026c0.03-0.02,0.06-0.041,0.09-0.066l0.001-0.001
      c0.046-0.041,0.091-0.089,0.133-0.147c0.003-0.003,0.005-0.008,0.007-0.011c0.04-0.056,0.079-0.119,0.116-0.193
      c0.011-0.021,0.021-0.05,0.031-0.073c0.028-0.063,0.056-0.125,0.084-0.202c0.036-0.106,0.072-0.224,0.106-0.36
      c0.556-2.224,1.483-3.522,3.522-3.522s2.781-1.854,5.005-1.854s2.039,0.742,2.781,1.854s2.41,1.483,3.151,1.297
      c0.047-0.012,0.09-0.015,0.13-0.011c0.041-1.724,0.101-4.406,0.101-5.411C584.97,340.236,583.997,340.236,581.355,339.819z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_69_" x="501.468" y="307.946" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_THESUDAN" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Sudan" dataWikipediaLink="https://en.wikipedia.org/wiki/the_Sudan" data-infotext="<b>Sudan</b> &amp;nbsp; Capital: Khartoum" data-imageFile="SUDAN.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="THESUDAN" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M609.832,225.383c-0.51,0.515-0.95,1.059-0.95,1.408c0,0.834-0.556,2.503-2.363,3.059c-1.807,0.556-3.198,1.807-4.449,1.807
      s-1.946,0.417-1.946,1.668c0,1.251-0.556,1.39-2.085,1.668s-2.363,0.278-2.502,1.807c-0.139,1.529-0.417,3.198,0,4.31
      s-0.695,2.502-1.39,3.615c-0.695,1.112-1.39,2.085-1.39,3.198c0,1.112-0.556,2.363-1.39,3.059s-1.668,1.251-0.834,2.781
      s0.278,4.171,0.278,6.117c0,0.452,0.015,0.974,0.024,1.525c0.003,0.217,0.006,0.44,0.007,0.665c0,0.024,0,0.049,0,0.074
      c-0.001,0.2-0.003,0.402-0.007,0.604c-0.001,0.029-0.001,0.058-0.002,0.087c-0.006,0.213-0.015,0.425-0.029,0.636
      c-0.003,0.041-0.006,0.083-0.009,0.124c-0.014,0.189-0.03,0.376-0.052,0.56c-0.003,0.028-0.005,0.058-0.009,0.086
      c-0.024,0.2-0.056,0.394-0.092,0.584c-0.009,0.045-0.018,0.087-0.027,0.131c-0.037,0.176-0.079,0.348-0.129,0.512
      c-0.005,0.016-0.009,0.034-0.014,0.051c-0.054,0.173-0.118,0.336-0.189,0.491c-0.017,0.037-0.034,0.074-0.053,0.11
      c-0.076,0.154-0.157,0.301-0.253,0.433c-1.112,1.529-0.973,2.363-1.251,3.893c-0.278,1.529,0.556,4.31-1.668,4.31
      s-3.615,0.278-4.727,2.781c-1.112,2.502-1.668,4.588-2.781,5.283c-1.112,0.695-1.529,1.807-1.39,2.92
      c0.139,1.112-1.39,1.668-0.834,2.92c0.556,1.251,1.39,3.059-0.417,3.893s-0.556-1.529-2.085-1.668s-2.224,0.695-2.502,1.251
      s-1.251,1.946-0.695,2.92c0.556,0.973,1.251,1.807,0.139,2.92c-0.99,0.99-1.645,1.765-1.68,3.49h-0.322
      c-0.824-0.211-1.638-0.353-2.474-0.49c-0.12-2.372,1.088-3.973,0.878-6.123c-0.219-2.233-1.577-2.548-2.686-4.155
      c-1.078-1.562-1.458-2.764-2.952-4.117c-1.452-1.315-2.92-2.486-5.045-2.707c0.549-2.613,1.105-4.563,1.105-7.491
      c0-2.769,0.748-4.831,1.111-7.257c-2.05-0.119-4.606-0.516-6.549-0.233c-0.056,1.103-0.013,2.248-0.069,3.351
      c-2.109-0.191-4.319-0.158-6.504-0.211c3.042,2.273,4.442,5.233,3.123,9.371c-0.927,2.908-5.336,4.359-5.972,7.709
      c-1.89,1.189-5.356,3.952-6.146,6.156c-1.571,0.088-3.177,0.042-4.76,0.04c-1.716-0.002-1.632,0.317-3.003-1.312
      c-2.089-2.483-2.896-3.768-6.312-3.214c-0.311,1.084-0.582,2.137-0.525,3.351c-2.261,0.115-8.187-0.88-7.513,3.192
      c-2.154,0.105-4.404,0.109-6.556,0c-0.083-3.446-2.495-3.334-5.28-3.613c-1.228-0.123-2.576-0.449-3.876-0.229
      c-1.372,0.232-2.137,1.011-3.382,1.407c-1.292,2.741-4.057,1.485-5.339-0.439c-0.931-1.397-0.599-1.381-2.224-1.881
      c-1.12-0.344-2.699-0.364-3.843,0.039c-0.366,1.86-0.113,4.327-2.275,4.789c-0.672,1.848-2.78,2.689-4.87,3.297
      c0.001-0.741-0.094-1.386-0.599-1.47c-0.834-0.139-1.807-0.973-1.807-2.224s-1.807-2.641-3.754-4.171
      c-0.131-0.103-0.247-0.213-0.351-0.325c-0.035-0.037-0.062-0.076-0.094-0.114c-0.065-0.078-0.128-0.156-0.182-0.237
      c-0.03-0.046-0.056-0.092-0.084-0.139c-0.045-0.077-0.087-0.155-0.124-0.235c-0.022-0.048-0.042-0.098-0.062-0.147
      c-0.031-0.081-0.06-0.164-0.084-0.246c-0.014-0.048-0.027-0.095-0.039-0.143c-0.021-0.091-0.038-0.182-0.051-0.274
      c-0.006-0.04-0.014-0.079-0.018-0.12c-0.012-0.115-0.019-0.23-0.021-0.346c-0.001-0.016-0.002-0.033-0.002-0.049
      c-0.001-0.279,0.027-0.557,0.073-0.825c0.068-0.398,0.177-0.773,0.291-1.102c0.004-0.012,0.008-0.024,0.012-0.036
      c0.054-0.155,0.109-0.299,0.161-0.43c0.006-0.015,0.011-0.029,0.017-0.044c0.051-0.123,0.098-0.238,0.139-0.332
      c0.417-0.973-1.112-2.085-2.781-2.781c-1.668-0.695-1.251-4.032-1.529-5.7c-0.278-1.668-0.834-2.224-0.556-3.337
      c0.278-1.112-1.946-1.39-3.476-1.39s-1.251-1.251-1.251-2.224s0.278-1.807,1.807-2.502s1.39-2.642,0.556-3.059
      s-0.139-0.695-0.139-2.503c0-1.807,0.973-1.668,2.781-2.224c1.807-0.556-0.278-2.642-0.278-3.615s0.278-1.668,1.529-1.668
      s0.834-0.834,0.834-1.807s0.417-1.668,1.807-2.085s1.112-1.668,0.278-2.919c-0.834-1.251,0.139-2.085,1.807-2.085
      s2.363,0,3.337-1.112c0.973-1.112,4.171,0,4.171,0v-35.219v-3.709l9.176-0.278c0,0,0-0.012,0-0.03
      c0.001-0.05,0.001-0.166,0.002-0.35c0.008-1.549,0.036-7.735,0.068-16.161c0.002-0.547,0.004-1.105,0.006-1.67h54.839
      c0,0,0.973,0.278,1.668-0.834s1.668-1.39,2.085-0.139s1.668,1.529,4.171,1.39s10.844-1.112,11.817,1.112s2.363,1.946,4.171,1.251
      c1.807-0.695,0.417-2.502,2.641-3.337s5.561-2.224,5.839-3.893c0.278-1.668,0.139-3.198,1.946-3.059s2.641,0,3.337-0.834
      c0.383-0.459,1.146-1.509,2.103-2.429c0.009,0.023,0.018,0.043,0.027,0.066c1.196,2.989,1.943,3.886,4.036,4.184
      c2.092,0.299,4.035,3.288,5.978,4.484c1.943,1.196,1.793,2.84,1.793,5.082s1.047,2.391,2.391,4.633c1.345,2.242,0,3.886,0,7.174
      s0.449,8.369,1.196,12.106c0.747,3.736,7.473,6.127,9.117,7.771C608.965,224.397,609.398,224.868,609.832,225.383z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_68_" x="504.476" y="228.593" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_THECENTRALAFRICANREPUBLIC" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Central African Republic" dataWikipediaLink="https://en.wikipedia.org/wiki/Central_African_Republic" data-infotext="<b>Central African Republic</b> &amp;nbsp; Capital: Bangui" data-imageFile="CENTRALAFRICAN.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="THECENTRALAFRICANREPUBLIC" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M507.532,336.483c-0.417-0.973-3.754-2.224-5.422-3.337c-1.668-1.112-0.834-2.641-0.834-4.31c0-1.668-1.946-2.92-3.476-3.198
      c-1.529-0.278-2.363-1.112-3.754-1.946c-1.39-0.834-2.224-1.946-2.224-3.198c0-1.251,0.139-2.92-1.39-3.059
      c-1.529-0.139-2.224-0.834-2.641-2.363s-1.112-0.556-3.754-0.556c-2.641,0-2.224-1.946-2.224-3.476
      c0-1.529-1.946-0.834-4.727-0.834s-1.946-1.39-2.085-2.502c-0.139-1.112,0.417-1.946,1.807-2.642s0.973-1.807,0.973-2.92
      s0.278-2.781-0.556-2.92s-1.807-0.973-1.807-2.224s-1.807-2.641-3.754-4.171c-0.131-0.103-0.247-0.212-0.351-0.325
      c-0.035-0.037-0.062-0.076-0.094-0.114c-0.065-0.078-0.128-0.156-0.182-0.237c-0.03-0.046-0.056-0.092-0.084-0.139
      c-0.045-0.077-0.087-0.155-0.124-0.235c-0.022-0.048-0.042-0.098-0.062-0.147c-0.031-0.081-0.06-0.164-0.084-0.246
      c-0.014-0.048-0.027-0.095-0.039-0.143c-0.021-0.091-0.037-0.182-0.051-0.274c-0.006-0.04-0.014-0.079-0.018-0.119
      c-0.013-0.115-0.019-0.23-0.021-0.346c-0.001-0.016-0.002-0.033-0.002-0.049c-0.001-0.28,0.027-0.557,0.073-0.825
      c-1.321,0.061-3.251,0.141-4.245,0.141c-1.599,0-2.016,1.043-3.962,1.877s-1.946,4.379-3.545,5.353
      c-1.599,0.973-2.92,2.085-4.518,3.893c-1.599,1.807-2.781,2.85-4.796,4.31c-2.015,1.46-3.615,0.904-4.588,1.529
      c-0.973,0.626-4.31,0.209-6.256,0.069c-1.946-0.139-1.46,0.556-2.641,0.834s-1.877,0.904-0.695,1.39
      c1.182,0.487,1.46,0.695,1.668,1.39c0.209,0.695-0.139,0.765-1.251,1.599c-1.112,0.834-2.294,2.085-3.615,3.476
      c-1.321,1.391-5.074,0.765-7.229,0.765s-1.946,0.973-3.476,1.043s-1.877,0.973-2.85,2.92c-0.973,1.946-1.529,0-2.433-0.139
      c-0.904-0.139-0.487-0.973-0.487-2.155s-1.529-0.417-2.155,0.417s-0.973,1.182-1.946,1.321c-0.973,0.139-1.668,0.07-1.877,0.695
      c-0.209,0.626-0.695,0.695-2.085,0.695c-0.692,0-1.417-0.206-1.972-0.414c-0.027,0.049-0.055,0.096-0.09,0.136
      c-0.649,0.741-2.502,0.649-2.781,2.502s-1.113,2.966-1.761,4.356c-0.648,1.39-1.761,2.04-2.039,2.966s-1.02,1.112-2.132,1.483
      s-1.946,1.668-0.648,2.317c1.297,0.649,1.019,1.853,1.019,3.059s-0.278,3.522,0,4.727s-0.463,2.873,0.927,3.708
      c1.39,0.834,3.429,4.171,3.337,5.839c-0.092,1.668,1.39,3.615,2.039,4.171s2.688,3.614,3.893,3.985
      c1.205,0.371,2.873,1.668,2.873,2.317s-0.186,2.595,0.185,3.059c0.273,0.342,0.046,1.438,0.685,2.473c0-0.001,0-0.001,0.001-0.001
      c0.117-0.444,0.262-0.853,0.453-1.214c0.004-0.007,0.008-0.014,0.012-0.021c0.056-0.104,0.117-0.205,0.182-0.301
      c0.018-0.027,0.037-0.052,0.056-0.078c0.054-0.074,0.11-0.147,0.171-0.216c0.026-0.03,0.053-0.06,0.081-0.089
      c0.058-0.061,0.119-0.119,0.183-0.174c0.032-0.028,0.064-0.056,0.098-0.083c0.069-0.054,0.143-0.104,0.218-0.153
      c0.033-0.021,0.064-0.043,0.098-0.064c0.112-0.064,0.23-0.123,0.357-0.174c1.854-0.741,0.927-1.668,1.483-3.707
      s-0.926-5.933,3.337-5.747s4.263-1.483,6.488-0.556c2.224,0.927,6.118,1.668,7.415,1.668c0.08,0,0.157-0.007,0.23-0.021
      c0.219-0.042,0.406-0.144,0.564-0.297c0.074-0.072,0.139-0.157,0.2-0.25c0.011-0.017,0.022-0.035,0.033-0.053
      c0.115-0.191,0.205-0.421,0.271-0.684c0.007-0.026,0.012-0.052,0.018-0.079c0.06-0.267,0.097-0.564,0.11-0.885
      c0.001-0.034,0.002-0.069,0.003-0.104c0.008-0.323-0.007-0.667-0.042-1.027c-0.004-0.042-0.009-0.085-0.014-0.128
      c-0.021-0.18-0.044-0.364-0.075-0.551c-0.371-2.224-2.78-3.336,0.927-5.19s2.409-1.668,3.707-3.708s5.19-2.966,7.229-1.112
      c2.039,1.853,3.151,2.595,3.708,4.264c0.556,1.668,1.483,2.039,3.522,2.039s2.039,0.741,5.005,1.112s2.781-0.742,5.561,0.556
      c2.781,1.298,5.191,0.928,5.376-1.483s2.41-5.005,4.449-3.893s2.41,2.224,4.635,0.741c2.224-1.483,1.483-2.039,3.707-2.224
      s1.483-1.853,3.151-1.668s2.41,1.298,2.966,2.039s2.41,0.37,3.337-0.742s2.224-0.556,2.224-2.039s2.41-1.668,2.966-1.112
      s-0.186,0.556,2.41,1.112c2.595,0.556,2.039,1.854,4.264,1.668c2.224-0.186,3.707,0.556,4.819-1.112
      c0.074-0.111,0.152-0.211,0.231-0.304c0.026-0.031,0.054-0.056,0.081-0.085c0.054-0.059,0.11-0.117,0.166-0.168
      c0.033-0.029,0.066-0.053,0.099-0.08c0.054-0.043,0.107-0.085,0.162-0.122c0.035-0.023,0.069-0.043,0.104-0.064
      c0.055-0.033,0.111-0.063,0.167-0.089c0.035-0.016,0.069-0.03,0.104-0.044c0.059-0.023,0.117-0.043,0.177-0.059
      c0.033-0.009,0.065-0.018,0.097-0.025c0.064-0.014,0.128-0.023,0.191-0.029c0.028-0.003,0.055-0.007,0.082-0.008
      c0.073-0.004,0.145-0.002,0.217,0.004c0.018,0.002,0.036,0.001,0.054,0.003c0.09,0.01,0.179,0.027,0.266,0.052
      C509.605,337.897,507.905,337.353,507.532,336.483z" pointerEvents="auto" className="landarea"></path>

      <rect id="C7" x="420.473" y="318.867" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_CAMEROON" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Cameroon" dataWikipediaLink="https://en.wikipedia.org/wiki/Cameroon" data-infotext="<b>Cameroon</b> &amp;nbsp; Capital: Yaoundé" data-imageFile="CAMEROON.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="CAMEROON" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M365.448,368.32c0.299,0,0.577-0.015,0.842-0.038c0.138-0.012,0.264-0.032,0.392-0.05c0.107-0.015,0.216-0.028,0.317-0.046
      c0.169-0.032,0.328-0.068,0.479-0.108c0.036-0.01,0.072-0.018,0.106-0.028c0.173-0.05,0.337-0.104,0.485-0.164
      c0.207-0.083,0.389-0.175,0.547-0.273c0.003-0.002,0.005-0.003,0.008-0.005c0.125-0.078,0.229-0.16,0.321-0.243
      c0.029-0.027,0.061-0.053,0.086-0.079c0.038-0.039,0.07-0.078,0.101-0.117c0.053-0.07,0.101-0.14,0.133-0.211
      c0.009-0.02,0.014-0.039,0.021-0.058c0.031-0.084,0.053-0.167,0.053-0.248c0-0.741,4.635,0.185,7.044,0.185
      c0.135,0,0.291,0.004,0.455,0.009c0.042,0.002,0.086,0.003,0.129,0.005c0.154,0.007,0.317,0.014,0.491,0.024
      c0.021,0.001,0.04,0.002,0.061,0.003c0.195,0.012,0.405,0.027,0.622,0.043c0.057,0.004,0.116,0.009,0.174,0.014
      c0.184,0.014,0.374,0.03,0.571,0.047c0.048,0.004,0.094,0.008,0.143,0.012c0.238,0.021,0.484,0.044,0.737,0.068
      c0.063,0.006,0.128,0.012,0.192,0.019c0.212,0.021,0.428,0.042,0.648,0.066c0.062,0.006,0.123,0.013,0.185,0.019
      c0.267,0.028,0.538,0.058,0.813,0.088c0.062,0.007,0.125,0.014,0.188,0.021c0.232,0.026,0.466,0.053,0.701,0.08
      c0.067,0.008,0.133,0.015,0.2,0.023c0.287,0.034,0.574,0.068,0.862,0.103c0.039,0.004,0.078,0.01,0.117,0.014
      c0.257,0.032,0.513,0.064,0.767,0.096c0.065,0.008,0.129,0.016,0.194,0.024c0.28,0.036,0.559,0.072,0.833,0.108
      c0.295,0.039,0.584,0.078,0.869,0.117c0.046,0.006,0.092,0.013,0.139,0.019c0.268,0.037,0.529,0.073,0.784,0.11
      c0.038,0.005,0.075,0.011,0.112,0.016c0.226,0.033,0.446,0.064,0.659,0.096c0.041,0.006,0.084,0.012,0.124,0.018
      c0.224,0.034,0.438,0.066,0.645,0.099c0.052,0.008,0.102,0.016,0.153,0.024c0.207,0.033,0.408,0.065,0.592,0.096
      c3.337,0.556,7.415-1.297,10.01,0.556c2.595,1.854,5.746,0.371,7.044,2.039s3.708,2.039,4.078-0.371
      c0.031-0.204,0.061-0.405,0.091-0.606c0.116-0.782,0.231-1.531,0.408-2.202c0.001-0.001,0.001-0.001,0.001-0.002
      c-0.639-1.035-0.412-2.132-0.685-2.473c-0.371-0.463-0.185-2.41-0.185-3.059s-1.668-1.946-2.873-2.317
      c-1.205-0.371-3.244-3.429-3.893-3.985c-0.649-0.556-2.132-2.502-2.039-4.171c0.093-1.669-1.946-5.005-3.337-5.839
      c-1.39-0.834-0.649-2.502-0.927-3.708s0-3.522,0-4.727s0.278-2.41-1.019-3.059c-1.297-0.649-0.464-1.946,0.648-2.317
      s1.854-0.556,2.132-1.483c0.278-0.926,1.39-1.576,2.039-2.966c0.648-1.39,1.483-2.502,1.761-4.356
      c0.278-1.854,2.132-1.761,2.781-2.502c0.035-0.04,0.063-0.087,0.09-0.136c0.027-0.051,0.049-0.107,0.069-0.166
      c0.002-0.005,0.004-0.01,0.005-0.016c0.018-0.058,0.031-0.119,0.042-0.184c0.002-0.009,0.004-0.018,0.005-0.027
      c0.01-0.062,0.016-0.129,0.02-0.197c0.001-0.012,0.002-0.025,0.003-0.037c0.003-0.067,0.002-0.136,0.001-0.207
      c-0.001-0.016-0.001-0.032-0.002-0.047c-0.003-0.069-0.008-0.14-0.015-0.211c-0.002-0.02-0.004-0.039-0.006-0.058
      c-0.008-0.07-0.017-0.141-0.029-0.211c-0.004-0.023-0.007-0.045-0.011-0.067c-0.011-0.07-0.025-0.138-0.039-0.208
      c-0.005-0.026-0.011-0.051-0.016-0.077c-0.015-0.066-0.03-0.133-0.047-0.199c-0.007-0.029-0.015-0.058-0.022-0.086
      c-0.017-0.062-0.034-0.124-0.052-0.185c-0.01-0.032-0.02-0.064-0.029-0.095c-0.018-0.057-0.036-0.113-0.054-0.168
      c-0.012-0.034-0.024-0.068-0.036-0.102c-0.018-0.05-0.036-0.1-0.054-0.148c-0.014-0.036-0.028-0.072-0.043-0.107
      c-0.017-0.042-0.034-0.085-0.052-0.125c-0.016-0.037-0.033-0.072-0.048-0.106c-0.016-0.035-0.033-0.071-0.049-0.103
      c-0.018-0.036-0.036-0.068-0.054-0.102c-0.014-0.026-0.028-0.054-0.042-0.078c-0.021-0.036-0.041-0.066-0.061-0.096
      c-0.011-0.016-0.022-0.036-0.033-0.051c-0.03-0.042-0.06-0.079-0.089-0.107c-0.464-0.464-1.205-1.946-1.205-3.059
      s-2.039-2.317-2.688-3.337c-0.649-1.02-2.224-1.668-3.337-2.595c-1.112-0.926-3.615-4.078-5.097-5.191
      c-1.482-1.113-0.834-3.059,0.278-4.078c1.112-1.019,2.873-0.185,5.19-0.371c2.317-0.186,5.932,0.556,7.971,0.742
      s1.576-0.927,0.649-1.483s-2.132-0.834-2.595-1.483c-0.464-0.649-0.463-1.112-1.575-1.853s-0.741-2.039-1.483-3.151
      s-0.463-2.688,0.278-3.522c0.741-0.834,0.371-3.059,0.371-4.449s-0.093-3.059-0.741-3.615s-0.834-1.205-0.834-2.132
      c0-0.926-0.278-1.854-1.112-2.502c-0.834-0.648-0.186-1.39-1.39-1.946s-1.761-0.834-1.946-1.946
      c-0.121-0.725-1.932-0.819-3.181-0.798c0,0.002,0,0.004,0.002,0.007c0.054,0.201,0.095,0.404,0.125,0.606
      c0.004,0.025,0.007,0.05,0.01,0.075c0.024,0.178,0.04,0.354,0.048,0.528c0.002,0.036,0.004,0.073,0.005,0.11
      c0.005,0.161,0.005,0.318,0,0.472c-0.002,0.04-0.001,0.079-0.003,0.118c-0.009,0.182-0.023,0.36-0.042,0.526
      c-0.139,1.182,0.626,1.946,0.904,3.545c0.278,1.599,1.251,0.904,2.781,1.529c1.529,0.626,0.765,5.074,0.765,6.187
      s-2.224,2.433-4.379,2.781s-3.128,2.572-3.893,3.823s-1.321,3.476-1.599,5.353c-0.278,1.877-1.043,2.085-1.529,3.962
      c-0.487,1.877-0.487,2.155-2.294,2.502c-1.807,0.347-2.155,2.781-2.155,4.24s-1.946,3.476-3.128,4.518
      c-1.182,1.043-1.39,1.807-1.529,3.267c-0.139,1.46-0.695,3.267-2.294,5.492c-1.599,2.224-0.417,3.684-2.016,4.588
      c-1.599,0.904-2.016,2.433-2.572,4.101s-1.877,1.668-3.128,1.46c-1.251-0.209-0.765-1.738-0.695-2.363
      c0.07-0.626-0.07-1.321-0.904-1.807c-0.834-0.487-1.599-1.043-2.711-1.668s-1.321,1.182-1.946,1.738
      c-0.626,0.556-1.321,0-2.989-0.417s-2.572,1.738-3.476,3.059c-0.904,1.321-1.668,1.946-2.989,2.363s-2.641,2.642-3.754,3.337
      s-0.695,3.476-0.695,5.144s-0.973,3.198-2.433,4.032c-0.907,0.518-1.25,1.813-1.38,2.721c1.375,0.062,2.594,0.457,3.225,1.656
      c1.495,2.84,1.345,3.736,4.334,3.736s4.185,0.449,4.334,2.541c0.15,2.092,0.449,2.69,1.196,3.736s1.345,1.793,0.598,2.989
      c-0.747,1.195-2.391,2.839-1.495,4.782c0.252,0.546,0.43,1.139,0.534,1.732c0.818,0.299,1.65,0.59,2.163,0.737
      C358.774,368.505,362.852,368.32,365.448,368.32z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_66_" x="350.529" y="320.241" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_ETHIOPIA" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Ethiopia" dataWikipediaLink="https://en.wikipedia.org/wiki/Ethiopia" data-infotext="<b>Ethiopia</b> &amp;nbsp; Capital: Addis Ababa" data-imageFile="ETHIOPIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="ETHIOPIA" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M590.839,262.069c0,0.024,0,0.049,0,0.074c-0.001,0.2-0.003,0.402-0.007,0.604c-0.001,0.029-0.001,0.058-0.002,0.087
      c-0.006,0.213-0.015,0.425-0.029,0.636c-0.003,0.042-0.006,0.083-0.009,0.124c-0.014,0.189-0.03,0.376-0.052,0.56
      c-0.003,0.028-0.005,0.058-0.009,0.086c-0.024,0.2-0.056,0.394-0.092,0.584c-0.009,0.045-0.018,0.087-0.027,0.131
      c-0.037,0.176-0.079,0.348-0.129,0.512c-0.005,0.016-0.009,0.034-0.014,0.05c-0.054,0.173-0.118,0.336-0.189,0.491
      c-0.017,0.037-0.034,0.074-0.053,0.11c-0.076,0.154-0.157,0.301-0.253,0.433c-1.112,1.529-0.973,2.363-1.251,3.893
      c-0.278,1.529,0.556,4.31-1.668,4.31s-3.615,0.278-4.727,2.781c-1.112,2.502-1.668,4.588-2.781,5.283
      c-1.112,0.695-1.529,1.807-1.39,2.92c0.139,1.112-1.39,1.668-0.834,2.92c0.556,1.251,1.39,3.059-0.417,3.893
      s-0.556-1.529-2.085-1.668s-2.224,0.695-2.502,1.251s-1.251,1.946-0.695,2.92c0.556,0.973,1.251,1.807,0.139,2.92
      c-1.112,1.112-1.807,1.946-1.668,4.171c0.139,2.224,0.834,8.759-0.278,9.871s-2.92,1.807-4.588,0.973s-3.198-0.973-3.615,0.278
      s0.278,2.502-0.973,3.198c-1.251,0.696-0.417,2.502,0.973,2.502s2.502-0.139,3.059,0.278c0.556,0.417,0.417,1.807,2.085,1.807
      s1.807,0.695,2.085,1.946s1.529,2.781,3.476,3.337c1.946,0.556,3.893,3.059,4.588,5.283s0.973,5.144,1.668,6.117
      s0.139,1.668,2.781,2.085s3.615,0.417,3.615,1.946c0,1.004-0.06,3.687-0.101,5.411c0.002,0,0.003,0.001,0.004,0.001
      c0.037,0.004,0.071,0.013,0.104,0.028c0.014,0.007,0.027,0.017,0.041,0.025c0.02,0.012,0.04,0.023,0.059,0.039
      c0.015,0.012,0.03,0.028,0.046,0.043c0.017,0.016,0.034,0.032,0.05,0.05c0.016,0.018,0.034,0.04,0.05,0.06
      c0.016,0.018,0.031,0.037,0.047,0.058c0.025,0.033,0.052,0.068,0.079,0.104c0.015,0.021,0.032,0.042,0.048,0.064
      c0.027,0.036,0.055,0.073,0.084,0.111c0.016,0.021,0.034,0.042,0.05,0.062c0.026,0.031,0.052,0.064,0.08,0.096
      c0.02,0.023,0.041,0.046,0.061,0.068c0.028,0.031,0.059,0.062,0.09,0.092c0.023,0.023,0.048,0.047,0.073,0.07
      c0.033,0.03,0.069,0.06,0.105,0.09c0.028,0.023,0.057,0.045,0.086,0.067c0.039,0.029,0.081,0.057,0.124,0.084
      c0.033,0.021,0.066,0.043,0.102,0.064c0.046,0.026,0.097,0.051,0.147,0.075c0.039,0.019,0.077,0.039,0.118,0.056
      c0.055,0.024,0.116,0.045,0.176,0.066c0.044,0.016,0.086,0.032,0.134,0.046c0.067,0.02,0.141,0.036,0.214,0.053
      c0.048,0.011,0.093,0.024,0.144,0.033c0.085,0.016,0.179,0.027,0.273,0.038c0.048,0.005,0.091,0.014,0.141,0.018
      c0.148,0.012,0.305,0.02,0.474,0.02c2.781,0,6.488-1.854,9.825,1.483c3.337,3.337,4.263,4.263,7.414,5.19s4.264,0.557,6.673,0.927
      s6.488,2.595,8.712-0.742s1.297-3.521,4.078-3.707s2.039-2.41,4.634-2.039s1.669,2.41,4.078,2.781
      c0.178,0.027,0.356,0.058,0.534,0.09c0.083,0.015,0.165,0.03,0.247,0.046c0.079,0.015,0.159,0.03,0.238,0.045
      c1.239,0.238,2.457,0.512,3.411,0.428c0.001,0,0.002,0,0.002,0c0.319-0.028,0.609-0.097,0.859-0.22
      c0.013-0.006,0.024-0.014,0.036-0.021c0.1-0.053,0.193-0.117,0.279-0.188c0.024-0.02,0.048-0.04,0.071-0.061
      c0.093-0.088,0.18-0.186,0.254-0.303c0.927-1.483,2.224-1.854,5.005-1.854s4.078-1.668,5.376-2.595
      c1.297-0.927,2.224-4.449,9.083-3.893s7.6,0.927,10.566-3.337s5.747-6.488,8.713-8.712s7.415-7.971,10.381-9.825
      c2.966-1.853,7.229-6.117,1.297-5.375c-5.932,0.741-7.23-0.37-10.381-1.668s-17.239-4.82-20.576-6.859s-6.858-3.151-7.044-5.19
      c-0.186-2.039-2.224-3.337-3.337-4.449c-1.112-1.112-3.707-5.005-1.668-6.673c0.176-0.144,0.332-0.288,0.478-0.431
      c0.035-0.034,0.071-0.068,0.104-0.103c0.137-0.14,0.266-0.279,0.38-0.418c0.016-0.019,0.029-0.038,0.044-0.056
      c0.101-0.125,0.192-0.25,0.277-0.374c0.024-0.035,0.048-0.07,0.071-0.105c0.087-0.134,0.168-0.267,0.241-0.4
      c0.018-0.033,0.034-0.065,0.051-0.097c0.057-0.109,0.111-0.216,0.16-0.323c0.018-0.039,0.037-0.077,0.054-0.115
      c0.055-0.122,0.105-0.243,0.153-0.362c0.023-0.059,0.046-0.118,0.069-0.176c0.024-0.062,0.047-0.124,0.071-0.186
      c0.07-0.186,0.136-0.369,0.204-0.547c-1.038,0.065-2.233,0.357-3.261,0.357c-1.321,0-0.973,1.112-2.085,1.043
      s-2.155-0.626-2.572,0.904c-0.417,1.529-1.182,0.278-2.294-0.695s-0.904-3.823-0.904-5.353s1.807-3.059,2.92-3.754
      c1.112-0.695,1.39-1.182,1.738-2.294c0.152-0.486,0.344-1.211,0.512-1.874c-1.088-0.893-1.994-2.314-2.527-2.992
      c-0.765-0.973-2.294-2.711-3.128-3.754s-3.198-2.363-3.962-2.989s-2.363-3.059-2.85-4.101s-2.155-1.738-2.711-1.738
      s-1.529,0.487-2.363-1.321s-5.561-1.112-7.368-1.112s-2.642,0.556-3.198-0.417c-0.556-0.973-2.224-1.529-2.92-0.417
      s-1.39,1.112-3.337,0.834c-1.946-0.278-2.363-0.973-3.615-2.781c-1.252-1.808-2.781-0.556-2.502,1.39
      c0.278,1.946-0.695,1.39-1.112,2.92c-0.417,1.529,0.695,2.085-0.695,2.224s-0.417-1.39-1.39-2.085s1.112-0.973-1.112-1.807
      s-1.251,2.085-2.781,1.529c-1.529-0.556-3.059-0.973-3.754,0.139c-0.345,0.553-0.759,0.899-1.088,1.108
      C590.837,261.622,590.839,261.844,590.839,262.069z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_64_" x="581.911" y="301.094" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_DEMOCRATICREPUBLICOFTHECONGO" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Democratic Republic of the Congo" dataWikipediaLink="https://en.wikipedia.org/wiki/Democratic_Republic_of_the_Congo" data-infotext="<b>Democratic Republic of the Congo</b> &amp;nbsp; Capital: Kinshasa" data-imageFile="CONGO.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="DEMOCRATICREPUBLICOFTHECONGO" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M397.887,439.964c3.522,0.278,15.663-1.761,16.775,1.298s1.02,5.561,2.317,7.137s2.41,6.395,3.893,7.971
      c1.483,1.576,3.151,3.151,5.283,3.151s2.966,0.371,4.078-0.463s2.41-0.928,4.078-0.186s4.635,0.834,4.82-1.297
      c0.185-2.132-0.001-2.502,0.926-3.337c0.927-0.834-0.37-4.356,1.576-4.634c1.946-0.278,5.469-0.093,6.951-0.186
      c1.483-0.092,0.37,3.152,2.873,3.244s9.547-0.649,9.639,0.741c0.092,1.39,1.019,2.41-0.186,2.873
      c-1.205,0.463-1.112,1.946-0.463,2.595s1.112,2.317,1.112,3.893c0,1.576,0.185,3.337-0.093,4.449
      c-0.278,1.112-0.834,2.318-0.556,3.8s0.463,3.43,1.946,4.449s1.761,2.781,1.946,4.171c0.185,1.39,0.835,3.43-0.37,4.171
      s-1.391,1.483-0.742,2.502s2.41,1.575,2.966,0.463s3.985-0.278,5.653-0.278s2.873-1.39,4.82-1.39c0.212,0,0.435-0.002,0.667-0.004
      c0.209-0.001,0.425-0.002,0.644-0.002c0.036,0,0.072,0.001,0.109,0.001c0.221,0.002,0.444,0.005,0.667,0.014
      c0.022,0.001,0.043,0.002,0.065,0.003c0.392,0.016,0.781,0.045,1.154,0.098c0.011,0.002,0.023,0.004,0.035,0.006
      c0.131,0.019,0.26,0.042,0.385,0.067c0.039,0.008,0.076,0.018,0.114,0.027c0.097,0.022,0.192,0.045,0.285,0.072
      c0.046,0.013,0.089,0.028,0.133,0.042c0.082,0.027,0.162,0.055,0.24,0.085c0.045,0.018,0.089,0.037,0.132,0.057
      c0.072,0.033,0.14,0.067,0.207,0.104c0.042,0.023,0.083,0.046,0.122,0.071c0.064,0.04,0.123,0.083,0.18,0.128
      c0.035,0.027,0.071,0.053,0.103,0.082c0.059,0.052,0.11,0.109,0.161,0.167c0.024,0.028,0.052,0.053,0.074,0.082
      c0.068,0.091,0.13,0.187,0.18,0.293c0.741,1.575,1.575,3.614,3.151,3.244c1.576-0.37,0.185-1.575,2.224-1.575
      s3.522-0.001,3.8,1.297c0.278,1.298,0.278,2.132,1.575,2.595s3.986,0.278,4.264,1.39s1.575,1.297,2.873,1.297
      s2.317,1.205,3.337,1.205c1.02,0,1.483-0.649,1.668-2.224s0.371-2.132,1.761-2.224c1.39-0.092,2.132-0.371,2.41,1.297
      s1.02,3.522,2.873,4.171c1.854,0.649,3.893,1.298,5.283,2.039s3.244,2.317,4.171,4.542c0.927,2.224,2.41,3.986,3.986,3.893
      c1.575-0.093,1.761-1.298,3.429-1.205s2.04-0.37,1.854-2.132c-0.186-1.761-0.279-2.132,0.37-3.615s1.204-2.688,0.556-3.708
      c-0.648-1.019-3.059-1.112-3.059,0.093s-0.926,2.41-2.688,1.946c-1.761-0.464-2.781-2.224-4.171-3.615s-3.8-2.966-3.708-4.635
      c0.093-1.668,0.278-3.708,1.668-5.005s0.556-3.985,0.556-5.375s0.834-5.005,0.093-6.859c-0.741-1.853-1.761-2.781-0.556-3.893
      s2.595-1.761,3.244-4.078s0.834-3.151,3.337-3.244s7.971,0.463,9.64-0.371c0.134-0.067,0.277-0.137,0.424-0.209
      c0.029-0.014,0.058-0.028,0.087-0.042c0.318-0.154,0.658-0.31,1.004-0.452h0.001c0.898-0.371,1.821-0.641,2.451-0.502
      c-0.003-0.01-0.005-0.02-0.008-0.03c-0.924-0.365-2.21-0.452-3.096-0.894c-1.24-0.62-1.682-3.72-2.125-5.313
      c-0.443-1.594-1.948-3.276-2.657-3.63s-1.859-1.417-2.125-2.745s-2.568-3.365-3.631-5.313s0.177-3.541,1.151-4.338
      c0.974-0.797,0.089-2.391-0.885-3.542s-0.089-4.162-0.089-5.047c0-0.745,0.125-3.553,0.112-5.271
      c-0.139-0.216-0.284-0.437-0.44-0.671c-1.112-1.668-0.927-6.303-0.927-8.156s-0.927-2.781-2.039-3.151
      c-0.336-0.112-0.551-0.479-0.64-0.954c-0.001-0.004-0.002-0.008-0.003-0.012c-0.013-0.072-0.021-0.148-0.029-0.224
      c-0.002-0.026-0.006-0.05-0.008-0.076c-0.005-0.067-0.005-0.137-0.005-0.206c0-0.038-0.001-0.076,0.001-0.115
      c0.002-0.064,0.008-0.128,0.013-0.192c0.004-0.047,0.009-0.094,0.015-0.142c0.008-0.06,0.018-0.119,0.029-0.179
      c0.01-0.054,0.02-0.108,0.032-0.161c0.012-0.056,0.027-0.111,0.042-0.167c0.016-0.058,0.033-0.115,0.052-0.173
      c0.017-0.052,0.035-0.103,0.055-0.154c0.023-0.06,0.048-0.118,0.074-0.176c0.022-0.048,0.043-0.095,0.067-0.142
      c0.03-0.059,0.063-0.116,0.097-0.173c0.026-0.043,0.051-0.087,0.079-0.129c0.038-0.057,0.079-0.11,0.121-0.164
      c0.03-0.038,0.058-0.077,0.089-0.113c0.047-0.053,0.098-0.103,0.15-0.152c0.032-0.031,0.062-0.064,0.096-0.093
      c0.06-0.052,0.125-0.096,0.19-0.141c0.031-0.021,0.059-0.046,0.091-0.065c0.1-0.061,0.205-0.116,0.318-0.161
      c1.854-0.741,1.854-1.854,1.298-2.781c-0.556-0.926,0-2.966,1.668-3.522c0.125-0.042,0.238-0.096,0.345-0.155
      c0.031-0.018,0.061-0.037,0.091-0.056c0.077-0.048,0.148-0.1,0.215-0.157c0.026-0.022,0.052-0.042,0.075-0.065
      c0.173-0.163,0.314-0.354,0.43-0.568c0.012-0.023,0.024-0.048,0.036-0.072c0.046-0.091,0.088-0.186,0.124-0.284
      c0.009-0.023,0.018-0.045,0.026-0.068c0.089-0.249,0.154-0.515,0.2-0.792c0.001-0.007,0.002-0.014,0.003-0.022
      c0.023-0.138,0.042-0.279,0.057-0.421c0-0.002,0.001-0.004,0.001-0.007c0.063-0.601,0.065-1.222,0.065-1.782
      c0-1.668,0.556-3.522,0.556-6.302c0-2.781,1.854-6.858,3.337-10.01c1.483-3.151,3.893-3.708,4.819-5.19
      c0.927-1.483,2.966-1.854,5.191-1.668c2.224,0.186,1.668-3.708,1.483-5.561c-0.186-1.853-1.483-0.556-3.151-0.927
      s-2.224-2.039-1.298-2.966c0.927-0.926,0.186-1.483,0.186-2.41s0.37-2.595,1.112-3.151s0.741-1.112-0.371-2.41
      c-0.186-0.217-0.305-0.488-0.382-0.785c-0.001,0.001-0.002,0.001-0.002,0.001c-0.051,0.029-0.104,0.055-0.16,0.079
      c-0.021,0.009-0.041,0.017-0.062,0.026c-0.047,0.018-0.096,0.035-0.147,0.05c-0.041,0.011-0.084,0.02-0.127,0.029
      c-0.04,0.008-0.079,0.018-0.122,0.024c-0.092,0.013-0.189,0.021-0.295,0.021c-1.853,0,0-2.781-3.151-3.151
      s-3.337-2.224-5.005-5.191s-2.781-1.668-4.078,0s-2.224,0.927-3.893-0.37s-2.41,0.37-2.781,1.668
      c-0.371,1.298-2.039,0.556-3.708,0.556c-1.668,0-1.297-1.668-2.224-1.854s-2.039-1.297-2.039-2.966
      c0-1.668-2.039-0.927-2.595-2.595c-0.057-0.171-0.13-0.324-0.212-0.467c-0.01-0.018-0.02-0.037-0.03-0.055
      c-0.081-0.133-0.174-0.252-0.274-0.358c-0.015-0.016-0.03-0.032-0.046-0.047c-0.103-0.103-0.215-0.192-0.332-0.266
      c-0.013-0.008-0.026-0.014-0.038-0.022c-0.125-0.073-0.256-0.133-0.392-0.172c-0.087-0.025-0.176-0.042-0.266-0.052
      c-0.018-0.002-0.036-0.002-0.054-0.003c-0.072-0.006-0.144-0.008-0.217-0.004c-0.027,0.002-0.055,0.005-0.082,0.008
      c-0.064,0.006-0.127,0.016-0.191,0.029c-0.032,0.007-0.065,0.016-0.097,0.025c-0.059,0.016-0.118,0.036-0.177,0.059
      c-0.035,0.014-0.069,0.028-0.104,0.044c-0.056,0.026-0.112,0.056-0.167,0.089c-0.035,0.021-0.07,0.041-0.104,0.064
      c-0.055,0.036-0.109,0.079-0.162,0.122c-0.033,0.027-0.066,0.051-0.099,0.08c-0.056,0.051-0.112,0.109-0.166,0.168
      c-0.027,0.029-0.055,0.054-0.081,0.085c-0.08,0.093-0.157,0.193-0.231,0.304c-1.112,1.668-2.595,0.926-4.819,1.112
      s-1.668-1.112-4.264-1.668c-2.595-0.556-1.853-0.556-2.41-1.112c-0.556-0.556-2.966-0.371-2.966,1.112s-1.297,0.926-2.224,2.039
      c-0.927,1.112-2.781,1.483-3.337,0.742s-1.297-1.854-2.966-2.039s-0.927,1.483-3.151,1.668s-1.483,0.741-3.707,2.224
      s-2.595,0.371-4.635-0.741c-2.039-1.112-4.264,1.483-4.449,3.893s-2.595,2.781-5.376,1.483s-2.595-0.185-5.561-0.556
      s-2.966-1.112-5.005-1.112s-2.966-0.37-3.522-2.039c-0.556-1.668-1.668-2.41-3.708-4.264c-2.039-1.853-5.931-0.927-7.229,1.112
      s0,1.853-3.707,3.708c-3.707,1.854-1.298,2.966-0.927,5.19c0.031,0.187,0.055,0.37,0.075,0.551c0.005,0.043,0.01,0.086,0.014,0.128
      c0.036,0.36,0.051,0.704,0.042,1.027c-0.001,0.035-0.002,0.07-0.003,0.104c-0.013,0.321-0.049,0.618-0.11,0.885
      c-0.006,0.027-0.012,0.053-0.018,0.079c-0.066,0.262-0.156,0.493-0.271,0.684c-0.011,0.018-0.022,0.036-0.033,0.053
      c-0.061,0.093-0.126,0.178-0.2,0.25c0.21,1.112,0.318,2.21,0.318,3.098c0,1.668-0.371,3.151-3.337,5.747s-2.039,4.634-2.224,8.712
      s-2.41,7.971-4.264,11.308c-1.853,3.337-2.966,5.005-3.708,8.342s-3.522,6.673-6.302,9.083c-2.781,2.41-1.483,7.785-1.483,10.566
      s0.186,4.634-1.297,7.044s-2.41,1.854-5.005,2.224s-0.741,2.41,0.185,3.337s0.927,1.668,0.742,2.966s-2.224-0.185-5.005-0.741
      s-1.112,0.926-2.781,2.781c-1.668,1.854-2.41,0-2.41-1.668s0-2.224-2.966-2.781c-2.966-0.556-1.854,0.556-4.634,2.966
      s-1.483,1.297-3.893,0.556c-0.14-0.043-0.263-0.083-0.376-0.122c-0.037-0.012-0.068-0.024-0.102-0.036
      c-0.072-0.025-0.142-0.05-0.203-0.074c-0.034-0.013-0.066-0.026-0.096-0.039c-0.053-0.022-0.102-0.043-0.147-0.064
      c-0.026-0.012-0.053-0.024-0.077-0.036c-0.042-0.021-0.08-0.042-0.115-0.062c-0.017-0.01-0.035-0.02-0.051-0.03
      c-0.096-0.062-0.161-0.123-0.205-0.185c0-0.001,0-0.001-0.001-0.001c-0.172-0.243-0.054-0.52-0.35-1.067
      c-0.001-0.001-0.001-0.002-0.002-0.004c-0.483,0.856-0.839,2.041-1.242,2.646c-0.556,0.834-1.854,1.297-2.41,1.946
      c-0.556,0.649-0.371,3.244-0.556,5.375c-0.108,1.239-1.404,1.474-2.472,1.456c-0.003,0.52,0.008,0.984,0.064,1.432
      c1.738-0.389,6.112-1.312,8.526-1.312C387.785,439.872,394.366,439.686,397.887,439.964z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_63_" x="388.74" y="422.217" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>

      <rect id="FLAG_4_" x="457.39" y="397.409" opacity="0" fill="#0A11FF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="25.1" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_TANZANIA" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Tanzania" dataWikipediaLink="https://en.wikipedia.org/wiki/Tanzania" data-infotext="<b>Tanzania</b> &amp;nbsp; Capital: Dodoma" data-imageFile="TANZANIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="TANZANIA" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M625.589,480.146c-1.644-2.242-4.035-1.195-4.782-4.334c-0.747-3.138-1.196-5.231-2.242-6.875s-2.989-5.082-1.943-6.875
      s1.943-3.438,0.747-4.484s-1.196-1.495-0.598-2.989c0.598-1.495,2.84-3.587,1.196-4.782c-1.644-1.196-2.092-2.84-3.587-3.288
      s-2.092-1.644-2.242-3.886c-0.149-2.242-0.149-2.989,0.598-4.185c0.747-1.195,1.943-5.53,2.69-7.323
      c0.11-0.263,0.257-0.508,0.426-0.746c-3.998-3.197-11.147-9.098-12.577-9.415c-1.668-0.37-1.854-1.854-1.297-4.449
      s-21.503-12.42-26.137-15.386c-0.353-0.226-0.687-0.443-1.011-0.654c-0.08-0.053-0.156-0.103-0.235-0.154
      c-0.257-0.17-0.506-0.335-0.745-0.495c-0.061-0.042-0.125-0.084-0.186-0.125c-0.595-0.403-1.132-0.78-1.614-1.135
      c-0.031-0.023-0.061-0.046-0.092-0.068c-0.223-0.165-0.435-0.326-0.635-0.482c-0.015-0.013-0.031-0.024-0.047-0.036
      c-0.44-0.345-0.824-0.671-1.16-0.982c-0.006-0.006-0.012-0.011-0.018-0.017c-0.167-0.155-0.321-0.306-0.464-0.455
      c-0.246-0.256-0.452-0.503-0.633-0.746c0.033,0.254,0.071,0.507,0.113,0.755c0.16,0.954,0.354,1.824,0.354,2.231
      c0,0.973-1.043,0.626-2.294,0.973s-1.46,1.599-1.877,2.363c-0.417,0.765-0.765,1.112-1.946,1.112c-1.182,0-0.834,1.251-0.973,2.016
      c-0.139,0.765-1.043,0.417-1.112-0.348c-0.07-0.765-0.556-0.695-1.251-0.695s-0.626,0.487-0.348,1.877
      c0.278,1.39,0.626,0.695,0.973,1.043c0.348,0.348,1.946,0.278,3.198,0.487c1.251,0.209,0,0.626,0,1.877s-0.139,1.182-1.529,1.182
      s-0.417-1.112-0.973-1.668s-2.016,0.348-2.294,1.182s-0.834,1.251-0.834,0.487s-0.765-1.46-1.668-1.529
      c-0.904-0.07-0.904-0.556-0.904-1.251s-0.904-0.695-1.251-1.182c-0.348-0.487-0.834,0.834-1.599,0.834s-1.182,0.278-1.182,1.043
      s-0.209,1.321-1.112,1.321c-0.904,0-0.556-2.155-0.973-2.294s-0.834-0.834-0.834-1.668s0-1.529,1.182-2.502
      s0.487-2.016,0.417-3.476s1.39-1.043,1.807-1.738s-0.765-0.556-0.904-1.251c-0.014-0.067-0.029-0.137-0.045-0.206
      c-1.662,0.021-3.096,0.021-3.662,0.021c-0.927,0-4.542,0-6.488-0.185c-0.142-0.014-0.274-0.018-0.402-0.017
      c-0.041,0-0.08,0.002-0.12,0.004c-0.085,0.003-0.168,0.008-0.247,0.017c-0.04,0.004-0.08,0.009-0.119,0.014
      c-0.08,0.011-0.156,0.026-0.23,0.042c-0.029,0.007-0.059,0.011-0.086,0.018c-0.096,0.024-0.187,0.051-0.274,0.081
      c-0.035,0.013-0.068,0.026-0.102,0.039c-0.054,0.021-0.107,0.041-0.158,0.064c-0.041,0.017-0.082,0.036-0.122,0.055
      c-0.038,0.018-0.076,0.036-0.113,0.055c-0.202,0.1-0.393,0.207-0.584,0.305c0.402,0.311,0.85,0.758,0.798,1.176
      c-0.093,0.742-0.928,2.317,0.092,2.595s1.484,1.297,1.298,2.132c-0.186,0.834-0.557,2.132,0.092,2.595s1.298,1.391,0.649,1.761
      s-0.649,1.205-0.649,1.668s-0.185,1.204-1.297,1.019c-0.118-0.02-0.235-0.022-0.35-0.02c-0.031,0-0.064-0.001-0.095,0.002
      c-0.018,0.001-0.035,0.007-0.053,0.008c-0.172,0.017-0.335,0.058-0.49,0.115c-0.042,0.016-0.087,0.028-0.128,0.047
      c-0.06,0.027-0.116,0.06-0.172,0.091c-0.046,0.027-0.092,0.052-0.135,0.081c-0.049,0.033-0.093,0.069-0.138,0.105
      c-0.044,0.036-0.087,0.074-0.127,0.113c-0.037,0.036-0.072,0.074-0.104,0.112c-0.04,0.047-0.076,0.096-0.109,0.146
      c-0.025,0.037-0.05,0.073-0.071,0.111c-0.033,0.059-0.057,0.121-0.08,0.183c-0.012,0.032-0.028,0.064-0.037,0.096
      c-0.026,0.097-0.043,0.194-0.043,0.292c0,0.834,0.093,2.132,1.39,2.132s2.781-0.277,2.781,0.649s0,2.039-1.112,2.41
      s-1.668,0.463-2.317,2.132c-0.649,1.668-1.761,4.264-2.966,5.191c-1.205,0.926-2.502,2.41-3.337,2.781
      c-0.175,0.078-0.338,0.172-0.51,0.272c0.239,0.393,0.557,0.826,0.977,1.54c0.886,1.505-0.177,3.72-0.177,3.72
      c-1.505,2.126-0.355,4.072-0.355,6.198c0,2.125,0,1.594,1.151,5.313c0.443,2.391,2.391,1.859,4.074,3.453s1.151,3.276,1.151,5.224
      s2.391,3.631,3.365,4.959s1.594,2.745,1.771,3.365s-0.886,1.062-1.594,0.088c-0.124-0.17-0.299-0.3-0.493-0.414
      c0.03,0.022,0.056,0.05,0.083,0.076c0.012,0.011,0.025,0.021,0.036,0.034c0.049,0.053,0.09,0.117,0.127,0.187
      c0.01,0.02,0.023,0.035,0.033,0.056c0.041,0.092,0.073,0.196,0.092,0.315c0.278,1.761,1.019,3.429,2.595,3.059
      s2.966,0.371,3.893,2.039s1.391,1.205,2.688,1.853c1.297,0.649,5.004,0.371,5.931,1.761c0.057,0.086,0.115,0.167,0.175,0.244
      c0.024,0.031,0.049,0.059,0.074,0.089c0.036,0.045,0.072,0.09,0.109,0.131c0.033,0.036,0.067,0.069,0.1,0.104
      c0.031,0.032,0.061,0.065,0.093,0.096c0.038,0.036,0.078,0.07,0.118,0.104c0.03,0.026,0.059,0.053,0.09,0.078
      c0.043,0.035,0.089,0.067,0.134,0.1c0.029,0.021,0.058,0.044,0.088,0.065c0.048,0.033,0.099,0.063,0.149,0.094
      c0.031,0.018,0.06,0.038,0.092,0.056c0.053,0.03,0.109,0.059,0.164,0.088c0.033,0.017,0.063,0.034,0.097,0.051
      c0.058,0.028,0.118,0.055,0.178,0.082c0.036,0.016,0.07,0.033,0.107,0.048c0.06,0.026,0.124,0.051,0.188,0.075
      c0.041,0.016,0.08,0.032,0.122,0.047c0.065,0.024,0.133,0.047,0.2,0.07c0.046,0.016,0.09,0.031,0.137,0.047
      c0.07,0.023,0.143,0.045,0.216,0.067c0.05,0.015,0.1,0.03,0.152,0.046c0.074,0.022,0.152,0.044,0.231,0.066
      c0.056,0.016,0.112,0.032,0.17,0.047c0.08,0.022,0.164,0.043,0.248,0.065c0.061,0.016,0.122,0.033,0.186,0.048
      c0.087,0.022,0.178,0.045,0.269,0.067c0.069,0.017,0.137,0.034,0.209,0.052c0.094,0.023,0.192,0.046,0.29,0.07
      c0.072,0.017,0.143,0.035,0.218,0.052c0.14,0.033,0.281,0.067,0.429,0.102c0.083,0.02,0.162,0.039,0.249,0.059
      c3.151,0.742,3.244,1.02,5.839,1.02s4.727,0.648,5.283,2.039c0.556,1.39,1.298,2.318,1.576,4.357s0.185,7.136,1.205,7.878
      c0.811,0.59,1.555,1.485,1.829,3.081c0.002,0,0.003,0,0.004,0c0.109-0.017,0.219-0.025,0.335-0.022c0.004,0.001,0.008,0,0.011,0
      c0.117,0.004,0.239,0.018,0.367,0.046c0.005,0.001,0.011,0.003,0.017,0.004c0.112,0.024,0.231,0.06,0.354,0.104
      c0.03,0.01,0.06,0.021,0.091,0.033c0.134,0.052,0.272,0.11,0.421,0.185c1.854,0.926,3.337,0.926,4.635-0.186
      c1.297-1.112,2.41-0.741,3.522,0.186c1.112,0.926,4.078,2.224,5.561,1.297s3.151-1.298,4.264-0.371
      c1.112,0.927,2.224,2.781,3.893-0.926c1.668-3.708,2.965-4.263,4.819-2.781c1.854,1.483,2.41,2.781,5.191,0.556
      c2.781-2.224,1.854-0.186,5.561-2.41c3.088-1.853,5.667-2.555,9.654-5.092C626.143,481.054,625.929,480.61,625.589,480.146z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_62_" x="548.487" y="435.014" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_ZAMBIA" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Zambia" dataWikipediaLink="https://en.wikipedia.org/wiki/Zambia" data-infotext="<b>Zambia</b> &amp;nbsp; Capital: Lusaka" data-imageFile="ZAMBIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="ZAMBIA" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M540.948,460.63c-0.078-0.038-0.162-0.07-0.254-0.09c-0.629-0.14-1.554,0.133-2.451,0.502c-0.001,0-0.001,0-0.001,0
      c-0.345,0.142-0.685,0.299-1.004,0.452c-0.029,0.014-0.059,0.028-0.087,0.042c-0.146,0.071-0.289,0.142-0.424,0.209
      c-1.668,0.834-7.137,0.278-9.64,0.371c-2.502,0.093-2.688,0.926-3.337,3.244s-2.039,2.966-3.244,4.078s-0.185,2.039,0.556,3.893
      c0.741,1.853-0.093,5.469-0.093,6.859s0.834,4.078-0.556,5.375s-1.575,3.337-1.668,5.005s2.317,3.244,3.708,4.635
      c1.391,1.391,2.41,3.151,4.171,3.615c1.761,0.464,2.688-0.742,2.688-1.946s2.41-1.112,3.059-0.093
      c0.648,1.019,0.093,2.224-0.556,3.708c-0.649,1.483-0.556,1.853-0.37,3.615c0.186,1.761-0.186,2.224-1.854,2.132
      c-1.668-0.093-1.854,1.112-3.429,1.205s-3.059-1.668-3.986-3.893c-0.927-2.224-2.781-3.8-4.171-4.542
      c-1.39-0.741-3.43-1.39-5.283-2.039c-1.853-0.649-2.595-2.502-2.873-4.171c-0.278-1.669-1.019-1.39-2.41-1.297
      s-1.575,0.649-1.761,2.224s-0.648,2.224-1.668,2.224s-2.039-1.205-3.337-1.205s-2.595-0.185-2.873-1.297
      c-0.278-1.112-2.966-0.927-4.264-1.39c-1.297-0.463-1.297-1.297-1.575-2.595s-1.761-1.297-3.8-1.297s-0.648,1.205-2.224,1.575
      c-1.576,0.37-2.41-1.668-3.151-3.244c-0.05-0.106-0.111-0.202-0.18-0.293c-0.022-0.029-0.049-0.054-0.074-0.082
      c-0.051-0.059-0.102-0.116-0.161-0.167c-0.033-0.029-0.068-0.055-0.103-0.082c-0.058-0.045-0.117-0.088-0.18-0.128
      c-0.039-0.024-0.08-0.048-0.122-0.071c-0.067-0.036-0.135-0.071-0.207-0.104c-0.043-0.02-0.087-0.039-0.132-0.057
      c-0.078-0.03-0.158-0.059-0.24-0.085c-0.044-0.014-0.087-0.029-0.133-0.042c-0.092-0.027-0.188-0.05-0.285-0.072
      c-0.038-0.009-0.075-0.018-0.114-0.027c-0.125-0.025-0.254-0.048-0.385-0.067c-0.012-0.002-0.023-0.004-0.035-0.006
      c-0.15-0.022-0.304-0.039-0.459-0.054l-0.25,18.158l-17.517,0.556l0.139,28.778c0,0,3.059,5.005,4.727,6.395
      s3.059,3.198,4.31,3.476c1.11,0.247,1.782,1.046,2.016,3.941c0.005-0.001,0.01-0.001,0.015-0.001
      c0.677-0.055,1.333-0.098,1.943-0.16c0.014-0.001,0.028-0.002,0.042-0.003c0.272-0.028,0.532-0.06,0.783-0.099
      c0.039-0.006,0.073-0.014,0.111-0.02c0.195-0.032,0.382-0.07,0.561-0.112c0.068-0.016,0.133-0.035,0.199-0.052
      c0.15-0.041,0.291-0.089,0.427-0.141c0.059-0.022,0.118-0.043,0.174-0.067c0.179-0.079,0.349-0.167,0.497-0.273
      c1.298-0.927,8.712-0.927,10.381,0.185c0.633,0.421,1.398,1.111,2.135,1.934c0.84-0.059,1.799,0.006,2.869,0.291
      c2.781,0.741,3.151-0.186,5.932,0.741s4.448,3.707,6.858,0.741s4.635-10.01,10.381-11.122s6.673-2.78,6.858-5.375
      s1.484-4.078,4.635-4.449c0.186-0.022,0.381-0.037,0.578-0.048c0.066-0.004,0.136-0.005,0.203-0.008
      c0.135-0.005,0.271-0.01,0.41-0.012c0.079-0.001,0.159-0.001,0.238-0.001c0.133,0,0.266,0.001,0.401,0.003
      c0.082,0.002,0.165,0.003,0.247,0.005c0.141,0.004,0.281,0.008,0.423,0.014c0.074,0.003,0.147,0.005,0.221,0.009
      c0.183,0.008,0.366,0.016,0.548,0.026c0.015,0.001,0.029,0.002,0.044,0.002c0.246,0.013,0.49,0.025,0.732,0.037
      c0.028,0.001,0.055,0.002,0.084,0.003c0.281,0.014,0.554,0.024,0.821,0.031c0.084,0.002,0.171,0.005,0.253,0.007
      c0.095,0.002,0.186,0.001,0.278,0.001c0.097,0,0.194,0,0.287-0.002c0.09-0.002,0.174-0.006,0.261-0.009
      c0.09-0.004,0.179-0.008,0.265-0.014c0.083-0.006,0.161-0.014,0.24-0.022c0.081-0.009,0.161-0.019,0.238-0.031
      c0.073-0.011,0.144-0.023,0.213-0.037c0.073-0.015,0.141-0.033,0.209-0.052c0.062-0.017,0.125-0.034,0.181-0.054
      c0.065-0.023,0.121-0.051,0.178-0.079c0.049-0.023,0.1-0.045,0.143-0.071c0.06-0.037,0.109-0.083,0.158-0.127
      c0.027-0.025,0.061-0.046,0.085-0.073c0.01-0.012,0.014-0.028,0.024-0.04c0.055-0.07,0.105-0.144,0.136-0.23
      c0.036-0.101,0.056-0.214,0.056-0.339c0-2.039-3.336-5.561,1.854-6.673s12.419-4.264,15.385-5.376
      c0.173-0.065,0.344-0.121,0.514-0.172c0.052-0.015,0.103-0.028,0.155-0.042c0.12-0.033,0.24-0.065,0.358-0.092
      c0.054-0.012,0.107-0.023,0.161-0.035c0.119-0.024,0.237-0.047,0.355-0.066c0.044-0.008,0.088-0.015,0.131-0.022
      c0.142-0.022,0.282-0.04,0.42-0.054c0.014-0.002,0.029-0.004,0.043-0.005c1.55-0.161,2.817,0.051,3.432-0.239
      c0.001,0,0.001-0.001,0.002-0.001c-0.915-1.633-0.101-3.072-0.936-3.907c-0.927-0.927,0-2.966,0.927-4.078s1.112-1.854,0.556-4.264
      c-0.556-2.41,1.668-1.483,3.337-2.224c1.668-0.741,0.556-2.039-0.741-2.781c-1.297-0.741-0.556-4.82-0.556-6.858
      c0-2.039,0.185-5.191,1.297-5.747s1.668-1.668,1.854-3.707c0.185-2.039-1.668-3.708-2.781-4.82
      c-0.518-0.518-0.594-2.159-0.527-3.766c-0.147-0.035-0.291-0.069-0.429-0.102c-0.074-0.017-0.146-0.035-0.218-0.052
      c-0.098-0.023-0.196-0.047-0.29-0.07c-0.071-0.017-0.14-0.035-0.209-0.052c-0.091-0.022-0.182-0.045-0.269-0.067
      c-0.064-0.016-0.125-0.032-0.186-0.048c-0.084-0.022-0.168-0.043-0.248-0.065c-0.058-0.016-0.115-0.031-0.17-0.047
      c-0.078-0.022-0.156-0.043-0.231-0.066c-0.052-0.015-0.102-0.03-0.152-0.046c-0.073-0.022-0.146-0.044-0.216-0.067
      c-0.047-0.015-0.091-0.031-0.137-0.047c-0.068-0.023-0.136-0.046-0.2-0.07c-0.042-0.015-0.082-0.031-0.122-0.047
      c-0.064-0.024-0.128-0.049-0.188-0.075c-0.037-0.015-0.072-0.032-0.107-0.048c-0.06-0.027-0.121-0.054-0.178-0.082
      c-0.033-0.016-0.064-0.034-0.097-0.051c-0.055-0.029-0.111-0.058-0.164-0.088c-0.031-0.018-0.061-0.038-0.092-0.056
      c-0.051-0.032-0.101-0.062-0.149-0.094c-0.03-0.021-0.059-0.043-0.088-0.065c-0.046-0.033-0.091-0.066-0.134-0.1
      c-0.031-0.024-0.06-0.052-0.09-0.078c-0.04-0.034-0.08-0.067-0.118-0.104c-0.032-0.03-0.062-0.064-0.093-0.096
      c-0.034-0.035-0.068-0.068-0.1-0.104c-0.037-0.041-0.073-0.087-0.109-0.131c-0.024-0.03-0.05-0.058-0.074-0.089
      c-0.06-0.078-0.118-0.159-0.175-0.244c-0.927-1.39-4.634-1.112-5.931-1.761c-1.297-0.649-1.761-0.185-2.688-1.853
      s-2.317-2.41-3.893-2.039s-2.317-1.297-2.595-3.059c-0.019-0.119-0.051-0.223-0.092-0.315c-0.009-0.021-0.022-0.036-0.033-0.056
      c-0.037-0.069-0.077-0.134-0.127-0.187c-0.011-0.013-0.024-0.022-0.036-0.034c-0.051-0.049-0.108-0.09-0.169-0.125
      C540.981,460.649,540.966,460.639,540.948,460.63z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_61_" x="469.94" y="506.915" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_ZIMBABWE" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Zimbabwe" dataWikipediaLink="https://en.wikipedia.org/wiki/Zimbabwe" data-infotext="<b>Zimbabwe</b> &amp;nbsp; Capital: Harare" data-imageFile="ZIMBABWE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="ZIMBABWE" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M536.055,528.049c-0.049,0.045-0.098,0.09-0.158,0.127c-0.043,0.027-0.094,0.048-0.143,0.071c-0.057,0.028-0.113,0.055-0.178,0.079
      c-0.057,0.02-0.119,0.037-0.181,0.054c-0.067,0.018-0.136,0.037-0.209,0.052c-0.069,0.014-0.14,0.027-0.213,0.037
      c-0.077,0.012-0.156,0.022-0.238,0.031c-0.079,0.008-0.157,0.016-0.24,0.022c-0.086,0.006-0.175,0.01-0.265,0.014
      c-0.086,0.003-0.171,0.008-0.261,0.009c-0.093,0.002-0.191,0.002-0.287,0.002c-0.092-0.001-0.183,0.001-0.278-0.001
      c-0.083-0.001-0.169-0.004-0.253-0.007c-0.267-0.008-0.54-0.018-0.821-0.031c-0.028-0.001-0.055-0.002-0.084-0.003
      c-0.241-0.012-0.485-0.024-0.732-0.037c-0.015-0.001-0.029-0.001-0.044-0.002c-0.182-0.009-0.366-0.017-0.548-0.026
      c-0.074-0.003-0.147-0.006-0.221-0.009c-0.142-0.006-0.282-0.01-0.423-0.014c-0.083-0.002-0.165-0.004-0.247-0.005
      c-0.135-0.003-0.268-0.003-0.401-0.003c-0.08,0-0.16,0-0.238,0.001c-0.139,0.002-0.275,0.007-0.41,0.012
      c-0.067,0.003-0.137,0.004-0.203,0.008c-0.198,0.011-0.392,0.026-0.578,0.048c-3.151,0.371-4.449,1.854-4.635,4.449
      c-0.186,2.595-1.112,4.263-6.858,5.375c-5.746,1.112-7.971,8.156-10.381,11.122s-4.078,0.186-6.858-0.741
      c-2.781-0.927-3.151,0-5.932-0.741c-1.07-0.285-2.029-0.35-2.869-0.291c1.208,1.348,2.339,3.058,2.684,4.554
      c0.556,2.41,3.521,1.298,3.707,5.005s3.337,8.341,7.044,9.268s3.892,0.556,4.634,3.151s0.37,2.966,2.781,2.966
      s0.742,1.854,0.742,3.708s0.927,3.522,2.224,3.522s0.185,2.224,2.039,2.781s7.044,0.186,8.156,2.41
      c0.08,0.16,0.156,0.312,0.231,0.46c0.014,0.027,0.027,0.052,0.041,0.078c0.063,0.122,0.124,0.24,0.185,0.354
      c0.018,0.034,0.036,0.066,0.054,0.099c0.059,0.108,0.118,0.211,0.177,0.312c0.016,0.028,0.033,0.057,0.049,0.084
      c0.074,0.123,0.149,0.241,0.226,0.352c0,0.001,0,0.001,0,0.001c0.086,0.123,0.175,0.238,0.268,0.347
      c0.018,0.022,0.039,0.043,0.058,0.065c0.077,0.086,0.157,0.168,0.242,0.245c0.022,0.02,0.043,0.041,0.065,0.06
      c0.105,0.091,0.215,0.175,0.333,0.252c0.01,0.006,0.021,0.011,0.03,0.017c0.108,0.068,0.224,0.129,0.345,0.186
      c0.035,0.016,0.072,0.032,0.108,0.047c0.115,0.049,0.237,0.093,0.367,0.132c0.028,0.009,0.053,0.018,0.081,0.026
      c0.158,0.045,0.326,0.081,0.506,0.112c0.042,0.008,0.089,0.012,0.132,0.019c0.147,0.022,0.302,0.04,0.465,0.053
      c0.058,0.005,0.115,0.01,0.176,0.014c0.214,0.013,0.438,0.022,0.682,0.022c3.151,0,3.521,1.483,6.488,1.483
      c0.14,0,0.285-0.008,0.432-0.019c0.028-0.002,0.056-0.004,0.084-0.006c0.141-0.013,0.285-0.028,0.431-0.048
      c0.038-0.005,0.076-0.011,0.115-0.016c0.127-0.018,0.255-0.037,0.386-0.059c0.081-0.014,0.163-0.028,0.246-0.042
      c0.07-0.012,0.14-0.025,0.211-0.038c2.257-0.411,4.89-1.046,6.413,1.066h0.001c1.109-0.991,2.145-2.097,2.804-2.691
      c0.927-0.834,5.468-5.561,6.58-6.951s1.483-4.82,1.483-6.673c0-1.854,1.853-2.688,2.966-3.986c1.112-1.297,1.668-2.039,1.946-3.151
      c0.278-1.112-0.093-1.205-1.205-1.853c-1.112-0.649,0-3.337-0.648-4.171c-0.648-0.834-0.927-1.761-0.927-2.781
      c0-1.019,0.834-2.317,1.946-2.966c1.112-0.648,1.019-3.522,0.185-4.635c-0.834-1.112,0.649-3.985,0.649-5.283
      s-0.371-2.41-0.927-3.337s-0.463-2.781-1.297-4.819s-5.654-1.854-7.044-2.595c-1.39-0.741-4.819-1.946-6.209-2.781
      s-2.781-0.834-4.449-0.463c-1.668,0.371-3.151-0.464-3.337-1.854c-0.081-0.608-0.375-1.216-0.679-1.707
      c0,0.001-0.001,0.001-0.001,0.001C536.117,528.003,536.083,528.024,536.055,528.049z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_59_" x="502.705" y="547.353" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_BOTSWANA" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Botswana" dataWikipediaLink="https://en.wikipedia.org/wiki/Botswana" data-infotext="<b>Botswana</b> &amp;nbsp; Capital: Gaborone" data-imageFile="BOTSWANA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="BOTSWANA" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M525.416,586.402c-0.016-0.027-0.033-0.056-0.049-0.084c-0.059-0.1-0.118-0.204-0.177-0.312c-0.017-0.033-0.036-0.066-0.054-0.099
      c-0.061-0.114-0.123-0.232-0.185-0.354c-0.014-0.026-0.027-0.052-0.041-0.078c-0.075-0.148-0.152-0.3-0.231-0.46
      c-1.112-2.224-6.302-1.854-8.156-2.41c-1.854-0.556-0.741-2.781-2.039-2.781c-1.297,0-2.224-1.668-2.224-3.522
      c0-1.854,1.668-3.708-0.742-3.708c-2.41,0-2.039-0.37-2.781-2.966s-0.926-2.224-4.634-3.151s-6.858-5.561-7.044-9.268
      s-3.151-2.595-3.707-5.005c-0.345-1.496-1.477-3.206-2.684-4.554c-1.342,0.094-2.378,0.505-3.062,0.847
      c-1.112,0.556-2.781,0.185-5.376,1.112s-2.781,2.039-4.264,3.522s-2.781-0.556-3.522-2.966s-4.078,0-6.673,0
      s-3.893,0.926-6.488,1.112s-7.971,1.297-7.971,1.297l-0.371,32.995l-8.342,0.371v5.005c0,0,0,0.049,0,0.144
      c0,0.079,0,0.186,0,0.328c0,0.069,0,0.146,0,0.229c0,0.125,0,0.267,0,0.423c0,0.193,0,0.407-0.001,0.646c0,0.112,0,0.232,0,0.354
      c0,0.287,0,0.603,0,0.941c0,0.172,0,0.35,0,0.535c-0.002,4.051-0.006,11.057-0.017,18.591c1.052,0.17,2.4,0.558,3.076,1.442
      c1.205,1.576,1.484,3.893,2.41,4.635c0.926,0.741,0.185,2.966,1.297,3.337s1.113,1.854,0.649,2.781
      c-0.464,0.927-1.668,1.668-1.946,2.781c-0.278,1.112-0.279,2.132-0.186,3.059c0.093,0.926,0,3.151,0.834,1.946
      s1.947-1.761,4.078-1.483c2.132,0.278,4.449,1.205,6.117-1.019s2.317-2.503,4.078-3.615c1.761-1.112,2.966-2.04,3.8-4.357
      c0.834-2.317,0.65-5.839,2.41-5.931c1.761-0.093,4.635,0.092,5.283,1.761c0.648,1.668,1.668,2.596,5.005,2.966
      s4.448,1.668,7.6,0.37c3.151-1.297,6.025-2.224,6.581-4.634s-0.092-6.118,2.502-5.654c2.595,0.464,3.892-0.741,5.097-2.317
      c1.205-1.576,2.503-1.297,2.966-3.893c0.463-2.595-0.557-3.522,2.317-4.913c2.874-1.391,5.84-2.224,6.581-4.263
      s2.131-3.893,4.263-4.264s3.893-0.741,4.727-2.132c0.432-0.72,1.036-1.958,1.519-2.987v-0.001
      C525.564,586.643,525.489,586.525,525.416,586.402z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_58_" x="454.433" y="575.289" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_NAMIBIA" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Namibia" dataWikipediaLink="https://en.wikipedia.org/wiki/Namibia" data-infotext="<b>Namibia</b> &amp;nbsp; Capital: Windhoek" data-imageFile="NAMIBIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="NAMIBIA" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M418.092,643.962c2.781,0.556,2.78,0.371,3.151,2.595s-0.741,3.707,1.854,4.078s3.337-0.185,4.449,0.927s3.892-0.371,5.931,0
      s4.264,0.742,5.191-0.741c0.926-1.483,2.039-2.966,3.707-2.781s1.854,0.556,2.039-3.151c0.003-0.061,0.006-0.136,0.009-0.222
      c0.001-0.02,0.002-0.045,0.002-0.066c0.002-0.072,0.005-0.147,0.007-0.234c0.001-0.024,0.001-0.052,0.002-0.077
      c0.002-0.09,0.004-0.185,0.007-0.289c0.001-0.038,0.002-0.08,0.002-0.121c0.002-0.098,0.004-0.2,0.006-0.309
      c0.001-0.051,0.002-0.106,0.003-0.159c0.002-0.112,0.003-0.226,0.005-0.348c0-0.052,0.001-0.104,0.002-0.158
      c0.002-0.145,0.004-0.294,0.006-0.451c0-0.03,0.001-0.06,0.001-0.091c0.002-0.186,0.004-0.38,0.007-0.582
      c0-0.016,0.001-0.031,0.001-0.048c0.061-5.701,0.092-17.694,0.109-28.596c0.011-7.533,0.016-14.54,0.017-18.591
      c0-0.185,0-0.363,0-0.535c0-0.339,0-0.653,0-0.941c0-0.124,0-0.241,0-0.354c0.001-0.238,0.001-0.453,0.001-0.646
      c0-0.157,0-0.298,0-0.423c0-0.083,0-0.161,0-0.229c0-0.136,0-0.251,0-0.328c0-0.096,0-0.144,0-0.144v-5.005l8.342-0.371
      l0.371-32.995c0,0,5.375-1.112,7.971-1.297s3.893-1.112,6.488-1.112s5.932-2.41,6.673,0s2.039,4.449,3.522,2.966
      s1.668-2.595,4.264-3.522c2.595-0.927,4.264-0.556,5.376-1.112c0.684-0.342,1.72-0.753,3.062-0.847
      c-0.737-0.823-1.503-1.512-2.135-1.934c-1.668-1.112-9.083-1.112-10.381-0.185c-0.148,0.106-0.318,0.193-0.497,0.273
      c-0.055,0.024-0.116,0.045-0.174,0.067c-0.135,0.052-0.277,0.099-0.427,0.141c-0.066,0.017-0.131,0.036-0.199,0.052
      c-0.179,0.042-0.366,0.08-0.561,0.112c-0.037,0.006-0.073,0.014-0.111,0.02c-0.25,0.039-0.512,0.071-0.783,0.099
      c-0.014,0.001-0.028,0.002-0.042,0.003c-0.611,0.062-1.265,0.105-1.943,0.16c-0.025,0.002-0.051,0.005-0.076,0.007
      c-0.253,0.021-0.508,0.043-0.765,0.069c-0.302,0.031-0.607,0.066-0.911,0.109c-2.595,0.371-9.454,1.668-10.566,2.41
      c-1.112,0.741-10.566,0-12.605-0.741s-14.088-0.371-14.088-0.371l-3.893-4.449c0,0-32.625-0.185-36.889-0.185
      s-4.634-0.371-6.302-2.595s-6.673-1.297-7.229,0.371c-0.556,1.668-2.781,1.668-3.337,1.297c-0.556-0.371-1.853-1.483-3.151-1.483
      c-0.478,0-1.158,0.303-1.825,0.684c-0.136,3.22-0.069,6.554,0.935,8.06c1.793,2.69,6.576,7.025,7.174,10.014
      c0.598,2.989,1.943,5.53,3.288,7.771c1.345,2.242,1.644,3.438,2.69,6.725c1.046,3.288,2.541,5.829,4.484,8.22
      c1.943,2.391,4.484,4.782,4.783,6.875c0.299,2.092,0,4.783-0.449,7.473s1.345,7.921,2.391,11.358
      c1.047,3.438,0.897,3.587,1.794,8.22s-0.15,7.323,1.495,10.163c1.644,2.84,1.793,3.886,2.391,7.473s2.989,5.978,5.38,8.519
      c1.489,1.583,3.442,3.861,5.137,5.968c0.531-0.376,1.206-0.686,2.04-0.75C417.721,647.485,415.312,643.406,418.092,643.962z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_57_" x="388.74" y="575.289" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_KENYA" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Kenya" dataWikipediaLink="https://en.wikipedia.org/wiki/Kenya" data-infotext="<b>Kenya</b> &amp;nbsp; Capital: Nairobi" data-imageFile="KENYA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="KENYA" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M631.451,362.017c0.556-0.556,4.078-3.708,5.561-6.117c0.511-0.83,0.777-1.724,0.91-2.543c0,0,0,0-0.001,0
      c-0.953,0.084-2.171-0.188-3.411-0.428c-0.079-0.015-0.159-0.03-0.238-0.045c-0.083-0.015-0.165-0.03-0.247-0.046
      c-0.179-0.032-0.357-0.063-0.534-0.09c-2.41-0.371-1.483-2.41-4.078-2.781c-2.595-0.371-1.854,1.853-4.634,2.039
      c-2.781,0.186-1.854,0.37-4.078,3.707s-6.303,1.112-8.712,0.742s-3.522,0-6.673-0.927s-4.078-1.853-7.414-5.19
      c-3.337-3.337-7.044-1.483-9.825-1.483c-0.169,0-0.326-0.008-0.474-0.02c-0.05-0.004-0.093-0.013-0.141-0.018
      c-0.093-0.011-0.187-0.022-0.273-0.038c-0.051-0.009-0.096-0.022-0.144-0.033c-0.073-0.017-0.147-0.033-0.214-0.053
      c-0.047-0.014-0.089-0.03-0.134-0.046c-0.06-0.021-0.121-0.042-0.176-0.066c-0.041-0.017-0.079-0.037-0.118-0.056
      c-0.05-0.024-0.101-0.049-0.147-0.075c-0.036-0.021-0.068-0.042-0.102-0.064c-0.043-0.027-0.085-0.055-0.124-0.084
      c-0.03-0.022-0.058-0.044-0.086-0.067c-0.036-0.03-0.072-0.059-0.105-0.09c-0.026-0.023-0.05-0.046-0.073-0.07
      c-0.031-0.03-0.061-0.061-0.09-0.092c-0.021-0.023-0.042-0.046-0.061-0.068c-0.028-0.032-0.055-0.064-0.08-0.096
      c-0.017-0.021-0.034-0.042-0.05-0.062c-0.029-0.039-0.058-0.075-0.084-0.111c-0.017-0.022-0.032-0.042-0.048-0.064
      c-0.027-0.036-0.053-0.071-0.079-0.104c-0.016-0.021-0.031-0.039-0.047-0.058c-0.017-0.021-0.033-0.042-0.05-0.06
      c-0.017-0.018-0.034-0.034-0.05-0.05c-0.015-0.015-0.03-0.03-0.046-0.043c-0.019-0.016-0.039-0.027-0.059-0.039
      c-0.014-0.008-0.027-0.019-0.041-0.025c-0.033-0.015-0.067-0.024-0.104-0.028c-0.001,0-0.003-0.001-0.004-0.001
      c-0.04-0.004-0.084-0.001-0.13,0.011c-0.741,0.185-2.41-0.185-3.151-1.297c-0.742-1.112-0.556-1.854-2.781-1.854
      s-2.966,1.854-5.005,1.854s-2.966,1.297-3.522,3.522c-0.034,0.136-0.07,0.255-0.106,0.36c-0.027,0.077-0.055,0.139-0.084,0.202
      c-0.01,0.023-0.02,0.052-0.031,0.073c-0.037,0.075-0.076,0.137-0.116,0.193c-0.002,0.003-0.004,0.008-0.007,0.011
      c-0.042,0.058-0.087,0.106-0.133,0.147c-0.001,0.001-0.001,0.001-0.001,0.001c0.343,0.877,0.663,1.833,0.663,2.35
      c0,1.112,0.186,2.039,1.854,2.039s0.741,2.224,0.741,3.337s0.371,2.781,1.112,3.337s0.742,2.409,2.039,3.151
      s1.297,2.595,1.297,3.893s1.112,3.893-0.371,6.303s-2.966,2.781-4.078,4.634c-1.112,1.854-3.337,3.892-4.264,6.858
      c-0.203,0.649-0.368,1.344-0.497,2.048c0.525,0.305,0.916,0.674,1.447,0.755c0.904,0.139,1.251-1.112,2.503-1.112
      s2.433,1.112,2.016,1.807s-1.46,1.251-2.363,1.529c-0.904,0.278-1.738,0.626-2.641,1.738c-0.427,0.526-0.402,1.613-0.258,2.714
      c0.181,0.243,0.387,0.49,0.633,0.746c0.142,0.148,0.297,0.3,0.464,0.455c0.006,0.006,0.012,0.011,0.018,0.017
      c0.336,0.311,0.72,0.636,1.16,0.982c0.015,0.012,0.031,0.024,0.047,0.036c0.2,0.156,0.412,0.317,0.635,0.482
      c0.031,0.023,0.061,0.046,0.092,0.068c0.482,0.355,1.019,0.732,1.614,1.135c0.061,0.041,0.124,0.083,0.186,0.125
      c0.239,0.16,0.488,0.325,0.745,0.495c0.078,0.051,0.155,0.102,0.235,0.154c0.324,0.211,0.658,0.428,1.011,0.654
      c4.634,2.966,26.693,12.791,26.137,15.386s-0.371,4.078,1.297,4.449c1.43,0.318,8.579,6.218,12.577,9.415
      c0.983-1.388,2.779-2.487,3.161-4.784c0.449-2.69,1.495-4.633,2.84-6.277c1.345-1.644,0.897-1.644,1.793-4.334
      c0.897-2.69,1.495-3.587,2.989-4.035c1.495-0.449,1.943-0.598,2.69-2.242s1.793-4.036,3.736-3.886
      c1.027,0.079,1.928,0.195,2.705-0.15c0.152-4.03-1.027-3.59-1.72-4.283c-0.741-0.741-2.224-2.781-2.966-3.708
      s-0.185-24.654-0.185-27.805C630.895,365.724,630.895,362.574,631.451,362.017z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_56_" x="575.19" y="385.966" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>

      <rect id="FLAG_6_" x="591.779" y="371.974" opacity="0" fill="#0A11FF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="25.1" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_SOUTHAFRICA" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="South Africa" dataWikipediaLink="https://en.wikipedia.org/wiki/South_Africa" data-infotext="<b>South Africa</b> &amp;nbsp; Capital: Pretoria (Pretoria: administrative, Cape Town: legislative, Bloemfontein: judicial)" data-imageFile="SAFRICA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="SOUTHAFRICA" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M553.782,632.84c1.096,0,2.727-1.187,5.775-1.333c-0.27,1.05-0.72,2.054-1.072,3.323c-0.747,2.69-1.345,4.484-0.598,7.772
      c0.747,3.288-0.299,2.839-2.839,4.782c-2.541,1.943-7.473,5.829-10.462,10.163c-2.989,4.334-3.139,5.53-4.634,10.162
      c-1.495,4.633-10.162,9.266-12.255,11.807c-2.092,2.54-6.875,7.024-9.864,9.266s-6.427,3.886-7.921,5.829
      c-1.495,1.943-5.53,4.185-10.312,4.036c-4.782-0.15-4.782,0.149-4.932,1.943c-0.149,1.794-5.679,0.897-7.025,1.794
      c-1.345,0.897-5.828,0.897-8.369-0.299c-2.54-1.195-2.54-0.747-4.484,0c-1.943,0.747-4.782-0.149-7.921-0.149
      c-3.139,0-3.139,0.149-4.185,1.793s-7.473,1.345-10.013,1.345c-2.541,0-3.139,0.747-4.932,2.391s-2.84,1.644-5.38,1.047
      c-2.541-0.598-3.886-1.644-7.323-5.38c-3.438-3.736-1.943,0.149-3.886,0.149s-1.495-4.036-1.495-6.427s-1.495-3.288-2.989-5.081
      c-1.495-1.793-0.449-2.69,1.793-3.288s1.345-2.989,1.495-5.829c0.149-2.84-0.299-4.035-2.242-5.978s-2.84-4.184-4.932-6.725
      c-2.092-2.54-3.139-4.782-3.587-8.668c-0.449-3.886-1.495-6.875-3.288-9.416c-0.677-0.958-1.608-2.172-2.634-3.448
      c0.531-0.376,1.206-0.686,2.04-0.75c2.41-0.185,0-4.264,2.781-3.708c2.781,0.556,2.78,0.371,3.151,2.595s-0.741,3.707,1.854,4.078
      s3.337-0.185,4.449,0.927s3.892-0.371,5.931,0s4.264,0.742,5.191-0.741c0.926-1.483,2.039-2.966,3.707-2.781
      s1.854,0.556,2.039-3.151c0.003-0.061,0.006-0.136,0.009-0.222c0.001-0.02,0.002-0.045,0.002-0.066
      c0.002-0.072,0.005-0.148,0.007-0.234c0.001-0.024,0.001-0.052,0.002-0.077c0.002-0.09,0.004-0.185,0.007-0.289
      c0.001-0.038,0.002-0.08,0.002-0.121c0.002-0.098,0.004-0.2,0.006-0.309c0.001-0.052,0.002-0.106,0.003-0.159
      c0.002-0.112,0.003-0.227,0.005-0.348c0-0.052,0.001-0.104,0.002-0.158c0.002-0.144,0.004-0.295,0.006-0.451
      c0.001-0.03,0.001-0.06,0.001-0.091c0.002-0.186,0.004-0.38,0.007-0.582c0-0.016,0.001-0.031,0.001-0.048
      c0.061-5.698,0.092-17.691,0.109-28.596c1.052,0.17,2.4,0.558,3.076,1.442c1.205,1.576,1.484,3.893,2.41,4.635
      c0.926,0.741,0.185,2.966,1.297,3.337s1.113,1.854,0.649,2.781c-0.464,0.927-1.668,1.668-1.946,2.781
      c-0.278,1.112-0.279,2.132-0.186,3.059c0.093,0.926,0,3.151,0.834,1.946s1.947-1.761,4.078-1.483
      c2.132,0.278,4.449,1.205,6.117-1.019s2.317-2.503,4.078-3.615c1.761-1.112,2.966-2.04,3.8-4.357
      c0.834-2.317,0.65-5.839,2.41-5.931c1.761-0.093,4.635,0.092,5.283,1.761c0.648,1.668,1.668,2.596,5.005,2.966
      s4.448,1.668,7.6,0.37c3.151-1.297,6.025-2.224,6.581-4.634s-0.092-6.118,2.502-5.654c2.595,0.464,3.892-0.741,5.097-2.317
      s2.503-1.297,2.966-3.893c0.463-2.595-0.557-3.522,2.317-4.913c2.874-1.391,5.84-2.224,6.581-4.263s2.131-3.893,4.263-4.264
      s3.893-0.741,4.727-2.132c0.432-0.72,1.036-1.958,1.519-2.987l0,0c0.086,0.123,0.175,0.238,0.268,0.347
      c0.018,0.022,0.039,0.043,0.058,0.065c0.077,0.086,0.157,0.168,0.242,0.245c0.022,0.02,0.043,0.041,0.065,0.06
      c0.105,0.091,0.215,0.175,0.333,0.252c0.01,0.006,0.021,0.011,0.03,0.017c0.108,0.068,0.224,0.129,0.345,0.186
      c0.035,0.016,0.072,0.032,0.108,0.047c0.115,0.049,0.237,0.093,0.367,0.132c0.028,0.009,0.053,0.018,0.081,0.026
      c0.157,0.045,0.326,0.081,0.506,0.112c0.042,0.008,0.089,0.012,0.132,0.019c0.147,0.022,0.302,0.04,0.465,0.053
      c0.058,0.005,0.116,0.01,0.176,0.014c0.214,0.013,0.438,0.022,0.682,0.022c3.151,0,3.521,1.483,6.488,1.483
      c0.14,0,0.285-0.008,0.432-0.019c0.028-0.002,0.056-0.004,0.084-0.006c0.141-0.013,0.285-0.028,0.431-0.048
      c0.038-0.005,0.076-0.011,0.115-0.016c0.127-0.018,0.255-0.037,0.386-0.059c0.081-0.014,0.163-0.028,0.246-0.042
      c0.07-0.012,0.14-0.025,0.211-0.038c2.257-0.41,4.89-1.047,6.413,1.066c0.001,0,0.001,0,0.001,0.001
      c0.197,0.274,0.375,0.594,0.532,0.966c0.011,0.026,0.021,0.054,0.032,0.08c0.061,0.15,0.118,0.31,0.171,0.477
      c0.016,0.049,0.032,0.098,0.047,0.148c0.061,0.203,0.118,0.414,0.168,0.642c1.297,5.932,1.668,8.713,3.337,10.566
      c1.668,1.854,2.225,4.264,2.595,8.156c0.37,3.893-0.742,9.824-0.186,11.492c0.085,0.257,0.165,0.501,0.241,0.741
      c0.001,0.003,0.002,0.007,0.003,0.01c-0.52,0.001-1.322-0.073-2.075-0.45c-1.251-0.626-2.502-2.224-3.684-1.946
      s-2.016,1.321-2.363,2.433c-0.348,1.112-1.599,2.433-2.155,2.85s-1.043,1.668-1.043,2.641s-0.348,2.016,0.973,2.572
      s1.877,1.946,2.363,2.92c0.487,0.973,0.139,1.529,2.085,1.599c1.946,0.07,5.144,0.973,5.283-0.973s-0.208-3.198,0.626-3.615
      c0.338-0.169,0.836-0.43,1.309-0.68c0.001,0.002,0.002,0.004,0.002,0.005c0.035,0.104,0.071,0.204,0.109,0.3
      c0.004,0.01,0.009,0.021,0.013,0.032c0.102,0.257,0.216,0.483,0.341,0.671c0.023,0.035,0.046,0.071,0.07,0.103
      c0.028,0.037,0.057,0.068,0.086,0.101c0.033,0.037,0.066,0.073,0.1,0.104c0.027,0.024,0.054,0.047,0.081,0.067
      c0.042,0.031,0.085,0.056,0.128,0.078c0.024,0.012,0.047,0.026,0.071,0.035C553.637,632.823,553.707,632.84,553.782,632.84z
       M527.985,655.518c-0.082-1.4-0.988-2.553-2.47-2.8c-1.483-0.247-2.224-1.4-2.635-2.306c-0.412-0.906-1.565-2.058-2.47-1.976
      c0,0-2.552,0.164-2.882,1.235s-1.812,1.73-2.965,1.73s-2.306,1.071-2.306,2.553s-1.399,1.73-2.388,2.553s-3.624,1.236-3.294,2.965
      s1.812,1.318,2.059,2.8c0.247,1.482,1.977,3.211,2.883,4.694c0.906,1.483,1.647,1.812,3.129,1.812s1.894,0.247,2.223-1.318
      c0.33-1.565,1.318-3.788,3.541-3.953s4.2-0.165,4.776-1.812c0.577-1.647,0.906-3.376,1.647-4.118
      C527.574,656.836,528.067,656.918,527.985,655.518z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12" x="441.176" y="671.473" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.843" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_MOZAMBIQUE" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Mozambique" dataWikipediaLink="https://en.wikipedia.org/wiki/Mozambique" data-infotext="<b>Mozambique</b> &amp;nbsp; Capital: Maputo" data-imageFile="MOZAMB.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="MOZAMBIQUE" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M629.326,517.061c-1.345-1.495-1.345-0.299-1.345-3.736c0-3.438,0-5.381-0.598-7.025c-0.597-1.644-1.046-2.69,0-4.484
      c1.047-1.793-1.046-3.736-1.195-5.829c-0.149-2.092-0.149-6.128-0.149-8.967c0-2.252,0.747-3.847,0.238-5.528
      c-3.987,2.537-6.566,3.239-9.654,5.092c-3.707,2.224-2.781,0.186-5.561,2.41c-2.781,2.224-3.337,0.926-5.191-0.556
      c-1.854-1.483-3.151-0.927-4.819,2.781c-1.668,3.708-2.781,1.853-3.893,0.926s-2.781-0.556-4.264,0.371s-4.449-0.371-5.561-1.297
      s-2.224-1.298-3.522-0.186c-1.297,1.112-2.781,1.112-4.635,0.186c-0.149-0.074-0.288-0.134-0.421-0.185
      c-0.031-0.011-0.061-0.022-0.091-0.033c-0.123-0.043-0.242-0.08-0.354-0.104c-0.005-0.001-0.011-0.003-0.017-0.004
      c-0.128-0.027-0.25-0.041-0.367-0.046c-0.004,0-0.008,0.001-0.011,0c-0.115-0.003-0.226,0.004-0.335,0.022
      c-0.002,0-0.004,0-0.006,0.001c-0.158,0.026-0.31,0.073-0.461,0.137c-0.003,0.001-0.006,0.003-0.009,0.004
      c-0.119,0.051-0.239,0.121-0.359,0.192c0.104,0.297,0.162,0.631,0.162,1.058c0,1.599,0,1.599-1.112,2.433s-1.043,4.24-0.556,6.812
      c0.317,1.675-0.338,2.613-0.777,3.544c-0.235,0.499-0.408,0.994-0.336,1.6c0.144,1.198,1.94,2,3.497,2.861
      c0.701,0.388,1.354,0.787,1.786,1.24c1.074,1.129,0.986,1.675,0.859,2.218c0.972,1.155,2.106,2.391,3.544,3.83
      c3.893,3.893,2.224,2.41,1.668,5.375c-0.556,2.965-0.186,4.82,0,8.527c0.186,3.708-2.595,1.854-4.449,2.039
      s-1.483,2.966-0.186,6.488s-0.741,3.151-3.151,0.741s-2.781-2.039-5.191-5.561s-0.741-6.117,0.742-8.156
      c1.483-2.039,0.926-3.708,0.556-6.303s-4.078-2.781-6.303-2.595c-2.225,0.186-2.595,1.112-4.634-2.41
      c-2.039-3.522-2.966-1.853-3.337-0.741c-0.029,0.087-0.076,0.156-0.131,0.218c-0.008,0.009-0.017,0.017-0.027,0.026
      c-0.056,0.056-0.123,0.103-0.201,0.14c-0.001,0.001-0.001,0.001-0.002,0.001s-0.001,0.001-0.002,0.001
      c-0.616,0.291-1.879,0.079-3.432,0.239c-0.014,0.002-0.029,0.004-0.043,0.005c-0.138,0.015-0.278,0.033-0.42,0.054
      c-0.043,0.007-0.088,0.014-0.131,0.022c-0.117,0.02-0.236,0.042-0.355,0.066c-0.054,0.011-0.107,0.023-0.161,0.035
      c-0.118,0.027-0.238,0.059-0.358,0.092c-0.052,0.014-0.103,0.027-0.155,0.042c-0.17,0.051-0.341,0.106-0.514,0.172
      c-2.966,1.112-10.195,4.264-15.385,5.376s-1.854,4.634-1.854,6.673c0,0.125-0.02,0.238-0.056,0.339
      c-0.031,0.086-0.081,0.16-0.136,0.23c-0.009,0.012-0.013,0.028-0.023,0.04c0.305,0.492,0.599,1.1,0.679,1.707
      c0.185,1.39,1.668,2.225,3.337,1.854c1.668-0.371,3.059-0.371,4.449,0.463c1.39,0.834,4.819,2.039,6.209,2.781
      s6.21,0.557,7.044,2.595c0.834,2.038,0.741,3.892,1.297,4.819c0.556,0.927,0.927,2.039,0.927,3.337s-1.483,4.171-0.649,5.283
      s0.927,3.986-0.185,4.635c-1.112,0.648-1.946,1.946-1.946,2.966c0,1.019,0.279,1.946,0.927,2.781
      c0.648,0.834-0.464,3.522,0.648,4.171s1.483,0.741,1.205,1.853c-0.278,1.112-0.834,1.854-1.946,3.151s-2.966,2.132-2.966,3.986
      c0,1.853-0.371,5.283-1.483,6.673c-1.112,1.39-5.653,6.117-6.58,6.951c-0.659,0.594-1.695,1.7-2.804,2.691c0,0,0,0,0.001,0.001
      c0.197,0.274,0.376,0.593,0.532,0.966c0.011,0.026,0.021,0.054,0.032,0.08c0.06,0.15,0.118,0.31,0.171,0.477
      c0.016,0.049,0.032,0.098,0.047,0.148c0.061,0.203,0.118,0.414,0.168,0.642c1.297,5.932,1.668,8.713,3.337,10.566
      c1.668,1.854,2.225,4.264,2.595,8.156c0.37,3.893-0.742,9.824-0.186,11.492c0.085,0.256,0.165,0.501,0.241,0.741
      c0.001,0.003,0.002,0.007,0.003,0.01s0.002,0.006,0.003,0.01c0.199,0.628,0.361,1.207,0.478,1.757
      c0.008,0.04,0.017,0.08,0.025,0.12c0.051,0.254,0.092,0.504,0.121,0.751c0.005,0.04,0.008,0.081,0.013,0.122
      c0.027,0.253,0.044,0.504,0.044,0.754c0,0.09,0.003,0.197,0.009,0.312c0.002,0.03,0.004,0.064,0.006,0.096
      c0.005,0.093,0.012,0.19,0.021,0.293c0.003,0.034,0.006,0.07,0.009,0.104c0.01,0.116,0.023,0.236,0.037,0.362
      c0.003,0.025,0.006,0.05,0.009,0.076c0.097,0.813,0.276,1.788,0.545,2.589c0.001,0.003,0.002,0.008,0.004,0.011
      c0.035,0.103,0.071,0.204,0.109,0.3c0.004,0.01,0.009,0.021,0.013,0.032c0.102,0.257,0.217,0.483,0.341,0.671
      c0.023,0.035,0.046,0.071,0.07,0.103c0.028,0.037,0.057,0.068,0.086,0.101c0.033,0.037,0.066,0.073,0.1,0.104
      c0.027,0.024,0.054,0.046,0.081,0.067c0.042,0.031,0.085,0.056,0.128,0.078c0.024,0.012,0.047,0.026,0.071,0.035
      c0.07,0.025,0.14,0.042,0.215,0.042c1.096,0,2.727-1.187,5.775-1.333c0.302-1.177,0.377-2.413-0.175-4.15
      c-1.046-3.288-3.736-3.138-0.299-6.576c3.438-3.437,4.185-5.081,9.864-6.426s12.554-4.185,12.554-7.772
      c0-3.586-0.747-3.886,0.149-6.575c0.897-2.69,2.391-3.288,0.897-6.725c-1.495-3.438-0.597-5.829-1.644-8.668
      c-1.046-2.84-0.448-2.54-1.046-5.679c-0.598-3.138-0.149-3.138-2.092-4.782s-3.138-2.242-2.69-4.334
      c0.449-2.092,0.15-2.989,2.989-4.633c2.84-1.644,1.943-2.391,4.932-5.231s10.462-5.829,12.106-8.818
      c1.644-2.989,3.587-4.334,6.277-6.277s3.586-2.092,7.323-3.438c3.736-1.345,7.473-1.495,10.312-5.082
      c2.84-3.586,6.277-8.22,8.071-10.013s1.345-3.438,2.092-5.38C629.924,519.004,630.671,518.556,629.326,517.061z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_55_" x="537.141" y="581.168" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_BURUNDI" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Burundi" dataWikipediaLink="https://en.wikipedia.org/wiki/Burundi" data-infotext="<b>Burundi</b> &amp;nbsp; Capital: Gitega (former capital: Bujumbura)" data-imageFile="BURUNDI.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="BURUNDI" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M537.239,408.589c-0.926-0.834-1.483,0.648-2.595,0.648s-2.132,0.742-2.41,2.132c-0.278,1.39-0.742,1.575-2.132,1.575
      s-2.224-0.093-2.41-0.834c-0.185-0.741-0.834-0.649-2.502-0.649c-0.844,0-1.285,0.404-1.514,0.803
      c0.066,0.053,0.137,0.097,0.217,0.124c1.112,0.371,2.039,1.298,2.039,3.151s-0.185,6.488,0.927,8.156
      c0.155,0.233,0.301,0.453,0.44,0.668c-0.003-0.323-0.009-0.612-0.023-0.837c-0.089-1.417,0.708-1.505,1.771-0.177
      s1.417,1.151,1.594,2.391c0.093,0.652,0.262,1.036,0.528,1.471c0.172-0.1,0.335-0.194,0.51-0.272
      c0.834-0.37,2.132-1.854,3.337-2.78c1.205-0.926,2.317-3.522,2.966-5.191c0.649-1.668,1.205-1.761,2.317-2.132
      s1.112-1.483,1.112-2.41s-1.483-0.649-2.781-0.649s-1.39-1.297-1.39-2.132c0-0.098,0.016-0.195,0.043-0.292
      c0.009-0.033,0.025-0.064,0.037-0.096c0.023-0.062,0.047-0.123,0.08-0.183c0.021-0.039,0.046-0.074,0.071-0.111
      c0.033-0.05,0.068-0.099,0.109-0.146c0.033-0.039,0.067-0.075,0.104-0.112c0.04-0.04,0.083-0.077,0.127-0.113
      c0.045-0.037,0.089-0.072,0.138-0.105c0.043-0.029,0.089-0.055,0.135-0.081c0.056-0.032,0.111-0.065,0.172-0.091
      c0.041-0.018,0.085-0.031,0.128-0.047c0.155-0.058,0.319-0.098,0.49-0.115c0.017-0.002,0.034-0.007,0.052-0.008
      C538.337,409.665,537.759,409.056,537.239,408.589z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_54_" x="504.622" y="411.694" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
   <circle fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeMiterlimit="10" cx="531.28" cy="425.494" r="13.8" pointerEvents="auto" opacity="0.001" className="clickarea landarea"></circle>
</g>
<g id="AREA_SOMALIA" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Somalia" dataWikipediaLink="https://en.wikipedia.org/wiki/Somalia" data-infotext="<b>Somalia</b> &amp;nbsp; Capital: Mogadishu" data-imageFile="SOMALIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="SOMALIA" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M721.538,281.822c-2.092-1.345-3.736-1.794-5.679,0.149s-3.138,2.989-5.231,2.989s-3.138,1.495-5.231,1.495
      c-2.092,0-2.989,2.092-5.38,1.196c-2.392-0.897-3.288-0.747-4.932,0.149c-1.644,0.897-1.644,1.495-4.334,0.747
      c-2.69-0.747-3.886-0.149-5.829,1.495s-5.231,2.54-6.875,2.092c-1.644-0.449-4.184-2.242-6.277-0.747
      c-2.093,1.495-12.703,2.989-13.301,1.793s-1.046-2.242-3.438-4.035c-2.354-1.766-2.395-3.673-4.393-3.874
      c-0.426,0.737-0.611,1.511-1.021,2.228c-0.169,0.295-0.299,0.611-0.424,0.941l0,0c-0.067,0.178-0.135,0.361-0.204,0.547
      c-0.023,0.061-0.047,0.123-0.071,0.186c-0.023,0.059-0.045,0.117-0.069,0.176c-0.048,0.12-0.098,0.241-0.153,0.362
      c-0.017,0.039-0.035,0.077-0.054,0.115c-0.049,0.107-0.103,0.215-0.16,0.323c-0.017,0.032-0.033,0.064-0.051,0.097
      c-0.073,0.133-0.154,0.266-0.241,0.4c-0.023,0.035-0.047,0.07-0.071,0.105c-0.085,0.124-0.177,0.249-0.277,0.374
      c-0.015,0.018-0.028,0.037-0.044,0.056c-0.114,0.139-0.242,0.278-0.38,0.418c-0.033,0.034-0.068,0.068-0.104,0.103
      c-0.147,0.143-0.302,0.287-0.478,0.431c-2.039,1.668,0.556,5.561,1.668,6.673s3.151,2.41,3.337,4.449s3.707,3.151,7.044,5.19
      c3.337,2.039,17.425,5.561,20.576,6.859s4.449,2.41,10.381,1.668c5.932-0.741,1.668,3.522-1.297,5.375
      c-2.966,1.854-7.415,7.6-10.381,9.825s-5.746,4.449-8.713,8.712c-2.966,4.264-3.707,3.893-10.566,3.337s-7.785,2.966-9.083,3.893
      c-1.297,0.927-2.595,2.595-5.376,2.595s-4.078,0.371-5.005,1.854c-0.073,0.117-0.16,0.214-0.254,0.303
      c-0.023,0.022-0.047,0.041-0.071,0.061c-0.086,0.072-0.179,0.136-0.279,0.188c-0.012,0.007-0.024,0.015-0.036,0.021
      c-0.25,0.124-0.54,0.192-0.859,0.22c-0.001,0-0.001,0-0.001,0c-0.133,0.818-0.399,1.712-0.91,2.543
      c-1.483,2.41-5.005,5.561-5.561,6.117c-0.556,0.556-0.556,3.707-0.556,6.858s-0.557,26.878,0.185,27.805s2.225,2.966,2.966,3.708
      c0.693,0.693,1.872,0.253,1.72,4.283c0.692-0.308,1.286-0.982,1.779-2.391c1.046-2.989,2.242-7.174,4.782-9.116
      c2.541-1.943,7.772-8.369,10.761-11.06c2.989-2.69,10.611-10.611,14.198-10.91s8.668-4.782,12.404-8.519
      c3.736-3.736,12.106-10.91,13.75-14.796s4.484-5.231,5.53-7.921c1.047-2.69,4.185-6.725,4.185-9.117
      c0-2.391,1.195-4.782,3.736-6.725c2.54-1.943,2.391-6.127,5.231-8.967c2.84-2.839,3.138-5.38,4.484-8.07
      c1.345-2.69,1.495-3.288,1.793-6.128c0.299-2.839,3.138-4.334,3.138-6.575c0-2.242-0.597-3.736-0.747-6.725
      C720.641,284.662,723.63,283.167,721.538,281.822z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_53_" x="634.494" y="358.534" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_MALAWI" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Malawi" dataWikipediaLink="https://en.wikipedia.org/wiki/Malawi" data-infotext="<b>Malawi</b> &amp;nbsp; Capital: Lilongwe" data-imageFile="MALAWI.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="MALAWI" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M559.37,514.448c0.009-0.008,0.018-0.016,0.027-0.026c0.055-0.061,0.103-0.131,0.131-0.218c0.371-1.112,1.297-2.781,3.337,0.741
      c2.039,3.522,2.41,2.596,4.634,2.41c2.224-0.186,5.932-0.001,6.303,2.595s0.927,4.264-0.556,6.303
      c-1.483,2.039-3.152,4.633-0.742,8.156c2.41,3.522,2.781,3.151,5.191,5.561s4.448,2.781,3.151-0.741
      c-1.297-3.522-1.668-6.303,0.186-6.488s4.635,1.669,4.449-2.039c-0.186-3.708-0.556-5.562,0-8.527
      c0.556-2.965,2.224-1.483-1.668-5.375c-1.439-1.439-2.573-2.675-3.544-3.83c-0.037,0.16-0.078,0.319-0.094,0.493
      c-0.07,0.765-1.321,1.043-2.572,0.417c-1.251-0.626-1.251,0.765-2.711,0.626s-0.487-4.449-0.695-5.909s-0.834-2.085-1.738-3.337
      c-0.904-1.251-1.043-3.545-1.182-4.588s-0.904-2.224-1.46-2.781s-0.209-2.572,0.834-2.92s-0.417-1.738,0.348-2.502
      c0.765-0.765,0.834-1.39,0.904-2.433s-0.208-2.502-0.695-3.128c-0.487-0.626-0.417-1.182-0.278-3.198s0.556-3.337-0.07-4.588
      c-0.626-1.251-1.39-2.502-1.39-2.502c-0.834-3.059,2.016-0.765,2.641,0.417s0.973,3.545,1.112,5.77
      c0.139,2.224,0.417,4.31,1.668,5.909c0.916,1.17,1.567,1.673,1.852,2.484c0.122-0.072,0.242-0.137,0.361-0.189
      c0.003-0.001,0.007-0.003,0.009-0.004c0.151-0.065,0.304-0.111,0.461-0.137c0.001,0,0.002-0.001,0.003-0.001
      c-0.274-1.596-1.018-2.491-1.829-3.081c-1.02-0.742-0.927-5.839-1.205-7.878s-1.02-2.966-1.576-4.357
      c-0.556-1.39-2.688-2.039-5.283-2.039s-2.688-0.278-5.839-1.02c-0.086-0.02-0.166-0.039-0.249-0.059
      c-0.067,1.608,0.009,3.249,0.527,3.766c1.112,1.112,2.966,2.781,2.781,4.82s-0.741,3.151-1.854,3.707
      c-1.112,0.556-1.297,3.708-1.297,5.747s-0.741,6.117,0.556,6.858c1.297,0.741,2.41,2.039,0.741,2.781
      c-1.668,0.741-3.893-0.185-3.337,2.224c0.556,2.41,0.371,3.151-0.556,4.264c-0.927,1.112-1.854,3.151-0.927,4.078
      c0.836,0.835,0.021,2.274,0.936,3.907c0.001,0,0.001-0.001,0.002-0.001C559.247,514.551,559.314,514.504,559.37,514.448z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_52_" x="538.222" y="495.815" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_UGANDA" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Uganda" dataWikipediaLink="https://en.wikipedia.org/wiki/Uganda" data-infotext="<b>Uganda</b> &amp;nbsp; Capital: Kampala" data-imageFile="UGANDA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="UGANDA" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M539.086,396.858c0.04-0.019,0.081-0.037,0.122-0.055c0.051-0.023,0.104-0.043,0.158-0.064c0.034-0.013,0.067-0.027,0.102-0.039
      c0.087-0.03,0.179-0.058,0.274-0.081c0.028-0.007,0.057-0.012,0.086-0.018c0.074-0.016,0.15-0.031,0.23-0.042
      c0.039-0.005,0.079-0.01,0.119-0.014c0.079-0.009,0.161-0.014,0.247-0.017c0.04-0.002,0.079-0.004,0.12-0.004
      c0.128-0.001,0.26,0.004,0.402,0.017c1.946,0.185,5.561,0.185,6.488,0.185c0.566,0,2,0,3.662-0.021
      c-0.149-0.645-0.399-1.343-0.65-1.531c-0.278-0.209-0.348-0.765-0.348-1.946s0.765-0.626,0.973-1.599s0.209-0.904,0.973-1.39
      c0.765-0.487,0.209-1.251,0.834-1.946s2.224-0.07,3.406-0.07s2.224-0.417,2.85-0.278c0.626,0.139,1.599-0.278,2.363-0.278
      s-0.417-2.085,0.278-2.085s0.973,0.278,1.182,1.112c0.208,0.834,0.973,0.278,1.112-0.556s0.626-0.556,1.599-0.417
      s0.417,0.487,0.417,1.46s0.765,0.695,0.765,0.695c0.602,0.029,1.034,0.212,1.403,0.426c0.13-0.705,0.295-1.399,0.497-2.048
      c0.926-2.966,3.151-5.005,4.264-6.858c1.112-1.853,2.595-2.224,4.078-4.634c1.483-2.41,0.371-5.005,0.371-6.303
      s0-3.151-1.297-3.893s-1.298-2.595-2.039-3.151s-1.112-2.224-1.112-3.337c0-1.112,0.927-3.337-0.741-3.337
      s-1.854-0.927-1.854-2.039c0-0.517-0.32-1.473-0.663-2.35c-0.029,0.026-0.06,0.046-0.09,0.066c-0.014,0.009-0.027,0.018-0.04,0.026
      c-0.033,0.02-0.067,0.037-0.102,0.051c-0.007,0.003-0.013,0.005-0.02,0.008c-0.307,0.121-0.686,0.102-1.169,0.291
      c-0.012,0.004-0.024,0.01-0.035,0.015c-0.073,0.03-0.148,0.066-0.225,0.106c-0.015,0.008-0.03,0.015-0.046,0.024
      c-0.091,0.051-0.185,0.108-0.282,0.177c-0.001,0.001-0.003,0.002-0.004,0.003c-0.091,0.065-0.186,0.142-0.283,0.227
      c-0.028,0.024-0.057,0.052-0.086,0.078c-0.076,0.069-0.153,0.146-0.232,0.229c-0.031,0.033-0.062,0.064-0.094,0.099
      c-0.109,0.119-0.22,0.247-0.336,0.395c-2.039,2.595-3.893,2.41-5.191,1.297c-1.298-1.112-2.966,0.556-5.005,0.556
      s-1.854,0.927-2.781,1.854s-2.039,0.37-3.151-0.927s-3.893,0-6.488-0.371c-0.148-0.021-0.285-0.033-0.415-0.039
      c-0.04-0.002-0.077-0.002-0.116-0.002c-0.088-0.002-0.173-0.001-0.254,0.005c-0.037,0.002-0.074,0.005-0.11,0.009
      c-0.083,0.009-0.16,0.021-0.235,0.035c-0.023,0.005-0.047,0.008-0.07,0.014c-0.195,0.046-0.363,0.113-0.511,0.195
      c-0.011,0.007-0.022,0.013-0.034,0.02c-0.067,0.039-0.131,0.08-0.191,0.124c-0.004,0.003-0.009,0.007-0.013,0.009
      c-0.453,0.336-0.736,0.793-1.21,1.066c-0.002,0.001-0.003,0.003-0.005,0.004c0.077,0.297,0.197,0.569,0.382,0.785
      c1.112,1.298,1.113,1.854,0.371,2.41s-1.112,2.224-1.112,3.151s0.741,1.484-0.186,2.41s-0.37,2.595,1.298,2.966
      c1.668,0.371,2.965-0.926,3.151,0.927s0.742,5.747-1.483,5.561c-2.224-0.186-4.264,0.186-5.191,1.668
      c-0.927,1.483-3.336,2.039-4.819,5.19s-3.337,7.229-3.337,10.01s-0.556,4.634-0.556,6.302c0,0.559-0.002,1.181-0.065,1.782
      c0,0.001,0,0.002,0,0.002c0.513,0.41,1.004,0.752,1.733,0.812c1.112,0.092,0.648,0.556,1.575,1.205s1.483,0.186,1.575-0.648
      c0.093-0.834-0.092-1.39,0.927-1.946s0.556-1.02,1.946-1.02c0.112,0,0.216-0.01,0.317-0.024c0.027-0.004,0.052-0.008,0.078-0.012
      c0.086-0.015,0.169-0.034,0.249-0.058c0.011-0.003,0.023-0.006,0.034-0.009c0.095-0.029,0.187-0.064,0.276-0.103
      c0.009-0.004,0.018-0.008,0.028-0.013c0.088-0.038,0.174-0.079,0.26-0.123c0.192-0.098,0.382-0.204,0.584-0.305
      C539.01,396.894,539.048,396.876,539.086,396.858z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_51_" x="526.608" y="364.865" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_RWANDA" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Rwanda" dataWikipediaLink="https://en.wikipedia.org/wiki/Rwanda" data-infotext="<b>Rwanda</b> &amp;nbsp; Capital: Kigali" data-imageFile="RWANDA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="RWANDA" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M538.1,397.353c-0.09,0.039-0.181,0.073-0.276,0.103c-0.011,0.003-0.023,0.006-0.034,0.009c-0.08,0.023-0.163,0.042-0.249,0.058
      c-0.026,0.004-0.051,0.009-0.078,0.012c-0.101,0.014-0.205,0.024-0.317,0.024c-1.39,0-0.927,0.464-1.946,1.02
      s-0.834,1.112-0.927,1.946s-0.648,1.297-1.575,0.648s-0.463-1.112-1.575-1.205c-0.729-0.06-1.22-0.402-1.733-0.812
      c0,0.002-0.001,0.003-0.001,0.004c-0.015,0.142-0.034,0.283-0.057,0.421c-0.001,0.008-0.002,0.015-0.003,0.022
      c-0.047,0.277-0.112,0.543-0.2,0.792c-0.008,0.023-0.017,0.045-0.026,0.068c-0.037,0.098-0.079,0.193-0.124,0.284
      c-0.012,0.024-0.023,0.048-0.036,0.072c-0.115,0.214-0.257,0.406-0.43,0.568c-0.024,0.023-0.05,0.043-0.075,0.065
      c-0.067,0.057-0.138,0.109-0.215,0.157c-0.03,0.019-0.059,0.039-0.091,0.056c-0.107,0.06-0.22,0.113-0.345,0.155
      c-1.668,0.556-2.224,2.596-1.668,3.522c0.556,0.926,0.556,2.039-1.298,2.78c-0.112,0.045-0.218,0.099-0.318,0.161
      c-0.032,0.02-0.06,0.044-0.091,0.065c-0.065,0.045-0.13,0.09-0.19,0.141c-0.034,0.029-0.064,0.062-0.096,0.093
      c-0.052,0.049-0.103,0.099-0.15,0.152c-0.032,0.036-0.06,0.075-0.089,0.113c-0.042,0.053-0.083,0.108-0.121,0.164
      c-0.028,0.042-0.053,0.085-0.079,0.129c-0.034,0.056-0.067,0.113-0.097,0.173c-0.024,0.047-0.046,0.094-0.067,0.142
      c-0.026,0.058-0.051,0.117-0.074,0.176c-0.02,0.051-0.038,0.103-0.055,0.154c-0.019,0.058-0.036,0.115-0.052,0.173
      c-0.015,0.055-0.03,0.111-0.042,0.167c-0.013,0.054-0.022,0.108-0.032,0.161c-0.011,0.06-0.021,0.12-0.029,0.179
      c-0.007,0.048-0.01,0.094-0.015,0.142c-0.005,0.065-0.011,0.129-0.013,0.192c-0.001,0.039-0.001,0.077-0.001,0.115
      c0.001,0.07,0.001,0.139,0.005,0.206c0.002,0.026,0.005,0.05,0.008,0.076c0.008,0.077,0.016,0.153,0.029,0.224
      c0.001,0.004,0.002,0.008,0.003,0.012c0.068,0.362,0.21,0.66,0.423,0.83c0.229-0.399,0.67-0.803,1.514-0.803
      c1.668,0,2.317-0.092,2.502,0.649s1.019,0.834,2.41,0.834c1.39,0,1.854-0.185,2.132-1.575s1.297-2.132,2.41-2.132
      s1.669-1.483,2.595-0.648c0.52,0.468,1.098,1.076,1.685,1.557c0,0,0.001,0,0.002,0c0.031-0.002,0.064-0.001,0.095-0.002
      c0.115-0.003,0.231,0,0.35,0.02c1.112,0.185,1.297-0.556,1.297-1.019s0-1.298,0.649-1.668s0-1.298-0.649-1.761
      c-0.649-0.463-0.278-1.761-0.092-2.595c0.186-0.834-0.278-1.853-1.298-2.132c-1.02-0.278-0.185-1.853-0.092-2.595
      c0.053-0.418-0.396-0.866-0.798-1.176c-0.086,0.044-0.172,0.085-0.26,0.123C538.119,397.345,538.109,397.349,538.1,397.353z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_50_" x="504.622" y="391.665" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
   <circle fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeMiterlimit="10" cx="531.441" cy="398.788" r="13.96" pointerEvents="auto" opacity="0.001" className="clickarea landarea"></circle>
</g>
<g id="AREA_SWAZILAND" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Eswatini" dataWikipediaLink="https://en.wikipedia.org/wiki/Swaziland" data-infotext="<b>Eswatini</b> (formerly known as Swaziland) &amp;nbsp; Capital: Mbabane" data-imageFile="SWAZILAND.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="SWAZILAND" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M549.26,623.504c-1.253-0.626-2.506-2.227-3.689-1.949c-1.183,0.278-2.019,1.322-2.367,2.436s-1.601,2.436-2.158,2.854
      s-1.044,1.671-1.044,2.645s-0.348,2.019,0.974,2.575s1.879,1.949,2.367,2.923s0.139,1.531,2.088,1.601
      c1.949,0.07,5.151,0.974,5.29-0.974c0.139-1.949-0.209-3.202,0.626-3.62c0.339-0.17,0.837-0.43,1.311-0.681
      c-0.001-0.002-0.001-0.004-0.002-0.005c-0.27-0.803-0.449-1.777-0.545-2.593c-0.003-0.026-0.006-0.051-0.009-0.076
      c-0.014-0.126-0.027-0.246-0.037-0.363c-0.003-0.035-0.007-0.07-0.009-0.104c-0.009-0.104-0.016-0.201-0.021-0.294
      c-0.002-0.032-0.004-0.066-0.006-0.096c-0.005-0.115-0.009-0.222-0.009-0.313c0-0.25-0.017-0.501-0.044-0.755
      c-0.004-0.041-0.007-0.082-0.013-0.122c-0.029-0.247-0.07-0.497-0.121-0.751c-0.008-0.04-0.017-0.079-0.025-0.12
      c-0.117-0.552-0.279-1.13-0.479-1.76c-0.001-0.003-0.002-0.007-0.003-0.01C550.816,623.955,550.013,623.881,549.26,623.504z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_49_" x="519.705" y="619.377" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
   <circle fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeMiterlimit="10" cx="546.631" cy="631.436" r="12.807" pointerEvents="auto" opacity="0.001" className="clickarea landarea"></circle>
</g>
<g id="AREA_LESOTHO" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Lesotho" dataWikipediaLink="https://en.wikipedia.org/wiki/Lesotho" data-infotext="<b>Lesotho</b> &amp;nbsp; Capital: Maseru" data-imageFile="LESHOTO.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="LESOTHO" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M525.573,652.759c-1.485-0.247-2.227-1.402-2.639-2.309s-1.567-2.061-2.474-1.979c0,0-2.556,0.164-2.886,1.237
      c-0.33,1.072-1.815,1.732-2.969,1.732s-2.309,1.072-2.309,2.556s-1.401,1.732-2.391,2.556c-0.99,0.824-3.629,1.237-3.299,2.969
      c0.33,1.731,1.814,1.32,2.061,2.804s1.979,3.215,2.886,4.7c0.907,1.484,1.649,1.815,3.133,1.815s1.896,0.247,2.226-1.32
      s1.32-3.793,3.546-3.958s4.205-0.165,4.783-1.814s0.907-3.381,1.649-4.123s1.237-0.66,1.154-2.061
      C527.964,654.161,527.057,653.006,525.573,652.759z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_1_" x="489.394" y="648.388" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_GABON" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Gabon" dataWikipediaLink="https://en.wikipedia.org/wiki/Gabon" data-infotext="<b>Gabon</b> &amp;nbsp; Capital: Libreville" data-imageFile="GABON.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="GABON" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M370.638,420.037c2.039,0.927,2.595,0.185,2.781-1.854c0.185-2.039-0.927-2.966-2.039-3.708c-1.112-0.741-1.483-2.41-1.483-4.078
      s2.039-1.668,4.634-1.668s3.337-1.112,3.337-2.224s-0.556-2.966,1.668-2.595s2.595,1.112,2.966,2.41
      c0.37,1.297,0.556,1.668,2.41,2.595s2.039,0,2.966-1.483s2.595,0.371,3.151,2.224s2.781,0,2.781-1.668s0.185-1.853,1.668-2.966
      c1.483-1.112,0.37-3.522,0.185-5.005s0-1.853,0.371-5.376c0.371-3.522-1.298-2.595-3.337-3.707s-1.668-2.595-1.854-3.893
      s0.927-3.522,2.039-4.078s2.781-1.668,2.966-3.522c0.185-1.853-2.224-2.224-2.224-3.151s-0.741-1.853-2.41-1.853
      c-1.668,0-2.224,1.668-4.634,2.781s-2.224-2.224-2.224-3.337s-0.556-2.595,0.741-3.337c0.583-0.333,0.566-1.564,0.371-2.819
      c-0.274-0.036-0.553-0.072-0.833-0.108c-0.065-0.008-0.129-0.016-0.194-0.024c-0.255-0.033-0.511-0.065-0.767-0.096
      c-0.039-0.004-0.078-0.01-0.117-0.014c-0.288-0.035-0.576-0.07-0.862-0.103c-0.067-0.008-0.133-0.016-0.2-0.023
      c-0.235-0.028-0.469-0.054-0.701-0.08c-0.063-0.007-0.126-0.014-0.188-0.021c-0.275-0.03-0.546-0.06-0.813-0.088
      c-0.063-0.007-0.123-0.013-0.185-0.019c-0.22-0.023-0.436-0.045-0.648-0.066c-0.064-0.007-0.129-0.013-0.192-0.019
      c-0.253-0.024-0.499-0.047-0.737-0.068c-0.049-0.004-0.094-0.008-0.143-0.012c-0.197-0.017-0.387-0.033-0.571-0.047
      c-0.059-0.005-0.117-0.009-0.174-0.014c-0.217-0.016-0.427-0.031-0.622-0.043c-0.021-0.001-0.04-0.002-0.061-0.003
      c-0.174-0.01-0.337-0.018-0.491-0.024c-0.043-0.002-0.087-0.003-0.129-0.005c-0.164-0.005-0.32-0.009-0.455-0.009
      c-2.41,0-7.044-0.926-7.044-0.185c0,0.081-0.021,0.165-0.053,0.248c-0.007,0.02-0.013,0.039-0.021,0.058
      c-0.033,0.071-0.078,0.141-0.133,0.211c-0.03,0.039-0.064,0.078-0.101,0.117c-0.026,0.027-0.057,0.053-0.086,0.079
      c-0.091,0.084-0.198,0.165-0.321,0.243c-0.003,0.002-0.006,0.004-0.008,0.005c-0.156,0.098-0.339,0.189-0.547,0.273
      c0.323,2.7,0.284,6.103,0.159,8.591c-0.185,3.708-6.302,2.41-8.712,2.41c-1.56,0-4.671-0.311-6.824-0.529
      c0.202,0.674,0.222,1.306-0.154,1.806c-0.897,1.196-1.196,2.392-0.598,3.886c0.598,1.495-1.196,1.495-1.345,3.437
      c-0.149,1.943,0.598,2.989-1.196,4.036c-1.793,1.046-4.334,1.046-2.989,4.334c1.345,3.288,4.035,6.427,5.53,8.967
      c1.495,2.541,3.587,6.427,6.128,8.071s2.69,1.644,4.484,4.185c0.737,1.045,2.16,2.47,3.68,3.962c0.11-0.141,0.22-0.284,0.328-0.445
      C367.672,418.925,368.599,419.11,370.638,420.037z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_48_" x="321.924" y="387.401" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_THECONGO" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Republic of the Congo" dataWikipediaLink="https://en.wikipedia.org/wiki/Republic_of_the_Congo" data-infotext="<b>Republic of the Congo</b> &amp;nbsp; Capital: Brazzaville" data-imageFile="THECONGO.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="THECONGO" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M382.316,427.638c0.08,0.09,0.147,0.173,0.209,0.253c0.023,0.03,0.045,0.059,0.066,0.087c0.03,0.042,0.059,0.083,0.085,0.123
      c0.052,0.077,0.097,0.149,0.134,0.217c0.002,0.003,0.005,0.008,0.007,0.011c0.001,0.001,0.001,0.003,0.002,0.004
      c0.296,0.546,0.181,0.825,0.35,1.067c0.001,0,0.001,0.001,0.001,0.001c0.045,0.062,0.11,0.123,0.205,0.185
      c0.015,0.01,0.034,0.02,0.051,0.03c0.035,0.021,0.072,0.041,0.115,0.062c0.024,0.012,0.05,0.024,0.077,0.036
      c0.045,0.021,0.094,0.042,0.147,0.064c0.031,0.013,0.062,0.026,0.096,0.039c0.061,0.024,0.131,0.049,0.203,0.074
      c0.034,0.012,0.065,0.024,0.102,0.036c0.113,0.039,0.237,0.079,0.376,0.122c2.41,0.741,1.112,1.853,3.893-0.556
      s1.668-3.522,4.634-2.966s2.966,1.112,2.966,2.781s0.742,3.522,2.41,1.668s0-3.337,2.781-2.781s4.82,2.039,5.005,0.741
      s0.185-2.039-0.742-2.966c-0.926-0.927-2.781-2.966-0.185-3.337s3.522,0.186,5.005-2.224s1.297-4.264,1.297-7.044
      s-1.297-8.156,1.483-10.566c2.781-2.41,5.56-5.746,6.302-9.083s1.854-5.005,3.708-8.342c1.853-3.337,4.078-7.23,4.264-11.308
      s-0.741-6.117,2.224-8.712s3.337-4.078,3.337-5.747c0-0.888-0.108-1.986-0.318-3.098c-0.158,0.153-0.345,0.255-0.564,0.297
      c-0.073,0.014-0.15,0.021-0.23,0.021c-1.297,0-5.191-0.741-7.415-1.668s-2.224,0.742-6.488,0.556s-2.781,3.708-3.337,5.747
      s0.37,2.966-1.483,3.707c-0.127,0.051-0.245,0.11-0.357,0.174c-0.034,0.02-0.065,0.043-0.098,0.064
      c-0.075,0.048-0.149,0.098-0.218,0.153c-0.034,0.026-0.066,0.054-0.098,0.083c-0.064,0.055-0.125,0.113-0.183,0.174
      c-0.028,0.029-0.055,0.059-0.081,0.089c-0.06,0.069-0.117,0.141-0.171,0.216c-0.019,0.026-0.038,0.051-0.056,0.078
      c-0.065,0.096-0.126,0.196-0.182,0.301c-0.004,0.007-0.008,0.014-0.012,0.021c-0.192,0.362-0.337,0.771-0.453,1.214
      c-0.001,0.001-0.001,0.002-0.001,0.003c-0.176,0.671-0.292,1.421-0.408,2.202c-0.03,0.201-0.06,0.402-0.091,0.606
      c-0.37,2.41-2.781,2.039-4.078,0.371s-4.449-0.186-7.044-2.039s-6.673,0-10.01-0.556c-0.184-0.03-0.385-0.063-0.592-0.096
      c-0.051-0.008-0.101-0.016-0.153-0.024c-0.206-0.033-0.42-0.065-0.645-0.099c-0.04-0.006-0.083-0.012-0.124-0.018
      c-0.213-0.032-0.433-0.064-0.659-0.096c-0.037-0.005-0.074-0.011-0.112-0.016c-0.254-0.036-0.517-0.073-0.784-0.11
      c-0.046-0.007-0.092-0.013-0.139-0.019c-0.285-0.039-0.574-0.078-0.869-0.117c0.194,1.255,0.211,2.486-0.371,2.819
      c-1.297,0.741-0.741,2.224-0.741,3.337s-0.185,4.449,2.224,3.337s2.966-2.781,4.634-2.781s2.41,0.926,2.41,1.853
      s2.41,1.298,2.224,3.151c-0.185,1.854-1.854,2.966-2.966,3.522s-2.225,2.781-2.039,4.078s-0.185,2.781,1.854,3.893
      s3.708,0.185,3.337,3.707s-0.556,3.893-0.371,5.376s1.298,3.893-0.185,5.005s-1.668,1.297-1.668,2.966
      c0,1.668-2.224,3.522-2.781,1.668c-0.556-1.854-2.224-3.708-3.151-2.224s-1.112,2.41-2.966,1.483s-2.04-1.298-2.41-2.595
      s-0.741-2.039-2.966-2.41c-2.224-0.371-1.668,1.483-1.668,2.595s-0.742,2.224-3.337,2.224s-4.634,0-4.634,1.668
      s0.371,3.337,1.483,4.078s2.224,1.669,2.039,3.708s-0.742,2.781-2.781,1.854s-2.966-1.112-4.078,0.556
      c-0.108,0.162-0.218,0.304-0.328,0.445c2.176,2.135,4.556,4.406,5.436,5.902c1.369,2.326,1.486,3.526,2.414,5.088
      c1.136-0.759,2.457-1.657,3.414-2.353C379.536,428.194,380.834,425.969,382.316,427.638z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_47_" x="397.957" y="374.68" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>

      <rect id="FLAG_3_" x="393.821" y="385.966" opacity="0" fill="#0A11FF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="25.1" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_ANGOLA" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Angola" dataWikipediaLink="https://en.wikipedia.org/wiki/Angola" data-infotext="<b>Angola</b> &amp;nbsp; Capital: Luanda" data-imageFile="ANGOLA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="ANGOLA" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M376.755,542.38c0.556,0.371,2.781,0.371,3.337-1.297s5.561-2.595,7.229-0.371s2.039,2.595,6.302,2.595
      c4.264,0,36.889,0.185,36.889,0.185l3.893,4.449c0,0,12.049-0.37,14.088,0.371s11.493,1.483,12.605,0.741
      c1.112-0.741,7.971-2.039,10.566-2.41c0.305-0.043,0.609-0.079,0.911-0.109c0.257-0.026,0.512-0.048,0.765-0.069
      c0.02-0.002,0.041-0.004,0.061-0.005c-0.234-2.895-0.906-3.695-2.016-3.941c-1.251-0.278-2.641-2.085-4.31-3.476
      c-1.668-1.39-4.727-6.395-4.727-6.395l-0.139-28.778l17.517-0.556l0.25-18.158c-0.229-0.021-0.461-0.035-0.694-0.044
      c-0.022-0.001-0.043-0.002-0.065-0.003c-0.223-0.008-0.446-0.012-0.667-0.014c-0.036,0-0.073-0.001-0.109-0.001
      c-0.219-0.001-0.436,0.001-0.644,0.002c-0.231,0.002-0.456,0.004-0.667,0.004c-1.946,0-3.151,1.39-4.82,1.39
      s-5.097-0.834-5.653,0.278c-0.556,1.112-2.317,0.556-2.966-0.463c-0.649-1.019-0.463-1.761,0.742-2.502
      c1.205-0.741,0.556-2.781,0.37-4.171c-0.186-1.39-0.463-3.151-1.946-4.171c-1.483-1.019-1.668-2.966-1.946-4.449
      s0.278-2.688,0.556-3.8c0.278-1.112,0.093-2.873,0.093-4.449c0-1.576-0.463-3.244-1.112-3.893
      c-0.649-0.649-0.741-2.132,0.463-2.595s0.278-1.483,0.186-2.873c-0.092-1.39-7.137-0.649-9.639-0.741s-1.39-3.337-2.873-3.244
      c-1.483,0.092-5.005-0.092-6.951,0.186c-1.946,0.278-0.649,3.8-1.576,4.634s-0.741,1.205-0.926,3.337s-3.151,2.039-4.82,1.297
      c-1.668-0.742-2.966-0.648-4.078,0.186s-1.947,0.463-4.078,0.463c-2.132,0-3.8-1.575-5.283-3.151s-2.595-6.395-3.893-7.971
      s-1.205-4.078-2.317-7.137s-13.254-1.02-16.775-1.298c-3.522-0.278-10.103-0.092-13.161-0.092c-2.414,0-6.787,0.923-8.526,1.312
      c0.133,1.065,0.519,2.052,1.596,3.542c1.943,2.69,3.438,2.69,4.185,5.829c0.747,3.138,1.046,3.138,2.69,5.978
      c1.644,2.839,2.989,4.782,1.195,7.921c-1.793,3.138-2.989,3.438-1.495,6.725c1.495,3.288,0.897,6.127,2.69,7.473
      c1.793,1.345,2.391,1.794,2.84,4.334c0.448,2.541-0.15,5.082-0.299,8.071c-0.149,2.989,1.495,4.035-1.644,5.829
      c-3.138,1.793-5.829,3.886-6.725,6.576c-0.897,2.69-3.587,4.185-3.587,7.772c0,3.587-0.299,4.782-0.448,7.473
      c-0.15,2.69-1.495,4.633-3.438,7.771s-1.644,5.38-1.644,8.668c0,1.447-0.231,3.906-0.338,6.436
      c0.667-0.382,1.347-0.684,1.825-0.684C374.902,540.897,376.199,542.01,376.755,542.38z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_60_" x="397.957" y="488.317" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>

      <path id="SIERRALEONE" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M378.609,438.296c0.185-2.132,0-4.726,0.556-5.375s1.853-1.112,2.41-1.946c0.403-0.605,0.759-1.79,1.242-2.646
      c-0.002-0.003-0.004-0.007-0.007-0.011c-0.038-0.068-0.083-0.141-0.134-0.217c-0.027-0.04-0.054-0.08-0.085-0.123
      c-0.021-0.028-0.043-0.058-0.066-0.087c-0.062-0.08-0.129-0.163-0.209-0.253c-1.483-1.668-2.781,0.556-4.82,2.039
      c-0.957,0.696-2.278,1.593-3.414,2.353c0.086,0.144,0.175,0.29,0.276,0.441c1.195,1.794,1.793,2.84,1.793,5.679
      c0,0.605-0.013,1.126-0.015,1.602C377.205,439.771,378.501,439.535,378.609,438.296z" pointerEvents="auto" className="landarea"></path>
</g>
<g id="AREA_SIERRALEONE" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Sierra Leone" dataWikipediaLink="https://en.wikipedia.org/wiki/Sierra_Leone" data-infotext="<b>Sierra Leone</b> &amp;nbsp; Capital: Freetown" data-imageFile="SIERRALEONE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <rect id="rect12_46_" x="135.419" y="300.96" opacity="0" fill="#006666" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
   <path fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeMiterlimit="10" d="M168.803,322.368
      c0.466-0.745,1.397-2.236,2.236-2.701c0.838-0.466,2.236-1.305,2.422-2.236s1.956-1.77,2.795-3.353
      c0.048-0.09,0.084-0.178,0.116-0.265c0.009-0.026,0.017-0.052,0.025-0.078c0.02-0.063,0.036-0.126,0.048-0.188
      c0.005-0.026,0.01-0.051,0.014-0.076c0.011-0.076,0.018-0.151,0.019-0.225c0-0.007,0.001-0.014,0.001-0.021
      c0-0.081-0.007-0.162-0.019-0.24c-0.003-0.021-0.007-0.041-0.011-0.062c-0.011-0.058-0.024-0.117-0.04-0.174
      c-0.006-0.022-0.012-0.044-0.019-0.066c-0.02-0.063-0.044-0.126-0.07-0.187c-0.004-0.01-0.008-0.021-0.013-0.031
      c-0.032-0.072-0.068-0.141-0.106-0.209c-0.009-0.016-0.019-0.032-0.029-0.048c-0.031-0.052-0.062-0.103-0.096-0.152
      c-0.013-0.02-0.027-0.039-0.04-0.058c-0.034-0.049-0.069-0.096-0.105-0.142c-0.011-0.014-0.022-0.028-0.033-0.043
      c-0.046-0.057-0.092-0.112-0.139-0.167c-0.017-0.019-0.034-0.038-0.051-0.056c-0.031-0.034-0.062-0.068-0.093-0.101
      c-0.022-0.023-0.045-0.047-0.067-0.07c-0.023-0.023-0.046-0.046-0.068-0.069c-0.025-0.025-0.05-0.05-0.074-0.074
      c-0.1-0.099-0.193-0.188-0.273-0.267c-0.075-0.074-0.139-0.139-0.182-0.193c-0.373-0.466-0.279-3.819-0.466-4.471
      s-0.559-1.677-1.584-2.328s-1.397-2.422-1.584-3.447c-0.186-1.024-2.236-1.583-2.701-2.515c-0.466-0.932-2.702-0.559-3.633-0.559
      s-2.143,0.279-2.981,0.932c-0.838,0.652-1.677,0.652-3.167,0.186s-1.956,0-2.236,1.491c-0.279,1.491-1.025,2.701-2.329,3.26
      c-1.304,0.559-1.211,0.932-1.956,2.05c-0.391,0.586-1.318,0.71-2.113,0.696c0.197,0.878,0.394,1.827,0.608,2.863
      c0.901,4.356,2.253,8.862,5.558,9.914s4.356,3.155,7.51,4.506c0.938,0.402,1.783,0.886,2.618,1.46
      C167.274,323.506,168.429,322.966,168.803,322.368z" pointerEvents="auto" className="landarea"></path>
</g>
<rect id="SVGHUD" x="-227.483" y="161.739" fill="#150B00" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" width="109.477" height="26.852"></rect>
<g id="AREA_COMOROS" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Comoros" dataWikipediaLink="https://en.wikipedia.org/wiki/Comoros" data-infotext="<b>Comoros</b> &amp;nbsp; Capital: Moroni" data-imageFile="COMOROS.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
   <path fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeMiterlimit="10" d="M652.606,501.694
      c0.904,0.348,1.043-0.139,1.043-0.973s-0.07-1.39-0.834-1.946c-0.765-0.556-0.348-2.155-0.834-2.641
      c-0.487-0.487-1.599-1.251-1.807-0.487c0,0-0.348,1.251-0.348,2.085c0,0.834,0.278,1.599,1.043,2.155
      C651.633,500.443,651.702,501.347,652.606,501.694z" pointerEvents="auto" className="landarea"></path>
   <path fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeMiterlimit="10" d="M656.707,505.587
      c0.278-0.487,0.626-2.085-1.112-1.529c0,0-1.39,0.973-0.904,1.599C655.178,506.282,656.429,506.074,656.707,505.587z" pointerEvents="auto" className="landarea"></path>
   <path fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeMiterlimit="10" d="M662.685,504.684
      c0.209-0.765-0.209-1.738,0.973-1.738s1.39-0.556,1.321-1.182c-0.069-0.626-1.112-1.39-2.016-0.765
      c-0.904,0.626-0.278,1.46-1.946,2.016c0,0-1.946,0.973-1.182,1.946S662.477,505.448,662.685,504.684z" pointerEvents="auto" className="landarea"></path>
   <path fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeMiterlimit="10" d="M666.161,511.426
      c0.695,0.626,2.294-0.417,2.641-1.738c0.348-1.321,1.321-4.31-0.278-2.502c0,0-0.487,1.182-1.807,1.946
      C665.396,509.897,665.466,510.801,666.161,511.426z" pointerEvents="auto" className="landarea"></path>
   <ellipse fill="#1e8346" stroke="#95bec8" strokeMiterlimit="10" cx="660.648" cy="503.203" rx="26.856" ry="25.316" pointerEvents="auto" opacity="0.001" className="clickarea landarea" strokeWidth="0.5"></ellipse>

      <rect id="rect12_4_" x="632.727" y="513.915" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>

      <rect id="FLAG_2_" x="647.589" y="485.509" opacity="0" fill="#0A11FF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="25.1" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>

<g id="AREA_CABOVERDE" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Cape Verde" dataWikipediaLink="https://en.wikipedia.org/wiki/Cape_Verde" data-infotext="<b>Cape Verde</b> (also known as Cabo Verde) &amp;nbsp; Capital: Praia" data-imageFile="CABOVERDE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
   <path fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeMiterlimit="10" d="M46.003,231.876c0,0-1.747-0.338-1.86,0.62
      c-0.113,0.959-0.62,1.748-0.113,2.086s1.297,0.958,1.691,0.564c0.395-0.395,0.508-1.24,0.959-1.24s0.902-0.564,0.676-0.959
      C47.131,232.553,47.074,232.045,46.003,231.876z" pointerEvents="auto" className="landarea"></path>
   <path fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeMiterlimit="10" d="M53.558,235.315
      c-0.216,0.13-1.24,0.903-0.733,1.974s1.353,2.03,2.029,1.861c0.677-0.169,1.353,0.169,1.466-1.184
      c0.113-1.353-0.789-0.903-1.071-1.635C54.967,235.597,54.121,234.976,53.558,235.315z" pointerEvents="auto" className="landarea"></path>
   <path fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeMiterlimit="10" d="M65.735,235.428c0,0-0.677,1.974-0.057,2.255
      c0.62,0.282,1.015-0.282,1.015-0.902S66.581,234.3,65.735,235.428z" pointerEvents="auto" className="landarea"></path>
   <path fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeMiterlimit="10" d="M66.637,240.051c0,0-1.015-0.113-1.015,0.733
      s-0.507,2.48,0.395,2.65c0.902,0.169,0.789-1.071,1.691-1.071s0.733-0.789,0.169-1.071
      C67.314,241.009,67.088,240.897,66.637,240.051z" pointerEvents="auto" className="landarea"></path>
   <path fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeMiterlimit="10" d="M52.317,251.043c0,0-0.846,0.113-0.846,0.846
      s0.62,0.733,0.789,1.804c0.169,1.071,0.62,1.353,1.466,1.071s0.62-0.733,0.62-1.522C54.347,252.452,54.629,250.536,52.317,251.043z
      " pointerEvents="auto" className="landarea"></path>
   <path fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeMiterlimit="10" d="M59.252,248.675c0,0-1.804,1.24-1.691,1.86
      s1.523,1.014,1.861,1.804s0.564,1.353,1.127,1.353c0.564,0,1.522-0.395,1.297-1.522s-0.845-1.297-1.071-2.199
      C60.548,249.07,59.928,248.225,59.252,248.675z" pointerEvents="auto" className="landarea"></path>
   <path fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeMiterlimit="10" d="M63.818,248.224c0,0-0.564,0.789-0.564,1.804
      s-0.282,2.142,0.451,2.593s1.184,0,1.184-1.127C64.889,250.366,64.776,246.758,63.818,248.224z" pointerEvents="auto" className="landarea"></path>
   <ellipse fill="#1e8346" stroke="#95bec8" strokeMiterlimit="10" cx="52.833" cy="246.004" rx="35.167" ry="31.998" pointerEvents="auto" opacity="0.001" className="clickarea landarea" strokeWidth="0.5"></ellipse>

      <rect id="rect12_5_" x="28.409" y="264.544" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>

      <rect id="FLAG_5_" x="43.006" y="233.635" opacity="0" fill="#0A11FF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="25.1" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_SEYCHELLES" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Seychelles" dataWikipediaLink="https://en.wikipedia.org/wiki/Seychelles" data-infotext="<b>Seychelles</b> &amp;nbsp; Capital: Victoria" data-imageFile="SEYCHELLES.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
   <path fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeMiterlimit="10" d="M760.61,429.183
      c-0.034-0.095-0.059-0.201-0.072-0.333c-0.028-0.292-0.013-0.707-0.238-0.932c-0.282-0.282-1.209-0.161-1.37,0.323
      c-0.161,0.483-1.048,0.725-1.048,1.088s0.604,1.007,1.048,1.652s1.048,0.725,1.652,0.604c0.42-0.084,0.575-0.544,0.574-0.922
      c-0.001-0.294-0.096-0.614-0.231-0.874C760.797,429.54,760.683,429.387,760.61,429.183z" pointerEvents="auto" className="landarea"></path>
   <ellipse fill="#1e8346" stroke="#95bec8" strokeMiterlimit="10" cx="743.544" cy="444.367" rx="31.02" ry="31.339" pointerEvents="auto" opacity="0.001" className="clickarea landarea" strokeWidth="0.5"></ellipse>

      <rect id="rect12_6_" x="717.846" y="473.03" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>

      <rect id="FLAG" x="730.994" y="434.796" opacity="0" fill="#0A11FF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="25.1" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_SAOTOMEANDPRINCIPE" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="São Tomé and Príncipe" dataWikipediaLink="https://en.wikipedia.org/wiki/S%C3%A3o_Tom%C3%A9_and_Pr%C3%Adncipe" data-infotext="<b>São Tomé and Príncipe</b> &amp;nbsp; Capital: São Tomé" data-imageFile="SAOTOME.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M324.004,386.717c0.557-0.556,2.04-2.595,0.371-2.595c0,0-2.502,0.37-2.41,1.483C322.058,386.717,323.448,387.273,324.004,386.717z
      " pointerEvents="auto" className="landarea"></path>

      <path fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M333.366,372.723c0-0.649,1.483-0.741,1.483-1.668c0-0.928-0.649-2.411-1.669-1.484c0,0-1.019,0.001-1.019,0.927
      s-1.483,1.575-0.834,2.41C331.976,373.742,333.366,373.372,333.366,372.723z" pointerEvents="auto" className="landarea"></path>
   <ellipse fill="#1e8346" stroke="#95bec8" strokeMiterlimit="10" cx="321.963" cy="381.788" rx="23.914" ry="22.543" pointerEvents="auto" opacity="0.001" className="clickarea landarea" strokeWidth="0.5"></ellipse>

      <rect id="rect12_7_" x="225.959" y="417.397" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>

      <line fill="#1e8346" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" x1="253.88" y1="427.045" x2="321.924" y2="387.401" pointerEvents="auto" opacity="0" className="clickarea"></line>
</g>
<g id="AREA_DJIBOUTI" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Djibouti" dataWikipediaLink="https://en.wikipedia.org/wiki/Djibouti" data-infotext="<b>Djibouti</b> &amp;nbsp; Capital: Djibouti" data-imageFile="DJIBOUTI.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path id="DJIBOUTI" fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M640.997,280.942c-1.112,0.695-2.92,2.224-2.92,3.754s-0.209,4.379,0.904,5.353c1.112,0.973,1.877,2.224,2.294,0.695
      s1.46-0.973,2.572-0.904c1.112,0.07,0.765-1.043,2.085-1.043c1.029,0,2.223-0.292,3.261-0.357c0.124-0.33,0.255-0.645,0.424-0.941
      c0.41-0.717,0.595-1.491,1.021-2.228c-0.031-0.003-0.059-0.01-0.091-0.012c-2.092-0.149-3.886-0.897-0.747-2.989
      s4.185-2.092,2.84-4.334c-0.969-1.615-1.865-2.61-3.015-3.598c-0.374,0.33-0.773,0.66-0.912,0.973
      c-0.278,0.626-1.529,0.07-1.529,1.182s-0.695,1.321-2.433,1.043c-0.526-0.084-1.032-0.374-1.504-0.761
      c-0.168,0.663-0.36,1.388-0.512,1.874C642.388,279.76,642.11,280.247,640.997,280.942z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_89_" x="621.562" y="272.453" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>

      <ellipse fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeMiterlimit="10" cx="645.333" cy="283.667" rx="19.333" ry="18" pointerEvents="auto" opacity="0.001" className="clickarea landarea"></ellipse>
</g>
<g id="AREA_MAURITIUS" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Mauritius" dataWikipediaLink="https://en.wikipedia.org/wiki/Mauritius" data-infotext="<b>Mauritius</b> &amp;nbsp; Capital: Port Louis" data-imageFile="MAURITIUS.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
   <path fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeMiterlimit="10" d="M778.692,567.955
      c0,0-1.268-0.267-1.668,0.467s-1,1.268-1.534,1.268s-0.8,1.6,0.134,2.201c0.934,0.601,3.402,1.601,3.469,0.067
      C779.159,570.423,780.426,568.489,778.692,567.955z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_8_" x="751.68" y="585.104" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
   <ellipse fill="#1e8346" stroke="#95bec8" strokeMiterlimit="10" cx="792.176" cy="569.138" rx="31.02" ry="31.339" pointerEvents="auto" opacity="0.001" className="clickarea landarea" strokeWidth="0.5"></ellipse>

      <rect id="FLAG_1_" x="765.292" y="559.323" opacity="0" fill="#0A11FF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="25.1" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
</g>
<g id="AREA_EQUATORIALGUINEA" data-colors="0" data-errors="0" hint-index="0" className="q" dataQText="Equatorial Guinea" dataWikipediaLink="https://en.wikipedia.org/wiki/Equatorial_Guinea" data-infotext="<b>Equatorial Guinea</b> &amp;nbsp; Capital: Malabo" data-imageFile="EQUATORIALGUINEA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

      <path fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M368.069,367.887c-0.149,0.06-0.31,0.115-0.485,0.164c-0.034,0.01-0.071,0.018-0.106,0.028c-0.152,0.04-0.308,0.077-0.479,0.108
      c-0.101,0.018-0.21,0.031-0.317,0.046c-0.129,0.017-0.253,0.038-0.392,0.05c-0.264,0.023-0.542,0.038-0.842,0.038
      c-2.596,0-6.674,0.185-7.971-0.185c-0.513-0.147-1.345-0.438-2.163-0.737c0.266,1.52,0.027,3.045-0.833,3.797
      c-1.195,1.046-3.885,2.839-3.138,4.334c0.435,0.869,1.069,1.89,1.349,2.827c2.153,0.218,5.264,0.529,6.824,0.529
      c2.41,0,8.527,1.298,8.712-2.408C368.353,373.99,368.392,370.587,368.069,367.887z" pointerEvents="auto" className="landarea"></path>

      <path fill="#1e8346" stroke="#95bec8" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
      M346.085,353.009c0,0-1.607,0.387-1.884,0.942s-0.665,1.579-1.219,1.856s-0.665,1.718-0.443,2.189
      c0.222,0.471,2.355,1.551,2.77,0.997c0.415-0.554,1.523-0.664,1.717-1.828s1.08-1.718,0.914-2.299
      C347.775,354.284,347.664,351.568,346.085,353.009z" pointerEvents="auto" className="landarea"></path>

      <rect id="rect12_3_" x="316.485" y="362.442" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" strokeWidth="0.25" strokeMiterlimit="10" enable-background="new    " width="55.842" height="19.629" pointerEvents="auto" visibility="hidden"></rect>
   <ellipse fill="#1e8346" stroke="#95bec8" strokeMiterlimit="10" cx="361.938" cy="372.79" rx="13.116" ry="11.543" pointerEvents="auto" opacity="0.001" className="clickarea landarea" strokeWidth="0.5"></ellipse>
</g>
<g id="CITY_NILERIVER" data-colors="0" data-errors="0" hint-index="0" className="">
   <path id="SEMITRANSPARENT" opacity="0" fill="#166c38" enable-background="new    " d="M565.189,394.105
      c-0.281,0-0.504-0.017-0.646-0.027c0.168,0.014,0.713,0.023,1.396-0.157l-1.468-5.584l-4.702-0.38l0.027-0.337
      c0.293-3.601,0.249-4.844-0.295-5.456c-0.087-0.024-0.177-0.049-0.266-0.074c-1.236-0.356-3.104-0.896-4.682-2.456
      c-1.355-1.34-1.937-2.829-2.279-3.835c-0.144-0.042-0.29-0.083-0.414-0.117c-1.614-0.45-4.621-1.288-6.089-4.476
      c-1.581-3.434,0.057-6.951,0.595-8.107c0.509-1.092,1.119-1.953,1.647-2.66c-0.284-0.434-0.56-0.942-0.789-1.532
      c-1.183-3.05-0.649-6.628-0.134-10.088c0.177-1.187,0.359-2.413,0.38-3.142l0.063-2.243c-0.006-0.264-0.014-0.487-0.022-0.612
      c0.007,0.098,0.018,0.196,0.031,0.295l0.029-1.09l-0.121-0.013c0.032-0.815,0.032-0.815-0.541-1.726
      c-0.524-0.832-1.242-1.971-1.956-3.639c-0.152-0.29-0.888-1.118-1.374-1.666c-1.001-1.127-2.246-2.53-3.217-4.258
      c-2.614-4.658-2.865-8.421-3.154-12.777l-0.023-0.351c-0.053-0.78-0.37-2.017-0.677-3.214c-0.672-2.621-1.433-5.592-0.854-8.574
      l0.766-3.936l3.966-0.59c0.879-0.13,1.771-0.334,2.718-0.55c1.773-0.405,3.783-0.863,6.127-0.929
      c0.376-0.011,0.659-0.025,0.87-0.041c0.093-0.176,0.209-0.411,0.353-0.722c0.462-0.998,0.864-2.039,1.289-3.142
      c0.358-0.928,0.721-1.863,1.128-2.817c0.579-1.354,1.34-2.632,2.011-3.76c0.569-0.958,1.215-2.043,1.422-2.668
      c0.523-1.578-0.12-5.125-0.546-7.472c-0.197-1.09-0.384-2.12-0.516-3.118c-0.243-1.836-0.597-3.671-0.971-5.613
      c-1.063-5.521-2.27-11.778-0.703-18.642c0.276-1.211,0.337-2.739,0.401-4.355c0.179-4.488,0.423-10.635,6.003-15.038
      c3.81-3.005,3.166-4.319,2.649-5.375c-0.045-0.079-0.302-0.339-0.472-0.512c-0.41-0.416-1.109-1.125-1.698-2.122
      c-0.906,0.354-1.74,0.58-2.45,0.772c-0.703,0.19-1.259,0.341-1.631,0.545c-0.62,0.34-1.189,0.882-1.849,1.509
      c-1.974,1.879-6.596,6.278-13.98,1.154c-3.354-2.328-4.721-5.39-5.536-7.798c-0.272-0.802-0.4-1.524-0.484-2.108
      c-0.723-0.567-1.573-1.408-2.111-2.656c-1.918-4.472-0.028-7.472,0.88-8.914c0.24-0.38,0.429-0.68,0.574-1.013
      c0.127-0.29,0.146-0.409,0.147-0.41c-0.003-0.061-0.038-0.327-0.27-1.16c-0.108-0.392-0.212-0.653-0.332-0.956
      c-1.821-4.583,0.544-7.07,2.444-9.069c0.845-0.888,1.668-1.665,2.465-2.415c1.042-0.982,2.026-1.91,2.857-2.917
      c1.239-1.502,2.148-2.75,2.557-3.956c1.223-3.606,1.9-4.612,4.533-6.722l0.478-0.385c3.2-2.589,4.345-3.82,4.386-5.568
      c0.019-0.747,0.049-1.494,0.08-2.242c0.052-1.24,0.101-2.411,0.083-3.53c-0.011-0.75-0.115-0.984-0.668-2.105
      c-0.418-0.849-0.938-1.904-1.392-3.322c-0.707-2.211-0.701-3.847-0.451-5.236c-5.634-1.9-9.217-7.054-10.276-8.762
      c-0.373-0.601-0.693-1.174-1.006-1.738c-0.534-0.962-0.887-1.598-1.483-2.263c-0.159-0.177-0.42-0.418-0.695-0.673
      c-1.126-1.042-2.826-2.616-3.638-5.251c-1.731-5.617,1.282-10.052,3.083-12.703c0.453-0.666,0.88-1.295,1.128-1.769
      c1.6-3.055,1.447-6.776-0.48-11.709c-1.524-3.9-0.229-7.424,0.629-9.755c0.293-0.798,0.57-1.551,0.692-2.157l11.113,2.251
      c-0.295,1.455-0.757,2.71-1.164,3.818c-0.225,0.612-0.553,1.505-0.626,1.933c3.038,7.902,2.998,14.926-0.119,20.879
      c-0.549,1.049-1.183,1.98-1.794,2.881c-0.502,0.738-1.48,2.178-1.619,2.792c0.147,0.146,0.34,0.323,0.496,0.468
      c0.41,0.379,0.921,0.852,1.43,1.419c1.455,1.62,2.291,3.127,2.963,4.339c0.228,0.41,0.457,0.828,0.727,1.263
      c1.446,2.331,3.558,3.941,4.684,4.095c0.141,0.02,0.381,0.021,0.635,0.023c1.161,0.008,3.881,0.027,5.865,2.416
      c2.171,2.613,1.576,5.931,1.29,7.525c-0.18,1.007-0.402,1.752-0.565,2.296c-0.012,0.038-0.022,0.075-0.034,0.111
      c0.006,0.018,0.012,0.037,0.019,0.057c0.196,0.614,0.445,1.12,0.761,1.76c0.754,1.528,1.785,3.621,1.836,6.943
      c0.022,1.442-0.036,2.833-0.092,4.178c-0.028,0.681-0.058,1.362-0.073,2.041c-0.175,7.302-5.238,11.399-8.589,14.111l-0.518,0.416
      c-0.192,0.154-0.355,0.281-0.496,0.396c-0.125,0.336-0.295,0.838-0.391,1.12c-1.02,3.009-2.789,5.399-4.55,7.533
      c-1.283,1.555-2.635,2.829-3.827,3.953c-0.479,0.451-0.94,0.886-1.382,1.322c0.681,2.491,1.17,5.247-0.46,8.978
      c-0.162,0.371-0.33,0.712-0.493,1.022c0.151,0.185,0.298,0.385,0.438,0.6c1.144,1.777,1.371,3.495,1.508,4.521
      c0.02,0.145,0.032,0.291,0.062,0.435c0.412,1.197,0.728,1.636,1.041,1.903c0.961-0.914,2.263-2.126,4.111-3.14
      c1.556-0.853,2.976-1.237,4.117-1.547c1.058-0.286,1.49-0.42,1.848-0.691c0.218-0.17,0.502-0.44,0.83-0.739
      c2.481-2.264,5.875-5.364,10.143-4.419c3.819,0.845,5.186,3.284,5.659,4.663c1.049,3.053-0.326,5.647-1.643,7.439
      c0.32,0.454,0.641,0.974,0.932,1.568c1.936,3.955,3.756,11.716-5.81,19.262c-1.348,1.063-1.544,2.737-1.696,6.587
      c-0.078,1.965-0.167,4.193-0.677,6.428c-1.036,4.538-0.193,8.911,0.782,13.974c0.389,2.019,0.791,4.106,1.077,6.274
      c0.097,0.728,0.26,1.626,0.432,2.578c0.707,3.894,1.586,8.739,0.153,13.063c-0.595,1.794-1.575,3.443-2.44,4.897
      c-0.539,0.905-1.047,1.76-1.329,2.419c-0.354,0.827-0.666,1.64-0.977,2.445c-0.472,1.223-0.96,2.487-1.575,3.817
      c-2.372,5.133-5.086,7.038-10.584,7.313l5.525,21.736c0.259,0.509,0.523,0.929,0.816,1.394c1.273,2.021,2.326,4.006,2.292,7.633
      c0.028,0.245,0.048,0.5,0.058,0.768c0.035,0.975,0.078,2.161-0.054,3.346h0.006c-0.039,1.411-0.263,2.911-0.499,4.499
      c-0.174,1.167-0.444,2.982-0.459,4.025c1.017,1.1,2.599,3.303,2.023,7.031c-0.283,1.84-1.071,3.276-1.816,4.37
      c0.574,0.297,1.161,0.663,1.734,1.124c1.733,1.394,2.505,3.081,2.93,4.253c0.892,0.264,2.017,0.641,3.098,1.406
      c6.214,4.397,5.675,11.012,5.281,15.842l-0.363,4.491l-4.075,0.913C566.094,394.073,565.585,394.105,565.189,394.105z
       M551.569,152.408C551.569,152.409,551.569,152.409,551.569,152.408C551.569,152.409,551.569,152.409,551.569,152.408z" pointerEvents="none" stroke="#95bec8" strokeWidth="0.5px" className="landarea" strokeOpacity="0"></path>
   <path id="RIVER" fill="none" stroke="#95bec8" strokeMiterlimit="10" d="M544.145,96.898c-0.634,3.141-2.703,5.917-1.597,8.757
      c2.038,5.236,3.035,11.074,0.222,16.466c-1.566,3.002-5.062,6.152-3.816,10.213c0.516,1.681,2.005,2.559,3.133,3.82
      c1.478,1.652,2.042,3.124,3.089,4.82c1.921,3.11,5.255,6.273,8.732,6.751c2.359,0.324,3.721-0.797,2.979,3.359
      c-0.414,2.322-1.188,2.739-0.402,5.21c0.899,2.824,2.28,3.877,2.329,7.092c0.031,2.001-0.117,4.018-0.164,6.02
      c-0.107,4.518-3.123,7.146-6.487,9.879c-2.227,1.809-2.222,1.618-3.207,4.536c-0.731,2.165-2.109,4.009-3.554,5.768
      c-1.75,2.129-3.726,3.687-5.587,5.652c-2.331,2.462-1.542,1.783-0.758,4.619c0.555,2.009,0.756,3.307-0.147,5.38
      c-1.061,2.437-2.496,2.965-1.441,5.432c0.215,0.503,1.468,1.017,1.816,1.559c0.749,1.169,0.532,2.437,0.941,3.649
      c0.701,2.077,1.607,3.731,3.399,4.979c5.178,3.607,5.737-0.714,9.871-2.989c2.24-1.233,4.439-1.005,6.668-2.706
      c1.33-1.014,4.494-4.56,6.318-4.154c4.106,0.912-1.055,4.553-1.471,6.224c-0.467,1.877,1.474,2.233,2.553,4.445
      c2.562,5.254-0.271,9.232-4.229,12.366c-5.142,4.073-3.134,10.746-4.389,16.267c-1.782,7.84,0.787,14.894,1.767,22.338
      c0.488,3.713,2.099,9.303,0.824,13.166c-0.725,2.196-2.639,4.64-3.601,6.899c-0.893,2.098-1.546,4.097-2.484,6.134
      c-1.588,3.447-2.587,3.965-6.212,4.067c-2.899,0.081-5.324,1-8.169,1.425c-0.565,2.919,1.407,7.143,1.622,10.368
      c0.281,4.219,0.42,7.117,2.465,10.773c1.397,2.499,3.973,4.418,4.858,6.492c1.559,3.652,3.107,3.907,2.951,7.853
      c0.099-0.558,0.148,4.103,0.022,3.61c-0.085,3.112-1.605,8.536-0.627,11.066c0.579,1.5,2.126,1.439,1.757,3.84
      c-0.285,1.851-1.951,3.082-2.762,4.83c-2.552,5.501,2.098,3.865,4.73,5.99c1.44,1.163,1.153,3.018,2.305,4.159
      c0.983,0.976,2.995,1.018,3.999,1.731c3.67,2.607,3.236,6.709,2.88,11.131c-0.3,0.067-0.639-0.05-0.922,0.024" pointerEvents="none" opacity="0" strokeWidth="0.5px" className="landarea"></path>
   <rect id="rect12_9_" x="516.37" y="182.991" fill="#FFFFFF" width="50" height="16" pointerEvents="none" opacity="0"></rect>
   <circle id="CITY_4_" fill="#166c38" cx="539.274" cy="209.5" r="8.5" pointerEvents="none" stroke="#95bec8" strokeWidth="0.5px" className="landarea" opacity="0"></circle>
   <ellipse fill="none" stroke="#95bec8" strokeMiterlimit="10" cx="538.432" cy="211.458" rx="15.258" ry="13.549" pointerEvents="none" opacity="0" strokeWidth="0.5px" className="landarea"></ellipse>
</g>
<g id="CITY_CONGORIVER" data-colors="0" data-errors="0" hint-index="0" className="">
   <path id="SEMITRANSPARENT_5_" opacity="0" fill="#166c38" enable-background="new    " d="M500.458,498.979
      c-0.076-0.039-0.153-0.07-0.227-0.104c-3.805-1.702-4.695-4.508-4.726-6.686c-0.072-0.173-0.277-0.507-0.417-0.735
      c-0.622-1.017-2.274-3.716-0.822-6.822c0.309-0.659,0.707-1.236,1.194-1.731c-0.259-0.348-0.604-0.739-1.015-1.102
      c-1.838-1.627-2.77-3.904-2.558-6.251c0.096-1.068,0.425-2.095,0.945-3.016c-0.409-0.747-0.771-1.641-0.91-2.688
      c-0.621-4.657,1.289-6.937,3.022-8.402l0.036-0.03c0.443-0.594,0.928-1.064,1.386-1.438c0.021-0.84,0.216-1.645,0.58-2.415
      c1.205-2.554,3.622-3.344,4.417-3.604c0.579-0.189,1.141-0.298,1.668-0.353c0.053-0.065,0.108-0.131,0.165-0.195
      c0.998-1.134,2.174-1.666,2.925-1.937c0.27-0.203,0.536-0.382,0.794-0.541c-0.015-0.552-0.059-0.893-0.104-1.103
      c-0.264-0.275-0.827-0.738-1.218-1.06c-1.792-1.475-4.5-3.702-5.136-7.517c-0.489-2.94,0.515-5.392,1.18-7.013
      c0.152-0.372,0.366-0.895,0.464-1.218c-0.137-0.229-0.363-0.561-0.541-0.821c-0.826-1.209-1.958-2.865-2.364-5.181
      c-0.629-3.587,0.682-6.255,1.549-8.021c0.184-0.372,0.447-0.909,0.54-1.19c-0.04-0.186-0.123-0.484-0.188-0.716
      c-0.341-1.226-0.855-3.077-0.51-5.353c0.275-1.822,0.987-3.238,1.508-4.271c0.182-0.362,0.431-0.857,0.473-1.033
      c0.099-1.086,0.09-1.438-0.853-2.964c-0.112-0.134-0.249-0.279-0.388-0.432c-0.997-1.099-2.237-2.466-2.648-4.429
      c-0.33-1.583-0.356-3.744,1.042-5.748c0.091-1.086-0.015-2.026-0.321-2.949c-0.266-0.803-1.493-1.81-2.792-2.874
      c-1.123-0.922-2.396-1.966-3.557-3.274c-0.386-0.436-0.72-0.832-1.027-1.197c-1.021-1.211-1.033-1.217-1.711-1.495
      c-0.349-0.143-0.652-0.235-1.036-0.353c-1.725-0.526-3.446-1.177-5.541-3.318c-0.866-0.887-1.557-1.723-2.165-2.46
      c-1.482-1.798-1.481-1.801-2.384-1.766c-1.743,0.063-3.606,0.397-5.578,0.752c-0.99,0.178-1.979,0.354-2.972,0.504
      c-3.468,0.525-6.365,0.101-8.924-0.27c-0.907-0.133-1.764-0.257-2.636-0.342c-4.595-0.441-10.85,0.939-15.215,3.367
      c-1.339,0.745-2.509,2.34-3.863,4.186c-0.448,0.61-0.896,1.221-1.358,1.814c-2.868,3.688-5.985,5.207-9,6.676l-0.108,0.053
      c-2.682,1.308-3.282,1.914-3.83,3.189c-0.304,0.709-0.598,1.882-0.881,3.017c-0.628,2.516-1.341,5.367-3.09,7.745
      c-1.303,1.774-3.105,2.686-4.336,3.29c-0.003,0.743,0.111,1.84,0.188,2.574c0.083,0.793,0.162,1.572,0.201,2.311
      c0.024,0.448,0.052,0.883,0.079,1.307c0.198,3.077,0.421,6.564-1.214,10.577l-0.768,1.884l-1.791,0.966
      c-0.355,0.191-0.74,0.376-1.148,0.553c0.103,0.51,0.154,1.055,0.138,1.635c-0.04,1.415-0.518,3.491-2.572,5.351
      c-0.412,0.372-3.415,2.919-7.076,1.476l-1.482,0.876c-0.165,0.115-0.62,0.631-0.92,0.973c-1.061,1.203-2.663,3.021-5.255,3.949
      c-1.021,0.367-2.011,0.49-2.943,0.448c-0.043,0.075-0.086,0.146-0.128,0.215c-2.277,3.663-6.24,3.602-7.54,3.578
      c-0.137-0.002-0.273-0.009-0.403-0.002c-1.318,0.119-2.518,0.071-3.481,0.032c-0.601-0.023-1.422-0.058-1.671,0.006
      c0.028-0.008,0.079-0.029,0.102-0.045l-6.506-9.286c3.085-2.161,6.103-2.101,8.349-2.012c0.204-0.729,0.502-1.303,0.734-1.677
      c1.986-3.209,5.361-4.04,10.032-2.472c0.072-0.081,0.142-0.16,0.207-0.233c0.371-0.421,0.802-0.91,1.314-1.411
      c0.001-0.012,0.002-0.021,0.003-0.03l-0.212-5.551l4.906-0.218c0.017-0.136,0.039-0.272,0.064-0.41l0.872-4.665l4.745,0.037
      c0.583-0.009,1.527-0.149,2.417-0.372c0.067-0.917,0.008-1.984-0.085-3.444c-0.03-0.463-0.06-0.937-0.086-1.425
      c-0.03-0.557-0.094-1.145-0.156-1.742c-0.322-3.084-0.763-7.308,1.87-10.811c1.248-1.66,2.918-2.479,4.137-3.077
      c0.146-0.071,0.33-0.161,0.492-0.244c0.368-0.808,0.75-2.335,1.017-3.404c0.393-1.569,0.798-3.192,1.463-4.741
      c2.198-5.115,5.943-7.28,9.28-8.907l0.11-0.054c2.678-1.305,3.813-1.896,5.015-3.441c0.396-0.511,0.781-1.036,1.167-1.563
      c1.87-2.548,3.988-5.437,7.493-7.387c6.38-3.55,14.94-5.413,21.823-4.744c1.142,0.11,2.174,0.261,3.173,0.406
      c2.119,0.308,3.791,0.551,5.6,0.279c0.888-0.134,1.773-0.294,2.66-0.454c2.273-0.408,4.625-0.831,7.172-0.923
      c6.474-0.241,9.529,3.445,11.539,5.88c0.512,0.619,0.994,1.204,1.527,1.749c0.134,0.138,0.222,0.215,0.268,0.253
      c0.152,0.058,0.309,0.097,0.476,0.148c0.504,0.153,1.194,0.364,2.033,0.709c3.264,1.34,4.693,3.037,6.076,4.679
      c0.252,0.299,0.525,0.624,0.842,0.98c0.562,0.635,1.387,1.312,2.261,2.028c2.257,1.851,5.064,4.153,6.363,8.071
      c1.013,3.049,1.194,6.22,0.558,9.692c-0.026,0.146-0.058,0.286-0.092,0.424c0.212,0.265,0.417,0.545,0.606,0.847
      c1.92,3.069,3.026,5.852,2.615,10.241c-0.205,2.193-1.025,3.824-1.625,5.016c-0.129,0.257-0.296,0.588-0.386,0.8
      c0.043,0.189,0.121,0.47,0.183,0.69c0.322,1.158,0.81,2.908,0.547,5.038c-0.257,2.079-1.06,3.714-1.646,4.907
      c-0.167,0.341-0.383,0.78-0.492,1.062c0.133,0.216,0.333,0.509,0.492,0.742c0.789,1.154,1.87,2.736,2.338,4.923
      c0.711,3.32-0.397,6.024-1.13,7.813c-0.086,0.208-0.187,0.453-0.273,0.678c0.291,0.261,0.658,0.563,0.94,0.795
      c1.47,1.208,3.298,2.713,4.365,5.126c1.262,2.857,1.203,6.301,0.952,9.597l-0.165,2.16l-1.563,1.499
      c-1.127,1.082-2.325,1.636-3.119,2.003c-0.106,0.049-0.215,0.095-0.319,0.146c-0.116,0.104-0.233,0.199-0.351,0.29
      c-0.305,0.956-0.888,1.998-1.999,2.851c-1.139,0.87-2.321,1.254-3.383,1.394c-0.191,0.375-0.4,0.714-0.612,1.017
      c-0.928,1.324-2.072,2.078-2.784,2.511c0.832,1.348,1.722,3.347,1.258,5.769c-0.096,0.503-0.264,1.082-0.549,1.689
      c2.986,3.958,3.619,8.448,1.656,12.31c0.116,0.322,0.224,0.664,0.316,1.024c1.603,1.045,3.613,3.058,3.939,6.978l-11.299,0.941
      C499.217,497.793,499.81,498.65,500.458,498.979z M494.797,461.672c-0.001,0.002-0.002,0.004-0.003,0.005
      C494.795,461.675,494.796,461.673,494.797,461.672z M513.197,460.315c-0.003,0.002-0.006,0.005-0.008,0.008
      C513.191,460.32,513.194,460.318,513.197,460.315z M506.915,449.946L506.915,449.946L506.915,449.946z M502.209,433.22
      L502.209,433.22L502.209,433.22z M501.31,417.93L501.31,417.93L501.31,417.93z M511.812,413.203L511.812,413.203L511.812,413.203z
       M417.456,406.524C417.456,406.525,417.456,406.525,417.456,406.524L417.456,406.524z M492.149,369.756
      C492.15,369.756,492.15,369.756,492.149,369.756C492.15,369.756,492.15,369.756,492.149,369.756z" pointerEvents="none" stroke="#95bec8" strokeWidth="0.5px" className="landarea" strokeOpacity="0"></path>
   <path id="RIVER_2_" fill="none" stroke="#95bec8" strokeMiterlimit="10" d="M376.46,441.178c2.365-1.656,4.768-0.724,7.833-1
      c1.252-0.113,2.828,0.319,3.598-0.919c0.952-1.532-1.473-2.31-0.779-3.431c1.14-1.844,4.702,1.159,6.755,0.424
      c2.197-0.787,3.173-3.265,5.203-4.466c0.106-1.078,0.483-2.122,0.441-3.224c1.335-0.078,2.689-0.134,3.867,0.259
      c1.073,0.358,1.795,2.68,3.332,1.289c2.629-2.379-2.923-4.45-2.531-6.549c2.145,0.017,5.439-0.812,7.226-1.775
      c1.322-3.242,0.911-5.953,0.724-9.439c-0.159-2.953-1.074-6.636,0.585-8.843c0.895-1.19,3.464-1.645,4.456-2.993
      c1.8-2.449,2.107-6.792,3.33-9.641c1.361-3.17,3.465-4.543,6.555-6.049c2.866-1.397,5.031-2.43,7.115-5.111
      c2.107-2.71,3.898-5.782,6.942-7.475c5.04-2.804,12.461-4.644,18.518-4.056c3.545,0.344,6.483,1.205,10.166,0.649
      c3.059-0.462,6.095-1.204,9.192-1.316c4.827-0.175,5.822,2.874,8.807,5.927c1.772,1.812,2.639,1.554,4.677,2.391
      c2.447,1.005,3,2.114,4.828,4.176c2.466,2.782,6.284,4.498,7.487,8.123c0.769,2.318,0.788,4.565,0.362,6.886
      c-0.181,0.987-1.274,0.711-0.914,2.435c0.156,0.747,1.729,2.115,2.2,2.868c1.481,2.366,2.033,3.952,1.776,6.702
      c-0.2,2.138-1.713,3.595-2.013,5.574c-0.319,2.102,1.003,3.747,0.749,5.806c-0.336,2.729-2.689,4.628-2.161,7.642
      c0.382,2.183,2.46,3.648,2.935,5.871c0.66,3.085-2.135,5.591-1.666,8.41c0.514,3.088,4.749,4.445,5.926,7.109
      c0.816,1.847,0.637,4.874,0.485,6.874c-0.898,0.862-2.297,1.045-3.228,1.954c-0.492,0.481-1.384,0.444-1.782,0.896
      c-0.374,0.425-0.043,1.831-0.303,2.03c-1.034,0.791-2.603-0.304-4.024,0.16c-2.498,0.817,0.256,1.467-1.167,3.5
      c-0.642,0.918-1.862,1.118-2.482,2.026c-0.919,1.347-2.344,1.156-1.935,4.224c0.213,1.595,3.667,3.736,0.966,5.466
      c-0.883,0.534-1.528,1.911-0.307,2.99s5.19,5.098,2.534,8.911c-3.311,0.489,0.372,2.046,0.44,5.53
      c-0.068,2.433,3.283,0.852,3.617,4.852" pointerEvents="none" opacity="0" strokeWidth="0.5px" className="landarea"></path>
   <circle id="CITY_7_" fill="#166c38" cx="448.793" cy="368.518" r="8.5" pointerEvents="none" stroke="#95bec8" strokeWidth="0.5px" className="landarea" opacity="0"></circle>
   <ellipse fill="none" stroke="#95bec8" strokeMiterlimit="10" cx="450.831" cy="368.518" rx="15.258" ry="13.549" pointerEvents="none" opacity="0" strokeWidth="0.5px" className="landarea"></ellipse>
</g>
<g id="CITY_MEDITERRANEAN" data-colors="0" data-errors="0" hint-index="0" className="">

      <rect id="rect12_4_1_" x="385.631" y="31.412" opacity="0" fill="#FFFFFF" stroke="#020304" strokeWidth="0.9741" strokeMiterlimit="10" enable-background="new    " width="82.19" height="20.398" pointerEvents="none"></rect>
   <circle id="CITY_4_1_" opacity="0" fill="#166c38" enable-background="new    " cx="426.726" cy="64.045" r="7.5" pointerEvents="none" stroke="#95bec8" strokeWidth="0.5px" className="landarea"></circle>

      <ellipse id="_x3C_Path_x3E__1_" fill="none" stroke="#95bec8" strokeMiterlimit="10" cx="435.989" cy="66.261" rx="41.149" ry="40.071" pointerEvents="none" opacity="0" strokeWidth="0.5px" className="landarea"></ellipse>
</g>
<g id="CITY_ATLANTIC" data-colors="0" data-errors="0" hint-index="0" className="">

      <rect id="rect12_4_2_" x="5.219" y="64.651" opacity="0" fill="#FFFFFF" stroke="#020304" strokeWidth="0.9741" strokeMiterlimit="10" enable-background="new    " width="82.19" height="20.398" pointerEvents="none"></rect>
   <circle id="CITY_4_2_" opacity="0" fill="#166c38" enable-background="new    " cx="46.314" cy="97.283" r="7.5" pointerEvents="none" stroke="#95bec8" strokeWidth="0.5px" className="landarea"></circle>

      <ellipse id="_x3C_Path_x3E__2_" fill="none" stroke="#95bec8" strokeMiterlimit="10" cx="38.404" cy="88.349" rx="58.322" ry="64.57" pointerEvents="none" opacity="0" strokeWidth="0.5px" className="landarea"></ellipse>
</g>
<g id="CITY_REDSEA" data-colors="0" data-errors="0" hint-index="0" className="">

      <rect id="rect12_4_3_" x="558.576" y="148.819" opacity="0" fill="#FFFFFF" stroke="#020304" strokeWidth="0.9741" strokeMiterlimit="10" enable-background="new    " width="82.19" height="20.398" pointerEvents="none"></rect>
   <circle id="CITY_4_3_" opacity="0" fill="#166c38" enable-background="new    " cx="599.671" cy="181.452" r="7.5" pointerEvents="none" stroke="#95bec8" strokeWidth="0.5px" className="landarea"></circle>

      <ellipse id="_x3C_Path_x3E__3_" fill="none" stroke="#95bec8" strokeMiterlimit="10" cx="602.934" cy="180.668" rx="16.397" ry="15.967" pointerEvents="none" opacity="0" strokeWidth="0.5px" className="landarea"></ellipse>
</g>
<g id="CITY_INDIANOCEAN" data-colors="0" data-errors="0" hint-index="0" className="">

      <rect id="rect12_4_4_" x="798.92" y="357.881" opacity="0" fill="#FFFFFF" stroke="#020304" strokeWidth="0.9741" strokeMiterlimit="10" enable-background="new    " width="82.19" height="20.398" pointerEvents="none"></rect>
   <circle id="CITY_4_4_" opacity="0" fill="#166c38" enable-background="new    " cx="840.015" cy="390.513" r="7.5" pointerEvents="none" stroke="#95bec8" strokeWidth="0.5px" className="landarea"></circle>

      <ellipse id="_x3C_Path_x3E__4_" fill="none" stroke="#95bec8" strokeMiterlimit="10" cx="851.876" cy="384.131" rx="61.484" ry="76.185" pointerEvents="none" opacity="0" strokeWidth="0.5px" className="landarea"></ellipse>
</g>
<g id="CITY_CAPEOFGOODHOPE" data-colors="0" data-errors="0" hint-index="0" className="">

      <ellipse id="_x3C_Path_x3E__5_" fill="none" stroke="#95bec8" strokeMiterlimit="10" cx="434.608" cy="701.896" rx="23.137" ry="23.104" pointerEvents="none" opacity="0" strokeWidth="0.5px" className="landarea"></ellipse>
   <rect id="rect12_10_" x="409.608" y="708.877" fill="#FFFFFF" width="50" height="16" pointerEvents="none" opacity="0"></rect>
   <circle id="CITY_13_" fill="#166c38" cx="434.333" cy="701.896" r="8.5" pointerEvents="none" stroke="#95bec8" strokeWidth="0.5px" className="landarea" opacity="0"></circle>
</g>
<g id="CITY_THESTRAITOFGIBRALTAR" data-colors="0" data-errors="0" hint-index="0" className="">
   <line fill="none" stroke="#000000" strokeMiterlimit="10" x1="119.561" y1="53.67" x2="220.894" y2="53.67" pointerEvents="none" opacity="0"></line>

      <ellipse id="_x3C_Path_x3E__6_" fill="none" stroke="#95bec8" strokeMiterlimit="10" cx="221.792" cy="53.852" rx="23.137" ry="23.104" pointerEvents="none" opacity="0" strokeWidth="0.5px" className="landarea"></ellipse>
   <rect id="rect12_15_" x="94.438" y="45.491" fill="#FFFFFF" width="50" height="16" pointerEvents="none" opacity="0"></rect>
   <circle id="CITY_22_" fill="#166c38" cx="220.329" cy="53.388" r="8.5" pointerEvents="none" stroke="#95bec8" strokeWidth="0.5px" className="landarea" opacity="0"></circle>
</g>
</svg>
}