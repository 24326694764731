export default function Oceania() {
    return <svg version="1.1" id="svgpoint" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 900 725" enable-background="new 0 0 900 725" width="100%" height="100%">
    <rect id="WATER" x="1.363" y="-0.203" fill="#a4d1dc" width="900" height="724.989" stroke="#95bec8" stroke-width="0.1" opacity="1" class="water"></rect>
    <g id="CITY_GREATBARRIERREEF" data-colors="0" data-errors="0" hint-index="0" class="">

            <path id="SEMITRANSPARENT2_23_" fill-rule="evenodd" clip-rule="evenodd" fill="#166c38" fill-opacity="0.9412" stroke="#95bec8" stroke-width="0.5px" d="
            M221.724,475.689l17.733-0.142l-9.487-33.8l-18.5-11.677l-19.058-12.108l-14.393-10.033l-8.783-24.164l-8.784-19.331v-22.392
            l-21.6,0.055l-0.892,2.572l15.013,58.47l65.388,70.928L221.724,475.689z" pointer-events="none" class="landarea" opacity="0" stroke-opacity="0"></path>

            <rect id="rect12_20_" x="168.556" y="399.017" opacity="0" fill="#FFFFFF" stroke="#010202" stroke-miterlimit="10" enable-background="new    " width="73.17" height="17.149" pointer-events="none"></rect>
        <circle id="CITY_20_" opacity="0" fill="#166c38" enable-background="new    " cx="203.822" cy="427.495" r="6.86" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>
    </g>
    <g id="LAND" data-colors="0" data-errors="0" hint-index="0" class="">
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M32.917,23.285
            c-0.526,0-2.98,1.226-2.98,1.928s-0.176,1.578,0.701,1.227s1.578-1.403,1.578-1.403l1.578-1.578L32.917,23.285z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M28.184,29.245
            c-0.35-0.701-1.052-0.701-1.052-0.175s0.001,1.753,0.702,1.052C28.535,29.42,28.184,29.245,28.184,29.245z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M25.029,32.4
            c-0.526,0.175-1.403,0.877-0.526,0.877C25.379,33.277,25.029,32.4,25.029,32.4z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M22.575,37.659
            c-0.526,0.526-0.526,1.928-1.578,1.753c-1.052-0.175-1.753,0.176-1.753,0.176s0.351,0.701-0.175,1.227s-1.753,1.753-1.227,1.928
            s1.227,0.35,1.578-0.176c0.35-0.526,0.526-1.402,1.052-1.753c0.526-0.351,1.578-1.227,2.279-1.753
            C23.451,38.536,22.575,37.659,22.575,37.659z" pointer-events="none" class="landarea"></path>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="46.064" cy="46.599" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="36.072" cy="24.688" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="122.667" cy="34.854" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="133.01" cy="30.999" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="133.36" cy="35.732" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="133.01" cy="39.916" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="125.998" cy="50.456" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="126.173" cy="56.416" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="127.575" cy="60.798" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="226.573" cy="60.141" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="328.166" cy="102.587" r="0.372" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="460.983" cy="252.219" r="0.372" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="573.915" cy="207.905" r="0.372" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="482.018" cy="44.496" r="0.372" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="467.425" cy="28.982" r="0.372" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="455.199" cy="25.959" r="0.372" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="447.31" cy="23.855" r="0.372" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="500.03" cy="47.52" r="0.372" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="509.364" cy="50.412" r="0.372" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="530.268" cy="54.093" r="0.372" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="543.832" cy="65.136" r="0.372" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="555.664" cy="66.451" r="0.372" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="517.757" cy="124.041" r="0.372" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="576.386" cy="211.921" r="0.372" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="462.035" cy="256.93" r="0.372" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="450.991" cy="500.171" r="0.372" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="446.259" cy="508.585" r="0.372" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="445.207" cy="512.003" r="0.372" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="194.713" cy="394.206" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="188.228" cy="394.557" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="200.849" cy="400.517" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="212.768" cy="412.086" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="208.912" cy="398.238" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="242.217" cy="398.413" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="230.298" cy="428.214" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="223.987" cy="435.576" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="238.887" cy="438.052" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="239.413" cy="448.02" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="267.986" cy="502.537" r="0.372" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="267.285" cy="506.919" r="0.372" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="195.59" cy="388.597" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="171.75" cy="382.812" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="177.184" cy="393.527" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="168.244" cy="369.315" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="82.087" cy="377.379" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="88.222" cy="383.076" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="90.15" cy="384.04" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="111.624" cy="395.346" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="45.713" cy="58.519" r="0.373" pointer-events="none" class="landarea"></circle>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="84.629" cy="93.578" r="0.373" pointer-events="none" class="landarea"></circle>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M1.715,176.228
            c0.087-0.438-0.175-1.14-0.526-0.789s-0.526,1.227-0.614,1.578s-0.438,0.789-0.175,0.877s0.876,0.439,0.876,0.789
            s-0.176,1.578,0.263,1.49s0.439-0.439,0.439-0.877s0.087-1.578-0.088-1.841S1.715,176.228,1.715,176.228z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M5.396,179.647
            c-0.176-0.526-0.613-0.877-0.789-0.526s-0.789,1.402-0.439,1.49c0.351,0.088,1.315,0.35,1.403,0
            C5.659,180.26,5.396,179.647,5.396,179.647z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M1.89,210.937
            c-0.088,0.526-0.701,1.315-0.964,1.49s-1.315,1.753-1.665,2.191c-0.351,0.438-0.526-0.526-0.526-1.227s-0.438-0.351-0.701-0.877
            s0.35-0.964,0.526-1.315s-0.088-0.789-0.351-1.052s-0.877,0.877-1.052,1.315c-0.175,0.438-0.964,0.526-1.49,0.351
            s-2.279-0.877-2.717-1.227c-0.438-0.351-3.418-1.928-3.944-2.542c-0.526-0.614-0.351-2.542-0.351-2.805s-0.175-0.526-0.613-0.789
            s0.438-1.928,0.613-2.279c0.175-0.351,1.14-0.876,1.403-1.139s0.175-0.702,0.175-1.315s-1.578-1.49-1.928-2.016
            c-0.35-0.526-1.753-0.613-2.366-0.964s-1.052-0.614-1.578-0.614c-0.526,0-0.614-0.087,0,1.052s-0.351,0.702-0.614,0.789
            s-0.088,0.877,0,1.14s0.175,0.789,0.175,0.789s-0.876-0.351-1.139-0.789s-0.351-0.263-0.964-0.526c-0.614-0.263,0,0,0-0.438
            s-0.175-0.614-0.438-0.702s-0.701,1.14-0.789,1.403s-0.964,0.351-1.403,0.351s-0.35-1.227-0.35-1.665s-0.351-0.789-0.614-0.789
            s-1.403,0.526-1.665,0.964c-0.263,0.438-0.789,1.14-1.139,1.49c-0.35,0.351-0.088,1.052-0.439,1.402
            c-0.351,0.35-1.139,2.892-1.578,3.506s-1.402-2.016-1.402-2.366s0.35-0.877,0.789-1.052s0.263-1.753,0.526-2.366
            c0.263-0.614,0.175-1.052,0-1.403s0.263-1.052,0.789-1.49s3.243-1.227,3.768-1.315c0.526-0.088,1.403-0.351,1.928-0.526
            c0.526-0.175,0.526-0.614,0.438-1.052s0.176-0.964,0.526-1.403c0.35-0.439,1.14-0.526,1.928-0.526c0.789,0,0.526-0.613,0.789-1.139
            s0.964,0.263,1.665,0.701c0.702,0.438,0.876-0.351,1.227-0.088s0.701,1.928,0.789,2.805c0.088,0.877-0.263,1.139-0.701,1.578
            s1.753-0.789,2.191-1.052s1.315-0.351,1.665-0.439c0.351-0.088,0.438-1.49,0.526-2.104s0.877-0.876,1.14-0.964
            c0.263-0.088,1.315,0.701,1.665,0.964s0.877-0.876,0.964-1.315c0.088-0.439,0.263-2.191,0.263-2.454s0.613-0.351,1.402,0.087
            s0.877,0.964,1.227,0.964c0.351,0,0.613-1.14,0.613-1.49c0-0.351,0.702-0.087,1.052,0.263s0.702,0.176,1.227-0.087
            s0.087-2.016,0-2.717c-0.087-0.701-0.614-2.366-0.702-2.98s0.702-0.438,0.964-0.175s0.526,0.526,0.789,0.877
            c0,0,0.263,0.351,0.964,0.526s1.052,0.35,1.315,0.701s0.788,1.578,1.227,1.578c0.439,0,0.789-0.176,0.964,0.263
            c0.175,0.439,0.087,1.753,0.438,2.016c0.351,0.263,0.526,1.052,0.526,1.315s-0.263,0.964-0.614,1.052
            c-0.35,0.087-1.314,0.964-0.876,1.227s1.402-0.088,1.665,0.175s0.263,0.526,0.176,0.877c-0.088,0.351-0.702,1.753-0.176,1.753
            s0.877-0.262,0.877,0.176c0,0.438-0.262,1.052-0.438,1.578s-0.351,0.525,0.087,1.139c0.439,0.614,1.052,0.614,1.14,1.14
            s0.263,1.578,0.263,2.191c0,0.613,0.351,1.49,0,1.841c-0.351,0.35-0.964,1.315-0.964,1.315s-1.052,0.262-0.964,0.613
            c0.088,0.351,0.263,1.14-0.087,0.877c-0.351-0.263-1.052-0.701-0.964-0.263c0.088,0.438,0.176,2.892,0.176,3.418
            s-0.263,0.877-0.439,0.614c-0.175-0.263-0.613-0.789-0.613-1.403s0.351-2.191-0.263-2.454s-0.789-0.963-0.964-1.402
            s-0.876-1.227-0.876-1.753s-0.263-0.963-0.614-0.613c-0.351,0.35-0.877,0.702-1.14,1.315s-0.438,1.49-0.701,1.841
            s-1.227,1.227-1.052,1.753s1.315,1.402,1.578,2.016c0.263,0.614,0.701,1.666,0.789,2.016C1.978,209.622,1.89,210.937,1.89,210.937z
            " pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M2.591,202.347
            c-0.175-0.438-0.526-0.702-0.526-0.088s-0.088,1.666,0.263,1.227C2.679,203.048,2.591,202.347,2.591,202.347z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M11.444,223.383c0,0-0.876-1.052-1.052-0.614
            c-0.176,0.439-0.702,0.789-0.351,1.227s0.789,0.702,0.526,1.052s-1.052,1.227-0.614,1.49s0.701,0.262,0.789-0.088
            c0.088-0.35,0.088-0.526,0.439-0.964c0.35-0.438,0.7-0.526,0.613-0.877C11.707,224.259,11.444,223.383,11.444,223.383z" pointer-events="none" class="landarea"></path>

            <ellipse fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="70.649" cy="251.429" rx="0.417" ry="0.427" pointer-events="none" class="landarea"></ellipse>

            <ellipse fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="71.043" cy="274.918" rx="0.417" ry="0.427" pointer-events="none" class="landarea"></ellipse>

            <ellipse fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="49.307" cy="293.675" rx="0.417" ry="0.427" pointer-events="none" class="landarea"></ellipse>

            <ellipse fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="47.466" cy="290.081" rx="0.417" ry="0.427" pointer-events="none" class="landarea"></ellipse>

            <ellipse fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="48.868" cy="279.388" rx="0.417" ry="0.427" pointer-events="none" class="landarea"></ellipse>

            <ellipse fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="50.271" cy="296.304" rx="0.417" ry="0.427" pointer-events="none" class="landarea"></ellipse>

            <ellipse fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="26.343" cy="287.539" rx="0.417" ry="0.427" pointer-events="none" class="landarea"></ellipse>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M1.013,229.43
            c-0.438-0.351-1.315-0.35-1.052,0.088s0.438,0.701,0.526,0.964s0.263,0.789,0.526,1.052s0.789,0.438,0.789,0
            s-0.35-0.877-0.35-0.877L1.013,229.43z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M-0.126,237.055
            c0-0.438-0.526-0.964-0.526-0.438s0.263,1.139,0.263,1.139L-0.126,237.055z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M-0.301,239.247
            c-0.25-0.083-0.7,0.525-0.35,0.613c0.35,0.088,0.789,0.176,0.789-0.175C0.137,239.334-0.038,239.335-0.301,239.247z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M15.212,255.286
            c-0.25-0.083-0.7,0.525-0.35,0.613c0.35,0.088,0.789,0.176,0.789-0.175C15.651,255.373,15.475,255.374,15.212,255.286z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M1.89,272.859
            c-0.92,0-2.104-0.394-1.841,0.131s1.446,0.526,2.104,0.526s5.259-0.789,4.076-0.789S1.89,272.859,1.89,272.859z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M4.256,275.225
            c0-0.394-0.789-1.709-0.789-0.526s0.131,2.366,0.131,2.366s1.183,0.657,1.183,0.263C4.782,276.935,4.256,275.225,4.256,275.225z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M18.192,269.178
            c-0.657,0-0.92,0.131-1.446,0.263s-1.183,0.263-1.183,0.92s0.131,1.709,0.789,1.709s1.183,0,1.972-0.131s2.761,0.131,2.892-0.394
            c0.131-0.526,1.446,0.526,0.131-0.526S18.192,269.178,18.192,269.178z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M16.746,267.6
            c-0.394,0-1.052,0.394-0.526,0.526s1.709,0.263,1.578-0.131C17.666,267.6,16.746,267.6,16.746,267.6z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M24.678,237.669
            c-0.667,0-1.403,0.702-1.578,1.052s-0.964,1.226-0.964,1.665s-0.001,1.49,0.438,1.665c0.439,0.175,1.227,0.35,1.578-0.088
            s1.403-1.402,1.49-2.016c0.087-0.614,0.262-0.964,0.087-1.315C25.555,238.282,24.941,237.669,24.678,237.669z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M20.208,242.051
            c0.176-0.614,0.526-1.227,0-0.964s-1.841,1.315-2.016,1.578c-0.175,0.263-0.789,0.613-1.052,1.402
            c-0.263,0.789-0.701,2.893-0.964,3.331c-0.263,0.438-0.614,1.051-0.702,1.402s-0.351,0.964,0,1.052s0.526,0.439,0.789,0.964
            c0.263,0.526,0.877,0.964,0.614,1.49s-0.263,1.227-0.263,1.665c0,0.438,0.438,1.14,0.789,1.578s0.438,0.613,0.438,0.876
            s0,1.841,0,2.104s-0.175,1.753,0.176,1.928c0.35,0.175,1.052,0.789,1.052,0.789s1.841,3.067,2.366,3.33s2.104,0.789,1.49,0.176
            c-0.614-0.614-1.227-0.964-1.49-1.753s-2.104-3.944-2.016-4.557c0.088-0.614,0-1.14,0.088-1.753s-0.088-1.928,0.526-1.841
            c0.614,0.088,1.49,0.351,2.279,0.439c0.789,0.087,1.402,0.175,1.841,0.438s2.542,1.139,2.454,0.701
            c-0.088-0.438-0.701-0.877-0.789-1.315s0.351-0.789-0.175-0.964s-2.192-0.788-2.542-0.964s-1.315-1.227-0.701-1.49
            s1.665-0.876,2.104-1.227c0.438-0.351,1.402-0.263,1.49-1.052s0.438-1.665,0.263-2.016c-0.175-0.351-0.439-1.315-0.789-1.315
            s-2.016,0-2.279,0.263s-2.191,1.402-1.841,1.665c0.351,0.263,1.227,0.702,0.701,0.964s-1.315,0.175-1.578,0.613
            c-0.263,0.439-0.963,2.718-1.402,2.542c-0.439-0.176-2.104-1.49-1.315-2.104s1.665-0.702,1.928-1.403s0.702-1.665,0.702-2.366
            s-0.263-1.665-0.526-1.841c-0.263-0.175-0.702-0.876-0.439-1.227C19.682,242.84,20.208,242.051,20.208,242.051z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M14.423,261.158
            c-0.175-0.438-0.7-1.315-0.876-0.701c-0.176,0.614-0.701,1.578-0.263,1.665C13.722,262.21,14.423,261.158,14.423,261.158z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M14.248,263.7
            c-0.263-0.263-1.314,0.263-0.701,0.526s1.139,0.788,1.227,0.35S14.248,263.7,14.248,263.7z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M16.79,260.895
            c-0.263,0.263-0.438,0.614-0.701,0.439c-0.263-0.176-0.877-0.35-1.052,0s-0.263,0.876-0.088,1.139
            c0.176,0.263,0.789,0.526,0.789,0.964s-0.262,1.051,0.176,1.139s1.227-0.35,1.49-0.087s0.876,0.789,1.227,0.701
            s1.052-0.701,0.526-0.964s-2.104-0.789-1.753-1.315s0.614-0.701,0.263-1.315C17.316,260.983,16.79,260.895,16.79,260.895z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M35.283,267.294
            c-0.351,0-1.14,0.175-0.789,0.526c0.351,0.351,1.315-0.175,1.315-0.175L35.283,267.294z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M37.562,272.027
            c-0.526,0.088-0.877,0.088-1.315,0.263s-2.542,1.052-2.016,1.315s1.928,0.263,2.366,0.614c0.438,0.35,1.14,0.613,1.403,0.35
            s0.438-0.963,0.876-1.139c0.439-0.176,0.701-0.176,0.526-0.702s-0.35-1.226-0.701-1.139
            C38.351,271.676,37.562,272.027,37.562,272.027z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M40.98,258.967c-0.613,0-0.439-0.35-1.052,0
            s-0.963,0.439-1.139,0.789s-0.175,0.613,0.263,0.613s0.964-0.175,1.052,0.088c0.087,0.263-0.176,1.578,0.175,1.578
            c0.351,0,0.175,0.263,0.526-0.438c0.351-0.702-0.087-1.403,0.351-0.702c0.438,0.702,0.613,1.14,0.964,1.14s1.227-0.175,1.578-0.438
            c0.351-0.263,0.702-0.351,1.14-0.351s1.226,0.35,1.402-0.088c0.176-0.438,0.526-1.489,0.263-1.665s-1.928-0.789-2.454-0.789
            c-0.526,0-2.016-0.439-2.016,0.175c0,0.614,0.263,1.665-0.263,1.052C41.243,259.318,40.98,258.967,40.98,258.967z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M41.243,264.664
            c-0.438,0-1.403-0.088-1.578,0.263s-0.964,0.088-0.175,0.351s2.629,0.087,3.067-0.176c0.439-0.263,1.315-0.526,0.526-0.701
            S41.243,264.664,41.243,264.664z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M31.953,258.792c0,0-1.227-0.701-0.614,0.176
            c0.614,0.876,1.403,1.227,1.403,0.701S31.953,258.792,31.953,258.792z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M44.048,265.716
            c-0.526,0-2.016,0.175-2.016,0.175s-0.438,0.175-0.175,0.789s1.051,1.841,1.49,2.016s0.702,0,0.877-0.526s0.438-0.877,0.438-1.403
            S44.311,265.716,44.048,265.716z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M9.427,282.632
            c-0.432,0.072-2.191,0.087-2.629,0.175c-0.438,0.088-0.876,0.439-1.139,0.351c-0.263-0.088-1.052-0.263-1.14,0.263
            s-0.088,1.489,0.263,1.928s1.665,2.016,2.016,2.104s3.243,1.315,3.769,1.14s0.701-0.613,1.227-0.701s1.403-0.35,1.753-0.526
            s0.613-0.438,0.613-0.789s0.526-1.49-0.175-1.49s-1.402-0.526-1.227-0.789s0.877-0.351,0-0.789s-1.227-0.35-1.578-0.613
            C10.83,282.632,9.953,282.545,9.427,282.632z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M23.539,281.054
            c-0.438,0-1.578,0.001-1.841,0.176s-0.701,1.139-0.701,1.139s-0.439,0.088-0.702,0.351s-0.964,0.613-0.964,0.964
            c0,0.351,0.088,1.052,0.088,1.403s-0.176,1.314,0.175,0.964s0.175-0.525,0.526-0.876c0.351-0.351,0.438-0.438,0.526-0.789
            c0.088-0.351,0.614-1.841,0.877-1.49s0.613,1.052,0.613,1.052s0.877,0.526,0.964,0.877s0.438,0.701,0.876,0.701
            c0.439,0,1.315,0.263,1.49-0.087c0.175-0.351,0.351-0.526,0.789-0.789s1.753-1.402,1.753-0.789s0.175,1.315,0.526,1.315
            s1.403-0.001,1.665,0.087c0.263,0.088,2.541,0.789,2.454,0.351s-0.262-1.402,0.439-1.227c0.701,0.175,2.629,0.35,2.805,0.613
            c0.175,0.263,0.262,0.876,0.701,1.052s1.841,0.701,2.366,1.052c0.526,0.351,3.243,2.191,3.506,1.841s0.088-0.876,0.351-1.227
            c0.263-0.351,0.438-0.526,0.35-1.052c-0.087-0.526-0.176-0.964-0.526-1.052c-0.35-0.088-1.315-0.438-1.315-0.964
            s-0.087-1.052-0.438-1.403c-0.351-0.351-1.928-1.403-2.629-1.315s-2.104,0.176-2.454-0.175s-1.928-0.964-2.279-1.14
            c-0.351-0.175-1.665-0.613-2.016-0.175s-1.49,1.14-1.841,1.315s-0.526,0-0.789-0.526s-0.439-0.702-0.702-0.614
            c-0.263,0.088-0.877,0.438-1.578,0.438C25.905,281.054,23.539,281.054,23.539,281.054z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M20.559,286.926
            c-0.277,0-0.7,0.526-0.613,0.789s0.613,0.438,0.876,0.175c0.263-0.263,0.439-0.263,0.877-0.526c0.438-0.263,1.315-0.35,1.315-0.701
            s-0.088-0.877-0.614-0.526C21.873,286.488,20.646,286.926,20.559,286.926z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M24.328,286.313c0,0-0.614,0.35-0.351,0.526
            s0.701,0.439,0.789,0.088C24.854,286.576,24.328,286.313,24.328,286.313z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M102.859,317.164
            c0.549-0.078,2.279-0.439,2.629-0.439c0.351,0,0.526,0,0.877,0.526s0.877,0.877,0.877,0.877s0.351,0.262,0.263,0.7
            c-0.088,0.439-0.001,0.963-0.176,1.315s-0.526,0.35-0.526,0.789s-0.175,1.14-0.175,1.14l-0.877,0.613l-1.928,1.665
            c0,0-0.35,1.052-1.315,0.702c-0.964-0.35-3.331-0.351-3.857-0.176c-0.526,0.176-2.191,0.966-1.841,0.088
            c0.351-0.877,2.455-5.697,3.331-6.223C101.018,318.216,102.245,317.252,102.859,317.164z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M105.138,323.738
            c0.526-0.263,0.877-0.79,1.052-0.439s0.876,1.579,0.35,1.666c-0.526,0.087-2.103,0.175-1.928-0.351
            C104.787,324.088,105.138,323.738,105.138,323.738z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M73.235,301.563
            c-0.263-0.175-1.052-0.175-1.14,0.176c-0.087,0.35-0.7,1.49-1.139,1.49s-1.578,0.351-1.052,0.526s1.052,0.263,0.964,0.877
            c-0.088,0.613-0.789,1.578-0.351,2.366s2.367,2.103,2.805,1.665s0.701-0.877,0.701-1.315s-0.438-0.963-0.438-1.402
            s0.087-1.315,0.087-1.665c0-0.351,0-1.14,0-1.14L73.235,301.563z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M70.254,308.4
            c-0.35-0.438-1.841-1.753-1.49-0.789c0.351,0.964,0.263,1.315,0,2.104s-0.614,2.104-0.351,2.454s0.789,0.702,1.227,0.614
            c0.438-0.088,0.439-0.526,1.052-0.789s1.314-0.614,1.402-0.964c0.088-0.35,0.439-0.789-0.175-1.227
            C71.306,309.364,70.254,308.4,70.254,308.4z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M80.86,263.788
            c-0.526-0.175-0.877-0.088-1.14-0.351s-0.964-0.176-0.701,0.263s1.051,0.438,1.139,0.789s0.614,1.139,0.877,0.789
            s0.788-1.315,0.438-1.315S80.86,263.788,80.86,263.788z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M83.927,265.102
            c-0.263-0.351-1.14-1.227-1.315-0.877c-0.175,0.351-0.789,0.439-0.526,0.964s0.438,0.7,0.438,1.139s-0.087,1.139,0.439,1.315
            s1.314,0.176,1.665,0.176s1.315-0.263,1.578-0.351s0.263-0.877-0.087-0.877c-0.351,0-0.526,0.176-0.964-0.35
            C84.716,265.716,83.927,265.102,83.927,265.102z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M75.601,266.066
            c-0.439-0.438-1.14-0.35-0.964,0.088s0.35,1.14,0.789,1.052C75.864,267.118,75.601,266.066,75.601,266.066z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M77.968,270.01
            c-0.351,0-1.227,0.263-0.702,0.439C77.792,270.624,78.318,270.01,77.968,270.01z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M84.453,271.325
            c-0.526,0-1.227,0.088-1.753,0.088s-3.681-0.438-2.629,0s2.629,0.789,3.243,1.14c0.614,0.35,2.366,0.789,2.98,0.789
            s2.016,0.175,2.629-0.088c0.614-0.263,3.155-0.789,1.753-1.052s-2.366-0.087-3.681-0.438
            C85.68,271.413,84.804,271.325,84.453,271.325z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M61.227,302.002
            c0-0.526,0.175-2.279-0.263-1.578s-0.613,1.314-0.613,1.665s0.087,0.877,0.438,0.614C61.139,302.44,61.227,302.002,61.227,302.002z
            " pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M60,303.842c0-0.263-0.262-0.877-0.438-0.351
            s-1.052,2.367-0.614,2.191C59.386,305.507,60,303.842,60,303.842z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M58.071,303.579
            c-0.175-0.351-0.789-1.139-0.789-0.526s-0.088,2.191,0.263,2.191s0.789-0.176,0.789-0.526S58.071,303.579,58.071,303.579z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M154.132,333.818
            c-0.263,0.175-0.964,0.875-0.526,0.963s-0.35,0.79,0.439,0.088C154.834,334.168,154.132,333.818,154.132,333.818z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M133.448,342.845c0,0-0.964-0.001-0.789,0.35
            s0.526,0.702,0.789,0.439S133.448,342.845,133.448,342.845z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M133.974,338.814c0,0-0.788,0.349-0.438,0.7
            s0.964,0.351,1.052,0.088S133.974,338.814,133.974,338.814z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M152.205,339.427
            c0,0-0.964-0.525-1.052-0.087s-0.175,0.789-0.263,1.052s0.087,0.526,0.35,0.35s0.088-0.438,0.351-0.613
            C151.854,339.953,152.205,339.427,152.205,339.427z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M140.81,335.22
            c0.088-0.526-0.351-0.79-0.614-0.351s-0.877,1.227-0.263,0.964C140.547,335.57,140.81,335.22,140.81,335.22z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M-16.516,339.251
            c-0.263,0.351-0.964,0.877-0.438,0.964c0.526,0.087,0.614,0.001,0.877-0.35s0.438-0.964,0.175-0.964
            S-16.516,339.251-16.516,339.251z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M1.364,323.475
            c0.088-0.526-0.613-0.614-0.701-0.176c-0.088,0.439,0,1.14,0.263,0.964C1.189,324.088,1.364,323.475,1.364,323.475z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M2.854,325.754
            c-0.613,0-2.717,0.439-3.243,0.526s-1.49,0.263-1.665,0.526s-0.614,0.789-0.789,1.052s-1.051,1.052-1.49,1.402
            s-1.315,1.316-2.104,1.49s-2.455,0.963-3.331,1.139s-2.016,0.788-2.366,1.139s-1.402,0.877-1.665,1.403s-0.439,2.015-0.439,2.015
            s-1.052,0.877-0.438,1.14s1.403,1.051,0.877,1.226c-0.526,0.176-1.227,0.44-1.49,0.877c-0.263,0.438-0.439,1.052,0,1.139
            s2.016,0.176,2.454,0s1.665-1.665,2.366-1.665s1.928,0.349,2.454-0.088s2.542-2.98,3.155-3.243s0.964-0.263,1.052-0.789
            s0.088-0.877,0.526-1.227c0.438-0.35,4.119-2.191,4.82-2.454s2.279-0.439,2.717-0.702s1.315-0.963,1.578-0.963
            s1.578,0.175,2.104-0.176c0.526-0.35,1.402-1.227,1.841-1.315c0.439-0.087,1.578-0.176,1.928-0.526
            c0.351-0.35,3.155-2.368,2.805-2.805c-0.35-0.438-1.665-0.614-2.104-0.526c-0.438,0.088-2.103,0.876-2.717,1.052
            s-1.403-0.262-1.928-0.175c-0.526,0.087-1.578,0.877-2.191,0.789S2.854,325.754,2.854,325.754z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M7.762,318.829
            c-0.499,0.501-1.753,0.702-2.191,0.702c-0.438,0-1.49-0.35-1.753-0.087s-1.314,2.365-0.964,2.278
            c0.35-0.087,0.963-0.789,1.665-0.789s3.506,0.703,3.769,0.088s0.438-1.227,0.964-1.227s2.191-0.088,1.578-0.439
            c-0.613-0.35-1.14-0.439-1.315-0.789C9.34,318.216,7.938,318.654,7.762,318.829z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M16.001,318.392
            c0.087-0.439-0.526-0.7-0.702-0.263c-0.175,0.438-0.613,0.963-0.087,0.876C15.739,318.918,16.001,318.392,16.001,318.392z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M20.033,322.599
            c-0.789,0-1.577-0.088-1.139,0.35c0.438,0.439,1.577,1.402,1.928,0.789s1.052-0.262,0.351-0.613
            C20.471,322.773,20.033,322.599,20.033,322.599z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M34.232,320.407
            c-0.438-0.087-1.402-0.351-1.227,0.263s0.525,1.665,1.139,1.315s1.227-0.612,1.052-0.963
            C35.02,320.67,34.232,320.407,34.232,320.407z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M44.398,322.511
            c-0.35-0.087-0.7,0.001-0.876,0.351s-1.49,1.928-0.702,1.402c0.789-0.526,0-0.176,0.789-0.526s1.841-0.525,1.665-0.876
            C45.1,322.511,44.398,322.511,44.398,322.511z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M49.482,315.762
            c0.175-0.263,0.526-0.79,0.088-0.877c-0.439-0.087-0.702-0.176-0.964,0.263c-0.263,0.439-1.139,1.929-1.402,2.016
            s-1.315,0.789-1.315,0.789s-0.176,1.665-0.439,1.928s-0.35,0.35-0.35,0.789s0,1.14,0.438,1.14s0.789,0.086,1.14-0.351
            s0.964-0.964,1.315-1.315c0.35-0.35,1.052-0.964,1.227-1.315c0.175-0.35,0.439-0.877,0.351-1.315
            C49.482,317.077,49.482,315.762,49.482,315.762z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M50.797,314.711
            c-0.263,0-1.139,0.176-0.438,0.526s1.402,0.789,1.49,0.526s0.351-0.877,0-0.964C51.498,314.711,50.797,314.711,50.797,314.711z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M89.099,348.367
            c0.263-0.526,0.964-1.929,0.526-1.49c-0.439,0.439-1.578,2.717-1.227,2.366C88.748,348.893,89.099,348.367,89.099,348.367z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M86.207,350.208
            c-0.263,0.087-1.403,0.963-0.877,0.963s2.366-1.314,1.841-1.226C86.645,350.032,86.207,350.208,86.207,350.208z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M56.844,346.438
            c-0.175-0.35-1.052-0.789-0.789-0.263s0.877,2.278,0.964,1.841C57.107,347.578,56.844,346.438,56.844,346.438z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M42.12,348.805
            c-0.526,0.088-1.226-0.175-1.402-0.438s-1.315-1.489-1.315-1.052c0,0.438,0,1.226,0.263,1.752s0.35,1.841,0.701,2.016
            c0.351,0.176,0.613,0.439,0.964,0.702s1.841,1.578,2.366,1.402c0.526-0.176,2.016-0.876,2.454-1.315s0.439-1.14,0.877-1.227
            c0.438-0.087,0.701-0.526,0.701-0.526s0.876-0.614,0.438-1.052s-0.35-0.789-0.701-1.052s-0.964-0.789-1.227-0.613
            s-0.351,0.789-0.702,0.789c-0.35,0-0.613-0.263-0.876,0s-0.526,0.702-0.877,0.439c-0.351-0.263,0.438-0.438-0.351-0.263
            C42.646,348.542,42.12,348.805,42.12,348.805z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M37.738,349.243
            c0.351-0.439,1.052-0.876,1.14-0.526c0.087,0.35,0.262,1.577,0.613,1.928c0.351,0.351,0.176,0.438,0.614,0.789
            s0.964,0.963,0.526,1.052c-0.438,0.088-1.227-0.789-1.578-0.526c-0.351,0.263-1.577,0.438-2.016,0.526s-0.964-0.525-0.439-0.7
            c0.526-0.176,1.052-0.088,1.052-0.614S37.738,349.243,37.738,349.243z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M86.207,368.088
            c-0.263-0.351-0.789-0.877-0.877-0.351s0.088,1.315,0.351,1.052S86.207,368.088,86.207,368.088z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M85.505,365.545
            c-0.438-0.176-0.876,0-0.701,0.263s0.351,0.7,0.614,0.35S85.505,365.545,85.505,365.545z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M91.29,368.788
            c0-0.438-0.175-0.876-0.613-0.613s-0.789,0.789-0.877,0.263s-1.052-1.226-1.052-0.876s-0.087,0.876-0.438,0.876
            c-0.351,0-0.964,0.001-0.964,0.439s0.176,2.015-0.087,2.278s-0.526,0.79-0.088,0.877s1.841,0.613,2.454,0.613
            s1.753,0.175,1.841-0.176c0.087-0.35,0.438-0.876-0.176-0.876c-0.613,0-2.104-0.088-1.753-0.439
            c0.351-0.35,0.614-0.789,0.614-0.789s-0.263-0.702,0.087-0.789C90.589,369.49,91.29,368.788,91.29,368.788z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M-93.47,470.46
            c0-0.439-0.176-0.876-0.351-1.315c-0.175-0.439-1.403-2.015-1.578-1.665s-0.702,0.875-0.351,1.489s0.439,0.877,0.702,1.49
            c0.263,0.613,0.964,2.891,1.227,2.454c0.263-0.438,0.263-0.875,0.263-1.226S-93.47,470.46-93.47,470.46z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M92.429,383.689
            c-0.263-0.439-0.789-0.702-0.614,0s0.525,1.489,0.701,1.139C92.692,384.478,92.429,383.689,92.429,383.689z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M113.026,390.087
            c-0.392,0-0.876-0.264-1.315-0.176s-2.104,1.052-2.104,1.052s-0.526,1.228,0,1.403c0.526,0.175,1.227-0.088,1.49-0.351
            s0.701-1.052,1.052-1.052c0.351,0,1.052,0.088,1.315-0.087C113.727,390.7,113.202,390.087,113.026,390.087z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M165.79,405.338c0,0-0.876-0.086-0.789,0.351
            s1.052,1.578,1.315,1.139C166.578,406.39,166.053,405.338,165.79,405.338z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M204.88,449.95
            c-0.351-0.439-1.49-1.052-0.964-0.263s1.928,2.718,1.928,2.366S204.88,449.95,204.88,449.95z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M221.621,461.081
            c-0.088-0.613-1.227-0.789-0.964-0.263s0.526,1.929,0.176,2.192c-0.351,0.263-1.402,0.702-1.315,1.052
            c0.087,0.35,0.614,1.403,0.263,1.578s-0.789,0.964-0.789,1.315c0,0.35,0.788,2.716,0.876,2.278
            c0.088-0.438,0.351-2.015,0.614-2.454s1.227-2.542,1.49-2.805s0.438-0.876,0.263-1.139s-0.789-0.702-0.701-0.964
            C221.621,461.607,221.621,461.081,221.621,461.081z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M222.848,481.328
            c0-0.526-0.263-0.875-0.526-0.7s-0.351,0.262-0.263,0.963c0.088,0.702,0.438,1.842,0.526,1.14
            C222.673,482.029,222.848,481.328,222.848,481.328z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M223.286,484.133
            c-0.263-0.176-0.789-0.439-0.789,0.087s0.176,2.718,0.351,2.279S223.286,484.133,223.286,484.133z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M77.968,549.429
            c-0.439-0.087-1.052,0.35-1.052,0.35s-0.263,0.439,0.35,0.439c0.614,0,1.14-0.439,1.14-0.439L77.968,549.429z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M181.566,607.626
            c-0.964,0-1.665,0.175-1.841,0.438c-0.175,0.263-0.614,0.789,0,0.789s1.841-0.349,2.191,0.088c0.35,0.438,0.526,0.789,0.789,0.35
            s0.526-0.262,0.263-0.876C182.706,607.801,181.829,607.626,181.566,607.626z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M180.777,609.378
            c-0.277,0-0.702,0.263-0.351,0.526s0.877,0.527,0.877,0.088C181.303,609.554,181.04,609.378,180.777,609.378z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M154.045,608.853
            c-0.088-0.263-0.439-0.438-0.439,0.088s-0.35,1.489,0.176,0.963S154.045,608.853,154.045,608.853z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M179.375,635.498
            c0-0.439-0.438-0.877-0.438-0.877s-0.614,0.351-0.526,0.702c0.088,0.35,0.438,0.877,0,0.964s-1.14-0.964-1.403-0.702
            c-0.263,0.263-0.526,0.702-0.263,0.964s0.438,1.753,0.789,1.841c0.351,0.087,0.614-0.614,0.964-0.439
            c0.35,0.176,0.438,0.614,0.701,0.176s0-0.963,0-1.315C179.2,636.461,179.375,635.498,179.375,635.498z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M180.426,631.817c0,0-0.701-0.526-0.701,0
            s-0.176,1.489,0.175,1.226C180.251,632.78,180.426,631.817,180.426,631.817z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M174.116,639.617
            c-0.087-0.613-0.087-0.613-0.438-0.35s-0.789,0.964-0.789,1.315c0,0.35,0.876,1.139,0.964,0.876
            C173.941,641.194,174.116,639.617,174.116,639.617z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M174.817,637.338
            c-0.088-0.263-0.439-0.439-0.439,0.087s0,1.14,0.263,0.964S174.817,637.338,174.817,637.338z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M155.36,610.693
            c-0.263-0.175-1.052,0.177-0.614,0.351c0.438,0.175,1.227,0.35,1.227,0.35L155.36,610.693z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M123.701,312.964
            c-1.969-0.787,0-4.922,0-4.922l0.093-29.268c-0.606-0.098-1.187-0.174-1.39-0.174c-0.438,0-1.49-0.614-1.841-0.789
            c-0.351-0.175-1.49,0-2.104-0.088s-1.315-0.613-1.578-0.964c-0.263-0.351-1.49,0.263-2.279,0.263s-1.841-0.438-2.629-0.789
            c-0.788-0.351-6.223-3.155-6.223-3.155c-0.526-0.438-1.928-0.877-2.805-0.964c-0.876-0.088-3.243-1.665-3.944-2.016
            c-0.701-0.351-5.084,2.191-5.872,2.542s0.614,1.753,0.701,2.104c0.087,0.35-1.578,0.351-2.016,0.351s-1.841,1.052-2.279,1.052
            s-1.227-0.175-1.928-0.351c-0.701-0.176-1.49,2.454-1.665,2.892c-0.176,0.438-1.402,0.964-1.753,1.227s-0.877,1.402-1.052,2.016
            c-0.175,0.614-0.701,0.526-1.14,0.701s-0.701,0.789-1.052,0.964c-0.351,0.176-0.613,0.701-1.052,1.402
            c-0.439,0.701-1.753,0.088-2.367,0.088s-2.016-0.701-2.366-0.964c-0.351-0.263-0.613-1.402-0.613-1.402s0.175-0.614,0.175-1.052
            s-0.526-0.088-1.052,0.088c-0.526,0.176-0.526-2.279-0.526-2.98s-0.351-0.789-0.789-0.614s-0.351,1.49-0.175,2.454
            c0.176,0.964-1.578-1.403-1.753-2.016c-0.175-0.613-0.263-1.14-0.964-1.665s-0.263-2.104-0.263-2.629s-0.175-2.104-0.175-2.454
            s0.613-0.876,0.613-0.876s0.526-1.49,0.614-1.753s-1.403-2.629-1.665-3.155s0.614-0.789,0.789-1.052s-1.315-0.789-1.753-0.964
            c-0.438-0.175-3.155,0.175-3.944,0.175s-2.454-1.49-2.805-1.841c-0.35-0.351-2.279-0.876-2.279-0.876
            c-0.351-0.088-2.98-0.438-3.506-0.263s-2.367,0.964-2.717,1.315s-1.402,1.14-1.753,1.315c-0.351,0.175-1.227,0.526-1.753,0.526
            s-1.578-0.175-1.753,0.088s-0.613,0.702-0.613,1.052s-0.088,1.841-0.439,2.016c-0.35,0.175-0.964,0.438-0.964,0.438
            s-1.665,1.753-1.052,1.841c0.614,0.088,1.928,0.35,2.454,0.263c0.526-0.087,0-0.613,0.876-0.35s2.016,0.613,2.542,0.701
            s1.402-0.088,1.402,0.175s-0.263,0.614,0.088,0.877s0.702,0.262,0.614,0.701s-0.35,0.351-0.263,0.964
            c0.087,0.613,0.35,1.578,0.876,1.753s1.403,0.526,1.49,0.877s0.614,0.876,1.052,0.613s1.928-0.701,2.016-0.438
            s0.877,0.614,1.315,0.614s2.717-0.877,2.98-0.877s1.402,0.351,1.928,0.351s1.666-0.176,2.016-0.351
            c0.35-0.175,1.84-0.964,1.665-0.438s-0.351,0.613-0.263,0.964c0.088,0.351,0.964,1.315,0.526,1.403s-0.788,0.088-0.876,0.526
            s0.438,0.35-0.088,0.438c-0.526,0.088-1.052-0.176-1.403-0.088c-0.35,0.088-0.789,0.613-1.315,0.263
            c-0.526-0.35-1.227-1.226-1.841-1.139c-0.613,0.087-1.402-0.088-1.753,0.438s-2.016,2.805-2.279,2.542s-1.315-0.963-1.578-0.876
            s-1.052-0.088-1.49-0.176c-0.438-0.087-1.315-0.35-1.753-0.087s-1.578,1.665-1.052,1.841s1.577-0.175,2.016,0
            s0.439,0.613,1.227,0.876s1.841,0.789,1.841,1.227s0.613,1.315,0.613,1.315s0.175-0.351,0.526-0.439
            c0.351-0.087,0.263,0.176,0.526,0.614s0.964,1.928,0.526,2.016s-1.052,0.351-0.964,0.964s0.263,1.489,0.526,1.928
            s0.702,1.49,1.14,1.315s0.35-0.438,0.876-0.438s1.14,0.175,1.49-0.263c0.35-0.438,1.315-0.877,1.227-1.403
            c-0.088-0.526-0.438-1.052,0-1.49s0.876-0.613,0.964-0.964c0.088-0.351-0.175-0.701,0.088-1.052s0.263-0.526,0.351-0.964
            c0.087-0.438,0.525-1.49,0.876-1.841c0.351-0.35,1.227-0.087,0.701,0.351c-0.526,0.438-0.789,0.526-0.877,1.315
            s-0.175,1.402-0.175,1.928s0.438,1.49,0.701,1.227s0.526-0.614,0.789-0.088s0.438,1.227,0.789,1.227s1.578-0.614,1.665-0.176
            c0.087,0.439,0.088,1.49,0.526,1.403c0.438-0.088,0.963-1.14,1.139-0.789s0.614,0.789,0.877,0.789s0.964-0.525,1.14,0.088
            c0.175,0.613,0.262,0.876,0.613,1.227s2.104,1.84,3.068,2.016c0.964,0.176,2.716,0.35,3.418,0.438
            c0.701,0.088,2.893,0.176,3.331,0.439s1.489,0.788,1.928,0.876s2.104,0.614,2.454,0.964c0.35,0.35,1.665,1.052,2.104,1.052
            s1.051-0.176,1.49,0.087s1.14,0.263,1.403,0.351s0.964,0.176,1.315,0.351c0.351,0.175,0.964,0.789,1.49,0.964
            c0,0,0,0.526,0.614,0.614c0.614,0.087,1.139,0.175,1.315,0.438s0.614,0.789,0.614,0.789s2.191,0.877,2.191,1.315
            s-0.351,1.49-0.088,1.578s0.613,0.088,0.964,0.351s0.439,0.789,0.614,1.227c0.175,0.438,1.052,2.805,1.403,3.33
            c0.35,0.526,0.789,1.14,1.227,1.49s0.351,0.35,0.789,0.876s1.49,1.929,1.14,2.016c-0.351,0.087-1.403-0.088-1.403-0.088
            s-1.14,0.613-0.614,0.876s2.542,1.579,2.191,1.666s-0.701,0.526-0.263,0.789s0.439,1.139,0.877,1.402
            c0.438,0.263,1.49,0.79,1.227,1.227c-0.263,0.438-0.702,0.789-0.614,1.139c0.088,0.35,0,1.052,0,1.052s-0.526,0.176-0.526,0.526
            s0.176,0.7-0.175,0.876s-0.614,0.351-0.526,0.702c0.088,0.35,0.176,1.227,0.614,0.964c0.438-0.263,2.016-1.491,2.279-1.14
            c0.263,0.351,0.964,0.614,1.403,0.614s1.315-0.351,1.753-0.351s2.191-0.526,2.542-0.263s1.578,1.578,1.841,1.753
            s1.139,0.963,1.578,1.402c0,0,2.016,2.28,2.366,2.63c0.351,0.35,1.227,1.315,1.578,1.578c0.214,0.16,0.753,0.644,1.281,0.999
            L123.701,312.964z" pointer-events="none" class="landarea"></path>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="442.14" cy="452.667" r="0.372" pointer-events="none" class="landarea"></circle>
    </g>
    <g id="MISC" data-colors="0" class="misc">
        <polyline fill="none" stroke="#E57441" stroke-dasharray="4.8189,1.1339" points="170.172,155.193 170.172,144.15 123.894,144.15
            123.894,71.841 277.977,71.841 424.172,167.026 424.172,227.502 454.673,264.839 571.419,264.839 571.419,286.4 597.713,286.4
            597.713,258.003 575.1,216.984 629.791,216.984 693.423,299.022 693.423,357.92 620.677,357.92     "></polyline>
        <path fill="none" stroke="#E57441" stroke-dasharray="4.8189,1.1339" d="M424.172,227.502h-77.304l-98.34-72.572H96.023
            l-59.95,29.975v49.433l87.786,24.319l-0.157,49.385c0,0-1.969,4.135,0,4.922l0.193,22.08c0,0,10.255,0.263,12.095-0.263
            c1.841-0.526,5.522-3.418,8.94-3.944s9.729-0.526,9.729-0.526v27.872l227.729,0.307"></path>
        <polyline fill="none" stroke="#E57441" stroke-dasharray="4.8189,1.1339" points="346.868,227.502 346.868,291.659
            382.102,348.454 382.102,448.372     "></polyline>
        <line fill="none" stroke="#E57441" stroke-dasharray="4.8189,1.1339" x1="123.894" y1="258.787" x2="347.275" y2="258.919"></line>
        <polyline fill="none" stroke="#E57441" stroke-dasharray="4.8189,1.1339" points="96.023,154.93 96.023,205.678 141.248,205.678
            174.961,258.848     "></polyline>
        <line fill="none" stroke="#E57441" stroke-dasharray="4.8189,1.1339" x1="248.528" y1="154.93" x2="248.528" y2="71.841"></line>
        <polyline fill="none" stroke="#E57441" stroke-dasharray="4.8189,1.1339" points="308.215,260.106 308.215,297.641
            253.108,297.641 242.774,317.187 242.774,358.183     "></polyline>
        <polyline fill="none" stroke="#E57441" stroke-dasharray="4.8189,1.1339" points="256.525,358.183 256.525,448.392
            621.049,448.392 695.199,503.326 765.732,503.326 820.423,473.351 821.475,298.496 693.423,299.022     "></polyline>
        <polyline fill="none" stroke="#E57441" stroke-dasharray="4.8189,1.1339" points="621.049,448.392 621.049,320.32 475.643,320.32
            420.714,291.659 309.001,291.659     "></polyline>
        <line fill="none" stroke="#E57441" stroke-dasharray="4.8189,1.1339" x1="382.102" y1="348.454" x2="475.95" y2="348.454"></line>
        <line fill="none" stroke="#E57441" stroke-dasharray="4.8189,1.1339" x1="475.643" y1="320.32" x2="475.643" y2="381.445"></line>
        <polyline fill="none" stroke="#E57441" stroke-dasharray="4.8189,1.1339" points="533.818,320.32 533.818,426.985 513.199,426.985
                "></polyline>
        <line fill="none" stroke="#E57441" stroke-dasharray="4.8189,1.1339" x1="476.147" y1="343.137" x2="533.818" y2="343.137"></line>
        <polyline fill="none" stroke="#E57441" stroke-dasharray="4.8189,1.1339" points="497.423,343.137 497.423,355.554 506.735,365.26
            506.735,408.493 533.818,408.493     "></polyline>
        <polyline fill="none" stroke="#E57441" stroke-dasharray="4.8189,1.1339" points="422.711,348.454 422.711,381.6 506.735,381.6
            "></polyline>
        <polyline fill="none" stroke="#E57441" stroke-dasharray="4.8189,1.1339" points="464.928,381.6 464.928,424.159 455.199,448.372
            "></polyline>
        <line fill="none" stroke="#E57441" stroke-dasharray="4.8189,1.1339" x1="821.475" y1="436.036" x2="897.892" y2="436.036"></line>
        <line fill="none" stroke="#E57441" stroke-dasharray="4.8189,1.1339" x1="819.371" y1="473.351" x2="895.788" y2="473.351"></line>
        <line fill="none" stroke="#E57441" stroke-dasharray="4.8189,1.1339" x1="512.203" y1="408.448" x2="512.203" y2="448.274"></line>
        <polyline fill="none" stroke="#E57441" stroke-dasharray="4.8189,1.1339" points="256.493,394.474 307.823,394.474
            346.215,430.362 381.688,430.362     "></polyline>
    </g>
    <g id="CITY_AMERICANSAMOA" data-colors="0" data-errors="0" hint-index="0" class="">
        <polygon id="SEMITRANSPARENT2_22_" opacity="0" fill="#166c38" enable-background="new    " points="533.818,408.493
            506.735,408.493 506.735,365.26 497.423,355.554 497.423,343.137 533.818,343.137  " pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea" stroke-opacity="0"></polygon>
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="520.146" y1="374.048" x2="584.391" y2="493.794" pointer-events="none" opacity="0"></line>

            <rect id="rect12" x="556.098" y="485.233" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="none"></rect>
        <circle id="CITY_24_14_" opacity="0" fill="#166c38" enable-background="new    " cx="519.345" cy="374.157" r="6.86" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>
    </g>
    <g id="CITY_COOKISLANDS" data-colors="0" data-errors="0" hint-index="0" class="">
        <polygon id="SEMITRANSPARENT2_21_" opacity="0" fill="#166c38" enable-background="new    " points="533.818,320.32
            533.818,426.985 512.203,426.985 512.203,448.392 621.049,448.392 621.049,320.32  " pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea" stroke-opacity="0"></polygon>

            <rect id="rect12_1" x="549.94" y="372.473" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="none"></rect>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="546.703" cy="344.599" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="550.208" cy="350.735" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="568.613" cy="364.056" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="585.793" cy="341.093" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="584.391" cy="337.937" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="609.106" cy="329.874" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="594.908" cy="410.334" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="567.561" cy="403.673" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="601.394" cy="413.839" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="606.653" cy="418.221" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="608.231" cy="419.974" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="611.21" cy="418.398" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="614.715" cy="421.552" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="609.696" cy="436.188" r="0.372" pointer-events="none" class="landarea"></circle>
        <circle id="CITY_24_21_" opacity="0" fill="#166c38" enable-background="new    " cx="577.861" cy="401.145" r="6.86" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>
    </g>
    <g id="CITY_FIJI" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Fiji" data-wikipediaLink="https://en.wikipedia.org/wiki/Fiji" data-infoText="<b>Fiji</b> &amp;nbsp; Capital: Suva" data-imageFile="FIJI.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="401.121" cy="383.777" r="0.372" pointer-events="auto" class="landarea"></circle>
        <polygon id="SEMITRANSPARENT2_20_" opacity="1" fill="#1e8346" enable-background="new    " points="464.928,424.159
            464.928,381.6 422.711,381.6 422.711,348.454 382.102,348.454 382.102,448.392 455.199,448.392     " pointer-events="auto" stroke="#95bec8" stroke-width="0.5" class="semitransparent2" fill-opacity="0.001" stroke-opacity="0"></polygon>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="411.201" cy="397.274" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="412.077" cy="395.609" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="414.619" cy="392.541" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="420.667" cy="393.33" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="428.38" cy="403.322" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="429.607" cy="401.92" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="429.344" cy="397.45" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="433.288" cy="408.143" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="435.392" cy="411.035" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="432.324" cy="412.963" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="442.776" cy="411.385" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="446.194" cy="412.613" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="448.363" cy="418.66" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="444.419" cy="425.672" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="444.243" cy="428.214" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="419.44" cy="406.39" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="415.32" cy="407.617" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="446.457" cy="407.003" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="443.74" cy="404.899" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="448.034" cy="402.358" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="442.162" cy="402.796" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="439.709" cy="401.131" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="440.76" cy="398.764" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="441.023" cy="396.924" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="442.688" cy="396.485" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="442.688" cy="393.155" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="438.306" cy="389.824" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="441.001" cy="387.371" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="438.547" cy="386.056" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="433.551" cy="384.566" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="391.414" cy="434.174" r="0.372" pointer-events="auto" class="landarea"></circle>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M432.675,389.298c0.439-0.439,1.579-1.665,0.877-1.578s-1.139,0.438-1.841,0.613c-0.702,0.176-1.49,0.263-1.753,0.526
            s-0.876,0.964-1.315,0.964s-3.068,0.526-3.068,0.526s-0.526,0.701-0.789,0.876c-0.263,0.176-0.614,0.263-1.052,0.263
            s-0.789,0.088-1.052,0.351s-0.701,0.964-0.438,1.052c0.263,0.087,0.7-0.001,0.876,0.438s-0.001,1.228,0.613,1.14
            s1.491-1.052,1.929-0.789s0.613,0.526,0.876,0s2.191-1.49,2.191-1.227s0,0.702,0.439,0.702s0.525-0.088,0.613-0.351
            s0.177-0.789,0.614-1.139C430.834,391.315,432.675,389.298,432.675,389.298z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M433.376,390.526c-0.277,0-1.316,0.613-1.49,0.876s-1.666,1.666-1.052,1.49c0.614-0.176,0.964-0.613,1.227-0.526
            s1.227,0.526,1.227,0.087s-0.175-0.789,0-1.052S433.639,390.526,433.376,390.526z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M434.866,393.505c0.35-0.439,0.438-1.402,0.087-1.139c-0.35,0.263-0.876,0.875-1.139,1.226s-0.613,1.052-0.263,1.052
            C433.902,394.645,434.866,393.505,434.866,393.505z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M424.699,399.992c-0.235-0.118-0.526-0.088-0.526,0.35c0,0.439,0.35,0.963,0.613,0.613S424.875,400.08,424.699,399.992z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M422.508,399.903c-0.439-0.263-1.227-1.051-1.49-1.226c-0.263-0.176-0.35-0.438-0.613-0.789s-0.264-0.614-0.877-0.439
            c-0.613,0.176-1.927,0.525-2.278,0.613s-0.703,0-1.14,0.176c-0.438,0.176-2.015,0.79-2.278,1.227s-0.088,1.052-0.439,1.402
            c-0.35,0.35-1.316,1.227-1.227,1.49s0.264,1.577,0.877,1.752c0.613,0.176,3.331,0.703,3.681,0.877
            c0.35,0.175,1.929,0.263,2.366,0.263s1.227-1.227,1.841-1.227c0.613,0,1.226,0.088,1.665-0.087
            c0.439-0.176,0.876-0.702,0.613-0.964s-0.35-0.613-0.35-1.052C422.858,401.481,422.508,399.903,422.508,399.903z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M420.229,410.86c-0.196-0.196-0.439,0.964-0.964,0.964s-1.49,0.526-1.052,0.613c0.439,0.087,0.79,0.176,1.14,0
            s0.7-0.876,1.139-0.789s1.402-0.087,1.315-0.35S420.405,411.035,420.229,410.86z" pointer-events="auto" class="landarea"></path>
        <line fill="#1e8346" stroke="#1D1D1B" stroke-miterlimit="10" x1="404.473" y1="399.136" x2="343.017" y2="467.236" pointer-events="auto" opacity="0" class="clickarea"></line>

            <rect id="rect12_2" x="316.996" y="457.027" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
        <circle id="CITY_24_12_" opacity="1" fill="#ffffff" enable-background="new    " cx="404.014" cy="399.585" r="6" pointer-events="auto" stroke="#000000" stroke-width="0.7px" class="city" rx="6" ry="6" stroke-opacity="0.9"></circle>
    <circle cx="404.014" cy="399.585" r="12" fill="#1e8346" pointer-events="auto" opacity="0.001" class="city2 landarea" stroke="#95bec8" stroke-width="0.5"></circle></g>
    <g id="CITY_FRENCHPOLYNESIA" data-colors="0" data-errors="0" hint-index="0" class="">
        <polygon id="SEMITRANSPARENT2_19_" opacity="0" fill="#166c38" enable-background="new    " points="695.199,503.326
            765.732,503.326 820.423,473.351 821.475,298.496 693.423,299.022 693.423,357.92 621.049,357.92 621.049,448.392   " pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea" stroke-opacity="0"></polygon>

            <rect id="rect12_3" x="700.32" y="395.029" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="none"></rect>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="636.628" cy="384.917" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="635.752" cy="390.876" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="641.886" cy="393.331" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="654.858" cy="390.526" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="659.417" cy="391.052" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="662.045" cy="393.681" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="664.5" cy="392.629" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="660.819" cy="391.753" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="667.655" cy="400.693" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="676.245" cy="394.383" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="683.431" cy="377.575" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="687.112" cy="378.101" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="691.845" cy="378.978" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="693.598" cy="380.358" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="700.085" cy="385.004" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="701.839" cy="387.721" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="703.064" cy="381.236" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="687.814" cy="402.008" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="709.2" cy="398.59" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="714.986" cy="393.857" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="712.881" cy="388.159" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="711.655" cy="385.091" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="708.061" cy="388.861" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="708.498" cy="382.287" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="705.343" cy="385.53" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="713.144" cy="373.785" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="705.169" cy="373.785" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="702.538" cy="374.048" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="723.137" cy="391.402" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="727.693" cy="394.646" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="727.869" cy="401.832" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="731.725" cy="399.816" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="737.159" cy="393.418" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="745.223" cy="385.091" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="742.857" cy="371.594" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="763.143" cy="341.531" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="760.002" cy="335.911" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="760.906" cy="333.402" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="755.788" cy="328.987" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="751.573" cy="333.001" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="751.372" cy="328.786" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="746.454" cy="321.962" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="749.466" cy="320.658" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="751.446" cy="386.494" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="762.051" cy="376.94" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="764.857" cy="397.625" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="746.626" cy="401.394" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="745.136" cy="404.111" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="746.626" cy="406.215" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="746.801" cy="412.526" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="749.254" cy="416.733" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="758.37" cy="414.366" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="761.876" cy="413.752" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="761.876" cy="409.282" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="776.075" cy="405.689" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="781.334" cy="407.441" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="764.068" cy="426.899" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="747.327" cy="434.086" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="761.7" cy="435.663" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="762.402" cy="438.907" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="778.266" cy="430.843" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="779.931" cy="431.456" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="781.247" cy="432.421" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="788.433" cy="432.771" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="775.462" cy="446.97" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="792.289" cy="446.269" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="796.146" cy="448.373" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="759.247" cy="426.724" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="732.513" cy="387.108" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="734.618" cy="404.286" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="724.362" cy="423.393" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="712.969" cy="419.186" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="727.781" cy="425.76" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="742.418" cy="413.226" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="635.752" cy="435.443" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="650.021" cy="442.144" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="662.045" cy="440.77" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="676.99" cy="448.56" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="691.472" cy="452.913" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="718.182" cy="485.561" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="724.735" cy="488.54" r="0.372" pointer-events="none" class="landarea"></circle>

            <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M674.31,398.859c-0.142-0.106-0.177-0.142-0.424-0.142s-0.354-0.071-0.318,0.177c0.036,0.247,0.071,0.53,0.318,0.53
            C674.134,399.424,674.31,398.859,674.31,398.859z" pointer-events="none" class="landarea"></path>
        <circle id="CITY_24_9_" opacity="0" fill="#166c38" enable-background="new    " cx="729.353" cy="424.021" r="6.86" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>
    </g>
    <g id="CITY_HAWAII" data-colors="0" data-errors="0" hint-index="0" class="">

            <rect id="SEMITRANSPARENT2_17_" x="582.5" y="70.658" opacity="0" fill="#000000" enable-background="new    " width="63.333" height="47.342" pointer-events="none" fill-opacity="0.1" stroke-opacity="0" stroke="#000000" visibility="visible"></rect>

            <rect id="rect12_5" x="599.001" y="63.759" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="none"></rect>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="577.488" cy="70.658" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="588.926" cy="83.016" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="620.062" cy="92.613" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="592.19" cy="80.65" r="0.372" pointer-events="none" class="landarea"></circle>

            <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M597.451,78.064c-0.614,0-1.139,0.262-1.578,0.613s-1.226,1.052-0.526,1.49c0.701,0.438,2.015,0.876,2.454,0.701
            c0.439-0.175,0.438-0.613,0.613-0.964s0.702-1.14,0.439-1.403S597.451,78.064,597.451,78.064z" pointer-events="none" class="landarea"></path>

            <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M609.984,83.323c-0.35-0.526-1.139-1.139-1.402-0.789s-0.613,0.702-0.876,0.789s-0.263,0.876-0.263,0.876s0.963,1.578,1.226,1.49
            c0.263-0.088,0.702-0.614,0.964-0.351c0.263,0.263,1.403,0.788,1.753,0.701s0.439-0.35,0.263-0.701s0-0.525-0.526-0.701
            s-0.527,0-0.877-0.526C609.896,83.586,609.984,83.323,609.984,83.323z" pointer-events="none" class="landarea"></path>

            <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M616.47,86.741c-0.439,0-1.052-0.35-1.315,0s-0.613,0.788-0.087,0.876s1.226-0.263,1.752,0s1.402,0.702,1.841,0.439
            s1.14-0.789,0.439-0.877S616.47,86.741,616.47,86.741z" pointer-events="none" class="landarea"></path>

            <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M618.31,89.458c-0.526-0.175-1.316-0.614-1.227,0c0.088,0.614,0.351,1.227,0.702,1.14C618.135,90.51,618.31,89.458,618.31,89.458z" pointer-events="none" class="landarea"></path>

            <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M621.816,89.108c-0.35,0.087-0.438,0.176-0.7-0.263s-0.876-0.876-1.052-0.526s-0.526,1.052-0.176,1.315s1.14,0.439,1.227,0.789
            s0.612,1.226,0.963,1.402s1.403,0.087,1.929-0.263s1.315-0.876,1.052-1.227s-0.702-0.614-1.227-0.877s-0.79-0.526-1.227-0.526
            C622.167,88.932,621.816,89.108,621.816,89.108z" pointer-events="none" class="landarea"></path>

            <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M628.215,95.331c-0.439-0.175-2.016-0.964-1.753-0.351c0.263,0.614,0.613,1.139,0.526,1.578s-0.614,1.578-0.964,1.753
            s-1.051,0.964-0.7,1.227c0.35,0.263,0.789,0.701,0.876,1.49s0.263,2.104,0.263,2.454s0,1.052,0.263,1.403s1.578,1.052,1.841,0.789
            s2.192-2.454,2.542-2.454s2.015-0.088,2.454-0.614c0.439-0.526,1.753-1.139,1.227-1.665s-1.14-0.789-1.403-1.315
            s-0.086-1.402-0.701-1.841c-0.614-0.439-1.666-1.315-2.192-1.49S628.215,95.331,628.215,95.331z" pointer-events="none" class="landarea"></path>

            <circle id="Circle_24_1_" fill="none" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="599.694" cy="101.716" r="77.339" pointer-events="none" opacity="0" class="landarea"></circle>
        <circle id="CITY_24_8_" opacity="0" fill="#166c38" enable-background="new    " cx="626.922" cy="92.241" r="6.86" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>
    </g>
    <g id="CITY_KIRIBATI" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Kiribati" data-wikipediaLink="https://en.wikipedia.org/wiki/Kiribati" data-infoText="<b>Kiribati</b> &amp;nbsp; Capital: South Tarawa" data-imageFile="KIRIBATI.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <polygon id="SEMITRANSPARENT2_16_" opacity="1" fill="#1e8346" enable-background="new    " points="346.868,291.659
            346.868,227.502 424.172,227.502 454.673,264.839 571.419,264.839 571.419,286.4 597.713,286.4 597.713,258.003 575.1,216.984
            629.791,216.984 693.423,299.022 693.423,357.92 621.049,357.92 621.049,320.32 475.643,320.32 420.714,291.659     " pointer-events="auto" stroke="#95bec8" stroke-width="0.5" class="semitransparent2" fill-opacity="0.001" stroke-opacity="0"></polygon>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="350.943" cy="251.561" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="350.811" cy="265.366" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="378.288" cy="232.63" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="377.368" cy="234.207" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="378.288" cy="244.068" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="381.049" cy="243.147" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="378.551" cy="251.167" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="385.519" cy="255.374" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="383.153" cy="257.215" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="389.858" cy="264.051" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="392.224" cy="268.39" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="394.591" cy="270.493" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="402.348" cy="268.915" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="405.634" cy="269.573" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="480.967" cy="286.795" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="477.548" cy="295.341" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="496.086" cy="294.158" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="503.842" cy="293.369" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="625.935" cy="302.879" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="633.472" cy="290.257" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="653.806" cy="338.639" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="671.336" cy="337.588" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="658.715" cy="349.507" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="508.444" cy="287.584" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="502.002" cy="286.664" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="505.026" cy="283.114" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="589.977" cy="221.033" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="598.626" cy="227.984" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="613.608" cy="243.583" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="500.293" cy="280.616" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="398.798" cy="273.122" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="402.085" cy="278.25" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="408.79" cy="279.433" r="0.372" pointer-events="auto" class="landarea"></circle>

            <rect id="rect12_6" x="361.931" y="240.795" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
        <circle id="CITY_24_20_" opacity="1" fill="#ffffff" enable-background="new    " cx="390.23" cy="270.865" r="6" pointer-events="auto" stroke="#000000" stroke-width="0.7px" class="city" rx="6" ry="6" stroke-opacity="0.9"></circle>
    <circle cx="390.23" cy="270.865" r="12" fill="#1e8346" pointer-events="auto" opacity="0.001" class="city2 landarea" stroke="#95bec8" stroke-width="0.5"></circle></g>
    <g id="CITY_MARSHALLISLANDS" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Marshall Islands" data-wikipediaLink="https://en.wikipedia.org/wiki/Marshall_Islands" data-infoText="<b>Marshall Islands</b> &amp;nbsp; Capital: Majuro" data-imageFile="MARSHALLISLANDS.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <polygon id="SEMITRANSPARENT2_15_" opacity="1" fill="#1e8346" enable-background="new    " points="248.528,71.841
            277.977,71.841 424.172,167.026 424.172,227.502 346.868,227.502 248.528,154.93   " pointer-events="auto" stroke="#95bec8" stroke-width="0.5" class="semitransparent2" fill-opacity="0.001" stroke-opacity="0"></polygon>

            <rect id="rect12_7" x="304.365" y="149.791" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
        <circle id="CITY_24" opacity="1" fill="#ffffff" enable-background="new    " cx="332.286" cy="141.37" r="6" pointer-events="auto" stroke="#000000" stroke-width="0.7px" class="city" rx="6" ry="6" stroke-opacity="0.9"></circle>

            <circle id="Circle_24" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" cx="331.234" cy="136.563" r="21.849" pointer-events="auto" opacity="0.001" class="clickarea landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="355.413" cy="160.978" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="346.604" cy="141.915" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="353.419" cy="168.866" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="351.6" cy="169.546" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="344.238" cy="222.375" r="0.372" pointer-events="auto" class="landarea"></circle>

            <ellipse fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="344.37" cy="200.682" rx="0.52" ry="0.372" pointer-events="auto" class="landarea"></ellipse>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="324.649" cy="187.403" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="322.545" cy="178.201" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="318.995" cy="165.842" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="339.373" cy="214.07" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="350.956" cy="211.458" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="348.793" cy="180.105" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="356.207" cy="182.885" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="362.694" cy="188.908" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="329.641" cy="169.293" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="335.975" cy="187.982" r="0.372" pointer-events="auto" class="landarea"></circle>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M285.163,178.905c-0.394-0.131-1.709-0.131-1.052,0.394s1.578,1.052,1.709,0.657S285.163,178.905,285.163,178.905z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M293.885,167.683c-0.131-0.789,0-1.709-0.657-1.841s-1.841,0-1.578,0.789s0.131,1.709,0.92,1.709S293.885,167.683,293.885,167.683z
            " pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M334.378,167.551c-0.515-0.172-1.183,0.131-0.789,0.657s0.657,0.789,1.052,0.394C335.036,168.207,334.772,167.683,334.378,167.551z
            " pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M326.095,169.261c0,0-1.709,0.263-0.789,0.526s1.709-0.263,1.709-0.263L326.095,169.261z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M319.522,185.563" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M318.865,185.563c0.224,0.672,0.263,1.578,0.92,1.709s0.92-0.263,0.657-0.657S318.733,185.168,318.865,185.563z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M354.624,176.097c0,0-0.263-0.92-0.657-0.263s-0.657,1.446-0.131,1.446S354.624,176.097,354.624,176.097z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M363.959,192.399c-0.394-0.131-1.315-0.657-1.052,0s1.052,1.578,1.183,1.052S363.959,192.399,363.959,192.399z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M354.887,185.3c-0.394-0.263-0.92-1.052-0.92-0.263s0.263,2.104,0.789,1.709S354.887,185.3,354.887,185.3z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M339.9,194.108c-0.263-0.526-1.709-1.052-1.183-0.394s0.526,0.526,0.789,1.315s0.263,1.841,0.789,1.578s0.789-0.789,0.263-1.183
            S339.9,194.108,339.9,194.108z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M367.114,201.997c-0.657-0.394-1.446-0.394-1.183,0s1.052,1.446,1.315,1.052S367.114,201.997,367.114,201.997z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M364.09,201.471c-0.394,0-1.446,0.131-0.789,0.526s1.315,0.394,1.315,0.394L364.09,201.471z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M370.795,209.096c-0.526,0-1.446-0.263-1.183,0.263s1.315,1.446,1.578,1.052s0.263-0.92,0.263-0.92L370.795,209.096z" pointer-events="auto" class="landarea"></path>
    <circle cx="332.286" cy="141.37" r="12" fill="#1e8346" pointer-events="auto" opacity="0.001" class="city2 landarea" stroke="#95bec8" stroke-width="0.5"></circle></g>
    <g id="CITY_MICRONESIA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="the Federated States of Micronesia" data-wikipediaLink="https://en.wikipedia.org/wiki/Federated_States_of_Micronesia" data-infoText="<b>the Federated States of Micronesia</b> &amp;nbsp; Capital: Palikir" data-imageFile="MICRONESIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <polyline id="SEMITRANSPARENT2_14_" opacity="1" fill="#FFFFFF" enable-background="new    " points="96.023,154.93
            96.023,205.678 141.248,205.678 174.961,258.848 346.868,258.919 346.868,227.502 248.528,154.93 96.023,154.93     " pointer-events="auto" class="semitransparent2" fill-opacity="0.001" stroke-opacity="0"></polyline>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="158.471" cy="185.037" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="151.766" cy="190.427" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="151.109" cy="201.076" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="162.415" cy="197.395" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="169.383" cy="194.371" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="164.65" cy="199.367" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="166.228" cy="198.973" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="171.881" cy="200.266" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="176.876" cy="194.218" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="188.709" cy="200.156" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="190.944" cy="198.052" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="192.916" cy="190.427" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="196.466" cy="187.14" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="206.194" cy="190.296" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="208.035" cy="200.025" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="210.27" cy="200.025" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="217.106" cy="203.969" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="229.596" cy="193.714" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="257.468" cy="202.26" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="258.782" cy="205.283" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="273.77" cy="205.546" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="280.475" cy="209.359" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="298.749" cy="216.458" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="253.108" cy="212.383" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="220.393" cy="211.594" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="225.389" cy="214.486" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="234.723" cy="228.028" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="233.277" cy="249.983" r="0.373" pointer-events="auto" class="landarea"></circle>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M101.15,182.145c0,0-1.446,1.709-0.657,1.446s1.315-1.183,1.315-1.183S101.413,181.75,101.15,182.145z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M114.253,178.42c-0.439-0.176-1.315-0.701-1.49-0.263c-0.175,0.438-0.263,0.876-0.263,1.402s0.176,1.227,0.614,1.14
            c0.438-0.088,0.439-0.262,0.351-0.701c-0.088-0.439-0.351-0.877,0.175-0.877s1.051,0.175,0.964-0.088
            S114.253,178.42,114.253,178.42z" pointer-events="auto" class="landarea"></path>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="119.95" cy="180.814" r="0.411" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="97.601" cy="189.375" r="0.411" pointer-events="auto" class="landarea"></circle>

            <ellipse fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="118.68" cy="193.802" rx="0.417" ry="0.427" pointer-events="auto" class="landarea"></ellipse>

            <ellipse fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="146.682" cy="200.068" rx="0.417" ry="0.427" pointer-events="auto" class="landarea"></ellipse>

            <ellipse fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="139.408" cy="203.837" rx="0.417" ry="0.427" pointer-events="auto" class="landarea"></ellipse>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M189.892,204.363c0,0-1.446-0.526-0.92,0s0.92,1.578,1.052,0.92S189.892,204.363,189.892,204.363z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M188.446,202.128c-0.394,0-1.183,0.131-0.657,0.526s1.052,0.394,1.052,0.394L188.446,202.128z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M209.744,190.033c-0.416,0-0.92,0.92-0.92,0.92s0.657,0.657,1.052,0.263S210.007,190.033,209.744,190.033z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M213.294,190.164c0.526-0.394,1.052-0.657,0.657-0.92c-0.394-0.263-0.789-0.394-1.052,0s-1.972,0.92-1.183,1.052
            S213.294,190.164,213.294,190.164z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M210.928,202.391c-0.394-0.263-1.446-0.394-0.92,0.131s0.92,1.052,1.183,0.657S210.928,202.391,210.928,202.391z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M224.337,215.275c-0.394-0.263-1.446-0.263-1.052,0.263s1.578,0.789,1.578,0.789L224.337,215.275z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M237.747,198.052c-0.657-0.131-1.183,0.394-0.789,0.789c0.394,0.394,1.578,0.789,1.709,0.131
            C238.799,198.315,237.747,198.052,237.747,198.052z" pointer-events="auto" class="landarea"></path>
        <circle id="CITY_24_3_" opacity="1" fill="#ffffff" enable-background="new    " cx="209.451" cy="192.072" r="6" pointer-events="auto" stroke="#000000" stroke-width="0.7px" class="city" rx="6" ry="6" stroke-opacity="0.9"></circle>

            <rect id="rect12_8" x="181.53" y="200.954" opacity="0" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
    <circle cx="209.451" cy="192.072" r="12" fill="#1e8346" pointer-events="auto" opacity="0.001" class="city2 landarea" stroke="#95bec8" stroke-width="0.5"></circle></g>
    <g id="CITY_NAURU" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Nauru" data-wikipediaLink="https://en.wikipedia.org/wiki/Nauru" data-infoText="<b>Nauru</b> &amp;nbsp; Capital: Yaren" data-imageFile="NAURU.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <polygon id="SEMITRANSPARENT2_13_" opacity="1" fill="#1e8346" enable-background="new    " points="346.868,258.919
            346.868,291.659 308.215,291.659 308.215,258.787     " pointer-events="auto" stroke="#95bec8" stroke-width="0.5" class="semitransparent2" fill-opacity="0.001" stroke-opacity="0"></polygon>

            <rect id="rect12_9" x="298.328" y="281.142" opacity="0" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
        <circle id="CITY_24_6_" opacity="1" fill="#ffffff" enable-background="new    " cx="326.512" cy="272.309" r="6" pointer-events="auto" stroke="#000000" stroke-width="0.7px" class="city" rx="6" ry="6" stroke-opacity="0.9"></circle>
    <circle cx="326.512" cy="272.309" r="12" fill="#1e8346" pointer-events="auto" opacity="0.001" class="city2 landarea" stroke="#95bec8" stroke-width="0.5"></circle></g>
    <g id="CITY_NEWCALEDONIA" data-colors="0" data-errors="0" hint-index="0" class="">
        <polygon id="SEMITRANSPARENT2_12_" opacity="0" fill="#166c38" enable-background="new    " points="346.215,430.362
            307.823,394.474 256.493,394.474 256.525,448.392 382.102,448.392 382.102,430.362     " pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea" stroke-opacity="0"></polygon>
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="290.816" y1="416.458" x2="234.848" y2="468.481" pointer-events="none" opacity="0"></line>

            <rect id="rect12_10" x="206.71" y="458.216" opacity="0" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="none"></rect>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="319.346" cy="418.221" r="0.373" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="326.885" cy="424.531" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="334.423" cy="442.061" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="346.518" cy="442.061" r="0.372" pointer-events="none" class="landarea"></circle>

            <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M303.921,416.972c0.206,0,0.373,0.167,0.373,0.373s-0.167,0.373-0.373,0.373s-0.373-0.167-0.373-0.373
            S303.715,416.972,303.921,416.972" pointer-events="none" class="landarea"></path>

            <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M332.935,426.768c-0.118-0.411,0.059-0.999-0.412-1.058c-0.47-0.058-1.528,0.294-1.116,0.47s0.764,0.234,0.529,0.588
            c-0.235,0.353-0.646,0.589-0.646,0.999c0,0.411-0.118,1.058,0.411,1.116c0.529,0.059,0.647,0,0.999,0.235s0.529,0.706,0.823,0.529
            s0.94-0.764,0.705-1.058c-0.235-0.294-0.705-0.706-0.94-0.999C333.052,427.297,332.935,426.768,332.935,426.768z" pointer-events="none" class="landarea"></path>

            <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M337.988,431.294c-0.291-0.074-0.706,0.234-0.706,0.469s0,1.352,0.47,1.587c0.47,0.235,0.765,0.53,0.999,0.47
            c0.234-0.06,0.588-0.235,0.646-0.47c0.059-0.235,0.469-1.175,0.059-1.175c-0.411,0-1.058,0.176-1.058-0.177
            C338.399,431.646,338.224,431.354,337.988,431.294z" pointer-events="none" class="landarea"></path>

            <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M311.369,423.008c-0.294-0.294-1.352-1.235-1.645-1.116s-0.764,0.412-1.058,0.118s-1.528-1.529-1.528-1.176
            s-0.528,0.764-0.059,0.999c0.47,0.235,1.117,0.763,1.058,0.94s-0.294,0.352,0,0.823c0.294,0.47,1.293,1.587,1.469,1.881
            s0.294,1.233,0.705,1.469c0.411,0.235,1.058,0.353,1.293,0.706c0.235,0.352,0.411,0.941,0.705,1.058s0.587-0.06,0.881,0.411
            c0.294,0.47,0.764,1.705,1.116,1.822c0.353,0.117,1.058,0.469,1.234,0.646s1.645,1.588,2.057,1.764
            c0.411,0.176,1.292,0.881,1.763,0.94c0.47,0.059,0.823,0.117,1.117,0.352s1.821,1.645,2.056,1.587
            c0.235-0.059,0.999-0.059,1.058,0.235c0.059,0.294-0.001,0.823,0.411,1.116c0.412,0.294,2.175,1.058,2.586,1.175
            c0.411,0.117,1.528,0.118,1.645,0.47c0.117,0.352,0.294,0.646,0.646,0.646s1.233,0,1.469-0.294c0.235-0.294,0.529-1.058,0.177-1.41
            s-2.173-1.352-2.35-1.587c-0.177-0.235-3.82-3.407-4.29-3.76s-1.058-0.706-1.352-0.941c-0.294-0.235-0.88-0.058-1.234-0.352
            c-0.353-0.294-1.528-0.822-1.645-1.175c-0.118-0.353-0.353-0.588-0.588-0.764s-0.999-0.706-1.116-1.058
            c-0.118-0.352-0.647-0.823-0.823-1.175s-0.529-0.941-0.823-1.058s-1.645-0.411-1.998-0.705c-0.352-0.294-1.528-1.293-1.763-1.528
            C312.309,423.831,311.369,423.008,311.369,423.008z" pointer-events="none" class="landarea"></path>
        <circle id="CITY_24_16_" opacity="0" fill="#166c38" enable-background="new    " cx="291.214" cy="416.033" r="6.86" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="271.141" cy="435.399" r="0.373" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="266.758" cy="431.718" r="0.373" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="262.727" cy="426.985" r="0.373" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="262.552" cy="419.974" r="0.373" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="261.324" cy="418.571" r="0.373" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="260.799" cy="415.767" r="0.373" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="264.129" cy="412.436" r="0.373" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="267.11" cy="414.715" r="0.373" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="274.472" cy="413.488" r="0.373" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="288.671" cy="428.213" r="0.373" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="298.838" cy="407.177" r="0.373" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="298.136" cy="402.795" r="0.373" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="309.18" cy="407.177" r="0.373" pointer-events="none" class="landarea"></circle>

            <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M303.921,416.972" pointer-events="none" class="landarea"></path>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="365.098" cy="439.782" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="370.708" cy="439.959" r="0.372" pointer-events="none" class="landarea"></circle>
    </g>
    <g id="CITY_NIUE" data-colors="0" data-errors="0" hint-index="0" class="">
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="523.508" y1="415.658" x2="523.508" y2="464.897" pointer-events="none" opacity="0"></line>
        <polyline id="SEMITRANSPARENT2_11_" opacity="0" fill="#166c38" enable-background="new    " points="533.818,426.985
            512.203,426.985 512.203,408.448 533.818,408.493 533.818,426.985     " pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea" stroke-opacity="0"></polyline>

            <rect id="rect12_11" x="495.587" y="457.421" opacity="0" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="none"></rect>
        <circle id="CITY_24_10_" opacity="0" fill="#166c38" enable-background="new    " cx="523.01" cy="418.026" r="6.86" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>
    </g>
    <g id="CITY_NORTHERNMARIANAISLANDS" data-colors="0" data-errors="0" hint-index="0" class="">
        <polygon id="SEMITRANSPARENT2_10_" opacity="0" fill="#166c38" enable-background="new    " points="248.528,71.841
            123.894,71.841 123.894,144.15 170.172,144.15 170.172,154.93 248.528,154.93  " pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea" stroke-opacity="0"></polygon>

            <rect id="rect12_12" x="154.156" y="74.322" opacity="0" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="none"></rect>
        <circle id="CITY_24_2_" opacity="0" fill="#166c38" enable-background="new    " cx="183.077" cy="103.966" r="6.86" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="154.746" cy="92.526" r="0.373" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="157.551" cy="96.733" r="0.373" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="158.603" cy="100.064" r="0.373" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="161.057" cy="107.251" r="0.373" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="161.757" cy="113.035" r="0.373" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="162.458" cy="117.418" r="0.373" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="162.459" cy="119.521" r="0.373" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="162.108" cy="124.43" r="0.373" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="160.881" cy="127.585" r="0.373" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="164.036" cy="130.39" r="0.373" pointer-events="none" class="landarea"></circle>
    </g>
    <g id="CITY_PALAU" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Palau" data-wikipediaLink="https://en.wikipedia.org/wiki/Palau" data-infoText="<b>Palau</b> &amp;nbsp; Capital: Ngerulmud" data-imageFile="PALAU.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <polyline id="SEMITRANSPARENT2_9_" opacity="1" fill="#FFFFFF" enable-background="new    " points="123.894,258.787
            175.095,258.919 141.248,205.678 96.023,205.678 96.023,154.93 36.072,184.905 36.072,234.338 123.858,258.657  " pointer-events="auto" class="semitransparent2" fill-opacity="0.001" stroke-opacity="0"></polyline>

            <rect id="rect12_13" x="63.171" y="232.961" opacity="0" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
        <circle id="CITY_24_4_" opacity="1" fill="#ffffff" enable-background="new    " cx="91.092" cy="224.215" r="6" pointer-events="auto" stroke="#000000" stroke-width="0.7px" class="city" rx="6" ry="6" stroke-opacity="0.9"></circle>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M72.884,199.236c0.131-0.92-0.131-2.104-0.394-1.315s-0.92,1.709-1.183,2.235s-1.052,1.841-0.394,1.446
            S72.884,199.236,72.884,199.236z" pointer-events="auto" class="landarea"></path>

            <ellipse fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="54.478" cy="224.215" rx="0.417" ry="0.427" pointer-events="auto" class="landarea"></ellipse>

            <ellipse fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="50.534" cy="235.389" rx="0.417" ry="0.427" pointer-events="auto" class="landarea"></ellipse>

            <ellipse fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="45.538" cy="234.732" rx="0.417" ry="0.427" pointer-events="auto" class="landarea"></ellipse>

            <ellipse fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="51.849" cy="221.585" rx="0.417" ry="0.427" pointer-events="auto" class="landarea"></ellipse>

            <ellipse fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="54.215" cy="216.589" rx="0.417" ry="0.427" pointer-events="auto" class="landarea"></ellipse>

            <ellipse fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="69.334" cy="203.574" rx="0.417" ry="0.427" pointer-events="auto" class="landarea"></ellipse>

            <ellipse fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="73.804" cy="193.582" rx="0.417" ry="0.427" pointer-events="auto" class="landarea"></ellipse>
    <circle cx="91.092" cy="224.215" r="12" fill="#1e8346" pointer-events="auto" opacity="0.001" class="city2 landarea" stroke="#95bec8" stroke-width="0.5"></circle></g>
    <g id="CITY_PAPUANEWGUINEA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Papua New Guinea" data-wikipediaLink="https://en.wikipedia.org/wiki/Papua_New_Guinea" data-infoText="<b>Papua New Guinea</b> &amp;nbsp; Capital: Port Moresby" data-imageFile="PAPUANEWGUINEA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <path id="SEMITRANSPARENT2_8_" opacity="1" fill="#1e8346" enable-background="new    " d="M123.894,258.787l184.321,0.132
            v38.723h-55.108l-10.334,19.546v41.303l-88.116-0.307v-27.872c0,0-6.311,0-9.729,0.526s-7.099,3.418-8.94,3.944
            s-12.095,0.263-12.095,0.263l-0.193-22.08c-1.969-0.787,0-4.922,0-4.922l0.157-49.385" pointer-events="auto" stroke="#95bec8" stroke-width="0.5" class="semitransparent2" fill-opacity="0.001" stroke-opacity="0"></path>

            <rect id="rect12_14" x="164.368" y="266.531" opacity="0" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M175.277,277.986c0,0.205-0.167,0.372-0.372,0.372c-0.206,0-0.373-0.167-0.373-0.372c0-0.206,0.167-0.373,0.373-0.373
            C175.11,277.613,175.277,277.78,175.277,277.986z M163.423,294.53c-0.206,0-0.373,0.167-0.373,0.373
            c0,0.205,0.167,0.372,0.373,0.372c0.205,0,0.372-0.167,0.372-0.372C163.795,294.696,163.629,294.53,163.423,294.53z
             M178.06,276.167c-0.206,0-0.373,0.167-0.373,0.373c0,0.205,0.167,0.372,0.373,0.372c0.205,0,0.372-0.167,0.372-0.372
            C178.432,276.334,178.265,276.167,178.06,276.167z M195.414,271.303c-0.206,0-0.373,0.167-0.373,0.373
            c0,0.205,0.167,0.372,0.373,0.372c0.205,0,0.372-0.167,0.372-0.372C195.786,271.47,195.619,271.303,195.414,271.303z
             M224.206,283.245c-0.206,0-0.373,0.167-0.373,0.373c0,0.205,0.167,0.372,0.373,0.372c0.205,0,0.372-0.167,0.372-0.372
            C224.578,283.412,224.411,283.245,224.206,283.245z M220.919,285.502c-0.206,0-0.373,0.167-0.373,0.373
            c0,0.205,0.167,0.372,0.373,0.372c0.205,0,0.372-0.167,0.372-0.372C221.292,285.669,221.125,285.502,220.919,285.502z
             M214.959,291.023c-0.206,0-0.373,0.167-0.373,0.373c0,0.205,0.167,0.372,0.373,0.372c0.205,0,0.372-0.167,0.372-0.372
            C215.332,291.19,215.165,291.023,214.959,291.023z M224.162,290.41c-0.206,0-0.373,0.167-0.373,0.373
            c0,0.205,0.167,0.372,0.373,0.372c0.205,0,0.372-0.167,0.372-0.372C224.535,290.577,224.368,290.41,224.162,290.41z
             M188.578,296.721c-0.206,0-0.373,0.167-0.373,0.373c0,0.205,0.167,0.372,0.373,0.372c0.205,0,0.372-0.167,0.372-0.372
            C188.95,296.888,188.784,296.721,188.578,296.721z M204.53,296.896c-0.206,0-0.373,0.167-0.373,0.373
            c0,0.205,0.167,0.372,0.373,0.372c0.205,0,0.372-0.167,0.372-0.372C204.902,297.063,204.735,296.896,204.53,296.896z
             M228.457,292.952c-0.206,0-0.373,0.167-0.373,0.373c0,0.205,0.167,0.372,0.373,0.372c0.205,0,0.372-0.167,0.372-0.372
            C228.83,293.119,228.663,292.952,228.457,292.952z M251.772,295.669c-0.206,0-0.373,0.167-0.373,0.373
            c0,0.205,0.167,0.372,0.373,0.372c0.205,0,0.372-0.167,0.372-0.372C252.144,295.836,251.977,295.669,251.772,295.669z
             M239.588,316.441c-0.206,0-0.373,0.166-0.373,0.373c0,0.205,0.167,0.373,0.373,0.373c0.205,0,0.372-0.167,0.372-0.373
            C239.96,316.608,239.793,316.441,239.588,316.441z M270.703,294.179c-0.206,0-0.373,0.166-0.373,0.373
            c0,0.205,0.167,0.373,0.373,0.373c0.205,0,0.372-0.167,0.372-0.373S270.908,294.179,270.703,294.179z M170.829,273.779
            c-1.452,0-2.498,0.526-2.498,0.526s-0.92,1.183-0.263,1.315s0.92-0.263,1.709-0.263s2.761,0.394,3.287,0s1.709,0,0.657-0.92
            S171.224,273.779,170.829,273.779z M192.785,269.309c-0.657,0-1.446,0.263-0.92,0.657s1.446,0.657,1.446,0.263
            S192.785,269.309,192.785,269.309z M197.123,277.198c-0.789,0-2.104,0.394-1.709,0.92s0.92,1.052,1.578,1.183s1.841,0.657,1.841,0
            s0.131-1.183-0.394-1.446S197.123,277.198,197.123,277.198z M173.196,300.073c-0.394-0.394-1.709-0.657-1.446-0.131
            s1.052,1.578,1.315,1.183S173.196,300.073,173.196,300.073z M178.849,302.308c-0.526-0.394-1.315-0.394-1.052,0.263
            s1.709,2.366,1.841,1.841s0.263-1.052,0.131-1.446S178.849,302.308,178.849,302.308z M143.44,324.79
            c-0.533,0-1.052,0.264-0.614,0.351c0.439,0.087,1.315,0.789,1.403,0.35C144.316,325.053,143.703,324.79,143.44,324.79z
             M142.651,325.579c-0.263,0-0.877,0.088-0.351,0.439c0.526,0.35,2.191,1.752,2.191,1.402s0.088-0.438-0.263-0.7
            C143.878,326.456,142.651,325.579,142.651,325.579z M160.969,338.112c-0.263,0-0.438,0.702-0.438,0.702s0.263,0.614,0.526,0.263
            s0.438-0.877,0.438-0.877L160.969,338.112z M206.37,281.142c-0.415-0.139-0.876,0.088-1.227-0.263s-1.051-0.876-1.49-1.227
            c-0.439-0.351-1.753-0.964-1.665-0.526c0.088,0.438-0.701,1.051-0.175,1.139s2.366,0.789,2.98,1.227s4.032,2.542,4.383,2.892
            c0.35,0.35,0.877,1.052,1.315,1.227s1.578,0.438,2.016,0.789s1.314,0.613,1.49,0.964s0.351,0.35,0.702,0.789
            c0.35,0.439,1.84,2.104,1.928,2.98c0.088,0.877-0.176,2.717,0,3.155s1.753,2.717,2.016,2.366s0.964-0.964,0.964-1.49
            s-0.087-1.227,0.088-1.578s0.7-1.139,0.35-1.578c-0.35-0.439-1.928-2.98-2.366-2.98s-0.526,0.176-1.315-0.438
            s-1.051-1.14-1.402-1.49c-0.351-0.35-2.279-1.402-2.454-1.928c-0.175-0.526-0.876-1.315-1.49-1.403s-1.49-0.702-2.366-1.14
            C207.772,282.193,206.633,281.229,206.37,281.142z M216.361,282.456c-0.35,0-0.876-0.088-0.613,0.438s0.613,1.052,0.701,0.702
            C216.537,283.245,216.361,282.456,216.361,282.456z M211.278,279.915c0,0-0.702,0.087-0.614,0.438s0.438,0.788,0.701,0.438
            C211.629,280.441,211.278,279.915,211.278,279.915z M202.513,281.405c-0.351,0-1.227,0.35-0.614,0.438
            c0.614,0.088,1.753,0.263,1.578,0S202.513,281.405,202.513,281.405z M212.768,292.185c0.088-0.438,0.088-0.877-0.263-0.877
            s-0.614-0.088-0.789,0.263s-0.35,0.876-0.701,0.789s-0.614-0.525-1.14-0.613s-2.629-0.789-2.366,0.263s1.14,3.506,1.14,4.207
            s0.088,1.227-0.526,1.227s-1.14-0.351-1.928,0c-0.789,0.351-2.366,1.49-2.542,2.104s0,2.279-0.789,2.191s-1.052-0.263-1.403,0
            s-0.525,0.701-0.964,0.438s-1.403-0.526-1.753-0.351c-0.35,0.176-0.701,0.438-1.227,0.526s-1.052-0.613-1.315-0.964
            s-0.438-1.14-0.175-1.665s1.052-1.402,0.438-1.49c-0.614-0.088-1.052-0.176-1.14,0.438c-0.088,0.614-0.35,0.964-0.35,1.578
            s0.175,1.49-0.176,1.665c-0.35,0.175-1.052,0.439-1.578,0.351c-0.526-0.088-0.613-0.263-1.139-0.088s-1.49,0.088-2.104,0.263
            s-0.877,0.088-1.49-0.263s-1.402-0.701-1.753-0.438c-0.351,0.263-0.964,0.351-1.49,0.263s-0.877,0-1.227-0.088
            s-1.489-0.263-1.665,0s-0.701,1.49,0,1.841s2.366,0.701,2.805,1.052c0.438,0.351,0.701,0.789,1.052,0.877
            c0.351,0.087,1.578,0.439,1.578,0.789s0.088,0.788,0.526,0.613s1.051-1.227,1.753-0.789c0.702,0.439,1.315,1.315,1.928,1.753
            c0.614,0.438,3.506,0.175,3.944,0.175s2.191-0.088,2.805-0.263s2.279-0.526,2.629-0.877c0.351-0.35,0.439-0.876,0.877-0.876
            s1.578-0.35,1.928-0.526c0.35-0.176,1.578-0.789,1.841-1.052s1.226-1.14,1.139-1.403s-0.438-1.052,0.176-1.052
            s2.191,0.701,2.542,0.526c0.351-0.175,2.367-1.051,2.454-1.49c0.087-0.439-0.263-0.789-0.614-1.227s-1.139-2.279-0.438-2.279
            s1.49,0.35,2.104-0.351c0.613-0.701,1.052-1.402,0.964-1.928s-0.35-1.227-0.175-1.578s0.438-1.14-0.088-1.14
            C213.557,292.711,212.768,292.185,212.768,292.185z M232.664,299.285c-0.087-0.526-0.263-1.665-0.701-1.14
            c-0.438,0.526-0.439,0.964-0.351,1.578s0.263,2.016,0.614,1.665s0.526-0.526,0.526-0.877
            C232.752,300.161,232.664,299.285,232.664,299.285z M234.504,302.089c-0.438,0-0.964-0.35-0.964-0.35s-0.614-0.439-0.526,0.175
            s0.175,1.315,0.175,1.578s-0.262,1.14-0.087,1.578c0.175,0.438,0.701,0.702,0.964,1.14c0.263,0.438,0.789,1.314,1.315,1.402
            s1.49,0.526,1.49,0.964s-0.613,1.139-0.35,1.665s3.768,2.98,4.031,2.717s0.702-1.052,1.227-1.052c0.526,0,1.139,0.001,0.964-0.438
            s-0.263-0.702-0.526-1.403s-1.139-1.403-1.49-1.841s-0.702-0.525-1.14-0.701s-0.964,0.263-1.052-0.614s0.176-0.789-0.438-1.14
            c-0.614-0.35-1.49-0.789-1.665-1.227c-0.175-0.438-0.351-2.279-0.789-2.279C235.206,302.265,234.504,302.089,234.504,302.089z
             M241.692,313.834c-0.263-0.087-0.876-0.262-0.964,0.088s-0.175,0.7,0.263,0.7s0.964,0.176,1.052-0.087
            S241.692,313.834,241.692,313.834z M204.442,325.754c0-0.35-0.263-0.964-0.526-0.702s-0.702,0.963-0.351,1.052
            c0.351,0.088,0.525,0.264,0.438,0.614c-0.087,0.35-0.438,0.526-0.175,1.052s0.877,0.877,0.789,0.526s-0.351-0.613-0.263-1.052
            C204.442,326.806,204.442,325.754,204.442,325.754z M216.975,329.611c-0.447,0-1.928,0-1.315,0.263
            c0.614,0.263,1.052,0.789,1.052,0.789s0.613,0.702,0.964,0.789s1.052,0.175,1.227-0.088s0-0.876,0-0.876
            S217.238,329.611,216.975,329.611z M228.72,348.367c-0.438,0-1.752,0.262-1.402,0.613s0.789,0.088,1.315,0.176
            c0.526,0.087,1.051,0.001,0.876-0.35C229.333,348.454,228.72,348.367,228.72,348.367z M223.636,349.594
            c-0.526-0.176-2.016-0.877-2.279-0.964c-0.263-0.087-0.789,0.176-0.263,0.526s0.526,0.439,0.877,0.789s1.052,0.438,1.403,0.613
            c0.35,0.176,2.103,0.439,1.665-0.087C224.6,349.945,223.636,349.594,223.636,349.594z M197.693,331.802
            c-0.263-0.263-0.789-0.702-0.964-0.263s-0.438,0.7-0.175,1.139s1.052,1.666,1.315,1.14c0.263-0.526,0.438-0.789,0.35-1.052
            S197.693,331.802,197.693,331.802z M200.235,333.204c-0.351-0.263-1.052-0.526-1.052-0.526s-0.35,0.44-0.263,0.877
            c0.087,0.438-0.001,0.964,0.35,1.052c0.351,0.087,1.315,0.175,1.665,0.175c0.351,0,1.227,0.439,1.315,0.176
            s-0.175-1.227-0.175-1.227s-0.263-0.35-0.614-0.35C201.111,333.379,200.235,333.204,200.235,333.204z M203.828,337.411
            c-0.088-0.263-0.438-0.525-0.701-0.7c-0.263-0.176-1.227-1.403-1.227-0.877s0.176,1.052,0.439,1.315s0.613,0.789,0.613,0.789
            s-0.087,0.35,0.351,0.526s1.753,1.227,1.928,0.702c0.175-0.526,0.351-2.366,0-2.104c-0.351,0.263-0.526,1.053-0.877,0.964
            C204.003,337.937,203.828,337.411,203.828,337.411z M202.426,342.144c0,0-0.789,0.176-0.789,0.439s0.789,0.876,0.877,0.613
            C202.601,342.933,202.689,342.144,202.426,342.144z M241.429,344.336c0.351-0.263,0.526-1.052,0-0.789s-1.49,1.578-0.964,1.402
            S241.429,344.336,241.429,344.336z M200.41,340.654c0.438,0,0.964-0.351,1.578-0.702c0.613-0.35-0.964-0.263-1.49-0.35
            s-0.964-0.176-1.315-0.176s-1.665-0.439-1.928-0.701s-0.701-0.175-1.753-0.526s-1.665-0.963-1.665-1.315
            c0-0.351,1.227-1.052,1.665-1.489c0.438-0.438-1.841-0.789-2.366-0.789s-2.016,0-2.279,0s-1.315-0.789-1.665-1.403
            c-0.351-0.614,0.351-1.139,0.614-1.928s0.087-0.964,0-1.49s-1.578-0.263-1.928,0.263s-1.315,0.351-1.753,0.351
            s-1.315,0.087-1.928-0.088s-0.964-2.454-0.964-2.804c0-0.35-1.315-1.052-1.665-1.227c-0.351-0.176-0.614-3.243-0.701-3.506
            s-1.14-0.614-1.403-0.789s-0.964-0.614-1.49-0.789s-0.877-0.439-1.14-0.789s-2.366-2.279-2.717-2.629s-0.789-0.526-1.139-0.614
            s-0.702-2.454-0.877-2.804c-0.175-0.35-0.614-0.964-0.789-1.403s0.877-1.227,1.227-1.227s1.403,0.175,2.104,0.175
            s3.155-0.526,3.594-0.613s0.087-1.928,0-2.366s-1.578-1.49-1.841-2.104c-0.263-0.613-1.315-1.315-1.928-1.403
            c-0.614-0.088-1.227,0.263-1.841,0.175s-1.49-0.964-1.49-0.964c-0.439-0.175-0.876-0.263-1.402-0.263s-0.702-0.439-1.052-0.789
            s-3.769-1.578-4.295-1.665c-0.526-0.088-1.928,0-2.717-0.088s-0.087-2.279,0-2.629c0.087-0.351,0.176-2.104,0.176-2.542
            s-3.506-3.067-3.769-3.33s-0.964-0.351-1.403-0.351s-1.227-1.14-1.49-1.403s-2.542-1.49-2.805-1.665s-1.052-1.227-1.403-1.578
            c-0.351-0.35-1.578,0.438-2.191,0.526s-4.294-2.805-4.82-3.155s-7.801-1.841-8.677-2.016c-0.876-0.176-3.506-1.315-4.12-1.841
            s-5.258-2.542-5.697-2.717c-0.286-0.114-1.316-0.302-2.121-0.42c0.142,0.021,0.292,0.044,0.443,0.068l-0.093,29.268
            c0,0-1.969,4.135,0,4.922l0.159,18.172c0.338,0.227,0.671,0.403,0.911,0.403c0.614,0,1.665-0.964,2.104-0.702
            s0.614,0.351,1.315,0.439s2.542,0,3.067,0s2.104-0.176,2.629,0s2.717,1.316,2.98,1.227c0.263-0.088,1.928-1.402,2.542-1.578
            c0.614-0.176,2.805-0.789,2.98-1.052s0.263-1.052,0.263-1.315s-1.139-2.629-1.753-2.805s-1.49-0.35-2.016-0.613
            s-2.104-1.228-1.578-1.14c0.526,0.088,1.929,0.613,2.279,0.526s1.578-0.439,2.016-0.526s2.892-0.176,2.892-0.526
            s-0.614-1.578-0.088-1.578s1.753,0.438,2.104,0.263s0.438-0.789,0.263-1.139s-0.963-1.929-0.087-1.753
            c0.876,0.176,2.629,1.226,2.629,0.789c0-0.438,0.35-1.664,0.701-1.226s0.877,1.489,1.14,0.876s0.525-0.788,0.876-0.876
            c0.351-0.088,2.016-0.35,2.016,0s0.263,1.138,0.789,1.226s3.681,1.053,4.294,1.14c0.614,0.087,1.928,0.438,2.717,0.613
            c0.789,0.176,2.367,0.439,2.454,0.789c0.087,0.35,0.789,1.051,1.052,1.402s0.087,0.789,0.438,1.227
            c0.351,0.439,2.366,2.891,2.366,3.243c0,0.351-0.001,1.489,0.438,1.578c0.439,0.088,2.016,0.526,2.016,0.789
            s0.087,1.316,0.35,1.403s0.964,1.138,1.315,1.226s2.191,1.139,2.629,1.841c0.439,0.702,1.139,2.104,1.578,2.366
            c0.271,0.162,1.402,0.877,1.665,1.052s1.753,0.263,2.191,0c0.439-0.263,2.279,0.701,2.629,0.701c0.351,0,1.315-0.263,1.578-0.35
            s0.789,0,1.052,0.35s0.613,0.439,0.876,0.526s1.403,0,1.665,0s1.052,0,1.578,0.263s0.964,0.176,1.227,0.35
            c0.263,0.175,1.315-0.087,1.578-0.087s1.665,0.526,1.928,0.789s0.175,0.702,0.088,1.139c-0.087,0.438,2.279,1.052,2.629,1.052
            s2.016-0.438,2.367-0.701s1.052-0.263,1.139-0.702s-1.315-0.789-1.753-0.876s-0.701-0.439-0.614-0.789
            S199.972,340.654,200.41,340.654z" pointer-events="auto" class="landarea"></path>
        <circle id="CITY_24_5_" opacity="1" fill="#ffffff" enable-background="new    " cx="192.543" cy="295.713" r="6" pointer-events="auto" stroke="#000000" stroke-width="0.7px" class="city" rx="6" ry="6" stroke-opacity="0.9"></circle>
    <circle cx="192.543" cy="295.713" r="12" fill="#1e8346" pointer-events="auto" opacity="0.001" class="city2 landarea" stroke="#95bec8" stroke-width="0.5"></circle></g>
    <g id="CITY_PITCAIRNISLANDS" data-colors="0" data-errors="0" hint-index="0" class="">
        <polygon id="SEMITRANSPARENT2_7_" opacity="0" fill="#166c38" enable-background="new    " points="897.892,436.036
            897.892,473.351 820.423,473.351 820.637,436.036     " pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea" stroke-opacity="0"></polygon>

            <rect id="rect12_15" x="830.35" y="436.827" opacity="0" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="none"></rect>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="826.207" cy="457.105" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="845.533" cy="461.049" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="873.537" cy="463.678" r="0.372" pointer-events="none" class="landarea"></circle>
        <circle id="CITY_24_22_" opacity="0" fill="#166c38" enable-background="new    " cx="858.271" cy="465.05" r="6.86" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>
    </g>
    <g id="CITY_SAMOA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Samoa" data-wikipediaLink="https://en.wikipedia.org/wiki/Samoa" data-infoText="<b>Samoa</b> &amp;nbsp; Capital: Apia" data-imageFile="SAMOA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <polygon id="SEMITRANSPARENT2_6_" opacity="1" fill="#1e8346" enable-background="new    " points="506.735,381.445
            475.643,381.445 475.643,343.137 497.423,343.137 497.423,355.554 506.514,365.03  " pointer-events="auto" stroke="#95bec8" stroke-width="0.5" class="semitransparent2" fill-opacity="0.001" stroke-opacity="0"></polygon>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M494.377,365.808c-0.656,0.438-2.892,0.263-2.498,0.789s0.92,1.578,1.446,1.578s2.235,0,2.235,0s0.657-0.526,0.263-1.183
            S494.772,365.545,494.377,365.808z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M499.767,368.701c-0.92-0.263-2.235-0.263-1.972,0.263s0.526,0.92,1.315,1.052s3.55,0.526,3.024,0.131s-0.526-0.526-1.052-0.789
            S499.767,368.701,499.767,368.701z" pointer-events="auto" class="landarea"></path>
        <line fill="#1e8346" stroke="#000000" stroke-miterlimit="10" x1="489.37" y1="365.257" x2="489.485" y2="493.726" pointer-events="auto" opacity="0" class="clickarea"></line>

            <rect id="rect12_16" x="459.779" y="485.233" opacity="0" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
        <circle id="CITY_24_11_" opacity="1" fill="#ffffff" enable-background="new    " cx="489.485" cy="366.005" r="6" pointer-events="auto" stroke="#000000" stroke-width="0.7px" class="city" rx="6" ry="6" stroke-opacity="0.9"></circle>
    <circle cx="489.485" cy="366.005" r="12" fill="#1e8346" pointer-events="auto" opacity="0.001" class="city2 landarea" stroke="#95bec8" stroke-width="0.5"></circle></g>
    <g id="CITY_SOLOMONISLANDS" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Solomon Islands" data-wikipediaLink="https://en.wikipedia.org/wiki/Solomon_Islands" data-infoText="<b>Solomon Islands</b> &amp;nbsp; Capital: Honiara" data-imageFile="SOLOMON.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <polygon id="SEMITRANSPARENT2_5_" opacity="1" fill="#1e8346" enable-background="new    " points="346.868,291.659
            382.102,348.454 382.102,358.49 242.774,358.183 242.774,317.187 253.108,297.641 308.215,297.641 308.215,291.659  " pointer-events="auto" stroke="#95bec8" stroke-width="0.5" class="semitransparent2" fill-opacity="0.001" stroke-opacity="0"></polygon>

            <rect id="rect12_17" x="276.627" y="330.168" opacity="0" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="356.07" cy="353.319" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="353.178" cy="350.689" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="329.25" cy="351.084" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="332.668" cy="337.411" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="330.959" cy="335.702" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="325.043" cy="340.303" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="320.047" cy="338.726" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="326.884" cy="348.191" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="257.117" cy="317.427" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="267.985" cy="330.311" r="0.373" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="290.598" cy="335.921" r="0.373" pointer-events="auto" class="landarea"></circle>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M322.809,343.196c0,0-1.578,0.394-1.315,0.789s0.92,0.526,1.315,0.263s1.709-0.92,1.183-1.052
            C323.466,343.064,322.809,343.196,322.809,343.196z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M249.58,312.169c-0.57-0.379-1.753-1.49-2.016-1.49s-1.403,0.175-0.702,0.876c0.702,0.702,1.315,1.314,1.665,1.753
            c0.351,0.439,1.753,2.366,2.104,2.542s0.788,0.264,1.227,0.439c0.439,0.175,1.403,0.263,1.753,0.526s0.613,0.438,0.964,0.35
            c0.351-0.087,0.964-0.964,0.526-0.964s-1.14-0.263-1.578-0.526s-1.227-0.612-1.315-1.226s0.087-0.878-0.614-1.14
            C250.894,313.045,249.843,312.344,249.58,312.169z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M248.44,319.005c-0.35-0.439-0.789-0.876-0.964-0.526s-0.351,0.7-0.088,1.139s1.227,1.666,1.227,1.666s0.087-0.263,0.438-0.702
            s0.526-0.613,0.351-0.876C249.229,319.444,248.44,319.005,248.44,319.005z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M247.914,322.16c-0.175-0.613-0.7-0.789-0.613-0.263s0.263,1.841,0.526,1.315S247.914,322.16,247.914,322.16z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M252.385,321.109c-0.439-0.35-0.877-0.526-1.14-0.35s-0.613,0.613-0.438,1.052s1.139,1.227,1.402,0.964s0.526-0.263,0.439-0.789
            C252.56,321.46,252.385,321.109,252.385,321.109z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M254.926,321.634c-0.372,0.125-0.702,0.439-0.877,0.702s-0.438,0.613-0.701,0.789s-0.964,0.351-0.701,0.702
            c0.263,0.35,0.175,0.963,0.701,0.613s0.964-0.526,1.403-0.526c0.438,0,0.789-0.001,0.789,0.35s-0.263,1.052,0.175,1.315
            c0.438,0.263,1.051,0.263,1.227,0.526s1.14,0.439,1.14-0.087s0-1.139-0.263-1.578s-0.351-0.876-0.789-0.876s-0.876,0-0.964-0.526
            S255.189,321.546,254.926,321.634z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M253.699,326.28c0.35-0.439,0.263-1.315-0.088-1.052s-1.052,0.7-0.877,1.139c0.176,0.439,0.877,1.666,0.964,1.14
            S253.699,326.28,253.699,326.28z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M256.066,327.595c-0.351-0.087-1.227-0.439-0.789,0s1.402,0.789,1.227,0.526C256.329,327.858,256.066,327.595,256.066,327.595z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M259.484,325.841c-0.351,0.176-0.964,0.527-1.14,0.877c-0.175,0.35-0.262,0.613,0.088,0.876s1.052,0.35,1.227,0.087
            s0.263-0.789,0.263-1.052S259.484,325.841,259.484,325.841z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M260.974,327.945c0,0-0.702,0.001-0.614,0.351c0.088,0.35,0.263,0.613,0.526,0.35S260.974,327.945,260.974,327.945z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M276.049,350.032c-0.35,0-1.315-0.526-1.052-0.087s2.104,0.964,1.665,0.702C276.225,350.383,276.049,350.032,276.049,350.032z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M278.503,351.347c-0.438-0.263-1.402-0.964-0.964-0.263c0.438,0.702,1.051,1.665,1.402,1.578s0.789-0.263,0.526-0.613
            C279.204,351.698,278.503,351.347,278.503,351.347z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M287.443,340.04c-0.614-0.438-1.139-0.7-1.49-0.613c-0.351,0.087-1.578,0.526-0.789,0.789s2.016,0.613,2.016,1.052
            s0.438,1.49,0.701,1.753s3.33,1.489,3.681,1.489s1.841-0.086,1.49-0.7s-0.613-0.963-0.789-1.315
            c-0.176-0.351-0.526-0.877-1.052-0.964c-0.526-0.087-1.578,0-1.928-0.263S287.443,340.04,287.443,340.04z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M275.173,333.029c-0.702,0-0.964,0.351-1.49-0.263s-1.226-1.228-1.665-0.877c-0.439,0.351-0.439,1.226-0.088,1.841
            c0.351,0.614,1.315,2.016,1.928,2.279s2.278,0.001,2.717,0.176s2.366,0.875,2.98,0.963s1.49,0.088,1.578-0.35
            c0.088-0.439-0.175-1.315-0.175-1.315s-0.439-0.176-0.614-0.526c-0.175-0.35-0.526-0.527-0.877-0.702
            c-0.35-0.175-0.525-0.088-0.876-0.526s-0.702-0.613-1.227-0.613S275.173,333.029,275.173,333.029z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M276.487,329.698c0,0-1.139,0.35-0.526,0.613s1.226,0.877,1.402,0.614s0.526-0.526,0.263-0.789S276.487,329.698,276.487,329.698z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M267.109,320.408c-0.439-0.263-0.964-0.877-1.315-1.14s-0.964-0.612-1.227-0.7s-2.638-0.852-2.366-0.614
            c0.702,0.614,1.052,0.876,1.227,1.315s0.526,1.052,0.964,1.227c0.438,0.176,1.578,1.227,1.928,1.49s4.47,2.279,4.733,2.366
            s0.702,0.086,1.052,0.438s1.49,1.578,1.49,1.227c0-0.35-0.35-0.876-0.175-1.139s0.175-0.526-0.176-0.789
            c-0.35-0.263-2.629-2.016-2.98-2.279s-1.14-0.088-1.753-0.439C267.898,321.021,267.109,320.408,267.109,320.408z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M281.658,325.053c-0.263-0.35-0.613-0.438-0.964-0.613s-1.578-0.438-1.14,0.176c0.439,0.613,0.964,1.315,0.877,1.841
            s-0.351,0.963-0.088,1.402s2.279,3.856,2.717,4.031c0.438,0.176,0.788,0.439,0.964,0.702s0.439,0.79,0.702,0.964
            c0.263,0.175,0.702,1.14,0.789,0.789s0-0.701-0.351-1.315s-0.351-1.052-0.351-1.49c0-0.439-0.35-1.49-0.701-1.841
            c-0.351-0.35-0.964-0.701-1.402-1.139c-0.438-0.439-0.7-0.614-0.35-0.964c0.35-0.35,0.789-0.438,0.438-0.876
            c-0.351-0.439-0.525-0.264-0.701-0.614C281.921,325.754,281.658,325.053,281.658,325.053z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M286.479,333.379c-0.175-0.613-0.614-1.227-0.526-0.439c0.088,0.789-0.001,1.666,0.175,2.016s1.49,1.49,1.49,1.052
            c0-0.439-0.087-1.052-0.263-1.315C287.18,334.431,286.479,333.379,286.479,333.379z" pointer-events="auto" class="landarea"></path>
        <circle id="CITY_24_7_" opacity="1" fill="#ffffff" enable-background="new    " cx="304.365" cy="321.759" r="6" pointer-events="auto" stroke="#000000" stroke-width="0.7px" class="city" rx="6" ry="6" stroke-opacity="0.9"></circle>
    <circle cx="304.365" cy="321.759" r="12" fill="#1e8346" pointer-events="auto" opacity="0.001" class="city2 landarea" stroke="#95bec8" stroke-width="0.5"></circle></g>
    <g id="CITY_TOKELAU" data-colors="0" data-errors="0" hint-index="0" class="">
        <polygon id="SEMITRANSPARENT2_4_" opacity="0" fill="#166c38" enable-background="new    " points="475.643,320.32
            532.689,321.003 533.819,343.137 475.643,343.137     " pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea" stroke-opacity="0"></polygon>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="494.114" cy="326.499" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="498.978" cy="331.604" r="0.372" pointer-events="none" class="landarea"></circle>

            <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="503.974" cy="332.941" r="0.372" pointer-events="none" class="landarea"></circle>
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="502.002" y1="235.816" x2="502.002" y2="332.941" pointer-events="none" opacity="0"></line>

            <rect id="rect12_18" x="474.453" y="224.021" opacity="0" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="none"></rect>
        <ellipse fill="none" stroke="#95bec8" stroke-miterlimit="10" cx="506.072" cy="330.373" rx="32.927" ry="15.559" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></ellipse>
        <circle id="CITY_24_17_" opacity="0" fill="#166c38" enable-background="new    " cx="501.63" cy="332.941" r="6.86" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>
    </g>
    <g id="CITY_TONGA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Tonga" data-wikipediaLink="https://en.wikipedia.org/wiki/Tonga" data-infoText="<b>Tonga</b> &amp;nbsp; Capital: Nuku'alofa" data-imageFile="TONGA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <polyline id="SEMITRANSPARENT2_3_" opacity="1" fill="#FFFFFF" enable-background="new    " points="512.212,408.488
            512.156,448.413 455.152,448.352 464.915,424.132 464.975,381.573 506.782,381.633 506.744,408.526 512.212,408.533
            512.156,448.314     " pointer-events="auto" class="semitransparent2" fill-opacity="0.001" stroke-opacity="0"></polyline>
        <line fill="#1e8346" stroke="#000000" stroke-miterlimit="10" x1="477.242" y1="396.829" x2="455.199" y2="465.75" pointer-events="auto" opacity="0" class="clickarea"></line>

            <rect id="rect12_19" x="427.278" y="457.421" opacity="0" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
        <circle id="CITY_24_15_" opacity="1" fill="#ffffff" enable-background="new    " cx="477.64" cy="396.404" r="6" pointer-events="auto" stroke="#000000" stroke-width="0.7px" class="city" rx="6" ry="6" stroke-opacity="0.9"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="468.784" cy="383.164" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="484.035" cy="386.232" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="479.302" cy="403.323" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="482.02" cy="408.493" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="476.585" cy="409.983" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="473.517" cy="417.696" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="475.709" cy="419.888" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="479.477" cy="417.258" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="475.446" cy="422.867" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="477.637" cy="422.692" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="470.625" cy="424.532" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="474.306" cy="431.457" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="464.402" cy="439.696" r="0.372" pointer-events="auto" class="landarea"></circle>
    <circle cx="477.64" cy="396.404" r="12" fill="#1e8346" pointer-events="auto" opacity="0.001" class="city2 landarea" stroke="#95bec8" stroke-width="0.5"></circle></g>
    <g id="CITY_TUVALU" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Tuvalu" data-wikipediaLink="https://en.wikipedia.org/wiki/Tuvalu" data-infoText="<b>Tuvalu</b> &amp;nbsp; Capital: Funafuti" data-imageFile="TUVALU.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <polygon id="SEMITRANSPARENT2_2_" opacity="1" fill="#1e8346" enable-background="new    " points="475.643,320.32
            475.643,348.454 382.102,348.454 346.868,291.659 420.714,291.659     " pointer-events="auto" stroke="#95bec8" stroke-width="0.5" class="semitransparent2" fill-opacity="0.001" stroke-opacity="0"></polygon>

            <rect id="rect12_20" x="377.347" y="303.982" opacity="0" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
        <circle id="CITY_24_19_" opacity="1" fill="#ffffff" enable-background="new    " cx="406.006" cy="333.818" r="6" pointer-events="auto" stroke="#000000" stroke-width="0.7px" class="city" rx="6" ry="6" stroke-opacity="0.9"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="403.268" cy="303.492" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="404.845" cy="308.619" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="412.471" cy="306.779" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="411.55" cy="315.587" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="423.909" cy="317.559" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="421.016" cy="322.555" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="432.717" cy="333.05" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="430.219" cy="343.962" r="0.372" pointer-events="auto" class="landarea"></circle>
    <circle cx="406.006" cy="333.818" r="12" fill="#1e8346" pointer-events="auto" opacity="0.001" class="city2 landarea" stroke="#95bec8" stroke-width="0.5"></circle></g>
    <g id="CITY_WALLISANDFUTUNA" data-colors="0" data-errors="0" hint-index="0" class="">
        <polygon id="SEMITRANSPARENT2_1_" opacity="0" fill="#166c38" enable-background="new    " points="422.711,381.6
            422.711,348.454 475.643,348.454 475.643,381.445     " pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea" stroke-opacity="0"></polygon>
        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="449.601" y1="365.452" x2="377.53" y2="493.92" pointer-events="none" opacity="0"></line>
        <circle id="CITY_24_13_" opacity="0" fill="#166c38" enable-background="new    " cx="450" cy="365.027" r="6.86" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>

            <rect id="rect12_21" x="349.694" y="485.233" opacity="0" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="none"></rect>
    </g>
    <g id="CITY_GUAM" data-colors="0" data-errors="0" hint-index="0" class="">

            <line fill="none" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" x1="80.719" y1="122.038" x2="146.033" y2="148.23" pointer-events="none" opacity="0"></line>

            <rect id="SEMITRANSPARENT2_18_" x="123.894" y="144.15" opacity="0" fill="#000000" enable-background="new    " width="46.277" height="10.781" pointer-events="none" fill-opacity="0.1" stroke-opacity="0" stroke="#000000" visibility="visible"></rect>
        <ellipse fill="none" stroke="#95bec8" stroke-miterlimit="10" cx="145.686" cy="150.154" rx="36.313" ry="15.643" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></ellipse>
        <circle id="CITY_24_1_" opacity="0" fill="#166c38" enable-background="new    " cx="145.686" cy="148.23" r="6.86" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>

            <rect id="rect12_1_" x="53.798" y="112.662" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="none"></rect>
        <circle fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="157.079" cy="146.424" r="0.373" pointer-events="none" class="landarea"></circle>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M155.238,151.551
            c0.131-0.526-0.526-1.578-0.92-1.183s-0.526,0.657-0.657,1.052s-0.92,1.052-0.657,1.446s0.789,0.657,1.052,0.131
            s0.263-0.526,0.263-0.526L155.238,151.551z" pointer-events="none" class="landarea"></path>
    </g>
    <g id="CITY_VANUATU" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Vanuatu" data-wikipediaLink="https://en.wikipedia.org/wiki/Vanuatu" data-infoText="<b>Vanuatu</b> &amp;nbsp; Capital: Port Vila" data-imageFile="VANUATU.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <polygon id="SEMITRANSPARENT2" opacity="1" fill="#1e8346" enable-background="new    " points="256.525,358.183
            382.102,358.49 382.102,430.362 346.215,430.362 307.823,394.474 256.525,394.474  " pointer-events="auto" stroke="#95bec8" stroke-width="0.5" class="semitransparent2" fill-opacity="0.001" stroke-opacity="0"></polygon>

            <rect id="rect12_22" x="310.6" y="364.234" opacity="0" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="342.047" cy="393.154" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="341.521" cy="395.345" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="335.824" cy="364.318" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="326.972" cy="363.266" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="328.024" cy="365.633" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="333.194" cy="366.159" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="347.394" cy="409.807" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="349.322" cy="415.591" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="353.003" cy="421.552" r="0.372" pointer-events="auto" class="landarea"></circle>

            <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="356.51" cy="415.767" r="0.372" pointer-events="auto" class="landarea"></circle>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M334.948,368.612c-0.176-0.438-0.439-1.403-0.789-0.789s-0.351,0.789-0.176,1.315c0.176,0.526,0.613,0.703,0.789,0.351
            C334.948,369.138,334.948,368.612,334.948,368.612z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M335.124,371.505c-0.351-0.175-0.703-0.351-0.877,0c-0.175,0.351-0.789,1.053-0.263,1.14s1.052,0.175,1.227-0.088
            C335.387,372.294,335.124,371.505,335.124,371.505z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M328.637,377.202c-0.176-0.526-0.876-2.016-1.139-1.841c-0.263,0.176-0.526,0.439-0.526,0.964s0.7,2.892,0.7,3.243
            c0,0.35-0.263,1.489,0,1.928s0.701,0.614,0.789,0.964c0.088,0.35,0.263,1.227,0.702,1.227s0.613-0.439,1.052-0.439
            s0.7-0.176,1.139-0.263s1.139-0.087,1.052-0.526s-0.439-0.79-0.526-1.227c-0.087-0.438,0.177-0.963-0.175-1.402
            c-0.351-0.439-0.526-0.526-0.526-1.052s-0.439-1.403-0.702-0.702c-0.263,0.702-0.351,1.489-0.702,1.578
            c-0.35,0.088-0.789-0.175-0.789-0.7S328.637,377.202,328.637,377.202z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M332.143,383.425c-0.263,0-0.789,0.35-0.526,0.613s0.877,0.702,0.964,0.263C332.669,383.863,332.143,383.425,332.143,383.425z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M333.633,386.142c-0.439-0.35-1.139-1.053-1.315-0.439s-0.79,2.192-0.176,2.192s1.49,0.087,1.49,0.613s0.35,1.226,0.35,1.665
            s0,1.403,0.439,1.227c0.439-0.176,0.788-1.052,1.139-0.789s1.139,0.613,1.315,0.35s0.351-0.963,0.088-1.226
            s-0.966-0.963-1.403-1.315S333.633,386.142,333.633,386.142z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M337.402,380.796c-0.35,0.175-1.752,1.139-1.139,1.402s1.49,0.35,1.578,0.087s1.139-1.401,0.613-1.489
            C337.928,380.708,337.402,380.796,337.402,380.796z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M340.12,383.162c0-0.351-0.7-0.613-0.526,0.263c0.175,0.876,0.612,3.155,0.7,2.629S340.12,383.162,340.12,383.162z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M339.857,379.043c0-0.35-0.526-1.139-0.526-0.35s0.526,3.067,0.613,2.717C340.031,381.059,339.857,379.043,339.857,379.043z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M340.294,387.807c0-0.263-0.35-0.788-0.613-0.438s-0.876,0.438-0.876,0.438l-0.439,0.964c0,0,1.139,0.702,1.578,0.702
            s1.227-0.351,0.964-0.702C340.645,388.421,340.294,387.807,340.294,387.807z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M340.908,392.19c-0.263-0.263-0.963-0.964-1.052-0.439s0,1.053,0.35,1.14s1.139,0.439,1.139,0
            C341.346,392.453,340.908,392.19,340.908,392.19z" pointer-events="auto" class="landarea"></path>

            <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
            M342.223,399.289c-0.263-0.176-1.052-0.263-1.315,0s-1.052,1.402-0.789,1.665s0.789,0.439,1.315,0.439s1.666-0.176,1.578-0.526
            c-0.088-0.35-0.351-0.525-0.439-0.876C342.486,399.639,342.223,399.289,342.223,399.289z" pointer-events="auto" class="landarea"></path>
        <circle id="CITY_24_18_" opacity="1" fill="#ffffff" enable-background="new    " cx="339.412" cy="394.285" r="6" pointer-events="auto" stroke="#000000" stroke-width="0.7px" class="city" rx="6" ry="6" stroke-opacity="0.9"></circle>
    <circle cx="339.412" cy="394.285" r="12" fill="#1e8346" pointer-events="auto" opacity="0.001" class="city2 landarea" stroke="#95bec8" stroke-width="0.5"></circle></g>
    <g id="AREA_AUSTRALIA" data-colors="0" data-errors="0" hint-index="0" class="q answered" data-qText="Australia" data-wikipediaLink="https://en.wikipedia.org/wiki/Australia" data-infoText="<b>Australia</b> &amp;nbsp; Capital: Canberra" data-imageFile="AUSTRAL.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M74.9,540.402
            c-0.048,0.531,0,0.876,0.439,1.139s2.191,2.104,2.366,2.717c0.175,0.613,0.35,0.702,0.35,1.403s0.351,0.876,0.614,1.139
            s0.263,1.315,0.351,1.665s0.175,0.964,0.438,1.403s-0.175,0.7-0.614,0.7s0.263,0.789,0.702,0.964
            c0.439,0.176,1.315,1.665,1.753,2.191c0.438,0.526,0.438-0.35,0.701-0.701s1.315,0.438,1.753,0.789
            c0.439,0.351,0.175-1.403,0.175-1.753s-0.614-0.176-0.964-0.263s0.263-1.578,0.526-1.841s0.526-0.439,0.876-0.526
            s0.526-0.439,0.526-0.876c0-0.438,0.614-0.789,0.964-1.227s0.876-1.578,1.139-2.104s3.506-2.366,3.944-2.542
            c0.438-0.176,0.614-0.35,0.789-0.613s1.052-0.087,1.403-0.087s1.665-3.418,1.753-3.857c0.087-0.439,0.526-1.402,0.526-1.402
            c0.175-0.351,2.366-1.228,2.454-1.666c0.088-0.438,0.351-0.875,0.351-1.226s-0.351-1.227-0.263-1.753s0.439-1.226,0.614-0.613
            c0.175,0.613,0.088,1.139,0.263,1.665s0.263,1.402,0.701,1.841c0.438,0.439,1.052,1.139,0.526,1.402s-1.578,0.351-1.403,0.964
            c0.176,0.613,1.052,3.244,0.789,3.331s-2.366,2.541-2.629,3.067s-0.526,1.228-0.701,1.666c-0.175,0.438-0.351,2.015-0.351,2.541
            s-0.087,1.929-0.087,2.542s-0.35,3.506-0.789,3.243s-0.789-0.525-1.14-0.438s-0.439,0.175-0.877,0.087
            c-0.438-0.087-0.789-0.264-0.964,0.087c-0.175,0.351,0.001,1.841-0.438,2.016c-0.439,0.176-1.315,1.752-0.702,1.665
            c0.614-0.087,0.877-0.439,1.403-0.526s1.227-0.438,1.665-0.613c0.438-0.176,1.139-0.79,1.49-0.702s2.191,0.964,2.279,0.439
            c0.088-0.526,0.702-3.068,0.964-3.594s0.262-1.226,0.35-1.665c0.088-0.439,0.263-1.665,0.702-2.191
            c0.438-0.526,0.702-2.543,1.052-1.929s0.526,1.49,0.964,2.016c0.438,0.526,1.227,2.016,1.49,2.279s0.877,0.963,0.877,1.489
            s-0.176,2.016-0.351,2.542c-0.175,0.526-0.175,1.139-0.175,1.578s0.176,0.788-0.175,1.139c-0.351,0.351-0.876,1.579-1.315,1.666
            c-0.439,0.087-1.841,0.964-1.052,1.315c0.789,0.35,2.279,0.526,2.542,0.263s1.051-0.964,1.49-0.964
            c0.439,0,1.666-0.087,2.016-0.263s0.613-0.876,0.613-0.876s0.351-0.35,0.614-0.526s0.964-0.439,1.315-0.439
            c0.351,0,1.49,0.79,1.14,0.964c-0.351,0.175-0.526,0.438-1.227,0.613c-0.701,0.176-0.964,0.35-1.227,0.526s-1.14,0.176-0.614,0.526
            s1.929,1.139,2.279,1.402s2.366,3.067,2.98,3.944c0.614,0.877,2.104,3.944,1.928,5.259c-0.175,1.315-1.665,1.929-1.403,2.542
            c0.263,0.613,0.526,0.614,0.439,1.227c-0.088,0.613-0.176,1.051,0.087,1.489s1.227,1.929,1.578,2.192
            c0.351,0.263,0.613,0.349,1.052,0.963s1.666,3.155,2.542,3.857c0.876,0.702,1.753,1.316,2.542,1.49
            c0.789,0.175,2.279-0.351,2.805,0.175s1.665,1.226,1.928,1.578c0.263,0.351,0.263,1.49,0.701,1.49c0.438,0,1.49,0.525,1.578,0.087
            c0.088-0.438-0.262-0.964,0.351-1.052c0.613-0.087,1.489-0.263,1.928-0.087s2.192,1.052,2.542,1.052s0.963-0.176,1.402-0.176
            s1.315,0.526,1.315,0.526s1.227,0.963,1.841,1.315c0.614,0.351,2.717,1.227,2.717,1.227c0.438,0.263,1.052,0.876,1.665,0.876
            c0.614,0,1.052,0.701,1.49,0.877s1.578-1.227,1.928-1.49c0.351-0.263,1.315-1.139,1.578-1.49s1.928-1.402,2.454-2.191
            s1.841-0.702,2.629-0.702c0.789,0,0.526-0.613,0.613-1.052s-0.789-0.087-1.315-0.087s0.263-1.052,0.614-1.315
            s1.14-0.613,1.665-1.315s1.49,0.526,1.49,0.789s0.614,0.876,0.789,1.227s-0.701,1.578-1.14,2.104s-0.964,0.526-1.578,0.35
            s0.263,1.052,0.964,1.402c0.702,0.35,1.052,0.088,1.402-0.175s0.526-0.702,0.964-0.877c0.439-0.176,0.35-0.526,0.438-1.052
            s0.614-0.438,1.052-0.438s1.052,0.7,1.052,0.7s0,0.789-0.351,1.315s-0.263,0.614-0.263,0.964s0.964,1.139,1.403,1.315
            c0.438,0.176,1.227,0,1.665,0.087c0.439,0.087,0.701,1.929,0.789,2.366c0.088,0.438,0.789,0,1.315-0.438s0.438,0,0.964,0.35
            s0.964,1.753,1.315,2.104c0.351,0.35,0.263,0.526,0.701,0.526s-0.087-2.542-0.087-3.068s-0.614,0.263-1.052,0.263
            s-0.526-0.701-0.526-1.226s1.578-0.351,2.104-0.439c0.526-0.087,1.841-0.35,2.629-0.526c0.789-0.176,2.717-2.454,4.996-4.909
            s5.522-2.804,6.661-3.243c1.139-0.439,3.068-0.263,3.857-0.263s2.717-0.263,3.33-0.263c0.614,0,1.928,0.087,2.454-0.087
            c0.526-0.175,1.928-1.929,2.104-2.192s0.964-0.263,1.315-0.35s0.35-1.315,0.087-1.928s0.439-0.614,0.789-0.877
            s-0.701-1.315-1.052-1.489c-0.351-0.175,0.176-1.052,0.176-1.052s-0.088-1.052-0.088-1.49c0-0.439,1.052-2.717,1.227-3.243
            s0.263-2.016,0.351-2.629c0.088-0.613,0.175-1.052,0.175-1.578s0.263-2.104,0.526-2.629s0.526-1.139,0.702-1.402
            s0.964-1.929,1.315-2.192s0.35-1.752,0.613-2.191c0.263-0.439,1.14-0.702,1.403-0.789s0.526-2.717,0.438-3.155
            c-0.088-0.439,0.439-1.052,0.614-1.402s0.175-0.614-0.088-0.964s0.176-0.876,0.263-1.315s1.665-2.366,2.191-2.717
            c0.526-0.35,0-0.614-0.175-0.877s0.263-0.789,0.613-0.963c0.35-0.175,0.877-1.49,1.052-1.929s0-1.315-0.087-1.752
            c-0.087-0.438,0.876-0.877,1.315-1.315s0.175-0.614,0-0.964s0.964-1.402,1.315-2.016s1.051-1.578,1.665-2.191
            s1.665-0.877,2.191-1.14s0.701-1.226,0.964-1.752s1.315-0.789,1.841-1.14s0.438-2.717,0.438-3.505s0.789-1.402,1.315-1.753
            s1.052-1.841,1.227-2.629s0.613-2.104,0.789-2.717s0.789-2.717,0.876-2.98s-0.263-1.14-0.526-1.403s0-1.402,0.088-2.015
            c0.088-0.613,0.526-2.366,0.964-2.892s0.614-2.366,0.614-2.981s1.052-3.243,1.14-3.944c0.088-0.702,0.175-1.841,0.175-2.366
            s0.614-1.578,0.789-2.104s0.789-1.139,1.052-1.489s0-2.717-0.175-3.243s0-1.666,0-2.192s-0.526-1.402-0.964-2.015
            s-0.263-1.315-0.263-2.016c0-0.702-0.176-0.526-0.526-0.789s-0.614-2.016-0.702-2.366s-0.789-0.876-1.052-1.227
            s-0.526-1.139-0.526-1.489s0.176-0.702,0.789-1.052s-0.263-2.279-0.35-2.981s-0.176-2.454-0.263-3.067
            c-0.087-0.613-0.176-0.789-0.176-1.403s0.526-1.489,0.614-2.278s-0.526-0.614-0.789-0.702c-0.263-0.087-0.877-1.315-1.227-1.665
            s0-1.841,0-2.455s-0.351-1.139-0.351-1.139c-0.087-0.438-1.578-0.702-2.104-0.877c-0.526-0.176-1.14-3.243-1.403-3.768
            s-1.49-0.789-2.104-1.227c-0.614-0.439-0.877-1.665-1.403-2.454s-1.665-2.366-2.104-2.629c-0.439-0.263-0.876,0.176-1.227,0.176
            s-1.665-1.227-2.191-1.578c-0.526-0.35-2.016-1.929-2.366-2.542c-0.351-0.613-0.789-0.263-1.139-0.35s-0.176-1.052-0.176-1.49
            c0-0.439,0-0.876,0.088-1.315s-0.088-1.578-0.088-1.928s0.088-1.315,0.088-1.753c0-0.439-0.701-1.578-0.701-1.578
            s-0.176-0.702-0.088-1.227c0.088-0.526-0.614-0.876-1.052-0.963s-0.526,0.963-0.526,1.752s-0.877-0.35-1.14-0.7
            s-1.052-0.526-1.402-0.702c-0.35-0.176-0.526-0.702-0.789-1.315s-0.789-0.702-1.227-0.526c-0.439,0.176,0.351,2.892,0.439,3.505
            s-1.052-0.876-1.403-1.226s-0.701,0.175-1.052,0.263s-0.438-0.614-0.438-1.052s-0.351-0.526-0.614-0.877s-1.052-3.33-1.14-3.681
            s0.351-1.139,0.351-1.489s-1.052-1.315-1.052-1.315s-0.701-1.753-0.789-2.279s-0.789-1.227-1.14-1.665
            c-0.351-0.438-0.613-0.789-0.613-0.789c-0.175-0.263-0.964-0.702-1.49-0.789s-0.789-1.227-1.227-1.665
            c-0.438-0.438,0.088-0.789,0.438-1.14s0.614,0,1.227,0.088c0.614,0.088,0.087-0.789-0.088-1.227
            c-0.175-0.439-1.14-0.964-1.49-1.052c-0.35-0.087-1.052-1.052-1.315-1.139s-0.351,0.175-0.702,0.438s-1.315-1.052-1.49-1.315
            s-1.14-0.963-1.14-0.963s-1.052,0.087-1.49,0s-0.351-0.263-0.701-0.964c-0.35-0.702-0.526,0.176-0.877,0.439
            s-1.665-2.892-1.928-3.418s-1.841,0.087-2.279,0.087s-1.052-0.702-1.403-1.227s-1.402-0.438-1.665-0.7s-0.964,0-1.315,0
            s-2.191-1.841-2.629-2.279c-0.438-0.439,0-2.366,0.087-2.805s-0.526-0.526-0.876-0.526s-0.702-1.315-0.877-1.665
            s-0.438-0.613-0.701-0.964s0.438-1.928,0.701-2.278s0-2.016,0-2.629s-0.088-1.841-0.351-2.455s-0.701-1.489-1.052-2.191
            s0-0.526,0.176-0.964c0.176-0.439,0-0.526-0.439-0.613s-2.191-1.402-2.454-1.665s-0.789-0.964-1.227-1.49s0-1.315,0.088-1.665
            s0.088-0.964,0.088-1.403c0-0.439-0.526-2.015-0.789-2.366s-0.439-2.191-0.614-2.804c-0.175-0.613,0-0.877,0.175-1.227
            c0.175-0.35-0.087-0.789-0.35-1.052s0-1.139,0.263-1.578s0.087-0.789-0.263-1.052s-1.315-0.176-1.753-0.526
            c-0.439-0.35-0.613-0.702-0.701-1.315s-1.753-0.964-2.454-1.227c-0.702-0.263-0.789-2.104-1.052-2.629
            c-0.263-0.526-0.789,0.526-1.139,0.789s-0.789,0-1.14-0.175s-0.614,0.789-0.701,1.226s-0.789,0.614-1.841,0.526
            s-1.14-1.665-1.227-2.454c-0.088-0.789-1.139-2.279-1.49-2.805s0-1.402,0.175-2.104s-0.087-1.489-0.438-2.104
            c-0.351-0.614-0.175-2.717-0.088-3.33c0.087-0.613-0.701-0.263-1.139-0.439s0-0.964,0.35-1.402c0.35-0.438-0.701-0.964-1.052-1.14
            c-0.351-0.176-0.263-0.7-0.263-1.226s-0.789-0.351-1.315-0.439c-0.526-0.087,0.439-2.016,0.877-2.542s-0.438-0.7-0.877-0.789
            s-1.052,0-1.49-0.175s-0.351-3.155-0.263-3.594s-0.877-3.068-0.877-3.068c-0.087-0.438-0.262-1.052-0.613-1.139
            c-0.351-0.087-0.964-0.175-0.877-0.613c0.088-0.439,0.702-1.053,0.088-1.14s-0.877-0.262-1.227,0.351s-0.438,1.315-0.964,1.315
            s-1.052-0.263-1.14,0.35c-0.087,0.613-0.438,4.207-0.701,4.733s-0.789,1.929-0.789,2.279s0.263,1.489,0.263,1.489
            s-1.139-0.525-1.227-0.175s-0.439,1.052-0.614,1.315s-0.964,2.541-0.964,2.541s0.788,0.35,1.139,0.526
            c0.351,0.176,0.789,0.613,0.526,0.789s-0.525,0.438-0.613,0.789c-0.088,0.351-0.176,0.876-0.439,1.052s-0.964,0.789-0.964,0.789
            c-0.439,0.35,0.176,1.49,0.263,2.104c0.087,0.613-0.175,1.139-0.438,1.578s-0.789,2.104-1.14,2.892
            c-0.351,0.789,0.877,2.016,1.14,2.542s-0.175,1.752-0.438,2.454s0.263,2.717,0.438,3.068c0.175,0.351,0.351,1.665,0.526,2.541
            c0.175,0.876-1.665,4.646-2.016,5.347c-0.35,0.702,0,2.717,0,3.155c0,0.439-0.439,1.315-0.789,1.752
            c-0.35,0.438-0.176,1.315-0.439,2.016c-0.263,0.702-0.701,1.315-1.402,1.928c-0.701,0.613-1.578,4.383-1.578,4.909
            s-1.928,1.49-2.454,1.753s-3.155,0.963-3.681,0.963s-1.578-0.7-2.016-0.963c-0.438-0.263-1.841-0.526-2.191-0.877
            c-0.351-0.351-2.366-1.314-2.366-1.314c-0.789-0.263-0.789-1.666-0.789-2.016s-1.14-1.402-1.578-1.665s-1.578-0.351-1.928-0.526
            c-0.351-0.175-1.403-0.439-1.928-0.439s-1.665-0.176-2.191-0.439s-3.594-3.681-4.12-4.031s-1.49-0.526-2.016-0.526
            s-2.366-1.665-2.892-2.016s-1.227,0-1.578,0.088c-0.351,0.088-0.614-0.088-1.227-0.088s-0.701-0.877-0.877-1.227
            c-0.176-0.35-1.315-0.789-1.753-1.052c-0.438-0.263-1.227-1.315-1.315-1.665c-0.088-0.35-4.382-2.892-5.171-3.418
            s-1.403-1.227-1.49-1.753s0.964-1.315,1.227-1.841s0.964-1.49,1.49-1.928s0.614-0.789,0.964-0.964
            c0.35-0.176,0.439-0.876,0.439-1.227s0.087-1.052,0.35-1.402s0.439-1.227,0.439-1.753s-0.351,0.088-0.877,0.176
            c-0.526,0.087-0.438-1.052-0.526-1.665s0.263-0.351,0.701-0.526c0.438-0.175-0.087-0.526-0.175-0.877
            c-0.088-0.351,1.14-1.052,1.49-1.226s0.439,0,0.702,0.263s0.789-0.877,1.315-1.227c0.526-0.35,0.35,0.702,0.438,1.315
            c0.088,0.613,0.526-0.263,0.701-0.613c0.175-0.35,0.263-0.789,0.176-1.227s0.526-0.176,0.789-0.263s-0.439-1.052-0.702-1.315
            s0.439-0.439,0.702-0.439s0.613-0.963,0.789-1.315s0.701-0.7,1.578-1.226c0.877-0.526-0.263-1.753-0.702-2.104
            c-0.439-0.35-0.526,0.263-0.613,0.613s-1.315-0.789-1.403-1.315s-0.614-0.789-0.964-0.964c-0.35-0.176-1.052,0.526-1.49,0.964
            c-0.439,0.439,0.263,0.876,0.614,1.052s-0.088,0.964-0.526,1.402c-0.438,0.438-1.49,0.351-1.841,0.176
            c-0.351-0.176-0.088-0.526,0-0.964c0.088-0.439-0.263-0.526-0.526-0.526s0.088-0.613,0.088-1.052s-1.14,0.789-1.578,0.964
            c-0.438,0.176-0.789,0-0.789-0.35s0.789-0.877,1.402-1.14s0.263-0.7-0.087-0.7s0.263-0.702,0.613-1.227s-0.526-0.087-0.876,0.526
            s-1.49,0.789-1.753,0.876s0,0.614-0.35,0.789s-1.227,0.088-1.578,0.088s-0.439,0.7-0.614,1.139c-0.175,0.439-1.14,0-1.928-0.087
            c-0.789-0.087-1.753-1.578-2.104-1.753c-0.351-0.176-0.789,0-1.052,0.263s-0.876,0.087-1.402,0s-1.052,0-1.49,0.087
            c-0.439,0.087-0.789-0.438-0.789-0.876c0-0.439-0.613-0.439-1.052-0.439s-0.789,0.263-1.227,0.263
            c-0.438,0-0.088-0.963-0.088-1.315s-1.227,0.088-1.665,0.351s-0.877,0.175-1.315-0.088c-0.438-0.263-0.877-0.526-1.315-0.526
            s-0.702,0-1.14,0s-1.227-1.928-1.227-1.928s-0.614-0.702-0.964-0.964c-0.351-0.263-0.789,0.789-1.227,1.14
            s-1.052-0.351-1.052-0.351c-0.176-0.263-0.964-1.226-1.315-1.489c-0.351-0.263-0.614-0.613-0.877-0.789s-1.052-0.44-1.052-0.088
            s-0.351,1.491-0.614,1.14c-0.263-0.351-0.613-1.489-0.964-1.315c-0.351,0.175-0.525,0.438-0.964,0.438s-0.964,0.527-0.614,0.614
            c0.351,0.087,1.14,0.438,1.403,0.876c0.263,0.439,0.263,1.226,1.052,1.139s2.454-0.963,2.805-0.35s0.964,1.402,1.052,2.104
            s0.263,2.454,0,2.629c-0.263,0.176-1.052,0.263-1.315,0.526s-0.789,0.613-1.315,0.613c-0.526,0-0.701-0.176-1.227,0.263
            s-1.139,0.614-1.578,0.351s-0.613-0.177-1.315-0.088c-0.702,0.088-2.629,0.439-3.155-0.087c-0.526-0.526-0.789-1.84-0.964-1.402
            s-0.438,0.7-0.876,0.7s-0.789-0.349-0.877,0.088c-0.088,0.438-0.001,1.402-0.351,1.402c-0.35,0-1.928-0.001-2.191,0.087
            s-0.614,0.087-0.702,0.526c-0.087,0.439,0.088,1.052-0.35,1.227c-0.439,0.176-1.49-0.087-1.578,0.526
            c-0.088,0.613,0,2.016-0.526,2.016s-1.403,0.262-1.49,0.7c-0.087,0.439,0,1.316,0.263,1.666c0.263,0.35,0.964,0.877,0.439,1.315
            c-0.526,0.438-1.841,1.841-2.104,1.665s-0.439-0.789-0.877-0.263s0.001,1.577-0.438,1.928s-0.526,1.753-0.526,1.753
            s-0.614,0.262-0.877,0.35s-0.702,0.177-0.877,0.614c-0.175,0.438-0.175,0.789-0.438,1.139s-0.789,1.14-0.526,1.49
            c0.263,0.35,1.753,1.139,1.753,1.402s0.087,1.139,0.526,1.402s2.016,0.789,1.315,0.789s-1.226-0.263-1.402,0.176
            s0.613,2.541,0.175,2.454c-0.438-0.087-0.964-1.226-1.227-1.489s-2.279-1.489-2.191-0.789s-0.088,2.104-0.263,1.841
            c-0.175-0.263-0.701-1.403-0.701-1.403s-1.227-0.699-1.753-0.875s-3.593-1.14-3.681-0.702c-0.088,0.439,0.087,2.191-0.176,2.191
            s-0.788-0.702-1.139-0.526s-0.439,0.263-0.439,0.789s-0.175,1.052-0.438,1.227c-0.263,0.176-1.052,1.139-1.052,0.35
            s0.351-2.278,0.614-2.804c0.263-0.526,1.14-1.052,0.263-1.578c-0.877-0.526-1.753-1.841-2.542-2.542
            c-0.789-0.702-2.98-2.804-3.067-3.243c-0.088-0.439-0.439-0.614-0.877-0.614s-1.928,0.177-2.104-0.175
            c-0.175-0.351-0.438-1.752-0.964-1.578s-1.49,0.087-1.403,0.613c0.088,0.526,0.088,1.139-0.175,1.578
            c-0.263,0.439-0.877,1.579-1.315,1.49c-0.438-0.088-1.227-0.613-0.964-1.052c0.263-0.439,0-0.964-0.351-0.702
            c-0.351,0.263-0.789,0.438-0.789,0.876c0,0.439,0.439,1.49-0.175,1.227s-1.227-0.439-1.227-0.439s-1.402,1.315-1.227,1.841
            c0.175,0.526,0.438,0.79-0.088,1.14s-0.964,1.138-1.052,0.7c-0.087-0.438-0.789-1.929-0.964-1.315
            c-0.175,0.614-0.526-0.175-0.877,0.176c-0.35,0.35-0.876,1.315-1.402,1.052s-0.877-0.35-1.227-0.087s-0.789,0.877-0.789,1.315
            s0.088,1.49,0.439,1.578c0.35,0.087,1.051,0.438,1.139,0.7c0.088,0.263,0.001,0.966-0.35,0.877
            c-0.351-0.088-0.877-0.614-1.227-0.614s-1.315,0.263-1.315,0.263s-0.964,0.264-1.052,0.614c-0.087,0.35,0.001,0.875,0.439,0.963
            s1.227,0.263,1.052,0.526c-0.176,0.263-0.526,0.614-0.877,0.439c-0.351-0.176-0.964-0.176-1.315-0.439s-0.525-0.438-0.701-0.175
            s-0.439,1.489-0.877,1.402c-0.438-0.087-0.526-0.439-1.052,0s-0.788,0.702-0.701,1.315s0.175,0.788,0.35,1.226
            c0.176,0.439,0.001,1.49,0.439,0.964c0.438-0.526,0.876-1.314,1.227-1.139c0.351,0.175-0.087,0.263-0.175,0.876
            c-0.088,0.613-0.263,2.016-0.614,1.841c-0.351-0.176-0.614-0.35-0.789,0s-0.702,1.226-0.088,1.402s1.666,0.789,0.964,0.789
            s-0.702-0.263-1.49-0.087c-0.789,0.176-0.7,0.789-1.402,0s-1.402-1.316-1.578-0.964c-0.176,0.351-0.613,0.966-1.052,0.614
            s-0.439-1.403-0.964-1.49c-0.526-0.087-1.402,0.262-1.227,0.613s0.702,1.14,0.614,1.49c-0.088,0.35-0.964,1.489-0.702,1.928
            c0.263,0.439,0.964,1.14,0.964,1.14s1.227-0.176,1.227,0.876s0.351,2.104-0.175,1.841s-1.665-0.789-1.665-0.526
            s0.088,0.963,0,1.402s-0.701,3.069-0.964,2.455s-0.877-1.491-0.964-1.929s-1.665-3.243-1.928-3.768
            c-0.263-0.526-1.227-1.403-1.14-2.016c0.088-0.613-0.088-2.015-0.351-1.402s-0.438,0.876-0.876,1.052
            c-0.439,0.176-0.701,0.613-0.526,1.052s0.702,0.788,0,0.876s-1.578-0.175-1.578,0.351s-0.087,1.052-0.526,1.139
            c-0.439,0.087-1.49,0.613-1.753,1.052s-1.49,2.629-1.227,3.418s0.351,3.944,0.614,3.944s2.016,0.877,1.315,1.227
            c-0.702,0.35-3.156,2.454-3.594,2.717s-1.139,0.263-1.139,0.789s-0.702,1.402-0.702,1.402s-0.175,0.789-0.438,1.227
            c-0.263,0.439-1.052,2.278-1.578,2.892c-0.526,0.614-3.067,3.507-5.259,4.383c-2.191,0.876-6.836,2.015-7.45,2.191
            c-0.614,0.176-1.49,0.963-2.104,0.789c-0.614-0.175-3.944-0.964-4.207-0.526c-0.263,0.439-1.49,2.366-2.104,2.366
            s-1.841,0.264-2.629,0.351c-0.789,0.087-2.63,0.263-3.243,0.613s-0.877,1.578-1.578,1.841s-2.717,1.052-3.243,1.052
            s-1.665-1.053-2.191-0.964c-0.526,0.088-3.068,1.052-2.98,0.439s0.175-1.579-0.526-0.702s-2.366,1.666-2.717,1.753
            c-0.351,0.087-1.49,0.175-1.753,0.613c-0.263,0.439-0.438,0.966-0.876,1.14c-0.439,0.175-0.877,0.439-1.14,0.789
            s-0.789,1.051-1.14,1.226c-0.35,0.176-1.578,0.877-1.578,0.877s-0.525,0.612-0.876,0.963s-0.613,0.702-1.227,0.964
            c-0.614,0.263-2.192,0.789-2.805,1.052s-2.104,0.789-2.279,1.315s-0.789,0.964-1.052,1.49s-0.789,1.84-0.877,2.278
            c-0.087,0.439-0.35,0.963-0.876,1.315c-0.526,0.351-1.578,0.789-1.49,0.263s0.701-0.877,0.263-1.315s-0.438-1.489-0.438-2.015
            s0.439-2.629-0.175-2.366s-1.227,1.14-1.403,1.841c-0.175,0.701-0.964,3.155-1.14,3.418s-0.788,1.227-0.613,2.104
            c0.175,0.876,1.139,2.366,0.964,2.717c-0.175,0.35-0.351,0.438-0.351,1.139c0,0.702,0.439,2.98-0.175,3.418
            c-0.614,0.439-1.49,2.105-1.841,2.718s-0.789,2.542-0.877,3.155s-0.438,1.753,0,2.366s1.052,1.578,1.315,2.104
            s0.614,2.98,1.052,3.243s0.877,0.79,1.052,1.227s1.49,3.155,2.016,3.768s1.315,1.84,1.315,2.191s-0.263,2.366-0.263,2.805
            s0.176,0.964-0.175,1.315c-0.351,0.35-0.525,0.7-0.964,0.35c-0.439-0.35-1.052-0.963-1.052-1.402s-0.35-2.891-0.526-2.191
            s-1.14,1.841-0.702,2.104s0.526,0.175,0.526,0.7s0.263,1.489-0.175,1.841s-1.403,0.877-1.665,0.351s-0.788-1.578-0.876-1.841
            s-1.315-3.067-1.403-2.629s-0.088,1.489-0.526,0.7s-1.314-2.717-1.49-3.243s-0.964-2.805-1.14-2.016s0.525,2.367,0.701,2.981
            s1.578,3.506,1.928,4.031s2.016,1.753,2.366,2.542s1.753,3.068,2.104,3.681s2.542,4.82,2.454,5.609s-0.175,1.579,0,2.279
            c0,0,0.439,1.051,0.789,1.752c0.35,0.702,2.629,3.945,2.629,4.558s0.351,1.927,0.789,2.278s2.191,3.155,2.279,3.857
            s0.175,4.732,0.175,5.346s0.701,6.836,1.841,8.853c1.14,2.016,2.892,5.785,3.155,6.311s1.052,2.191,1.052,2.629
            c0,0.439,0.088,5.872-0.175,6.135s-1.227,1.491-0.964,2.192c0.263,0.7,0.877,3.154,0.789,3.856s-0.439,2.805-1.227,3.594
            c-0.789,0.789-2.104,2.104-2.629,1.578c-0.526-0.526-1.315-1.578-1.49-0.789s-0.175,4.996,0,5.785s0.438,1.84,0.789,1.752
            c0.351-0.087,0.877-0.789,1.403-0.613s4.82,3.067,4.908,3.506c0.088,0.439,0.525,1.14,0.964,1.403
            c0.439,0.263,2.016,0.789,2.366,1.052c0.351,0.263,1.753,0.789,2.279,0.876s1.928,0,2.279,0s1.928,0.087,2.366,0.087
            c0.438,0,2.717,0.964,2.98,0.701s0.438-0.614,0.964-0.439c0.526,0.176,2.016,0.789,1.928,0.263s0.262-0.701,0.613-0.701
            c0.351,0,1.227,0.176,1.403-0.263c0.175-0.439,0.613-0.527,0.964-0.614s0.614-0.526,0.702-0.876
            c0.087-0.35,0.789-0.964,1.227-1.052c0.438-0.087,1.14-0.439,1.315-0.964c0.175-0.526,0.701-1.664,1.753-1.489
            c1.052,0.175,1.928,0.614,2.191,0.526s0.614-0.613,1.052-0.789c0.438-0.176,1.314-0.263,1.227-0.702
            c-0.087-0.439,0.35-2.016,0.789-2.279c0.439-0.263,2.454-1.84,3.506-1.752c0,0,2.542,0.176,3.155-0.087s1.315-0.526,1.578-0.526
            s4.119,0,4.733-0.263s1.314-0.351,1.753-0.351s1.315,0.263,1.841,0.263s2.279-0.349,2.629,0.088
            c0.351,0.438,0.526,1.402,1.14,1.402s1.578-0.613,1.841-0.613s2.016-0.088,2.279-0.176c0.263-0.087,2.98-0.877,3.243-0.439
            s0.263,1.579,0.789,1.403c0.526-0.176,1.49-1.226,1.841-1.052c0.351,0.175,2.104-0.176,2.366-0.701
            c0.262-0.525,0.439-1.315,0.877-1.578s0.876-1.052,1.139-1.578s1.578-4.997,2.805-5.172s3.418-0.7,3.944-0.963
            s2.892-1.752,3.418-2.104c0.526-0.351,2.805-1.14,3.067-1.403s2.279-2.104,2.892-2.191c0.614-0.087,2.717,0.001,3.33,0.176
            c0.614,0.175,3.593,0.438,4.47,0.35c0.877-0.087,5.61-1.403,6.661-1.666c1.052-0.263,4.996-1.752,6.048-2.278
            s4.031-2.104,4.996-2.104c0.964,0,8.853-0.526,10.08-0.526s2.98,0.263,3.856-0.176c0.877-0.439,1.665-1.227,2.366-0.789
            c0.702,0.439,4.031,1.842,4.908,2.542c0.877,0.7,2.279,2.278,2.717,2.278s1.578,0.263,1.928,0c0.35-0.263,0.877-0.613,1.315-0.613
            s3.155,1.052,3.33,1.315s1.49,0.877,1.928,0.964s1.14,0.351,1.578,0s0.964-0.964,1.315-0.702s0.613,0.964,0.964,1.052
            c0.351,0.087,1.665,1.227,1.403,1.49s-0.876,1.314-0.263,1.226c0.613-0.087,1.753-0.7,2.104-0.35
            c0.35,0.35,1.227,1.402,1.052,1.928c-0.176,0.526-0.351,1.053-0.614,0.877s-0.789-0.44-0.877-0.088
            c-0.087,0.351-0.35,0.877-0.087,1.14s0.613,0.526,0.701,0.789s0.35,1.139,0.701,1.402s0.964,0.176,1.227,0.263
            s1.752,0.35,2.191,0.789C74.023,537.772,74.987,539.438,74.9,540.402z" pointer-events="auto" class="landarea"></path>

            <rect id="rect12_2_" x="85.098" y="461.233" opacity="0" fill="#f3f3f3" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
        <path fill="#f3f3f3" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M148.348,600.878
            c-0.438-0.35-0.789-1.053-1.052-0.702c-0.263,0.351-0.526,1.139-0.526,1.578s-0.438,3.77,0.088,3.594s1.928-0.964,1.841-1.49
            c-0.088-0.526-0.175-0.702-0.175-1.315S148.348,600.878,148.348,600.878z" pointer-events="auto" class="landarea"></path>
        <path fill="#f3f3f3" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M93.481,560.385
            c-0.438,0.087-2.454,0.612-2.892,0.701c-0.438,0.088-1.402,0.001-1.753,0.614s-0.789,1.314-0.439,1.489s0.877,1.315,1.403,1.315
            s1.578-0.263,2.191-0.263s1.14-0.526,1.578-0.526s1.226,0.789,1.665,0.789s1.315,0,1.403-0.35s0.175-1.053,0.701-1.227
            c0.526-0.175,1.403-0.175,1.753-0.087s0.613,0.439,1.139,0.176s0.789-0.527,0.526-0.877s-1.051-0.7-1.402-0.613
            c-0.351,0.087-0.877,0.35-1.227,0.263s-1.139-0.175-1.139-0.613c0-0.439,0.175-1.053-0.439-1.14s-1.227-0.263-1.578-0.087
            C94.621,560.122,93.481,560.385,93.481,560.385z" pointer-events="auto" class="landarea"></path>
        <path fill="#f3f3f3" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M180.163,602.455
            c-0.526-0.613-0.876-1.139-1.402-0.876s-1.315,1.14-0.964,1.315s0.526,0.612,0.702,0.963c0.175,0.351,1.402,2.981,1.753,2.981
            c0.351,0,0.789-0.264,1.14-0.351c0.35-0.087,0.613-0.438,0.438-0.876c-0.175-0.439,0.175-1.227-0.088-1.49
            S180.163,602.455,180.163,602.455z" pointer-events="auto" class="landarea"></path>
        <path fill="#f3f3f3" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M172.889,638.828
            c-0.301-0.2-0.526-0.613-0.877-0.701c-0.351-0.087-0.175,0.789-0.087,1.226c0.088,0.438-0.263,0.702-0.439,1.14
            c-0.176,0.439-0.263,0.789-0.526,1.052s-0.35,1.226-0.35,1.226s-1.052,0-1.315-0.263s-0.789-0.526-1.052-1.052
            s-0.964,0.176-1.227,0.176s-1.402-0.087-1.665-0.176c-0.263-0.088-1.14,0.263-1.49,0.263c-0.351,0-0.35-1.052-0.35-1.489
            c0-0.438,0.35-0.176,0.876-0.351s-0.263-0.438-0.263-0.438s-0.964-0.614-1.227-0.877s0,0-0.438,0.439s-1.578-2.279-1.665-2.717
            c-0.088-0.438-0.614-0.702-0.964-0.614c-0.35,0.087-0.526-1.052-0.614-1.489c-0.088-0.438-1.403-2.805-1.49-3.331
            s-0.439-2.016-0.439-2.717s0.702,0.438,1.14,0.963c0.438,0.526,0.701,0.701,0.877,1.052c0.176,0.35,0.35-0.613,0.35-1.139
            s-0.964-1.139-1.227-1.578s-0.263-0.439-0.614-0.35c-0.351,0.088-0.35-1.403-0.35-1.666s-1.841-2.278-2.191-2.804
            c-0.351-0.526-0.614-1.578-0.789-2.104s-0.789-1.841-0.789-2.455s-0.877-2.717-0.964-3.243s0.087-0.965,0.35-1.402
            c0.263-0.438,0.088-1.403,0.088-1.753s0.789,0,1.14,0.087c0,0,0.702,0.177,1.052,0.351c0.35,0.175,0.526,0.175,0.964,0.35
            c0.438,0.176,1.84,0.439,2.542,0.526s1.403,0.001,1.753,0.439c0.35,0.438,1.578,1.315,2.104,1.578s4.207,1.489,4.557,1.489
            c0.351,0,1.14-1.139,1.49-0.876s1.314,0.876,1.402,0.526s-0.351-0.964,0.263-1.227s2.191-0.087,2.629-0.35
            c0.439-0.263,0.701-0.614,1.052-0.439c0.351,0.176,0.526,0.527,0.964,0.351c0.438-0.176,1.226-1.491,1.665-1.403
            s0.964,0.438,1.315,0.263s1.315-1.314,1.578-1.226s1.578,0.789,1.753,1.139s0.438,0.613,0.438,1.139s-0.263,2.015-0.088,2.366
            s0.351,0.789,0.351,1.227c0,0.439-0.263,0.876-0.263,1.139s-0.176,3.682-0.088,4.032s0.263,0.701,0.263,1.052
            c0,0.35-0.087,1.226-0.087,1.226s0.263,0.527,0.263,0.877c0,0.35-0.263,1.49-0.526,0.789s-0.789-1.227-1.14-0.964
            s-0.877,1.753-0.877,2.016s0.176,1.314,0.088,1.752c-0.088,0.439-0.789,0.526-0.789,0.964c0,0.439,0.176,1.402,0.088,1.841
            s-0.702,1.753-0.964,1.841c-0.263,0.087-1.139,0.087-1.139,0.087s-0.877-0.701-0.877-0.35c0,0.35-0.087,1.752-0.438,1.665
            c-0.351-0.087-1.403-1.402-1.49-0.876s-0.175,1.051-0.35,1.665C173.59,637.338,173.152,639.002,172.889,638.828z" pointer-events="auto" class="landarea"></path>
    </g>
    <g id="AREA_NEWZEALAND" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="New Zealand" data-wikipediaLink="https://en.wikipedia.org/wiki/New_Zealand" data-infoText="<b>New Zealand</b> &amp;nbsp; Capital: Wellington" data-imageFile="NEWZEALAND.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
        <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" cx="371.058" cy="546.448" r="0.372" pointer-events="auto" class="landarea"></circle>
        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M417.581,592.231
            c-0.156,0.31-0.388,1.011-0.155,1.555s0.7,1.01,0.7,1.243s-0.855,1.089-1.088,1.011s-0.156-0.933-0.078-1.166
            c0.078-0.233-0.621-0.544-0.933-0.544s-1.71,0-2.254,0s-2.643,1.089-2.954,1.399s-1.321,2.799-1.166,3.342
            c0.155,0.543,0.933,1.399,1.244,1.632c0.311,0.233-0.155,1.477-0.466,1.943s-1.011,2.253-1.088,2.643
            c-0.077,0.389-1.011,1.321-1.399,1.632c-0.388,0.311-0.544,1.321-0.622,1.865s-0.699,0.933-1.166,1.166
            c-0.466,0.233-1.477,2.098-1.787,2.72s-1.865,3.653-2.177,4.042c-0.311,0.388-1.554,1.632-2.02,1.943s-3.342,2.332-3.887,2.098
            c-0.544-0.233-0.699-1.477-0.855-1.787c-0.155-0.31-1.477-0.233-2.021-0.233s-0.777-1.71-0.7-2.021s0.389-1.011,0.622-1.244
            c0.233-0.233,0.233-0.933,0.31-1.243s1.477-2.098,1.71-2.488c0.233-0.389,0.855-3.264,0.855-4.43s-1.321-3.186-1.865-3.576
            c-0.544-0.389-2.254-0.855-2.798-1.088c-0.544-0.233-1.865-1.244-2.72-1.943s-2.643-1.088-3.187-1.321s-1.088-1.71-1.166-2.254
            c-0.078-0.543,1.399-2.177,1.943-2.488s2.253-0.932,2.798-0.932c0.544,0,1.477-1.477,1.787-1.943s0.311-2.876,0.311-3.186
            s0.155-0.777,0.388-1.166c0.233-0.388,0.233-1.477,0.233-1.943s0.777-0.388,1.011-0.388c0.233,0-0.155-1.71-0.233-2.021
            s0.233-0.778,0.466-1.088s0.078-0.622-0.233-1.011c-0.311-0.388-0.778-2.332-0.933-2.72s0-0.622-0.078-0.933
            c-0.078-0.31-0.855-2.021-1.088-2.332c-0.233-0.31,0.466-0.699,0.466-0.699s0.311,0.699,0.544,1.088
            c0.233,0.388,0.543-0.621,0.933-1.011c0.389-0.389-0.311-0.621-0.311-0.621s-0.621-0.466-1.011-0.544
            c-0.389-0.078-0.855,0.544-1.088,0.699c-0.233,0.155-0.544-1.01-0.621-1.244s-0.622-1.787-0.699-2.098
            c-0.077-0.311-1.244-1.554-1.477-1.865s0.078-0.466,0.544-0.466s1.088,1.011,1.476,1.322c0.388,0.311,0.078-1.555-0.077-1.865
            s-0.622-0.233-1.166-0.466c-0.543-0.233,0.233-0.778,0.077-1.089s-0.777-0.233-1.321-0.31s0.155,1.166,0.233,1.476
            s-0.621,0-0.933-0.233c-0.311-0.233-0.933-1.399-1.244-1.942c-0.311-0.543-3.652-5.441-3.886-5.83
            c-0.233-0.389-1.089-1.709-1.244-2.02s-0.621-0.778-0.933-1.166c-0.311-0.388,0.233-0.388,0.777-0.778
            c0.544-0.389-1.477-2.953-1.865-3.419s-2.021-2.254-2.098-2.798c-0.077-0.543,1.632-0.622,2.175-0.622s0.389,0.699,0.078,1.322
            c-0.311,0.622,0.233,0.855,0.699,1.321c0,0,0.234,0.31,0.544,0.699s0.777,0.467,0.777,0.856c0,0.388-0.233,1.088,0.233,1.088
            s0.7-0.156,0.7-0.544s0.777-0.777,0.777-0.388c0,0.388-0.311,0.855-0.078,1.011c0.233,0.155,0.234,0.31,0.7,0.077
            s0.933-0.62,1.088-0.31s0.778,0.778,1.011,0.7c0.233-0.078,0.387-0.155,0.777,0c0.389,0.155,1.322,0.622,1.322,0.933
            c0,0.31-0.234,0.777,0.077,1.088s0.779,0.621,1.089,0.466s0.621-0.544,0.855-0.311c0.233,0.233,0.544,0.855,0.777,1.399
            s0.933,1.477,0.933,1.71s-0.077,0.389,0,0.777s0.465,1.632,0.077,1.632s-1.32-0.467-1.01,0.155s0.387,0.701,0.543,1.089
            s0.856,1.321,1.011,1.554c0.155,0.233,1.244,1.243,1.399,1.787s0.467,1.322,0.078,1.477c-0.389,0.155-0.933,1.089-0.7,1.244
            s0.778,0.311,0.7,0.699s-0.233,1.089-0.078,1.477s1.01,1.553,1.399,1.476c0.389-0.077,0.777-0.155,1.399,0.078
            c0.622,0.233,1.088,0.544,1.166,0.933c0.078,0.388,1.089,2.487,1.555,1.865c0.466-0.621,0.466-1.71,0.466-1.71
            s-0.855-1.244-0.7-1.865s0.155-1.011,0.155-1.399s-0.077-0.699-0.388-1.088c-0.311-0.388-0.855-1.476-0.078-1.166
            c0.778,0.31,1.165,0.778,1.321,1.166s0.155,1.088,0.699,1.088s0.544,0,0.544,0s1.088,0.156,0.621,0.389
            c-0.466,0.233-1.089,1.088-0.699,1.088s0.933-0.078,1.011,0.388c0.078,0.466,0.078,2.954,0.466,3.575
            c0.388,0.621,0.544,1.011,0.544,1.632s-0.466,1.711,0.155,2.021s3.886,1.631,4.43,1.942c0.544,0.311,3.73,0.856,4.042,1.322
            c0.311,0.466,0.778,0.854,1.166,0.776c0.388-0.077,2.565-0.778,2.876-1.166s1.321-2.175,2.02-2.487
            c0.699-0.311,1.942-1.322,2.332-1.322s1.4,0.233,1.788,0.466c0.388,0.233,2.019,1.4,1.709,1.633s-0.466,0.387-0.7,0.777
            c-0.233,0.389-0.776,3.498-0.776,3.887c0,0.388,0.233,1.942,0,2.254c-0.233,0.311-1.555,2.488-1.943,2.488s-1.166,0-1.166,0.233
            C417.892,590.989,417.581,592.231,417.581,592.231z" pointer-events="auto" class="landarea"></path>
        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M336.442,673.68
            c0.155,0.466,2.332-0.155,2.876-0.155s0.855,0.466,1.244,0.778c0.388,0.311,0.388,0.544,0.155,0.855
            c-0.233,0.31,0.156,0.699,0.466,0.777s1.166,0.155,1.555,0.078s1.632-0.078,1.865-0.078s0.699,0.544,0.933,0.855
            c0.233,0.31,0.777,0.233,1.321,0.078c0.544-0.155,5.363-1.71,5.829-2.02s0.466-1.322,0.544-1.788s2.02-1.477,2.41-1.943
            c0.389-0.466,0.621-1.166,0.777-1.632c0.155-0.466,1.011-1.01,1.399-1.243s1.555-0.389,1.865-0.466s0.233-1.166-0.155-1.477
            s-0.311-0.466-0.078-0.777s1.166-1.631,1.399-2.019s0.311-2.021,0.311-2.41c0-0.388,2.253-3.575,2.565-4.119
            s0.077-2.099,0.077-2.566c0-0.466-0.077-0.855,0.078-1.243s0.621-1.865,0.933-3.031s8.316-4.897,8.627-4.974
            c0.31-0.077,1.632-0.233,2.098-0.233s1.011,0.31,1.399,0.621s0.856,0,1.244-0.155s0.778-0.466,0.855-0.856
            c0.077-0.389-0.155-1.166-0.388-1.321c-0.233-0.155-1.088-0.388-1.477-0.388s-0.699-0.311-1.088-0.778
            c-0.388-0.466,0.233-3.497,0.544-4.042c0.311-0.544,1.321-0.855,1.709-1.01s1.166-0.856,1.866-1.71
            c0.7-0.855,2.019-3.886,2.252-4.274s0.699-1.088,1.166-1.477c0.466-0.389,0.466-0.543,0.622-0.777
            c0.156-0.233,1.321-1.477,1.321-1.477s0.544-1.011,0.544-1.399s1.942-2.876,2.175-3.186s0-0.466-0.31-0.778
            c-0.31-0.311-0.389-1.477-0.389-1.943s-0.543-0.621-0.7-1.088c-0.156-0.466,0.544-0.933,0.856-0.933s0.466-0.389,0.621-0.699
            s-1.011-0.233-1.477-0.156c-0.466,0.077-0.699-0.077-0.621-0.543s0.621-0.311,1.011-0.389c0.389-0.078,0.7-0.466,0.855-0.855
            c0.155-0.388-0.466-0.621-0.855-0.621c-0.388,0-0.389,0.31-0.389,0.699s-0.233,0.388-0.699,0.543s-0.7-0.233-0.621-0.543
            c0.078-0.31-0.388-0.311-0.621-0.389s0.155-0.466,0.31-1.088c0.155-0.621,0,0-0.388,0.078s-0.699,0.543-0.933,0.933
            c-0.233,0.389-0.621,0.388-1.011,0.388s-1.088,1.088-1.476,1.166s-1.477,1.088-1.865,1.166s-1.011-2.487-1.011-3.031
            s0.233-1.088,0.155-1.787s-1.321-0.233-1.71-0.233c-0.389,0-0.543-0.622-0.933-1.244c-0.389-0.621,0.078-0.777,0.156-1.166
            c0.078-0.388,0.621-0.311,1.321-0.389s-0.311-0.466-0.933-0.466c-0.621,0-1.088,0.311-1.244,0.699
            c-0.156,0.388-0.699,0.466-1.321,0.544c-0.621,0.078-1.477,1.322-1.943,1.865c0,0-0.775,1.243-0.775,1.709
            s-0.155,4.508-0.778,5.207s-2.254,2.875-2.643,2.875c-0.388,0-1.321-0.001-1.554,0.466c-0.233,0.467-1.399,4.974-1.71,5.519
            s-2.021,4.196-2.41,4.585c-0.388,0.389-1.71,1.866-2.176,2.254s-1.71,1.088-2.098,1.321s-1.399,1.245-1.787,1.555
            s-1.554,1.787-2.098,2.02c-0.544,0.233-0.932,0.621-1.321,0.933c-0.389,0.311-1.555,1.477-1.943,1.632s-3.264,1.711-3.575,2.021
            s-2.098,2.097-2.565,2.175c-0.466,0.078-1.943,0.077-2.332,0.233c-0.388,0.156-1.322,0.855-1.71,1.166
            c-0.388,0.311-0.777,1.089-1.01,1.477s-0.388,0.777-0.778,1.088s-1.32,0.699-1.476,1.166c-0.156,0.466,0.777,2.099,0.31,1.943
            c-0.466-0.156-0.933-0.544-1.166-0.311c-0.233,0.233-0.388,0.621-0.855,0.933c-0.466,0.311-1.399,1.088-1.399,1.088
            s-0.466,0.466-0.466,0.778s-0.156,1.321-0.311,1.088s-0.543-0.621-0.855-0.388c-0.311,0.233-0.856,0.543-0.544,0.855
            s0.466,0.778,0,0.778s-0.621,0.001-0.855,0.311c-0.233,0.31-0.934,0.932-0.622,1.243c0.311,0.311,1.322,0.855,1.089,0.933
            s-0.7,0.155-0.466,0.466c0.233,0.311,0.543,1.244,0.31,1.477s-0.543,0.001-0.777-0.388c-0.233-0.389-0.467-1.166-0.855-1.166
            s-0.778,0.31-0.933,0.621s-1.01,2.021-0.621,2.021c0.388,0,1.4,0.155,1.011,0.388s-1.166,0.699-0.778,0.933
            c0.388,0.233,1.787,1.011,1.321,1.011c-0.466,0-3.652,0.622-3.652,1.166s-0.078,1.632,0.233,1.554s1.633-1.321,1.477-0.855
            s-0.621,1.321-0.233,1.088s1.398-0.466,1.243-0.233c-0.155,0.233-1.01,1.011-1.01,1.399s0.156,0.699,0.699,0.933
            c0.543,0.233,2.177,0.311,2.798,0.466s1.166,0.621,1.632,0.233s0.778-1.166,1.166-1.166c0.388,0,1.399,0.389,1.554,0.778
            C335.976,672.204,336.442,673.68,336.442,673.68z" pointer-events="auto" class="landarea"></path>
        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M338.619,678.111
            c-0.233-0.389-0.466-0.779-0.933-0.855c-0.466-0.077-1.554,0.155-1.399,0.699c0.155,0.544,0.311,1.089,0.311,1.477
            s-0.078,1.399-0.311,1.632c-0.233,0.233-0.622,0.156-0.777,0.544s-0.31,0.698-0.543,1.01s-0.934,1.166-0.389,1.244
            c0.544,0.078,0.933,0.156,0.933-0.155s0.079-0.933,0.622-0.933s1.088,0.078,1.554,0c0.466-0.078,2.021-0.466,2.176-0.777
            s0.465-1.165,0.155-1.321s-1.088-0.233-1.399-0.466c-0.311-0.233-0.621-0.7-0.155-0.7s1.01,0.311,1.088,0s0-0.543-0.233-0.699
            C339.085,678.654,338.619,678.111,338.619,678.111z" pointer-events="auto" class="landarea"></path>
        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M462.167,643.868
            c-0.789,0-2.892,0.789-2.104,0.789s1.709,0.526,1.446,0.92s-0.789,0.789-0.657,1.315s0.263,1.315,0.789,1.052
            s1.709-0.263,1.578-0.92s-0.526-1.578,0.131-1.972s1.315-1.052,0.657-1.052S462.167,643.868,462.167,643.868z" pointer-events="auto" class="landarea"></path>
        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M464.796,649.39c0,0-0.657-0.131-0.657,0.263
            s0.526,1.183,0.789,0.789S464.796,649.39,464.796,649.39z" pointer-events="auto" class="landarea"></path>

            <rect id="rect12_3_" x="342.098" y="627.233" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>

            <ellipse fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" cx="385.596" cy="617.5" rx="95.371" ry="88.5" pointer-events="auto" opacity="0.001" class="clickarea landarea"></ellipse>
    </g>
    </svg>
}