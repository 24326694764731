export default function Poland() {
    return <svg version="1.1" id="svgpoint" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 900 700" enable-background="new 0 0 900 700" >
<rect id="BACKGROUND" x="2.354" y="1.724" fill="#deded7" width="900" height="700" stroke="#deded7" class="background"></rect>
<rect id="SVGHUD" x="-37.646" y="-289.276" fill="#FFFFFF" stroke="#000000" stroke-width="6" stroke-miterlimit="10" width="106" height="26"></rect>
<g id="AREA_POLAND2_LODZKIE" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Łódź" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_POLAND2_LODZKIE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#7eb08e" d="M367.048,441.97c-0.094-0.309-0.123-0.715-0.153-0.935c-0.052-0.385,0.12-1.185,0-1.553
        c-0.15-0.458-0.894-1.137-1.133-1.554c-0.202-0.352-0.667-1.084-0.648-1.49c0.021-0.416,0.636-1.078,0.81-1.456
        c0.176-0.384,0.482-1.175,0.582-1.586c0.092-0.381,0.26-1.169,0.194-1.554c-0.074-0.428-0.408-1.326-0.776-1.554
        c-0.345-0.213-1.216-0.038-1.619,0c-0.435,0.04-1.295,0.442-1.716,0.324c-0.474-0.134-1.296-0.862-1.457-1.327
        c-0.186-0.544,0.415-1.692,0.388-2.267c-0.03-0.66-0.32-2.002-0.68-2.557c-0.147-0.227-0.71-0.442-0.841-0.679
        c-0.194-0.35-0.028-1.222-0.194-1.586c-0.146-0.319-0.814-0.71-0.939-1.037c-0.105-0.272-0.138-0.907,0-1.165
        c0.151-0.282,0.761-0.62,1.068-0.712c0.263-0.08,0.827,0.035,1.1,0c0.349-0.044,1.115-0.105,1.359-0.356
        c0.198-0.201,0.123-0.853,0.26-1.1c0.245-0.447,0.938-1.307,1.425-1.457c0.473-0.146,1.452,0.464,1.942,0.389
        c0.529-0.083,1.577-0.591,1.877-1.037c0.338-0.5,0.309-1.792,0.291-2.395c-0.015-0.542-0.389-1.593-0.388-2.136
        c0-0.221,0.033-0.692,0.162-0.874c0.189-0.267,0.817-0.564,1.132-0.647c0.36-0.097,1.125-0.073,1.489,0
        c0.558,0.111,1.553,0.727,2.103,0.874c0.363,0.097,1.164,0.007,1.49,0.194c0.274,0.157,0.603,0.737,0.777,1.003
        c0.176,0.27,0.404,0.889,0.614,1.133c0.258,0.296,0.873,0.883,1.263,0.938c0.324,0.046,1-0.22,1.23-0.453
        c0.259-0.263,0.387-1.037,0.486-1.392c0.157-0.566,0.195-1.787,0.486-2.297c0.293-0.519,1.35-1.177,1.747-1.619
        c0.289-0.322,0.899-0.979,1.036-1.392c0.216-0.653,0.079-2.067,0-2.752c-0.073-0.635-0.567-1.853-0.583-2.492
        c-0.01-0.411,0.25-1.209,0.292-1.618c0.038-0.37,0.102-1.123,0.033-1.489c-0.059-0.311-0.488-0.85-0.486-1.165
        c0.002-0.476,0.591-1.299,0.744-1.748c0.083-0.246,0.254-0.744,0.259-1.004c0.006-0.339-0.048-1.088-0.291-1.327
        c-0.265-0.26-1.304,0.047-1.456-0.29c-0.119-0.266,0.545-0.717,0.582-1.005c0.047-0.365-0.34-1.058-0.388-1.424
        c-0.031-0.233-0.166-0.772,0-0.939c0.178-0.178,0.775,0.104,1.004,0c0.188-0.084,0.454-0.431,0.55-0.614
        c0.16-0.302,0.392-0.992,0.324-1.328c-0.07-0.346-0.652-0.835-0.841-1.133c-0.128-0.2-0.436-0.602-0.453-0.841
        c-0.048-0.644,0.5-1.901,0.874-2.427c0.314-0.441,1.232-1.06,1.683-1.36c0.299-0.198,1.152-0.339,1.262-0.679
        c0.128-0.392-0.71-1.077-0.712-1.49c-0.001-0.267,0.285-0.762,0.454-0.97c0.178-0.219,0.821-0.409,0.906-0.68
        c0.117-0.372-0.485-1.097-0.485-1.489c-0.001-0.399,0.41-1.128,0.485-1.521c0.084-0.43,0.231-1.321,0.129-1.747
        c-0.065-0.275-0.579-0.688-0.582-0.971c-0.006-0.54,0.834-1.39,1.068-1.877c0.247-0.51,0.527-1.633,0.842-2.104
        c0.277-0.415,1.154-0.974,1.425-1.392c0.178-0.274,0.215-1.005,0.453-1.23c0.265-0.249,1.028-0.426,1.392-0.421
        c0.307,0.004,0.957,0.162,1.165,0.388c0.253,0.275,0.054,1.237,0.355,1.457c0.282,0.205,1.061,0.013,1.393-0.098
        c0.328-0.109,0.836-0.637,1.165-0.744c0.47-0.154,1.48-0.143,1.975-0.129c0.53,0.016,1.642-0.001,2.104,0.26
        c0.341,0.192,0.626,1.044,0.971,1.229c0.25,0.135,0.849,0.103,1.132,0.097c0.513-0.009,1.585-0.021,2.039-0.258
        c0.656-0.344,1.428-1.704,1.976-2.202c0.595-0.541,2.05-1.313,2.589-1.91c0.448-0.495,0.93-1.788,1.359-2.297
        c0.267-0.316,1.009-0.742,1.263-1.068c0.282-0.362,0.754-1.195,0.81-1.651c0.119-1.004-0.241-3.161-0.874-3.949
        c-0.314-0.39-1.44-0.509-1.813-0.842c-0.22-0.195-0.472-0.746-0.615-1.003c-0.176-0.317-0.629-0.933-0.648-1.294
        c-0.022-0.432,0.594-1.187,0.615-1.619c0.009-0.185-0.122-0.542-0.194-0.712c-0.105-0.251-0.504-0.671-0.55-0.939
        c-0.084-0.496,0.385-1.471,0.389-1.974c0.002-0.312-0.112-0.939-0.228-1.231c-0.136-0.348-0.665-0.916-0.809-1.262
        c-0.201-0.482-0.445-1.517-0.453-2.04c-0.006-0.395,0.065-1.229,0.291-1.553c0.253-0.363,1.106-0.754,1.521-0.906
        c0.416-0.153,1.313-0.378,1.748-0.291c0.346,0.068,0.861,0.639,1.197,0.743c0.457,0.144,1.435,0.048,1.91,0.097
        c0.317,0.033,0.963,0.086,1.262,0.194c0.178,0.065,0.462,0.355,0.648,0.388c0.219,0.04,0.713-0.007,0.874-0.161
        c0.234-0.224,0.173-0.961,0.291-1.262c0.143-0.365,0.414-1.214,0.777-1.359c0.344-0.139,1.055,0.374,1.424,0.42
        c0.571,0.07,1.795,0.183,2.298-0.097c0.377-0.209,0.712-1.08,0.971-1.425c0.222-0.293,0.727-0.831,0.939-1.132
        c0.247-0.353,0.761-1.073,0.874-1.489c0.136-0.5,0.233-1.609,0-2.071c-0.155-0.305-0.776-0.703-1.102-0.81
        c-0.299-0.099-1.01,0.192-1.262,0c-0.268-0.203-0.311-0.962-0.355-1.295c-0.045-0.328-0.079-1.004,0-1.326
        c0.082-0.345,0.489-0.945,0.647-1.263c0.176-0.354,0.576-1.044,0.679-1.425c0.118-0.431-0.168-1.479,0.162-1.78
        c0.322-0.295,1.357,0.195,1.748,0c0.372-0.186,0.646-1.116,1.003-1.327c0.387-0.23,1.332-0.227,1.781-0.26
        c0.621-0.044,1.877-0.097,2.492,0c0.387,0.062,1.149,0.291,1.489,0.486c0.318,0.183,0.78,0.792,1.1,0.971
        c0.244,0.136,0.808,0.423,1.068,0.324c0.204-0.078,0.409-0.531,0.453-0.745c0.075-0.359-0.103-1.1-0.194-1.457
        c-0.067-0.26-0.467-0.745-0.388-1.003c0.077-0.256,0.657-0.459,0.874-0.616c0.335-0.239,1.167-0.592,1.327-0.971
        c0.2-0.474-0.005-1.611-0.291-2.039c-0.141-0.211-0.651-0.396-0.874-0.518c-0.301-0.165-0.887-0.592-1.229-0.615
        c-0.197-0.012-0.564,0.18-0.745,0.258c-0.328,0.145-0.907,0.724-1.263,0.68c-0.347-0.042-0.933-0.604-1.036-0.938
        c-0.11-0.358,0.261-1.094,0.356-1.457c0.121-0.463,0.423-1.376,0.517-1.845c0.101-0.496,0.141-1.516,0.26-2.008
        c0.039-0.163,0.09-0.395,0.154-0.639c0.11-0.418,0.26-0.871,0.46-1.077c0.316-0.322,1.259-0.497,1.684-0.647
        c0.552-0.194,1.658-0.823,2.233-0.713c0.349,0.068,0.763,0.79,1.1,0.907c0.405,0.141,1.302,0.081,1.716-0.033
        c0.422-0.116,1.125-0.684,1.521-0.874c0.298-0.142,0.903-0.534,1.23-0.485c0.256,0.039,0.603,0.49,0.809,0.648
        c0.295,0.226,0.837,0.958,1.199,0.874c0.303-0.07,0.506-0.799,0.582-1.1c0.093-0.377-0.113-1.182,0-1.553
        c0.135-0.448,0.7-1.229,1.035-1.554c0.274-0.266,1.035-0.53,1.296-0.81c0.293-0.315,0.439-1.299,0.809-1.521
        c0.229-0.138,0.806-0.054,1.068,0c0.048,0.009,0.109,0.024,0.175,0.043c0.236,0.066,0.544,0.182,0.667,0.312
        c0.182,0.194,0.415,0.744,0.355,1.004c-0.06,0.267-0.671,0.522-0.777,0.776c-0.112,0.27-0.175,0.932,0,1.165
        c0.178,0.235,0.862,0.208,1.133,0.323c0.327,0.139,1.052,0.404,1.23,0.712c0.174,0.302-0.11,1.062,0,1.393
        c0.153,0.461,0.77,1.271,1.165,1.553c0.256,0.183,0.891,0.319,1.198,0.389c0.589,0.134,1.792,0.311,2.395,0.323
        c0.601,0.012,1.811-0.088,2.395-0.226c0.152-0.036,0.428-0.25,0.582-0.227c0.274,0.04,0.622,0.564,0.874,0.68
        c0.256,0.117,0.876,0.058,1.102,0.227c0.277,0.209,0.51,0.91,0.647,1.23c0.161,0.376,0.269,1.229,0.518,1.553
        c0.242,0.315,1.079,0.564,1.326,0.874c0.26,0.324,0.245,1.313,0.583,1.553c0.501,0.357,1.845,0.102,2.46,0.065
        c0.384-0.023,1.142-0.291,1.52-0.226c0.461,0.079,1.361,0.508,1.652,0.874c0.218,0.275,0.311,1.011,0.355,1.359
        c0.045,0.345-0.212,1.116,0,1.392c0.195,0.251,0.913,0.304,1.23,0.323c0.367,0.023,1.122-0.045,1.457-0.194
        c0.33-0.147,0.879-0.653,1.1-0.938c0.17-0.221,0.265-0.834,0.486-1.004c0.282-0.215,1.064-0.393,1.392-0.258
        c0.264,0.109,0.421,0.766,0.648,0.938c0.423,0.319,1.477,0.697,2.007,0.68c0.445-0.013,1.3-0.403,1.651-0.68
        c0.478-0.377,0.871-1.721,1.424-1.974c0.427-0.196,1.413,0.084,1.878,0.162c0.592,0.099,1.733,0.643,2.331,0.583
        c0.464-0.048,1.454-0.402,1.682-0.81c0.199-0.354-0.228-1.217-0.162-1.618c0.071-0.422,0.367-1.303,0.713-1.554
        c0.343-0.25,1.266-0.273,1.682-0.194c0.505,0.096,1.382,0.691,1.814,0.971c0.386,0.252,1.186,0.758,1.456,1.132
        c0.17,0.237,0.179,0.897,0.389,1.101c0.188,0.184,0.785,0.175,1.003,0.324c0.19,0.129,0.394,0.581,0.582,0.712
        c0.28,0.196,0.975,0.327,1.294,0.453c0.485,0.192,1.448,0.597,1.91,0.842c0.337,0.178,0.942,0.661,1.293,0.809
        c0.293,0.121,0.979,0.1,1.231,0.291c0.279,0.213,0.592,0.89,0.679,1.231c0.075,0.289,0.064,0.905,0,1.197
        c-0.087,0.4-0.622,1.084-0.679,1.489c-0.053,0.381,0.091,1.161,0.226,1.521c0.142,0.379,0.665,1.014,0.874,1.359
        c0.298,0.494,0.91,1.476,1.133,2.007c0.147,0.35,0.186,1.159,0.421,1.457c0.266,0.335,1.218,0.51,1.49,0.841
        c0.296,0.362,0.119,1.712,0.582,1.781c0.292,0.044,0.44-0.797,0.679-0.97c0.315-0.229,1.125-0.594,1.489-0.454
        c0.325,0.125,0.412,1.02,0.712,1.197c0.23,0.136,0.856-0.128,1.068,0.033c0.237,0.179,0.153,0.918,0.356,1.132
        c0.303,0.319,1.212,0.566,1.651,0.616c0.422,0.046,1.261-0.263,1.683-0.227c0.533,0.046,1.641,0.291,2.039,0.648
        c0.416,0.372,0.967,1.485,0.907,2.039c-0.048,0.449-0.919,1.03-1.068,1.457c-0.186,0.527,0.09,1.707-0.098,2.233
        c-0.178,0.496-0.812,1.412-1.262,1.683c-0.401,0.241-1.426,0.087-1.845,0.292c-0.418,0.202-1.225,0.818-1.359,1.262
        c-0.112,0.367,0.074,1.231,0.355,1.49c0.199,0.183,0.818,0.059,1.068,0.161c0.435,0.178,1.09,0.917,1.521,1.101
        c0.334,0.142,1.064,0.221,1.425,0.258c0.29,0.031,0.925-0.131,1.165,0.033c0.239,0.163,0.331,0.805,0.453,1.068
        c0.135,0.288,0.507,0.823,0.582,1.132c0.092,0.377,0.023,1.166,0,1.553c-0.021,0.35,0.074,1.132-0.161,1.393
        c-0.329,0.362-1.491,0.169-1.91,0.421c-0.384,0.23-1.026,0.934-1.165,1.359c-0.142,0.431-0.128,1.438,0.129,1.813
        c0.218,0.316,1.021,0.549,1.392,0.647c0.377,0.101,1.189,0.268,1.554,0.13c0.326-0.123,0.678-0.813,0.97-1.004
        c0.137-0.09,0.453-0.202,0.615-0.226c0.249-0.037,0.792-0.072,1.003,0.065c0.261,0.169,0.665,0.8,0.583,1.1
        c-0.065,0.239-0.754,0.286-0.841,0.517c-0.047,0.127,0.072,0.417,0.162,0.519c0.171,0.192,0.682,0.433,0.939,0.421
        c0.271-0.015,0.671-0.499,0.939-0.551c0.224-0.043,0.683,0.079,0.906,0.13c0.256,0.059,0.759,0.423,1.003,0.323
        c0.339-0.137,0.392-1.069,0.679-1.294c0.264-0.207,1.054-0.093,1.295-0.324c0.164-0.158,0.095-0.715,0.259-0.875
        c0.205-0.197,0.821-0.344,1.101-0.291c0.256,0.048,0.592,0.538,0.841,0.616c0.302,0.094,0.972,0.092,1.263-0.034
        c0.304-0.131,0.626-0.826,0.939-0.938c0.475-0.17,1.54,0.004,2.007,0.194c0.49,0.199,1.177,1.09,1.651,1.327
        c0.403,0.202,1.441,0.12,1.747,0.453c0.284,0.307,0.243,1.234,0.292,1.651c0.049,0.443-0.134,1.399,0.097,1.78
        c0.155,0.259,0.742,0.545,1.035,0.615c0.348,0.083,1.076-0.021,1.425-0.097c0.423-0.094,1.192-0.545,1.619-0.615
        c0.479-0.08,1.504-0.147,1.942,0.064c0.62,0.3,1.374,1.548,1.78,2.105c0.27,0.37,0.651,1.226,0.97,1.553
        c0.372,0.38,1.333,0.879,1.781,1.166c0.37,0.238,1.246,0.571,1.489,0.938c0.171,0.26,0.334,0.953,0.195,1.23
        c-0.128,0.252-0.722,0.511-1.004,0.517c-0.383,0.009-1.008-0.664-1.392-0.647c-0.224,0.01-0.665,0.259-0.776,0.452
        c-0.105,0.184-0.055,0.64,0,0.842c0.079,0.291,0.489,0.763,0.614,1.037c0.171,0.372,0.492,1.144,0.519,1.553
        c0.021,0.338-0.201,0.995-0.261,1.327c-0.107,0.605-0.45,1.817-0.387,2.428c0.041,0.405,0.298,1.229,0.583,1.521
        c0.231,0.24,0.906,0.433,1.23,0.517c0.472,0.126,1.548-0.063,1.942,0.228c0.325,0.242,0.389,1.175,0.647,1.488
        c0.163,0.198,0.737,0.35,0.841,0.583c0.156,0.341-0.043,1.132-0.162,1.488c-0.16,0.49-0.933,1.277-1.035,1.781
        c-0.103,0.504,0.405,1.546,0.259,2.04c-0.078,0.265-0.531,0.634-0.713,0.841c-0.158,0.182-0.517,0.509-0.648,0.713
        c-0.168,0.266-0.229,0.978-0.486,1.165c-0.3,0.22-1.12,0.185-1.488,0.129c-0.682-0.104-1.885-0.884-2.557-1.037
        c-0.513-0.116-1.582-0.063-2.104-0.129c-0.515-0.065-1.588-0.132-2.04-0.388c-0.359-0.202-0.735-1.04-1.1-1.23
        c-0.296-0.155-0.997-0.109-1.328-0.161c-0.611-0.099-1.866-0.225-2.428-0.486c-0.094-0.043-0.228-0.219-0.323-0.26
        c-0.368-0.159-1.207-0.355-1.587-0.226c-0.461,0.156-0.94,1.146-1.36,1.392c-0.405,0.237-1.373,0.316-1.812,0.485
        c-0.349,0.134-1.11,0.377-1.327,0.68c-0.353,0.492-0.208,1.808-0.356,2.395c-0.07,0.274-0.377,0.784-0.389,1.068
        c-0.01,0.243,0.084,0.77,0.26,0.938c0.18,0.173,0.769,0.082,0.971,0.227c0.301,0.217,0.535,0.982,0.777,1.263
        c0.249,0.286,0.927,0.677,1.166,0.971c0.31,0.38,0.853,1.246,0.922,1.731c0.075,0.529-0.699,1.624-0.436,2.088
        c0.252,0.447,1.621,0.277,1.941,0.68c0.336,0.421,0.247,1.597,0.243,2.136c-0.002,0.489-0.067,1.485-0.243,1.942
        c-0.159,0.415-0.863,1.04-1.019,1.456c-0.16,0.424-0.197,1.345-0.195,1.797c0.002,0.306-0.009,0.95,0.145,1.213
        c0.304,0.516,1.375,1.212,1.943,1.408c0.597,0.207,1.927,0.302,2.524,0.098c0.375-0.128,0.939-0.735,1.213-1.02
        c0.288-0.299,0.608-1.363,1.02-1.311c0.486,0.062,0.662,1.321,0.777,1.797c0.134,0.555,0.183,1.711,0.146,2.282
        c-0.042,0.63-0.19,1.92-0.486,2.476c-0.234,0.439-1.122,1-1.408,1.408c-0.331,0.472-0.638,1.619-0.971,2.088
        c-0.314,0.441-1.163,1.131-1.554,1.506c-0.446,0.427-1.252,1.406-1.796,1.699c-0.417,0.225-1.505,0.108-1.845,0.437
        c-0.298,0.288-0.353,1.195-0.436,1.601c-0.126,0.612-0.367,1.852-0.341,2.476c0.022,0.498,0.405,1.446,0.437,1.943
        c0.016,0.251,0.039,0.644,0.028,1.031c-0.014,0.419-0.069,0.831-0.222,1.056c-0.298,0.441-1.366,0.901-1.894,0.971
        c-0.516,0.068-1.542-0.595-2.039-0.436c-0.377,0.12-0.925,0.797-1.068,1.165c-0.198,0.508-0.17,1.665,0,2.185
        c0.102,0.313,0.629,0.773,0.776,1.068c0.204,0.409,0.411,1.308,0.535,1.747c0.157,0.556,0.629,1.659,0.583,2.234
        c-0.036,0.449-0.338,1.426-0.729,1.651c-0.371,0.212-1.371,0.08-1.699-0.194c-0.389-0.326-0.176-1.64-0.583-1.942
        c-0.389-0.29-1.488-0.174-1.942,0c-0.305,0.117-0.691,0.705-0.97,0.874c-0.388,0.235-1.249,0.681-1.7,0.631
        c-0.461-0.052-1.106-1.143-1.553-1.019c-0.35,0.097-0.619,0.947-0.631,1.31c-0.012,0.327,0.324,0.929,0.485,1.214
        c0.218,0.384,1.094,0.967,1.068,1.408c-0.023,0.385-0.746,0.902-1.068,1.117c-0.443,0.297-1.462,0.695-1.99,0.777
        c-0.553,0.086-1.68-0.226-2.233-0.146c-0.347,0.05-1.027,0.283-1.311,0.486c-0.357,0.255-0.745,1.122-1.117,1.355
        c-0.487,0.305-1.66,0.527-2.233,0.539c-0.693,0.015-2.044-0.384-2.719-0.539c-0.648-0.148-1.915-0.579-2.574-0.674
        c-0.722-0.105-2.228-0.344-2.913-0.098c-0.38,0.136-1.053,0.698-1.213,1.068c-0.145,0.335-0.149,1.15,0.048,1.456
        c0.161,0.252,0.91,0.28,1.068,0.535c0.172,0.278,0.065,0.989,0,1.31c-0.054,0.272-0.389,0.923-0.534,0.971
        c-0.146,0.048-1.277,0.39-1.602,0.68c-0.326,0.288-0.839,1.07-0.874,1.505c-0.028,0.337,0.424,0.93,0.485,1.263
        c0.082,0.441,0.035,1.349,0,1.796c-0.047,0.649-0.375,1.923-0.388,2.574c-0.009,0.439-0.06,1.389,0.194,1.747
        c0.21,0.297,0.95,0.587,1.311,0.631c0.579,0.07,1.699-0.508,2.282-0.485c0.342,0.013,1.089,0.129,1.311,0.388
        c0.295,0.344,0.307,1.348,0.243,1.797c-0.081,0.565-0.77,1.538-0.923,2.087c-0.105,0.377-0.061,1.187-0.193,1.554
        c-0.192,0.529-1.056,1.354-1.214,1.894c-0.132,0.454,0.154,1.446,0,1.894c-0.134,0.393-0.625,1.182-1.019,1.311
        c-0.504,0.165-1.551-0.377-2.04-0.582c-0.45-0.191-1.417-0.573-1.699-0.971c-0.428-0.604-0.184-2.261-0.534-2.912
        c-0.223-0.417-0.926-1.126-1.359-1.311c-0.48-0.207-1.606,0.105-2.088-0.098c-0.504-0.21-1.078-1.331-1.602-1.482
        c-0.475-0.136-1.584,0.026-1.942,0.365c-0.444,0.422-0.26,1.859-0.583,2.379c-0.209,0.337-1.013,0.677-1.214,1.02
        c-0.163,0.276-0.145,0.957-0.243,1.263c-0.089,0.279-0.407,0.785-0.485,1.067c-0.141,0.506-0.293,1.565-0.243,2.088
        c0.045,0.482,0.533,1.363,0.583,1.845c0.048,0.474,0.016,1.466-0.195,1.894c-0.318,0.648-1.606,1.453-2.087,1.99
        c-0.654,0.729-1.855,2.282-2.379,3.107c-0.256,0.405-0.551,1.133-0.855,1.768c-0.184,0.381-0.369,0.73-0.553,0.952
        c-0.415,0.499-1.44,1.354-2.04,1.602c-0.644,0.268-2.078,0.44-2.767,0.34c-0.538-0.079-1.634-0.464-1.991-0.875
        c-0.497-0.571-0.411-2.279-0.825-2.912c-0.249-0.383-1.013-0.936-1.408-1.165c-0.416-0.243-1.38-0.442-1.796-0.68
        c-0.406-0.231-1.011-1.03-1.457-1.165c-0.548-0.167-1.723,0.07-2.282,0.194c-0.693,0.152-2.131,0.508-2.671,0.97
        c-0.306,0.262-0.417,1.228-0.777,1.408c-0.307,0.154-1.118,0.049-1.359-0.194c-0.508-0.512-0.34-2.142-0.388-2.864
        c-0.038-0.545,0.085-1.645,0-2.185c-0.074-0.466-0.266-1.447-0.582-1.797c-0.348-0.382-1.504-0.488-1.894-0.825
        c-0.401-0.346-0.921-1.305-1.118-1.797c-0.243-0.609-0.274-1.969-0.533-2.573c-0.134-0.312-0.644-0.804-0.778-1.117
        c-0.246-0.581,0.104-2.25-0.485-2.476c-0.453-0.174-1.114,0.96-1.554,1.165c-0.531,0.248-1.777,0.831-2.282,0.534
        c-0.305-0.179-0.205-1.057-0.389-1.359c-0.147-0.242-0.587-0.623-0.825-0.777c-0.32-0.206-1.186-0.272-1.408-0.583
        c-0.213-0.296-0.183-1.102-0.097-1.456c0.1-0.413,0.933-1.037,0.874-1.456c-0.069-0.494-0.996-1.169-1.456-1.36
        c-0.606-0.251-2.019-0.355-2.622-0.097c-0.49,0.211-1.22,1.104-1.408,1.602c-0.171,0.454,0.112,1.469,0,1.942
        c-0.15,0.634-0.646,1.937-1.165,2.331c-0.513,0.388-1.885,0.548-2.525,0.485c-0.678-0.065-2.044-0.537-2.524-1.019
        c-0.416-0.417-0.339-1.941-0.874-2.185c-0.269-0.122-0.821,0.396-1.117,0.388c-0.485-0.013-1.345-0.553-1.796-0.729
        c-0.556-0.214-1.654-0.688-2.234-0.825c-0.535-0.126-1.761,0.107-2.185-0.243c-0.374-0.306-0.478-1.372-0.582-1.844
        c-0.141-0.634-0.029-2.004-0.34-2.574c-0.305-0.559-1.398-1.318-1.942-1.651c-0.404-0.248-1.343-0.484-1.748-0.728
        c-0.553-0.335-1.429-1.33-1.991-1.652c-0.247-0.141-0.784-0.408-1.068-0.388c-0.493,0.034-1.426,0.547-1.747,0.922
        c-0.268,0.312-0.406,1.164-0.534,1.554c-0.139,0.424-0.242,1.363-0.534,1.699c-0.186,0.213-0.738,0.506-1.02,0.486
        c-0.262-0.019-0.701-0.384-0.874-0.582c-0.292-0.337-0.348-1.484-0.777-1.602c-0.448-0.123-1.119,0.857-1.554,1.02
        c-0.769,0.286-2.434,0.382-3.253,0.437c-0.799,0.053-2.411-0.114-3.204,0c-0.689,0.098-2.11,0.364-2.67,0.776
        c-0.283,0.207-0.503,0.949-0.777,1.165c-0.262,0.206-0.769,0.423-1.242,0.566c-0.282,0.086-0.552,0.145-0.749,0.163
        c-0.27,0.024-0.837-0.052-1.068-0.194c-0.377-0.231-0.832-1.035-1.068-1.409c-0.226-0.358-0.442-1.245-0.776-1.505
        c-0.305-0.236-1.222-0.079-1.506-0.341c-0.448-0.413-0.55-1.746-0.728-2.33c-0.236-0.775-0.438-2.458-0.923-3.108
        c-0.199-0.267-0.791-0.656-1.117-0.728c-0.503-0.112-1.67-0.068-2.039,0.291c-0.362,0.352-0.22,1.501-0.34,1.99
        c-0.113,0.458-0.368,1.376-0.582,1.797c-0.225,0.44-0.674,1.544-1.165,1.602c-0.317,0.038-0.79-0.564-0.971-0.825
        c-0.184-0.266-0.29-0.927-0.437-1.214c-0.206-0.405-0.585-1.37-1.019-1.505c-0.224-0.07-0.64,0.352-0.874,0.34
        c-0.476-0.026-1.265-0.674-1.7-0.874c-0.464-0.215-1.497-0.453-1.894-0.777c-0.439-0.36-0.781-1.59-1.262-1.894
        c-0.426-0.27-1.488-0.29-1.991-0.291c-0.686-0.001-2.033,0.357-2.719,0.388c-0.779,0.036-2.383-0.239-3.059-0.631
        c-0.461-0.269-0.99-1.271-1.408-1.602c-0.273-0.217-0.887-0.598-1.23-0.663c-0.472-0.09-1.43,0.198-1.909,0.226
        c-0.517,0.031-1.66,0.314-2.072,0C367.16,442.261,367.096,442.128,367.048,441.97z" pointer-events="auto" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_5_" x="416.67" y="381.604" opacity="0" fill="#FFFFFF" stroke="#010202" stroke-miterlimit="10" enable-background="new    " width="59.957" height="20.14" pointer-events="auto" visibility="hidden"></rect>
</g>
<rect id="rect12_1_" x="-240.66" y="235.016" opacity="0.3" fill="#FFFFFF" stroke="#010202" stroke-miterlimit="10" enable-background="new    " width="59.957" height="20.14"></rect>
<rect id="rect12_2_" x="-240.66" y="235.016" opacity="0.3" fill="#FFFFFF" stroke="#010202" stroke-miterlimit="10" enable-background="new    " width="59.957" height="20.14"></rect>
<rect id="rect12_3_" x="-240.66" y="235.016" opacity="0.3" fill="#FFFFFF" stroke="#010202" stroke-miterlimit="10" enable-background="new    " width="59.957" height="20.14"></rect>
<rect id="rect12_4_" x="-240.66" y="235.016" opacity="0.3" fill="#FFFFFF" stroke="#010202" stroke-miterlimit="10" enable-background="new    " width="59.957" height="20.14"></rect>
<rect id="rect12_6_" x="-240.66" y="235.016" opacity="0.3" fill="#FFFFFF" stroke="#010202" stroke-miterlimit="10" enable-background="new    " width="59.957" height="20.14"></rect>
<rect id="rect12_7_" x="-240.66" y="235.016" opacity="0.3" fill="#FFFFFF" stroke="#010202" stroke-miterlimit="10" enable-background="new    " width="59.957" height="20.14"></rect>
<g id="AREA_POLAND2_WIELKOPOLSKIE" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Greater Poland" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_POLAND2_WIELKOPOLSKIE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#7eb08e" d="M245.368,362.166c-0.161-0.258-0.306-0.585-0.342-0.791
        c-0.063-0.353,0.17-1.065,0.194-1.424c0.033-0.493,0.098-1.491,0-1.975c-0.058-0.283-0.345-0.8-0.454-1.068
        c-0.143-0.353-0.318-1.121-0.55-1.424c-0.207-0.272-0.812-0.628-1.1-0.81c-0.303-0.192-0.957-0.494-1.262-0.68
        c-0.184-0.112-0.582-0.313-0.712-0.486c-0.259-0.34-0.324-1.255-0.55-1.617c-0.157-0.254-0.583-0.695-0.842-0.842
        c-0.253-0.145-0.861-0.184-1.132-0.291c-0.327-0.13-0.907-0.541-1.23-0.68c-0.445-0.19-1.387-0.428-1.845-0.583
        c-0.366-0.123-1.073-0.568-1.456-0.517c-0.37,0.047-0.984,0.561-1.23,0.841c-0.259,0.293-0.548,1.037-0.712,1.392
        c-0.124,0.271-0.24,0.893-0.454,1.1c-0.198,0.192-0.76,0.406-1.035,0.388c-0.193-0.012-0.559-0.208-0.68-0.356
        c-0.182-0.221-0.037-1.095-0.324-1.1c-0.154-0.003-0.072,0.489-0.194,0.582c-0.225,0.172-0.85,0.09-1.132,0.065
        c-0.374-0.036-1.161-0.126-1.456-0.356c-0.261-0.203-0.371-0.945-0.616-1.165c-0.291-0.262-1.064-0.582-1.456-0.583
        c-0.374-0.001-1.019,0.516-1.392,0.551c-0.295,0.027-0.977,0.002-1.165-0.227c-0.21-0.256-0.121-1.018,0-1.327
        c0.117-0.299,0.598-0.775,0.874-0.939c0.194-0.116,0.706-0.076,0.874-0.226c0.205-0.186,0.423-0.761,0.388-1.036
        c-0.037-0.294-0.59-0.689-0.68-0.971c-0.09-0.285-0.043-0.901,0-1.198c0.041-0.284,0.236-0.827,0.323-1.101
        c0.136-0.424,0.683-1.249,0.582-1.683c-0.078-0.346-0.825-0.696-1.003-1.003c-0.139-0.24-0.087-0.882-0.291-1.068
        c-0.2-0.184-0.798-0.218-1.068-0.194c-0.438,0.039-1.233,0.475-1.651,0.615c-0.352,0.117-1.11,0.223-1.425,0.421
        c-0.194,0.122-0.413,0.572-0.615,0.68c-0.403,0.217-1.715,0.259-1.813,0.259c-0.097,0-1.227-0.126-1.553-0.356
        c-0.229-0.161-0.474-0.696-0.615-0.938c-0.179-0.307-0.37-1.042-0.648-1.262c-0.235-0.188-0.912-0.13-1.165-0.292
        c-0.238-0.151-0.581-0.624-0.711-0.874c-0.216-0.411-0.453-1.321-0.518-1.781c-0.04-0.279-0.149-0.892,0-1.132
        c0.162-0.262,0.855-0.366,1.101-0.55c0.238-0.179,0.687-0.602,0.809-0.874c0.14-0.311,0.179-1.021,0.13-1.359
        c-0.075-0.518-0.422-1.559-0.777-1.942c-0.214-0.23-0.833-0.455-1.133-0.55c-0.344-0.108-1.064-0.249-1.425-0.226
        c-0.37,0.024-1.052,0.435-1.424,0.42c-0.295-0.011-0.821-0.325-1.101-0.42c-0.295-0.1-0.914-0.226-1.198-0.356
        c-0.421-0.193-1.291-0.622-1.553-1.003c-0.29-0.423-0.408-1.494-0.421-2.008c-0.01-0.417,0.049-1.289,0.258-1.651
        c0.178-0.303,0.9-0.585,1.101-0.874c0.224-0.321,0.436-1.1,0.486-1.489c0.065-0.514,0.073-1.593-0.13-2.071
        c-0.098-0.232-0.533-0.539-0.68-0.744c-0.314-0.443-0.902-1.384-1.035-1.91c-0.13-0.509-0.024-1.578,0-2.103
        c0.036-0.812,0.289-2.425,0.292-3.238c0.002-0.868-0.323-2.595-0.292-3.463c0.023-0.688,0.242-2.055,0.421-2.719
        c0.121-0.453,0.368-1.398,0.68-1.748c0.207-0.233,0.839-0.414,1.1-0.582c0.233-0.151,0.716-0.453,0.874-0.68
        c0.202-0.293,0.37-1.008,0.421-1.359c0.116-0.809-0.07-2.453-0.041-3.27c0.016-0.432,0.179-1.292,0.146-1.724
        c-0.036-0.467-0.255-1.389-0.437-1.821c-0.22-0.522-0.801-1.528-1.213-1.918c-0.283-0.267-1.016-0.572-1.359-0.753
        c-0.372-0.196-1.153-0.522-1.506-0.752c-0.311-0.203-0.861-0.704-1.141-0.947c-0.347-0.302-1.159-0.824-1.359-1.238
        c-0.19-0.394-0.194-1.316-0.121-1.748c0.064-0.378,0.316-1.158,0.607-1.408c0.233-0.2,0.887-0.37,1.19-0.315
        c0.227,0.04,0.629,0.339,0.752,0.534c0.152,0.24,0.187,0.833,0.194,1.117c0.005,0.201-0.15,0.607-0.097,0.801
        c0.049,0.184,0.313,0.486,0.461,0.607c0.266,0.218,0.894,0.629,1.238,0.607c0.297-0.019,0.827-0.409,0.995-0.656
        c0.265-0.39,0.404-1.374,0.389-1.845c-0.008-0.271-0.273-0.774-0.291-1.044c-0.033-0.489,0.274-1.452,0.267-1.942
        c-0.007-0.48-0.18-1.433-0.316-1.894c-0.138-0.469-0.446-1.435-0.776-1.796c-0.358-0.393-1.585-0.57-1.869-1.02
        c-0.172-0.271-0.119-0.968-0.073-1.286c0.049-0.338,0.439-0.946,0.462-1.287c0.025-0.397-0.127-1.218-0.34-1.553
        c-0.181-0.285-0.911-0.527-1.068-0.825c-0.147-0.28-0.062-0.947-0.073-1.263c-0.016-0.48,0.142-1.468-0.024-1.918
        c-0.128-0.348-0.775-0.813-0.947-1.141c-0.208-0.396-0.41-1.283-0.486-1.724c-0.128-0.749-0.232-2.275-0.194-3.035
        c0.033-0.669-0.008-2.144,0.437-2.646c0.172-0.194,0.803-0.117,0.995-0.291c0.286-0.259,0.563-1.046,0.582-1.433
        c0.026-0.48-0.53-1.365-0.534-1.845c-0.002-0.371,0.133-1.164,0.389-1.432c0.431-0.454,1.761-0.679,2.379-0.777
        c0.725-0.116,2.208,0.086,2.938,0c0.49-0.058,1.433-0.374,1.918-0.462c0.452-0.08,1.385-0.076,1.821-0.219
        c0.47-0.153,1.416-0.583,1.723-0.971c0.229-0.287,0.345-1.048,0.413-1.408c0.13-0.687,0.227-2.086,0.25-2.784
        c0.03-0.816,0.446-2.631-0.064-3.268c-0.339-0.424-1.73-0.126-2.104-0.519c-0.261-0.273-0.318-1.11-0.291-1.488
        c0.044-0.649,0.377-2.007,0.842-2.46c0.54-0.527,2.352-0.367,2.88-0.907c0.283-0.289,0.198-1.24,0.453-1.554
        c0.358-0.441,1.666-0.614,2.007-1.068c0.234-0.31,0.291-1.133,0.324-1.52c0.056-0.654-0.113-1.967-0.097-2.622
        c0.02-0.877,0.293-2.619,0.291-3.495c-0.002-1.008-0.188-3.021-0.356-4.015c-0.111-0.657-0.481-1.943-0.647-2.589
        c-0.158-0.617-0.623-1.824-0.648-2.461c-0.024-0.632,0.17-1.926,0.454-2.492c0.196-0.395,0.961-0.922,1.197-1.295
        c0.002-0.003,0.003-0.005,0.005-0.008c0.313,0.129,0.675,0.288,0.837,0.396c0.331,0.222,0.78,0.953,1.149,1.101
        c0.368,0.148,1.226-0.166,1.586,0c0.131,0.06,0.282,0.324,0.373,0.437c0.214,0.268,0.533,0.919,0.825,1.1
        c0.279,0.174,0.966,0.213,1.294,0.243c0.56,0.052,1.706-0.144,2.25,0c0.395,0.105,1.015,0.789,1.424,0.809
        c0.318,0.017,0.912-0.325,1.165-0.517c0.243-0.185,0.494-0.827,0.777-0.939c0.396-0.155,1.279,0.354,1.684,0.227
        c0.398-0.126,0.884-0.899,1.23-1.133c0.311-0.211,1.003-0.526,1.359-0.648c0.471-0.16,1.473-0.257,1.942-0.421
        c0.366-0.127,1.014-0.598,1.392-0.68c0.262-0.056,0.811-0.037,1.068,0.032c0.231,0.062,0.612,0.386,0.841,0.454
        c0.389,0.115,1.24,0.275,1.619,0.129c0.208-0.079,0.503-0.453,0.614-0.647c0.261-0.453,0.277-1.584,0.583-2.007
        c0.202-0.278,0.841-0.597,1.133-0.777c0.417-0.255,1.39-0.575,1.716-0.939c0.327-0.365,0.843-1.332,0.744-1.812
        c-0.1-0.488-1.417-0.894-1.424-1.393c-0.005-0.349,0.825-0.686,0.97-1.003c0.155-0.34,0.113-1.116,0.129-1.489
        c0.01-0.235-0.085-0.721,0-0.939c0.144-0.368,0.894-0.811,1.068-1.165c0.231-0.472,0.139-1.592,0.356-2.071
        c0.159-0.351,0.594-1.117,0.971-1.198c0.459-0.099,1.224,0.949,1.682,0.842c0.496-0.116,0.802-1.346,1.231-1.619
        c0.355-0.226,1.23-0.311,1.651-0.355c0.402-0.043,1.221-0.075,1.618,0c0.308,0.057,0.881,0.581,1.165,0.453
        c0.402-0.18,0.484-1.23,0.616-1.651c0.069-0.222,0.161-0.681,0.226-0.906c0.158-0.548,0.221-1.879,0.712-2.169
        c0.298-0.176,1.053,0.421,1.359,0.259c0.18-0.094,0.154-0.633,0.325-0.744c0.399-0.262,1.488-0.225,1.909,0
        c0.335,0.179,0.518,1.064,0.842,1.262c0.306,0.187,1.113,0.338,1.425,0.161c0.34-0.193,0.605-1.035,0.647-1.424
        c0.031-0.272-0.23-0.795-0.226-1.068c0.009-0.536,0.486-1.975,0.551-2.072c0.064-0.097,0.834-0.985,1.132-1.294
        c0.203-0.211,0.571-0.7,0.842-0.809c0.292-0.118,1.011,0.19,1.263,0c0.282-0.213,0.122-1.127,0.388-1.359
        c0.259-0.226,1.225,0.121,1.359-0.194c0.055-0.128-0.238-0.344-0.323-0.454c-0.415-0.528-1.181-1.68-1.748-2.039
        c-0.39-0.247-1.35-0.319-1.781-0.486c-0.702-0.27-2.135-0.847-2.686-1.359c-0.277-0.257-0.449-1.109-0.777-1.294
        c-0.281-0.16-0.971,0-1.295,0c-0.517,0-1.623,0.259-2.071,0c-0.465-0.269-0.971-1.684-1.1-1.845
        c-0.13-0.162-1.291-0.275-1.716-0.389c-0.294-0.08-0.899-0.258-1.133-0.454c-0.364-0.306-0.832-1.174-1.003-1.619
        c-0.263-0.683-0.465-2.151-0.518-2.88c-0.035-0.484,0.206-1.478,0.064-1.942c-0.053-0.172-0.365-0.412-0.421-0.582
        c-0.129-0.401-0.093-1.273,0-1.684c0.124-0.551,0.481-1.758,0.971-2.039c0.429-0.247,1.551-0.094,1.975,0.162
        c0.337,0.203,0.506,1.123,0.842,1.327c0.298,0.182,1.048,0.19,1.392,0.129c0.582-0.102,1.566-0.852,2.136-1.003
        c0.581-0.155,1.795-0.226,2.395-0.194c0.576,0.029,1.69,0.41,2.266,0.421c0.499,0.01,1.516-0.093,1.975-0.291
        c0.417-0.181,1.102-0.811,1.424-1.133c0.322-0.321,0.869-1.053,1.133-1.424c0.167-0.235,0.363-0.833,0.615-0.971
        c0.283-0.156,0.997,0.126,1.295,0c0.268-0.115,0.659-0.591,0.809-0.842c0.257-0.43,0.537-1.414,0.615-1.91
        c0.089-0.567-0.143-1.741,0-2.297c0.114-0.439,0.736-1.156,0.874-1.587c0.15-0.465,0.091-1.473,0.227-1.942
        c0.156-0.535,0.634-1.563,0.971-2.006c0.208-0.273,0.714-0.785,1.035-0.907c0.372-0.139,1.199,0.087,1.587,0
        c0.367-0.082,1.004-0.526,1.359-0.647c0.258-0.088,0.808-0.143,1.068-0.227c0.379-0.122,1.084-0.505,1.457-0.647
        c0.237-0.091,0.591-0.179,0.961-0.273c0.348,0.322,0.808,0.766,0.981,1.002c0.525,0.712,1.261,2.337,1.634,3.14
        c0.264,0.567,0.769,1.717,0.954,2.314c0.264,0.846,0.16,2.799,0.745,3.464c0.334,0.381,1.407,0.744,1.91,0.68
        c0.494-0.063,1.155-1.066,1.651-1.117c0.379-0.039,1.197,0.265,1.408,0.582c0.292,0.442-0.194,1.621-0.017,2.12
        c0.127,0.36,0.629,1.01,0.971,1.182c0.62,0.311,2.123,0.383,2.768,0.129c0.301-0.117,0.659-0.713,0.906-0.923
        c0.442-0.373,1.336-1.174,1.894-1.326c0.701-0.191,2.178,0.122,2.898,0.227c0.658,0.095,1.942,0.495,2.605,0.55
        c0.662,0.054,1.993-0.17,2.654-0.114c0.516,0.043,1.506,0.456,2.023,0.437c0.468-0.017,1.328-0.543,1.797-0.534
        c0.44,0.009,1.305,0.315,1.667,0.566c0.475,0.331,1.057,1.379,1.456,1.796c0.27,0.283,0.84,0.822,1.165,1.036
        c0.377,0.248,1.195,0.666,1.636,0.76c0.336,0.073,1.035-0.065,1.375-0.015c0.25,0.036,0.807,0.22,1.16,0.341
        c-0.069,0.237-0.142,0.448-0.221,0.614c-0.27,0.568-0.985,1.623-1.408,2.088c-0.296,0.325-1.032,0.823-1.359,1.117
        c-0.544,0.487-1.596,1.501-2.087,2.039c-0.39,0.427-1.254,1.255-1.457,1.797c-0.242,0.649,0.053,2.092-0.097,2.767
        c-0.134,0.605-0.479,1.905-0.971,2.282c-0.418,0.32-1.723-0.089-2.087,0.291c-0.354,0.37-0.332,1.546-0.195,2.039
        c0.152,0.552,0.877,1.536,1.359,1.845c0.564,0.36,2.032,0.217,2.622,0.534c0.424,0.227,1.068,0.976,1.36,1.359
        c0.371,0.488,0.983,1.566,1.213,2.136c0.183,0.454,0.229,1.477,0.486,1.894c0.271,0.44,1.258,0.937,1.553,1.359
        c0.307,0.437,0.651,1.471,0.777,1.991c0.164,0.676,0.334,2.073,0.291,2.767c-0.04,0.647-0.205,1.999-0.582,2.525
        c-0.283,0.394-1.194,0.855-1.651,1.019c-0.495,0.179-1.566,0.217-2.087,0.292c-0.583,0.083-1.809,0.068-2.331,0.34
        c-0.267,0.139-0.751,0.583-0.825,0.874c-0.186,0.73,0.963,2.183,0.777,2.913c-0.079,0.308-0.76,0.626-0.874,0.923
        c-0.179,0.465-0.016,1.497,0.048,1.991c0.034,0.26,0.021,0.88,0.243,1.019c0.242,0.153,0.835-0.289,1.117-0.243
        c0.488,0.081,1.543,0.55,1.699,1.02c0.161,0.485-0.443,1.506-0.777,1.894c-0.295,0.342-1.356,0.516-1.553,0.923
        c-0.166,0.341,0.191,1.125,0.194,1.505c0.006,0.757-0.128,2.283-0.34,3.01c-0.155,0.532-0.789,1.468-0.971,1.99
        c-0.241,0.693-0.486,2.146-0.631,2.865c-0.147,0.726-0.129,2.293-0.534,2.913c-0.249,0.381-1.292,0.619-1.505,1.02
        c-0.187,0.353-0.062,1.206,0,1.602c0.05,0.315,0.306,0.906,0.388,1.214c0.074,0.276,0.027,0.93,0.243,1.117
        c0.478,0.415,1.897,0.273,2.524,0.194c0.521-0.066,1.467-0.633,1.991-0.68c0.477-0.043,1.473,0.064,1.894,0.291
        c0.718,0.388,1.53,1.941,2.185,2.428c0.345,0.255,1.156,0.567,1.553,0.728c0.453,0.183,1.506,0.282,1.845,0.631
        c0.372,0.383,0.607,1.506,0.631,2.039c0.015,0.348-0.286,1.012-0.292,1.359c-0.005,0.346,0.069,1.061,0.243,1.359
        c0.278,0.477,1.414,0.936,1.7,1.408c0.243,0.404,0.381,1.374,0.388,1.845c0.009,0.493-0.189,1.473-0.34,1.942
        c-0.137,0.424-0.677,1.168-0.777,1.602c-0.101,0.438-0.1,1.358,0,1.796c0.087,0.381,0.619,1.022,0.679,1.408
        c0.069,0.436,0.008,1.385-0.242,1.748c-0.244,0.353-1.103,0.675-1.505,0.825c-0.535,0.2-1.672,0.594-2.233,0.486
        c-0.318-0.062-0.747-0.691-1.068-0.729c-0.272-0.032-0.774,0.271-1.02,0.388c-0.368,0.177-1.173,0.491-1.408,0.825
        c-0.264,0.374-0.382,1.341-0.34,1.796c0.043,0.467,0.324,1.442,0.68,1.748c0.377,0.324,1.459,0.313,1.942,0.437
        c0.428,0.109,1.336,0.234,1.7,0.486c0.285,0.198,0.723,0.785,0.825,1.117c0.167,0.546,0.082,1.741-0.097,2.282
        c-0.102,0.307-0.633,0.759-0.728,1.068c-0.085,0.279-0.225,0.979,0,1.165c0.256,0.212,0.983-0.299,1.311-0.243
        c0.264,0.045,0.678,0.429,0.923,0.534c0.395,0.17,1.264,0.675,1.651,0.485c0.269-0.132,0.247-0.885,0.436-1.117
        c0.183-0.225,0.699-0.532,0.971-0.631c0.471-0.172,1.558,0.012,1.99-0.243c0.374-0.22,0.632-1.209,1.02-1.408
        c0.432-0.22,1.466-0.099,1.942,0c0.388,0.081,1.235,0.302,1.457,0.631c0.298,0.443,0.235,1.636,0.049,2.136
        c-0.119,0.323-0.8,0.699-0.923,1.02c-0.175,0.453-0.276,1.543,0,1.942c0.295,0.426,1.424,0.719,1.942,0.729
        c0.307,0.006,0.89-0.524,1.165-0.389c0.265,0.13,0.368,0.821,0.388,1.117c0.035,0.494-0.447,1.451-0.388,1.942
        c0.051,0.426,0.309,1.464,0.728,1.553c0.471,0.1,1.093-0.946,1.457-1.262c0.194-0.17,0.552-0.552,0.777-0.68
        c0.199-0.115,0.718-0.121,0.874-0.291c0.252-0.276,0.137-1.143,0.34-1.456c0.207-0.32,0.833-0.949,1.214-0.923
        c0.359,0.024,0.674,0.914,1.019,1.019c0.247,0.077,0.788-0.076,1.019-0.194c0.268-0.137,0.579-0.771,0.874-0.825
        c0.311-0.057,0.931,0.274,1.165,0.485c0.359,0.324,0.506,1.453,0.922,1.7c0.354,0.211,1.319-0.245,1.651,0
        c0.266,0.196,0.148,1.037,0.388,1.262c0.31,0.289,1.235,0.466,1.651,0.388c0.317-0.059,0.754-0.805,1.068-0.728
        c0.523,0.127,0.68,1.477,1.019,1.894c0.251,0.308,1.017,0.676,1.214,1.019c0.178,0.31,0.094,1.084,0.243,1.408
        c0.112,0.243,0.421,0.761,0.679,0.825c0.306,0.076,0.853-0.526,1.165-0.486c0.197,0.026,0.522,0.32,0.631,0.486
        c0.269,0.408,0.169,1.521,0.486,1.894c0.136,0.161,0.566,0.364,0.776,0.34c0.241-0.028,0.546-0.505,0.777-0.582
        c0.334-0.112,1.132-0.219,1.408,0c0.206,0.162,0.339,0.776,0.243,1.02c-0.128,0.325-1,0.468-1.165,0.777
        c-0.16,0.3-0.227,1.107,0,1.359c0.375,0.419,1.695,0.402,2.234,0.243c0.239-0.071,0.536-0.564,0.776-0.631
        c0.414-0.115,1.271,0.243,1.7,0.243c0.429,0,1.334-0.468,1.7-0.243c0.261,0.161,0.339,0.862,0.388,1.165
        c0.072,0.443-0.254,1.426,0,1.796c0.251,0.364,1.289,0.378,1.651,0.631c0.29,0.203,0.687,0.807,0.923,1.068
        c0.333,0.371,0.911,1.238,1.359,1.457c0.481,0.236,1.622,0.04,2.136,0.194c0.395,0.118,1.157,0.493,1.457,0.776
        c0.34,0.323,0.597,1.321,0.971,1.602c0.483,0.363,1.727,0.601,2.33,0.631c0.517,0.025,1.58-0.103,2.04-0.34
        c0.442-0.228,1.001-1.121,1.408-1.408c0.484-0.342,1.618-0.734,2.136-1.02c0.439-0.242,1.396-0.668,1.699-1.068
        c0.259-0.343,0.22-1.313,0.486-1.651c0.269-0.342,1.073-0.82,1.505-0.874c0.35-0.043,1.168,0.091,1.359,0.388
        c0.313,0.484-0.318,1.706-0.34,2.282c-0.039,1.046,0.158,3.14,0.308,4.175c0.043,0.303,0.004,1.028,0.258,1.198
        c0.342,0.229,1.208-0.271,1.619-0.291c0.608-0.03,1.828,0.305,2.428,0.194c0.352-0.064,1.018-0.388,1.294-0.615
        c0.429-0.351,0.857-1.487,1.328-1.78c0.446-0.279,1.552-0.472,2.071-0.388c0.465,0.074,1.271,0.627,1.651,0.906
        c0.397,0.291,1.168,0.939,1.425,1.359c0.224,0.367,0.183,1.345,0.485,1.651c0.422,0.428,1.706,0.605,2.298,0.712
        c0.686,0.124,2.147-0.123,2.783,0.162c0.324,0.145,0.828,0.695,1.004,1.003c0.243,0.428,0.21,1.501,0.485,1.91
        c0.213,0.316,1.004,0.598,1.23,0.906c0.31,0.422,0.49,1.489,0.68,1.976c0.074,0.189,0.182,0.6,0.324,0.743
        c0.294,0.296,1.181,0.441,1.521,0.68c0.449,0.316,1.2,1.141,1.521,1.587c0.232,0.319,0.627,1.015,0.745,1.391
        c0.193,0.623-0.045,2.032,0.292,2.59c0.191,0.319,0.861,0.786,1.229,0.841c0.428,0.064,1.317-0.243,1.651-0.517
        c0.221-0.182,0.314-0.843,0.55-1.003c0.338-0.228,1.222-0.285,1.619-0.194c0.44,0.1,1.072,1.014,1.521,0.971
        c0.433-0.042,0.927-0.922,1.262-1.199c0.238-0.195,0.729-0.574,1.004-0.712c0.356-0.179,1.132-0.394,1.521-0.485
        c0.255-0.06,0.821-0.011,1.036-0.162c0.347-0.244,0.705-1.078,0.809-1.489c0.076-0.298-0.078-0.933,0-1.23
        c0.101-0.378,0.409-1.228,0.777-1.359c0.36-0.127,1.18,0.258,1.425,0.551c0.275,0.329,0.067,1.34,0.323,1.682
        c0.202,0.271,1.012,0.336,1.198,0.616c0.3,0.452-0.141,1.717,0.161,2.168c0.233,0.346,1.229,0.42,1.49,0.745
        c0.292,0.364,0.13,1.474,0.453,1.812c0.35,0.367,1.479,0.377,1.942,0.582c0.222,0.098,0.706,0.404,0.996,0.591
        c-0.064,0.244-0.115,0.476-0.154,0.639c-0.118,0.492-0.159,1.512-0.26,2.008c-0.095,0.469-0.396,1.382-0.517,1.845
        c-0.095,0.362-0.466,1.099-0.356,1.457c0.103,0.334,0.689,0.896,1.036,0.938c0.355,0.043,0.934-0.535,1.263-0.68
        c0.181-0.078,0.548-0.271,0.745-0.258c0.343,0.022,0.929,0.45,1.229,0.615c0.224,0.121,0.733,0.307,0.874,0.518
        c0.286,0.428,0.491,1.565,0.291,2.039c-0.159,0.379-0.992,0.732-1.327,0.971c-0.217,0.156-0.797,0.359-0.874,0.616
        c-0.078,0.258,0.322,0.743,0.388,1.003c0.091,0.356,0.269,1.097,0.194,1.457c-0.044,0.214-0.249,0.667-0.453,0.745
        c-0.261,0.099-0.824-0.189-1.068-0.324c-0.32-0.179-0.782-0.788-1.1-0.971c-0.34-0.194-1.102-0.424-1.489-0.486
        c-0.615-0.097-1.87-0.044-2.492,0c-0.449,0.033-1.394,0.03-1.781,0.26c-0.357,0.211-0.631,1.141-1.003,1.327
        c-0.391,0.195-1.426-0.295-1.748,0c-0.33,0.301-0.044,1.349-0.162,1.78c-0.104,0.381-0.504,1.07-0.679,1.425
        c-0.157,0.318-0.565,0.918-0.647,1.263c-0.079,0.322-0.045,0.998,0,1.326c0.044,0.333,0.088,1.092,0.355,1.295
        c0.251,0.192,0.963-0.099,1.262,0c0.326,0.106,0.947,0.504,1.102,0.81c0.233,0.462,0.136,1.572,0,2.071
        c-0.114,0.416-0.627,1.136-0.874,1.489c-0.212,0.301-0.717,0.839-0.939,1.132c-0.259,0.345-0.594,1.215-0.971,1.425
        c-0.504,0.28-1.728,0.167-2.298,0.097c-0.368-0.046-1.08-0.559-1.424-0.42c-0.363,0.145-0.634,0.994-0.777,1.359
        c-0.118,0.301-0.057,1.038-0.291,1.262c-0.161,0.154-0.656,0.201-0.874,0.161c-0.186-0.033-0.47-0.323-0.648-0.388
        c-0.3-0.108-0.945-0.161-1.262-0.194c-0.476-0.049-1.454,0.047-1.91-0.097c-0.336-0.105-0.852-0.676-1.197-0.743
        c-0.435-0.087-1.332,0.138-1.748,0.291c-0.416,0.152-1.268,0.542-1.521,0.906c-0.227,0.324-0.297,1.159-0.291,1.553
        c0.008,0.522,0.252,1.558,0.453,2.04c0.144,0.346,0.672,0.913,0.809,1.262c0.116,0.292,0.23,0.918,0.228,1.231
        c-0.004,0.503-0.473,1.478-0.389,1.974c0.045,0.268,0.445,0.688,0.55,0.939c0.072,0.17,0.203,0.527,0.194,0.712
        c-0.02,0.432-0.636,1.187-0.615,1.619c0.018,0.361,0.472,0.977,0.648,1.294c0.143,0.257,0.395,0.808,0.615,1.003
        c0.373,0.333,1.499,0.452,1.813,0.842c0.633,0.788,0.993,2.945,0.874,3.949c-0.055,0.456-0.527,1.288-0.81,1.651
        c-0.254,0.326-0.996,0.752-1.263,1.068c-0.43,0.509-0.912,1.803-1.359,2.297c-0.539,0.597-1.993,1.369-2.589,1.91
        c-0.548,0.498-1.32,1.858-1.976,2.202c-0.454,0.237-1.525,0.249-2.039,0.258c-0.284,0.006-0.883,0.039-1.132-0.097
        c-0.346-0.186-0.63-1.038-0.971-1.229c-0.462-0.261-1.574-0.244-2.104-0.26c-0.495-0.014-1.505-0.025-1.975,0.129
        c-0.329,0.108-0.837,0.636-1.165,0.744c-0.331,0.111-1.111,0.303-1.393,0.098c-0.302-0.22-0.102-1.182-0.355-1.457
        c-0.209-0.226-0.858-0.384-1.165-0.388c-0.364-0.005-1.127,0.172-1.392,0.421c-0.238,0.225-0.275,0.956-0.453,1.23
        c-0.271,0.418-1.148,0.977-1.425,1.392c-0.314,0.471-0.595,1.593-0.842,2.104c-0.234,0.487-1.074,1.337-1.068,1.877
        c0.004,0.283,0.517,0.696,0.582,0.971c0.102,0.426-0.045,1.317-0.129,1.747c-0.076,0.393-0.486,1.123-0.485,1.521
        c0,0.392,0.603,1.117,0.485,1.489c-0.085,0.272-0.728,0.461-0.906,0.68c-0.169,0.208-0.455,0.704-0.454,0.97
        c0.002,0.413,0.84,1.098,0.712,1.49c-0.111,0.341-0.963,0.481-1.262,0.679c-0.451,0.3-1.369,0.919-1.683,1.36
        c-0.374,0.525-0.922,1.783-0.874,2.427c0.017,0.239,0.325,0.641,0.453,0.841c0.19,0.298,0.771,0.788,0.841,1.133
        c0.068,0.336-0.164,1.026-0.324,1.328c-0.096,0.183-0.362,0.53-0.55,0.614c-0.229,0.104-0.826-0.178-1.004,0
        c-0.166,0.167-0.031,0.706,0,0.939c0.049,0.365,0.436,1.058,0.388,1.424c-0.038,0.288-0.702,0.739-0.582,1.005
        c0.152,0.338,1.192,0.031,1.456,0.29c0.243,0.239,0.297,0.988,0.291,1.327c-0.004,0.26-0.176,0.758-0.259,1.004
        c-0.153,0.449-0.742,1.273-0.744,1.748c-0.002,0.315,0.426,0.854,0.486,1.165c0.069,0.366,0.005,1.119-0.033,1.489
        c-0.041,0.409-0.302,1.207-0.292,1.618c0.016,0.639,0.51,1.857,0.583,2.492c0.079,0.684,0.216,2.099,0,2.752
        c-0.136,0.413-0.746,1.07-1.036,1.392c-0.398,0.442-1.454,1.1-1.747,1.619c-0.29,0.51-0.329,1.732-0.486,2.297
        c-0.099,0.355-0.227,1.129-0.486,1.392c-0.23,0.233-0.906,0.5-1.23,0.453c-0.389-0.055-1.005-0.642-1.263-0.938
        c-0.21-0.245-0.438-0.864-0.614-1.133c-0.174-0.267-0.503-0.846-0.777-1.003c-0.326-0.188-1.126-0.098-1.49-0.194
        c-0.55-0.147-1.545-0.763-2.103-0.874c-0.365-0.073-1.129-0.097-1.489,0c-0.315,0.083-0.944,0.38-1.132,0.647
        c-0.129,0.182-0.162,0.653-0.162,0.874c-0.001,0.543,0.373,1.594,0.388,2.136c0.018,0.603,0.046,1.896-0.291,2.395
        c-0.3,0.445-1.348,0.954-1.877,1.037c-0.49,0.075-1.469-0.535-1.942-0.389c-0.487,0.15-1.179,1.01-1.425,1.457
        c-0.136,0.247-0.061,0.898-0.26,1.1c-0.245,0.252-1.011,0.312-1.359,0.356c-0.272,0.035-0.837-0.08-1.1,0
        c-0.307,0.092-0.917,0.429-1.068,0.712c-0.138,0.258-0.105,0.893,0,1.165c0.125,0.327,0.793,0.718,0.939,1.037
        c0.166,0.363,0,1.236,0.194,1.586c0.131,0.237,0.695,0.452,0.841,0.679c0.36,0.555,0.65,1.898,0.68,2.557
        c0.026,0.575-0.575,1.723-0.388,2.267c0.16,0.465,0.983,1.193,1.457,1.327c0.421,0.118,1.28-0.283,1.716-0.324
        c0.403-0.038,1.274-0.213,1.619,0c0.369,0.228,0.703,1.125,0.776,1.554c0.066,0.385-0.102,1.173-0.194,1.554
        c-0.1,0.411-0.407,1.202-0.582,1.586c-0.174,0.378-0.789,1.041-0.81,1.456c-0.019,0.406,0.446,1.137,0.648,1.49
        c0.239,0.417,0.984,1.096,1.133,1.554c0.12,0.368-0.052,1.168,0,1.553c0.029,0.22,0.058,0.626,0.153,0.935
        c-0.083,0.049-0.158,0.09-0.218,0.117c-0.452,0.208-1.438,0.408-1.91,0.567c-0.238,0.081-0.701,0.274-0.938,0.356
        c-0.537,0.187-1.604,0.615-2.169,0.68c-0.443,0.051-1.36-0.282-1.78-0.13c-0.296,0.108-0.783,0.585-0.906,0.875
        c-0.181,0.425,0.242,1.452,0,1.844c-0.276,0.447-1.374,0.79-1.878,0.939c-0.414,0.123-1.291,0.111-1.715,0.194
        c-0.578,0.115-1.697,0.492-2.266,0.648c-0.371,0.101-1.104,0.375-1.488,0.388c-0.601,0.019-1.764-0.506-2.363-0.453
        c-0.536,0.048-1.471,0.731-2.007,0.776c-0.246,0.022-0.74-0.106-0.971-0.194c-0.316-0.119-0.842-0.583-1.165-0.68
        c-0.327-0.096-1.056,0.09-1.359-0.064c-0.252-0.126-0.636-0.602-0.712-0.874c-0.098-0.346,0.002-1.121,0.194-1.425
        c0.196-0.312,1.055-0.486,1.23-0.809c0.238-0.442-0.067-1.51,0-2.007c0.061-0.45,0.532-1.296,0.485-1.748
        c-0.036-0.352-0.368-1.043-0.647-1.262c-0.491-0.386-1.806-0.531-2.427-0.583c-0.229-0.019-0.548-0.019-0.889-0.003
        c-0.039-0.422-0.112-0.871-0.245-1.064c-0.237-0.347-1.246-0.465-1.457-0.826c-0.354-0.607,0.167-2.132,0-2.816
        c-0.081-0.332-0.4-0.962-0.631-1.213c-0.185-0.201-0.778-0.351-0.923-0.583c-0.35-0.559-0.407-1.972-0.291-2.621
        c0.11-0.615,1.01-1.619,1.117-2.233c0.085-0.495-0.078-1.518-0.243-1.991c-0.207-0.591-1.141-1.533-1.311-2.136
        c-0.113-0.397,0.166-1.273,0-1.651c-0.08-0.18-0.432-0.407-0.582-0.533c-0.318-0.269-1.128-0.648-1.311-1.02
        c-0.165-0.339-0.056-1.133,0-1.506c0.055-0.364,0.157-1.168,0.437-1.408c0.373-0.32,1.56,0.018,1.942-0.291
        c0.322-0.262,0.581-1.139,0.583-1.554c0.001-0.387-0.175-1.31-0.534-1.456c-0.287-0.116-0.816,0.604-1.117,0.534
        c-0.404-0.096-0.609-1.369-1.02-1.311c-0.388,0.054-0.313,1.172-0.582,1.456c-0.258,0.272-0.985,0.619-1.359,0.632
        c-0.476,0.016-1.339-0.503-1.78-0.68c-0.27-0.109-0.799-0.347-1.068-0.453c-0.393-0.156-1.169-0.517-1.587-0.582
        c-0.416-0.065-1.307-0.125-1.683,0.065c-0.441,0.224-0.782,1.342-1.23,1.553c-0.431,0.204-1.48,0.176-1.909-0.032
        c-0.357-0.174-0.857-0.862-1.004-1.231c-0.248-0.626,0.018-2.047-0.194-2.686c-0.132-0.4-0.685-1.064-0.906-1.424
        c-0.316-0.513-1.137-1.477-1.23-2.072c-0.09-0.574,0.554-1.686,0.518-2.265c-0.025-0.378-0.492-1.047-0.518-1.426
        c-0.019-0.272,0.051-0.858,0.227-1.068c0.151-0.18,0.639-0.327,0.874-0.355c0.275-0.034,0.834,0.243,1.1,0.162
        c0.251-0.077,0.61-0.507,0.776-0.712c0.15-0.183,0.375-0.601,0.486-0.809c0.269-0.505,0.968-1.501,0.971-2.072
        c0.003-0.551-0.733-1.485-0.906-2.007c-0.142-0.425-0.146-1.371-0.388-1.747c-0.155-0.239-0.654-0.553-0.907-0.679
        c-0.226-0.115-0.791-0.115-0.971-0.292c-0.229-0.227-0.249-0.938-0.388-1.23c-0.17-0.357-0.517-1.153-0.874-1.327
        c-0.599-0.29-1.993,0.302-2.654,0.227c-0.422-0.047-1.248-0.311-1.619-0.518c-0.345-0.193-0.835-0.878-1.198-1.036
        c-0.455-0.199-1.495-0.094-1.974-0.227c-0.377-0.104-1.09-0.444-1.425-0.647c-0.369-0.225-0.984-0.856-1.359-1.068
        c-0.633-0.358-2.003-0.905-2.719-1.036c-0.819-0.151-2.519-0.174-3.334,0c-0.624,0.131-1.704,0.918-2.331,1.036
        c-0.486,0.09-1.562-0.37-1.975-0.097c-0.351,0.233-0.573,1.163-0.582,1.586c-0.007,0.323,0.332,0.913,0.388,1.23
        c0.06,0.335,0.045,1.02,0.065,1.359c0.034,0.59,0.316,1.791,0.162,2.363c-0.072,0.265-0.348,0.85-0.615,0.906
        c-0.328,0.069-0.809-0.633-1.132-0.712c-0.292-0.072-0.91,0.012-1.199,0.097c-0.344,0.103-0.913,0.599-1.262,0.68
        c-0.418,0.097-1.336,0.135-1.715-0.065c-0.338-0.179-0.572-1.046-0.907-1.229c-0.206-0.115-0.705-0.092-0.938-0.065
        c-0.537,0.059-1.667,0.257-2.072,0.614c-0.244,0.217-0.35,0.919-0.518,1.199c-0.181,0.301-0.523,1.115-0.874,1.1
        c-0.466-0.019-0.626-1.329-1.036-1.553c-0.384-0.21-1.401-0.298-1.748-0.033c-0.238,0.183-0.142,0.903-0.291,1.165
        c-0.157,0.279-0.605,0.797-0.906,0.906c-0.526,0.193-1.711,0.008-2.234-0.194c-0.398-0.155-0.956-0.894-1.359-1.037
        c-0.29-0.102-0.936,0.058-1.23-0.032c-0.386-0.12-1.08-0.582-1.359-0.875c-0.29-0.304-0.561-1.142-0.841-1.456
        c-0.235-0.264-0.795-0.739-1.133-0.842c-0.42-0.125-1.31,0.099-1.748,0.097c-0.552,0-1.798,0.247-2.202-0.128
        c-0.37-0.345,0.029-1.59-0.258-2.007c-0.225-0.325-1.139-0.442-1.393-0.744c-0.268-0.319-0.305-1.227-0.517-1.587
        c-0.181-0.305-0.777-0.737-0.971-1.035c-0.169-0.259-0.382-0.843-0.485-1.133c-0.179-0.508-0.293-1.618-0.583-2.071
        c-0.12-0.19-0.465-0.519-0.679-0.583c-0.383-0.116-1.201,0.298-1.586,0.194c-0.207-0.056-0.49-0.421-0.68-0.518
        c-0.505-0.256-1.62-0.509-2.169-0.647c-0.432-0.109-1.34-0.175-1.748-0.356c-0.211-0.094-0.542-0.437-0.744-0.55
        c-0.244-0.137-0.806-0.262-1.036-0.421c-0.244-0.17-0.701-0.598-0.809-0.875c-0.181-0.459-0.187-1.516,0-1.974
        c0.175-0.429,1.115-0.9,1.294-1.328c0.144-0.344,0.056-1.118,0.033-1.489c-0.032-0.482-0.212-1.434-0.291-1.909
        c-0.134-0.812,0.018-2.676-0.583-3.237c-0.374-0.35-1.558-0.045-2.039-0.227c-0.369-0.139-0.999-0.644-1.294-0.905
        c-0.3-0.268-0.737-0.97-1.068-1.198c-0.32-0.22-1.142-0.322-1.457-0.551c-0.241-0.177-0.48-0.797-0.745-0.939
        c-0.485-0.263-1.705,0.051-2.2-0.194c-0.334-0.165-0.76-0.819-1.004-1.1c-0.415-0.477-1.183-1.484-1.618-1.942
        c-0.395-0.415-1.202-1.244-1.684-1.554c-0.279-0.181-0.983-0.292-1.229-0.517C245.532,362.406,245.448,362.295,245.368,362.166z" pointer-events="auto" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_8_" x="277.177" y="309.82" opacity="0" fill="#FFFFFF" stroke="#010202" stroke-miterlimit="10" enable-background="new    " width="59.957" height="20.14" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_POLAND2_SLASKIE" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Silesian" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_POLAND2_SLASKIE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#7eb08e" d="M492.535,525.162c0.109,0.324,0.092,1.139-0.171,1.359
        c-0.311,0.262-1.225-0.003-1.626-0.073c-0.426-0.074-1.218-0.524-1.651-0.51c-0.39,0.013-1.213,0.255-1.457,0.559
        c-0.234,0.292-0.085,1.131-0.218,1.481c-0.114,0.299-0.428,0.906-0.704,1.068c-0.409,0.241-1.462-0.052-1.894,0.146
        c-0.28,0.128-0.589,0.754-0.874,0.874c-0.487,0.204-1.642,0.266-2.112,0.024c-0.349-0.179-0.551-1.105-0.898-1.286
        c-0.199-0.104-0.683-0.064-0.898,0c-0.471,0.139-1.2,0.875-1.651,1.068c-0.557,0.238-1.749,0.537-2.356,0.559
        c-0.565,0.02-1.675-0.464-2.233-0.364c-0.596,0.106-1.57,0.917-2.112,1.19c-0.416,0.207-1.247,0.647-1.699,0.752
        c-0.532,0.125-1.643,0.03-2.185,0.097c-0.59,0.074-1.745,0.586-2.331,0.486c-0.369-0.063-0.942-0.627-1.286-0.777
        c-0.474-0.204-1.477-0.616-1.991-0.558c-0.315,0.036-0.843,0.444-1.116,0.606c-0.298,0.178-1.005,0.446-1.166,0.753
        c-0.19,0.36-0.038,1.229,0.048,1.626c0.073,0.336,0.504,0.922,0.534,1.263c0.029,0.309-0.027,1.016-0.267,1.213
        c-0.21,0.174-0.862,0.17-1.093,0.025c-0.287-0.181-0.247-1.105-0.558-1.238c-0.237-0.102-0.764,0.187-0.971,0.34
        c-0.256,0.189-0.726,0.704-0.752,1.02c-0.027,0.297,0.27,0.929,0.534,1.068c0.348,0.183,1.179-0.336,1.554-0.219
        c0.298,0.094,0.802,0.561,0.922,0.85c0.128,0.308,0.128,1.051-0.048,1.336c-0.204,0.327-0.996,0.598-1.359,0.728
        c-0.349,0.125-1.097,0.382-1.457,0.291c-0.388-0.097-0.837-1.025-1.238-1.019c-0.184,0.002-0.482,0.317-0.558,0.485
        c-0.196,0.431-0.009,1.421,0,1.894c0.006,0.346,0.152,1.053,0.048,1.384c-0.073,0.231-0.39,0.629-0.582,0.777
        c-0.296,0.227-1.142,0.273-1.384,0.558c-0.249,0.294-0.075,1.225-0.34,1.506c-0.55,0.584-2.318,0.657-3.083,0.898
        c-0.306,0.096-1.067,0.126-1.213,0.413c-0.124,0.243,0.194,0.8,0.315,1.044c0.165,0.331,0.556,1.003,0.874,1.19
        c0.339,0.197,1.181,0.077,1.554,0.194c0.51,0.161,1.452,0.694,1.894,0.996c0.405,0.276,1.321,0.823,1.481,1.286
        c0.135,0.391,0.004,1.363-0.316,1.627c-0.243,0.2-0.963-0.102-1.262,0c-0.391,0.13-1.005,0.72-1.311,0.995
        c-0.342,0.306-1.058,0.923-1.262,1.335c-0.151,0.303-0.107,1.016-0.219,1.335c-0.138,0.395-0.684,1.062-0.825,1.457
        c-0.218,0.614-0.197,1.967-0.436,2.573c-0.133,0.339-0.468,1.07-0.802,1.214c-0.476,0.203-1.523-0.319-2.04-0.364
        c-0.405-0.036-1.244-0.139-1.626,0c-0.243,0.088-0.668,0.437-0.793,0.663c-0.201,0.364,0,1.301-0.227,1.651
        c-0.222,0.346-1.123,0.564-1.391,0.874c-0.402,0.462-0.659,1.729-1.004,2.233c-0.443,0.648-1.773,1.578-2.201,2.233
        c-0.181,0.278-0.367,0.922-0.486,1.231c-0.183,0.476-0.397,1.509-0.713,1.91c-0.186,0.237-0.736,0.537-1.003,0.679
        c-0.285,0.152-0.936,0.296-1.197,0.486c-0.577,0.417-1.44,1.583-1.845,2.168c-0.4,0.578-1.19,1.778-1.359,2.46
        c-0.201,0.81,0.069,2.5,0.064,3.334c-0.003,0.534,0.09,1.625-0.064,2.136c-0.089,0.292-0.563,0.741-0.648,1.036
        c-0.109,0.381-0.119,1.207,0,1.586c0.118,0.377,0.543,1.137,0.906,1.295c0.495,0.215,1.634-0.097,2.136-0.291
        c0.413-0.159,1.029-0.894,1.457-1.004c0.308-0.078,0.998-0.045,1.262,0.13c0.363,0.239,0.748,1.096,0.842,1.521
        c0.124,0.562-0.178,1.722-0.162,2.297c0.018,0.645,0.302,1.913,0.324,2.557c0.014,0.421,0.029,1.281-0.097,1.683
        c-0.13,0.416-0.79,1.069-0.907,1.489c-0.128,0.46-0.095,1.442,0,1.91c0.095,0.465,0.381,1.442,0.745,1.747
        c0.356,0.299,1.348,0.424,1.813,0.421c0.642-0.004,1.853-0.563,2.492-0.614c0.623-0.052,1.911-0.038,2.493,0.193
        c0.406,0.162,1.171,0.706,1.359,1.1c0.172,0.359,0.092,1.201,0,1.588c-0.083,0.351-0.541,0.949-0.648,1.294
        c-0.109,0.359-0.303,1.13-0.194,1.488c0.168,0.549,1.117,1.311,1.521,1.716c0.421,0.423,1.23,1.357,1.78,1.587
        c0.554,0.233,1.801,0.082,2.395,0.162c1.171,0.157,3.489,0.634,4.629,0.939c0.618,0.166,1.918,0.422,2.428,0.81
        c0.426,0.324,1.099,1.253,1.198,1.779c0.13,0.694-0.294,2.125-0.615,2.753c-0.261,0.509-1.133,1.292-1.521,1.715
        c-0.286,0.313-1.009,0.837-1.165,1.23c-0.138,0.346-0.23,1.197,0,1.489c0.354,0.453,1.684,0.378,2.233,0.55
        c0.77,0.243,2.379,0.663,3.01,1.166c0.35,0.279,1.016,1.04,1.003,1.489c-0.011,0.417-0.823,0.953-1.003,1.327
        c-0.094,0.197-0.203,0.69-0.268,1.008c-0.441-0.144-0.886-0.238-1.189-0.231c-0.785,0.019-2.365,0.605-2.913,1.165
        c-0.29,0.296-0.299,1.252-0.582,1.554c-0.528,0.563-2.231,0.759-2.816,1.263c-1.292,1.111-2.423,4.592-3.69,5.729
        c-0.93,0.833-3.337,1.704-4.466,2.233c-1.152,0.54-3.916,1.009-4.662,2.04c-0.757,1.047-0.17,3.893-0.485,5.146
        c-0.29,1.15-0.953,3.59-1.845,4.371c-0.991,0.868-3.776,1.216-5.082,1.391c-0.642,0.087-1.976,0.205-2.59,0
        c-0.383-0.127-0.891-0.938-1.294-0.971c-0.454-0.037-1.198,0.665-1.619,0.842c-0.675,0.283-2.053,0.869-2.784,0.906
        c-0.777,0.04-2.51-0.08-3.043-0.647c-0.371-0.393-0.363-1.597-0.323-2.136c0.044-0.608,0.752-1.723,0.712-2.33
        c-0.041-0.622-0.961-1.649-1.036-2.267c-0.115-0.948,1.072-2.869,0.713-3.755c-0.298-0.732-1.836-1.575-2.59-1.813
        c-0.602-0.19-1.896,0.05-2.525,0c-0.817-0.065-2.569-0.041-3.237-0.517c-0.672-0.48-1.399-2.103-1.553-2.913
        c-0.19-0.991,0.6-3.015,0.453-4.014c-0.144-0.975-1.147-2.727-1.554-3.625c-0.34-0.751-1.218-2.179-1.424-2.978
        c-0.21-0.816-0.017-2.541-0.194-3.367c-0.124-0.576-0.487-1.731-0.842-2.201c-0.292-0.388-1.092-1.016-1.554-1.166
        c-0.878-0.283-2.792,0.277-3.69,0.065c-0.781-0.186-2.308-0.894-2.848-1.489c-0.435-0.478-0.451-2.014-0.971-2.395
        c-0.328-0.24-1.248,0.036-1.619-0.13c-0.752-0.335-1.932-1.584-2.395-2.265c-0.391-0.578-0.79-1.938-1.036-2.591
        c-0.393-1.044-1.29-3.109-1.489-4.208c-0.164-0.907-0.163-2.772-0.064-3.689c0.067-0.629,0.43-1.847,0.583-2.459
        c0.109-0.438,0.571-1.313,0.452-1.748c-0.066-0.243-0.57-0.504-0.712-0.713c-0.249-0.364-0.698-1.176-0.712-1.618
        c-0.019-0.568,0.753-1.569,0.777-2.136c0.027-0.62-0.194-1.974-0.648-2.395c-0.254-0.235-1.018-0.311-1.359-0.259
        c-0.852,0.128-2.229,1.335-3.042,1.618c-0.42,0.147-1.316,0.428-1.748,0.323c-0.516-0.123-1.373-0.864-1.684-1.293
        c-0.23-0.32-0.239-1.21-0.518-1.489c-0.315-0.315-1.264-0.431-1.682-0.582c-0.684-0.249-2.002-0.919-2.719-1.037
        c-0.8-0.13-2.445-0.034-3.238,0.129c-0.797,0.164-2.249,0.978-3.042,1.165c-0.491,0.115-1.606,0.501-2.007,0.194
        c-0.3-0.228-0.113-1.14-0.259-1.489c-0.345-0.824-1.43-2.29-2.072-2.913c-0.502-0.487-1.856-1.037-2.33-1.553
        c-0.387-0.422-0.664-1.637-1.101-2.008c-0.405-0.345-1.476-0.753-2.007-0.711c-0.606,0.048-1.561,0.965-2.136,1.165
        c-0.356,0.124-1.114,0.307-1.488,0.259c-0.545-0.069-1.676-0.466-2.007-0.906c-0.344-0.458-0.283-1.694-0.323-2.266
        c-0.033-0.468,0.108-1.421,0-1.877c-0.124-0.528-0.551-1.598-0.971-1.942c-0.477-0.391-1.76-0.592-2.382-0.647
        c0.06-0.373,0.168-1.005,0.246-1.264c0.117-0.38,0.455-1.102,0.631-1.457c0.186-0.374,0.718-1.051,0.825-1.456
        c0.123-0.469,0.129-1.474,0-1.942c-0.114-0.409-0.734-1.055-0.874-1.456c-0.178-0.509-0.289-1.597-0.291-2.136
        c-0.002-0.576-0.141-1.902,0.291-2.282c0.273-0.241,1.114-0.124,1.457,0c0.267,0.097,0.624,0.591,0.874,0.728
        c0.357,0.195,1.178,0.642,1.554,0.486c0.315-0.131,0.357-1.009,0.631-1.213c0.406-0.304,1.509-0.234,1.991-0.389
        c0.305-0.098,0.853-0.466,1.165-0.534c0.617-0.135,1.915,0.162,2.524,0c0.379-0.1,0.97-0.753,1.359-0.777
        c0.342-0.02,0.935,0.636,1.263,0.534c0.235-0.072,0.464-0.589,0.534-0.825c0.151-0.511-0.261-1.67,0-2.136
        c0.181-0.323,0.922-0.628,1.262-0.777c0.496-0.216,1.579-0.397,2.088-0.582c0.643-0.234,1.916-0.764,2.493-1.133
        c0.459-0.293,1.322-0.989,1.651-1.424c0.242-0.32,0.316-1.285,0.68-1.456c0.317-0.149,1.052,0.186,1.359,0.355
        c0.37,0.206,0.831,1.024,1.23,1.165c0.535,0.192,1.791,0.151,2.266-0.161c0.205-0.136,0.405-0.633,0.453-0.874
        c0.096-0.478-0.01-1.48-0.161-1.942c-0.077-0.235-0.353-0.658-0.519-0.842c-0.191-0.214-0.806-0.441-0.905-0.712
        c-0.115-0.31,0.31-0.97,0.259-1.295c-0.04-0.265-0.459-0.677-0.518-0.938c-0.058-0.262-0.014-0.814,0.065-1.068
        c0.088-0.288,0.597-0.736,0.615-1.037c0.019-0.318-0.385-0.876-0.518-1.165c-0.134-0.293-0.343-0.918-0.55-1.165
        c-0.254-0.302-1.023-0.619-1.294-0.906c-0.17-0.18-0.451-0.604-0.518-0.842c-0.088-0.311-0.124-0.995,0-1.294
        c0.189-0.455,1.106-0.991,1.392-1.392c0.312-0.439,0.915-1.404,0.939-1.943c0.02-0.476-0.343-1.443-0.68-1.779
        c-0.325-0.324-1.454-0.271-1.716-0.648c-0.201-0.292-0.167-1.11,0-1.424c0.142-0.269,0.839-0.426,1.004-0.68
        c0.178-0.275,0.162-0.973,0.227-1.295c0.144-0.716,0.525-2.128,0.647-2.848c0.108-0.634,0.317-1.913,0.292-2.557
        c-0.017-0.421-0.52-1.279-0.324-1.651c0.087-0.164,0.496-0.291,0.68-0.291c0.246,0,0.66,0.368,0.905,0.389
        c0.399,0.032,1.157-0.341,1.554-0.389c0.241-0.03,0.765-0.129,0.971,0c0.329,0.205,0.373,1.155,0.679,1.392
        c0.287,0.222,1.095,0.444,1.425,0.291c0.385-0.179,0.744-1.07,0.809-1.489c0.101-0.643-0.059-2.066-0.486-2.558
        c-0.246-0.283-1.138-0.241-1.423-0.485c-0.278-0.237-0.555-0.952-0.68-1.295c-0.194-0.529-0.46-1.638-0.486-2.201
        c-0.017-0.383-0.04-1.218,0.194-1.521c0.347-0.448,1.537-0.82,2.103-0.841c0.729-0.029,2.061,0.782,2.784,0.874
        c0.748,0.094,2.275,0.003,3.01-0.163c0.461-0.103,1.285-0.62,1.748-0.711c0.413-0.081,1.278,0.114,1.683,0
        c0.539-0.152,1.686-0.653,1.91-1.166c0.132-0.302-0.112-0.999-0.259-1.294c-0.141-0.281-0.723-0.647-0.841-0.939
        c-0.173-0.428,0.047-1.397-0.065-1.845c-0.17-0.673-0.8-1.946-1.23-2.492c-0.358-0.456-1.258-1.237-1.78-1.489
        c-0.506-0.245-1.692-0.217-2.202-0.454c-0.214-0.099-0.641-0.369-0.743-0.582c-0.185-0.377,0.09-1.272,0-1.682
        c-0.088-0.393-0.479-1.11-0.68-1.457c-0.169-0.291-0.653-0.788-0.777-1.1c-0.233-0.585-0.171-1.891-0.356-2.493
        c-0.119-0.388-0.722-1.051-0.712-1.456c0.009-0.334,0.472-0.883,0.647-1.166c0.212-0.342,0.573-1.103,0.907-1.327
        c0.419-0.281,1.51-0.222,1.975-0.421c0.251-0.107,0.777-0.373,0.905-0.615c0.08-0.15-0.095-0.539,0-0.679
        c0.177-0.259,1.028-0.174,1.166-0.454c0.141-0.287-0.208-0.946-0.355-1.23c-0.179-0.341-0.697-0.927-0.971-1.197
        c-0.293-0.287-0.999-0.721-1.295-1.003c-0.199-0.192-0.598-0.59-0.712-0.842c-0.226-0.5-0.582-1.686-0.323-2.169
        c0.189-0.354,1.238-0.343,1.457-0.679c0.264-0.408-0.121-1.482,0.032-1.942c0.235-0.71,1.165-1.961,1.748-2.428
        c0.433-0.348,1.536-0.642,2.039-0.875c0.231-0.107,0.738-0.264,0.906-0.453c0.408-0.456,0.577-1.746,0.842-2.298
        c0.196-0.409,0.816-1.116,0.939-1.554c0.151-0.546-0.126-1.713,0-2.266c0.099-0.437,0.735-1.171,0.777-1.618
        c0.043-0.467-0.335-1.368-0.486-1.812c-0.116-0.342-0.492-0.973-0.551-1.328c-0.062-0.376-0.095-1.192,0.097-1.521
        c0.141-0.242,0.745-0.412,0.939-0.615c0.272-0.285,0.669-0.989,0.809-1.359c0.172-0.454,0.252-1.435,0.356-1.909
        c0.113-0.514,0.154-1.661,0.517-2.04c0.374-0.389,1.705-0.218,2.071-0.615c0.276-0.295,0.325-1.488,0.325-1.586
        c0-0.097-0.575-1.168-0.68-1.587c-0.045-0.179-0.075-0.496-0.093-0.826c0.473-0.143,0.98-0.359,1.242-0.566
        c0.274-0.216,0.494-0.958,0.777-1.165c0.56-0.412,1.981-0.678,2.67-0.776c0.793-0.114,2.406,0.053,3.204,0
        c0.819-0.055,2.484-0.151,3.253-0.437c0.435-0.163,1.106-1.143,1.554-1.02c0.429,0.118,0.485,1.266,0.777,1.602
        c0.173,0.198,0.612,0.564,0.874,0.582c0.281,0.021,0.834-0.272,1.02-0.486c0.292-0.336,0.395-1.276,0.534-1.699
        c0.128-0.39,0.267-1.242,0.534-1.554c0.321-0.375,1.254-0.889,1.747-0.922c0.284-0.02,0.821,0.247,1.068,0.388
        c0.562,0.322,1.437,1.317,1.991,1.652c0.405,0.244,1.344,0.48,1.748,0.728c0.544,0.333,1.637,1.092,1.942,1.651
        c0.311,0.57,0.199,1.94,0.34,2.574c0.105,0.472,0.209,1.538,0.582,1.844c0.424,0.349,1.65,0.116,2.185,0.243
        c0.58,0.137,1.678,0.611,2.234,0.825c0.451,0.176,1.312,0.716,1.796,0.729c0.295,0.008,0.848-0.51,1.117-0.388
        c0.535,0.244,0.459,1.768,0.874,2.185c0.481,0.482,1.847,0.954,2.524,1.019c0.64,0.063,2.012-0.097,2.525-0.485
        c0.519-0.394,1.015-1.697,1.165-2.331c0.112-0.473-0.171-1.488,0-1.942c0.188-0.499,0.918-1.391,1.408-1.602
        c0.603-0.259,2.016-0.154,2.622,0.097c0.46,0.192,1.387,0.867,1.456,1.36c0.059,0.42-0.774,1.044-0.874,1.456
        c-0.086,0.354-0.116,1.16,0.097,1.456c0.222,0.31,1.088,0.376,1.408,0.583c0.238,0.154,0.678,0.535,0.825,0.777
        c0.184,0.302,0.084,1.181,0.389,1.359c0.504,0.297,1.75-0.286,2.282-0.534c0.44-0.205,1.101-1.339,1.554-1.165
        c0.588,0.226,0.239,1.896,0.485,2.476c0.134,0.313,0.644,0.805,0.778,1.117c0.259,0.604,0.29,1.964,0.533,2.573
        c0.196,0.492,0.717,1.451,1.118,1.797c0.39,0.338,1.546,0.443,1.894,0.825c0.317,0.349,0.508,1.331,0.582,1.797
        c0.085,0.54-0.038,1.64,0,2.185c0.048,0.722-0.12,2.352,0.388,2.864c0.242,0.244,1.052,0.349,1.359,0.194
        c0.36-0.18,0.471-1.146,0.777-1.408c0.54-0.462,1.978-0.818,2.671-0.97c0.559-0.124,1.734-0.361,2.282-0.194
        c0.446,0.135,1.051,0.934,1.457,1.165c0.416,0.238,1.38,0.437,1.796,0.68c0.395,0.229,1.159,0.782,1.408,1.165
        c0.415,0.634,0.329,2.342,0.825,2.912c0.357,0.411,1.453,0.796,1.991,0.875c0.689,0.101,2.124-0.072,2.767-0.34
        c0.599-0.249,1.625-1.104,2.04-1.602c0.184-0.222,0.369-0.571,0.553-0.952l0.272,0.272c0.328,0.328,0.968,0.999,1.311,1.311
        c0.197,0.18,0.66,0.468,0.825,0.679c0.246,0.318,0.39,1.179,0.679,1.457c0.36,0.347,1.558,0.367,1.845,0.777
        c0.265,0.377,0.156,1.386,0.097,1.845c-0.036,0.277-0.164,0.85-0.34,1.068c-0.123,0.152-0.488,0.424-0.68,0.388
        c-0.17-0.032-0.286-0.451-0.436-0.534c-0.333-0.185-1.132-0.266-1.506-0.195c-0.246,0.046-0.718,0.29-0.874,0.486
        c-0.293,0.365-0.526,1.328-0.534,1.796c-0.01,0.578,0.828,1.712,0.582,2.234c-0.246,0.52-1.599,0.666-2.088,0.97
        c-0.425,0.266-1.081,1.096-1.553,1.263c-0.333,0.117-1.115-0.195-1.409,0c-0.262,0.175-0.592,0.868-0.485,1.165
        c0.107,0.296,0.933,0.327,1.117,0.583c0.186,0.259,0.246,0.946,0.194,1.262c-0.067,0.418-0.766,1.082-0.776,1.506
        c-0.011,0.467,0.404,1.418,0.776,1.699c0.255,0.193,0.944,0.209,1.263,0.194c0.438-0.022,1.265-0.382,1.699-0.437
        c0.47-0.059,1.459-0.186,1.894,0c0.404,0.172,0.812,1.091,1.214,1.263c0.492,0.208,1.627-0.163,2.136,0
        c0.34,0.109,0.815,0.895,1.165,0.825c0.238-0.048,0.286-0.731,0.509-0.825c0.315-0.133,1.136,0.013,1.336,0.291
        c0.249,0.348,0.068,1.361-0.195,1.699c-0.188,0.242-0.854,0.331-1.141,0.437c-0.296,0.112-1.007,0.177-1.189,0.436
        c-0.278,0.399-0.18,1.492,0,1.943c0.117,0.294,0.583,0.793,0.874,0.922c0.51,0.226,1.689,0.176,2.233,0.048
        c0.307-0.072,0.807-0.635,1.117-0.582c0.314,0.052,0.797,0.615,0.875,0.922c0.101,0.404-0.239,1.237-0.437,1.602
        c-0.139,0.258-0.608,0.636-0.777,0.875c-0.217,0.305-0.425,1.09-0.729,1.31c-0.571,0.414-2.211,0.102-2.767,0.534
        c-0.382,0.296-0.682,1.287-0.825,1.749c-0.113,0.364-0.097,1.152-0.243,1.505c-0.183,0.442-0.752,1.244-1.117,1.554
        c-0.33,0.281-1.356,0.39-1.554,0.777c-0.28,0.547,0.029,1.929,0.389,2.428c0.232,0.321,1.034,0.595,1.408,0.728
        c0.689,0.243,2.192,0.298,2.865,0.582c0.514,0.218,1.33,1.045,1.844,1.263c0.382,0.163,1.308,0.333,1.939,0.436
        c0.002,0.428,0.229,1.144,0.344,1.506c0.112,0.353,0.306,1.116,0.582,1.359c0.18,0.16,0.717,0.144,0.922,0.268
        C491.929,524.425,492.435,524.865,492.535,525.162z" pointer-events="auto" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_9_" x="380.825" y="536.376" opacity="0" fill="#FFFFFF" stroke="#010202" stroke-miterlimit="10" enable-background="new    " width="59.957" height="20.14" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_POLAND2_OPOLSKIE" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Opole" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_POLAND2_OPOLSKIE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#7eb08e" d="M341.148,439.122c0.34-0.016,0.66-0.016,0.889,0.003c0.621,0.052,1.937,0.197,2.427,0.583
        c0.279,0.219,0.611,0.909,0.647,1.262c0.047,0.452-0.425,1.298-0.485,1.748c-0.067,0.498,0.238,1.565,0,2.007
        c-0.174,0.323-1.034,0.497-1.23,0.809c-0.192,0.304-0.292,1.079-0.194,1.425c0.076,0.271,0.46,0.747,0.712,0.874
        c0.304,0.154,1.033-0.032,1.359,0.064c0.323,0.097,0.849,0.561,1.165,0.68c0.231,0.088,0.725,0.215,0.971,0.194
        c0.536-0.045,1.472-0.728,2.007-0.776c0.599-0.053,1.762,0.472,2.363,0.453c0.384-0.013,1.118-0.287,1.488-0.388
        c0.569-0.156,1.689-0.533,2.266-0.648c0.424-0.084,1.301-0.071,1.715-0.194c0.505-0.149,1.602-0.492,1.878-0.939
        c0.242-0.392-0.181-1.42,0-1.844c0.123-0.289,0.61-0.767,0.906-0.875c0.42-0.152,1.337,0.182,1.78,0.13
        c0.565-0.065,1.632-0.494,2.169-0.68c0.237-0.082,0.7-0.275,0.938-0.356c0.472-0.159,1.458-0.358,1.91-0.567
        c0.059-0.027,0.134-0.067,0.218-0.117c0.048,0.158,0.113,0.29,0.203,0.359c0.413,0.314,1.555,0.031,2.072,0
        c0.48-0.028,1.437-0.316,1.909-0.226c0.344,0.065,0.957,0.446,1.23,0.663c0.418,0.332,0.947,1.334,1.408,1.602
        c0.675,0.392,2.279,0.666,3.059,0.631c0.686-0.031,2.033-0.389,2.719-0.388c0.504,0.001,1.566,0.022,1.991,0.291
        c0.48,0.304,0.822,1.533,1.262,1.894c0.397,0.324,1.43,0.562,1.894,0.777c0.434,0.199,1.223,0.848,1.7,0.874
        c0.234,0.013,0.651-0.41,0.874-0.34c0.434,0.134,0.814,1.1,1.019,1.505c0.147,0.287,0.253,0.949,0.437,1.214
        c0.182,0.262,0.655,0.863,0.971,0.825c0.491-0.058,0.94-1.162,1.165-1.602c0.215-0.421,0.47-1.339,0.582-1.797
        c0.12-0.49-0.022-1.638,0.34-1.99c0.369-0.359,1.536-0.403,2.039-0.291c0.325,0.072,0.918,0.461,1.117,0.728
        c0.485,0.65,0.687,2.333,0.923,3.108c0.178,0.583,0.28,1.917,0.728,2.33c0.283,0.262,1.201,0.105,1.506,0.341
        c0.335,0.26,0.55,1.146,0.776,1.505c0.236,0.374,0.692,1.178,1.068,1.409c0.231,0.141,0.798,0.217,1.068,0.194
        c0.197-0.018,0.467-0.077,0.749-0.163c0.018,0.331,0.047,0.648,0.093,0.826c0.105,0.419,0.68,1.49,0.68,1.587
        c0,0.098-0.049,1.29-0.325,1.586c-0.367,0.397-1.697,0.226-2.071,0.615c-0.364,0.379-0.405,1.525-0.517,2.04
        c-0.105,0.474-0.185,1.455-0.356,1.909c-0.14,0.37-0.536,1.074-0.809,1.359c-0.195,0.203-0.799,0.373-0.939,0.615
        c-0.192,0.33-0.159,1.145-0.097,1.521c0.059,0.354,0.435,0.986,0.551,1.328c0.151,0.443,0.529,1.345,0.486,1.812
        c-0.042,0.447-0.678,1.181-0.777,1.618c-0.126,0.553,0.151,1.72,0,2.266c-0.122,0.438-0.743,1.145-0.939,1.554
        c-0.266,0.553-0.434,1.842-0.842,2.298c-0.168,0.19-0.676,0.347-0.906,0.453c-0.503,0.233-1.606,0.527-2.039,0.875
        c-0.583,0.467-1.513,1.718-1.748,2.428c-0.153,0.46,0.233,1.534-0.032,1.942c-0.219,0.337-1.268,0.325-1.457,0.679
        c-0.259,0.483,0.097,1.669,0.323,2.169c0.114,0.252,0.512,0.651,0.712,0.842c0.296,0.282,1.002,0.716,1.295,1.003
        c0.275,0.27,0.793,0.856,0.971,1.197c0.147,0.284,0.497,0.943,0.355,1.23c-0.138,0.28-0.989,0.195-1.166,0.454
        c-0.095,0.14,0.08,0.529,0,0.679c-0.128,0.242-0.654,0.508-0.905,0.615c-0.465,0.198-1.556,0.139-1.975,0.421
        c-0.334,0.224-0.695,0.985-0.907,1.327c-0.175,0.283-0.638,0.832-0.647,1.166c-0.01,0.405,0.592,1.068,0.712,1.456
        c0.186,0.602,0.123,1.907,0.356,2.493c0.124,0.312,0.608,0.809,0.777,1.1c0.201,0.348,0.592,1.064,0.68,1.457
        c0.09,0.411-0.185,1.305,0,1.682c0.103,0.213,0.529,0.484,0.743,0.582c0.51,0.237,1.696,0.209,2.202,0.454
        c0.522,0.252,1.422,1.033,1.78,1.489c0.43,0.546,1.06,1.819,1.23,2.492c0.113,0.448-0.108,1.417,0.065,1.845
        c0.117,0.292,0.7,0.658,0.841,0.939c0.148,0.295,0.391,0.992,0.259,1.294c-0.224,0.513-1.371,1.014-1.91,1.166
        c-0.405,0.114-1.27-0.081-1.683,0c-0.463,0.091-1.287,0.608-1.748,0.711c-0.735,0.166-2.262,0.257-3.01,0.163
        c-0.723-0.092-2.055-0.902-2.784-0.874c-0.566,0.021-1.757,0.393-2.103,0.841c-0.235,0.303-0.211,1.138-0.194,1.521
        c0.026,0.563,0.292,1.671,0.486,2.201c0.125,0.344,0.402,1.058,0.68,1.295c0.285,0.244,1.177,0.201,1.423,0.485
        c0.427,0.492,0.587,1.915,0.486,2.558c-0.065,0.419-0.425,1.31-0.809,1.489c-0.329,0.153-1.137-0.069-1.425-0.291
        c-0.306-0.237-0.35-1.187-0.679-1.392c-0.206-0.129-0.731-0.03-0.971,0c-0.397,0.048-1.155,0.421-1.554,0.389
        c-0.245-0.021-0.659-0.389-0.905-0.389c-0.185,0-0.593,0.127-0.68,0.291c-0.196,0.372,0.307,1.23,0.324,1.651
        c0.026,0.644-0.184,1.923-0.292,2.557c-0.122,0.721-0.503,2.133-0.647,2.848c-0.065,0.323-0.049,1.02-0.227,1.295
        c-0.164,0.255-0.861,0.412-1.004,0.68c-0.167,0.314-0.201,1.131,0,1.424c0.262,0.377,1.391,0.324,1.716,0.648
        c0.338,0.336,0.7,1.303,0.68,1.779c-0.024,0.539-0.626,1.504-0.939,1.943c-0.286,0.401-1.203,0.937-1.392,1.392
        c-0.124,0.299-0.088,0.983,0,1.294c0.067,0.238,0.348,0.662,0.518,0.842c0.271,0.287,1.04,0.604,1.294,0.906
        c0.208,0.247,0.417,0.872,0.55,1.165c0.133,0.289,0.537,0.847,0.518,1.165c-0.017,0.301-0.526,0.748-0.615,1.037
        c-0.079,0.255-0.123,0.807-0.065,1.068c0.059,0.261,0.478,0.673,0.518,0.938c0.051,0.326-0.374,0.985-0.259,1.295
        c0.1,0.271,0.714,0.498,0.905,0.712c0.165,0.185,0.441,0.607,0.519,0.842c0.152,0.462,0.257,1.464,0.161,1.942
        c-0.047,0.241-0.248,0.737-0.453,0.874c-0.475,0.312-1.731,0.352-2.266,0.161c-0.398-0.141-0.86-0.959-1.23-1.165
        c-0.308-0.17-1.042-0.504-1.359-0.355c-0.364,0.171-0.438,1.136-0.68,1.456c-0.329,0.434-1.192,1.13-1.651,1.424
        c-0.577,0.369-1.85,0.899-2.493,1.133c-0.508,0.186-1.591,0.366-2.088,0.582c-0.34,0.149-1.082,0.454-1.262,0.777
        c-0.261,0.466,0.151,1.625,0,2.136c-0.07,0.236-0.299,0.753-0.534,0.825c-0.328,0.102-0.921-0.554-1.263-0.534
        c-0.39,0.024-0.98,0.677-1.359,0.777c-0.61,0.162-1.908-0.135-2.524,0c-0.313,0.068-0.86,0.436-1.165,0.534
        c-0.482,0.155-1.585,0.085-1.991,0.389c-0.273,0.204-0.315,1.082-0.631,1.213c-0.376,0.156-1.197-0.291-1.554-0.486
        c-0.249-0.136-0.607-0.631-0.874-0.728c-0.343-0.124-1.184-0.241-1.457,0c-0.432,0.379-0.293,1.706-0.291,2.282
        c0.002,0.539,0.113,1.627,0.291,2.136c0.141,0.401,0.761,1.048,0.874,1.456c0.129,0.468,0.123,1.473,0,1.942
        c-0.107,0.405-0.639,1.082-0.825,1.456c-0.177,0.355-0.515,1.077-0.631,1.457c-0.078,0.259-0.187,0.891-0.246,1.264
        c-0.005,0-0.009-0.002-0.013-0.002c-0.503-0.041-1.629-0.14-2.006,0.195c-0.397,0.352-0.578,1.557-0.454,2.071
        c0.083,0.347,0.569,0.987,0.906,1.102c0.409,0.138,1.342-0.654,1.684-0.389c0.511,0.396,0.381,2.025,0.064,2.59
        c-0.237,0.424-1.212,0.852-1.683,0.971c-0.534,0.135-1.691,0.141-2.202-0.064c-0.424-0.171-0.914-1.119-1.359-1.23
        c-0.647-0.162-2.092,0.201-2.589,0.648c-0.451,0.403-0.413,1.84-0.842,2.266c-0.575,0.57-2.234,1.077-3.042,1.1
        c-0.912,0.028-2.646-0.707-3.496-1.036c-0.487-0.188-1.572-0.484-1.878-0.906c-0.363-0.502,0.039-1.956-0.323-2.46
        c-0.494-0.687-2.415-0.92-3.042-1.488c-0.41-0.371-0.896-1.396-1.165-1.879c-0.388-0.694-1.231-2.075-1.425-2.847
        c-0.145-0.581,0.129-1.828-0.065-2.396c-0.195-0.569-0.95-1.571-1.424-1.942c-0.373-0.289-1.277-0.701-1.748-0.712
        c-0.773-0.018-2.141,1.065-2.913,1.036c-0.319-0.012-0.926-0.307-1.165-0.518c-0.398-0.35-0.801-1.375-1.101-1.812
        c-0.45-0.658-1.589-1.811-1.942-2.525c-0.193-0.39-0.422-1.248-0.453-1.683c-0.029-0.406-0.056-1.296,0.194-1.618
        c0.276-0.355,1.238-0.573,1.682-0.648c0.927-0.155,2.818,0.192,3.756,0.129c0.589-0.038,1.78-0.173,2.33-0.388
        c0.577-0.225,1.62-0.936,2.071-1.359c0.472-0.443,1.333-1.462,1.553-2.071c0.321-0.884,0.17-2.816,0.194-3.756
        c0.012-0.469,0.077-1.414,0-1.877c-0.091-0.54-0.73-1.523-0.712-2.071c0.013-0.421,0.541-1.147,0.647-1.554
        c0.141-0.535,0.568-1.741,0.259-2.202c-0.403-0.599-2.071-0.892-2.784-0.776c-0.54,0.088-1.432,0.837-1.813,1.23
        c-0.4,0.413-0.88,1.487-1.23,1.942c-0.378,0.49-1.124,1.549-1.683,1.812c-0.499,0.236-1.651,0.124-2.201,0.13
        c-0.422,0.004-1.264-0.098-1.683-0.065c-0.74,0.059-2.171,0.599-2.913,0.582c-0.931-0.02-3.432-0.841-3.626-0.841
        s-2.218-0.152-2.783,0.259c-0.458,0.334-0.482,1.698-0.907,2.071c-0.304,0.269-1.167,0.575-1.553,0.454
        c-0.417-0.13-1.047-0.926-1.1-1.36c-0.061-0.495,0.787-1.315,0.842-1.813c0.057-0.526-0.217-1.597-0.454-2.071
        c-0.211-0.421-0.78-1.285-1.231-1.425c-0.479-0.147-1.658,0.104-1.942,0.518c-0.238,0.349,0.218,1.261,0.194,1.683
        c-0.028,0.514-0.095,1.638-0.453,2.007c-0.268,0.276-1.131,0.528-1.489,0.389c-0.447-0.176-0.87-1.169-1.036-1.619
        c-0.124-0.337-0.234-1.067-0.194-1.425c0.066-0.6,0.847-1.662,0.841-2.266c-0.003-0.4-0.329-1.18-0.583-1.489
        c-0.341-0.417-1.28-1.079-1.812-1.165c-0.644-0.104-1.873,0.659-2.525,0.647c-0.574-0.009-1.654-0.475-2.201-0.647
        c-0.489-0.153-1.558-0.31-1.942-0.648c-0.864-0.759-1.877-3.884-2.007-4.144c-0.129-0.258-0.359-1.707-0.776-2.071
        c-0.772-0.673-3.088-0.399-4.014-0.842c-0.924-0.44-2.244-2.156-3.173-2.589c-0.626-0.292-2.074-0.266-2.719-0.518
        c-0.657-0.256-1.891-0.995-2.395-1.489c-0.719-0.703-1.466-2.679-2.201-3.367c-0.755-0.703-2.499-1.294-3.611-1.313
        c0.102-0.483,0.24-1.01,0.389-1.243c0.2-0.31,0.977-0.596,1.15-0.923c0.216-0.409-0.078-1.439,0.146-1.845
        c0.107-0.195,0.503-0.457,0.712-0.534c0.335-0.122,1.068-0.091,1.424-0.08c0.493,0.013,1.555-0.057,1.958,0.226
        c0.208,0.145,0.231,0.785,0.454,0.905c0.328,0.18,1.194,0.069,1.488-0.161c0.204-0.159,0.337-0.714,0.356-0.97
        c0.023-0.301-0.268-0.883-0.242-1.183c0.024-0.288,0.32-0.812,0.42-1.084c0.209-0.567,0.693-1.686,0.794-2.283
        c0.097-0.574-0.186-1.777,0-2.33c0.213-0.635,1.214-1.601,1.619-2.136c0.438-0.582,1.517-1.639,1.748-2.33
        c0.107-0.323-0.046-1.023,0-1.36c0.081-0.598,0.2-1.922,0.647-2.33c0.268-0.244,1.064-0.287,1.425-0.259
        c0.291,0.024,0.841,0.255,1.1,0.389c0.475,0.245,1.252,1.033,1.748,1.229c0.647,0.256,2.062,0.475,2.752,0.388
        c0.572-0.071,1.749-0.445,2.136-0.874c0.267-0.294,0.433-1.125,0.453-1.52c0.017-0.346-0.21-1.018-0.258-1.359
        c-0.049-0.347-0.227-1.056-0.13-1.393c0.13-0.442,1.032-1.006,1.133-1.456c0.088-0.396-0.206-1.208-0.356-1.586
        c-0.111-0.28-0.523-0.748-0.615-1.037c-0.127-0.402-0.35-1.322-0.129-1.682c0.187-0.306,1.037-0.338,1.326-0.551
        c0.204-0.149,0.497-0.574,0.648-0.777c0.339-0.452,0.763-1.61,1.263-1.877c0.142-0.076,0.526,0.107,0.648,0
        c0.331-0.29,0.39-1.339,0.226-1.747c-0.143-0.359-1.009-0.636-1.198-0.971c-0.18-0.32-0.204-1.09-0.194-1.457
        c0.011-0.435,0.067-1.362,0.323-1.716c0.227-0.312,0.972-0.74,1.359-0.743c0.264-0.003,0.697,0.378,0.939,0.486
        c0.166,0.074,0.5,0.232,0.68,0.258c0.36,0.055,1.117,0.037,1.456-0.097c0.348-0.136,1.029-0.589,1.165-0.938
        c0.118-0.305-0.043-1.004-0.194-1.295c-0.223-0.427-1.086-0.957-1.393-1.327c-0.171-0.207-0.511-0.647-0.582-0.906
        c-0.106-0.391-0.087-1.243,0.065-1.618c0.123-0.303,0.576-0.838,0.874-0.97c0.444-0.198,1.49,0.174,1.942,0
        c0.573-0.222,1.566-1.091,1.813-1.651c0.115-0.26-0.113-0.874,0-1.133c0.141-0.326,0.928-0.634,1.035-0.971
        c0.089-0.274-0.107-0.87-0.226-1.133c-0.11-0.242-0.425-0.698-0.648-0.841c-0.245-0.157-0.925-0.055-1.132-0.259
        c-0.187-0.181-0.277-0.744-0.259-1.003c0.024-0.334,0.367-0.932,0.518-1.23c0.221-0.437,0.578-1.441,1.004-1.683
        c0.372-0.211,1.348-0.221,1.715,0c0.251,0.151,0.326,0.848,0.55,1.036c0.202,0.17,0.745,0.372,1.003,0.324
        c0.226-0.042,0.584-0.391,0.712-0.582c0.234-0.351,0.455-1.198,0.486-1.619c0.029-0.391-0.232-1.161-0.227-1.554
        c0.005-0.328,0.065-1.008,0.227-1.293c0.158-0.28,0.67-0.73,0.971-0.842c0.28-0.106,0.904,0.058,1.197,0
        c0.378-0.074,1.098-0.379,1.425-0.583c0.257-0.161,0.635-0.67,0.906-0.809c0.273-0.139,0.92-0.131,1.198-0.26
        c0.379-0.174,1.142-0.631,1.327-1.003c0.137-0.275,0.059-0.928,0-1.23c-0.066-0.335-0.497-0.921-0.518-1.262
        c-0.031-0.494,0.292-1.469,0.518-1.91c0.176-0.343,0.882-0.822,0.971-1.198c0.088-0.369-0.383-1.113-0.323-1.489
        c0.054-0.351,0.465-0.973,0.712-1.23c0.199-0.206,0.707-0.502,0.971-0.614c0.429-0.185,1.495-0.113,1.813-0.454
        c0.191-0.203,0.208-0.821,0.194-1.1c-0.013-0.236-0.277-0.672-0.259-0.907c0.037-0.48,0.706-1.277,0.809-1.747
        c0.117-0.529,0.085-1.633,0-2.169c-0.052-0.336-0.208-1.028-0.421-1.294c-0.218-0.271-1.026-0.41-1.198-0.712s-0.071-1.051,0-1.392
        c0.07-0.337,0.275-1.054,0.55-1.263c0.384-0.29,1.455-0.098,1.91-0.259c0.339-0.119,1.009-0.461,1.231-0.744
        c0.151-0.194,0.093-0.793,0.291-0.939c0.299-0.22,1.125-0.081,1.488,0c0.261,0.058,0.793,0.253,0.971,0.454
        c0.175,0.196,0.142,0.813,0.324,1.003c0.269,0.281,1.077,0.466,1.456,0.55c0.428,0.094,1.323,0.233,1.748,0.129
        c0.509-0.124,1.288-0.925,1.781-1.1c0.299-0.107,0.952-0.087,1.262-0.163c0.612-0.15,1.777-0.642,2.363-0.874
        c0.482-0.192,1.405-0.688,1.91-0.809C340.05,439.205,340.621,439.145,341.148,439.122z" pointer-events="auto" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_10_" x="303.221" y="502.242" opacity="0" fill="#FFFFFF" stroke="#010202" stroke-miterlimit="10" enable-background="new    " width="59.957" height="20.14" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_POLAND2_DOLNOSLASKIE" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Lower Silesian" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_POLAND2_DOLNOSLASKIE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#7eb08e" d="M340.903,438.058c0.133,0.194,0.206,0.643,0.245,1.064
        c-0.527,0.023-1.097,0.083-1.442,0.166c-0.505,0.12-1.429,0.617-1.91,0.809c-0.585,0.232-1.75,0.724-2.363,0.874
        c-0.31,0.076-0.964,0.056-1.262,0.163c-0.493,0.175-1.272,0.975-1.781,1.1c-0.425,0.104-1.319-0.036-1.748-0.129
        c-0.38-0.084-1.188-0.269-1.456-0.55c-0.182-0.191-0.149-0.807-0.324-1.003c-0.178-0.201-0.71-0.396-0.971-0.454
        c-0.363-0.081-1.189-0.22-1.488,0c-0.198,0.146-0.14,0.744-0.291,0.939c-0.222,0.283-0.892,0.625-1.231,0.744
        c-0.455,0.161-1.526-0.032-1.91,0.259c-0.275,0.208-0.48,0.926-0.55,1.263c-0.071,0.341-0.172,1.09,0,1.392s0.98,0.44,1.198,0.712
        c0.212,0.267,0.368,0.959,0.421,1.294c0.085,0.536,0.117,1.64,0,2.169c-0.103,0.471-0.772,1.268-0.809,1.747
        c-0.018,0.235,0.246,0.671,0.259,0.907c0.014,0.278-0.004,0.896-0.194,1.1c-0.318,0.342-1.383,0.27-1.813,0.454
        c-0.264,0.113-0.772,0.408-0.971,0.614c-0.247,0.257-0.658,0.879-0.712,1.23c-0.059,0.376,0.412,1.12,0.323,1.489
        c-0.089,0.375-0.795,0.855-0.971,1.198c-0.226,0.441-0.548,1.417-0.518,1.91c0.021,0.341,0.452,0.927,0.518,1.262
        c0.059,0.302,0.137,0.955,0,1.23c-0.186,0.372-0.949,0.829-1.327,1.003c-0.278,0.128-0.925,0.12-1.198,0.26
        c-0.271,0.138-0.649,0.648-0.906,0.809c-0.326,0.204-1.047,0.509-1.425,0.583c-0.293,0.058-0.917-0.106-1.197,0
        c-0.301,0.113-0.813,0.562-0.971,0.842c-0.162,0.285-0.222,0.966-0.227,1.293c-0.005,0.393,0.256,1.163,0.227,1.554
        c-0.031,0.422-0.251,1.268-0.486,1.619c-0.128,0.192-0.486,0.54-0.712,0.582c-0.258,0.048-0.801-0.154-1.003-0.324
        c-0.225-0.188-0.299-0.885-0.55-1.036c-0.367-0.221-1.343-0.211-1.715,0c-0.426,0.242-0.783,1.246-1.004,1.683
        c-0.151,0.298-0.494,0.896-0.518,1.23c-0.018,0.259,0.073,0.822,0.259,1.003c0.208,0.203,0.888,0.102,1.132,0.259
        c0.223,0.143,0.538,0.599,0.648,0.841c0.119,0.264,0.315,0.859,0.226,1.133c-0.108,0.338-0.894,0.646-1.035,0.971
        c-0.113,0.26,0.115,0.874,0,1.133c-0.247,0.56-1.241,1.429-1.813,1.651c-0.452,0.174-1.498-0.198-1.942,0
        c-0.298,0.132-0.75,0.667-0.874,0.97c-0.153,0.375-0.171,1.227-0.065,1.618c0.071,0.26,0.412,0.699,0.582,0.906
        c0.307,0.37,1.17,0.9,1.393,1.327c0.151,0.291,0.312,0.99,0.194,1.295c-0.136,0.349-0.817,0.802-1.165,0.938
        c-0.34,0.133-1.096,0.152-1.456,0.097c-0.181-0.026-0.514-0.184-0.68-0.258c-0.242-0.108-0.675-0.489-0.939-0.486
        c-0.387,0.004-1.132,0.431-1.359,0.743c-0.257,0.353-0.312,1.28-0.323,1.716c-0.01,0.367,0.014,1.137,0.194,1.457
        c0.189,0.336,1.054,0.612,1.198,0.971c0.164,0.409,0.105,1.457-0.226,1.747c-0.121,0.107-0.505-0.076-0.648,0
        c-0.5,0.267-0.924,1.425-1.263,1.877c-0.151,0.203-0.444,0.628-0.648,0.777c-0.289,0.213-1.139,0.245-1.326,0.551
        c-0.222,0.36,0.002,1.281,0.129,1.682c0.092,0.288,0.504,0.756,0.615,1.037c0.15,0.377,0.445,1.19,0.356,1.586
        c-0.101,0.45-1.003,1.014-1.133,1.456c-0.097,0.337,0.081,1.047,0.13,1.393c0.048,0.342,0.275,1.014,0.258,1.359
        c-0.019,0.396-0.186,1.226-0.453,1.52c-0.387,0.428-1.564,0.803-2.136,0.874c-0.689,0.087-2.105-0.132-2.752-0.388
        c-0.497-0.196-1.274-0.984-1.748-1.229c-0.259-0.134-0.809-0.365-1.1-0.389c-0.36-0.029-1.157,0.015-1.425,0.259
        c-0.447,0.408-0.565,1.732-0.647,2.33c-0.046,0.338,0.107,1.038,0,1.36c-0.231,0.691-1.31,1.748-1.748,2.33
        c-0.404,0.535-1.405,1.502-1.619,2.136c-0.186,0.553,0.097,1.756,0,2.33c-0.101,0.596-0.584,1.716-0.794,2.283
        c-0.1,0.272-0.396,0.796-0.42,1.084c-0.025,0.3,0.265,0.882,0.242,1.183c-0.019,0.257-0.152,0.812-0.356,0.97
        c-0.294,0.23-1.16,0.341-1.488,0.161c-0.223-0.12-0.245-0.76-0.454-0.905c-0.403-0.283-1.465-0.213-1.958-0.226
        c-0.356-0.011-1.089-0.042-1.424,0.08c-0.209,0.077-0.605,0.339-0.712,0.534c-0.224,0.406,0.071,1.436-0.146,1.845
        c-0.173,0.327-0.95,0.613-1.15,0.923c-0.149,0.233-0.287,0.76-0.389,1.243c-0.067-0.001-0.131-0.001-0.193,0.002
        c-0.788,0.04-2.077,0.4-2.605,0.988c-0.493,0.549-1.26,1.869-1.425,2.59c-0.197,0.869-0.05,2.703,0.194,3.56
        c0.204,0.711,0.925,2.046,1.425,2.59c0.315,0.344,1.333,0.624,1.554,1.036c0.48,0.899-0.213,3.081,0,4.078
        c0.14,0.66,0.584,2.027,1.1,2.46c0.512,0.428,2.028,0.288,2.59,0.648c0.631,0.403,1.534,1.666,1.877,2.33
        c0.41,0.792,0.828,2.551,0.971,3.432c0.169,1.04,0.314,3.161,0.194,4.207c-0.082,0.719-0.117,2.487-0.777,2.784
        c-0.508,0.229-1.589-0.602-2.006-0.97c-0.246-0.217-0.616-0.788-0.713-1.101c-0.209-0.68,0.382-2.293-0.064-2.848
        c-0.387-0.48-1.911-0.964-2.396-0.583c-0.534,0.421,0.265,2.094,0,2.719c-0.254,0.601-1.288,1.533-1.877,1.814
        c-0.751,0.355-2.484,0.241-3.301,0.388c-1.017,0.183-3.117,0.46-4.014,0.971c-0.746,0.427-1.969,1.716-2.395,2.46
        c-0.609,1.063-0.552,3.752-1.295,4.726c-0.422,0.555-1.87,0.967-2.395,1.425c-0.529,0.462-1.124,1.879-1.748,2.201
        c-0.836,0.431-2.836,0.393-3.755,0.194c-0.963-0.208-2.794-1.123-3.496-1.813c-0.63-0.619-1.123-2.425-1.683-3.107
        c-0.774-0.943-3.237-2.005-3.755-3.108c-0.323-0.688,0.091-2.288,0-3.043c-0.139-1.141-0.546-3.459-1.1-4.467
        c-0.281-0.51-1.208-1.268-1.619-1.682c-0.701-0.707-2.155-2.069-2.848-2.784c-0.798-0.82-2.466-2.417-3.107-3.366
        c-0.402-0.594-0.737-2.069-1.231-2.59c-0.304-0.321-1.239-0.531-1.553-0.842c-0.449-0.443-0.932-1.646-1.231-2.201
        c-0.283-0.529-0.946-1.556-1.1-2.136c-0.184-0.689,0.114-2.178-0.129-2.849c-0.2-0.553-0.877-1.661-1.424-1.877
        c-0.809-0.319-2.591,0.809-3.431,0.582c-0.611-0.164-1.519-1.15-1.942-1.618c-0.445-0.493-1.235-1.588-1.489-2.201
        c-0.327-0.793-0.61-2.511-0.647-3.367c-0.041-0.943-0.092-2.943,0.388-3.756c0.39-0.66,1.868-1.35,2.524-1.747
        c0.86-0.522,2.751-1.28,3.56-1.877c0.24-0.178,0.574-0.713,0.842-0.842c1.027-0.496,3.494-0.044,4.532-0.518
        c0.938-0.427,2.513-1.9,3.043-2.784c0.404-0.672,0.598-2.277,0.777-3.042c0.195-0.837,0.818-2.513,0.712-3.367
        c-0.09-0.733-0.871-2.049-1.294-2.654c-0.348-0.498-1.22-1.357-1.683-1.749c-0.691-0.582-2.849-1.942-2.913-2.136
        c-0.065-0.194-1.081-1.736-1.619-2.136c-0.699-0.52-3.042-1.23-3.237-1.293c-0.194-0.065-2.734,0.019-3.626,0.194
        c-0.467,0.092-1.489,0.232-1.812,0.582c-0.338,0.366-0.3,1.467-0.453,1.943c-0.129,0.4-0.25,1.416-0.648,1.553
        c-0.527,0.181-1.572-0.683-1.942-1.101c-0.419-0.472-0.479-1.935-0.971-2.331c-0.563-0.453-2.135-0.647-2.848-0.517
        c-0.964,0.174-2.814,1.198-3.367,2.007c-0.276,0.406-0.211,1.463-0.323,1.942c-0.127,0.543-0.195,1.809-0.648,2.136
        c-0.589,0.427-2.236,0.203-2.913-0.065c-0.404-0.16-1.218-0.737-1.294-1.165c-0.068-0.378,0.641-0.982,0.712-1.359
        c0.136-0.721,0.057-2.287-0.324-2.913c-0.212-0.349-0.965-0.798-1.359-0.905c-0.876-0.241-2.767-0.103-3.625,0.194
        c-0.374,0.128-0.901,0.935-1.296,0.906c-0.467-0.036-1.168-0.867-1.359-1.294c-0.23-0.518,0.195-1.762-0.064-2.267
        c-0.307-0.595-1.922-0.983-2.136-1.618c-0.216-0.636,0.765-1.92,0.712-2.59c-0.077-0.974-0.805-3.018-1.619-3.561
        c-0.849-0.566-3.062-0.213-4.078-0.128c-0.576,0.047-1.689,0.432-2.266,0.453c-1.027,0.036-3.103-0.194-4.078-0.518
        c-0.812-0.27-2.251-1.234-2.978-1.683c-0.615-0.38-1.774-1.249-2.395-1.618c-0.392-0.233-1.181-0.714-1.619-0.841
        c-0.579-0.17-2.201-0.194-2.395-0.26c-0.194-0.065-1.865-1.033-2.525-1.294c-1.014-0.403-3.124-1.037-4.207-1.165
        c-0.466-0.055-1.465-0.158-1.877,0.064c-0.42,0.226-0.637,1.446-1.101,1.553c-0.592,0.138-1.8-0.658-2.136-1.165
        c-0.277-0.421-0.191-1.503-0.194-2.006c-0.003-0.569,0.229-1.698,0.194-2.267c-0.023-0.396-0.09-1.232-0.323-1.553
        c-0.231-0.317-1.074-0.509-1.359-0.777c-0.452-0.425-1.002-1.566-1.359-2.071c-0.387-0.548-1.251-1.576-1.619-2.136
        c-0.397-0.606-1.218-1.83-1.424-2.524c-0.144-0.482-0.272-1.525-0.13-2.007c0.155-0.521,1.131-1.23,1.296-1.748
        c0.205-0.648,0.099-2.058-0.065-2.719c-0.157-0.632-0.677-1.9-1.165-2.331c-0.218-0.193-0.844-0.248-1.1-0.388
        c-0.395-0.214-1.017-0.911-1.425-1.1c-0.421-0.195-1.368-0.52-1.813-0.389c-0.329,0.098-0.671,0.804-0.971,0.971
        c-0.362,0.202-1.298,0.655-1.618,0.388c-0.224-0.188,0.193-0.902,0.064-1.165c-0.198-0.408-1.038-1.092-1.488-1.037
        c-0.683,0.085-1.258,1.664-1.813,2.073c-0.385,0.283-1.307,0.963-1.747,0.776c-0.523-0.222-0.372-1.738-0.777-2.136
        c-0.562-0.554-2.355-0.553-2.978-1.036c-0.185-0.143-0.355-0.61-0.518-0.777c-0.235-0.242-0.777-0.683-1.1-0.777
        c-0.56-0.161-1.8-0.114-2.33,0.129c-0.593,0.272-1.568,1.274-1.813,1.878c-0.236,0.584-0.457,2.089,0,2.525
        c0.247,0.234,1.039-0.178,1.359-0.065c0.347,0.121,0.95,0.636,1.101,0.97c0.286,0.635,0.193,2.116,0,2.784
        c-0.203,0.698-1.352,1.76-1.554,2.459c-0.176,0.606-0.131,1.907,0,2.525c0.141,0.667,1.036,2.33,1.036,2.524s0.316,1.53,0,1.877
        c-0.7,0.77-3.116,0.555-4.143,0.389c-0.28-0.044-0.76-0.395-1.037-0.452c-0.522-0.111-1.614-0.111-2.136,0
        c-0.589,0.122-1.6,0.948-2.201,0.97c-0.722,0.026-2.158-0.517-2.719-0.97c-0.504-0.407-1.191-1.578-1.36-2.202
        c-0.164-0.61-0.142-1.929,0.065-2.525c0.292-0.84,1.491-2.234,2.136-2.847c0.481-0.458,1.634-1.143,2.201-1.49
        c0.458-0.279,1.604-0.574,1.877-1.036c0.436-0.738-0.233-2.606,0-3.432c0.227-0.804,1.396-2.09,1.748-2.847
        c0.303-0.65,0.67-2.044,0.906-2.719c0.358-1.025,1.004-3.108,1.489-4.079c0.345-0.69,1.464-1.848,1.683-2.59
        c0.271-0.914,0-3.496,0-3.819c0-0.324,0.595-2.003,0.842-2.654c0.326-0.864,1.086-2.55,1.359-3.432
        c0.323-1.046,0.993-3.178,0.971-4.273c-0.012-0.619-0.71-1.778-0.648-2.394c0.092-0.908,1.554-3.108,1.554-3.302
        s0.269-2.904,0-3.82c-0.286-0.977-1.538-2.657-2.202-3.431c-0.365-0.427-1.439-0.982-1.683-1.489
        c-0.281-0.584-0.317-1.97-0.129-2.59c0.1-0.33,0.484-0.846,0.838-1.305c0.357,0.107,0.868,0.25,1.104,0.269
        c0.518,0.043,1.679,0.149,2.071-0.194c0.294-0.257,0.271-1.139,0.356-1.52c0.103-0.469,0.016-1.576,0.388-1.879
        c0.383-0.311,1.482,0.03,1.975,0c0.48-0.028,1.547,0.09,1.91-0.226c0.265-0.231,0.278-1.017,0.355-1.359
        c0.058-0.258,0.038-0.824,0.193-1.036c0.147-0.197,0.633-0.409,0.874-0.453c0.587-0.106,1.823,0.541,2.364,0.291
        c0.281-0.13,0.548-0.755,0.68-1.036c0.167-0.36,0.18-1.233,0.453-1.521c0.313-0.331,1.412-0.275,1.716-0.615
        c0.233-0.261,0.152-1.055,0.323-1.359c0.191-0.334,0.745-0.924,1.101-1.068c0.293-0.118,0.98-0.141,1.263,0
        c0.361,0.181,0.843,0.91,0.971,1.294c0.078,0.238-0.166,0.815,0,1.004c0.264,0.299,1.198,0.291,1.586,0.194
        c0.259-0.065,0.607-0.629,0.874-0.616c0.258,0.014,0.612,0.482,0.777,0.68c0.182,0.217,0.37,0.784,0.582,0.97
        c0.213,0.188,0.788,0.319,1.036,0.454c0.358,0.195,1.126,0.571,1.359,0.905c0.161,0.232,0.066,0.894,0.258,1.102
        c0.168,0.18,0.707,0.373,0.939,0.291c0.341-0.12,0.703-0.853,0.809-1.199c0.088-0.286-0.08-0.909,0-1.198
        c0.08-0.283,0.522-0.725,0.616-1.003c0.154-0.455,0.17-1.43,0.194-1.909c0.014-0.3-0.223-0.997,0-1.198
        c0.312-0.284,1.348,0.42,1.683,0.161c0.441-0.34,0.38-1.644,0.356-2.201c-0.025-0.599-0.51-1.735-0.583-2.33
        c-0.048-0.395-0.131-1.212,0-1.587c0.132-0.381,0.656-1.058,1.003-1.263c0.481-0.281,1.671-0.527,2.202-0.355
        c0.228,0.074,0.452,0.603,0.68,0.68c0.518,0.174,1.628-0.372,2.169-0.292c0.386,0.056,1.051,0.527,1.425,0.648
        c0.588,0.19,1.813,0.409,2.428,0.485c0.297,0.038,0.921-0.052,1.197,0.065c0.285,0.12,0.695,0.617,0.906,0.842
        c0.158,0.167,0.412,0.559,0.583,0.712c0.245,0.218,0.843,0.509,1.102,0.712c0.314,0.247,0.904,0.797,1.165,1.101
        c0.166,0.195,0.407,0.656,0.583,0.841c0.162,0.173,0.601,0.394,0.744,0.582c0.18,0.238,0.396,0.809,0.454,1.101
        c0.061,0.31-0.101,0.964,0,1.263c0.116,0.342,0.524,1.06,0.874,1.149c0.543,0.136,1.716-0.465,2.04-0.923
        c0.276-0.391-0.134-1.533,0.162-1.909c0.252-0.322,1.251-0.242,1.553-0.518c0.281-0.256,0.51-1.026,0.615-1.391
        c0.156-0.543,0.014-1.763,0.324-2.233c0.298-0.453,1.438-0.776,1.845-1.133c0.291-0.255,0.822-0.842,0.971-1.198
        c0.191-0.451,0.125-1.464,0.227-1.942c0.079-0.373,0.181-1.19,0.453-1.457c0.355-0.348,1.438-0.391,1.909-0.55
        c0.338-0.114,0.98-0.437,1.328-0.518c0.561-0.13,1.725-0.256,2.297-0.193c0.328,0.035,0.963,0.525,1.263,0.388
        c0.458-0.209,0.726-1.349,0.81-1.845c0.081-0.488-0.213-1.481-0.162-1.975c0.046-0.452,0.381-1.313,0.517-1.747
        c0.171-0.545,0.539-1.625,0.713-2.169c0.108-0.339,0.265-1.039,0.421-1.359c0.149-0.309,0.66-0.811,0.777-1.133
        c0.112-0.305,0.114-0.97,0.129-1.294c0.018-0.355-0.085-1.079,0-1.425c0.105-0.418,0.56-1.169,0.81-1.52
        c0.235-0.332,0.779-0.949,1.1-1.199c0.312-0.242,1.114-0.464,1.392-0.743c0.465-0.467,0.796-1.835,1.23-2.331
        c0.203-0.231,0.71-0.618,1.003-0.713c0.432-0.136,1.38,0.134,1.813,0c0.468-0.143,1.281-0.746,1.619-1.1
        c0.255-0.269,0.642-0.918,0.777-1.263c0.198-0.506,0.054-1.733,0.421-2.136c0.17-0.187,0.717-0.276,0.971-0.291
        c0.279-0.016,0.879,0.025,1.1,0.194c0.271,0.206,0.251,1.152,0.583,1.23c0.23,0.054,0.641-0.37,0.744-0.582
        c0.117-0.241,0.091-0.817,0-1.068c-0.09-0.249-0.44-0.698-0.679-0.81c-0.176-0.082-0.606,0.093-0.777,0
        c-0.314-0.17-0.766-0.841-0.777-1.198c-0.01-0.344,0.383-1.025,0.68-1.198c0.398-0.232,1.39-0.072,1.845,0
        c0.361,0.057,1.122,0.207,1.392,0.453c0.241,0.219,0.327,0.933,0.518,1.198c0.12,0.167,0.415,0.499,0.615,0.55
        c0.177,0.046,0.53-0.181,0.712-0.161c0.248,0.026,0.738,0.236,0.907,0.421c0.162,0.178,0.202,0.698,0.324,0.905
        c0.205,0.351,0.699,1.058,1.068,1.23c0.359,0.169,1.203-0.075,1.586,0.033c0.569,0.161,1.448,1.224,2.039,1.199
        c0.488-0.023,1.113-1.017,1.587-1.133c0.23-0.056,0.755-0.021,0.938,0.129c0.28,0.228,0.496,0.999,0.485,1.359
        c-0.01,0.351-0.358,1-0.549,1.295c-0.168,0.26-0.669,0.65-0.842,0.905c-0.181,0.268-0.48,0.851-0.551,1.166
        c-0.076,0.34-0.189,1.1,0,1.391c0.121,0.186,0.588,0.346,0.81,0.356c0.296,0.017,0.836-0.368,1.132-0.356
        c0.584,0.024,1.867,0.374,2.17,0.875c0.285,0.474,0.054,1.724-0.228,2.201c-0.165,0.281-0.864,0.491-1.068,0.745
        c-0.147,0.184-0.35,0.638-0.355,0.874c-0.008,0.272,0.142,0.863,0.355,1.036c0.202,0.164,0.799,0.021,1.036,0.129
        c0.297,0.135,0.721,0.664,0.971,0.874c0.346,0.288,0.981,1.017,1.424,1.101c0.198,0.038,0.599-0.129,0.777-0.226
        c0.463-0.255,1.315-0.94,1.586-1.393c0.256-0.427,0.189-1.518,0.453-1.942c0.237-0.379,1.04-0.853,1.392-1.132
        c0.488-0.387,1.537-1.077,1.974-1.521c0.361-0.367,0.863-1.289,1.23-1.651c0.306-0.301,1.107-0.683,1.392-1.003
        c0.288-0.326,0.763-1.095,0.842-1.522c0.095-0.514-0.339-1.55-0.291-2.071c0.037-0.407,0.326-1.191,0.518-1.553
        c0.119-0.226,0.448-0.618,0.615-0.811c0.368-0.422,1.173-1.205,1.586-1.586c0.219-0.2,0.732-0.631,1.087-0.925
        c0.08,0.129,0.164,0.24,0.241,0.311c0.246,0.225,0.95,0.337,1.229,0.517c0.482,0.31,1.289,1.139,1.684,1.554
        c0.434,0.458,1.203,1.465,1.618,1.942c0.244,0.28,0.67,0.935,1.004,1.1c0.495,0.246,1.715-0.068,2.2,0.194
        c0.265,0.142,0.504,0.762,0.745,0.939c0.314,0.229,1.136,0.331,1.457,0.551c0.331,0.227,0.768,0.93,1.068,1.198
        c0.295,0.262,0.925,0.766,1.294,0.905c0.481,0.182,1.666-0.123,2.039,0.227c0.601,0.562,0.449,2.426,0.583,3.237
        c0.079,0.476,0.26,1.428,0.291,1.909c0.024,0.371,0.112,1.145-0.033,1.489c-0.18,0.427-1.12,0.898-1.294,1.328
        c-0.187,0.457-0.181,1.514,0,1.974c0.109,0.276,0.565,0.705,0.809,0.875c0.23,0.159,0.792,0.283,1.036,0.421
        c0.202,0.113,0.533,0.456,0.744,0.55c0.408,0.182,1.316,0.248,1.748,0.356c0.549,0.137,1.665,0.391,2.169,0.647
        c0.191,0.098,0.473,0.462,0.68,0.518c0.385,0.105,1.202-0.309,1.586-0.194c0.214,0.063,0.559,0.393,0.679,0.583
        c0.29,0.453,0.404,1.563,0.583,2.071c0.103,0.29,0.316,0.875,0.485,1.133c0.194,0.297,0.791,0.73,0.971,1.035
        c0.212,0.359,0.25,1.268,0.517,1.587c0.254,0.302,1.168,0.42,1.393,0.744c0.287,0.417-0.112,1.663,0.258,2.007
        c0.403,0.375,1.65,0.128,2.202,0.128c0.438,0.002,1.328-0.222,1.748-0.097c0.338,0.103,0.898,0.579,1.133,0.842
        c0.28,0.314,0.551,1.152,0.841,1.456c0.279,0.292,0.974,0.754,1.359,0.875c0.294,0.09,0.94-0.07,1.23,0.032
        c0.403,0.142,0.962,0.882,1.359,1.037c0.523,0.201,1.708,0.386,2.234,0.194c0.301-0.11,0.749-0.627,0.906-0.906
        c0.149-0.262,0.053-0.982,0.291-1.165c0.348-0.266,1.364-0.178,1.748,0.033c0.41,0.224,0.57,1.534,1.036,1.553
        c0.352,0.015,0.694-0.799,0.874-1.1c0.168-0.279,0.274-0.981,0.518-1.199c0.405-0.357,1.535-0.555,2.072-0.614
        c0.234-0.027,0.732-0.049,0.938,0.065c0.335,0.184,0.569,1.05,0.907,1.229c0.379,0.2,1.297,0.162,1.715,0.065
        c0.349-0.081,0.919-0.578,1.262-0.68c0.288-0.085,0.906-0.169,1.199-0.097c0.324,0.079,0.805,0.781,1.132,0.712
        c0.268-0.056,0.543-0.642,0.615-0.906c0.155-0.572-0.128-1.772-0.162-2.363c-0.02-0.34-0.005-1.025-0.065-1.359
        c-0.056-0.317-0.395-0.907-0.388-1.23c0.009-0.423,0.231-1.353,0.582-1.586c0.413-0.273,1.488,0.187,1.975,0.097
        c0.627-0.117,1.707-0.904,2.331-1.036c0.815-0.174,2.514-0.151,3.334,0c0.716,0.13,2.086,0.677,2.719,1.036
        c0.376,0.212,0.991,0.843,1.359,1.068c0.334,0.202,1.048,0.543,1.425,0.647c0.479,0.133,1.519,0.028,1.974,0.227
        c0.362,0.158,0.853,0.842,1.198,1.036c0.371,0.207,1.197,0.471,1.619,0.518c0.661,0.075,2.055-0.517,2.654-0.227
        c0.357,0.174,0.704,0.97,0.874,1.327c0.139,0.292,0.159,1.003,0.388,1.23c0.181,0.178,0.745,0.178,0.971,0.292
        c0.253,0.126,0.752,0.44,0.907,0.679c0.243,0.376,0.246,1.323,0.388,1.747c0.174,0.522,0.91,1.456,0.906,2.007
        c-0.004,0.572-0.702,1.567-0.971,2.072c-0.111,0.207-0.336,0.626-0.486,0.809c-0.167,0.204-0.525,0.635-0.776,0.712
        c-0.266,0.081-0.825-0.195-1.1-0.162c-0.235,0.029-0.723,0.176-0.874,0.355c-0.176,0.21-0.246,0.797-0.227,1.068
        c0.027,0.379,0.494,1.047,0.518,1.426c0.036,0.579-0.608,1.691-0.518,2.265c0.093,0.595,0.914,1.559,1.23,2.072
        c0.221,0.359,0.774,1.024,0.906,1.424c0.212,0.64-0.054,2.06,0.194,2.686c0.147,0.369,0.647,1.057,1.004,1.231
        c0.429,0.207,1.478,0.236,1.909,0.032c0.448-0.211,0.789-1.329,1.23-1.553c0.376-0.191,1.268-0.13,1.683-0.065
        c0.418,0.065,1.193,0.426,1.587,0.582c0.27,0.107,0.799,0.345,1.068,0.453c0.441,0.178,1.304,0.696,1.78,0.68
        c0.374-0.013,1.102-0.359,1.359-0.632c0.27-0.284,0.194-1.402,0.582-1.456c0.411-0.058,0.616,1.215,1.02,1.311
        c0.301,0.07,0.83-0.651,1.117-0.534c0.359,0.146,0.535,1.069,0.534,1.456c-0.002,0.415-0.261,1.292-0.583,1.554
        c-0.382,0.309-1.569-0.029-1.942,0.291c-0.28,0.24-0.382,1.044-0.437,1.408c-0.056,0.372-0.165,1.167,0,1.506
        c0.183,0.372,0.993,0.751,1.311,1.02c0.15,0.126,0.503,0.353,0.582,0.533c0.166,0.378-0.113,1.254,0,1.651
        c0.17,0.603,1.104,1.545,1.311,2.136c0.165,0.473,0.328,1.497,0.243,1.991c-0.106,0.614-1.007,1.618-1.117,2.233
        c-0.116,0.65-0.059,2.062,0.291,2.621c0.145,0.232,0.738,0.382,0.923,0.583c0.231,0.252,0.549,0.882,0.631,1.213
        c0.167,0.684-0.354,2.209,0,2.816C339.657,437.593,340.666,437.711,340.903,438.058z" pointer-events="auto" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_11_" x="186.343" y="435.6" opacity="0" fill="#FFFFFF" stroke="#010202" stroke-miterlimit="10" enable-background="new    " width="59.957" height="20.14" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_POLAND2_LUBUSKIE" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Lubusz" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_POLAND2_LUBUSKIE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#7eb08e" d="M218.553,218.718c-0.002,0.003-0.003,0.005-0.005,0.008
        c-0.236,0.373-1.001,0.9-1.197,1.295c-0.283,0.566-0.478,1.86-0.454,2.492c0.024,0.636,0.489,1.844,0.648,2.461
        c0.166,0.646,0.536,1.932,0.647,2.589c0.168,0.994,0.353,3.007,0.356,4.015c0.002,0.877-0.271,2.619-0.291,3.495
        c-0.015,0.656,0.154,1.969,0.097,2.622c-0.033,0.388-0.089,1.21-0.324,1.52c-0.342,0.455-1.649,0.627-2.007,1.068
        c-0.255,0.314-0.17,1.265-0.453,1.554c-0.528,0.54-2.34,0.38-2.88,0.907c-0.466,0.453-0.798,1.811-0.842,2.46
        c-0.027,0.379,0.03,1.215,0.291,1.488c0.374,0.392,1.765,0.095,2.104,0.519c0.51,0.638,0.093,2.452,0.064,3.268
        c-0.024,0.699-0.12,2.097-0.25,2.784c-0.068,0.36-0.184,1.121-0.413,1.408c-0.308,0.388-1.253,0.818-1.723,0.971
        c-0.436,0.143-1.369,0.138-1.821,0.219c-0.485,0.087-1.428,0.403-1.918,0.462c-0.73,0.086-2.212-0.116-2.938,0
        c-0.618,0.098-1.948,0.323-2.379,0.777c-0.256,0.269-0.391,1.061-0.389,1.432c0.004,0.479,0.56,1.365,0.534,1.845
        c-0.02,0.387-0.296,1.173-0.582,1.433c-0.192,0.174-0.823,0.097-0.995,0.291c-0.445,0.502-0.404,1.977-0.437,2.646
        c-0.038,0.76,0.066,2.286,0.194,3.035c0.076,0.441,0.278,1.327,0.486,1.724c0.172,0.328,0.818,0.793,0.947,1.141
        c0.166,0.45,0.008,1.438,0.024,1.918c0.01,0.316-0.075,0.983,0.073,1.263c0.157,0.298,0.888,0.54,1.068,0.825
        c0.213,0.336,0.365,1.156,0.34,1.553c-0.022,0.341-0.413,0.949-0.462,1.287c-0.046,0.318-0.099,1.015,0.073,1.286
        c0.284,0.45,1.511,0.626,1.869,1.02c0.33,0.361,0.639,1.327,0.776,1.796c0.136,0.461,0.309,1.414,0.316,1.894
        c0.007,0.49-0.3,1.453-0.267,1.942c0.018,0.27,0.283,0.773,0.291,1.044c0.015,0.471-0.123,1.455-0.389,1.845
        c-0.168,0.246-0.698,0.637-0.995,0.656c-0.344,0.022-0.972-0.389-1.238-0.607c-0.148-0.121-0.412-0.424-0.461-0.607
        c-0.053-0.194,0.102-0.599,0.097-0.801c-0.008-0.284-0.042-0.877-0.194-1.117c-0.123-0.195-0.525-0.493-0.752-0.534
        c-0.303-0.055-0.957,0.115-1.19,0.315c-0.29,0.251-0.543,1.031-0.607,1.408c-0.074,0.432-0.069,1.354,0.121,1.748
        c0.2,0.414,1.013,0.936,1.359,1.238c0.28,0.243,0.83,0.744,1.141,0.947c0.352,0.23,1.133,0.556,1.506,0.752
        c0.344,0.181,1.077,0.486,1.359,0.753c0.413,0.389,0.993,1.396,1.213,1.918c0.183,0.431,0.402,1.354,0.437,1.821
        c0.033,0.431-0.13,1.292-0.146,1.724c-0.029,0.817,0.157,2.461,0.041,3.27c-0.051,0.351-0.219,1.066-0.421,1.359
        c-0.158,0.227-0.641,0.529-0.874,0.68c-0.261,0.169-0.893,0.35-1.1,0.582c-0.312,0.35-0.558,1.295-0.68,1.748
        c-0.179,0.664-0.397,2.031-0.421,2.719c-0.031,0.868,0.294,2.595,0.292,3.463c-0.003,0.813-0.256,2.426-0.292,3.238
        c-0.024,0.525-0.13,1.593,0,2.103c0.133,0.526,0.721,1.467,1.035,1.91c0.147,0.205,0.581,0.512,0.68,0.744
        c0.203,0.478,0.195,1.557,0.13,2.071c-0.05,0.389-0.262,1.167-0.486,1.489c-0.201,0.288-0.924,0.571-1.101,0.874
        c-0.209,0.361-0.269,1.234-0.258,1.651c0.013,0.513,0.13,1.585,0.421,2.008c0.262,0.381,1.133,0.81,1.553,1.003
        c0.284,0.131,0.902,0.256,1.198,0.356c0.279,0.095,0.806,0.409,1.101,0.42c0.371,0.015,1.054-0.396,1.424-0.42
        c0.36-0.023,1.081,0.118,1.425,0.226c0.3,0.095,0.919,0.32,1.133,0.55c0.355,0.383,0.702,1.425,0.777,1.942
        c0.048,0.338,0.01,1.048-0.13,1.359c-0.121,0.272-0.571,0.696-0.809,0.874c-0.246,0.184-0.939,0.288-1.101,0.55
        c-0.149,0.241-0.04,0.853,0,1.132c0.065,0.46,0.302,1.37,0.518,1.781c0.131,0.25,0.473,0.723,0.711,0.874
        c0.253,0.161,0.931,0.104,1.165,0.292c0.277,0.221,0.469,0.955,0.648,1.262c0.141,0.242,0.386,0.777,0.615,0.938
        c0.326,0.23,1.457,0.356,1.553,0.356c0.098,0,1.41-0.042,1.813-0.259c0.202-0.108,0.421-0.557,0.615-0.68
        c0.315-0.197,1.072-0.303,1.425-0.421c0.418-0.14,1.212-0.576,1.651-0.615c0.27-0.024,0.868,0.01,1.068,0.194
        c0.204,0.187,0.153,0.828,0.291,1.068c0.178,0.307,0.925,0.658,1.003,1.003c0.1,0.434-0.446,1.26-0.582,1.683
        c-0.087,0.273-0.282,0.816-0.323,1.101c-0.043,0.296-0.09,0.912,0,1.198c0.089,0.282,0.643,0.677,0.68,0.971
        c0.035,0.275-0.183,0.851-0.388,1.036c-0.168,0.151-0.68,0.111-0.874,0.226c-0.276,0.164-0.757,0.64-0.874,0.939
        c-0.121,0.309-0.21,1.071,0,1.327c0.189,0.229,0.87,0.254,1.165,0.227c0.373-0.035,1.017-0.552,1.392-0.551
        c0.392,0.001,1.165,0.321,1.456,0.583c0.245,0.221,0.355,0.962,0.616,1.165c0.295,0.23,1.083,0.321,1.456,0.356
        c0.283,0.025,0.907,0.107,1.132-0.065c0.122-0.093,0.041-0.585,0.194-0.582c0.287,0.004,0.142,0.879,0.324,1.1
        c0.121,0.149,0.488,0.345,0.68,0.356c0.275,0.018,0.837-0.196,1.035-0.388c0.214-0.207,0.329-0.83,0.454-1.1
        c0.164-0.355,0.453-1.099,0.712-1.392c0.246-0.28,0.86-0.794,1.23-0.841c0.384-0.05,1.09,0.394,1.456,0.517
        c0.459,0.154,1.4,0.392,1.845,0.583c0.323,0.139,0.903,0.55,1.23,0.68c0.271,0.108,0.879,0.146,1.132,0.291
        c0.26,0.148,0.685,0.588,0.842,0.842c0.226,0.362,0.291,1.278,0.55,1.617c0.13,0.173,0.528,0.374,0.712,0.486
        c0.306,0.187,0.959,0.489,1.262,0.68c0.288,0.182,0.893,0.537,1.1,0.81c0.233,0.303,0.407,1.07,0.55,1.424
        c0.109,0.269,0.395,0.785,0.454,1.068c0.098,0.484,0.033,1.482,0,1.975c-0.024,0.358-0.257,1.07-0.194,1.424
        c0.036,0.205,0.181,0.533,0.342,0.791c-0.354,0.294-0.868,0.725-1.087,0.925c-0.413,0.38-1.218,1.164-1.586,1.586
        c-0.167,0.193-0.496,0.584-0.615,0.811c-0.192,0.362-0.481,1.146-0.518,1.553c-0.048,0.521,0.387,1.557,0.291,2.071
        c-0.078,0.427-0.553,1.197-0.842,1.522c-0.285,0.32-1.086,0.702-1.392,1.003c-0.367,0.361-0.869,1.283-1.23,1.651
        c-0.437,0.444-1.486,1.134-1.974,1.521c-0.351,0.279-1.155,0.753-1.392,1.132c-0.264,0.424-0.197,1.514-0.453,1.942
        c-0.271,0.453-1.124,1.138-1.586,1.393c-0.178,0.097-0.579,0.264-0.777,0.226c-0.443-0.084-1.079-0.813-1.424-1.101
        c-0.251-0.209-0.674-0.738-0.971-0.874c-0.237-0.109-0.833,0.035-1.036-0.129c-0.213-0.173-0.363-0.763-0.355-1.036
        c0.005-0.236,0.208-0.69,0.355-0.874c0.203-0.255,0.902-0.464,1.068-0.745c0.282-0.477,0.513-1.727,0.228-2.201
        c-0.302-0.501-1.585-0.851-2.17-0.875c-0.296-0.012-0.836,0.373-1.132,0.356c-0.222-0.011-0.689-0.171-0.81-0.356
        c-0.189-0.291-0.076-1.051,0-1.391c0.071-0.315,0.37-0.898,0.551-1.166c0.173-0.256,0.674-0.646,0.842-0.905
        c0.191-0.295,0.54-0.944,0.549-1.295c0.01-0.36-0.205-1.131-0.485-1.359c-0.184-0.15-0.708-0.186-0.938-0.129
        c-0.474,0.116-1.099,1.111-1.587,1.133c-0.59,0.026-1.47-1.038-2.039-1.199c-0.382-0.108-1.227,0.136-1.586-0.033
        c-0.369-0.172-0.863-0.879-1.068-1.23c-0.121-0.207-0.161-0.728-0.324-0.905c-0.169-0.185-0.659-0.395-0.907-0.421
        c-0.182-0.02-0.535,0.207-0.712,0.161c-0.2-0.051-0.494-0.383-0.615-0.55c-0.192-0.265-0.277-0.978-0.518-1.198
        c-0.271-0.246-1.031-0.396-1.392-0.453c-0.455-0.072-1.446-0.232-1.845,0c-0.297,0.173-0.69,0.854-0.68,1.198
        c0.011,0.356,0.463,1.028,0.777,1.198c0.171,0.093,0.601-0.082,0.777,0c0.239,0.112,0.589,0.561,0.679,0.81
        c0.091,0.251,0.117,0.827,0,1.068c-0.104,0.212-0.514,0.637-0.744,0.582c-0.332-0.078-0.312-1.024-0.583-1.23
        c-0.222-0.17-0.821-0.21-1.1-0.194c-0.253,0.015-0.801,0.105-0.971,0.291c-0.366,0.404-0.223,1.63-0.421,2.136
        c-0.135,0.345-0.522,0.994-0.777,1.263c-0.338,0.353-1.151,0.957-1.619,1.1c-0.433,0.134-1.381-0.136-1.813,0
        c-0.293,0.095-0.8,0.482-1.003,0.713c-0.434,0.496-0.765,1.864-1.23,2.331c-0.278,0.279-1.08,0.502-1.392,0.743
        c-0.321,0.25-0.865,0.867-1.1,1.199c-0.25,0.351-0.705,1.103-0.81,1.52c-0.085,0.346,0.018,1.069,0,1.425
        c-0.015,0.325-0.017,0.989-0.129,1.294c-0.117,0.323-0.628,0.824-0.777,1.133c-0.155,0.321-0.313,1.021-0.421,1.359
        c-0.174,0.544-0.542,1.624-0.713,2.169c-0.136,0.434-0.471,1.295-0.517,1.747c-0.051,0.494,0.243,1.487,0.162,1.975
        c-0.083,0.497-0.351,1.636-0.81,1.845c-0.3,0.137-0.934-0.353-1.263-0.388c-0.573-0.062-1.736,0.063-2.297,0.193
        c-0.348,0.081-0.99,0.405-1.328,0.518c-0.471,0.159-1.554,0.202-1.909,0.55c-0.272,0.268-0.374,1.084-0.453,1.457
        c-0.102,0.478-0.037,1.491-0.227,1.942c-0.149,0.355-0.68,0.943-0.971,1.198c-0.407,0.357-1.547,0.68-1.845,1.133
        c-0.31,0.47-0.167,1.69-0.324,2.233c-0.105,0.365-0.334,1.135-0.615,1.391c-0.303,0.276-1.301,0.196-1.553,0.518
        c-0.296,0.376,0.115,1.518-0.162,1.909c-0.324,0.458-1.497,1.059-2.04,0.923c-0.349-0.089-0.758-0.808-0.874-1.149
        c-0.101-0.299,0.061-0.953,0-1.263c-0.057-0.292-0.274-0.863-0.454-1.101c-0.143-0.189-0.581-0.41-0.744-0.582
        c-0.176-0.186-0.417-0.647-0.583-0.841c-0.261-0.304-0.851-0.854-1.165-1.101c-0.259-0.202-0.857-0.494-1.102-0.712
        c-0.171-0.153-0.424-0.545-0.583-0.712c-0.211-0.225-0.621-0.722-0.906-0.842c-0.276-0.117-0.9-0.027-1.197-0.065
        c-0.615-0.076-1.839-0.295-2.428-0.485c-0.373-0.12-1.039-0.591-1.425-0.648c-0.541-0.08-1.651,0.466-2.169,0.292
        c-0.228-0.077-0.452-0.606-0.68-0.68c-0.53-0.172-1.721,0.074-2.202,0.355c-0.348,0.204-0.871,0.882-1.003,1.263
        c-0.131,0.374-0.048,1.192,0,1.587c0.073,0.595,0.558,1.731,0.583,2.33c0.023,0.557,0.084,1.861-0.356,2.201
        c-0.335,0.259-1.37-0.445-1.683-0.161c-0.223,0.2,0.014,0.897,0,1.198c-0.025,0.48-0.041,1.454-0.194,1.909
        c-0.094,0.278-0.535,0.72-0.616,1.003c-0.08,0.288,0.088,0.911,0,1.198c-0.106,0.346-0.468,1.078-0.809,1.199
        c-0.232,0.082-0.771-0.112-0.939-0.291c-0.192-0.207-0.097-0.87-0.258-1.102c-0.233-0.335-1.001-0.71-1.359-0.905
        c-0.248-0.135-0.823-0.267-1.036-0.454s-0.401-0.753-0.582-0.97c-0.165-0.198-0.519-0.666-0.777-0.68
        c-0.267-0.013-0.615,0.551-0.874,0.616c-0.388,0.097-1.322,0.105-1.586-0.194c-0.166-0.189,0.078-0.766,0-1.004
        c-0.128-0.384-0.61-1.114-0.971-1.294c-0.282-0.141-0.97-0.118-1.263,0c-0.355,0.144-0.91,0.735-1.101,1.068
        c-0.172,0.304-0.09,1.099-0.323,1.359c-0.304,0.34-1.402,0.284-1.716,0.615c-0.273,0.288-0.286,1.161-0.453,1.521
        c-0.131,0.28-0.399,0.905-0.68,1.036c-0.541,0.25-1.777-0.397-2.364-0.291c-0.241,0.044-0.727,0.256-0.874,0.453
        c-0.156,0.211-0.135,0.778-0.193,1.036c-0.077,0.343-0.091,1.128-0.355,1.359c-0.363,0.316-1.431,0.198-1.91,0.226
        c-0.493,0.03-1.591-0.311-1.975,0c-0.372,0.303-0.285,1.41-0.388,1.879c-0.085,0.381-0.062,1.264-0.356,1.52
        c-0.392,0.343-1.553,0.237-2.071,0.194c-0.236-0.019-0.747-0.162-1.104-0.269c0.34-0.442,0.651-0.834,0.651-0.961
        c0-0.259-0.665-1.729-0.454-2.266c0.18-0.455,1.342-0.697,1.619-1.101c0.481-0.699,0.709-2.456,0.777-3.301
        c0.056-0.695-0.075-2.096-0.194-2.784c-0.092-0.529-0.24-1.657-0.582-2.071c-0.443-0.536-2.202-1.1-2.46-1.294
        c-0.259-0.194-1.255-1.151-1.748-1.425c-0.538-0.298-1.73-0.655-2.33-0.777c-0.589-0.12-1.884,0.12-2.396-0.194
        c-0.501-0.308-0.728-1.701-1.23-2.008c-0.554-0.338-1.944-0.262-2.589-0.194c-0.571,0.061-1.631,0.583-2.201,0.648
        c-0.79,0.089-2.478,0.194-3.173-0.194c-0.639-0.357-1.334-1.751-1.683-2.396c-0.332-0.612-1.004-1.892-1.036-2.589
        c-0.036-0.793,0.429-2.409,0.907-3.043c0.705-0.936,3.285-1.559,3.948-2.525c0.365-0.531,0.521-1.88,0.519-2.524
        c-0.003-0.86-0.631-2.51-0.713-3.366c-0.076-0.806-0.297-2.514,0.065-3.237c0.212-0.423,1.295-0.735,1.488-1.166
        c0.213-0.472,0.154-1.636-0.129-2.071c-0.209-0.322-1.015-0.541-1.359-0.711c-0.544-0.271-1.755-0.624-2.201-1.037
        c-0.505-0.468-1.218-1.722-1.359-2.395c-0.193-0.924,0.542-2.824,0.388-3.755c-0.101-0.615-0.709-1.735-1.035-2.266
        c-0.327-0.529-1.236-1.409-1.554-1.943c-0.298-0.498-0.899-1.555-0.907-2.136c-0.006-0.486,0.622-1.334,0.713-1.812
        c0.144-0.763-0.216-2.362,0-3.107c0.101-0.35,0.603-0.911,0.777-1.231c0.314-0.58,0.74-1.844,1.1-2.396
        c0.426-0.653,1.683-1.655,2.071-2.331c0.442-0.767,0.997-2.486,1.101-3.366c0.092-0.79-0.408-2.39-0.26-3.172
        c0.178-0.939,1.521-2.49,1.683-3.431c0.143-0.835-0.504-2.527-0.389-3.367c0.128-0.935,1.095-2.612,1.425-3.496
        c0.299-0.801,1.384-2.431,1.101-3.236c-0.14-0.398-1.127-0.607-1.424-0.907c-0.389-0.392-0.963-1.363-1.165-1.877
        c-0.231-0.582-0.454-2.266-0.454-2.46s0.034-3.063,0.129-4.079c0.089-0.946,0.548-2.806,0.582-3.755
        c0.053-1.428,0.217-4.435-0.452-5.698c-0.34-0.641-1.636-1.453-2.267-1.812c-1.082-0.616-3.804-0.846-4.661-1.748
        c-0.515-0.542-0.868-2.102-0.906-2.849c-0.04-0.792,0.742-2.319,0.648-3.107c-0.095-0.783-1.072-2.114-1.359-2.848
        c-0.397-1.01-1.448-3.069-1.295-4.144c0.116-0.808,1.536-1.95,1.813-2.719c0.269-0.749-0.155-2.534,0.323-3.171
        c0.336-0.448,1.589-0.562,2.072-0.842c0.409-0.237,1.236-0.765,1.489-1.165c0.239-0.379,0.073-1.431,0.388-1.748
        c0.258-0.259,1.167-0.064,1.425-0.323c0.698-0.707,0.872-2.892,0.841-3.884c-0.025-0.774-0.811-2.205-0.841-2.978
        c-0.045-1.155,0.933-3.376,0.906-4.532c-0.013-0.534-0.18-1.659-0.518-2.071c-0.953-1.166-4.284-1.623-5.438-2.59
        c-0.341-0.285-0.688-0.882-1.034-1.46c0.245-0.168,0.474-0.341,0.581-0.466c0.271-0.318,0.435-1.208,0.729-1.505
        c0.209-0.213,0.836-0.347,1.068-0.534c0.242-0.195,0.493-0.844,0.777-0.971c0.4-0.179,1.328-0.029,1.747,0.097
        c0.323,0.097,0.842,0.583,1.165,0.68c0.408,0.122,1.31,0.269,1.7,0.097c0.229-0.101,0.46-0.594,0.631-0.777
        c0.147-0.156,0.427-0.514,0.631-0.583c0.463-0.154,1.532-0.069,1.942,0.195c0.39,0.25,0.629,1.255,0.939,1.602
        c0.269,0.302,0.917,0.826,1.295,0.971c0.341,0.131,1.092,0.161,1.456,0.129c0.467-0.039,1.412-0.242,1.813-0.486
        c0.327-0.198,0.834-0.806,1.035-1.132c0.192-0.31,0.333-1.047,0.518-1.359c0.14-0.238,0.534-0.631,0.712-0.842
        c0.178-0.211,0.555-0.616,0.712-0.842c0.197-0.281,0.495-0.908,0.68-1.198c0.217-0.336,0.754-0.941,0.939-1.294
        c0.202-0.388,0.508-1.22,0.582-1.651c0.067-0.391,0.096-1.202,0-1.587c-0.104-0.418-0.755-1.093-0.809-1.521
        c-0.071-0.553,0.16-1.741,0.518-2.17c0.271-0.325,1.148-0.554,1.553-0.68c0.378-0.117,1.214-0.088,1.553-0.291
        c0.475-0.282,1.115-1.237,1.392-1.715c0.248-0.428,0.681-1.352,0.712-1.845c0.016-0.267-0.17-0.785-0.259-1.036
        c-0.089-0.253-0.491-0.706-0.453-0.971c0.044-0.319,0.574-0.795,0.842-0.971c0.156-0.103,0.59-0.084,0.712-0.226
        c0.107-0.125-0.014-0.527,0.097-0.648c0.169-0.184,0.726-0.27,0.97-0.226c0.277,0.048,0.659,0.607,0.939,0.615
        c0.242,0.007,0.695-0.292,0.842-0.486c0.117-0.154,0.208-0.552,0.194-0.745c-0.02-0.271-0.223-0.816-0.422-1.003
        c-0.23-0.217-1.014-0.162-1.198-0.42c-0.131-0.186-0.033-0.683,0-0.907c0.045-0.309,0.141-0.971,0.356-1.197
        c0.183-0.193,0.741-0.4,1.003-0.356c0.245,0.04,0.552,0.512,0.777,0.616c0.232,0.106,0.786,0.059,1.003,0.194
        c0.599,0.37,1.315,1.655,1.716,2.234c0.218,0.313,0.683,0.934,0.809,1.294c0.092,0.261-0.034,0.877,0.13,1.1
        c0.115,0.158,0.517,0.334,0.712,0.324c0.272-0.015,0.804-0.314,0.939-0.55c0.141-0.246-0.053-0.854,0-1.133
        c0.041-0.222,0.155-0.692,0.323-0.841c0.2-0.178,0.77-0.279,1.036-0.259c0.314,0.023,0.977,0.232,1.165,0.486
        c0.242,0.326-0.02,1.254,0.161,1.619c0.133,0.266,0.601,0.692,0.874,0.809c0.462,0.198,1.522,0.261,2.007,0.129
        c0.641-0.174,1.617-1.172,2.202-1.488c0.95-0.516,3.012-1.205,3.982-1.683c0.617-0.305,1.785-1.041,2.394-1.359
        c0.549-0.286,1.641-0.889,2.234-1.068c0.447-0.135,1.444-0.024,1.845-0.26c0.325-0.19,0.631-0.942,0.907-1.197
        c0.261-0.244,0.974-0.499,1.198-0.777c0.216-0.27,0.428-0.955,0.485-1.295c0.049-0.287,0.119-0.916-0.032-1.165
        c-0.193-0.314-1.028-0.457-1.295-0.712c-0.256-0.242-0.639-0.861-0.744-1.197c-0.095-0.302-0.144-0.957-0.065-1.262
        c0.061-0.24,0.295-0.736,0.518-0.842c0.412-0.197,1.437-0.033,1.813,0.226c0.385,0.265,0.481,1.399,0.874,1.651
        c0.383,0.244,1.375,0.218,1.813,0.097c0.348-0.096,0.977-0.521,1.197-0.809c0.275-0.36,0.311-1.353,0.583-1.716
        c0.284-0.382,1.131-0.881,1.553-1.1c0.534-0.278,1.706-0.591,2.266-0.809c0.818-0.317,2.415-1.052,3.237-1.359
        c0.631-0.236,1.891-0.748,2.557-0.842c0.417-0.058,1.289-0.084,1.683,0.065c0.323,0.122,0.865,0.594,1.068,0.874
        c0.21,0.287,0.284,1.059,0.519,1.326c0.255,0.291,1.091,0.464,1.359,0.745c0.292,0.306,0.407,1.265,0.743,1.521
        c0.393,0.298,1.466,0.23,1.942,0.355c0.333,0.088,0.959,0.519,1.296,0.454c0.654-0.125,1.527-1.285,2.006-1.748
        c0.247-0.239,0.656-0.81,0.939-1.003c0.18-0.123,0.592-0.303,0.809-0.324c0.213-0.02,0.671,0.033,0.842,0.162
        c0.356,0.267,0.371,1.509,0.809,1.586c0.292,0.051,0.603-0.672,0.866-0.809c0.384-0.199,1.298-0.176,1.692-0.356
        c0.314-0.144,0.951-0.529,1.1-0.841c0.151-0.314,0.162-1.103-0.032-1.393c-0.183-0.27-1.04-0.232-1.199-0.518
        c-0.135-0.242-0.012-0.883,0.162-1.1c0.195-0.245,0.862-0.381,1.165-0.454c0.403-0.096,1.29,0.042,1.651-0.162
        c0.282-0.158,0.705-0.719,0.776-1.035c0.064-0.281-0.022-0.929-0.227-1.133c-0.117-0.117-0.486-0.094-0.648-0.129
        c-0.268-0.059-0.87-0.069-1.068-0.259c-0.151-0.146-0.311-0.617-0.226-0.809c0.154-0.348,0.982-0.634,1.359-0.68
        c0.403-0.049,1.198,0.474,1.586,0.355c0.203-0.061,0.488-0.425,0.583-0.615c0.188-0.374,0.047-1.311,0.291-1.651
        c0.248-0.344,1.11-0.621,1.489-0.809c0.246-0.122,0.81-0.257,1.004-0.453c0.241-0.245,0.472-0.928,0.549-1.263
        c0.076-0.323-0.124-1.053,0.065-1.327c0.186-0.268,0.878-0.452,1.198-0.517c0.579-0.119,1.78-0.096,2.364,0
        c0.547,0.09,1.576,0.539,2.103,0.712c0.332,0.109,1.002,0.298,1.328,0.421c0.356,0.134,1.098,0.373,1.391,0.615
        c0.404,0.332,1.062,1.21,1.199,1.716c0.124,0.462,0.043,1.477-0.162,1.909c-0.145,0.305-0.796,0.651-0.971,0.939
        c-0.19,0.311-0.3,1.049-0.421,1.392C218.977,217.709,218.748,218.405,218.553,218.718z" pointer-events="auto" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_12_" x="134.516" y="282.345" opacity="0" fill="#FFFFFF" stroke="#010202" stroke-miterlimit="10" enable-background="new    " width="59.957" height="20.14" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_POLAND2_ZACHODNIOPOMORSKIE" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="West Pomeranian" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_POLAND2_ZACHODNIOPOMORSKIE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#7eb08e" d="M115.81,115.663c0.377-0.216,0.857-0.991,1.198-1.263c0.905-0.723,2.958-1.83,3.884-2.524
        c0.854-0.64,2.33-2.22,3.237-2.784c0.807-0.501,2.565-1.298,3.496-1.489c1.078-0.22,3.309,0.13,4.402,0
        c0.479-0.057,1.428-0.276,1.877-0.453c0.951-0.374,2.636-1.574,3.561-2.006c0.705-0.33,2.166-0.883,2.913-1.101
        c1.206-0.352,3.715-0.679,4.92-1.035c1.596-0.473,4.649-1.829,6.215-2.396c1.189-0.43,3.581-1.254,4.79-1.619
        c1.331-0.4,4.047-1.011,5.373-1.424c1.512-0.471,4.442-1.68,5.957-2.136c1.101-0.332,3.377-0.735,4.467-1.101
        c1.319-0.443,3.816-1.696,5.114-2.2c1.102-0.428,3.311-1.305,4.467-1.554c1.604-0.346,4.939-0.217,6.539-0.582
        c1.028-0.236,2.92-1.255,3.949-1.49c0.903-0.206,2.784-0.135,3.689-0.323c1.604-0.333,4.639-1.629,6.215-2.071
        c1.871-0.526,5.672-1.355,7.574-1.748c2.369-0.491,7.115-1.456,9.516-1.748c1.61-0.196,4.877-0.113,6.475-0.388
        c1.029-0.179,3.034-0.802,4.013-1.165c1.318-0.488,3.85-1.718,5.114-2.33c1.081-0.524,3.295-1.504,4.273-2.202
        c1.66-1.183,4.557-4.092,5.892-5.632c1.405-1.623,3.721-5.256,4.984-6.992c0.927-1.272,2.777-3.817,3.756-5.049
        c0.856-1.08,2.56-3.261,3.56-4.208c1.09-1.033,3.492-2.871,4.791-3.626c0.958-0.557,2.993-1.517,4.078-1.748
        c1.06-0.225,3.253-0.056,4.337-0.064c0.956-0.008,2.868,0.069,3.82,0c0.558-0.041,1.345-0.137,2.169-0.253
        c-0.055,0.759-0.131,1.991-0.097,2.519c0.055,0.824,0.362,2.457,0.583,3.253c0.145,0.525,0.327,1.729,0.777,2.039
        c0.41,0.282,1.508-0.122,1.991,0c0.321,0.081,0.96,0.372,1.165,0.631c0.396,0.501,0.631,2.33,0.631,2.477
        c0,0.146,0.366,1.602,0.291,2.136c-0.077,0.555-0.805,1.493-0.923,2.039c-0.061,0.285-0.087,0.888,0,1.165
        c0.159,0.508,0.968,1.277,1.214,1.748c0.144,0.275,0.559,0.88,0.436,1.165c-0.172,0.4-1.176,0.593-1.602,0.68
        c-0.297,0.06-0.97-0.18-1.214,0c-0.234,0.174-0.344,0.825-0.34,1.117c0.005,0.422,0.42,1.195,0.534,1.602
        c0.074,0.265,0.278,0.797,0.243,1.068c-0.078,0.597-0.912,1.569-1.117,2.136c-0.059,0.163-0.178,0.509-0.146,0.68
        c0.061,0.328,0.435,0.959,0.728,1.117c0.267,0.145,0.99-0.204,1.214,0c0.478,0.435,0.227,1.928,0.243,2.573
        c0.018,0.778,0.293,2.466-0.146,3.107c-0.337,0.494-1.617,0.784-2.185,0.971c-0.531,0.175-1.637,0.37-2.185,0.486
        c-0.484,0.102-1.485,0.196-1.942,0.388c-0.434,0.184-1.29,0.677-1.553,1.068c-0.472,0.701-0.745,2.457-0.728,3.301
        c0.01,0.577,0.286,1.738,0.582,2.233c0.247,0.413,1.215,0.87,1.408,1.311c0.242,0.556-0.193,1.852,0,2.428
        c0.193,0.576,1.11,1.444,1.457,1.942c0.231,0.332,0.76,0.972,0.874,1.359c0.168,0.571,0,2.234,0,2.379
        c0,0.146,0.226,1.438,0.388,1.894c0.236,0.665,1.1,1.838,1.263,2.524c0.184,0.78,0.197,2.429,0,3.205
        c-0.075,0.295-0.474,0.784-0.583,1.068c-0.303,0.792-0.679,2.46-0.777,3.301c-0.059,0.506-0.195,1.568,0,2.039
        c0.072,0.175,0.445,0.367,0.534,0.534c0.18,0.339,0.116,1.165,0.291,1.505c0.186,0.358,0.718,1.104,1.117,1.165
        c0.273,0.042,0.846-0.286,0.971-0.534c0.21-0.415-0.304-1.384-0.243-1.845c0.042-0.312,0.265-0.939,0.485-1.165
        c0.167-0.171,0.635-0.377,0.874-0.388c0.268-0.013,0.842,0.138,1.019,0.34c0.293,0.334,0.345,1.303,0.34,1.748
        c-0.006,0.633-0.679,1.86-0.534,2.477c0.107,0.453,0.797,1.17,1.165,1.456c0.463,0.36,1.645,0.651,2.136,0.971
        c0.518,0.338,1.351,1.278,1.845,1.651c0.435,0.328,1.582,0.687,1.845,1.165c0.352,0.638,0,2.185,0,2.913
        c0,0.485,0.242,1.52,0,1.942c-0.202,0.354-1.013,0.719-1.408,0.825c-0.609,0.165-1.901-0.094-2.524,0
        c-0.654,0.098-2.03,0.34-2.525,0.777c-0.509,0.45-1.04,1.801-1.117,2.476c-0.028,0.246,0.05,0.769,0.194,0.971
        c0.294,0.412,1.436,0.621,1.748,1.02c0.368,0.468,0.411,1.751,0.68,2.282c0.173,0.34,0.581,1.045,0.923,1.214
        c0.305,0.151,1.075-0.187,1.359,0c0.254,0.167,0.474,0.813,0.485,1.117c0.013,0.334-0.203,1.022-0.436,1.262
        c-0.336,0.345-1.394,0.42-1.797,0.68c-0.663,0.428-1.854,1.541-2.233,2.234c-0.161,0.294-0.302,0.975-0.291,1.311
        c0.022,0.696,0.537,2.018,0.777,2.671c0.266,0.724,1.06,2.071,1.262,2.816c0.291,1.072,0.833,3.363,0.486,4.418
        c-0.134,0.408-0.803,1.031-1.165,1.263c-0.298,0.19-0.84,0.338-1.354,0.471c-0.369,0.095-0.724,0.182-0.961,0.273
        c-0.373,0.142-1.078,0.525-1.457,0.647c-0.26,0.084-0.81,0.139-1.068,0.227c-0.356,0.12-0.992,0.564-1.359,0.647
        c-0.388,0.087-1.215-0.139-1.587,0c-0.321,0.121-0.827,0.633-1.035,0.907c-0.338,0.443-0.815,1.471-0.971,2.006
        c-0.136,0.47-0.077,1.477-0.227,1.942c-0.138,0.431-0.76,1.148-0.874,1.587c-0.143,0.556,0.089,1.731,0,2.297
        c-0.078,0.497-0.357,1.48-0.615,1.91c-0.15,0.25-0.541,0.727-0.809,0.842c-0.298,0.126-1.012-0.156-1.295,0
        c-0.252,0.138-0.448,0.736-0.615,0.971c-0.264,0.37-0.811,1.102-1.133,1.424c-0.321,0.322-1.007,0.953-1.424,1.133
        c-0.459,0.198-1.476,0.301-1.975,0.291c-0.576-0.011-1.69-0.391-2.266-0.421c-0.6-0.031-1.815,0.04-2.395,0.194
        c-0.57,0.152-1.555,0.901-2.136,1.003c-0.344,0.06-1.093,0.052-1.392-0.129c-0.335-0.205-0.505-1.125-0.842-1.327
        c-0.424-0.256-1.545-0.409-1.975-0.162c-0.49,0.281-0.847,1.488-0.971,2.039c-0.093,0.411-0.129,1.282,0,1.684
        c0.055,0.171,0.368,0.411,0.421,0.582c0.142,0.464-0.099,1.458-0.064,1.942c0.052,0.73,0.255,2.198,0.518,2.88
        c0.171,0.445,0.639,1.313,1.003,1.619c0.234,0.196,0.839,0.374,1.133,0.454c0.425,0.114,1.586,0.226,1.716,0.389
        c0.129,0.161,0.635,1.576,1.1,1.845c0.449,0.259,1.554,0,2.071,0c0.324,0,1.014-0.16,1.295,0c0.328,0.186,0.5,1.037,0.777,1.294
        c0.55,0.513,1.984,1.09,2.686,1.359c0.431,0.166,1.391,0.239,1.781,0.486c0.568,0.359,1.334,1.511,1.748,2.039
        c0.085,0.11,0.378,0.325,0.323,0.454c-0.134,0.315-1.101-0.032-1.359,0.194c-0.266,0.232-0.106,1.147-0.388,1.359
        c-0.252,0.19-0.971-0.118-1.263,0c-0.271,0.109-0.639,0.598-0.842,0.809c-0.298,0.31-1.068,1.197-1.132,1.294
        c-0.065,0.097-0.542,1.536-0.551,2.072c-0.004,0.273,0.257,0.796,0.226,1.068c-0.041,0.389-0.307,1.231-0.647,1.424
        c-0.312,0.177-1.119,0.025-1.425-0.161c-0.323-0.198-0.506-1.084-0.842-1.262c-0.421-0.225-1.51-0.262-1.909,0
        c-0.17,0.111-0.145,0.65-0.325,0.744c-0.306,0.162-1.061-0.434-1.359-0.259c-0.491,0.29-0.553,1.62-0.712,2.169
        c-0.065,0.225-0.157,0.684-0.226,0.906c-0.131,0.422-0.213,1.472-0.616,1.651c-0.285,0.128-0.857-0.395-1.165-0.453
        c-0.397-0.075-1.215-0.043-1.618,0c-0.421,0.044-1.295,0.129-1.651,0.355c-0.429,0.273-0.735,1.503-1.231,1.619
        c-0.458,0.107-1.223-0.941-1.682-0.842c-0.377,0.081-0.812,0.847-0.971,1.198c-0.217,0.479-0.125,1.599-0.356,2.071
        c-0.174,0.354-0.924,0.797-1.068,1.165c-0.085,0.218,0.01,0.704,0,0.939c-0.016,0.373,0.026,1.149-0.129,1.489
        c-0.145,0.317-0.975,0.654-0.97,1.003c0.007,0.498,1.323,0.905,1.424,1.393c0.099,0.479-0.418,1.447-0.744,1.812
        c-0.326,0.364-1.299,0.684-1.716,0.939c-0.292,0.18-0.931,0.499-1.133,0.777c-0.306,0.424-0.322,1.554-0.583,2.007
        c-0.111,0.193-0.406,0.567-0.614,0.647c-0.379,0.146-1.23-0.014-1.619-0.129c-0.229-0.068-0.611-0.391-0.841-0.454
        c-0.258-0.069-0.807-0.088-1.068-0.032c-0.378,0.081-1.026,0.553-1.392,0.68c-0.469,0.163-1.471,0.26-1.942,0.421
        c-0.356,0.121-1.048,0.437-1.359,0.648c-0.346,0.234-0.831,1.007-1.23,1.133c-0.405,0.127-1.288-0.382-1.684-0.227
        c-0.283,0.112-0.534,0.754-0.777,0.939c-0.253,0.192-0.847,0.534-1.165,0.517c-0.409-0.019-1.029-0.704-1.424-0.809
        c-0.544-0.144-1.69,0.052-2.25,0c-0.328-0.03-1.015-0.069-1.294-0.243c-0.292-0.182-0.611-0.832-0.825-1.1
        c-0.091-0.113-0.242-0.377-0.373-0.437c-0.359-0.166-1.217,0.148-1.586,0c-0.369-0.149-0.818-0.88-1.149-1.101
        c-0.162-0.109-0.523-0.267-0.837-0.396c0.195-0.312,0.424-1.008,0.545-1.352c0.12-0.343,0.231-1.081,0.421-1.392
        c0.176-0.288,0.826-0.634,0.971-0.939c0.205-0.432,0.286-1.447,0.162-1.909c-0.136-0.506-0.794-1.384-1.199-1.716
        c-0.293-0.242-1.035-0.481-1.391-0.615c-0.326-0.123-0.996-0.312-1.328-0.421c-0.527-0.173-1.555-0.621-2.103-0.712
        c-0.583-0.096-1.785-0.119-2.364,0c-0.32,0.066-1.012,0.249-1.198,0.517c-0.19,0.274,0.011,1.004-0.065,1.327
        c-0.077,0.335-0.308,1.018-0.549,1.263c-0.194,0.196-0.758,0.331-1.004,0.453c-0.379,0.189-1.241,0.465-1.489,0.809
        c-0.244,0.34-0.103,1.277-0.291,1.651c-0.095,0.19-0.38,0.553-0.583,0.615c-0.388,0.118-1.183-0.405-1.586-0.355
        c-0.378,0.046-1.206,0.332-1.359,0.68c-0.085,0.193,0.075,0.663,0.226,0.809c0.198,0.191,0.801,0.2,1.068,0.259
        c0.161,0.035,0.53,0.013,0.648,0.129c0.204,0.204,0.291,0.852,0.227,1.133c-0.072,0.316-0.494,0.877-0.776,1.035
        c-0.361,0.204-1.248,0.066-1.651,0.162c-0.304,0.073-0.97,0.209-1.165,0.454c-0.174,0.217-0.297,0.857-0.162,1.1
        c0.159,0.286,1.016,0.248,1.199,0.518c0.194,0.289,0.183,1.079,0.032,1.393c-0.149,0.311-0.785,0.697-1.1,0.841
        c-0.393,0.181-1.308,0.157-1.692,0.356c-0.263,0.137-0.574,0.86-0.866,0.809c-0.438-0.077-0.452-1.319-0.809-1.586
        c-0.171-0.129-0.629-0.183-0.842-0.162c-0.216,0.021-0.629,0.201-0.809,0.324c-0.284,0.193-0.692,0.764-0.939,1.003
        c-0.479,0.463-1.353,1.623-2.006,1.748c-0.337,0.065-0.963-0.365-1.296-0.454c-0.476-0.126-1.549-0.057-1.942-0.355
        c-0.337-0.256-0.451-1.215-0.743-1.521c-0.269-0.281-1.104-0.454-1.359-0.745c-0.235-0.267-0.309-1.039-0.519-1.326
        c-0.203-0.28-0.745-0.752-1.068-0.874c-0.393-0.15-1.266-0.123-1.683-0.065c-0.666,0.093-1.926,0.606-2.557,0.842
        c-0.822,0.307-2.419,1.042-3.237,1.359c-0.56,0.218-1.732,0.531-2.266,0.809c-0.422,0.219-1.269,0.719-1.553,1.1
        c-0.271,0.363-0.308,1.356-0.583,1.716c-0.22,0.287-0.85,0.713-1.197,0.809c-0.438,0.121-1.431,0.148-1.813-0.097
        c-0.393-0.252-0.489-1.386-0.874-1.651c-0.376-0.259-1.401-0.423-1.813-0.226c-0.223,0.107-0.457,0.602-0.518,0.842
        c-0.078,0.306-0.03,0.96,0.065,1.262c0.105,0.336,0.488,0.955,0.744,1.197c0.268,0.255,1.103,0.397,1.295,0.712
        c0.152,0.25,0.081,0.878,0.032,1.165c-0.057,0.341-0.269,1.025-0.485,1.295c-0.224,0.278-0.936,0.533-1.198,0.777
        c-0.276,0.255-0.583,1.007-0.907,1.197c-0.401,0.236-1.398,0.124-1.845,0.26c-0.592,0.179-1.685,0.781-2.234,1.068
        c-0.61,0.319-1.777,1.055-2.394,1.359c-0.97,0.478-3.032,1.167-3.982,1.683c-0.584,0.316-1.56,1.315-2.202,1.488
        c-0.485,0.132-1.545,0.069-2.007-0.129c-0.272-0.117-0.74-0.543-0.874-0.809c-0.181-0.364,0.081-1.292-0.161-1.619
        c-0.189-0.254-0.851-0.463-1.165-0.486c-0.266-0.02-0.836,0.081-1.036,0.259c-0.169,0.15-0.282,0.62-0.323,0.841
        c-0.053,0.279,0.141,0.888,0,1.133c-0.136,0.236-0.667,0.536-0.939,0.55c-0.195,0.01-0.597-0.166-0.712-0.324
        c-0.164-0.224-0.038-0.839-0.13-1.1c-0.126-0.36-0.591-0.981-0.809-1.294c-0.401-0.579-1.117-1.863-1.716-2.234
        c-0.217-0.135-0.771-0.088-1.003-0.194c-0.225-0.104-0.532-0.575-0.777-0.616c-0.262-0.044-0.82,0.163-1.003,0.356
        c-0.215,0.226-0.311,0.889-0.356,1.197c-0.033,0.225-0.131,0.722,0,0.907c0.184,0.258,0.968,0.203,1.198,0.42
        c0.198,0.187,0.402,0.733,0.422,1.003c0.014,0.193-0.078,0.591-0.194,0.745c-0.147,0.193-0.599,0.493-0.842,0.486
        c-0.281-0.008-0.662-0.566-0.939-0.615c-0.244-0.044-0.802,0.042-0.97,0.226c-0.111,0.12,0.009,0.522-0.097,0.648
        c-0.121,0.142-0.555,0.123-0.712,0.226c-0.268,0.177-0.797,0.653-0.842,0.971c-0.038,0.265,0.364,0.718,0.453,0.971
        c0.089,0.251,0.275,0.769,0.259,1.036c-0.031,0.493-0.464,1.417-0.712,1.845c-0.277,0.478-0.917,1.433-1.392,1.715
        c-0.34,0.203-1.176,0.175-1.553,0.291c-0.405,0.125-1.282,0.354-1.553,0.68c-0.357,0.428-0.588,1.616-0.518,2.17
        c0.054,0.427,0.705,1.103,0.809,1.521c0.096,0.385,0.067,1.195,0,1.587c-0.074,0.431-0.38,1.263-0.582,1.651
        c-0.186,0.353-0.723,0.959-0.939,1.294c-0.185,0.289-0.482,0.916-0.68,1.198c-0.157,0.226-0.534,0.631-0.712,0.842
        c-0.178,0.21-0.572,0.604-0.712,0.842c-0.186,0.312-0.327,1.05-0.518,1.359c-0.201,0.326-0.708,0.933-1.035,1.132
        c-0.401,0.244-1.346,0.446-1.813,0.486c-0.364,0.032-1.116,0.002-1.456-0.129c-0.378-0.144-1.026-0.669-1.295-0.971
        c-0.31-0.347-0.548-1.352-0.939-1.602c-0.411-0.264-1.48-0.349-1.942-0.195c-0.203,0.068-0.484,0.427-0.631,0.583
        c-0.171,0.183-0.402,0.676-0.631,0.777c-0.39,0.172-1.292,0.026-1.7-0.097c-0.323-0.097-0.842-0.583-1.165-0.68
        c-0.42-0.126-1.348-0.275-1.747-0.097c-0.284,0.127-0.535,0.776-0.777,0.971c-0.232,0.187-0.859,0.321-1.068,0.534
        c-0.293,0.297-0.458,1.187-0.729,1.505c-0.107,0.125-0.336,0.298-0.581,0.466c-0.305-0.509-0.61-1.005-0.908-1.259
        c-1.004-0.853-3.792-1.401-4.726-2.33c-0.487-0.485-0.969-1.824-1.23-2.46c-0.316-0.772-0.631-2.444-1.035-3.173
        c-0.318-0.572-1.244-1.521-1.684-2.006c-0.699-0.772-2.269-2.16-2.913-2.978c-0.377-0.48-0.859-1.643-1.294-2.071
        c-0.58-0.571-2.138-1.189-2.784-1.684c-0.444-0.341-1.257-1.125-1.618-1.554c-0.504-0.597-1.214-2.018-1.748-2.589
        c-0.434-0.464-1.415-1.326-2.007-1.554c-0.606-0.233-2.066,0.191-2.59-0.194c-0.673-0.495-1.263-2.273-1.229-3.107
        c0.028-0.738,0.916-2.02,1.294-2.655c0.427-0.718,1.765-1.902,1.942-2.719c0.195-0.902-0.704-2.702-0.712-3.625
        c-0.009-0.92,0.214-2.812,0.647-3.625c0.371-0.698,1.625-1.741,2.267-2.202c0.605-0.434,2.009-0.985,2.654-1.359
        c0.769-0.445,2.266-1.409,2.978-1.942c1.074-0.805,3.156-2.519,4.078-3.496c0.586-0.621,1.708-1.946,2.071-2.719
        c0.85-1.804,1.549-5.791,1.813-7.768c0.18-1.349,0.094-4.081,0.194-5.438c0.085-1.169,0.071-3.577,0.518-4.661
        c0.308-0.746,1.534-1.871,2.007-2.525c0.328-0.455,0.986-1.371,1.229-1.877c0.339-0.706,0.849-2.205,0.971-2.978
        c0.163-1.022,0.181-3.124,0-4.144c-0.148-0.831-0.985-2.346-1.165-3.171c-0.134-0.619-0.126-1.896-0.194-2.525
        c-0.152-1.414-0.653-4.216-0.777-5.632c-0.112-1.275-0.02-3.847-0.195-5.115c-0.16-1.17-0.566-3.542-1.1-4.596
        c-0.509-1.004-2.596-2.352-2.913-3.432c-0.245-0.829,0.368-2.569,0.453-3.431c0.097-0.968,0.304-2.912,0.259-3.884
        c-0.066-1.424-0.575-4.244-0.906-5.632c-0.2-0.837-0.971-3.042-0.971-3.302c0-0.259,0.364-1.472,0.711-1.813
        c0.291-0.284,1.22-0.344,1.49-0.647c0.371-0.418,0.494-1.6,0.647-2.136c0.138-0.484,0.119-1.636,0.519-1.942
        c0.217-0.168,0.868-0.148,1.1,0c0.544,0.345,0.79,1.765,1.1,2.33c0.583,1.057,1.679,3.284,2.59,4.078
        c0.469,0.409,1.689,0.8,2.265,1.035c0.749,0.306,2.266,0.874,3.043,1.101c0.461,0.134,1.427,0.253,1.877,0.42
        c0.387,0.144,1.173,0.477,1.457,0.777c0.678,0.72,1.275,2.678,1.651,3.593c0.323,0.788,0.587,2.578,1.165,3.204
        c0.434,0.471,1.777,1.392,2.33,1.068c0.588-0.344-0.112-2.127,0.227-2.719c0.083-0.146,0.442-0.259,0.55-0.388
        c0.32-0.384,0.775-1.318,0.842-1.813c0.052-0.39,0.046-1.305-0.259-1.553c-0.471-0.383-1.871,0.342-2.428,0.097
        c-0.436-0.192-1.06-1.011-1.23-1.457c-0.21-0.551-0.033-1.772-0.064-2.363c-0.044-0.818-0.387-2.46-0.259-3.269
        c0.109-0.692,0.854-1.923,1.068-2.589c0.152-0.475,0.089-1.601,0.453-1.942c0.503-0.472,2.134-0.088,2.719-0.454
        c0.353-0.221,0.838-0.963,0.971-1.359c0.209-0.629,0.081-1.991,0.064-2.654c-0.016-0.65,0.253-2.12-0.194-2.59
        c-0.18-0.189-0.804-0.249-1.035-0.129c-0.245,0.126-0.245,0.944-0.519,0.971c-0.228,0.022-0.504-0.496-0.582-0.713
        c-0.195-0.548-0.039-1.749,0-2.33c0.025-0.358,0.258-1.07,0.194-1.424c-0.06-0.343-0.349-1.05-0.647-1.23
        c-1.054-0.636-3.692,0.064-4.921,0c-0.957-0.05-3.107-0.323-3.819-0.323c-0.404,0-0.852-0.153-1.133-0.041
        c-0.596,0.236-1.013,1.57-1.602,1.821c-0.383,0.162-1.263-0.342-1.651-0.194c-0.683,0.26-1.259,1.928-1.942,2.185
        c-0.458,0.172-1.466-0.132-1.942-0.243c-0.688-0.16-2.175-0.419-2.67-0.923c-0.412-0.419-0.472-1.705-0.728-2.233
        c-0.249-0.512-0.832-1.522-1.263-1.894c-0.366-0.316-1.699-0.68-1.699-0.68s-0.072-3.047,0-3.933
        c0.074-0.923,0.518-3.803,0.518-3.803s1.998,0.816,2.638,1.036c0.838,0.288,2.475,0.984,3.253,1.408
        c0.5,0.272,1.344,1.114,1.894,1.262c0.599,0.162,1.86-0.076,2.477-0.146C114.938,115.824,115.553,115.81,115.81,115.663z" pointer-events="auto" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_13_" x="168.275" y="143.426" opacity="0" fill="#FFFFFF" stroke="#010202" stroke-miterlimit="10" enable-background="new    " width="59.957" height="20.14" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_POLAND2_POMORSKIE" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Pomeranian" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_POLAND2_POMORSKIE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#7eb08e" d="M471.189,62.352c0.187,0.358-0.215,1.226-0.454,1.553
        c-0.426,0.583-1.79,1.224-2.395,1.619c-1.377,0.897-4.135,2.69-5.567,3.496c-1.262,0.709-3.844,2.029-5.179,2.589
        c-1.514,0.636-4.668,1.584-6.215,2.136c-1.137,0.407-3.338,1.491-4.532,1.684c-0.927,0.149-2.833-0.313-3.755-0.13
        c-0.384,0.077-1.212,0.318-1.424,0.648c-0.202,0.312-0.079,1.125,0,1.489c0.132,0.609,0.867,1.666,1.035,2.266
        c0.15,0.533,0.261,1.647,0.26,2.201c-0.001,0.538-0.616,1.734-0.26,2.136c0.024,0.026,0.048,0.05,0.077,0.069
        c-0.165,0.275-0.389,0.672-0.432,0.87c-0.103,0.467,0.017,1.438,0.097,1.91c0.072,0.424,0.305,1.259,0.485,1.651
        c0.186,0.403,0.624,1.211,0.971,1.489c0.289,0.232,1.111,0.275,1.391,0.517c0.384,0.332,0.709,1.346,0.971,1.781
        c0.271,0.451,1.153,1.23,1.165,1.756c0.008,0.296-0.371,0.84-0.606,1.019c-0.271,0.207-0.984,0.271-1.311,0.364
        c-0.377,0.107-1.216,0.173-1.506,0.437c-0.221,0.202-0.412,0.817-0.437,1.117c-0.036,0.42,0.393,1.234,0.34,1.651
        c-0.074,0.571-0.658,1.617-1.02,2.063c-0.228,0.281-0.939,0.596-1.141,0.898c-0.198,0.299-0.391,1.025-0.388,1.384
        c0.002,0.234,0.225,0.668,0.267,0.898c0.048,0.263,0.089,0.801,0.073,1.068c-0.014,0.234-0.028,0.736-0.17,0.923
        c-0.208,0.275-1.104,0.289-1.238,0.607c-0.159,0.377,0.486,1.481,0.509,1.554c0.025,0.073,0.731,1.123,1.117,1.311
        c0.454,0.222,1.535-0.035,2.015,0.121c0.193,0.063,0.592,0.255,0.68,0.437c0.1,0.208-0.045,0.696-0.097,0.922
        c-0.084,0.359-0.513,1.015-0.51,1.384c0.003,0.319,0.208,0.996,0.461,1.189c0.243,0.185,0.922,0.209,1.214,0.121
        c0.306-0.091,0.694-0.695,0.995-0.801c0.223-0.078,0.728-0.088,0.947,0c0.366,0.148,0.801,0.893,1.14,1.092
        c0.493,0.288,1.698,0.359,2.186,0.656c0.253,0.154,0.737,0.567,0.825,0.85c0.113,0.358-0.158,1.116-0.243,1.481
        c-0.137,0.587-0.615,1.71-0.704,2.306c-0.054,0.366-0.091,1.122,0,1.481c0.11,0.434,0.464,1.351,0.849,1.578
        c0.445,0.262,1.563,0.099,2.064-0.024c0.35-0.086,0.965-0.492,1.286-0.656c0.407-0.207,1.199-0.666,1.626-0.825
        c0.563-0.21,1.732-0.547,2.331-0.582c0.489-0.029,1.484,0.071,1.942,0.242c0.63,0.236,2.028,0.864,2.233,1.505
        c0.111,0.346-0.238,1.12-0.509,1.359c-0.215,0.191-0.921,0.057-1.117,0.267c-0.226,0.243-0.234,0.979-0.219,1.311
        c0.019,0.377,0.414,1.081,0.389,1.457c-0.031,0.44-0.408,1.28-0.68,1.627c-0.251,0.321-1.074,0.639-1.311,0.971
        c-0.255,0.357-0.499,1.237-0.533,1.675c-0.03,0.367,0.259,1.09,0.218,1.457c-0.042,0.382-0.294,1.153-0.559,1.433
        c-0.27,0.286-1.177,0.355-1.433,0.655c-0.31,0.364-0.507,1.366-0.509,1.845c-0.003,0.496,0.504,1.422,0.509,1.918
        c0.006,0.493-0.092,1.591-0.461,1.918c-0.193,0.171-0.781,0.218-1.02,0.121c-0.328-0.132-0.731-0.78-0.897-1.092
        c-0.157-0.291-0.224-0.976-0.389-1.262c-0.138-0.243-0.508-0.69-0.752-0.825c-0.372-0.208-1.258-0.231-1.675-0.316
        c-0.427-0.085-1.275-0.27-1.699-0.364c-0.474-0.106-1.411-0.382-1.894-0.437c-0.428-0.049-1.349-0.213-1.724,0
        c-0.246,0.14-0.53,0.692-0.582,0.971c-0.059,0.314,0.208,0.942,0.218,1.262c0.017,0.476-0.071,1.434-0.194,1.894
        c-0.138,0.514-0.786,1.417-0.875,1.942c-0.069,0.409,0.202,1.24,0.146,1.651c-0.065,0.476-0.394,1.41-0.679,1.797
        c-0.306,0.414-1.237,0.926-1.627,1.262c-0.566,0.487-1.728,1.455-2.161,2.063c-0.305,0.429-0.784,1.398-0.874,1.918
        c-0.095,0.553,0.17,1.673,0.194,2.234c0.021,0.485,0.078,1.462,0,1.942c-0.061,0.379-0.206,1.194-0.486,1.457
        c-0.324,0.306-1.281,0.403-1.724,0.461c-0.276,0.036-0.866-0.122-1.117,0c-0.124,0.061-0.231,0.351-0.34,0.437
        c-0.219,0.173-0.798,0.291-1.02,0.462c-0.304,0.234-0.813,0.84-0.971,1.19c-0.196,0.431-0.208,1.408-0.316,1.869
        c-0.051,0.222-0.187,0.671-0.301,1.041c-0.021-0.008-0.042-0.015-0.063-0.021c-0.514-0.159-1.598-0.254-2.136-0.291
        c-0.823-0.056-2.476-0.007-3.301,0c-0.765,0.006-2.294,0.01-3.059,0.048c-1.035,0.05-3.094,0.277-4.127,0.34
        c-0.619,0.038-1.922,0.373-2.476,0.097c-0.304-0.152-0.477-0.957-0.776-1.117c-0.762-0.405-2.589,0.031-3.448-0.048
        c-0.638-0.059-1.945-0.164-2.525-0.437c-0.405-0.191-1.182-0.755-1.359-1.165c-0.303-0.703-0.079-2.328,0.146-3.059
        c0.185-0.599,1.117-1.541,1.311-2.136c0.189-0.579,0.532-1.921,0.194-2.428c-0.232-0.348-1.186-0.402-1.586-0.518
        c-0.506-0.147-1.53-0.653-2.039-0.518c-0.52,0.138-1.047,1.311-1.554,1.489c-0.697,0.245-2.227-0.083-2.946-0.259
        c-0.391-0.095-1.092-0.543-1.489-0.615c-0.263-0.048-0.822-0.072-1.068,0.032c-0.373,0.158-0.868,0.855-1.165,1.133
        c-0.302,0.28-0.845,0.952-1.23,1.1c-0.43,0.164-1.464-0.259-1.845,0c-0.402,0.274-0.327,1.515-0.712,1.813
        c-0.264,0.204-1.005,0.251-1.327,0.161c-0.345-0.094-0.965-0.558-1.132-0.874c-0.183-0.344,0.106-1.222-0.098-1.553
        c-0.167-0.272-0.932-0.376-1.101-0.647c-0.239-0.387,0.165-1.468-0.129-1.813c-0.317-0.374-1.42-0.434-1.91-0.454
        c-0.261-0.009-0.799,0.049-1.035,0.162c-0.454,0.218-1.006,1.217-1.49,1.359c-0.189,0.056-0.581-0.145-0.777-0.129
        c-0.44,0.033-1.241,0.522-1.682,0.55c-0.51,0.032-1.51-0.272-2.007-0.388c-0.686-0.159-2.017-0.684-2.719-0.744
        c-0.51-0.045-1.534,0.278-2.04,0.194c-0.675-0.114-2.062-0.6-2.492-1.133c-0.192-0.239-0.31-0.895-0.259-1.198
        c0.054-0.319,0.651-0.779,0.679-1.101c0.044-0.503-0.299-1.638-0.744-1.877c-0.518-0.278-1.714,0.424-2.298,0.485
        c-0.362,0.038-1.122,0.143-1.457,0c-0.279-0.118-0.562-0.755-0.842-0.874c-0.514-0.219-1.767,0.306-2.234,0
        c-0.237-0.155-0.309-0.793-0.453-1.036c-0.141-0.238-0.421-0.776-0.68-0.874c-0.33-0.125-1.04,0.222-1.392,0.259
        c-0.346,0.036-1.1,0.222-1.392,0.032c-0.301-0.196-0.345-1.032-0.55-1.326c-0.239-0.343-0.813-1.158-1.231-1.133
        c-0.372,0.022-0.817,0.793-0.971,1.133c-0.262,0.581-0.198,1.907-0.355,2.524c-0.148,0.578-0.487,1.754-0.842,2.234
        c-0.261,0.351-0.956,1.009-1.391,1.068c-0.443,0.059-1.323-0.378-1.651-0.68c-0.235-0.215-0.358-0.907-0.583-1.133
        c-0.367-0.37-1.413-0.68-1.845-0.971c-0.343-0.231-1.032-0.727-1.263-1.068c-0.158-0.233-0.154-0.872-0.356-1.068
        c-0.164-0.158-0.647-0.274-0.874-0.259c-0.38,0.024-1.017,0.553-1.392,0.616c-0.265,0.042-0.832,0.028-1.068-0.097
        c-0.204-0.108-0.374-0.617-0.583-0.713c-0.272-0.124-0.95-0.137-1.198,0.033c-0.295,0.203-0.556,0.967-0.549,1.326
        c0.006,0.33,0.299,0.984,0.549,1.199c0.205,0.175,0.839,0.108,1.036,0.291c0.315,0.293,0.505,1.195,0.583,1.618
        c0.074,0.398,0.148,1.231,0.033,1.619c-0.095,0.319-0.504,0.871-0.744,1.1c-0.501,0.481-1.73,1.173-2.364,1.457
        c-0.438,0.197-1.363,0.528-1.845,0.55c-0.454,0.022-1.373-0.154-1.78-0.356c-0.318-0.157-0.728-0.833-1.068-0.938
        c-0.444-0.138-1.487-0.071-1.845,0.226c-0.269,0.223-0.401,0.98-0.421,1.328c-0.027,0.461,0.18,1.386,0.355,1.812
        c0.089,0.217,0.416,0.567,0.518,0.777c0.157,0.322,0.251,1.064,0.453,1.359c0.072,0.105,0.271,0.278,0.388,0.324
        c0.25,0.098,0.814-0.015,1.068,0.064c0.371,0.116,1.163,0.457,1.327,0.81c0.069,0.148-0.056,0.489-0.097,0.647
        c-0.098,0.369-0.33,1.113-0.551,1.424c-0.258,0.365-1.008,0.888-1.359,1.165c-0.29,0.23-0.828,0.908-1.197,0.874
        c-0.542-0.05-1.01-1.326-1.488-1.586c-0.203-0.11-0.683-0.216-0.907-0.162c-0.353,0.084-0.802,0.841-1.165,0.874
        c-0.396,0.036-1.034-0.636-1.424-0.712c-0.399-0.078-1.344-0.169-1.619,0.129c-0.279,0.303-0.268,1.337,0,1.651
        c0.17,0.199,0.838-0.01,1.037,0.162c0.23,0.199,0.386,0.862,0.355,1.165c-0.033,0.32-0.318,1.008-0.615,1.132
        c-0.395,0.167-1.32-0.195-1.651-0.469c-0.189-0.155-0.259-0.706-0.437-0.874c-0.252-0.239-1.117-0.485-1.262-0.582
        c-0.146-0.097-0.378-0.844-0.583-1.068c-0.151-0.164-0.555-0.411-0.776-0.437c-0.351-0.041-1.092,0.157-1.359,0.388
        c-0.223,0.191-0.28,0.858-0.486,1.068c-0.198,0.201-0.738,0.465-1.02,0.485c-0.494,0.036-1.475-0.317-1.894-0.582
        c-0.305-0.194-0.858-0.726-0.971-1.068c-0.145-0.441,0.206-1.382,0.194-1.845c-0.005-0.233,0-0.74-0.145-0.922
        c-0.163-0.204-0.711-0.364-0.971-0.389c-0.442-0.042-1.384,0.083-1.747,0.34c-0.369,0.26-0.638,1.203-0.923,1.554
        c-0.259,0.32-0.922,0.825-1.214,1.117c-0.328,0.327-1.123,0.917-1.263,1.359c-0.24,0.759,0.391,2.361,0.437,3.156
        c0.037,0.619,0.154,1.892-0.048,2.476c-0.204,0.589-1.222,1.435-1.506,1.991c-0.296,0.582-0.503,1.748-0.75,2.591
        c-0.352-0.121-0.909-0.305-1.16-0.341c-0.34-0.049-1.039,0.088-1.375,0.015c-0.441-0.094-1.259-0.512-1.636-0.76
        c-0.325-0.214-0.895-0.753-1.165-1.036c-0.399-0.418-0.981-1.465-1.456-1.796c-0.361-0.251-1.227-0.557-1.667-0.566
        c-0.469-0.009-1.329,0.517-1.797,0.534c-0.517,0.019-1.508-0.393-2.023-0.437c-0.661-0.056-1.992,0.168-2.654,0.114
        c-0.663-0.055-1.947-0.456-2.605-0.55c-0.72-0.104-2.197-0.418-2.898-0.227c-0.557,0.153-1.452,0.954-1.894,1.326
        c-0.247,0.21-0.605,0.805-0.906,0.923c-0.645,0.254-2.148,0.182-2.768-0.129c-0.342-0.171-0.844-0.822-0.971-1.182
        c-0.177-0.5,0.309-1.678,0.017-2.12c-0.211-0.317-1.03-0.621-1.408-0.582c-0.496,0.05-1.157,1.053-1.651,1.117
        c-0.503,0.064-1.576-0.299-1.91-0.68c-0.585-0.665-0.482-2.618-0.745-3.464c-0.185-0.597-0.69-1.747-0.954-2.314
        c-0.374-0.802-1.109-2.428-1.634-3.14c-0.174-0.236-0.634-0.68-0.981-1.002c0.513-0.133,1.055-0.281,1.354-0.471
        c0.362-0.232,1.031-0.855,1.165-1.263c0.348-1.055-0.195-3.346-0.486-4.418c-0.201-0.745-0.996-2.092-1.262-2.816
        c-0.24-0.653-0.755-1.975-0.777-2.671c-0.011-0.336,0.13-1.016,0.291-1.311c0.38-0.693,1.57-1.805,2.233-2.234
        c0.403-0.26,1.461-0.335,1.797-0.68c0.233-0.24,0.449-0.929,0.436-1.262c-0.011-0.304-0.231-0.95-0.485-1.117
        c-0.285-0.187-1.055,0.151-1.359,0c-0.342-0.169-0.75-0.874-0.923-1.214c-0.269-0.531-0.311-1.814-0.68-2.282
        c-0.312-0.398-1.454-0.608-1.748-1.02c-0.144-0.202-0.222-0.725-0.194-0.971c0.077-0.675,0.608-2.026,1.117-2.476
        c0.495-0.437,1.871-0.679,2.525-0.777c0.624-0.094,1.916,0.165,2.524,0c0.394-0.107,1.205-0.471,1.408-0.825
        c0.242-0.422,0-1.457,0-1.942c0-0.729,0.352-2.275,0-2.913c-0.263-0.478-1.41-0.837-1.845-1.165
        c-0.494-0.373-1.326-1.313-1.845-1.651c-0.492-0.321-1.673-0.611-2.136-0.971c-0.368-0.286-1.058-1.003-1.165-1.456
        c-0.145-0.617,0.528-1.843,0.534-2.477c0.005-0.445-0.047-1.414-0.34-1.748c-0.178-0.202-0.751-0.352-1.019-0.34
        c-0.239,0.012-0.707,0.218-0.874,0.388c-0.22,0.226-0.443,0.853-0.485,1.165c-0.061,0.462,0.453,1.431,0.243,1.845
        c-0.125,0.248-0.698,0.576-0.971,0.534c-0.399-0.061-0.931-0.807-1.117-1.165c-0.176-0.34-0.112-1.166-0.291-1.505
        c-0.089-0.167-0.463-0.359-0.534-0.534c-0.195-0.471-0.059-1.533,0-2.039c0.098-0.842,0.474-2.509,0.777-3.301
        c0.109-0.284,0.508-0.773,0.583-1.068c0.197-0.776,0.184-2.425,0-3.205c-0.162-0.686-1.027-1.86-1.263-2.524
        c-0.162-0.456-0.388-1.748-0.388-1.894s0.168-1.808,0-2.379c-0.114-0.388-0.643-1.027-0.874-1.359
        c-0.347-0.498-1.264-1.366-1.457-1.942c-0.193-0.576,0.242-1.871,0-2.428c-0.193-0.441-1.161-0.898-1.408-1.311
        c-0.296-0.495-0.572-1.657-0.582-2.233c-0.017-0.845,0.256-2.6,0.728-3.301c0.263-0.391,1.12-0.885,1.553-1.068
        c0.457-0.193,1.458-0.287,1.942-0.388c0.548-0.116,1.654-0.311,2.185-0.486c0.569-0.187,1.848-0.477,2.185-0.971
        c0.439-0.642,0.164-2.329,0.146-3.107c-0.016-0.646,0.235-2.138-0.243-2.573c-0.224-0.204-0.947,0.145-1.214,0
        c-0.293-0.158-0.667-0.788-0.728-1.117c-0.032-0.171,0.086-0.516,0.146-0.68c0.205-0.567,1.039-1.539,1.117-2.136
        c0.035-0.272-0.169-0.804-0.243-1.068c-0.114-0.407-0.528-1.18-0.534-1.602c-0.004-0.292,0.106-0.943,0.34-1.117
        c0.244-0.18,0.917,0.06,1.214,0c0.426-0.086,1.43-0.279,1.602-0.68c0.122-0.285-0.292-0.89-0.436-1.165
        c-0.247-0.471-1.055-1.24-1.214-1.748c-0.087-0.278-0.061-0.881,0-1.165c0.117-0.546,0.845-1.484,0.923-2.039
        c0.075-0.534-0.291-1.99-0.291-2.136s-0.235-1.976-0.631-2.477c-0.205-0.259-0.844-0.549-1.165-0.631
        c-0.483-0.122-1.581,0.282-1.991,0c-0.45-0.31-0.631-1.514-0.777-2.039c-0.221-0.796-0.528-2.429-0.583-3.253
        c-0.034-0.528,0.042-1.76,0.097-2.519c1.151-0.164,2.373-0.369,3.14-0.523c0.886-0.18,2.637-0.627,3.496-0.907
        c1.257-0.409,3.775-1.28,4.92-1.942c1.217-0.702,3.313-2.607,4.402-3.496c1.505-1.226,4.368-3.869,5.956-4.984
        c1.839-1.293,5.721-3.603,7.769-4.532c1.943-0.882,6.083-1.999,8.092-2.719c1.158-0.414,3.411-1.423,4.596-1.748
        c1.648-0.451,5.045-0.901,6.733-1.165c1.388-0.216,4.197-0.465,5.567-0.776c1.093-0.248,3.188-1.076,4.272-1.359
        c1.167-0.304,3.556-0.677,4.726-0.971c2.424-0.608,7.187-2.134,9.581-2.848c1.943-0.581,5.777-1.951,7.769-2.33
        c1.5-0.286,4.559-0.563,6.086-0.518c1.242,0.036,3.679,0.649,4.921,0.712c0.776,0.04,2.333-0.056,3.107-0.129
        c1.092-0.104,3.269-0.402,4.337-0.648c0.579-0.133,1.679-0.622,2.266-0.712c0.767-0.118,2.342-0.139,3.107,0
        c0.815,0.148,2.456,0.651,3.107,1.165c0.613,0.484,1.206,2.027,1.748,2.589c0.57,0.592,1.963,1.498,2.654,1.942
        c0.781,0.502,2.447,1.327,3.236,1.813c1.729,1.063,5.021,3.442,6.734,4.532c1.672,1.063,5.204,2.9,6.797,4.078
        c1.419,1.05,3.997,3.477,5.244,4.726c0.752,0.754,2.37,2.219,2.848,3.172c0.407,0.812,0.697,2.653,0.711,3.561
        c0.009,0.491,0.132,1.754-0.323,1.942c-0.664,0.273-1.784-1.218-2.33-1.684c-0.487-0.416-1.476-1.267-1.813-1.812
        c-0.295-0.479-0.532-1.603-0.712-2.136c-0.224-0.665-0.642-2.006-0.906-2.655c-0.142-0.349-0.39-1.085-0.648-1.359
        c-0.457-0.485-1.72-1.028-2.33-1.294c-0.577-0.251-1.929-0.354-2.396-0.777c-0.418-0.38-0.505-1.672-0.905-2.071
        c-0.488-0.486-1.921-0.771-2.525-1.101c-0.82-0.447-2.365-1.506-3.107-2.071c-0.749-0.57-2.08-1.917-2.848-2.46
        c-1.065-0.754-3.321-2.103-4.532-2.59c-0.372-0.15-1.204-0.583-1.554-0.388c-0.506,0.28-0.537,1.65-0.712,2.201
        c-0.149,0.468-0.509,1.392-0.582,1.877c-0.126,0.832-0.415,2.634,0,3.367c0.232,0.41,1.199,0.754,1.618,0.971
        c0.77,0.397,2.699,0.666,3.173,1.392c0.318,0.487,0,1.748,0,2.33c0,0.923-0.267,2.807,0,3.69c0.203,0.67,0.855,2.274,1.553,2.33
        c0.463,0.037,0.807-1.447,1.262-1.359c0.461,0.089,0.593,1.287,0.68,1.748c0.152,0.811-0.097,2.482,0,3.302
        c0.073,0.62,0.369,1.841,0.582,2.428c0.353,0.969,1.608,2.686,1.845,3.69c0.457,1.937-0.141,5.977,0,7.962
        c0.061,0.856,0.133,2.616,0.485,3.399c0.354,0.784,1.522,2.136,2.234,2.622c0.614,0.42,2.149,0.641,2.816,0.971
        c0.549,0.272,1.559,0.979,2.04,1.359c0.78,0.619,2.022,2.272,2.912,2.719c0.97,0.488,3.278,0.343,4.273,0.777
        c0.779,0.34,1.926,1.735,2.719,2.039c1.025,0.392,3.275,0.34,4.37,0.388c2.086,0.092,6.264,0.066,8.351,0
        c1.654-0.053,4.952-0.322,6.603-0.421c1.764-0.105,5.298-0.229,7.057-0.388c1.803-0.163,5.428-0.469,7.186-0.907
        c2.081-0.518,6.061-2.181,8.027-3.042c2.188-0.959,6.52-3.006,8.545-4.273c0.969-0.606,2.671-2.157,3.626-2.784
        c0.418-0.273,1.25-0.924,1.747-0.971C470.184,61.865,471.022,62.035,471.189,62.352z" pointer-events="auto" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_14_" x="320.623" y="84.635" opacity="0" fill="#FFFFFF" stroke="#010202" stroke-miterlimit="10" enable-background="new    " width="59.957" height="20.14" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_POLAND2_KUJAWSKOPOMORSKIE" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Kuyavian-Pomeranian" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_POLAND2_KUJAWSKOPOMORSKIE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#7eb08e" d="M318.061,172.148c0.248-0.843,0.454-2.008,0.75-2.591
        c0.283-0.556,1.301-1.401,1.506-1.991c0.202-0.584,0.085-1.858,0.048-2.476c-0.046-0.795-0.677-2.397-0.437-3.156
        c0.14-0.443,0.934-1.032,1.263-1.359c0.292-0.291,0.955-0.796,1.214-1.117c0.284-0.351,0.553-1.294,0.923-1.554
        c0.364-0.256,1.305-0.382,1.747-0.34c0.261,0.024,0.808,0.184,0.971,0.389c0.145,0.182,0.14,0.689,0.145,0.922
        c0.012,0.464-0.339,1.404-0.194,1.845c0.113,0.343,0.666,0.875,0.971,1.068c0.419,0.265,1.399,0.619,1.894,0.582
        c0.282-0.02,0.821-0.284,1.02-0.485c0.206-0.21,0.263-0.877,0.486-1.068c0.268-0.232,1.008-0.429,1.359-0.388
        c0.221,0.026,0.626,0.273,0.776,0.437c0.205,0.224,0.437,0.971,0.583,1.068c0.145,0.097,1.01,0.344,1.262,0.582
        c0.178,0.168,0.248,0.719,0.437,0.874c0.33,0.273,1.256,0.636,1.651,0.469c0.297-0.124,0.582-0.812,0.615-1.132
        c0.031-0.303-0.125-0.966-0.355-1.165c-0.198-0.172-0.867,0.037-1.037-0.162c-0.268-0.313-0.279-1.348,0-1.651
        c0.275-0.298,1.22-0.207,1.619-0.129c0.39,0.076,1.027,0.748,1.424,0.712c0.363-0.033,0.812-0.789,1.165-0.874
        c0.224-0.054,0.704,0.052,0.907,0.162c0.478,0.259,0.947,1.535,1.488,1.586c0.369,0.034,0.907-0.644,1.197-0.874
        c0.352-0.278,1.102-0.8,1.359-1.165c0.221-0.311,0.453-1.055,0.551-1.424c0.041-0.157,0.166-0.499,0.097-0.647
        c-0.164-0.353-0.957-0.694-1.327-0.81c-0.255-0.079-0.818,0.034-1.068-0.064c-0.117-0.046-0.317-0.219-0.388-0.324
        c-0.202-0.295-0.295-1.037-0.453-1.359c-0.103-0.21-0.429-0.56-0.518-0.777c-0.176-0.426-0.382-1.351-0.355-1.812
        c0.019-0.348,0.152-1.105,0.421-1.328c0.357-0.297,1.401-0.364,1.845-0.226c0.34,0.105,0.75,0.781,1.068,0.938
        c0.407,0.202,1.326,0.378,1.78,0.356c0.481-0.022,1.406-0.353,1.845-0.55c0.633-0.283,1.863-0.976,2.364-1.457
        c0.24-0.229,0.65-0.781,0.744-1.1c0.116-0.388,0.041-1.22-0.033-1.619c-0.078-0.423-0.268-1.324-0.583-1.618
        c-0.197-0.184-0.831-0.117-1.036-0.291c-0.25-0.215-0.544-0.868-0.549-1.199c-0.007-0.359,0.254-1.124,0.549-1.326
        c0.248-0.17,0.926-0.157,1.198-0.033c0.209,0.096,0.379,0.605,0.583,0.713c0.236,0.125,0.803,0.14,1.068,0.097
        c0.375-0.062,1.011-0.591,1.392-0.616c0.227-0.015,0.71,0.101,0.874,0.259c0.202,0.196,0.198,0.835,0.356,1.068
        c0.231,0.342,0.92,0.837,1.263,1.068c0.432,0.291,1.478,0.601,1.845,0.971c0.225,0.226,0.348,0.918,0.583,1.133
        c0.328,0.302,1.207,0.738,1.651,0.68c0.435-0.058,1.13-0.716,1.391-1.068c0.355-0.48,0.695-1.656,0.842-2.234
        c0.157-0.618,0.093-1.944,0.355-2.524c0.154-0.34,0.599-1.111,0.971-1.133c0.418-0.024,0.992,0.79,1.231,1.133
        c0.205,0.294,0.249,1.13,0.55,1.326c0.291,0.19,1.046,0.004,1.392-0.032c0.351-0.037,1.061-0.385,1.392-0.259
        c0.259,0.098,0.539,0.636,0.68,0.874c0.145,0.243,0.216,0.882,0.453,1.036c0.467,0.306,1.72-0.219,2.234,0
        c0.28,0.118,0.562,0.755,0.842,0.874c0.335,0.143,1.095,0.038,1.457,0c0.584-0.061,1.78-0.763,2.298-0.485
        c0.445,0.239,0.789,1.374,0.744,1.877c-0.028,0.322-0.625,0.782-0.679,1.101c-0.052,0.302,0.067,0.959,0.259,1.198
        c0.429,0.533,1.817,1.019,2.492,1.133c0.506,0.084,1.529-0.239,2.04-0.194c0.702,0.06,2.033,0.584,2.719,0.744
        c0.498,0.116,1.497,0.42,2.007,0.388c0.441-0.028,1.242-0.517,1.682-0.55c0.196-0.015,0.588,0.186,0.777,0.129
        c0.484-0.143,1.036-1.141,1.49-1.359c0.236-0.114,0.774-0.172,1.035-0.162c0.49,0.02,1.592,0.08,1.91,0.454
        c0.294,0.346-0.11,1.427,0.129,1.813c0.169,0.271,0.934,0.375,1.101,0.647c0.203,0.332-0.085,1.21,0.098,1.553
        c0.167,0.316,0.787,0.78,1.132,0.874c0.322,0.089,1.063,0.042,1.327-0.161c0.385-0.298,0.31-1.539,0.712-1.813
        c0.382-0.259,1.415,0.164,1.845,0c0.385-0.148,0.928-0.819,1.23-1.1c0.297-0.278,0.792-0.975,1.165-1.133
        c0.246-0.104,0.806-0.08,1.068-0.032c0.396,0.072,1.098,0.519,1.489,0.615c0.719,0.176,2.249,0.504,2.946,0.259
        c0.507-0.179,1.034-1.352,1.554-1.489c0.509-0.134,1.533,0.372,2.039,0.518c0.4,0.116,1.355,0.17,1.586,0.518
        c0.338,0.507-0.006,1.849-0.194,2.428c-0.194,0.595-1.126,1.537-1.311,2.136c-0.225,0.732-0.448,2.356-0.146,3.059
        c0.178,0.411,0.955,0.975,1.359,1.165c0.58,0.272,1.887,0.378,2.525,0.437c0.858,0.079,2.686-0.356,3.448,0.048
        c0.3,0.159,0.473,0.965,0.776,1.117c0.554,0.276,1.858-0.059,2.476-0.097c1.033-0.063,3.093-0.29,4.127-0.34
        c0.764-0.038,2.293-0.042,3.059-0.048c0.825-0.007,2.478-0.056,3.301,0c0.538,0.037,1.622,0.132,2.136,0.291
        c0.021,0.006,0.042,0.013,0.063,0.021c0.525,0.194,1.369,0.843,1.879,0.95c0.238,0.049,0.739-0.119,0.971-0.048
        c0.406,0.124,1.169,0.64,1.359,1.019c0.206,0.413-0.251,1.458,0,1.845c0.145,0.223,0.761,0.273,0.971,0.437
        c0.218,0.171,0.59,0.612,0.68,0.874c0.122,0.356,0.144,1.157,0,1.505c-0.066,0.158-0.388,0.344-0.486,0.485
        c-0.286,0.417-0.824,1.34-0.825,1.845c-0.001,0.478,0.454,1.396,0.777,1.748c0.272,0.296,1.127,0.489,1.408,0.776
        c0.258,0.265,0.557,0.964,0.68,1.311c0.192,0.543,0.315,1.701,0.461,2.258c0.131,0.499,0.632,1.45,0.631,1.966
        c0,0.434-0.482,1.22-0.534,1.651c-0.038,0.326-0.011,1.002,0.097,1.311c0.139,0.395,0.66,1.078,0.947,1.384
        c0.195,0.208,0.653,0.559,0.898,0.704c0.267,0.157,0.839,0.555,1.141,0.486c0.197-0.044,0.461-0.419,0.534-0.607
        c0.127-0.328-0.201-1.139,0.025-1.408c0.25-0.298,1.172-0.445,1.529-0.291c0.186,0.079,0.271,0.565,0.436,0.68
        c0.266,0.184,0.94,0.251,1.263,0.267c0.324,0.015,1.013-0.318,1.286-0.146c0.332,0.209,0.438,1.096,0.51,1.481
        c0.089,0.478-0.129,1.5,0.072,1.942c0.156,0.34,0.755,0.859,1.093,1.02c0.327,0.154,1.175-0.059,1.433,0.194
        c0.288,0.284-0.074,1.35,0.243,1.602c0.195,0.155,0.748-0.036,0.995-0.073c0.4-0.06,1.173-0.383,1.578-0.364
        c0.332,0.014,0.938,0.34,1.263,0.413c0.233,0.052,0.758-0.025,0.947,0.121c0.272,0.212,0.434,0.949,0.509,1.286
        c0.063,0.285-0.011,0.886,0.073,1.165c0.091,0.308,0.381,0.915,0.631,1.117c0.268,0.217,0.969,0.384,1.311,0.413
        c0.36,0.031,1.101-0.052,1.433-0.194c0.422-0.18,1.003-0.982,1.433-1.141c0.718-0.267,2.295-0.18,3.059-0.121
        c0.666,0.051,2.155,0.032,2.621,0.51c0.353,0.361,0.063,1.568,0.341,1.991c0.243,0.37,1.175,0.652,1.481,0.971
        c0.352,0.367,0.842,1.281,1.044,1.748c0.274,0.637,0.23,2.214,0.752,2.67c0.198,0.173,0.912-0.082,1.045,0.146
        c0.181,0.311-0.26,1.103-0.534,1.335c-0.423,0.356-1.783,0.058-2.161,0.462c-0.402,0.43-0.355,1.742-0.34,2.33
        c0.01,0.334,0.043,1.062,0.267,1.311c0.32,0.355,1.36,0.453,1.822,0.583c0.247,0.069,0.779,0.105,0.995,0.243
        c0.335,0.212,0.894,0.854,0.995,1.238c0.187,0.714-0.446,2.176-0.486,2.913c-0.026,0.504,0.056,1.515,0.121,2.015
        c0.091,0.692,0.802,2.075,0.569,2.741c-0.003,0.009-0.006,0.018-0.01,0.026c-0.187,0.469-1.337,0.77-1.651,1.165
        c-0.299,0.375-0.419,1.407-0.729,1.772c-0.18,0.212-0.686,0.486-0.947,0.583c-0.248,0.092-0.817,0.033-1.044,0.17
        c-0.338,0.204-0.594,1.086-0.947,1.262c-0.293,0.147-0.997,0.093-1.311,0c-0.32-0.095-0.803-0.839-1.117-0.728
        c-0.295,0.104-0.391,0.877-0.389,1.189c0.003,0.315,0.349,0.881,0.413,1.19c0.106,0.513-0.016,1.59,0.146,2.088
        c0.148,0.456,0.887,1.165,1.02,1.627c0.164,0.572-0.155,1.804,0,2.379c0.125,0.467,0.83,1.212,0.971,1.675
        c0.192,0.628,0.204,1.967,0.17,2.622c-0.022,0.437-0.357,1.291-0.291,1.723c0.067,0.437,0.635,1.168,0.802,1.578
        c0.162,0.4,0.628,1.235,0.509,1.651c-0.086,0.302-0.642,0.752-0.947,0.825c-0.462,0.112-1.432-0.225-1.845-0.461
        c-0.547-0.313-1.294-1.385-1.772-1.796c-0.381-0.329-1.152-1.019-1.626-1.19c-0.384-0.138-1.239-0.223-1.627-0.097
        c-0.246,0.079-0.687,0.423-0.801,0.656c-0.135,0.279-0.179,0.969-0.025,1.238c0.204,0.356,1.166,0.49,1.433,0.801
        c0.205,0.241,0.431,0.873,0.437,1.19c0.007,0.37-0.205,1.141-0.461,1.408c-0.279,0.289-1.141,0.389-1.506,0.558
        c-0.36,0.167-1.006,0.658-1.384,0.777c-0.453,0.144-1.423,0.118-1.894,0.194c-0.386,0.063-1.152,0.448-1.529,0.34
        c-0.383-0.11-0.741-1.104-1.141-1.117c-0.472-0.015-1.261,0.796-1.432,1.238c-0.164,0.423-0.062,1.442,0.218,1.796
        c0.273,0.345,1.416,0.235,1.651,0.607c0.293,0.463-0.281,1.643-0.195,2.185c0.078,0.483,0.678,1.317,0.777,1.796
        c0.056,0.273,0.182,0.904,0,1.117c-0.252,0.294-1.196,0.048-1.529,0.243c-0.463,0.27-0.997,1.259-1.335,1.675
        c-0.227,0.278-0.626,0.891-0.915,1.1c-0.21,0.155-0.824,0.172-0.971,0.388c-0.213,0.315-0.081,1.15,0,1.522
        c0.087,0.4,0.374,1.216,0.679,1.488c0.274,0.244,1.106,0.225,1.392,0.454c0.235,0.188,0.524,0.749,0.615,1.036
        c0.127,0.402-0.071,1.311,0.129,1.683c0.213,0.396,0.986,0.998,1.425,1.1c0.562,0.132,1.811-0.809,2.266-0.453
        c0.446,0.35-0.027,1.712,0.097,2.265c0.06,0.266,0.336,0.745,0.422,1.003c0.15,0.46,0.472,1.396,0.452,1.878
        c-0.015,0.417-0.218,1.294-0.517,1.586c-0.284,0.276-1.125,0.465-1.521,0.453c-0.542-0.017-1.499-0.7-2.04-0.745
        c-0.393-0.032-1.282,0.004-1.554,0.292c-0.268,0.285-0.166,1.163-0.194,1.553c-0.032,0.436-0.154,1.329-0.033,1.748
        c0.087,0.297,0.659,0.726,0.68,1.036c0.018,0.278-0.286,0.809-0.486,1.003c-0.248,0.244-0.946,0.439-1.263,0.583
        c-0.362,0.164-1.146,0.397-1.456,0.648c-0.374,0.302-0.914,1.126-1.133,1.553c-0.166,0.326-0.395,1.031-0.452,1.392
        c-0.078,0.487-0.129,1.498,0,1.974c0.1,0.371,0.49,1.071,0.776,1.328c0.463,0.413,1.928,0.485,2.266,1.003
        c0.186,0.285,0.068,1.026,0,1.359c-0.087,0.427-0.648,1.521-0.648,1.619s-0.024,0.864-0.226,1.036
        c-0.322,0.273-1.418-0.199-1.682,0.129c-0.231,0.285,0.363,1.131,0.194,1.456c-0.114,0.219-0.644,0.366-0.874,0.454
        c-0.414,0.155-1.465,0.057-1.716,0.421c-0.227,0.329,0.02,1.228,0.195,1.586c0.15,0.309,0.653,0.843,0.97,0.97
        c0.324,0.13,1.067-0.157,1.392-0.032c0.395,0.152,1.057,0.778,1.23,1.165c0.116,0.259,0.176,0.889,0.033,1.133
        c-0.186,0.315-1.049,0.409-1.295,0.679c-0.304,0.337-0.405,1.322-0.679,1.683c-0.16,0.211-0.715,0.415-0.842,0.648
        c-0.183,0.335,0.106,1.219-0.129,1.52c-0.168,0.217-0.763,0.329-1.036,0.356c-0.381,0.039-1.149-0.288-1.521-0.194
        c-0.376,0.095-1.089,0.558-1.262,0.906c-0.149,0.297-0.117,1.032,0.032,1.328c0.208,0.412,1.192,0.755,1.457,1.133
        c0.284,0.404,0.651,1.385,0.614,1.877c-0.022,0.316-0.392,0.972-0.634,1.37c-0.066-0.019-0.126-0.034-0.175-0.043
        c-0.263-0.054-0.839-0.138-1.068,0c-0.369,0.222-0.515,1.206-0.809,1.521c-0.261,0.279-1.022,0.543-1.296,0.81
        c-0.335,0.325-0.9,1.106-1.035,1.554c-0.113,0.372,0.093,1.177,0,1.553c-0.076,0.302-0.279,1.03-0.582,1.1
        c-0.361,0.083-0.903-0.649-1.199-0.874c-0.205-0.157-0.552-0.609-0.809-0.648c-0.327-0.048-0.932,0.343-1.23,0.485
        c-0.396,0.191-1.099,0.758-1.521,0.874c-0.414,0.115-1.311,0.174-1.716,0.033c-0.337-0.117-0.751-0.839-1.1-0.907
        c-0.576-0.111-1.681,0.519-2.233,0.713c-0.426,0.15-1.368,0.325-1.684,0.647c-0.2,0.206-0.35,0.66-0.46,1.077
        c-0.29-0.188-0.774-0.493-0.996-0.591c-0.463-0.205-1.591-0.215-1.942-0.582c-0.323-0.338-0.16-1.448-0.453-1.812
        c-0.261-0.326-1.257-0.399-1.49-0.745c-0.303-0.451,0.139-1.716-0.161-2.168c-0.186-0.28-0.996-0.346-1.198-0.616
        c-0.256-0.343-0.048-1.354-0.323-1.682c-0.245-0.293-1.064-0.678-1.425-0.551c-0.368,0.131-0.676,0.982-0.777,1.359
        c-0.078,0.297,0.076,0.932,0,1.23c-0.104,0.411-0.462,1.245-0.809,1.489c-0.215,0.152-0.781,0.102-1.036,0.162
        c-0.388,0.091-1.164,0.307-1.521,0.485c-0.275,0.138-0.766,0.517-1.004,0.712c-0.336,0.277-0.829,1.157-1.262,1.199
        c-0.449,0.042-1.081-0.871-1.521-0.971c-0.397-0.091-1.28-0.034-1.619,0.194c-0.236,0.16-0.329,0.821-0.55,1.003
        c-0.334,0.274-1.222,0.581-1.651,0.517c-0.368-0.054-1.038-0.521-1.229-0.841c-0.337-0.558-0.099-1.967-0.292-2.59
        c-0.117-0.376-0.513-1.072-0.745-1.391c-0.322-0.445-1.072-1.271-1.521-1.587c-0.34-0.239-1.227-0.384-1.521-0.68
        c-0.143-0.144-0.25-0.554-0.324-0.743c-0.19-0.487-0.37-1.554-0.68-1.976c-0.226-0.308-1.016-0.589-1.23-0.906
        c-0.275-0.409-0.242-1.482-0.485-1.91c-0.176-0.308-0.68-0.858-1.004-1.003c-0.636-0.286-2.097-0.039-2.783-0.162
        c-0.592-0.107-1.876-0.284-2.298-0.712c-0.302-0.306-0.261-1.284-0.485-1.651c-0.257-0.421-1.028-1.068-1.425-1.359
        c-0.38-0.279-1.186-0.832-1.651-0.906c-0.52-0.084-1.625,0.109-2.071,0.388c-0.471,0.293-0.898,1.429-1.328,1.78
        c-0.276,0.227-0.942,0.551-1.294,0.615c-0.599,0.111-1.82-0.225-2.428-0.194c-0.411,0.02-1.277,0.52-1.619,0.291
        c-0.254-0.17-0.215-0.894-0.258-1.198c-0.15-1.036-0.347-3.13-0.308-4.175c0.022-0.577,0.653-1.798,0.34-2.282
        c-0.191-0.297-1.009-0.432-1.359-0.388c-0.432,0.053-1.236,0.532-1.505,0.874c-0.266,0.338-0.227,1.308-0.486,1.651
        c-0.303,0.4-1.26,0.826-1.699,1.068c-0.519,0.286-1.653,0.678-2.136,1.02c-0.407,0.287-0.966,1.18-1.408,1.408
        c-0.46,0.237-1.523,0.365-2.04,0.34c-0.603-0.03-1.848-0.268-2.33-0.631c-0.374-0.281-0.631-1.28-0.971-1.602
        c-0.3-0.283-1.061-0.658-1.457-0.776c-0.514-0.154-1.655,0.042-2.136-0.194c-0.448-0.219-1.027-1.086-1.359-1.457
        c-0.236-0.262-0.633-0.865-0.923-1.068c-0.362-0.253-1.4-0.267-1.651-0.631c-0.254-0.37,0.072-1.354,0-1.796
        c-0.049-0.303-0.127-1.004-0.388-1.165c-0.365-0.225-1.271,0.243-1.7,0.243c-0.429,0-1.286-0.357-1.7-0.243
        c-0.241,0.067-0.537,0.561-0.776,0.631c-0.539,0.159-1.859,0.176-2.234-0.243c-0.227-0.252-0.16-1.059,0-1.359
        c0.165-0.309,1.038-0.452,1.165-0.777c0.096-0.244-0.037-0.858-0.243-1.02c-0.276-0.219-1.074-0.112-1.408,0
        c-0.231,0.077-0.536,0.554-0.777,0.582c-0.211,0.025-0.64-0.179-0.776-0.34c-0.317-0.372-0.217-1.486-0.486-1.894
        c-0.11-0.166-0.434-0.46-0.631-0.486c-0.312-0.04-0.859,0.561-1.165,0.486c-0.259-0.064-0.568-0.582-0.679-0.825
        c-0.149-0.325-0.065-1.099-0.243-1.408c-0.197-0.344-0.964-0.711-1.214-1.019c-0.34-0.417-0.497-1.766-1.019-1.894
        c-0.313-0.077-0.751,0.669-1.068,0.728c-0.417,0.077-1.342-0.099-1.651-0.388c-0.24-0.225-0.123-1.066-0.388-1.262
        c-0.332-0.245-1.296,0.211-1.651,0c-0.416-0.246-0.563-1.376-0.922-1.7c-0.234-0.211-0.855-0.542-1.165-0.485
        c-0.296,0.054-0.606,0.689-0.874,0.825c-0.231,0.118-0.772,0.271-1.019,0.194c-0.345-0.106-0.66-0.995-1.019-1.019
        c-0.381-0.026-1.007,0.603-1.214,0.923c-0.203,0.313-0.088,1.18-0.34,1.456c-0.156,0.17-0.674,0.177-0.874,0.291
        c-0.225,0.128-0.583,0.51-0.777,0.68c-0.363,0.316-0.986,1.362-1.457,1.262c-0.42-0.089-0.677-1.127-0.728-1.553
        c-0.059-0.492,0.423-1.448,0.388-1.942c-0.02-0.295-0.123-0.987-0.388-1.117c-0.276-0.136-0.859,0.394-1.165,0.389
        c-0.519-0.01-1.647-0.302-1.942-0.729c-0.276-0.399-0.175-1.489,0-1.942c0.123-0.32,0.804-0.697,0.923-1.02
        c0.186-0.501,0.249-1.693-0.049-2.136c-0.222-0.329-1.068-0.55-1.457-0.631c-0.476-0.099-1.51-0.22-1.942,0
        c-0.388,0.198-0.646,1.188-1.02,1.408c-0.432,0.255-1.519,0.071-1.99,0.243c-0.272,0.099-0.789,0.407-0.971,0.631
        c-0.189,0.232-0.168,0.985-0.436,1.117c-0.387,0.19-1.256-0.315-1.651-0.485c-0.245-0.105-0.66-0.489-0.923-0.534
        c-0.328-0.056-1.054,0.455-1.311,0.243c-0.225-0.187-0.085-0.887,0-1.165c0.095-0.309,0.626-0.762,0.728-1.068
        c0.18-0.542,0.265-1.736,0.097-2.282c-0.102-0.332-0.54-0.919-0.825-1.117c-0.363-0.252-1.271-0.377-1.7-0.486
        c-0.483-0.123-1.565-0.113-1.942-0.437c-0.355-0.306-0.637-1.281-0.68-1.748c-0.042-0.455,0.077-1.423,0.34-1.796
        c0.235-0.334,1.04-0.649,1.408-0.825c0.246-0.117,0.748-0.421,1.02-0.388c0.321,0.038,0.75,0.666,1.068,0.729
        c0.561,0.109,1.698-0.286,2.233-0.486c0.402-0.151,1.261-0.472,1.505-0.825c0.25-0.363,0.311-1.312,0.242-1.748
        c-0.06-0.387-0.592-1.027-0.679-1.408c-0.1-0.438-0.101-1.358,0-1.796c0.1-0.434,0.64-1.179,0.777-1.602
        c0.151-0.469,0.349-1.449,0.34-1.942c-0.008-0.471-0.145-1.441-0.388-1.845c-0.286-0.472-1.422-0.931-1.7-1.408
        c-0.174-0.298-0.248-1.014-0.243-1.359c0.006-0.348,0.307-1.012,0.292-1.359c-0.024-0.533-0.259-1.657-0.631-2.039
        c-0.34-0.35-1.392-0.449-1.845-0.631c-0.398-0.161-1.208-0.473-1.553-0.728c-0.655-0.486-1.467-2.039-2.185-2.428
        c-0.421-0.227-1.417-0.334-1.894-0.291c-0.524,0.046-1.47,0.614-1.991,0.68c-0.628,0.079-2.047,0.221-2.524-0.194
        c-0.216-0.188-0.169-0.841-0.243-1.117c-0.083-0.308-0.339-0.899-0.388-1.214c-0.062-0.396-0.187-1.248,0-1.602
        c0.213-0.401,1.256-0.639,1.505-1.02c0.405-0.62,0.387-2.187,0.534-2.913c0.145-0.719,0.39-2.173,0.631-2.865
        c0.182-0.522,0.816-1.458,0.971-1.99c0.212-0.728,0.346-2.253,0.34-3.01c-0.003-0.38-0.36-1.164-0.194-1.505
        c0.198-0.406,1.258-0.581,1.553-0.923c0.334-0.388,0.938-1.408,0.777-1.894c-0.156-0.47-1.211-0.939-1.699-1.02
        c-0.282-0.046-0.875,0.395-1.117,0.243c-0.222-0.14-0.209-0.759-0.243-1.019c-0.064-0.494-0.227-1.526-0.048-1.991
        c0.114-0.296,0.795-0.615,0.874-0.923c0.186-0.73-0.963-2.183-0.777-2.913c0.074-0.292,0.558-0.736,0.825-0.874
        c0.522-0.272,1.748-0.256,2.331-0.34c0.521-0.075,1.592-0.113,2.087-0.292c0.457-0.164,1.368-0.625,1.651-1.019
        c0.378-0.526,0.543-1.878,0.582-2.525c0.043-0.694-0.127-2.092-0.291-2.767c-0.126-0.52-0.47-1.554-0.777-1.991
        c-0.296-0.422-1.283-0.92-1.553-1.359c-0.257-0.417-0.303-1.44-0.486-1.894c-0.23-0.57-0.842-1.648-1.213-2.136
        c-0.292-0.383-0.936-1.132-1.36-1.359c-0.589-0.317-2.057-0.174-2.622-0.534c-0.483-0.309-1.207-1.293-1.359-1.845
        c-0.137-0.493-0.16-1.669,0.195-2.039c0.364-0.381,1.669,0.029,2.087-0.291c0.493-0.377,0.837-1.677,0.971-2.282
        c0.15-0.676-0.146-2.119,0.097-2.767c0.203-0.542,1.067-1.369,1.457-1.797c0.491-0.539,1.544-1.552,2.087-2.039
        c0.328-0.293,1.064-0.791,1.359-1.117c0.424-0.465,1.138-1.52,1.408-2.088C317.918,172.596,317.992,172.385,318.061,172.148z" pointer-events="auto" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_15_" x="359.018" y="207.96" opacity="0" fill="#FFFFFF" stroke="#010202" stroke-miterlimit="10" enable-background="new    " width="59.957" height="20.14" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_POLAND2_WARMINSKOMARZURSKIE" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Warmian-Masurian" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_POLAND2_WARMINSKOMARZURSKIE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#7eb08e" d="M602.01,176.632c-0.823-0.04-1.806-0.115-2.367,0.014c-0.566,0.131-1.558,0.78-2.087,1.02
        c-0.66,0.298-1.975,0.92-2.672,1.117c-0.448,0.127-1.382,0.356-1.844,0.291c-0.571-0.079-1.516-0.9-2.088-0.971
        c-0.319-0.039-1.057-0.005-1.263,0.243c-0.216,0.262,0.166,1.062,0,1.359c-0.145,0.262-0.75,0.498-1.019,0.631
        c-0.397,0.196-1.216,0.55-1.651,0.631c-0.646,0.119-2.052-0.321-2.622,0c-0.408,0.23-0.531,1.442-0.971,1.602
        c-0.494,0.18-1.464-0.68-1.99-0.68c-0.411,0-1.183,0.711-1.554,0.534c-0.35-0.167-0.194-1.272-0.533-1.457
        c-0.383-0.208-1.293,0.232-1.7,0.389c-0.324,0.125-0.904,0.519-1.214,0.68c-0.298,0.156-0.938,0.389-1.213,0.582
        c-0.448,0.318-1.111,1.23-1.553,1.554c-0.298,0.218-0.944,0.714-1.311,0.68c-0.276-0.025-0.612-0.59-0.875-0.68
        c-0.344-0.118-1.099-0.07-1.456,0c-0.283,0.055-0.84,0.26-1.068,0.437c-0.466,0.361-1.012,1.457-1.408,1.894
        c-0.375,0.415-1.233,1.135-1.651,1.505c-0.335,0.296-0.913,1.126-1.359,1.165c-0.38,0.033-1.041-0.51-1.311-0.777
        c-0.314-0.31-0.563-1.249-0.922-1.505c-0.365-0.26-1.301-0.402-1.748-0.388c-0.441,0.014-1.357,0.205-1.699,0.486
        c-0.369,0.303-0.477,1.433-0.875,1.699c-0.596,0.4-2.164,0.08-2.864,0.243c-0.393,0.091-1.239,0.278-1.506,0.582
        c-0.239,0.273-0.344,1.046-0.34,1.408c0.004,0.327,0.488,0.971,0.34,1.262c-0.259,0.511-1.573,0.72-2.136,0.825
        c-0.357,0.067-1.135-0.173-1.455,0c-0.371,0.2-0.537,1.239-0.923,1.408c-0.406,0.178-1.318-0.184-1.748-0.291
        c-0.484-0.12-1.395-0.609-1.894-0.631c-0.685-0.031-2.096,0.255-2.67,0.631c-0.355,0.233-0.718,1.06-1.02,1.359
        c-0.321,0.317-1.196,0.7-1.456,1.068c-0.339,0.476-0.453,1.696-0.68,2.234c-0.215,0.51-0.591,1.66-1.068,1.942
        c-0.617,0.364-2.242-0.354-2.864,0c-0.393,0.223-0.594,1.244-0.923,1.553c-0.328,0.309-1.156,0.76-1.602,0.825
        c-0.464,0.069-1.376-0.354-1.844-0.34c-0.401,0.012-1.172,0.507-1.554,0.388c-0.403-0.125-0.768-1.028-1.118-1.263
        c-0.523-0.35-1.75-0.871-2.378-0.825c-0.341,0.025-0.994,0.369-1.214,0.631c-0.336,0.4-0.346,1.553-0.631,1.991
        c-0.239,0.368-0.977,0.881-1.311,1.165c-0.465,0.398-1.585,1.025-1.894,1.554c-0.391,0.67-0.658,2.292-0.534,3.059
        c0.091,0.557,0.968,1.436,1.068,1.991c0.066,0.361-0.001,1.144-0.194,1.457c-0.215,0.35-1.072,0.627-1.359,0.922
        c-0.332,0.341-0.579,1.428-1.02,1.602c-0.455,0.18-1.407-0.451-1.894-0.485c-0.975-0.068-2.908,0.463-3.885,0.437
        c-0.96-0.025-2.862-0.371-3.787-0.631c-0.656-0.184-1.85-0.924-2.524-1.019c-0.701-0.099-2.141,0.074-2.816,0.291
        c-0.44,0.142-1.141,0.893-1.602,0.923c-0.473,0.03-1.36-0.457-1.747-0.729c-0.31-0.217-0.674-0.964-1.02-1.117
        c-0.189-0.083-0.623-0.037-0.826,0c-0.547,0.099-1.538,0.688-2.087,0.777c-0.408,0.067-1.506-0.049-1.651,0.097
        c-0.146,0.146-0.61,0.831-0.922,0.922c-0.43,0.125-1.301-0.373-1.748-0.388c-0.577-0.02-1.735,0.521-2.282,0.34
        c-0.44-0.146-0.956-1.014-1.311-1.311c-0.295-0.245-0.976-0.619-1.263-0.874c-0.302-0.269-0.86-0.867-1.068-1.214
        c-0.107-0.178-0.276-0.57-0.291-0.777c-0.02-0.285,0.171-0.84,0.243-1.117c0.074-0.283,0.399-0.831,0.34-1.117
        c-0.087-0.422-0.705-1.184-1.117-1.311c-0.396-0.122-1.28,0.174-1.602,0.437c-0.314,0.259-0.43,1.179-0.729,1.457
        c-0.346,0.323-1.391,0.418-1.747,0.728c-0.361,0.315-0.953,1.172-0.971,1.651c-0.021,0.553,0.847,1.45,0.971,1.99
        c0.054,0.237,0.083,0.743,0,0.971c-0.155,0.43-0.727,1.28-1.165,1.408c-0.612,0.179-1.813-0.614-2.428-0.777
        c-0.396-0.105-1.2-0.27-1.602-0.34c-0.26-0.045-0.767-0.113-1.228-0.172c0.233-0.666-0.478-2.05-0.569-2.741
        c-0.065-0.5-0.147-1.511-0.121-2.015c0.039-0.737,0.672-2.199,0.486-2.913c-0.101-0.385-0.66-1.026-0.995-1.238
        c-0.216-0.138-0.748-0.174-0.995-0.243c-0.461-0.129-1.502-0.227-1.822-0.583c-0.223-0.249-0.257-0.977-0.267-1.311
        c-0.016-0.588-0.062-1.9,0.34-2.33c0.378-0.403,1.739-0.105,2.161-0.462c0.274-0.232,0.715-1.024,0.534-1.335
        c-0.132-0.228-0.846,0.027-1.045-0.146c-0.522-0.456-0.478-2.033-0.752-2.67c-0.201-0.467-0.691-1.381-1.044-1.748
        c-0.306-0.319-1.238-0.601-1.481-0.971c-0.277-0.423,0.013-1.63-0.341-1.991c-0.466-0.478-1.955-0.459-2.621-0.51
        c-0.763-0.058-2.341-0.146-3.059,0.121c-0.429,0.159-1.011,0.962-1.433,1.141c-0.332,0.142-1.072,0.225-1.433,0.194
        c-0.343-0.029-1.044-0.196-1.311-0.413c-0.25-0.202-0.54-0.809-0.631-1.117c-0.084-0.28-0.01-0.881-0.073-1.165
        c-0.075-0.338-0.237-1.074-0.509-1.286c-0.189-0.147-0.714-0.069-0.947-0.121c-0.325-0.073-0.931-0.398-1.263-0.413
        c-0.405-0.019-1.178,0.304-1.578,0.364c-0.247,0.037-0.801,0.229-0.995,0.073c-0.317-0.253,0.045-1.318-0.243-1.602
        c-0.258-0.253-1.106-0.04-1.433-0.194c-0.338-0.16-0.937-0.68-1.093-1.02c-0.201-0.442,0.017-1.464-0.072-1.942
        c-0.072-0.385-0.179-1.272-0.51-1.481c-0.273-0.173-0.963,0.161-1.286,0.146c-0.323-0.016-0.997-0.083-1.263-0.267
        c-0.166-0.115-0.251-0.6-0.436-0.68c-0.357-0.154-1.28-0.007-1.529,0.291c-0.226,0.269,0.103,1.08-0.025,1.408
        c-0.073,0.188-0.337,0.562-0.534,0.607c-0.302,0.069-0.874-0.328-1.141-0.486c-0.246-0.145-0.703-0.496-0.898-0.704
        c-0.286-0.306-0.808-0.988-0.947-1.384c-0.109-0.31-0.136-0.985-0.097-1.311c0.052-0.431,0.534-1.216,0.534-1.651
        c0.001-0.516-0.5-1.467-0.631-1.966c-0.146-0.557-0.269-1.715-0.461-2.258c-0.123-0.348-0.423-1.047-0.68-1.311
        c-0.28-0.287-1.136-0.48-1.408-0.776c-0.323-0.352-0.778-1.27-0.777-1.748c0.001-0.505,0.539-1.429,0.825-1.845
        c0.097-0.142,0.42-0.327,0.486-0.485c0.144-0.348,0.122-1.149,0-1.505c-0.089-0.263-0.462-0.703-0.68-0.874
        c-0.21-0.164-0.826-0.214-0.971-0.437c-0.251-0.388,0.206-1.433,0-1.845c-0.191-0.38-0.954-0.895-1.359-1.019
        c-0.232-0.071-0.734,0.098-0.971,0.048c-0.509-0.107-1.354-0.756-1.879-0.95c0.115-0.37,0.25-0.819,0.301-1.041
        c0.108-0.461,0.119-1.438,0.316-1.869c0.158-0.35,0.667-0.956,0.971-1.19c0.222-0.171,0.801-0.288,1.02-0.462
        c0.109-0.085,0.215-0.376,0.34-0.437c0.251-0.122,0.84,0.036,1.117,0c0.442-0.058,1.4-0.156,1.724-0.461
        c0.28-0.263,0.425-1.078,0.486-1.457c0.078-0.48,0.021-1.457,0-1.942c-0.024-0.56-0.289-1.681-0.194-2.234
        c0.09-0.519,0.57-1.488,0.874-1.918c0.433-0.609,1.594-1.576,2.161-2.063c0.39-0.336,1.321-0.849,1.627-1.262
        c0.285-0.387,0.614-1.321,0.679-1.797c0.056-0.411-0.215-1.242-0.146-1.651c0.089-0.525,0.737-1.428,0.875-1.942
        c0.122-0.46,0.21-1.418,0.194-1.894c-0.01-0.32-0.277-0.948-0.218-1.262c0.052-0.279,0.337-0.831,0.582-0.971
        c0.375-0.213,1.295-0.049,1.724,0c0.483,0.055,1.42,0.331,1.894,0.437c0.425,0.094,1.273,0.278,1.699,0.364
        c0.418,0.085,1.303,0.108,1.675,0.316c0.244,0.136,0.614,0.582,0.752,0.825c0.165,0.286,0.232,0.971,0.389,1.262
        c0.167,0.312,0.57,0.961,0.897,1.092c0.239,0.096,0.827,0.049,1.02-0.121c0.369-0.327,0.467-1.425,0.461-1.918
        c-0.006-0.496-0.512-1.422-0.509-1.918c0.002-0.479,0.199-1.481,0.509-1.845c0.256-0.3,1.163-0.369,1.433-0.655
        c0.265-0.28,0.516-1.051,0.559-1.433c0.04-0.366-0.248-1.089-0.218-1.457c0.035-0.438,0.278-1.318,0.533-1.675
        c0.237-0.332,1.06-0.65,1.311-0.971c0.272-0.347,0.65-1.187,0.68-1.627c0.025-0.376-0.37-1.08-0.389-1.457
        c-0.015-0.332-0.007-1.068,0.219-1.311c0.195-0.21,0.901-0.077,1.117-0.267c0.271-0.239,0.62-1.013,0.509-1.359
        c-0.205-0.641-1.603-1.269-2.233-1.505c-0.458-0.172-1.453-0.271-1.942-0.242c-0.599,0.036-1.768,0.373-2.331,0.582
        c-0.427,0.159-1.219,0.619-1.626,0.825c-0.322,0.164-0.937,0.569-1.286,0.656c-0.502,0.123-1.619,0.286-2.064,0.024
        c-0.385-0.227-0.739-1.144-0.849-1.578c-0.091-0.359-0.054-1.115,0-1.481c0.089-0.596,0.567-1.719,0.704-2.306
        c0.085-0.365,0.355-1.123,0.243-1.481c-0.089-0.282-0.573-0.696-0.825-0.85c-0.488-0.296-1.693-0.368-2.186-0.656
        c-0.34-0.199-0.774-0.945-1.14-1.092c-0.219-0.088-0.724-0.078-0.947,0c-0.301,0.106-0.689,0.71-0.995,0.801
        c-0.292,0.087-0.971,0.063-1.214-0.121c-0.253-0.193-0.458-0.87-0.461-1.189c-0.003-0.369,0.426-1.025,0.51-1.384
        c0.051-0.226,0.196-0.714,0.097-0.922c-0.088-0.183-0.488-0.374-0.68-0.437c-0.48-0.156-1.561,0.1-2.015-0.121
        c-0.386-0.188-1.092-1.238-1.117-1.311c-0.024-0.073-0.669-1.177-0.509-1.554c0.134-0.317,1.03-0.332,1.238-0.607
        c0.142-0.187,0.156-0.689,0.17-0.923c0.016-0.267-0.025-0.805-0.073-1.068c-0.041-0.23-0.265-0.664-0.267-0.898
        c-0.003-0.359,0.19-1.085,0.388-1.384c0.202-0.302,0.913-0.617,1.141-0.898c0.362-0.447,0.946-1.493,1.02-2.063
        c0.053-0.418-0.376-1.232-0.34-1.651c0.025-0.299,0.216-0.915,0.437-1.117c0.289-0.264,1.128-0.33,1.506-0.437
        c0.327-0.093,1.041-0.157,1.311-0.364c0.235-0.18,0.614-0.723,0.606-1.019c-0.012-0.526-0.893-1.305-1.165-1.756
        c-0.263-0.435-0.587-1.449-0.971-1.781c-0.28-0.242-1.102-0.285-1.391-0.517c-0.348-0.278-0.786-1.086-0.971-1.489
        c-0.181-0.391-0.414-1.227-0.485-1.651c-0.08-0.472-0.2-1.443-0.097-1.91c0.044-0.198,0.267-0.595,0.432-0.87
        c0.601,0.444,2.319-0.278,3.03-0.069c0.721,0.211,1.925,1.169,2.525,1.619c0.395,0.296,1.284,0.846,1.49,1.294
        c0.241,0.53-0.341,1.859,0,2.33c0.179,0.25,0.883,0.509,1.165,0.388c0.346-0.147,0.564-0.993,0.647-1.359
        c0.129-0.568-0.152-1.767,0-2.33c0.059-0.217,0.347-0.579,0.453-0.777c0.224-0.414,0.545-1.318,0.842-1.683
        c0.443-0.546,1.635-1.332,2.201-1.748c0.678-0.496,2.196-1.279,2.784-1.877c0.501-0.509,1.022-1.92,1.553-2.396
        c0.513-0.458,1.861-0.893,2.46-1.23c0.4-0.223,1.101-0.901,1.554-0.971c0.826-0.126,2.402,0.789,3.237,0.842
        c0.375,0.024,1.152-0.028,1.489-0.194c0.411-0.202,0.948-0.996,1.294-1.294c0.975-0.837,3.288-2.084,4.144-3.043
        c0.607-0.682,1.21-2.485,1.813-3.172c0.564-0.643,2.171-1.416,2.719-2.072c0.285-0.342,0.412-1.385,0.809-1.586
        c1.207-0.611,4.073,0.404,5.341,0.874c1.094,0.405,2.877,2.055,3.982,2.428c2.682,0.905,8.45,0.858,11.264,1.165
        c6.559,0.715,19.647,2.428,26.219,3.01c11.606,1.029,34.87,2.505,46.514,2.913c6.455,0.227,19.38,0.523,25.831,0.194
        c10.244-0.521,30.56-3.648,40.784-4.467c8.117-0.65,24.406-1.099,32.531-1.651c1.143-0.078,3.519,0.078,4.563-0.388
        c0.488-0.217,1.166-1.092,1.554-1.457c0.284-0.265,0.719-0.702,1.136-1.099c0.315,0.273,0.654,0.581,0.807,0.76
        c0.159,0.187,0.464,0.589,0.533,0.825c0.148,0.501,0.059,1.569,0,2.088c-0.061,0.532-0.51,1.553-0.486,2.088
        c0.02,0.384,0.523,1.074,0.486,1.457c-0.042,0.444-0.567,1.23-0.873,1.554c-0.327,0.345-1.147,0.887-1.603,1.019
        c-0.396,0.116-1.29-0.201-1.651,0c-0.323,0.181-0.461,1.067-0.776,1.262c-0.581,0.359-2.04,0.307-2.719,0.243
        c-0.44-0.041-1.262-0.534-1.7-0.485c-0.329,0.036-0.946,0.383-1.165,0.631c-0.242,0.274-0.248,1.133-0.534,1.359
        c-0.482,0.38-1.832,0.194-2.428,0.34c-0.387,0.095-1.141,0.37-1.505,0.534c-0.482,0.216-1.435,0.689-1.846,1.019
        c-0.329,0.263-0.971,0.866-1.116,1.263c-0.18,0.49,0.306,1.634,0.047,2.088c-0.269,0.473-1.619,0.599-1.894,1.068
        c-0.164,0.283-0.15,1.019,0,1.311c0.186,0.355,1.078,0.597,1.311,0.923c0.149,0.206,0.143,0.766,0.292,0.971
        c0.225,0.311,1.017,0.579,1.262,0.874c0.342,0.409,0.554,1.517,0.875,1.942c0.182,0.241,0.638,0.674,0.922,0.777
        c0.387,0.142,1.238,0,1.651,0c0.291,0,0.928-0.17,1.165,0c0.491,0.35,0.607,1.703,0.777,2.282c0.271,0.923,1.326,2.865,0.923,3.738
        c-0.126,0.271-0.891,0.294-1.068,0.534c-0.395,0.534-0.581,1.973-0.437,2.622c0.078,0.358,0.552,0.97,0.825,1.214
        c0.235,0.209,0.815,0.67,1.117,0.582c0.257-0.075,0.274-0.858,0.534-0.923c0.266-0.065,0.753,0.369,0.923,0.583
        c0.296,0.372,0.46,1.352,0.631,1.797c0.215,0.564,0.603,1.72,0.922,2.233c0.281,0.453,1.158,1.131,1.409,1.602
        c0.34,0.643,0.401,2.167,0.728,2.816c0.331,0.655,1.372,1.721,1.845,2.282c0.521,0.618,1.704,1.744,2.136,2.428
        c0.346,0.547,0.89,1.745,1.019,2.379c0.166,0.809,0.126,2.486,0,3.301c-0.147,0.957-0.815,2.788-1.165,3.69
        c-0.188,0.487-0.492,1.539-0.874,1.894c-0.437,0.404-1.894,0.337-2.233,0.825c-0.402,0.578-0.128,2.124,0,2.816
        c0.062,0.332,0.458,0.926,0.485,1.262c0.039,0.47,0.012,1.594-0.388,1.845c-0.349,0.221-1.192-0.432-1.602-0.388
        c-0.361,0.038-1.062,0.365-1.311,0.631c-0.312,0.334-0.443,1.307-0.679,1.699c-0.271,0.45-0.943,1.282-1.359,1.602
        c-0.447,0.345-1.679,0.536-2.039,0.971c-0.304,0.367-0.363,1.385-0.486,1.845c-0.109,0.401-0.094,1.369-0.437,1.602
        c-0.273,0.186-1.026,0.019-1.311-0.146c-0.392-0.227-0.617-1.423-1.068-1.457c-0.651-0.049-1.529,1.274-1.845,1.845
        c-0.265,0.479-0.057,1.794-0.486,2.136c-0.474,0.379-1.838-0.147-2.427,0c-0.227,0.056-0.705,0.237-0.825,0.437
        c-0.225,0.374-0.079,1.318,0,1.747c0.105,0.575,0.798,1.601,0.825,2.185c0.016,0.365-0.101,1.182-0.389,1.408
        c-0.306,0.24-1.228,0.261-1.554,0.048c-0.328-0.214-0.258-1.237-0.583-1.456c-0.472-0.32-1.765-0.242-2.282,0
        c-0.429,0.201-0.852,1.151-1.213,1.456c-0.387,0.326-1.4,0.653-1.748,1.02c-0.31,0.327-0.56,1.238-0.825,1.602
        c-0.415,0.568-1.435,1.559-1.991,1.99c-0.567,0.44-1.925,0.995-2.476,1.457c-0.719,0.605-1.817,2.152-2.427,2.865
        c-1.315,1.536-3.724,4.841-5.293,6.118c-0.734,0.597-2.404,1.547-3.301,1.845c-0.965,0.32-3.139,0.045-4.03,0.534
        c-0.264,0.145-0.648,0.65-0.777,0.923c-0.284,0.603-0.122,2.063-0.486,2.622c-0.28,0.432-1.191,1.248-1.699,1.165
        c-0.497-0.08-0.815-1.296-1.213-1.602c-0.28-0.216-0.964-0.466-1.311-0.534c-0.464-0.091-1.457-0.182-1.894,0
        c-0.681,0.282-1.709,1.454-2.088,2.088c-0.39,0.653-0.506,2.248-0.874,2.913c-0.312,0.564-1.121,1.657-1.7,1.942
        c-0.338,0.167-1.156,0.242-1.505,0.097c-0.492-0.204-0.958-1.317-1.408-1.602c-0.448-0.284-1.524-0.458-2.04-0.583
        c-0.709-0.174-2.136-0.509-2.864-0.583c-1.002-0.101-3.033-0.145-4.03,0c-0.339,0.049-1.022,0.206-1.311,0.389
        c-0.399,0.253-0.842,1.191-1.263,1.408c-0.737,0.383-2.472,0.34-3.301,0.389C602.695,176.661,602.367,176.65,602.01,176.632z" pointer-events="auto" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_16_" x="514.344" y="118.345" opacity="0" fill="#FFFFFF" stroke="#010202" stroke-miterlimit="10" enable-background="new    " width="59.957" height="20.14" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_POLAND2_SWIETOKRZYSKIE" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Holy Cross" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_POLAND2_SWIETOKRZYSKIE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#7eb08e" d="M489.816,521.036c-0.004,0.03-0.004,0.061-0.004,0.096
        c-0.631-0.104-1.557-0.273-1.939-0.436c-0.514-0.218-1.33-1.045-1.844-1.263c-0.673-0.284-2.176-0.34-2.865-0.582
        c-0.374-0.132-1.176-0.407-1.408-0.728c-0.36-0.499-0.669-1.881-0.389-2.428c0.198-0.387,1.224-0.496,1.554-0.777
        c0.364-0.31,0.934-1.112,1.117-1.554c0.146-0.352,0.13-1.14,0.243-1.505c0.143-0.461,0.443-1.452,0.825-1.749
        c0.557-0.432,2.197-0.12,2.767-0.534c0.304-0.22,0.511-1.005,0.729-1.31c0.169-0.239,0.638-0.617,0.777-0.875
        c0.198-0.365,0.538-1.199,0.437-1.602c-0.078-0.307-0.561-0.87-0.875-0.922c-0.31-0.052-0.81,0.51-1.117,0.582
        c-0.544,0.127-1.723,0.178-2.233-0.048c-0.29-0.129-0.756-0.628-0.874-0.922c-0.18-0.451-0.278-1.544,0-1.943
        c0.182-0.26,0.892-0.325,1.189-0.436c0.287-0.107,0.954-0.195,1.141-0.437c0.263-0.338,0.443-1.352,0.195-1.699
        c-0.199-0.278-1.021-0.425-1.336-0.291c-0.223,0.095-0.272,0.777-0.509,0.825c-0.349,0.07-0.825-0.717-1.165-0.825
        c-0.509-0.163-1.645,0.208-2.136,0c-0.403-0.172-0.811-1.091-1.214-1.263c-0.434-0.186-1.424-0.059-1.894,0
        c-0.434,0.055-1.261,0.416-1.699,0.437c-0.319,0.015-1.008-0.002-1.263-0.194c-0.372-0.281-0.787-1.232-0.776-1.699
        c0.01-0.424,0.709-1.088,0.776-1.506c0.052-0.316-0.008-1.003-0.194-1.262c-0.184-0.257-1.01-0.287-1.117-0.583
        c-0.108-0.297,0.223-0.99,0.485-1.165c0.294-0.195,1.076,0.117,1.409,0c0.472-0.167,1.128-0.997,1.553-1.263
        c0.489-0.304,1.842-0.45,2.088-0.97c0.246-0.522-0.592-1.657-0.582-2.234c0.008-0.468,0.241-1.431,0.534-1.796
        c0.156-0.195,0.628-0.439,0.874-0.486c0.373-0.071,1.173,0.01,1.506,0.195c0.15,0.083,0.267,0.503,0.436,0.534
        c0.193,0.036,0.557-0.236,0.68-0.388c0.176-0.218,0.304-0.791,0.34-1.068c0.059-0.459,0.168-1.468-0.097-1.845
        c-0.287-0.41-1.485-0.43-1.845-0.777c-0.289-0.278-0.433-1.139-0.679-1.457c-0.165-0.211-0.628-0.5-0.825-0.679
        c-0.344-0.312-0.983-0.983-1.311-1.311l-0.272-0.272c0.304-0.635,0.599-1.363,0.855-1.768c0.524-0.825,1.726-2.378,2.379-3.107
        c0.481-0.537,1.769-1.343,2.087-1.99c0.21-0.427,0.243-1.42,0.195-1.894c-0.049-0.482-0.537-1.363-0.583-1.845
        c-0.05-0.523,0.102-1.582,0.243-2.088c0.078-0.282,0.396-0.788,0.485-1.067c0.098-0.306,0.08-0.986,0.243-1.263
        c0.201-0.343,1.005-0.683,1.214-1.02c0.323-0.52,0.138-1.958,0.583-2.379c0.358-0.34,1.467-0.502,1.942-0.365
        c0.524,0.151,1.099,1.272,1.602,1.482c0.482,0.202,1.608-0.11,2.088,0.098c0.433,0.186,1.136,0.894,1.359,1.311
        c0.35,0.652,0.106,2.308,0.534,2.912c0.282,0.399,1.249,0.781,1.699,0.971c0.489,0.205,1.535,0.747,2.04,0.582
        c0.394-0.129,0.885-0.918,1.019-1.311c0.154-0.447-0.132-1.439,0-1.894c0.158-0.54,1.023-1.364,1.214-1.894
        c0.132-0.367,0.089-1.177,0.193-1.554c0.153-0.549,0.842-1.522,0.923-2.087c0.064-0.449,0.052-1.453-0.243-1.797
        c-0.222-0.259-0.97-0.375-1.311-0.388c-0.582-0.024-1.703,0.555-2.282,0.485c-0.361-0.043-1.101-0.334-1.311-0.631
        c-0.255-0.358-0.203-1.308-0.194-1.747c0.013-0.651,0.341-1.925,0.388-2.574c0.035-0.447,0.082-1.355,0-1.796
        c-0.061-0.333-0.512-0.926-0.485-1.263c0.035-0.434,0.548-1.216,0.874-1.505c0.326-0.29,1.456-0.632,1.602-0.68
        c0.145-0.048,0.48-0.699,0.534-0.971c0.065-0.321,0.172-1.032,0-1.31c-0.158-0.255-0.907-0.283-1.068-0.535
        c-0.197-0.306-0.193-1.122-0.048-1.456c0.16-0.37,0.833-0.932,1.213-1.068c0.685-0.246,2.192-0.007,2.913,0.098
        c0.659,0.096,1.926,0.526,2.574,0.674c0.675,0.155,2.026,0.554,2.719,0.539c0.574-0.012,1.746-0.234,2.233-0.539
        c0.371-0.233,0.759-1.1,1.117-1.355c0.284-0.202,0.965-0.435,1.311-0.486c0.553-0.08,1.68,0.232,2.233,0.146
        c0.528-0.082,1.547-0.48,1.99-0.777c0.322-0.214,1.046-0.732,1.068-1.117c0.026-0.441-0.85-1.024-1.068-1.408
        c-0.161-0.285-0.497-0.888-0.485-1.214c0.012-0.363,0.28-1.213,0.631-1.31c0.447-0.124,1.092,0.967,1.553,1.019
        c0.451,0.05,1.312-0.396,1.7-0.631c0.279-0.169,0.665-0.757,0.97-0.874c0.454-0.174,1.553-0.29,1.942,0
        c0.408,0.302,0.195,1.616,0.583,1.942c0.328,0.274,1.328,0.407,1.699,0.194c0.391-0.225,0.692-1.202,0.729-1.651
        c0.046-0.576-0.426-1.678-0.583-2.234c-0.124-0.439-0.331-1.339-0.535-1.747c-0.147-0.295-0.674-0.755-0.776-1.068
        c-0.17-0.52-0.198-1.676,0-2.185c0.143-0.368,0.691-1.045,1.068-1.165c0.497-0.159,1.522,0.504,2.039,0.436
        c0.527-0.07,1.595-0.53,1.894-0.971c0.153-0.225,0.208-0.638,0.222-1.056c0.392,0.138,0.812,0.275,1.041,0.312
        c0.359,0.059,1.1-0.073,1.456,0c0.302,0.062,0.836,0.404,1.133,0.486c0.472,0.13,1.476,0.108,1.942,0.259
        c0.324,0.106,1.049,0.342,1.198,0.648c0.156,0.327,0.019,1.193-0.26,1.424c-0.205,0.172-0.805-0.044-1.068,0
        c-0.169,0.03-0.571,0.073-0.647,0.227c-0.174,0.351,0.169,1.259,0.486,1.489c0.327,0.239,1.277-0.217,1.618,0
        c0.339,0.216,0.488,1.104,0.679,1.457c0.205,0.377,0.728,1.067,0.907,1.456c0.248,0.54,0.554,1.693,0.712,2.266
        c0.102,0.369,0.126,1.183,0.356,1.49c0.183,0.246,0.766,0.679,1.068,0.614c0.282-0.06,0.454-0.756,0.679-0.939
        c0.295-0.239,1.013-0.58,1.391-0.614c0.316-0.03,0.946,0.153,1.231,0.29c0.176,0.086,0.42,0.513,0.615,0.486
        c0.327-0.044,0.497-0.892,0.776-1.068c0.321-0.199,1.142-0.408,1.489-0.259c0.242,0.104,0.484,0.631,0.583,0.874
        c0.131,0.321,0.095,1.055,0.26,1.359c0.235,0.433,0.986,1.136,1.425,1.359c0.358,0.184,1.26,0.055,1.586,0.291
        c0.328,0.238,0.614,1.053,0.777,1.425c0.193,0.438,0.706,1.343,0.615,1.814c-0.071,0.365-0.841,0.782-0.971,1.131
        c-0.095,0.259-0.068,0.834,0,1.102c0.097,0.374,0.367,1.27,0.744,1.359c0.278,0.066,0.764-0.423,0.94-0.649
        c0.186-0.238,0.208-0.917,0.421-1.132c0.219-0.222,0.858-0.396,1.165-0.453c0.294-0.055,0.919-0.112,1.197,0
        c0.261,0.106,0.701,0.519,0.81,0.777c0.152,0.357-0.201,1.222,0,1.554c0.128,0.21,0.629,0.465,0.875,0.452
        c0.338-0.016,0.804-0.66,1.131-0.743c0.661-0.168,2.055,0.377,2.719,0.226c0.879-0.198,2.33-1.362,3.108-1.813
        c0.437-0.252,1.248-0.943,1.747-1.003c0.604-0.072,1.783,0.418,2.331,0.679c0.436,0.208,1.192,0.822,1.587,1.101
        c0.399,0.28,1.1,1.115,1.586,1.133c0.312,0.011,0.78-0.531,1.068-0.648c0.247-0.099,0.823-0.069,1.037-0.227
        c0.383-0.281,0.772-1.205,0.938-1.651c0.101-0.271,0.248-0.845,0.227-1.133c-0.026-0.359-0.494-0.998-0.486-1.359
        c0.01-0.402,0.259-1.302,0.615-1.488c0.235-0.124,0.809,0.092,1.036,0.226c0.306,0.181,0.721,0.787,0.938,1.067
        c0.318,0.411,1.197,1.199,1.166,1.716c-0.025,0.419-0.836,0.939-1.101,1.263c-0.21,0.261-0.681,0.758-0.808,1.068
        c-0.144,0.348-0.405,1.178-0.195,1.489c0.215,0.319,1.148,0.212,1.489,0.388c0.433,0.226,1.124,0.94,1.489,1.263
        c0.305,0.27,0.957,0.774,1.199,1.101c0.234,0.318,0.645,1.032,0.679,1.424c0.035,0.394-0.325,1.139-0.421,1.522
        c-0.072,0.289-0.374,0.891-0.259,1.165c0.136,0.326,0.861,0.642,1.197,0.744c0.659,0.2,2.065,0.135,2.752,0.098
        c0.825-0.046,2.446-0.418,3.27-0.486c0.952-0.079,2.864-0.037,3.82-0.065c0.575-0.017,1.723-0.109,2.298-0.097
        c0.495,0.009,1.557-0.106,1.974,0.162c0.572,0.366,1.118,1.719,1.328,2.363c0.172,0.527-0.033,1.73,0.259,2.202
        c0.207,0.338,0.954,0.737,1.327,0.874c0.581,0.212,1.887,0.056,2.46,0.291c0.417,0.17,0.999,0.955,1.424,1.1
        c0.436,0.15,1.417,0.172,1.845,0c0.394-0.156,0.931-0.865,1.23-1.165c0.268-0.267,0.929-0.737,1.036-1.1
        c0.089-0.3-0.248-0.919-0.227-1.23c0.021-0.281,0.176-0.853,0.357-1.068c0.296-0.352,1.161-0.821,1.618-0.875
        c0.524-0.061,1.512,0.549,2.039,0.551c0.443,0.001,1.273-0.438,1.716-0.453c0.552-0.019,1.69,0.697,2.169,0.421
        c0.451-0.262,0.313-1.602,0.68-1.975c0.198-0.202,0.786-0.425,1.068-0.388c0.38,0.049,0.879,0.837,1.262,0.874
        c0.299,0.028,0.8-0.481,1.1-0.486c0.305-0.005,0.909,0.247,1.133,0.453c0.306,0.282,0.43,1.216,0.744,1.489
        c0.444,0.386,1.685,0.53,2.233,0.744c0.332,0.13,0.942,0.544,1.295,0.583c0.468,0.05,1.443-0.14,1.844-0.389
        c0.367-0.228,0.862-0.98,1.068-1.359c0.226-0.414,0.312-1.421,0.616-1.781c0.294-0.349,1.258-0.815,1.878-1.092
        c0.333,0.716,0.683,1.505,0.743,2.032c0.092,0.805-0.425,2.396-0.486,3.204c-0.056,0.751-0.206,2.287,0,3.01
        c0.266,0.934,1.904,2.342,2.039,3.301c0.152,1.074-1.285,3.099-1.165,4.176c0.074,0.659,1.062,1.699,1.263,2.33
        c0.305,0.956,0.218,3.015,0.486,3.982c0.254,0.922,1.299,2.574,1.553,3.496c0.325,1.179,0.51,3.635,0.582,4.855
        c0.041,0.671,0.071,1.651,0.079,2.659c0.01,1.209-0.01,2.459-0.079,3.265c-0.146,1.718-0.628,5.16-1.165,6.797
        c-0.202,0.619-0.749,1.829-1.165,2.331c-0.486,0.586-1.755,1.485-2.428,1.844c-0.724,0.387-2.431,0.603-3.107,1.068
        c-0.81,0.557-2.19,2.088-2.525,3.01c-0.273,0.753,0.298,2.461,0,3.205c-0.261,0.654-1.518,1.476-1.942,2.04
        c-0.607,0.809-1.533,2.619-2.039,3.496c-0.588,1.016-1.501,3.248-2.331,4.077c-0.969,0.97-3.514,2.148-4.758,2.719
        c-0.726,0.334-2.332,0.65-3.011,1.068c-0.774,0.48-1.998,1.862-2.621,2.525c-1.027,1.092-2.666,3.692-3.885,4.564
        c-0.912,0.654-3.205,1.088-4.175,1.651c-2.459,1.425-6.821,5.135-8.885,7.089c-0.411,0.388-1.544,1.105-1.605,1.683
        c0,0-0.273,0.797-0.419,1.036c-0.323,0.529-1.039,1.659-1.618,1.877c-0.443,0.168-1.44-0.44-1.877-0.259
        c-0.299,0.124-0.533,0.824-0.777,1.036c-0.653,0.568-2.234,1.324-3.027,1.668c-0.398,0.172-1.25,0.369-1.651,0.534
        c-0.327,0.133-0.929,0.514-1.263,0.631c-0.608,0.213-1.884,0.46-2.524,0.534c-0.53,0.061-1.616-0.071-2.136,0.048
        c-0.455,0.105-1.267,0.603-1.699,0.777c-0.584,0.234-1.763,0.696-2.379,0.825c-0.572,0.121-1.745,0.21-2.33,0.194
        c-0.441-0.012-1.307-0.266-1.748-0.243c-0.412,0.021-1.282,0.129-1.602,0.389c-0.326,0.262-0.428,1.212-0.729,1.505
        c-0.334,0.326-1.236,0.72-1.699,0.777c-0.66,0.082-1.962-0.585-2.621-0.486c-0.489,0.075-1.392,0.58-1.748,0.923
        c-0.454,0.436-0.931,1.647-1.263,2.184c-0.312,0.507-0.879,1.58-1.311,1.991c-0.377,0.361-1.291,0.929-1.796,1.068
        c-0.386,0.106-1.258,0.203-1.602,0c-0.453-0.269-0.616-1.506-1.019-1.845c-0.296-0.249-1.072-0.504-1.457-0.509
        c-0.534-0.01-1.586,0.35-2.04,0.631c-0.405,0.252-0.905,1.133-1.311,1.383c-0.388,0.241-1.324,0.361-1.747,0.535
        c-0.316,0.128-0.874,0.588-1.213,0.631c-0.341,0.042-0.994-0.285-1.336-0.316c-0.539-0.047-1.628,0.024-2.16,0.121
        c-0.294,0.054-0.882,0.215-1.141,0.364c-0.418,0.239-1.029,1.024-1.433,1.286c-0.451,0.292-1.435,0.789-1.966,0.875
        c-0.417,0.066-1.26-0.237-1.675-0.171c-0.354,0.056-0.955,0.547-1.311,0.583c-0.445,0.044-1.332-0.222-1.747-0.389
        c-0.337-0.135-0.943-0.543-1.239-0.752c-0.217-0.155-0.648-0.48-0.825-0.68c-0.194-0.22-0.37-0.857-0.631-0.995
        c-0.183-0.097-0.619,0.041-0.825,0.025c-0.154-0.013-0.485-0.028-0.607-0.121c-0.197-0.153-0.484-0.653-0.436-0.898
        c0.054-0.282,0.688-0.538,0.849-0.777c0.174-0.258,0.494-0.859,0.437-1.165c-0.038-0.207-0.393-0.502-0.558-0.632
        c-0.254-0.199-0.95-0.348-1.141-0.606c-0.132-0.179-0.083-0.669-0.17-0.875c-0.103-0.239-0.495-0.614-0.607-0.849
        c-0.116-0.245-0.34-0.778-0.291-1.045c0.057-0.311,0.642-0.722,0.752-1.019c0.132-0.353,0.215-1.139,0.121-1.506
        c-0.077-0.302-0.534-0.769-0.68-1.044c-0.176-0.332-0.31-1.125-0.583-1.384c-0.234-0.222-0.896-0.377-1.213-0.436
        c-0.27-0.051-0.841,0.081-1.093-0.025c-0.351-0.149-0.96-0.71-1.092-1.068c-0.158-0.429,0.081-1.372,0.17-1.821
        c0.056-0.286,0.122-0.923,0.34-1.117c0.328-0.291,1.381-0.043,1.724-0.316c0.361-0.288,0.711-1.237,0.729-1.699
        c0.016-0.449-0.405-1.286-0.583-1.699c-0.196-0.454-0.685-1.315-0.923-1.748c-0.276-0.503-0.805-1.525-1.14-1.99
        c-0.267-0.37-0.901-1.029-1.214-1.359c-0.259-0.274-0.866-0.748-1.068-1.068c-0.096-0.152-0.223-0.501-0.243-0.68
        c-0.049-0.462,0.31-1.384,0.243-1.845c-0.073-0.5-0.434-1.523-0.825-1.844c-0.251-0.206-0.976-0.138-1.262-0.291
        c-0.326-0.175-0.711-0.933-1.068-1.02c-0.209-0.05-0.611,0.257-0.825,0.243c-0.505-0.034-1.429-0.539-1.845-0.825
        c-0.353-0.244-0.797-1.116-1.214-1.214c-0.336-0.079-0.994,0.298-1.311,0.437c-0.457,0.2-1.264,0.846-1.747,0.97
        c-0.729,0.189-2.296,0.239-3.01,0c-0.519-0.173-1.365-0.92-1.747-1.31c-0.263-0.268-0.557-1.016-0.875-1.214
        c-0.553-0.346-1.921-0.446-2.573-0.437c-0.433,0.006-1.28,0.45-1.7,0.341c-0.432-0.114-0.958-0.969-1.359-1.166
        c-0.434-0.212-1.414-0.44-1.894-0.388c-0.516,0.056-1.532,0.479-1.894,0.85C490.27,519.45,489.866,520.504,489.816,521.036z" pointer-events="auto" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_17_" x="515.593" y="485.776" opacity="0" fill="#FFFFFF" stroke="#010202" stroke-miterlimit="10" enable-background="new    " width="59.957" height="20.14" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_POLAND2_MALOPOLSKIE" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Lesser Poland" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_POLAND2_MALOPOLSKIE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#7eb08e" d="M489.813,521.131c0-0.035,0-0.066,0.004-0.096c0.049-0.531,0.453-1.586,0.825-1.967
        c0.361-0.371,1.377-0.794,1.894-0.85c0.48-0.052,1.459,0.176,1.894,0.388c0.401,0.197,0.926,1.052,1.359,1.166
        c0.42,0.11,1.267-0.335,1.7-0.341c0.652-0.009,2.02,0.092,2.573,0.437c0.318,0.198,0.612,0.947,0.875,1.214
        c0.382,0.39,1.228,1.137,1.747,1.31c0.714,0.239,2.282,0.189,3.01,0c0.484-0.124,1.29-0.77,1.747-0.97
        c0.317-0.139,0.975-0.516,1.311-0.437c0.418,0.099,0.861,0.97,1.214,1.214c0.417,0.286,1.341,0.792,1.845,0.825
        c0.214,0.014,0.616-0.293,0.825-0.243c0.357,0.087,0.742,0.845,1.068,1.02c0.285,0.153,1.011,0.085,1.262,0.291
        c0.391,0.321,0.752,1.345,0.825,1.844c0.067,0.461-0.292,1.383-0.243,1.845c0.02,0.18,0.147,0.528,0.243,0.68
        c0.202,0.32,0.81,0.794,1.068,1.068c0.313,0.331,0.948,0.989,1.214,1.359c0.336,0.465,0.864,1.488,1.14,1.99
        c0.238,0.433,0.727,1.294,0.923,1.748c0.178,0.413,0.598,1.25,0.583,1.699c-0.018,0.462-0.367,1.411-0.729,1.699
        c-0.343,0.272-1.396,0.025-1.724,0.316c-0.217,0.194-0.283,0.83-0.34,1.117c-0.089,0.448-0.328,1.391-0.17,1.821
        c0.132,0.358,0.74,0.919,1.092,1.068c0.252,0.106,0.823-0.027,1.093,0.025c0.317,0.059,0.979,0.214,1.213,0.436
        c0.273,0.259,0.407,1.052,0.583,1.384c0.146,0.274,0.603,0.741,0.68,1.044c0.094,0.366,0.011,1.152-0.121,1.506
        c-0.111,0.297-0.695,0.708-0.752,1.019c-0.048,0.267,0.175,0.8,0.291,1.045c0.113,0.235,0.505,0.61,0.607,0.849
        c0.087,0.205,0.038,0.696,0.17,0.875c0.192,0.259,0.888,0.407,1.141,0.606c0.165,0.13,0.52,0.425,0.558,0.632
        c0.056,0.306-0.264,0.907-0.437,1.165c-0.161,0.239-0.795,0.495-0.849,0.777c-0.047,0.246,0.239,0.745,0.436,0.898
        c0.122,0.094,0.453,0.109,0.607,0.121c0.206,0.017,0.643-0.121,0.825-0.025c0.261,0.138,0.436,0.775,0.631,0.995
        c0.178,0.2,0.608,0.525,0.825,0.68c0.296,0.209,0.902,0.617,1.239,0.752c0.416,0.167,1.302,0.433,1.747,0.389
        c0.356-0.037,0.957-0.527,1.311-0.583c0.416-0.066,1.259,0.237,1.675,0.171c0.531-0.086,1.515-0.582,1.966-0.875
        c0.404-0.263,1.015-1.047,1.433-1.286c0.26-0.149,0.847-0.31,1.141-0.364c0.532-0.098,1.621-0.169,2.16-0.121
        c0.342,0.031,0.995,0.358,1.336,0.316c0.34-0.042,0.897-0.503,1.213-0.631c0.424-0.174,1.359-0.294,1.747-0.535
        c0.406-0.25,0.906-1.131,1.311-1.383c0.454-0.281,1.506-0.641,2.04-0.631c0.385,0.006,1.161,0.261,1.457,0.509
        c0.403,0.34,0.566,1.577,1.019,1.845c0.345,0.203,1.216,0.106,1.602,0c0.505-0.139,1.419-0.707,1.796-1.068
        c0.432-0.412,0.999-1.484,1.311-1.991c0.332-0.537,0.809-1.747,1.263-2.184c0.356-0.344,1.26-0.848,1.748-0.923
        c0.659-0.1,1.961,0.568,2.621,0.486c0.463-0.057,1.365-0.451,1.699-0.777c0.3-0.292,0.403-1.243,0.729-1.505
        c0.32-0.26,1.191-0.368,1.602-0.389c0.441-0.023,1.307,0.231,1.748,0.243c0.584,0.016,1.758-0.073,2.33-0.194
        c0.616-0.129,1.795-0.591,2.379-0.825c0.432-0.174,1.244-0.672,1.699-0.777c0.52-0.119,1.606,0.013,2.136-0.048
        c0.641-0.074,1.916-0.321,2.524-0.534c0.334-0.116,0.936-0.498,1.263-0.631c0.401-0.165,1.253-0.362,1.651-0.534
        c0.793-0.344,2.374-1.1,3.027-1.668c0.244-0.211,0.478-0.911,0.777-1.036c0.436-0.182,1.434,0.427,1.877,0.259
        c0.58-0.218,1.295-1.348,1.618-1.877c0.146-0.239,0.419-1.036,0.419-1.036c-0.004,0.039-0.004,0.076,0.002,0.113
        c0.069,0.429,1.322,0.48,1.506,0.875c0.266,0.57-0.119,1.933-0.437,2.476c-0.174,0.298-0.825,0.627-1.068,0.874
        c-0.453,0.463-1.142,1.581-1.601,2.039c-0.251,0.251-0.936,0.57-1.118,0.875c-0.339,0.571-0.701,2.012-0.436,2.622
        c0.226,0.522,1.767,0.707,1.894,1.262c0.138,0.605-1.329,1.52-1.263,2.136c0.048,0.444,1.042,0.898,1.213,1.311
        c0.216,0.515,0.155,1.676,0.098,2.233c-0.086,0.834-0.485,2.486-0.825,3.253c-0.124,0.279-0.591,0.727-0.68,1.02
        c-0.151,0.499-0.151,1.589,0,2.087c0.153,0.503,0.821,1.352,1.166,1.748c0.202,0.234,0.74,0.575,0.922,0.825
        c0.373,0.514,0.94,1.699,1.02,2.331c0.075,0.598-0.079,1.861-0.389,2.378c-0.235,0.394-1.197,0.739-1.456,1.117
        c-0.193,0.282-0.353,0.971-0.388,1.311c-0.047,0.449-0.093,1.416,0.145,1.797c0.165,0.263,0.832,0.43,1.068,0.631
        c0.216,0.185,0.698,0.591,0.729,0.874c0.055,0.503-0.599,1.41-0.923,1.797c-0.333,0.399-1.366,0.827-1.651,1.263
        c-0.225,0.346-0.381,1.19-0.388,1.602c-0.017,1.017,0.416,3.051,0.825,3.981c0.284,0.644,1.051,1.912,1.651,2.283
        c0.639,0.394,2.211,0.496,2.962,0.486c0.643-0.009,1.893-0.604,2.524-0.486c0.606,0.114,1.719,0.818,2.088,1.311
        c0.348,0.464,0.769,1.669,0.631,2.233c-0.138,0.567-1.066,1.468-1.601,1.699c-0.413,0.178-1.349-0.029-1.797,0
        c-0.489,0.031-1.56-0.063-1.943,0.243c-0.363,0.29-0.348,1.379-0.63,1.748c-0.207,0.27-0.794,0.674-1.118,0.776
        c-0.59,0.186-1.937-0.304-2.476,0c-0.185,0.105-0.253,0.624-0.436,0.729c-0.433,0.246-1.517-0.152-1.991,0
        c-0.198,0.064-0.568,0.31-0.68,0.486c-0.212,0.333-0.443,1.189-0.29,1.554c0.192,0.46,1.378,0.699,1.651,1.116
        c0.336,0.517,0.321,1.823,0.436,2.429c0.117,0.607,0.221,1.868,0.486,2.427c0.115,0.243,0.432,0.725,0.679,0.825
        c0.859,0.352,2.764-0.437,3.69-0.388c0.521,0.029,1.625,0.12,2.04,0.437c0.314,0.241,0.426,1.15,0.729,1.408
        c0.466,0.399,1.763,0.538,2.33,0.777c0.365,0.154,1.102,0.477,1.408,0.728c0.301,0.247,0.758,0.891,0.971,1.214
        c0.241,0.368,0.661,1.145,0.825,1.554c0.267,0.666,0.484,2.111,0.776,2.767c0.13,0.289,0.575,0.77,0.68,1.068
        c0.249,0.702,0.132,2.246,0.341,2.962c0.124,0.427,0.659,1.173,0.776,1.602c0.191,0.693,0.456,2.178,0.243,2.864
        c-0.255,0.823-1.811,1.893-2.185,2.671c-0.329,0.683-0.834,2.232-0.631,2.962c0.138,0.496,0.976,1.223,1.408,1.505
        c0.399,0.261,1.423,0.338,1.797,0.632c0.194,0.152,0.539,0.58,0.533,0.825c-0.01,0.373-0.758,0.857-0.873,1.213
        c-0.094,0.289-0.12,0.935,0,1.214c0.138,0.323,0.851,0.661,1.019,0.971c0.282,0.521,0.068,1.867,0.436,2.331
        c0.322,0.404,1.536,0.453,1.894,0.825c0.326,0.338,0.69,1.279,0.68,1.748c-0.011,0.529-0.824,1.412-0.85,1.941
        c-0.019,0.41,0.497,1.145,0.51,1.554c0.013,0.389-0.341,1.12-0.388,1.506c-0.059,0.457,0.073,1.389,0,1.845
        c-0.051,0.314-0.294,1-0.464,1.453c-1.021-0.383-2.53-0.885-3.372-1.065c-1.098-0.236-3.361-0.683-4.466-0.486
        c-0.908,0.163-2.724,0.931-3.301,1.652c-0.439,0.546-0.174,2.233-0.68,2.719c-0.518,0.498-2.173,0.903-2.816,0.583
        c-0.408-0.204-0.469-1.315-0.777-1.651c-0.494-0.537-1.825-1.251-2.525-1.457c-0.956-0.281-2.995-0.34-3.981-0.193
        c-0.357,0.051-1.117,0.218-1.359,0.485c-0.471,0.519-0.818,2.033-0.68,2.719c0.12,0.594,1.283,1.36,1.457,1.942
        c0.215,0.723,0.349,2.488-0.195,3.011c-0.771,0.74-3.47-0.607-4.272,0.097c-0.831,0.729-0.079,3.442-0.68,4.37
        c-0.535,0.824-2.353,1.883-3.302,2.136c-0.892,0.238-2.768-0.253-3.689-0.195c-0.542,0.035-1.636,0.598-2.136,0.388
        c-0.595-0.249-0.829-1.978-1.456-2.136c-0.716-0.18-1.89,1.275-2.622,1.36c-0.498,0.057-1.524-0.21-1.942-0.486
        c-0.94-0.622-2.106-2.648-2.719-3.594c-0.627-0.965-2.039-3.689-2.136-4.077c-0.098-0.389-0.304-2.486-0.97-2.817
        c-0.73-0.362-2.333,0.721-3.108,0.971c-0.877,0.283-2.603,0.938-3.496,1.165c-0.668,0.17-2.143,0.105-2.719,0.486
        c-0.702,0.464-1.034,2.593-1.845,2.816c-1.015,0.279-2.895-1.285-3.787-1.845c-0.864-0.541-2.294-2.064-3.205-2.524
        c-0.652-0.329-2.211-1.187-2.816-0.777c-0.729,0.494,0.221,2.863-0.388,3.496c-0.801,0.83-3.415,0.774-4.564,0.68
        c-0.789-0.065-2.219-0.995-3.01-0.971c-0.469,0.014-1.509,0.276-1.748,0.679c-0.3,0.505,0.061,1.791,0.292,2.331
        c0.195,0.461,1.136,1.069,1.262,1.554c0.105,0.406,0.011,1.36-0.291,1.651c-0.511,0.494-2.108,0.447-2.816,0.388
        c-0.811-0.067-2.302-0.848-3.108-0.97c-1.032-0.157-3.174-0.295-4.175,0c-0.859,0.254-2.543,1.178-3.01,1.942
        c-0.509,0.832-0.193,2.928-0.389,3.884c-0.097,0.474-0.275,1.471-0.582,1.845c-0.509,0.623-2.417,0.856-2.816,1.554
        c-0.422,0.738,0.133,2.549,0.097,3.398c-0.045,1.074,0.147,3.403-0.486,4.273c-0.345,0.475-1.549,0.969-2.135,0.971
        c-0.718,0.003-1.988-0.83-2.622-1.166c-0.53-0.28-1.598-0.856-2.04-1.262c-0.51-0.47-1.021-1.939-1.651-2.233
        c-1.124-0.525-3.791-0.144-4.952,0.29c-0.892,0.335-2.15,1.924-3.01,2.331c-0.788,0.373-2.526,0.786-3.399,0.777
        c-1.201-0.012-3.753-0.379-4.661-1.165c-0.435-0.378-0.762-1.571-0.874-2.136c-0.085-0.43-0.161-1.343,0-1.747
        c0.222-0.559,1.215-1.334,1.651-1.748c0.648-0.617,2.29-1.545,2.719-2.331c0.341-0.623,0.651-2.155,0.389-2.816
        c-0.357-0.898-2.585-1.56-3.011-2.428c-0.503-1.024-0.085-3.423-0.097-4.564c-0.009-0.825,0.324-2.542,0-3.301
        c-0.343-0.806-1.659-2.302-2.524-2.428c-0.785-0.114-2.047,1.27-2.817,1.456c-0.873,0.213-2.762,0.345-3.593,0
        c-0.698-0.289-1.66-1.55-2.136-2.136c-0.752-0.923-1.821-3.098-2.621-3.981c-0.65-0.716-2.545-1.58-3.011-2.428
        c-0.316-0.579-0.128-1.983-0.29-2.622c-0.286-1.119-1.526-3.141-1.748-4.272c-0.178-0.905,0.25-2.802,0-3.69
        c-0.316-1.122-1.519-3.25-2.428-3.982c-0.283-0.228-0.808-0.472-1.336-0.643c0.064-0.318,0.174-0.811,0.268-1.008
        c0.18-0.374,0.992-0.91,1.003-1.327c0.013-0.449-0.653-1.209-1.003-1.489c-0.631-0.503-2.24-0.923-3.01-1.166
        c-0.549-0.172-1.879-0.097-2.233-0.55c-0.23-0.292-0.138-1.143,0-1.489c0.156-0.393,0.879-0.917,1.165-1.23
        c0.388-0.423,1.261-1.206,1.521-1.715c0.321-0.628,0.745-2.058,0.615-2.753c-0.099-0.526-0.771-1.455-1.198-1.779
        c-0.509-0.388-1.81-0.644-2.428-0.81c-1.14-0.305-3.458-0.782-4.629-0.939c-0.594-0.08-1.841,0.071-2.395-0.162
        c-0.55-0.23-1.359-1.164-1.78-1.587c-0.405-0.405-1.354-1.167-1.521-1.716c-0.11-0.357,0.085-1.128,0.194-1.488
        c0.107-0.346,0.565-0.943,0.648-1.294c0.092-0.387,0.172-1.228,0-1.588c-0.188-0.394-0.953-0.938-1.359-1.1
        c-0.581-0.231-1.87-0.246-2.493-0.193c-0.639,0.051-1.85,0.61-2.492,0.614c-0.465,0.004-1.457-0.121-1.813-0.421
        c-0.364-0.305-0.65-1.282-0.745-1.747c-0.095-0.468-0.128-1.45,0-1.91c0.117-0.42,0.777-1.073,0.907-1.489
        c0.125-0.402,0.111-1.263,0.097-1.683c-0.022-0.644-0.306-1.912-0.324-2.557c-0.016-0.576,0.286-1.736,0.162-2.297
        c-0.094-0.426-0.479-1.282-0.842-1.521c-0.264-0.176-0.955-0.208-1.262-0.13c-0.428,0.11-1.044,0.845-1.457,1.004
        c-0.502,0.195-1.642,0.506-2.136,0.291c-0.362-0.158-0.787-0.918-0.906-1.295c-0.119-0.378-0.109-1.204,0-1.586
        c0.084-0.294,0.558-0.743,0.648-1.036c0.155-0.511,0.061-1.602,0.064-2.136c0.005-0.834-0.265-2.524-0.064-3.334
        c0.169-0.682,0.959-1.883,1.359-2.46c0.405-0.585,1.268-1.751,1.845-2.168c0.261-0.19,0.912-0.334,1.197-0.486
        c0.268-0.142,0.817-0.442,1.003-0.679c0.316-0.402,0.53-1.435,0.713-1.91c0.118-0.309,0.305-0.953,0.486-1.231
        c0.427-0.656,1.757-1.586,2.201-2.233c0.346-0.504,0.602-1.771,1.004-2.233c0.269-0.31,1.169-0.528,1.391-0.874
        c0.227-0.349,0.026-1.286,0.227-1.651c0.125-0.226,0.55-0.576,0.793-0.663c0.382-0.139,1.221-0.036,1.626,0
        c0.516,0.045,1.564,0.568,2.04,0.364c0.334-0.144,0.668-0.876,0.802-1.214c0.239-0.606,0.218-1.959,0.436-2.573
        c0.141-0.395,0.687-1.062,0.825-1.457c0.113-0.319,0.068-1.032,0.219-1.335c0.203-0.412,0.92-1.029,1.262-1.335
        c0.306-0.275,0.92-0.865,1.311-0.995c0.299-0.102,1.019,0.2,1.262,0c0.32-0.264,0.451-1.236,0.316-1.627
        c-0.16-0.463-1.076-1.01-1.481-1.286c-0.441-0.302-1.383-0.835-1.894-0.996c-0.373-0.116-1.215,0.004-1.554-0.194
        c-0.318-0.187-0.709-0.859-0.874-1.19c-0.121-0.244-0.439-0.801-0.315-1.044c0.146-0.286,0.907-0.317,1.213-0.413
        c0.765-0.242,2.533-0.314,3.083-0.898c0.265-0.28,0.091-1.211,0.34-1.506c0.242-0.284,1.088-0.331,1.384-0.558
        c0.193-0.148,0.509-0.546,0.582-0.777c0.104-0.331-0.042-1.039-0.048-1.384c-0.009-0.473-0.196-1.462,0-1.894
        c0.076-0.168,0.374-0.483,0.558-0.485c0.401-0.006,0.85,0.922,1.238,1.019c0.36,0.091,1.108-0.166,1.457-0.291
        c0.363-0.129,1.155-0.401,1.359-0.728c0.177-0.284,0.177-1.028,0.048-1.336c-0.12-0.289-0.624-0.756-0.922-0.85
        c-0.375-0.117-1.206,0.402-1.554,0.219c-0.265-0.139-0.561-0.771-0.534-1.068c0.027-0.316,0.497-0.831,0.752-1.02
        c0.207-0.153,0.735-0.441,0.971-0.34c0.311,0.133,0.271,1.057,0.558,1.238c0.231,0.145,0.883,0.149,1.093-0.025
        c0.24-0.197,0.295-0.904,0.267-1.213c-0.031-0.341-0.461-0.927-0.534-1.263c-0.086-0.397-0.238-1.266-0.048-1.626
        c0.161-0.307,0.868-0.576,1.166-0.753c0.272-0.162,0.801-0.571,1.116-0.606c0.514-0.058,1.517,0.353,1.991,0.558
        c0.345,0.15,0.917,0.714,1.286,0.777c0.586,0.101,1.741-0.412,2.331-0.486c0.542-0.067,1.653,0.029,2.185-0.097
        c0.452-0.106,1.283-0.545,1.699-0.752c0.542-0.272,1.515-1.084,2.112-1.19c0.558-0.1,1.669,0.384,2.233,0.364
        c0.606-0.022,1.799-0.321,2.356-0.559c0.451-0.194,1.18-0.929,1.651-1.068c0.215-0.064,0.699-0.104,0.898,0
        c0.348,0.182,0.549,1.108,0.898,1.286c0.47,0.242,1.625,0.181,2.112-0.024c0.284-0.119,0.593-0.745,0.874-0.874
        c0.431-0.198,1.485,0.095,1.894-0.146c0.275-0.162,0.59-0.769,0.704-1.068c0.133-0.349-0.016-1.189,0.218-1.481
        c0.244-0.304,1.067-0.546,1.457-0.559c0.432-0.014,1.225,0.436,1.651,0.51c0.401,0.07,1.315,0.335,1.626,0.073
        c0.263-0.22,0.279-1.036,0.171-1.359c-0.101-0.297-0.606-0.737-0.875-0.898c-0.205-0.123-0.742-0.108-0.922-0.268
        c-0.276-0.244-0.471-1.006-0.582-1.359C490.042,522.276,489.814,521.56,489.813,521.131z" pointer-events="auto" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_18_" x="484.365" y="600.094" opacity="0" fill="#FFFFFF" stroke="#010202" stroke-miterlimit="10" enable-background="new    " width="59.957" height="20.14" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_POLAND2_PODKARPACKIE" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Subcarpathian" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_POLAND2_PODKARPACKIE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#7eb08e" d="M754.771,542.107c0.303,0.331,0.354,1.756,0.354,1.756
        c-2.454,2.733-5.672,8.675-7.831,11.304c-1.047,1.275-3.544,3.464-4.565,4.759c-1.001,1.269-2.269,4.385-3.495,5.438
        c-0.545,0.467-2.156,0.619-2.622,1.165c-0.734,0.861-0.659,3.359-1.165,4.37c-1.052,2.102-4.447,5.477-5.827,7.381
        c-2.6,3.587-7.47,10.996-9.808,14.76c-1.762,2.836-5.137,8.604-6.7,11.555c-0.681,1.286-1.546,4.167-2.526,5.244
        c-0.725,0.798-2.989,1.414-3.69,2.233c-0.664,0.778-1.085,2.877-1.553,3.787c-0.668,1.3-2.337,3.71-3.107,4.952
        c-0.661,1.065-1.953,3.214-2.622,4.273c-0.619,0.979-2.552,2.727-2.525,3.884c0.018,0.748,1.376,1.779,1.748,2.428
        c0.953,1.659,2.538,5.166,3.107,6.992c0.897,2.877,1.919,8.849,2.233,11.847c0.223,2.126,0.492,6.429,0.194,8.545
        c-0.159,1.133-1.616,3.255-1.359,4.37c0.12,0.519,1.087,1.171,1.457,1.554c0.668,0.69,2.156,1.939,2.719,2.719
        c0.349,0.485,0.56,1.823,1.068,2.136c0.269,0.166,1.003-0.181,1.262,0c0.466,0.326,0.488,1.647,0.777,2.136
        c0.394,0.665,1.525,1.751,2.039,2.33c1.108,1.247,3.869,3.396,4.467,4.952c0.279,0.726-0.111,2.339,0,3.108
        c0.122,0.851,0.982,2.442,0.97,3.301c-0.01,0.791-0.309,2.577-0.97,3.01c-0.244,0.161-0.875,0.021-1.166,0
        c-0.683-0.048-2.074-0.15-2.719-0.388c-0.592-0.219-1.586-1.049-2.135-1.359c-0.992-0.562-2.969-1.777-4.078-2.04
        c-1.56-0.368-4.893,0.516-6.409,0c-1.131-0.384-2.824-2.206-3.787-2.913c-0.478-0.349-1.376-1.181-1.942-1.359
        c-1.227-0.387-3.861,0.061-5.147,0c-1.534-0.073-4.65-0.129-6.117-0.582c-1.007-0.312-3.14-1.141-3.69-2.04
        c-0.368-0.6,0.383-2.299-0.097-2.816c-0.764-0.823-3.388-0.8-4.467-0.486c-0.447,0.13-0.998,1.08-1.456,1.165
        c-1.179,0.217-3.607-0.737-4.564-1.456c-0.279-0.211-0.684-0.827-0.777-1.166c-0.129-0.469,0.221-1.472,0.097-1.942
        c-0.08-0.306-0.385-0.952-0.679-1.068c-0.815-0.322-2.523,0.83-3.398,0.874c-0.639,0.033-1.903-0.243-2.525-0.388
        c-1.264-0.293-3.756-1.053-4.952-1.554c-0.863-0.36-2.658-1.071-3.302-1.747c-0.668-0.699-1.473-2.546-1.651-3.496
        c-0.13-0.693,0.378-2.135,0.193-2.816c-0.169-0.624-1.044-1.639-1.456-2.136c-0.525-0.634-1.802-1.7-2.331-2.331
        c-0.38-0.454-1.037-1.444-1.359-1.942c-0.386-0.593-0.887-2.009-1.457-2.428c-0.423-0.31-1.538-0.332-2.04-0.486
        c-0.991-0.303-2.922-1.067-3.884-1.456c-0.958-0.387-2.827-2.032-3.787-1.651c-0.344,0.136-0.34,1.08-0.582,1.359
        c-0.559,0.64-2.074,1.88-2.912,1.747c-0.766-0.12-1.768-1.606-2.04-2.33c-0.239-0.637,0.341-2.132,0-2.719
        c-0.983-1.693-4.861-3.595-6.798-3.885c-1.305-0.195-3.855,0.891-5.146,1.166c-1.909,0.407-7.283,1.456-7.671,1.456
        c-0.389,0-3.07,0.565-4.079,0.389c-0.877-0.154-2.379-1.222-3.205-1.554c-0.148-0.06-0.322-0.126-0.511-0.197
        c0.17-0.453,0.413-1.139,0.464-1.453c0.073-0.456-0.059-1.388,0-1.845c0.047-0.386,0.401-1.117,0.388-1.506
        c-0.014-0.409-0.529-1.144-0.51-1.554c0.026-0.529,0.839-1.412,0.85-1.941c0.01-0.47-0.354-1.411-0.68-1.748
        c-0.357-0.372-1.572-0.422-1.894-0.825c-0.368-0.464-0.154-1.81-0.436-2.331c-0.168-0.31-0.881-0.648-1.019-0.971
        c-0.12-0.279-0.094-0.925,0-1.214c0.115-0.356,0.863-0.84,0.873-1.213c0.006-0.246-0.34-0.673-0.533-0.825
        c-0.374-0.294-1.398-0.371-1.797-0.632c-0.431-0.281-1.27-1.009-1.408-1.505c-0.203-0.73,0.302-2.279,0.631-2.962
        c0.374-0.778,1.93-1.847,2.185-2.671c0.213-0.686-0.052-2.171-0.243-2.864c-0.117-0.43-0.652-1.175-0.776-1.602
        c-0.208-0.716-0.092-2.26-0.341-2.962c-0.106-0.298-0.55-0.779-0.68-1.068c-0.292-0.657-0.509-2.101-0.776-2.767
        c-0.164-0.409-0.584-1.186-0.825-1.554c-0.213-0.324-0.67-0.968-0.971-1.214c-0.306-0.251-1.043-0.574-1.408-0.728
        c-0.567-0.239-1.864-0.378-2.33-0.777c-0.302-0.258-0.415-1.167-0.729-1.408c-0.415-0.317-1.518-0.409-2.04-0.437
        c-0.926-0.049-2.832,0.74-3.69,0.388c-0.247-0.101-0.565-0.583-0.679-0.825c-0.265-0.559-0.369-1.82-0.486-2.427
        c-0.115-0.606-0.101-1.911-0.436-2.429c-0.273-0.417-1.459-0.656-1.651-1.116c-0.153-0.365,0.078-1.221,0.29-1.554
        c0.113-0.176,0.482-0.422,0.68-0.486c0.474-0.152,1.558,0.246,1.991,0c0.184-0.105,0.252-0.624,0.436-0.729
        c0.539-0.304,1.886,0.186,2.476,0c0.324-0.102,0.91-0.506,1.118-0.776c0.282-0.369,0.267-1.458,0.63-1.748
        c0.383-0.306,1.454-0.212,1.943-0.243c0.448-0.029,1.384,0.178,1.797,0c0.535-0.231,1.463-1.132,1.601-1.699
        c0.138-0.564-0.283-1.769-0.631-2.233c-0.369-0.494-1.482-1.198-2.088-1.311c-0.632-0.118-1.882,0.477-2.524,0.486
        c-0.751,0.01-2.323-0.092-2.962-0.486c-0.599-0.37-1.366-1.639-1.651-2.283c-0.41-0.93-0.842-2.964-0.825-3.981
        c0.007-0.413,0.163-1.257,0.388-1.602c0.284-0.435,1.318-0.864,1.651-1.263c0.324-0.387,0.978-1.294,0.923-1.797
        c-0.031-0.282-0.512-0.689-0.729-0.874c-0.236-0.2-0.903-0.368-1.068-0.631c-0.238-0.381-0.193-1.348-0.145-1.797
        c0.035-0.34,0.195-1.029,0.388-1.311c0.26-0.377,1.221-0.723,1.456-1.117c0.31-0.517,0.464-1.78,0.389-2.378
        c-0.08-0.632-0.647-1.817-1.02-2.331c-0.182-0.251-0.72-0.591-0.922-0.825c-0.345-0.397-1.013-1.245-1.166-1.748
        c-0.151-0.499-0.151-1.589,0-2.087c0.089-0.293,0.556-0.74,0.68-1.02c0.341-0.767,0.739-2.419,0.825-3.253
        c0.057-0.557,0.119-1.718-0.098-2.233c-0.172-0.413-1.165-0.867-1.213-1.311c-0.066-0.616,1.401-1.531,1.263-2.136
        c-0.126-0.555-1.668-0.739-1.894-1.262c-0.265-0.61,0.098-2.052,0.436-2.622c0.182-0.305,0.867-0.624,1.118-0.875
        c0.459-0.458,1.148-1.576,1.601-2.039c0.243-0.247,0.894-0.576,1.068-0.874c0.318-0.543,0.703-1.906,0.437-2.476
        c-0.184-0.395-1.437-0.445-1.506-0.875c-0.006-0.037-0.006-0.074-0.002-0.113c0.061-0.579,1.195-1.295,1.605-1.683
        c2.063-1.954,6.425-5.664,8.885-7.089c0.971-0.563,3.263-0.997,4.175-1.651c1.219-0.872,2.858-3.472,3.885-4.564
        c0.623-0.663,1.847-2.046,2.621-2.525c0.679-0.419,2.286-0.735,3.011-1.068c1.244-0.571,3.789-1.749,4.758-2.719
        c0.83-0.829,1.743-3.062,2.331-4.077c0.505-0.877,1.432-2.687,2.039-3.496c0.424-0.564,1.681-1.386,1.942-2.04
        c0.298-0.743-0.273-2.451,0-3.205c0.336-0.922,1.716-2.453,2.525-3.01c0.676-0.465,2.383-0.681,3.107-1.068
        c0.672-0.359,1.942-1.258,2.428-1.844c0.416-0.502,0.963-1.712,1.165-2.331c0.537-1.638,1.019-5.08,1.165-6.797
        c0.069-0.806,0.089-2.056,0.079-3.265c0.488-0.006,1.134,0.001,1.427,0.06c0.384,0.079,1.07,0.515,1.456,0.582
        c0.623,0.109,1.9-0.185,2.525-0.097c0.582,0.081,1.647,0.762,2.233,0.729c0.494-0.029,1.319-0.701,1.797-0.825
        c0.282-0.075,0.896,0.017,1.165-0.098c0.657-0.28,1.591-1.433,2.136-1.894c0.44-0.374,1.323-1.124,1.797-1.456
        c0.383-0.271,1.137-0.912,1.601-0.971c0.667-0.084,1.951,0.523,2.574,0.777c0.609,0.248,1.727,0.953,2.331,1.213
        c0.636,0.277,2.163,0.401,2.621,0.923c0.27,0.308,0.23,1.207,0.341,1.602c0.069,0.248,0.151,0.796,0.34,0.971
        c0.314,0.288,1.315,0.172,1.651,0.436c0.442,0.35,1.07,1.426,1.068,1.99c-0.002,0.457-0.619,1.224-0.875,1.602
        c-0.535,0.795-2.138,2.049-2.427,2.962c-0.194,0.616,0.419,1.969,0.194,2.574c-0.173,0.467-1.281,0.926-1.408,1.408
        c-0.138,0.524,0.093,1.858,0.583,2.087c0.482,0.227,1.41-0.883,1.943-0.874c0.581,0.009,1.63,0.661,2.087,1.02
        c0.448,0.351,1.11,1.297,1.457,1.747c0.346,0.449,0.857,1.507,1.311,1.845c0.389,0.291,1.374,0.502,1.844,0.632
        c0.67,0.185,2.044,0.42,2.719,0.582c0.502,0.12,1.501,0.376,1.99,0.534c0.556,0.179,1.684,0.526,2.186,0.825
        c0.62,0.369,1.463,1.839,2.184,1.894c0.273,0.02,0.661-0.507,0.923-0.582c0.339-0.099,1.068-0.091,1.408,0
        c0.757,0.2,1.944,1.442,2.719,1.554c0.309,0.043,0.906-0.343,1.213-0.292c0.453,0.074,1.41,0.537,1.554,0.971
        c0.182,0.543-0.6,1.61-0.825,2.136c-0.168,0.394-0.723,1.125-0.729,1.554c-0.006,0.516,0.761,1.38,0.825,1.894
        c0.048,0.377-0.424,1.17-0.242,1.505c0.171,0.316,0.953,0.585,1.31,0.583c0.349-0.003,0.923-0.507,1.264-0.583
        c0.225-0.049,0.742-0.144,0.922,0c0.269,0.218,0.472,1.062,0.29,1.359c-0.189,0.312-1.089,0.208-1.408,0.388
        c-0.276,0.157-0.837,0.569-0.922,0.875c-0.06,0.218,0.041,0.769,0.243,0.874c0.583,0.303,1.821-0.85,2.476-0.874
        c0.276-0.01,0.989,0.026,1.068,0.291c0.148,0.495-1.004,1.179-1.359,1.553c-0.431,0.455-1.229,1.481-1.796,1.748
        c-0.719,0.338-2.364,0.314-3.156,0.34c-0.572,0.019-1.796,0.202-2.282-0.097c-0.404-0.248-0.515-1.412-0.923-1.651
        c-0.313-0.185-1.136-0.173-1.456,0c-0.332,0.18-0.731,0.897-0.825,1.263c-0.093,0.353-0.113,1.158,0.097,1.456
        c0.404,0.573,2.356,0.449,2.573,1.117c0.126,0.387-0.505,1.155-0.825,1.408c-0.329,0.26-1.222,0.31-1.601,0.486
        c-0.407,0.189-1.291,0.578-1.506,0.971c-0.145,0.266-0.206,0.991,0,1.213c0.356,0.382,1.624-0.098,2.088,0.145
        c0.344,0.182,0.756,0.891,0.971,1.214c0.479,0.725,0.993,2.491,1.651,3.059c0.43,0.372,1.58,0.658,2.135,0.777
        c0.643,0.138,1.969,0.263,2.622,0.194c1.571-0.164,4.505-1.528,6.069-1.748c0.829-0.117,2.523-0.137,3.351,0
        c0.48,0.08,1.659,0.181,1.844,0.632c0.243,0.586-0.578,1.946-1.116,2.282c-0.413,0.258-1.48-0.144-1.943,0
        c-0.508,0.159-1.574,0.711-1.747,1.214c-0.192,0.551,0.221,1.872,0.679,2.233c0.62,0.49,2.371,0.292,3.156,0.194
        c0.354-0.044,1.006-0.484,1.359-0.436c0.417,0.056,1.005,0.802,1.408,0.922c0.652,0.194,2.04-0.007,2.719,0
        c1.202,0.012,3.611-0.027,4.806,0.098c0.652,0.068,1.919,0.461,2.574,0.485c0.866,0.033,2.58-0.409,3.448-0.388
        c0.603,0.015,1.783,0.303,2.378,0.388c0.896,0.127,2.689,0.468,3.594,0.437c0.704-0.025,2.062-0.522,2.767-0.534
        c0.307-0.005,0.941,0.336,1.213,0.195c0.757-0.392,0.933-2.428,1.506-3.06c0.604-0.665,2.424-1.256,3.06-1.894
        c0.752-0.756,1.714-2.707,2.233-3.641c0.251-0.45,0.571-1.468,0.922-1.845c0.424-0.453,1.528-1.142,2.136-1.262
        c0.651-0.127,2.048,0.057,2.622,0.388c0.711,0.411,1.228,2.445,2.039,2.573c0.346,0.055,0.858-0.61,1.166-0.776
        c0.382-0.207,1.242-0.434,1.601-0.68c0.517-0.353,1.236-1.436,1.748-1.796c0.479-0.339,1.549-0.963,2.135-0.971
        c0.409-0.006,1.256,0.308,1.506,0.631c0.42,0.544-0.059,2.199,0.388,2.719c0.332,0.385,1.467,0.408,1.943,0.583
        c0.484,0.178,1.593,0.405,1.894,0.825c0.346,0.485,0.355,1.805,0.193,2.378c-0.122,0.434-0.927,1.028-1.068,1.457
        c-0.109,0.335-0.223,1.136,0,1.408c0.332,0.4,1.544,0.235,2.04,0.388c0.405,0.125,1.129,0.693,1.554,0.68
        c0.586-0.018,1.501-1.083,2.087-1.068c0.365,0.009,1.118,0.393,1.263,0.728c0.206,0.482-0.424,1.519-0.485,2.04
        c-0.041,0.338-0.217,1.098,0,1.359c0.409,0.496,1.899,0.335,2.523,0.486C753.901,541.839,754.565,541.884,754.771,542.107z" pointer-events="auto" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_19_" x="622.41" y="574.434" opacity="0" fill="#FFFFFF" stroke="#010202" stroke-miterlimit="10" enable-background="new    " width="59.957" height="20.14" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_POLAND2_LUBELSKIE" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Lublin" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_POLAND2_LUBELSKIE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#7eb08e" d="M627.595,451.466c-0.205-0.446-0.403-0.863-0.519-1.173
        c-0.306-0.819-1.017-2.44-1.165-3.301c-0.217-1.269-0.105-3.864,0-5.148c0.171-2.083,0.952-6.202,1.359-8.254
        c0.145-0.733,0.533-2.18,0.68-2.912c0.101-0.507,0.399-1.526,0.34-2.04c-0.036-0.314-0.194-1.043-0.486-1.165
        c-0.241-0.102-0.71,0.374-0.971,0.388c-0.353,0.019-1.146-0.106-1.359-0.388c-0.184-0.243-0.143-0.947,0-1.214
        c0.186-0.348,1.082-0.553,1.31-0.874c0.312-0.44,0.26-1.625,0.534-2.088c0.364-0.612,1.87-1.264,2.088-1.942
        c0.204-0.637-0.104-2.11-0.534-2.621c-0.344-0.41-1.465-0.678-1.99-0.778c-0.407-0.076-1.263,0.139-1.651,0
        c-0.561-0.199-1.612-0.957-1.846-1.505c-0.233-0.549-0.009-1.818,0.195-2.379c0.122-0.336,0.527-0.97,0.825-1.165
        c0.393-0.259,1.404-0.557,1.844-0.389c0.269,0.103,0.46,0.738,0.68,0.923c0.29,0.245,0.995,0.789,1.359,0.68
        c0.442-0.133,0.839-1.148,0.922-1.603c0.099-0.531-0.404-1.599-0.34-2.135c0.075-0.627,1.106-1.715,0.971-2.331
        c-0.089-0.403-1.034-0.745-1.214-1.117c-0.201-0.416-0.138-1.383-0.146-1.845c-0.01-0.559,0.288-1.708,0.098-2.233
        c-0.246-0.679-1.569-1.518-1.942-2.136c-0.294-0.487-0.515-1.632-0.777-2.136c-0.343-0.658-1.042-2.086-1.699-2.428
        c-0.355-0.186-1.204-0.044-1.603,0c-0.705,0.08-2.057,0.637-2.766,0.631c-0.663-0.006-1.992-0.313-2.574-0.631
        c-0.587-0.321-1.462-1.377-1.942-1.845c-0.407-0.395-1.319-1.112-1.602-1.601c-0.297-0.515-0.554-1.708-0.615-2.299
        c-0.061-0.588-0.02-1.789,0.129-2.363c0.117-0.444,0.432-1.387,0.809-1.651c0.388-0.27,1.412-0.267,1.878-0.194
        c0.419,0.065,1.291,0.348,1.554,0.679c0.168,0.212,0.01,0.869,0.194,1.068c0.285,0.307,1.348,0.102,1.618,0.421
        c0.174,0.204-0.03,0.832,0.097,1.068c0.172,0.32,0.786,0.805,1.133,0.906c0.367,0.107,1.244,0.103,1.521-0.162
        c0.345-0.328-0.007-1.508,0.291-1.877c0.211-0.264,0.963-0.331,1.263-0.486c0.424-0.221,1.13-0.924,1.586-1.068
        c0.439-0.139,1.383-0.028,1.845-0.033c0.436-0.004,1.457,0.326,1.747,0c0.374-0.42-0.369-1.682-0.259-2.233
        c0.071-0.355,0.421-1.047,0.713-1.263c0.205-0.153,0.782-0.094,1.003-0.226c0.423-0.254,0.942-1.145,1.294-1.489
        c0.218-0.213,0.799-0.507,0.939-0.777c0.302-0.584,0.578-2.089,0.193-2.622c-0.209-0.292-1.157-0.083-1.391-0.356
        c-0.333-0.389,0.175-1.682-0.194-2.039c-0.472-0.457-2.178,0.323-2.621-0.163c-0.22-0.237-0.121-1.009,0.033-1.293
        c0.262-0.487,1.285-1.098,1.813-1.263c0.764-0.24,2.52,0.417,3.205,0c0.466-0.284,0.726-1.471,1.003-1.942
        c0.274-0.47,0.821-1.422,1.197-1.814c0.415-0.429,1.586-0.912,1.943-1.391c0.369-0.495,1.029-1.755,0.81-2.331
        c-0.18-0.467-1.381-0.632-1.747-0.97c-0.451-0.416-0.82-1.75-1.36-2.04c-0.349-0.188-1.237-0.188-1.587,0
        c-0.401,0.215-0.601,1.305-1.003,1.522c-0.384,0.206-1.351,0.182-1.748,0c-0.452-0.21-1.23-1.027-1.293-1.522
        c-0.051-0.391,0.346-1.213,0.679-1.425c0.643-0.405,2.475,0.508,3.043,0c0.328-0.293,0.17-1.309,0.193-1.747
        c0.051-0.929,0.304-2.842,0-3.722c-0.218-0.633-1.248-1.57-1.651-2.105c-0.408-0.542-1.378-1.555-1.586-2.2
        c-0.239-0.74-0.312-2.397,0-3.107c0.318-0.724,1.7-1.66,2.33-2.136c0.357-0.27,1.095-0.805,1.521-0.939
        c0.819-0.258,2.65,0.163,3.432-0.194c0.686-0.313,1.616-1.593,2.104-2.169c0.123-0.146,0.338-0.463,0.452-0.616
        c0.27-0.352,0.688-1.232,1.101-1.392c0.422-0.163,1.346,0.171,1.781,0.291c0.528,0.147,1.5,0.714,2.039,0.809
        c0.582,0.103,1.789-0.206,2.364-0.064c0.387,0.095,1.003,0.685,1.391,0.776c0.718,0.17,2.382,0.346,2.946-0.13
        c0.266-0.225,0.255-1.013,0.291-1.359c0.047-0.451-0.212-1.412,0-1.812c0.115-0.216,0.565-0.562,0.81-0.55
        c0.304,0.014,0.665,0.641,0.938,0.776c0.47,0.234,1.599,0.2,2.04,0.486c0.29,0.189,0.563,0.894,0.841,1.1
        c0.43,0.318,1.478,0.825,2.007,0.745c0.445-0.068,1.205-0.708,1.425-1.1c0.297-0.533-0.099-1.933,0.259-2.428
        c0.182-0.253,0.845-0.635,1.133-0.518c0.515,0.209,0.705,1.525,0.808,2.072c0.063,0.335-0.097,1.035,0,1.359
        c0.077,0.251,0.35,0.754,0.583,0.874c0.627,0.321,2.258,0.331,2.816-0.097c0.155-0.118,0.198-0.552,0.228-0.744
        c0.006-0.04-0.02-0.126,0-0.162c0.113-0.222,0.581-0.478,0.808-0.582c0.652-0.295,2.144-0.329,2.784-0.647
        c0.5-0.247,1.479-0.919,1.716-1.424c0.194-0.41,0.059-1.363,0-1.813c-0.057-0.44-0.634-1.31-0.453-1.715
        c0.182-0.407,1.153-0.705,1.587-0.81c0.322-0.077,1.023-0.133,1.327,0c0.383,0.168,0.762,1.037,1.132,1.23
        c0.327,0.17,1.198,0.162,1.425,0.356c0.226,0.194,0.921,1.07,1.327,1.294c0.415,0.229,1.374,0.377,1.845,0.421
        c0.427,0.04,1.383,0.207,1.715-0.064c0.291-0.237,0.265-1.093,0.356-1.456c0.078-0.309,0.317-0.913,0.323-1.231
        c0.014-0.671-0.39-1.979-0.582-2.622c-0.071-0.241-0.369-0.689-0.355-0.939c0.016-0.264,0.312-0.739,0.486-0.938
        c0.357-0.412,1.271-1.068,1.78-1.262c0.412-0.156,1.35-0.38,1.748-0.194c0.38,0.176,0.568,1.195,0.938,1.392
        c0.472,0.25,1.609-0.079,2.136,0c0.411,0.061,1.24,0.256,1.587,0.485c0.21,0.14,0.453,0.615,0.648,0.776
        c0.414,0.347,1.347,1.169,1.877,1.068c0.368-0.07,0.597-1.013,0.939-1.165c0.408-0.182,1.391-0.122,1.78,0.097
        c0.427,0.241,0.693,1.347,1.1,1.619c0.537,0.357,1.924,0.748,2.525,0.517c0.32-0.122,0.727-0.769,0.81-1.1
        c0.148-0.595-0.468-1.825-0.356-2.428c0.066-0.356,0.425-1.043,0.712-1.263c0.239-0.183,0.865-0.295,1.165-0.291
        c0.488,0.006,1.426,0.695,1.878,0.517c0.417-0.163,1.005-1.08,0.939-1.521c-0.052-0.352-0.781-0.728-1.068-0.938
        c-0.214-0.157-0.803-0.307-0.906-0.551c-0.129-0.306,0.115-1.013,0.291-1.294c0.138-0.22,0.63-0.46,0.81-0.648
        c0.199-0.21,0.505-0.708,0.679-0.939c0.282-0.371,0.81-1.155,1.165-1.457c0.227-0.192,0.748-0.507,1.037-0.582
        c0.423-0.111,1.313-0.051,1.747,0c0.28,0.033,0.825,0.193,1.1,0.259c0.333,0.08,0.986,0.329,1.328,0.323
        c0.244-0.002,0.792-0.065,0.939-0.258c0.271-0.354,0.215-1.391,0-1.781c-0.152-0.276-0.786-0.535-1.068-0.68
        c-0.388-0.198-1.301-0.348-1.618-0.647c-0.194-0.184-0.441-0.704-0.453-0.971c-0.03-0.645,0.439-1.951,0.873-2.428
        c0.386-0.424,1.593-0.659,2.073-0.971c0.494-0.323,1.397-1.104,1.779-1.553c0.776-0.915,2.11-2.954,2.525-4.078
        c0.165-0.446,0.255-1.404,0.291-1.878c0.008-0.104,0.006-0.344,0.003-0.5c0.184-0.055,0.36-0.085,0.482-0.05
        c0.574,0.163,1.131,1.406,1.619,1.747c0.2,0.141,0.537,0.253,0.892,0.365c0.486,0.154,1.004,0.31,1.245,0.542
        c0.464,0.446,1.148,1.687,1.1,2.33c-0.042,0.582-1.271,1.358-1.293,1.942c-0.021,0.499,0.51,1.552,0.97,1.748
        c0.584,0.25,1.881-0.91,2.459-0.648c0.403,0.183,0.582,1.165,0.713,1.619c0.129,0.454,1.775-1.176,2.46-1.295
        c0.216-0.037,0.663,0.128,0.841,0.259c0.488,0.36,0.946,1.564,1.359,2.007c0.197,0.211,0.622,0.757,0.908,0.712
        c0.691-0.111,0.875-2.156,1.553-2.33c0.476-0.122,1.286,0.732,1.748,0.906c0.311,0.118,1.041,0.107,1.293,0.325
        c0.348,0.297,0.4,1.35,0.713,1.682c0.296,0.317,1.123,0.832,1.554,0.777c0.659-0.083,1.351-1.634,2.006-1.747
        c0.566-0.098,1.686,0.485,2.136,0.842c0.355,0.281,0.913,1.053,1.037,1.488c0.149,0.531-0.376,1.708-0.13,2.202
        c0.207,0.418,1.141,0.816,1.554,1.035c0.417,0.222,1.39,0.405,1.747,0.713c0.6,0.516,1.045,2.175,1.619,2.719
        c0.304,0.289,1.07,0.821,1.489,0.777c0.353-0.037,0.75-0.842,1.101-0.907c0.402-0.074,1.17,0.374,1.553,0.518
        c0.625,0.235,2.032,0.523,2.46,1.035c0.616,0.738,0.518,2.849,0.842,3.755c0.157,0.444,0.682,1.24,0.84,1.683
        c0.393,1.094,1.037,3.37,1.037,4.532c0,0.797-0.423,2.366-0.712,3.107c-0.143,0.365-0.574,1.026-0.777,1.359
        c-0.371,0.61-1.352,1.711-1.554,2.396c-0.17,0.574,0.178,1.823,0,2.394c-0.111,0.356-0.78,0.864-0.841,1.231
        c-0.061,0.364,0.245,1.084,0.388,1.425c0.174,0.415,0.797,1.118,0.906,1.553c0.107,0.425,0.191,1.393-0.064,1.748
        c-0.293,0.408-1.568,0.316-1.878,0.712c-0.413,0.527-0.491,2.007-0.324,2.654c0.199,0.775,1.518,1.875,1.878,2.59
        c0.342,0.681,0.838,2.153,0.906,2.913c0.045,0.52,0.01,1.624-0.259,2.071c-0.377,0.627-1.743,1.354-2.396,1.683
        c-0.411,0.206-1.408,0.271-1.748,0.582c-0.507,0.464-1.001,1.843-1.1,2.524c-0.115,0.791,0.209,2.394,0.389,3.173
        c0.145,0.633,0.73,1.819,0.841,2.459c0.178,1.021,0.256,3.125,0.064,4.143c-0.086,0.465-0.583,1.294-0.712,1.748
        c-0.188,0.663-0.428,2.03-0.453,2.719c-0.023,0.682,0.214,2.038,0.26,2.719c0.059,0.921-0.04,2.783,0.128,3.69
        c0.082,0.437,0.19,1.475,0.583,1.683c0.549,0.29,1.785-0.537,2.396-0.648c0.672-0.121,2.073-0.48,2.719-0.259
        c0.693,0.237,1.748,1.385,2.136,2.006c0.26,0.417,0.231,1.534,0.582,1.879c0.589,0.576,2.655,0.263,3.172,0.905
        c0.642,0.796-0.032,3.099,0.259,4.078c0.122,0.41,0.679,1.094,0.842,1.489c0.372,0.9,0.836,2.802,1.036,3.756
        c0.162,0.768,0.536,2.326,0.452,3.107c-0.107,1.013-0.742,3.062-1.424,3.82c-0.362,0.404-1.569,0.577-1.941,0.97
        c-0.618,0.654-1.414,2.402-1.426,3.301c-0.011,0.994,0.94,2.861,1.489,3.69c0.29,0.438,1.048,1.203,1.49,1.49
        c0.422,0.272,1.435,0.474,1.877,0.711c0.369,0.197,1.169,0.6,1.36,0.971c0.533,1.037-0.199,3.513,0,4.661
        c0.122,0.711,0.38,2.303,0.971,2.719c0.503,0.354,1.848,0.06,2.459,0c0.756-0.074,2.231-0.716,2.979-0.583
        c0.615,0.109,1.624,0.939,2.136,1.294c0.965,0.67,3.13,1.88,3.689,2.913c0.511,0.943,0.247,3.21,0.389,4.273
        c0.074,0.554,0.054,1.753,0.389,2.201c0.324,0.435,1.475,0.696,1.941,0.971c0.492,0.29,1.525,0.848,1.878,1.294
        c0.527,0.668,1.042,2.344,1.23,3.172c0.14,0.618,0.101,1.9,0.195,2.525c0.099,0.657,0.509,1.93,0.582,2.59
        c0.111,0.997-0.285,3.052,0,4.013c0.219,0.74,1.301,1.918,1.683,2.591c0.598,1.05,1.559,3.275,2.136,4.336
        c0.513,0.944,1.439,2.932,2.201,3.69c0.7,0.696,2.592,1.44,3.399,2.007c0.437,0.307,1.362,0.909,1.651,1.359
        c0.363,0.571,0.803,1.956,0.679,2.622c-0.075,0.411-0.6,1.168-0.971,1.358c-0.496,0.256-1.733-0.247-2.233,0
        c-0.574,0.284-1.183,1.516-1.554,2.04c-0.434,0.616-1.696,1.772-1.651,2.524c0.038,0.602,1.107,1.441,1.554,1.845
        c0.523,0.473,2.136,1.457,2.136,1.845s-0.572,2.901,0,3.593c0.819,0.991,4.326-0.091,5.05,0.971
        c0.445,0.654-0.39,2.341-0.583,3.107c-0.156,0.618-0.756,1.791-0.776,2.428c-0.026,0.8,0.89,2.315,0.776,3.108
        c-0.095,0.667-1.213,1.671-1.359,2.33c-0.284,1.285,0.463,3.927,0.486,5.244c0.043,2.528-0.034,7.63-0.582,10.099
        c-0.342,1.531-1.408,4.558-2.332,5.826c-0.65,0.892-2.399,2.346-3.398,2.816c-1.162,0.549-3.791,0.705-5.049,0.971
        c-2.316,0.491-6.98,1.388-9.225,2.136c-1.083,0.361-3.321,1.09-4.176,1.845c-0.742,0.656-1.775,2.444-2.04,3.398
        c-0.207,0.749,0.39,2.436,0,3.108c-0.561,0.969-3.001,1.543-3.884,2.233c-0.635,0.497-1.761,1.676-2.233,2.331
        c-0.851,1.18-1.767,4.045-2.719,5.146c-0.806,0.934-3.118,2.033-3.981,2.913c-0.107,0.11-0.216,0.226-0.326,0.349
        c0,0-0.051-1.426-0.354-1.756c-0.206-0.223-0.87-0.269-1.166-0.34c-0.624-0.151-2.115,0.01-2.523-0.486
        c-0.217-0.262-0.041-1.022,0-1.359c0.061-0.52,0.691-1.558,0.485-2.04c-0.145-0.335-0.897-0.719-1.263-0.728
        c-0.586-0.015-1.501,1.05-2.087,1.068c-0.424,0.013-1.149-0.555-1.554-0.68c-0.496-0.153-1.708,0.012-2.04-0.388
        c-0.223-0.271-0.109-1.073,0-1.408c0.141-0.429,0.946-1.023,1.068-1.457c0.162-0.574,0.153-1.894-0.193-2.378
        c-0.3-0.421-1.41-0.648-1.894-0.825c-0.476-0.176-1.611-0.198-1.943-0.583c-0.447-0.52,0.032-2.175-0.388-2.719
        c-0.25-0.323-1.097-0.637-1.506-0.631c-0.586,0.009-1.657,0.633-2.135,0.971c-0.512,0.359-1.231,1.442-1.748,1.796
        c-0.359,0.246-1.219,0.473-1.601,0.68c-0.308,0.166-0.82,0.831-1.166,0.776c-0.811-0.127-1.328-2.162-2.039-2.573
        c-0.575-0.331-1.972-0.515-2.622-0.388c-0.608,0.119-1.713,0.809-2.136,1.262c-0.352,0.377-0.671,1.395-0.922,1.845
        c-0.519,0.934-1.481,2.885-2.233,3.641c-0.636,0.638-2.455,1.228-3.06,1.894c-0.573,0.632-0.748,2.668-1.506,3.06
        c-0.272,0.141-0.906-0.199-1.213-0.195c-0.705,0.012-2.063,0.509-2.767,0.534c-0.904,0.031-2.697-0.31-3.594-0.437
        c-0.595-0.085-1.775-0.373-2.378-0.388c-0.868-0.021-2.582,0.421-3.448,0.388c-0.655-0.024-1.922-0.417-2.574-0.485
        c-1.195-0.124-3.604-0.086-4.806-0.098c-0.679-0.007-2.067,0.194-2.719,0c-0.403-0.12-0.991-0.866-1.408-0.922
        c-0.353-0.047-1.005,0.392-1.359,0.436c-0.785,0.099-2.536,0.296-3.156-0.194c-0.458-0.361-0.871-1.682-0.679-2.233
        c0.174-0.504,1.24-1.055,1.747-1.214c0.463-0.144,1.53,0.258,1.943,0c0.538-0.336,1.359-1.695,1.116-2.282
        c-0.186-0.451-1.364-0.552-1.844-0.632c-0.827-0.137-2.522-0.117-3.351,0c-1.564,0.22-4.498,1.585-6.069,1.748
        c-0.654,0.068-1.98-0.056-2.622-0.194c-0.556-0.119-1.706-0.405-2.135-0.777c-0.658-0.568-1.172-2.334-1.651-3.059
        c-0.215-0.324-0.628-1.033-0.971-1.214c-0.464-0.243-1.732,0.237-2.088-0.145c-0.206-0.222-0.145-0.948,0-1.213
        c0.214-0.394,1.099-0.783,1.506-0.971c0.379-0.176,1.273-0.226,1.601-0.486c0.321-0.253,0.952-1.021,0.825-1.408
        c-0.217-0.667-2.169-0.544-2.573-1.117c-0.209-0.298-0.19-1.103-0.097-1.456c0.095-0.365,0.494-1.083,0.825-1.263
        c0.32-0.173,1.143-0.185,1.456,0c0.408,0.239,0.519,1.403,0.923,1.651c0.486,0.299,1.71,0.116,2.282,0.097
        c0.793-0.026,2.438-0.002,3.156-0.34c0.567-0.268,1.364-1.293,1.796-1.748c0.355-0.374,1.508-1.058,1.359-1.553
        c-0.079-0.266-0.792-0.301-1.068-0.291c-0.656,0.024-1.894,1.177-2.476,0.874c-0.201-0.105-0.303-0.656-0.243-0.874
        c0.085-0.306,0.646-0.718,0.922-0.875c0.319-0.18,1.219-0.076,1.408-0.388c0.182-0.297-0.021-1.141-0.29-1.359
        c-0.18-0.144-0.697-0.049-0.922,0c-0.341,0.075-0.915,0.58-1.264,0.583c-0.357,0.003-1.139-0.267-1.31-0.583
        c-0.182-0.335,0.29-1.127,0.242-1.505c-0.064-0.513-0.831-1.377-0.825-1.894c0.006-0.429,0.561-1.16,0.729-1.554
        c0.225-0.526,1.007-1.593,0.825-2.136c-0.144-0.434-1.101-0.897-1.554-0.971c-0.307-0.05-0.904,0.336-1.213,0.292
        c-0.775-0.112-1.962-1.354-2.719-1.554c-0.34-0.091-1.069-0.099-1.408,0c-0.262,0.075-0.651,0.602-0.923,0.582
        c-0.721-0.054-1.564-1.524-2.184-1.894c-0.502-0.299-1.63-0.647-2.186-0.825c-0.49-0.158-1.489-0.414-1.99-0.534
        c-0.675-0.163-2.049-0.398-2.719-0.582c-0.47-0.13-1.455-0.341-1.844-0.632c-0.454-0.339-0.966-1.396-1.311-1.845
        c-0.348-0.45-1.009-1.396-1.457-1.747c-0.457-0.358-1.507-1.011-2.087-1.02c-0.533-0.009-1.461,1.101-1.943,0.874
        c-0.49-0.229-0.721-1.563-0.583-2.087c0.126-0.482,1.235-0.941,1.408-1.408c0.225-0.605-0.388-1.958-0.194-2.574
        c0.288-0.913,1.892-2.167,2.427-2.962c0.256-0.378,0.873-1.145,0.875-1.602c0.002-0.565-0.626-1.64-1.068-1.99
        c-0.336-0.265-1.337-0.148-1.651-0.436c-0.189-0.176-0.271-0.724-0.34-0.971c-0.111-0.395-0.071-1.294-0.341-1.602
        c-0.458-0.522-1.985-0.646-2.621-0.923c-0.604-0.261-1.722-0.966-2.331-1.213c-0.623-0.254-1.906-0.861-2.574-0.777
        c-0.464,0.059-1.218,0.701-1.601,0.971c-0.474,0.333-1.357,1.082-1.797,1.456c-0.545,0.461-1.48,1.613-2.136,1.894
        c-0.269,0.115-0.883,0.023-1.165,0.098c-0.478,0.124-1.303,0.797-1.797,0.825c-0.586,0.034-1.652-0.648-2.233-0.729
        c-0.626-0.088-1.902,0.205-2.525,0.097c-0.386-0.067-1.072-0.504-1.456-0.582c-0.292-0.059-0.939-0.066-1.427-0.06
        c-0.008-1.008-0.039-1.987-0.079-2.659c-0.072-1.22-0.258-3.677-0.582-4.855c-0.254-0.922-1.299-2.574-1.553-3.496
        c-0.268-0.967-0.181-3.026-0.486-3.982c-0.2-0.631-1.189-1.671-1.263-2.33c-0.12-1.077,1.317-3.102,1.165-4.176
        c-0.135-0.96-1.773-2.367-2.039-3.301c-0.206-0.724-0.056-2.259,0-3.01c0.061-0.808,0.578-2.399,0.486-3.204
        C628.278,452.971,627.927,452.182,627.595,451.466z" pointer-events="auto" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_20_" x="677.096" y="424.995" opacity="0" fill="#FFFFFF" stroke="#010202" stroke-miterlimit="10" enable-background="new    " width="59.957" height="20.14" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_POLAND2_PODLASKIE" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Podlaskie" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_POLAND2_PODLASKIE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#7eb08e" d="M715.155,305.816c-0.254,0.076-0.523,0.201-0.683,0.273
        c-0.617,0.28-1.613,1.305-2.265,1.489c-0.484,0.136-1.542,0.123-2.008-0.065c-0.424-0.171-1.145-0.824-1.358-1.229
        c-0.168-0.318-0.027-1.107-0.195-1.425c-0.295-0.556-1.332-1.366-1.877-1.683c-0.443-0.257-1.497-0.396-1.943-0.648
        c-0.63-0.355-1.591-1.487-2.202-1.877c-0.42-0.27-1.324-0.734-1.813-0.842c-0.395-0.087-1.214-0.027-1.617,0
        c-0.831,0.057-2.47,0.401-3.301,0.454c-1.39,0.086-4.265,0.425-5.568-0.065c-0.699-0.264-1.566-1.683-2.266-1.942
        c-0.638-0.236-2.059-0.167-2.719,0c-0.602,0.152-1.58,1.042-2.185,1.182c-0.876,0.202-2.838,0.343-3.594-0.146
        c-0.638-0.413-0.777-2.229-1.359-2.719c-0.389-0.326-1.56-0.25-1.943-0.583c-0.262-0.228-0.456-0.938-0.583-1.263
        c-0.245-0.629-0.721-1.906-0.825-2.573c-0.134-0.863,0.509-2.785,0-3.496c-0.349-0.491-1.876-0.331-2.282-0.777
        c-0.455-0.498-0.418-1.982-0.631-2.622c-0.126-0.377-0.342-1.187-0.632-1.457c-0.621-0.578-2.539-0.589-3.205-1.117
        c-0.46-0.366-0.997-1.464-1.262-1.99c-0.288-0.57-0.523-1.882-0.922-2.379c-0.411-0.512-1.689-1.028-2.186-1.457
        c-0.247-0.214-0.701-0.694-0.874-0.971c-0.137-0.222-0.26-0.744-0.388-0.971c-0.248-0.437-1.054-1.125-1.214-1.602
        c-0.158-0.472-0.144-1.514,0-1.991c0.215-0.712,1.558-1.738,1.651-2.476c0.07-0.549-0.636-1.543-0.729-2.088
        c-0.188-1.113-0.193-3.403,0-4.515c0.136-0.775,0.927-2.188,1.068-2.962c0.134-0.729,0.178-2.233,0.049-2.962
        c-0.095-0.537-0.407-1.638-0.777-2.039c-0.382-0.415-1.535-0.717-2.039-0.971c-0.369-0.185-1.047-0.828-1.457-0.776
        c-0.555,0.07-1.295,1.075-1.651,1.505c-0.444,0.538-0.785,2.193-1.457,2.379c-0.581,0.16-1.601-0.838-2.136-1.117
        c-0.654-0.341-2.237-0.728-2.621-1.359c-0.453-0.747,0.441-2.741,0-3.496c-0.288-0.492-1.702-0.624-1.991-1.117
        c-0.184-0.314-0.211-1.162,0-1.457c0.18-0.249,0.859-0.394,1.166-0.388c0.415,0.008,1.154,0.695,1.553,0.583
        c0.417-0.117,0.876-0.973,1.068-1.359c0.147-0.292,0.482-0.969,0.341-1.263c-0.184-0.383-1.223-0.415-1.554-0.68
        c-0.447-0.357-0.882-1.528-1.359-1.845c-0.478-0.317-1.663-0.467-2.233-0.534c-0.748-0.088-2.334-0.33-3.01,0
        c-0.621,0.304-1.373,1.575-1.699,2.185c-0.164,0.305-0.157,1.109-0.437,1.311c-0.285,0.206-1.064-0.077-1.408,0
        c-0.864,0.193-2.322,1.571-3.205,1.505c-0.435-0.033-1.022-0.923-1.456-0.971c-0.542-0.061-1.512,0.912-2.04,0.776
        c-0.417-0.107-0.8-1.019-1.117-1.311c-0.275-0.254-0.896-0.676-1.213-0.874c-0.351-0.218-1.276-0.406-1.457-0.776
        c-0.249-0.509,0.515-1.681,0.389-2.234c-0.122-0.525-1.197-1.213-1.263-1.748c-0.041-0.324,0.244-0.994,0.485-1.214
        c0.291-0.263,1.272-0.123,1.507-0.437c0.24-0.32,0.136-1.226,0-1.602c-0.169-0.461-1.095-1.044-1.264-1.505
        c-0.145-0.399,0.111-1.288,0-1.699c-0.17-0.636-0.741-1.953-1.31-2.282c-0.619-0.358-2.194,0.249-2.865,0
        c-0.54-0.201-1.44-1.017-1.747-1.505c-0.374-0.596-0.637-2.022-0.729-2.719c-0.053-0.409-0.068-1.244,0-1.651
        c0.042-0.253,0.1-0.877,0.341-0.971c0.434-0.171,1.185,0.897,1.651,0.874c0.217-0.011,0.625-0.292,0.728-0.486
        c0.195-0.364,0.137-1.261,0-1.651c-0.076-0.221-0.524-0.502-0.583-0.728c-0.09-0.346,0.412-1.093,0.243-1.408
        c-0.157-0.292-0.883-0.515-1.213-0.534c-0.369-0.021-1.125,0.2-1.409,0.437c-0.325,0.272-0.396,1.32-0.777,1.505
        c-0.178,0.086-0.617-0.029-0.776-0.146c-0.413-0.301-0.675-1.381-0.971-1.797c-0.448-0.63-1.602-1.68-2.088-2.282
        c-0.289-0.359-0.67-1.27-1.067-1.505c-0.632-0.374-2.194-0.479-2.914-0.34c-0.571,0.111-1.458,1.126-2.039,1.117
        c-0.408-0.006-1.081-0.581-1.408-0.825c-0.44-0.329-1.223-1.107-1.602-1.505c-0.427-0.45-1.388-1.311-1.602-1.894
        c-0.171-0.467,0-1.494,0-1.991c0-0.631,0.216-1.931,0-2.524c-0.206-0.566-1.347-1.279-1.553-1.845c-0.092-0.25,0-0.801,0-1.068
        c0-0.534,0.19-1.637,0-2.136c-0.17-0.442-0.927-1.075-1.264-1.408c-0.195-0.194-0.656-0.512-0.825-0.728
        c-0.243-0.313-0.748-1.012-0.728-1.408c0.021-0.42,0.797-1.007,0.923-1.408c0.126-0.406,0.253-1.327,0.047-1.7
        c-0.217-0.396-1.287-0.556-1.553-0.922c-0.263-0.362-0.453-1.305-0.388-1.748c0.036-0.248,0.278-0.734,0.485-0.874
        c0.476-0.322,1.717-0.399,2.282-0.291c0.518,0.099,1.337,0.881,1.846,1.02c0.432,0.118,1.404,0.268,1.796,0.048
        c0.403-0.226,0.936-1.14,0.923-1.602c-0.012-0.421-0.624-1.112-0.923-1.408c-0.317-0.315-1.058-0.947-1.506-0.971
        c-0.379-0.021-1.005,0.816-1.359,0.68c-0.474-0.184-0.833-1.397-0.729-1.894c0.059-0.286,0.709-0.557,0.825-0.825
        c0.164-0.379,0.154-1.252,0.048-1.651c-0.18-0.674-0.969-1.881-1.456-2.379c-0.363-0.37-1.5-0.662-1.748-1.117
        c-0.227-0.416,0.086-1.429,0-1.894c-0.092-0.499-0.435-1.479-0.728-1.894c-0.259-0.366-1.208-0.744-1.36-1.165
        c-0.13-0.362-0.023-1.083,0.133-1.762c0.358,0.018,0.686,0.029,0.935,0.014c0.829-0.048,2.564-0.006,3.301-0.389
        c0.421-0.217,0.864-1.155,1.263-1.408c0.289-0.183,0.972-0.34,1.311-0.389c0.997-0.145,3.028-0.101,4.03,0
        c0.728,0.073,2.155,0.409,2.864,0.583c0.515,0.125,1.591,0.299,2.04,0.583c0.45,0.285,0.916,1.398,1.408,1.602
        c0.349,0.145,1.167,0.07,1.505-0.097c0.58-0.285,1.388-1.378,1.7-1.942c0.367-0.665,0.484-2.261,0.874-2.913
        c0.379-0.634,1.407-1.805,2.088-2.088c0.436-0.182,1.43-0.091,1.894,0c0.348,0.068,1.031,0.318,1.311,0.534
        c0.399,0.306,0.717,1.521,1.213,1.602c0.508,0.083,1.419-0.733,1.699-1.165c0.363-0.558,0.201-2.019,0.486-2.622
        c0.129-0.273,0.513-0.778,0.777-0.923c0.891-0.489,3.065-0.214,4.03-0.534c0.897-0.298,2.568-1.248,3.301-1.845
        c1.569-1.277,3.978-4.581,5.293-6.118c0.61-0.713,1.708-2.259,2.427-2.865c0.551-0.462,1.909-1.017,2.476-1.457
        c0.557-0.431,1.577-1.423,1.991-1.99c0.266-0.364,0.515-1.275,0.825-1.602c0.349-0.367,1.361-0.694,1.748-1.02
        c0.361-0.306,0.784-1.255,1.213-1.456c0.516-0.242,1.81-0.32,2.282,0c0.325,0.219,0.255,1.242,0.583,1.456
        c0.326,0.213,1.248,0.192,1.554-0.048c0.288-0.226,0.405-1.043,0.389-1.408c-0.028-0.584-0.721-1.61-0.825-2.185
        c-0.079-0.43-0.225-1.374,0-1.747c0.12-0.2,0.598-0.381,0.825-0.437c0.588-0.147,1.953,0.379,2.427,0
        c0.428-0.342,0.221-1.657,0.486-2.136c0.316-0.571,1.195-1.895,1.845-1.845c0.451,0.034,0.676,1.23,1.068,1.457
        c0.285,0.165,1.038,0.331,1.311,0.146c0.344-0.233,0.329-1.202,0.437-1.602c0.122-0.461,0.182-1.478,0.486-1.845
        c0.359-0.435,1.591-0.626,2.039-0.971c0.417-0.32,1.089-1.153,1.359-1.602c0.236-0.392,0.367-1.365,0.679-1.699
        c0.249-0.266,0.95-0.593,1.311-0.631c0.411-0.043,1.254,0.609,1.602,0.388c0.4-0.251,0.427-1.375,0.388-1.845
        c-0.027-0.337-0.423-0.93-0.485-1.262c-0.128-0.692-0.402-2.238,0-2.816c0.34-0.488,1.796-0.421,2.233-0.825
        c0.382-0.354,0.686-1.407,0.874-1.894c0.349-0.902,1.018-2.733,1.165-3.69c0.126-0.815,0.166-2.493,0-3.301
        c-0.129-0.634-0.673-1.832-1.019-2.379c-0.432-0.684-1.615-1.81-2.136-2.428c-0.473-0.56-1.514-1.627-1.845-2.282
        c-0.327-0.65-0.388-2.173-0.728-2.816c-0.251-0.471-1.128-1.15-1.409-1.602c-0.319-0.513-0.707-1.669-0.922-2.233
        c-0.171-0.445-0.335-1.425-0.631-1.797c-0.17-0.214-0.658-0.648-0.923-0.583c-0.26,0.064-0.277,0.848-0.534,0.923
        c-0.302,0.087-0.882-0.373-1.117-0.582c-0.273-0.244-0.747-0.856-0.825-1.214c-0.143-0.649,0.042-2.088,0.437-2.622
        c0.178-0.24,0.942-0.263,1.068-0.534c0.403-0.874-0.652-2.815-0.923-3.738c-0.17-0.579-0.286-1.932-0.777-2.282
        c-0.237-0.17-0.874,0-1.165,0c-0.413,0-1.264,0.142-1.651,0c-0.284-0.103-0.74-0.536-0.922-0.777
        c-0.321-0.425-0.533-1.533-0.875-1.942c-0.245-0.295-1.037-0.563-1.262-0.874c-0.149-0.205-0.143-0.765-0.292-0.971
        c-0.233-0.325-1.125-0.567-1.311-0.923c-0.15-0.291-0.164-1.027,0-1.311c0.274-0.469,1.625-0.595,1.894-1.068
        c0.259-0.454-0.227-1.597-0.047-2.088c0.144-0.396,0.787-1,1.116-1.263c0.411-0.33,1.364-0.803,1.846-1.019
        c0.363-0.164,1.118-0.439,1.505-0.534c0.595-0.146,1.946,0.039,2.428-0.34c0.286-0.226,0.292-1.086,0.534-1.359
        c0.219-0.248,0.836-0.595,1.165-0.631c0.438-0.049,1.26,0.444,1.7,0.485c0.679,0.064,2.137,0.117,2.719-0.243
        c0.315-0.195,0.453-1.082,0.776-1.262c0.36-0.201,1.255,0.116,1.651,0c0.456-0.133,1.277-0.675,1.603-1.019
        c0.306-0.324,0.83-1.11,0.873-1.554c0.038-0.383-0.466-1.073-0.486-1.457c-0.025-0.535,0.424-1.555,0.486-2.088
        c0.059-0.518,0.148-1.587,0-2.088c-0.069-0.236-0.374-0.638-0.533-0.825c-0.153-0.179-0.492-0.487-0.807-0.76
        c0.297-0.283,0.587-0.546,0.807-0.714c0.468-0.356,1.506-0.921,2.006-1.229c0.47-0.29,1.326-1.132,1.878-1.165
        c1.532-0.092,4.093,2.164,5.568,2.589c0.737,0.213,2.451-0.1,3.043,0.388c0.563,0.467,0.334,2.328,0.905,2.784
        c0.572,0.456,2.214,0.042,2.913,0.26c0.409,0.126,1.199,0.526,1.489,0.841c0.472,0.512,0.713,1.997,1.165,2.525
        c0.455,0.532,1.632,1.5,2.33,1.553c0.499,0.039,1.347-1.018,1.813-0.841c0.496,0.187,0.415,1.629,0.843,1.942
        c0.879,0.645,3.489-0.233,4.336,0.453c0.529,0.428,0.338,2.132,0.842,2.59c0.932,0.849,3.693,0.813,4.92,1.1
        c1.055,0.248,3.309,0.432,4.208,1.036c0.914,0.614,2.087,2.582,2.591,3.561c0.375,0.728,0.561,2.44,1.035,3.107
        c0.462,0.649,1.955,1.388,2.525,1.942c0.751,0.728,2.131,2.33,2.654,3.236c0.573,0.989,1.229,3.199,1.619,4.273
        c0.421,1.16,1.667,3.429,1.618,4.661c-0.042,1.043-1.33,2.837-1.683,3.819c-0.382,1.06-1.185,3.211-1.23,4.338
        c-0.02,0.496,0.225,1.475,0.389,1.942c0.186,0.533,0.747,1.52,1.036,2.007c0.416,0.702,1.901,2.828,2.001,2.736
        c-0.028,0.298-0.138,2.369,0.005,3.025c0.133,0.609,0.641,1.785,1.036,2.265c0.355,0.43,1.448,0.874,1.814,1.295
        c0.369,0.427,0.976,1.445,1.036,2.007c0.097,0.917-0.776,2.659-0.971,3.561c-0.17,0.786-0.597,2.372-0.517,3.173
        c0.091,0.912,0.998,2.563,1.294,3.431c0.356,1.041,0.989,3.15,1.295,4.208c0.86,2.98,2.386,8.994,3.236,11.977
        c0.88,3.087,2.569,9.291,3.69,12.3c1.158,3.109,4.132,9.056,5.438,12.106c0.709,1.655,1.927,5.05,2.719,6.668
        c1.239,2.534,4.268,7.306,5.632,9.775c1.037,1.876,2.711,5.872,4.012,7.574c0.419,0.545,1.54,1.374,2.008,1.878
        c0.367,0.396,1.173,1.175,1.359,1.683c0.24,0.654,0.072,2.088,0.064,2.784c-0.008,0.713,0.029,2.153-0.129,2.848
        c-0.202,0.892-1.353,2.455-1.424,3.367c-0.052,0.665,0.368,1.982,0.647,2.589c0.363,0.793,1.639,2.06,2.007,2.849
        c0.69,1.471,1.346,4.689,1.683,6.279c0.235,1.106,0.788,3.338,0.713,4.467c-0.061,0.894-0.881,2.549-1.037,3.432
        c-0.308,1.755-0.655,5.357-0.389,7.121c0.216,1.433,1.716,4.018,2.008,5.438c0.44,2.143,0.551,6.554,0.452,8.739
        c-0.051,1.143-0.148,3.502-0.647,4.532c-0.829,1.709-3.856,4.205-5.243,5.502c-1.461,1.365-4.474,4.024-6.151,5.115
        c-1.528,0.994-4.874,2.49-6.538,3.236c-1.499,0.672-4.605,1.756-6.087,2.461c-1.452,0.691-4.396,2.077-5.632,3.107
        c-0.786,0.656-1.977,2.351-2.589,3.172c-0.585,0.784-1.599,2.466-2.202,3.238c-0.733,0.94-2.474,2.594-3.172,3.56
        c-0.722,1-2.141,3.082-2.459,4.272c-0.189,0.704,0.237,2.225,0,2.913c-0.241,0.698-1.379,1.735-1.813,2.33
        c-0.61,0.839-1.795,2.544-2.332,3.432c-0.407,0.675-1.11,2.091-1.488,2.783c-0.479,0.878-1.371,2.675-1.942,3.495
        c-0.292,0.419-1.091,1.323-1.569,1.854c-0.354-0.113-0.691-0.224-0.892-0.365c-0.488-0.342-1.046-1.585-1.619-1.747
        C715.515,305.731,715.338,305.76,715.155,305.816z" pointer-events="auto" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_21_" x="661.938" y="189.371" opacity="0" fill="#FFFFFF" stroke="#010202" stroke-miterlimit="10" enable-background="new    " width="59.957" height="20.14" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_POLAND2_MAZOWIECKIE" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Masovian" data-wikipediaLink="" data-infoText="" data-imageFile="AREA_POLAND2_MAZOWIECKIE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#7eb08e" d="M715.155,305.816c0.003,0.156,0.005,0.396-0.003,0.5
        c-0.036,0.474-0.126,1.432-0.291,1.878c-0.416,1.125-1.749,3.163-2.525,4.078c-0.382,0.449-1.285,1.23-1.779,1.553
        c-0.48,0.311-1.687,0.547-2.073,0.971c-0.433,0.477-0.902,1.783-0.873,2.428c0.012,0.267,0.259,0.787,0.453,0.971
        c0.317,0.299,1.23,0.449,1.618,0.647c0.282,0.145,0.916,0.403,1.068,0.68c0.215,0.39,0.271,1.427,0,1.781
        c-0.147,0.194-0.695,0.256-0.939,0.258c-0.342,0.006-0.995-0.243-1.328-0.323c-0.274-0.066-0.819-0.226-1.1-0.259
        c-0.434-0.051-1.325-0.111-1.747,0c-0.288,0.076-0.81,0.39-1.037,0.582c-0.355,0.302-0.883,1.086-1.165,1.457
        c-0.175,0.231-0.48,0.729-0.679,0.939c-0.18,0.188-0.671,0.427-0.81,0.648c-0.176,0.281-0.421,0.989-0.291,1.294
        c0.104,0.244,0.692,0.393,0.906,0.551c0.287,0.21,1.016,0.586,1.068,0.938c0.066,0.442-0.522,1.358-0.939,1.521
        c-0.452,0.178-1.39-0.511-1.878-0.517c-0.3-0.004-0.926,0.109-1.165,0.291c-0.287,0.22-0.646,0.906-0.712,1.263
        c-0.112,0.603,0.505,1.832,0.356,2.428c-0.083,0.331-0.49,0.977-0.81,1.1c-0.601,0.231-1.988-0.16-2.525-0.517
        c-0.407-0.272-0.673-1.378-1.1-1.619c-0.389-0.219-1.372-0.278-1.78-0.097c-0.342,0.152-0.571,1.095-0.939,1.165
        c-0.53,0.101-1.463-0.721-1.877-1.068c-0.195-0.162-0.437-0.637-0.648-0.776c-0.346-0.23-1.176-0.425-1.587-0.485
        c-0.527-0.079-1.665,0.25-2.136,0c-0.37-0.197-0.558-1.216-0.938-1.392c-0.399-0.186-1.337,0.038-1.748,0.194
        c-0.509,0.194-1.423,0.851-1.78,1.262c-0.174,0.199-0.47,0.675-0.486,0.938c-0.014,0.25,0.284,0.698,0.355,0.939
        c0.193,0.643,0.596,1.951,0.582,2.622c-0.006,0.317-0.245,0.922-0.323,1.231c-0.092,0.363-0.065,1.219-0.356,1.456
        c-0.332,0.271-1.287,0.105-1.715,0.064c-0.471-0.043-1.431-0.192-1.845-0.421c-0.406-0.224-1.101-1.1-1.327-1.294
        c-0.227-0.194-1.098-0.187-1.425-0.356c-0.37-0.194-0.749-1.062-1.132-1.23c-0.304-0.133-1.005-0.077-1.327,0
        c-0.433,0.105-1.405,0.403-1.587,0.81c-0.181,0.405,0.396,1.275,0.453,1.715c0.059,0.45,0.194,1.403,0,1.813
        c-0.237,0.505-1.216,1.177-1.716,1.424c-0.64,0.318-2.133,0.352-2.784,0.647c-0.227,0.104-0.695,0.361-0.808,0.582
        c-0.02,0.036,0.006,0.122,0,0.162c-0.03,0.192-0.073,0.626-0.228,0.744c-0.558,0.428-2.189,0.419-2.816,0.097
        c-0.234-0.12-0.506-0.623-0.583-0.874c-0.097-0.325,0.063-1.025,0-1.359c-0.103-0.546-0.292-1.863-0.808-2.072
        c-0.288-0.117-0.952,0.265-1.133,0.518c-0.357,0.495,0.039,1.895-0.259,2.428c-0.219,0.392-0.979,1.032-1.425,1.1
        c-0.529,0.08-1.577-0.427-2.007-0.745c-0.278-0.206-0.551-0.912-0.841-1.1c-0.44-0.285-1.57-0.251-2.04-0.486
        c-0.273-0.135-0.634-0.762-0.938-0.776c-0.245-0.011-0.695,0.334-0.81,0.55c-0.212,0.4,0.047,1.361,0,1.812
        c-0.037,0.346-0.026,1.135-0.291,1.359c-0.564,0.476-2.228,0.3-2.946,0.13c-0.388-0.092-1.004-0.681-1.391-0.776
        c-0.575-0.142-1.781,0.167-2.364,0.064c-0.539-0.095-1.511-0.662-2.039-0.809c-0.435-0.12-1.359-0.454-1.781-0.291
        c-0.413,0.16-0.831,1.039-1.101,1.392c-0.115,0.153-0.329,0.47-0.452,0.616c-0.488,0.576-1.418,1.855-2.104,2.169
        c-0.782,0.357-2.612-0.064-3.432,0.194c-0.427,0.134-1.164,0.669-1.521,0.939c-0.63,0.477-2.012,1.413-2.33,2.136
        c-0.312,0.711-0.239,2.367,0,3.107c0.207,0.645,1.178,1.659,1.586,2.2c0.403,0.535,1.432,1.472,1.651,2.105
        c0.304,0.88,0.051,2.793,0,3.722c-0.024,0.438,0.134,1.454-0.193,1.747c-0.568,0.508-2.4-0.405-3.043,0
        c-0.334,0.211-0.731,1.034-0.679,1.425c0.063,0.496,0.841,1.312,1.293,1.522c0.398,0.182,1.364,0.206,1.748,0
        c0.402-0.217,0.602-1.307,1.003-1.522c0.349-0.188,1.237-0.188,1.587,0c0.54,0.289,0.909,1.624,1.36,2.04
        c0.366,0.339,1.568,0.504,1.747,0.97c0.219,0.576-0.44,1.836-0.81,2.331c-0.357,0.479-1.528,0.962-1.943,1.391
        c-0.375,0.392-0.922,1.344-1.197,1.814c-0.277,0.471-0.537,1.658-1.003,1.942c-0.685,0.417-2.441-0.24-3.205,0
        c-0.527,0.165-1.551,0.776-1.813,1.263c-0.154,0.284-0.253,1.056-0.033,1.293c0.443,0.486,2.149-0.294,2.621,0.163
        c0.369,0.356-0.138,1.65,0.194,2.039c0.234,0.273,1.182,0.064,1.391,0.356c0.384,0.533,0.109,2.038-0.193,2.622
        c-0.14,0.27-0.721,0.564-0.939,0.777c-0.352,0.344-0.872,1.235-1.294,1.489c-0.221,0.132-0.798,0.073-1.003,0.226
        c-0.292,0.216-0.642,0.907-0.713,1.263c-0.111,0.551,0.633,1.814,0.259,2.233c-0.29,0.326-1.311-0.004-1.747,0
        c-0.462,0.005-1.406-0.107-1.845,0.033c-0.455,0.144-1.162,0.847-1.586,1.068c-0.3,0.155-1.051,0.222-1.263,0.486
        c-0.298,0.369,0.053,1.549-0.291,1.877c-0.277,0.265-1.154,0.269-1.521,0.162c-0.348-0.102-0.962-0.586-1.133-0.906
        c-0.126-0.236,0.077-0.864-0.097-1.068c-0.271-0.319-1.333-0.114-1.618-0.421c-0.185-0.199-0.027-0.856-0.194-1.068
        c-0.263-0.332-1.135-0.614-1.554-0.679c-0.466-0.073-1.49-0.076-1.878,0.194c-0.376,0.264-0.692,1.206-0.809,1.651
        c-0.149,0.574-0.191,1.774-0.129,2.363c0.061,0.591,0.318,1.784,0.615,2.299c0.283,0.49,1.196,1.206,1.602,1.601
        c0.48,0.468,1.355,1.524,1.942,1.845c0.581,0.318,1.91,0.625,2.574,0.631c0.709,0.006,2.061-0.551,2.766-0.631
        c0.399-0.044,1.248-0.186,1.603,0c0.658,0.342,1.357,1.77,1.699,2.428c0.262,0.505,0.483,1.65,0.777,2.136
        c0.373,0.618,1.696,1.457,1.942,2.136c0.191,0.525-0.108,1.674-0.098,2.233c0.008,0.462-0.055,1.43,0.146,1.845
        c0.181,0.371,1.125,0.714,1.214,1.117c0.134,0.616-0.896,1.704-0.971,2.331c-0.064,0.536,0.438,1.604,0.34,2.135
        c-0.083,0.455-0.48,1.47-0.922,1.603c-0.364,0.109-1.069-0.435-1.359-0.68c-0.22-0.185-0.412-0.82-0.68-0.923
        c-0.44-0.168-1.451,0.13-1.844,0.389c-0.298,0.195-0.703,0.829-0.825,1.165c-0.204,0.562-0.428,1.83-0.195,2.379
        c0.234,0.548,1.285,1.305,1.846,1.505c0.388,0.139,1.244-0.076,1.651,0c0.525,0.1,1.647,0.368,1.99,0.778
        c0.43,0.511,0.738,1.984,0.534,2.621c-0.218,0.678-1.724,1.33-2.088,1.942c-0.274,0.463-0.222,1.648-0.534,2.088
        c-0.228,0.321-1.125,0.526-1.31,0.874c-0.143,0.268-0.184,0.971,0,1.214c0.212,0.282,1.005,0.407,1.359,0.388
        c0.262-0.014,0.731-0.49,0.971-0.388c0.291,0.122,0.45,0.851,0.486,1.165c0.059,0.513-0.239,1.532-0.34,2.04
        c-0.147,0.733-0.535,2.18-0.68,2.912c-0.408,2.052-1.189,6.171-1.359,8.254c-0.105,1.283-0.217,3.879,0,5.148
        c0.148,0.862,0.859,2.482,1.165,3.301c0.117,0.31,0.314,0.727,0.519,1.173c-0.62,0.277-1.584,0.743-1.878,1.092
        c-0.304,0.36-0.39,1.367-0.616,1.781c-0.206,0.379-0.701,1.131-1.068,1.359c-0.401,0.249-1.376,0.439-1.844,0.389
        c-0.353-0.039-0.964-0.453-1.295-0.583c-0.548-0.214-1.789-0.358-2.233-0.744c-0.314-0.272-0.438-1.206-0.744-1.489
        c-0.224-0.206-0.828-0.458-1.133-0.453c-0.3,0.005-0.801,0.513-1.1,0.486c-0.383-0.037-0.882-0.824-1.262-0.874
        c-0.282-0.037-0.87,0.186-1.068,0.388c-0.367,0.372-0.229,1.713-0.68,1.975c-0.479,0.276-1.617-0.439-2.169-0.421
        c-0.443,0.015-1.273,0.454-1.716,0.453c-0.527-0.002-1.514-0.612-2.039-0.551c-0.457,0.053-1.322,0.523-1.618,0.875
        c-0.182,0.215-0.337,0.787-0.357,1.068c-0.021,0.311,0.316,0.93,0.227,1.23c-0.107,0.362-0.768,0.833-1.036,1.1
        c-0.299,0.3-0.836,1.009-1.23,1.165c-0.429,0.172-1.409,0.15-1.845,0c-0.424-0.145-1.007-0.93-1.424-1.1
        c-0.574-0.235-1.879-0.079-2.46-0.291c-0.373-0.137-1.12-0.536-1.327-0.874c-0.291-0.472-0.087-1.674-0.259-2.202
        c-0.21-0.644-0.756-1.996-1.328-2.363c-0.417-0.268-1.479-0.153-1.974-0.162c-0.576-0.012-1.724,0.08-2.298,0.097
        c-0.956,0.029-2.868-0.014-3.82,0.065c-0.823,0.068-2.445,0.439-3.27,0.486c-0.686,0.038-2.092,0.103-2.752-0.098
        c-0.336-0.103-1.06-0.419-1.197-0.744c-0.115-0.274,0.187-0.876,0.259-1.165c0.096-0.383,0.455-1.128,0.421-1.522
        c-0.035-0.392-0.445-1.106-0.679-1.424c-0.242-0.327-0.893-0.831-1.199-1.101c-0.365-0.323-1.055-1.037-1.489-1.263
        c-0.341-0.176-1.274-0.069-1.489-0.388c-0.209-0.311,0.051-1.141,0.195-1.489c0.126-0.31,0.597-0.808,0.808-1.068
        c0.265-0.324,1.076-0.844,1.101-1.263c0.031-0.517-0.848-1.305-1.166-1.716c-0.217-0.28-0.632-0.887-0.938-1.067
        c-0.227-0.134-0.801-0.35-1.036-0.226c-0.356,0.186-0.605,1.086-0.615,1.488c-0.008,0.361,0.46,1,0.486,1.359
        c0.021,0.288-0.126,0.863-0.227,1.133c-0.166,0.445-0.555,1.369-0.938,1.651c-0.213,0.158-0.79,0.128-1.037,0.227
        c-0.288,0.116-0.756,0.659-1.068,0.648c-0.486-0.019-1.187-0.853-1.586-1.133c-0.395-0.278-1.15-0.892-1.587-1.101
        c-0.548-0.262-1.727-0.751-2.331-0.679c-0.5,0.06-1.31,0.751-1.747,1.003c-0.778,0.45-2.229,1.614-3.108,1.813
        c-0.664,0.151-2.057-0.394-2.719-0.226c-0.328,0.083-0.794,0.728-1.131,0.743c-0.246,0.013-0.746-0.242-0.875-0.452
        c-0.201-0.332,0.152-1.197,0-1.554c-0.109-0.258-0.549-0.671-0.81-0.777c-0.277-0.112-0.902-0.055-1.197,0
        c-0.307,0.057-0.946,0.231-1.165,0.453c-0.212,0.215-0.235,0.894-0.421,1.132c-0.176,0.226-0.661,0.715-0.94,0.649
        c-0.377-0.09-0.648-0.985-0.744-1.359c-0.068-0.268-0.095-0.843,0-1.102c0.13-0.35,0.9-0.766,0.971-1.131
        c0.091-0.471-0.423-1.375-0.615-1.814c-0.163-0.371-0.449-1.187-0.777-1.425c-0.326-0.236-1.227-0.108-1.586-0.291
        c-0.438-0.223-1.19-0.926-1.425-1.359c-0.165-0.304-0.128-1.039-0.26-1.359c-0.099-0.243-0.341-0.77-0.583-0.874
        c-0.346-0.149-1.168,0.059-1.489,0.259c-0.279,0.176-0.449,1.024-0.776,1.068c-0.195,0.028-0.439-0.4-0.615-0.486
        c-0.285-0.137-0.915-0.32-1.231-0.29c-0.378,0.035-1.096,0.375-1.391,0.614c-0.225,0.183-0.397,0.879-0.679,0.939
        c-0.302,0.065-0.886-0.368-1.068-0.614c-0.23-0.307-0.255-1.121-0.356-1.49c-0.158-0.573-0.464-1.726-0.712-2.266
        c-0.18-0.389-0.702-1.079-0.907-1.456c-0.192-0.353-0.341-1.241-0.679-1.457c-0.342-0.217-1.291,0.239-1.618,0
        c-0.317-0.23-0.659-1.137-0.486-1.489c0.076-0.154,0.478-0.197,0.647-0.227c0.264-0.044,0.863,0.172,1.068,0
        c0.278-0.231,0.416-1.097,0.26-1.424c-0.148-0.306-0.874-0.542-1.198-0.648c-0.466-0.151-1.47-0.128-1.942-0.259
        c-0.297-0.082-0.831-0.424-1.133-0.486c-0.356-0.073-1.097,0.059-1.456,0c-0.229-0.037-0.649-0.174-1.041-0.312
        c0.012-0.387-0.012-0.78-0.028-1.031c-0.033-0.497-0.416-1.445-0.437-1.943c-0.027-0.624,0.214-1.864,0.341-2.476
        c0.083-0.407,0.138-1.313,0.436-1.601c0.341-0.33,1.429-0.212,1.845-0.437c0.544-0.293,1.35-1.273,1.796-1.699
        c0.391-0.374,1.24-1.064,1.554-1.506c0.334-0.469,0.641-1.616,0.971-2.088c0.285-0.408,1.174-0.969,1.408-1.408
        c0.296-0.556,0.444-1.846,0.486-2.476c0.036-0.571-0.012-1.727-0.146-2.282c-0.115-0.476-0.291-1.735-0.777-1.797
        c-0.412-0.052-0.732,1.012-1.02,1.311c-0.274,0.285-0.838,0.892-1.213,1.02c-0.597,0.204-1.927,0.11-2.524-0.098
        c-0.568-0.195-1.639-0.892-1.943-1.408c-0.154-0.264-0.143-0.907-0.145-1.213c-0.003-0.452,0.035-1.373,0.195-1.797
        c0.156-0.417,0.86-1.042,1.019-1.456c0.176-0.457,0.241-1.453,0.243-1.942c0.004-0.539,0.093-1.716-0.243-2.136
        c-0.32-0.403-1.689-0.233-1.941-0.68c-0.263-0.464,0.511-1.559,0.436-2.088c-0.069-0.485-0.612-1.351-0.922-1.731
        c-0.239-0.294-0.917-0.685-1.166-0.971c-0.242-0.28-0.476-1.046-0.777-1.263c-0.201-0.145-0.791-0.054-0.971-0.227
        c-0.176-0.168-0.27-0.695-0.26-0.938c0.012-0.284,0.319-0.794,0.389-1.068c0.148-0.587,0.003-1.903,0.356-2.395
        c0.217-0.303,0.978-0.546,1.327-0.68c0.438-0.169,1.407-0.248,1.812-0.485c0.421-0.246,0.899-1.236,1.36-1.392
        c0.379-0.129,1.218,0.067,1.587,0.226c0.095,0.04,0.229,0.216,0.323,0.26c0.562,0.261,1.817,0.387,2.428,0.486
        c0.331,0.052,1.032,0.006,1.328,0.161c0.365,0.191,0.74,1.028,1.1,1.23c0.452,0.256,1.524,0.323,2.04,0.388
        c0.522,0.066,1.591,0.013,2.104,0.129c0.672,0.153,1.875,0.933,2.557,1.037c0.368,0.055,1.188,0.091,1.488-0.129
        c0.257-0.187,0.318-0.899,0.486-1.165c0.13-0.203,0.49-0.531,0.648-0.713c0.182-0.207,0.635-0.577,0.713-0.841
        c0.146-0.494-0.361-1.536-0.259-2.04c0.102-0.504,0.875-1.291,1.035-1.781c0.118-0.355,0.318-1.147,0.162-1.488
        c-0.105-0.233-0.678-0.385-0.841-0.583c-0.258-0.313-0.322-1.246-0.647-1.488c-0.394-0.291-1.47-0.102-1.942-0.228
        c-0.324-0.084-0.999-0.277-1.23-0.517c-0.284-0.292-0.541-1.117-0.583-1.521c-0.063-0.611,0.28-1.823,0.387-2.428
        c0.059-0.332,0.281-0.989,0.261-1.327c-0.028-0.409-0.349-1.181-0.519-1.553c-0.125-0.273-0.535-0.745-0.614-1.037
        c-0.055-0.202-0.105-0.659,0-0.842c0.111-0.194,0.552-0.442,0.776-0.452c0.384-0.018,1.009,0.656,1.392,0.647
        c0.282-0.006,0.876-0.266,1.004-0.517c0.139-0.277-0.024-0.97-0.195-1.23c-0.243-0.367-1.119-0.7-1.489-0.938
        c-0.448-0.287-1.409-0.786-1.781-1.166c-0.32-0.327-0.701-1.183-0.97-1.553c-0.406-0.557-1.16-1.805-1.78-2.105
        c-0.438-0.211-1.463-0.144-1.942-0.064c-0.427,0.07-1.197,0.521-1.619,0.615c-0.349,0.076-1.077,0.18-1.425,0.097
        c-0.292-0.07-0.88-0.356-1.035-0.615c-0.231-0.381-0.047-1.337-0.097-1.78c-0.049-0.417-0.008-1.344-0.292-1.651
        c-0.306-0.333-1.345-0.251-1.747-0.453c-0.474-0.237-1.161-1.127-1.651-1.327c-0.467-0.191-1.532-0.364-2.007-0.194
        c-0.313,0.112-0.635,0.807-0.939,0.938c-0.29,0.125-0.961,0.127-1.263,0.034c-0.249-0.078-0.585-0.568-0.841-0.616
        c-0.279-0.052-0.895,0.094-1.101,0.291c-0.164,0.16-0.095,0.717-0.259,0.875c-0.241,0.231-1.032,0.117-1.295,0.324
        c-0.287,0.225-0.341,1.157-0.679,1.294c-0.244,0.1-0.747-0.264-1.003-0.323c-0.223-0.051-0.682-0.174-0.906-0.13
        c-0.268,0.052-0.667,0.536-0.939,0.551c-0.257,0.013-0.768-0.229-0.939-0.421c-0.09-0.103-0.209-0.392-0.162-0.519
        c0.087-0.231,0.776-0.278,0.841-0.517c0.083-0.3-0.322-0.931-0.583-1.1c-0.211-0.137-0.754-0.102-1.003-0.065
        c-0.162,0.024-0.478,0.136-0.615,0.226c-0.292,0.192-0.645,0.881-0.97,1.004c-0.365,0.137-1.177-0.03-1.554-0.13
        c-0.371-0.098-1.174-0.331-1.392-0.647c-0.258-0.374-0.272-1.381-0.129-1.813c0.139-0.426,0.781-1.129,1.165-1.359
        c0.42-0.252,1.582-0.058,1.91-0.421c0.235-0.261,0.14-1.042,0.161-1.393c0.023-0.388,0.092-1.176,0-1.553
        c-0.075-0.309-0.447-0.844-0.582-1.132c-0.122-0.264-0.214-0.905-0.453-1.068c-0.24-0.164-0.875-0.002-1.165-0.033
        c-0.36-0.037-1.091-0.116-1.425-0.258c-0.431-0.185-1.086-0.924-1.521-1.101c-0.25-0.102-0.869,0.021-1.068-0.161
        c-0.281-0.259-0.467-1.123-0.355-1.49c0.134-0.443,0.942-1.059,1.359-1.262c0.42-0.204,1.444-0.051,1.845-0.292
        c0.45-0.271,1.084-1.187,1.262-1.683c0.188-0.526-0.088-1.706,0.098-2.233c0.149-0.427,1.02-1.008,1.068-1.457
        c0.059-0.554-0.492-1.667-0.907-2.039c-0.398-0.356-1.506-0.601-2.039-0.648c-0.423-0.036-1.262,0.273-1.683,0.227
        c-0.438-0.049-1.348-0.296-1.651-0.616c-0.203-0.215-0.119-0.954-0.356-1.132c-0.212-0.161-0.838,0.103-1.068-0.033
        c-0.3-0.177-0.387-1.072-0.712-1.197c-0.363-0.141-1.174,0.225-1.489,0.454c-0.239,0.174-0.387,1.014-0.679,0.97
        c-0.463-0.069-0.286-1.419-0.582-1.781c-0.271-0.33-1.224-0.506-1.49-0.841c-0.235-0.298-0.273-1.107-0.421-1.457
        c-0.223-0.531-0.835-1.514-1.133-2.007c-0.208-0.346-0.732-0.98-0.874-1.359c-0.135-0.36-0.279-1.141-0.226-1.521
        c0.057-0.405,0.592-1.088,0.679-1.489c0.064-0.292,0.075-0.908,0-1.197c-0.087-0.341-0.4-1.018-0.679-1.231
        c-0.252-0.191-0.938-0.17-1.231-0.291c-0.351-0.148-0.957-0.631-1.293-0.809c-0.462-0.245-1.426-0.651-1.91-0.842
        c-0.319-0.126-1.014-0.257-1.294-0.453c-0.189-0.131-0.393-0.583-0.582-0.712c-0.218-0.149-0.815-0.14-1.003-0.324
        c-0.21-0.203-0.219-0.864-0.389-1.101c-0.271-0.374-1.07-0.881-1.456-1.132c-0.431-0.28-1.308-0.875-1.814-0.971
        c-0.417-0.079-1.34-0.056-1.682,0.194c-0.346,0.251-0.642,1.132-0.713,1.554c-0.066,0.401,0.361,1.263,0.162,1.618
        c-0.228,0.408-1.218,0.762-1.682,0.81c-0.598,0.06-1.739-0.484-2.331-0.583c-0.465-0.078-1.45-0.358-1.878-0.162
        c-0.553,0.253-0.946,1.597-1.424,1.974c-0.35,0.276-1.205,0.666-1.651,0.68c-0.53,0.017-1.585-0.36-2.007-0.68
        c-0.227-0.172-0.384-0.829-0.648-0.938c-0.328-0.135-1.11,0.043-1.392,0.258c-0.221,0.17-0.316,0.783-0.486,1.004
        c-0.221,0.286-0.77,0.791-1.1,0.938c-0.336,0.149-1.09,0.217-1.457,0.194c-0.317-0.019-1.036-0.072-1.23-0.323
        c-0.212-0.276,0.045-1.047,0-1.392c-0.044-0.349-0.137-1.084-0.355-1.359c-0.29-0.366-1.191-0.795-1.652-0.874
        c-0.378-0.065-1.136,0.203-1.52,0.226c-0.615,0.038-1.96,0.292-2.46-0.065c-0.339-0.241-0.324-1.23-0.583-1.553
        c-0.247-0.31-1.084-0.559-1.326-0.874c-0.25-0.324-0.357-1.177-0.518-1.553c-0.136-0.32-0.369-1.021-0.647-1.23
        c-0.226-0.169-0.846-0.11-1.102-0.227c-0.252-0.116-0.599-0.639-0.874-0.68c-0.154-0.023-0.43,0.191-0.582,0.227
        c-0.584,0.138-1.794,0.238-2.395,0.226c-0.603-0.011-1.806-0.189-2.395-0.323c-0.307-0.07-0.942-0.206-1.198-0.389
        c-0.395-0.282-1.012-1.093-1.165-1.553c-0.11-0.33,0.174-1.09,0-1.393c-0.178-0.308-0.903-0.573-1.23-0.712
        c-0.271-0.116-0.956-0.088-1.133-0.323c-0.175-0.233-0.112-0.896,0-1.165c0.106-0.254,0.717-0.509,0.777-0.776
        c0.059-0.26-0.174-0.81-0.355-1.004c-0.123-0.13-0.431-0.247-0.667-0.312c0.242-0.398,0.612-1.054,0.634-1.37
        c0.037-0.493-0.33-1.473-0.614-1.877c-0.266-0.378-1.249-0.721-1.457-1.133c-0.149-0.296-0.181-1.031-0.032-1.328
        c0.173-0.348,0.886-0.811,1.262-0.906c0.372-0.094,1.14,0.233,1.521,0.194c0.272-0.028,0.868-0.14,1.036-0.356
        c0.235-0.301-0.053-1.186,0.129-1.52c0.127-0.233,0.682-0.436,0.842-0.648c0.274-0.361,0.375-1.347,0.679-1.683
        c0.246-0.271,1.11-0.364,1.295-0.679c0.143-0.245,0.083-0.875-0.033-1.133c-0.173-0.387-0.835-1.013-1.23-1.165
        c-0.325-0.125-1.068,0.162-1.392,0.032c-0.318-0.127-0.82-0.662-0.97-0.97c-0.175-0.358-0.422-1.257-0.195-1.586
        c0.251-0.363,1.302-0.265,1.716-0.421c0.23-0.087,0.76-0.235,0.874-0.454c0.17-0.325-0.425-1.171-0.194-1.456
        c0.265-0.328,1.36,0.144,1.682-0.129c0.202-0.172,0.226-0.939,0.226-1.036c0-0.097,0.561-1.191,0.648-1.619
        c0.068-0.333,0.186-1.074,0-1.359c-0.338-0.518-1.803-0.59-2.266-1.003c-0.286-0.257-0.676-0.957-0.776-1.328
        c-0.129-0.475-0.078-1.486,0-1.974c0.057-0.361,0.286-1.066,0.452-1.392c0.219-0.428,0.759-1.251,1.133-1.553
        c0.31-0.25,1.094-0.483,1.456-0.648c0.317-0.144,1.015-0.339,1.263-0.583c0.199-0.194,0.504-0.725,0.486-1.003
        c-0.021-0.31-0.593-0.738-0.68-1.036c-0.121-0.42,0.001-1.312,0.033-1.748c0.028-0.39-0.074-1.268,0.194-1.553
        c0.272-0.288,1.161-0.324,1.554-0.292c0.541,0.045,1.498,0.728,2.04,0.745c0.397,0.012,1.237-0.177,1.521-0.453
        c0.299-0.292,0.503-1.169,0.517-1.586c0.02-0.482-0.302-1.419-0.452-1.878c-0.086-0.258-0.361-0.737-0.422-1.003
        c-0.123-0.553,0.349-1.916-0.097-2.265c-0.455-0.356-1.704,0.584-2.266,0.453c-0.438-0.102-1.211-0.704-1.425-1.1
        c-0.2-0.372-0.002-1.281-0.129-1.683c-0.091-0.287-0.38-0.848-0.615-1.036c-0.286-0.229-1.118-0.21-1.392-0.454
        c-0.305-0.272-0.592-1.088-0.679-1.488c-0.081-0.372-0.213-1.206,0-1.522c0.146-0.217,0.76-0.234,0.971-0.388
        c0.289-0.21,0.688-0.822,0.915-1.1c0.338-0.416,0.872-1.405,1.335-1.675c0.334-0.195,1.278,0.051,1.529-0.243
        c0.182-0.213,0.056-0.844,0-1.117c-0.099-0.48-0.699-1.313-0.777-1.796c-0.087-0.542,0.488-1.722,0.195-2.185
        c-0.235-0.372-1.377-0.263-1.651-0.607c-0.28-0.354-0.382-1.374-0.218-1.796c0.171-0.442,0.96-1.253,1.432-1.238
        c0.4,0.012,0.758,1.007,1.141,1.117c0.377,0.108,1.143-0.277,1.529-0.34c0.471-0.076,1.44-0.05,1.894-0.194
        c0.378-0.119,1.024-0.61,1.384-0.777c0.364-0.169,1.226-0.269,1.506-0.558c0.256-0.267,0.468-1.038,0.461-1.408
        c-0.006-0.316-0.232-0.949-0.437-1.19c-0.267-0.311-1.228-0.445-1.433-0.801c-0.154-0.269-0.111-0.959,0.025-1.238
        c0.114-0.233,0.555-0.576,0.801-0.656c0.388-0.125,1.243-0.04,1.627,0.097c0.474,0.171,1.245,0.86,1.626,1.19
        c0.478,0.412,1.225,1.483,1.772,1.796c0.414,0.236,1.383,0.573,1.845,0.461c0.305-0.074,0.861-0.523,0.947-0.825
        c0.118-0.416-0.348-1.25-0.509-1.651c-0.167-0.41-0.735-1.141-0.802-1.578c-0.066-0.432,0.27-1.286,0.291-1.723
        c0.035-0.656,0.022-1.994-0.17-2.622c-0.14-0.463-0.845-1.208-0.971-1.675c-0.155-0.575,0.164-1.807,0-2.379
        c-0.133-0.462-0.872-1.17-1.02-1.627c-0.162-0.498-0.04-1.575-0.146-2.088c-0.063-0.309-0.41-0.875-0.413-1.19
        c-0.002-0.312,0.094-1.086,0.389-1.189c0.314-0.111,0.797,0.633,1.117,0.728c0.314,0.093,1.018,0.147,1.311,0
        c0.352-0.177,0.609-1.058,0.947-1.262c0.226-0.137,0.796-0.078,1.044-0.17c0.261-0.097,0.767-0.371,0.947-0.583
        c0.31-0.365,0.429-1.397,0.729-1.772c0.314-0.395,1.464-0.696,1.651-1.165c0.004-0.008,0.007-0.017,0.01-0.026
        c0.461,0.059,0.969,0.127,1.228,0.172c0.403,0.07,1.206,0.235,1.602,0.34c0.615,0.163,1.816,0.956,2.428,0.777
        c0.438-0.128,1.01-0.978,1.165-1.408c0.083-0.228,0.054-0.734,0-0.971c-0.124-0.54-0.992-1.437-0.971-1.99
        c0.019-0.479,0.61-1.336,0.971-1.651c0.356-0.311,1.402-0.405,1.747-0.728c0.298-0.278,0.415-1.198,0.729-1.457
        c0.322-0.263,1.206-0.559,1.602-0.437c0.412,0.127,1.03,0.89,1.117,1.311c0.059,0.285-0.266,0.834-0.34,1.117
        c-0.072,0.277-0.263,0.831-0.243,1.117c0.015,0.207,0.185,0.599,0.291,0.777c0.208,0.347,0.766,0.945,1.068,1.214
        c0.286,0.255,0.968,0.628,1.263,0.874c0.355,0.297,0.871,1.165,1.311,1.311c0.547,0.181,1.705-0.36,2.282-0.34
        c0.447,0.016,1.318,0.514,1.748,0.388c0.312-0.091,0.776-0.776,0.922-0.922c0.145-0.146,1.243-0.03,1.651-0.097
        c0.549-0.089,1.54-0.678,2.087-0.777c0.203-0.037,0.638-0.083,0.826,0c0.346,0.153,0.71,0.899,1.02,1.117
        c0.387,0.272,1.275,0.759,1.747,0.729c0.461-0.029,1.162-0.781,1.602-0.923c0.674-0.218,2.115-0.39,2.816-0.291
        c0.674,0.095,1.869,0.835,2.524,1.019c0.925,0.26,2.828,0.606,3.787,0.631c0.977,0.026,2.91-0.505,3.885-0.437
        c0.487,0.034,1.438,0.665,1.894,0.485c0.441-0.175,0.688-1.261,1.02-1.602c0.286-0.295,1.143-0.573,1.359-0.922
        c0.193-0.313,0.261-1.095,0.194-1.457c-0.101-0.555-0.977-1.434-1.068-1.991c-0.123-0.766,0.143-2.389,0.534-3.059
        c0.309-0.529,1.429-1.156,1.894-1.554c0.334-0.284,1.072-0.797,1.311-1.165c0.284-0.438,0.295-1.591,0.631-1.991
        c0.22-0.262,0.874-0.606,1.214-0.631c0.628-0.045,1.855,0.475,2.378,0.825c0.349,0.235,0.715,1.137,1.118,1.263
        c0.382,0.118,1.153-0.376,1.554-0.388c0.468-0.015,1.38,0.409,1.844,0.34c0.446-0.066,1.275-0.516,1.602-0.825
        c0.329-0.31,0.53-1.33,0.923-1.553c0.622-0.354,2.247,0.364,2.864,0c0.477-0.282,0.853-1.432,1.068-1.942
        c0.227-0.538,0.342-1.758,0.68-2.234c0.261-0.368,1.135-0.751,1.456-1.068c0.302-0.299,0.664-1.126,1.02-1.359
        c0.574-0.376,1.984-0.662,2.67-0.631c0.499,0.022,1.41,0.51,1.894,0.631c0.43,0.107,1.343,0.469,1.748,0.291
        c0.386-0.169,0.552-1.208,0.923-1.408c0.32-0.173,1.098,0.067,1.455,0c0.564-0.105,1.878-0.314,2.136-0.825
        c0.148-0.291-0.336-0.935-0.34-1.262c-0.004-0.362,0.101-1.135,0.34-1.408c0.267-0.304,1.113-0.491,1.506-0.582
        c0.7-0.162,2.268,0.157,2.864-0.243c0.398-0.266,0.505-1.397,0.875-1.699c0.342-0.281,1.258-0.472,1.699-0.486
        c0.447-0.014,1.383,0.128,1.748,0.388c0.359,0.256,0.608,1.195,0.922,1.505c0.271,0.267,0.931,0.81,1.311,0.777
        c0.446-0.039,1.025-0.869,1.359-1.165c0.418-0.37,1.276-1.09,1.651-1.505c0.396-0.437,0.942-1.532,1.408-1.894
        c0.228-0.177,0.785-0.382,1.068-0.437c0.357-0.07,1.113-0.118,1.456,0c0.263,0.089,0.598,0.655,0.875,0.68
        c0.367,0.034,1.013-0.462,1.311-0.68c0.442-0.324,1.105-1.236,1.553-1.554c0.275-0.194,0.915-0.427,1.213-0.582
        c0.31-0.16,0.891-0.554,1.214-0.68c0.407-0.156,1.317-0.596,1.7-0.389c0.34,0.185,0.184,1.29,0.533,1.457
        c0.371,0.177,1.143-0.534,1.554-0.534c0.526,0,1.497,0.859,1.99,0.68c0.44-0.16,0.564-1.372,0.971-1.602
        c0.571-0.321,1.977,0.119,2.622,0c0.434-0.08,1.254-0.435,1.651-0.631c0.269-0.133,0.874-0.369,1.019-0.631
        c0.166-0.297-0.216-1.097,0-1.359c0.205-0.248,0.944-0.282,1.263-0.243c0.572,0.071,1.517,0.892,2.088,0.971
        c0.462,0.065,1.396-0.164,1.844-0.291c0.697-0.197,2.012-0.818,2.672-1.117c0.529-0.24,1.521-0.889,2.087-1.02
        c0.561-0.129,1.543-0.054,2.367-0.014c-0.156,0.68-0.264,1.4-0.133,1.762c0.152,0.422,1.102,0.799,1.36,1.165
        c0.292,0.415,0.636,1.395,0.728,1.894c0.086,0.465-0.227,1.478,0,1.894c0.249,0.454,1.385,0.746,1.748,1.117
        c0.488,0.498,1.277,1.705,1.456,2.379c0.106,0.399,0.116,1.272-0.048,1.651c-0.117,0.268-0.766,0.54-0.825,0.825
        c-0.105,0.496,0.255,1.71,0.729,1.894c0.353,0.137,0.979-0.7,1.359-0.68c0.447,0.024,1.189,0.656,1.506,0.971
        c0.299,0.296,0.911,0.987,0.923,1.408c0.013,0.463-0.52,1.376-0.923,1.602c-0.392,0.22-1.363,0.07-1.796-0.048
        c-0.509-0.139-1.328-0.921-1.846-1.02c-0.565-0.108-1.806-0.031-2.282,0.291c-0.206,0.14-0.449,0.626-0.485,0.874
        c-0.065,0.443,0.125,1.386,0.388,1.748c0.266,0.366,1.336,0.526,1.553,0.922c0.205,0.372,0.079,1.294-0.047,1.7
        c-0.126,0.401-0.902,0.988-0.923,1.408c-0.021,0.396,0.485,1.095,0.728,1.408c0.17,0.216,0.631,0.534,0.825,0.728
        c0.337,0.333,1.094,0.966,1.264,1.408c0.19,0.5,0,1.602,0,2.136c0,0.267-0.092,0.818,0,1.068c0.206,0.566,1.347,1.279,1.553,1.845
        c0.216,0.593,0,1.894,0,2.524c0,0.497-0.171,1.524,0,1.991c0.214,0.583,1.175,1.444,1.602,1.894
        c0.379,0.398,1.162,1.176,1.602,1.505c0.327,0.245,1,0.819,1.408,0.825c0.581,0.009,1.468-1.006,2.039-1.117
        c0.721-0.14,2.283-0.034,2.914,0.34c0.397,0.235,0.778,1.146,1.067,1.505c0.486,0.602,1.64,1.652,2.088,2.282
        c0.296,0.416,0.559,1.496,0.971,1.797c0.159,0.116,0.598,0.232,0.776,0.146c0.381-0.186,0.452-1.234,0.777-1.505
        c0.283-0.237,1.04-0.459,1.409-0.437c0.331,0.019,1.056,0.242,1.213,0.534c0.169,0.315-0.333,1.062-0.243,1.408
        c0.058,0.226,0.506,0.507,0.583,0.728c0.137,0.389,0.195,1.286,0,1.651c-0.103,0.194-0.51,0.475-0.728,0.486
        c-0.466,0.023-1.216-1.045-1.651-0.874c-0.241,0.094-0.298,0.718-0.341,0.971c-0.068,0.407-0.053,1.242,0,1.651
        c0.092,0.697,0.354,2.123,0.729,2.719c0.307,0.488,1.207,1.304,1.747,1.505c0.671,0.249,2.246-0.358,2.865,0
        c0.569,0.329,1.14,1.646,1.31,2.282c0.111,0.411-0.145,1.3,0,1.699c0.169,0.461,1.095,1.044,1.264,1.505
        c0.136,0.377,0.24,1.282,0,1.602c-0.235,0.313-1.215,0.174-1.507,0.437c-0.241,0.22-0.525,0.89-0.485,1.214
        c0.066,0.535,1.14,1.223,1.263,1.748c0.126,0.552-0.638,1.725-0.389,2.234c0.182,0.371,1.106,0.558,1.457,0.776
        c0.317,0.198,0.938,0.621,1.213,0.874c0.317,0.291,0.7,1.204,1.117,1.311c0.528,0.135,1.498-0.838,2.04-0.776
        c0.434,0.048,1.021,0.938,1.456,0.971c0.883,0.066,2.341-1.312,3.205-1.505c0.344-0.077,1.123,0.206,1.408,0
        c0.28-0.202,0.273-1.006,0.437-1.311c0.326-0.61,1.078-1.881,1.699-2.185c0.676-0.33,2.262-0.088,3.01,0
        c0.571,0.067,1.755,0.217,2.233,0.534c0.478,0.316,0.912,1.487,1.359,1.845c0.331,0.265,1.37,0.297,1.554,0.68
        c0.141,0.294-0.194,0.97-0.341,1.263c-0.193,0.387-0.652,1.243-1.068,1.359c-0.399,0.112-1.138-0.575-1.553-0.583
        c-0.307-0.005-0.986,0.139-1.166,0.388c-0.211,0.295-0.184,1.142,0,1.457c0.289,0.492,1.703,0.624,1.991,1.117
        c0.441,0.755-0.453,2.749,0,3.496c0.384,0.631,1.968,1.019,2.621,1.359c0.535,0.278,1.556,1.277,2.136,1.117
        c0.672-0.186,1.013-1.841,1.457-2.379c0.355-0.43,1.096-1.436,1.651-1.505c0.41-0.052,1.088,0.591,1.457,0.776
        c0.503,0.255,1.657,0.557,2.039,0.971c0.37,0.401,0.682,1.502,0.777,2.039c0.128,0.729,0.085,2.233-0.049,2.962
        c-0.141,0.774-0.932,2.187-1.068,2.962c-0.193,1.112-0.188,3.402,0,4.515c0.093,0.545,0.799,1.539,0.729,2.088
        c-0.093,0.738-1.435,1.764-1.651,2.476c-0.144,0.477-0.158,1.519,0,1.991c0.16,0.477,0.967,1.164,1.214,1.602
        c0.128,0.228,0.251,0.749,0.388,0.971c0.173,0.277,0.627,0.757,0.874,0.971c0.497,0.429,1.775,0.945,2.186,1.457
        c0.399,0.497,0.634,1.81,0.922,2.379c0.265,0.526,0.802,1.624,1.262,1.99c0.665,0.527,2.584,0.539,3.205,1.117
        c0.29,0.27,0.505,1.08,0.632,1.457c0.213,0.639,0.176,2.124,0.631,2.622c0.406,0.446,1.932,0.286,2.282,0.777
        c0.509,0.711-0.134,2.633,0,3.496c0.105,0.667,0.581,1.944,0.825,2.573c0.126,0.325,0.321,1.035,0.583,1.263
        c0.383,0.332,1.554,0.257,1.943,0.583c0.582,0.49,0.721,2.306,1.359,2.719c0.755,0.489,2.718,0.349,3.594,0.146
        c0.605-0.14,1.583-1.03,2.185-1.182c0.659-0.167,2.081-0.236,2.719,0c0.7,0.259,1.567,1.678,2.266,1.942
        c1.303,0.49,4.178,0.151,5.568,0.065c0.831-0.052,2.47-0.396,3.301-0.454c0.403-0.027,1.222-0.087,1.617,0
        c0.489,0.108,1.393,0.572,1.813,0.842c0.61,0.39,1.572,1.522,2.202,1.877c0.446,0.251,1.5,0.39,1.943,0.648
        c0.545,0.316,1.582,1.126,1.877,1.683c0.168,0.318,0.027,1.107,0.195,1.425c0.213,0.405,0.934,1.058,1.358,1.229
        c0.466,0.189,1.524,0.201,2.008,0.065c0.652-0.184,1.648-1.209,2.265-1.489C714.632,306.017,714.901,305.891,715.155,305.816z" pointer-events="auto" stroke-width="0.5" class="landarea"></path>

        <rect id="rect12_22_" x="533.626" y="282.345" opacity="0" fill="#FFFFFF" stroke="#010202" stroke-miterlimit="10" enable-background="new    " width="59.957" height="20.14" pointer-events="auto" visibility="hidden"></rect>
</g>
</svg>
}