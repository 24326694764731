export default function SouthAmerica({innerRef}) {
    return <svg ref={innerRef} version="1.1" id="svgpoint" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 900 700" enable-background="new 0 0 900 700"  width="100%" height="100%" preserveAspectRatio="xMidYMid meet">
<g id="WATER" data-colors="0" data-errors="0" hint-index="0" class="">
    <rect x="0.926" y="0.526" fill="#a4d1dc" width="900" height="700" pointer-events="none" opacity="1" stroke="#95bec8" stroke-width="0.1" class="water"></rect>
</g>
<g id="LAND" data-colors="0" data-errors="0" hint-index="0" class="">
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M447.147,53.349c0,0-1.128,0.085-1.128,1.041
        s1.128,1.217,1.909-0.085C448.708,53.004,449.403,53.174,447.147,53.349z" pointer-events="none" class="landarea"></path>
    <g data-colors="0">
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M470.23-6.443c0,0-0.693,0-1.562-0.521
            c-0.869-0.521,0.174,0.434-1.562,0.781c-1.736,0.347-1.91,1.822-0.434,1.909s2.689-0.086,3.732-0.607
            c1.041-0.521,2.344-0.434,2.69-1.041c0.347-0.607,1.91-1.042,2.603-0.694c0.693,0.348,1.302,0.608,1.302,1.389
            s1.562,0.868,1.562,1.649c0,0.78-0.348,1.734-1.215,1.909c-0.868,0.174-0.522,1.128,0.347,2.343s1.648,1.648,1.302,0
            c-0.347-1.648-1.041-2.429,0.261-3.036c1.302-0.608,1.129-1.91,0.694-2.691c-0.434-0.78-2.171-1.475-2.778-2.43
            c-0.607-0.955-2.864-1.303-3.644-1.216c-0.781,0.087-1.909,0.261-1.909,1.216C471.619-6.529,471.792-6.356,470.23-6.443z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M471.097,6.314c0,0,0.868,2.082-0.26,2.864
            c-1.128,0.78-0.955,1.823-0.175,2.603c0.781,0.78,1.388,1.388,1.562,1.909s0.347,0.693,1.041-0.261s1.389-1.214,1.129-2.516
            s-0.521-1.041-0.955-2.343C473.007,7.269,470.664,3.538,471.097,6.314z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M474.482,14.211c0,0-1.65,0.608-1.216,1.389
            s1.389,1.909,1.389,2.255c0,0.347,1.476,0.87,1.562-0.173c0.086-1.041,0.606-1.649-0.087-2.082
            C475.437,15.166,475.09,13.951,474.482,14.211z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M477.259,6.835c0,0-0.261,0.433-0.694,0.607
            c-0.434,0.174-0.694,1.041,0.086,1.041C477.432,8.484,478.996,5.88,477.259,6.835z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M483.073,3.625c0,0-0.781,1.216,0,1.562
            c0.781,0.347,1.996,0.173,2.257,1.128s0.78,0.086,0.607,1.302c-0.174,1.215,0.001,1.909,0.434,1.302
            c0.433-0.607,1.561-0.694,0.954-1.823c-0.607-1.128-0.693-1.476-1.649-1.996C484.722,4.579,483.941,3.191,483.073,3.625z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M491.231,10.393
            c0,0-1.389,0.261-0.781,1.302s1.302,0.348,1.302,2.082s0.435,2.168,0.869,1.734c0.433-0.433,0.693-1.302,0.347-2.343
            C492.619,12.128,492.705,11.348,491.231,10.393z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M488.281,18.463
            c0,0-1.649,1.216-0.174,1.649c1.476,0.433,3.992,2.345,3.037,0.869C490.189,19.504,489.147,18.115,488.281,18.463z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M493.487,18.898c0,0-0.78,1.039,0,1.647
            c0.781,0.608,0.781,1.387,1.041,2.343c0.261,0.956,0.781,2.082,1.302,2.082s1.562-0.175,0.781-1.302s-0.433-1.3-1.128-2.168
            C494.789,20.633,493.834,17.944,493.487,18.898z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M500.863,25.407
            c0,0,0.001,1.735,0.869,1.735c0.868,0,1.562-0.173,1.562,0.869c0,1.041-0.434,1.3-1.215,1.908s-1.563,1.649-0.955,1.91
            c0.608,0.261,0.954-0.085,1.823-0.954s1.823-1.562,1.736-2.864c-0.086-1.302-0.52-1.477-1.909-1.91
            C501.383,25.667,500.95,24.453,500.863,25.407z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M511.277,29.225c0,0-1.302,0.78,0.174,1.041
            c1.476,0.26,2.43,0.606,2.43-0.087C513.88,29.485,512.318,28.964,511.277,29.225z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M508.501,38.685
            c0,0-2.779,0.435-2.951,1.041s-0.434,1.475,1.302,1.735c1.736,0.26,1.824-0.693,2.69-0.954c0.868-0.26,1.215-0.956,1.128-1.649
            C510.583,38.163,509.369,37.904,508.501,38.685z" pointer-events="none" class="landarea"></path>
    </g>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M587.96,62.282c0,0-0.398,1.196-1.194,1.461
        c-0.795,0.265-0.929,1.857,0,1.857c0.928,0,2.256,0.267,2.256-1.194C589.022,62.945,588.623,61.088,587.96,62.282z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M574.96,66.395c0,0-2.387-0.397-2.387,0.797
        S577.879,66.791,574.96,66.395z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M590.746,69.446c0,0-1.857-0.399-1.459,0.53
        c0.397,0.929,1.459,1.463,1.857,0.797C591.541,70.109,591.94,69.71,590.746,69.446z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M600.032,75.946c0,0,0.133,1.725-1.194,2.255
        c-1.327,0.531-1.991,0.793-1.327,1.458s2.387,1.327,2.653-0.132c0.266-1.458,1.99-1.062,1.459-2.256
        C601.093,76.078,600.166,74.353,600.032,75.946z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M600.828,83.244c0,0-1.725,0.793-0.531,1.59
        s1.856,2.255,1.989,0.929C602.421,84.437,601.493,82.979,600.828,83.244z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M602.818,88.548c0,0-0.795,1.857-0.132,2.786
        c0.663,0.929,1.06,1.989,1.989,1.989c0.93,0,1.99-0.265,1.062-1.59c-0.928-1.326-2.123-0.797-2.123-2.256
        C603.614,88.019,603.151,87.47,602.818,88.548z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M603.439,95.787c0,0-0.868,1.389-0.261,2.082
        c0.607,0.694,1.562,0.433,1.648-0.521C604.914,96.395,603.874,94.138,603.439,95.787z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M613.939,101.948
        c0,0-0.694,0.956,0.086,1.216c0.781,0.261,2.518,0.171,1.649-0.435C614.807,102.122,614.286,101.079,613.939,101.948z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M602.137,101.602c0,0-0.607,1.993,0,2.168
        c0.607,0.175,0.868-0.172,0.868-1.128C603.005,101.687,602.312,100.3,602.137,101.602z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M598.058,110.453c0,0-0.259,2.17,0.694,2.083
        C599.707,112.449,598.84,109.672,598.058,110.453z" pointer-events="none" class="landarea"></path>
    <g data-colors="0">
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M602.398,120.78
            c0,0-1.909,0.779-2.083,1.735s-0.261,2.864-1.215,2.951c-0.955,0.087-3.036,1.389-1.128,1.649c1.909,0.26,5.034,0.867,5.207-0.175
            c0.173-1.041,1.995-0.433,1.128-2.256C603.439,122.862,603.178,119.13,602.398,120.78z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M606.216,117.308
            c0,0-1.387,1.13-1.215,1.737c0.173,0.606,1.127,0.26,1.475-0.261C606.824,118.264,606.909,116.352,606.216,117.308z" pointer-events="none" class="landarea"></path>
    </g>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M611.126,641.041
        c0,0,0.034,0.716-1.466,0.307s-2.318-0.238-2.08,0.375c0.238,0.614,1.704,0.886,1.977,1.568c0.273,0.681,1.33,1.704,0.136,1.67
        c-1.193-0.034-2.283-0.307-1.602,0.238s1.328,1.875,0.545,2.114c-0.783,0.238-1.466,0.511-1.977-0.376
        c-0.512-0.886-1.33-1.499-2.147-1.466c-0.818,0.034-0.273,0.442,0.034,1.159s0.955,2.727,1.738,2.999s2.147,0.648,3.272,0.307
        s2.625-0.614,3.442-1.295c0.818-0.681,1.09-0.716,2.352-1.84c1.261-1.125,2.215-2.692,2.931-3.135s1.534-0.886,1.568-1.5
        s-1.397-1.09-1.704-0.545c-0.307,0.545-1.466,1.024-1.635,0.069c-0.171-0.955-0.069-2.011-0.852-2.011s-0.477,1.193-1.125,1.09
        c-0.647-0.103-0.647-0.409-0.409-1.09s-0.239-1.636-0.818-1.431c-0.579,0.204-0.136,1.602-0.988,1.806
        C611.467,640.257,611.195,640.53,611.126,641.041z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M622.203,640.598
        c0,0-1.466,2.011-1.806,2.692s-0.954,1.602-1.772,2.215c-0.818,0.614-1.67,2.147-2.283,2.522c-0.614,0.376-1.704,0.954-0.716,1.806
        c0.988,0.852,2.454,1.806,2.658,1.125c0.204-0.681,1.466-0.614,1.432-1.397c-0.034-0.783,0.614-1.466,1.227-0.988
        c0.614,0.477,1.09,0.274,1.397,0.784s1.432,0.852,1.67,0.647s0.409-1.33-0.238-1.636c-0.647-0.307-1.057-0.955-0.205-0.988
        c0.852-0.033,2.625,0.375,2.692-0.375s-0.102-0.716,0.988-0.681c1.091,0.034,1.636,0,1.5-0.341
        c-0.136-0.34-0.136-0.512,0.614-0.614s1.5-0.204,1.397-1.295c-0.102-1.09,0.068-2.045-0.852-1.84
        c-0.92,0.205-0.545-1.227-1.773-1.295c-1.227-0.068-0.852,1.67-1.942,1.193c-1.091-0.477,0.171-1.398-0.954-1.704
        C624.112,640.122,622.578,639.985,622.203,640.598z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M779.212,669.391
        c0,0-3.582-0.051-3.454,0.406s1.169,0.254,1.88,0.965s2.134,2.185,3.352,2.032c1.219-0.152,0.864-0.305,1.88,0.762
        s2.997,2.057,4.064,2.514s1.803,0.965,1.905,1.981c0.102,1.016,1.626,1.397,2.108,0.431c0.482-0.965,0.177-1.143,0.965-1.423
        c0.787-0.279,2.006-1.321,0.66-1.727s-2.413-0.864-3.022-2.007c-0.61-1.143-1.448-1.244-2.261-1.447s-0.939-1.474-2.006-1.626
        c-1.067-0.152-3.352-0.076-3.86-0.533C780.914,669.263,780.05,669.314,779.212,669.391z" pointer-events="none" class="landarea"></path>
</g>
<g id="AREA_VENEZUELA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Venezuela" data-wikipediaLink="https://en.wikipedia.org/wiki/Venezuela" data-infoText="<b>Venezuela</b> &amp;nbsp; Capital: Caracas" data-imageFile="VENEZUEL.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <rect id="rect12_14_" x="515.86" y="116.952" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="69.699" height="19.609" pointer-events="auto" visibility="hidden"></rect>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M547.247,110.516
        c0,0-0.651,0.585-0.227,0.846s0.748,0.912,1.172,0.912s0.878-0.13,0.553-0.716C548.419,110.972,547.476,110.06,547.247,110.516z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M581.397,118.436c0,0-0.001,0.78-1.215,0.78
        c-1.215,0-1.822,0.608-1.735,1.041c0.086,0.433,0.954,0.781,1.735,0.781c0.78,0,2.17-0.433,2.517-1.302
        C583.045,118.87,581.656,116.874,581.397,118.436z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M542.497,109.735
        c0,0-1.465-0.227-1.725-0.488c-0.261-0.26-1.334,0.39-0.39,1.009c0.943,0.619,1.399,1.106,1.628,1.367
        c0.227,0.26,2.018,0.78,2.343,0.326c0.326-0.456-0.261-1.302-0.846-1.465C542.92,110.321,543.342,109.898,542.497,109.735z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M604.875,163.939
        c-0.484-1.211-1.332-0.969-3.028-2.785c-1.695-1.817-0.364-2.422,0.605-3.633s0.242-1.817,0.242-2.664
        c0-0.848,0.969-0.969,2.422-1.211s0.606-0.969,2.18-0.848c1.575,0.121,1.211-0.606,1.575-1.817s-0.848-1.332-2.422-2.543
        c-1.575-1.211,1.211-2.543,2.059-3.27s1.211-1.332,2.906-1.695c1.695-0.364,0.606-1.453,0.484-2.301
        c-0.06-0.419-0.149-1.401-0.224-2.286c-0.11-0.02-0.221-0.04-0.337-0.057c-2.431-0.348-4.686-1.214-6.422,0
        s-2.778,2.081-3.819,0.867s1.562-1.039,1.562-2.429c0-1.389,1.217-1.215,1.389-2.603c0.173-1.389,1.908-1.215,1.214-2.603
        c-0.693-1.389-2.083-2.083-3.471-2.083s-1.563-0.523-4.339-1.216c-2.777-0.693-4.339,1.389-4.859-0.174
        c-0.521-1.562,0.521-1.908-0.694-2.255c-1.216-0.348-2.602,0.175-2.43-1.562c0.173-1.736,0.521-1.389,2.603-1.389
        s3.644-0.521,3.644-1.562c0-1.041-2.604-0.519-5.554-0.173s-3.991,0.866-6.074,0.866s-4.167-0.348-5.901,1.562
        c-1.735,1.91-6.248,2.949-8.852,2.082c-2.603-0.867-3.47-1.041-4.859-2.603c-1.389-1.562-5.207-1.387-6.943-1.214
        c-1.736,0.173-0.869,2.431-4.86,1.562c-3.991-0.869-3.124-0.346-5.033-3.297s-3.124-3.994-6.074-4.34
        c-2.951-0.346-4.687,0.695-5.381-1.908s-1.042-3.472-2.256-3.472s-2.257,1.214-1.562,2.603c0.694,1.389,2.258,3.82-0.693,3.992
        c-2.951,0.174-5.9,2.081-6.769,2.429c-0.869,0.348-2.43,2.951-1.562,4.513c0.868,1.562,3.297,3.818,3.297,5.9
        c0,2.083-0.348,3.818-2.082,4.166c-1.735,0.348-2.949,0.175-3.297-0.867c-0.348-1.041-2.431-1.214-2.431-3.645
        s2.603-3.472,2.603-5.554s1.562-2.776,0.175-4.686c-1.388-1.91-2.603-4.511,0-5.38c0.779-0.26,1.463-0.442,2.041-0.616
        c-0.663-0.15-1.542-0.285-2.19-0.152c-1.18,0.244-1.952,0.854-2.44,1.831c-0.488,0.977-2.36,3.214-3.295,4.108
        c-0.935,0.895-3.051,2.888-3.295,3.824c-0.244,0.935-0.732,1.099-0.732,2.685s0.427,2.825-0.702,4.196
        c-1.13,1.373-1.776,2.341-2.341,3.39c-0.566,1.049,0.242,0.888,1.373,0.888s3.956-0.646,4.037,0.404
        c0.08,1.05-1.049,1.05-0.969,2.099s0.404,1.453,1.373,2.018c0.969,0.566,2.18,1.937,1.857,3.23
        c-0.323,1.292-1.211,0.969-1.13,2.826c0.08,1.857-0.08,2.826,1.211,3.552c1.291,0.727,1.857,1.937,2.26,2.584
        c0.404,0.646,1.373,0.566,3.229,0.566s4.763,0.807,5.167-0.081c0.403-0.888,1.857-1.534,2.906-0.727
        c1.049,0.808,1.211,0.808,2.341,0.969c1.13,0.162,1.937,0.162,2.987,1.777c1.049,1.615,2.664,3.633,3.552,4.44
        c0.888,0.808,1.615,0.888,2.019,0.162c0.403-0.727,1.857-0.808,2.906-0.324s4.844,0.162,5.812-0.566
        c0.969-0.727,1.211-0.808,2.341-0.727c1.13,0.08,2.261,0.727,2.261,1.695c0,0.969-0.727,1.777-0.969,2.583
        c-0.242,0.807,0.242,2.098-0.969,2.744s-1.292,0.97-1.05,2.341c0.242,1.373-0.08,2.098-0.161,3.471
        c-0.08,1.373-0.404,3.634,0.888,5.005c1.292,1.373,0.242,2.26,1.615,2.744c1.373,0.484,2.664,1.453,1.049,2.987
        s-3.956,2.664-1.937,3.875s2.503,1.05,2.826,2.987c0.241,1.448,0.436,2.129-0.322,2.549c0.789,0.46,1.648,1.093,1.775,1.729
        c0.242,1.211-0.727,5.207,0.727,5.449s2.18,0.484,3.028,1.575s0.484,1.332,2.18,1.575c1.695,0.242,3.028,0.606,3.39-0.484
        c0.364-1.09,0.727-1.937,1.817-1.211s1.09,1.695,2.422,1.09c1.332-0.606,0.606-2.059,2.301-2.18
        c1.695-0.122,1.09-1.695,3.028-1.695c1.937,0,3.39,0.242,3.633-1.575s0.242-2.664,2.059-3.27s3.148-0.484,3.39-1.575
        s0.969-1.817-1.211-2.059s-2.906,0.122-2.906-1.332s-0.848-2.301-1.575-2.664c-0.727-0.364,0.727-3.512-0.727-5.086
        c-1.453-1.575-2.785-2.301-2.785-3.39s0-1.332,1.695-1.09s3.39-0.122,3.754,1.09c0.364,1.211,1.09,0.364,2.543,0.969
        s2.664-0.606,3.39,0.484c0.727,1.09,0.606,1.695,1.453,1.817s2.18,0.848,2.059-0.727s0.242-2.664,1.332-2.664
        c1.09,0,0.605-0.848,2.543-0.727c1.937,0.122,2.059-1.211,3.996-1.211c1.937,0,1.937-0.364,3.512-1.09s1.937-0.122,2.422-1.332
        c0.484-1.211,1.937-0.848,2.18-2.301c0.086-0.516,0.036-0.983,0.059-1.37C605.442,166.019,605.291,164.98,604.875,163.939z" pointer-events="auto" class="landarea"></path>
</g>
<g id="AREA_GUYANA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Guyana" data-wikipediaLink="https://en.wikipedia.org/wiki/Guyana" data-infoText="<b>Guyana</b> &amp;nbsp; Capital: Georgetown" data-imageFile="GUYANA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M635.995,188.036
        c-0.363-1.453-0.848-1.695-1.695-2.785c-0.848-1.09-1.211-1.817-1.211-3.633s-1.453-2.301-2.059-3.754
        c-0.605-1.453-2.301-1.695-3.028-2.422c-0.727-0.727-0.848-4.843,0-6.539s2.785-1.695,4.601-1.937
        c1.817-0.242,0.484-2.906,0.484-3.875s0.242-1.695,1.453-2.906c0.35-0.35,0.464-0.864,0.453-1.416
        c-3.062-0.976-4.622-2.704-5.608-3.972c-1.216-1.562-2.602-3.124-3.991-3.124c-1.389,0-1.562-2.081-1.562-3.99
        s-4.513-4.34-6.943-5.902c-2.312-1.486-3.055-2.498-5.216-2.892c0.075,0.885,0.165,1.868,0.225,2.286
        c0.122,0.848,1.211,1.937-0.484,2.301s-2.059,0.969-2.906,1.695c-0.848,0.727-3.633,2.059-2.059,3.27
        c1.575,1.211,2.786,1.332,2.422,2.543c-0.363,1.211,0,1.937-1.575,1.817c-1.575-0.122-0.727,0.606-2.18,0.848
        s-2.422,0.364-2.422,1.211c0,0.848,0.727,1.453-0.242,2.664s-2.301,1.817-0.605,3.633c1.695,1.817,2.543,1.575,3.028,2.785
        c0.417,1.04,0.568,2.08,1.754,2.505c0.041-0.703,0.324-1.13,2.121-1.052c2.785,0.122,3.148,1.211,2.906,2.906
        s-2.059,2.422-0.121,2.906c1.937,0.484,1.937,1.09,2.059,1.937c0.121,0.848,2.664,2.18,1.09,3.148
        c-1.574,0.969-2.18,1.453-1.695,2.785c0.484,1.332-0.242,2.543-0.727,3.39c-0.484,0.848-0.364,3.148-0.122,4.239
        c0.242,1.09,0.848,1.332,0.969,2.664s1.09,2.18,2.422,3.512s3.148,3.028,3.633,3.39s2.543,1.332,3.512-0.364
        c0.969-1.695,2.422-2.906,2.785-2.18c0.363,0.727,1.575,0.606,2.422-0.484s2.18-1.575,3.875-1.817s2.422-0.363,3.754-0.363
        c0.719,0,2.635,0.281,4.247,0.294c-0.222-0.577-0.468-1.188-0.978-1.626C637.933,189.005,636.358,189.489,635.995,188.036z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_2_" x="596.366" y="133.773" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="34.152" height="19.609" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_SURINAM" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Suriname" data-wikipediaLink="https://en.wikipedia.org/wiki/Suriname" data-infoText="<b>Suriname</b> &amp;nbsp; Capital: Paramaribo" data-imageFile="SURINAME.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M656.459,184.646
        c1.332-1.09-0.122-2.785-0.122-4.481c0-1.695-0.363-3.996-0.848-5.692c-0.485-1.695-0.727-2.422-1.453-3.39
        c-0.727-0.969,0.121-2.301,0.121-3.754s2.301-2.301,3.39-3.39c0.64-0.64,0.653-2.321,0.555-3.602
        c-1.098-0.495-2.153-0.91-3.03-1.027c-2.603-0.348-4.166-0.867-5.207-0.174c-1.041,0.693-1.041,0.693-3.124,0.174
        c-2.083-0.521-3.297,0.173-4.166,0.693c-0.869,0.521-0.521,0-5.207-0.693c-0.879-0.13-1.668-0.316-2.376-0.541
        c0.011,0.552-0.103,1.065-0.453,1.416c-1.211,1.211-1.453,1.937-1.453,2.906s1.332,3.633-0.484,3.875
        c-1.817,0.242-3.754,0.242-4.601,1.937c-0.848,1.695-0.727,5.812,0,6.539s2.422,0.969,3.028,2.422s2.059,1.937,2.059,3.754
        s0.363,2.543,1.211,3.633s1.332,1.332,1.695,2.785c0.363,1.453,1.937,0.969,2.785,1.695c0.51,0.437,0.756,1.049,0.978,1.626
        c1.378,0.011,2.534-0.174,2.534-0.899c0-1.575,0-3.754,1.817-3.754s3.028,0.242,3.633-0.364c0.606-0.606,3.27-1.211,3.754-0.122
        c0.384,0.865,1.078,2.411,2.858,2.894c0.087-0.316,0.197-0.661,0.29-1.078C654.885,186.946,655.127,185.735,656.459,184.646z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_3_" x="630.518" y="153.177" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="29.25" height="19.609" pointer-events="auto" visibility="hidden"></rect>
    <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" cx="649.254" cy="151.615" r="21.844" pointer-events="auto" opacity="0.001" class="clickarea landarea"></circle>
</g>
<g id="AREA_COLOMBIA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Colombia" data-wikipediaLink="https://en.wikipedia.org/wiki/Colombia" data-infoText="<b>Colombia</b> &amp;nbsp; Capital: Bogotá" data-imageFile="COLOMBIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M472.766,198.813
        c2.059,0.242,3.148,0.484,3.512,1.937c0.364,1.453,1.817,2.543,2.785,2.543c0.969,0,1.332,1.211,3.148,1.211
        c1.817,0,2.664,0.122,2.785-0.848c0.122-0.97,1.937-1.575,2.785-0.364s1.211,1.695,2.422,2.059
        c1.211,0.363,1.211,1.211,3.148,1.211s2.906-1.09,4.239,0.606c1.332,1.695,3.996,2.543,3.996,4.239c0,1.695,0,2.906,1.575,2.906
        s3.27,1.09,3.754,2.422c0.484,1.331,1.453,3.633,2.906,3.754c1.453,0.122,0.848,1.09,1.211,2.664s0.848,1.453,2.543,1.695
        c1.695,0.242,6.781,0.484,6.781-0.606s0.484-1.817,1.817-1.575c1.332,0.242,1.332,0.848,1.817,1.575
        c0.484,0.727,1.575,0.242,1.575-0.242c0-0.484,1.09-0.484,2.422-0.484s2.059,0.848,3.27,1.211c1.211,0.363,3.27,1.211,2.543,2.664
        s-0.605,2.785-2.059,3.754c-1.453,0.969-3.27,3.27-1.937,4.359c1.332,1.09,2.785-0.727,3.27,0.364
        c0.418,0.941,0.928,1.701,2.384,2.593c0.212-0.667,0.159-1.624,0.159-2.472c0-1.453,0.888-2.987,0.888-4.843
        c0-1.857,1.13-5.409,1.13-6.296c0-0.888,0.242-2.341,0.242-3.552s0.242-2.503,0.808-3.633c0.566-1.129,0.565-1.615,0.484-2.987
        s-0.242-1.615-0.727-2.906c-0.484-1.292-2.745-3.148-3.714-3.956s-0.565-3.955-0.323-5.65s4.117-1.05,5.812-1.13
        c1.695-0.081,0.484-0.727,0.646-2.018s-2.664-0.969-4.198-1.211s-0.727-1.695-1.13-4.198c-0.403-2.503,5.49-1.211,8.396-1.13
        s3.23,0,3.23-1.05s1.373-0.727,1.937,0.162c0.565,0.888,2.18,0.727,2.422-0.08s0.484-1.05,2.502-1.453
        c2.018-0.404,1.776-0.969,1.453-2.906s-0.807-1.777-2.826-2.987c-2.019-1.211,0.324-2.341,1.937-3.875s0.323-2.503-1.049-2.987
        c-1.373-0.484-0.323-1.373-1.615-2.744c-1.292-1.373-0.969-3.633-0.888-5.005c0.081-1.373,0.403-2.099,0.161-3.471
        c-0.242-1.373-0.161-1.695,1.05-2.341s0.727-1.937,0.969-2.744s0.969-1.615,0.969-2.584s-1.13-1.615-2.261-1.695
        c-1.13-0.081-1.373,0-2.341,0.727s-4.763,1.05-5.812,0.566s-2.503-0.404-2.906,0.324c-0.403,0.727-1.13,0.646-2.019-0.162
        c-0.888-0.808-2.502-2.826-3.552-4.44c-1.049-1.615-1.857-1.615-2.987-1.777c-1.13-0.162-1.291-0.162-2.341-0.969
        c-1.049-0.808-2.503-0.162-2.906,0.727c-0.404,0.888-3.31,0.081-5.167,0.081s-2.826,0.08-3.229-0.566
        c-0.404-0.646-0.969-1.857-2.26-2.584s-1.13-1.695-1.211-3.552c-0.08-1.857,0.807-1.533,1.13-2.826
        c0.323-1.292-0.888-2.664-1.857-3.23s-1.292-0.969-1.373-2.018c-0.08-1.049,1.049-1.05,0.969-2.099s-2.906-0.404-4.037-0.404
        c-1.13,0-1.937,0.162-1.373-0.888s1.211-2.019,2.341-3.39c1.13-1.373,0.702-2.61,0.702-4.196s0.488-1.75,0.732-2.685
        s2.359-2.929,3.295-3.824c0.935-0.895,2.807-3.133,3.295-4.108c0.488-0.977,1.261-1.586,2.44-1.831
        c0.648-0.133,1.528,0.002,2.19,0.152c1.355-0.407,2.124-0.771,2.124-1.988c0-1.736-2.082-3.298-3.471-3.298
        s-4.167,2.433-5.034,3.82c-0.868,1.387-5.553,3.124-6.596,3.818c-1.041,0.694-0.347,1.91-3.471,1.562s-4.34-0.696-5.034,0.693
        c-0.693,1.389-0.694,3.472-3.124,1.91s-2.603-1.041-4.687,0.521c-2.082,1.562-3.819,3.818-3.124,5.9
        c0.694,2.082,1.214,4.513-1.041,5.034c-2.255,0.521-3.123,2.085-4.339,3.472s-2.951,2.601-2.951,3.99s-1.736,4.339-3.124,1.562
        c-0.642-1.284-1.286-2.382-2.083-3.364c-0.153,0.418-0.287,0.912-0.266,1.319c0.047,0.894-0.565,0.848-0.188,1.741
        s-0.094,1.364,0.706,1.552s0.847,1.082,0.847,1.506s0.188,1.458-0.518,2.07s-1.835,1.882-2.211,1.129s-1.365-1.552-1.412-0.893
        s0.094,2.587-0.141,3.058c-0.09,0.179-0.208,0.318-0.376,0.423c0.139,0.215,0.295,0.457,0.479,0.745
        c1.172,1.823,1.953,2.342,2.474,3.71c0.521,1.367,0.585,3.124,0.585,3.839s-0.13,1.237,0.39,1.628s0.716,1.107,0.13,1.758
        c-0.585,0.651-1.562,0.977-1.236,2.342c0.326,1.367,0.456,2.538,1.107,3.905s0.521,1.497,0.39,3.58s-1.757,2.213-0.846,3.515
        c0.911,1.302,2.213,1.496,1.367,2.929c-0.846,1.431-1.497,2.147-2.213,3.775s-1.823,3.515-3.319,4.231
        c-1.497,0.716-4.361,1.107-4.946,2.083c-0.585,0.977,0.781,2.408-1.106,3.385s-2.474,1.367-2.148,2.408
        c0.132,0.424,0.36,1.075,0.467,1.714c0.815,0.069,1.857,0.293,2.458,0.961C469.255,196.996,470.708,198.57,472.766,198.813z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_4_" x="462.043" y="165.259" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="69.699" height="19.609" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_ECUADOR" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Ecuador" data-wikipediaLink="https://en.wikipedia.org/wiki/Ecuador" data-infoText="<b>Ecuador</b> &amp;nbsp; Capital: Quito" data-imageFile="ECUADOR.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M455.572,232.597
        c0.848,0.484,1.453,3.512,0,3.875s-2.18,0.727-1.695,1.817c0.484,1.09,0.727,4.117,1.211,2.785
        c0.484-1.331,1.332-1.817,2.543-1.575s2.543,0.364,3.27,1.575s0.727,2.664,1.695,3.028c0.969,0.363,2.422,1.937,2.906,0.242
        c0.484-1.695,0.242-2.059,1.332-2.301c1.09-0.242,1.211-0.364,1.575-1.817s-0.364-2.664,0.727-4.481
        c1.09-1.817,3.028-6.539,6.176-7.265s5.449-1.211,7.507-2.059c2.059-0.848,8.84-6.054,9.324-8.718s0.605-3.633,1.211-4.117
        c0.606-0.484,1.09-0.484,1.09-1.817c0-1.332,1.211-0.727-0.727-2.18c-1.386-1.039-2.027-2.266-2.28-3.544
        c-0.361-0.246-0.663-0.524-1.232-0.694c-1.211-0.364-1.575-0.848-2.422-2.059c-0.848-1.211-2.664-0.606-2.785,0.364
        c-0.122,0.97-0.969,0.848-2.785,0.848c-1.817,0-2.18-1.211-3.148-1.211c-0.969,0-2.422-1.09-2.785-2.543
        c-0.364-1.453-1.453-1.695-3.512-1.937s-3.512-1.817-4.601-3.028c-0.601-0.668-1.643-0.893-2.458-0.961
        c0.156,0.931,0.054,1.841-0.987,1.995c-1.757,0.261-2.603,0.326-3.189,0.977c-0.585,0.651-0.846,0.586-2.343,0.846
        c-1.497,0.26-2.474,0.781-2.474,2.408c0,1.628,1.562,4.036-0.13,4.882c-1.692,0.846-2.278,0.912-2.278,2.082
        c0,1.172-0.065,2.929-1.236,3.58c-1.172,0.651-3.385,0.846-3.124,2.799c0.261,1.952-0.065,3.905,0.977,4.556
        c1.041,0.651,1.562,1.823,0.521,2.343c-1.041,0.521-1.692,1.562-0.326,2.213s1.692,0.977,2.343,1.887
        c0.651,0.911,1.628,2.018,2.538,1.171c0.911-0.846,1.497-1.302,1.692-2.408c0.195-1.106,0.911-1.302,1.041-0.326
        c0.13,0.977,2.148,2.018,0.521,2.734s-2.343,1.172-1.562,1.692c0.781,0.521,1.823,1.692-0.13,2.278
        c-0.911,0.274-1.963,0.534-2.959,0.925C454.852,231.907,455.186,232.376,455.572,232.597z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_5_" x="432.252" y="210.165" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="69.699" height="19.609" pointer-events="auto" visibility="hidden"></rect>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M371.484,205.77c0,0-1.725-1.692-2.213-1.041
        s-0.39,1.367-0.032,1.628c0.358,0.26,0.781,0.651,0,0.878c-0.781,0.228-1.172,0.195-1.172,0.943c0,0.749,0.358,1.172,0.846,1.172
        s0.781-0.26,0.911-0.585c0.13-0.326,1.139-0.521,1.107,0.097c-0.032,0.619-0.098,1.302-0.553,1.594
        c-0.456,0.293-1.041,0.521-1.172,1.237c-0.13,0.716-0.032,1.431,0.619,1.594s1.204,0.358,1.204,0.846s0.911,0.585,1.107,0.065
        c0.195-0.521,0.748-0.683,1.399-0.78s0.651-0.683,0.911-1.465c0.261-0.78,0.228-1.334-0.488-1.53
        c-0.716-0.195-1.041-0.39-0.943-1.4c0.098-1.009-0.456-1.204-0.619-1.497S372.07,206.224,371.484,205.77z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M377.569,208.893
        c0,0-0.163,1.009-0.748,1.302c-0.586,0.293-1.009,0.553-0.553,0.943c0.456,0.391,0.846,1.204,1.594,1.106
        c0.748-0.097,1.107-0.293,1.139-0.977C379.034,210.586,377.993,206.876,377.569,208.893z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M384.403,211.335
        c0,0-0.878,0.651-0.814,0.977c0.065,0.326,0.521,0.911,1.074,0.878c0.553-0.032,1.106-0.228,1.172-0.846
        C385.9,211.725,384.762,210.813,384.403,211.335z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M375.129,206.062c0,0-0.39,2.278,0.227,2.278
        c0.619,0,0.619,0.066,0.619-0.846C375.975,206.582,375.389,205.281,375.129,206.062z" pointer-events="auto" class="landarea"></path>
</g>
<g id="AREA_URUGUAY" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Uruguay" data-wikipediaLink="https://en.wikipedia.org/wiki/Uruguay" data-infoText="<b>Uruguay</b> &amp;nbsp; Capital: Montevideo" data-imageFile="URUGUAY.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M661.666,469.69
        c-0.242-1.817-0.242-4.723-0.848-6.539c-0.606-1.817-2.18-1.575-3.633-2.18c-1.453-0.606-1.817-1.09-2.543-2.785
        c-0.727-1.695-1.332-1.937-3.633-2.059c-2.3-0.122-2.906-1.09-3.996-2.906c-1.09-1.817-0.969-0.848-2.18,0.484
        s-2.059-0.969-2.422-3.028c-0.363-2.059-2.18-1.211-2.543-2.664c-0.363-1.453-0.969-1.453-3.028-2.18s-0.727,0-3.27,1.09
        c-1.276,0.547-2.337,0.301-3.079-0.079c-1.187,0.934-2.072,2.141-1.885,3.349c0.242,1.575,1.211,2.422,0.121,4.117
        s-0.242,4.601-1.332,5.812c-1.09,1.211-1.695,2.059-1.211,4.238c0.484,2.18,1.575,3.754,0.606,5.207s-0.848,2.543-0.727,3.996
        c0.121,1.453-0.242,2.906,0.727,3.996c0.913,1.027,2.576,3.02,2.665,5.373c1.543,0.41,2.638,0.416,4.094,0.416
        c1.736,0,2.256,0.173,3.819,1.041c1.562,0.869,2.777,1.387,5.553,1.563c2.778,0.175,3.125-0.348,5.381,0
        c2.255,0.348,3.124,1.216,6.248-0.346c3.124-1.562,6.074-4.861,8.678-7.464c0.094-0.094,0.196-0.19,0.295-0.285
        c-0.553-1.115-0.888-2.322-1.376-3.688C661.545,472.475,661.908,471.507,661.666,469.69z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_7_" x="613.442" y="461.99" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="69.699" height="24.5" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_BOLIVIA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Bolivia" data-wikipediaLink="https://en.wikipedia.org/wiki/Bolivia" data-infoText="<b>Bolivia</b> &amp;nbsp; Capital: Sucre (La Paz is the seat of government)" data-imageFile="BOLIVIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M593.977,374.837
        c0.646-0.727,0.646-0.97,0.727-3.068c0.081-2.099-0.161-5.005,1.373-6.296c1.533-1.291,1.453-4.521,3.229-5.328
        c1.777-0.807,1.534-1.291,3.714-1.373c2.18-0.08,5.005-0.323,5.974-1.05s1.777-1.373,4.279-1.13c2.502,0.242,6.539,0,7.427,0.888
        s3.39,1.777,4.601,3.23c0.863,1.035,1.807,1.087,2.834,1.119c-0.092-0.428-0.492-0.741-0.675-1.361
        c-0.303-1.029,0.121-2.483,0.969-3.451c0.848-0.969,1.029-1.332,0.969-2.24s0.424-2.603,0.424-3.814s1.272-1.514,1.453-2.543
        s-0.545-1.998-1.211-2.483c-0.666-0.484-0.424-0.848-0.424-2.119s-1.634-1.756-2.725-2.119c-1.09-0.364-1.998-0.666-2.18-1.15
        c-0.181-0.484-0.848-1.756-0.908-2.603c-0.061-0.848-0.181-1.575,0.545-2.301c0.727-0.727,0.122-2.785,0.122-2.785l-14.168,0.061
        c0,0,0.061-4.117,0.061-5.994c0.424-1.634-0.787-1.272-1.634-2.24c-0.848-0.969-0.424-1.453,0.605-2.059s0.666-1.695,0.181-2.543
        s0.364-1.211-0.484-2.119s-0.303-1.453-0.181-2.725c0.122-1.272-0.848-2.18-1.817-3.209c-0.969-1.029-3.148-0.666-5.025-0.666
        s-4.359-0.242-4.904-1.514s-3.27-2.24-4.723-2.361c-1.453-0.122-2.18-0.545-2.906-1.937c-0.727-1.392-2.845-0.787-3.875-1.937
        c-1.029-1.15-3.633-0.424-5.207-0.545c-1.575-0.122-2.664-0.303-3.028-1.211c-0.363-0.908-1.332-1.634-2.361-2.18
        c-1.029-0.545-1.877-1.756-2.301-2.845c-0.424-1.09-1.029-1.695-1.817-2.603c-0.787-0.908-0.484-1.937-0.363-3.028
        c0.121-1.09-0.061-1.695-0.545-2.785s0.242-1.09,0.181-1.695c-0.061-0.606,0.181-1.332,0.908-2.059s0.181-1.877-0.122-2.785
        c-0.303-0.908-1.15-1.332-1.695-0.727c-0.545,0.606-2.422,0.424-3.451,0.424s-2.543,0.122-3.148,0.848
        c-0.605,0.727-2.785,0.787-3.33,1.575c-0.545,0.787-1.211,1.392-2.603,1.695s-2.361,0.908-3.028,1.998s-2.119,0.787-3.39,1.15
        c-1.272,0.364-2.361,1.332-2.725,2.543c-0.363,1.211-2.483,1.029-2.967,0.242s0.181,0.061-3.148-0.545
        c-1.321-0.24-2.161-0.241-2.699-0.156c0.403,0.256,0.732,0.768,0.862,1.811c0.323,2.583,0.888,2.583,1.937,3.713
        s1.211,1.938,1.937,2.826c0.727,0.888,2.584,2.664,1.373,4.359s-1.615,1.615-1.695,3.39s-0.162,2.664-0.727,3.471
        c-0.565,0.808-0.323,2.987,0.324,3.794s1.533,1.211,0.646,2.664s-1.937,2.098-2.744,3.229c-0.807,1.13-1.695,2.18-0.807,2.745
        c0.887,0.566,1.291,2.181,0.403,3.23s-0.888,2.583-0.323,3.713s0.727,1.937,1.937,2.745c1.211,0.808,0.969,3.472-0.323,4.359
        c-1.292,0.888-3.633,2.906-3.39,3.875c0.242,0.969-0.242,2.18,0.888,2.906c1.13,0.727,1.211,2.907,2.261,3.23
        c1.049,0.323,1.373,1.857,1.13,3.552c-0.242,1.695,0.162,3.956,1.777,4.925c1.614,0.969,2.744,1.615,2.341,2.906
        c-0.403,1.291-1.373,2.098-0.807,2.986c0.565,0.888,0.242,1.938-0.404,2.341s-1.049,1.857-0.08,3.068s0.727,3.875,1.937,4.521
        s1.615,2.099,1.937,4.037c0.323,1.937,1.534,3.39,2.018,5.893s-0.242,4.439,1.695,4.843c1.349,0.281,3.244-0.53,4.19-0.288
        c0.712-1.279,1.064-1.578,2.027-3.022c1.211-1.817,2.906-1.817,4.239-4.359c1.332-2.542,2.301-0.484,3.875,0.484
        c1.575,0.969,3.996,0.848,5.934,0.605c1.937-0.242,3.028,0.606,3.512,1.453c0.484,0.848,1.575,2.301,2.059,5.086
        s1.453-1.817,1.453-2.906c0-1.089,1.332-3.148,2.422-3.996s4.601-0.484,7.387-0.242c0.919,0.08,1.531,0.4,1.991,0.832
        c0.195-0.666,0.427-1.501,0.551-2.125C592.766,376.37,593.331,375.563,593.977,374.837z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_8_" x="543.743" y="330.816" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="69.699" height="24.5" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_PERU" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Peru" data-wikipediaLink="https://en.wikipedia.org/wiki/Peru" data-infoText="<b>Peru</b> &amp;nbsp; Capital: Lima" data-imageFile="PERU.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M535.941,348.541
        c0.648-0.948,0.948-1.547,0.798-2.743c-0.15-1.197,0.199-1.945,0.748-2.294c0.296-0.188,0.624-0.318,0.985-0.437
        c-0.307-0.68-0.102-1.511-0.277-2.215c-0.242-0.969,2.099-2.987,3.39-3.875c1.292-0.888,1.534-3.552,0.323-4.359
        c-1.211-0.808-1.373-1.615-1.937-2.745c-0.565-1.13-0.565-2.664,0.323-3.713s0.484-2.664-0.403-3.23
        c-0.888-0.566,0-1.615,0.807-2.745s1.857-1.776,2.744-3.229s0-1.857-0.646-2.664s-0.888-2.987-0.324-3.794
        c0.565-0.808,0.646-1.695,0.727-3.471c0.08-1.776,0.484-1.695,1.695-3.39s-0.646-3.472-1.373-4.359
        c-0.727-0.888-0.888-1.695-1.937-2.826s-1.615-1.13-1.937-3.713c-0.323-2.583-1.857-1.937-2.745-2.099s-2.341-0.323-2.986,0.727
        c-0.646,1.049-1.937,0.242-2.906-0.323s-1.291-1.937-1.291-3.472c0-1.534,0.646-4.117,0.565-5.247
        c-0.08-1.13,1.049-2.019,0.969-3.23s-2.744,1.13-3.471,2.18s-1.534,1.05-3.633,2.019c-2.098,0.969-3.552,0.404-5.167,0.404
        s-1.211-1.937-1.533-3.31s-2.826-1.615-4.279-1.777c-1.453-0.162-1.776-1.13-1.533-2.261s-0.323-1.291-1.13-1.857
        c-0.808-0.566-0.807-1.534-1.857-2.664c-1.049-1.13-1.777-3.068-2.583-4.198s-0.484-1.13-1.857-2.26
        c-1.373-1.129,0.323-1.937,1.453-2.503c1.13-0.566,0.162-1.292-0.162-2.261c-0.324-0.969,1.13-1.937,1.937-2.906
        c0.808-0.969,1.695-0.807,2.744-1.291c1.049-0.484,0.565-1.534-0.162-2.583s0.323-1.776,1.13-2.744
        c0.808-0.969,1.211-3.068,0.888-3.956s0.727-1.534,1.776-2.019c1.049-0.484,3.229-1.776,4.843-3.471
        c1.615-1.695,3.39-1.13,5.408-1.211c2.018-0.08,3.068-0.888,4.117-1.777c1.049-0.888,1.695-0.646,2.664-0.242
        c0.969,0.404,2.341,0.969,3.633,0.888c0.537-0.033,0.821-0.363,0.972-0.837c-1.456-0.891-1.966-1.652-2.384-2.593
        c-0.484-1.09-1.937,0.727-3.27-0.364c-1.332-1.09,0.484-3.39,1.937-4.359s1.332-2.301,2.059-3.754
        c0.727-1.453-1.332-2.301-2.543-2.664c-1.211-0.364-1.937-1.211-3.27-1.211s-2.422,0-2.422,0.484s-1.09,0.969-1.575,0.242
        c-0.484-0.727-0.484-1.332-1.817-1.575c-1.332-0.242-1.817,0.484-1.817,1.575s-5.086,0.848-6.781,0.606s-2.18-0.122-2.543-1.695
        c-0.364-1.574,0.242-2.543-1.211-2.664c-1.453-0.122-2.422-2.422-2.906-3.754s-2.18-2.422-3.754-2.422s-1.575-1.211-1.575-2.906
        s-2.664-2.543-3.996-4.239c-1.332-1.695-2.301-0.606-4.239-0.606c-1.028,0-1.509-0.239-1.917-0.517
        c0.253,1.279,0.893,2.504,2.28,3.544c1.937,1.453,0.727,0.848,0.727,2.18c0,1.331-0.484,1.332-1.09,1.817
        c-0.606,0.484-0.727,1.453-1.211,4.117s-7.265,7.871-9.324,8.718c-2.059,0.848-4.359,1.332-7.507,2.059
        c-3.148,0.727-5.086,5.449-6.176,7.265c-1.09,1.817-0.364,3.028-0.727,4.481c-0.364,1.453-0.484,1.575-1.575,1.817
        c-1.09,0.242-0.848,0.606-1.332,2.301c-0.484,1.695-1.937,0.122-2.906-0.242s-0.969-1.817-1.695-3.028
        c-0.727-1.211-2.059-1.332-3.27-1.575c-1.211-0.242-2.059,0.242-2.543,1.575s-0.727-1.695-1.211-2.785s0.242-1.453,1.695-1.817
        s0.848-3.39,0-3.875c-0.385-0.221-0.72-0.69-0.971-1.137c-1.138,0.446-2.204,1.063-2.898,2.07c-1.302,1.887-1.302,2.798-2.864,3.97
        s-1.692,2.603-1.367,4.231c0.326,1.628,0.456,1.823,1.236,3.645c0.781,1.823,1.628,1.757,1.692,2.993
        c0.065,1.237,0.13,1.953-0.977,2.538c-1.107,0.585-1.823,1.692,0.586,2.474c2.408,0.781,2.994,0.977,4.686,2.342
        c1.692,1.367,4.751,4.426,5.988,6.834c1.236,2.408,2.733,3.84,4.1,5.597s1.367,1.823,2.603,5.662s3.385,5.142,4.1,8.005
        c0.716,2.864,1.887,5.011,3.449,6.898s2.213,2.864,2.278,4.687c0.065,1.822-1.041,3.515,1.172,5.011
        c2.213,1.497,2.538,1.236,4.556,3.839s1.823,3.58,2.929,4.751c1.106,1.172,2.669,2.603,1.757,3.71
        c-0.911,1.106-1.952,1.302-1.562,2.929c0.391,1.628,1.302,3.254,3.124,4.491c1.823,1.236,3.905,3.059,4.49,4.686
        c0.586,1.628,1.823,2.864,3.059,3.449s2.734,0.977,4.556,2.473c1.823,1.497,3.905,2.929,6.639,3.84s4.816,1.952,6.834,3.059
        c2.018,1.106,3.385,1.757,5.142,2.864c1.757,1.107,3.775,1.497,4.296,3.449s1.106,2.213,2.798,3.059
        c1.6,0.8,3.258,1.08,4.425,3.861c0.468,0.017,1.021,0.092,1.518,0.308C534.095,349.688,535.293,349.489,535.941,348.541z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_9_" x="437.844" y="280.195" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="69.699" height="19.609" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_ARGENTINA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Argentina" data-wikipediaLink="https://en.wikipedia.org/wiki/Argentina" data-infoText="<b>Argentina</b> &amp;nbsp; Capital: Buenos Aires" data-imageFile="ARGENTIN.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <rect id="rect12_10_" x="554.172" y="429.678" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="69.699" height="24.5" pointer-events="auto" visibility="hidden"></rect>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M573.673,675.655
        c-1.562-0.651-3.905-0.651-6.118-0.521c-2.213,0.13-3.385-1.432-6.379-3.385c-2.994-1.952-9.112-6.769-10.023-8.201
        c-0.911-1.431-1.041-2.213-2.213-2.213s-2.083-2.083-0.781-2.083s0.651-3.254-0.521-3.775c-0.666-0.295-0.828-0.717-1.129-1.002
        v22.842c0,0,1.776,1.695,2.906,1.049s2.18-0.161,3.31,0.324c1.13,0.484,3.068,0.728,3.794,0.081c0.727-0.646,2.099-1.292,3.23,0.08
        c1.13,1.373,2.826,1.777,3.31,2.099c0.357,0.238,0.714,0.652,1.038,1.144c0.046-0.117,0.073-0.264,0.073-0.452
        c0-1.302-0.521-2.213,1.562-2.213s6.769,0.261,7.029-0.911C573.021,677.349,575.234,676.306,573.673,675.655z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M626.792,477.561
        c-0.969-1.09-0.606-2.543-0.727-3.996c-0.122-1.453-0.242-2.543,0.727-3.996s-0.122-3.028-0.606-5.207
        c-0.484-2.18,0.122-3.028,1.211-4.239c1.09-1.211,0.242-4.117,1.332-5.812c1.09-1.695,0.122-2.543-0.121-4.117
        c-0.242-1.574,1.332-3.148,3.028-4.117c1.695-0.969,1.211-2.422,3.148-3.27s1.817-2.059,3.148-4.117
        c1.332-2.059,2.785-2.18,3.996-3.996c1.211-1.817,1.937-2.18,3.512-4.481s3.028-2.422,4.723-3.754s3.148-3.27,7.629-4.239
        c4.481-0.969,2.664-2.785,2.543-4.481c-0.122-1.695,0.121-2.301,0.969-3.27s0.242-1.937-0.242-2.543s-0.969-1.695-0.969-2.785
        s-1.332-1.453-3.27-1.453c-1,0-2.095-0.065-2.923-0.126c-0.061,0.756-0.105,1.555-0.105,2.79c0,2.059,0.727,5.207-1.817,7.023
        c-2.543,1.817-5.328,1.817-5.449,3.633c-0.121,1.817-1.211,2.422-2.18,2.059c-0.969-0.364-2.059-1.332-3.39-0.122
        c-1.332,1.211-1.575,1.332-3.754,1.09c-2.18-0.242-3.875-0.122-5.57-0.606c-1.695-0.484-2.543-0.848-5.328-0.848
        s-5.086,0-2.422-2.422s2.543-3.028,2.664-5.692c0.121-2.664,0.363-2.18,1.695-3.148c1.332-0.969,1.211-0.969,1.817-2.664
        c0.605-1.695,0.727-2.18-1.09-3.875s-6.054-1.817-8.598-3.512c-2.543-1.695-6.297-6.176-9.203-6.054s-5.812-0.242-6.66-1.937
        c-0.848-1.695-1.937-2.543-3.512-3.148s-2.543-1.453-3.754-3.27s-1.817-2.906-3.148-4.117c-1.332-1.211-1.332-3.633-4.117-3.875
        c-2.784-0.242-6.297-0.606-7.387,0.242s-2.422,2.906-2.422,3.996s-0.969,5.692-1.453,2.906c-0.484-2.785-1.575-4.239-2.059-5.086
        c-0.484-0.848-1.575-1.695-3.512-1.453s-4.359,0.364-5.934-0.605c-1.575-0.969-2.543-3.028-3.875-0.484s-3.028,2.543-4.239,4.359
        c-0.963,1.444-1.315,1.743-2.027,3.022c0.413,0.106,0.647,0.409,0.574,1.095c-0.242,2.261-0.08,3.148-0.565,3.875
        c-0.484,0.727-0.646,1.534-0.646,2.503s-0.242,1.856-1.615,2.26s-3.633,1.292-4.763,2.341s-2.906,1.776-3.23,2.26
        c-0.323,0.484-1.291,1.211-0.161,2.503s0.646,2.261-0.081,2.987c-0.727,0.727-0.646,2.422-0.08,3.552
        c0.565,1.13,0.969,2.341,0.969,2.987s-1.453,0.969-1.453,2.422s0.484,1.857,1.291,2.422c0.808,0.565,1.615,1.373,0.969,2.422
        s-1.292,1.534-2.422,1.453s-2.422,1.211-2.502,2.26c-0.08,1.05-0.242,2.18-1.13,3.472s-1.695,2.26-2.744,3.068
        c-1.049,0.808-2.018,1.615-1.937,3.148c0.08,1.534,0.484,2.826-0.727,3.956s-1.937,1.695-1.695,3.068s1.453,1.453,1.049,2.906
        s-0.323,2.261,0.08,2.826c0.404,0.565,0.404,2.26-0.727,2.583c-1.13,0.323-1.777,0.323-2.099,2.019
        c-0.323,1.695-0.242,2.986-0.969,3.713c-0.727,0.727-1.615,1.937-1.695,2.987s-0.646,1.615,0.323,3.23s1.373,1.05,1.453,1.937
        c0.081,0.888,0.484,1.937,0.646,2.826c0.162,0.888-0.081,2.341,1.049,3.23s1.13,1.211,0.484,2.26
        c-0.646,1.05-0.807,2.26,0.566,2.906s2.422,0.323,1.695,1.937c-0.727,1.615,0,2.099-0.484,3.875
        c-0.484,1.777-1.13,2.099-1.857,3.472s-1.373,2.986-1.776,4.037c-0.404,1.05-1.373,2.18-1.373,3.39
        c0,1.211-0.404,2.745,0.484,3.068s0.727,1.695,0.727,2.664s-0.484,2.099-1.453,2.664s-1.13,1.776-2.583,2.26
        s-1.937,2.018-2.018,2.906c-0.08,0.888-0.404,1.696,0,2.584s-0.727,5.893,0.323,7.427s0.888,2.665,0.727,3.23
        c-0.162,0.565,1.937,2.098,0.888,3.471s-1.695,0.565-2.906,1.453s-1.776,2.744-1.291,4.117c0.484,1.373,0.08,2.503-1.05,3.068
        s-0.969,1.534-0.969,2.906s0.08,4.359-0.646,5.408c-0.727,1.05-0.808,2.664-0.808,3.23s0.162,1.777,0.08,3.39
        c-0.081,1.614-0.242,4.601,0.242,5.328s0.727,0.808,0.727,1.695s0.646,1.211-0.324,1.695c-0.969,0.484-2.26,0.969-2.26,1.695
        s-0.484,6.297,0.646,6.862s1.373,1.211,1.049,2.502c-0.323,1.292,1.211,1.938,0.888,3.31c-0.324,1.373,1.13,2.18,0.242,3.552
        c-0.888,1.373-0.969,3.068,0.565,3.23c1.533,0.161,4.037-0.081,3.955,1.291c-0.08,1.373-1.695,2.341-3.068,2.341
        s-3.714-1.13-3.714-0.404c0,0.727-0.08,1.453,1.05,1.695s2.341,0.324,2.986,1.615c0.646,1.291,2.744,1.776,1.049,2.986
        c-1.695,1.211-2.986,1.534-3.068,2.664s2.099,2.341,0.727,3.148c-1.373,0.808-2.664,1.615-0.808,2.745s0.727,1.695,0.646,3.148
        c-0.08,1.453-1.857,0.484-1.857,2.422s1.13,3.713-0.484,4.521c-1.615,0.808-2.261,0.566-2.422,1.857
        c-0.162,1.291-1.13,1.614-1.291,2.987s0.484,0.808,0.969,2.019c0.484,1.211,0.969,1.937,0.484,3.068
        c-0.484,1.13-2.341,0.97-2.18,2.341c0.162,1.373,0.081,2.664-1.291,3.713c-1.373,1.049-1.857,1.453-2.664,2.422
        c-0.808,0.969-2.745,0.404-3.068,1.777s-0.484,7.103,0.08,7.911c0.566,0.807,1.857,0.807,1.615,2.261
        c-0.242,1.453,0.242,2.826,1.13,3.23c0.888,0.403,1.695,0.646,2.18,0s0.727-1.049,2.18-0.727s2.18,0.646,2.18,1.13
        s1.533,1.13,0.646,2.099c-0.888,0.969-0.888,1.373-0.888,2.745s-1.13,3.391,0,4.44s2.584,1.129,2.986,2.502
        c0.404,1.373,2.18,1.373,3.39,1.373c1.211,0,4.843,0,7.75,0c2.906,0,6.054-0.081,7.023,0.565s3.148-0.162,4.359,0.484
        s2.341,0.727,3.552,1.534c0.708,0.472,1.272,1.049,1.623,1.452c0.6-0.563,0.511-2.336-0.573-3.528
        c-1.302-1.431-4.751-7.941-4.882-9.762c-0.13-1.823-0.456-5.662,0.977-6.898c1.431-1.237,2.864-1.887,3.839-2.799
        c0.977-0.912,5.597,0.456,5.857-3.32c0.261-3.775,1.172-9.177,3.385-10.349s4.556-3.71,6.769-5.077
        c2.213-1.367,4.816-2.734,4.686-3.775s-1.236-0.326-0.716-1.953c0.521-1.628,1.302-1.887,0.977-3.58
        c-0.326-1.692,0.521-2.993-1.887-3.125c-2.408-0.13-5.142,0.195-6.444-1.431c-1.302-1.628-1.367-2.408-2.929-3.189
        s-2.343-0.846-2.603-3.059c-0.261-2.213,0.195-4.556,1.562-5.597s2.474-4.231,4.816-4.946c2.343-0.716,3.319-2.668,4.946-2.408
        c1.628,0.261,3.385,1.106,3.515-0.716c0.13-1.823,0.13-2.734,1.367-3.515c1.236-0.781,1.757-2.083,1.757-3.97
        s-2.083-3.971,0.066-5.143c2.147-1.172,0.911-3.189,2.538-3.515c1.628-0.326,3.385-0.066,3.515-1.041
        c0.13-0.977-0.195-1.041-1.367-1.628c-1.172-0.585-2.408-1.041-2.213-1.823c0.195-0.781,1.041-0.39,1.432-1.302
        c0.39-0.911,2.343-0.13,2.734,0.586c0.39,0.716,0.456,2.343,2.669,2.148s2.994-0.13,3.059-1.628s0.13-2.603-0.261-3.515
        c-0.39-0.911-0.261-1.887-1.823-1.953c-1.562-0.065-2.278,0.651-2.278,1.563c0,0.911-0.456,2.213-1.628,1.041
        s-0.977-1.823-2.018-1.823s-3.124-0.26-3.254-2.018s1.432-3.515,0.781-4.361s-1.367-0.912-1.236-2.864
        c0.13-1.953-0.391-2.929,0.977-3.189c1.367-0.261,3.254-0.261,3.905,0.585s0.846,0.78,1.823,0.977s1.823,0.456,2.213,1.041
        s1.757,0.585,3.449,0.781c1.692,0.195,2.408-0.846,3.905-0.846s6.313-2.018,6.052-3.905s-1.887-2.538-1.887-3.644
        c0-1.107,0.911-2.083,1.692-2.994s1.106-2.929,1.172-3.775s1.172-1.172-0.39-2.018s-1.823-0.977-1.367-2.278
        c0.456-1.302,1.757-2.538,1.952-0.912c0.195,1.628,1.302,1.823,1.367,0.912c0.065-0.912,1.172-1.107,1.823-0.456
        s2.603,0.651,4.036,0.326c1.432-0.326,3.472-1.647,7.463-1.647s5.9-1.734,8.678-2.082c2.778-0.348,10.933-3.818,11.802-6.769
        s3.472-4.168,4.86-6.596c1.388-2.429,2.257-4.337,1.562-6.073c-0.694-1.736-3.991-0.867-4.687-3.644
        c-0.694-2.779-1.214-1.737,0.174-3.473c1.388-1.734,0.868-3.299-0.694-4.513s-3.298-3.295-5.207-3.47s-3.818,0.519-3.471-1.216
        c0.347-1.735,1.562-1.908,3.644-1.215c0.335,0.112,0.642,0.2,0.938,0.278C629.367,480.581,627.705,478.588,626.792,477.561z" pointer-events="auto" class="landarea"></path>
</g>
<g id="AREA_CHILE" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Chile" data-wikipediaLink="https://en.wikipedia.org/wiki/Chile" data-infoText="<b>Chile</b> &amp;nbsp; Capital: Santiago de Chile" data-imageFile="CHILE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <rect id="rect12_11_" x="472.693" y="469.526" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="69.699" height="24.5" pointer-events="auto" visibility="hidden"></rect>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M503.893,574.274
        c0,0-2.156-0.218-2.641,0.269c-0.485,0.485-1.832,1.346-0.539,1.454s1.563,0.378,1.617,1.133c0.054,0.754,0.809,1.132,1.293,1.293
        c0.485,0.161-0.054,1.401,0.646,1.617c0.701,0.216,1.455,0.592,0.647,1.239c-0.808,0.647-0.754,1.187-0.647,1.995
        c0.108,0.808,0.485,1.564,0.701,0.431c0.216-1.132,0.484-2.048,1.292-2.372c0.809-0.323,0.971-0.483,0.971-1.401
        s-0.27-1.887-0.701-2.319c-0.431-0.431-0.593-1.347,0.431-1.347s1.024-0.108,1.132-0.97c0.108-0.863-1.078-1.564-1.509-1.078
        s-0.217,1.885-0.97,1.401C504.864,575.136,505.134,574.329,503.893,574.274z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M504.918,567.052c0,0-0.109,1.94,0.538,2.048
        s0.539-0.326,0.485-0.918C505.887,567.591,505.564,565.597,504.918,567.052z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M507.289,568.291
        c0,0-0.162,0.376-0.162,1.293c0,0.917,0.701,1.401,0.808,0.431C508.043,569.046,507.773,566.782,507.289,568.291z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M504.217,570.339c0,0-0.485,1.511,0.054,1.78
        s0.755-0.11,0.809-0.594C505.134,571.04,504.594,569.098,504.217,570.339z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M495.002,628.712
        c0,0-0.916,1.292-1.131,1.993c-0.216,0.702-0.593,1.833-0.216,2.911c0.378,1.078,1.401,3.071,1.617,1.725s0.054-1.778,0.539-2.317
        c0.485-0.538,0.215-1.564,1.292-1.564s1.671-0.81,1.779-1.617c0.108-0.808-0.486-1.076-1.509-1.238
        C496.349,628.442,495.54,627.957,495.002,628.712z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M499.905,640.354
        c0,0-0.378,0.915-1.617,0.915s-2.586-0.376-2.856,0.271c-0.27,0.646-1.239,2.426-0.324,2.586c0.916,0.161,0.486-0.541,1.455-0.701
        c0.97-0.161,2.64-0.594,2.748-1.078c0.108-0.484,1.455-0.753,1.563-1.563C500.983,639.976,500.282,639.332,499.905,640.354z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M498.827,645.206
        c0,0-1.778-0.324-2.209,0.324c-0.431,0.646-0.323,1.24,0.27,2.156c0.592,0.915,0.484,1.723,2.155,1.723s3.18-1.129,1.617-1.831
        C499.097,646.875,499.851,645.206,498.827,645.206z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M504.217,649.518
        c0,0-2.156-0.755-2.048,0.216c0.108,0.97,1.293,0.753,1.671,1.778c0.377,1.025,1.024,1.238,1.239,0.647
        c0.216-0.592,1.238-0.647,1.238-1.185c0-0.539,0.971-1.077,0.755-1.723c-0.216-0.647-1.131-1.67-1.293-0.755
        s0.538,1.938-0.324,1.938S504.972,649.733,504.217,649.518z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M504.325,657.765
        c0,0-4.204-2.211-4.204-1.026s0.485,1.672,1.671,1.995c1.185,0.324,2.748,0.377,3.664,0.916c0.916,0.538,1.725,1.027,2.749,1.456
        s3.503,1.615,3.665,1.076c0.162-0.538-0.324-1.454-1.779-1.885c-1.455-0.43-3.018-0.97-3.503-1.509
        S505.888,658.303,504.325,657.765z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M563.058,680.951
        c-0.484-0.323-2.18-0.727-3.31-2.099c-1.13-1.373-2.503-0.727-3.23-0.08c-0.727,0.646-2.664,0.403-3.794-0.081
        s-2.18-0.969-3.31-0.324s-2.906-1.049-2.906-1.049v-22.842c-0.229-0.218-0.539-0.356-1.215-0.3
        c-1.562,0.13-0.911,0.878-2.245,0.683s-1.66-0.651-2.213-0.619c-0.553,0.032-1.269,0.066-1.431,0.39
        c-0.163,0.325-0.163,1.952-1.27,2.018c0,0-3.644-0.39-3.775,0.912s-1.823,0.39-1.692,1.562c0.13,1.172,0.26,4.165,1.562,4.165
        s2.213,0,2.603-0.911c0.391-0.911,1.823-0.26,2.603-0.26c0.781,0,1.823,0,1.172,1.041s-0.911,1.823-2.343,1.953
        s-3.254,0.651-3.124,1.823s-0.651,2.474,1.302,2.994c1.952,0.521,3.254,1.822,2.603,2.213c-0.651,0.39-3.644,0.781-4.686,0.13
        s-1.302-1.302-0.911-2.343c0.39-1.041-0.261-1.692-0.521-2.474s-1.562-2.734-1.562-2.734s-0.39,1.562-1.172,2.213
        c-0.781,0.651-2.994,1.172-1.562,2.343s2.474,0.911,2.213,1.822c-0.261,0.912-1.823,0.651-2.474-0.26s-1.302-1.692-2.603-2.213
        c-1.302-0.521-2.603-0.261-3.775-0.39c-1.172-0.13-1.562-0.911-2.082,0.13s-2.603,2.864-1.431,3.124s1.041-1.431,1.823-0.781
        c0.781,0.651,1.431,0.651,1.692,0s1.041-0.912,1.953,0c0.911,0.911,2.734,2.083,1.823,2.343s-1.172,1.953-2.213,1.172
        s-4.295-0.521-3.254,0.781s0.911,1.432,2.733,1.302c1.823-0.13,4.426-1.041,4.426,0s-2.864,2.213-1.432,2.474
        s1.952,0.13,3.124,1.041s3.905,2.474,4.036,1.172c0.13-1.302-0.651-2.864,1.302-2.864s2.864-0.26,2.603,0.912
        s-1.823,2.083-0.13,2.864s3.124,2.213,4.426,2.734s2.734-0.39,1.172-1.302c-1.562-0.911-1.302-2.342,0.521-1.431
        c1.823,0.911,2.603,2.213,3.775,2.213s1.952,1.563,3.905,2.343c1.952,0.781,2.083,0,0.521-1.302s-2.213-2.213-2.474-3.514
        c-0.261-1.302,0.781-3.385,1.432-1.823s0.651,2.864,2.343,2.342c1.692-0.521,2.603-1.171,3.124-0.26s2.083,1.823,2.603,0.781
        s0-0.911-0.911-2.213s0.13-2.734,1.432-1.172s1.823,1.562,2.994,2.343c1.002,0.668,2,1.141,2.27,0.452
        C563.773,681.604,563.416,681.189,563.058,680.951z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M552.887,386.057
        c-1.937-0.404-1.211-2.341-1.695-4.843c-0.484-2.503-1.695-3.955-2.018-5.893c-0.323-1.937-0.727-3.39-1.937-4.037
        c-1.211-0.646-0.969-3.31-1.937-4.521c-0.969-1.211-0.565-2.664,0.08-3.068c0.646-0.404,0.969-1.453,0.404-2.341
        s0.404-1.695,0.807-2.986c0.404-1.291-0.727-1.937-2.341-2.906c-1.615-0.969-2.019-3.23-1.777-4.925
        c0.242-1.695-0.08-3.23-1.13-3.552c-1.049-0.323-1.13-2.503-2.261-3.23c-0.31-0.199-0.495-0.434-0.611-0.691
        c-0.362,0.12-0.689,0.249-0.985,0.437c-0.549,0.349-0.898,1.097-0.748,2.294c0.15,1.197-0.15,1.795-0.798,2.743
        s-1.845,1.147-2.992,0.648c-0.497-0.216-1.05-0.291-1.518-0.308c0.067,0.16,0.133,0.324,0.196,0.5
        c1.172,3.254,0.651,5.727,1.887,7.55s2.082,5.662,1.562,6.899c-0.521,1.236-1.692,0.911-1.692,2.799
        c0,1.887,0.651,2.018,0.977,4.426c0.326,2.409,0,4.817-0.586,6.508c-0.586,1.692-0.456,3.189-0.456,4.751s-0.066,2.994-1.172,3.775
        s-2.603,2.082-1.431,3.32c1.172,1.236,2.213,1.822,1.431,2.864c-0.78,1.041-1.302,2.082-1.106,3.645
        c0.195,1.562,0,1.367,0.065,3.254s-0.065,4.165,0.521,4.686s-0.456,1.172-1.562,2.213c-1.107,1.041,0.195,2.148,0.456,4.166
        c0.261,2.018,0.195,3.189-0.781,5.467c-0.977,2.278-1.497,2.799-1.628,5.011c-0.13,2.213,0.195,2.603-0.781,3.971
        c-0.977,1.367-1.106,1.367-1.172,3.319s1.106,2.734-0.13,4.036s-2.278,2.213-2.213,3.514c0.066,1.302-0.195,2.408,0.911,3.515
        c1.107,1.107,1.041,2.278,0.585,3.645c-0.456,1.367-2.018,2.669-2.799,3.905s-1.236,2.408-0.716,4.946
        c0.521,2.538,0.39,3.514,1.107,4.295c0.716,0.781,0.651,0.651,0.585,2.474c-0.065,1.823-0.26,2.538,0.456,3.775
        c0.716,1.236,0.78,1.628,0.456,3.385c-0.326,1.757-0.651,2.408-1.497,2.994c-0.846,0.585-0.911,1.628-0.781,3.905
        c0.13,2.278,0,3.97-0.911,5.011s-0.195,1.041-1.041,3.385c-0.846,2.343-1.106,3.059-1.497,5.011
        c-0.39,1.952-1.302,0.912-1.953,3.645c-0.651,2.734-1.692,2.799-2.148,5.077c-0.456,2.278-0.651,4.621-2.018,6.247
        c-1.367,1.628-1.302,3.124-1.302,3.905s-0.911,1.757-1.692,1.692c-0.781-0.066-2.474,0.065-2.603,1.367
        c-0.13,1.302,0.781,5.012,1.692,6.314c0.911,1.302,0.977,1.171,0.911,3.254c-0.066,2.082,0,4.686,0.456,6.118
        c0.456,1.431,0.195,4.556-0.716,5.337c-0.911,0.781-2.538,2.278-2.538,3.905s0.13,4.036-0.781,5.272
        c-0.911,1.236-1.562,2.993-0.911,4.295s0.977,2.148,1.628,3.385c0.651,1.236,2.213,2.929,0.456,2.993
        c-1.757,0.066-3.254-0.846-3.385,1.041s1.562,2.668,0.326,3.319c-1.236,0.651-1.367,1.692-1.041,2.799
        c0.326,1.106,0.326,2.408-0.066,3.32c-0.39,0.911-1.172,2.342,0,3.319s4.231,1.563,4.296,0.521
        c0.065-1.041,0.326-2.148,1.106-2.603c0.781-0.456,1.431-1.497,0.586-2.343c-0.846-0.846-2.408-1.562-1.692-2.343
        s1.757-1.041,1.562-2.343c-0.195-1.302,1.172-0.846,1.041-2.082s-1.432-1.628,0.26-2.342c1.692-0.716,2.018-1.692,2.148-2.474
        c0.13-0.78,1.497-0.066,2.148-0.066s1.367,0.977,0.846,1.562s-1.628,1.431-0.651,2.213c0.977,0.781,3.124,1.431,1.302,2.018
        c-1.823,0.585-2.473-0.261-2.018,2.147c0.456,2.409,1.041,3.515-0.195,5.272c-1.236,1.757-2.798,3.905-2.082,4.751
        c0.716,0.846,1.367,2.018,0.651,3.385s-2.082,3.71-0.261,3.971c1.823,0.26,0.39,1.562-0.521,1.822
        c-0.911,0.261-1.497,1.107-1.172,2.603c0.326,1.497,0.195,3.189,0.195,4.231s0.716,2.278-0.846,2.734s-2.474,0.065-2.018,1.367
        s1.692,1.106,0.911,2.734c-0.781,1.628-0.977,3.71-1.497,2.669s-0.846-2.408-1.562-2.603s-2.343,0.195-2.343-1.497
        s0.13-2.408-0.977-2.864c-1.106-0.456-0.586-1.237-1.431,0.911c-0.846,2.148-0.977,2.734-2.408,3.189
        c-1.431,0.456-2.538,1.302-1.628,1.887s0.716,1.172-0.716,1.692c-1.431,0.521-1.692,1.106-2.734,2.278
        c-1.041,1.172-2.733,2.083-1.562,3.385c1.172,1.302,2.278,1.497,2.473,0.065c0.195-1.431,1.823-1.431,2.734-0.456
        c0.911,0.977,1.172,1.432,2.603,0.977c1.431-0.456,2.864-1.236,3.254-0.39c0.39,0.846,0.716,2.993-0.456,3.644
        s-0.846,2.734-1.302,3.645s0.066,1.953,1.432,2.213c1.367,0.261,2.733,0.781,3.515-0.065c0.781-0.846,2.343-0.39,2.603,0.521
        c0.26,0.912,1.757,2.864,0.39,2.994c-1.367,0.13-3.449-0.716-3.84-1.367c-0.39-0.651-1.823-1.302-2.864-0.585
        c-1.041,0.717-1.887,0.585-1.367,1.757c0.521,1.172,1.953,1.563,2.474,1.952c0.521,0.391,1.431,1.041-0.261,1.692
        s-3.905,0.26-3.905-0.781s-0.781-0.781-0.781-2.083s2.082-2.734,0.261-2.473c-1.823,0.26-2.082,1.692-2.994,0.78
        c-0.911-0.912-1.952-0.39-2.343,0.781c-0.39,1.172-1.302,3.775-0.781,4.946c0.521,1.172,1.432,0.911,2.343,0.13
        c0.911-0.78,2.213-1.562,2.213-0.13c0,1.431,0.781,1.953-1.172,2.864c-1.952,0.911-2.864,2.083-2.603,2.994
        c0.261,0.911,2.082,3.775,3.254,2.083s0.261-4.686,1.823-2.864c1.562,1.823,0.651,2.734,0,3.645s0.39,2.083-2.343,1.952
        c-2.734-0.13-4.166,0.39-1.953,1.172c2.213,0.781,3.775,1.562,4.166,0.781c0.39-0.781,2.603,1.562,2.734-0.391
        c0.13-1.952,0.261-3.644,1.952-3.775c1.692-0.13,1.041,1.041,0.911,2.473c-0.13,1.432,1.172,2.213-0.521,2.734
        c-1.692,0.521-3.905,1.431-2.213,2.603c1.692,1.171,3.644,1.952,1.823,2.342c-1.823,0.39-3.124,1.041-2.734,1.692
        c0.39,0.651-1.692,0.781-1.692,1.823s1.041,3.644,1.953,2.864c0.911-0.781,2.213-1.823,3.254-0.651s-0.39,3.775,0.911,3.124
        c1.301-0.651,2.994-1.041,3.124,0.13c0.13,1.172-1.302,0.977-1.367,1.628s-1.628,0.977-2.603,1.628
        c-0.977,0.651-3.059,1.041-1.628,1.952s2.864,1.953,2.864,1.106s-0.131-2.018,1.04-2.018c1.172,0,2.213-0.846,2.213,0.456
        s0.456,1.952,1.497,1.823c1.041-0.13,2.148,0.456,2.278-0.912c0.13-1.367,0.651-1.757,1.757-1.562s1.692,0.195,1.692,1.172
        s-0.586,1.496-1.431,1.757c-0.846,0.26-0.066,1.236-0.977,1.497c-0.911,0.26-0.977-0.456-2.343-0.456s-2.018,0.066-2.603,1.041
        c-0.585,0.977-1.952,2.213-0.716,2.799c1.236,0.585,0.781,1.757,2.278,1.628c1.497-0.13,0.911-0.716,1.236,0.39
        c0.326,1.107-2.082,1.887-1.106,2.669c0.977,0.781,1.887,1.562,2.798,1.302c0.911-0.26,1.236-0.521,1.367,0.391
        c0.13,0.911-0.521,1.822,0.651,2.342c1.172,0.521,1.823,1.172,2.343,0.456c0.521-0.716,1.367-1.172,2.278-1.757
        s2.148-2.018,3.839-2.929c1.692-0.911,2.474-1.367,1.172-1.757s-3.059-1.562-4.1-0.781s-3.319,1.692-4.231,1.302
        c-0.911-0.39-2.864-2.343-0.13-2.538c2.733-0.195,3.515-0.326,4.231-0.456s2.733-0.13,3.775,0.651s2.278,1.367,2.408,2.734
        s0.846,2.213-1.367,2.734s-3.124,1.236-3.97,1.562c-0.846,0.326-1.497,2.018-2.148,2.669s-1.431,1.171-3.124,0.651
        c-1.692-0.521-4.295-1.237-5.077-1.237c-0.781,0-2.082,0.261-2.082,1.302s-0.13,1.563,0.977,2.213
        c1.107,0.651,2.343,1.172,1.497,2.018c-0.846,0.846,0.195,1.106,1.628,1.172c1.431,0.066,3.515,0.26,3.84-0.651
        c0.326-0.911,2.864-0.26,2.473-1.628c-0.39-1.367,0.456-1.628,1.692-1.628s2.018,0.326,3.385,0.716s4.036,1.302,4.231-0.586
        c0.195-1.887,0.586-5.272,1.106-5.923c0.521-0.651,1.107-1.823,1.236-2.994c0.13-1.172,0.586-2.083,1.432-2.083
        s1.952,0.977,2.343,0.13c0.39-0.846,1.628-1.628,2.798-1.692c1.172-0.065,3.254-0.195,3.71-1.172s1.431-2.538,2.798-1.367
        c1.367,1.172,3.385,1.887,4.426,1.887c0.175,0,0.322-0.069,0.442-0.182c-0.351-0.403-0.915-0.979-1.623-1.452
        c-1.211-0.807-2.341-0.888-3.552-1.534s-3.39,0.162-4.359-0.484s-4.117-0.565-7.023-0.565s-6.539,0-7.75,0s-2.986,0-3.39-1.373
        s-1.857-1.453-2.986-2.502s0-3.068,0-4.44c0-1.373,0-1.777,0.888-2.745c0.888-0.969-0.646-1.615-0.646-2.099
        s-0.727-0.807-2.18-1.13s-1.695,0.08-2.18,0.727c-0.484,0.646-1.292,0.403-2.18,0s-1.373-1.777-1.13-3.23
        c0.242-1.453-1.049-1.453-1.615-2.261c-0.566-0.808-0.403-6.539-0.08-7.911c0.323-1.373,2.26-0.808,3.068-1.777
        c0.808-0.969,1.291-1.373,2.664-2.422c1.373-1.05,1.453-2.341,1.291-3.713c-0.162-1.373,1.695-1.211,2.18-2.341
        c0.484-1.13,0-1.857-0.484-3.068s-1.13-0.646-0.969-2.019c0.162-1.373,1.13-1.695,1.291-2.987c0.161-1.291,0.808-1.049,2.422-1.857
        s0.484-2.584,0.484-4.521s1.777-0.969,1.857-2.422s1.211-2.019-0.646-3.148s-0.565-1.937,0.808-2.745
        c1.373-0.807-0.808-2.018-0.727-3.148c0.08-1.13,1.373-1.453,3.068-2.664s-0.403-1.695-1.049-2.987s-1.857-1.373-2.986-1.615
        c-1.13-0.242-1.05-0.969-1.05-1.695s2.341,0.404,3.714,0.404s2.986-0.969,3.068-2.341c0.08-1.373-2.422-1.13-3.955-1.291
        s-1.453-1.857-0.565-3.23s-0.566-2.18-0.242-3.552c0.323-1.373-1.211-2.019-0.888-3.31c0.323-1.292,0.08-1.937-1.049-2.502
        c-1.13-0.565-0.646-6.136-0.646-6.862s1.291-1.211,2.26-1.695s0.324-0.808,0.324-1.695s-0.242-0.969-0.727-1.695
        c-0.484-0.727-0.323-3.714-0.242-5.328c0.08-1.614-0.08-2.826-0.08-3.39s0.08-2.18,0.808-3.23c0.727-1.05,0.646-4.036,0.646-5.408
        s-0.161-2.341,0.969-2.906c1.129-0.565,1.534-1.695,1.05-3.068s0.08-3.229,1.291-4.117s1.857-0.08,2.906-1.453
        s-1.049-2.906-0.888-3.471s0.323-1.695-0.727-3.23c-1.05-1.534,0.081-6.539-0.323-7.427s-0.08-1.695,0-2.583
        s0.565-2.422,2.018-2.906s1.615-1.695,2.583-2.26c0.969-0.565,1.453-1.695,1.453-2.664s0.162-2.341-0.727-2.664
        c-0.888-0.323-0.484-1.857-0.484-3.068s0.969-2.341,1.373-3.39c0.404-1.05,1.049-2.664,1.776-4.037s1.373-1.695,1.857-3.472
        c0.484-1.777-0.242-2.26,0.484-3.875c0.727-1.615-0.323-1.291-1.695-1.937c-1.373-0.646-1.211-1.857-0.566-2.906
        c0.646-1.05,0.646-1.373-0.484-2.26c-1.13-0.888-0.888-2.341-1.049-3.23c-0.162-0.888-0.565-1.937-0.646-2.826
        c-0.08-0.888-0.484-0.323-1.453-1.937c-0.969-1.615-0.404-2.18-0.323-3.23c0.08-1.049,0.969-2.261,1.695-2.987
        c0.727-0.727,0.646-2.018,0.969-3.713s0.969-1.695,2.099-2.019c1.13-0.323,1.13-2.019,0.727-2.584s-0.484-1.373-0.08-2.826
        c0.403-1.453-0.807-1.533-1.049-2.906c-0.242-1.373,0.484-1.937,1.695-3.068c1.211-1.13,0.807-2.422,0.727-3.956
        s0.888-2.341,1.937-3.148s1.857-1.776,2.744-3.068s1.05-2.422,1.13-3.472s1.373-2.341,2.502-2.26
        c1.13,0.08,1.777-0.404,2.422-1.453c0.646-1.049-0.161-1.857-0.969-2.422c-0.807-0.565-1.291-0.969-1.291-2.422
        s1.453-1.777,1.453-2.422c0-0.646-0.404-1.857-0.969-2.987s-0.646-2.826,0.08-3.552c0.727-0.727,1.211-1.695,0.081-2.987
        s-0.162-2.019,0.161-2.503c0.323-0.484,2.099-1.211,3.23-2.26s3.39-1.937,4.763-2.341s1.615-1.291,1.615-2.26
        c0-0.969,0.162-1.777,0.646-2.503c0.484-0.727,0.323-1.615,0.565-3.875C557.892,384.604,554.824,386.461,552.887,386.057z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M467.245,474.338
        c0,0-1.021,0.291-1.021,1.021s0.437,1.021,1.021,0.802C467.827,475.942,468.557,473.755,467.245,474.338z" pointer-events="auto" class="landarea"></path>
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M231.931,419.757
        c0,0-1.943,1.458-1.295,2.268s0.485,0.324,1.295-0.486S233.389,419.272,231.931,419.757z" pointer-events="auto" class="landarea"></path>

        <rect id="UNANSWERED_6_" x="514.963" y="470.896" opacity="0" fill="#EB35FF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="24.691" height="20.581" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_BRAZIL" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Brazil" data-wikipediaLink="https://en.wikipedia.org/wiki/Brazil" data-infoText="<b>Brazil</b> &amp;nbsp; Capital: Brasilia" data-imageFile="BRAZIL.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M801.386,259.454
        c-2.431-4.511-2.778-8.677-3.298-10.587s-2.778-4.861-5.207-4.513c-2.43,0.348-6.943,2.084-13.537-3.99
        c-6.596-6.075-17.531-13.54-21.522-13.713c-3.991-0.173-6.594,2.779-7.983,1.217c-1.389-1.562-1.735-1.041-3.124-1.562
        c-1.389-0.521-6.597-2.949-7.984-2.603c-1.388,0.346-4.167,0.691-4.86,0.867c-0.693,0.175-0.867,0.871-1.214,2.258
        c-0.348,1.387-2.604,1.212-2.431-0.695c0.174-1.908,3.992-2.256,1.736-4.859s-1.909-4.513-4.686-4.513
        c-2.778,0-5.729-2.429-7.984-2.429s-2.431-2.433-4.686-2.258c-2.256,0.175-8.158-1.737-9.372-1.562s-2.949,2.258-3.818,4.513
        c-0.869,2.256-4.686,4.168-5.901,5.555s-2.43-2.603-0.521-3.645c1.909-1.041,4.513-4.165,5.381-6.075s0.693-3.992-1.736-4.166
        c-2.43-0.173-4.513-0.173-4.86,0.869c-0.347,1.041-2.081-1.391-2.256-2.258c-0.174-0.867-0.869-2.256-2.257-2.777
        s-2.431-0.869-2.778,1.214c-0.347,2.082-2.95,2.082-2.95,4.166c0,2.082,0.174,5.034-1.388,5.034s-0.869-1.91-2.257-2.431
        s-2.431-3.645,0.347-4.859s4.86-1.736,6.422-5.035c1.562-3.297,2.083-4.337,2.778-6.073c0.693-1.737-0.347-3.473-2.083-3.992
        c-1.736-0.521-4.166,0.175-4.86-4.339c-0.694-4.513-2.602-9.547-2.95-11.282c-0.297-1.48,0.164-2.709-2.499-3.685
        c-1.327,1.485-1.67,3.736-2.909,5.165c-1.575,1.817-2.785,2.664-4.481,7.871c-1.695,5.207-6.297,3.754-8.113,3.633
        c-1.817-0.122-2.543,1.09-5.57,1.332s-3.996-1.937-4.481-3.028s-3.148-0.484-3.754,0.122c-0.605,0.606-1.817,0.364-3.633,0.364
        s-1.817,2.18-1.817,3.754c0,1.575-5.449,0.606-6.781,0.606s-2.059,0.122-3.754,0.363c-1.695,0.242-3.028,0.727-3.875,1.817
        s-2.059,1.211-2.422,0.484c-0.364-0.727-1.817,0.484-2.785,2.18c-0.969,1.695-3.028,0.727-3.512,0.364
        c-0.484-0.364-2.301-2.059-3.633-3.39c-1.332-1.332-2.301-2.18-2.422-3.512c-0.122-1.332-0.727-1.575-0.969-2.664
        s-0.363-3.39,0.122-4.239s1.211-2.059,0.727-3.39c-0.484-1.332,0.122-1.817,1.695-2.785c1.574-0.969-0.969-2.301-1.09-3.148
        c-0.122-0.848-0.122-1.453-2.059-1.937s-0.122-1.211,0.121-2.906s-0.121-2.785-2.906-2.906s-1.937,0.969-2.18,2.422
        c-0.242,1.453-1.695,1.09-2.18,2.301c-0.484,1.211-0.848,0.606-2.422,1.332c-1.574,0.727-1.575,1.09-3.512,1.09
        s-2.059,1.332-3.996,1.211c-1.937-0.122-1.453,0.727-2.543,0.727s-1.453,1.09-1.332,2.664c0.122,1.575-1.211,0.848-2.059,0.727
        c-0.848-0.122-0.727-0.727-1.453-1.817c-0.727-1.09-1.937,0.121-3.39-0.484s-2.18,0.242-2.543-0.969
        c-0.363-1.211-2.059-0.848-3.754-1.09c-1.695-0.242-1.695,0-1.695,1.09s1.332,1.817,2.786,3.39c1.453,1.575,0,4.723,0.727,5.086
        c0.727,0.364,1.575,1.211,1.575,2.664s0.727,1.09,2.906,1.332c2.18,0.242,1.453,0.969,1.211,2.059s-1.575,0.969-3.39,1.575
        c-1.817,0.606-1.817,1.453-2.059,3.27s-1.695,1.575-3.633,1.575c-1.937,0-1.332,1.575-3.028,1.695
        c-1.695,0.122-0.969,1.575-2.301,2.18c-1.332,0.606-1.332-0.364-2.422-1.09c-1.09-0.727-1.453,0.122-1.817,1.211
        c-0.363,1.09-1.695,0.727-3.39,0.484c-1.695-0.242-1.332-0.484-2.18-1.575c-0.848-1.09-1.575-1.332-3.028-1.575
        c-1.453-0.242-0.484-4.239-0.727-5.449c-0.127-0.635-0.986-1.269-1.775-1.729c-0.257,0.142-0.622,0.255-1.131,0.357
        c-2.018,0.404-2.26,0.646-2.502,1.453s-1.857,0.969-2.422,0.08c-0.566-0.888-1.937-1.211-1.937-0.162s-0.324,1.13-3.23,1.05
        s-8.799-1.373-8.396,1.13c0.404,2.503-0.404,3.956,1.13,4.198s4.359-0.08,4.198,1.211c-0.162,1.291,1.049,1.937-0.646,2.018
        c-1.695,0.081-5.57-0.565-5.812,1.13s-0.646,4.843,0.323,5.65c0.969,0.808,3.23,2.664,3.714,3.956
        c0.484,1.292,0.646,1.534,0.727,2.906c0.08,1.373,0.08,1.857-0.484,2.987c-0.565,1.13-0.808,2.422-0.808,3.633
        s-0.242,2.664-0.242,3.552s-1.13,4.441-1.13,6.296c0,1.857-0.888,3.39-0.888,4.843s0.161,3.229-1.13,3.31
        c-1.291,0.08-2.664-0.484-3.633-0.888s-1.615-0.646-2.664,0.242s-2.099,1.696-4.117,1.777s-3.794-0.484-5.408,1.211
        c-1.615,1.695-3.794,2.986-4.843,3.471s-2.099,1.13-1.776,2.019c0.323,0.888-0.081,2.987-0.888,3.956s-1.857,1.695-1.13,2.744
        s1.211,2.099,0.162,2.583c-1.049,0.484-1.937,0.323-2.744,1.291c-0.808,0.969-2.26,1.937-1.937,2.906s1.291,1.695,0.162,2.261
        c-1.13,0.566-2.826,1.373-1.453,2.503s1.05,1.129,1.857,2.26c0.807,1.13,1.534,3.068,2.583,4.198s1.049,2.099,1.857,2.664
        c0.808,0.565,1.373,0.727,1.13,1.857s0.08,2.099,1.533,2.261s3.956,0.404,4.279,1.777c0.323,1.373-0.081,3.31,1.533,3.31
        c1.615,0,3.068,0.566,5.167-0.404s2.906-0.969,3.633-2.019c0.727-1.05,3.39-3.39,3.471-2.18c0.08,1.211-1.049,2.099-0.969,3.23
        c0.08,1.13-0.565,3.713-0.565,5.247s0.323,2.907,1.291,3.472c0.969,0.565,2.26,1.372,2.906,0.323s2.099-0.888,2.986-0.727
        c0.529,0.096,1.287-0.089,1.883,0.288c0.538-0.085,1.378-0.084,2.699,0.156c3.33,0.606,2.664-0.242,3.148,0.545
        c0.484,0.787,2.603,0.969,2.967-0.242c0.364-1.211,1.453-2.18,2.725-2.543c1.272-0.364,2.725-0.061,3.39-1.15
        c0.666-1.089,1.634-1.695,3.028-1.998c1.392-0.303,2.059-0.908,2.603-1.695c0.545-0.787,2.725-0.848,3.33-1.575
        c0.605-0.727,2.119-0.848,3.148-0.848s2.906,0.181,3.451-0.424c0.545-0.606,1.392-0.181,1.695,0.727s0.848,2.059,0.122,2.785
        c-0.727,0.727-0.969,1.453-0.908,2.059s-0.666,0.606-0.181,1.695c0.484,1.089,0.666,1.695,0.545,2.785
        c-0.122,1.09-0.424,2.119,0.363,3.028c0.787,0.908,1.392,1.514,1.817,2.603c0.424,1.09,1.272,2.301,2.301,2.845
        c1.029,0.545,1.998,1.272,2.361,2.18c0.364,0.908,1.453,1.09,3.028,1.211s4.178-0.606,5.207,0.545
        c1.029,1.15,3.148,0.545,3.875,1.937s1.453,1.817,2.906,1.937c1.453,0.122,4.178,1.09,4.723,2.361s3.028,1.514,4.904,1.514
        c1.877,0,4.056-0.364,5.025,0.666c0.969,1.029,1.937,1.937,1.817,3.209c-0.121,1.272-0.666,1.817,0.181,2.725
        c0.848,0.908,0,1.272,0.484,2.119s0.848,1.937-0.181,2.543s-1.453,1.09-0.605,2.059s2.059,0.606,1.634,2.24
        c0,1.877-0.061,5.994-0.061,5.994l14.168-0.061c0,0,0.605,2.059-0.122,2.785c-0.727,0.727-0.605,1.453-0.545,2.301
        c0.061,0.848,0.727,2.119,0.908,2.603s1.09,0.787,2.18,1.15c1.09,0.364,2.725,0.848,2.725,2.119s-0.242,1.634,0.424,2.119
        c0.666,0.484,1.392,1.453,1.211,2.483s-1.453,1.332-1.453,2.543s-0.484,2.906-0.424,3.814c0.061,0.908-0.122,1.272-0.969,2.24
        c-0.848,0.969-1.272,2.422-0.969,3.451s1.211,1.211,0.303,2.483s-2.059,1.514-0.787,2.725c1.272,1.211,1.877,1.575,1.634,3.39
        c-0.242,1.817,0.121,3.028-0.364,4.239s-0.484,3.39-0.121,4.481c0.363,1.09-0.484,2.18,1.817,2.422s3.028,0.606,4.359,1.09
        c1.332,0.484,3.875,0.364,4.239-0.484s1.09-2.059,2.543-0.727s2.786,0.606,2.906,2.543c0.122,1.937,1.332,3.633,1.817,5.692
        c0.484,2.059,0.363,6.054,2.18,6.418c1.817,0.363,3.512,1.09,4.239-0.122c0.727-1.211,1.332-1.817,2.543-1.09
        c1.211,0.727,2.18,1.332,2.18,3.148c0,1.817,0.242,1.937-0.969,4.239c-1.211,2.301-1.817,3.633-1.937,5.086
        c-0.048,0.58-0.097,1.065-0.137,1.569c0.828,0.063,1.922,0.126,2.923,0.126c1.937,0,3.27,0.363,3.27,1.453s0.484,2.18,0.969,2.785
        c0.484,0.606,1.09,1.575,0.242,2.543s-1.09,1.575-0.969,3.27c0.121,1.695,1.937,3.512-2.543,4.481
        c-4.481,0.969-5.934,2.906-7.629,4.239c-1.695,1.332-3.148,1.453-4.723,3.754s-2.301,2.664-3.512,4.481s-2.664,1.937-3.996,3.996
        s-1.211,3.27-3.148,4.117c-1.937,0.848-1.453,2.301-3.148,3.27c-0.394,0.226-0.781,0.485-1.141,0.768
        c0.742,0.38,1.804,0.627,3.079,0.079c2.543-1.09,1.211-1.817,3.27-1.09s2.664,0.727,3.028,2.18s2.18,0.606,2.543,2.664
        c0.364,2.059,1.211,4.359,2.422,3.028c1.211-1.332,1.09-2.301,2.18-0.484c1.09,1.817,1.695,2.785,3.996,2.906
        c2.301,0.121,2.906,0.364,3.633,2.059c0.727,1.695,1.09,2.18,2.543,2.785c1.453,0.606,3.028,0.364,3.633,2.18
        c0.605,1.817,0.605,4.723,0.848,6.539c0.242,1.817-0.121,2.785,0.484,4.481c0.487,1.366,0.823,2.572,1.376,3.688
        c2.623-2.521,6.653-5.212,6.82-8.392c0.174-3.299,2.083-4.339,1.91-6.769c-0.174-2.431,0-4.166,1.388-5.728
        c1.388-1.563,5.38-2.777,6.074-5.728s0.348-3.297,2.257-3.645s2.951-0.348,2.778,1.214c-0.174,1.562-2.083,2.603-3.298,4.166
        c-1.216,1.562-1.042,2.951-3.298,4.165c-2.255,1.214-4.512,3.297-4.512,4.166s1.389-0.348,2.778-1.041
        c1.388-0.693,8.33-6.073,9.024-7.983s2.95-5.207,3.645-8.331c0.694-3.124,4.34-6.25,5.381-7.985
        c1.041-1.735,6.074-4.686,6.074-7.81s2.083-3.472,0.869-5.728c-1.215-2.256-2.257-2.949-1.736-6.421s-0.694-3.47,0.868-5.38
        c1.562-1.91,1.562-1.389,1.041-3.473c-0.521-2.082-0.348-1.908,1.562-1.734c1.91,0.173,2.082-0.867,3.298-3.645
        c1.215-2.779,5.033-2.258,6.074-3.992c1.041-1.735,0.348-1.041,3.298-2.082c2.95-1.041,4.167-1.389,4.86-2.431
        c0.693-1.041,2.603,0.346,4.166,0.521c1.562,0.175-0.694-2.774,1.562-3.47c2.255-0.695,3.818-0.693,4.859-2.082
        s3.992-3.647,3.819-2.258c-0.174,1.389,0.694,1.217,2.778,0.869c2.083-0.348,11.28,1.217,11.628-2.255
        c0.348-3.473,1.214-4.166,3.125-6.076c1.91-1.91-0.869-5.382,2.083-7.637c2.951-2.256,4.167-4.511,5.034-6.942
        c0.868-2.431,1.389-3.99,2.603-4.859c1.214-0.869,1.388-1.041,1.388-5.034c0-3.992,0.348-7.117,2.778-8.679
        c2.43-1.562,1.041-7.115,1.388-9.718s3.124-3.647,2.083-7.464s-1.563-6.073-1.216-8.504c0.348-2.431,1.389-3.126,0.869-5.034
        c-0.521-1.908-1.562-2.603-0.521-4.166c1.041-1.562,1.561-4.686,2.255-4.686s-1.389-2.082,0.521-1.562s4.512,0.695,5.728-2.429
        c1.215-3.124,2.951-7.81,4.513-8.852c1.562-1.041,0.868-3.992,3.991-4.513c3.124-0.521,4.165-4.165,7.29-7.29
        c3.124-3.124,6.422-7.985,6.769-11.803C800.518,265.53,803.816,263.966,801.386,259.454z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_12_" x="634.647" y="267.945" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="69.699" height="19.609" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_PARAGUAY" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Paraguay" data-wikipediaLink="https://en.wikipedia.org/wiki/Paraguay" data-infoText="<b>Paraguay</b> &amp;nbsp; Capital: Asunción" data-imageFile="PARAGUAY.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M656.944,396.673
        c0-1.817-0.969-2.422-2.18-3.148c-1.211-0.727-1.817-0.122-2.543,1.09c-0.727,1.211-2.422,0.484-4.239,0.122
        c-1.817-0.364-1.695-4.359-2.18-6.418c-0.484-2.059-1.695-3.754-1.817-5.692c-0.121-1.937-1.453-1.211-2.906-2.543
        s-2.18-0.122-2.543,0.727c-0.364,0.848-2.906,0.969-4.239,0.484c-1.332-0.484-2.059-0.848-4.359-1.09s-1.453-1.332-1.817-2.422
        c-0.363-1.09-0.363-3.27,0.121-4.481c0.484-1.211,0.122-2.422,0.364-4.239c0.242-1.817-0.364-2.18-1.634-3.39
        c-1.272-1.211-0.122-1.453,0.787-2.725c0.362-0.506,0.432-0.838,0.372-1.122c-1.027-0.031-1.971-0.083-2.834-1.119
        c-1.211-1.453-3.714-2.341-4.601-3.23c-0.888-0.888-4.925-0.646-7.427-0.888s-3.31,0.404-4.279,1.13
        c-0.969,0.727-3.794,0.969-5.974,1.05s-1.937,0.566-3.714,1.373c-1.777,0.807-1.695,4.037-3.229,5.328
        c-1.533,1.291-1.291,4.198-1.373,6.297c-0.08,2.099-0.08,2.341-0.727,3.068c-0.646,0.727-1.211,1.533-1.453,2.744
        c-0.125,0.623-0.356,1.458-0.551,2.125c0.935,0.878,1.232,2.232,2.126,3.042c1.332,1.211,1.937,2.301,3.148,4.117
        c1.211,1.817,2.18,2.664,3.754,3.27c1.575,0.606,2.664,1.453,3.512,3.148s3.754,2.059,6.66,1.937
        c2.906-0.122,6.66,4.359,9.203,6.054c2.543,1.695,6.781,1.817,8.598,3.512s1.695,2.18,1.09,3.875
        c-0.606,1.695-0.484,1.695-1.817,2.664s-1.575,0.484-1.695,3.148c-0.122,2.664,0,3.27-2.664,5.692s-0.363,2.422,2.422,2.422
        c2.784,0,3.633,0.364,5.328,0.848c1.695,0.484,3.39,0.364,5.57,0.606c2.18,0.242,2.422,0.122,3.754-1.09
        c1.332-1.211,2.422-0.242,3.39,0.122c0.969,0.363,2.059-0.242,2.18-2.059c0.122-1.817,2.906-1.817,5.449-3.633
        c2.543-1.817,1.817-4.965,1.817-7.023c0-2.059,0.122-2.906,0.242-4.359c0.121-1.453,0.727-2.785,1.937-5.086
        C657.186,398.611,656.944,398.489,656.944,396.673z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_6_" x="591.135" y="378.092" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="69.699" height="24.5" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_FRENCHGUIANA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="French Guiana" data-wikipediaLink="https://en.wikipedia.org/wiki/French_Guiana" data-infoText="<b>French Guiana</b> &amp;nbsp; Capital: Cayenne" data-imageFile="FRENCHGUYANA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" d="M675.38,170.765
        c-4.513-1.041-3.644-3.99-6.248-5.38c-2.603-1.389-2.779-2.779-5.034-2.779c-1.496,0-3.831-1.295-5.995-2.271
        c0.098,1.28,0.085,2.962-0.555,3.602c-1.09,1.09-3.39,1.937-3.39,3.39s-0.848,2.785-0.121,3.754
        c0.727,0.969,0.969,1.695,1.453,3.39c0.484,1.695,0.848,3.996,0.848,5.692s1.453,3.39,0.122,4.481
        c-1.332,1.09-1.575,2.301-1.817,3.39c-0.093,0.417-0.202,0.762-0.29,1.078c0.464,0.126,0.996,0.183,1.622,0.133
        c3.028-0.242,3.754-1.453,5.57-1.332c1.817,0.122,6.418,1.575,8.113-3.633c1.695-5.207,2.906-6.054,4.481-7.871
        c1.238-1.429,1.581-3.679,2.909-5.165C676.589,171.078,676.042,170.918,675.38,170.765z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_1_" x="659.768" y="173.708" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="69.699" height="19.609" pointer-events="auto" visibility="hidden"></rect>
    <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" cx="679.991" cy="174.202" r="21.844" pointer-events="auto" opacity="0.001" class="clickarea landarea"></circle>

        <rect id="FLAG_6_" x="649.178" y="173.698" opacity="0" fill="#0A11FF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="25.1" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_PANAMA" data-colors="0" data-errors="0" hint-index="0" class="">
    <g data-colors="0">
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M458.862,135.575
            c-0.716-0.716-1.692-2.799-2.864-1.953c-1.172,0.846-1.952,2.278-3.905,2.538s-5.728,2.082-6.444,0.456
            c-0.716-1.628-1.628-2.929-3.254-2.993c-1.628-0.066-3.189-0.13-3.71-1.692s-1.041-2.148-2.148-2.343
            c-0.008-0.001-0.017-0.006-0.025-0.007c-0.283,0.674-0.673,1.457-1.082,1.868c-0.808,0.807-0.888,2.019-0.808,2.664
            s-0.484,2.018,0.888,2.099c1.373,0.081,0.888,0.888,0.404,1.453s-0.566,1.211-0.566,1.857c0,0.485-0.32,1.2-0.34,2.246
            c0.011-0.003,0.021-0.004,0.031-0.007c1.757-0.521,2.278-0.586,4.361-0.326c2.082,0.26,3.189,0.39,4.036,1.628
            c0.846,1.236,1.302,2.342,2.929,2.408c1.628,0.065,2.734-0.78,2.734,0.585c0,1.367,0.521,0.456,1.236,2.018
            c0.716,1.562,0.521,1.041,2.278,1.172c1.757,0.13,2.082,0.326,2.408-0.651s2.278-0.066,2.474-0.977
            c0.195-0.912,0.521-1.692-0.586-2.213s-3.189-1.628-2.669-2.734s0.585-1.562,1.953-1.952c1.367-0.391,3.189-1.431,3.385-2.799
            C459.773,136.551,459.578,136.29,458.862,135.575z" pointer-events="none" class="landarea"></path>
        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M474.509,148.856
            c0.235-0.471,0.094-2.399,0.141-3.058s1.035,0.141,1.412,0.893c0.376,0.753,1.506-0.518,2.211-1.129
            c0.706-0.612,0.518-1.646,0.518-2.07s-0.047-1.318-0.847-1.506s-0.329-0.659-0.706-1.552c-0.376-0.893,0.235-0.847,0.188-1.741
            c-0.022-0.407,0.114-0.9,0.266-1.319c-0.926-1.141-2.058-2.126-3.643-3.057c-2.95-1.735-4.166-1.735-5.728-2.255
            c-1.562-0.521-2.603-1.391-6.595-1.043c0,0-0.521,0.39-1.497,0.846c-0.977,0.456-2.148,0.586-1.497,1.367s0.846,1.692,1.172,2.018
            s0.977,1.628,0.977,2.668c0,1.041,1.692,2.278,2.603,1.041s1.302-1.497,2.213-0.977c0.911,0.521,0.781-0.586,2.018,0.326
            c1.236,0.911,3.124-0.066,3.71,0.195c0.585,0.26,2.213,1.106,0.716,1.822s-2.082,1.497-1.692,2.929
            c0.391,1.432,0.195,2.213,1.562,3.515c1.151,1.096,1.38,1.365,2.124,2.509C474.3,149.174,474.419,149.035,474.509,148.856z" pointer-events="none" class="landarea"></path>

            <ellipse fill="none" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="456.377" cy="138.077" rx="21.279" ry="15.791" pointer-events="none" opacity="0" class="landarea"></ellipse>

            <rect id="C3_5_" x="448.741" y="118.085" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="23.495" height="19.609" pointer-events="none"></rect>
    </g>
</g>
<rect id="SVGHUD" x="-133.879" y="238.461" stroke="#FFFFFF" stroke-width="6" stroke-miterlimit="10" width="111.695" height="27.396"></rect>
<g id="AREA_COSTARICA" data-colors="0" data-errors="0" hint-index="0" class="">
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M435.35,139.519
        c0-0.646,0.08-1.291,0.566-1.857s0.969-1.373-0.404-1.453s-0.807-1.453-0.888-2.099s0-1.857,0.808-2.664
        c0.41-0.41,0.799-1.194,1.082-1.868c-1.107-0.225-2.785-2.605-4.271-3.768c-1.497-1.172,0.13-1.562-1.757-3.124
        c-0.976-0.807-1.202-1.423-1.267-1.964c-0.689,0.196-1.175,0.562-1.376,1.116c-0.323,0.888-0.161,1.13-2.018,0.808
        s-1.776-0.969-2.502-2.099c-0.727-1.13-4.44-0.404-5.651-0.404s-1.615,0.646-3.229-0.727c-0.72-0.612-1.422-0.726-1.981-0.664
        c0.173,1.035-0.517,2.316,0.255,3.152c0.781,0.846,0.651,1.562-0.261,2.082c-0.911,0.521-1.106,2.148,0.13,3.385
        c1.236,1.236,2.669,1.106,3.254,2.343c0.585,1.236,2.669,1.953,3.254,0.846c0.585-1.106,1.953-3.124,2.082-0.977
        c0.13,2.148,0.716,2.148,2.213,2.409c1.497,0.26,4.361,0.911,5.011,2.082c0.651,1.172,1.628,1.431,1.236,2.734
        c-0.39,1.302-1.302,2.474,0.39,3.254c1.682,0.777,3.236,2.194,4.981,1.699C435.03,140.719,435.35,140.004,435.35,139.519z" pointer-events="none" class="landarea"></path>

        <rect id="C3_4_" x="401.702" y="126.597" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="23.495" height="19.609" pointer-events="none"></rect>

        <ellipse fill="none" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="419.054" cy="135.537" rx="20.3" ry="14.747" pointer-events="none" opacity="0" class="landarea"></ellipse>
</g>
<g id="AREA_NICARAGUA" data-colors="0" data-errors="0" hint-index="0" class="">
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M414.441,119.418
        c1.615,1.373,2.018,0.727,3.229,0.727s4.925-0.727,5.651,0.404c0.727,1.13,0.646,1.776,2.502,2.099
        c1.856,0.324,1.695,0.08,2.018-0.808c0.202-0.554,0.686-0.92,1.376-1.116c-0.061-0.507,0.022-0.949-0.23-1.421
        c-0.521-0.977-0.977-1.237-0.326-2.278s1.172-1.497,0.585-2.994c-0.585-1.497-0.195-2.864,0.586-3.839
        c0.78-0.977,2.082-2.474,1.562-4.361c-0.521-1.887-1.887-3.319-1.041-4.491s0.846-2.343,1.107-3.645
        c0.26-1.302,1.562-1.496,1.497-3.449c-0.065-1.952-1.302-2.213-0.716-3.71c0.352-0.899,0.842-1.306,1.053-1.782
        c-1.584,0.086-2.208,0.722-3.111,0.797c-0.969,0.08-1.049,0.162-2.341,0.969s-1.937,0.566-3.148,0.566s-1.695,1.373-2.583,1.453
        s-0.404-0.324-0.646-1.453c-0.242-1.13-0.807-0.727-1.937-0.727s-1.776,1.777-2.099,2.584c-0.324,0.807-0.969,2.422-1.937,2.664
        c-0.969,0.242-1.453,1.373-2.18,2.261s-1.695,0.404-2.18-0.484c-0.484-0.888-1.615-0.404-1.937,0.081
        c-0.324,0.484-1.13,1.291-2.422,1.291s-1.453,1.13-1.453,2.826s-1.453,1.453-2.018,2.502s-0.969,0.808-2.341,0.727
        c-0.858-0.051-2.063,0.088-2.846,0.2c-0.017,0.307,0.142,0.637,0.543,0.954c1.236,0.977,0.326,2.082,2.082,2.342
        c1.757,0.261,2.603,0.716,3.319,2.213c0.716,1.496,2.278,3.189,3.645,4.165c1.367,0.977,4.556,2.994,4.751,4.036
        c0.002,0.012,0.003,0.025,0.005,0.037C413.019,118.693,413.722,118.807,414.441,119.418z" pointer-events="none" class="landarea"></path>

        <rect id="C3_3_" x="418.726" y="95.014" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="23.495" height="19.609" pointer-events="none"></rect>
</g>
<g id="AREA_HONDURAS" data-colors="0" data-errors="0" hint-index="0" class="">
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M400.96,104.807
        c1.373,0.081,1.777,0.323,2.341-0.727c0.565-1.049,2.018-0.807,2.018-2.502s0.162-2.826,1.453-2.826s2.099-0.807,2.422-1.291
        s1.453-0.969,1.937-0.081c0.484,0.887,1.453,1.373,2.18,0.484c0.727-0.888,1.211-2.019,2.18-2.261
        c0.969-0.242,1.615-1.857,1.937-2.664c0.323-0.807,0.969-2.584,2.099-2.584s1.695-0.404,1.937,0.727
        c0.242,1.13-0.242,1.534,0.646,1.453s1.373-1.453,2.583-1.453s1.857,0.242,3.148-0.566c1.292-0.807,1.373-0.888,2.341-0.969
        c0.903-0.075,1.527-0.711,3.111-0.797c0.14-0.317,0.158-0.666-0.076-1.212c-0.586-1.367-2.603-0.521-4.361-2.278
        c-1.757-1.757-5.012-3.905-7.029-3.905c-2.018,0-2.929-0.521-5.142-0.13c-2.213,0.39-4.361,0.39-7.159,0.39
        s-2.733-0.195-4.686,0.521s-3.515,0.326-5.077-0.195c-1.562-0.521-2.603-0.846-3.905-0.26c-0.721,0.324-1.52,0.526-2.279,0.644
        c-0.572,1.924-1.932,2.861-2.782,3.589c-1.003,0.86-0.86,1.432-2.866,1.862c-2.006,0.429-2.006,2.292-2.149,4.155
        c-0.036,0.471-0.192,0.777-0.411,0.986c0.709,0.796,1.691,1.824,2.347,2.199c1.13,0.646,2.503,2.341,4.359,2.422
        s5.005-0.242,5.247,0.888c0.196,0.914,0.187,2.191,0.516,3.076c0.157-0.061,0.375-0.051,0.749,0.029
        c0.911,0.195,2.864,2.082,1.367,2.213c-1.011,0.088-1.811,0.622-1.845,1.259C398.896,104.895,400.102,104.756,400.96,104.807z" pointer-events="none" class="landarea"></path>

        <rect id="C3_2_" x="396.999" y="71.271" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="23.495" height="19.609" pointer-events="none"></rect>
</g>
<g id="AREA_ELSALVADOR" data-colors="0" data-errors="0" hint-index="0" class="">
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M392.08,97.542
        c-1.857-0.081-3.23-1.776-4.359-2.422c-0.657-0.376-1.638-1.403-2.347-2.199c-0.646,0.623-1.847,0.414-2.169,1.163
        c-0.43,1.003-0.429,1.862-1.719,2.006c-1.289,0.143,0,1.863-1.863,1.863c-0.747,0-1.214,0.37-1.51,0.811
        c0.792,0.679,1.543,1.41,3.166,1.796c2.734,0.651,4.1,0.585,5.857,1.628c1.757,1.041,5.467,1.823,7.68,1.367
        c2.213-0.456,2.082-0.585,2.474-1.302c0.229-0.421,0.327-0.658,0.552-0.745c-0.328-0.884-0.32-2.162-0.516-3.076
        C397.085,97.3,393.937,97.622,392.08,97.542z" pointer-events="none" class="landarea"></path>

        <rect id="C3_1_" x="375.047" y="98.598" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="23.495" height="19.609" pointer-events="none"></rect>

        <ellipse fill="none" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="387.247" cy="103.507" rx="15.148" ry="10.116" pointer-events="none" opacity="0" class="landarea"></ellipse>
</g>
<g id="AREA_GUATEMALA" data-colors="0" data-errors="0" hint-index="0" class="">
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M379.623,97.952
        c1.863,0,0.573-1.72,1.863-1.863c1.289-0.143,1.289-1.003,1.719-2.006s2.436-0.287,2.579-2.149s0.143-3.726,2.149-4.155
        c2.006-0.429,1.863-1.002,2.866-1.862c0.849-0.728,2.21-1.666,2.782-3.589c-0.612,0.095-1.198,0.137-1.691,0.137
        c-0.758,0-1.568-0.219-2.05-0.646c-1.74,0.553-3.624,0.085-3.624,0.085l0.129-15.171c-0.777,1.119-8.024,0.126-10.875-0.16
        c-2.865-0.286-3.152,1.003-3.152,2.149s1.289,2.149-1.719,2.149s-1.72,0.717,0,1.863c1.72,1.146,2.435,2.579,4.441,4.155
        s1.289,1.432,1.289,3.009c0,1.576-3.582,0.573-6.877,0.573s-2.435,1.72-2.435,3.009s-2.435,3.438-3.582,5.588
        c-0.725,1.358-1.105,3-1.287,4.026c0.191,0.093,0.404,0.174,0.649,0.24c1.431,0.39,1.302-0.585,3.449,1.757
        c2.148,2.343,3.58,2.278,4.946,2.278c1.367,0,2.799-0.521,4.946,0.195c0.873,0.291,1.433,0.733,1.976,1.198
        C378.409,98.321,378.876,97.952,379.623,97.952z" pointer-events="none" class="landarea"></path>

        <rect id="C3" x="364.284" y="76.977" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="23.495" height="19.609" pointer-events="none"></rect>
</g>
<g id="AREA_BELIZE" data-colors="0" data-errors="0" hint-index="0" class="">
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M389.838,81.818
        c-0.223-0.197-0.381-0.433-0.423-0.721c-0.13-0.911,0.066-1.431,1.302-2.082s2.082-1.106,2.278-2.538
        c0.195-1.431,1.367-0.391,1.172-2.734s-0.977-6.314,0.195-7.746c0.585-0.715,1.544-1.122,2.365-1.602
        c-0.811-0.269-1.535-0.929-1.63-1.404c-0.143-0.716-0.429-1.146-1.289-1.432c-0.86-0.286-2.292-0.286-3.009,2.292
        c-0.717,2.579-1.289,2.006-2.722,1.576c-1.432-0.43-1.003,0.143-1.719,1.289c-0.004,0.006-0.01,0.011-0.014,0.016l-0.129,15.171
        C386.214,81.904,388.098,82.371,389.838,81.818z" pointer-events="none" class="landarea"></path>

        <ellipse fill="none" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="393.183" cy="71.288" rx="6.243" ry="9.856" pointer-events="none" opacity="0" class="landarea"></ellipse>
</g>
<g id="AREA_MEXICO" data-colors="0" data-errors="0" hint-index="0" class="">
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M319.224,0.589
        c-1.453-0.364-3.633-0.727-4.723-1.695s-2.543-1.453-4.239-1.575c-1.695-0.122-2.059-0.969-2.059-2.18s-0.606-2.059-1.211-2.543
        c-0.606-0.484-0.606-1.817-0.606-3.148c0-1.332-0.121-2.301-0.848-2.543s-2.18-1.937-3.633-3.39s-2.664-4.359-3.512-5.934
        s-1.332-4.601-2.906-4.601c-1.575,0-1.575-1.211-2.906-2.906c-1.332-1.695-4.481-1.453-7.387-1.817
        c-2.906-0.364-3.028,0.606-4.965,4.965s-5.812,1.09-7.507,0.122c-1.695-0.969-3.512-2.422-4.723-2.785
        c-1.211-0.364-2.18-3.028-2.301-4.601c-0.121-1.575-1.211-2.301-1.332-3.39c-0.121-1.09-0.606-1.817-1.937-2.059
        c-1.332-0.242-2.18-1.09-3.028-1.817s-3.996-3.27-5.449-5.57c-1.453-2.301-9.082-1.332-12.472-1.211
        c-3.39,0.122-2.664,1.453-2.785,3.028c-0.122,1.575-1.211,1.332-1.211,1.332s-15.621-0.122-20.828-0.122
        c-2.543-0.727-9.687-4.239-13.077-5.449s-8.355-2.422-10.656-3.27c-2.301-0.848-2.664-1.09-4.843-2.906
        c-2.18-1.817-9.687,0.727-12.715,0.727c-1.703,0-3.905,0.345-5.505,0.647c0.146,0.293,0.355,0.578,0.667,0.863
        c1.497,1.367,2.213,1.628,2.669,3.449c0.456,1.823,0.651,3.514,1.887,5.011s0.846,2.474,2.148,3.97
        c1.302,1.497,2.278,2.213,2.799,3.97s0.39,5.272,1.887,6.183c1.497,0.911,1.628,1.823,3.515,2.474s2.343,1.172,2.734,2.343
        s1.367,1.236,2.603,2.408c1.236,1.172,3.059,1.953,3.059,3.839c0,1.887-0.065,4.231-1.431,4.231s-3.97-0.13-4.621,0
        s-2.213,0.977-0.781,1.628c1.432,0.651,2.408,3.385,4.426,3.905c2.018,0.521,3.84,2.474,5.337,3.449
        c1.497,0.977,2.669-0.977,4.426,0.716s2.538,3.254,3.905,3.775s2.799,0.78,3.189,1.692c0.39,0.911,1.236,1.562,1.041,2.669
        s0.13,3.449-0.39,4.231c-0.521,0.781-1.887,2.474-0.716,3.385c1.172,0.911,2.148,2.082,3.775,2.408
        c1.628,0.326,2.929,1.692,3.775,2.603s3.059,1.823,4.166,2.994c1.106,1.171,3.124,1.628,4.426,4.1
        c1.302,2.474,0.39,3.71,2.018,3.971c1.628,0.26,1.432-0.651,2.082-1.562c0.651-0.912,1.757-2.083,1.757-3.189
        s-1.041-2.474-1.757-2.929c-0.716-0.456-0.326-2.473-1.823-3.124c-1.497-0.651-2.603-1.562-3.124-1.172s-1.823,0.781-2.474-0.13
        s-1.562-2.474-0.911-3.905c0.651-1.432,2.148-2.538,0.781-3.124c-1.367-0.585-1.562-0.261-2.148-1.887
        c-0.586-1.628-3.58-2.343-2.474-3.644c1.107-1.302,0.911-2.343,0.066-2.473c-0.846-0.13-1.236-0.195-1.497-1.887
        s-0.781-2.018-2.278-3.58s-3.71-5.402-5.011-6.183c-1.302-0.781-1.692-1.107-2.082-3.059c-0.39-1.953,0.065-1.887-1.628-3.644
        c-1.692-1.757-3.124-2.929-4.361-4.49c-1.236-1.562-1.172-2.798,0.195-1.887c1.367,0.911,2.538-0.066,1.628-1.432
        s-2.147-2.798-2.864-2.147c-0.716,0.651-1.236,1.497-2.538,0.326s-5.597-2.669-5.858-4.946c-0.261-2.278,0.716-4.751-0.456-6.574
        s-1.757-1.823-1.628-3.97c0.13-2.148,1.172-3.905,1.887-3.319c0.716,0.586,0.977,1.431,2.474,1.367
        c1.497-0.065,2.864-0.586,3.385-0.065s0.911,2.473,2.603,2.473s2.929-0.065,2.929,2.213s-0.456,3.059,0.977,4.295
        c1.432,1.236,1.367,1.758,1.887,3.645c0.521,1.887,0.39,3.059,1.823,4.1c1.431,1.041,1.367,2.018,0.716,2.669
        s-1.757,2.278-0.846,3.254c0.911,0.977,2.018,1.628,2.278,0.716c0.261-0.911,1.562-2.278,2.603,0s2.148,4.1,3.58,4.686
        c1.432,0.586,1.236,3.905,3.515,3.58c2.278-0.326,2.474-0.065,2.734,1.757c0.261,1.823,0.781,2.799,2.408,3.32
        c1.628,0.521,2.603,0.716,2.929,2.213s0.651,1.887,2.408,2.018c1.758,0.13,2.994,1.106,2.603,2.603
        c-0.39,1.497-0.456,2.147-1.172,2.669c-0.716,0.521-1.172,1.431,0.781,2.408c1.952,0.977,3.319,2.864,4.426,2.799
        c1.106-0.065,3.775-0.195,4.426,1.953s-1.497,2.734,2.082,4.426c3.58,1.692,7.941,6.183,9.762,8.852
        c1.823,2.668,2.798,4.946,5.207,5.988c2.408,1.041,5.533,4.946,5.533,7.745s0.846,5.467,0.13,6.444s-2.082,1.302-1.692,2.148
        s1.041,1.367-0.065,2.018c-1.107,0.651-1.692,1.823-1.041,2.603s0.13,1.106,1.692,2.994c1.562,1.887,2.343,4.621,4.686,5.272
        s5.077,1.041,6.899,2.864c1.823,1.823,5.012,4.946,7.095,5.793c2.082,0.846,2.669,1.172,5.467,1.172s3.644-0.326,5.142,1.431
        c1.497,1.757,4.036,4.231,5.988,4.751c1.953,0.521,4.426,1.628,6.183,2.213c1.757,0.586,3.319,1.823,5.857,2.213
        s4.166,0.911,5.857,2.082c1.692,1.172,4.166,3.385,6.964,3.515c2.799,0.13,3.32,0.066,4.687,1.172
        c1.367,1.106,6.248,1.172,7.81,0.521c1.562-0.651,3.385-1.887,5.728-2.669c2.343-0.78,2.343-2.018,5.207-1.562
        s4.166,0.521,6.379,2.669c2.213,2.147,4.426,3.905,5.793,5.011s2.473,1.562,3.385,3.58c0.756,1.673,1.155,2.494,2.085,2.949
        c0.182-1.027,0.563-2.668,1.287-4.026c1.146-2.149,3.582-4.298,3.582-5.588c0-1.289-0.86-3.009,2.435-3.009
        c3.295,0,6.877,1.003,6.877-0.573s0.717-1.432-1.289-3.009s-2.722-3.009-4.441-4.155s-3.009-1.863,0-1.863s1.719-1.003,1.719-2.149
        s0.287-2.436,3.152-2.149c2.865,0.287,10.172,1.289,10.889,0.143s0.286-1.72,1.719-1.289s2.006,1.003,2.722-1.576
        c0.716-2.579,2.149-2.579,3.009-2.292s1.146,0.717,1.289,1.432c0.095,0.475,0.82,1.135,1.63,1.404
        c0.823-0.48,1.507-1.033,1.54-2.043c0.065-2.018-0.781-1.628,0.195-3.059c0.977-1.431,1.367-2.538,1.041-4.295
        c-0.326-1.757-1.041-3.559-0.086-5.035c0.955-1.475,0.782-3.383,1.735-4.251c0.954-0.869,1.647-1.129,1.735-0.087
        c0.087,1.041,0.782,1.908,1.129,0.954c0.347-0.954,1.823-1.91,0.868-2.082c-0.955-0.174-1.476-0.693-0.955-1.735
        c0.521-1.042,1.388-0.263,1.041-2.691c-0.347-2.429,0.782-2.688-0.433-3.557c-1.215-0.869-2.516-1.216-3.905-0.348
        c-1.389,0.869-3.298,0.608-4.079-0.173c-0.781-0.781-2.604-0.608-4.079-0.261c-1.475,0.348-1.302,0.607-3.644,1.214
        c-2.343,0.608-8.245,0.521-9.112,1.91c-0.868,1.389-2.778,0.433-2.864,2.516c-0.086,2.082-0.607,5.813-0.868,7.29
        s-1.389,1.217-1.736,2.951c-0.347,1.735-0.868,3.211-2.69,3.905c-1.823,0.693-4.774,2.949-3.819,3.557
        c0.955,0.608,2.255-0.085,1.823,0.956c-0.433,1.041-0.954,1.562-2.256,1.562s-1.649-1.129-1.736-1.65
        c-0.086-0.521-1.042-1.387-2.517-1.214c-1.475,0.174-3.211,0.606-4.252,1.475c-1.041,0.869-3.732,0.869-5.293,1.041
        c-1.562,0.174-2.778,0-3.819,0.781c-1.041,0.78-2.083,1.649-3.298,1.302c-1.214-0.348-1.387-0.435-1.475-1.129
        c-0.087-0.693-1.562-1.477-2.343-1.562c-0.781-0.085-0.521-1.993-2.082-1.908s-5.902,0-6.076-1.041
        c-0.173-1.041-1.041-1.562-1.823-2.864c-0.781-1.302-1.301-1.912-1.475-3.299s0.348-1.562-1.041-2.777
        c-1.389-1.214-1.041-2.255-2.778-3.385c-1.736-1.129-1.996-2.256-2.256-3.036c-0.261-0.78-0.608-1.475-1.562-2.603
        s-1.302-1.823-0.347-2.603c0.955-0.78,0.434-0.956-0.521-1.649s-0.954-0.954-1.648-2.777c-0.694-1.822-0.869-1.997-0.869-3.992
        s-0.174-2.69,0.521-3.732c0.694-1.041,0.956-1.995,0.608-4.946c-0.348-2.95-1.042-5.813-0.087-7.809s0.781-4.08,1.736-5.294
        s2.517-3.732,2.517-5.293c0-0.617,0.011-1.111-0.031-1.585c-0.882,0.19-1.495,0.599-2.504,0.91
        C321.283,1.436,320.677,0.952,319.224,0.589z" pointer-events="none" class="landarea"></path>

        <rect id="rect12_13_" x="276.267" y="55.513" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="69.699" height="19.609" pointer-events="none"></rect>
</g>
<g id="AREA_CUBA" data-colors="0" data-errors="0" hint-index="0" class="">
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M427.186,26.794c0,0-3.212,0.26-3.644,1.823
        c-0.433,1.562,0.173,2.864-1.041,3.124c-1.214,0.261-3.036,0.783-3.036,1.737s0.954,0.346,1.475-0.176
        c0.521-0.521,1.302,0.433,1.736,0.78c0.434,0.348,1.909,0.523,2.343-0.346s1.215-1.91,2.778-1.91c1.562,0,0.78-1.043,2.777-1.129
        c1.996-0.085,3.385-0.867,3.905-1.735s1.389-2.17,3.558-2.17s5.38,0.26,5.553,0.78c0.174,0.521-0.433,1.302-1.388,1.91
        s0.347,2.342,1.909,2.342s2.343-0.693,3.558-0.173c1.216,0.521,2.864,0.521,4.426,0.693c1.562,0.174,2.517,0.263,3.819,1.649
        c1.302,1.387,3.123,2.518,4.598,2.603c1.476,0.085,3.733,0.348,4.599,1.041c0.868,0.693,2.257-1.041,3.558,1.823
        c1.302,2.864,2.169,3.385,4.512,3.644c2.343,0.261,6.162,0.258,6.422,1.647c0.261,1.389,0.085,1.997-1.389,2.17
        c-1.475,0.174-3.212,0.608-3.124,1.65c0.087,1.041,0.782,1.214,1.91,1.214s1.996-1.389,3.905-1.041s8.59-0.433,9.546,0.608
        c0.955,1.041,3.385,1.477,4.599,0.78c1.216-0.695,0.694-1.91,2.603-1.91s4.165-0.348,4.252-1.129
        c0.087-0.78-1.995-0.779-3.036-2.168c-1.041-1.389-2.171-1.391-3.733-1.737s-3.557,0.176-4.252-1.214s-0.521-2.17-2.343-2.343
        c-1.823-0.173-1.909-0.346-3.905-1.475c-1.996-1.129-4.512-1.217-5.814-2.431s-2.257-1.91-3.298-2.431s-0.522-1.212-1.736-2.429
        s-3.037-2.168-2.343-0.781s-0.085,2.258-1.214,0.781c-1.129-1.477-0.693-3.647-1.823-3.559c-1.129,0.087-0.347,2.082-2.343,2.17
        s-2.778,0.262-4.339-1.475c-1.562-1.737-1.735-3.126-3.991-3.299c-2.257-0.174-3.211,0.085-4.686-0.781
        c-1.476-0.866-4.078-1.387-5.467-1.387s-2.17,0.346-4.599,0.346s-3.733,0.346-5.381,0.521c-1.649,0.175-4.946,0.085-5.553,0.78
        C431.439,24.886,429.008,25.838,427.186,26.794z" pointer-events="none" class="landarea"></path>

        <rect id="C3_7_" x="446.725" y="24.233" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="23.495" height="19.609" pointer-events="none"></rect>

        <ellipse fill="none" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="461.949" cy="34.069" rx="36.93" ry="17.732" pointer-events="none" opacity="0" class="landarea"></ellipse>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M434.823,33.217c0,0-1.562,0.085-1.736,1.475
        s-0.694,2.168,1.128,2.516c1.823,0.348,3.038,0.35,3.212-0.606c0.173-0.956,0.173-2.864-1.216-3.124
        C434.823,33.217,435.344,33.132,434.823,33.217z" pointer-events="none" class="landarea"></path>
</g>
<g id="AREA_JAMAICA" data-colors="0" data-errors="0" hint-index="0" class="">
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M477.086,60.726c0,0-3.558-0.348-4.946,0
        s-2.95,2.255-1.388,2.516c1.562,0.26,1.908,1.041,2.516,1.823c0.608,0.78,1.216,1.475,2.778,1.302
        c1.562-0.174,4.165,0.087,4.165-0.694c0-0.78,0.694-1.216,1.736-0.869c1.041,0.348,1.648,1.041,2.43,1.041
        c0.781,0,1.649-0.606,1.649-1.475s-0.52-1.735-1.995-1.823c-1.476-0.087-1.303-0.956-2.691-1.129
        C479.95,61.246,480.73,61.334,477.086,60.726z" pointer-events="none" class="landarea"></path>

        <rect id="C3_6_" x="460.388" y="61.639" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="23.495" height="19.609" pointer-events="none"></rect>

        <ellipse fill="none" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="471.776" cy="72.296" rx="21.279" ry="15.791" pointer-events="none" opacity="0" class="landarea"></ellipse>
</g>
<g id="AREA_HAITI" data-colors="0" data-errors="0" hint-index="0" class="">
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M519.436,62.22
        c-0.24-0.602,0.24-2.045,0.902-3.008s0.481-2.887,0.18-3.789s0-1.384,0.662-3.008c0.431-1.059-0.363-1.833-0.969-2.247
        c-0.199,0.542-0.75,0.828-1.818,0.493c-1.389-0.435-2.083-0.783-2.951-1.477c-0.868-0.693-3.036-1.82-4.339-0.867
        c0,0-0.347,0.694-1.823,0.954s-1.562,1.304-0.781,1.91s1.387,1.127,2.256,1.127c0.869,0,1.995,0.521,2.343,1.129
        s0.607,1.823,0.521,2.864s3.211,4.944-0.26,4.339s-3.385,0.346-3.991-0.78c-0.607-1.128-1.215-1.908-2.864-2.168
        c-1.648-0.261-2.951-1.825-3.298-0.696c-0.347,1.129,1.128,2.603-0.781,2.691c-1.909,0.087-2.43,0.696-1.909,1.649
        c0.521,0.954,0.955,0.085,1.736,1.387c0.78,1.302,2.17,1.823,3.124,0.869c0.954-0.954,1.476-0.782,3.385-0.608
        c1.656,0.152,8.855-0.481,10.909,0.48C519.705,62.98,519.583,62.588,519.436,62.22z" pointer-events="none" class="landarea"></path>

        <rect id="C3_8_" x="496.846" y="48.817" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="23.495" height="19.609" pointer-events="none"></rect>

        <ellipse fill="none" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="509.771" cy="54.62" rx="13.356" ry="15.791" pointer-events="none" opacity="0" class="landarea"></ellipse>
</g>
<g id="AREA_THEDOMINICANREPUBLIC" data-colors="0" data-errors="0" hint-index="0" class="">
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M546.25,59.425
        c-1.215-1.129,0.521-2.258-0.261-3.733c-0.78-1.475-3.644-2.601-5.381-2.429s-4.688,1.477-5.642-0.26
        c-0.954-1.737-1.04-1.215-2.777-1.823s-2.17-0.954-3.472-2.082c-1.302-1.129-3.818-0.956-5.814-0.696
        c-1.996,0.26-2.603,0.35-2.603,1.216c0,0.201-0.031,0.385-0.091,0.549c0.606,0.413,1.4,1.188,0.969,2.247
        c-0.662,1.625-0.963,2.106-0.662,3.008s0.481,2.828-0.18,3.789s-1.143,2.406-0.902,3.008c0.147,0.368,0.271,0.76,0.234,1.241
        c0.315,0.147,0.512,0.33,0.546,0.562c0.261,1.737,1.648,2.866,2.69,3.038c1.041,0.174,1.649-0.346,1.736-1.562
        c0.086-1.217,1.735-4.511,3.557-3.385c1.823,1.127,3.385,2.431,4.687,1.041c1.302-1.389,2.864-1.389,4.079-1.389
        s1.214-0.608,3.471-0.348c2.256,0.26,6.161,1.649,6.683,0.869C547.638,61.507,547.466,60.553,546.25,59.425z" pointer-events="none" class="landarea"></path>

        <rect id="C3_9_" x="527.309" y="33.809" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="23.495" height="19.609" pointer-events="none"></rect>
</g>
<g id="CITY_PUERTORICO" data-colors="0" data-errors="0" hint-index="0" class="">
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M561.523,60.638c0,0-5.207-0.258-5.814,0.435
        s-0.694,0.519-0.174,1.647s0.347,2.603,1.823,2.603s7.898,0.606,8.245-0.26c0.347-0.867,1.909-0.691,1.909-1.908
        c0-1.216,0.261-2.258-1.475-2.258S565.081,60.551,561.523,60.638z" pointer-events="none" class="landarea"></path>

        <rect id="C3_10_" x="548.812" y="63.152" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="23.495" height="19.609" pointer-events="none"></rect>

        <ellipse fill="none" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="568.149" cy="64.476" rx="21.279" ry="15.791" pointer-events="none" opacity="0" class="landarea"></ellipse>
</g>
<g id="CITY_FALKLANDISLANDS" data-colors="0" data-errors="0" hint-index="0" class="">

        <rect id="rect12_40_" x="588.643" y="620.667" opacity="0" fill="#FFFFFF" stroke="#010202" stroke-miterlimit="10" enable-background="new    " width="60.91" height="14.28" pointer-events="none"></rect>
    <circle id="CITY_40_" opacity="0" fill="#166c38" enable-background="new    " cx="619.36" cy="643.78" r="5.71" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>

        <circle id="Circle_40_" fill="none" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="619.403" cy="632.923" r="38.56" pointer-events="none" opacity="0" class="landarea"></circle>
</g>
<g id="CITY_GALAPAGOSISLANDS" data-colors="0" data-errors="0" hint-index="0" class="">

        <ellipse id="_x3C_Path_x3E__7_" fill="none" stroke="#95bec8" stroke-miterlimit="10" cx="371.444" cy="208.655" rx="61.786" ry="53.83" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></ellipse>

        <rect id="rect12_22_" x="357.565" y="181.604" opacity="0" fill="#FFFFFF" enable-background="new    " width="30.723" height="17" pointer-events="none"></rect>
    <circle id="CITY_7_" opacity="0" fill="#166c38" enable-background="new    " cx="372.389" cy="209.856" r="6.5" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>
</g>
<g id="CITY_AMAZONRIVER" data-colors="0" data-errors="0" hint-index="0" class="">
    <path id="SEMITRANSPARENT_5_" opacity="0" fill="#166c38" enable-background="new    " d="M503.639,251.439
        c-0.52,0-1.078-0.051-1.666-0.177l1.854-8.633c-0.041-0.062-0.081-0.124-0.119-0.188l0.184-0.113l1.057-4.924
        c-0.496-0.106-0.967-0.139-1.381-0.138c-0.386,0.148-0.771,0.343-1.135,0.594c-0.984,0.677-1.933-0.248-0.381-2.529
        c0.881-1.3,2.171-2.254,3.587-2.869c0.271-0.733,0.626-1.425,1.063-2.061c0.776-1.133,2.97-3.854,6.542-4.616
        c0.057-0.012,0.113-0.023,0.169-0.034c0.409-0.399,0.883-0.772,1.433-1.102c2.022-1.209,4.466-1.417,6.714-0.569
        c1.09,0.412,1.939,1.026,2.604,1.67c0.537,0.085,0.979,0.212,1.292,0.321c0.652,0.226,1.211,0.524,1.689,0.852
        c1.288-0.187,2.877-0.149,4.555,0.562c1.166,0.493,2.016,1.188,2.605,1.777c0.533,0.227,1.146,0.546,1.779,1.011
        c0.243,0.179,0.461,0.352,0.674,0.524l0.096,0.077c0.374,0.228,0.673,0.447,0.887,0.614c0.256,0.153,0.555,0.343,0.867,0.583
        c0.16,0.123,0.325,0.258,0.491,0.405c2.146-1.095,4.121-1.221,5.516-1.23c1.059-2.753,3.453-5.024,6.375-5.786
        c0.199-0.052,0.394-0.095,0.581-0.131c0.28-0.245,0.581-0.473,0.902-0.684c2.277-1.494,4.53-1.425,5.369-1.4l0.111,0.003
        c1.211,0.013,2.226,0.146,3.041,0.254c0.279,0.037,0.562,0.075,0.856,0.105c0.19,0.019,0.375,0.044,0.553,0.072
        c0.046-0.137,0.093-0.269,0.143-0.395l0.589-9.336l8.919,4.43c0.667-0.232,1.467-0.417,2.391-0.463
        c0.591-0.03,1.196-0.024,1.8-0.01c0.188,0.004,0.402,0.026,0.563,0.008c0.252,0,0.516,0.01,0.784,0.024
        c1.098-0.26,2.472-0.412,4.209,0.009c0.379,0.092,0.729,0.201,1.044,0.312c0.207-0.054,0.426-0.104,0.657-0.146
        c0.176-0.032,0.354-0.07,0.533-0.108c0.896-0.188,2.081-0.437,3.543-0.44c3.203,0.012,5.399,1.545,6.58,2.369
        c0.134,0.093,0.265,0.191,0.404,0.275c0.281,0.155,0.536,0.317,0.757,0.468c0.229,0.07,0.475,0.157,0.732,0.264
        c0.013,0.001,0.024,0.002,0.037,0.004c1.962,0.218,3.405,0.978,4.428,1.765c1.384-0.739,2.671-1.005,3.474-1.166
        c0.492-0.252,1.164-0.547,1.994-0.745c1.041-0.248,1.948-0.26,2.56-0.237c0.179-0.2,0.375-0.404,0.592-0.608
        c0.49-0.858,1.206-1.737,2.377-2.533c0.851-0.842,2.7-2.404,5.782-2.509l0.088-0.01c1.29-0.102,3.688-0.298,6.001,1.096
        c0.337,0.204,0.643,0.421,0.919,0.647c2.453-1.847,5.127-1.722,6.436-1.506c0.393,0.065,0.832,0.16,1.298,0.268
        c2.05-1.567,4.286-1.619,5.581-1.471c1.385,0.163,2.436,0.684,2.999,0.964c0.758,0.353,1.537,0.789,2.277,1.273
        c0.658,0,1.406,0.042,2.226,0.217c0.296,0.063,0.572,0.14,0.828,0.224c0.659,0.064,1.429,0.222,2.239,0.559
        c0.216,0.089,0.454,0.199,0.707,0.333c0.001-0.001,0.003-0.003,0.004-0.004c2.468-2.682,6.246-3.168,6.979-3.239
        c0.222-0.021,0.433-0.036,0.636-0.046c0.521-0.348,1.218-0.754,2.092-1.09c0.088-0.034,0.178-0.067,0.27-0.099
        c0.128-0.134,0.269-0.274,0.423-0.418c0.837-0.782,2.521-2.354,5.183-2.889c1.402-0.717,3.459-1.249,6.055-0.477
        c0.013,0,0.024,0,0.037,0.001c0.188-0.094,0.383-0.184,0.587-0.268c0.628-3.27,3.124-6.73,8.263-6.73c0.036,0,0.072,0,0.108,0
        l-0.133,14.173c2.56-0.018,4.583,1.837,3.04,3.343c-1.708,1.667-3.844,2.307-5.491,2.538c-0.982,0.712-2.008,1.09-2.857,1.289
        c-1.444,0.338-3.192,0.252-4.288,0.019c-0.231-0.05-0.45-0.107-0.656-0.17c-0.076,0.024-0.151,0.047-0.225,0.068
        c-0.178,0.051-0.353,0.094-0.522,0.13c-0.302,0.313-0.674,0.661-1.121,1.002c-1.213,0.927-2.44,1.362-3.213,1.605
        c-0.551,0.404-1.439,1.007-2.672,1.457c-0.761,0.278-1.467,0.437-2.083,0.528c-0.097,0.202-0.203,0.407-0.32,0.616
        c-1.207,2.137-3.746,4.618-8.962,4.281c-4.448,0.951-7.209-0.803-8.823-2.665c-1.115,0.062-2.887-0.023-4.672-1.106
        c-1.689,0.582-3.358,0.598-4.792,0.428c-1.238,1.077-2.781,1.741-4.609,1.981c-1.292,0.299-3.601,0.668-5.831,0.19
        c-0.425-0.091-1.368-0.293-2.669-1.061c-2.157,3.617-5.455,4.297-7.92,4.193c-0.692-0.027-1.284-0.123-1.747-0.221
        c-0.17,0.063-0.351,0.125-0.541,0.182c-0.335,0.1-0.646,0.178-0.926,0.241c-0.033,0.034-0.066,0.067-0.1,0.101
        c-1.703,1.693-3.69,2.146-4.438,2.316c-1.458,0.364-4.186,0.827-6.83,0.024l-4.791-1.456l-0.082-1.785
        c-0.325-0.139-0.619-0.29-0.88-0.441c-0.135-0.043-0.274-0.091-0.418-0.146c-1.207-0.456-2.071-1.103-2.54-1.454
        c-0.293-0.171-0.561-0.342-0.801-0.502c-0.68,0.295-1.822,0.752-3.297,0.823c-1.672,0.085-3.075-0.319-4.096-0.703
        c-0.958,0.116-1.775,0.064-2.29,0.03l-0.195-0.012c-0.1,0-0.218,0-0.348-0.002c-0.051,0.136-0.104,0.268-0.161,0.395l-1.84,4.169
        l-2.991,0.037c-0.16,0.073-0.33,0.145-0.509,0.212c-0.374,0.142-0.75,0.262-1.122,0.377l-0.259,0.081
        c-1.01,0.355-1.9,0.477-2.433,0.549l-0.04,0.005c-0.782,0.253-2.368,0.754-4.521,0.444c-1.131-0.163-2.038-0.495-2.591-0.701
        c-0.115-0.014-0.229-0.028-0.343-0.042c-0.168,0.118-0.337,0.228-0.506,0.33c-0.278,1.512-1.128,3.638-3.579,5.331
        c-3.349,2.313-6.988,2.219-9.161,2.164c-0.284-0.007-0.565-0.016-0.842-0.016c-0.003,0-0.006,0-0.01,0l-0.389-0.001
        c-0.005,0-0.01,0-0.015,0c-1.476,0.947-3.753,2.271-6.716,2.338c-3.405,0.079-5.483-1.463-6.426-2.212
        c-0.837-0.447-2.218-1.347-3.203-3.049c-0.165-0.116-0.314-0.227-0.448-0.329c-0.366-0.134-0.79-0.313-1.249-0.56
        c-1.326,0.205-2.971,0.185-4.715-0.545c-0.406-0.17-0.815-0.39-1.187-0.612c-0.504-0.03-1.049-0.108-1.615-0.263
        c-0.185,1.619-0.83,3.388-2.471,4.878c-1.12,1.017-2.473,1.649-3.913,1.869c-0.635,0.905-1.497,1.795-2.667,2.602
        c-1.648,1.134-3.228,1.425-4.259,1.49C505.556,251.237,504.675,251.439,503.639,251.439z M536.897,233.807L536.897,233.807
        L536.897,233.807z M627.326,231.716c-0.001,0-0.002,0-0.003,0C627.324,231.717,627.325,231.716,627.326,231.716z M618.037,230.875
        c-0.001,0.001-0.003,0.002-0.005,0.003C618.034,230.877,618.036,230.876,618.037,230.875z M536.713,230.791
        c0.001,0.001,0.002,0.002,0.004,0.002C536.716,230.792,536.714,230.792,536.713,230.791z" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea" stroke-opacity="0"></path>
    <path id="RIVER_3_" fill="none" stroke="#95bec8" stroke-miterlimit="10" d="M679.46,209.333c-0.83-0.008-1.179,0.085-1.349,1.001
        c-0.066,0.356,0.026,0.806,0.019,1.165c-0.009,0.484-0.146,0.722-0.262,1.167c-0.175,0.669,0.166,1.488-0.384,2.025
        c-0.514,0.501-1.673,0.659-2.357,0.642c-0.742-0.019-1.395,0.09-1.926,0.657c-0.32,0.342-0.199,0.575-0.733,0.7
        c-0.263,0.061-0.922,0.046-1.187-0.011c-0.285-0.061-0.437-0.26-0.683-0.317c-0.374-0.088-0.763,0.021-1.146-0.021
        c-0.576-0.063-1.203-0.633-1.798-0.284c-0.351,0.206-0.24,0.507-0.682,0.634c-0.303,0.087-0.704-0.088-1.021-0.037
        c-0.74,0.12-1.295,0.644-1.824,1.138c-0.391,0.365-0.722,0.883-1.151,1.211c-0.51,0.39-1.231,0.507-1.832,0.739
        c-0.907,0.349-1.315,0.984-2.185,1.302c-0.867,0.317-1.5,0.187-2.408,0.274c-0.693,0.066-1.941,0.439-2.441,0.983
        c-0.67,0.728-0.804,2.188-1.295,3.057c-0.699,1.238-2.498,0.466-3.73,0.717c-1.017,0.207-1.887,0.602-2.649-0.401
        c-0.467-0.615-0.1-1.597-0.97-1.956c-0.348-0.144-0.684,0.044-1.024-0.032c-0.314-0.07-0.47-0.293-0.804-0.364
        c-0.645-0.138-1.494,0.004-2.161,0.011c-0.351,0.004-0.984,0.154-1.295-0.048c-0.299-0.194-0.507-0.72-0.859-0.968
        c-0.541-0.381-1.144-0.732-1.711-0.996c-0.179-0.083-0.62-0.323-0.811-0.345c-0.588-0.068-0.527,0.2-0.901,0.518
        c-0.351,0.298-0.824,0.66-1.208,0.921c-1.299,0.883-3.651-0.044-5.112-0.285c-1.451-0.239-1.613,1.086-2.288,2.064
        c-0.423,0.613-0.94,0.464-1.654,0.639c-0.791,0.194-2.031,0.368-2.863,0.19c-0.651-0.139-1.661-1.057-2.124-1.524
        c-0.397-0.401-0.453-1.147-0.927-1.432c-0.458-0.276-1.674-0.092-2.19-0.074c-0.941,0.032-1.022,0.619-1.732,1.09
        c-0.509,0.338-0.474,0.228-0.676,0.741c-0.217,0.55-0.083,0.593-0.667,0.933c-0.595,0.347-0.92,0.874-1.325,1.462
        c-0.782,1.138-0.573,1.848-2.151,1.786c-0.769-0.03-1.211-0.344-2.025-0.339c-0.429,0.003-0.919-0.074-1.333,0.025
        c-0.442,0.105-0.8,0.436-1.24,0.567c-0.894,0.267-1.804,0.289-2.593,0.734c-0.408,0.23-0.814,0.504-1.135,0.831
        c-0.142,0.144-0.12,0.4-0.247,0.526c-0.331,0.329-0.812,0.389-1.249,0.498c-0.84,0.21-2.134,0.358-2.978,0.101
        c-0.035-0.769-0.589-1.087-1.058-1.607c-0.601-0.665-0.864-1.249-1.82-1.355c-0.348-0.039-0.775,0.124-1.114,0.037
        c-0.414-0.106-0.427-0.311-0.764-0.494c-0.305-0.165-0.631-0.129-0.96-0.254c-0.478-0.181-0.91-0.631-1.358-0.879
        c-1.177-0.65-2.155-1.742-3.559-1.747c-0.907-0.004-1.876,0.268-2.772,0.432c-0.601,0.11-1.264,0.582-1.806,0.609
        c-0.932,0.045-2.007-0.654-2.843-0.857c-0.727-0.176-0.952,0.062-1.66,0.183c-0.506,0.086-1.139-0.017-1.659-0.017
        c-0.668,0-1.349-0.04-2.016-0.007c-0.751,0.038-0.969,0.54-1.66,0.698c-1.212,0.278-2.588-0.149-3.599-0.651
        c-0.026,0.419-0.344,0.58-0.426,0.942c-0.082,0.36-0.022,0.946,0.074,1.294c0.927-0.078,0.811,1.768,0.559,2.341
        c-0.599,0.008-0.851,0.457-1.365,0.651c-0.41,0.155-0.824,0.261-1.216,0.398c-0.609,0.214-1.275,0.2-1.873,0.365
        c-0.635,0.176-1.051,0.411-1.764,0.308c-0.842-0.121-1.574-0.599-2.391-0.682c-1.121-0.114-2.089-0.311-3.258-0.323
        c-0.55-0.006-1.036-0.08-1.514,0.234c-0.499,0.328-0.314,0.677-0.626,1.031c-0.709,0.805-2.094,0.491-2.96,0.717
        c-0.829,0.216-1.662,1.179-1.663,2.016c-0.001,1.26,1.997,1.971,0.352,3.107c-1.746,1.206-3.994,0.891-5.989,0.893
        c-1.337,0.001-2.367-0.077-3.527,0.668c-1.117,0.718-2.385,1.64-3.747,1.671c-1.216,0.028-1.524-0.455-2.399-1.097
        c-0.285-0.209-0.753-0.297-0.943-0.642c-0.347-0.63,0.074-0.748-0.564-1.238c-0.133-0.102-0.442-0.262-0.577-0.354
        c-0.229-0.156-0.317-0.278-0.585-0.435c-0.505-0.296-0.813-0.603-1.25-0.925c-0.463-0.34-0.975-0.334-1.507-0.624
        c-0.555-0.304-0.97-1.148-1.443-1.348c-1.176-0.498-2.611,1.045-3.882,0.514c-0.158-0.066-0.745-0.44-0.944-0.6
        c-0.216-0.173-0.811-1.099-0.983-1.159c-0.587-0.204-0.847,0.602-1.491,0.612c-0.731,0.011-1.148-0.787-1.517-1.302
        c-0.219-0.305-0.754-1.247-1.066-1.365c-1.16-0.438-1.164,1.778-1.292,2.24c-1.123,0.352-2.053-0.443-3.041-0.233
        c-0.86,0.183-1.704,1.006-2.174,1.692c-0.556,0.812-0.562,1.971-0.344,2.917c0.154,0.669,0.838,3.034-0.584,2.539
        c-0.3-0.105-0.526-0.393-0.805-0.531c-0.253-0.025-0.488-0.105-0.705-0.24c-0.153-0.098-0.277-0.223-0.372-0.377
        c-0.387-0.083-1.595,0.262-1.825,0.6c-0.247,0.364-0.123,0.799-0.116,1.211c0.026,1.386-0.072,2.294-1.345,3.17
        c-0.617,0.425-0.882,0.165-1.582,0.283c-0.492,0.083-0.889,0.463-1.408,0.352" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></path>
    <path fill="none" stroke="#95bec8" stroke-miterlimit="10" d="M503.657,264.202c-1.453,0-2.908-0.156-4.362-0.468l4.041-21.063
        c-0.029-0.046-0.06-0.093-0.091-0.141c0.027,0.042,0.059,0.088,0.093,0.139l0.553-0.341l2.684-12.5c0,0-3.569-13.913,0.884-15.597
        c0.282-0.187,0.561-0.362,0.834-0.525c5.386-3.224,11.864-3.791,17.774-1.557c1.467,0.554,2.756,1.231,3.886,1.966
        c2.297,0.157,4.538,0.7,6.722,1.625c1.079,0.456,2.051,0.978,2.917,1.522c1.885-1.521,4.025-2.744,6.35-3.579
        c1.445-0.896,2.968-1.605,4.563-2.125l23.614-6.396c0.985-0.039,1.82-0.022,2.393-0.01h0.027c1.691-0.249,3.927-0.368,6.528,0.048
        c1.209-0.206,2.731-0.397,4.56-0.392c6.152,0.023,10.575,2.496,12.861,4.002c0.187,0.047,0.373,0.098,0.563,0.151
        c0.326-0.112,0.666-0.219,1.021-0.322c0.44-0.396,0.901-0.774,1.383-1.137c4.519-3.989,9.393-5.126,12.953-5.322
        c1.548-0.124,4.576-0.333,8.137,0.6c1.812-0.404,3.615-0.554,5.348-0.509c2.89-1.011,6.021-1.375,9.188-1.009
        c3.187,0.374,5.585,1.409,7.132,2.172c0.175,0.083,0.349,0.168,0.521,0.254c1.965-0.89,3.834-1.419,5.363-1.735
        c1.691-1.385,4.442-3.265,8.201-4.336c0.929-0.378,1.977-0.728,3.13-0.998c3.876-4.444,9.588-7.132,16.005-7.132
        c0.071,0,0.144,0,0.216,0.001l-0.373,39.684c1.2,0.014-0.647,6.452-2.276,6.834c-1.684,0.394-3.303,0.544-4.745,0.562
        c-0.875,0.479-1.696,0.853-2.417,1.146c-0.741,0.451-1.634,0.939-2.664,1.397c-3.025,3.283-8.138,6.801-16.139,6.801
        c-0.046,0-0.091,0-0.137,0c-6.416,0.897-11.326-0.929-14.761-3.166c-0.291-0.063-0.578-0.131-0.864-0.207
        c-1.686,0.79-3.624,1.422-5.839,1.775c-1.978,0.418-4.512,0.74-7.28,0.581c-3.037,1.804-6.532,2.824-10.28,2.941
        c-1.834,1.023-3.854,1.792-6.043,2.295c-2.717,0.663-7.93,1.466-13.413-0.199l-8.773-2.666l-0.896,2.033l-9.063,0.111
        c-0.055,0.017-0.11,0.034-0.165,0.051c0.003-0.001,0.025-0.009,0.059-0.021c-1.479,0.508-2.804,0.785-3.746,0.943
        c-0.961,0.267-2.294,0.569-3.909,0.72c-0.959,0.97-2.021,1.86-3.177,2.659c-5.386,3.722-10.91,4.368-14.446,4.438
        c-2.462,1.149-5.725,2.216-9.648,2.304c-5.004,0.129-9.633-1.333-13.757-4.306c-0.254-0.154-0.504-0.312-0.749-0.474
        c-1.217,0.91-2.521,1.671-3.888,2.278c-0.905,0.83-1.782,1.504-2.555,2.035c-2.783,1.916-5.828,3.104-9.089,3.551
        C506.968,263.999,505.314,264.202,503.657,264.202z M630.364,244.105c-0.006,0.001-0.013,0.003-0.019,0.005
        C630.352,244.108,630.358,244.106,630.364,244.105z M633.964,230.035c-0.001,0.003-0.002,0.005-0.003,0.008
        C633.962,230.041,633.963,230.038,633.964,230.035z M583.46,224.607c-0.003,0.009-0.006,0.018-0.008,0.027
        C583.454,224.625,583.457,224.616,583.46,224.607z" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></path>
    <circle id="CITY_15_" opacity="0" fill="#166c38" enable-background="new    " cx="613.148" cy="226.234" r="6.5" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>

        <rect id="rect12_15_" x="597.786" y="198.801" opacity="0" fill="#FFFFFF" enable-background="new    " width="30.723" height="17" pointer-events="none"></rect>
</g>
<g id="CITY_PARAGUAYRIVER" data-colors="0" data-errors="0" hint-index="0" class="">
    <path id="SEMITRANSPARENT_1_" opacity="0" fill="#166c38" enable-background="new    " d="M618.442,424.06
        c3.587-0.993,7.087-1.135,10.231-0.326c0.243-0.231,0.46-0.448,0.647-0.635l0.011-0.011c1.287-1.961,2.508-3.966,3.733-5.966
        c0.054-0.088,0.106-0.176,0.16-0.264c0.078-0.479,0.131-0.948,0.147-1.391c1.753-1.363,2.86-3.478,2.899-5.927l0.004-0.287
        c0.171-0.542,0.389-1.36,0.457-2.423c0.007-0.111,0.013-0.22,0.019-0.326c0.13-0.196,0.261-0.392,0.393-0.588
        c0.478-0.715,1.021-1.524,1.534-2.411c0.632-1.089,2.109-3.639,1.494-7.287c-0.271-1.593-0.869-2.805-1.38-3.652
        c0.083-1.309,0.115-3.102-0.584-5.027c-0.062-0.173-0.13-0.339-0.199-0.499c0.895-2.992-0.088-5.409-0.909-6.756
        c-0.342-0.564-0.798-1.171-1.402-1.735c-0.004-0.04-0.01-0.08-0.015-0.12c-0.198-1.484-0.692-2.789-1.486-3.909
        c0.021-0.059,0.041-0.116,0.062-0.174c0.311-0.875,0.736-2.074,0.927-3.522c0.053-0.396,0.08-0.76,0.093-1.079
        c0.508-0.955,0.812-1.868,0.969-2.722c0.99-0.542,1.961-1.381,2.839-2.617c1.142-1.608,2.667-4.88,0.545-9.18
        c1.655-1.378,3.607-3.557,4.053-6.896c0.344-2.571-0.273-4.504-1.025-5.863c0.013-0.03,0.024-0.062,0.036-0.093
        c0.329-0.871,0.498-1.703,0.562-2.473c1.595-0.877,2.813-2.264,3.637-4.146l0.064-0.146c0.596-1.163,1.99-3.887,0.913-7.614
        l-1.653-5.721l-1.815,0.197c-0.778-1.016-1.873-2.005-3.42-2.64c-0.067-0.111-0.139-0.221-0.212-0.33
        c0.315-0.45,0.562-0.895,0.766-1.315c0.009-0.005,0.018-0.01,0.028-0.014c1.33-0.631,1.829-0.744,1.982-0.764l-1.793-14.06
        c-2.437,0.311-4.628,1.242-6.265,2.018c-1.236,0.587-3.398,1.612-5.171,3.961c-0.866,0.136-1.836,0.426-2.808,0.999
        c-4.13,2.44-4.804,7.601-3.341,11.22c0.966,2.387,2.811,4.404,4.187,5.674c0.02,0.018,0.038,0.035,0.058,0.053
        c0.021,0.076,0.045,0.154,0.069,0.232c0.295,0.95,0.754,1.897,1.387,2.747c-1.242,1.938-1.721,4.515-1.296,7.41
        c-0.377,0.539-0.73,1.174-1,1.916c-0.927,2.564-0.301,4.919,0.512,6.624c-2.156,1.854-4.14,4.387-3.013,9.023
        c-0.154,0.052-0.307,0.109-0.46,0.171c-3.656,1.474-5.815,5.685-4.918,9.576c0.165,0.721,0.55,1.906,1.431,3.069
        c-0.018,0.375-0.014,0.695-0.01,0.945c-0.054,0.17-0.145,0.425-0.215,0.624c-0.133,0.372-0.265,0.75-0.385,1.132
        c-0.056,0.173-0.133,0.342-0.202,0.51c-1.737,4.222-1.365,7.798,1.104,10.65c0.003,1.396,0.256,3.111,1.313,4.792
        c0.083,0.133,0.173,0.268,0.271,0.404c-0.013,0.336-0.005,0.685,0.026,1.046c0.164,1.876,0.894,3.307,1.566,4.288
        c-0.033,0.501-0.06,1.081-0.037,1.708c0.057,1.524,0.459,2.773,0.911,3.739c-0.034,0.049-0.066,0.098-0.099,0.148
        c-0.393,0.585-0.782,1.169-1.143,1.756c-1.39,2.26-1.547,4.271-1.609,5.546c-2.881,2.044-3.481,4.988-3.558,6.731
        c-0.019,0.435-0.009,0.853,0.012,1.243c-0.074,0.074-0.145,0.142-0.196,0.19l-0.027,0.017c-0.614,0.372-1.644,0.994-2.591,2.086
        c-0.41,0.474-0.729,0.944-0.98,1.378c-0.813,0.685-1.488,1.539-2.035,2.573c-0.873,1.647-1.215,3.131-1.256,4.424
        c1.146-0.168,2.329-0.252,3.55-0.23C615.736,423.744,620.625,423.456,618.442,424.06z" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea" stroke-opacity="0"></path>
    <path id="RIVER" fill="none" stroke="#95bec8" stroke-miterlimit="10" d="M642.647,310.375c-4.5-1-5.5,4-9.5,6c-1,0-2-1-3,2
        c0,1,1,2,3,3c1,1,1,3,2,4c2,2,9,2,5,8c-1,0-3,0-4,0l-1,2c2,3,0,5-1,7c0,3,4,4,2,6c-4,3-3,8-3,12c0,2-3,2-5,2c-1,0-2,1-1,2h2
        c1,4-3,7-3,11c0,2,2,3,2,6c0,2,4,2,3,4c-1,1-1,2-1,3c2,3,2,6,2,9c1,4-3,8-3,12c0,1-2,1-3,2c0,1,0,3,0,4c-1,4-5,5-6.811,7.823" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></path>
    <path fill="none" stroke="#95bec8" stroke-miterlimit="10" d="M625.063,497.271l-0.983,8.633c2.213,0.255,2.168-5.633,1.183-8.664
        C625.196,497.25,625.13,497.262,625.063,497.271z" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></path>
    <path fill="none" stroke="#95bec8" stroke-miterlimit="10" d="M660.841,342.691c-0.003,0.007-0.006,0.013-0.01,0.02
        C660.835,342.705,660.838,342.698,660.841,342.691z" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></path>
    <circle id="CITY_13_" opacity="0" fill="#166c38" enable-background="new    " cx="630.45" cy="389.013" r="6.5" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>

        <rect id="rect12_17_" x="615.089" y="360.581" opacity="0" fill="#FFFFFF" enable-background="new    " width="30.723" height="17" pointer-events="none"></rect>
    <path fill="none" stroke="#95bec8" stroke-miterlimit="10" d="M645.639,317.571c-0.14-2.404-1.046-4.263-2.066-5.044
        c-0.369-0.893-0.816-1.672-1.302-2.29c-0.162-0.841-0.394-1.633-0.69-2.354c0.159-2.402-0.382-4.808-1.889-5.312
        c-2.219-0.741-4.966-0.98-6.99,1.525c-0.344-0.136-0.718-0.157-1.12-0.015c-4.211,1.493-7.242,10.465-8.29,18.442
        c-0.573,4.363-0.739,8.923-0.815,13.524c-0.2,4.327-0.302,8.676-0.518,12.628c-0.239,4.38-0.534,8.726-0.62,13.134
        c-0.008,0.408-0.011,0.825-0.013,1.243c-0.141,2.678-0.244,5.35-0.303,8.028c-0.053,1.393-0.076,2.77-0.054,4.114
        c-0.013,3.041,0.028,6.104,0.148,9.226c0.19,4.92,0.603,9.797,1.666,14.264c0.943,3.969,2.516,6.13,3.559,9.467
        c0.189,0.604,0.407,1.083,0.643,1.461c0.031,0.552,0.075,1.1,0.136,1.643c0.496,4.405,3.853,5.596,4.844,1.314
        c0.426-1.842,0.983-3.724,1.188-5.739c0.02-0.201,0.087-0.53,0.145-0.831c0.197-0.25,0.414-0.564,0.484-0.668
        c0.347-0.521,0.651-1.181,0.9-1.91c0.577-0.985,1.213-3.987,1.386-4.706c1.816-7.575,3.715-15.138,4.734-23.297
        c1.06-8.493,2.374-16.702,3.181-25.434C644.981,339.168,646.286,328.664,645.639,317.571z M637.57,356.301
        c0.099-0.004,0.197-0.005,0.296-0.015c0.046-0.005,0.093-0.015,0.14-0.021c-0.159,1.446-0.342,2.882-0.554,4.308
        C637.507,359.146,637.549,357.721,637.57,356.301z" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></path>
</g>
<g id="CITY_PARANARIVER" data-colors="0" data-errors="0" hint-index="0" class="">
    <path id="SEMITRANSPARENT_4_" opacity="0" fill="#166c38" enable-background="new    " d="M637.445,430.487
        c-0.477,0-0.933-0.008-1.359-0.015c-0.455-0.009-0.907-0.013-1.36-0.013c-1.423,0.025-2.624-0.242-3.407-0.413
        c-0.161-0.035-0.323-0.072-0.492-0.102l-0.239-0.031c-0.412-0.052-0.942-0.119-1.521-0.249c-0.724-0.109-1.363-0.298-1.928-0.537
        c-0.367,0.015-0.733-0.004-1.098-0.008c-0.388-0.006-0.773-0.011-1.164-0.007c-0.055,0.012-0.112,0.023-0.171,0.036
        c-1.721,0.355-6.943,1.447-10.19-2.808l11.266-8.6c-1.472-1.928-3.629-2.457-4.645-2.338c0.229-0.027,0.463-0.085,0.675-0.13
        c0.652-0.136,1.391-0.29,2.255-0.319c0.721-0.023,1.438-0.016,2.157-0.008l0.842,0.008c0.069-0.003,0.185-0.014,0.303-0.021
        c1.043-0.075,2.788-0.201,4.725,0.466l1.244,0.43l0.106-0.985l5.262-0.435c0.362-0.111,0.818-0.233,1.356-0.315
        c0.63-0.096,1.223-0.115,1.768-0.082c0.138-0.129,0.276-0.255,0.416-0.381c1.05-0.95,1.981-1.47,2.756-1.854
        c0.257-0.128,0.505-0.238,0.736-0.333c0.003-0.718,0.09-1.527,0.344-2.369c-0.103-0.359-0.196-0.75-0.268-1.17
        c-0.317-1.843-0.115-3.834,0.179-5.082c0.028-0.12,0.051-0.247,0.075-0.374c0.128-0.661,0.321-1.662,0.778-2.774
        c0.618-1.511,1.498-2.652,2.245-3.481c-0.019-0.267-0.029-0.551-0.028-0.853c0-0.01,0-0.019,0.001-0.028
        c-0.082-0.284-0.158-0.606-0.217-0.962c-0.626-3.842,1.418-6.355,2.09-7.182l0.226-0.301c0.542-0.777,1.764-2.526,3.999-3.527
        c0.295-0.377,0.642-0.751,1.052-1.108c0.614-0.534,1.225-0.901,1.733-1.155c0.51-0.571,1.245-1.366,2.276-2.097
        c0.006-0.017,0.013-0.033,0.019-0.05l1.888-5.005l0.22,0.019c0.608-1.104,1.358-2.318,2.431-3.481
        c0.62-0.693,1.744-1.95,3.568-2.648c0.089-0.033,0.178-0.065,0.266-0.096c0.163-0.14,0.339-0.28,0.527-0.418
        c0.012-0.009,0.023-0.018,0.036-0.026c-0.148-0.425-0.273-0.893-0.364-1.408c-0.192-1.102-0.127-2.013-0.088-2.557l0.004-0.052
        l-0.001-0.096c-0.007-1.36-0.021-4.153,2.198-6.874c0.048-0.138,0.092-0.285,0.145-0.445c0.794-2.43,2.273-4.077,3.158-5.061
        c0.275-0.307,0.552-0.581,0.827-0.826c0.016-2.354,0.921-4.573,2.654-6.281c0.006-0.012,0.012-0.024,0.018-0.036l0.082-0.203
        c1.781-4.438,5.124-5.604,7.615-5.8c0.274-0.021,0.537-0.032,0.788-0.036c0.772-0.712,1.801-1.364,3.161-1.717
        c0.293-0.076,0.617-0.141,0.966-0.185l0.29-3.671l4.997-0.268c0.889-0.408,2.171-0.793,3.761-0.812
        c0.907,0.009,1.675,0.146,2.218,0.267c0.317-0.438,0.698-0.876,1.154-1.298c1.944-1.792,4.514-2.616,7.036-2.257
        c2.156,0.305,4.12,1.438,5.559,3.17c0.218,0.055,0.423,0.116,0.613,0.18c0.646,0.072,1.407,0.235,2.212,0.577
        c1.323,0.563,2.262,1.39,2.864,2.027c0.657,0.353,1.506,0.901,2.305,1.759c2.137,2.293-0.503,2.728-1.405,2.625l-1.59,14.083
        c-3.093-0.349-4.827-1.911-5.66-2.661c-1.663-1.496-2.429-3.189-2.813-4.577c-0.714-0.014-1.567-0.111-2.481-0.405
        c-0.042,0.024-0.085,0.048-0.128,0.072c-2.29,1.271-4.572,1.069-5.802,0.962l-0.717-0.015c-0.327-0.001-0.653-0.004-0.983-0.001
        c-0.044,0.249-0.102,0.51-0.177,0.781l-1.186,4.268l-4.356,0.804c-0.608,0.112-1.185,0.166-1.728,0.172l-4.512,6.278
        c-1.659,2.309-4.032,3.035-5.563,3.358c-0.232,0.656-0.521,1.366-0.94,2.12c0.582,1.107,1.106,2.521,1.25,4.171l0.014,0.143
        c0.181,1.973,0.272,4.262-0.931,6.475c-0.238,0.443-0.507,0.828-0.777,1.159c-0.187,0.368-0.426,0.766-0.732,1.169
        c-1.407,1.857-3.089,2.622-4.518,2.9c-0.22,0.077-0.424,0.144-0.581,0.188c-0.429,0.383-0.95,0.771-1.578,1.108
        c-0.257,0.138-0.512,0.256-0.762,0.357c-0.016,0.027-0.03,0.055-0.044,0.081c-0.067,0.822-0.255,2.16-0.984,3.523
        c-0.179,0.884-0.524,1.904-1.186,2.939c-1.326,2.078-3.072,3.02-4.323,3.471c-0.387,0.427-0.898,0.963-1.545,1.488
        c-0.354,0.683-0.852,1.398-1.552,2.068c-0.541,0.515-1.106,0.91-1.668,1.215c0.028,0.133,0.056,0.271,0.082,0.417
        c0.736,4.083-1.339,6.694-2.12,7.678c-0.5,0.628-0.98,1.117-1.367,1.51c-0.014,0.015-0.028,0.029-0.042,0.044
        c-0.016,0.076-0.03,0.152-0.046,0.229c0.558,1.287,1.499,3.71,0.751,6.765l-0.309,1.259c0.113,1.661-0.125,3.876-1.695,6.037
        c-1.358,1.864-3.052,2.783-4.063,3.333c-0.108,0.059-0.216,0.114-0.316,0.174c-0.58,0.388-1.149,0.674-1.654,0.889
        c-0.878,2.116-2.47,3.266-3.329,3.758c-1.925,1.109-4.185,1.228-5.882,1.095c-1.899,1.359-3.97,1.555-4.624,1.581
        C638.319,430.48,637.874,430.487,637.445,430.487z M659.804,405.996c-0.006,0.024-0.012,0.048-0.017,0.072
        C659.793,406.047,659.798,406.022,659.804,405.996z M703.415,345.461c0.02,0,0.039,0,0.058,0H703.415z" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea" stroke-opacity="0"></path>
    <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="796.23" y1="398.207" x2="657.261" y2="398.62" pointer-events="none" opacity="0"></line>
    <path id="RIVER_2_" fill="none" stroke="#95bec8" stroke-miterlimit="10" d="M721.147,345.042c-0.847-0.096-1.17-0.397-1.74-0.91
        c-0.673-0.606-0.767-1.317-0.898-2.269c-0.091-0.662,0.134-1.142-0.346-1.657c-0.386-0.415-1.052-0.554-1.478-0.868
        c-0.428-0.315-0.778-1.015-1.28-1.228c-0.321-0.136-0.606,0.001-0.935-0.049c-0.33-0.05-0.538-0.301-0.898-0.369
        c-0.65-0.122-1.476,0.236-2.099-0.033c-0.666-0.288-1.001-1.415-1.335-1.966c-0.589-0.972-1.625-1.441-2.574-0.566
        c-0.934,0.861-0.569,2.543-1.735,3.19c-0.542,0.301-1.76,0.058-2.356,0.058c-0.664,0-1.329-0.01-1.992-0.001
        c-0.795,0.01-1.386-0.33-2.167-0.338c-0.91-0.009-1.251,0.692-2.109,0.737c-0.087,1.102-1.411,1.597-1.813,2.528
        c-0.16,0.37-0.209,0.647-0.318,1.077c-0.078,0.308-0.236,0.521-0.27,0.862c-0.039,0.387,0.066,0.712-0.042,1.102
        c-1.511,0.279-1.797-0.561-2.883-1.324c-0.866-0.608-0.955-0.184-1.399,0.708c-0.692,1.391-3.163,0.871-4.5,0.976
        c-1.328,0.104-1.344,0.889-1.832,1.934c-0.214,0.458-0.164,0.689-0.317,1.089c-0.173,0.451-0.656,0.569-1.025,0.886
        c-0.955,0.82-0.779,1.901-0.242,2.948c0.359,0.7,0.764,1.706,1.49,2.066c-0.396,0.552-1.927,0.621-2.571,0.832
        c-0.848,0.278-1.344,0.35-1.984,1.061c-0.673,0.748-1.369,1.53-1.685,2.498c-0.356,1.09-0.585,1.805-1.341,2.684
        c-0.727,0.845-0.659,1.616-0.659,2.675c0,0.376-0.087,0.901-0.024,1.259c0.112,0.635,0.31,0.592,0.7,1.073
        c0.51,0.63,0.936,1.51,1.007,2.317c0.052,0.599,0.22,2.061-0.082,2.617c-0.1,0.185-0.384,0.302-0.517,0.466
        c-0.224,0.277-0.23,0.722-0.409,0.958c-0.29,0.382-0.155,0.147-0.676,0.307c-0.22,0.068-0.56,0.21-0.74,0.26
        c-0.721,0.204-1.141-0.058-1.757,0.391c-0.429,0.313-0.757,1.037-1.217,1.284c-0.368,0.198-0.831-0.025-1.221,0.124
        c-0.258,0.099-0.734,0.665-0.891,0.835c-0.722,0.782-1.229,1.702-1.716,2.618c-0.362,0.68-0.467,1.118-0.624,1.85
        c-0.12,0.564,0.08,1.766-0.776,1.695c-0.28,0.743,0.113,1.457-0.366,2.209c-0.589,0.922-1.375,0.551-2.18,1.118
        c-0.863,0.608-1.426,1.568-2.251,2.208c-0.34,0.264-0.902,0.345-1.203,0.607c-0.55,0.479-0.212,1.31-0.712,1.787
        c-0.344,0.329-1.241,0.291-1.689,0.342c-0.892,0.102-1.278,0.975-1.843,1.698c-0.476,0.609-0.918,0.999-0.786,1.808
        c0.056,0.346,0.265,0.637,0.32,0.997c0.056,0.367-0.01,0.797-0.012,1.17c-0.004,0.806,0.213,1.245,0.35,2
        c0.166,0.92-0.165,1.341-0.695,2.007c-0.787,0.989-1.9,1.755-2.403,2.985c-0.273,0.666-0.347,1.38-0.512,2.083
        c-0.144,0.613-0.201,1.623-0.091,2.259c0.238,1.39,1.326,2.481,0.968,3.943c-1.275-0.025-0.946,1.083-0.784,1.887
        c0.205,1.018,0.593,2.016-0.07,2.928c-0.597,0.82-1.72,1.207-2.521,1.742c-0.755,0.504-1.437,0.518-2.242,0.918
        c-0.457,0.227-0.767,0.413-1.138,0.749c-0.157,0.142-0.729,0.651-0.804,0.816c-0.284,0.627,0.297,1.52-0.451,1.95
        c-0.549,0.315-2.334,0.181-2.95,0.001c-0.591-0.172-0.996-0.646-1.674-0.542c-0.416,0.064-0.806,0.286-1.267,0.324
        c-0.107,0.991-0.22,1.911-1.376,1.957c-1.271,0.051-2.555-0.02-3.827-0.007c-0.914,0.009-1.697-0.247-2.587-0.406
        c-0.456-0.082-1.165-0.132-1.598-0.253c-0.414-0.115-0.805,0.059-0.695-0.617c-0.804-0.278-1.861-0.057-2.724-0.057
        c-0.915,0-1.837-0.034-2.752-0.003c-0.925,0.031-3.414,0.977-4.157,0.003" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></path>
    <path fill="none" stroke="#95bec8" stroke-miterlimit="10" d="M637.443,438.991c-0.548,0-1.052-0.009-1.5-0.017
        c-0.38-0.006-0.76-0.015-1.14-0.011c-2.388,0.026-4.28-0.387-5.296-0.608l-0.071-0.015c-0.476-0.061-1.145-0.149-1.934-0.313
        c-0.634-0.106-1.237-0.245-1.812-0.408c-10.123,1.83-15.568-3.014-17.937-6.119l6.896-5.264c-0.532-2.122-0.628-4.367-0.262-6.63
        l7.382,1.195l2.699-2.061l1.193-11.123c-2.271-0.774-3.481-1.108-1.886-1.163c0.856-0.027,1.705-0.023,2.554-0.012l0.46,0.005
        c0.89-0.066,2.527-0.18,4.538,0.086l5.818-0.521c-0.01-1.696,0.172-3.429,0.54-5.033l0.028-0.146
        c0.169-0.872,0.482-2.494,1.261-4.39c0.385-0.939,0.821-1.79,1.275-2.554c-0.188-3.319,0.524-7.648,3.99-11.971
        c1.731-2.457,3.654-4.16,5.561-5.332c0.278-0.279,0.554-0.534,0.819-0.766c0.673-0.586,1.34-1.072,1.977-1.479
        c0.141-0.141,0.289-0.288,0.447-0.438l3.39-8.987l1.76,0.146c0.087-0.1,0.177-0.199,0.268-0.298
        c0.36-0.403,0.943-1.051,1.758-1.773c0-0.005,0-0.01,0.001-0.016c-0.005-1.809,0.102-6.224,3.079-10.671
        c1.054-2.761,2.545-4.802,3.67-6.146c0.585-2.598,1.767-5.049,3.513-7.17c3.098-6.407,8.26-8.669,11.811-9.46l0.441-5.597
        l11.065-0.592c1.277-0.43,2.839-0.788,4.641-0.876c3.618-2.687,8.089-3.855,12.545-3.226c3.193,0.452,6.155,1.796,8.609,3.846
        c0.648,0.185,1.285,0.41,1.91,0.676l1.39,5.787l-1.376,12.187c0.188-0.46,0.3-0.819,0.36-1.032c-0.119,0.43-0.247,0.85-0.386,1.261
        l-2.097,18.568c-5.804-0.655-9.142-3.661-10.397-4.793c-0.342-0.308-0.663-0.618-0.965-0.933l-0.86,3.098l-8.068,1.488
        l-2.392,3.329c-1.13,1.572-2.411,2.78-3.709,3.714c0.008,0.078,0.016,0.157,0.022,0.235l0.009,0.104
        c0.183,1.989,0.61,6.647-1.928,11.314c-0.329,0.612-0.703,1.205-1.119,1.775c-0.309,0.528-0.67,1.08-1.093,1.637
        c-2.145,2.83-4.653,4.429-6.958,5.319c-0.063,0.16-0.128,0.318-0.194,0.474c-0.396,1.455-1.043,3.079-2.101,4.734
        c-1.232,1.932-3.263,4.272-6.458,5.94c-0.344,0.507-0.718,0.994-1.12,1.463c-0.257,2.78-1.203,5.437-2.832,7.947
        c0.295,1.979,0.317,4.346-0.328,6.98l-0.052,0.214c-0.029,2.766-0.662,5.351-1.896,7.735l-1.023,4.177l-1.929-0.038
        c-2.003,2.105-4.079,3.287-5.192,3.894c-1.348,1.934-3.134,3.576-5.304,4.821c-2.641,1.521-5.43,2.103-7.892,2.232
        c-2.088,0.961-4.32,1.475-6.501,1.563C638.528,438.983,637.965,438.991,637.443,438.991z M630.401,410.317
        c-3.048,2.109-5.139,5.262-5.848,8.361l7.646-5.837c0.07-0.143,0.143-0.283,0.215-0.421
        C631.786,411.619,631.108,410.922,630.401,410.317z" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></path>
    <circle id="CITY_14_" opacity="0" fill="#166c38" enable-background="new    " cx="656.867" cy="398.675" r="6.5" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>

        <rect id="rect12_16_" x="782.838" y="389.242" opacity="0" fill="#FFFFFF" enable-background="new    " width="30.723" height="17" pointer-events="none"></rect>
    <path id="SEMITRANSPARENT" opacity="0" fill="#166c38" enable-background="new    " d="M622.729,420.365
        c-1.049,0.15-2.12,0.228-3.201,0.228c-2.193,0-4.219-0.666-6.004-1.777c-0.151,0.233-0.293,0.477-0.428,0.731
        c-1.347,2.54-1.441,4.699-1.093,6.354l-3.887,0.897l-0.097,4.684c-0.279,0.77-0.623,1.971-0.601,3.495
        c0.003,0.248-0.009,0.502-0.019,0.757c-0.025,0.703-0.057,1.569,0.02,2.543c-0.327,0.41-0.584,0.81-0.783,1.165
        c-1.019,1.049-1.709,2.307-2.058,3.752c-0.513,2.117-0.404,5.196,0.259,7.321c0.591,1.894,1.526,3.456,2.277,4.711
        c0.088,0.146,0.187,0.312,0.287,0.481c-0.76,0.494-1.334,0.997-1.747,1.382c-5.078,1.288-6.757,4.791-7.266,7.537
        c-0.838,4.505-0.106,8.903,2.118,12.729c0.048,0.111,0.094,0.229,0.142,0.348c0.388,0.959,0.921,2.272,1.987,3.501
        c0.62,0.713,1.337,1.294,2.124,1.73c2.55,2.875,5.795,4.609,9.282,4.966c2.418,2.761,5.097,3.403,7.183,3.403
        c1.248,0,2.284-0.23,2.928-0.372c0.156-0.034,0.32-0.071,0.494-0.105c0.397-0.079,0.797-0.175,1.197-0.275
        c0.187-0.047,0.372-0.101,0.56-0.132c-0.012,0.001-0.283,0.045-0.701-0.002l1.604-14.082c-1.705-0.195-3.147,0.053-4.226,0.303
        c-0.473-0.485-1.021-0.969-1.667-1.397c-2.362-1.562-4.731-1.494-5.781-1.453c-0.069-0.056-0.166-0.144-0.278-0.271
        c-0.31-0.444-0.931-1.321-1.996-2.146c-0.16-0.35-0.346-0.719-0.57-1.096c-0.12-0.202-0.222-0.412-0.303-0.631
        c0.436-0.237,0.884-0.522,1.333-0.867c0.59-0.453,1.041-0.882,1.34-1.165c0.028-0.027,0.057-0.056,0.085-0.083
        c1.943-1.149,3.345-2.578,4.522-4.599l0.153-0.249c0.361-0.576,0.95-1.514,1.333-2.889l1.72-0.984l-1.137-5.099
        c-0.531-2.377-1.654-4.253-2.555-5.761c-0.037-0.06-0.075-0.124-0.114-0.19c0.79-0.529,1.575-1.255,2.24-2.262
        c1.864-2.835,1.381-5.658,0.7-7.531l4.403,0.136l-0.011-5.193c1.213-2.017,1.191-3.978,1.11-5.033
        c0.077-0.722,0.191-1.926-0.051-3.287c0.686-0.553,1.214-1.084,1.618-1.489l0.41-0.394c0.699-0.62,1.87-1.659,2.662-3.217
        c0.001-0.002,0.001-0.003,0.002-0.005C629.233,420.693,625.848,421.022,622.729,420.365z" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea" stroke-opacity="0"></path>
    <path opacity="0.4" fill="#166c38" enable-background="new    " d="M600.597,477.509c0.004,0.006,0.007,0.011,0.01,0.017
        C600.604,477.52,600.6,477.515,600.597,477.509z" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></path>
    <path opacity="0.4" fill="#166c38" enable-background="new    " d="M614.977,467.68c-0.001,0.001-0.003,0.002-0.004,0.002
        C614.974,467.682,614.976,467.681,614.977,467.68z" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></path>
    <path id="RIVER_4_" fill="none" stroke="#95bec8" stroke-miterlimit="10" d="M621.19,420.663c-1.189,1.712-2.189,3.712-0.189,5.712
        c0,1-1,2-1,3c-1,3-6,3-5,6c1,5-3,7-3,10c-1,5,5,10,1,15c-3,3-9,4-8,10c1,3,2,7,5,8c6,1,10,8,16.499,4.998" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></path>
    <path fill="none" stroke="#95bec8" stroke-miterlimit="10" d="M624.968,496.82l-1.034,9.084
        C626.254,506.171,626.093,499.686,624.968,496.82z" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></path>
    <path fill="none" stroke="#95bec8" stroke-miterlimit="10" d="M587.197,485.479c0.005,0.008,0.009,0.016,0.014,0.022
        C587.206,485.494,587.202,485.486,587.197,485.479z" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></path>
    <path fill="none" stroke="#95bec8" stroke-miterlimit="10" d="M660.695,342.691c-0.003,0.007-0.006,0.013-0.01,0.02
        C660.689,342.705,660.692,342.698,660.695,342.691z" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></path>
</g>
<g id="CITY_PACIFICOCEAN" data-colors="0" data-errors="0" hint-index="0" class="">

        <ellipse id="_x3C_Path_x3E__1_" fill="none" stroke="#95bec8" stroke-miterlimit="10" cx="249.33" cy="402.592" rx="61.786" ry="53.83" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></ellipse>

        <rect id="rect12_18_" x="235.451" y="375.541" opacity="0" fill="#FFFFFF" enable-background="new    " width="30.723" height="17" pointer-events="none"></rect>
    <circle id="CITY_1_" opacity="0" fill="#166c38" enable-background="new    " cx="250.275" cy="403.793" r="6.5" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>
</g>
<g id="CITY_ATLANTIC" data-colors="0" data-errors="0" hint-index="0" class="">

        <ellipse id="_x3C_Path_x3E__2_" fill="none" stroke="#95bec8" stroke-miterlimit="10" cx="820.6" cy="84.755" rx="61.786" ry="53.83" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></ellipse>

        <rect id="rect12_19_" x="806.721" y="57.704" opacity="0" fill="#FFFFFF" enable-background="new    " width="30.723" height="17" pointer-events="none"></rect>
    <circle id="CITY_2_" opacity="0" fill="#166c38" enable-background="new    " cx="821.545" cy="85.956" r="6.5" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>
</g>
<g id="CITY_TRINIDADANDTOBAGO" data-colors="0" data-errors="0" hint-index="0" class="">

        <ellipse id="_x3C_Path_x3E__3_" fill="none" stroke="#95bec8" stroke-miterlimit="10" cx="600.518" cy="122.286" rx="13.185" ry="11.487" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></ellipse>

        <rect id="rect12_20_" x="586.638" y="95.235" opacity="0" fill="#FFFFFF" enable-background="new    " width="30.723" height="17" pointer-events="none"></rect>
    <circle id="CITY_3_" opacity="0" fill="#166c38" enable-background="new    " cx="601.463" cy="123.487" r="6.5" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>
</g>
</svg>
}