export default function Europe() {
    return <svg version="1.1" id="svgpoint" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 900 725" enable-background="new 0 0 900 725" width="100%" height="100%" preserveAspectRatio="xMidYMid meet">
<rect id="WATER" x="-9.982" y="-0.663" fill="#a4d1dc" stroke="#95bec8" stroke-miterlimit="10" width="899.48" height="725" stroke-width="0.1" opacity="1" class="water"></rect>
<g id="LAND" data-colors="0" data-errors="0" hint-index="0" class="">
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M854.068,656.892c-1.293,0.857-2.33,1.727-2.641,2.461
        c-1.024,2.416-1.063,6.128-5.486,8.595c-4.423,2.466-6.835,3.812-6.835,3.812s-1.632,14.088-0.46,19.497
        s-4.891,17.224-4.891,17.224l-33.547,21.608l0.054,0.336l3.36,21.09l0.418-0.487l181.004,17.983l-90-201.565
        C894.075,567.967,854.947,656.308,854.068,656.892z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M766.01,705.93c1.839,1.515-0.75,2.765-0.042,2.895
        s1.274,2.63,2.179,3.561c0.905,0.93-1.052,4.203-3.167,5.162s-1.936,2.073-1.63,3.117c0.306,1.044-0.551,0.942-1.773,1.127
        c-1.223,0.185-1.85,0.148-3.375,1.275s-1.037,1.655-0.693,2.816c0.344,1.161,0.1,1.021-0.919,2.114s-1.265,2.582-3.99,3.44
        c-1.072,0.338-1.895,0.986-2.502,1.649c0.593,0.481-0.799,3.647-0.695,4.914c0.064,0.776-0.144,2.144-0.468,3.365
        c1.299,0.425,2.473,1.282,4.611,2.886c3.812,6.835,10.384,6.333,10.384,6.333s11.974-6.414,26.716-15.426
        c4.214-3.235,7.331-4.491,9.615-4.733l-0.054-0.336l33.547-21.608c0,0,6.062-11.815,4.891-17.224
        c-1.172-5.409,0.46-19.497,0.46-19.497s2.412-1.346,6.835-3.812c4.423-2.467,4.462-6.179,5.486-8.595
        c0.311-0.733,1.349-1.604,2.641-2.461c-0.268-0.57-0.596-1.16-0.94-1.777c-1.166-2.091-3.254,0.835-11.653,2.975
        c-8.4,2.139-6.545-0.853-9.666-0.482c-3.121,0.37-9.026,5.229-14.735,7.63s-11.521,2.51-15.209,2.217
        c-3.687-0.293-3.527-3.515-5.454-3.811c-1.927-0.296-3.684,1.468-9.295,2.639c-5.611,1.171-3.983,1.634-5.477,2.467
        s-3.256-0.925-4.517-0.026c-1.261,0.899-1.697-1.99-3.36-3.216c-1.663-1.226-3.122,0.37-3.98,3.394
        c-0.859,3.024-2.538,3.922-1.406,5.249c1.131,1.327,2.449,2.588,0.091,2.924s-2.553,2.794-3.613,4.756
        c-1.06,1.961-1.823,1.996-2.82,1.964c-0.417-0.014-1.439-0.682-2.486-1.456c-0.449,2.634-1.585,4.933-1.567,7.18
        c2.106,4.138,1.121,8.521,0.022,13.302c0.615,1.08,0.968,1.995,1.116,2.789c1.854,0.059,3.458,0.123,4.193-0.413
        C764.116,704.611,764.172,704.415,766.01,705.93z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M745.32,737.014c1.061-0.048,2.003-1.525,2.51-2.08
        c0.506-0.554,0.62-1.569,1.934-1.894c0.15-0.037,0.258-0.017,0.334,0.045c0.607-0.663,1.43-1.312,2.502-1.649
        c2.725-0.857,2.97-2.346,3.99-3.44c1.019-1.093,1.263-0.953,0.919-2.114s-0.832-1.689,0.693-2.816
        c1.525-1.127,2.153-1.091,3.375-1.275c1.222-0.185,2.079-0.083,1.773-1.127c-0.306-1.044-0.486-2.158,1.63-3.117
        c2.115-0.959,4.073-4.232,3.167-5.162s-1.471-3.431-2.179-3.561c-0.708-0.13,1.88-1.38,0.042-2.895
        c-1.839-1.515-1.895-1.317-2.699-0.731c-0.735,0.536-2.339,0.472-4.193,0.413c0.657,3.52-2.739,4.648-5.481,7.109
        c-3.224,2.538-7.692,12.494-8.725,15.827c-0.928,2.994-1.787,3.294-3.69,7.459c0.988,0.17,1.993,0.293,2.412,0.18
        C744.58,735.931,744.26,737.061,745.32,737.014z" pointer-events="none" class="landarea"></path>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" d="M762.796,793.889l11.456-0.828l2.01-7.079l6.934-4.662
        l-15.191-16.554l31.459-8.408l4.156-4.844l-3.36-21.09c-2.283,0.242-5.4,1.497-9.615,4.733
        c-14.742,9.012-26.716,15.426-26.716,15.426s-6.572,0.502-10.384-6.333c-2.138-1.604-3.313-2.461-4.611-2.886
        c-0.273,1.028-0.628,1.952-0.975,2.332c-0.76,0.832-2.031,12.685-2.165,15.614s-2.629,13.563-2.629,13.563
        s-4.528,9.185-5.377,11.561s-1.994,7.636-3.298,14.072c-0.382,1.886-0.832,3.476-1.277,4.791c0.292,0.258,0.36,1.027,0.286,1.966
        c3.802,0.542,14.223,2.004,18.267,2.325C756.754,807.985,762.796,793.889,762.796,793.889z" pointer-events="none" class="landarea"></path>
    <polygon fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" points="213.66,725.702 213.052,724.904
        212.437,723.943 212.315,722.96 213.102,722.105 213.404,721.436 213.88,721.004 212.095,719.597 211.398,718.639 210.691,717.434
        209.518,716.907 208.821,715.949 208.055,715.321 207.667,713.858 207.667,713.858 205.735,712.868 203.997,712.61
        201.955,712.939 199.264,711.486 197.709,709.658 198.857,711.585 198.963,712.157 197.445,711.233 197.315,710.087
        197.351,708.933 196.975,707.798 196.925,706.566 196.809,705.748 196.193,706.761 195.497,707.858 195.022,708.288
        194.115,708.243 193.722,708.67 192.589,709.129 190.133,709.392 187.212,708.36 185.517,707.115 183.672,708.259 183.347,708.355
        182.154,707.334 181.326,707.204 180.088,707.09 179.198,707.454 176.982,707.545 175.842,707.839 173.103,707.21 172.768,707.059
        167.281,703.584 166.324,702.306 164.94,700.636 163.878,698.788 162.76,697.6 162.778,696.037 162.252,695.235 162.222,694.498
        162.316,692.767 163.428,691.816 163.428,691.816 163.261,691.741 161.76,691.227 160.052,691.707 158.184,692.277 156.535,692.18
        155.399,692.556 153.494,692.222 152.665,694.064 151.672,695.915 149.514,699.457 146.218,705.347 145.989,705.769
        140.406,714.056 135.859,719.504 135.777,719.508 133.658,721.98 131.898,723.202 128.511,724.821 127.529,724.944 124.614,726.05
        123.704,725.921 122.9,726.366 120.391,727.373 118.989,727.266 117.128,727.999 115.414,728.316 115.174,728.491 114.353,728.524
        113.378,728.811 113.292,728.732 110.107,729.274 107.832,729.941 106.388,730.822 104.496,730.819 104.51,731.147
        105.452,754.217 203.149,750.225 212.18,749.857 212.069,747.147 212.97,745.055 213.44,744.459 213.445,742.569 212.902,741.357
        211.883,740.577 212.637,738.9 212.59,737.751 213.887,733.258 213.069,729.344 212.694,728.207 213.3,726.949 214.248,726.006
        " pointer-events="none" class="landarea"></polygon>
    <polygon fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" points="357.603,740.545 359.499,738.658
        361.042,738.184 361.927,735.681 364.07,733.785 366.322,732.542 367.595,731.503 367.818,730.918 368.376,730.483
        368.267,729.831 368.79,728.576 368.719,724.795 369.165,723.626 369.81,721.296 370.738,719.86 371.019,718.699 369.689,718.342
        369.074,717.379 369.458,714.733 369.513,712.017 368.95,710.313 368.126,708.29 368.434,705.728 368.743,703.248 369.1,701.918
        369.613,698.361 369.546,696.72 368.79,696.339 366.711,695.766 368.611,693.962 369.569,693.264 370.613,692.647 370.967,691.233
        370.194,690.443 371.409,689.983 372.63,689.686 373.664,688.821 373.069,688.352 373.029,687.366 373.029,687.366
        373.029,687.366 370.186,688.222 369.764,687.993 368.199,687.892 366.947,687.451 365.982,687.983 360.508,688.865
        360.337,688.707 360.385,687.884 360.592,686.887 358.868,686.959 357.797,686.92 356.269,685.748 355.451,685.865 354.87,685.723
        354.689,685.319 354.036,685.428 352.801,685.397 351.826,685.683 352.608,686.72 350.618,688.365 349.473,688.493
        348.091,688.879 346.23,687.639 345.246,687.68 344.09,687.561 342.589,687.048 342.569,686.555 342.306,686.155 341.615,685.36
        338.677,685.892 337.81,686.832 337.829,687.324 337.775,687.985 335.842,688.967 333.57,689.719 330.782,689.915 329.152,690.311
        327.784,691.024 327.161,691.871 325.395,692.931 321.267,692.524 320.251,691.824 320.368,690.668 319.281,690.22
        318.266,689.522 316.758,688.842 315.667,688.311 314.521,688.441 312.954,688.34 311.638,688.311 310.553,687.944
        309.408,688.074 308.333,687.954 306.859,688.095 304.717,688.019 303.151,687.918 302.396,687.538 301.171,687.753
        299.794,688.303 298.923,689.077 297.622,689.459 296.161,689.931 294.591,689.748 293.258,689.309 292.529,689.585
        291.734,690.275 290.227,689.597 288.965,688.908 286.713,690.152 284.635,691.636 280.807,692.532 280.144,692.394
        279.874,691.829 277.49,691.845 275.375,692.425 273.737,692.574 272.164,692.308 269.458,692.501 264.514,692.293
        263.297,692.671 261.327,692.751 257.829,693.717 256.96,694.574 255.096,695.227 254.773,695.405 254.773,695.405
        250.557,696.892 248.451,697.636 247.419,698.582 246.284,698.959 245.569,699.563 244.795,700.746 243.408,703.022
        240.916,704.441 238.229,703.069 238.12,702.417 237.19,701.796 236.711,702.146 235.719,702.022 235.273,703.191 234.322,704.051
        233.757,704.321 233.363,704.748 232.46,704.785 231.872,704.48 230.443,703.717 229.484,704.413 228.092,704.553 226.634,705.105
        226.244,705.616 225.597,705.889 224.396,706.678 223.852,707.439 223.238,708.535 222.402,710.212 221.122,711.087
        218.995,711.339 217.626,712.053 216.27,713.095 214.33,713.915 213.598,714.108 212.619,714.314 210.485,714.4 207.667,713.858
        207.667,713.858 207.667,713.858 208.055,715.321 208.821,715.949 209.518,716.907 210.691,717.434 211.398,718.639
        212.095,719.597 213.88,721.004 213.404,721.436 213.102,722.105 212.315,722.96 212.437,723.943 213.052,724.904 213.66,725.702
        214.248,726.006 213.3,726.949 212.694,728.207 213.069,729.344 213.887,733.258 212.59,737.751 212.637,738.9 211.883,740.577
        212.902,741.357 213.445,742.569 213.44,744.459 212.97,745.055 212.069,747.147 212.18,749.857 357.001,743.941 357.266,742.368
        " pointer-events="none" class="landarea"></polygon>
    <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M376.872,676.766l0.555-0.516
        L376.872,676.766L376.872,676.766L376.872,676.766z M412.574,717.329l-1.88-1.732l0.06-2.552l-0.112-0.736l-0.447-0.886
        l-0.816-1.858l-1.826-0.419l-0.612-0.88l-0.112-0.736l-1.892-0.005l-1.552-1.746l-1.213-1.513l-1.201-3.24l0.821-4.063l2.563-1.667
        l1.615-0.724l0.734-2.168l1.066-2.099l0.918-1.682l1.345-1.288l-0.665-2.193l-0.3-1.304l-0.762-0.544l-1.047,0.536l-0.705,0.851
        l-0.709,0.769l-0.876,0.694l-1.27,1.121l-1.871,0.488l-0.672,1.672l-1.523,0.967l0,0l-1.836-0.665l-0.263-0.4l0,0l0,0l-0.927-0.538
        l0.804-0.444l0.427,0.394l0.767-1.347l-2.584-2.855l0.374-0.92l0.41-0.017l-0.188-0.568l-1.015-0.699l0.995,0.206l0.715-0.605
        l-0.664-0.137l-0.591-0.387l-1.166-0.364l-0.999-0.288l-0.565,0.27l-1.82-0.255l-0.627,0.766l1.32,0.111l-0.288,0.999l-1.392,0.139
        l-0.536-1.047l0.88-0.612l0.37-1.002l-0.841-0.459l-1.977-0.084l-0.962,0.615l-1.458,0.553l-1.783,0.648l-1.461,0.471l-1.625,0.478
        l-1.362,0.878l-2.684,2.741l0,0l-2.094,1.072l-1.954,0.491l0,0l0.04,0.985l0.595,0.469l-0.952,0.861l-1.303,0.3l-1.215,0.461
        l0.772,0.791l-0.357,1.33l-1.04,0.7l-0.958,0.697l-1.9,1.805l2.158,0.487l0.756,0.38l-0.015,1.645l-0.513,3.557l-0.353,1.412
        l-0.313,2.398l-0.224,2.558l0.823,2.022l0.563,1.704l-0.054,2.716l-0.385,2.647l0.615,0.962l1.248,0.36l-0.285,1.081l-0.925,1.518
        l-0.727,2.332l-0.364,1.166l-0.007,3.865l-0.524,1.255l0.191,0.65l-0.558,0.434l-0.223,0.585l-1.273,1.039l-2.252,1.243
        l-2.061,1.893l-0.885,2.503l-1.543,0.474l-1.897,1.887l-0.337,1.823l-0.265,1.573l40.228-1.643l-0.985-3.989l1.692-2.865
        l0.801-0.526l0.643-0.355l0.64-0.437l0.719-0.523l1.27-1.121l1.191-1.036l1.293-0.546l0.764-1.429l1.516-1.131l0.823-2.007
        l1.03-0.947l0.692-1.18l0.488-2.158l0.987-2.014l0,0L412.574,717.329L412.574,717.329z M413.092,727.999l0.124-0.992l0.462-0.759
        l0.493-0.02l0.61-1.176l0.831,0.213l-0.384,0.674l0.181,0.404l-0.479,0.349L413.092,727.999L413.092,727.999L413.092,727.999z" pointer-events="none" class="landarea"></path>

        <path id="MALTA" fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M446.826,680.761c0,0-0.699,0.961,0,1.313c0.7,0.35,1.662,0.569,1.792,1.18c0.131,0.612-0.744,1.137,0.961,1.946
        c1.705,0.809,2.973,1.64,3.278,0.809c0.307-0.831,0.612-0.787-0.219-1.837c-0.83-1.049-2.055-1.312-2.317-2.1
        c-0.262-0.787-0.787-1.4-1.4-1.313s-1.093,0.787-1.399,0.349C447.219,680.673,447.125,680.064,446.826,680.761z" pointer-events="none" class="landarea"></path>
</g>
<g id="AREA_HUNGARY" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Hungary" data-wikipediaLink="https://en.wikipedia.org/wiki/Hungary" data-infoText="<b>Hungary</b> &amp;nbsp; Capital: Budapest" data-imageFile="HUNGARY.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="HUNGARY" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M571.011,448.573c-2.623-2.017-0.949-2.367-3.922-4.815c-2.975-2.449-4.622-1.749-4.622-3.848c0-0.158,0-0.309,0-0.464
        c-0.843-0.006-1.307,0.103-2.025,0.464c-2.098,1.051-2.848,1.575-3.548,1.575c-0.699,0-4.397-5.633-6.321-4.303
        c-1.924,1.331-2.111,3.821-4.385,2.052c-2.274-1.77-4.204-4.661-5.953-2.254s-3.439,5.032-3.995,6.255
        c-0.555,1.225-3.356-0.175-4.58,1.925c-1.225,2.098-2.1,1.575-2.799,0c-0.698-1.575-4.023-0.874-4.198,0
        c-0.176,0.874-1.837,0.843-2.404,1.384c-0.569,0.54-7.58-0.334-7.66,0.715c-0.08,1.049-2.004,2.799-1.481,4.198
        c0.525,1.4-2.106,2.623-3.678,2.623s-3.844,0.175-6.642,0s-2.274-0.7-4.372-1.749c-2.1-1.049-4.897-4.187-6.297-3.755
        c-1.399,0.432-2.973,2.706-2.798,3.93c0.176,1.224-1.049,2.973-2.623,2.973c-1.575,0-3.148-1.924-3.498-1.4
        c-0.35,0.525-3.149,2.623-1.399,3.148c1.749,0.525,3.149,2.449,2.1,2.798c-1.051,0.35-4.723,0.525-4.547,1.749
        c0.175,1.225,1.269,1.749,0.984,3.849c-0.285,2.098,0.228,5.767-0.991,5.594c-0.745-0.105-2.406,0.382-3.585,1.571
        c1.078,0.014,1.978,0.15,2.103,0.968c0.175,1.138,0.961,2.996,1.749,3.696c0.333,0.297,0.516,1.644,0.617,2.909
        c0.026,0,0.055,0,0.082,0c0.961,0,4.198,2.601,5.073,2.688c0.874,0.087,1.312,1.083,1.4,1.695c0.087,0.612,2.971,3.557,3.936,3.557
        c0.961,0,1.137,0.643,1.224,2.187s0.262,1.225,2.798,0.875c2.536-0.349,0.961-0.102,2.011,0.773
        c1.051,0.873,0.525,1.654,1.487,1.479c0.961-0.175,1.837,0.609,3.062,1.921c1.225,1.312,6.296,0.435,7.963-0.002
        c1.666-0.438,3.233-2.451,4.106-2.713c0.521-0.155,1.137,0.308,1.546,0.71c0.314-0.539,0.824-0.947,1.427-1.148
        c1.05-0.349,0.961-1.312,1.662-1.953c0.7-0.643,1.05,0.381,1.4,0.817c0.349,0.436,2.449-1.4,3.237-1.487s1.924-2.274,2.623-2.885
        c0.7-0.612,1.486,0.175,2.361,1.05c0.874,0.874,2.975-0.087,5.335,0c1.081,0.04,1.756,0.429,2.173,0.839
        c0.289-0.087,0.625-0.162,1.004-0.226c2.1-0.35,2.798,0.116,4.198-1.4c1.399-1.516,1.165-3.498,3.149-2.1
        c1.983,1.4,3.031-1.516,4.43-1.634c1.4-0.116,1.4-1.049,1.4-2.916c0-1.865,0.467-3.032,1.749-4.665
        c1.281-1.631,4.081-1.865,4.198-4.081c0.116-2.214,1.399-4.198,1.631-4.896c0.234-0.699,0.934-1.75,1.4-2.333
        c0.466-0.584,1.049-1.631,1.049-3.149c0-1.516,0.35-1.4,1.049-1.865c0.7-0.467,1.167-0.699,1.866-2.334
        c0.7-1.633,0.117-1.399,1.282-1.749c1.165-0.349,1.283-0.466,2.332-1.749c1.051-1.283,0.7-0.117,2.449,0.816
        c1.749,0.932,1.049-0.816,1.983-1.633c0.932-0.816,1.865-0.816,1.983-2.099c0.042-0.464,0.115-1.109,0.189-1.746
        C572.101,449.137,571.629,449.048,571.011,448.573z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_4_" x="491.316" y="456.756" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_THENETHERLANDS" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Netherlands" data-wikipediaLink="https://en.wikipedia.org/wiki/Netherlands" data-infoText="<b>Netherlands</b> &amp;nbsp; Capital: Amsterdam (The Hague holds the seat of the States General, Cabinet, and Supreme Court)" data-imageFile="NETHERLAND.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="NETHERLANDS" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M329.506,382.54c-0.131-1.4,1.354-2.623,1.18-4.198c-0.175-1.575,0.349-2.798,1.924-5.073c1.575-2.274,0-3.911-1.924-6.883
        c-1.924-2.975,0-2.861,0-2.861c0-0.843,5.248,0,5.248,0s3.148-1.686,4.082-2.561s0.64-2.331,0.991-4.604
        c0.349-2.274,2.623-2.564,3.848-4.664c1.225-2.099-0.35-2.506-2.274-3.206c-1.925-0.699-1.749-1.078-2.449-3.178
        c-0.7-2.099,1.924-1.063,3.674-1.239c1.749-0.175,0.7-2.105,1.049-4.905c0.35-2.798,1.406-0.877,1.58-2.978
        c0.175-2.098-0.345-3.676-0.345-3.676v-3.153c0-0.609-0.497-1.352-0.938-1.746c-1.049-0.933-2.394-1.866-2.743-2.681
        c-0.349-0.817-0.847-0.817-2.945,0c-2.099,0.816-2.522,0.583-6.138,0.232c-3.615-0.349-8.375,3.033-10.589,4.082
        s-1.038,1.749-1.855,3.964c-0.817,2.215-3.493,2.099-6.292,4.198c-2.799,2.098-1.164-0.116-2.096-0.933
        c-0.934-0.816,1.283-2.215,1.168-3.731c-0.116-1.516-1.749-1.749-2.215,0c-0.467,1.749,0.117,2.681-0.816,3.614
        c-0.932,0.933-0.349,3.964-0.467,7.813c-0.116,3.848-5.48,12.243-7.346,13.293c-1.865,1.049-1.983,2.333-2.215,3.615
        c-0.233,1.282-1.516,2.215-3.732,2.681c-2.215,0.467,0.233,1.633,2.215,2.449c1.983,0.816,0.233,1.749-0.584,1.749
        c-0.816,0-3.381,0.583-5.597,1.749c-2.215,1.167,2.682,1.4,5.248,2.332c2.565,0.933,1.865,1.05-0.117,1.283
        c-1.983,0.233-2.332,0-5.712-0.117c-0.268-0.009-0.515-0.005-0.754,0.006c0.172,0.382,0.286,0.763,0.286,1.102
        c0,1.4,1.049,3.324,2.974,2.972c1.924-0.349,3.148,1.134,5.073,0.042c1.925-1.091,4.023-4.315,4.897-3.052
        c0.874,1.262,2.973,0.037,3.324-1.536c0.349-1.575,2.274-2.974,2.975-1.924c0.698,1.05,1.049,2.274,2.623,1.05
        c1.575-1.224,2.623-0.874,2.798,1.224c0.175,2.099,1.749,2.274,4.896,2.449c3.149,0.175,4.722,0.899,4.722,2.198
        c0,1.298-2.448,6.196-1.224,7.771c1.224,1.575,3.937,1.575,4.592,2.098c0.116,0.093,0.229,0.176,0.339,0.258
        C330.457,385.572,329.589,383.426,329.506,382.54z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_2_" x="296.657" y="341.982" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_BELGIUM" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Belgium" data-wikipediaLink="https://en.wikipedia.org/wiki/Belgium" data-infoText="<b>Belgium</b> &amp;nbsp; Capital: Brussels" data-imageFile="BELGIUM.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="BELGIUM" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M327.714,411.75c-0.175-1.399-1.749-2.623-1.575-5.93c0.175-3.306,1.749-1.941,2.449-4.388c0.7-2.449,2.799,0,2.799,0l3.149-5.248
        c0,0,0.7-1.925,0-2.274c-0.699-0.349-2.449-1.749-3.149-3.673c-0.699-1.924-1.225-2.098-1.879-2.623
        c-0.655-0.525-3.368-0.525-4.592-2.098c-1.225-1.575,1.224-6.472,1.224-7.771s-1.575-2.024-4.722-2.198
        c-3.148-0.175-4.722-0.349-4.896-2.449c-0.175-2.099-1.225-2.449-2.798-1.224c-1.575,1.224-1.925,0-2.623-1.05
        c-0.699-1.049-2.624,0.349-2.974,1.924s-2.449,2.798-3.324,1.536c-0.874-1.262-2.972,1.961-4.897,3.052
        c-1.924,1.091-3.148-0.391-5.073-0.042s-2.975-1.575-2.975-2.972c0-0.339-0.115-0.721-0.286-1.102
        c-2.777,0.118-3.717,1.486-5.542,2.561c-1.983,1.167-5.286,3.498-6.568,4.198c-0.108,0.059,0.411,0.109-0.854,0.162v0.655
        c0,0,1.986,6.471,3.781,7.171c1.796,0.698,4.672-1.575,5.546-0.874s0.651,4.023,2.749,5.073c2.099,1.049,5.486,0,5.486,1.924
        c0,1.925,2.83,3.848,4.229,4.023c1.4,0.175,2.29,0.698,2.29,1.924s-0.342,5.455,1.408,5.788c1.749,0.333,5.076,1.59,5.601,0
        c0.525-1.591,3.85-5.788,3.85-3.69c0,2.099-1.924,6.647,1.576,7.347c3.498,0.699,4.897,0.874,5.947,4.022
        c1.049,3.149,1.4,2.798,4.198,2.449c0.79-0.099,1.537-0.083,2.213,0.002C327.646,414.343,327.807,412.509,327.714,411.75z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_3_" x="279.005" y="377.241" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_MONTENEGRO" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Montenegro" data-wikipediaLink="https://en.wikipedia.org/wiki/Montenegro" data-infoText="<b>Montenegro</b> &amp;nbsp; Capital: Podgorica" data-imageFile="MONTENEGRO.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="MONTENEGRO" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M517.927,541.096c-0.612,0.349-2.799-1.4-2.799-0.438c0,0.964,0.874,1.576,1.225,2.012c0.349,0.438,0.961,1.838,0.961,1.838
        s0.175,1.224-0.437,1.313c-0.611,0.087-2.361-2.012-2.711-1.487s-1.4,1.575-1.575,2.449c-0.176,0.874-0.874,1.313-0.874,2.536
        c0,1.224-0.438,2.361-1.138,1.925c-0.698-0.438-2.187,0.524-2.187,0.961s-0.262,1.75,0,2.711c0.263,0.961,0.875,1.225,1.313,2.011
        c0.438,0.787,0.438,1.474,0,2.355c-0.291,0.591-1.001,1.492-1.326,2.415c0.39,0.452,0.931,0.994,2.231,2.052
        c3.148,2.566,3.732,1.749,5.714,3.382c1.984,1.631,3.031,5.131,3.964,6.181c0.613,0.69,1.135,0.671,1.977,0.514
        c-0.222-0.475-0.336-1.06,0.034-1.592c0.787-1.137,0.546-2.711,0.185-3.236s-1.323-2.1-0.798-2.886
        c0.524-0.787,1.575-1.312,2.011-2.623c0.438-1.312,0.525-1.487,0.964-2.361c0.436-0.874,0.436-2.361,1.312-1.661
        c0.874,0.7,1.837,2.623,2.623,2.623c0.787,0,2.623-0.35,3.674-1.749c0.377-0.503,0.672-0.562,0.907-0.411
        c-0.056-1.459-0.12-3.174,0.448-4.007c0.569-0.832,1.356-0.655,2.144-1.354c0.787-0.7,0.436-1.137,0-1.749
        c-0.438-0.612-1.049-1.05-1.799-1.224c-0.751-0.175-1.96-1.4-2.835-2.441c-0.874-1.04-1.662-0.183-2.536-0.008
        s-3.498-2.361-4.111-2.536c-0.613-0.175-1.749-2.187-2.623-3.062c-0.78-0.78-1.835-1.219-2.241-2.234
        C519.041,541.1,518.28,540.893,517.927,541.096z" pointer-events="auto" class="landarea"></path>

        <rect id="C3_5_" x="477.784" y="553.72" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_BULGARIA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Bulgaria" data-wikipediaLink="https://en.wikipedia.org/wiki/Bulgaria" data-infoText="<b>Bulgaria</b> &amp;nbsp; Capital: Sofia" data-imageFile="BULGARIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="BULGARIA" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M568.3,530.514c-0.087,0.525-1.224-0.175-1.925,1.313c-0.7,1.487-1.835,2.711-1.137,4.46s1.575,1.662,1.575,2.798
        c0,1.137,0.7,2.886,1.313,3.149c0.612,0.262,2.711,0.524,2.885,1.662c0.175,1.137,0.438,2.361,2.012,2.973
        c1.575,0.611,2.011,1.137,1.487,1.749c-0.525,0.612-1.575,1.924-2.187,2.536s-1.575,1.049-1.575,2.187
        c0,1.137,0.079,1.313-0.88,1.313c-0.957,0-2.793-1.224-2.881,0c-0.087,1.224,0.787,1.662,0.087,2.273
        c-0.7,0.612-1.313,0.874-0.175,2.187c1.137,1.313,2.449,3.673,1.049,4.198c-1.4,0.525-2.449,1.749-2.449,2.011
        s1.312,2.711,2.711,3.849c1.4,1.137,3.498,0.612,3.849,1.837c0.349,1.224,0.175,3.236,0.787,4.983
        c0.612,1.749,1.575,2.799,1.4,3.76c-0.175,0.96-2.361,4.634-0.436,4.372c1.924-0.262,2.011-0.698,5.597-1.486
        c3.586-0.787,3.499,1.137,5.422-0.087c1.925-1.225,4.023-2.012,6.821-2.012s1.749,0.175,5.859,1.137
        c4.111,0.961,4.372-0.437,5.947,1.049c1.575,1.487,2.537,3.324,4.986,2.361c2.448-0.964,2.098-1.051,5.422-0.964
        c3.324,0.087,5.115,0.263,5.487-1.224s0.372-2.798-0.59-4.634c-0.964-1.837,0.524-4.198,2.011-3.324s1.838,0.262,2.623-0.525
        c0.787-0.787,1.662-1.313,2.448-2.711c0.788-1.4,1.749-0.874,3.5-0.612c1.749,0.262,1.486-1.137,3.235-1.312
        c1.75-0.176,4.198-0.961,5.335,0.611c1.138,1.576,1.575,2.711,3.76,1.838c1.517-0.607,3.288-1.802,5.224-2.185
        c-0.304-0.883-0.986-1.278-1.959-2.101c-1.516-1.282-1.631-1.983-2.214-4.198c-0.583-2.214-1.984-1.516-2.799-1.749
        c-0.816-0.233,0.116-1.983,0.933-3.149c0.817-1.165,1.866-2.098,3.965-3.031c2.098-0.933,0.583-1.749,0.234-4.198
        c-0.35-2.448-0.117-2.215,0.932-3.382c1.05-1.167,1.282-1.866,0.933-3.497c-0.35-1.634,3.149-0.933,5.363-0.933
        c2.215,0,2.683-1.283,2.566-3.964c-0.084-1.942-0.229-2.479-0.081-3.114c-6.029,0.214-7.204-1.277-8.2-3.182
        c-1.398-2.681-5.248-1.866-8.744-3.031c-3.498-1.165-2.566-1.399-6.647-2.215s-6.063,1.866-9.445,2.448
        c-3.382,0.584-2.682,1.634-4.315,3.266c-1.633,1.634-5.363,3.498-8.045,4.198c-2.682,0.699-4.664-0.117-7.112-1.4
        c-2.449-1.282-7.114-0.7-9.562-0.584s-7.346-0.467-9.677-1.4s-8.28,0.117-9.795,0c-1.516-0.117-0.235-2.798,1.05-3.732
        c1.283-0.932-0.466-1.866-2.798-2.916c-1.768-0.796-1.46-0.922-1.969-1.699c-0.11,0.187-0.185,0.416-0.159,0.707
        C569.438,528.152,568.387,529.989,568.3,530.514z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_5_" x="586.436" y="547.325" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_MACEDONIA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="North Macedonia" data-wikipediaLink="https://en.wikipedia.org/wiki/Macedonia" data-infoText="<b>North Macedonia</b> (formerly known as Macedonia or FYROM) &amp;nbsp; Capital: Skopje" data-imageFile="MACEDON.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="MACEDONIA" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M562.091,564.884c-0.699,0.698-1.487,0-3.41-0.35c-1.925-0.349-2.187,1.4-2.623,2.141c-0.438,0.74-1.049,0.308-2.798,0.046
        c-1.749-0.262-2.186,0.612-2.449,2.012c-0.262,1.4-0.262,1.138-1.051,1.138c-0.787,0-0.612-1.487-0.787-2.274
        s-3.062-0.087-3.411,1.049c-0.35,1.138-2.972,0.7-3.848,1.313c-0.874,0.611-0.262,1.399,0.175,1.749
        c0.438,0.35,0.175,1.487,0,2.616c-0.175,1.13-1.4,0.094-1.749,0.007c-0.103-0.026-0.43,0.032-0.833,0.127
        c-0.236,0.56-0.511,1.259-0.686,2.322c-0.405,2.449-0.12,4.634,0,7.084c0.12,2.449,0.646,4.722,1.957,6.034
        c1.287,1.287,3.833,1.484,5.168,3.554c0.903-0.571,2.622-0.668,2.878-0.668c0.349,0,3.586,0.787,4.984,0
        c1.4-0.787,2.187,0.263,3.236-1.487c1.051-1.748,2.448-3.673,3.935-3.673s6.908,0.087,7.522,0c0.612-0.087,2.361-0.515,2.536-2.27
        c0.1-1,1.222-1.033,2.282-1.458c-0.476-0.804,0.986-3.362,1.128-4.142c0.175-0.961-0.787-2.011-1.4-3.76
        c-0.612-1.749-0.438-3.76-0.787-4.984s-2.448-0.7-3.849-1.837c-1.399-1.138-2.711-3.587-2.711-3.849
        c0-0.082,0.112-0.263,0.297-0.482C564.272,564.275,562.633,564.342,562.091,564.884z" pointer-events="auto" class="landarea"></path>

        <rect id="C3" x="530.491" y="570.134" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_SERBIA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Serbia" data-wikipediaLink="https://en.wikipedia.org/wiki/Serbia" data-infoText="<b>Serbia</b> &amp;nbsp; Capital: Belgrade" data-imageFile="SERBIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-miterlimit="10" d="M573.024,546.869c-1.575-0.612-1.838-1.838-2.012-2.973
        c-0.175-1.138-2.274-1.4-2.885-1.662c-0.612-0.262-1.313-2.012-1.313-3.149s-0.874-1.05-1.575-2.798s0.436-2.973,1.137-4.46
        c0.7-1.488,1.837-0.787,1.925-1.313c0.087-0.525,1.138-2.361,1.051-3.324c-0.026-0.291,0.049-0.52,0.159-0.707
        c-0.162-0.247-0.406-0.56-0.829-0.983c-1.749-1.748-1.633-3.031-1.283-4.664c0.349-1.634,3.032-0.467,2.916-1.983
        c-0.116-1.515-3.615-0.698-4.781-1.281c-1.165-0.583-1.866,2.214-3.265,4.198c-1.4,1.983-1.866-1.05-2.916-3.149
        c-1.049-2.1-3.498,0.116-5.986-1.167c-2.488-1.282-1.244-1.866,0-2.682s-0.775-1.282-1.942-2.682c-1.166-1.4,1.4-3.149,1.942-4.081
        c0.544-0.932-2.758-2.332-5.674-2.681c-2.915-0.35-4.547-3.149-5.248-5.714c-0.7-2.566-1.05-3.732-1.635-4.897
        c-0.584-1.166-4.547-2.565-6.062-3.849c-1.241-1.051-0.917-1.708,0.394-2.105c-0.417-0.409-1.092-0.799-2.173-0.839
        c-2.361-0.087-4.46,0.874-5.335,0s-1.662-1.662-2.361-1.05c-0.7,0.611-1.837,2.798-2.623,2.885
        c-0.787,0.087-2.886,1.924-3.236,1.487c-0.349-0.438-0.698-1.459-1.4-0.817c-0.7,0.643-0.612,1.604-1.662,1.953
        c-1.051,0.35-1.838,1.313-1.749,2.536c0.087,1.224,1.487,2.885,1.049,3.673c-0.438,0.787-0.525,1.399,0.175,1.487
        c0.7,0.087,1.924,0.524,1.487,1.137c-0.436,0.612-0.7,2.1,0.087,2.799c0.787,0.698,3.324,1.837,4.111,2.186
        c0.788,0.35,1.838,0.525,0.612,1.225c-1.224,0.7-3.672-0.35-4.022,0.7c-0.349,1.05-0.874,4.285,0,4.46
        c0.874,0.175,2.975,0.524,3.324,0.787c0.349,0.262,1.486,1.486,0.524,2.885c-0.961,1.399-1.575,2.1-2.098,3.848
        c-0.524,1.749-1.924,3.148-1.224,4.46c0.7,1.313,1.824,0.684,2.705,1.916c0.881,1.232,1.406,1.67,2.105,2.282
        c0.698,0.612,2.616,2.012,1.745,2.623c-0.871,0.612-1.484,1.487-2.533,0.961c-1.049-0.524-3.062-0.552-2.798,0.162
        c0.262,0.713,1.487,1.413,2.361,2.112c0.874,0.7,1.487,2.449,2.012,3.149s0.525,2.448-0.7,2.536
        c-1.224,0.087-1.674-0.087-2.193-0.524c-0.518-0.438-2.093-0.175-1.831,1.137c0.262,1.313,1.487,1.749,2.361,2.623
        c0.874,0.874,2.011,2.885,2.623,3.062c0.612,0.175,3.236,2.711,4.111,2.536c0.874-0.175,1.662-1.032,2.536,0.008
        c0.874,1.041,2.085,2.265,2.835,2.441c0.366,0.085,0.698,0.235,0.995,0.435l1.145-1.907l0.405-0.304l0.304,0.101l0.202-0.405
        l0.405-1.215l0.202-0.708l-0.405-0.405h-0.304l-0.101-0.304l-0.506-0.607l0.506-1.012l1.113-0.911l1.214-0.81l1.316,0.911
        l-0.405,0.405l0.101,0.708l1.619,0.506l1.417,0.607l1.214-0.202l0.405-0.405l0.607-0.506l0.81,0.607l0.607,0.304l0.101,1.518
        l0.202,0.607l1.012-0.607l0.304,1.012v1.012l1.012,0.405l0.506-0.304l0.708,0.202l0.506,0.304h1.316l-0.708,1.518l0.304,1.316
        l1.214-0.101l0.506,0.304l0.708,0.304l0.81,0.101l0.911-0.405l1.012,0.81l-0.506,0.708v0.506l-0.202,0.101l0.304,0.405
        l-0.405,1.113l-0.607,1.417l-0.304,1.012l0.101,1.113l-1.417,1.316l1.113,1.619h-0.202l-1.518,0.708l-0.405,0.405l-0.607-0.202
        l-0.911,1.012l-0.405,1.215l0.112,0.28c0.358-0.424,0.914-0.622,1.857-0.481c1.749,0.262,2.361,0.694,2.798-0.046
        c0.436-0.741,0.698-2.491,2.623-2.141c1.924,0.35,2.709,1.049,3.41,0.35c0.541-0.541,2.182-0.609,3.707-0.045
        c0.404-0.483,1.19-1.169,2.153-1.529c1.4-0.525,0.087-2.885-1.049-4.198c-1.138-1.313-0.525-1.575,0.175-2.187
        c0.699-0.611-0.175-1.05-0.087-2.273c0.087-1.224,1.924,0,2.881,0c0.958,0,0.88-0.176,0.88-1.313c0-1.138,0.961-1.575,1.575-2.187
        c0.612-0.612,1.662-1.924,2.187-2.536C575.035,548.005,574.596,547.48,573.024,546.869z" pointer-events="auto" stroke-width="0.5" class="landarea"></path>

        <rect id="C3_4_" x="525.41" y="514.17" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_KOSOVO" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Kosovo" data-wikipediaLink="https://en.wikipedia.org/wiki/Kosovo" data-infoText="<b>Kosovo</b> (disputed) &amp;nbsp; Capital: Pristina" data-imageFile="KOSOVO.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-miterlimit="10" d="M556.752,555.687v-0.506l0.506-0.708l-1.012-0.81l-0.911,0.405
        l-0.81-0.101l-0.708-0.304l-0.506-0.304l-1.215,0.101l-0.304-1.316l0.708-1.518h-1.316l-0.506-0.304l-0.708-0.202l-0.506,0.304
        l-1.012-0.405v-1.012l-0.304-1.012l-1.012,0.607l-0.202-0.607l-0.101-1.518l-0.607-0.304l-0.81-0.607l-0.607,0.506l-0.405,0.405
        l-1.214,0.202l-1.417-0.607l-1.619-0.506l-0.101-0.708l0.405-0.405l-1.316-0.911l-1.214,0.81l-1.113,0.911l-0.506,1.012
        l0.506,0.607l0.101,0.304h0.304l0.405,0.405l-0.202,0.708l-0.405,1.215l-0.202,0.405l-0.304-0.101l-0.405,0.304l-1.145,1.907
        l-0.07,0.117l0.405,0.304l0.101,0.202h0.101v0.101h0.101v0.101v0.101v0.101l-0.101,0.101l-0.101,0.101l-0.202,0.202l-1.822,0.911
        l-1.417,0.506l-0.708,0.506l0.101,0.708l-2.227,0.101l-0.101,1.721l0.911,1.518l-0.202,0.81l0.101,1.012l1.619,1.113l1.012,1.518
        l-0.101,0.405l0.405,0.405l0.304,0.81l2.125,0.405l2.125,0.911l1.316,1.822l0.457,0.64l0.049,0.069l0.029,0.358l0.071,0.856
        l0.118,0.163c0.132,0.703,0.254,1.343,0.339,1.766c0.15,0.744-0.088,1.236-0.397,1.963c0.405-0.094,0.731-0.153,0.833-0.127
        c0.019,0.005,0.05,0.021,0.073,0.031l0.046,0.454l1.113,0.202l0.405-0.202h1.012l0.607-0.607l0.101-0.506l-0.202-0.405
        l-0.202-0.708l-0.304-1.619l1.41-1.532c0.656-0.116,1.214-0.311,1.363-0.791c0.349-1.138,3.236-1.837,3.411-1.05
        s0,2.274,0.787,2.274s0.787,0.262,1.051-1.138c0.04-0.214,0.087-0.409,0.14-0.596l0.745-0.203l-0.292-0.732l-0.112-0.28
        l0.405-1.215l0.911-1.012l0.607,0.202l0.405-0.405l1.518-0.708h0.202l-1.113-1.619l1.417-1.316l-0.101-1.113l0.304-1.012
        l0.607-1.417l0.405-1.113l-0.304-0.405L556.752,555.687z" pointer-events="auto" stroke-width="0.5" class="landarea"></path>

        <rect id="C3_3_" x="529.338" y="547.291" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_BOSNIAANDHERZEGOVINA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Bosnia and Herzegovina" data-wikipediaLink="https://en.wikipedia.org/wiki/Bosnia_and_Herzegovina" data-infoText="<b>Bosnia and Herzegovina</b> &amp;nbsp; Capital: Sarajevo" data-imageFile="BOSNIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M523.44,528.764c-0.7-0.611-1.225-1.05-2.105-2.282c-0.881-1.232-2.005-0.603-2.705-1.916s0.7-2.711,1.224-4.46
        c0.525-1.749,1.137-2.449,2.098-3.848c0.961-1.4-0.175-2.623-0.524-2.885c-0.331-0.247-2.219-0.574-3.165-0.756
        c-0.119,0.27-0.279,0.524-0.511,0.669c-0.7,0.436-1.662,0.874-2.536,0.698c-0.874-0.175-1.224-0.048-1.224-1.248
        c0-1.199,0-1.725-1.051-1.725c-1.049,0-0.525-0.349-1.575-0.961c-0.971-0.567-1.273-0.309-1.936-0.061
        c0.087,0.216-0.637,0.219-0.163,0.061c0.059-0.019,0.109-0.04,0.163-0.061c-0.058-0.143-0.466-0.379-1.825-0.728
        c-3.411-0.874-1.575,0.438-2.885,0.7c-1.313,0.262-1.138-0.525-1.925-0.438c-0.787,0.087-1.313,0.961-2.448,0.874
        c-1.138-0.087-1.924-1.838-2.711-1.838s-2.536,1.749-3.498,0.7c-0.961-1.05-1.662-1.749-2.536-1.575
        c-0.874,0.175-0.349,1.52-2.098-0.026c-1.749-1.547-2.623-3.122-3.062-2.073c-0.436,1.049-0.262,2.361-1.924,1.838
        c-1.662-0.525-2.973-1.575-3.324-0.787c-0.349,0.787-2.274,2.536-2.361,3.586c-0.087,1.051-0.087,2.973-1.573,1.051
        c-1.487-1.925-2.712-2.712-3.674-3.848c-0.96-1.137-2.274-1.313-2.622-0.087c-0.35,1.224-0.874,3.149-0.874,5.16
        c0,2.012-0.612,3.412,0.874,4.198c1.487,0.786,2.622,1.4,2.622,2.012c0,0.611-0.611,1.487,0.438,2.098
        c1.051,0.612,1.924,1.312,1.837,2.449c-0.087,1.138-0.436,2.274,1.051,3.586c1.486,1.313,2.535,1.487,3.061,3.411
        c0.525,1.924,0.964,2.098,2.536,3.323c1.575,1.225,2.798,2.1,3.674,3.849c0.874,1.749,2.098,2.972,3.235,3.411
        c1.138,0.438,2.536,0.262,2.536,1.4s-0.349,1.749,0.525,2.973c0.874,1.225,3.76,4.372,4.81,5.073
        c1.049,0.7,0.321,1.516,1.573,2.711c0.05,0.047,0.097,0.071,0.146,0.111c-0.003-0.002-0.005-0.003-0.008-0.005
        c0,0.496,0,0.738,0,1.01c0.33-0.055,0.703-0.047,1.116,0.08c0.06,0.018,0.12,0.053,0.18,0.076c-0.017-0.244-0.115-0.468-0.177-0.66
        c0.749,0.095,1.478-0.129,2.329,0.438c1.312,0.874,3.848,3.149,4.111,3.76c0.22,0.514,1.358,2.431,2.34,2.836
        c0.305-0.521,0.63-1,0.808-1.356c0.438-0.882,0.438-1.568,0-2.355c-0.436-0.786-1.049-1.049-1.313-2.011
        c-0.262-0.962,0-2.274,0-2.711s1.487-1.4,2.187-0.961c0.7,0.436,1.138-0.7,1.138-1.925s0.698-1.662,0.874-2.536
        c0.175-0.874,1.224-1.924,1.575-2.449c0.349-0.525,2.1,1.575,2.711,1.487c0.611-0.087,0.436-1.313,0.436-1.313
        s-0.611-1.4-0.961-1.838c-0.35-0.436-1.225-1.049-1.225-2.012c0-0.961,2.187,0.787,2.799,0.438
        c0.354-0.202,1.114,0.003,1.695,0.215c-0.049-0.121-0.093-0.249-0.12-0.39c-0.262-1.313,1.313-1.575,1.831-1.137
        c0.518,0.436,0.969,0.611,2.193,0.524c1.225-0.087,1.225-1.837,0.699-2.536c-0.525-0.699-1.137-2.449-2.012-3.149
        c-0.874-0.699-2.1-1.4-2.361-2.112c-0.262-0.714,1.749-0.686,2.798-0.162c1.049,0.525,1.662-0.349,2.533-0.961
        C526.056,530.776,524.139,529.376,523.44,528.764z" pointer-events="auto" class="landarea"></path>

        <rect id="C4_1_" x="477.784" y="518.842" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_CROATIA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Croatia" data-wikipediaLink="https://en.wikipedia.org/wiki/Croatia" data-infoText="<b>Croatia</b> &amp;nbsp; Capital: Zagreb" data-imageFile="CROATIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M506.558,557.801c-0.262-0.611-2.799-2.885-4.111-3.76c-0.853-0.568-1.583-0.354-2.334-0.455c0.061,0.196,0.166,0.427,0.183,0.678
        c1.459,0.553,2.961,2.359,5.649,4.82c1.644,1.507,1.882,1.969,2.434,2.612c0.129-0.364,0.321-0.719,0.518-1.059
        C507.916,560.231,506.778,558.313,506.558,557.801z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M521.509,506.199c-0.786-0.349-3.323-1.487-4.111-2.186c-0.787-0.699-0.524-2.187-0.087-2.799c0.438-0.612-0.787-1.05-1.487-1.137
        c-0.7-0.087-0.612-0.699-0.175-1.487c0.438-0.787-0.962-2.449-1.05-3.673c-0.036-0.52,0.09-0.99,0.322-1.389
        c-0.407-0.398-1.02-0.863-1.541-0.707c-0.874,0.262-2.441,2.274-4.106,2.711c-1.665,0.438-6.737,1.313-7.963,0
        c-1.224-1.313-2.1-2.098-3.062-1.924c-0.961,0.175-0.436-0.611-1.487-1.487c-1.05-0.874,0.525-1.137-2.011-0.786
        c-2.536,0.349-2.711,0.639-2.798-0.906c-0.087-1.543-0.262-2.243-1.224-2.243c-0.964,0-3.849-3.062-3.936-3.673
        c-0.088-0.612-0.525-1.838-1.4-1.925c-0.874-0.087-4.111-3.148-5.073-3.148c-0.962,0-2.186,0-2.449,1.225
        c-0.263,1.225-0.35,2.798-2.187,2.885c-1.838,0.087-1.313,0.612-2.1,1.137c-0.787,0.524-2.448,0.961-3.148,1.225
        c-0.7,0.262-3.062,2.797-2.361,3.235c0.7,0.438,1.137,0.874,0.961,2.274c-0.175,1.4,0.269,2.536-1.089,3.062
        c-1.358,0.525-3.107,0.599-3.545,1.742c-0.436,1.145,0.438,1.406,0,2.281c-0.436,0.874-1.224,1.137-0.96,2.098
        c0.262,0.961,0.96,3.149-0.438,3.149c-1.4,0-2.098-2.274-3.673-2.011c-1.575,0.262-3.323,0.699-4.198-0.525
        c-0.874-1.224-0.874-4.285-1.924-3.411c-1.05,0.874-1.487,2.886-3.411,3.673s-3.411-0.35-4.896,0.087
        c-1.488,0.438-0.438,1.487-2.012,0.961c-0.969-0.323-2.963-0.909-4.661-1.11c-0.054,0.129-0.095,0.323-0.148,0.644
        c-0.233,1.4,0.116,1.75,0.234,4.665c0.117,2.916,0.467,1.634,1.75,3.615c1.282,1.983,2.565,6.296,3.614,6.181
        c1.051-0.117,0.816-2.916,1.749-4.431c0.933-1.516,2.682-2.332,3.498-2.332c0.817,0,0.233-1.4-0.116-2.916s2.215-1.982,3.149-1.749
        c0.933,0.234,0.116,2.682-1.167,3.849c-1.282,1.165,0.467,0.933,1.4,1.983s2.215,1.631,4.315,0.816
        c2.1-0.816-0.816-1.516-1.865-2.915c-1.049-1.4-0.117-1.633,1.516-0.934c1.631,0.699,1.749,1.749,1.515,4.082
        c-0.233,2.333,1.051,4.781,1.166,6.763c0.116,1.983,3.497,4.897,5.48,5.947c1.983,1.05,3.499,1.866-0.349,1.05
        c-3.848-0.816-3.265,0.817-3.381,1.634c-0.117,0.816,8.512,9.095,10.611,9.678c2.1,0.583,0.467,2.333,1.631,4.198
        c1.167,1.865,6.297-0.116,7.696,0.117c1.399,0.233,2.098,1.516,1.282,2.566c-0.817,1.051-2.215,0-3.849,0.233
        c-1.633,0.233,4.315,1.866,6.763,2.215c2.449,0.349,0.467-1.749-0.233-2.333s-0.583-1.282,1.282-0.816
        c1.866,0.467,2.916,2.565,4.547,4.431c1.631,1.866,1.866,1.517,3.265,3.149c1.4,1.631-1.399,0.933-3.382,0.933
        c-1.983,0-2.098,0.233-2.214,1.516c-0.116,1.282,3.265,0.233,4.781-0.467s1.749,0.467,2.449,2.1c0.7,1.631,2.566,0.816,2.449,0.349
        c-0.085-0.34,0.452-0.981,1.333-1.13c0-0.272,0-0.514,0-1.01c-0.047-0.039-0.092-0.062-0.14-0.107
        c-1.253-1.195-0.524-2.011-1.573-2.711s-3.936-3.848-4.81-5.073c-0.874-1.225-0.525-1.837-0.525-2.973s-1.4-0.961-2.536-1.4
        c-1.137-0.438-2.361-1.662-3.235-3.411c-0.875-1.749-2.1-2.623-3.674-3.849c-1.574-1.224-2.011-1.399-2.536-3.323
        s-1.575-2.098-3.061-3.411c-1.487-1.313-1.138-2.448-1.051-3.586c0.087-1.138-0.787-1.837-1.837-2.449
        c-1.05-0.611-0.438-1.487-0.438-2.098c0-0.612-1.137-1.225-2.622-2.012c-1.487-0.787-0.874-2.187-0.874-4.198
        s0.524-3.936,0.874-5.16c0.349-1.225,1.662-1.049,2.622,0.087c0.961,1.137,2.187,1.924,3.674,3.848
        c1.486,1.924,1.486,0,1.573-1.051c0.087-1.049,2.012-2.798,2.361-3.586c0.35-0.787,1.662,0.262,3.324,0.787
        c1.662,0.524,1.487-0.787,1.924-1.838c0.438-1.049,1.313,0.525,3.062,2.073c1.749,1.548,1.225,0.201,2.098,0.026
        c0.874-0.175,1.575,0.524,2.536,1.575c0.961,1.05,2.711-0.7,3.498-0.7s1.575,1.749,2.711,1.838
        c1.137,0.087,1.662-0.787,2.448-0.874c0.787-0.087,0.612,0.7,1.925,0.438c1.312-0.262-0.525-1.575,2.885-0.7
        c1.358,0.348,1.767,0.584,1.825,0.728c0.663-0.248,0.966-0.506,1.936,0.061c1.049,0.611,0.525,0.961,1.575,0.961
        c1.051,0,1.051,0.525,1.051,1.725c0,1.201,0.349,1.074,1.224,1.248c0.875,0.176,1.838-0.262,2.536-0.698
        c0.232-0.145,0.393-0.399,0.508-0.669c-0.057-0.011-0.109-0.022-0.159-0.032c-0.874-0.175-0.349-3.411,0-4.46
        c0.35-1.051,2.799,0,4.022-0.7C523.346,506.725,522.297,506.55,521.509,506.199z" pointer-events="auto" class="landarea"></path>

        <rect id="C4" x="439.528" y="502.306" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="46.1" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_TURKEY" data-colors="0" data-errors="0" hint-index="0" class="">

        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M641.441,668.238c0,0-1.224,0.859-2.098,0.715s-2.157-0.406-1.983,0.337c0.176,0.743,1.051,0.898,2.392,1.053
        c1.34,0.155,2.272,1.244,2.391,0c0.117-1.242,0.349-1.476,0.816-1.708C643.424,668.4,643.539,667.027,641.441,668.238z" pointer-events="none" class="landarea"></path>

        <path fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M660.215,585.085c-0.583-0.933-5.014-1.984-6.647-2.566c-1.634-0.583-4.781-3.848-6.063-5.597c-1.282-1.749-0.699-2.1-0.467-4.781
        c0.083-0.961,0.03-1.604-0.139-2.098c-1.935,0.383-3.706,1.578-5.224,2.185c-2.187,0.874-2.623-0.262-3.76-1.838
        c-1.137-1.573-3.586-0.787-5.335-0.611c-1.749,0.175-1.487,1.574-3.235,1.312c-1.75-0.262-2.711-0.787-3.499,0.612
        c-0.787,1.4-1.661,1.924-2.448,2.711c-0.439,0.438-0.747,0.813-1.186,0.916c0.067,0.291,0.197,0.594,0.457,0.833
        c0.757,0.7,1.983,0.467,2.274,1.575c0.291,1.108,1.108,0.758,1.399,1.983c0.292,1.224,2.857,1.225,0.874,2.858
        c-1.983,1.631-1.634,1.982-1.634,2.214c0,0.235-2.623,0.235-2.797,1.633c-0.175,1.4,0.408,1.924-0.175,3.615
        c-0.584,1.69-2.611,3.79-3.26,4.198c-0.283,0.18-0.724,0.563-1.128,0.961c0.154,0.647,0.24,1.52,0.482,2.361
        c0.467,1.631,4.197,0.933,6.179-0.349c1.983-1.282,2.332-0.35,2.1,0.349c-0.233,0.7-3.382,2.681-4.198,3.265
        s-1.865,2.098-1.282,3.732c0.584,1.631,1.865-1.634,3.265-3.031c1.399-1.399,3.148-2.916,4.896-2.798
        c1.749,0.117,2.566-1.282,4.547-1.749c1.982-0.466,3.382-2.449,4.896-4.782c1.516-2.333,1.283-1.865,5.597-1.865
        c4.315,0,2.682-0.35,4.547-1.167c1.866-0.816,4.315,0.117,6.647,1.516s3.498-0.584,4.897-1.634
        C662.198,587.999,660.798,586.017,660.215,585.085z" pointer-events="none" class="landarea"></path>

        <path id="TURKEY" fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M881.65,609.107c-1.749-1.749-5.364-0.349-8.045-0.816c-2.682-0.467-1.049-3.732-2.449-7.229c-1.4-3.498,0.932-2.449,1.516-4.43
        c0.583-1.983,0-2.798-1.283-4.315c-1.283-1.516-1.281-1.167-1.4-2.682c-0.116-1.516,0.117-0.699-1.516-1.749
        c-1.631-1.049-1.049-0.584-2.916-2.565c-1.866-1.983-2.216-1.282-4.43-3.615c-2.215-2.333-1.4-1.982-3.033-2.564
        c-1.635-0.584-2.682,0.583-2.682,2.811c0,2.228-2.098-0.947-2.565-0.713s-3.031-0.235-4.198-0.927s-1.631-0.239-2.214,1.043
        c-0.583,1.282-1.633,0.117-3.382,0c-1.75-0.116-2.449-0.584-2.449-0.584c-1.282,3.498-9.795,5.831-13.175,7.463
        c-3.382,1.631-5.947,3.732-11.544,0.932c-5.598-2.798-11.427-0.234-15.159,1.866c-3.732,2.1-4.897,0.699-8.162-0.583
        s-2.332,0-4.197-1.866c-1.865-1.865-2.448-1.167-3.265,0.117c-0.816,1.283-1.4,0-3.498-0.933c-2.098-0.933-3.849-1.049-5.248-1.983
        c-1.399-0.932-2.448-3.265-4.547-3.031c-2.098,0.233-2.798,3.031-4.43,1.865c-1.633-1.167-5.364-6.88-5.831-8.279
        c-0.467-1.4-6.53,0.232-7.93,0.584c-1.4,0.349-2.916-2.1-4.081-2.799c-1.165-0.699-0.584-4.082-1.049-4.781
        c-0.466-0.7-2.449-0.234-3.964,1.631c-1.517,1.866-3.149,0.235-6.647,0.467c-3.498,0.234-4.548-0.583-9.911-1.282
        c-5.363-0.699-5.598,1.631-10.844,1.983c-5.248,0.349-6.413,2.1-8.396,4.315s-4.781,3.497-7.112,4.43
        c-2.332,0.933-6.297,3.149-6.764,5.597c-0.466,2.449-5.947,1.049-7.812,1.049c-1.866,0-3.732,0-5.714-2.215
        c-1.983-2.215-3.382,1.283-5.014,1.749c-1.631,0.466-8.395-0.7-11.078-1.165c-2.682-0.467-3.614,4.431-1.4,6.063
        c2.214,1.631,4.547,1.866,7.696,2.215s-1.749,1.631-6.063,1.749c-4.315,0.116-4.082,0.467-6.88,2.798
        c-2.798,2.332,2.682,0.7,3.732,1.983c1.051,1.282-4.315,1.283-6.414,1.049c-2.1-0.234-2.681-0.933-3.731-0.933
        c-1.051,0-3.615,0.816-5.248,1.165c-1.631,0.349-0.584-0.7-0.584-2.682s-3.848,0.117-3.149,1.283
        c0.7,1.165,0.817,1.516-1.159,2.097c-1.976,0.584-3.739-0.116-4.321-0.932c-0.583-0.816-2.1-1.282-3.964-0.699
        c-1.866,0.583-1.399,0.933-4.896,0.467c-3.498-0.466-2.915,1.983-3.964,3.382c-1.05,1.399-1.983,3.265-3.382,3.732
        c-1.4,0.466-1.515,5.363-1.865,7.58c-0.349,2.215-0.584,1.631,0.583,2.916c1.167,1.281,1.282-0.816,6.297-2.215
        c5.014-1.4,3.615,0,2.332,1.4c-1.283,1.399-1.983,4.198,0.467,5.947s1.631,1.749,0.35,3.031c-1.282,1.283,0.117,1.283,2.098,1.283
        c1.983,0,0,1.748-1.516,2.797s-2.1,2.216-1.165,3.382c0.933,1.168,3.848,1.633,3.031,2.916c-0.816,1.283-2.215,1.049-4.547,0.816
        c-2.333-0.235-1.865-2.915-3.498-4.548s-3.148,0.584-2.332,1.635c0.816,1.049,2.682,2.798,1.516,3.497
        c-1.165,0.699-1.399,1.631-0.933,3.031s1.283,1.866,3.265,1.167c1.983-0.7,0.117-2.916,2.798-0.116
        c2.682,2.798,1.866,2.098,5.015,2.916c3.149,0.816,1.749,2.215,0.116,3.732c-1.633,1.516,0.7,1.749,1.051,3.031
        c0.349,1.282,0.349,5.598,1.049,3.964c0.7-1.634,1.866,0.35,3.615,1.866c1.749,1.516-0.349,1.982-3.03,2.681
        c-2.682,0.699-0.7,4.897-0.35,3.382c0.35-1.517,2.1-0.7,3.614-0.35c1.516,0.35,5.714-1.165,8.745-1.4
        c3.031-0.233,1.517,1.05,0.235,2.216s0.116,1.983-1.516,4.315c-1.631,2.333-0.35,1.982,1.634,0.933
        c1.983-1.049,1.166-2.449,3.615-4.198s2.1-0.117,3.265,2.215c1.165,2.333,2.681,2.798,3.614,2.1c0.933-0.7,1.4-1.049,2.682,0.116
        c1.283,1.165,0,2.566,0.233,4.315c0.234,1.749,3.031,1.049,8.045,4.315c5.015,3.265,2.449-1.051,6.647-2.449
        c4.198-1.4,2.1,1.631,4.547,1.282c2.449-0.35,2.216-2.798,2.098-5.947c-0.116-3.148,0.233-4.082,1.4-5.363
        c1.167-1.282,7.578,0.234,12.71,2.798c5.132,2.565,2.565,1.865,6.879,3.381c4.315,1.516,4.548,4.782,8.279,8.863
        c3.732,4.082,4.547-1.631,8.512-2.798c3.964-1.167,4.081,1.516,6.647-0.349c2.565-1.865,2.681-1.282,3.732-0.817
        c1.05,0.467,1.283,0.117,2.1-1.749s5.014-5.363,7.463-8.279c2.448-2.916,7.929,3.031,11.427,4.431
        c3.498,1.399,2.333-0.116,5.714-3.382c3.381-3.265,3.148-2.332,6.063-1.049s-0.467,3.615-4.197,7.463
        c-3.732,3.849-1.866,1.749-0.699,3.381c1.167,1.632-0.349,5.947,2.565,6.414c2.916,0.467,1.4-1.634,4.547-4.082
        c3.148-2.449,0.116-1.865,1.633-2.449c1.516-0.583,2.681-0.466,2.098-2.332s-0.584-2.682-0.584-5.219s1.167-3.06,3.498-2.359
        c2.332,0.7,4.781,1.983,4.781,1.983s0,0,2.215,0.349c2.216,0.35,5.48-2.682,9.911-4.781c4.433-2.1,3.964-0.7,7.812,1.749
        c3.848,2.449,11.544,1.282,17.025,0.932c5.48-0.349,6.179-2.098,10.377-5.13s4.547-1.866,9.445-1.749
        c4.896,0.116,4.197,0.349,8.863-0.235c4.665-0.584,3.381-1.516,7.578-3.148c4.198-1.633,1.049,2.565,3.615,3.031
        s1.631-2.682,2.682-3.031c1.049-0.349,1.281,0,4.081-1.4c2.798-1.4,3.498,0.117,7.696,2.448c4.198,2.332,5.714,0.816,8.047-1.516
        c2.332-2.332,2.916,0.933,2.1,3.614c-0.816,2.682,1.4,3.265,2.449,1.749c1.049-1.516,2.215-1.515,5.364-1.865
        s2.1-1.283,1.049-2.798c-1.049-1.516-2.215-1.634-1.983-5.598c0.234-3.964-0.933-2.098-5.248-3.848
        c-4.312-1.749-0.234-2.332,2.215-5.714c2.449-3.381,0.584-3.031-1.165-3.732c-1.748-0.699,0.7-2.332-0.233-5.947
        c-0.933-3.614-1.865-3.849-1.865-6.647c0-2.799-0.234-1.516-1.516-3.382c-1.281-1.866-1.631-3.031,0.234-3.149
        c1.865-0.117,2.215,0.35,3.265-3.265c1.049-3.614,2.1-3.031,4.781-3.382c0.359-0.046,0.712,0.055,1.062,0.264
        C886.936,612.405,882.873,610.329,881.65,609.107z" pointer-events="none" class="landarea"></path>

        <rect id="rect12_11_" x="710.813" y="617.837" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="none"></rect>
</g>
<g id="AREA_GREECE" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Greece" data-wikipediaLink="https://en.wikipedia.org/wiki/Greece" data-infoText="<b>Greece</b> &amp;nbsp; Capital: Athens" data-imageFile="GREECE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M574.043,676.212c0,0-1.049,0.525-0.757,1.051c0.292,0.524,1.165,1.399,0.757,2.1c-0.408,0.699,0.117,0.932,0.584,0.874
        c0.466-0.059,0.932-1.165,1.165-1.282c0.235-0.117,1.75-0.7,0.758-1.108C575.56,677.438,574.811,675.532,574.043,676.212z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M585.705,688.34c0,0-1.05,0-0.874,1.283c0.175,1.282,0.835,1.748-0.019,1.515c-0.855-0.232-1.088-1.865-1.437-1.37
        c-0.349,0.497-0.467,0.671-0.467,2.188c0,1.516,0.407,2.681,0,3.323c-0.409,0.642-0.409,1.165,1.106,1.108
        c1.517-0.059,4.548-1.282,5.014-0.583c0.467,0.7-0.64,0.874,1.341,0.874c1.983,0,4.315-0.698,4.722,0.292
        c0.408,0.991-0.234,0.991,1.691,0.991c1.924,0,2.391,0.176,2.391,1.109s-1.691,2.098,0.524,2.04
        c2.215-0.059,3.906-0.642,6.356-0.874c2.448-0.232,3.498-0.991,6.237-0.583c2.741,0.408,3.79-0.584,5.48,0.059
        s1.925,1.109,2.681,0.292c0.758-0.816,0.758-0.524,1.109-1.983c0.349-1.458-0.701-3.15-0.992-2.508
        c-0.292,0.642-0.583,1.575-1.807,0.874c-1.224-0.699-2.157-0.642-2.857,0.175s-1.691,1.808-2.216,0.992
        c-0.524-0.816-0.292-2.508-0.524-3.324c-0.234-0.817-2.157,0.176-2.799,0.495c-0.642,0.321-1.4,0.321-1.749-0.729
        c-0.349-1.051-0.467-0.466-1.691-0.349c-1.225,0.116-2.098,0.873-2.565-0.235c-0.466-1.108-0.29-1.631-1.865-1.108
        c-1.575,0.525-3.324-0.408-4.839,0.175c-1.516,0.583-1.106,0.932-2.391,1.049c-1.282,0.117-2.449,0.408-2.449-0.525
        c0-0.932,0.233-2.1-0.642-2.215c-0.874-0.116-1.691-1.983-1.691-1.983s-0.525,0.117-1.106,0.992
        c-0.584,0.874-1.108,1.341-1.924,0.349C586.636,689.157,586.424,687.964,585.705,688.34z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M610.892,660.413c0,0-0.933,0.524-1.049,1.165c-0.117,0.642-0.059,0.525-0.642,0.758s-0.817,0.116-0.349,1.108
        c0.467,0.991,1.151,0.875,0.897,1.691c-0.256,0.816-0.256,1.866,0.502,0.874s0.643,0.233,1.283-1.341
        S611.733,659.693,610.892,660.413z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M600.046,646.188c0,0-1.4-0.059-0.991,1.165c0.408,1.224,1.457,1.341,1.808,2.1c0.349,0.757,0.175,0.991,1.049,1.631
        c0.874,0.642,1.516,1.283,1.749,1.69c0.234,0.408,0.931-0.816,0.29-1.457c-0.642-0.642-1.282-0.619-1.282-1.796
        c0-1.178,0.482-2.46-0.518-2.46C601.153,647.061,600.38,646.158,600.046,646.188z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M606.169,652.857c0,0-2.331-1.26-1.952,0c0.379,1.259,0.845,1.493,1.602,1.842C606.577,655.048,607.043,653.696,606.169,652.857z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M607.16,661.113c0,0-1.341,0.524-1.516,1.049s-0.525,1.064-1.049,1.202s-1.516,0.779-0.932,1.188c0.583,0.408,1.631-0.409,1.982,0
        c0.35,0.408,1.516,0.175,1.516-0.583S608.267,660.939,607.16,661.113z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M597.305,667.059c0,0-1.283,0.173-1.4,0.815c-0.116,0.642,0.058,0.525-0.7,0.816c-0.757,0.292-2.332,0.758-1.865,1.457
        c0.466,0.7,0.789,1.108,1.94,0.467c1.151-0.642,1.734-1.02,1.617-1.618c-0.116-0.597,0.292-0.482,1.05-0.423
        c0.757,0.059,1.283-0.235,0.467-0.699C597.598,667.408,598.314,666.725,597.305,667.059z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M594.099,652.296c0,0-0.991,0.304-1.108,0.829c-0.116,0.524-1.049,1.805,0,1.63c1.051-0.174,0.816-0.406,1.457-0.931
        C595.09,653.3,594.745,651.999,594.099,652.296z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M607.652,668.285c0,0-0.595-0.234-1.01,0.349c-0.415,0.584-0.535,0.524-1.233,0.816s-0.99,0.757-0.465,0.874
        c0.525,0.117,1.925-0.816,2.1-0.7c0.175,0.117,0,0.545,0.992,0.711C609.025,670.498,609.137,668.285,607.652,668.285z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M596.041,627.764c0,0-1.242,1.05-0.601,2.098c0.64,1.049,1.166,0.408,1.225,1.224c0.059,0.816,0.524,1.341,0.932,1.224
        c0.408-0.116,2.106-0.991,1.23-1.457c-0.88-0.467-1.568-0.467-1.662-1.108C597.074,629.105,596.69,626.892,596.041,627.764z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M609.434,608.23c0,0-1.167,0.059-1.341,0.7c-0.175,0.642-0.408,0.29-1.631,0c-1.224-0.29-2.157-0.677-2.565,0.362
        c-0.408,1.037,0.29,2.204,0.816,2.729c0.525,0.525,1.277-0.232,1.893,0c0.614,0.234,0.79,1.225,1.432,1.167
        c0.64-0.059,0.991-1.806,0.874-2.477s-0.116-0.847,0.525-0.905C610.074,609.746,610.126,608.092,609.434,608.23z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M615.788,604.676c0,0-1.224-0.35-1.457,0.408c-0.235,0.758-0.525,0.467-1.166,1.049c-0.643,0.584,0.466,1.576,0.991,1.808
        s0.757-1.109,1.631-0.992c0.874,0.117,1.691,0.874,1.4-0.35C616.896,605.376,617.247,604.267,615.788,604.676z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M611.998,599.077c0,0-2.497,0.233-2.065,1.282c0.433,1.051,1.134,0.933,1.249,1.634c0.116,0.699,1.106,0.874,1.282,0
        c0.175-0.874,1.165-1.332,1.575-1.161C614.447,601.003,614.972,598.904,611.998,599.077z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M634.796,685.199c0,0-1.051,1.219-1.109,1.976c-0.058,0.758-0.059,1.807-0.29,2.04c-0.234,0.235-0.525,0.409-0.292,0.992
        c0.234,0.583,0.761,0.932-0.086,1.225c-0.847,0.29-1.604,0.175-1.78,0.816c-0.175,0.64-0.289,1.108,0.468,0.698
        c0.757-0.408,0.173-0.408,1.748-0.292c1.575,0.117,2.507-1.282,2.041-2.1c-0.467-0.816-1.108-0.481-0.874-1.406
        c0.235-0.926,0.94-0.926,0.995-1.568C635.669,686.941,635.436,684.329,634.796,685.199z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M541.715,647.834c-0.108,0.085-1.618,0.175-1.575,0.918c0.044,0.744,0.918,1.269,1.049,2.055c0.131,0.787,0.22,0.874,1.093,1.18
        c0.874,0.307,0.482,0.612,1.269,0s2.012,0.131,1.53-0.656c-0.481-0.787-1.05-1.267-1.399-1.967
        C543.333,648.665,542.151,647.485,541.715,647.834z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M539.79,637.428c0,0-1.619,0.481-1.4,1.313c0.22,0.83,0.131,1.224-0.436,1.224c-0.569,0-1.225-1.006-1.356-0.22
        c-0.131,0.787,0.482,1.051-0.262,1.225c-0.744,0.175-0.874,0.744,0,1.443s1.137,0.481,1.705,1.224
        c0.569,0.744,0.569,0.787,1.749,0.918c1.18,0.131,1.049,0.349,1.837,0.568c0.787,0.219,1.943,0,1.037-1.093
        c-0.907-1.094-0.688-1.618-1.476-2.187c-0.787-0.568-1.924-0.873-0.874-1.268c1.049-0.394,1.4,0.262,1.924,0.044
        c0.525-0.219,0.744-1.006,0.044-1.442C541.583,638.738,540.447,637.152,539.79,637.428z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M622.492,651.61c0,0-1.515,0.502-1.631,0.688c-0.116,0.186-0.525,0.642-1.341,0.56c-0.815-0.082-2.04-0.095-2.156,0.669
        c-0.117,0.765-1.341,1.466,0,1.756s1.59,0.292,2.254-0.292c0.662-0.584,0.837-0.816,1.419-0.816s0.643-0.583,1.051-0.932
        C622.492,652.892,623.892,651.723,622.492,651.61z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M617.713,634.993c0,0-3.79,0.29-2.391,1.749c1.399,1.458,2.566,1.982,2.041,2.623c-0.525,0.642-1.748,0.409-1.282,1.691
        c0.467,1.281,0.932,2.215,1.631,2.04s1.106-0.408,1.749-1.4c0.642-0.992,0.685-1.283,0.459-2.857
        C619.695,637.267,619.403,634.292,617.713,634.993z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M630.131,649.219c0,0-2.857-0.643-2.916,0c-0.058,0.642-0.058,1.341-0.582,1.457c-0.525,0.116-1.904,0.29-0.689,0.933
        c1.214,0.642,1.621-0.758,2.088,0c0.466,0.757,1.458,1.679,2.1,1.248c0.642-0.431,0.583-1.364,1.282-1.248
        C632.113,651.725,631.507,649.452,630.131,649.219z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M649.838,673.707c0,0-0.524,0.525-1.165,0.933c-0.642,0.407-0.875-1.341-1.983,0c-1.107,1.341-0.816,1.749-2.098,2.098
        c-1.282,0.35-1.34,1.225-1.807,2.041c-0.467,0.816-1.341,0.541-0.757,1.465c0.583,0.924,0.816,1.567,0.933,2.79
        c0.117,1.225,0.816,1.574,1.167,0.932c0.35-0.642,0.739-1.749,1.71-2.274c0.971-0.525,2.545,0.407,2.254-0.874
        c-0.29-1.282-0.407-2.411,0.467-2.663c0.874-0.252,0.991-0.311,0.932-1.185s0.584-1.516,0.933-1.69
        C650.77,675.105,650.62,673.04,649.838,673.707z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M634.212,666.413c0,0-1.049,0.588-1.225,0.938c-0.175,0.349-1.365,0.258-1.877,0.888c-0.514,0.628-0.362,1.504,0,1.619
        c0.361,0.116,0.486-0.525,1.386-0.905c0.901-0.379,1.892,0.381,2.242-0.437c0.349-0.817,0.757-1.225,1.574-1.225
        C637.127,667.293,637.243,666.001,634.212,666.413z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M626.341,579.72c-0.29-1.224-1.108-0.874-1.399-1.983c-0.292-1.108-1.516-0.874-2.274-1.575c-0.26-0.239-0.391-0.542-0.457-0.833
        c-0.349,0.08-0.779-0.005-1.439-0.391c-1.487-0.874-2.975,1.487-2.011,3.324c0.961,1.837,0.961,3.149,0.59,4.634
        c-0.372,1.487-2.164,1.313-5.487,1.224c-3.323-0.087-2.972,0-5.422,0.964c-2.449,0.961-3.412-0.875-4.986-2.361
        c-1.575-1.487-1.837-0.087-5.947-1.049c-4.109-0.961-3.061-1.137-5.859-1.137c-2.799,0-4.897,0.787-6.821,2.012
        c-1.924,1.224-1.837-0.7-5.422,0.087c-3.586,0.787-3.673,1.224-5.597,1.486c-0.364,0.051-0.58-0.042-0.692-0.229
        c-1.061,0.425-2.182,0.458-2.282,1.458c-0.175,1.755-1.925,2.182-2.536,2.269c-0.612,0.087-6.034,0-7.522,0
        s-2.885,1.924-3.935,3.673c-1.049,1.75-1.837,0.7-3.236,1.487c-1.399,0.787-4.634,0-4.984,0c-0.35,0-3.412,0.176-3.434,1.487
        c-0.022,1.312,0.81,2.711,0.722,4.022c-0.087,1.313-1.312,1.575-2.186,2.535c-0.875,0.961-1.4,0.964-2.152,2.274
        c-0.752,1.313-0.473,2.536-0.034,4.022c0.438,1.487-3.238,0.874-4.46,1.1c-1.225,0.226-1.138,2.225-0.874,3.717
        c0.262,1.494-1.486,1.655-2.098,1.249c-0.612-0.405-1.837-1.081-2.098-1.868c-0.096-0.286-0.554-0.446-1.095-0.532
        c-0.355,0.572-1.376,1.095-2.23,0.882c-1.4-0.349-2.798,2.215-2.216,3.381s1.749,2.799,3.498,4.782
        c1.749,1.983,2.567,1.282,2.567,0.233c0-1.051-0.817-1.866-2.333-2.682c-1.515-0.816-0.467-1.4-0.467-2.683
        c0-1.282,2.215,0.467,3.031,1.283c0.816,0.817,0.933,3.031,3.733,5.363c2.798,2.332,4.312,6.063,5.597,5.248
        c1.282-0.816,2.448-0.232,5.014-0.232c2.564,0,0.933,2.565,0.583,2.915c-0.349,0.35-1.749,0-4.782-0.116
        c-3.031-0.116-3.265,2.799-4.43,4.664c-1.166,1.865,2.681,1.283,3.265,0.467c0.584-0.816-0.116-2.098,1.633-1.749
        s1.399,1.4,3.031,3.499c1.633,2.098,1.167,3.848,1.983,2.565s1.282-1.167,3.381,1.165c2.098,2.332,0.933,0.349,2.682-0.467
        s3.848-0.7,6.181-0.584c2.332,0.116,2.798-1.165,5.248-0.466c2.449,0.7,3.031,0.116,4.198,1.983s2.798,1.866,5.83,1.983
        s1.516,1.983-0.349,2.449s-1.166,1.516-2.566,1.983c-1.399,0.467-3.031-1.05-6.063-2.565c-3.031-1.516-4.782-2.681-6.531-3.381
        s-3.848,0.7-5.13,1.631c-1.282,0.932-0.933,0.817-2.215,0.233c-1.283-0.584-2.1-0.816-2.215,1.049
        c-0.116,1.865-1.866,2.682-2.916,3.615c-1.049,0.933-0.932,3.381,0.349,3.149c1.283-0.234,2.333,1.749,5.364,4.081
        c3.033,2.332,2.098,2.682,1.633,5.014c-0.468,2.332-2.1,1.749-0.7,4.781c1.4,3.031,1.983,4.547,3.498,4.896
        c1.516,0.35,1.282-1.748,0.816-3.498c-0.466-1.749,1.865-3.498,2.915-2.916c1.051,0.584,1.051,2.916,2.215,5.48
        c1.167,2.566,0.35,3.031,1.167,6.414c0.817,3.382,1.4,0.234,2.1-1.4c0.7-1.631,0.816-2.098,1.749-4.665
        c0.933-2.567,1.282-0.816,2.333-0.117c1.049,0.7,1.865,0,2.798,3.031c0.932,3.033,2.448,2.333,2.332,1.165
        c-0.117-1.167-0.7-1.049-1.283-3.848c-0.582-2.799-0.698-3.265-1.049-5.131c-0.349-1.866-0.117-2.798-1.631-4.781
        c-1.516-1.983-1.865-2.565-1.865-5.015c0-2.449,1.982,1.516,2.681,1.282c0.7-0.232,1.984,1.282,3.382,4.548
        c1.4,3.265,1.282-0.584,2.332-2.332c1.049-1.75,2.448-1.167,2.332-2.1c-0.117-0.934-0.699-0.933-1.165-2.332
        c-0.467-1.399-1.4,1.167-2.916,0.234s0.233-2.915-1.4-3.848c-1.633-0.934-0.583-1.516-0.233-1.983
        c0.349-0.466,2.682-0.116,3.963-1.516c1.282-1.399,2.449-0.116,3.498,1.167c1.051,1.283,4.315,3.615,6.182,4.781
        c1.865,1.165,1.049-1.049,0.232-2.214c-0.816-1.165-0.117-3.149-0.117-3.149c1.049-5.83-1.749-3.149-2.332-4.315
        c-0.583-1.167-1.516-1.866-4.896-3.265c-3.381-1.4-3.031-1.749-4.897-4.198c-1.866-2.448-1.282-0.933-5.946-3.265
        c-4.665-2.332,1.982-1.167,4.315-1.167s5.714,4.198,6.179,5.831c0.468,1.631,2.799,2.682,5.48,2.449
        c2.681-0.235,1.634-0.234,2.449,1.282c0.816,1.516,1.167,2.332,1.983,3.031c0.817,0.7,1.4,1.167,4.081,3.498
        c2.682,2.332,1.866-0.816,2.216-1.634c0.35-0.815-1.282-1.748-4.431-2.448c-3.148-0.7-0.699-1.749-1.749-3.498
        c-1.049-1.749-1.4-3.498-1.749-4.896c-0.349-1.4-3.031-1.049-6.53-1.517c-3.498-0.467-1.516-1.281-2.916-3.149
        c-1.4-1.865-0.7-1.631-3.614-1.165c-2.915,0.466-2.449,0.116-3.032-1.4c-0.584-1.516-0.349-1.983-1.516-3.614
        c-1.165-1.631,0.932-1.866,3.381-0.817s0.816,1.633,2.449,3.031c1.631,1.399,1.049-0.816,0.583-2.449s-0.816-3.148-3.498-4.664
        s-1.516-2.215-2.449-4.781s-1.631-2.332-3.732-4.897c-2.1-2.565-0.35-2.449-0.35-4.665c0-2.215,0-2.448,0.816-3.265
        c0.817-0.815,1.051-2.798,2.449-3.732c1.4-0.933,1.516,1.517,0.7,1.983c-0.815,0.467-0.816,2.332,0,2.682
        c0.816,0.349,2.682,2.215,4.315,4.197c1.631,1.983,1.866,2.567,2.916,4.315s3.732,1.4,5.13,1.051c1.4-0.35-0.7-1.051-2.214-2.682
        c-1.516-1.633-3.498-2.915-3.732-4.315c-0.235-1.4,3.031,0.7,3.615,0.7c0.583,0,1.282,1.165,1.982,2.332s2.215,2.798,3.266,3.498
        c1.049,0.7,0.932-1.399,0.467-3.848s-1.166-1.281-3.031-1.983c-1.866-0.7-0.467-1.516-0.233-2.333
        c0.233-0.816,3.031,1.049,4.431,1.749c1.399,0.7,2.798,2.448,4.547,2.215s0.234-1.165-0.932-2.448
        c-1.167-1.282-3.498-2.215-5.131-2.448c-1.631-0.235-1.749-0.35-1.282-1.4c0.466-1.051,0.116-1.283,0.116-1.283
        s-1.399-1.866-2.098-3.265c-0.7-1.4,2.216-0.699,3.732,0.234c1.515,0.932,1.516-0.816,3.382-1.516c1.865-0.7,1.4-1.634,2.214-2.916
        c0.816-1.282,1.516-0.234,3.031,1.166c1.516,1.399-0.116,1.865-1.167,3.031c-1.049,1.166,1.167,2.215,1.982,2.565
        c0.816,0.349,1.633,0.116,1.4-1.984c-0.233-2.1,0-3.03,1.984-4.314c1.983-1.282,1.515-1.4,2.565-1.283s1.749,0.584,3.732,0.584
        s0.933,3.149,4.664,2.215c3.732-0.933,3.964,0.933,4.897,0.933c0.451,0,0.656,0.411,0.803,1.019
        c0.405-0.398,0.845-0.781,1.128-0.962c0.648-0.408,2.676-2.507,3.26-4.198s0-2.215,0.175-3.615
        c0.175-1.399,2.797-1.399,2.797-1.633s-0.349-0.583,1.633-2.214C629.198,580.946,626.633,580.946,626.341,579.72z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_13_" x="554.754" y="610.451" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M621.502,620.3c0,0-1.983,0.467-2.098,1.399c-0.116,0.933,0,0.817-1.4,0.992s-3.865-0.525-2.895,0.757
        c0.97,1.282,0.836,2.741,2.419,2.391c1.585-0.349,1.699-0.934,1.992-1.516c0.293-0.583,1.516-0.933,1.69-0.642
        c0.176,0.292-0.756,1.342-1.165,1.808c-0.409,0.467-1.049,1.165,0,1.807s1.808-0.116,2.041,0.642
        c0.232,0.758,0.175,1.282,1.631,0.991c1.457-0.29,2.333-0.341,2.216-1.366c-0.117-1.024-0.35-1.316-0.816-2.308
        c-0.468-0.991-0.875-1.983-1.341-2.098c-0.467-0.116-0.942-0.059-0.676-0.932C623.368,621.35,622.314,620.033,621.502,620.3z" pointer-events="auto" class="landarea"></path>
</g>
<g id="AREA_ROMANIA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Romania" data-wikipediaLink="https://en.wikipedia.org/wiki/Romania" data-infoText="<b>Romania</b> &amp;nbsp; Capital: Bucharest" data-imageFile="ROMANIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="ROMANIA" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M670.477,509.641c0.054-0.449,0.122-0.835,0.198-1.18c-0.214-0.163-0.414-0.41-0.578-0.773c-0.874-1.924-4.897-5.16-5.947-4.46
        c-1.049,0.699-4.634,4.547-8.483,3.498c-3.848-1.049-8.483-3.673-9.184-6.296c-0.7-2.623,0.875-1.138,1.051-3.586
        c0.175-2.449-2.281-2.712-1.229-5.422c1.054-2.711,0.441-3.324,2.19-6.559c1.749-3.236,1.487-5.772,1.049-7.26
        c-0.436-1.487-4.722-9.532-7.172-12.156c-2.449-2.623-6.296-3.848-6.997-9.271c-0.699-5.422,0-7.871-3.498-9.795
        c-3.498-1.925-4.547-5.249-5.947-3.498c-1.4,1.749-4.372,2.274-4.547,3.848c-0.176,1.575-2.1,2.623-4.723,2.974
        c-2.623,0.349-5.946,0.349-6.821,1.049c-0.874,0.7-2.798-0.525-4.023,1.05c-1.224,1.575-0.874,4.372-3.323,3.324
        c-2.449-1.05-3.673-4.722-5.947-4.722c-2.274,0-2.9,0.699-5.998-0.349c-3.097-1.049-8.87-3.312-10.619-1.482
        c-1.749,1.831-2.798-1.143-4.547-2.017c-1.338-0.669-1.654,2.039-2.901,2.452c-0.075,0.637-0.148,1.282-0.189,1.746
        c-0.116,1.282-1.049,1.283-1.983,2.099c-0.933,0.816-0.233,2.565-1.983,1.632c-1.749-0.933-1.4-2.101-2.449-0.816
        c-1.05,1.282-1.167,1.399-2.332,1.749c-1.165,0.349-0.583,0.116-1.282,1.749c-0.699,1.635-1.167,1.866-1.866,2.334
        c-0.7,0.466-1.049,0.349-1.049,1.865s-0.584,2.564-1.05,3.149c-0.466,0.584-1.167,1.634-1.4,2.333
        c-0.233,0.698-1.516,2.682-1.631,4.896c-0.117,2.215-2.917,2.449-4.198,4.081c-1.283,1.631-1.749,2.799-1.749,4.665
        s0,2.798-1.4,2.916s-2.448,3.032-4.43,1.634c-1.983-1.4-1.75,0.584-3.149,2.1c-1.4,1.515-2.1,1.049-4.198,1.4
        c-2.098,0.349-2.915,1.05-1.399,2.331c1.515,1.283,5.479,2.682,6.062,3.849c0.584,1.167,0.933,2.332,1.635,4.897
        c0.7,2.565,2.332,5.363,5.248,5.714c2.916,0.351,6.217,1.749,5.674,2.681c-0.542,0.933-3.108,2.681-1.942,4.081
        c1.166,1.4,3.185,1.866,1.942,2.682c-1.244,0.816-2.488,1.4,0,2.682c2.489,1.282,4.937-0.933,5.986,1.167s1.517,5.131,2.916,3.149
        c1.4-1.984,2.1-4.781,3.265-4.198c1.165,0.584,4.665-0.233,4.781,1.281c0.117,1.517-2.566,0.349-2.916,1.983
        c-0.349,1.634-0.466,2.916,1.283,4.664c1.749,1.749,0.467,1.632,2.798,2.682c2.332,1.05,4.082,1.984,2.798,2.916
        c-1.282,0.933-2.564,3.614-1.049,3.732c1.516,0.117,7.463-0.933,9.795,0s7.229,1.516,9.678,1.4s7.114-0.699,9.562,0.584
        c2.449,1.282,4.43,2.1,7.112,1.4s6.413-2.565,8.045-4.198c1.633-1.634,0.933-2.682,4.315-3.266
        c3.382-0.583,5.363-3.265,9.445-2.448c4.081,0.817,3.149,1.051,6.647,2.215c3.498,1.165,7.347,0.35,8.744,3.031
        c0.994,1.906,2.171,3.396,8.2,3.182c0.057-0.243,0.153-0.498,0.314-0.851c0.584-1.282,1.283-4.43,1.166-8.047
        c-0.117-3.615,1.283-7.462,1.983-8.044c0.7-0.583,1.866-1.634,0.816-4.081c-1.05-2.449,0-3.964,0.816-4.897
        s2.916,2.449,2.798,4.664c-0.116,2.215,2.216,0.234,6.53-0.116C673.507,515.004,670.128,512.556,670.477,509.641z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_14_" x="573.677" y="480.615" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_IRELAND" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Ireland" data-wikipediaLink="https://en.wikipedia.org/wiki/Ireland" data-infoText="<b>Ireland</b> &amp;nbsp; Capital: Dublin" data-imageFile="IRELAND.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="IRELAND" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M154.844,309.37c-0.175-0.467-0.816-1.282-2.332-0.7c-1.516,0.583-2.449,1.167-4.082,1.714c-1.633,0.55-1.165-1.365-1.108-3.463
        c0.058-2.098-1.458-0.583-2.274-1.225c-0.816-0.642-0.642-2.332-1.341-3.09c-0.699-0.757-1.516-1.633-2.741-0.992
        c-1.224,0.642-1.399,0.757-1.69,2.099c-0.291,1.341,0.816,2.215,0.291,2.391c-0.525,0.175-1.05,0.992-2.1,1.983
        s-1.983-0.409-2.623-0.409s-1.983-0.175-2.681-1.516c-0.699-1.341-0.35-0.758-1.165-0.758c-0.816,0-1.866-0.408-2.623-2.215
        c-0.758-1.807-2.392-1.749-2.858-2.915c-0.466-1.166,2.215-1.632,3.52-1.691c1.305-0.059,2.078-1.633,3.186-2.565
        s-1.517-1.165-2.449-1.575c-0.933-0.408,0.233-0.7,0.816-0.933c0.584-0.233,2.098-0.524,3.207-0.758
        c1.108-0.233,0.816-0.991,1.4-1.631c0.584-0.641,0.408-1.341,0.992-1.983c0.583-0.642,0.642-1.4,0.642-2.215
        s0.583-1.341,1.575-1.69s1.4-0.291,1.4-0.291c-0.467-1.4,0-1.399,1.4-2.565c1.4-1.167-0.233-2.334-1.05-2.858
        c-0.816-0.524-2.215-4.488-2.798-3.089c-0.583,1.4,0.234,2.333-1.399,2.565c-1.631,0.233-0.467,0,0,1.632s1.117,4.198-0.841,3.382
        c-1.958-0.816-1.258-4.198-3.357-4.082c-2.098,0.116-1.633,1.631-3.615,1.631c-1.983,0-4.665,0.35-4.431,1.167
        c0.234,0.816,0.234,1.865-1.165,2.215c-1.4,0.349-1.749,1.05-0.699,1.865c1.05,0.817,0.816,2.565,0.35,2.565
        c-0.467,0-2.565,0.583-2.449,1.167c0.116,0.584-1.399,1.75-2.214,2.333c-0.817,0.583-1.866,1.983,0,2.332
        c1.865,0.35,4.665,1.05,5.714,0c1.05-1.05,2.567-1.282,1.983,0.117c-0.583,1.4-0.933,4.514-0.933,4.514s-2.567-1.084-3.382,0
        c-0.816,1.083-1.167,2.016-1.167,2.715s0.467,2.555-1.05,2.561c-1.515,0.005-4.431-1.044-5.131-0.578
        c-0.699,0.467,0.117,2.1-1.4,1.983c-1.516-0.117-0.816-3.149-2.798-2.798c-1.983,0.349-5.248-1.051-6.647-0.117
        s-0.699,1.975-2.449,1.511c-1.749-0.461-3.265-0.461-2.333,0.587c0.933,1.05,3.615,3.732,2.799,4.198
        c-0.816,0.467-5.248-0.583-3.964,1.05c1.283,1.632,2.332,3.732,3.848,3.265c1.516-0.467,4.547-2.565,4.547-1.167
        c0,1.4-0.233,3.033-1.399,3.265c-1.167,0.233-4.782,1.4-3.848,2.798c0.933,1.4-0.699,1.866-1.4,3.149
        c-0.699,1.282,0,3.148,0.584,3.382c0.583,0.234,4.547,0.234,4.431,0.932c-0.117,0.7-3.033,2.215,0.467,2.333
        c3.498,0.116,5.363-0.233,6.645,0c1.283,0.232,5.363-2.749,4.082,0.024c-1.282,2.774-3.265,2.891-5.248,3.707
        s-1.983,1.983-1.866,3.149c0.117,1.166,1.167,3.498-0.699,4.781c-1.866,1.283-4.897,1.4-4.781,2.798
        c0.116,1.4,2.449,0.933,4.665,0.583c2.215-0.349,0.584,1.631,3.848-0.117c3.265-1.749,3.266-3.848,4.082-2.915
        c0.817,0.933,1.983,2.448-0.349,2.915s-5.363,1.75-7.347,2.215c-1.983,0.467-3.382,0.349-4.665,1.865
        c-1.282,1.516-0.698,2.682-0.583,3.149c0.116,0.467-1.283,2.798-1.866,2.332c-0.583-0.467-2.095-1.516-3.379-0.699
        c-1.284,0.816-3.501,1.282-2.684,2.215c0.816,0.933,0.467,0.584,4.431,0.584s5.83-0.805,4.314,0.065
        c-1.515,0.867-3.381,0.519-5.83,2.734c-2.449,2.215-5.132,3.149-3.091,4.315c2.042,1.167,4.723,2.215,6.822,0.816
        c2.098-1.4,3.964-3.148,3.615-1.631s-5.248,3.149-5.83,4.897c-0.583,1.749,1.282,3.265,2.567,1.865
        c1.282-1.399,5.483-3.614,5.366-3.265c-0.118,0.349-1.985,3.382-2.683,4.547c-0.699,1.167-0.35,2.916,3.265,0
        s0.933,0.116,2.682,0.116s5.598,0.699,8.162-1.516c2.565-2.215,6.181-3.732,6.181-4.665s-1.983-3.149-0.699-3.149
        c1.282,0-0.933,2.565,2.449,2.215s3.733-1.049,4.431-2.333c0.699-1.282-0.584-1.865,1.749-1.749
        c2.332,0.116,2.915-0.583,4.081-1.516c1.167-0.933,1.749-1.632,3.848-1.516c2.098,0.116,5.248-1.632,5.248-2.215
        c0-0.584,1.282-0.349,3.848,0.116c2.565,0.467,3.265-0.933,2.215-1.749s-0.583-2.565,0.699-3.498
        c1.282-0.932,1.983-2.098,2.565-4.314c0.583-2.215,1.166-2.916,2.565-4.897c1.399-1.983-0.116-8.745-1.051-10.145
        c-0.932-1.399-2.798-1.399-1.165-4.314c1.631-2.916,0.699-4.431-0.467-4.781c-1.167-0.35,0.349-6.063-1.516-6.296
        c-1.866-0.233-2.798-0.233-0.699-1.865c0,0,1.282,0.233,3.148-0.467c0.429-0.16,0.791-0.355,1.112-0.569
        C155.376,310.154,154.939,309.623,154.844,309.37z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_16_" x="101.169" y="325.741" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="58.436" height="20.542" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_ITALY" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Italy" data-wikipediaLink="https://en.wikipedia.org/wiki/Italy" data-infoText="<b>Italy</b> &amp;nbsp; Capital: Rome" data-imageFile="ITALY.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M376.773,585.522c-0.128,0.51-1.662,0.918-2.274,1.662c-0.612,0.744-0.219,0.656-1.792,1.399c-1.575,0.744-1.487,0.788-2.144,1.969
        c-0.656,1.18-1.093,1.618-2.056,1.662c-0.961,0.044-0.961,1.137-2.406,1.18c-1.443,0.044-0.919,0.087-2.318-0.612
        c-1.4-0.699-2.406-0.175-1.836-1.443c0.569-1.268,1.662-1.662,1.792-2.536s0.219-2.449-0.482-1.529
        c-0.699,0.918-0.892,1.574-1.474,2.448s-0.843-0.262-1.194,1.618c-0.349,1.88,0.436,1.53,0,2.843
        c-0.436,1.312-1.137,1.705-0.349,2.929c0.787,1.224,1.05,0.219,2.274,1.968s1.88,1.881,1.749,3.192
        c-0.131,1.312,0.393,0.524,0.874,1.924c0.482,1.4,1.181,1.662,0.482,2.885c-0.699,1.224-2.056,0.7-1.487,2.231
        c0.568,1.53,1.267,1.093,1.575,2.406c0.306,1.312,0.612,1.575,0.262,2.449c-0.349,0.874-0.744,2.711-1.049,3.28
        c-0.307,0.569-1.181,0.7-1.138,1.967c0.044,1.269,0.22,1.662,0.175,2.623c-0.044,0.961,0,1.006-0.7,1.749
        c-0.699,0.744-0.874,1.792,0,1.705c0.875-0.087,1.487-0.787,1.53,0.219c0.044,1.006-0.831,2.361,0.349,2.58
        c1.181,0.22,1.313-0.219,1.792,0.874c0.482,1.093,2.012,0.612,2.885,0.612c0.874,0,1.269,1.399,2.274-0.131
        c1.006-1.53,2.536-2.667,1.924-3.849c-0.611-1.18-1.006-2.493-0.044-2.361c0.961,0.133,0.481,1.225,1.618,0.395
        c1.137-0.831,1.18-1.268,1.662,0.131c0.482,1.4,0.263,2.406,1.575,2.011c1.313-0.393,2.536-1.267,2.318-2.449
        c-0.219-1.18-0.693-1.486,0.025-2.755c0.719-1.269,1.068-0.744,0.936-2.842c-0.131-2.098-0.821-2.187,0.027-3.542
        c0.847-1.356,1.852-3.673,1.329-4.942c-0.525-1.267-1.792-2.144-1.618-3.76c0.176-1.618,0.991-2.1,1.763-3.367
        c0.773-1.267,1.779-3.411,1.079-4.155c-0.699-0.743-2.406-2.667-1.88-4.417c0.524-1.749,0.524-2.186-0.525-3.411
        c-1.049-1.225-1.705-2.318-1.705-3.28C380.579,586.09,377.079,584.298,376.773,585.522z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M509.094,604.558c-1.167-2.1-5.48-5.48-7.463-8.162s-4.312-1.866-6.762-3.381c-2.449-1.517-1.634-3.382-10.263-6.182
        c-8.628-2.798-9.212-4.081-11.894-6.179c-2.682-2.1,1.983-3.149,2.682-6.063c0.7-2.916-2.798-2.333-6.763-1.866
        c-3.964,0.467-10.261-1.282-12.71-3.149c-2.449-1.865-8.279-8.978-10.378-11.078c-2.099-2.098-4.082-14.108-5.48-17.607
        c-1.4-3.498-3.031-0.584-5.248-3.615c-2.215-3.032-6.647-5.248-10.843-7.58c-4.198-2.331-3.615-8.745-4.198-12.01
        c-0.584-3.266,1.865-3.265,3.731-4.781c1.865-1.516-0.933-1.4-2.449-3.732s-0.583-1.517-1.865-4.316
        c-1.282-2.799,0.816-1.865,2.215-3.849c1.399-1.982,2.567,0.584,3.539,0.817c0.975,0.233,0.657-0.117,1.591-1.4
        c0.932-1.282,1.749-0.933,3.614-1.167c1.865-0.233,1.282-0.117,1.167-1.166c-0.116-1.049,1.05-1.282,2.567-2.098
        c1.516-0.817,1.749,1.049,2.682,1.516c0.933,0.467,0.35-0.584,1.05-1.632c0.468-0.701,0.728-0.571,1.23-0.546
        c-0.017-0.467-0.001-0.944,0.11-1.204c0.262-0.612,1.049-2.535,0-2.622c-1.05-0.087-3.149-0.236-2.536-1.08
        c0.612-0.843,3.936-2.856,2.623-3.03c-1.312-0.175-4.109-0.175-3.848-1.224c0.263-1.049,0.961-2.711,1.575-3.149
        c0.607-0.432,1.654-2.409,2.688-2.53c-3.122-1.782-11.617-2.94-15.894-3.328c-5.772-0.525-2.099-2.973-4.372-4.382
        c-2.274-1.409-0.525-3.138-0.525-5.413c0-2.274-5.073,0.874-5.809,2.274c-0.737,1.4-5.21,0.524-7.659,1.224
        s-2.974,3.498-4.198,3.059c-1.225-0.439-3.324-0.783-5.598-0.085c-0.535,0.166-0.941,0.071-1.262-0.164
        c-0.434,1.997-0.474,4.44-0.663,5.762c-0.35,2.449-2.273-1.049-4.139-1.749c-1.865-0.7-2.157,1.924-1.109,3.148
        c1.05,1.225,1.225,4.547,1.05,5.817c-0.175,1.27-2.098-1.444-2.449-3.183c-0.35-1.739-4.897,1.389-6.998,1.213
        c-2.098-0.175-1.575-3.673-3.148-4.198c-1.575-0.524,0,4.024-1.4,6.168c-1.399,2.145-1.924,2.404-0.874,3.773
        c1.051,1.369-0.524,4.404-1.399,3.25c-0.874-1.154-1.924-3.044-2.449-4.618c-0.525-1.575-4.198-1.924-5.947-2.404
        c-1.749-0.481,1.049-5.466-0.175-5.117c-1.225,0.349-5.248,0.7-4.723,1.934c0.525,1.236-0.874,5.587-1.749,6.955
        c-0.874,1.37-3.848,2.129-4.547,1.43c-0.7-0.7-3.673,0.524-6.297,1.4c-2.161,0.721-2.779,0.489-3.027-0.494
        c-0.506,0.63-1.081,1.249-1.345,1.172c-0.525-0.153-2.449,1.946-1.051,3.171c1.4,1.224,4.023,2.973,3.324,4.197
        c-0.7,1.225,0.757,2.993,0.116,3.945c-0.642,0.952-1.34,2.352-2.74,3.402c-1.399,1.05-5.248-0.176-3.498,3.498
        c1.749,3.673,3.673,3.848,4.723,3.148c1.049-0.698,2.841,4.372,1.683,4.547c-1.159,0.175-6.193,4.372-3.239,5.772
        c2.954,1.4,6.976,4.547,9.076,3.673c2.099-0.874,2.274-1.273,1.575,1.462c-0.281,1.1-1.432,2.944-2.243,4.87
        c1.322-0.348,4.173-0.804,7.258-1.259c3.149-0.467,3.498-3.732,5.131-6.764c1.631-3.03,3.732-3.03,7.463-4.315
        c3.732-1.283,2.565,1.4,5.13,1.516c2.565,0.117,2.916,1.866,5.831,4.081c2.916,2.214,4.198,1.399,5.598,1.399
        c1.399,0,3.149,3.032,3.265,5.947c0.117,2.915,1.4,5.597,2.682,6.997s1.517,3.031,0.699,5.714
        c-0.816,2.682,0.817,3.031,1.516,3.031c0.7,0,1.866,2.448,2.799,2.682c0.932,0.234,6.647,8.862,9.212,8.862
        s4.316,2.916,4.547,4.547c0.233,1.631,2.682,2.449,4.547,3.615c1.866,1.167,2.682,5.248,8.162,9.212
        c5.481,3.966,3.964,3.149,5.363,5.014c1.4,1.865,0.467-1.165,4.081-0.816c3.615,0.349,2.916-0.35,6.063-0.35
        c3.149,0,3.964,5.248,3.964,6.296c0,1.049,3.615,1.865,5.364,1.4c1.749-0.467,0.233,3.614,0.467,5.597
        c0.232,1.983,2.098-1.167,4.896-1.4c2.799-0.233,3.265,4.081,2.566,6.763s2.565,0.816,5.48,4.198c2.916,3.381,3.498,0.116,4.547,0
        c1.049-0.117,5.83,8.629,5.83,13.176s2.098,6.181,2.916,9.445c0.817,3.266-2.098,1.051-3.848,3.614
        c-1.749,2.566,0.117,2.916-1.049,6.064c-1.167,3.148-1.865,1.05-2.682,3.266c-0.816,2.215,1.282,2.798,3.149,3.964
        c1.866,1.165,2.682,0.349,3.614-2.1c0.933-2.448,0.235-0.699,3.382-3.732c3.149-3.031,2.565-1.165,3.382-4.547
        c0.815-3.381,0.349-3.849,1.167-5.947c0.816-2.098,4.781-1.4,6.53-1.282c1.749,0.116,1.167-2.1,0.349-3.033
        c-0.816-0.933,0.233-3.732-0.234-6.181c-0.467-2.449-1.166-2.565-3.265-3.964c-2.1-1.4-2.681-0.7-4.547-3.382
        s1.399-3.732,1.866-7.347s3.148-5.248,5.013-6.413c1.865-1.165,2.333,1.049,5.014,3.148c2.682,2.098,4.198,1.282,5.714,1.399
        c1.516,0.116,1.631,3.031,2.916,5.714c1.281,2.682,2.332,3.498,5.131,4.082c2.798,0.584,1.049-1.866,1.983-4.082
        C510.377,607.241,510.261,606.657,509.094,604.558z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_17_" x="361.824" y="494.001" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M451.941,645.472c0.619-0.049,1.705-1.18,1.836-1.006c0.131,0.176,0.874-0.262,1.137-0.874c0.262-0.612,0.918-1.225,1.969-0.874
        c1.05,0.349,1.749,2.04,3.061,1.326c1.313-0.714,1.356-1.719,2.274-1.676c0.919,0.044,1.618,0.263,2.056-0.219
        c0.437-0.482,0.699-1.4,1.792-0.961c1.093,0.436,2.055,0.611,1.618,1.443c-0.438,0.831-2.187,2.098-2.58,3.104
        c-0.393,1.006-0.787,2.361-1.486,2.972c-0.7,0.612-1.313,0.964-1.313,1.969c0,1.006,0.176,1.486-0.481,2.449
        c-0.656,0.961-1.269,1.137-1.181,2.055c0.087,0.918-0.525,1.969,0.307,2.929c0.831,0.961,1.399,0.787,1.399,1.75
        c0,0.961-0.22,1.617,0.569,2.1c0.787,0.481,1.792,0.698,0.962,1.574c-0.831,0.874-1.88,1.269-1.967,1.881s-1.269,1.006-1.181,2.055
        c0.088,1.051,0.656,2.056,0.045,2.406c-0.612,0.35-0.919,0.525-2.886,0c-1.967-0.525-1.705,0.482-3.498-0.569
        s-3.454-1.313-3.63-2.842c-0.175-1.53,0.482-2.055-1.792-2.974c-2.274-0.918-2.449-1.662-4.242-1.093s-2.756,0.219-4.198-1.224
        c-1.443-1.443-2.318-1.967-3.761-2.58c-1.443-0.612-1.836-1.18-2.973-2.274c-1.137-1.093-1.792-2.23-3.105-2.012
        c-1.313,0.22-3.761,1.094-5.466-0.393s-3.323-2.231-3.235-3.28c0.087-1.051,0.693-2.056-0.003-2.406
        c-0.696-0.35-1.045-1.267-0.565-1.443c0.481-0.175,1.137,0.087,1.88-0.918c0.744-1.006,0.535-1.575,1.646-2.1
        c1.11-0.525,1.241-2.274,1.852-1.181c0.612,1.094,1.137,2.623,1.881,2.843c0.744,0.219,2.361-0.831,2.667-1.4
        c0.306-0.569,0.874-1.4,1.442-1.443c0.569-0.044,1.749-1.225,2.493-0.262c0.744,0.962,1.617,1.705,2.756,2.493
        c1.137,0.787,1.705,2.055,3.104,1.792c1.4-0.262,1.722-1.137,3.353-0.874C446.125,645.998,448.007,645.78,451.941,645.472z" pointer-events="auto" class="landarea"></path>
</g>
<g id="AREA_AUSTRIA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Austria" data-wikipediaLink="https://en.wikipedia.org/wiki/Austria" data-infoText="<b>Austria</b> &amp;nbsp; Capital: Vienna" data-imageFile="AUSTRIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="AUSTRIA" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M440.354,436.222c-1.225,0.19-4.896-2.263-4.372-0.161c0.525,2.101,1.19,5.25-0.805,5.424c-1.994,0.176-4.268-0.873-4.967,1.925
        c-0.699,2.798-3.149,0.874-2.799,3.149c0.35,2.274,2.975,3.149,2.799,4.547c-0.176,1.4-1.749,3.848-0.35,4.197
        c1.4,0.35,2.099,1.576,1.4,2.449c-0.699,0.874-3.568,2.972-3.883,0.874s-1.572-4.198-2.431-3.324
        c-0.858,0.874-1.383,0.874-3.131-0.349c-1.75-1.224-1.75-2.993-2.099-0.796c-0.35,2.195-1.925,2.195-4.547,2.721
        c-2.623,0.525-6.123,0-6.472,1.749s-0.873,2.449-2.972,2.274c-2.099-0.175-4.547,0.7-5.422-0.874
        c-0.874-1.575-2.449-2.798-2.974-1.749s-1.575,0.175-2.973,0.7c-1.401,0.524-0.525,3.148-1.401,4.197
        c-0.874,1.05-2.449,2.274-2.973,0s-4.022-3.848-5.072-3.848c-1.051,0-2.274,1.575-1.575,2.448c0.7,0.875,0.814,1.925-0.205,3.673
        c-1.018,1.749-0.669,1.575,1.08,3.498c1.749,1.924,2.623,4.004,4.022,3.838c1.4-0.166,3.848-3.138,4.372-2.614
        c0.524,0.525,0.524,4.374,2.798,3.673c2.274-0.698,4.372-0.354,5.598,0.085c1.224,0.44,1.749-2.358,4.198-3.059
        c2.449-0.699,6.923,0.175,7.659-1.224c0.737-1.4,5.809-4.547,5.809-2.274c0,2.274-1.749,4.004,0.525,5.413s-1.4,3.858,4.372,4.382
        c4.276,0.389,12.77,1.545,15.894,3.328c0.009-0.001,0.019-0.005,0.028-0.006c1.044-0.087,5.505-1.049,6.116-0.611
        c0.612,0.436,0.612,1.486,2.798,1.749c2.187,0.263,3.412,0.961,3.674,1.4c0.263,0.438,0.7,0.7,1.138,0.087
        c0.436-0.612,3.149-3.76,3.848-4.46c0.7-0.7,2.187-2.273,3.236-1.662c1.051,0.612,3.411,1.224,4.372,0.874
        c0.961-0.349,2.098,0.073,2.361-1.013c0.262-1.086,3.586-1.262,3.849-1.173c0.262,0.087,2.011,1.05,2.098,0.611
        c0.087-0.436-0.087-3.497,1.487-3.497c0.441,0,0.886-0.011,1.307-0.007c1.179-1.189,2.84-1.677,3.585-1.571
        c1.219,0.172,0.705-3.496,0.991-5.594c0.285-2.099-0.809-2.623-0.984-3.849c-0.175-1.224,3.497-1.399,4.547-1.749
        c1.05-0.349-0.35-2.274-2.1-2.798c-1.75-0.524,1.049-2.622,1.399-3.148c0.35-0.524,1.924,1.4,3.498,1.4
        c1.575,0,2.799-1.749,2.623-2.972c-0.146-1.027,0.939-2.79,2.119-3.588c-1.413-2.806-4.6-5.611-4.315-6.525
        c0.429-1.367,0.621-4.23,0.095-6.331c-0.119-0.481-0.116-0.865-0.036-1.206c-1.569-1.549-4.413-2.952-5.385-2.815
        c-1.225,0.175-2.799,1.575-4.897,0.525c-2.1-1.049-2.623-2.098-3.498-1.749c-0.874,0.35-3.673,0-4.198-1.049
        c-0.524-1.05-2.449-1.4-3.848-1.224c-1.4,0.175-3.325-1.225-5.947-1.571c-2.623-0.346-0.698,3.671-0.874,5.244
        c-0.175,1.575-1.749,0.175-3.149,1.767c-1.399,1.592-0.699,1.907-1.575,2.079c-0.874,0.173-3.148-0.697-5.423,0
        c-2.274,0.698-3.323-1.046-3.497-2.079c-0.147-0.864-1.883-1.52-2.55-2.225c-0.232,0.679-0.424,1.469-0.424,2.225
        C441.928,435.888,441.578,436.031,440.354,436.222z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_19_" x="419.508" y="446.941" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_CZECHREPUBLIC" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Czech Republic (Czechia)" data-wikipediaLink="https://en.wikipedia.org/wiki/Czech_Republic" data-infoText="<b>Czech Republic (Czechia)</b> &amp;nbsp; Capital: Prague" data-imageFile="CZECH.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="CHECH_REPUBLIC" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M506.819,419.972c-0.175-0.525,3.498-3.498,5.073-3.674c0.814-0.09,1.766-0.415,2.5-0.706c-1.228-1.798-3.059-5.095-4.074-5.94
        c-1.049-0.874-0.524-2.623-3.673-3.149c-3.149-0.525-4.722-3.498-5.771-2.274c-1.05,1.224-4.547-2.972-4.547-2.972
        s4.896-1.4,2.1-2.449c-2.798-1.05-4.023,1.749-6.821-1.05c-2.798-2.798-5.597-3.848-5.597-1.924c0,1.924,2.098,4.197,0,5.248
        c-2.098,1.05-5.073,1.575-5.423-0.35c-0.349-1.924-3.673-4.372-4.372-5.073c-0.699-0.7,0.349-0.874,1.749-2.449
        s1.049-4.722-1.049-4.022c-2.1,0.7-4.023,1.924-6.821,0c-2.799-1.925-5.073-1.224-7.872-4.548
        c-2.798-3.324-3.148-3.324-3.848-3.324c-0.168,0-0.133-0.276-0.001-0.724c-0.73,0.339-1.574,1.009-1.574,2.298
        c0,2.274-2.098,4.723-3.323,4.372c-1.224-0.349,0.175-5.073-2.274-5.073s-4.915,1.924-3.77,3.498
        c1.146,1.575,1.598,4.198-0.289,4.198c-1.889,0-6.261-1.924-7.135-0.699c-0.875,1.224-4.547,3.498-5.947,3.848
        s-3.498,4.547-4.547,3.673s-4.406-0.286-4.564,0.119c-0.159,0.406-4.006,0.755-4.182,1.629s-1.399,1.224-2.972,0
        c-1.575-1.224-1.05,1.049,0,2.799c1.049,1.749,4.023,1.749,4.023,4.372s-1.75,3.324-0.699,5.073
        c1.049,1.749,3.99,2.973,3.832,4.896c-0.159,1.925,1.241,3.498,3.515,4.198c2.273,0.7,4.722,2.449,4.896,4.023
        c0.175,1.573,3.517,2.105,4.643,3.151c1.127,1.046,3.927,3.496,4.101,4.369c0.175,0.874,2.623,1.61,2.799,2.642
        c0.175,1.032,1.224,2.777,3.497,2.079c2.274-0.697,4.547,0.173,5.423,0c0.874-0.172,0.176-0.487,1.575-2.079
        c1.4-1.592,2.975-0.193,3.149-1.767c0.176-1.575-1.749-5.59,0.874-5.244s4.547,1.746,5.947,1.571
        c1.399-0.175,3.324,0.175,3.848,1.224c0.525,1.049,3.324,1.4,4.198,1.049c0.874-0.349,1.4,0.7,3.498,1.749
        c2.098,1.049,3.673-0.35,4.897-0.525c0.973-0.138,3.817,1.265,5.385,2.815c0.27-1.152,1.46-1.765,2.135-3.54
        c0.874-2.301,1.75-0.847,3.324-0.323c1.575,0.525,2.974,0.525,5.032-0.874c2.059-1.4,2.315-1.4,4.064-2.098
        c1.749-0.699,2.274-1.4,2.1-3.498C503.671,422.42,506.996,420.495,506.819,419.972z" pointer-events="auto" class="landarea"></path>

        <rect id="C5_1_" x="432.786" y="399.992" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_LITHUANIA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Lithuania" data-wikipediaLink="https://en.wikipedia.org/wiki/Lithuania" data-infoText="<b>Lithuania</b> &amp;nbsp; Capital: Vilnius" data-imageFile="LITHUAN.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="LITHUANIA" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M556.144,282.489c1.924,2.277,2.623,3.852,4.723,2.978c2.098-0.874,5.597-1.575,6.645-0.7c1.049,0.874,1.925-0.003,2.274,2.097
        c0.35,2.101-1.924,0.177,0.35,2.101c2.274,1.925,2.098,4.023,1.575,4.897c-0.525,0.874-4.547,5.771-1.925,7.696
        c2.623,1.924,5.598,0.349,6.997,1.749c1.4,1.4,4.896,0.525,4.896,4.722c0,4.198,1.225,5.947,3.849,5.947s3.221-1.575,6.946-0.699
        c3.724,0.875,3.724-0.874,5.648-1.05c1.924-0.175,1.749-2.098,2.1-3.673c0.35-1.575,0-1.225,2.274-1.225
        c2.273,0,2.798-1.575,4.198-2.448c1.4-0.874,2.274-2.099,2.973-0.874c0.699,1.224-1.575,3.498,1.75,3.498
        c3.324,0,2.623-2.274,1.049-4.722c-1.575-2.449-1.575-3.149-0.176-5.248c1.4-2.098,1.406-2.623,1.49-5.947
        c0.085-3.324-1.839-2.972,2.183-3.673c4.023-0.7,4.897-2.1,4.897-3.848c0-1.749,0.874-0.709,4.022-1.579
        c3.149-0.87,6.122-4.208,3.673-4.376c-2.449-0.168-5.248,0.882-4.547-2.616c0.393-1.961,2.232-3.815,3.675-6.727
        c-1.063,0.399-2.288,0.279-5.074-1.494c-3.848-2.449-3.674-2.623-4.177-4.547s-4.043-3.149-7.366-4.896
        c-3.324-1.749-3.149-0.35-6.821-2.519c-3.673-2.17-1.575-3.953-2.623-5.729c-1.05-1.776-2.1,1.426-5.073,3.7
        c-2.975,2.274-0.875,0.525-4.074,0.525c-3.2,0-1.348-0.525-2.748-0.7c-1.4-0.175-4.372,0-7.347-1.05
        c-2.972-1.05-3.149,0-5.073,1.575s-1.924-1.05-2.798-3.26c-0.874-2.211-2.798,0.812-3.673,1.86c-0.874,1.05-3.498-0.7-4.897-1.4
        s-4.896-0.525-7.347,0c-2.448,0.525-4.198,2.449-7.346,2.623c-3.149,0.175-1.749,5.073-1.924,6.472
        c-0.084,0.673-1.341,0.696-2.719,0.542c-0.105,1.071-0.187,2.357-0.079,3.946c0.349,5.13,3.498,6.647,4.897,11.427
        c0.625,2.138,0.294,3.248-0.231,4.126c0,0,0.001,0,0.001,0.001C552.646,281.443,554.22,280.21,556.144,282.489z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_21_" x="560.046" y="262.599" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_LATVIA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Latvia" data-wikipediaLink="https://en.wikipedia.org/wiki/Latvia" data-infoText="<b>Latvia</b> &amp;nbsp; Capital: Riga" data-imageFile="LATVIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="LATVIA" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M549.324,259.929c0.175-1.399-1.225-6.296,1.924-6.472c3.148-0.175,4.897-2.098,7.346-2.623c2.449-0.525,5.948-0.7,7.347,0
        c1.4,0.7,4.023,2.449,4.897,1.4s2.798-4.072,3.673-1.86c0.874,2.211,0.874,4.835,2.798,3.26c1.924-1.575,2.098-2.623,5.073-1.575
        c2.974,1.05,5.947,0.874,7.347,1.05c1.4,0.175-0.452,0.7,2.748,0.7c3.199,0,1.1,1.749,4.074-0.525
        c2.973-2.274,4.023-5.476,5.073-3.7s-1.049,3.561,2.623,5.729c3.673,2.169,3.498,0.77,6.821,2.519
        c3.325,1.749,6.863,2.972,7.366,4.896c0.503,1.925,0.33,2.1,4.177,4.547c3.848,2.449,4.722,1.749,6.296,0.874
        s1.574-4.023,4.197-2.449c2.623,1.575,5.772,1.05,6.472,0.175s-1.224-1.924,1.4-3.498s4.722-8.711,5.772-7.066
        c1.049,1.644,3.328,0.944,2.364-2.904c-0.965-3.848-0.614-6.296-2.539-7.871c-1.924-1.575-2.273-2.812-1.924-3.856
        c0.35-1.043-1.049-2.616-1.749-2.442c-0.699,0.175-2.624,1.405-1.749-1.135c0.874-2.538,2.274-2.714,1.575-4.812
        c-0.7-2.099,1.049-2.099,0.874-4.023s0.35-2.449-2.098-4.023c-2.033-1.307-1.544-4.055-4.12-5.453
        c-0.136,0.906-0.332,1.768-0.777,2.416c-1.4,2.035-1.924-0.636-4.723-2.21c-2.798-1.575-2.449,0.874-5.947,0.874
        s-2.975-1.05-5.073-3.324s-2.623-3.673-3.673-3.673c-1.049,0-2.623-0.349-5.073-2.85c-2.449-2.5-2.798-2.922-3.848-1.987
        c-1.051,0.936-1.925-1.111-3.849-1.811c-1.924-0.698-2.798,2.449-4.723,2.199c-1.924-0.249-4.023,1.999-5.248,3.397
        c-0.297,0.339-0.696,0.636-1.13,0.892c0.223,0.599,0.466,1.19,0.664,1.789c0.816,2.449,0.583,6.879,0.349,11.777
        c-0.233,4.897-3.031,6.647-6.763,9.445s-5.947-0.7-8.162-1.866c-2.216-1.167-1.631-2.916-2.565-7.812
        c-0.932-4.897-3.031-3.265-5.363-4.845c-2.332-1.579-2.449-4.25-2.566-6.116c-0.116-1.866-0.349-2.099-2.332-0.116
        s-7.114,4.314-10.028,4.082c-2.916-0.234-3.498,3.848-4.315,5.83c-0.816,1.983,0,7.114-1.865,10.378
        c-1.866,3.265-2.449,1.865-3.733,5.131c-1.281,3.265,0.233,4.43-0.698,6.88c-0.932,2.449-2.098,6.53-0.117,8.279
        c1.367,1.207,1.015,2.527,0.779,4.917C547.982,260.625,549.239,260.603,549.324,259.929z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_22_" x="584.198" y="226.045" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_ESTONIA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Estonia" data-wikipediaLink="https://en.wikipedia.org/wiki/Estonia" data-infoText="<b>Estonia</b> &amp;nbsp; Capital: Tallinn" data-imageFile="ESTONIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M571.712,180.404c0,0-0.758,0.408-1.109,0c-0.349-0.408-0.524-2.449-1.107-2.215c-0.584,0.234-1.341,0.873-1.808,1.575
        c-0.467,0.699-0.757,1.224-1.749,1.282c-0.991,0.059-1.167-0.291-2.565,0c-1.4,0.291-2.916-0.35-2.799,0.642
        s-0.176,1.4,1.282,1.575s2.449-1.05,2.916,0c0.468,1.05,1.22,0.874,1.367,1.866c0.15,0.992,0.149,1.283,0.614,1.749
        c0.467,0.467,0,0.524,0.584,1.516c0.583,0.991,1.167,1.748,1.749,0.816c0.584-0.933,0.584-3.265,1.457-3.265
        c0.874,0,0.874,1.458,1.573,1.051c0.7-0.409,1.167-1.632,1.633-1.632s2.1-0.175,1.458-0.874c-0.643-0.7-1.458-1.341-1.517-2.098
        C573.636,181.628,572.645,179.822,571.712,180.404z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M579.408,190.899c0,0-2.04-2.01-2.391-1.267c-0.35,0.743-0.813,2.143-1.661,1.967c-0.847-0.175-2.188-1.05-3.18-0.7
        c-0.991,0.35-0.874,0.788-1.924,0.715s-2.215-1.773-2.681-1.069c-0.467,0.704-1.573,1.928-2.682,2.162
        c-1.109,0.233-1.051,0.699-1.575,1.224c-0.524,0.524-2.039,0.642-2.274,0.583s-1.749-1.4-2.215-0.874
        c-0.468,0.525-1.167,0.874-0.35,1.575c0.817,0.7,1.282,1.167,1.049,1.866c-0.233,0.7-1.101,1.808-1.425,2.157
        s-0.44,1.924,0.319,2.391c0.757,0.467,1.619,0.116,2.15,0.992c0.531,0.874,1.055,0.992,1.58,1.05
        c0.524,0.059,1.576,0.349,0.642,1.05c-0.934,0.7-2.624,0.642-3.09,2.391c-0.467,1.749-0.699,3.906,0.408,3.673
        c1.108-0.233,1.982-1.742,2.623-2.738c0.642-0.994,0.468-1.578,1.283-2.335c0.816-0.758-0.409-1.283,0.933-2.623
        c1.341-1.341,2.006-2.215,3.014-1.749c1.008,0.467,0.485,1.106,1.825,0.467c1.34-0.642,3.265-2.682,3.848-3.614
        c0.583-0.933,1.225-1.282,2.157-1.983c0.932-0.7,1.165-1.866,1.983-0.874c0.816,0.992,2.1,0.408,1.633-0.349
        c-0.467-0.758-1.341-2.274-0.817-2.449C579.116,192.355,580.208,191.605,579.408,190.899z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M579.408,179.18c0,0-1.808-0.116-2.682,0s-0.932,0.874-0.408,1.457s0.598,1.399,1.377,0.874c0.779-0.524,1.231-0.932,1.997-1.108
        C580.457,180.23,580.515,179.225,579.408,179.18z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M594.449,211.83c1.224-1.4,3.324-3.647,5.248-3.397c1.925,0.249,2.799-2.9,4.723-2.199c1.924,0.7,2.798,2.747,3.849,1.811
        c1.049-0.935,1.399-0.512,3.848,1.987c2.449,2.5,4.023,2.85,5.073,2.85c1.049,0,1.575,1.399,3.673,3.673
        c2.098,2.274,1.575,3.324,5.073,3.324s3.149-2.449,5.947-0.874c2.798,1.575,3.324,4.244,4.723,2.21
        c1.399-2.034,0.349-6.189,2.623-7.172c2.274-0.984,6.472,2.339,3.849-2.208s-3.673-5.248-4.199-9.796
        c-0.524-4.547,1.575-4.023,0.525-7.521c-1.049-3.498-2.449-4.547-0.874-7.696c1.575-3.149,1.049-4.955,2.972-6.414
        c1.924-1.457,1.749-5.48,3.849-6.88c1.71-1.14,7.021-3.095,0.874-6.716c-0.495,0.73-1.252,1.173-2.098,1.526
        c-1.4,0.584-6.997-0.35-10.377-0.584c-3.381-0.232-5.015-3.148-7.813-3.148c-2.799,0-5.831-0.699-9.328-1.865
        s-3.849,1.749-5.364,2.682c-1.516,0.932-2.681-0.234-4.781,0.583c-2.1,0.816-4.081,1.05-7.929,0.699
        c-3.849-0.349-3.733,0.584-6.064,1.399c-2.332,0.816-3.731,2.099-4.896,3.382c-1.166,1.283-2.798,1.282-5.715,1.631
        c-2.915,0.35-0.232,2.916,0,4.431c0.235,1.516,1.283,3.031-0.466,3.498s-0.467,1.631-0.467,3.964c0,2.332,4.081,0.933,5.597,1.631
        c1.516,0.7-3.265,1.05-5.48,2.215c-2.215,1.167,0.232,2.332,2.098,3.149c1.866,0.816-0.35,4.781,0.933,4.781
        s3.265,3.382,6.296,4.081c3.031,0.7,3.848-4.316,5.48-4.198c1.633,0.117,0.467,1.865-0.115,4.081
        c-0.583,2.215-0.35,2.682-2.215,5.831c-1.41,2.378-0.889,4.291-0.197,6.14C593.753,212.465,594.154,212.168,594.449,211.83z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_23_" x="588.818" y="174.633" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_DENMARK" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Denmark" data-wikipediaLink="https://en.wikipedia.org/wiki/Denmark" data-infoText="<b>Denmark</b> &amp;nbsp; Capital: Copenhagen" data-imageFile="DENMARK.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M421.815,258.005c0,0-4.198,0.175-4.374,1.05c-0.175,0.874-2.099,3.848-1.225,5.422c0.875,1.575-0.227,3.498-0.227,3.498
        s-1.523,1.4-1.26,0c0.262-1.4-1.487-3.062-1.838-2.449c-0.349,0.612-1.575,1.05-1.4,0c0.176-1.05,0.525-3.062-0.612-2.975
        s-1.487,0.525-1.836,1.4c-0.349,0.874,0.176,0.874-1.924,1.836c-2.099,0.961-0.263,1.836-1.487,2.274
        c-1.225,0.436-2.274-1.313-2.886-0.175c-0.612,1.137-0.874,1.924,0,2.798s1.224,2.1,1.049,2.972c-0.175,0.874,0,2.361,0.349,3.061
        c0.35,0.7-0.349,1.836,0.961,2.623c1.312,0.787,1.224,1.137,2.712,1.4c1.486,0.262,1.573,0.349,3.061,0.611
        c1.487,0.263,1.749,0.612,1.575,1.662c-0.175,1.05-1.575,1.4,0.087,2.098c1.662,0.7,1.049,0.263,2.187,0.964
        c1.137,0.7,2.274,0.611,2.274,1.137s-0.263,1.575-1.663,1.313c-1.4-0.263-1.924-1.575-2.622-1.05
        c-0.699,0.524-0.3,1.487-0.719,2.186c-0.419,0.7-0.594-0.087-2.343,0c-1.749,0.088-1.4,0.176-3.149-0.874s-3.498-2.1-3.673-0.525
        c-0.175,1.575,0.35,1.924,0.087,2.536c-0.262,0.611-0.699,0.35,0.964,1.662c1.662,1.312,2.798,2.536,4.111,3.149
        c1.312,0.611,3.497-1.137,4.547-0.612s3.673,4.023,3.498,2.099c-0.175-1.924-0.7-3.76,0.35-4.372
        c1.05-0.611,2.449-2.711,2.536-3.236s-0.349-0.7,1.749-1.05c2.099-0.35,4.285-1.138,3.062-2.011
        c-1.224-0.874-3.411-1.749-3.586-1.05s-1.224,1.924-1.312,0.874c-0.087-1.05-0.175-2.098-0.961-2.711
        c-0.787-0.612-1.925-0.175-0.525-1.575s1.749-0.262,3.411-1.575c1.662-1.312,3.062-2.885,1.051-3.76
        c-2.012-0.874-3.149-0.961-2.886-2.098c0.262-1.138,0.874-4.198,2.098-4.198c1.225,0,1.924-0.087,2.274,0.961
        c0.349,1.051,0.699,2.799,1.224,1.138c0.525-1.662,0.087-2.449,0-4.46c-0.087-2.012-1.487-2.536-1.575-4.285
        s1.995-1.749,1.829-2.885C424.612,259.667,422.338,257.305,421.815,258.005z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M371.965,289.314c0.874,0.7,6.472,2.162,6.647,1.256c0.175-0.907,4.146-3.131,5.659-1.407c0,0.001,0.001,0.001,0.001,0.001
        c0.941-0.312,3.159,0.193,4.133-0.375c1.4-0.816-2.332-3.031-2.915-4.431s-3.381-0.116-5.014-0.816
        c-1.631-0.7,0.233-1.516,2.216-2.332s-0.584-3.381-1.283-5.248c-0.699-1.866,1.983-1.05,3.031,0.7
        c1.05,1.749,1.982,3.964,5.597,7.114c3.615,3.149,6.531-0.467,8.629-1.4s0-4.198-0.584-5.831c-0.583-1.631-0.7-1.749-4.081-1.865
        c-3.382-0.116-0.934-2.567-2.798-3.382c-1.866-0.816-3.498,0.933-5.481,1.865c-1.983,0.933-2.565-1.05-3.265-1.749
        c-0.7-0.7,2.333-1.983,4.665-1.866c2.332,0.116-0.349-2.215-0.349-4.314s1.749-0.467,3.148-1.282c1.4-0.816,0.467-5.598,0.933-7.93
        c0.467-2.332,3.265-2.798,3.265-1.516s1.631,2.449,3.615,2.216c1.983-0.233,1.165-2.799,2.448-3.615s0.35-3.732-0.233-5.48
        c-0.583-1.749-3.149-0.117-5.131-0.233c-1.983-0.116-2.449-0.933-2.681-3.031c-0.233-2.099-0.467-1.4-1.167-2.099
        c-0.699-0.698-0.699-1.165-0.932-2.798c-0.233-1.632,0.116-4.897,0.932-4.081s1.167-1.167,1.516-3.031
        c0.349-1.865,0.816-3.381,1.749-4.081c0.933-0.7,0.467-3.381-0.117-5.13c-0.584-1.749-0.467-2.449-0.467-4.547
        s2.565-2.215,2.682-4.316c0.116-2.098-3.031,2.215-5.248,3.964c-2.215,1.749-3.382,0.349-4.897,1.4
        c-1.516,1.05-3.964,4.897-5.597,7.93c-1.632,3.031-2.332,2.215-4.082,2.682c-1.749,0.467-2.565,0.816-5.597,0.7
        c-3.033-0.116-3.266,0.233-4.782,1.4c-1.517,1.167-2.916,3.732-4.315,5.714c-1.4,1.982,1.282,1.983,1.282,3.732
        s1.516,1.283,2.215-0.116c0.699-1.4,1.165-0.816,1.165-0.117c0,0.699,1.167,1.632,2.449,1.632s0.932-0.816,2.215-2.915
        c1.282-2.098,0.234-2.798,0.583-5.364c0.349-2.565,1.282-0.349,4.547,0s0,2.1-0.7,3.732c-0.699,1.632,0.933,3.964,2.449,5.48
        s-0.349,2.798-0.7,2.681c-0.349-0.116,0-2.215-1.982-0.816c-1.983,1.4-1.633-0.233-1.051-0.699
        c0.584-0.467,0.583-2.799,0.467-3.732s-0.699-1.865-2.214-1.4c-1.516,0.467-0.816,2.098-2.682,3.614c-1.865,1.516,0,2.565,0,4.082
        c0,1.516-1.865,0.233-2.565-0.933c-0.698-1.167-0.698-1.05-2.448-0.933c-1.749,0.116-2.565-1.05-3.382-1.983
        c-0.816-0.933-1.865-3.381-1.282,0.933c0.584,4.314-0.116,3.149-1.631,8.863c-1.516,5.714-0.35,6.063,0.233,4.781
        s1.399,0,4.43,3.498s0.117,2.215,0.117,2.215s-1.749-1.167-1.749,0.7c0,1.866-1.051,5.014-1.283,5.831
        c-0.233,0.816,0.699,2.565,1.165,2.798c0.467,0.233,2.333,1.05,2.333,1.05c1.049,3.149,2.098,2.098,1.983,1.631
        c-0.117-0.467-1.167-1.631,1.515-1.282c2.682,0.35,1.051,1.749,0.467,3.382c-0.584,1.631,0.233,7.114,0.35,9.445
        c0.046,0.933-0.037,1.435-0.125,1.86C369.674,289.298,371.514,288.954,371.965,289.314z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_24_" x="359.89" y="248.063" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M455.572,278.382c0,0-0.962,1.487-0.962,2.098c0,0.612,0.262,1.05,0.482,2.186c0.219,1.138-0.394,0.874,0.482,1.749
        c0.874,0.874,1.487,0.175,1.924,1.224c0.438,1.05,0.612,1.575,2.1,0.961c1.487-0.612,1.137-1.575,1.487-2.187
        c0.349-0.612,0.699-1.836-0.787-2.449c-1.487-0.612-1.094-0.612-2.296-1.924C456.794,278.732,456.048,277.569,455.572,278.382z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M403.45,225.91c0,0-2.012-0.961-2.361,0c-0.35,0.961-1.575,1.575-0.787,2.361c0.787,0.787,1.487,0.438,2.274,0
        c0.787-0.436,1.749-1.008,2.011-1.16C404.848,226.958,403.973,225.559,403.45,225.91z" pointer-events="auto" class="landarea"></path>

        <ellipse id="_x3C_Path_x3E__7_" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" cx="389.344" cy="254.701" rx="33.744" ry="40.421" pointer-events="auto" opacity="0.001" class="clickarea landarea"></ellipse>
</g>
<g id="AREA_SPAIN" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Spain" data-wikipediaLink="https://en.wikipedia.org/wiki/Spain" data-infoText="<b>Spain</b> &amp;nbsp; Capital: Madrid" data-imageFile="SPAIN.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M287.717,610.331c-0.154,0.084-3.149,0-3.674,0.992c-0.525,0.992-0.991,0.991-2.274,1.631c-1.282,0.642-1.983,1.866-3.206,2.333
        c-1.225,0.467-1.925,1.983-0.933,2.449c0.992,0.466,1.166,0.7,1.924,1.4c0.758,0.7,0.642-0.29,1.925-1.224
        c1.282-0.934,1.924-1.051,1.575,0.583c-0.35,1.631-0.233,2.623,1.34,2.508c1.575-0.116,2.215-0.642,2.274,0.467
        c0.058,1.107,0,3.206,1.049,1.749c1.05-1.457,2.039-2.039,2.916-3.79c0.874-1.749,0.991-1.049,2.098-2.857
        c1.108-1.807,1.458-3.498,0-3.323c-1.457,0.175-1.282,1.631-2.623,0.583c-1.341-1.049-1.399-1.341-1.224-2.391
        C289.057,610.389,288.358,609.98,287.717,610.331z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M299.262,607.415c0,0-2.041,0.408-1.283,1.225c0.758,0.816,0.705,0.874,0.558,1.808c-0.15,0.932,0.551,1.34,1.25,1.281
        c0.698-0.059,1.983-1.108,2.391-0.349c0.408,0.757,0.233,1.458,1.575,1.108c1.341-0.35,1.341-0.35,2.157-0.467s0.758-1.225,0-1.516
        c-0.758-0.29-0.992-0.582-1.225-1.515c-0.232-0.934,0.525-1.867-0.932-1.808s-1.457,1.105-2.449,0.553
        S299.727,606.889,299.262,607.415z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M265.794,625.254c0,0-1.575-0.057-2.332,0.409c-0.757,0.467-1.341,0.467-1.983,1.631c-0.64,1.167-1.223,2.1,0,2.391
        c1.225,0.29,1.458,0.643,2.508,0c1.049-0.642,1.4-1.332,2.215-2.035C267.019,626.947,266.935,625.199,265.794,625.254z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M263.754,631.553c0,0-0.584,1.517-0.584,1.983c0,0.466-0.408,0.816,0.933,0.7c1.341-0.117,3.298-0.176,2.641-0.584
        c-0.659-0.408-1.533-0.327-1.999-0.746C264.28,632.485,264.397,631.144,263.754,631.553z" pointer-events="auto" class="landarea"></path>

        <path id="SPAIN" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M290.341,565.61c1.632-1.282,0.583-2.214,0-2.681c-0.365-0.291-0.684-0.947-1.127-1.882c-1.651,0.212-2.954,0.647-2.954,0.647
        s-4.198,2.228-8.396,1.876c-4.197-0.349-3.673-1.399-6.645-1.876c-2.972-0.478-3.324-1.445-5.947-3.195
        c-2.623-1.749-2.799-1.399-3.674-1.392s-5.422-1.581-7.346-2.106c-1.925-0.525-1.575,1.4-2.623,2.212
        c-1.05,0.813-2.099,0.761-3.149-0.107c-1.05-0.867-3.323-0.357-4.547,0c-1.224,0.356-3.324-0.183-5.073-1.581
        c-1.749-1.4-2.798-0.699-4.198-1.051c-1.399-0.349-4.022-0.874-5.422-3.256c-1.399-2.382-3.148-0.241-6.997-0.011
        c-3.848,0.231,0.524-3.379,0-4.778c-0.525-1.4-4.722-1.051-4.722-1.051l-0.296-1.594c-0.194,0.042-0.389,0.086-0.579,0.137
        c-2.682,0.7-2.449,0.235-4.781,0.933c-2.333,0.699-1.632-0.116-6.181-1.516c-4.547-1.4-3.265-0.933-5.014,0.349
        s-1.05,0-5.363-1.282c-4.314-1.282-2.799-2.215-6.063-1.983c-3.265,0.233-6.53,1.283-10.029,2.566
        c-3.498,1.282-7.463-1.631-10.728-2.449c-3.265-0.817-5.13,0-6.414-1.631s-2.215-1.165-4.547,0.233c-2.332,1.4-4.081,0.7-6.53,0.35
        s-1.05,0-4.547,1.165c-3.498,1.167-4.665,0.7-8.162-1.749c-3.498-2.448-3.964-2.565-5.714-3.032
        c-1.749-0.467-3.732,0.934-6.063,2.1c-2.332,1.165-0.699,3.732-0.933,6.182c-0.233,2.449-1.515,0.116-1.983-0.467
        c-0.466-0.583-1.165-0.116-2.798,1.051c-1.633,1.167-2.916,0.116-5.714,0.467c-2.799,0.35-5.947,2.332-6.297,5.13
        c-0.349,2.799,1.051,0.817,1.865,2.332c0.816,1.516,1.283,1.866,2.916,1.749c1.633-0.116-0.116,1.866-0.816,3.149
        c-0.7,1.283-0.234,2.916,0.933,3.498c1.167,0.583,0.467-2.215,1.749-3.732c1.282-1.517,2.332,0.817,1.399,2.565
        c-0.933,1.749-0.933,3.149-0.699,4.664c0.235,1.516,1.283-0.816,2.215-1.049c0.932-0.234,0.349,1.283-0.699,2.333
        c-1.05,1.049-1.517,2.565-2.682,5.363c-0.593,1.423-0.522,2.392-0.171,3.095c1.081-0.93,2.101-1.88,2.328-2.335
        c0.525-1.051,4.372-2.798,5.947-2.798s4.896-1.925,4.722,0c-0.175,1.924-4.547,1.767-2.623,3.332
        c1.924,1.566,3.498,2.965,5.772,1.215c2.274-1.748,3.149,0.35,5.248,0.7c2.099,0.35,3.848-2.799,4.722-2.799
        s0.525-0.349,2.972,0.175c2.449,0.525,3.324-0.155,3.849,0.709c0.524,0.865-1.4,3.84,1.4,4.189
        c2.798,0.349,5.422,1.399,5.422,2.623s-4.722,4.198-5.772,5.422s-2.098,1.748-3.323,2.972c-1.225,1.224-2.449,2.1-1.4,3.149
        c1.05,1.05,1.225,5.073,0.874,5.772c-0.349,0.7-0.525,0.874,0.175,2.274c0.699,1.4,1.925,0.874,0.525,2.623
        c-1.4,1.75-4.722,2.799-4.023,4.198s2.274,1.122,2.099,3.885c-0.175,2.762,1.399,4.336-1.575,5.21
        c-2.972,0.874-4.547-1.049-5.422,0c-0.874,1.051-2.449,1.575-0.175,3.149s1.4,1.749,2.449,3.848
        c1.05,2.098,1.575,4.022,2.449,4.198c0.874,0.175,1.749,0.476,1.4,1.638c-0.349,1.161-1.4,0.485-2.449,2.398
        c-1.05,1.914-2.449,3.007-2.449,4.208c0,1.202-1.4,2.427,0.35,4.525c1.749,2.1,1.573,3.849,3.149,4.023
        c1.575,0.175,2.972,1.924,1.749,2.623c-1.225,0.7-3.149-0.874-4.023,1.049c-0.874,1.924-1.399,1.575-2.449,4.198
        c-1.05,2.622-2.485,2.594-0.874,5.598c0.719,1.339,1.177,2.146,1.405,3.139c1.166-0.85,2.219,0.515,4.659,0.417
        c2.915-0.117,1.866-0.467,3.732,1.631c1.865,2.098,2.333,2.098,4.825,3.382c2.492,1.282,1.588,1.049,0.423,3.149
        c-1.165,2.098,0.349,1.4,1.282,3.964c0.933,2.565,0.467,2.215,1.516,2.916c1.051,0.7,3.033,5.014,7.347,7.463
        c4.314,2.448,1.516,0.349,3.498-1.866c1.983-2.215,2.682-3.498,3.031-4.781c0.35-1.282,4.198-2.566,7.812-2.333
        c3.614,0.234,1.749-0.933,2.798-3.149c1.05-2.216,0.816-0.583,3.964-0.349c3.149,0.232,1.983-0.467,4.198-1.282
        c2.214-0.816,1.983,0,3.848,0.583c1.866,0.584,2.332,1.516,3.382,0.7c1.05-0.817,2.565-0.7,5.597-0.35
        c3.031,0.35,3.732,1.167,4.547,0.584c0.816-0.583,2.332-2.215,4.431-2.681c2.098-0.468,3.031,2.681,3.849,2.448
        c0.816-0.235,2.915-2.448,3.848-5.714c0.933-3.265,2.449-4.782,4.081-6.997c1.631-2.215,6.647-1.983,9.095-2.1
        c2.449-0.116,3.265-0.349,2.916-1.049c-0.35-0.699,0.7-1.4,0.914-2.449s0.37-2.449,0.019-3.615
        c-0.349-1.167,4.082-8.162,5.248-10.145c1.165-1.983,3.265-1.749,6.296-3.615c3.031-1.866,1.631-1.633,0.932-2.682
        s-3.848-1.282-5.014-4.198c-1.165-2.916-1.165-3.498-2.215-5.714c-1.05-2.215-0.7-3.149,1.4-5.712
        c2.098-2.565,7.463-9.68,9.212-12.711s3.031-3.964,3.265-5.364c0.233-1.399,1.866-0.466,3.031-1.983
        c1.166-1.516,0.349-1.749-0.7-3.615c-1.05-1.865,4.547-3.614,6.181-4.312c1.632-0.7,6.647-1.749,10.728-2.333
        s3.964-1.516,6.181-4.198c2.215-2.682,4.896-2.682,8.279-5.248c3.382-2.565,3.265-1.631,4.314-3.731
        c1.05-2.1,0.116-3.382-0.584-4.198C288.708,567.36,288.708,566.894,290.341,565.61z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_26_" x="169.74" y="592.772" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_THEUKRAINE" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Ukraine" data-wikipediaLink="https://en.wikipedia.org/wiki/Ukraine" data-infoText="<b>Ukraine</b> &amp;nbsp; Capital: Kyiv (Alternative spelling: Kiev)" data-imageFile="UKRAINE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="UKRAINE" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M818.421,415.861c-1.4,1.749-3.673-1.399-4.722-3.323s-2.187-1.662-3.586-1.137c-1.4,0.525-1.749-1.05-2.187-3.935
        c-0.438-2.886-1.138-0.175-1.838,0.611c-0.7,0.787-3.674-0.525-4.547-0.611c-0.874-0.087-3.76-0.35-4.46-1.925
        s-3.149-4.46-4.022-0.961c-0.874,3.498-3.412-0.525-4.285-1.224c-0.874-0.7-4.548-1.924-4.634-5.248
        c-0.087-3.324-2.187-3.237-2.623-3.062c-0.438,0.175-1.312,2.011-3.586,1.487s-3.761-0.874-4.81,0.874
        c-1.05,1.749-2.798,2.711-3.586,0.421s-3.849,0.454-4.635-1.033c-0.787-1.487-4.986-2.099-6.997,0.262s-4.109-1.312-5.684-2.885
        c-1.575-1.575,2.361-3.848,0.961-8.133s-2.711-4.023-3.062-5.335c-0.349-1.313-1.837-3.324-2.798-2.973
        c-0.961,0.349-3.935,1.05-5.073,0c-1.138-1.049-3.587-0.525-5.685-0.943c-2.1-0.419-1.399-1.505-1.137-2.992
        c0.262-1.487,0-3.674-1.138-5.511c-1.137-1.836,1.051-1.312,3.849-2.274c2.797-0.961,1.575-3.236-2.361-5.422
        c-3.935-2.187-1.313-2.011-2.1-5.581s-2.187-1.678-3.673-3.69c-1.487-2.011-1.575-0.349-3.324,0.787
        c-1.749,1.137-2.885-0.874-5.073-1.122c-2.186-0.248-2.972,1.385-5.073,2.958c-2.098,1.575-3.76-1.312-5.335-2.711
        c-1.575-1.4-3.645,3.439-3.645,6.704c0,3.266-2.799,0.833-2.799,0.833l-3.029-0.601c0.356,1.062-0.405,2.425-5.367-0.698
        c-6.296-3.964-3.031,3.031-3.731,3.732c-0.699,0.7-3.733-2.332-5.131-1.167c-1.4,1.167-3.03,2.798-3.265,6.531
        c-0.233,3.731,0.749,12.477-3.241,9.678c-3.99-2.798-4.165-6.472-6.439-5.248c-2.274,1.225-2.274,1.749-4.022,0.699
        c-1.749-1.049-1.749,1.224-3.498,1.749c-1.749,0.525-0.874-6.296-3.848-5.947s-3.324,1.924-4.896,3.849
        c-1.575,1.924-2.973,1.924-3.149-0.35c-0.175-2.274-3.498-2.449-3.498-1.575s-1.627-0.349-2.3-1.924s-2.423,0.7-2.947,2.273
        c-0.524,1.575-1.923-1.575-3.497-1.749c-1.575-0.175-0.35,6.123-2.274,3.673c-1.924-2.449-4.022-6.122-4.722-5.073
        c-0.699,1.049-1.224-1.051-4.023-1.749c-2.798-0.699-2.972-1.225-6.296-1.575c-3.323-0.349-6.296-2.274-10.669-1.924
        c-4.372,0.349-5.073-0.175-9.969-0.175c-4.897,0-9.271-1.575-10.494,0.7c-1.225,2.274-1.501,4.372-3.9,5.771
        c-1.858,1.084-0.289,5.31-7.205,3.019c0.022,1.658,0.029,2.996,1.136,4.504c1.924,2.623,1.125,7.346,3.71,8.92
        c2.586,1.575,2.76,2.274,1.888,4.023c-0.875,1.749,0.524,3.149-0.875,5.073c-1.4,1.924-1.575,2.449-4.372,4.023
        c-2.798,1.575-6.471,4.896-10.494,9.795c-4.022,4.897-5.073,6.122-4.372,9.62s0,4.897,0.525,6.471
        c0.525,1.575,3.324,3.849,0.875,3.849c-1.685,0-2.789,0.083-4.281-0.549c-0.224,3.485-2.427,6.957-3.416,8.069
        c-1.4,1.575-1.4,3.323-1.4,5.422s1.749,1.4,4.723,3.848c2.972,2.449,1.399,2.799,4.022,4.815c2.623,2.017,2.623-2.892,4.372-2.017
        s2.798,3.848,4.547,2.017s7.522,0.432,10.619,1.482c3.098,1.049,3.724,0.349,5.998,0.349s3.498,3.673,5.947,4.722
        c2.449,1.05,2.098-1.749,3.323-3.324s3.149-0.349,4.023-1.049s4.198-0.7,6.821-1.05c2.623-0.35,4.547-1.4,4.723-2.974
        c0.175-1.575,3.149-2.099,4.547-3.848c0.476-0.594,0.911-0.594,1.416-0.272c0.653-1.312,1.449-2.627,1.908-2.352
        c0.874,0.525,3.848,0.976,5.947-0.036c2.1-1.012,4.372-3.112,6.122-1.712c1.75,1.399,6.297,0.407,7.522,3.002
        s1.924,2.769,2.974,3.469c1.051,0.699,4.547-2.623,5.598,0c1.049,2.623,0.699,4.372,2.449,4.723
        c1.749,0.349,3.673,0.175,4.023,2.274c0.349,2.098-2.098,5.771-1.225,7.696c0.874,1.925,2.449,2.274,3.499,4.897
        c1.05,2.623-0.175,4.023,2.448,5.597c2.623,1.576,3.673,2.974,4.722,5.073c1.051,2.099,0.874,3.498,2.041,5.48
        c1.167,1.984,3.265,3.382,0,3.031c-3.266-0.35-3.964-2.917-4.665-1.283c-0.698,1.634-2.682,1.631-3.498,0
        c-0.816-1.634-1.983-2.098-2.332-0.816c-0.35,1.282-1.749,1.051-2.799-0.349c-1.049-1.4-2.915-1.168-2.564,1.165
        c0.349,2.332,1.516,2.214,1.631,4.197c0.117,1.984-0.583,3.498-2.215,4.198c-1.631,0.699-2.448,0.583-2.098,2.098
        c0.35,1.515-2.565,4.431-3.148,4.782c-0.584,0.349,0.933,4.198-0.584,4.781c-0.509,0.195-1.563,0.935-2.847,1.86
        c1.658,1.015,3.619,1.848,5.412,2.336c3.849,1.051,7.434-2.798,8.483-3.498c1.051-0.699,5.073,2.536,5.947,4.46
        c0.164,0.362,0.364,0.61,0.578,0.773c0.419-1.89,1.101-2.381,1.199-3.368c0.117-1.165-0.7-1.05-3.964-4.547
        c-3.265-3.498-0.699-3.265-0.584-2.682c0.118,0.583,2.799,2.449,3.149,0.584c0.349-1.865,4.431-3.848,7.462-5.014
        c3.032-1.167,4.315-3.964,6.064-6.296c1.749-2.333,3.031-5.248,3.382-7.463c0.349-2.215,2.565-1.282,7.229-1.282
        c4.665,0,3.614-1.983,5.598-1.866c1.983,0.117,1.4,2.333,4.896,1.866c3.498-0.467,5.48,2.215,3.732,2.215
        c-1.75,0-3.149-0.117-3.149,1.167c0,1.282-2.566,0.584-2.681,3.031c-0.116,2.449,0.466,1.051,3.614,1.283
        c3.149,0.234,7.23,3.149,9.795,2.916s2.333-1.749,3.265-2.449c0.933-0.698,2.449,1.634,4.547,2.449
        c2.1,0.817,1.631-1.282,2.799-1.865c1.167-0.584,0.817,3.031,1.516,3.848c0.7,0.816,0,2.565-2.916,3.031
        c-2.916,0.467-2.916,1.634-5.714,2.098c-2.798,0.467-7.812,4.666-9.561,6.064c-1.749,1.399,2.332,3.031,3.031,1.749
        c0.7-1.281,4.081-0.699,5.48,1.167s4.315,2.565,6.647,3.732s1.983,2.916,1.165,3.964c-0.816,1.051-0.816,1.282-0.932,2.449
        s-0.233,3.148-1.983,3.732c-1.749,0.583,5.131,5.131,8.045,5.014c2.916-0.116,8.163-8.514,9.795-8.514
        c1.633,0,6.297-1.282,8.162-1.866c1.866-0.583,3.732-3.264,4.431-4.43c0.7-1.167,0.7-1.749,2.565-0.117
        c1.866,1.631,3.149,1.866,7.463,1.866c4.315,0,3.265-1.167,2.916-3.031c-0.35-1.865,2.215-3.732,1.865-5.48
        c-0.349-1.749-4.547-0.699-5.714,0.467c-1.167,1.165-1.634,0.349-3.732-0.583s-3.031,1.982-6.064,2.1
        c-3.031,0.116-2.915-2.916-4.664-4.897c-1.749-1.982-3.032-8.629-3.849-10.028c-0.815-1.4-1.167-3.031,0.349-3.265
        c1.517-0.235,3.265-1.983,4.198-3.265s0.467-0.816,1.749,0.467c1.283,1.282,5.712-3.265,7.696-5.248
        c1.984-1.983,2.565-1.633,3.849-1.05c1.283,0.583,2.916,0.699,3.148-0.7c0.234-1.4,3.732-1.282,4.897-0.467
        c1.167,0.817,3.031-0.117,3.031-1.516c0-1.4,2.799-1.05,3.964-2.1c1.167-1.05,1.865-0.466,3.265-0.116
        c1.399,0.35,3.614-3.381,3.848-3.848c0.234-0.467,4.897-0.583,6.414,0.584c0.917,0.705,1.45,0.215,2.011-0.436
        c0.085-1.227,0.067-2.396-0.175-2.799c-0.525-0.874,0.961-5.509,1.574-8.047c0.612-2.536,2.624-1.137,3.673-1.4
        c1.051-0.262,2.274-0.525,2.537-2.623c0.262-2.099,2.098-2.712,4.284-1.077c2.187,1.636,5.509-0.41,8.134-0.061
        c2.623,0.349,2.711-0.874,2.449-2.099c-0.262-1.224-0.262-2.536,0.175-3.673c0.436-1.138,2.711-1.313,2.187-2.535
        c-0.525-1.225-0.087-1.313,0.524-2.361c0.612-1.05,0.087-2.885-2.623-3.848c-2.711-0.961-2.012-3.76-3.149-5.335
        c-1.137-1.575,3.411-0.875,5.422-1.225c2.012-0.349,1.224-1.924-1.05-1.836c-2.274,0.087-3.324-0.525-4.022-2.623
        c-0.7-2.099,2.274-1.225,4.197-2.274c1.925-1.05,3.76-2.361,2.886-3.936s0.349-1.924,0.524-2.972
        C821.132,416.735,819.82,414.111,818.421,415.861z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_27_" x="663.698" y="399.1" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_BELORUSSIA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Belarus" data-wikipediaLink="https://en.wikipedia.org/wiki/Belarus" data-infoText="<b>Belarus</b> &amp;nbsp; Capital: Minsk" data-imageFile="BELARUS.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="BELARUS" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M590.551,367.673c2.399-1.4,2.674-3.498,3.9-5.771c1.224-2.274,5.597-0.699,10.494-0.699s5.598,0.524,9.969,0.175
        c4.372-0.35,7.347,1.575,10.669,1.924c3.324,0.349,3.5,0.874,6.296,1.575c2.798,0.698,3.324,2.798,4.023,1.749
        c0.699-1.051,2.798,2.623,4.722,5.073c1.924,2.449,0.699-3.849,2.274-3.673c1.575,0.175,2.973,3.324,3.497,1.749
        c0.525-1.575,2.274-3.848,2.947-2.273c0.673,1.575,2.3,2.798,2.3,1.924s3.324-0.699,3.498,1.575
        c0.175,2.274,1.575,2.274,3.149,0.35c1.574-1.924,1.924-3.498,4.896-3.849c2.974-0.349,2.1,6.471,3.848,5.947
        c1.749-0.524,1.749-2.798,3.498-1.749c1.749,1.05,1.749,0.525,4.022-0.699c2.274-1.224,2.449,2.449,6.439,5.248
        c3.99,2.798,3.008-5.947,3.241-9.678c0.235-3.732,1.865-5.364,3.265-6.531s4.431,1.866,5.131,1.167
        c0.699-0.699-2.567-7.696,3.731-3.732c6.297,3.964,5.83,0.698,4.897,0c-0.933-0.699-2.098-6.531-2.798-11.894
        c-0.699-5.364,0-4.198-3.732-7.463s0-4.897,1.631-6.297s2.799-2.565,4.198-0.467c1.4,2.099,6.53,2.332,6.53,2.332s0,0,0.699-2.798
        c0.7-2.798,1.634-0.933,5.131-2.333c3.498-1.399-0.235-3.498-1.865-5.363c-1.633-1.865-1.4-2.332-1.4-4.198
        c0-1.865-2.098-1.631-5.598-2.332c-3.498-0.7-3.732-1.167-3.732-2.682c0-1.517,1.4-2.449,0-4.547
        c-1.4-2.099-3.266-1.866-4.897-3.732s-0.234-3.031-2.798-4.665c-2.565-1.631-1.4-3.265-1.4-5.363s-3.732-3.031-5.363-4.051
        s2.333-5.277,3.265-6.209c0.933-0.933-0.233-3.732-1.866-7.296c-1.631-3.564,0.933-3.199,0.933-6.698s-1.633-1.631-4.431-2.455
        c-2.799-0.823-2.1-2.442-5.598-4.307c-3.498-1.866-4.431,1.631-7.93,4.307c-3.498,2.675-3.265-0.809-3.498-3.841
        c-0.234-3.031-0.467-3.031-4.431-3.265c-3.964-0.233-2.565,0.7-4.897-1.244c-2.333-1.944-1.4-2.021-3.498-0.622
        c-2.1,1.4-3.265,0-3.265,0l-2.019-4.037c-0.578,0.401-1.376,0.162-1.887-0.638c-1.051-1.645-3.149,5.492-5.772,7.066
        c-2.622,1.575-0.7,2.623-1.4,3.498c-0.7,0.874-3.849,1.4-6.472-0.175c-2.623-1.575-2.623,1.575-4.197,2.449
        c-0.435,0.241-0.817,0.467-1.224,0.619c-1.443,2.913-3.282,4.766-3.675,6.727c-0.699,3.498,2.098,2.449,4.547,2.616
        s-0.525,3.506-3.673,4.376c-3.148,0.87-4.022-0.17-4.022,1.579s-0.874,3.148-4.897,3.848c-4.022,0.7-2.1,0.349-2.183,3.673
        c-0.084,3.324-0.09,3.849-1.49,5.947c-1.399,2.098-1.399,2.798,0.176,5.248c1.575,2.449,2.274,4.722-1.049,4.722
        s-1.049-2.274-1.75-3.498c-0.698-1.224-1.575,0-2.972,0.874c-1.399,0.873-1.925,2.448-4.198,2.448
        c-2.274,0-1.924-0.349-2.274,1.225c-0.35,1.575-0.175,3.498-2.1,3.673c-1.924,0.175-1.924,1.924-5.648,1.05s-4.323,0.7-6.946,0.7
        c-1.37,0-2.356-0.484-2.988-1.538c-0.658,1.025-1.324,2.401-1.035,3.461c0.525,1.924-0.524,5.073,2.1,9.096
        c2.624,4.023,5.4,11.544,4.362,13.994c-1.038,2.449-0.339,5.422-4.711,5.771c-4.374,0.349-5.423,3.324-5.772,5.947
        c-0.35,2.623,4.061,3.149,5.354,5.772s-1.138,5.598-0.01,9.795c0.481,1.786,0.503,3.19,0.518,4.418
        C590.261,372.983,588.693,368.757,590.551,367.673z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_28_" x="621.422" y="315.927" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_RUSSIA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Russia" data-wikipediaLink="https://en.wikipedia.org/wiki/Russia" data-infoText="<b>Russia</b> &amp;nbsp; Capital: Moscow" data-imageFile="RUSSIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M954.762-171.047c0,0-4.547-4.198-6.296-2.274s-5.073,2.972-6.471,5.073c-1.4,2.1-3.498,3.849-4.023,8.921
        c-0.525,5.073-0.525,8.745,0,12.594c0.524,3.848,2.798,5.597,4.723,5.947c1.924,0.349,4.372-1.749,5.073-1.225
        c0.7,0.525,2.798-0.525,5.597-1.924c2.799-1.4,3.324,0.524,4.198-1.749c0.874-2.274,3.498-1.4,3.673-4.547s1.225-5.947,2.449-6.296
        s1.749,1.749,1.224,3.324c-0.525,1.575,1.575,2.798,2.098,0.7c0.525-2.098,1.575-5.772,0-7.696s-4.547-2.274-6.296-5.248
        C958.96-168.424,957.035-171.573,954.762-171.047z" pointer-events="auto" class="landarea"></path>

        <path id="RUSSIA" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M552.121,301.033c7.254-0.315,15.641,3.82,20.555,1.542c-0.948-0.086-1.941-0.323-2.889-1.018c-2.623-1.924,1.4-6.821,1.925-7.696
        c0.524-0.874,0.7-2.972-1.575-4.897c-2.274-1.924,0,0-0.35-2.101c-0.349-2.101-1.224-1.223-2.274-2.097
        c-1.049-0.874-4.547-0.175-6.645,0.7c-2.1,0.874-2.799-0.7-4.723-2.978c-1.924-2.279-3.498-1.044-4.952-2.516
        c0,0-0.001,0-0.001-0.001c-0.65,1.086-1.595,1.817-1.401,3.685c0.35,3.382-0.816,4.665-4.315,5.131
        c-3.497,0.467-7.229-0.7-12.01-1.516c-4.781-0.816-1.749,3.498-4.198,9.445c-0.761,1.848-1.511,2.806-2.351,3.323
        c3.692,0.291,4.918-0.058,10.164,0.992C542.326,302.082,544.076,301.383,552.121,301.033z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M965.141,375.779c1.983-1.283,4.403-1.283,4.403-1.283s3.324-495.693,0-495.605s-4.46,3.062-8.045,4.372
        c-3.587,1.312-3.935,4.022-6.209,5.422c-2.274,1.399-3.324,3.848-5.689,3.324c-2.364-0.524-5.506-3.673-6.293-3.848
        c-0.786-0.175-0.611,1.312,0.7,2.536c1.313,1.224,1.662,2.187,1.662,4.547c0,2.361,2.187,1.836,3.411,3.324
        c1.224,1.487,0,2.1-0.698,0.874c-0.7-1.224-2.537-0.961-4.372-1.137c-1.837-0.175-2.098,0.262-4.022,0.787
        c-1.925,0.525-3.061,1.749-4.285,1.662c-1.225-0.087-4.198,1.4-4.023,4.198s1.224,3.062,0,4.986s0.087,4.285-1.924,7.171
        s0.699,3.411,0.961,7.434c0.263,4.023-0.698,1.224-1.837,3.498c-1.138,2.274-1.138,1.05-2.274-0.262
        c-1.137-1.313-7.171,2.098-10.663,4.111c-3.492,2.012-2.017-0.087-2.28-2.187c-0.262-2.098-3.235,0.612-7.084,0.438
        s-2.623-2.187-4.81-4.198c-2.187-2.012-0.175-2.798-0.612-4.897c-0.438-2.098-1.049-3.586-5.509-5.073
        c-4.46-1.487-4.548-2.187-5.947-6.209c-1.4-4.023,1.137-4.111,3.06-5.423c1.925-1.312,2.973-0.874,2.973-3.586
        s0.787-3.324,2.974-4.81c2.187-1.487,6.645,1.312,8.133-0.349c1.487-1.662,2.012-0.874,6.123-0.349
        c4.109,0.525,2.709-0.612,2.885-3.586c0.175-2.972-1.137-2.623-2.098-5.684c-0.961-3.062-0.087-1.749-2.187-5.685
        c-2.1-3.935-2.623-4.372-5.073-6.734c-2.449-2.362-1.837-2.972-3.062-5.335c-1.225-2.362-2.449-2.623-8.134-3.324
        c-5.685-0.7-5.86-0.262-9.795,0.524c-3.935,0.787-4.634,0.525-9.095-1.05c-4.46-1.575-6.296-3.76-8.57-5.248
        c-2.274-1.487-4.198,2.536-2.012,3.061c2.187,0.525,13.731,15.829,13.118,17.928c-0.611,2.1-0.787,2.361-0.611,4.722
        c0.175,2.361,0.873,4.023,0.437,5.248c-0.437,1.225-1.837,1.575-1.487,3.324s2.1,2.011,0.612,5.16
        c-1.487,3.149-5.335,14.605-5.509,18.279c-0.176,3.673,2.885,2.449,4.109,1.487c1.224-0.961,4.809,4.198,6.384,8.396
        c1.575,4.198,1.313,2.098,1.313,4.897c0,2.798-1.137,3.236-1.137,5.073s-0.087,1.575-1.138,4.722
        c-1.05,3.148-3.41,5.947-3.673,8.745c-0.262,2.798,1.4,11.02,1.137,13.556c-0.262,2.536-1.486-2.449-1.924-6.034
        c-0.437-3.586-3.498-2.798-4.372-3.498s-3.324,1.749-4.81,3.848c-1.487,2.098-1.137-0.787-0.262-2.711s3.848-2.273,3.935-4.547
        s-1.575-1.4-3.235-1.749c-1.662-0.35-1.662-1.749-2.711-3.673s-2.1,0.611-4.198,1.137c-2.098,0.525-4.46,1.487-5.16,1.4
        c-0.699-0.087,0.087-1.662,0-2.711s-2.187-0.612-3.848-2.798c-1.662-2.187-2.274,0.787-5.16,2.536s-2.711,4.81-4.372,8.396
        c-1.662,3.586-8.483,7.084-10.756,6.997c-2.274-0.087-3.149,0.525-4.635,3.324s-3.411,4.81-6.647,7.784
        c-3.235,2.975-5.248,5.335-5.772,9.708c-0.524,4.372,4.198,10.232,7.609,13.555c3.412,3.324,1.836,5.772,2.623,8.57
        c0.788,2.798-0.698,1.749-1.924,2.885c-1.224,1.137-0.175,3.586-0.699,4.547s-2.798-0.612-3.848-2.536
        c-1.05-1.924-1.924-0.262-3.673,1.4c-1.748,1.662-6.471-1.313-9.27-3.587s-7.608-3.235-8.832-4.198
        c-1.225-0.961,0.087-0.961-5.335-1.836s-2.361-1.487-4.547-3.586c-2.187-2.1-1.575-1.487-2.448-3.324
        c-0.875-1.836-8.221-3.498-10.581-3.586c-2.361-0.087-0.874,2.798-0.787,6.209c0.088,3.411-2.798,2.186-3.498,1.836
        c-0.698-0.35-2.098,1.05-1.487,3.148c0.612,2.1,2.975,4.285,6.034,9.009c3.062,4.722,2.186,5.86,6.385,7.347
        c4.197,1.487,2.885-0.7,5.422-2.187c2.536-1.487,0.612,1.575,2.448,2.449c1.838,0.874,1.225,2.186,0.088,4.111
        c-1.137,1.924,1.224,1.487,2.098,4.986s-1.137,2.274-0.961,4.023c0.175,1.749-3.062,1.575-5.248,1.749
        c-2.187,0.175-2.449,1.224-2.536,3.061c-0.088,1.836-1.487-0.087-2.711-1.575c-1.225-1.487-0.961-0.961-4.285-1.749
        c-3.324-0.788-4.198-1.575-7.522-2.711s-2.623-3.936-4.111-7.784s-0.611-0.087-2.799-0.087c-2.186,0-1.05-1.836-3.935-4.986
        c-2.886-3.148-1.662,1.836-6.209,1.224c-4.547-0.611-3.498-3.76-5.422-6.471s2.098-2.011,1.138-3.324
        c-0.961-1.313-0.35-1.575-0.612-4.634c-0.262-3.062,1.4-2.187,0-4.547c-1.4-2.361-1.575-2.274-1.486-4.111
        c0.087-1.836-0.612-2.711-2.799-5.248c-2.186-2.536-0.349-2.798-2.098-4.897c-1.749-2.098-0.263-2.361,1.049-2.798
        c1.313-0.436,1.313-2.274,2.798-6.034c1.487-3.76-0.262-3.848-0.961-5.684c-0.7-1.836,1.924,1.662,2.623,1.836
        s0.612-1.4,0.612-2.885c0-1.487-0.262-2.798-1.924-3.586s-0.961-1.313-1.399-5.073c-0.436-3.76-1.838-2.187-3.236-4.023
        c-1.4-1.836-3.411-1.487-4.372-2.361s-4.636-1.4-6.559-2.449c-1.924-1.05-1.313-2.536-0.7-3.848
        c0.612-1.313,0.612-2.361,0.263-3.586c-0.35-1.224-2.623,0.262-4.372-0.087s-2.1-1.487-1.662-3.324s1.575,0,2.187-1.575
        s-2.187-0.961-4.023-2.098c-1.837-1.137-2.187-2.011-3.41-2.886c-1.225-0.874-1.05-0.612-1.313-2.274
        c-0.262-1.662-0.699-0.438-4.111-1.924c-3.411-1.487,0.087-1.749,0.612-4.198s-1.225-0.612-2.885-2.798
        c-1.662-2.187-1.487-0.438-3.412-0.961c-1.924-0.525-2.711-1.836-2.1-2.011c0.612-0.175,3.062,0.175,4.81-0.612
        c1.749-0.787,1.487-0.349,3.062,0.612s2.972,0.525,3.236,0.525c0.262,0,3.323,1.224,2.098,2.011s-0.438,1.924,0.349,5.16
        s4.722,3.849,7.696,7.347c2.973,3.498,1.225-0.787,1.838-3.061c0.611-2.274,2.273,1.312,4.022,3.848s2.449,0.175,3.148-1.924
        c0.7-2.1,1.487,0.961,2.098,3.148s2.798,0,3.498,0.524c0.7,0.525-0.874,1.313-1.312,3.498c-0.436,2.187,2.1,2.711,4.285,0.612
        c2.187-2.098,2.011-1.313,3.935-0.349c1.925,0.961,3.586,3.673,4.897,5.073c1.313,1.4,3.411,0.349,9.009,2.711
        c5.598,2.361,5.86,2.011,12.156,1.924s5.422,0.525,10.669,4.198s6.296,2.011,11.631,3.76c5.335,1.749,8.308-1.313,13.469-2.098
        c5.16-0.787,4.111-1.662,8.133-3.76c4.022-2.1,7.171-5.073,9.095-6.122c1.924-1.05,5.947-9.707,8.483-11.807
        c2.535-2.098,3.498-5.073,3.411-6.908c-0.087-1.836,1.837-7.522,1.486-10.145c-0.349-2.623-0.437-1.137-2.711-2.886
        c-2.273-1.749-0.262-3.586,0.262-5.947c0.525-2.361,0-1.137-2.361-3.324s-0.436-2.274,0.438-4.634
        c0.874-2.361-0.263-1.575-1.138-3.061c-0.874-1.487-2.011-0.438-2.973-2.011c-0.961-1.575-1.487-1.137-2.536-0.175
        c-1.049,0.961-0.874,1.224-2.274-0.262c-1.4-1.487-0.087-2.012-2.187-4.723c-2.1-2.711-2.186-1.399-3.498-3.061
        s-0.436-2.885-1.749-5.509c-1.313-2.624-1.749-3.236-1.749-0.612c0,2.623,0.612,3.848-3.062,2.711
        c-3.673-1.137-6.997-5.335-9.707-8.483c-2.71-3.148-3.237-2.187-4.547-1.4c-1.313,0.787-1.662-0.7-3.936-4.46
        c-2.273-3.76-6.471-6.734-9.795-10.669c-3.324-3.936-2.886-1.399-6.034-5.509c-3.149-4.111-11.631-5.509-15.218-8.658
        c-3.586-3.148-10.669-7.522-12.505-8.308c-1.837-0.787-1.313,1.575-1.749,2.798c-0.437,1.224-2.448-0.175-3.76-0.961
        c-1.312-0.787-2.623-2.098-4.111-1.575c-1.487,0.525-3.149-0.349-4.986-1.487c-1.836-1.137-3.148-0.438-5.16-0.787
        s-1.487-0.087-3.41,1.05c-1.924,1.137-0.874,3.498-2.1,4.81c-1.225,1.313-0.787-1.137-0.787-2.449s-0.438-0.874-1.575-2.623
        c-1.138-1.749,1.575-2.536,2.449-3.236s0-1.749-2.974-2.274c-2.975-0.525-1.924,1.749-3.848,2.098
        c-1.924,0.35,0.436-0.961,0.349-2.536s-2.711-0.438-3.498-1.137s-3.411-0.087-5.335-0.175c-1.923-0.087-1.313-1.487-1.837-2.536
        c-0.525-1.049-1.837-0.438-2.975-0.612c-1.137-0.175-1.662-1.224-0.874-1.662c0.787-0.438,0.349-1.925,0.263-2.886
        c-0.087-0.961,1.748,0.175,3.586,1.313c1.837,1.137,7.084,1.749,10.669,0.262c3.586-1.487-0.524-3.498-0.874-4.547
        c-0.35-1.05-0.7-1.749-3.411-2.187c-2.711-0.436-3.586,0.175-4.022-1.924c-0.438-2.098-3.149-1.575-4.897-3.411
        c-1.749-1.836-1.313-1.924-3.673-2.098c-2.361-0.175-0.7,2.798,2.098,5.248c2.799,2.449-0.262,1.924-1.4,1.224
        s-1.924-1.575-3.411-1.749c-1.486-0.175-0.612,4.372,1.225,6.558c1.837,2.187-0.438,1.137-2.623,0.874
        c-2.187-0.262-2.975,1.575-3.149,0.699c-0.175-0.874-0.175-3.062-2.535-4.285c-2.361-1.224-4.198-0.349-5.249-1.05
        c0.525,3.76,3.083,9.009,1.104,10.407c-1.979,1.4-3.378,2.798-6.526,0c-3.149-2.798-2.448-5.947-3.149-2.798
        c-0.7,3.148-2.1,8.745-3.498,9.095c-1.4,0.35-6.997,2.798-7.696,4.198c-0.7,1.4-5.248,10.844-6.647,10.844
        c-1.137,0-3.89,2.079-3.945-0.52c-1.644,1.044-3.674,2.516-4.8,4.019c-2.1,2.798,1.4,3.148,4.896,4.198
        c3.498,1.05-1.749,4.897-3.848,10.145c-2.1,5.248,2.798,3.848,3.149,11.544c0.349,7.696,3.497,4.897,8.395,7.696
        c4.896,2.798,3.848,7.347,4.251,9.445c0.404,2.098,6.244,4.198,7.293,5.947c1.051,1.749-7.347,14.343-10.494,19.24
        c-3.149,4.897-5.248,6.645-3.149,11.894c2.098,5.249,11.195,28.684,13.643,36.381c2.449,7.696-3.848,3.498-4.897,3.848
        c-1.049,0.349-1.049,4.547-2.395,8.045s-0.054,5.248,3.095,7.696c3.149,2.449-1.4,3.848-3.095,5.778
        c-1.696,1.931-0.404,6.115,5.894,7.864c6.296,1.749,2.798,3.498,0.7,8.396c-2.098,4.897,0.349,4.547,1.749,7.346
        c1.4,2.798,3.848,4.547,5.947,9.445c2.098,4.897-4.547,7.347-9.445,10.393c-4.897,3.047,3.848,7.448,10.145,9.546
        c6.296,2.098,7.347,8.745,10.685,12.278s-0.89,7.312-0.191,13.958c0.7,6.647-0.349,4.547-6.296,9.795s-5.248,5.947-10.494,13.643
        c-5.248,7.696-6.647,9.795-9.096,13.992c-2.448,4.197-8.745,9.095-13.294,13.992c-4.547,4.897-8.745,9.095-12.01,13.293
        c1.281,1.167,4.781,1.282,6.296-0.233c1.516-1.516,0-2.916,2.799-3.732c2.798-0.817,1.983-1.282,2.798-2.449
        c0.816-1.167,2.565,1.516,0.933,1.983c-1.634,0.467-1.516,3.381-2.566,3.148c-1.049-0.232,0.117,2.682,1.749,5.947
        c1.633,3.265,3.031,1.166,4.431,3.498s2.798,2.449,6.296,1.866c3.498-0.584,2.799-0.699,5.833-0.699
        c3.031,0,0.698,4.431,3.149,5.363c2.448,0.933,3.732,0.817,3.031,2.449c-0.699,1.633-1.049,1.866-1.517,1.983
        c-0.466,0.116,0.584,4.547-3.381-1.166c-3.964-5.714-2.332-1.399-5.363-2.215c-3.032-0.816-3.964,0.349-6.414,0.584
        c-2.448,0.233-0.698,2.915-2.798,4.547c-2.098,1.633-1.865-0.234-4.082-2.214c-2.215-1.983-1.631,1.749-1.865,3.498
        c-0.233,1.749-1.983,1.516-2.916,0.467c-0.932-1.05-1.051-2.449-3.499-0.35c-2.448,2.1,0.35,4.081,0.233,6.064
        c-0.046,0.782-0.261,1.377-0.583,1.855c6.147,3.62,0.837,5.575-0.874,6.716c-2.1,1.4-1.925,5.422-3.849,6.88
        s-1.4,3.265-2.972,6.414c-1.575,3.149-0.175,4.198,0.874,7.696s-1.049,2.972-0.525,7.521c0.525,4.547,1.575,5.248,4.199,9.796
        c2.622,4.547-1.575,1.224-3.849,2.208c-1.551,0.671-1.556,2.815-1.846,4.757c2.576,1.398,2.087,4.146,4.12,5.453
        c2.449,1.575,1.924,2.1,2.098,4.023c0.175,1.924-1.574,1.924-0.874,4.023c0.699,2.098-0.7,2.274-1.575,4.812
        c-0.874,2.538,1.049,1.309,1.749,1.135c0.698-0.175,2.098,1.399,1.749,2.442c-0.349,1.042,0,2.281,1.924,3.856
        c1.925,1.575,1.576,4.022,2.539,7.871c0.495,1.977,0.134,3.116-0.477,3.542l2.019,4.037c0,0,1.165,1.4,3.265,0
        c2.098-1.399,1.165-1.322,3.498,0.622c2.333,1.943,0.932,1.01,4.897,1.244c3.964,0.233,4.199,0.235,4.431,3.265
        c0.234,3.031,0,6.517,3.498,3.841c3.498-2.675,4.431-6.174,7.93-4.307c3.498,1.866,2.798,3.485,5.598,4.307
        c2.798,0.823,4.431-1.042,4.431,2.455s-2.565,3.133-0.933,6.698c1.631,3.564,2.799,6.363,1.866,7.296
        c-0.933,0.932-4.897,5.19-3.265,6.209c1.631,1.019,5.363,1.952,5.363,4.051s-1.165,3.732,1.4,5.363
        c2.565,1.633,1.167,2.799,2.798,4.665c1.632,1.865,3.498,1.631,4.897,3.732c1.4,2.099,0,3.031,0,4.547s0.234,1.983,3.732,2.682
        s5.598,0.467,5.598,2.332c0,1.866-0.233,2.333,1.4,4.198c1.631,1.866,5.363,3.964,1.865,5.363c-3.498,1.4-4.431-0.466-5.131,2.333
        c-0.7,2.799-0.7,2.798-0.7,2.798s-5.13-0.233-6.53-2.332s-2.566-0.933-4.198,0.467c-1.631,1.399-5.363,3.031-1.631,6.297
        c3.732,3.265,3.031,2.098,3.732,7.463c0.7,5.363,1.865,11.195,2.798,11.894c0.197,0.148,0.374,0.413,0.469,0.698l3.029,0.601
        c0,0,2.799,2.432,2.799-0.833s2.07-8.104,3.645-6.704s3.236,4.285,5.335,2.711c2.1-1.575,2.885-3.206,5.073-2.958
        s3.324,2.259,5.073,1.122s1.838-2.798,3.324-0.787c1.487,2.011,2.886,0.12,3.673,3.69c0.787,3.569-1.835,3.393,2.1,5.581
        c3.936,2.187,5.159,4.46,2.361,5.422c-2.798,0.961-4.986,0.436-3.849,2.274c1.138,1.836,1.4,4.022,1.138,5.511
        c-0.262,1.486-0.961,2.573,1.137,2.992c2.098,0.418,4.547-0.105,5.685,0.943c1.137,1.049,4.109,0.349,5.073,0
        c0.961-0.349,2.449,1.662,2.798,2.972c0.35,1.313,1.662,1.05,3.062,5.335s-2.536,6.558-0.961,8.133
        c1.575,1.575,3.673,5.248,5.684,2.885c2.012-2.361,6.21-1.749,6.997-0.262s3.848-1.257,4.635,1.033s2.536,1.329,3.586-0.421
        c1.049-1.749,2.536-1.4,4.81-0.874c2.274,0.525,3.148-1.313,3.586-1.487c0.437-0.175,2.535-0.262,2.623,3.062
        c0.087,3.323,3.76,4.547,4.634,5.248c0.874,0.699,3.411,4.722,4.285,1.224c0.874-3.498,3.324-0.611,4.022,0.961
        c0.7,1.575,3.586,1.838,4.46,1.925c0.874,0.087,3.848,1.4,4.547,0.611c0.7-0.787,1.4-3.498,1.838-0.611
        c0.436,2.885,0.787,4.46,2.187,3.935s2.536-0.787,3.586,1.137c1.05,1.924,3.323,5.073,4.722,3.323
        c1.4-1.749,2.711,0.874,2.536,1.925c-0.175,1.05-1.4,1.399-0.524,2.973c0.874,1.575-0.961,2.886-2.886,3.936
        c-1.924,1.05-4.896,0.175-4.197,2.274c0.698,2.098,1.749,2.711,4.022,2.623c2.273-0.087,3.06,1.487,1.049,1.836
        s-6.559-0.349-5.422,1.225c1.138,1.575,0.438,4.371,3.149,5.335c2.711,0.961,3.235,2.799,2.623,3.848
        c-0.611,1.049-1.049,1.138-0.524,2.361c0.524,1.224-1.749,1.399-2.187,2.535c-0.437,1.137-0.437,2.449-0.175,3.673
        c0.262,1.225,0.175,2.449-2.449,2.099c-2.624-0.35-5.947,1.695-8.134,0.061c-2.187-1.635-4.022-1.023-4.284,1.077
        c-0.263,2.098-1.487,2.361-2.537,2.623c-1.049,0.262-3.062-1.138-3.673,1.4c-0.611,2.536-2.098,7.171-1.574,8.047
        c0.241,0.404,0.258,1.572,0.175,2.799c0.366-0.426,0.746-0.919,1.254-1.196c1.283-0.699,2.798-0.349,4.781-0.116
        c1.983,0.235,3.266-1.167,4.548-3.031c1.282-1.866,4.081-0.35,4.897,0.117s0.7,5.364-2.1,5.015
        c-2.798-0.35-4.081,2.449-6.063,3.498c-1.983,1.05-2.1,0.932-4.781,0.932s1.983,1.631,3.266,3.381
        c1.283,1.749-1.049,0.35-3.964-0.699c-2.916-1.05-2.215,0.116-4.547-0.116c-2.332-0.233-2.799,1.516-2.916,4.082
        c-0.116,2.566,1.865,1.982,3.149,1.4c1.281-0.583,2.215,2.566,3.848,3.381c1.631,0.816,3.849,3.498,3.964,4.432
        c0.117,0.933-3.615,0-4.781-1.051c-1.165-1.049-2.448,0.583-3.848,2.332s-0.349,3.615-3.732,4.665
        c-3.382,1.05,0.234,6.53,0.7,9.095c0.467,2.566-3.614-0.117-5.597,0.466s-1.049,0.117-7.696-3.264
        c-6.645-3.382-0.583,1.631,1.167,3.381c1.749,1.749-1.631,0.816-2.682,2.1c-1.049,1.282,1.283,2.332,3.149,3.031
        c1.865,0.699,5.48-4.897,4.547,1.633c-0.933,6.531,4.664,6.88,6.879,6.53s3.615-0.234,5.598,5.014
        c1.983,5.248,4.781,1.167,6.88,1.167c2.098,0,14.109,13.294,16.675,16.325c1.841,2.175,2.245,1.836,3.443,2.716l0.001-0.002
        c0,0,1.451-4.812,3.551-3.415c2.098,1.4,4.665,1.4,6.763,1.865c2.1,0.467,4.665-0.467,6.997,2.566
        c2.332,3.031,4.43,2.1,6.997,2.332c2.566,0.233,7.229-3.031,11.195,0c3.964,3.031,9.329,1.167,9.796,3.499
        c0.467,2.331,4.43,3.732,7.229,4.43c2.798,0.699-0.466,3.732,2.567,4.198c3.031,0.467,7.696-2.332,9.561-2.332
        s3.962,4.536,5.13,2.383c1.167-2.152,2.565-4.018,4.431-1.918s5.831,2.565,7.229,3.498c1.4,0.933-2.565,1.4,0,4.897
        c2.565,3.498,8.162,6.063,10.261,6.763c2.098,0.7,2.098,0.234,3.964,1.634c1.865,1.399,3.498,1.4,4.897,3.732
        c1.4,2.332,1.866,2.098,3.265,4.431c1.4,2.332,2.098,3.031,4.897,3.498c2.798,0.467,3.732-0.233,4.896-1.167
        c1.165-0.932,0.699-0.699,3.498-2.798c1.819-1.364,3.442-5.78,5.507-8.706c-0.958-1.271-1.072-0.444-2.242-1.322
        c-1.4-1.049-8.978-11.427-9.212-12.826c-0.234-1.4,0-3.615-1.049-5.248c-1.049-1.631-2.215-1.983-1.982-6.182
        c0.233-4.197,0.466-2.332-0.467-4.081c-0.932-1.75-0.932-2.799-0.116-5.364c0.815-2.566-2.215-7.463-4.782-11.195
        c-2.565-3.732-2.098-1.865-5.479-3.265c-3.382-1.4-1.283-2.916-0.933-6.181c0.349-3.265,4.896-3.614,6.763-5.831
        c1.866-2.215,3.149-7.93,4.547-10.028c1.4-2.098,0.233-2.681,0.933-5.014c0.7-2.332,1.282,0.467,2.799,1.865
        c1.516,1.4,1.166-0.583,3.498-2.1c2.332-1.516,3.964-1.749,4.897-1.749s2.332-0.584,2.332-2.215s1.749-2.332,7.346-3.848
        c5.598-1.515,1.866-3.964-2.215-6.645c-4.081-2.682-3.964-1.866-5.131-6.182c-1.167-4.315,2.682,0.467,6.296,0.934
        c3.614,0.467,1.283-3.848-1.516-6.413c-2.799-2.567-4.665-6.53-6.997-10.378s-4.665-5.248-6.414-6.647
        c-1.749-1.4-5.13,2.566-8.045,2.215c-2.916-0.349-1.983-5.248-3.265-10.494c-1.282-5.248-4.315-1.983-8.047-4.082
        c-3.732-2.098,1.865-3.614,1.516-8.279s2.1-4.665,4.782-8.514c2.682-3.848-0.466-2.798-2.215-4.43
        c-1.749-1.633,0.467-6.647,0.817-10.263c0.349-3.615,3.963-3.498,6.063-5.248s0.699-5.014,1.983-8.162
        c1.282-3.148,3.848,0.584,7.228,5.364c3.381,4.781,5.248,7.346,9.911,7.114c4.665-0.233,4.198-3.498,3.265-6.53
        c-0.933-3.031-1.865-4.547-2.565-7.813c-0.7-3.265,0-2.681,1.515-3.848c1.516-1.166,1.866,1.049,3.265,0.7
        c1.4-0.35,0.933-1.631,3.498-3.964c2.565-2.332,2.565-0.35,3.031-2.099c0.467-1.749-1.049-1.983-1.516-4.198
        c-0.466-2.215,2.916-1.167,4.664-0.7c1.75,0.467,1.634-0.467,1.634-1.866C960.476,376.711,963.158,377.06,965.141,375.779z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_29_" x="732.328" y="164.787" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>

        <rect id="UNANSWERED_2_" x="754.309" y="174.779" opacity="0" fill="#EB35FF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="24.691" height="20.581" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_FRANCE" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="France" data-wikipediaLink="https://en.wikipedia.org/wiki/France" data-infoText="<b>France</b> &amp;nbsp; Capital: Paris" data-imageFile="FRANCE.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M379.749,551.241c0,0-2.099,0.175-2.099,1.312c0,1.138,0.525,2.011,0,2.536c-0.524,0.525,0,1.4,0,1.924s-0.087,0.874-1.4,0
        c-1.312-0.874-2.274-1.4-2.711,0.175c-0.438,1.575-0.612,1.661-2.011,1.749c-1.4,0.087-1.663,0.438-1.925,1.4
        c-0.262,0.961-0.438-0.175-1.575,1.4c-1.137,1.575-1.312,1.049-1.05,2.187c0.263,1.137,1.838,0.611,1.138,1.575
        c-0.7,0.961-2.274,1.313-1.662,2.186c0.612,0.875,1.838,0.088,1.749,1.4c-0.087,1.312-1.313,2.187-0.787,2.711
        c0.525,0.525,2.1-0.175,2.1,0.787c0,0.963-0.787,1.663-0.7,2.231c0.087,0.569-0.35,1.576,0.611,1.53
        c0.961-0.044,1.925,0,1.313,0.699c-0.612,0.7-1.836,0.306-0.787,1.618c1.051,1.312,1.662,1.093,2.361,2.23
        c0.699,1.137,1.225,2.318,2.144,2.669c0.918,0.349,1.269-0.044,1.575-1.356c0.307-1.313,0.131-2.361,1.138-3.411
        c1.006-1.049,1.4-0.787,1.18-3.017c-0.219-2.231-0.874-3.15,0.306-4.023c1.18-0.874,2.098-1.05,2.098-2.231s0.831-5.116,0.35-6.429
        c-0.482-1.312-0.044-3.936-0.831-4.285s-1.05-0.262-0.7-1.313c0.35-1.049,0.35-1.224,0.612-2.406
        c0.262-1.18,0.044-1.53-0.133-2.361C379.879,551.896,380.727,551.033,379.749,551.241z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M357.798,426.949c-1.051-0.367-5.772-1.03-6.472-3.305c-0.698-2.274-5.946,0.525-6.645,0c-0.699-0.525-2.098,0.525-4.023-0.874
        c-1.924-1.399-4.547-3.848-5.422-5.422c-0.874-1.575-4.198,0.699-4.547,0c-0.349-0.7-2.623-1.749-5.422-1.4
        c-2.798,0.349-3.148,0.7-4.198-2.449c-1.049-3.148-2.449-3.324-5.947-4.022c-3.498-0.7-1.575-5.248-1.575-7.347
        s-3.324,2.1-3.848,3.69c-0.525,1.591-3.849,0.333-5.598,0s-1.4-4.563-1.4-5.788c0-1.224-0.874-1.749-2.274-1.924
        c-1.399-0.176-4.197-2.1-4.197-4.023s-3.324-0.874-5.422-1.924c-2.098-1.049-1.749-4.372-2.623-5.073
        c-0.874-0.7-4.003,1.575-5.799,0.874c-1.795-0.699-3.781-7.171-3.781-7.171v-0.655c-0.422,0.571-2.832,0.771-6.142,0.771
        c-3.615,0-4.761,3.498-5.929,6.879c-1.166,3.381-0.105,5.248-0.456,7.347c-0.351,2.099,2.746,3.963,1.813,4.315
        c-0.933,0.349-2.3-0.584-2.3,0.7c0,1.282-2.9,3.148-5.582,5.131c-2.681,1.983-7.57,3.381-10.721,4.43
        c-3.148,1.05-3.145,2.916-4.194,4.431c-1.05,1.515,1.985,1.516,3.384,1.749c1.4,0.234,3.033,1.283,0.817,1.283
        s-2.214,1.165-4.081,1.865c-1.865,0.699-3.381,0.934-5.014,1.632c-1.632,0.7-0.933-0.816-3.265-1.166
        c-2.333-0.35-3.615-0.467-6.53-1.167c-2.916-0.7-2.682,0.699-4.198-0.466c-1.516-1.165-0.35-1.749-1.4-3.266
        c-1.05-1.515,1.516-2.565,1.516-3.731s-1.983-0.7-4.431,0.816c-2.449,1.516-3.382-0.35-4.897-0.466
        c-1.516-0.116-0.933,2.681,0.35,6.413c1.282,3.732,2.098,1.283,2.332,4.316c0.233,3.031,0.233,2.449,1.983,3.732
        c1.749,1.281-0.234,2.215-1.167,4.782c-0.932,2.565,3.498,3.031,2.333,3.963c-1.167,0.932-2.333,0.584-4.431,0.233
        c-2.098-0.35-0.7-1.632-1.983-1.749c-1.283-0.116-2.916,2.449-4.781,1.631c-1.866-0.816-2.449-0.583-3.732,0.117
        c-1.283,0.7-2.682,1.865-3.849,1.633c-1.165-0.233-2.332-3.732-3.148-5.248c-0.817-1.517-4.316-0.816-7.347-0.699
        c-3.033,0.116,0,3.266-1.282,3.733s-1.4-1.4-2.682-1.167c-1.282,0.233-1.632,0.932-3.498,0.584c-1.865-0.35-2.332,0-6.647,0
        c-4.314,0-2.565,2.215-5.364,4.665c-2.798,2.449,2.214,3.265,3.498,2.332c1.282-0.933,3.265-1.982,3.265-1.399
        s0.234,1.049,1.749,2.332c1.516,1.283-0.583,1.049-2.332,1.866c-1.749,0.815,1.516,1.515,1.749,2.798
        c0.233,1.282-2.449,0.235-4.781,0.467c-2.333,0.232-1.167,1.749,0.698,1.749c1.866,0,2.682,2.566,4.198,4.781
        c1.517,2.215,1.05-0.349,1.866-1.631s2.565-1.282,3.381,0.817c0.817,2.099,0.7,1.165,3.149,0.583
        c2.449-0.583,0.7,1.517,2.215,1.517c1.516,0,2.098-0.117,3.265,0.932c1.165,1.049,1.516,1.517,3.732,2.682
        c2.214,1.165,2.1-0.816,3.031-0.933c0.932-0.117,2.565,1.167,3.381,1.167s2.215,0.349,2.798,0.932
        c0.584,0.583-1.399,1.983-1.282,3.615c0.116,1.631,2.565,0.699,3.732,0c1.167-0.7,0.584,1.982,0.349,3.731s2.098,1.516,2.332,3.498
        c0.233,1.983-1.749,0.235-2.567,0.933c-0.816,0.7,0.467,2.449,2.332,3.732c1.866,1.283,2.567,3.381,5.248,6.53
        c2.682,3.149,4.198,2.332,6.647,2.681s3.615,1.049,2.215,1.516c-1.399,0.468-1.282,2.332-0.467,3.614
        c0.816,1.283,0.117,2.683-0.583,4.666c-0.7,1.983-1.399,0.349-1.983,1.749c-0.584,1.399,2.565,1.983,5.014,3.964
        c2.449,1.984,2.567,2.332,3.964,6.297c1.4,3.964,0.7,4.781,0,4.312c-0.7-0.467-1.632-2.916-2.215-5.597
        c-0.583-2.682-1.983-2.215-3.964-3.382c-1.983-1.167-1.05,3.732-1.05,5.248s-0.35,3.615-1.282,6.53
        c-0.932,2.916,1.051,2.1,1.866,2.449c0.816,0.35,0.584,3.032-0.467,2.682c-1.05-0.35-1.516,2.214-1.282,4.781
        c0.233,2.566-3.615,13.993-3.382,17.374c0.218,3.139-2.381,3.164-4.902,3.712l0.296,1.594c0,0,4.198-0.349,4.722,1.051
        c0.524,1.399-3.848,5.01,0,4.778c3.848-0.231,5.598-2.37,6.997,0.011c1.4,2.381,4.023,2.907,5.422,3.256
        c1.4,0.35,2.449-0.349,4.198,1.051c1.749,1.399,3.849,1.937,5.073,1.581c1.225-0.357,3.498-0.867,4.547,0
        c1.05,0.867,2.099,0.919,3.149,0.107c1.05-0.814,0.7-2.739,2.623-2.212c1.925,0.525,6.472,2.112,7.346,2.106
        c0.874-0.007,1.051-0.357,3.674,1.392c2.622,1.749,2.972,2.717,5.947,3.195c2.972,0.48,2.449,1.527,6.645,1.876
        c4.198,0.35,8.396-1.876,8.396-1.876s1.305-0.435,2.954-0.647c-0.266-0.56-0.578-1.223-0.973-1.965
        c-1.05-1.982-1.631-6.063,0-9.212c1.632-3.148,2.215-3.031,3.149-5.13c0.933-2.1,6.88-1.866,10.029-3.848
        c3.148-1.983,0.932,1.282,3.148,0.7c2.215-0.582,4.081-0.117,5.014,1.282c0.933,1.4,1.631,1.167,3.463,1.167
        s0.618-0.933,0.501-1.749c-0.117-0.816-0.233-1.749,1.516-2.332s2.916,0.933,1.165,2.215c-1.749,1.281,0,2.566,1.4,1.167
        c1.399-1.4,2.449-0.584,2.565,1.049c0.116,1.631-0.116,2.098,1.05,1.749c1.167-0.35,2.215,0.698,3.964,2.566
        c1.749,1.866,2.099,0.815,2.797,0.349c0.7-0.467,3.031,2.214,4.665,1.982c1.631-0.233,1.516-1.631,3.031-2.215
        c1.515-0.584,2.681-1.05,2.681-2.449s1.632-3.148,2.682-4.081c1.049-0.932,3.498-0.816,4.198-2.916s3.848-3.381,5.131-3.732
        c0.026-0.007,0.062-0.015,0.088-0.023c0.813-1.926,1.962-3.77,2.243-4.87c0.699-2.735,0.524-2.336-1.575-1.462
        s-6.122-2.273-9.076-3.673c-2.954-1.4,2.08-5.598,3.239-5.772c1.159-0.175-0.635-5.248-1.683-4.547
        c-1.05,0.7-2.975,0.525-4.723-3.148c-1.749-3.674,2.099-2.449,3.498-3.498c1.4-1.05,2.1-2.449,2.74-3.402
        c0.642-0.952-0.816-2.72-0.116-3.945c0.699-1.224-1.925-2.972-3.324-4.197s0.525-3.324,1.051-3.171
        c0.264,0.077,0.839-0.541,1.345-1.172c-0.053-0.21-0.09-0.452-0.12-0.73c-0.175-1.575-0.525-2.798-2.274-3.765
        s-0.874-3.581-0.874-5.681s-3.674-1.575-4.897-1.575c-1.225,0-2.099,2.623-1.575,3.673c0.525,1.049-1.924,3.848-2.799,3.581
        c-0.874-0.268-2.798-0.957-1.749-1.612c1.049-0.654,1.224-3.718,1.049-4.767c-0.175-1.051,1.575-4.198,2.099-4.897
        c0.525-0.699,2.623-1.4,3.149-3.149c0.525-1.748-1.224-2.448,0-2.798c1.225-0.349,2.449-0.349,2.623-1.749
        c0.175-1.4,4.722-2.972,5.597-3.154c0.874-0.179,0-2.27-1.925-3.145c-1.924-0.874,2.1-3.673,2.799-4.022
        c0.7-0.35,1.399,0.698,2.798,1.749c1.399,1.049,2.274-1.05,2.623-2.448c0.091-0.363,0.229-0.701,0.396-0.983
        c-1.188-4.395,1.257-6.72,1.703-8.72c0.524-2.356,2.1-4.464,2.1-7.44c0-2.973,0.349-4.198,2.973-8.045
        C360.421,429.241,358.846,427.317,357.798,426.949z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_30_" x="249.239" y="456.756" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_FINLAND" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Finland" data-wikipediaLink="https://en.wikipedia.org/wiki/Finland" data-infoText="<b>Finland</b> &amp;nbsp; Capital: Helsinki" data-imageFile="FINLAND.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M529.151,140.35c0,0-1.809,0.757-0.758,2.041c1.049,1.282,2.1,1.983,1.283,2.098c-0.817,0.116-3.033-1.983-3.382-1.167
        c-0.35,0.816-1.749,1.106-1.865,2.565c-0.117,1.458,0.35,2.508,1.108,2.391c0.757-0.116,0.874-0.059,1.05,0.816
        c0.175,0.874,1.516,1.69,2.215,1.633c0.699-0.059,3.09-0.291,2.975-1.458c-0.117-1.167-1.157-2.74,0.003-2.623
        c1.162,0.116,1.104,1.108,1.571,0.175c0.466-0.933-0.758-2.1,0.583-2.157c1.341-0.059,3.089-0.583,1.808-1.224
        c-1.282-0.642-2.916-0.524-3.555-1.631c-0.643-1.108-1.341-1.4-1.634-1.457C530.257,140.292,530.142,140.117,529.151,140.35z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M600.572-7.216c0,0-1.691-0.467-2.566,0.525c-0.874,0.992-0.874,2.798,0,3.673s1.049,1.458,1.69,0.467
        c0.643-0.992,0.408-2.916,1.282-2.682c0.874,0.234,2.857,0.757,2.449,0.116C603.02-5.758,600.688-6.166,600.572-7.216z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M550.14,49.6c0,0-0.7,1.428-1.106,1.603c-0.409,0.175-0.643,0.175-1.051,0.67c-0.408,0.497-1.457,0.349-1.254,1.224
        c0.204,0.874,0.701,1.224,0.758,1.924c0.059,0.699-0.146,1.254,0.525,1.721s0.991,1.721,1.633,0.961
        c0.642-0.758,0.233-1.749,1.078-1.808c0.846-0.059,1.4,0.467,1.458-0.349c0.058-0.816-0.118-1.952-0.612-2.07
        c-0.495-0.117-1.108,0.495-1.049-0.291c0.059-0.787,0.321-1.108,0.7-1.631C551.598,51.029,550.904,48.319,550.14,49.6z" pointer-events="auto" class="landarea"></path>

        <path id="FINLAND" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M668.961,109.332c2.449-4.198,3.849-6.296,9.096-13.992c5.248-7.696,4.547-8.396,10.494-13.643
        c5.947-5.248,6.997-3.149,6.296-9.795c-0.698-6.645,3.529-10.426,0.19-13.958s-4.388-10.179-10.685-12.278
        c-6.297-2.098-15.043-6.499-10.145-9.546c4.897-3.046,11.544-5.497,9.445-10.393c-2.1-4.897-4.547-6.647-5.947-9.445
        s-3.848-2.449-1.749-7.346c2.1-4.897,5.598-6.647-0.7-8.396c-6.297-1.749-7.59-5.933-5.894-7.864s6.244-3.331,3.095-5.778
        c-3.149-2.449-4.442-4.198-3.095-7.696c1.346-3.498,1.346-7.696,2.395-8.045c1.051-0.349,7.347,3.849,4.897-3.848
        c-2.448-7.696-11.544-31.134-13.643-36.381c-2.099-5.248,0-6.997,3.149-11.894c3.148-4.897,11.544-17.491,10.494-19.24
        s-6.889-3.848-7.293-5.947c-0.404-2.099,0.646-6.647-4.251-9.445s-8.045,0-8.395-7.696c-0.35-7.696-5.249-6.296-3.149-11.544
        c2.098-5.248,7.347-9.095,3.848-10.145c-3.498-1.05-6.997-1.4-4.896-4.198c1.128-1.503,3.158-2.974,4.8-4.019
        c-0.013-0.6,0.118-1.448,0.446-2.628c1.749-6.296,6.997-10.757,6.297-15.174s-1.05-9.663-8.396-12.113
        c-7.346-2.449-13.642-15.742-16.441-10.843c-2.797,4.897,1.4,8.745-6.997,6.645c-8.396-2.098-9.795-3.848-10.494,2.449
        c-0.7,6.296-4.897,3.849-4.897,8.396s-2.799,6.296-2.448,13.293c0.349,6.997,0.349,10.844-2.799,14.692s-8.87,3.149-8.109,6.647
        c0.762,3.498,0.803,8.396-3.066,5.598c-3.868-2.798-8.765-9.095-11.564-6.997c-2.798,2.098-1.749,4.198-5.946,4.198
        c-4.199,0-3.15,2.449-7.347,0.698c-4.197-1.749-5.947,2.449-9.795-3.498c-3.848-5.947-4.547-14.343-9.096-15.392
        c-4.547-1.05-11.776-9.795-11.309-3.848c0.466,5.947,5.088,8.396-0.022,7.346c-3.413-0.701-3.953-1.087-4.81-0.955
        c0.628,1.272,1.359,2.624,2.121,3.753c2.127,3.149-1.713,0.349,4.054,5.947c5.769,5.598,9.617,5.248,10.666,9.796
        c1.049,4.547,0.35,3.498,4.198,3.848c3.849,0.349,11.894,0.349,13.642,5.248c1.75,4.897,2.1,5.947,6.297,8.745
        c4.198,2.798,2.798,6.997,1.75,8.045c-1.051,1.05-2.799,6.997-2.1,8.745c0.698,1.749-1.75,3.148,0.349,7.346
        c2.1,4.198,5.597,0,4.547,4.198c-1.049,4.198-4.198,6.996-2.449,10.145s2.484,5.248,2.641,7.347c0.158,2.098-1.908,3.849,0,6.647
        s0.56,4.547,0.009,8.396c-0.552,3.849-1.342,6.647,0.977,11.894c2.32,5.248,5.015,7.946,5.243,13.069
        c0.624-0.211,0.905-0.723,1.625-1.641c1.283-1.633,3.031,3.149,3.964,6.531c0.932,3.381,0.933-0.35,3.498-0.584
        s1.516,3.848,4.431,5.248c2.915,1.4,1.399,3.382,1.165,7.463c-0.235,4.081,0.467,4.314,1.516,6.063
        c1.051,1.749,0,3.732-1.516,4.081s0.584,3.149,0.35,5.831c-0.235,2.682-1.75-0.583-3.265-2.449
        c-1.516-1.866-7.931,7.696-10.263,11.544s-4.081,5.014-5.714,8.161c-1.633,3.148-3.265,3.849-3.498,5.364
        c-0.233,1.515-1.049,2.916-3.963,3.148c-2.916,0.234-0.934,1.983-1.866,4.665s-2.916,3.382-5.248,4.081s-2.682,1.167-2.682,2.799
        c0,1.631-0.233,2.916-4.431,5.248c-4.198,2.332,0.816,7.578-0.7,7.229c-1.517-0.349-4.082,2.449-4.082,3.732
        s-2.098,1.631-3.381,0.699c-1.282-0.933-3.148-0.234-4.782,0.349s-0.583,2.565-0.234,2.915c0.349,0.35-0.7,2.682-2.098,4.314
        c-1.4,1.633-1.282,2.682-2.916,3.732c-1.633,1.05-1.049,3.265-0.233,5.131c0.816,1.866-0.816,2.215-1.866,3.732
        c-1.049,1.516,0.234,1.866,2.1,2.916c1.865,1.05,0.234,1.4-1.631,2.916c-1.866,1.516,1.866,1.865,3.381,2.333
        c1.515,0.467,1.166,2.916-0.7,4.781c-1.865,1.865-0.115,1.4-2.097,3.615c-1.984,2.215,2.915,2.215,4.197,4.198
        c1.283,1.983,1.4,2.916,1.4,4.665s1.517,2.798,2.682,5.248c1.167,2.449-0.933,1.167-2.682,3.964c-1.749,2.799-0.349,2.916,0,4.082
        c0.35,1.165-0.349,4.431-0.816,5.83c-0.466,1.4-0.233,8.045-0.233,9.911s-0.7,2.798-1.865,3.265s-1.4,2.682-1.167,3.732
        c0.233,1.05,3.732-0.933,4.315,0.467c0.584,1.4,1.749,3.615,4.547,3.615s-1.167-2.916-1.4-4.897
        c-0.233-1.983,1.749-0.117,3.614,2.215s3.032,1.865,5.014,1.865c1.983,0,3.615,1.516,1.049,2.449
        c-2.565,0.933,1.051,1.983,2.449,2.333c1.4,0.349-0.349,1.05-1.749,1.749s-1.866,1.282-0.234,3.732
        c1.633,2.449,1.749,1.865,3.265,2.1c1.516,0.233,1.283-3.849,2.566-5.831c1.282-1.983,0.584,0.699,0.584,2.916
        c0,2.215,1.633,0.467,3.497,0.699c1.865,0.234-0.466,3.615-1.399,4.665c-0.933,1.05-1.983,1.05-1.983,2.681
        s2.798-0.232,4.198-1.282s1.281-2.215,2.1-3.732c0.816-1.516,2.798-0.584,3.615-0.349c0.816,0.234,4.315,0.349,6.413-1.05
        c2.1-1.4,2.916-0.816,5.014,0.933c2.098,1.749,2.216-1.633,3.265-3.732s4.199-0.234,7.112-0.933
        c2.916-0.699,1.166-1.865,3.964-2.798c2.799-0.933,2.916,1.05,4.666,0.467c1.749-0.583,0.932-1.05,2.332-1.05
        c1.4,0,2.449-0.933,2.1-1.983c-0.35-1.05,0.117-2.565,1.865-1.865c1.749,0.699,3.149,1.05,3.615,0.349
        c0.467-0.699,1.631-1.749,4.664-1.865c3.031-0.116,2.333-0.583,4.315-2.332c1.983-1.749,2.215-0.349,4.081,0.349
        c1.866,0.699,2.566,1.865,4.782,1.167c2.215-0.699,1.282-2.916,2.567-1.749c3.265-4.198,7.463-8.396,12.01-13.293
        C660.215,118.427,666.512,113.53,668.961,109.332z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_31_" x="578.72" y="71.091" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>

        <rect id="UNANSWERED_4_" x="591.87" y="80.429" opacity="0" fill="#EB35FF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="24.691" height="20.581" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_NORWAY" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Norway" data-wikipediaLink="https://en.wikipedia.org/wiki/Norway" data-infoText="<b>Norway</b> &amp;nbsp; Capital: Oslo" data-imageFile="NORWAY.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="NORWAY" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M408.346,183.9c1.4,2.156,3.498-0.057,4.285-4.773c0.787-4.715-2.186-8.577,0-10.676c2.187-2.098,1.662-3.148,0.856-4.547
        c-0.805-1.4-0.943-9.095,3.266-9.095s8.034-12.594,8.192-15.742c0.159-3.149-4.006-7.347-4.357-13.643
        c-0.349-6.296,4.897-3.849,6.998-8.396c2.099-4.547-1.749-5.947-4.896-8.745c-3.149-2.798-3.498-5.598-4.198-11.544
        c-0.698-5.947,2.449-13.293-0.504-19.24c-2.954-5.947,0.155-7.696,1.555-12.243s-1.4-4.897-1.4-8.746c0-3.848-0.349-8.396,0-13.643
        c0.349-5.248,8.745-8.745,13.643-11.894s5.598,0.7,11.545,0c5.947-0.7,1.4-7.696-1.4-13.992s-0.699-4.897,0-9.096
        c0.7-4.198,2.449-6.647,5.598-15.211c3.148-8.564,3.148-11.376,5.248-16.973c2.098-5.597-0.699-10.145,0-16.092
        c0.7-5.947,3.498-1.4,9.444-3.149c5.947-1.749,3.149-4.198,2.1-9.445c-1.05-5.248,7.347-13.643,11.544-17.142
        c4.198-3.498,3.848-5.597,3.148-9.795c-0.699-4.198-1.399-12.943,0-15.392c1.4-2.449,4.198-11.195,5.947-16.413
        c1.748-5.218,6.296-1.428,9.795-0.379c3.498,1.05,5.248,0,6.647-4.198c1.4-4.198,3.149-7.346,4.897-13.992
        c1.749-6.647,5.248,0,8.045-1.05c2.798-1.05,9.795,5.248,12.943,5.947c3.149,0.699,5.947-4.198,3.149-6.647
        c-2.798-2.449,0.7-3.848,3.498-6.997c2.798-3.149,1.749-9.796-1.049-13.643c-2.798-3.848,2.798-0.349,5.737-1.4
        c2.94-1.05,1.608-0.698,6.717,0.349c5.109,1.05,0.487-1.399,0.022-7.346c-0.467-5.947,6.762,2.798,11.309,3.848
        s5.248,9.445,9.096,15.392c3.849,5.947,5.598,1.749,9.795,3.498c4.197,1.749,3.149-0.698,7.347-0.698
        c4.197,0,3.148-2.1,5.946-4.198c2.799-2.1,7.696,4.198,11.564,6.997c3.867,2.798,3.828-2.099,3.066-5.598
        c-0.762-3.498,4.961-2.798,8.109-6.647c3.149-3.848,3.149-7.696,2.799-14.692c-0.35-6.997,2.448-8.745,2.448-13.293
        c0-4.547,4.197-2.1,4.897-8.396c0.7-6.296,2.098-4.547,10.494-2.449c8.395,2.1,4.198-1.749,6.997-6.645
        c2.799-4.897,9.096,8.396,16.441,10.843c7.347,2.449,7.697,7.696,8.396,12.113c0.7,4.417-4.547,8.876-6.297,15.174
        c-1.749,6.296,2.1,3.148,3.498,3.148s5.947-9.445,6.647-10.844c0.7-1.399,6.296-3.848,7.696-4.198
        c1.399-0.349,2.798-5.947,3.498-9.095c0.699-3.148,0,0,3.149,2.798c3.148,2.798,4.547,1.4,6.526,0
        c1.978-1.399-0.579-6.647-1.104-10.407c-1.05-0.699-1.05-1.05-4.022-1.137c-2.975-0.087-3.237-0.7-4.372-2.449
        s-1.661-0.35-2.012,1.05c-0.349,1.399-1.312-0.175-1.662-1.4c-0.349-1.225-0.961-1.225-2.012,0.436
        c-1.049,1.662-0.874,0.787-2.797-1.137c-1.925-1.924-1.488,0.349-2.799-0.787c-1.313-1.137,1.837-1.399,1.837-2.449
        s-0.525-0.087-2.623-1.312c-2.098-1.225-5.86-1.4-8.307-2.449c-2.45-1.05-2.361-1.312-2.012-2.011s-0.35-0.699-0.262-1.575
        c0.087-0.874,2.274-0.176,4.634,0.7c2.361,0.874,8.92,2.098,11.195,2.711c2.274,0.612,4.285,0.087,5.947-1.487
        c1.662-1.575,2.274-1.749,2.623-2.798c0.349-1.05,1.049-0.787,1.313-2.449c0.262-1.662,3.411-0.874,5.248-0.874
        s1.924-1.224,3.673-2.011c1.749-0.787,0.524-1.4,0-2.274c-0.525-0.874-1.313-1.924-1.837-3.586c-0.525-1.662-0.874-0.349-2.274,0
        c-1.399,0.349-1.487-1.05-2.536-2.274s-1.137-2.098-2.273-2.798c-1.138-0.699-2.886,2.012-4.722,1.225
        c-1.838-0.787,1.835-2.361,2.274-3.149c0.437-0.787-0.176-1.137-0.964-1.224c-0.788-0.087-1.661-1.313-0.349-2.274
        c1.313-0.961-1.4-0.787-2.798-1.487c-1.399-0.699-1.924,0.436-2.361,1.749c-0.437,1.312-0.961,1.049-1.925,0.874
        c-0.961-0.175,0.612-1.312,1.049-2.973c0.438-1.662-0.437-2.011-2.098-0.263c-1.662,1.749-3.148,2.187-2.885,1.662
        c0.262-0.525-0.787-1.049-1.749-1.662c-0.961-0.611,0-2.274,0-3.76c0-1.487-2.098-1.313-2.885-2.099
        c-0.787-0.787-3.527-1.575-4.694-1.575c-1.165,0-1.049,3.382-2.816,5.248c-1.766,1.866-1.033,2.099-0.216,4.082
        c0.818,1.983-0.816,1.049-2.681,3.849c-1.865,2.798,1.165,3.031,0.466,5.014c-0.7,1.983-2.681,0.117-5.13,0.584
        s-0.583-2.215,0.233-3.732c0.816-1.516,1.983-3.265,2.333-5.831c0.35-2.565-1.282,0.817-3.732,3.149s-2.915,0.933-3.614-0.116
        c-0.699-1.049,1.983-2.565,3.149-3.265c1.167-0.699-0.467-2.798-1.633-3.382c-1.167-0.584-0.349-1.282,0.933-1.282
        c1.283,0,3.732,1.4,4.315,0.117c0.583-1.282,2.449-2.567,3.849-3.615c1.399-1.049,0.583-3.964,0.466-5.363
        c-0.116-1.4-1.749-0.233-3.498-0.584c-1.748-0.349-0.349-2.098-0.467-3.848c-0.116-1.749-3.614,1.749-5.131,1.749
        c-1.516,0-1.749-0.932-4.066-2.682c-2.318-1.749-0.248,2.565-0.014,5.248c0.233,2.682-2.449-0.699-3.149-1.517
        c-0.698-0.816-1.983,0.117-1.399,1.283c0.583,1.166-0.934,1.633-2.1,1.749c-1.165,0.116-0.584,4.081,0.816,4.431
        c1.399,0.35,3.382-0.467,4.665,0.699c1.281,1.166-0.932,1.399-3.381,1.166c-2.45-0.233-0.468,1.4,0.349,2.682
        c0.816,1.282-1.167,1.4-2.566,1.631c-1.4,0.233-0.35,1.632,1.167,4.316c1.517,2.682-0.467,0.932-2.574,0.583
        s-1.508,2.916-0.924,4.081c0.584,1.166,1.749,2.682,0,2.215s-2.1,0-3.96,2.215c-1.86,2.216-1.171,1.516-2.338,1.749
        c-1.165,0.233-0.349-2.567-0.233-4.198s0.584-4.665,0.349-6.53c-0.233-1.865-1.749-0.233-2.1-1.4
        c-0.35-1.166,2.799-2.098,3.498-3.614c0.699-1.516,0-1.983-0.467-3.149c-0.466-1.166,1.4-1.866,1.283-3.732
        c-0.117-1.865-1.865-1.749-3.382-0.933c-1.515,0.816-3.148,5.014-4.781,7.114c-1.633,2.099-1.049,3.031-1.749,5.48
        s-1.631,1.4-1.631,2.449s-2.332,4.431-5.363,7.463c-3.032,3.031-1.631,2.332-0.467,3.848c1.167,1.517-0.117,2.682-1.516,3.732
        s-1.631,2.215-1.983,5.364c-0.349,3.149-1.983,1.749-2.333,0.467c-0.35-1.282-1.4-2.682-1.749-4.198s1.749-2.449,2.799-3.849
        c1.049-1.4,0.349-1.516-0.467-2.682s1.282-1.165,2.682-3.848c1.4-2.682-0.234-1.631-1.049-3.614
        c-0.816-1.983,0.816-1.865,2.681-3.614c1.865-1.749,1.749-4.198,3.382-5.715c1.633-1.516,3.265-3.848,2.682-5.014
        c-0.584-1.167-1.866-1.282-2.916-2.215s-0.349-1.4,0.816-2.449c1.167-1.049,4.315,1.166,6.354,0.349
        c2.039-0.816-1.34-1.166-2.507-1.983s2.1-1.166,2.448-2.333c0.35-1.167-0.933-1.631-2.681-1.631c-1.749,0-0.933-0.349-0.933-2.084
        c0-1.733-2.215-1.298-2.449,0c-0.233,1.298-2.215,0.8-3.031,0.218s-2.565,0-2.448,1.866s0.933,2.098,0.234,2.682
        c-0.7,0.583-0.467,1.866-0.234,3.498c0.234,1.631,3.498,2.565,2.916,2.565c-0.583,0-2.333,2.332-4.431,3.031
        c-2.1,0.699,0-1.865,0-3.265s-1.865-2.565-2.915-2.916c-1.049-0.349,0,2.682-0.7,3.031s-1.399-0.349-2.214-2.332
        c-0.816-1.983-2.216-1.749-3.616-1.166c-1.399,0.583,1.051,3.149,0.934,4.547c-0.116,1.4-3.265-0.816-5.015,0
        c-1.749,0.816,1.282,3.498,3.149,3.498s2.448,3.382,4.081,5.364c1.633,1.983-1.399,1.865-3.149-0.349
        c-1.748-2.215-2.681-1.516-4.897-0.583c-2.215,0.933,1.282,2.449,0,5.363c-1.283,2.916,0.7,2.098,1.516,3.848
        c0.816,1.749-2.448-0.583-4.315-1.982c-1.866-1.4-2.332,1.631-4.664,1.282c-2.333-0.35-1.4-2.215-3.096-4.781
        c-1.696-2.565-3.902,1.632-4.835,3.031c-0.933,1.399-2.215,1.282-4.198,2.798s1.049,3.265,2.215,3.148
        c1.167-0.117,1.049,2.798,2.215,3.615c1.167,0.816,1.516-1.866,2.214-2.798c0.7-0.933,1.517,0.349,2.216,2.256
        c0.7,1.907-0.816,2.175-3.498,2.642s1.749,0.699,1.167,4.081c-0.583,3.381-1.634,2.449-0.349,4.781
        c1.282,2.333,1.749,0.933,2.214,2.1c0.467,1.165-1.631,0.699-3.381,1.282s-1.4-0.817-1.983-2.332c-0.583-1.516-0.7-0.467-0.933-2.1
        c-0.233-1.634-0.116-1.165-1.289-2.098c-1.173-0.933-0.692-0.816-0.109-2.565c0.583-1.749-1.283-1.517-2.916-2.1
        c-1.631-0.583-3.498,0.349-3.031,2.332s-1.4,1.4-2.915,0c-1.516-1.4,1.282-3.148-0.699-3.148c-1.982,0-1.165-0.233-1.165-1.749
        c0-1.516-2.682-1.631-4.897,0.467c-2.216,2.098,0.932,3.149-1.167,2.681c-2.098-0.467-1.4-2.798-3.031-3.848
        s-1.749,2.798-1.865,5.598c-0.117,2.798,1.399,1.166,2.798,2.565c1.399,1.4,3.732-0.467,4.781-0.467
        c1.051,0,0.933,0.933-1.631,2.098c-2.565,1.167,1.049,1.749,2.332,2.215c1.283,0.467,0.816,1.166-0.234,2.565
        c-1.051,1.4,1.983,7.58,0.933,8.28c-1.05,0.698-1.749-1.634-1.984-2.798c-0.233-1.165-1.282-1.749-3.381-2.565
        c-2.1-0.817-1.631-2.565-2.1-5.014c-0.467-2.449-1.516-0.698-3.106-0.698s-1.209,1.983-0.625,3.732s1.049,2.332,0,2.1
        c-1.049-0.233-2.565,1.516-2.565,2.332s-1.167,0-1.516-1.165c-0.35-1.166-1.749-1.282-1.983-0.584
        c-0.233,0.699,0,3.149-1.866,3.964c-1.865,0.816-2.332,1.166-3.265,4.43c-0.933,3.265,2.449,4.082,3.964,5.48
        c1.516,1.4-0.467,1.282-2.1,1.282s-0.584-1.399-1.749-2.449c-1.167-1.05-0.933,3.031-2.1,4.431
        c-1.167,1.399-1.05,2.565-2.449,4.781c-1.4,2.215-3.149,2.565-3.149,1.983s1.282-3.265,2.681-4.665
        c1.401-1.4,1.517-2.333,1.401-3.615c-0.117-1.282,0.349-2.449,1.049-3.149c0.7-0.699,0.584-2.565,0.116-3.498
        c-0.466-0.933-0.116-1.983,0.35-4.082c0.467-2.098,0.933-3.732,0.933-6.763s-1.631-3.148-1.749-2.565
        c-0.117,0.584-1.865,3.149-2.798,5.248c-0.934,2.1-1.165,3.149-2.317,3.149c-1.151,0-1.066,1.865-1.648,3.732
        c-0.584,1.865,0,1.866,0,1.866c1.954,0.125,1.865,2.681,1.281,2.332c-0.583-0.349-0.816,0-1.982,2.682
        c-1.165,2.682-0.467,3.498-2.333,4.781c-1.865,1.282-0.116-0.817,0-2.798c0.116-1.983,0.467-3.149,1.049-4.198
        c0.584-1.05-0.233-1.749,0.117-3.964c0.349-2.215,0.583-3.614,0.583-5.83s-1.282-0.699-3.299,0.7
        c-2.017,1.4-3.58,1.166-4.512,1.618c-0.933,0.451-1.866,2.93-2.682,4.795c-0.817,1.865,0.816,1.865,2.449,2.098
        c1.631,0.233-0.116,2.449-1.05,1.865c-0.933-0.583-0.35,2.215,0.584,3.848c0.933,1.632,2.798,2.567,4.431,4.316
        c1.631,1.749,0.583,4.547-1.983,4.431c-2.565-0.117-0.467-2.449,0.466-3.381c0.933-0.933-2.098-1.4-3.614-2.215
        c-1.515-0.817-2.332-3.149-2.682-5.131c-0.35-1.983-0.7-2.916-1.516-4.431c-0.816-1.516,0-2.215,0-3.615s-1.866-3.265-2.1-2.327
        c-0.233,0.938-2.1,0.811-2.682,0.111c-0.584-0.7-3.265,2.098-2.1,3.031s-1.165,1.282-3.614,1.866
        c-2.448,0.583,1.748,2.098,3.732,2.798c1.983,0.699,4.781,0.233,5.714,1.05c0.933,0.816-0.934,2.215-1.516,4.431
        c-0.584,2.215,3.148,3.149,4.896,4.082c1.749,0.933,0.349,2.098-0.7,1.05c-1.051-1.05-2.449,0.467-4.082,1.05
        c-1.631,0.583-1.983-3.265-1.983-5.131c0-1.865-1.633-1.983-3.849-3.498c-2.215-1.516-1.049,2.099-1.475,3.848
        c-0.427,1.749,0.076,2.565-0.974,2.565s-0.233,3.498,0.7,4.547c0.932,1.05-0.583,3.031-3.614,3.031s-2.566,3.031-5.481,5.131
        c-2.916,2.098,0,2.565,1.283,2.565s5.248,2.215,1.983,1.749c-3.265-0.467-0.7,1.4-0.597,2.916c0.1,1.516-1.152,0.467-4.183,1.4
        c-3.031,0.932,1.749,2.449,3.498,3.148c1.749,0.699-0.235,2.682-1.749,1.516c-1.515-1.167-2.215-1.399-2.565-0.349
        c-0.349,1.05-1.749,2.215-2.448,1.282s-2.215-1.282-4.431,0.349c-2.214,1.631-2.798,0.699-3.382-1.983
        c-0.584-2.682-1.749-3.498-1.982-5.248c-0.233-1.749-3.149-1.983-4.547-3.149c-1.4-1.166-2.682,1.983-1.049,3.848
        c1.631,1.865,0,4.781-1.05,6.063c-1.051,1.282-2.449-0.233-0.817-1.516c1.634-1.282,0.349-1.865-0.116-2.215
        c-0.467-0.349-0.467-2.916,0-6.063c0.467-3.148-1.749-1.865-4.198-1.282s-2.098,2.916-2.098,4.897c0,1.983-1.05,3.031-2.215,2.332
        s-0.816-4.431-2.449-7.112c-1.631-2.682-1.282-1.282-2.448-1.983c-1.167-0.7-1.749,3.265-1.167,5.597
        c0.583,2.332,0,1.983-0.7,2.565c-0.698,0.583-0.698,0-4.081,0c-3.381,0-2.098,3.964-2.332,6.414
        c-0.235,2.449,2.682-0.584,4.198-2.449s3.848-1.166,5.598-1.166c1.749,0-2.332,2.215-3.265,4.198s1.049,1.05,3.848-0.467
        c2.798-1.516,3.849-1.983,4.431-1.983s0.933,1.4-1.4,2.1c-2.333,0.699-1.631,3.615-1.516,5.248
        c0.116,1.631-2.332,0.233-4.314,1.631c-1.983,1.4-2.098,1.4-5.248,1.167c-3.148-0.234-2.682,1.983-3.265,4.547
        c-0.583,2.565-1.982,3.031-1.516,5.015c0.467,1.983,2.449-1.866,3.732-1.866c1.282,0,1.865-1.05,2.916-2.449
        c1.05-1.4,2.1-1.167,3.031,0.583c0.933,1.749,1.049-0.233,1.982-2.565c0.933-2.332,4.782-3.382,3.732-1.166
        c-1.05,2.215,2.1,0.233,3.382,1.632c1.282,1.399,1.05-0.117,1.516-1.866c0.466-1.749,2.098-2.798,2.682-0.817
        c0.583,1.983,1.631-1.05,2.215-2.916c0.584-1.865,4.431-3.149,5.131-4.431c0.698-1.282,2.798,1.516,0.932,3.498
        s4.198,0.816,5.598,0.467s0.817-2.682,3.149-2.64c2.331,0.043,1.399,2.289,2.798,1.589c1.4-0.7,0.816-2.098,3.849-1.399
        c3.031,0.699,2.682,2.682,1.049,2.915s-1.166,0.933-1.4,2.607c-0.233,1.676-1.4,0.657-2.915,0.657c-1.516,0-1.984,0.233-2.799,1.4
        s-3.382,0-4.082-1.166c-0.7-1.167-4.896-0.933-7.228-0.698c-2.332,0.233,0.466,2.798,1.399,3.614c0.933,0.816,1.631,1.983,0,3.265
        s-0.233,3.615-0.583,5.369s-0.584,0.928-3.032,0.171c-2.449-0.757-3.732,1.224-1.982,1.69c1.749,0.467-1.749,2.332-2.798,2.098
        c-1.05-0.234-2.1,1.166-4.782,1.983c-2.681,0.816-1.983-0.699-1.165-2.798c0.816-2.1-0.233-2.449-2.566-2.916
        s-1.982,1.983-1.631,4.314c0.349,2.332-0.933,1.865-3.732,2.798c-2.798,0.933-1.983,2.215-0.466,3.128
        c1.516,0.913-0.235,1.886-1.517,3.867c-1.282,1.983,2.448,0.467,4.781,0c2.332-0.467,1.983-1.749,2.682-3.031
        c0.698-1.282,3.265-0.933,6.028,0s-1.479,1.632-3.811,1.749c-2.332,0.116-2.916,2.449-2.449,2.682
        c0.467,0.233,2.215,1.983,0.467,2.098c-1.749,0.117,0.35,1.983-0.116,3.849c-0.467,1.865-1.4,0-2.682-2.333
        s-4.197,1.282-3.849,3.265c0.349,1.983,3.381,2.1,2.449,2.915c-0.934,0.816-2.798,0-5.363,0c-2.566,0-1.051,2.333-1.984,4.897
        c-0.933,2.565,0.467-0.584,2.916-1.05c2.45-0.467,2.916,0.349,3.966-0.816c1.049-1.167,2.798-0.349,3.614,1.749
        c0.816,2.1,3.849,0.467,5.831,0.699c1.983,0.233,0.7,1.865-0.816,2.798c-1.516,0.933-2.098,2.798-3.382,0.349
        c-1.283-2.449-2.915-0.467-5.363-1.865c-2.449-1.4-3.849-0.349-3.615,1.05c0.233,1.4-2.332,0.816-5.363,1.516
        c-3.033,0.699-1.983,2.215-2.444,4.665c-0.462,2.449-3.269,2.682-5.486,2.215c-2.215-0.467-1.631,1.983-0.467,3.732
        c1.167,1.749-0.349,2.682-2.215,3.615c-1.865,0.933-1.165,2.798-0.116,4.198c1.05,1.399,0.467,4.081-1.167,3.265
        c-1.631-0.816-3.148-0.817-3.148,0.816s-0.467,2.565-2.1,3.265c-1.631,0.7-0.349,3.615,1.866,4.665s-0.699-4.431,3.031-0.933
        c3.732,3.498,5.48-0.116,8.978,0s-5.248,5.248-6.414,6.53c-1.167,1.282-1.982-0.349-2.332-1.866
        c-0.349-1.516-3.382-0.116-4.782,1.4c-1.399,1.516-3.964-0.467-5.363,0c-1.399,0.467-5.014,5.131-0.933,2.798s5.48-0.7,3.963,0
        c-1.516,0.699,0.934,2.565,0.817,4.316c-0.116,1.749-3.031-0.233-5.905,1.167c-2.873,1.4,0.774,1.983,2.403,3.849
        c1.629,1.865,0.005,4.198-2.403,3.265s-1.79-1.165-2.491,0c-0.7,1.167-1.282,4.547-1.983,5.131
        c-0.698,0.584-1.515,5.598-2.915,8.396s0.816,0.7,2.215-0.116c1.399-0.816,1.282-3.732,2.915-3.848
        c1.631-0.116,1.865,1.05,2.257,2.682c0.392,1.631-2.957,1.983-4.24,3.848c-1.282,1.865,5.363,0.467,6.413,1.167
        s-1.749,2.332-3.382,4.198c-1.632,1.866-3.265-1.749-4.315-2.916c-1.049-1.167,0.699,3.265-3.266,4.431
        c-3.964,1.167-2.448,1.632-4.081,3.498c-1.633,1.865-1.516,1.516-4.316,1.865c-2.798,0.349-5.364-0.116-6.063,1.4
        c-0.699,1.516,1.516,2.332,3.498,1.633c1.983-0.7,7.696,0.116,5.714,2.098c-1.983,1.983-4.547,1.516-4.198,3.732
        c0.349,2.215,4.665,1.05,6.296,1.773c1.631,0.723-1.749,2.542-2.798,3.009s-1.049,4.781-2.099,5.48
        c-1.049,0.7-1.983-0.583-1.749-2.449c0.233-1.865-1.4-1.983-2.215-4.665s-1.75-0.933-2.915,0.933
        c-1.167,1.865-2.1,3.615-4.316,3.732c-2.215,0.116-3.148,2.449-4.081,5.831c-0.932,3.382-6.063,4.081-5.597,6.997
        c0.467,2.916,1.283,3.031-1.866,5.13c-3.148,2.098-5.13,3.732-2.798,3.615c2.332-0.116,4.781-0.234,3.265,1.866
        c-1.516,2.098,1.983,4.665,5.714,3.848c3.732-0.816,6.414-4.897,8.745-7.229c2.332-2.333,0.584-1.631-1.865-0.7
        c-2.448,0.932,1.166-4.314,3.381-7.229c2.215-2.916,3.265-2.215,2.332-1.05c-0.933,1.167,2.332,1.516,4.486,1.749
        c2.154,0.234-1.92,1.516-3.67,3.849c-1.749,2.332,2.681,1.631,3.498,3.265c0.817,1.633-1.399,0.816-4.315,3.149
        c-2.916,2.332-1.983,0.933-4.314,2.215s-0.35,3.498,0.933,5.714c1.283,2.215-2.449,1.4-6.647,0.349
        c-4.198-1.05-3.149,0.583-4.314,3.498c-1.167,2.916-1.4,0.816-3.964,0.583c-2.565-0.234-0.467-1.749-0.816-4.547
        s-1.4-2.798-2.099-3.732c-0.7-0.933-3.615,1.282-4.897,2.565c-1.282,1.282,0,2.333-0.817,4.781c-0.816,2.449-2.681,0-2.332-1.749
        c0.35-1.749-2.098-1.05-3.963-1.282c-1.866-0.234-2.565,1.282-1.517,2.332c1.049,1.05,0,1.865-1.865,2.332
        c-1.866,0.467-1.4,0.933-2.567,3.031c-1.167,2.099-2.332,0.116-3.848,1.749c-1.517,1.633,0.467,2.799-0.117,4.082
        c-0.583,1.282-1.983-0.349-2.682-1.633c-0.699-1.282-2.332,0-4.665-0.467c-2.332-0.467-1.515,3.732-2.797,4.081
        c-1.283,0.349-2.565-1.282-4.198-2.916c-1.631-1.631-4.896,0.699-3.149,1.865c1.749,1.167,1.4,4.547-0.583,5.364
        c-1.983,0.816-2.332,1.983-0.467,2.565c1.865,0.583,5.248,0,6.88,0c1.633,0,3.266,1.165,1.631,3.148
        c-1.632,1.983-1.866,0.933-3.849,1.167s-3.848-0.933-5.712-1.631c-1.866-0.699-3.149,0-3.149,2.215s0.117,3.382,0,5.131
        c-0.115,1.749-2.332,3.149-4.312,2.682c-1.983-0.467-2.449-0.816-2.798,0c-0.35,0.816-0.933,4.665-3.032,4.431
        c-2.1-0.234-3.381-1.983-5.131-0.933c-1.749,1.05-0.816,3.265-1.4,5.598c-0.584,2.332-5.131,0.583-5.363,1.516
        c-0.233,0.933,4.315,2.565,2.915,4.781c-1.4,2.215-5.014,3.265-5.947,4.547c-0.933,1.282,2.683,1.633,5.715,1.983
        c3.032,0.35,0,2.215-1.631,2.916c-1.633,0.698,0.816,1.516,2.565,2.916s-1.4,1.631-3.265,2.215
        c-1.865,0.583,1.516,4.781,3.265,6.645c1.749,1.866,4.547-1.05,6.063-1.516s1.749,2.449,0.467,2.449
        c-1.283,0-1.749,1.282-5.131,2.1s-2.449-0.467-4.081-1.282c-1.631-0.816-1.749,1.516-2.215,3.848
        c-0.466,2.333,1.516,2.565,3.965,3.265c2.449,0.699,0,2.682-1.631,3.031c-1.633,0.35-1.4,1.749,0.699,2.798
        c2.099,1.05,3.848,3.031,2.099,2.565s-1.632,0.699-1.632,2.697s-1.282,0.918-1.865,0.102c-0.584-0.817-2.567,3.498-3.265,5.714
        c-0.7,2.215,1.865-0.234,3.732-0.933c1.866-0.699,0.7,2.916,1.749,5.363c1.049,2.449,1.865-0.467,2.332-2.565
        s1.983-3.498,1.983-2.915c0,0.583,0.349,1.983,1.633,2.915c1.282,0.933,0.467,2.215-1.749,3.732
        c-2.215,1.516-2.215,1.865-3.265,3.381c-1.05,1.516-0.233-3.265-3.529,0.233c-3.297,3.498-0.669,4.547,0.73,6.763
        c1.4,2.215,1.4-1.166,3.848-1.516c2.449-0.349,1.283-1.983,1.749-4.665c0.467-2.681,2.215-3.265,4.316-3.615
        c2.098-0.349,1.749-0.699,1.749-2.915c0-2.215-0.467-3.149,0.467-3.615c0.932-0.467,4.315-4.081,5.714-4.081
        c1.4,0-0.816,3.148-3.498,4.781c-2.682,1.633,1.516,4.081,0.816,4.081c-0.699,0-2.565,5.131-2.916,4.082
        c-0.35-1.05-1.166,0.349-3.149,0.699c-1.983,0.349-0.816,3.732-0.116,4.547c0.699,0.816,1.749,2.215-0.35,3.232
        c-2.099,1.016-1.865,0.384-4.547,0.034c-2.681-0.349-2.332,2.1-2.215,3.725c0.116,1.626,0.349,3.739-1.631,4.204
        c-1.983,0.467-0.349,2.449,0,6.53c0.35,4.081,0.117-1.517,2.215-3.031c2.098-1.516,2.448-0.699,3.059-0.699
        c0.609,0,3.356-1.983,3.939-3.498c0.583-1.516,1.516-2.565,3.498-3.031c1.983-0.467,2.449,1.167,0.584,2.565
        c-1.865,1.4-0.816,1.749,1.281,2.449c2.099,0.699,1.866,2.332-0.933,4.665c-2.799,2.332-2.566,1.983-1.399,3.498
        c1.167,1.516,0.698,1.983-0.933,3.031c-1.631,1.05-2.799-0.699-3.732-1.631c-0.933-0.933-1.749,2.915-2.215,4.082
        c-0.467,1.167,0.932,5.714,2.565,8.045c1.631,2.333,1.866,0.116,2.915,2.099c1.051,1.983,4.431,4.431,6.647,5.598
        c2.215,1.167,4.316-0.349,5.831,0.7c1.516,1.05-1.516,1.516-2.099,5.248c-0.584,3.732,2.215,0.467,4.665-1.167
        c2.449-1.632,3.498,0,6.53,2.215c3.031,2.216,3.849,1.282,5.015-0.933c1.166-2.215,4.547-5.363,4.896-2.215
        c0.35,3.149,3.615-2.332,6.996-5.248c3.381-2.915,1.399-3.732,3.265-5.014c1.866-1.282,2.215-0.7,3.382-2.799
        c1.167-2.098,0.583-2.798,2.798-3.031c2.215-0.232,2.798-0.7,1.516-1.983c-1.282-1.282,0.584-3.382,3.265-3.848
        c2.682-0.467,1.984,0.35,2.449-1.516c0.467-1.865-2.565-2.682-1.516-3.382c1.051-0.699,3.031,3.848,6.413,4.198
        c3.381,0.349,2.449-0.233,2.916-2.449c0.467-2.215,0.817-3.732,1.749-4.664c0.933-0.933,4.431-0.584,0.816-3.498
        c-3.615-2.916-1.282-5.131-0.933-4.084c0.349,1.045,0.7,2.801,3.849-0.347c3.149-3.149,0,1.749,0,1.749
        c-0.576,0.938,1.982,4.781,2.448,7.229c0.467,2.448,6.063,1.631,5.48,2.449c-0.443,0.621-1.224,2.056-1.003,3.478
        C405.555,181.612,407.674,182.867,408.346,183.9z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_32_" x="331.968" y="117.017" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>

        <rect id="UNANSWERED_5_" x="346.885" y="116.54" opacity="0" fill="#EB35FF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="24.691" height="20.581" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_POLAND" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Poland" data-wikipediaLink="https://en.wikipedia.org/wiki/Poland" data-infoText="<b>Poland</b> &amp;nbsp; Capital: Warsaw" data-imageFile="POLAND.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="POLAND" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M448.4,334.441c-1.573,3.323-1.399,4.197,1.75,6.471c3.149,2.273,1.749,2.798,1.224,5.947s0.176,3.674,2.799,5.947
        c2.622,2.273,0.175,5.597-1.077,8.92c-1.25,3.324,0.552,2.623,0.202,5.947s-0.35,1.749,0.874,2.975
        c1.224,1.224,3.848,0.699,5.073,3.324c1.224,2.623-1.575,7.346-0.874,7.346s1.05,0,3.848,3.324
        c2.799,3.324,5.073,2.623,7.872,4.548c2.798,1.924,4.722,0.7,6.821,0c2.098-0.699,2.449,2.449,1.05,4.022
        c-1.4,1.575-2.448,1.749-1.749,2.449c0.7,0.699,4.022,3.149,4.372,5.073c0.35,1.925,3.325,1.4,5.423,0.35
        c2.098-1.051,0-3.324,0-5.248c0-1.924,2.798-0.874,5.597,1.924c2.798,2.799,4.023,0,6.821,1.05c2.798,1.051-2.1,2.449-2.1,2.449
        s3.498,4.197,4.547,2.972c1.049-1.224,2.623,1.749,5.771,2.274c3.149,0.524,2.624,2.274,3.673,3.149
        c1.049,0.874,2.972,4.372,4.198,6.122c1.225,1.749,1.049,2.098,2.972,2.623c1.925,0.524,3.674-3.324,5.772-4.372
        c2.1-1.05,0.874,1.925,2.798,4.023c1.925,2.099,2.274,0.349,1.925,2.449c-0.35,2.098,1.4,1.051,4.023,1.575
        s1.224-1.749,2.273-2.975c1.049-1.224,8.395,0,9.97,1.225c1.575,1.224,3.324-1.4,4.547-2.274s7.171,0,9.62,1.575
        s3.324,3.323,6.297,5.422c2.972,2.098,4.197,1.924,6.647,1.924c2.448,0-0.35-2.274-0.875-3.849s0.176-2.973-0.525-6.471
        c-0.7-3.498,0.35-4.722,4.372-9.62c4.023-4.897,7.696-8.221,10.494-9.795c2.798-1.575,2.972-2.1,4.372-4.023
        c1.4-1.924,0-3.323,0.875-5.073c0.874-1.749,0.698-2.449-1.888-4.023c-2.587-1.575-1.787-6.296-3.71-8.92
        c-1.925-2.623-0.525-4.722-1.654-8.921c-1.128-4.197,1.304-7.171,0.01-9.795c-1.293-2.624-5.704-3.148-5.354-5.772
        c0.349-2.623,1.399-5.597,5.772-5.947c4.372-0.35,3.673-3.324,4.711-5.771c1.038-2.449-1.739-9.97-4.362-13.994
        c-2.623-4.022-1.575-7.171-2.1-9.096c-0.288-1.061,0.379-2.437,1.035-3.461c-0.578-0.965-0.86-2.404-0.86-4.41
        c0-4.198-3.498-3.323-4.896-4.722c-0.894-0.893-2.43-0.577-4.107-0.731c-4.914,2.278-13.302-1.857-20.555-1.542
        c-8.044,0.35-9.795,1.05-15.042,0c-5.248-1.05-6.472-0.698-10.164-0.992c-1.861,1.146-4.168,0.119-8.026,1.808
        c-5.597,2.449-5.248-0.233-6.647-2.682c-1.4-2.449-1.631-3.732-3.148-6.763c-1.516-3.031-8.863-1.749-13.877-0.116
        c-5.015,1.632-6.53,4.081-11.195,3.964s-4.547,1.399-6.996,5.714c-2.449,4.314-2.916,3.149-8.162,3.615
        c-5.248,0.467-10.377,3.382-15.042,5.48c-4.665,2.099-0.349,2.099-1.05,4.198c-0.7,2.099,1.282,2.799,0.467,4.665
        c-0.644,1.47-1.512,0.044-4.131-1.255C451.338,335.088,449.87,331.338,448.4,334.441z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_33_" x="486.471" y="336.467" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_GERMANY" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Germany" data-wikipediaLink="https://en.wikipedia.org/wiki/Germany" data-infoText="<b>Germany</b> &amp;nbsp; Capital: Berlin" data-imageFile="GERMANY.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="GERMANY" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M459.245,373.97c-1.224-2.623-3.849-2.098-5.073-3.324c-1.225-1.224-1.225,0.349-0.874-2.975c0.349-3.323-1.452-2.623-0.202-5.947
        c1.252-3.324,3.699-6.647,1.077-8.92c-2.623-2.273-3.324-2.798-2.799-5.947s1.925-3.673-1.224-5.947
        c-3.148-2.274-3.324-3.149-1.75-6.471c1.471-3.103,2.938,0.647,0.709-15.772c-0.704-0.349-1.527-0.689-2.516-0.96
        c-4.665-1.282-2.567-1.399-0.816-2.682c1.749-1.282,0.816-2.798-1.05-2.798s-1.4-2.565-1.4-3.732s-1.516-1.516-5.597-0.467
        c-4.082,1.05-2.332-0.467-2.332-2.215s2.681-3.031,4.781-3.498c2.098-0.467-0.7-0.584-1.749-1.166
        c-1.05-0.583,0.349-0.933,1.516-2.798c1.166-1.866-1.633-2.215-3.265-1.167c-1.632,1.05-1.865,0.349-2.682-0.583
        c-0.816-0.933-1.983,0.817-0.932,1.516c1.05,0.7,0.116,2.565-1.631,3.382c-1.749,0.816-2.448,0-3.849-0.349
        c-1.4-0.349-0.816,0.233-4.081,1.631c-3.265,1.4-3.149,1.282-4.198,3.149c-1.05,1.865-1.167,2.215-3.732,1.633
        s-4.314,0.467-5.014,2.332c-0.699,1.865-2.449,2.565-3.732,2.332c-1.282-0.233-4.198-1.05-7.347-0.816
        c-3.148,0.233-1.631-1.4,0.233-3.382c1.866-1.983,2.681-1.866,2.681-2.682s0.699-2.1,2.215-2.916
        c1.516-0.816,1.983-1.516,1.749-3.148c-0.233-1.632-1.4-1.282-3.732-1.4c-2.332-0.116,1.866,3.149,0.233,3.031
        c-1.632-0.116-1.049,0.116-2.682,1.865c-1.631,1.749-2.448-0.349-3.381,0.35c-0.933,0.698-2.098-0.35-3.732-1.866
        c-1.631-1.516-2.565-1.866-4.547-1.866c-1.983,0-2.448-0.116-1.631-1.983c0.816-1.865,0.233-2.682-0.7-4.198
        s-1.282-1.05-2.215-3.031c-0.282-0.6-0.111-0.889,0.297-1.024l-0.001-0.001c-1.512-1.725-5.483,0.5-5.659,1.407
        c-0.175,0.906-5.772-0.557-6.647-1.256c-0.45-0.361-2.291-0.016-3.972,0.404c-0.133,0.638-0.271,1.101,0.008,2.571
        c0.467,2.449,1.983,2.798,4.082,4.314c2.098,1.516,0.467,3.265-3.033,4.316c-3.497,1.05-1.631,2.449-1.282,2.215
        c0.35-0.233,4.198,0.233,3.382,1.399s-0.933,1.749-0.116,2.449c0.816,0.7,2.797,0.816,2.098,1.516
        c-0.699,0.699-1.633,1.632-1.866,1.983c-0.233,0.349,0.584,2.099,1.4,3.033c0.816,0.933,0.116,1.516-1.631,0.816
        c-1.75-0.699-3.033,0.817-4.199,2.449c-1.165,1.631-0.349,2.916-0.349,4.198s-1.283,1.167-1.983,0.117s-1.05-0.584-2.332-0.699
        c-1.282-0.117,0.233,2.915,0.699,5.014c0.467,2.098-1.982-0.233-2.916-0.699c-0.932-0.467-2.449-1.282-0.467-1.631
        c1.983-0.35,0.584-1.865,0.233-3.382c-0.35-1.516-1.4-1.631-3.149-1.282s-2.798,0-5.946-0.816
        c-3.149-0.816-2.449,1.516-3.965,3.265s-1.631,3.498,0.235,5.131c1.865,1.633,1.288,2.215,0.822,3.848
        c-0.27,0.944-1.204,0.365-1.626-0.47v3.153c0,0,0.519,1.575,0.345,3.673c-0.175,2.099-1.402,0.175-1.751,2.974
        c-0.349,2.798,0.786,4.723-0.964,4.897c-1.749,0.175-4.329-0.874-3.629,1.224c0.7,2.099,0.546,2.449,2.469,3.149
        c1.924,0.698,3.509,1.049,2.284,3.148c-1.224,2.099-3.493,2.274-3.843,4.547c-0.349,2.274-0.054,3.96-0.987,4.835
        c-0.934,0.874-4.08,2.561-4.08,2.561s-5.248-0.843-5.248,0c0,0-1.924-0.112,0,2.861c1.924,2.972,3.498,4.842,1.924,7.115
        c-1.575,2.274-2.098,3.382-1.924,4.956c0.175,1.575-1.312,2.741-1.18,4.14c0.083,0.885,0.951,3.002,0.339,5.301
        c0.508,0.384,0.965,0.769,1.541,2.351c0.699,1.924,2.449,3.316,3.149,3.666c0.699,0.35,0,2.27,0,2.27l-2.995,4.99
        c0.282,0.959,0.546,2.069,0.546,2.877c0,1.749-1.051,2.758,1.924,4.353c2.974,1.595,4.198,2.293,2.799,3.343
        c-0.677,0.507-1.515,2.649-1.944,5.218c0.147,0.088,0.274,0.208,0.37,0.38c0.874,1.575,3.498,4.023,5.422,5.422
        c1.925,1.4,3.324,0.35,4.023,0.874c0.7,0.525,5.947-2.274,6.645,0c0.699,2.274,5.423,2.937,6.472,3.305
        c1.049,0.367,2.623,2.291,0,6.139s-2.973,5.073-2.973,8.045c0,2.974-1.575,5.084-2.1,7.44c-0.444,2-2.891,4.325-1.703,8.72
        c0.476-0.803,1.185-1.13,1.703-0.172c0.7,1.292,1.575,1.33,2.623,0.806c1.049-0.525,3.324-0.176,4.723-0.806
        c1.399-0.631,3.323,0.981,6.246,1.33c2.923,0.35,1.101-2.099-1.348-3.24c-2.448-1.14,2.098-2.533,3.498-1.056
        c1.399,1.477,2.798,1.496,5.319,1.056c2.52-0.441,5.176,1.315,7.468,3.065c1.045,0.798,1.814,1.368,2.352,1.763
        c0.35-0.411,0.843-0.714,1.304-0.714c1.05,0,4.547,1.575,5.072,3.848c0.525,2.274,2.099,1.05,2.972,0
        c0.874-1.049,0-3.673,1.401-4.197c1.399-0.525,2.448,0.349,2.973-0.7c0.525-1.051,2.099,0.175,2.974,1.749
        c0.874,1.575,3.324,0.7,5.422,0.874c2.099,0.175,2.623-0.525,2.972-2.274s3.849-1.224,6.472-1.749s4.198-0.525,4.547-2.721
        s0.349-0.427,2.099,0.796c1.749,1.224,2.273,1.224,3.131,0.349c0.858-0.874,2.115,1.225,2.431,3.324s3.183,0,3.883-0.874
        s0-2.098-1.4-2.449c-1.4-0.349,0.175-2.797,0.35-4.197c0.175-1.4-2.449-2.274-2.799-4.547c-0.349-2.274,2.099-0.35,2.799-3.149
        s2.973-1.749,4.967-1.925c1.995-0.175,1.33-3.323,0.805-5.424c-0.524-2.102,3.148,0.351,4.372,0.161
        c1.224-0.192,1.575-0.333,1.575-2.24c0-0.756,0.192-1.544,0.424-2.225c-0.128-0.137-0.222-0.274-0.249-0.417
        c-0.175-0.874-2.975-3.323-4.101-4.369c-1.127-1.045-4.469-1.578-4.643-3.151c-0.175-1.575-2.623-3.325-4.896-4.023
        c-2.274-0.699-3.673-2.273-3.515-4.198c0.159-1.924-2.782-3.149-3.832-4.896c-1.049-1.749,0.699-2.449,0.699-5.073
        c0-2.623-2.974-2.623-4.023-4.372c-1.049-1.749-1.575-4.023,0-2.799s2.798,0.874,2.973,0s4.023-1.224,4.182-1.629
        c0.159-0.406,3.515-0.994,4.564-0.119c1.05,0.874,3.149-3.324,4.547-3.673c1.4-0.349,5.072-2.623,5.947-3.848
        c0.874-1.224,5.248,0.7,7.135,0.7c1.889,0,1.435-2.624,0.289-4.198c-1.146-1.575,1.322-3.498,3.77-3.498s1.049,4.722,2.274,5.073
        c1.224,0.35,3.323-2.098,3.323-4.372c0-1.289,0.842-1.959,1.573-2.298C458.782,379.17,460.176,375.966,459.245,373.97z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_34_" x="366.22" y="361.61" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_THEUNITEDKINGDOM" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="United Kingdom" data-wikipediaLink="https://en.wikipedia.org/wiki/United_Kingdom" data-infoText="<b>United Kingdom</b> &amp;nbsp; Capital: London" data-imageFile="UK.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M153.212,194.104c0,0-1.865,0.816-2.449,2.215c-0.583,1.4-1.165,1.749-2.099,2.1c-0.932,0.349-2.825-0.233-2.928,1.4
        c-0.103,1.631,0.83,2.798,0.83,2.798s-1.399,1.05-2.099,0c-0.699-1.05-1.282-2.565-1.983-1.399c-0.699,1.167-1.983,1.631-1.4,3.031
        c0.583,1.399,1.866,1.866,1.516,2.449c-0.349,0.584-3.678,1.4-1.664,1.983s3.879-0.233,2.946,0.7s-3.848,3.265-2.682,4.198
        s1.516,3.149,2.915,1.632c1.4-1.516,3.964-3.381,3.265-4.897s1.166-0.816,2.682-1.4s1.982-0.117,1.516-1.983
        c-0.467-1.865-0.117-3.382,1.631-3.964c1.749-0.584,1.749-1.984,1.166-2.215c-0.584-0.234-2.099-1.983-0.467-3.381
        C155.545,195.97,155.074,192.778,153.212,194.104z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M139.219,215.728c0,0-5.363,3.449-3.614,4.031c1.749,0.583,1.865,1.632,1.631,2.449c-0.234,0.816-0.816,1.632,0,2.449
        c0.817,0.817,0.699,1.05,0,3.031c-0.699,1.983-1.05,3.849,0,3.849s2.449-0.467,1.983-2.449c-0.467-1.983,0.117-3.031,0.584-3.849
        c0.467-0.816-0.584-0.7-0.467-2.449c0.117-1.749,0.35-1.866,1.517-3.498C142.017,217.659,140.839,214.593,139.219,215.728z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M135.255,233.519c0,0-1.865,1.749-0.699,2.215c1.167,0.467,2.565-0.467,2.916-1.108
        C137.819,233.984,136.13,232.435,135.255,233.519z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M149.131,242.963c0,0-1.983,1.167-2.333,2.215c-0.349,1.05-1.4,1.282-2.565,1.866c-1.165,0.583-2.681,2.278-1.05,2.247
        c1.632-0.031,2.565-1.081,3.614-1.664C147.848,247.044,152.629,242.963,149.131,242.963z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M160.559,255.906c0,0-1.983,1.983-2.214,3.265c-0.234,1.282-1.167,1.516-1.866,1.983c-0.699,0.467-2.098,0.467-3.149,1.05
        c-1.05,0.584-1.399,0.7-1.865,2.332s-0.349,3.498,1.05,3.732s1.399,1.983,1.399,1.983s2.098,1.399,2.798-2.682
        c0.699-4.081,1.4-4.43,2.332-5.363C159.975,261.27,162.657,256.84,160.559,255.906z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M180.498,299.984c0,0-1.983,2.798-2.798,4.431c-0.816,1.631-3.149,1.167-3.031,3.265c0.117,2.098,0.467,3.382,2.215,2.332
        c1.749-1.05,1.399-3.241,3.614-4.827C182.713,303.598,181.214,298.942,180.498,299.984z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M197.668,175.098c0,0-1.924,1.749-1.836,3.498c0.087,1.749-0.702,2.623,0.916,3.498c1.619,0.874,1.706,0.087,2.756,0.874
        c1.051,0.787,3.061,0.612,3.236,0c0.175-0.611-1.487-0.874-2.1-2.187c-0.612-1.312-1.051-3.76-0.087-4.285
        C201.515,175.973,198.897,173.582,197.668,175.098z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M194.17,182.62c-0.467,0.841-0.349,2.099,0.7,2.885c1.051,0.787,3.236,1.662,2.711,0
        C197.055,183.844,194.606,181.833,194.17,182.62z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M199.679,185.243c0,0-0.874,0.524,0,1.575c0.875,1.05,2.974,0,2.361-0.787C201.428,185.243,200.642,184.805,199.679,185.243z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M223.904,133.907c0,0-4.547,2.536-2.972,4.111c1.575,1.575,4.372,1.749,2.972,2.975c-1.4,1.224-2.149,0.612-3.305,1.749
        c-1.155,1.137-0.893,2.536,1.032,3.149c1.924,0.612,4.634-0.175,3.323,2.186c-1.312,2.361-1.486,4.635-1.05,6.384
        s1.128,1.575,1.395,0c0.268-1.575,0.791-4.111,1.579-5.248c0.787-1.137,0.787-0.874,0-2.975c-0.787-2.098-0.436-2.098,1.138-3.498
        c1.575-1.4,1.05-2.187-0.525-3.324C225.916,138.28,225.477,132.855,223.904,133.907z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M231.775,126.91c0,0-2.297,2.711-2.811,3.586c-0.512,0.874-2.961,1.48-1.825,3.276c1.138,1.796,0.787,3.458,1.051,3.984
        c0.262,0.524,1.924-0.699,2.274-2.098c0.349-1.4,0.611-2.281,1.312-1.883c0.699,0.397,1.05,0.696,0.874-1.376
        C232.474,130.321,233.786,126.211,231.775,126.91z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M193.908,190.957c0,0,1.749-0.467,1.749-1.05s0.583-1.165,2.098,0c1.517,1.167,2.682,2.332,1.4,3.382
        c-1.282,1.05-0.7,2.215-0.116,2.682s-0.234,1.282-1.4,2.449c-1.165,1.165-3.497,3.148-4.314,4.314
        c-0.817,1.167-1.632,1.516-3.265,2.567c-1.631,1.05-3.148,3.031-4.314,4.081c-1.167,1.05-2.916,0.933-2.215,2.215
        c0.7,1.282,2.332,1.4,3.031,1.4s1.517-1.749,2.216-0.932c0.7,0.816-0.116,2.448-1.749,2.915c-1.632,0.467-2.798,0.583-2.798,0.583
        s-4.431,1.516-2.567,1.866c1.866,0.349,4.431-1.167,3.964,0s-1.983,0.816-1.282,1.866c0.7,1.05,2.098-0.117,2.449,0
        c0.349,0.116,2.424-1.051,3.66-2.215c1.237-1.167,1.936-0.933,4.152-1.749c2.215-0.816,2.215-0.35,3.266,0.816
        c1.05,1.167,1.749,0.816,3.148,0.233c1.4-0.583,1.983-1.749,5.131-0.583c3.149,1.167,3.382,0.583,5.364,0.467
        s3.614-0.699,4.081,0.816c0.468,1.516,1.75,1.117,1.983,2.657c0.234,1.54-1.631,3.522-2.565,4.573
        c-0.933,1.05-0.933,1.167-0.933,4.896c0,3.732,0,2.565-1.4,3.849c-1.4,1.282-0.933,4.316-1.05,5.48
        c-0.116,1.167-1.632,0.233-2.682,2.332s-2.798,5.947-4.081,6.997c-1.282,1.05-2.448,0.117-3.615,0.584s-3.031,0.467-3.964,2.215
        c-0.933,1.749,0.349,2.798,1.165,1.631c0.816-1.165,1.983-3.265,3.382-2.1c1.399,1.167-0.35,1.918,1.05,2.591
        c1.4,0.673,2.915,1.257,2.682,2.307c-0.234,1.05-2.449,1.632-4.431,1.399c-1.983-0.234-2.332,0.932-3.382,1.866
        c-1.05,0.932-1.982,2.215-3.963,1.631c-1.983-0.583-2.099,1.05-0.583,1.05s4.197,0.233,5.014,0.7c0.816,0.467,1.865,1.167,3.265,0
        c1.4-1.165,2.565-3.265,3.732-1.865c1.167,1.4,2.449,1.393,5.131,2.504c2.682,1.11,2.916-0.172,3.848,2.86
        c0.933,3.031,2.567,3.031,4.082,4.547c1.516,1.516,1.749,0.349,1.866,4.547c0.116,4.198-0.35,5.48,0.7,7.112
        c1.05,1.631,0.7,0.7,2.799,5.363c2.098,4.665-0.116,6.297,2.798,8.162c2.916,1.865,4.314,0.233,5.48,2.449
        c1.167,2.214,2.916,0.233,3.848,2.682s1.866,4.314,3.614,5.947c1.749,1.631,3.732,1.4,2.799,2.565
        c-0.933,1.167-3.031,0.817-1.632,3.033c1.4,2.215,3.498,4.314,4.082,6.181c0.583,1.866-0.583,2.566-2.215,0.933
        c-1.632-1.632-3.849-3.265-4.082-1.166c-0.234,2.099,0.35,2.099,2.333,3.265c1.983,1.167,5.131,3.148,5.947,5.363
        s3.732,4.431,2.215,5.831c-1.516,1.4-4.781,2.798-4.665,5.131c0.116,2.332,2.332,2.098,2.798,2.915
        c0.467,0.817,1.56,0.583,2.295-1.282c0.737-1.865,1.203-3.031,2.253-3.031s2.798-1.05,6.063,0.35
        c3.265,1.4,6.181-0.233,6.879,2.449c0.699,2.681,2.565-2.332,3.265,3.148c0.699,5.48,2.215,6.647,0.699,9.445
        c-1.516,2.799-0.932,5.014-2.332,6.531s-2.215,3.149-4.431,2.215c-2.215-0.933-2.565,0.233-1.983,0.932
        c0.584,0.7,2.215,2.333,1.049,2.917c-1.167,0.583-3.848,0.116-4.082-0.584c-0.233-0.7-0.466-1.104-1.282,0.847
        s1.516,1.018,1.516,2.534s-4.081,1.983-4.547,3.031c-0.467,1.049-1.516,1.399,0.7,2.215c2.215,0.816-0.7,0.933,3.265,1.282
        c3.964,0.349,7.346-1.865,7.578-0.699c0.233,1.167-0.467,2.565-0.583,4.665c-0.116,2.098-1.983,2.332-3.031,2.682
        c-1.051,0.349-1.517,0.816-1.983,2.214c-0.466,1.4-2.215,2.1-2.565,1.4c-0.35-0.699-1.516-1.783-2.215,0.273
        c-0.7,2.059,0.234,2.992-1.632,2.525s-2.916-1.166-3.265-0.349c-0.35,0.816-0.584,3.265-2.449,2.215
        c-1.865-1.05-3.732-2.332-4.665-1.983s-2.567-0.583-3.732,0.233s-1.05,1.983-3.382,1.05s-2.449,1.983-4.197,0.233
        c-1.749-1.749-5.639-3.031-5.035-1.516c0.603,1.516,2.003,0.515,2.469,2.24c0.467,1.725-0.509,4.64-2.469,3.008
        c-1.96-1.632-1.146-3.848-2.428-3.848c-1.281,0-1.165,0.467-2.449,0.816c-1.281,0.349-3.848-0.349-4.665-0.583
        c-0.816-0.233-1.865-1.049-1.282,0.583c0.584,1.633,1.749,4.343-0.816,3.512c-2.565-0.831-4.197-2.812-4.547-1.18
        c-0.349,1.632-0.234,3.368-1.983,1.334c-1.749-2.033-1.516-3.2-3.381-3.317c-1.865-0.116-2.681-1.282-4.896,0
        c-2.215,1.283-3.732,0.932-4.781,2.215c-1.05,1.282-1.632,1.631-1.167,3.148c0.467,1.516,0.349,2.566-0.584,3.032
        c-0.933,0.467-1.165,2.098-1.165,2.565s-2.916,1.4-4.198,0c-1.283-1.4-3.849-3.848-4.198-3.498s-2.916,0.467-4.431,0.467
        c-1.516,0-2.799-0.233-3.381,1.049c-0.583,1.282,1.399,2.411-2.099,2.721c-3.498,0.311-2.798,1.943-2.916,2.876
        c-0.116,0.933-0.816,4.082-2.682,1.866c-1.865-2.215-4.198-3.732-5.363-2.682c-1.167,1.049-3.149-2.214-1.983-2.214
        c1.167,0,1.632,1.049,3.615-0.235c1.982-1.282,5.248-1.749,5.363-3.731c0.117-1.983,1.983-4.082,3.498-3.732
        c1.516,0.349,0.584-0.698,1.983-2.099c1.4-1.4,3.264-1.983,3.732-3.148c0.467-1.167-1.632-5.831,0.349-5.714
        c1.983,0.116,4.431,0.816,4.547-1.049c0.116-1.866-2.565-4.547,2.332-3.849c4.897,0.7,7.112,0.933,9.445,0.583
        c2.332-0.349,4.081,1.631,4.665,0.35c0.584-1.282-1.4-2.332,0.932-3.848c2.333-1.516,3.615-2.332,4.665-3.849
        c1.05-1.516,2.449-2.098,3.265-3.498s-0.116-0.933-0.933-0.35c-0.816,0.583-3.149,1.865-4.547,3.265
        c-1.4,1.4-0.933,0.583-3.149,1.282c-2.215,0.7-0.234,1.983-2.565,2.565c-2.332,0.584-4.081,1.865-5.831-0.584
        c-1.749-2.449-1.399-5.947-3.498-5.248c-2.099,0.7-3.265,3.964-4.314,2.682c-1.05-1.282,0.35-1.983-0.7-3.382
        c-1.05-1.4-1.749-2.565-2.798-1.982s-5.364,0.116-5.947,1.749c-0.583,1.631-2.798,3.265-3.382,2.214
        c-0.584-1.049-4.082-2.1-2.449-3.382c1.632-1.282,3.498-2.565,1.516-3.149c-1.983-0.583-3.614-1.983-2.215-2.449
        c1.4-0.467,6.064-1.05,6.764-1.865c0.7-0.816,1.167-2.215,3.149-2.215s3.148,0,4.547-1.633c1.4-1.632,2.915-4.081,3.265-5.712
        c0.349-1.633,1.516-2.449,0.116-2.682c-1.4-0.233-2.916-2.332-1.516-2.916c1.4-0.584,3.382-0.816,1.4-1.983
        c-1.983-1.166-2.449-3.149-2.449-3.732s-0.116-1.282-2.565,0.35c-2.449,1.632-5.248,3.614-5.014,2.098s1.282-2.682,2.798-3.848
        c1.516-1.167,4.082-2.682,3.031-3.498c-1.05-0.816-5.13-1.282-4.081-3.615c1.05-2.332,1.167-5.13,3.732-3.498
        c2.565,1.632,4.081,3.149,5.48,3.732s1.749-1.749,3.498-0.698c1.749,1.05,2.449,1.282,4.082,0.116
        c1.631-1.167,2.215-2.916,3.381-0.7c1.167,2.215,0.001-1.749,1.631-2.215c1.631-0.467,0.116-5.48,3.031-7.346
        c2.916-1.866-2.214-4.198-0.584-5.598c1.632-1.4,1.165-3.849,1.983-5.364c0.816-1.516,0.584-3.265-0.583-2.332
        c-1.167,0.932-3.031,1.4-3.031,2.798c0,1.399,0.116,3.498-1.282,2.449c-1.4-1.05-0.233-2.215-2.099-3.848s-3.149,0.232-3.149-3.615
        s-2.565-5.248-1.631-6.879c0.932-1.632,1.516-5.947,3.148-6.53c1.632-0.584,4.781-2.099,2.565-2.099s-5.831-1.167-5.831,0.349
        s1.516,2.565-0.349,2.798s-2.215,0.234-3.265,1.631c-1.05,1.4-1.282,2.449-2.565,1.282s-5.597-5.48-4.547-3.265
        s2.449,5.48,0.116,5.014s-3.382-3.964-5.131-3.964s-3.964-3.964-3.149,0s3.732,5.714,1.4,5.015c-2.332-0.7-2.215-2.1-3.031-3.732
        c-0.817-1.632-2.1-4.431-1.4-4.781c0.7-0.349,1.749,1.282,1.866,0.467c0.116-0.816-0.7-3.614,0.349-4.781s2.798-5.598,3.849-6.764
        c1.05-1.165,1.632-3.964,0-5.014s-3.5-1.983-3.149-3.614c0.349-1.632,0.933-4.782,1.4-4.782s2.682,0,1.749-1.632
        c-0.933-1.631-2.798-2.798-2.332-4.197c0.467-1.4-2.215-1.749-0.467,1.399c1.749,3.149-0.7,2.449-0.7,3.848
        c0,1.4-1.05,1.283-1.167,2.565c-0.116,1.282-1.516,1.631-1.749,0.933c-0.234-0.7-1.515-1.282-1.983-1.983
        c-0.467-0.698-1.983,0.583-1.517,2.215c0.468,1.632,2.099,1.282,3.149,2.565c1.05,1.282,3.615,2.798,2.332,4.431
        c-1.282,1.632-2.215,3.732-3.265,1.983s-2.682-4.081-2.332-4.547s-1.282-1.749-1.865,1.865c-0.584,3.614,1.399,4.547,0.932,5.597
        c-0.467,1.051-2.098,1.749-3.265,1.167c-1.167-0.583-2.1-1.983-1.05-4.547c1.05-2.565,1.632-4.314,2.098-5.831
        c0.467-1.516,0.584-0.467,0.233-2.798c-0.349-2.332-1.282-4.431,0.116-6.181c1.4-1.749,2.215-3.964,1.749-4.897
        s-2.449-1.865-0.933-3.498c1.516-1.632,2.215-1.167,2.215-2.799c0-1.631-0.933-2.332,0-2.916c0.933-0.583,3.031-2.333,3.149-2.916
        c0.116-0.583-0.583-1.4-2.098,0.349c-1.517,1.749-2.915,2.215-3.148,3.265c-0.234,1.05-1.866,0.467-1.983,2.215
        c-0.116,1.749,1.982,2.215-0.234,3.732s-4.316,0.583-5.598,1.282c-1.282,0.698-3.031,2.567-3.031,0.933
        c0-1.632,1.516-3.148,2.098-3.964c0.583-0.816,1.05-1.4-0.349-2.332c-1.4-0.933-3.382-2.916-1.749-3.614
        c1.631-0.7,2.798,0.116,2.332-1.051s1.516-3.148,2.565-3.031c1.05,0.116,2.449,0.816,2.1-0.35
        c-0.349-1.167-3.149-1.516-1.632-2.682c1.516-1.167,2.682,0.349,2.798-1.4c0.117-1.749-1.399-4.665-2.332-3.149
        s-3.615,1.516-2.916,0s2.798-3.265,0-3.265s-4.198,0.816-4.198-0.698c0-1.517-0.933-4.316-1.865-3.849
        c-0.933,0.467-3.848,0.35-3.615-1.165c0.234-1.516,1.05-2.682,2.449-3.149c1.4-0.467,2.565,1.737,3.031,0.752
        s-1.749-8.214,1.4-4.367c3.148,3.848,2.682,4.665,3.265,6.064c0.584,1.4,0.699,0.816,1.4,1.167c0.699,0.35-0.816,2.449,1.4,2.916
        c2.214,0.467,2.797-0.7,1.631-2.215c-1.165-1.516-3.964-4.081-2.332-4.431c1.631-0.349,2.098,0.235,2.682-0.583
        c0.584-0.816-0.233-1.4-0.467-3.148c-0.234-1.749-0.467-2.215,0.584-3.033c1.05-0.816-1.051-2.565,0.116-2.916
        c1.167-0.349,1.05-1.05,3.149-0.583c2.098,0.467,2.682,1.282,3.149,1.05c0.467-0.234,1.631-0.7,2.332,0.35
        c0.7,1.05,2.449,0,0.467-1.05s-3.615-3.732-4.897-4.314s0.116-2.215,0.116-2.215s2.449,0.467,2.098-0.467
        c-0.349-0.933-2.798-3.149-0.932-3.149s2.567-2.448,3.149-3.964c0.583-1.516,0.232-2.798,1.516-4.082
        c1.282-1.283,2.099-2.449,3.031-1.05c0.933,1.399,1.866,2.215,3.031,1.865c1.167-0.349,1.632-2.099,2.333-0.816
        c0.7,1.282,0.7,1.516,2.332,0.933c1.631-0.584,4.781-0.933,7.229-1.167C191.458,191.773,191.925,188.508,193.908,190.957z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M163.123,294.037c-2.449,0.116-2.916,1.05-3.848,1.05c-0.933,0-1.282-2.215,0.349-2.332c1.632-0.116,2.733-0.583,1.715-1.865
        c-1.017-1.283-5.027-3.615-5.062-5.831c-0.035-2.215-0.501-6.063-2.716-5.48s-4.665,0.583-5.83,0.932
        c-1.166,0.35-1.866,1.516-3.032,1.983c-1.165,0.467-1.282,0.933-2.332,1.983s-2.099,1.865-2.567,0.467c0,0-0.408-0.059-1.4,0.291
        c-0.992,0.35-1.575,0.874-1.575,1.69s-0.059,1.575-0.642,2.215c-0.584,0.642-0.408,1.341-0.992,1.983
        c-0.583,0.642-0.29,1.399-1.4,1.631c-1.108,0.233-2.623,0.525-3.207,0.758c-0.583,0.233-1.749,0.525-0.816,0.933
        c0.932,0.408,3.556,0.642,2.449,1.575c-1.108,0.933-1.881,2.508-3.186,2.565c-1.304,0.059-3.986,0.525-3.52,1.691
        c0.467,1.165,2.1,1.108,2.858,2.915c0.757,1.808,1.808,2.215,2.623,2.215c0.816,0,0.467-0.583,1.165,0.758s2.041,1.516,2.681,1.516
        c0.642,0,1.575,1.4,2.623,0.409c1.05-0.992,1.575-1.808,2.1-1.983c0.524-0.175-0.584-1.05-0.291-2.391
        c0.291-1.341,0.467-1.457,1.69-2.1c1.224-0.642,2.041,0.233,2.741,0.992c0.699,0.759,0.524,2.448,1.341,3.09
        c0.816,0.642,2.332-0.874,2.274,1.225c-0.059,2.098-0.525,4.013,1.108,3.463c1.632-0.549,2.565-1.132,4.082-1.714
        c1.516-0.583,2.157,0.234,2.332,0.7c0.095,0.253,0.531,0.783,1.346,1.354c1.076-0.718,1.641-1.681,2.268-2.579
        c0.817-1.165,0.233-3.274,1.866-3.036c1.633,0.238,3.266-1.161,3.382-2.094c0.116-0.933-0.583-1.05-1.283-1.4
        c-0.698-0.349-2.399-1.282-1.083-2.798c1.316-1.516,2.598,2.098,3.298,0C165.338,296.719,165.572,293.92,163.123,294.037z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_35_" x="169.74" y="269.704" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>

        <rect id="UNANSWERED_1_" x="195.055" y="300.435" opacity="0" fill="#EB35FF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="24.691" height="20.581" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_ICELAND" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Iceland" data-wikipediaLink="https://en.wikipedia.org/wiki/Iceland" data-infoText="<b>Iceland</b> &amp;nbsp; Capital: Reykjavik" data-imageFile="ICELAND.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="ICELAND" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M77.627,88.768c0,0-1.4,5.598,0,5.83c1.4,0.234,2.799,1.865,3.033,3.031c0.232,1.167,2.098-1.4,3.265-2.565
        c1.165-1.167,4.198-2.798,4.198-2.798s1.865,1.399,0,2.565c-1.865,1.167-5.856,1.866-5.143,4.665
        c0.713,2.798,0.713,3.498,1.646,4.665c0.933,1.167,2.799,2.1,2.333,3.498c-0.467,1.4-3.291,2.565-3.978,3.964
        c-0.686,1.4-4.496,2.098-5.972,2.332c-1.478,0.233-3.343,0.699-3.343,2.332c0,1.632-0.933,5.597,2.098,4.431
        c3.031-1.167,4.431-2.333,7.696-2.1c3.265,0.234,2.825,1.4,5.494,0c2.67-1.4,4.069-0.467,5.468-2.332
        c1.4-1.866,1.865-1.166,2.333,0.933c0.467,2.098,0.698,2.755,3.265,3.709c2.565,0.955,2.098-0.212,4.198,2.587
        c2.1,2.798,6.763,4.897,10.961,5.364s3.498,1.866,11.195,3.265c7.696,1.4,9.329,1.167,13.061-1.633
        c3.732-2.798,3.732-8.628,7.229-8.862c3.498-0.233,4.433,0.467,8.863-1.633c4.43-2.098,7.463-3.964,10.494-6.53
        c3.031-2.565,5.13-7.463,8.628-7.463s4.897-1.631,4.897-2.798c0-1.165,0-0.467,2.798,0.933c2.799,1.4,2.333,2.333,4.431-0.7
        c2.098-3.031,5.131-0.698,5.598-4.664c0.467-3.966-0.932-6.064,0.933-7.93c1.865-1.865,3.498-2.332,5.597-2.098
        s3.732-2.798,3.265-4.897c-0.467-2.098-1.631-4.897-3.265-5.131c-1.631-0.234-3.265-3.265-0.699-3.031
        c2.565,0.232,4.431,5.83,6.063,3.964c1.633-1.865,1.167-5.131,0-6.296c-1.166-1.167-4.897-3.498-2.798-4.897
        c2.098-1.4,4.431-7.93,2.798-7.93c-1.631,0-8.279-3.498-9.212-3.614c-0.933-0.116-2.916-1.516-2.214-3.149
        c0.699-1.633-0.233-1.749-1.517-0.816c-1.282,0.933-5.714,4.198-4.314,1.516c1.4-2.682,4.431-4.547,3.265-7.696
        s-6.413-1.282-6.88-3.849c-0.467-2.565,0.816-4.314,1.631-5.014c0.817-0.7-2.214-1.866,0.584-3.615s6.763-2.449,4.314-2.682
        s-5.014-2.916-5.947-0.583c-0.933,2.332-1.05,3.732-2.449,4.897c-1.4,1.167-0.467,3.031-1.4,3.382
        c-0.933,0.349-6.88-3.149-6.647-4.431c0.234-1.282,2.332-2.1,0.35-3.498c-1.983-1.399-2.682-1.516-4.081-3.964
        c-1.4-2.449-3.498-2.449-4.665-1.282c-1.167,1.165-3.498-0.933-2.682,2.449s2.565,5.364,1.4,7.696
        c-1.167,2.332,0.583,2.915-1.749,3.148c-2.332,0.233-2.215,2.449-3.498,1.516c-1.282-0.932-2.098-2.798-3.031-2.681
        c-0.933,0.116-4.316-0.001-4.781,1.983c-0.467,1.983-1.282,4.665-2.449,6.063c-1.167,1.4-0.234,1.749-2.798-1.399
        c-2.565-3.149-5.248-5.598-7.229-5.015s-4.43,1.749-2.1,3.849c2.332,2.098,3.732,3.498,4.082,6.88
        c0.349,3.382,0.922,9.212-0.822,6.063c-1.744-3.149-2.792-8.863-4.192-10.145s-1.865-3.031-1.865-4.896
        c0-1.866-1.749-5.014-4.665-3.382s-0.35,3.732-3.148,4.314c-2.799,0.583-4.198-0.816-4.897,1.4c-0.7,2.215-2.565,2.682-0.816,4.082
        s2.098,5.714,0.699,6.179c-1.4,0.467-3.732-0.816-4.314-3.265c-0.583-2.449-1.399-0.116-2.798-4.547s-2.449-6.647-4.198-5.48
        s-4.316,1.516-3.265,4.431c1.05,2.916,2.916,2.798,2.215,7.112c-0.7,4.316-0.234,8.98-1.633,9.212
        c-1.4,0.234-2.565-1.516-3.031-3.498c-0.467-1.982-2.1-2.449-2.916-0.583c-0.816,1.865-3.732,7.463-2.916,8.978
        c0.816,1.516-1.167,1.282-1.167,3.265s0.233,6.88-0.467,2.916c-0.7-3.964-0.467-6.53-1.165-7.813
        c-0.7-1.282-2.215,0.117-2.215-1.282s2.1-3.732,0.7-4.081s-1.165-0.467-3.031-1.633c-1.866-1.167-3.849-0.116-2.916-1.983
        c0.933-1.866,1.631,0,3.498-0.7c1.865-0.7,0.933-1.167,1.983-2.916s1.865-2.565,0.7-4.43c-1.167-1.865-2.682-0.467-3.031-2.798
        c-0.35-2.33-2.798-1.746-3.381-2.795c-0.584-1.05,1.516-4.314-1.167-4.781c-2.685-0.471-5.017-2.453-5.95-2.686
        c-0.933-0.232-1.866-6.997-4.198-6.413c-2.332,0.583-1.631,0.698-4.314,1.165c-2.681,0.467-3.614-0.7-3.848,1.633
        c-0.233,2.333,1.516,1.866,4.314,2.333c2.799,0.467,4.431,1.05,4.665,2.449c0.233,1.4-2.916,0.116-4.665,0.583
        s-3.964,1.516-0.7,2.682c3.265,1.165,3.964,0.116,5.131,2.1c1.167,1.983,1.167,4.314,0.467,5.248s-0.932,0.7-2.681-1.4
        c-1.749-2.1-3.031-0.816-3.732-0.816c-0.7,0-3.615-2.1-4.897-4.081c-1.282-1.983-3.615-3.148-4.431-1.516
        c-0.816,1.631-1.282,2.215,0,3.149c1.282,0.932,2.332,3.732,0.349,2.798c-1.983-0.933-6.997-2.682-5.248-0.7
        s3.382,2.332,3.265,3.381c-0.116,1.049-3.963-0.584-3.03,1.749c0.933,2.333,0.467,2.449,2.449,3.732
        c1.982,1.283,4.082,1.05,4.198,1.983c0.116,0.933,2.1,2.565-0.116,2.682c-2.215,0.117-2.682-0.933-5.131-2.449
        s-4.314-4.431-4.547-2.682s1.05,4.198,1.749,4.547c0.699,0.349-0.698,1.399-0.816,1.983c-0.116,0.584-1.749-1.165-3.149-0.583
        c-1.399,0.583-2.682,1.282-3.265,2.799c-0.583,1.516-1.983,2.682,1.983,2.682s4.548-0.933,5.48,0.7
        c0.933,1.631-0.467,3.498,2.916,1.631c3.382-1.865,1.983-3.615,5.598-3.382c3.614,0.234,2.332-1.282,4.896-2.449
        c2.565-1.165,2.333-2.098,3.149-0.234c0.816,1.866,0.816-0.234,3.031,1.167c2.215,1.4,2.215,3.265,4.547,3.031
        s1.749-1.983,3.265-0.467s4.081,0.816,1.167,2.332c-2.916,1.516-5.131,2.332-5.947,4.314c-0.816,1.983-2.798,3.265,0.117,3.964
        c2.915,0.7,3.614,1.866,5.131,1.05c1.516-0.817,2.332-3.382,3.031-1.517c0.7,1.866,0.933,3.498-0.933,4.198
        c-1.865,0.7-4.081,1.128-7.578,0.915c-3.498-0.215-0.349,1.069-3.732,1.418c-3.382,0.349-5.364-1.631-5.714-0.467
        c-0.349,1.167,0,2.449-2.098,2.098c-2.1-0.349-3.733-2.215-4.316-0.7s0,2.332-2.565,1.866c-2.565-0.467-6.181,0.117-5.598,1.4
        s2.098,7.112,4.897,5.014c2.798-2.1,1.983-3.848,4.198-3.149c2.215,0.7,3.964,1.749,5.831,0.584
        c1.865-1.167,3.964-1.632,5.014-0.234C74.712,88.419,77.045,86.785,77.627,88.768z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_1_" x="113.263" y="72.459" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_SWEDEN" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Sweden" data-wikipediaLink="https://en.wikipedia.org/wiki/Sweden" data-infoText="<b>Sweden</b> &amp;nbsp; Capital: Stockholm" data-imageFile="SWEDEN.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="SWEDEN" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M586.255-56.481c0.551-3.848,1.898-5.597-0.009-8.396c-1.908-2.798,0.158-4.547,0-6.647c-0.158-2.098-0.892-4.198-2.641-7.347
        c-1.75-3.148,1.4-5.947,2.449-10.145c1.049-4.198-2.449,0-4.547-4.198c-2.1-4.198,0.349-5.598-0.349-7.346
        c-0.7-1.749,1.05-7.696,2.1-8.745c1.049-1.05,2.449-5.248-1.75-8.045c-4.198-2.798-4.547-3.848-6.297-8.745
        c-1.749-4.897-9.795-4.897-13.642-5.248c-3.848-0.349-3.149,0.699-4.198-3.848c-1.051-4.547-4.897-4.198-10.666-9.796
        c-5.769-5.597-1.927-2.798-4.054-5.947c-0.762-1.128-1.494-2.481-2.121-3.753c-0.426,0.066-0.93,0.256-1.907,0.605
        c-2.94,1.05-8.536-2.449-5.737,1.4c2.798,3.848,3.848,10.494,1.05,13.643c-2.798,3.148-6.296,4.547-3.498,6.997
        c2.798,2.449,0,7.347-3.149,6.647c-3.149-0.699-10.145-6.997-12.943-5.947c-2.798,1.05-6.296-5.598-8.045,1.05
        c-1.749,6.647-3.498,9.795-4.897,13.992c-1.399,4.198-3.149,5.248-6.647,4.198c-3.498-1.05-8.047-4.841-9.795,0.379
        c-1.749,5.218-4.547,13.964-5.947,16.413c-1.399,2.449-0.7,11.195,0,15.392c0.699,4.198,1.049,6.296-3.148,9.795
        c-4.198,3.498-12.593,11.894-11.544,17.142c1.051,5.248,3.849,7.696-2.1,9.445c-5.947,1.749-8.745-2.798-9.444,3.149
        s2.098,10.494,0,16.092c-2.099,5.598-2.099,8.409-5.248,16.973s-4.897,11.012-5.598,15.211c-0.7,4.198-2.799,2.798,0,9.096
        c2.799,6.296,7.347,13.293,1.4,13.992c-5.947,0.7-6.647-3.149-11.545,0c-4.897,3.145-13.295,6.643-13.644,11.89
        c-0.349,5.248,0,9.795,0,13.643s2.798,4.198,1.4,8.746c-1.4,4.547-4.51,6.296-1.555,12.243c2.953,5.947-0.194,13.293,0.504,19.24
        c0.699,5.947,1.051,8.745,4.198,11.544c3.148,2.798,6.996,4.198,4.896,8.745c-2.098,4.547-7.347,2.098-6.998,8.396
        c0.35,6.296,4.515,10.494,4.357,13.643c-0.159,3.148-3.982,15.742-8.192,15.742c-4.209,0-4.072,7.696-3.266,9.095
        c0.806,1.4,1.331,2.449-0.856,4.547c-2.186,2.098,0.787,5.961,0,10.676c-0.787,4.716-2.886,6.929-4.285,4.773
        c-0.671-1.033-2.79-2.288-4.968-2.644c0.068,0.443,0.231,0.888,0.536,1.304c1.282,1.749,0.933,7.347,1.05,9.329
        c0.117,1.983,0.117,4.547,1.4,4.547c1.282,0,2.215,1.516,2.215,3.382s1.516,0.816,3.615,0.234c2.098-0.583,1.983,2.333,1.983,3.732
        c0,1.4-1.983,4.082-2.682,6.531s0,2.098,1.282,3.265c1.282,1.165,1.051,2.915,1.051,6.414c0,3.498,1.281,2.798,2.798,2.916
        c1.516,0.117,1.282,1.4,1.516,3.964c0.233,2.565,1.865,6.296,3.031,9.679c1.167,3.382,2.332,2.682,4.431,5.598
        s2.916,2.565,3.964,4.081c1.05,1.516-2.799,1.632-3.964,1.051c-1.165-0.583,0.117,2.565,1.75,4.198
        c1.632,1.632-0.467,2.682-0.816,2.565c-0.35-0.116-2.799-1.631-2.565-0.233c0.233,1.4,5.947,12.01,7.578,13.293
        c1.632,1.282-0.117,2.916-1.4,5.014c-1.282,2.099-0.467,2.916,1.749,4.198c2.215,1.282,3.732,1.749,6.763,0.349
        c3.031-1.399,3.149-1.165,5.597-0.816c2.449,0.349,3.382-0.234,4.665-0.583c1.282-0.349,0.467-3.964-1.4-5.947
        c-1.865-1.983,0.35-3.732,1.749-6.764c1.4-3.031,1.633-2.332,4.316-2.449c2.682-0.116,1.167-0.816,1.167-2.449
        c0-1.632,1.165-0.816,4.082,0c2.915,0.816,4.081,0,6.296-1.749s3.614,1.282,6.413,1.866c2.798,0.584,1.866-1.866,2.1-3.964
        c0.233-2.1,2.215-5.598,2.449-7.114c0.233-1.516,3.964-7.696,3.732-9.679c-0.233-1.983,0.7-2.1,1.983-3.031
        c1.282-0.932-0.117-2.215-1.051-4.547c-0.932-2.332,0.233-2.798,2.565-6.064c2.332-3.265,0.235-1.05-0.932-4.081
        s0.467-3.964-0.583-5.83c-1.051-1.866-0.117-1.516,1.749-2.215c1.865-0.7-0.235-1.516-0.934-3.732
        c-0.7-2.215,0.35-2.333,1.749-3.149c1.4-0.816,0-2.682-0.583-3.265c-0.584-0.583-1.4-4.665-0.233-4.547
        c1.167,0.116,1.865,0.349,2.565-1.866c0.699-2.215-0.699-2.332-1.399-3.498c-0.7-1.165,1.634-0.583,3.498-0.933
        c1.865-0.35,1.167-0.467,1.749-1.983c0.584-1.516,1.517-1.167,2.682-1.632c1.167-0.467,2.799-2.099,3.732-3.964
        c0.933-1.865,1.749-1.631,2.566,1.05c0.816,2.682,1.281,0.816,3.265-2.449c1.983-3.265,2.333-0.584,4.782-3.149
        s0.932-1.631,2.915-3.498c1.982-1.865,3.265-2.567,1.282-4.082c-1.983-1.516-2.332,0.35-4.197-0.467
        c-1.866-0.816-3.266,1.516-4.547,2.682c-1.282,1.166-3.733,0.584-4.665-0.233c-0.933-0.816,0.349-4.431-3.265-0.116
        c-3.615,4.314-0.583-1.516-2.449-1.866c-1.866-0.349-4.314-1.983-5.712-2.449c-1.4-0.467-3.382,0.349-6.88-0.116
        c-3.497-0.467-0.467-2.449,0.583-3.265c1.051-0.816,0.467-1.05,1.75,0c1.282,1.05,3.149,0.233,3.963-0.467
        c0.816-0.699,3.149,0.116,7.696,2.099c4.547,1.983,1.866-2.449,2.334-5.131c0.467-2.682,1.4,0.584,3.031,1.516
        c1.631,0.933,0.816,4.081,0.698,5.48c-0.116,1.399,1.983,1.516,3.849,0.349s1.631-3.381,4.897-4.198
        c3.265-0.816,4.547-2.1,5.014-3.849s1.282-1.749,3.149-1.983s0.583-2.567-0.584-4.198s-0.932-0.933-1.165-3.265
        s0.117-3.381-0.698-6.181c-0.816-2.798-3.5,1.282-4.316,1.05c-0.816-0.234-0.232-1.749,0.35-3.849
        c0.583-2.098-0.933-4.665-1.634-2.098c-0.699,2.567-2.332,1.749-4.547-1.633s-0.235-3.848-2.449-1.865
        c-2.214,1.983-2.798,0.816-3.964-1.399c-1.165-2.215-3.031-2.215-5.014-2.682s-0.584-1.165,0.233-3.265
        c0.816-2.098-0.7-4.081-1.865-6.063c-1.167-1.983-0.584-5.363,0.349-9.212c0.933-3.848,0.35-3.964-0.467-5.598
        c-0.817-1.633,0-2.449,0.467-5.014s2.916,1.05,4.198-1.05c1.282-2.098-0.816-2.098-1.866-4.431c-1.05-2.332,0.35-6.181,2.566-8.745
        c2.215-2.565,0.932-3.148-0.584-4.431c-1.516-1.282-5.015,0.816-5.131-2.1c-0.117-2.916,2.916-4.547,4.431-1.865
        c1.516,2.682,1.051-1.516,3.614-1.749c2.565-0.234,2.916-0.349,2.916-2.916c0-2.565,1.516-2.682,2.332-3.732
        s-0.932-1.4-1.865-2.915c-0.933-1.516-1.749-2.216-1.749-4.665s0.466-0.583,2.332,1.866c1.865,2.449,2.215,2.098,2.332,1.282
        c0.116-0.817,1.866-1.282,3.498-1.282c1.631,0,1.866-1.983,1.399-3.964c-0.466-1.983-0.583-1.167-0.583-2.565
        c0-1.399,0.817-2.565,1.866-3.149c1.049-0.583,3.149-1.167,3.498-2.682s2.215,1.05,3.148,1.866s1.282-2.332,1.282-3.964
        c0-1.633,0.699-2.916,1.866-3.031c1.167-0.115,1.165-1.4,1.865-3.382c0.699-1.983,0.816-0.234,2.098,1.165
        c1.282,1.4,2.449-0.467,3.498-3.498c1.049-3.031,1.749-1.749,6.181-2.332c4.431-0.583,3.265-1.516,4.34-4.665
        c1.075-3.148,3.59-4.198,4.757-5.363c1.165-1.167,2.448-3.031,2.681-6.647c0.234-3.614,1.749-5.014,4.665-6.53
        c2.916-1.516,2.565-3.148,3.498-4.081s-1.4-2.215-4.082-4.547c-2.682-2.333-2.682-2.449-1.516-3.964
        c1.167-1.516,0.7-3.265-0.233-4.781c-0.934-1.516,1.865-1.633,3.614-2.449s0.234-2.1,0.116-3.149
        c-0.115-1.05,0.467-2.798,2.682-5.363c2.216-2.565-0.7-3.149-1.167-5.48s4.431-2.215,6.763-3.265s2.916-3.148,4.198-3.498
        c1.282-0.349,0.117-3.615,0.933-7.229c0.816-3.615,3.265-2.1,5.714,0.233c2.449,2.332,1.983,1.399,3.381,1.399
        c1.4,0,1.283-1.516,5.363-3.148c4.082-1.631,4.082,3.614,4.781,1.631c0.7-1.983,2.332-1.983,5.248-1.631
        c1.275,0.153,1.971,0.171,2.457,0.008c-0.227-5.122-2.923-7.821-5.243-13.069C584.913-49.835,585.705-52.634,586.255-56.481z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M515.04,214.802c-0.377,0.449-1.05,1.05-0.874,2.1c0.175,1.05,0.578,0.874,0.419,1.924c-0.158,1.05-1.119,0.7-0.944,1.924
        c0.175,1.224,0.263,2.098,1.4,2.361c1.138,0.262,1.313,1.05,0.436,1.4c-0.874,0.349-1.837-0.438-2.274,0.961
        c-0.436,1.4,0.788,2.885-0.436,3.324c-1.224,0.436-2.449,0.436-2.972,1.05c-0.525,0.611-2.187,2.098-2.711,2.711
        c-0.525,0.612-0.087,0.961,0,1.749c0.087,0.787,1.575,2.711-0.961,2.798c-2.537,0.087-2.624-1.836-1.662-2.187
        c0.961-0.349,1.137-2.623,0.787-3.498c-0.349-0.874-0.349-1.575-0.874-2.449c-0.525-0.874-0.874-2.271-0.175-3.017
        c0.7-0.745,0.262-1.443,0.087-2.581c-0.175-1.137,0-3.149,1.137-4.897c1.138-1.749,2.536-2.975,3.062-4.547
        c0.524-1.575,0.262-2.711,2.098-2.798c1.837-0.087,3.149-0.175,3.412-0.524c0.262-0.35,0.494-0.175,2.04,0
        c1.545,0.175,0.584-0.262,1.808-1.4c1.225-1.137,2.886-1.575,3.324-0.7c0.436,0.874-0.525,1.749-1.138,2.011
        c-0.612,0.262-1.662,0.612-1.662,1.836s-0.263,1.662-1.051,1.662C516.527,214.016,515.806,213.889,515.04,214.802z" pointer-events="auto" class="landarea"></path>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M488.892,226.084c0,0-1.137-0.309-1.313,1.026c-0.175,1.335-0.874,3.346-1.573,4.921c-0.699,1.575-1.313,2.187-2.187,4.372
        c-0.874,2.187-0.699,2.536-2.011,3.673c-1.313,1.137-2.361,4.023-2.361,7.346c0,3.324,0.087,7.171,0.525,7.522
        c0.438,0.35,1.925,1.05,1.838-1.399c-0.087-2.449,0.611-8.396,1.399-9.795c0.787-1.399,1.224-2.449,1.924-4.46
        c0.699-2.011,0.612-4.111,1.225-5.16c0.612-1.05,1.4-1.836,1.662-2.798C488.28,230.37,489.825,225.884,488.892,226.084z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_10_" x="431.639" y="71.091" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>

        <path fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M406.859,203.522c0,0,0.175-0.7,1.313-1.05c1.137-0.35,1.924-1.749,2.536-0.961c0.612,0.787,1.924,0.961,1.924,2.012
        c0,1.05-0.349,1.924-1.225,3.498c-0.874,1.575-1.486,3.586-2.186,3.062c-0.699-0.524-1.224-1.836-0.699-2.972
        c0.524-1.137,1.925-2.187,0.787-2.361C408.171,204.57,406.771,205.182,406.859,203.522z" pointer-events="auto" class="landarea"></path>

        <rect id="UNANSWERED_3_" x="445.36" y="90.236" opacity="0" fill="#EB35FF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="24.691" height="20.581" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_SLOVAKIA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Slovakia" data-wikipediaLink="https://en.wikipedia.org/wiki/Slovakia" data-infoText="<b>Slovakia</b> &amp;nbsp; Capital: Bratislava" data-imageFile="SLOVAKIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="SLOVAKIA" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M564.715,425.044c-2.975-2.099-3.848-3.848-6.297-5.422c-2.448-1.575-8.395-2.449-9.62-1.575c-1.224,0.874-2.975,3.498-4.547,2.274
        c-1.575-1.225-8.922-2.449-9.97-1.225c-1.049,1.225,0.35,3.498-2.273,2.975c-2.623-0.525-4.372,0.524-4.023-1.575
        c0.349-2.098,0-0.349-1.925-2.449c-1.924-2.099-0.698-5.073-2.798-4.023c-2.098,1.051-3.848,4.897-5.772,4.372
        c-1.924-0.525-1.748-0.874-2.973-2.623c-0.04-0.058-0.083-0.121-0.124-0.183c-0.733,0.291-1.686,0.617-2.5,0.706
        c-1.575,0.176-5.248,3.149-5.073,3.674c0.176,0.524-3.149,2.449-2.972,4.547c0.175,2.099-0.35,2.798-2.1,3.498
        c-1.749,0.698-2.005,0.698-4.064,2.098s-3.458,1.4-5.032,0.874c-1.573-0.524-2.449-1.979-3.324,0.323
        c-0.874,2.301-2.623,2.647-2.1,4.748c0.525,2.101,0.332,4.963-0.095,6.331c-0.286,0.915,2.902,3.72,4.315,6.525
        c0.227-0.153,0.455-0.272,0.679-0.342c1.4-0.432,4.198,2.706,6.297,3.755c2.098,1.051,1.575,1.575,4.372,1.749
        c2.798,0.175,5.073,0,6.642,0c1.57,0,4.201-1.225,3.678-2.623c-0.524-1.399,1.4-3.148,1.481-4.198
        c0.08-1.05,7.091-0.175,7.66-0.715s2.229-0.509,2.404-1.384s3.498-1.575,4.197,0c0.7,1.575,1.575,2.098,2.798,0
        c1.225-2.099,4.023-0.699,4.579-1.925c0.555-1.224,2.243-3.849,3.993-6.255c1.75-2.408,3.673,0.484,5.947,2.254
        c2.274,1.77,2.449-0.721,4.372-2.052c1.924-1.33,5.597,4.303,6.296,4.303c0.699,0,1.4-0.524,3.498-1.575
        c0.719-0.359,1.372-0.469,1.925-0.464c0.008-1.894,0.104-3.503,1.399-4.959c0.99-1.112,3.191-4.586,3.416-8.069
        C566.403,426.134,565.645,425.699,564.715,425.044z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_18_" x="494.764" y="424.165" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_SWITZERLAND" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Switzerland" data-wikipediaLink="https://en.wikipedia.org/wiki/Switzerland" data-infoText="<b>Switzerland</b> &amp;nbsp; Capital: Bern" data-imageFile="SWITZLND.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="SWITZERLAND" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M373.786,455.213c-2.52,0.441-3.92,0.422-5.318-1.056c-1.4-1.477-5.947-0.085-3.498,1.056c2.449,1.14,4.27,3.589,1.348,3.24
        c-2.922-0.35-4.846-1.96-6.246-1.33s-3.673,0.28-4.723,0.806c-1.05,0.524-1.924,0.487-2.623-0.806
        c-0.7-1.292-1.749-0.244-2.098,1.155c-0.35,1.399-1.225,3.497-2.623,2.448c-1.4-1.05-2.1-2.099-2.798-1.749
        c-0.699,0.349-4.723,3.148-2.799,4.022c1.925,0.874,2.799,2.965,1.925,3.145c-0.874,0.179-5.422,1.754-5.597,3.154
        s-1.4,1.4-2.623,1.749c-1.224,0.349,0.525,1.051,0,2.798c-0.525,1.749-2.623,2.449-3.149,3.149c-0.524,0.7-2.274,3.848-2.099,4.897
        s0,4.113-1.049,4.767c-1.05,0.655,0.874,1.344,1.749,1.612c0.874,0.266,3.324-2.531,2.799-3.581
        c-0.525-1.049,0.349-3.673,1.575-3.673c1.224,0,4.897-0.525,4.897,1.575s-0.874,4.714,0.874,5.681
        c1.749,0.967,2.1,2.191,2.274,3.765c0.175,1.575,0.524,2.098,3.148,1.225c2.624-0.874,5.598-2.1,6.297-1.4
        c0.7,0.699,3.673-0.06,4.547-1.43c0.874-1.368,2.274-5.719,1.749-6.955s3.498-1.585,4.723-1.934
        c1.224-0.35-1.575,4.637,0.175,5.117c1.749,0.481,5.422,0.829,5.947,2.404c0.525,1.575,1.575,3.466,2.449,4.618
        c0.874,1.154,2.448-1.88,1.399-3.25c-1.05-1.368-0.524-1.628,0.874-3.773c1.4-2.144-0.175-6.692,1.4-6.168
        c1.575,0.525,1.05,4.024,3.148,4.198c2.098,0.175,6.647-2.952,6.998-1.214c0.349,1.739,2.273,4.452,2.449,3.183
        c0.175-1.27,0-4.593-1.049-5.817c-1.05-1.224-0.756-3.848,1.109-3.148c1.865,0.699,3.789,4.198,4.139,1.749
        c0.189-1.323,0.229-3.765,0.663-5.762c-1.037-0.77-1.137-3.106-1.537-3.509c-0.525-0.524-2.972,2.449-4.372,2.614
        c-1.399,0.166-2.274-1.915-4.022-3.838c-1.749-1.924-2.1-1.749-1.08-3.498c1.019-1.749,0.905-2.798,0.205-3.673
        c-0.394-0.492-0.178-1.204,0.272-1.736c-0.539-0.394-1.306-0.966-2.352-1.763C378.961,456.527,376.306,454.771,373.786,455.213z" pointer-events="auto" class="landarea"></path>

        <rect id="C4_4_" x="316.631" y="462.97" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>

        <rect id="FLAG" x="347.333" y="462.06" opacity="0" fill="#0A11FF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="25.1" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_MOLDOVA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Moldova" data-wikipediaLink="https://en.wikipedia.org/wiki/Moldova" data-infoText="<b>Moldova</b> &amp;nbsp; Capital: Chisinau" data-imageFile="MOLDOVA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="MOLDOVA" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M653.685,497.748c0.583-0.35,3.498-3.266,3.148-4.782c-0.349-1.516,0.467-1.4,2.098-2.098c1.634-0.699,2.333-2.215,2.216-4.198
        c-0.116-1.983-1.281-1.865-1.631-4.197c-0.349-2.333,1.516-2.565,2.564-1.165c1.051,1.4,2.45,1.631,2.799,0.349
        s1.516-0.816,2.332,0.816c0.816,1.631,2.799,1.633,3.498,0c0.7-1.635,1.399,0.933,4.665,1.283c3.265,0.349,1.167-1.05,0-3.031
        c-1.166-1.983-0.992-3.382-2.041-5.48c-1.049-2.098-2.098-3.498-4.722-5.073c-2.623-1.573-1.4-2.973-2.448-5.597
        c-1.049-2.623-2.623-2.973-3.5-4.897c-0.874-1.924,1.575-5.597,1.225-7.696c-0.349-2.1-2.274-1.924-4.023-2.274
        c-1.749-0.35-1.4-2.1-2.449-4.723c-1.05-2.623-4.547,0.699-5.598,0c-1.049-0.7-1.749-0.874-2.975-3.469
        c-1.224-2.595-5.771-1.603-7.522-3.002c-1.749-1.4-4.022,0.698-6.122,1.712c-2.1,1.012-5.073,0.562-5.947,0.036
        c-0.459-0.274-1.255,1.04-1.908,2.352c0.981,0.628,2.221,2.5,4.531,3.77c3.498,1.924,2.798,4.372,3.498,9.795
        s4.547,6.647,6.997,9.271c2.449,2.624,6.734,10.669,7.172,12.156s0.7,4.023-1.049,7.26c-1.749,3.235-1.137,3.848-2.19,6.559
        s1.403,2.972,1.229,5.422c-0.175,2.448-1.75,0.961-1.051,3.586c0.373,1.401,1.873,2.798,3.772,3.96
        c1.283-0.925,2.338-1.666,2.847-1.86C654.619,501.946,653.104,498.097,653.685,497.748z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_12_" x="628.582" y="458.366" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="AREA_PORTUGAL" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Portugal" data-wikipediaLink="https://en.wikipedia.org/wiki/Portugal" data-infoText="<b>Portugal</b> &amp;nbsp; Capital: Lisbon" data-imageFile="PORTUGAL.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="PORTUGAL" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M136.712,650.676c1.05-2.623,1.575-2.274,2.449-4.198s2.798-0.349,4.023-1.049c1.224-0.7-0.175-2.449-1.749-2.623
        c-1.575-0.175-1.4-1.924-3.149-4.023c-1.749-2.098-0.35-3.324-0.35-4.525s1.399-2.296,2.449-4.208
        c1.051-1.912,2.1-1.236,2.449-2.398s-0.525-1.462-1.4-1.638s-1.4-2.1-2.449-4.198c-1.05-2.1-0.175-2.274-2.449-3.848
        c-2.273-1.575-0.699-2.098,0.175-3.149c0.874-1.049,2.449,0.874,5.422,0c2.974-0.874,1.4-2.448,1.575-5.21s-1.4-2.486-2.099-3.885
        c-0.699-1.4,2.623-2.448,4.023-4.198c1.4-1.749,0.175-1.224-0.525-2.623c-0.699-1.4-0.524-1.575-0.175-2.274
        c0.35-0.699,0.175-4.722-0.874-5.772c-1.05-1.049,0.175-1.925,1.4-3.149c1.224-1.225,2.274-1.749,3.323-2.972
        c1.051-1.225,5.772-4.198,5.772-5.422c0-1.225-2.623-2.274-5.422-2.623c-2.798-0.35-0.874-3.324-1.4-4.189
        c-0.525-0.864-1.4-0.184-3.849-0.709c-2.449-0.524-2.1-0.175-2.972-0.175c-0.874,0-2.623,3.149-4.722,2.799
        c-2.099-0.349-2.975-2.448-5.248-0.7c-2.274,1.75-3.848,0.35-5.772-1.215c-1.924-1.564,2.449-1.409,2.623-3.332
        c0.175-1.925-3.148,0-4.722,0s-5.422,1.749-5.947,2.798c-0.228,0.455-1.248,1.406-2.328,2.335c0.339,0.679,0.938,1.109,1.453,1.453
        c1.05,0.7,0.35,2.215,0,4.782c-0.349,2.565,1.05,5.947,2.1,8.628c1.05,2.682,0.816,3.964,0.234,6.88
        c-0.583,2.915-2.332,4.431-3.265,6.645c-0.933,2.215,1.516,2.683,0.932,3.966c-0.583,1.282-1.865,3.149-3.265,6.879
        c-1.4,3.732-1.983,3.964-2.916,4.897c-0.933,0.932-0.233,3.732-0.699,4.665c-0.467,0.932-2.215,6.181-2.916,7.347
        c-0.699,1.165,0.117,2.448,3.382,2.333c3.265-0.116,1.516-2.682,3.731-3.265c2.215-0.583,0.933,2.098-0.583,3.031
        s-1.4,2.682-1.866,4.665c-0.468,1.983,1.866,0.467,3.498-0.816c1.633-1.282,2.916,0.699,3.964,1.283
        c1.051,0.584,0.35,0.933-1.05,0.583c-1.4-0.349-1.167,1.983-1.633,3.964c-0.466,1.983-0.466,1.749,0,4.198
        c0.467,2.449,0.467,2.332-0.116,4.431c-0.583,2.1-0.583,3.964-0.116,7.347c0.467,3.381-0.699,3.732-1.983,4.781
        c-1.282,1.05,0.116,1.283,1.866,0.467c1.749-0.816,3.148-0.933,5.248-1.166c2.1-0.234,2.565,0.235,5.597,1.4
        c3.033,1.165,3.498,0.349,5.131-0.35c1.633-0.7,3.498-0.932,5.014-3.148c0.247-0.361,0.478-0.601,0.704-0.766
        c-0.228-0.993-0.686-1.799-1.405-3.139C134.227,653.271,135.662,653.3,136.712,650.676z" pointer-events="auto" class="landarea"></path>

        <rect id="rect12_25_" x="94.157" y="612.184" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="CITY_ANDORRA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Andorra" data-wikipediaLink="https://en.wikipedia.org/wiki/Andorra" data-infoText="<b>Andorra</b> &amp;nbsp; Capital: Andorra la Vella" data-imageFile="ANDORRA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <polygon fill="#1e8346" stroke="#95bec8" stroke-miterlimit="10" points="260.355,556.673 259.578,556.047 259.604,555.526
        257.172,554.737 255.491,554.202 254.93,554.457 254.178,555.157 253.962,556.735 254.001,557.691 253.556,557.88 253.721,558.48
        254.651,559.397 255.833,559.32 257.31,559.027 257.999,558.225 259.107,558.085   " pointer-events="auto" stroke-width="0.5" class="landarea"></polygon>
    <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" cx="256.658" cy="556.043" r="23.853" pointer-events="auto" opacity="0.001" class="clickarea landarea"></circle>
    <path fill="#1e8346" stroke="#95bec8" stroke-miterlimit="10" d="M262.458,556.985c0,2.868-2.326,5.194-5.194,5.194
        c-2.868,0-5.194-2.326-5.194-5.194s2.175-4.907,5.194-5.194C260.118,551.517,262.458,554.117,262.458,556.985z" pointer-events="auto" stroke-width="0.5" class="landarea"></path>
</g>
<g id="AREA_LUXEMBURG" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Luxembourg" data-wikipediaLink="https://en.wikipedia.org/wiki/Luxemburg" data-infoText="<b>Luxembourg</b> &amp;nbsp; Capital: Luxembourg" data-imageFile="LUXEMBURG.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="LUXEMBURG" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M336.807,411.75c1.4-1.05,0.175-1.749-2.799-3.343c-2.973-1.594-1.924-2.603-1.924-4.353c0-0.81-0.263-1.92-0.546-2.879
        l-0.154,0.256c0,0-2.099-2.449-2.799,0c-0.699,2.449-2.274,1.083-2.449,4.388c-0.175,3.306,1.4,4.531,1.575,5.93
        c0.094,0.758-0.068,2.593-0.236,4.2c1.714,0.216,2.958,0.896,3.208,1.398c0.312,0.624,2.98-1.106,4.177-0.38
        C335.293,414.4,336.132,412.257,336.807,411.75z" pointer-events="auto" class="landarea"></path>

        <rect id="C3_1_" x="301.837" y="404.892" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
    <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" cx="330.411" cy="411.688" r="15.438" pointer-events="auto" opacity="0.001" class="clickarea landarea"></circle>
</g>
<g id="CITY_MALTA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Malta" data-wikipediaLink="https://en.wikipedia.org/wiki/Malta" data-infoText="<b>Malta</b> &amp;nbsp; Capital: Valletta" data-imageFile="MALTA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <path fill="#1e8346" stroke="#95bec8" stroke-miterlimit="10" d="M454.061,684.027c0,2.328-1.888,4.215-4.215,4.215
        s-4.215-1.888-4.215-4.215c0-2.328,1.891-4.098,4.215-4.215C452.486,679.678,454.061,681.699,454.061,684.027z" pointer-events="auto" stroke-width="0.5" class="landarea"></path>

        <ellipse fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" cx="449.846" cy="689.403" rx="26.055" ry="20.085" pointer-events="auto" opacity="0.001" class="clickarea landarea"></ellipse>
</g>
<g id="CITY_LIECHTENSTEIN" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Liechtenstein" data-wikipediaLink="https://en.wikipedia.org/wiki/Liechtenstein" data-infoText="<b>Liechtenstein</b> &amp;nbsp; Capital: Vaduz" data-imageFile="LIECHTENSTEIN.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <rect id="C5" x="370.514" y="458.384" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="30.381" height="19.629" pointer-events="auto" visibility="hidden"></rect>
    <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" cx="389.822" cy="471.234" r="15.438" pointer-events="auto" opacity="0.001" class="clickarea landarea"></circle>
    <path fill="#1e8346" stroke="#95bec8" stroke-miterlimit="10" d="M389.441,469.515c0,2.797-2.269,5.065-5.065,5.065
        s-5.065-2.269-5.065-5.065s2.316-5.587,5.065-5.065C389.441,465.41,389.441,466.718,389.441,469.515z" pointer-events="auto" stroke-width="0.5" class="landarea"></path>
</g>
<g id="CITY_MONACO" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Monaco" data-wikipediaLink="https://en.wikipedia.org/wiki/Monaco" data-infoText="<b>Monaco</b> &amp;nbsp; Capital: Monaco" data-imageFile="MONACO.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" cx="349.612" cy="535.61" r="16.454" pointer-events="auto" opacity="0.001" class="clickarea landarea"></circle>
    <path fill="#1e8346" stroke="#95bec8" stroke-miterlimit="10" d="M353.161,536.391c0,2.82-2.287,5.106-5.106,5.106
        s-5.106-2.287-5.106-5.106s2.493-4.388,5.106-5.106C350.773,530.536,353.161,533.569,353.161,536.391z" pointer-events="auto" stroke-width="0.5" class="landarea"></path>
</g>
<g id="CITY_SANMARINO" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="San Marino" data-wikipediaLink="https://en.wikipedia.org/wiki/San_Marino" data-infoText="<b>San Marino</b> &amp;nbsp; Capital: San Marino" data-imageFile="SANMARINO.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" cx="426.609" cy="528.396" r="17.1" pointer-events="auto" opacity="0.001" class="clickarea landarea"></circle>

        <rect id="C3_6_" x="405.26" y="521.304" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="32.743" height="19.629" pointer-events="auto" visibility="hidden"></rect>
    <path fill="#1e8346" stroke="#95bec8" stroke-miterlimit="10" d="M428.169,530.114c0,2.804-2.274,5.076-5.076,5.076
        s-5.263-2.279-5.076-5.076c0.186-2.789,2.274-5.076,5.076-5.076C425.898,525.036,428.169,527.309,428.169,530.114z" pointer-events="auto" stroke-width="0.5" class="landarea"></path>
</g>
<g id="CITY_VATICAN" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Vatican City" data-wikipediaLink="https://en.wikipedia.org/wiki/Vatican_City" data-infoText="<b>Vatican City State</b> &amp;nbsp; Capital: Vatican City State" data-imageFile="VATICAN.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">
    <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" cx="420.385" cy="582.21" r="18.119" pointer-events="auto" opacity="0.001" class="clickarea landarea"></circle>

        <rect id="C4_2_" x="409.526" y="567.117" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="30.002" height="19.629" pointer-events="auto" visibility="hidden"></rect>
    <path fill="#1e8346" stroke="#95bec8" stroke-miterlimit="10" d="M430.168,575.814c0,2.866-2.324,5.189-5.189,5.189
        s-5.189-2.324-5.189-5.189c0-2.866,2.325-5.101,5.189-5.189C428.35,570.522,430.168,572.949,430.168,575.814z" pointer-events="auto" stroke-width="0.5" class="landarea"></path>
</g>
<g id="CITY_CYPRUS" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Cyprus" data-wikipediaLink="https://en.wikipedia.org/wiki/Cyprus" data-infoText="<b>Cyprus</b> &amp;nbsp; Capital: Nicosia" data-imageFile="CYPRUS.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="CYPRUS" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M741.33,687.363c-0.129-0.025-1.881,0.306-2.756,1.138c-0.874,0.831-1.268,1.006-2.406,1.268c-1.137,0.262-0.874-0.131-2.361,1.049
        s-1.53,1.267-2.536,1.442s-1.749,0.656-3.148,0.788c-1.4,0.131-1.705,0.611-1.837,0.787c-0.131,0.175-0.393,0.349-2.056,0
        c-1.662-0.349-2.448-0.918-3.454-0.525s-1.967,0.438-3.018,0.176c-1.049-0.263-1.486-0.252-1.399,0.552s0.722,1.94-0.01,2.596
        c-0.732,0.655-0.296,1.093-1.519,0.524c-1.225-0.569-2.536-1.4-3.018-0.787s-0.482,1.53-1.225,2.056
        c-0.744,0.524-0.831,1.399-1.356,0.787c-0.524-0.612-1.267-1.487-1.442-0.787c-0.175,0.698,0.699,2.361,0.787,3.104
        c0.087,0.744,0,0.918,0.744,2.144c0.744,1.224,0.874,0.831,2.1,1.487c1.225,0.656,1.181,1.575,2.449,1.137
        c1.269-0.436,1.618-1.267,2.144-0.699c0.525,0.569,0.656,1.443,1.487,1.837c0.831,0.393,1.268,1.006,1.53-0.437
        s-0.306-2.231,1.224-2.1c1.53,0.131,2.493,0.394,3.367-0.437s1.618-0.831,2.493-1.312c0.874-0.482,1.837-0.874,1.705-1.792
        c-0.131-0.918,0.569-1.793,1.88-1.443c1.313,0.349,1.967,0.745,2.974,0.262c1.006-0.482,1.355-0.393,0.306-1.4
        c-1.049-1.006-2.23-1.18-1.224-2.011s1.137-2.886,2.623-3.324c1.487-0.436,2.056-1.705,3.149-2.143
        c1.093-0.436,1.749-0.482,2.449-1.4C740.674,688.98,742.685,687.626,741.33,687.363z" pointer-events="auto" class="landarea"></path>
    <circle fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-miterlimit="10" cx="719.54" cy="698.813" r="28.584" pointer-events="auto" opacity="0.001" class="clickarea landarea"></circle>
</g>
<g id="AREA_ALBANIA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Albania" data-wikipediaLink="https://en.wikipedia.org/wiki/Albania" data-infoText="<b>Albania</b> &amp;nbsp; Capital: Tirana" data-imageFile="ALBANIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="ALBANIA" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M540.577,589.894c-1.312-1.313-1.837-3.586-1.957-6.034s-0.405-4.634,0-7.084c0.404-2.448,1.346-2.972,1.083-4.285
        c-0.262-1.313-0.874-4.634-1.083-5.859c-0.209-1.225-1.627-0.961-3.552-1.225c-1.924-0.262-0.874-3.411-1.1-3.936
        c-0.226-0.524-0.65-2.536-1.697-1.137c-1.051,1.4-2.886,1.749-3.674,1.749s-1.749-1.925-2.623-2.623
        c-0.874-0.7-0.874,0.787-1.312,1.661s-0.525,1.051-0.964,2.361c-0.437,1.313-1.487,1.837-2.011,2.623
        c-0.525,0.787,0.436,2.361,0.798,2.886c0.361,0.525,0.601,2.098-0.185,3.236c-0.37,0.532-0.256,1.117-0.034,1.592
        c0.438-0.082,0.959-0.201,1.638-0.28c1.983-0.234,1.4,3.732,1.516,5.364c0.117,1.634-1.4,1.631-2.332,2.214
        c-0.933,0.583,0.35,2.215,1.049,2.799c0.7,0.583-0.698,2.215-0.583,4.197c0.116,1.983,0.116,4.431-1.517,5.014
        c-1.631,0.583,0,3.964,0.583,5.947c0.583,1.983-0.698,2.799-0.583,4.315c0.117,1.516,7.114,5.83,8.514,6.413
        c0.548,0.228,0.592,0.616,0.363,0.984c0.541,0.086,0.999,0.246,1.095,0.532c0.262,0.787,1.486,1.464,2.098,1.868
        c0.612,0.406,2.361,0.245,2.098-1.249c-0.262-1.493-0.349-3.493,0.874-3.717c1.224-0.225,4.897,0.388,4.46-1.1
        c-0.438-1.486-0.718-2.711,0.034-4.022c0.753-1.312,1.276-1.314,2.152-2.274c0.874-0.96,2.098-1.224,2.186-2.535
        c0.088-1.313-0.744-2.711-0.722-4.022c0.006-0.347,0.229-0.613,0.554-0.82C544.408,591.379,541.864,591.181,540.577,589.894z" pointer-events="auto" class="landarea"></path>

        <rect id="C3_2_" x="494.335" y="585.353" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<g id="MISC" data-colors="0" class="misc">

        <line fill="none" stroke="#000000" stroke-width="0.25" stroke-miterlimit="10" x1="234.29" y1="41.852" x2="234.29" y2="100.387"></line>

        <line fill="none" stroke="#000000" stroke-width="0.25" stroke-miterlimit="10" x1="7.736" y1="157.533" x2="132.917" y2="157.533"></line>

        <line fill="none" stroke="#000000" stroke-width="0.25" stroke-miterlimit="10" x1="132.917" y1="157.533" x2="234.29" y2="100.527"></line>
</g>
<g id="AREA_SLOVENIA" data-colors="0" data-errors="0" hint-index="0" class="q" data-qText="Slovenia" data-wikipediaLink="https://en.wikipedia.org/wiki/Slovenia" data-infoText="<b>Slovenia</b> &amp;nbsp; Capital: Ljubljana" data-imageFile="SLOVENIA.PNG" data-sayAfter="" data-sayAfterTrans="" data-toggle="0" onclick="checkQuestion(this, evt)">

        <path id="SLOVENIA" fill="#1e8346" stroke="#95bec8" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
        M477.872,473.756c-0.176-1.137-1.837-0.961-3.411-0.961c-1.575,0-1.4,3.061-1.487,3.497c-0.087,0.438-1.837-0.524-2.098-0.611
        c-0.263-0.088-3.586,0.087-3.849,1.173c-0.262,1.086-1.399,0.664-2.361,1.013c-0.961,0.349-3.323-0.263-4.372-0.874
        s-2.536,0.961-3.236,1.662c-0.7,0.699-3.411,3.849-3.848,4.46c-0.436,0.612-0.874,0.35-1.138-0.087
        c-0.262-0.438-1.487-1.137-3.674-1.4c-2.186-0.262-2.186-1.313-2.798-1.749c-0.611-0.438-5.072,0.524-6.116,0.611
        c-1.043,0.088-2.104,2.1-2.716,2.536c-0.612,0.438-1.312,2.1-1.575,3.149c-0.262,1.05,2.537,1.05,3.848,1.224
        c1.313,0.175-2.01,2.187-2.623,3.03c-0.611,0.845,1.487,0.992,2.536,1.08c1.049,0.087,0.262,2.011,0,2.622
        c-0.111,0.26-0.128,0.738-0.11,1.204c0.251,0.014,0.559,0,0.985-0.154c1.282-0.466,1.049,0.116,1.399,1.049
        c0.35,0.933,1.75,0.816,2.682,1.982c0.933,1.167-1.866,1.051-3.732,2.215c-1.44,0.9-1.767,0.554-1.95,0.99
        c1.697,0.2,3.692,0.786,4.661,1.11c1.575,0.525,0.524-0.524,2.012-0.961c1.486-0.438,2.973,0.7,4.896-0.087
        c1.924-0.787,2.361-2.798,3.411-3.673c1.05-0.874,1.05,2.187,1.924,3.411c0.874,1.225,2.623,0.787,4.198,0.525
        s2.274,2.011,3.673,2.011s0.7-2.187,0.438-3.149c-0.263-0.961,0.524-1.224,0.96-2.098c0.438-0.875-0.436-1.137,0-2.281
        c0.438-1.144,2.187-1.218,3.545-1.742c1.358-0.525,0.915-1.662,1.089-3.062c0.175-1.4-0.262-1.837-0.961-2.274
        c-0.7-0.438,1.662-2.972,2.361-3.235c0.7-0.263,2.361-0.699,3.148-1.225c0.787-0.525,0.262-1.049,2.1-1.137
        c1.837-0.087,1.924-1.662,2.187-2.885c0.255-1.19,1.417-1.224,2.366-1.224c-0.1-1.165-0.283-2.154-0.617-2.45
        C478.833,476.292,478.048,474.894,477.872,473.756z" pointer-events="auto" class="landarea"></path>

        <rect id="C4_3_" x="439.043" y="478.453" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="36.307" height="19.629" pointer-events="auto" visibility="hidden"></rect>
</g>
<rect id="SVGHUD" x="-164.314" y="-27.061" stroke="#FFFFFF" stroke-width="6" stroke-miterlimit="10" width="102.039" height="25.028"></rect>
<g id="AREA_GEORGIEN" data-colors="0" data-errors="0" hint-index="0" class="">
    <path id="GEORGIA" fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M878.969,585.933
        c1.865-1.252,9.329-1.547,12.128,0s2.1-1.547,3.226-3.879c1.126-2.332,0.739-0.467,4.004,1.399
        c3.266,1.866,3.964,3.498,5.364,3.498s3.031,0.233,7.463,2.308c4.431,2.076,3.964-2.775,3.498-4.64
        c-0.467-1.866-2.798-2.565-4.431-5.598c-1.631-3.031,0-3.498,0-3.498l2.583-2.583c-0.399-0.093-0.885-0.192-1.652-0.448
        c-2.1-0.7-7.696-3.265-10.261-6.763s1.4-3.964,0-4.897s-5.363-1.4-7.229-3.498c-1.866-2.1-3.265-0.233-4.431,1.918
        c-1.168,2.153-3.265-2.383-5.13-2.383s-6.53,2.798-9.561,2.332c-3.031-0.467,0.232-3.498-2.566-4.198
        c-2.799-0.699-6.763-2.1-7.229-4.43c-0.467-2.332-5.831-0.467-9.796-3.499c-3.964-3.031-8.628,0.233-11.195,0
        c-2.566-0.232-4.665,0.699-6.997-2.332c-2.332-3.031-4.897-2.1-6.997-2.566c-2.098-0.466-4.665-0.467-6.763-1.865
        c-2.1-1.4-3.551,3.414-3.551,3.414l-0.001,0.002c0.473,0.346,1.063,0.875,1.92,1.832c3.031,3.381,2.333,3.498,5.831,4.081
        s4.43,0,6.062,3.964c1.634,3.964,2.798,0.583,5.831,3.732s1.631,5.947,4.664,12.361s-1.865,7.578-3.148,11.078
        c0,0,0.7,0.467,2.449,0.584c1.749,0.117,2.799,1.282,3.382,0s1.05-1.736,2.214-1.043c1.167,0.693,3.732,1.161,4.198,0.927
        c0.467-0.235,2.565,2.942,2.565,0.713s1.049-3.393,2.682-2.811c1.634,0.583,0.816,0.231,3.033,2.564
        c2.216,2.333,2.565,1.633,4.43,3.615c0.791,0.84,1.142,1.238,1.407,1.501C870.523,584.733,877.513,586.91,878.969,585.933z" pointer-events="none" class="landarea"></path>

        <rect id="rect12_6_" x="837.813" y="550.504" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="none"></rect>
</g>
<g id="AREA_ARMENIA" data-colors="0" data-errors="0" hint-index="0" class="">
    <path id="ARMENIA" fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M913.718,627.118
        c0.933-0.646,0-2.385-1.865-4.251c-1.866-1.866,0-3.031,0-4.665c0-1.633-3.265-2.798-7.418-4.896
        c-4.154-2.1-2.843-2.332-3.776-3.964c-0.934-1.631,3.265-1.866,3.732-3.637c0.467-1.77-2.798-3.594-6.296-6.158
        c-3.497-2.565,0-3.498,0-5.363c0-1.866-2.1-3.498-3.497-4.413c-1.4-0.916-1.167-0.252-1.167-2.116c0-0.515-0.18-1.066-0.438-1.592
        c-0.349,0.381-0.899,0.424-1.894-0.127c-2.798-1.547-10.262-1.252-12.128,0c-1.455,0.977-8.446-1.201-12.003,0.888
        c0.361,0.356,0.568,0.459,1.509,1.064c1.633,1.049,1.399,0.234,1.516,1.749c0.117,1.516,0.116,1.165,1.4,2.682
        c1.283,1.516,1.866,2.333,1.283,4.315c-0.584,1.982-2.916,0.932-1.516,4.43s-0.234,6.763,2.449,7.229
        c2.681,0.467,6.296-0.932,8.045,0.816c1.223,1.223,5.286,3.299,6.075,5.394c2.257,1.349,4.343,7.332,6.868,8.949
        c2.916,1.865,3.964,5.248,7.347,5.48c3.382,0.233,4.548,0.933,6.647,0.699c1.099-0.122,2.289,0.234,3.244,0.517
        C911.959,628.506,913.065,627.569,913.718,627.118z" pointer-events="none" class="landarea"></path>

        <rect id="rect12_7_" x="854.146" y="595.837" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.25" stroke-miterlimit="10" enable-background="new    " width="55.842" height="19.629" pointer-events="none"></rect>
</g>
<g id="CITY_THESTRAITOFGIBRALTAR" data-colors="0" data-errors="0" hint-index="0" class="">

        <ellipse id="_x3C_Path_x3E__3_" fill="none" stroke="#95bec8" stroke-miterlimit="10" cx="161.351" cy="687.42" rx="23.137" ry="23.104" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></ellipse>

        <rect id="rect12_15_" x="134.256" y="696.168" opacity="0" fill="#FFFFFF" enable-background="new    " width="49.201" height="16" pointer-events="none"></rect>
    <circle id="CITY_22_" opacity="0" fill="#166c38" enable-background="new    " cx="159.888" cy="686.956" r="8.5" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>
</g>
<g id="CITY_MEDITERRANEAN" data-colors="0" data-errors="0" hint-index="0" class="">

        <rect id="rect12_4_1_" x="464.61" y="697.25" opacity="0" fill="#FFFFFF" stroke="#020304" stroke-width="0.9741" stroke-miterlimit="10" enable-background="new    " width="82.19" height="20.398" pointer-events="none"></rect>
    <circle id="CITY_4_1_" opacity="0" fill="#166c38" enable-background="new    " cx="506.846" cy="687.91" r="7.5" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>

        <ellipse id="_x3C_Path_x3E__1_" fill="none" stroke="#95bec8" stroke-miterlimit="10" cx="516.109" cy="690.126" rx="41.149" ry="40.071" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></ellipse>
</g>
<g id="CITY_ATLANTIC" data-colors="0" data-errors="0" hint-index="0" class="">

        <rect id="rect12_4_2_" x="10.668" y="470.094" opacity="0" fill="#FFFFFF" stroke="#020304" stroke-width="0.9741" stroke-miterlimit="10" enable-background="new    " width="82.19" height="20.398" pointer-events="none"></rect>
    <circle id="CITY_4_2_" opacity="0" fill="#166c38" enable-background="new    " cx="52.904" cy="460.754" r="7.5" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>

        <ellipse id="_x3C_Path_x3E__2_" fill="none" stroke="#95bec8" stroke-miterlimit="10" cx="28.581" cy="455.264" rx="90.4" ry="96.8" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></ellipse>
</g>
<g id="CITY_THEENGLISHCHANNEL" data-colors="0" data-errors="0" hint-index="0" class="">

        <rect id="rect12_7_1_" x="195.634" y="409.48" opacity="0" fill="#FFFFFF" stroke="#020304" stroke-width="0.9741" stroke-miterlimit="10" enable-background="new    " width="81.311" height="16.029" pointer-events="none"></rect>
    <circle id="CITY_7_1_" opacity="0" fill="#166c38" enable-background="new    " cx="237.419" cy="401.285" r="7.5" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>
    <ellipse fill="none" stroke="#95bec8" stroke-miterlimit="10" cx="237.098" cy="400.801" rx="14.167" ry="9.5" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></ellipse>
</g>
<g id="CITY_BLACKSEA" data-colors="0" data-errors="0" hint-index="0" class="">

        <rect id="rect12_4_3_" x="661.628" y="552.62" opacity="0" fill="#FFFFFF" stroke="#020304" stroke-width="0.9741" stroke-miterlimit="10" enable-background="new    " width="82.19" height="20.398" pointer-events="none"></rect>
    <circle id="CITY_4_3_" opacity="0" fill="#166c38" enable-background="new    " cx="703.864" cy="543.28" r="7.5" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>

        <ellipse id="_x3C_Path_x3E__4_" fill="none" stroke="#95bec8" stroke-miterlimit="10" cx="736.093" cy="544.488" rx="64.115" ry="25.646" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></ellipse>
</g>
<g id="CITY_DANUBE" data-colors="0" data-errors="0" hint-index="0" class="">
    <path id="RIVER" fill="none" stroke="#95bec8" stroke-miterlimit="10" d="M667.857,504.562c-0.368,0-0.628,0.038-0.958,0.127
        c-0.366,0.1-0.564-0.104-0.772-0.36c-0.354-0.435-0.584-1.262-1.106-1.498c-0.096-0.478-0.635-1.04-1.056-1.235
        c-0.47-0.217-0.93-0.171-1.438-0.172c-0.274-0.001-0.556-0.02-0.83,0c-0.302,0.022-0.524,0.151-0.798,0.242
        c-0.556,0.185-1.09,0.361-1.596,0.69c-0.469,0.304-0.959,0.75-1.347,1.129c-0.39,0.381-0.865,0.586-1.311,0.861
        c-0.442,0.273-0.735,0.639-1.197,0.88c-0.476,0.248-0.88,0.375-1.415,0.405c-1.031,0.059-2.18-0.032-3.159-0.31
        c-0.443-0.126-0.876-0.319-1.307-0.484c-0.499-0.192-0.78-0.571-1.217-0.938c-0.45-0.38-0.619-0.725-0.931-1.233
        c-0.363-0.59-0.697-0.373-1.27-0.31c-0.502,0.054-1.009-0.16-1.495-0.239c-0.212-0.034-0.709-0.193-0.9-0.076
        c-0.196,0.123-0.388,0.586-0.517,0.787c-0.308,0.479-0.064,0.994,0.141,1.492c0.229,0.556,0.359,1.004,0.282,1.621
        c-0.068,0.557-0.129,1.093-0.129,1.688c0,0.285,0.036,0.562,0.007,0.827c-0.03,0.282-0.171,0.525-0.254,0.793
        c-0.268,0.854-0.368,1.824-0.843,2.58c-0.506,0.151-0.806,0.9-0.564,1.379c0.193,0.135,0.298,0.317,0.475,0.46
        c0.243,0.198,0.412,0.317,0.503,0.683c0.143,0.578-0.276,0.953-0.401,1.491c-0.152,0.661,0.274,0.947,0.497,1.482
        c0.293,0.704-0.077,1.106-0.376,1.714c-0.24,0.49-0.481,1.022-0.631,1.554c-0.147,0.518-0.279,1.098-0.52,1.551
        c-0.146,0.276-0.382,0.444-0.597,0.631c-0.094,0.081-0.182,0.208-0.296,0.259c-0.12,0.054-0.268-0.006-0.382,0.048
        c-0.18,0.086-0.427,0.416-0.542,0.578c-0.07,0.098-0.115,0.25-0.189,0.346c-0.094,0.122-0.279,0.179-0.354,0.282
        c-0.167,0.227-0.257,0.466-0.485,0.682c-0.272,0.255-0.555,0.481-0.822,0.743c-0.38,0.374-1.2,0.473-1.725,0.499
        c-0.319,0.016-0.567-0.16-0.888-0.131c-0.273,0.026-0.528,0.129-0.78,0.265c-0.265,0.144-0.517,0.224-0.822,0.236
        c-0.266,0.011-0.706-0.135-0.928,0.065l-0.859,0.08c-0.544-0.002-1.097-0.078-1.626-0.106c-0.537-0.028-1.686,0.064-2.184,0.244
        c-1.017,0.368-1.634,0.466-2.606,0.932c-0.258,0.123-0.441,0.343-0.701,0.452c-0.248,0.103-0.573,0.412-0.849,0.44
        c-0.522,0.055-1.03-0.032-1.549,0.024c-0.533,0.058-1.089,0.229-1.633,0.295c-0.471,0.055-0.67,0.103-1.112,0.274
        c-0.236,0.092-0.454,0.163-0.669,0.303c-0.206,0.135-0.402,0.336-0.638,0.445c-0.258,0.119-0.523,0.155-0.772,0.277
        c-0.28,0.138-0.557,0.679-0.785,0.879c-0.416,0.366-0.583,0.852-0.902,1.331c-0.149,0.223-0.373,0.443-0.577,0.662
        c-0.244,0.261-0.521,0.457-0.778,0.704c-0.418,0.402-0.795,0.707-1.262,1.012c-0.449,0.294-1.239,0.782-1.694,1.076
        c-0.881,0.567-1.265,1.807-2.393,1.758c-0.298-0.013-0.764,0.133-1.033,0.001c-0.234-0.114-0.529-0.282-0.797-0.353
        c-0.571-0.146-0.684-0.676-1.138-1.029c-0.421-0.327-1.012-0.447-1.532-0.447l-2.851,0.316c-1.176,0-4.732-0.232-6.33-1.26
        c-1.599-1.027-3.479,0.758-6.159,0.487s-4.795-0.974-6.018-2.057c-1.222-1.083-2.445-0.758-6.582,0.433
        c-6.161,2.206-3.126-1.702-2.309-2.721c1.718-2.141-2.63-3.649-4.272-3.667c-0.376-0.107-0.658-0.379-0.894-0.651l-0.43-1.277
        c-2.023-1.615-1.698-3.056-0.734-4.932c0.845-1.623,1.544-1.433,0.344-2.7c-0.851-0.898-1.186-0.433-3.272-0.33
        c-2.106,0.105-3.393,6.346-4.472,3.941c-2.254-5.022-6.662-2.942-7.18-4.025c-0.607-1.27-2.339-3.136-2.47-4.801
        c-0.084-1.059,1.182-1.663-0.116-2.468c-1.576-0.977-2.744,0.479-4,1.198c-0.505,0.288-1.018,0.397-1.595,0.467
        c-1.13,0.136-1.993,0.862-3.047,1.173c-0.555,0.163-1.147,0.574-1.596,0.086c-0.485-0.527-0.006-0.92-0.799-1.24
        c-0.489-0.198-1.104-0.323-1.596-0.408c-0.657-0.114-1.094,0.331-1.54,0.097c-0.058-0.03-0.223-0.074-0.261-0.107
        c-0.28-0.731-0.86-1.282-0.739-2.123c0.06-0.419,0.306-0.4,0.2-0.917c-0.071-0.341-0.335-0.83-0.58-1.085
        c-1.049-1.093-2.98-0.06-4.03-1.361c-0.164-0.994-0.795-0.152-1.19-0.005c-0.413,0.154-1.361,0.097-1.797,0.018
        c-1.218-0.221-2.247,0.08-3.556,0.079c-1.276-0.001-2.314-0.23-3.48-0.63c-0.533-0.183-1.018-0.397-1.329-1.017
        c-0.342-0.679-2.397-0.358-2.457-1.038c-0.125-1.408,0.666-1.64,0.133-2.855c-0.464-1.059-1.604-0.054-1.875-1.176
        c-0.117-0.486-0.099-2.895-0.294-3.338c-0.231-0.527,0.336-1.981-0.09-2.343c-1.553-1.322-2.028-4.757-1.638-6.801
        c0.304-1.591,1.82-1.272,2.832-1.861c1.111-0.646,0.997-2.209,1.16-3.385c0.163-1.175,0.961-2.482,0.638-3.748
        c-0.13-0.513-0.643-0.935-0.425-1.588c0.189-0.563,0.802-0.737,1.181-0.993c1.255-0.85,0.861-1.858,0.655-3.173
        c-0.146-0.933,0.458-0.557,0.636-1.235c0.149-0.567-0.546-1.09-0.584-1.644c-0.049-0.71,0.45-0.932,0.582-1.569
        c0.135-0.65-0.102-1.223-0.496-1.581c-0.043-0.537-0.612-0.974-0.945-1.254c-0.122-0.635,0.281-1.498,0.423-2.103
        c0.128-0.546,0.287-1.141,0.368-1.717c0.206-1.457,0.096-2.196,1.318-3.047c0.504-0.352,0.901-0.549,1.089-1.25
        c0.151-0.564-0.644-0.899-0.561-1.497c0.091-0.658-1.105-1.867-2.256-1.695c-0.719,0.255-0.012,0.888-0.754,0.826
        c-0.593-0.051-0.331-0.586-0.596-0.847c-0.272-0.268-0.695-0.355-1.059-0.314c-0.738,0.085-1.436,0.376-2.264,0.376
        c-0.47,0-0.806,0.005-1.213,0.177c-0.391,0.164-0.702,0.563-1.168,0.462c-2.637,0.672-4.453,0.433-6.543,0.556
        c-1.146,0.066-2.375,0.284-3.32-0.314c-0.518-0.327-1.143,0.278-1.542-0.193c-0.194-0.229-0.188-0.46-0.435-0.613
        c-0.231-0.143-0.615,0.004-0.874-0.087c-2.131-0.76-2.795-3.828-5.217-4.066c-0.591-0.058-1.127,0.204-1.673-0.074
        c-0.46-0.235-0.743-1.006-1.229-1.242c-1.219-0.59-2.285,0.485-3.253,0.926c-1.459,0.666-3.158,0.178-4.535-0.495
        c-1.337-0.653-2.547-0.996-3.516-2.324c-0.897-1.23-1.724-1.355-3.138-1.49c-2.546-0.241-4.421-1.797-6.965-1.959
        c-0.371-0.024-0.795-0.064-1.106-0.285c-0.507,0.779-1.989,0.524-2.292,1.083c-0.234,1.485-0.928,2.426-2.336,2.653
        c-0.469,0.075-1.258,0.287-1.812,0.155c-0.514-0.123-0.923-0.722-1.448-0.787c-0.606-0.076-0.976,0.416-1.531,0.612
        c-0.768,0.271-0.811-0.058-1.444-0.355c-0.629-0.296-1.256-0.068-1.886-0.176c-0.618-0.104-1.16-0.5-1.818-0.39
        c-1.378,0.232-2.363,0.567-3.411-0.78c-0.165-0.214-0.27-0.616-0.449-0.787c-0.417-0.4-0.344-0.205-0.857-0.175
        c-0.563,0.034-1.212,0.093-1.687-0.138c-0.46-0.223-0.929-0.753-1.213-1.099c-0.253-0.308-0.238-0.473-0.583-0.628
        c-0.297-0.134-0.556,0.044-0.854-0.156c-0.407-0.274-0.573-1.109-0.724-1.505c-0.274-0.724-0.564-1.136-0.979-1.789
        c-0.555-0.874-0.893-1.495-1.867-1.8c-0.936-0.294-1.621-0.684-2.396-1.408c-0.842-0.786-1.583-1.169-2.576-1.596
        c-0.734-0.316-2.092-1.489-2.516-2.229c-0.01-0.019-1.536-0.359-1.724-0.416c-0.648-0.197-1.217-0.139-1.891-0.145
        c-0.749-0.007-1.287-0.251-1.96-0.551c-0.634-0.283-0.987-0.616-1.756-0.624c-0.823-0.009-1.32,0.243-2.033,0.393
        c-1.255,0.262-2.007-1.5-3.249-1.865c-0.649-0.191-1.355,0.104-1.96-0.166c-0.666-0.298-0.908-0.984-1.668-1.103
        c-0.8-0.125-0.969,0.419-1.522,0.629c-0.801,0.304-0.983-0.218-1.586,0.623c-0.988,1.378-1.675,1.065-3.204,1.099
        c-0.604,0.013-1.253,0.175-1.886,0.314c-0.723,0.158-0.874,0.503-1.471,0.94c-0.923,0.678-2.156,0.977-3.246,0.699
        c-1.138-0.289-2.321-0.623-3.556-0.62c-1.449,0.002-2.288,0.46-3.409,1.333c-0.481,0.374-0.979,0.699-1.596,0.784
        c-0.823,0.116-0.988-0.376-1.653-0.57c-0.7-0.203-0.736,0.367-1.316,0.736c-0.439,0.278-1.357,0.583-1.892,0.616
        c-0.411,0.025-0.794-0.294-1.237-0.16c-0.412,0.124-0.868,0.616-1.286,0.804c-0.771,0.349-1.47,1.099-2.337,1.236
        c-1.218,0.193-0.404,0.022-1.308-0.527c-2.893-1.762-1.38,1.418-2.034,3.267c-0.433,1.229-1.091,2.735-2.412,2.346
        c-1.487-0.44-2.6-1.579-4.198-0.133c-0.506,0.459-0.98,0.985-1.39,1.539c-0.584,0.792-0.585,0.861-1.455,0.652
        c-0.135-0.033-0.308-0.23-0.454-0.301c-0.355,0.545-0.185,1.28-0.5,1.832c-0.398,0.696-1.16,1.057-1.784,1.427
        c-0.712,0.421-0.911,1.252-1.537,1.634c-0.882,0.538-1.802-0.041-2.386-0.547c-0.034-0.722,0.241-1.584-0.085-2.259" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></path>

        <rect id="rect12" x="494.112" y="487.61" opacity="0" fill="#FFFFFF" stroke="#030405" stroke-width="0.9741" stroke-miterlimit="10" enable-background="new    " width="46.068" height="20.3" pointer-events="none"></rect>
    <polygon id="Circle" fill="none" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" points="380.383,423.085
        366.574,439.721 364.382,448.838 372.148,450.916 409.526,435.16 432.312,444.773 480.935,457.013 510.964,462.06 504.405,493.939
        526.068,515.772 562.267,535.797 617.247,540.366 676.622,508.781 670.991,491.095 632.8,500.244 604.57,519.582 558.412,512.12
        530.491,498.082 531.366,450.095 465.596,438.047 424.527,422.92  " pointer-events="none" opacity="0" class="landarea"></polygon>

        <ellipse id="CITY_4_16_" opacity="0" fill="#166c38" enable-background="new    " cx="518.67" cy="476.842" rx="7.706" ry="7.83" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></ellipse>
</g>
<g id="CITY_RHINE" data-colors="0" data-errors="0" hint-index="0" class="">
    <line fill="none" stroke="#FFFFFF" stroke-miterlimit="10" x1="338.662" y1="309.104" x2="338.662" y2="383.587" pointer-events="none" opacity="0"></line>
    <path id="RIVER_9" fill="none" stroke="#95bec8" stroke-miterlimit="10" d="M371.009,478.602c1.377-0.492,4.182,0,4.5-1.489
        c0.583-2.601,1.873-2.345,3.887-3.023c1.154-0.331,0.631-3.728,0.608-4.607c-0.089-1.657,2.269,0.475,2.197-1.065
        c-0.425-0.997,0.835-1.939,0.938-2.969c0.164-1.629-0.559-2.106-0.394-3.474c0.143-1.183,0.662-2.394-0.289-3.58
        c-0.439-0.547-2.834-0.958-3.768-1.639c-0.726-0.53-1.74-0.968-2.504-0.96c-1.24,0.014-3.616-0.422-4.283-0.186
        c-1.523,0.538-5.309-1.923-6.624-1.906c-1.414,0.918,1.938,1.19,2.209,3.422c0.059,0.891,0.182,0.9-0.303,1.34
        c-0.963,0.874-2.444,0.739-3.047,0.172c-1.895-1.777-5.123-1.138-5.742-1.311c-3.82,1.058-7.163,0.341-7.048-1.53
        c0.082-1.325,0.098-1.982,0.134-4.291c0.021-1.321,1.887-1.978,1.902-3.044c0.023-1.685-0.316-1.819,0.243-3.515
        c0.509-1.544,0.814-3.794,1.234-5.028c0.43-1.262,0.955-3.428,1.6-4.492c1.39-2.293,2.736-2.473,4.251-3.515
        c1.289-0.887,1.66-3.893,1.259-5.326c-0.453-1.623-3.221-5.078-2.856-6.723c0.73-3.292-0.66-5.251-1.603-8.244
        c-0.409-1.299,0.722-6.438-1.948-6.436c-0.927-1.493-4.037-0.189-5.381-1.702c-1.126-0.042-2.494-1.285-3.012-2.367
        c-0.603-1.259-0.697-3.571-1.566-4.591c-0.458-0.539-1.553-0.721-2.178-1.139c-0.768-0.512-1.231-1.271-1.699-1.945
        c-0.842-1.212-1.801-2.499-2.643-3.747c-1.522-2.251-2.471-5.077-4.099-7.228c-0.941-1.245-1.489-1.268-1.998-2.952
        c-0.473-1.567-0.495-2.539-0.446-4.085c0.103-3.242-2.425-5.108-4.39-7.309c-1.634-1.829-4.322-3.163-6.474-4.213
        c-2.503-1.222-5.218-2.65-8.027-1.516c-2.656,1.073-5.288,2.361-7.904,3.609c-1.66,0.792-6.017,0.413-7.892,0.86" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></path>

        <rect id="rect12_9" x="314.304" y="298.142" opacity="0" fill="#FFFFFF" stroke="#030405" stroke-width="0.9741" stroke-miterlimit="10" enable-background="new    " width="48.476" height="21.923" pointer-events="none"></rect>

        <ellipse id="CITY_4_10_" opacity="0" fill="#166c38" enable-background="new    " cx="339.043" cy="386.64" rx="8.108" ry="8.456" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></ellipse>

        <ellipse fill="none" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" cx="338.052" cy="384.743" rx="14.447" ry="14.283" pointer-events="none" opacity="0" class="landarea"></ellipse>
</g>
<g id="CITY_BALTICSEA" data-colors="0" data-errors="0" hint-index="0" class="">

        <rect id="rect12_4_4_" x="492.087" y="192.193" opacity="0" fill="#FFFFFF" stroke="#020304" stroke-width="0.9741" stroke-miterlimit="10" enable-background="new    " width="82.19" height="20.398" pointer-events="none"></rect>
    <circle id="CITY_4_4_" opacity="0" fill="#166c38" enable-background="new    " cx="534.323" cy="182.853" r="7.5" pointer-events="none" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>

        <ellipse id="_x3C_Path_x3E__5_" fill="none" stroke="#95bec8" stroke-miterlimit="10" cx="536.12" cy="182.079" rx="21.728" ry="37.081" pointer-events="none" opacity="0" stroke-width="0.5px" class="landarea"></ellipse>
</g>
<g id="CITY_NORTHSEA" data-colors="0" data-errors="0" hint-index="0" class="">
    <rect id="rect12_4_5_" x="239.416" y="242.802" fill="none" width="82.19" height="20.398" pointer-events="none" opacity="0"></rect>
    <circle id="CITY_4_5_" fill="none" cx="281.652" cy="233.462" r="7.5" pointer-events="none" opacity="0" stroke="#95bec8" stroke-width="0.5px" class="landarea"></circle>
    <ellipse id="_x3C_Path_x3E__6_" fill="none" cx="282.942" cy="249.435" rx="56.101" ry="53.828" pointer-events="none" opacity="0" stroke="#95bec8" stroke-width="0.5px" class="landarea"></ellipse>
</g>
<g id="AREA_AZERBAIJAN" data-colors="0" data-errors="0" hint-index="0" class="">
    <path id="AZERBAIJAN" fill="#166c38" stroke="#95bec8" stroke-width="0.5px" stroke-miterlimit="10" d="M965.374,597.912
        c-3.732-1.634-6.414,1.049-8.047-2.1c-1.631-3.149-1.634-2.215-3.382-3.265c-1.749-1.049-0.933-4.082-3.265-8.514
        c-2.332-4.43-1.631-2.215-3.848-5.014c-2.215-2.798-2.448-2.214-4.082-4.896c-0.268-0.44-0.485-0.762-0.672-1.01
        c-2.065,2.926-3.688,7.343-5.507,8.706c-2.797,2.1-2.332,1.866-3.498,2.798c-1.165,0.932-2.098,1.631-4.896,1.167
        c-2.799-0.467-3.498-1.167-4.897-3.498c-1.4-2.332-1.866-2.1-3.265-4.431c-1.4-2.332-3.031-2.333-4.897-3.732
        c-1.185-0.889-1.618-1.025-2.314-1.185l-2.583,2.583c0,0-1.631,0.467,0,3.498c1.633,3.033,3.964,3.732,4.431,5.598
        c0.467,1.865,0.933,6.717-3.498,4.64c-4.431-2.074-6.063-2.308-7.463-2.308s-2.098-1.631-5.364-3.498
        c-3.265-1.865-2.877-3.731-4.004-1.399c-0.727,1.502-0.697,3.316-1.332,4.005c0.257,0.527,0.438,1.077,0.438,1.592
        c0,1.864-0.233,1.199,1.167,2.117c1.399,0.915,3.497,2.547,3.497,4.413c0,1.865-3.497,2.799,0,5.363
        c3.498,2.565,6.764,4.387,6.296,6.159c-0.467,1.771-4.666,2.005-3.732,3.637c0.933,1.634-0.377,1.865,3.776,3.964
        c4.154,2.098,7.418,3.265,7.418,4.896c0,1.634-1.866,2.799,0,4.665c1.865,1.865,2.798,3.605,1.865,4.251
        c-0.652,0.452-1.758,1.39-1.887,3.029c0.871,0.26,1.547,0.459,1.77,0.181c0.466-0.584,1.167-2.332,2.915-3.498
        c1.749-1.167,2.215-0.7,3.615-2.915c1.4-2.214,8.746-6.764,10.028-8.045c1.282-1.283,4.431,0.116,7.114,1.982
        c2.682,1.865,0.466,2.799-1.283,3.848s1.517,2.1,2.682,4.548c1.167,2.449-2.916,1.049-4.314,1.749
        c-1.4,0.699,1.865,4.547,3.848,6.763c1.983,2.216,4.781,5.015,8.162,3.032c3.382-1.983-0.933-3.615-1.633-5.947
        c-0.7-2.332,1.166-1.282,2.448-2.1c1.282-0.816,0.933-4.547,1.4-5.598c0.466-1.05,1.165,1.051,1.983,3.031
        c0.816,1.983,1.282-0.116,1.749-2.916c0.467-2.798,1.165-2.798,2.449-2.448c1.283,0.349,0.583-1.634-1.049-2.566
        c-1.631-0.932-0.467-3.031,0.583-4.665c1.049-1.634,1.049-5.131,2.566-8.744c1.516-3.614,4.781-2.1,7.114-3.498
        c2.333-1.4,4.43,0.816,5.014,0C969.571,601.527,969.105,599.543,965.374,597.912z" pointer-events="none" class="landarea"></path>

        <rect id="rect12_8_" x="894.799" y="580.615" opacity="0" fill="#FFFFFF" stroke="#1D1D1B" stroke-width="0.1285" stroke-miterlimit="10" enable-background="new    " width="14.757" height="19.629" pointer-events="none"></rect>
</g>
</svg>
}