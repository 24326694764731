import React from 'react'

import africa from './africa'
import northAmerica from './north-america'
import southAmerica from './south-america'
import asia from './asia'
import oceania from './oceania'
import europe from './europe'
import poland from './poland'
import germany from './germany'
import italy from './italy'

import bem from '../bem'
import './map.scss'


const maps = {
   asia,
   africa,
   northAmerica,
   southAmerica,
   oceania,
   europe,
   poland,
   germany,
   italy,
}


export default function Map({mapType, selectedId, onClick}) {
   const ref = React.useRef()
   function handleClick(id) {
      if (onClick) {
         onClick(id)
      }
   }
   const Map = maps[mapType]

   React.useEffect(() => {
      if (!ref.current) {return}
      Array.from(ref.current.querySelectorAll('.map__element--selected')).forEach(e => {
         e.classList.remove('map__element--selected')
      })
      if (!selectedId) {return}
      const selected = document.getElementById(selectedId)
      if (selected) {
         selected.classList.add('map__element--selected')
      }
   }, [selectedId])

   function setup(svgContainer) {
      if (ref.current === svgContainer) {return}
      if (!svgContainer) {return}
      ref.current = svgContainer
      const gs = Array.from(svgContainer.querySelectorAll('g'))
      // const clickable = []
      gs.forEach(g => {
         if (!g.id) {return}
         const gIsClickable = g.firstChild.getAttribute('pointer-events')
         if (gIsClickable !== 'none') {
            // clickable.push(mapType + ' ' + g.id)
            g.addEventListener('click', () => handleClick(g.id))
         }
      })
      // console.log(clickable.join('\n'))
   }

   return <div className={bem("map", {clickable: !!onClick})} ref={setup}>
      <Map />
   </div>
}
